import { makeAutoObservable, reaction } from "mobx";
import { Constants } from "../constants/Constants";
import { WindowObject } from "../models/common";
import { ServerError } from "../models/serverError";

export default class CommonStore{
    errorMessage: string | null = null;
    serverError: ServerError | null = null;
    successMessage: string = "";
    successMessageWithoutNavigation: string = "";
    token: string | null = window.localStorage.getItem("jwt");
    locale: string = Constants.en;
    appLoaded = false;
    loading: boolean = false;
    loadingModal: boolean = false;
    totalItem: number = 0;
    showDeletePrompt: boolean = false;
    showPendingMenu: boolean = window.localStorage.getItem("showPendingMenu") ? true : false;
    branchRefresh: boolean = false;
    windowSize: WindowObject = {innerWidth: 0, innerHeight: 0};
    loadNavigation: boolean = false;
    displayName: string = "";
    displayPermissionDenied: boolean = false;
    prevPath : string = "";
    showCancelPrompt: boolean = false;
    showHelpMenu: boolean = false;
    dropdownCustomSearchValue: string = "";
    hideComponentForPrint: boolean = false;
    blnDevAndUatEnv: boolean = false;

    constructor(){
      makeAutoObservable(this);

      reaction(
        () => this.token,
        token => {
          if(token)
              window.localStorage.setItem("jwt",token)
          else
              window.localStorage.removeItem("jwt")
        }
      )
    } 

    setDisplayName = (displayName: string) => {
      this.displayName = displayName;
    }

    setServerError = (error: ServerError ) => {
      this.serverError = error;
    }

    setErrorMessage = (errorMessage: string ) => {
        this.errorMessage = errorMessage;
    }
    
    setSuccessMessage = (successMessage: string) => {
      this.successMessage = successMessage;
    }

    setSuccessMessageWithoutNavigation = (successMessageWithoutNavigation: string) => {
      this.successMessageWithoutNavigation = successMessageWithoutNavigation;
    }

    setToken = (token:string|null) => {
      this.token = token;
    }

    setLocale = (locale: string) => {
      this.locale = locale;
    }

    setAppLoaded = () => {
      this.appLoaded = true;
    }

    setLoading = (loading) => {
      this.loading = loading;
    }

    setLoadingModal = (loadingModal) => {
      this.loadingModal = loadingModal;
    }

    setTotalItem = (totalItem: number) => {
        this.totalItem = totalItem;
    }

    setShowDeletePrompt = (showDeletePrompt: boolean) => {
      this.showDeletePrompt = showDeletePrompt;
    } 

    setShowCancelPrompt = (showCancelPrompt: boolean) => {
      this.showCancelPrompt = showCancelPrompt;
    } 

    setShowPendingMenu = (showPendingMenu: boolean) => {
      this.showPendingMenu = showPendingMenu;
      if (showPendingMenu) {
        window.localStorage.setItem("showPendingMenu", "show");
      }
      else {
        window.localStorage.removeItem("showPendingMenu")
      }
    }

    setBranchRefresh = (branchRefresh: boolean) => {
      this.branchRefresh = branchRefresh;
    }

    setWindowSize = (windowSize: WindowObject) => {
      this.windowSize = windowSize;
    }

    setLoadNavigation = (blnNavigation: boolean) => {
      this.loadNavigation = blnNavigation;
    }

    setDisplayPermissionDenied = (displayPermissionDenied: boolean) => {
      this.displayPermissionDenied = displayPermissionDenied;
    }

    setPrevPath = (prevPath: string) => {
      this.prevPath = prevPath;
    }

    setShowHelpMenu = (showHelpMenu: boolean) => {
      this.showHelpMenu = showHelpMenu;
    }

    setDropdownCustomSearchValue = (dropdownCustomSearchValue: string) => {
      this.dropdownCustomSearchValue = dropdownCustomSearchValue;
    }

    setHideComponentForPrint = (hideComponentForPrint: boolean) => {
      this.hideComponentForPrint = hideComponentForPrint;
    }

    setBlnDevAndUatEnv = (blnDevAndUatEnv: boolean) => {
      this.blnDevAndUatEnv = blnDevAndUatEnv;
    }
}