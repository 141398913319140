import React, { useEffect, useRef, useState } from 'react';
import { ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { breadCrumbObject, burgerMenu } from '../../models/common';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import MetaTags from "react-meta-tags";
import { observer } from 'mobx-react-lite';
import AuditLog from '../../../features/AuditLog/AuditLog';
import { useIntl } from 'react-intl';
import { PermissionConstants } from '../../constants/PermissionConstants';
import { checkPermission } from '../../common/function/function';
import { useStore } from '../../stores/store';
import ViewSequentialIcon from 'mdi-react/ViewSequentialIcon';
import { Constants } from '../../constants/Constants';
import ScrollButton from '../scrollButton/ScrollButton';
import PrinterIcon from "mdi-react/PrinterIcon";

interface Props {
  title: string;
  breadCrumbList: breadCrumbObject[];
  children: any;
  auditTrailId?: string;
  auditTrailIdWithoutPermission?: string;
  burgerMenuList?: burgerMenu[];
  blnScroll?: boolean;
  blnSupportStickyHeader?: boolean;
  blnShowPrint?: boolean;
}

export default observer(function DetailViewLayout(props: Props) {
  const { commonStore } = useStore();
  const { loading, setHideComponentForPrint } = commonStore;
  const [dropdown, setDropdown] = useState(false);
  const [auditTrailModal, setAuditTrailModal] = useState(false);
  const intl = useIntl();
  const burgerMenuListTemp = props.burgerMenuList || [];
  const refDiv : any = useRef(null);
  const blnShowAuditTrail = props.auditTrailIdWithoutPermission || (props.auditTrailId && checkPermission([PermissionConstants.ManageAuditLog]));

  const displayBurgetMenuContent = (burgerMenuTemp: burgerMenu[]) => {
    let aryViews: any = [];
    burgerMenuTemp.map((valueBurgerMenuTemp, index) => {
      aryViews.push(
        <div style={{paddingLeft: valueBurgerMenuTemp.blnSubView ? '16px' : undefined}} key={`dropdown_item_${index}`}>
          <DropdownItem
            onClick={()=> {valueBurgerMenuTemp.onFunction()}}
            disabled={valueBurgerMenuTemp.blnSubTitle}>
            {`${valueBurgerMenuTemp.blnSubView ? "↳ " : ""}${valueBurgerMenuTemp.label}`}
          </DropdownItem>
        </div>
      )
    })

    return aryViews;
  }

  return (
    <div className={props.blnSupportStickyHeader ? "page-content-sticky-header display-grid" : "page-content"}>
      <MetaTags>
        <title>{props.title}</title>
      </MetaTags>
      <Container fluid>
        <div className='flex-direction-row margin-bottom-16 flex-align-items-center-without-height d-print-none'>
          <BreadCrumb 
            className='padding-bottom-0 flex-1'
            breadCrumbList={props.breadCrumbList} 
            activeBreadCrumbTitle={props.title}/>
          {
            props.blnShowPrint
            &&
            <div
              onClick={(event) => { 
                setHideComponentForPrint(true);
                setTimeout(()=> {
                  window.print(); 
                }, 100)
                event.preventDefault();
              }}
              className="btn btn-success me-1"
            >
              <PrinterIcon size={Constants.menuIconSize}/>
            </div>
          }
          {
            (blnShowAuditTrail || burgerMenuListTemp.length > 0)
            &&
            <ButtonDropdown
              isOpen={dropdown}
              toggle={() => setDropdown(!dropdown)}
              className={"d-inline-block flex-right flex"}
              style={{ alignSelf: 'center' }}>
              <DropdownToggle caret color="primary" style={{height: "38px"}}>
                <ViewSequentialIcon size={Constants.menuIconSize}/> 
              </DropdownToggle>
              <DropdownMenu>
                {blnShowAuditTrail && <DropdownItem onClick={()=> {setAuditTrailModal(true)}}>{intl.formatMessage({ id: "AuditLog" })}</DropdownItem>}
                {displayBurgetMenuContent(burgerMenuListTemp)}
              </DropdownMenu>
            </ButtonDropdown>
          }
        </div>
        {
          props.blnScroll && !loading
          &&
          <ScrollButton
            blnScrollToTop={false}
            refDiv={refDiv}/>
        }
        <div style={{...props.blnScroll && { paddingRight: "60px" }}}>
          {props.children}
        </div>
        {
          props.blnScroll && !loading
          &&
          <ScrollButton
            blnScrollToTop={true}
            refDiv={refDiv}/>
        }
      </Container>
      {
        auditTrailModal
        &&
        <AuditLog 
          blnModal={auditTrailModal}
          setModal={setAuditTrailModal}
          auditLogId={props.auditTrailId || props.auditTrailIdWithoutPermission}
          skipPermisssion={props.auditTrailIdWithoutPermission ? true : false}/>
      }
      <div ref={refDiv}/>
    </div>
  )
})
