import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table, Label } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { AddOptionsValueField, appointmentStatusColor, checkPermission, firstDayOfCurrentMonthToLastDayOfCurrentMonth, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, paymentStatusColor, removeAllSpace, returnNewExpiredDate, returnPriceWithCurrency, returnSubBrandList, salesOrderStatusColor } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import moment from "moment";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { RoutesList } from "../../../app/constants/RoutesList";
import { Link } from 'react-router-dom';
import GeneralInput from "../../../app/components/form/GeneralInput";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import SelectAppointmentListModal from "./SelectAppointmentListModal";
import { DateRangeInterface } from "../../../app/models/common";
import SelectCustomerSalesOrderListModal from "./SelectCustomerSalesOrderListModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  optionYearList: any;
  optionMonthList: any;
  addMarcomDailyRecord: Function;
}

export default observer(function MarcomDailyRecordDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { branchStore, appointmentStore, staticReferenceStore, customerSalesOrderStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { appointmentList, appointmentTotalItems, getAppointmentList} = appointmentStore;
  const { customerSalesOrderList, getCustomerSalesOrder, customerSalesOrderTotalItems } = customerSalesOrderStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { successMessage, setErrorMessage } = commonStore;
  const pageSize = Constants.defaultPageSize;

  const [loadingAPI, setLoadingAPI] = useState(false);
  const [loadingAddMarcomDailyRecord, setLoadingAddMarcomDailyRecord] = useState(false);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [loadingCustomerSalesOrder, setLoadingCustomerSalesOrder] = useState(false);

  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [appointmentListModal, setAppointmentListModal] = useState(false);
  const [customerSalesOrderListModal, setCustomerSalesOrderListModal] = useState(false);
  const [resetAppointmentUI, setResetAppointmentUI] = useState(false);
  const [resetCustomerSalesOrderUI, setResetCustomerSalesOrderUI] = useState(false);
  const [dailySalesRecordOptionType, setDailySalesRecordOptionType] = useState<any>([]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const defaultMarcomDailyRecordDetail = {
    year: selectedYear,
    month: selectedMonthValue,
    branchId: getBranchUser() ? selectedBranchId : "",
    branchName: getBranchUser() ? selectedBranchName : "",
    appointmentId: "",
    appointmentCustomerName: "",
    salesOrderId: "",
    customerSalesOrderNo: "",
    type: "",
    remark: ""
  }
  const [marcomDailyRecordDetail, setMarcomDailyRecordDetail] = useState(defaultMarcomDailyRecordDetail)

  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const [errorState, setErrorState] = useState({
    year: false,
    month: false,
    branchId: false,
    appointmentId: false,
    salesOrderId: false,
    type: false,
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      year: false,
      month: false,
      branchId: false,
      appointmentId: false,
      salesOrderId: false,
      type: false,
    });
  }

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer && row.customerId
        ?
        <div>
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div>{row.customerName}</div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "AppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div className="">
            {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
    },
    // {
    //   dataField: "branchName",
    //   text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    // },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.serviceTreatmentNames || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.consultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSourceName",
      text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.mediaSourceName) || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
    },
    {
      dataField: "appointmentSourceType",
      text: intl.formatMessage({ id: "SourceType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.appointmentSourceType)}
        </div>
      )
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName)}
          </div>
        )
      }
    },
    {
      dataField: "isFollowUpOnly",
      text: intl.formatMessage({ id: "FollowUp" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isFollowUpOnly ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Select" })} />
          </div>
        </>
      ),
    },
  ];

  const CustomerSalesOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomerSalesOrder && row.id !== Constants.emptyId
            ?
            <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            row.salesOrderNo || Constants.emptyData
          }
        </div>
      ),
    },
    {
      dataField: "orderDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.orderDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.orderDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div className="text-center">
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="text-center">{row.customerName}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {
            returnPriceWithCurrency(row.balance)
          }
        </div>
      ),
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.paymentStatus}
          statusColorFunction={paymentStatusColor}/>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Select" })} />
          </div>
        </>
      ),
    }
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      year: selectedYear,
      month: selectedMonthValue,
      branchId: getBranchUser() ? selectedBranchId : "",
      branchName: getBranchUser() ? selectedBranchName : "",
      appointmentId: "",
      appointmentCustomerName: "",
      appointmentDate: "",
      salesOrderId: "",
      customerSalesOrderNo: "",
      type: "",
      remark: ""
    },
    onSubmit: async (values) => {
    },
  });

  const blnCancellationType = validation.values.type === Constants.cancellation;

  const hideModal = () => {
    validation.resetForm();
    props.setModal(false);
  }

  useEffect(() => {
    async function fetchMdrAPI() {
      setLoadingAPI(true)
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.marcomRecordType)
      ];
      let resultAPI = await Promise.all(aryAPI);

      if (resultAPI[1]) {
        let marcomRecordTypeTemp = AddOptionsValueField(resultAPI[1], "displayValue", "displayValue");
        setDailySalesRecordOptionType(marcomRecordTypeTemp);
      }
      setLoadingAPI(false);
    }

    fetchMdrAPI();
  }, [])

  const onChangeBranch = async (id: string, removeData: boolean = true) => {
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    let objTemp : any = {};
    if (indexBranch > -1) {
      objTemp = { ...objTemp, branchId: branchDropDownList[indexBranch].id, branchName: branchDropDownList[indexBranch].name};
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
    }

    if (removeData) {
      setResetAppointmentUI(true);
      setResetCustomerSalesOrderUI(true);
      objTemp = {
        ...objTemp,
        appointmentId: "",
        appointmentCustomerName: "",
        salesOrderId: "",
        customerSalesOrderNo: "",
      }
      setTimeout(()=> {
        setResetAppointmentUI(false);
        setResetCustomerSalesOrderUI(false);
      }, 100)
    }

    validation.setValues((values)=> ({
      ...values,
      ...objTemp
    }))

    clearErrorState("branchId");
  };

  const onClickFetchAppointmentListingAPI = async (branchId: string) =>{
    setLoadingAppointment(true);
    await getAppointmentList({ pageNumber: 1, pageSize: pageSize, branchId: branchId, sourceTypes: ["1","2"], dateRange: firstDayOfCurrentMonthToLastDayOfCurrentMonth(), fromSubmissionDate: "2018-01-01", toSubmissionDate: "2023-12-31" })
    setLoadingAppointment(false);
    setAppointmentListModal(true)
  }

  const onClickFetchCustomerSalesOrderListingAPI = async (branchId: string, appointmentDate: string) =>{
    setLoadingCustomerSalesOrder(true);
    await getCustomerSalesOrder({ pageNumber: 1, pageSize: pageSize, branchId: branchId, dateRange: { fromDate: appointmentDate, toDate: appointmentDate} })
    setLoadingCustomerSalesOrder(false);
    setCustomerSalesOrderListModal(true)
  }

  const onChangeYear = (value) => {
    validation.setValues((values) => ({
      ...values,
      year: Number(value)
    }))
    clearErrorState("year");
  }

  const onChangeMonth = (value) => {
    validation.setValues((values) => ({
      ...values,
      month: Number(value)
    }))
    clearErrorState("month");
  }

  const onChangeDailySalesRecordType = (type) => {
    let typeTemp = removeAllSpace(type)

    if (typeTemp === Constants.cancellation) {
      setResetCustomerSalesOrderUI(true);
      validation.setFieldValue("salesOrderId", "");
      validation.setFieldValue("customerSalesOrderNo", "");
    }
    else {
      if (resetCustomerSalesOrderUI) {
        setResetCustomerSalesOrderUI(false);
      }
    }
    validation.setFieldValue("type", typeTemp);
    clearErrorState("type");
  }

  const onChangeAppointmentModal = (row) => {
    setResetCustomerSalesOrderUI(true);
    validation.setValues((values) => ({
      ...values,
      appointmentId: row.id,
      appointmentCustomerName: `${row.customerName ? row.customerName: row.leadName} ${row.subBrandName ? `- ${row.subBrandName}` : ""} (${moment(row.appointStartDateTime).format(Constants.displayDateAndTimeFormat)})`,
      appointmentDate: moment(row.appointStartDateTime).format(Constants.displayDateFormat),
      salesOrderId: "",
      customerSalesOrderNo: ""
    }))
    setResetCustomerSalesOrderUI(false);

    clearErrorState("appointmentId");
    setAppointmentListModal(false);
  }

  const onChangeCustomerSalesOrderModal = (row) => {
    validation.setValues((values) => ({
      ...values,
      salesOrderId: row.id,
      customerSalesOrderNo: `${row.customerSalesOrderNo}`
    }))

    clearErrorState("salesOrderId");
    setCustomerSalesOrderListModal(false);
  }

  const onClickSubmitMarcomDailyRecord = async (addMarcomDailyRecordTemp: string) => {
    if (props.addMarcomDailyRecord) {
      setLoadingAddMarcomDailyRecord(true);
      await props.addMarcomDailyRecord(addMarcomDailyRecordTemp)
      setLoadingAddMarcomDailyRecord(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingAddMarcomDailyRecord && !successMessage) {
            hideModal();
          }
        }}
        size="xl"
        centered>
        {
          loadingAPI
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{ flex: 1 }}>
                  <h5 className="modal-title margin-top-0" style={{ flex: 1 }}>{intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "MarcomDailyRecordHistory" }) })}</h5>
                </div>
                {
                  !loadingAddMarcomDailyRecord && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"year"}
                      title={intl.formatMessage({ id: "Year" })}
                      className={errorState.year ? "mb-0" : "mb-3"}
                      specifyReturnFieldName={[]}
                      labelField={"label"}
                      valueField={"value"}
                      options={props.optionYearList}
                      initialLabel={selectedYear}
                      initialValue={selectedYear}
                      setFieldValue={setSelectedYear}
                      disabled={Boolean(successMessage) || loadingAddMarcomDailyRecord}
                      validationRequired={true}
                      validation={validation}
                      onChangeFunction={onChangeYear}
                      disableMenuPortalTarget={true} />
                    {errorState.year ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
                    ) : null}
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"month"}
                      title={intl.formatMessage({ id: "Month" })}
                      className={errorState.month ? "mb-0" : "mb-3"}
                      specifyReturnFieldName={[]}
                      labelField={"label"}
                      valueField={"value"}
                      options={props.optionMonthList}
                      initialLabel={selectedMonth}
                      initialValue={selectedMonthValue}
                      setFieldValue={setSelectedMonthValue}
                      disabled={Boolean(successMessage) || loadingAddMarcomDailyRecord}
                      validationRequired={true}
                      validation={validation}
                      onChangeFunction={onChangeMonth}
                      disableMenuPortalTarget={true} />
                    {errorState.month ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })}</FormFeedback>
                    ) : null}
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchId"
                    className={errorState.branchId ? "mb-0" : "mb-3"}
                    options={branchDropDownList}
                    labelField={"name"}
                    valueField={"id"}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    disabled={loadingAPI || Boolean(successMessage) || getBranchUser() || loadingAddMarcomDailyRecord}
                    onChange={onChangeBranch}
                    validationRequired={true}
                  />
                  {errorState.branchId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                  ) : null}
                </SingleColumnRowParent>
                {
                  !resetAppointmentUI
                  ?
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      name={"appointmentCustomerName"}
                      title={intl.formatMessage({ id: "Appointment" })}
                      className={errorState.appointmentId ? "mb-0" : "mb-3"}
                      classNameInput="width-100-percentage"
                      type="text"
                      disabled={
                        true
                      }
                      validation={validation}
                      validationRequired={true}
                      childrenUI={
                        <MyButton
                          type="button"
                          content={"Select"}
                          class="btn btn-primary margin-left-8"
                          onClick={() => { 
                            onClickFetchAppointmentListingAPI(validation.values.branchId) 
                          }}
                          loading={loadingAppointment}
                          disable={!validation.values.branchId || loadingAddMarcomDailyRecord || loadingAppointment || Boolean(successMessage)}
                        />
                      } />
                    {errorState.appointmentId ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Appointment" }) })}</FormFeedback>
                    ) : null}
                  </SingleColumnRowParent>
                  :
                  <Loading/>
                }
                <Label>
                  <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "Type" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.type ? "mb-0" : "mb-3"}
                  options={dailySalesRecordOptionType}
                  onChange={onChangeDailySalesRecordType}
                  initialLabel={validation.values.type}
                  initialValue={validation.values.type}
                  blnValueWithNewSpace={true}
                  disabled={Boolean(successMessage) || loadingAddMarcomDailyRecord}
                  validationRequired={true}
                />
                {errorState.type ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Type" }) })}</FormFeedback>
                ) : null}
                {
                  !blnCancellationType
                  ?
                  !resetCustomerSalesOrderUI
                  ?
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      name={"customerSalesOrderNo"}
                      title={intl.formatMessage({ id: "CustomerSalesOrder" })}
                      className={errorState.salesOrderId ? "mb-0" : "mb-3"}
                      classNameInput="width-100-percentage"
                      type="text"
                      disabled={
                        true
                      }
                      validation={validation}
                      validationRequired={true}
                      childrenUI={
                        <MyButton
                          type="button"
                          content={"Select"}
                          class="btn btn-primary margin-left-8"
                          onClick={() => { 
                            onClickFetchCustomerSalesOrderListingAPI(validation.values.branchId, validation.values.appointmentDate) 
                          }}
                          loading={loadingCustomerSalesOrder}
                          disable={!validation.values.branchId || !validation.values.appointmentId || loadingAddMarcomDailyRecord || loadingAppointment || Boolean(successMessage)}
                        />
                      } />
                    {errorState.salesOrderId ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CustomerSalesOrder" }) })}</FormFeedback>
                    ) : null}
                  </SingleColumnRowParent>
                  :
                  <Loading/>
                  :
                  null
                }
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Remark" })}
                  name="remark"
                  className={"mb-3"}
                  row={4}
                  field={marcomDailyRecordDetail}
                  setFieldValue={setMarcomDailyRecordDetail}
                  disabled={Boolean(successMessage) || loadingAddMarcomDailyRecord} />
                <div className="mb-2 mt-4 flex-right">
                  {
                    !Boolean(successMessage)
                    &&
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '25%' }}
                      content={Constants.submit}
                      onClick={() => {
                        const valuesTemp: any = _.cloneDeep(validation.values);
                        let errorStateTemp = {
                          year: false,
                          month: false,
                          branchId: false,
                          appointmentId: false,
                          salesOrderId: false,
                          type: false,
                        };
                        let blnErrorFound = false;
                        if (!valuesTemp.branchId) {
                          errorStateTemp.branchId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.appointmentId) {
                          errorStateTemp.appointmentId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.type) {
                          errorStateTemp.type = true;
                          blnErrorFound = true;
                        }
                        if (!blnCancellationType && !valuesTemp.salesOrderId) {
                          errorStateTemp.salesOrderId = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          if(marcomDailyRecordDetail.remark){
                            valuesTemp.remark = marcomDailyRecordDetail.remark;
                          }
                          onClickSubmitMarcomDailyRecord(valuesTemp);
                        }
                      }}
                      disable={loadingAddMarcomDailyRecord || Boolean(successMessage)}
                      loading={loadingAddMarcomDailyRecord}
                    />
                  }
                </div>
              </div>
            </div>
        }
        {/* AppointmentList */}
        {
          appointmentListModal
          &&
          <SelectAppointmentListModal
            blnShow={appointmentListModal}
            setModal={setAppointmentListModal}
            title={`${intl.formatMessage({ id: "AppointmentList" })} (${validation.values.branchName})`}
            options={appointmentList}
            keyField={"id"}
            fieldName={"id"}
            columns={AppointmentColumns}
            validation={validation}
            onSelectFunction={onChangeAppointmentModal}
            totalItem={appointmentTotalItems}
            requestAPI={getAppointmentList}
            branchId={selectedBranchId}
          />
        }
        {
          customerSalesOrderListModal
          &&
          <SelectCustomerSalesOrderListModal
            blnShow={customerSalesOrderListModal}
            setModal={setCustomerSalesOrderListModal}
            title={`${intl.formatMessage({ id: "CustomerSalesOrderList" })} (${validation.values.branchName})`}
            options={customerSalesOrderList}
            keyField={"id"}
            fieldName={"id"}
            columns={CustomerSalesOrderColumns}
            validation={validation}
            onSelectFunction={onChangeCustomerSalesOrderModal}
            totalItem={customerSalesOrderTotalItems}
            requestAPI={getCustomerSalesOrder}
            branchId={selectedBranchId}
            appointmentDate={validation.values.appointmentDate}
          />
        }
        {/*Customer Sales Order List*/}
      </Modal>
  )
})
