import { PaginationRequestBody } from "../models/pagination";
import { EmployeeListObject, EmployeeAddObject, EmployeeUpdateObject, EmployeeDetailObject, AccessTreeObject, EmployeeDropdownRequestBody, EmployeeExportObject, EmployeeImportObject, EmployeeImportSuccessObject, ReassignCustomerObject, UpdateEmployeeBranchTransferHistoryObject, UpdateEmployeePositionMovementHistoryObject, AddEmployeePositionMovementHistoryObject, AddEmployeeBranchTransferHistoryObject, EmployeeDropdownForConsultantSalesRequestBody } from "../models/employee";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import _ from "lodash";
import { Constants } from "../constants/Constants";
import moment from "moment";
import { downloadExcel, newSpaceBeforeCapitalLetter } from "../common/function/function";
import { toast } from "react-toastify";
export default class EmployeeStore {
  employeeList: EmployeeListObject[] = [];
  employeeDropdownList: EmployeeListObject[] = [];
  employeeDetail: EmployeeDetailObject | undefined = undefined;
  accessTree : AccessTreeObject | undefined = undefined;
  employeePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.employeeList = [];
    this.employeeDropdownList = [];
    this.employeeDetail = undefined;
    this.accessTree = undefined;
    this.employeePaginationParams= undefined;
  }

  setEmployeeDetail = (employeeDetail: EmployeeDetailObject | undefined) => {
    this.employeeDetail = employeeDetail;
  }

  setAccessTree = (accessTreeDetail: AccessTreeObject | undefined) => {
    this.accessTree = accessTreeDetail;
  }

  setEmployeeList = (employeeList: EmployeeListObject[]) => {
    this.employeeList = employeeList;
  }

  setEmployeeDropdownList = (employeeDropdownList: EmployeeListObject[]) => {
    this.employeeDropdownList = employeeDropdownList;
  }

  setEmployeePaginationParams = (employeePaginationParams: PaginationRequestBody | undefined) => {
    this.employeePaginationParams = employeePaginationParams;
  }

  getEmployee = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setEmployeePaginationParams(PaginationRequestBody);
    try{
      const resultEmployees = await agent.Employee.employeeList(PaginationRequestBody);
      runInAction(() => {
        this.employeeList = resultEmployees.data;
        store.commonStore.setTotalItem(resultEmployees.pagination.totalItems);
      });

      return Promise.resolve(resultEmployees.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.employeeList = [];
    }
  }

  getEmployeeDropdown = async (employeeDropdownRequestBody: EmployeeDropdownRequestBody = {}, blnDisableSetData: boolean = false) => {
    try{
      const resultEmployeesDropdown = await agent.Employee.employeeDropdownList(employeeDropdownRequestBody);
      if (!blnDisableSetData) {
        runInAction(() => {
          this.employeeDropdownList = resultEmployeesDropdown;
        });
      }

      return Promise.resolve(resultEmployeesDropdown)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.employeeDropdownList = [];
    }
  }

  getEmployeeDropdownOnlyHQ = async (employeeDropdownRequestBody: EmployeeDropdownRequestBody = {}) => {
    try{
      const resultEmployeesDropdown = await agent.Employee.employeeDropdownOnlyHQList(employeeDropdownRequestBody);
      return Promise.resolve(resultEmployeesDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve([]);
    }
  }

  getEmployeeDropdownForConsultantSales = async (employeeDropdownForConsultantSalesRequestBody: EmployeeDropdownForConsultantSalesRequestBody) => {
    try{
      const resultEmployeesDropdownForConsultantSales = await agent.Employee.employeeDropdownForConsultantSalesList(employeeDropdownForConsultantSalesRequestBody);
      return Promise.resolve(resultEmployeesDropdownForConsultantSales);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve([]);
    }
  }

  getEmployeeWithId = async (id: string) => {
    try{
      const resultEmployeeDetail = await agent.Employee.employeeDetail(id);
      runInAction(() => {
        let resultEmployeeDetailTemp = _.cloneDeep(resultEmployeeDetail);
        if (resultEmployeeDetailTemp.positionMovementHistories) {
          resultEmployeeDetailTemp.positionMovementHistories = resultEmployeeDetailTemp.positionMovementHistories.map((valuePositionMovementHistoriesTemp, index)=> ({
            ...valuePositionMovementHistoriesTemp,
            positionDisplayName: `${index+1}. ${valuePositionMovementHistoriesTemp.positionName}`,
            roleName: newSpaceBeforeCapitalLetter(valuePositionMovementHistoriesTemp.roleName),
            startDate: moment(valuePositionMovementHistoriesTemp.startDate).format(Constants.displayDateFormat),
            //endDate: String(moment(valuePositionMovementHistoriesTemp.endDate).year()) === Constants.year9999 ? Constants.present : moment(valuePositionMovementHistoriesTemp.endDate).format(Constants.displayInputDateFormat),
            displayEndDate: String(moment(valuePositionMovementHistoriesTemp.endDate).year()) === Constants.year9999 ? Constants.present : moment(valuePositionMovementHistoriesTemp.endDate).format(Constants.displayInputDateFormat),
            disabledDisplayPurpose: index == 0 ? false : true,
            isStartDateEditable: (index == 0 && String(moment(valuePositionMovementHistoriesTemp.endDate).year()) === Constants.year9999)  ? true : false,
            isEndDateAffect: (index == 1 && String(moment(valuePositionMovementHistoriesTemp.endDate).year()) !== Constants.year9999) ? true : false,
          }))
        }
        if (resultEmployeeDetailTemp.branchTransferHistories) {
          resultEmployeeDetailTemp.branchTransferHistories = resultEmployeeDetailTemp.branchTransferHistories.map((valueBranchTransferHistoriesTemp, index)=> ({
            ...valueBranchTransferHistoriesTemp,
            branchDisplayName: `${index+1}. ${valueBranchTransferHistoriesTemp.branchName}`,
            startDate: moment(valueBranchTransferHistoriesTemp.startDate).format(Constants.displayDateFormat),
            //endDate: String(moment(valueBranchTransferHistoriesTemp.endDate).year()) === Constants.year9999 ? Constants.present : moment(valueBranchTransferHistoriesTemp.endDate).format(Constants.displayInputDateFormat),
            displayEndDate: String(moment(valueBranchTransferHistoriesTemp.endDate).year()) === Constants.year9999 ? Constants.present : moment(valueBranchTransferHistoriesTemp.endDate).format(Constants.displayInputDateFormat),
            disabledDisplayPurpose: index == 0 ? false : true,
            isStartDateEditable: (index == 0 && String(moment(valueBranchTransferHistoriesTemp.endDate).year()) === Constants.year9999)  ? true : false,
            isEndDateAffect: (index == 1 && String(moment(valueBranchTransferHistoriesTemp.endDate).year()) !== Constants.year9999) ? true : false,
          }))
        }

        resultEmployeeDetailTemp.email = resultEmployeeDetailTemp.email || "";
        this.employeeDetail = resultEmployeeDetailTemp;
      });
      return Promise.resolve(resultEmployeeDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.employeeDetail = undefined;
    }
  }

  addEmployee = async (employeeRequestBody: EmployeeAddObject) => {
    try{
      await agent.Employee.addEmployee(employeeRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateEmployee = async (employeeRequestBody: EmployeeUpdateObject) => {
    try{
      await agent.Employee.updateEmployee(employeeRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      if (!Array.isArray(error)) {
        if (error.message === Constants.EmployeePositionMovementErrorMessage || error.message === Constants.EmployeeBranchTransferErrorMessage) {
          return Promise.resolve({status: Constants.failed, data: error.message});
        }
      }
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteEmployee  = async (id: string, name: string) => {
    try {
      await agent.Employee.deleteEmployee(id);
      store.commonStore.setSuccessMessage(`EmployeeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getAccessTree = async () => {
    try{
      const resultAccessTree = await agent.Employee.accessTree();
      runInAction(() => {
        this.accessTree = resultAccessTree;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.accessTree = undefined;
    }
  } 

  getEmployeeForCustomerInfo = async (identityNo: string, employeeNo: string) => {
    try{
      const resultEmployeeForCustomerInfo = await agent.Employee.employeeForCustomerInfo(identityNo, employeeNo);
      return Promise.resolve({status: Constants.success, data: resultEmployeeForCustomerInfo})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}})
    }
  }

  exportEmployee = async (EmployeeExportRequestBody: EmployeeExportObject) => {
    try{
      await downloadExcel(`/employee/export`, EmployeeExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportEmployeeNameUpdate = async () => {
    try{
      await downloadExcel(`/employee/name/export`, {});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importEmployee = async (employeeImportRequestBody: EmployeeImportObject) => {
    try{
      const resutlImportEmployee = await agent.Employee.importEmployee(employeeImportRequestBody);
      return Promise.resolve(resutlImportEmployee);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importEmployeeNameUpdate = async (employeeNameUpdateImportRequestBody: EmployeeImportObject) => {
    try{
      const resutlImportEmployeeNameUpdate = await agent.Employee.importEmployeeNameUpdate(employeeNameUpdateImportRequestBody);
      return Promise.resolve({status: Constants.success, data: resutlImportEmployeeNameUpdate.skippedRowNumbers});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message);
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  importEmployeeConfirmation = async (employeeImportRequestBody: EmployeeImportSuccessObject) => {
    try{
      await agent.Employee.importEmployeeConfirmation(employeeImportRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeImportSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reassignCustomer = async (reassignCustomerRequestBody: ReassignCustomerObject) => {
    try{
      await agent.Employee.reassignCustomer(reassignCustomerRequestBody);
      toast.success(`Customer has been reassigned successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addEmployeePositionMovementHistory = async (addEmployeePositionMovementHistoryRequestBody: AddEmployeePositionMovementHistoryObject) => {
    try{
      await agent.Employee.addEmployeePositionMovementHistory(addEmployeePositionMovementHistoryRequestBody);
      toast.success(`Position Movement History has been added successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateEmployeePositionMovementHistory = async (updateEmployeePositionMovementHistoryRequestBody: UpdateEmployeePositionMovementHistoryObject) => {
    try{
      await agent.Employee.updateEmployeePositionMovementHistory(updateEmployeePositionMovementHistoryRequestBody);
      toast.success(`Position Movement History has been updated successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
  
  deleteEmployeePositionMovementHistory = async (id: string, employeeId: string) => {
    try{
      await agent.Employee.deleteEmployeePositionMovementHistory(id, employeeId);
      toast.success(`Position Movement History has been deleted successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  addEmployeeBranchTransferHistory = async (addEmployeeBranchTransferHistoryRequestBody: AddEmployeeBranchTransferHistoryObject) => {
    try{
      await agent.Employee.addEmployeeBranchTransferHistory(addEmployeeBranchTransferHistoryRequestBody);
      toast.success(`Branch Transfer History has been added successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateEmployeeBranchTransferHistory = async (updateEmployeeBranchTransferHistoryRequestBody: UpdateEmployeeBranchTransferHistoryObject) => {
    try{
      await agent.Employee.updateEmployeeBranchTransferHistory(updateEmployeeBranchTransferHistoryRequestBody);
      toast.success(`Branch Transfer History has been updated successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  deleteEmployeeBranchTransferHistory = async (id: string, employeeId: string) => {
    try{
      await agent.Employee.deleteEmployeeBranchTransferHistory(id, employeeId);
      toast.success(`Branch Transfer History has been deleted successfully.`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  resetEmployeePassword = async (employeeId: string) => {
    try{
      const resultEmployeePasswordReset = await agent.Employee.resetEmployeePassword({employeeId: employeeId});
      return Promise.resolve({status: Constants.success, data: resultEmployeePasswordReset})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {password: ""}})
    }
  }

  resetEmployeePasscode = async (employeeId: string) => {
    try{
      const resultEmployeePasscodeReset = await agent.Employee.resetEmployeePasscode({employeeId: employeeId});
      return Promise.resolve({status: Constants.success, data: resultEmployeePasscodeReset})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {passcode: ""}})
    }
  }
}