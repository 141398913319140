import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, closeCounterStatusColor, contructValidationErrorMessage, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label, InputGroup, Input, Table } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const OverrideCloseCounterDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { closeCounterStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { closeCounterDetail, setCloseCounterDetail, getCloseCounterWithId, overrideCloseCounter } = closeCounterStore;
  const displayTitle = `${intl.formatMessage({ id: "Override" })} ${intl.formatMessage({ id: "CloseCounter" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CloseCounter" }), urlPath: RoutesList.closeCounter }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: closeCounterDetail || 
    {
      id: "",
      branchId: "",
      branchName: "",
      submissionDate: "",
      status: "",
      isOverridden: false,
      attachmentFileHistoryIds: [],
      attachmentFiles: [],
      closeCounterDetails: [],
      closeCounterSettlements: [],
      aryAttachments: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        await overrideCloseCounter(valuesTemp);
      } finally {
        validation.setSubmitting(false);
      }
    },
  });


  useEffect(() => {
    async function fetchCloseCounterDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getCloseCounterWithId(id, true),
      ]
      
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        if (resultAPI[0].status === Constants.draft) {
          setLoading(false);
          history.push(`/${RoutesList.notFound}`);
          return;
        }
      }
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCloseCounter, PermissionConstants.OverrideCloseCounter], true)) {
      return;
    }

    fetchCloseCounterDetailAPI();

    return (() => {
      if (id) {
        setCloseCounterDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const displayAmountInput = (row, fieldName, fieldName2, index1, index2) => {
    return (
      <InputGroup>
        <span className="input-group-text">
          {returnCurrency()}
        </span>
        <Input
          name={`settle_payment_${index1}_${index2}`}
          type={"text"}
          onBlur={(valueTemp)=> {
            let detailsTemp = _.cloneDeep(validation.values[fieldName]);
            if (!fieldName2 && index2 === -1) {
              detailsTemp[index1].overriddenAmount = Number(valueTemp.target.value);
            }
            else if (fieldName2 && index2 > -1) {
              detailsTemp[index1][fieldName2][index2].overriddenAmount = Number(valueTemp.target.value);
            }
            validation.setFieldValue(fieldName, detailsTemp);
          }}
          defaultValue={row.overriddenAmount}
        />
      </InputGroup>
    )
  }

  const displayTableContent = () => {
    let aryUITemp : any = [];
    validation.values.closeCounterDetails.map((valueCloseCounterDetailTemp, indexCloseCounterDetailTemp)=> {
      if (valueCloseCounterDetailTemp.closeCounterTerminalDetails.length > 0) {
        aryUITemp.push (
          <tr key={`payment_details_${indexCloseCounterDetailTemp}`}>
            <td style={{backgroundColor: "#eeeeee"}}>
              <p style={{fontSize: "12px", fontWeight: "bold"}} className="mb-0">
                {valueCloseCounterDetailTemp.paymentName}
              </p>
            </td>
            <td colSpan={2} style={{backgroundColor: "#eeeeee"}}/>
          </tr>
        )
              
        valueCloseCounterDetailTemp.closeCounterTerminalDetails.map((valueTerminalsTemp, indexTerminalsTemp)=> (
          aryUITemp.push (
            <tr key={`payment_details_${indexCloseCounterDetailTemp}_${indexTerminalsTemp}`}>
              <td style={{alignContent: "space-around"}}>
                {valueTerminalsTemp.terminalName}
              </td>
              <td style={{alignContent: "space-around"}}>
                {returnPriceWithCurrency(valueTerminalsTemp.amount)}
              </td>
              <td>{displayAmountInput(valueTerminalsTemp, "closeCounterDetails", "closeCounterTerminalDetails", indexCloseCounterDetailTemp, indexTerminalsTemp)}</td>
            </tr>
          )
        ))
      }
      else {
        aryUITemp.push (
          <tr key={`payment_details_${indexCloseCounterDetailTemp}_0`}>
            <td style={{alignContent: "space-around"}}>
              {valueCloseCounterDetailTemp.paymentName}
            </td>
            <td style={{alignContent: "space-around"}}>
              {returnPriceWithCurrency(valueCloseCounterDetailTemp.amount)}
            </td>
            <td>{displayAmountInput(valueCloseCounterDetailTemp, "closeCounterDetails", "", indexCloseCounterDetailTemp, -1)}</td>
          </tr>
        )
      }
    }) 

    return aryUITemp;
  }

  const displayTableSettlements = () => {
    let aryUITemp : any = [];
    validation.values.closeCounterSettlements.map((valueCloseCounterSettlementTemp, indexCloseCounterSettlementTemp)=> {
      aryUITemp.push (
        <tr key={`settlements_details_${indexCloseCounterSettlementTemp}_0`}>
          <td style={{alignContent: "space-around"}}>
            {valueCloseCounterSettlementTemp.paymentMethodName}
          </td>
          <td style={{alignContent: "space-around"}}>
            {returnPriceWithCurrency(valueCloseCounterSettlementTemp.amount)}
          </td>
          <td>{displayAmountInput(valueCloseCounterSettlementTemp, "closeCounterSettlements", "", indexCloseCounterSettlementTemp, -1)}</td>
        </tr>
      )
    })
    return aryUITemp;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "CloseCounter" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"branchId"}
                    className="mb-3 mt-4"
                    title={intl.formatMessage({ id: "Branch"})}
                    specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={[]}
                    disabled={true}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    validation={validation}
                    validationRequired={true}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SubmissionDate" })}
                    name="submissionDate"
                    type="date"
                    disabled={true}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <Label>{intl.formatMessage({ id: "Status" })}</Label>
                  <GeneralStatus
                    status={validation.values.status}
                    statusColorFunction={closeCounterStatusColor}
                    blnDisableCenter={true}/>
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Details" })}
                  className="fw-bold"
                  titleSize="16px"
                />
                <Table className="table-fixed table-bordered" style={{ width: '100%' }}>
                  <thead className="table-light">
                    <tr>
                      <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "PaymentMethod" }).toUpperCase()}</th>
                      <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "Amount" }).toUpperCase()}</th>
                      <th>{intl.formatMessage({ id: "OverriddenAmount" }).toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayTableContent()}
                  </tbody>
                </Table>

                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Settlements" })}
                  className="fw-bold"
                  titleSize="16px"
                />
                <Table className="table-fixed table-bordered" style={{ width: '100%' }}>
                  <thead className="table-light">
                    <tr>
                      <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "PaymentMethod" }).toUpperCase()}</th>
                      <th style={{width: "33.33%"}}>{intl.formatMessage({ id: "Amount" }).toUpperCase()}</th>
                      <th>{intl.formatMessage({ id: "OverriddenAmount" }).toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayTableSettlements()}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={false}
              validation={validation}
              dirtyDisabled={true}
              hidePrimaryButton={false}
              submitButtonTitle={intl.formatMessage({ id: "Override" })}
              extraLeftDirtyDisabled={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(OverrideCloseCounterDetail);
