import {
  Input} from "reactstrap";
import { Constants } from "../../constants/Constants";

interface Props {
  name: string;
  className?: any;
  placeholder?: string;
  onChangeCustomSearch?: Function;
  loadingCustomSearch?: boolean;
  setLoadingCustomSearch?: Function;
}

let localSearchValue = "";
export default function GeneralInputWithCallAPI(props: Props) {  
  let timeOut : any =  0;

  const localOnChangeCustomSearch = (value) => {
    clearTimeoutFunction();
    localSearchValue = value;

    timeOut = setTimeout(() => {
      if (props.setLoadingCustomSearch) {
        props.setLoadingCustomSearch(true);
      }
      if (props.onChangeCustomSearch) {
        props.onChangeCustomSearch(value);
      }
    }, Constants.dropdownSearchAPITimeout);
  }

  const clearTimeoutFunction = () => {
    localSearchValue = "";
    if(timeOut) {
      clearTimeout(timeOut)
    }
  }
  
  return (
    <div className={`search-box position-relative ${props.className || "" } `}>
      <Input
        name={props.name}
        className="form-control"
        placeholder={props.placeholder || "Search"}
        onChange={(event: any)=> {
          localOnChangeCustomSearch(event.target.value);
        }}
      />
      <i className="bx bx-search-alt search-icon"/>
    </div>
  )
}