import React, { Fragment } from 'react'
import { Input, InputGroup, Label, Row } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import _ from 'lodash';
import { Constants } from '../../constants/Constants';
import moment from 'moment';

interface Props {
  title?: string;
  name: string;
  className?: string;
  type: InputType;
  typeExcludeNumberType?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inputGroup?: boolean;
  inputGroupText?: string;
  timeStep?: string;
  maxLength?: number;
  numberMaxValue?: number;
  field: any;
  validationRequired?: boolean;
  validationRequiredForce?: boolean;
  setFieldValue: Function;
  onBlur?: Function;
  onChangeFunction?: Function;
  onChangeFunctionWithValueReturn?: Function;
  childrenUI?: any;
  childrenUISideBySide?: any;
  blnDisableNegativeValue?: boolean;
  blnAlphaNumberic?: boolean;
  minCurrentDate?: boolean;
  maxCurrentDate?: boolean;
  minDateValue?: string | any;
}

export default function GeneralInputForModal(props: Props) {
  function handleChange(event) {
    if (props.blnAlphaNumberic) {
      const alphanumericRegex = Constants.textAlphaNumericRegex;
      if (!alphanumericRegex.test(event.target.value) && event.target.value !== '') {
        return false;
      }
    }

    if (props.setFieldValue) {
      let fieldTemp = _.cloneDeep(props.field);
      if (props.maxLength) {
        if(event.target.value.length==(props.maxLength+1)) return false;
      }

      let finalValue = props.type == 'checkbox' ? event.target.checked : (props.type == 'number' && event.target.value) ? (props.numberMaxValue || props.numberMaxValue === 0) ? Math.max(0, Math.min(props.numberMaxValue, !props.typeExcludeNumberType ? Number(event.target.value) : (event.target.value))) : !props.typeExcludeNumberType ? Number(event.target.value) : event.target.value : event.target.value;
      fieldTemp[props.name] = finalValue;
      props.setFieldValue(fieldTemp);

      if (props.onChangeFunction) {
        props.onChangeFunction();
      }

      if (props.onChangeFunctionWithValueReturn) {
        props.onChangeFunctionWithValueReturn(finalValue)
      }
    }
  }

  return (
      <div>
          <div className={props.className || "mb-3"}>
            {
              props.title 
              && 
              <Label>
                {props.validationRequired && (props.validationRequiredForce || !props.disabled) && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
                <Label className="margin-bottom-0">{props.title}</Label>
              </Label>
            }
            <br/>
            {
              props.inputGroup
              ?
              <Row className="g-0">
                <InputGroup>
                  <span className="input-group-text">
                    {props.inputGroupText}
                  </span>
                  <Input
                    name={props.name}
                    type={props.type}
                    onChange={handleChange}
                    value={props.field[props.name]}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    step={props.timeStep}
                    maxLength={props.maxLength}
                    min={(props.numberMaxValue || props.blnDisableNegativeValue) ? 0 : undefined}
                    max={props.numberMaxValue? props.numberMaxValue : undefined}
                    onBlur={()=> {
                      if (props.onBlur) {
                        props.onBlur()
                      }
                    }}
                    onWheel={(event) => { event.currentTarget.blur(); }}
                  />
                </InputGroup>
              </Row>
              :
              <div className={`${props?.childrenUISideBySide ? "flex-direction-row" : ""}`}>
              <Input
                name={props.name}
                type={props.type}
                onChange={handleChange}
                // value={props.field[props.name]}      
                value={
                  (props.type === 'date' || props.type === 'datetime-local')
                  ? 
                  props.field[props.name] 
                  ? 
                  moment(props.field[props.name]).isValid() 
                  ? 
                  props.type === 'date'
                  ?
                  moment(props.field[props.name]).format("YYYY-MM-DD") 
                  :
                  moment(props.field[props.name]).format("YYYY-MM-DDTHH:mm") 
                  : 
                  props.field[props.name] 
                  : 
                  props.field[props.name] 
                  : 
                  props.field[props.name]
                }          
                checked={props.field[props.name]}
                disabled={props.disabled}
                placeholder={props.placeholder}
                step={props.timeStep}
                {...props.type === 'date' && {min: props.minCurrentDate ? props.minDateValue ? new Date(props.minDateValue).toISOString().split("T")[0] : new Date().toISOString().split("T")[0] : undefined}}
                {...props.type === 'datetime-local' && {min: props.minCurrentDate ? props.minDateValue ? new Date(props.minDateValue).toISOString().slice(0, -8) : new Date().toISOString().slice(0, -8) : undefined}}
                {...props.maxCurrentDate && {max: props.type === 'date' ? moment().toISOString().split("T")[0] : moment().toISOString().slice(0, -8)}}
                maxLength={props.maxLength}
                onBlur={()=> {
                  if (props.onBlur) {
                    props.onBlur()
                  }
                }}
                onWheel={(event) => { event.currentTarget.blur(); }}
              />
              {props?.childrenUI}
              {props?.childrenUISideBySide}
              </div>
            }
          </div>
      </div>
  )
}
