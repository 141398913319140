import { PaginationRequestBody } from "../models/pagination";
import { VirtualWarehouseStockMovementListObject } from "../models/virtualWarehouseStockMovement";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class VirtualWarehouseStockMovementStore {
  virtualWarehouseStockMovementList: VirtualWarehouseStockMovementListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.virtualWarehouseStockMovementList = [];
  }

  getVirtualWarehouseStockMovement = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultVirtualWarehouseStockMovement = await agent.VirtualWarehouseStockMovement.virtualWarehouseStockMovementList(PaginationRequestBody);
      runInAction(() => {
        this.virtualWarehouseStockMovementList = resultVirtualWarehouseStockMovement.data;
        store.commonStore.setTotalItem(resultVirtualWarehouseStockMovement.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.virtualWarehouseStockMovementList = [];
    }
  }
}