import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import { CategoryListObject } from "../../../app/models/category";
import { SalesCategoryListObject } from "../../../app/models/salesCategory";

const SalesByProduct = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, categoryStore, salesCategoryStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { salesByProductList, getSalesByProductList, setSalesByProductList, exportSalesByProduct } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getCategoryDropdown } = categoryStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;

  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const AllCategory = `${intl.formatMessage({ id: "AllCategory" })}`;
  const [categoryList, setCategoryList] = useState<CategoryListObject[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(AllCategory);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string >(" ");
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [selectedSalesCategory, setSelectedSalesCategory] = useState<string>(AllSalesCategory);
  const [selectedSalesCategoryId, setSelectedSalesCategoryId] = useState< string>(" ");
  
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesByProduct() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCategoryDropdown(),
        getSalesCategoryDropdown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setCategoryList(arraySpliceInAllValue(resultAPI[1], AllCategory, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[2], AllSalesCategory, false, "name", "id"))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSalesByProduct();
    return (() => {
      setSalesByProductList([]);
    })
  }, [])

  const fetchSalesByProductWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportSalesByProduct({ pageNumber: 1, pageSize: pageSize,branchId: selectedBranchId, dateRange: selectedDateRange, categoryId: selectedCategoryId, salesCategoryId: selectedSalesCategoryId })
    }else{
      await getSalesByProductList({ branchId: selectedBranchId, dateRange: selectedDateRange, categoryId: selectedCategoryId, salesCategoryId: selectedSalesCategoryId});
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const returnSalesByProductSummaryFontWeight = (index) => {
    return index == salesByProductList.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight;
  }

  //Table Content UI
  const SalesByProductColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByProductSummaryFontWeight(index)}}>
          {row.name}
        </span>
      )
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByProductSummaryFontWeight(index)}}>
          {row.categoryName}
        </span>
      )
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByProductSummaryFontWeight(index)}}>
          {row.salesCategoryName}
        </span>
      )
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByProductSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.totalAmount)}
        </span>
      )
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: "300px" },
      formatter: (cellContent, row, index) => (
        index == salesByProductList.length -1
        ?
        <div/>
        :
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDynamicSalesDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.dynamicSalesDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={`${intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: `SalesByProduct`  })  })} `}
        title={`${intl.formatMessage({ id: "SalesByProduct" })}`}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchSalesByProductWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-By-Product.aspx`}>
        <Row>
            <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
        </Row>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Category" })}
              name="category"
              options={categoryList}
              labelField="name"
              valueField="id"
              initialLabel={selectedCategory}
              initialValue={selectedCategoryId}
              setFieldLabel={setSelectedCategory}
              setFieldValue={setSelectedCategoryId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "SalesCategory" })}
              name="salesCategory"
              options={salesCategoryList}
              labelField="name"
              valueField="id"
              initialLabel={selectedSalesCategory}
              initialValue={selectedSalesCategoryId}
              setFieldLabel={setSelectedSalesCategory}
              setFieldValue={setSelectedSalesCategoryId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchSalesByProductWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={salesByProductList.length}
              tableColumns={SalesByProductColumns}
              data={salesByProductList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              keyField={"name"}
              requestAPI={getSalesByProductList}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesByProduct);
