import { PaginationRequestBody } from "../models/pagination";
import { CustomerVirtualWarehouseDetailObject, CustomerVirtualWarehouseListObject, CustomerVirtualWarehouseMovementListObject } from "../models/customerVirtualWarehouse";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class customerVirtualWarehouseStore {
  customerVirtualWarehouseList: CustomerVirtualWarehouseListObject[] = [];
  customerVirtualWarehouseDetail: CustomerVirtualWarehouseDetailObject | undefined = undefined;
  customerVirtualWarehouseMovementsList: CustomerVirtualWarehouseMovementListObject[] = [];
  customerVirtualWarehouseMovementsTotalItem: number = 0;
  customerVirtualWarehousePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerVirtualWarehouseList = [];
    this.customerVirtualWarehouseMovementsList = [];
    this.customerVirtualWarehouseDetail = undefined;
    this.customerVirtualWarehouseMovementsTotalItem = 0;
    this.customerVirtualWarehousePaginationParams = undefined
  }

  setCustomerVirtualWarehouseDetail = (customerVirtualWarehouseDetail: CustomerVirtualWarehouseDetailObject | undefined) => {
    this.customerVirtualWarehouseDetail = customerVirtualWarehouseDetail;
  }

  setCustomerVirtualWarehousePaginationParams = (customerVirtualWarehousePaginationParams: PaginationRequestBody | undefined) => {
    this.customerVirtualWarehousePaginationParams = customerVirtualWarehousePaginationParams;
  }
  
  getCustomerVirtualWarehouse = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCustomerVirtualWarehousePaginationParams(PaginationRequestBody);
    try{
      const resultCustomerVirtualWarehouse = await agent.CustomerVirtualWarehouse.customerVirtualWarehouseList(PaginationRequestBody);
      runInAction(() => {
        this.customerVirtualWarehouseList = resultCustomerVirtualWarehouse.data;
        store.commonStore.setTotalItem(resultCustomerVirtualWarehouse.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerVirtualWarehouseList = [];
    }
  }
  
  getCustomerVirtualWarehouseWithId = async (id: string) => {
    try{
      const resultCustomerVirtualWarehouse = await agent.CustomerVirtualWarehouse.customerVirtualWarehouseDetail(id);
      runInAction(() => {
        this.customerVirtualWarehouseDetail = resultCustomerVirtualWarehouse;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerVirtualWarehouseDetail = undefined;
    }
  }

  getCustomerVirtualWarehouseMovement = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerVirtualWarehouseMovement = await agent.CustomerVirtualWarehouse.customerVirtualWarehouseMovementList(PaginationRequestBody);
      runInAction(() => {
        this.customerVirtualWarehouseMovementsList = resultCustomerVirtualWarehouseMovement.data;
        this.customerVirtualWarehouseMovementsTotalItem = resultCustomerVirtualWarehouseMovement.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerVirtualWarehouseMovementsList = [];
    }
  }
}