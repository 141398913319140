import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getMonths, getYears, returnSubBrandList } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import moment from "moment";
import { SubBrandObject } from "../../../app/models/brand";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";

const RoomOccupancy = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppointmentStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { roomOccupancyList, getRoomOccupancyList, setRoomOccupancyList, exportRoomOccupancy } = reportAppointmentStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() :  AllBranch);
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportAppointmentReport = checkPermission([PermissionConstants.ExportAppointmentReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    setYearList(getYears());
    setMonthList(getMonths());
    
    async function fetchRoomOccupancy() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      setLoading(false);
    }
    if (!checkPermission([PermissionConstants.ManageAppointmentReport], true)) {
      return;
    }

    fetchRoomOccupancy();

    return (() => {
      setRoomOccupancyList([]);
    })
  }, [])

  const fetchRoomOccupancyWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportRoomOccupancy({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId })
    }
    else { 
      await getRoomOccupancyList({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const AppointmentSummaryColumns = [
    {
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "occupancyPercentage",
      text: intl.formatMessage({ id: "OccupancyPercentage" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.occupancyPercentage.toFixed(2)}
        </div>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.roomUtilization}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "RoomOccupancySummary" }) })}
        title={intl.formatMessage({ id: "RoomOccupancySummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppointmentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchRoomOccupancyWithLoading(true)
            }}
          />
        }>
        <Row>
          {
            subBrandListFinal.length > 0
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchRoomOccupancyWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentSummaryColumns}
              data={roomOccupancyList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "RoomOccupancySummary" }) })}
              objSorted={defaultSorted}
              keyField={"branchId"}
              requestAPI={getRoomOccupancyList}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              subBrandId={selectedSubBrandId}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(RoomOccupancy);
