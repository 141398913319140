import { Link } from "react-router-dom"
import { RoutesList } from "../../constants/RoutesList";
import { useIntl } from "react-intl";

interface breadCrumbObject {
  title: string,
  urlPath: string,
}

interface Props {
  breadCrumbList?: breadCrumbObject[] | any;
  activeBreadCrumbTitle: string;
  className?: string;
}

export default function BreadCrumb(props: Props) {
  //Use INTL
  const intl = useIntl();

  const displayMultipleBreadCrumb = () => {
    return props.breadCrumbList.map((value: breadCrumbObject, index)=> {
      let urlPath = value.urlPath ? `/${value.urlPath}` : '#';
      return (
        <li key={`breadCrumb_${index}`}className="breadcrumb-item"><Link to={urlPath}>{value.title}</Link></li>
      )
    })
  };


  return (
    <div className={`page-title-box d-block align-items-center justify-content-between ${props.className}`}>
        <div className="page-title-right">
            <nav className="">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={`/${RoutesList.dashboard}`}>{intl.formatMessage({ id: "Dashboard" })}</Link></li>
                    {props.breadCrumbList && displayMultipleBreadCrumb()}
                    <li className="active breadcrumb-item" aria-current="page">{props.activeBreadCrumbTitle}</li>
                </ol>
            </nav>
        </div>
    </div>
  )
}
