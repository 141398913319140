import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import StockAdjustmentRequestModal from "./Modal/StockAdjustmentRequestModal";

const StockTakeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockTakeStore, branchStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockTake, updateStockTake, stockTakeDetail, setStockTakeDetail, getStockTakeWithId } = stockTakeStore;
  const { branchForHeaderDropDown, getBranchProductList } = branchStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockTake" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockTake" }), urlPath: RoutesList.stockTake }];
  const [loadingStockTake, setStockTakeLoading] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [stockTakeIdTemp, setStockTakeIdTemp] = useState<any>(undefined);
  const [showRequestStockAdjustmentModal, setShowRequestStockAdjustmentModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  const [aryDynamicInputAndCheckBoxTabFinal, setAryDynamicInputAndCheckBoxTabFinal] = useState<ObjectForTableWithDynamicInputAndCheckBox[]>([]);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "productName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "CurrentQuantity" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "quantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "actualQuantity",
        inputType: "number",
      },
      {
        label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "remark",
        inputType: "text",
      },
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockTakeDetail || {
      entityType: "",
      branchName: getBranchName(),
      branchId: getBranchId(),
      note: "",
      stockTakeDetails: [],
      status: "",
      workflowAction: "Save",
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let result: any = [];
      try {
        if (addAction) {
          result = await addStockTake(valuesTemp);
        } else {
          result = await updateStockTake(valuesTemp);
        }
        if (result) {
          if (result.hasDiscrepancy) {
            setStockTakeIdTemp(result.stockTakeId);
            setShowRequestStockAdjustmentModal(true);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnHQStaff = !getBranchUser();
  const blnPermissionUpdateStockTake = checkPermission([PermissionConstants.UpdateStockTake]);

  useEffect(() => {
    async function fetchStockTakeDetailAPI() {
      setLoading(true);
      let aryAPI: any = [];
      if(addAction){
        getProductWithLoading(getBranchId());
      }
      if (id && !addAction) {
        aryAPI.push(getStockTakeWithId(id));
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 0) {
        if (resultAPI[0]) {
          if (resultAPI[0].status !== Constants.completed && viewAction && blnPermissionUpdateStockTake) {
            burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.stockTake}/edit/${id}`) } })
          }
        }
      }
      setLoading(false);
    }

    if (addAction) {
      setStockTakeDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockTake], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockTake], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockTake], true)) {
        return;
      }
    }

    fetchStockTakeDetailAPI();
    displayTableContent();

    return (() => {
      if (id && !addAction) {
        setStockTakeDetail(undefined);
      }
    })
  }, []);

  const getProductWithLoading = async (id: string) => {
    setStockTakeLoading(true);
    let stockTakeBranchProductListTemp: any = await getBranchProductList(id);
    if (stockTakeBranchProductListTemp) {
      let stockTakeProductListContent = stockTakeBranchProductListTemp.map((valueProductList) => {
        return ({
          productId: valueProductList.productId,
          productName: valueProductList.productName,
          quantity: valueProductList.quantity,
          actualQuantity: 0,
          remark: ""
        })
      })
      validation.setFieldValue("stockTakeDetails", stockTakeProductListContent)
    }
    setStockTakeLoading(false);
  };

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const displayTableContent = () => {
    if (!blnHQStaff) {
      let aryDynamicInputAndCheckBoxTabTemp: ObjectForTableWithDynamicInputAndCheckBox[] = []
      aryDynamicInputAndCheckBoxTab.map((value, index) => {
        if (value.inputName !== 'quantity') {
          aryDynamicInputAndCheckBoxTabTemp.push({
            ...value,
            xl: "4"
          })
        }
      })
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTabTemp)
    }
    else {
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTab)
    }
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              <Col xl={4}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockTake" }) })}
                  h4Title
                />
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"branchId"}
                    title={intl.formatMessage({ id: "BranchName" })}
                    className="mb-3 mt-4"
                    specifyReturnFieldName={[
                      {
                        field: "branchId",
                        value: "id",
                      },
                      {
                        field: "branchName",
                        value: "name",
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={branchForHeaderDropDown}
                    disabled={disabledFieldInput || viewAction || !blnHQStaff}
                    onChangeFunction={getProductWithLoading}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Note" })}
                    name="note"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
              <Col xl={8}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                  h4Title
                />
                <TableWithEditableInputFields
                  name="stockTakeDetails"
                  title=""
                  buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                  blnEditable={!viewAction}
                  aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabFinal}
                  data={validation.values.stockTakeDetails}
                  validation={validation}
                  disabled={disabledFieldInput}
                  hideAddButton={true}
                  hideDeleteButton={true} />
              </Col>
            </Row>
            <div className="mt-3" />
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={true}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", 'Save')
              }}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", 'Submit')
              }}
            />
          </Form>
        )}
        {
          showRequestStockAdjustmentModal &&
          <StockAdjustmentRequestModal
            blnShow={showRequestStockAdjustmentModal}
            setModal={setShowRequestStockAdjustmentModal}
            onRequestClick={() => {
              if (stockTakeIdTemp) {
                history.push({
                  pathname: `/${RoutesList.stockAdjustment}/add`,
                  state: { detail: { stockTakeId: stockTakeIdTemp } }
                });
              }
            }}
            onCancelClick={()=>{
              if(addAction){
                setSuccessMessage("Stock Take has added successfully.")
              }
              else{
                setSuccessMessage("Stock Take has updated successfully.")
              }
            }}
          />
        }
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockTakeDetail);