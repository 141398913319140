import React, { Fragment } from 'react'
import { CardText, Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Borderline from '../../../../app/components/form/BorderLine';
import GeneralTextArea from '../../../../app/components/form/GeneralTextArea';
import { RedeemPurchaseDetailObject } from '../../../../app/models/customerOrder';
import { useIntl } from "react-intl";
import { Constants } from '../../../../app/constants/Constants';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { checkPermission, displayOptionalProducts } from '../../../../app/common/function/function';
import GeneralInput from '../../../../app/components/form/GeneralInput';
import { PermissionConstants } from '../../../../app/constants/PermissionConstants';
import moment from 'moment';
import { AppolousCustomerPackageRedeemDetailObject } from '../../../../app/models/appolousCustomerPackage';
interface Props {
  validation: any;
  disabledCustomerOrder: boolean;
  selectAppolousCustomerPackagePurchase?: Function;
  deleteAppolousCustomerPackagePurchase?: Function;
}

export default function AppolousCustomerPackagePurchaseCart (props: Props) {
  const intl = useIntl();

  const displayCustomerOrder = () => {
    if (props.validation.values.redeemPurchaseDetails.length < 1) {
      return (
        <div style={{height: '20px'}}>
            <span className="small-text">{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({id: "Item"}).toLowerCase() })}</span> 
            {props.validation.touched.redeemPurchaseDetails && props.validation.errors.redeemPurchaseDetails ? (
              <Label className="error margin-left-8" style={{fontSize: "12px"}}>{`* ${intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })}`}</Label>
            ) : null}
        </div>)
    }

    return (
      <div>
        <Row className='padding-horizontal-8px'>
          {/* <div className="avatar-sm"> */}
          <Col xs="2" md="2" xl="2" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Count" })}</span>
          </Col>
          <Col xs="8" md="8" xl="8" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Name" })}</span>
          </Col>
          {
            !props.disabledCustomerOrder
            &&
            <Col xs="2" md="2" xl="2" className="flex-justfity-content-center flex-align-items-center-without-height text-center">
              <span className="tab-bar-title text-center">{intl.formatMessage({ id: "Action" })}</span>
            </Col>
          }
          {/* </div> */}
        </Row>
        <hr/>
        {
          props.validation.values.redeemPurchaseDetails.map((valueRedeemPurchaseDetailTemp: AppolousCustomerPackageRedeemDetailObject, index) => (           
            <Row className="mb-4 padding-horizontal-8px" key={valueRedeemPurchaseDetailTemp.appolousCustomerPackageId+valueRedeemPurchaseDetailTemp.appolousCustomerPackageServiceId}>
              <Col xs="2" md="2" xl="2"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectAppolousCustomerPackagePurchase && !props.disabledCustomerOrder) props.selectAppolousCustomerPackagePurchase(index) }}>
                <span className="avatar-sm circle-with-text rounded-circle" style={{backgroundColor: `${Constants.lightpurple}`}}>{valueRedeemPurchaseDetailTemp.count}</span>
              </Col>
              <Col xs="8" md="8" xl="8"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectAppolousCustomerPackagePurchase && !props.disabledCustomerOrder) props.selectAppolousCustomerPackagePurchase(index) }}>
                <div>
                  <span>{`${valueRedeemPurchaseDetailTemp.serviceName}`}</span>
                  <br/>
                  {valueRedeemPurchaseDetailTemp.mandatoryProductsUsed ? valueRedeemPurchaseDetailTemp.mandatoryProductsUsed.length > 0 ? displayOptionalProducts(valueRedeemPurchaseDetailTemp.mandatoryProductsUsed, intl) : <div/> : <div/>}
                  {valueRedeemPurchaseDetailTemp.availableOptionalProductsUsed ? valueRedeemPurchaseDetailTemp.availableOptionalProductsUsed.length > 0 ? displayOptionalProducts(valueRedeemPurchaseDetailTemp.availableOptionalProductsUsed, intl) : <div/> : <div/>}
                </div>
              </Col>
              {
                !props.disabledCustomerOrder
                &&
                <Col xs="2" md="2" xl="2" className="text-danger flex-justfity-content-center" onClick={()=> { if (props.deleteAppolousCustomerPackagePurchase && !props.disabledCustomerOrder) props.deleteAppolousCustomerPackagePurchase(index) }}>
                  <DeleteIcon size={18} id="deletetooltip" className="margin-top-8"/> 
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {intl.formatMessage({ id: "Delete" })}
                  </UncontrolledTooltip>
                </Col>
              }
            </Row>
          ))
        }
      </div>
    )
  }

  return (
    <div>
      {/*Item Selected */}
      {
        displayCustomerOrder()
      }
      <Borderline/>      
    </div>
  )
}
