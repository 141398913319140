import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, branchCommissionStatusColor, returnCurrency, returnPriceWithCurrency, isBranchManager } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form, CardTitle } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import Borderline from "../../app/components/form/BorderLine";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import MiniCard from "../../app/components/form/MiniCard";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import EnterPasscodeModal from "../../app/components/modal/EnterPasscodeModal";
import { CommissionDetailObject } from "../../app/models/reportSales";

const BranchCommissionDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { branchCommissionStore, settingsStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, windowSize } = commonStore;
  const { branchCommissionDetail, setBranchCommissionDetail, getBranchCommissionWithId, updateBranchCommission, branchCommissionReviewFirstApproval, branchCommissionReviewSecondApproval, branchCommissionReviewFinalApproval } = branchCommissionStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "BranchCommission" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "BranchCommission" }), urlPath: RoutesList.branchCommission }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const blnPermissionManageSalesReport = checkPermission([PermissionConstants.ManageSalesReport]);
  const blnIsShowAppolous = generalSettings?.isShowAppolous;
  const [finalPackageCommissionDifference, setFinalPackageCommissionDifference] = useState({amount: "", error: false});
  const [finalProductCommissionDifference, setFinalProductCommissionDifference] = useState({amount: "", error: false});
  const [finalAngPaoBonusDifference, setFinalAngPaoBonusDifference] = useState({amount: "", error: false});
  const [modalEnterPasscode, setModalEnterPasscode] = useState<boolean>(false);

  const branchCommissionFullDetail = branchCommissionDetail || {
    id: "",
    isDelete: false,
    branchId: "",
    branchName: "",
    month: 0,
    year: 0,
    totalBranchSales: 0,
    totalActualBranchSales: 0,
    totalBranchPackageSales: 0,
    totalActualBranchPackageSales: 0,
    totalBranchProductSales: 0,
    totalActualBranchProductSales: 0,
    totalPartialPaymentBranchProductSales: 0,
    totalCommission: 0,
    totalPackageCommission: 0,
    totalProductCommission: 0,
    totalPackageSalesAdjustment: 0,
    totalProductSalesAdjustment: 0,
    totalProductCommissionAdjustment: 0,
    totalSubBranchSalesEarned: 0,
    totalSubBranchSalesShared: 0,
    totalAngPaoBonus: 0,
    totalAppolousSalesAdjustment: 0,
    totalAppolousPackageSalesAdjustment: 0,
    totalAppolousProductSalesAdjustment: 0,
    totalAppolousProductCommissionAdjustment: 0,
    status: "",
    brandId: "",
    brandName: "",
    generateBy: "",
    generatedOn: "",
    amendBy: "",
    amendedOn: "",
    actionBy: "",
    actionOn: "",
    preparedBy: "",
    preparedOn: "",
    checkedBy: "",
    checkedOn: "",
    reviewedBy: "",
    reviewedOn: "",
    routeUrl: "",
    commissionDetails: []
  }

  const [aryDynamicInputAndCheckBoxTab, setAryDynamicInputAndCheckBoxTab] = useState<ObjectForTableWithDynamicInputAndCheckBox[]>([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: branchCommissionDetail || {
      id: "",
      workflowAction: "",
      reason: "",
      commissionDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let commissionDetailsTemp : CommissionDetailObject[] = [];
      valuesTemp.commissionDetails.map((valueCommissionDetailsTemp)=> {
        if (valueCommissionDetailsTemp.id) {
          commissionDetailsTemp.push(valueCommissionDetailsTemp);
        }
      })
      valuesTemp.commissionDetails = commissionDetailsTemp;

      let blnActionRejectedByFirstApproverTemp = valuesTemp.workflowAction === Constants.rejectedByFirstApprover;
      let blnActionRejectedBySecondApproverTemp = valuesTemp.workflowAction === Constants.rejectedBySecondApprover;
      let blnActionRejectedByFinalApproverTemp = valuesTemp.workflowAction === Constants.rejectedByFinalApprover;
      let blnActionApprovedByFirstApproverTemp = valuesTemp.workflowAction === Constants.approvedByFirstApprover;
      let blnActionApprovedBySecondApproverTemp = valuesTemp.workflowAction === Constants.approvedBySecondApprover;
      let blnActionApprovedByFinalApproverTemp = valuesTemp.workflowAction === Constants.approvedByFinalApprover;

      if (blnActionRejectedByFirstApproverTemp || blnActionRejectedBySecondApproverTemp || blnActionRejectedByFinalApproverTemp) {
        if(!valuesTemp.reason) {
          setErrorMessage(`${intl.formatMessage({ id: "EnterReasonWhenReject" })}`)
          return;
        }
      }
      try {
        if (!blnActionApprovedByFirstApproverTemp && !blnActionApprovedBySecondApproverTemp && !blnActionApprovedByFinalApproverTemp &&!blnActionRejectedByFirstApproverTemp && !blnActionRejectedBySecondApproverTemp && !blnActionRejectedByFinalApproverTemp) {
          await updateBranchCommission(valuesTemp);
        } 
        else if (blnActionApprovedByFirstApproverTemp || blnActionRejectedByFirstApproverTemp){
          await branchCommissionReviewFirstApproval(valuesTemp);
        }
        else if (blnActionApprovedBySecondApproverTemp || blnActionRejectedBySecondApproverTemp){
          await branchCommissionReviewSecondApproval(valuesTemp);
        }
        else if (blnActionApprovedByFinalApproverTemp || blnActionRejectedByFinalApproverTemp){
          await branchCommissionReviewFinalApproval(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnStatusGenerated = branchCommissionFullDetail.status === Constants.generated;
  const blnStatusPendingApproval = branchCommissionFullDetail.status === Constants.pendingApproval;
  const blnStatusPendingSecondApproval = branchCommissionFullDetail.status === Constants.pendingSecondApproval;
  const blnStatusPendingFinalApproval = branchCommissionFullDetail.status === Constants.pendingFinalApproval;
  const blnStatusRejected = branchCommissionFullDetail.status === Constants.rejectedByFirstApprover || branchCommissionFullDetail.status === Constants.rejectedBySecondApprover || branchCommissionFullDetail.status === Constants.rejectedByFinalApprover;
  const blnStatusFinal = branchCommissionFullDetail.status === Constants.final;

  const blnPermissionUpdateBranchCommission = checkPermission([PermissionConstants.UpdateBranchCommission]);
  const blnPermissionReviewBranchCommissionFirstApproval = checkPermission([PermissionConstants.ReviewBranchCommissionFirstApproval]);
  const blnPermissionReviewBranchCommissionSecondApproval = checkPermission([PermissionConstants.ReviewBranchCommissionSecondApproval]);
  const blnPermissionReviewBranchCommissionFinalApproval = checkPermission([PermissionConstants.ReviewBranchCommissionFinalApproval]);

  const blnFirstApprover = blnPermissionReviewBranchCommissionFirstApproval && blnStatusPendingApproval && !Boolean(successMessage);
  const blnSecondApprover = blnPermissionReviewBranchCommissionSecondApproval && blnStatusPendingSecondApproval && !Boolean(successMessage);
  const blnFinalApprover = blnPermissionReviewBranchCommissionFinalApproval && blnStatusPendingFinalApproval && !Boolean(successMessage);
  
  const miniCardSize = windowSize.innerWidth > 900 ? 4 : 12;
  const totalBranchSalesAdjustment = branchCommissionFullDetail.totalPackageSalesAdjustment + branchCommissionFullDetail.totalProductSalesAdjustment;

  async function fetchBranchCommissionDetailAPI(passcode: number | string) {
    setLoading(true);
    let aryAPI: any = [
      getGeneralSettings()
    ];
    if (id && !addAction) {
      aryAPI.push(getBranchCommissionWithId(id, passcode));
    }
    let resultAPI = await Promise.all(aryAPI);
    
    if (resultAPI.length > 1) {
      if (!resultAPI[1]) {
        setModalEnterPasscode(true);
        return;
      }
    } 

    if (resultAPI[1]) {
      let blnStatusGeneratedTemp = resultAPI[1].status === Constants.generated;
      let blnStatusPendingApprovalTemp = resultAPI[1].status === Constants.pendingApproval || resultAPI[1].status === Constants.pendingSecondApproval || resultAPI[1].status === Constants.pendingFinalApproval;
      let blnStatusRejectedTemp = resultAPI[1].status === Constants.rejectedByFirstApprover || resultAPI[1].status === Constants.rejectedBySecondApprover || resultAPI[1].status === Constants.rejectedByFinalApprover;
      let blnStatusFinalTemp = resultAPI[1].status === Constants.final;
      let xlWidthTemp = blnStatusGeneratedTemp ? "1.9" : "1.2";
      let aryDynamicInputAndCheckBoxTabTemp : ObjectForTableWithDynamicInputAndCheckBox[] = [
        {
          label: intl.formatMessage({ id: "No." }).toUpperCase(),
          type: "label",
          xl: "0.6",
          inputName: "",
          inputType: "text",
          hideIndexNumberFieldName: "id",
          displayIndexNumber: true
        },
        {
          label: intl.formatMessage({ id: "Employee" }).toUpperCase(),
          type: "label",
          xl: xlWidthTemp,
          inputName: "employeeName",
          inputType: "text",
        },
        {
          label: intl.formatMessage({ id: "Position" }).toUpperCase(),
          type: "label",
          xl: xlWidthTemp,
          inputName: "positionName",
          inputType: "text",
          labelBoldField: "boldLabel"
        },
      ];

      // if (blnStatusPendingApprovalTemp || blnStatusRejectedTemp) {
      //   aryDynamicInputAndCheckBoxTabTemp.push({
      //     label: intl.formatMessage({ id: "SupervisorOverridingCommission" }).toUpperCase(),
      //     type: "input",
      //     xl: xlWidthTemp,
      //     inputName: "totalExtraCommission",
      //     inputType: "number",
      //     inputGroup: true,
      //     inputGroupText: returnCurrency(),
      //     disabled: true
      //   })
      // }
      
      aryDynamicInputAndCheckBoxTabTemp.push({
        label: intl.formatMessage({ id: blnStatusGeneratedTemp ? "SupervisorOverridingCommission" : "FinalSupervisorOverridingCommission" }).toUpperCase(),
        type: "input",
        xl: xlWidthTemp,
        inputName: "finalExtraCommission",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      })

      if (blnStatusPendingApprovalTemp || blnStatusRejectedTemp || blnStatusFinalTemp) {
        aryDynamicInputAndCheckBoxTabTemp.push({
          label: intl.formatMessage({ id: "TotalPackageComm" }).toUpperCase(),
          type: "input",
          xl: xlWidthTemp,
          inputName: "totalPackageCommission",
          inputType: "number",
          inputGroup: true,
          inputGroupText: returnCurrency(),
          disabled: true
        })
      }
  
      aryDynamicInputAndCheckBoxTabTemp.push({
        label: intl.formatMessage({ id: blnStatusGeneratedTemp ? "TotalPackageComm" : "FinalPackageComm" }).toUpperCase(),
        type: "input",
        xl: xlWidthTemp,
        inputName: "finalPackageCommission",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        customInputStyle: "packageStyle",
        reverseDisabledField: "id"
      })
  
      if (blnStatusPendingApprovalTemp || blnStatusRejectedTemp || blnStatusFinalTemp) {
        aryDynamicInputAndCheckBoxTabTemp.push({
          label: intl.formatMessage({ id: "TotalProductComm" }).toUpperCase(),
          type: "input",
          xl: xlWidthTemp,
          inputName: "totalProductCommission",
          inputType: "number",
          inputGroup: true,
          inputGroupText: returnCurrency(),
          disabled: true
        })
      }
      
      aryDynamicInputAndCheckBoxTabTemp.push({
        label: intl.formatMessage({ id: blnStatusGeneratedTemp ? "TotalProductComm" : "FinalProductComm" }).toUpperCase(),
        type: "input",
        xl: xlWidthTemp,
        inputName: "finalProductCommission",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        customInputStyle: "productStyle",
        reverseDisabledField: "id"
      })

      if (blnStatusPendingApprovalTemp || blnStatusRejectedTemp || blnStatusFinalTemp) {
        aryDynamicInputAndCheckBoxTabTemp.push({
          label: intl.formatMessage({ id: "TotalAngPaoBonus" }).toUpperCase(),
          type: "input",
          xl: xlWidthTemp,
          inputName: "totalAngPaoBonus",
          inputType: "number",
          inputGroup: true,
          inputGroupText: returnCurrency(),
          disabled: true
        })
      }
      
      aryDynamicInputAndCheckBoxTabTemp.push({
        label: intl.formatMessage({ id: blnStatusGeneratedTemp ? "TotalAngPaoBonus" : "FinalAngPaoBonus" }).toUpperCase(),
        type: "input",
        xl: xlWidthTemp,
        inputName: "finalAngPaoBonus",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        customInputStyle: "angPaoStyle",
        reverseDisabledField: "id"
      })
      setAryDynamicInputAndCheckBoxTab(aryDynamicInputAndCheckBoxTabTemp);

      if(blnPermissionManageSalesReport && id && !addAction){
        burgerMenuListContent.push({ label: `${intl.formatMessage({id: "NavigateToConsultantSalesCommission"})}`, onFunction: () => { history.push(`/${RoutesList.report}/${RoutesList.consultantSalesCommission}${resultAPI[1]?.routeUrl || ""}`)} })
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageBranchCommission], true)) {
      return;
    }

    if (!isBranchManager()) {
      fetchBranchCommissionDetailAPI("");
    }
    else {
      setModalEnterPasscode(true);
    }
    
    return (() => {
      if (id && !addAction) {
        setBranchCommissionDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    if (branchCommissionDetail) {
      let indexEditAction = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "EditAction" })}`})
      if(viewAction && blnPermissionUpdateBranchCommission && !blnStatusFinal && indexEditAction < 0){
        burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.branchCommission}/edit/${id}`) } });
      }
    }
  }, [branchCommissionDetail])
  
  useEffect(()=> {
    if (validation.values.commissionDetails) {
      let commissionDetailsTemp : any = _.cloneDeep(validation.values.commissionDetails);
      let finalPackageCommissionTemp : any = 0;
      let finalProductCommissionTemp : any = 0;
      let finalAngPaoBonusTemp : any = 0;
      commissionDetailsTemp.map((valueCommissionDetailsTemp)=> {
        if (valueCommissionDetailsTemp.id) {
          finalPackageCommissionTemp += Number(valueCommissionDetailsTemp.finalPackageCommission);
          finalProductCommissionTemp += Number(valueCommissionDetailsTemp.finalProductCommission);
          finalAngPaoBonusTemp += Number(valueCommissionDetailsTemp.finalAngPaoBonus);
        }
      })

      finalPackageCommissionTemp = finalPackageCommissionTemp.toFixed(2);
      finalProductCommissionTemp = finalProductCommissionTemp.toFixed(2);
      finalAngPaoBonusTemp = finalAngPaoBonusTemp.toFixed(2);

      setFinalPackageCommissionDifference(finalPackageCommissionTemp > branchCommissionFullDetail.totalPackageCommission || finalPackageCommissionTemp < branchCommissionFullDetail.totalPackageCommission ? {amount: `${returnPriceWithCurrency(finalPackageCommissionTemp)} (${returnPriceWithCurrency(finalPackageCommissionTemp-branchCommissionFullDetail.totalPackageCommission)})`, error: true} : {amount: `${returnPriceWithCurrency(finalPackageCommissionTemp)} (✔)`, error: false})
      setFinalProductCommissionDifference(finalProductCommissionTemp > branchCommissionFullDetail.totalProductCommission || finalProductCommissionTemp < branchCommissionFullDetail.totalProductCommission ? {amount: `${returnPriceWithCurrency(finalProductCommissionTemp)} (${returnPriceWithCurrency(finalProductCommissionTemp-branchCommissionFullDetail.totalProductCommission)})`, error: true} : {amount: `${returnPriceWithCurrency(finalProductCommissionTemp)} (✔)`, error: false})
      setFinalAngPaoBonusDifference(finalAngPaoBonusTemp > branchCommissionFullDetail.totalAngPaoBonus || finalAngPaoBonusTemp < branchCommissionFullDetail.totalAngPaoBonus ? {amount: `${returnPriceWithCurrency(finalAngPaoBonusTemp)} (${returnPriceWithCurrency(finalAngPaoBonusTemp-branchCommissionFullDetail.totalAngPaoBonus)})`, error: true} : {amount: `${returnPriceWithCurrency(finalAngPaoBonusTemp)} (✔)`, error: false})
    } 
  }, [validation.values.commissionDetails])

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }


  const displayPaymentAmountData = (fullPaymentTitle: string, valueFullPaymentAmount: number, partialPaymentTitle: string, valuePartialPaymentAmount: number, appolousPaymentTitle: string, valueAppolousPaymentAmount: number ) => {
    return (
      <>
        <Row className="list-inline" style={{marginTop:"10px",marginBottom: "-10px"}}> 
          <Col className="list-inline-item me-3"> 
          <h6 className="font-size-13 text-muted">
            {fullPaymentTitle}: <br/>
            {returnPriceWithCurrency(valueFullPaymentAmount)} 
          </h6> </Col>&nbsp; 
          <Col className="list-inline-item me-3"> 
            <h6 className="font-size-13 text-muted"> 
              {partialPaymentTitle}: <br/>
              {" "} {returnPriceWithCurrency(valuePartialPaymentAmount)} 
            </h6> 
          </Col> 
        </Row>
        {
          blnIsShowAppolous
          &&
          <Row className="mt-3">
            <Col className="list-inline-item me-3"> 
              <h6 className="font-size-13 text-muted"> 
                {appolousPaymentTitle}: <br/>
                {" "} {returnPriceWithCurrency(valueAppolousPaymentAmount)} 
              </h6> 
            </Col>
          </Row>
        }
      </>
    )
  }

  const displayTotalCommisionUI = (value, text) => {
    return (
      <div 
        style={{
          width: blnStatusRejected ? '18.833333333333333%' : blnStatusPendingApproval || blnStatusPendingFinalApproval ? '19%' : '16%', 
          color: value.error ? Constants.red : Constants.green,
          fontSize: '13px',
          fontWeight: 'bold'
        }}>
        {`${text}: `}
        <br/>
        {`${value.amount}`}
      </div>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading || modalEnterPasscode ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <style type="text/css" media="print">{"\
                @page {\ size: 22in 16in;;\ }\
              "}</style>
              <Col>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <Col>
                        <div className="logo-lg" style={{ lineHeight: '20px' }}>
                          <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                            {intl.formatMessage({ id: "BranchCommission" })}
                          </span>
                          <br />
                          <br />
                        </div>
                      </Col>
                      <Row>
                        <Col xl='6'>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Brand" })}:`, branchCommissionFullDetail.brandName)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Branch" })}:`, branchCommissionFullDetail.branchName)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, `${moment(branchCommissionFullDetail.month, "MM").format("MMM")} ${branchCommissionFullDetail.year}`)}
                        </Col>
                        <Col xl='6'>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "GeneratedBy" })}:`, branchCommissionFullDetail.generateBy || Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "GeneratedOn" })}:`, branchCommissionFullDetail.generatedOn !== Constants.invalidDateTime ? moment(branchCommissionFullDetail.generatedOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)}
                        </Col>
                      </Row>
                    </div>
                    <Borderline />
                    <Row className="mb-3">
                      <Col xs="6">
                        <address className="font-size-14">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div className="" style={{ marginTop: '8px' }}>
                            <GeneralStatus
                              status={branchCommissionFullDetail.status}
                              statusColorFunction={branchCommissionStatusColor}
                              blnDisableCenter />
                          </div>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <MiniCard
                        title={intl.formatMessage({ id: "TotalBranchSales" })}
                        value={returnPriceWithCurrency(branchCommissionFullDetail.totalBranchSales)}
                        size={miniCardSize} 
                        displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "ActualAmt" }), branchCommissionFullDetail.totalActualBranchSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), branchCommissionFullDetail.totalBranchSales, intl.formatMessage({ id: "AppolousSalesAmt" }), branchCommissionFullDetail.totalAppolousSalesAdjustment )} />
                      <MiniCard
                        title={intl.formatMessage({ id: "TotalNettSales" })}
                        value={returnPriceWithCurrency(branchCommissionFullDetail.totalBranchPackageSales)}
                        borderColor={Constants.lightgreen}
                        size={miniCardSize} 
                        displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "ActualAmt" }), branchCommissionFullDetail.totalActualBranchPackageSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), branchCommissionFullDetail.totalBranchPackageSales, intl.formatMessage({ id: "AppolousSalesAmt" }), branchCommissionFullDetail.totalAppolousPackageSalesAdjustment )}/>
                      <MiniCard
                        title={intl.formatMessage({ id: "TotalProductSales" })}
                        value={returnPriceWithCurrency(branchCommissionFullDetail.totalBranchProductSales)}
                        borderColor={Constants.orange}
                        size={miniCardSize}
                        displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "FullPayment" }), branchCommissionFullDetail.totalActualBranchProductSales, intl.formatMessage({ id: "PartialPayment" }), branchCommissionFullDetail.totalPartialPaymentBranchProductSales, intl.formatMessage({ id: "AppolousSalesAmt" }), branchCommissionFullDetail.totalAppolousProductSalesAdjustment )}/>
                      {/* <MiniCard
                        title={intl.formatMessage({ id: "TotalBranchSalesAdjustment" })}
                        value={returnPriceWithCurrency(branchCommissionFullDetail.totalBranchSalesAdjustment)}
                        size={miniCardSize} /> */}
                    </Row>
                    <Row>
                      <Col xl="6" lg="6" sm="12" xs="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-3">{intl.formatMessage({ id: "SalesAdjustment" }).toUpperCase()}</CardTitle>
                            <hr className="m-0"/>
                            <div className="table-responsive">
                              <Table className="table-nowrap mb-0">
                                <tbody>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalSalesAdjustment" }).toUpperCase()} :</th>
                                    <td className={`${totalBranchSalesAdjustment !== 0 ? totalBranchSalesAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                      {returnPriceWithCurrency(totalBranchSalesAdjustment)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalPackageSalesAdjustment" }).toUpperCase()} :</th>
                                    <td className={`${branchCommissionFullDetail.totalPackageSalesAdjustment !== 0 ? branchCommissionFullDetail.totalPackageSalesAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                      {returnPriceWithCurrency(branchCommissionFullDetail.totalPackageSalesAdjustment)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalProductSalesAdjustment" }).toUpperCase()} :</th>
                                    <td className={`${branchCommissionFullDetail.totalProductSalesAdjustment !== 0 ? branchCommissionFullDetail.totalProductSalesAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                      {returnPriceWithCurrency(branchCommissionFullDetail.totalProductSalesAdjustment)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalProductCommissionAdjustment" }).toUpperCase()} :</th>
                                    <td className={`${branchCommissionFullDetail.totalProductCommissionAdjustment !== 0 ? branchCommissionFullDetail.totalProductCommissionAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                      {returnPriceWithCurrency(branchCommissionFullDetail.totalProductCommissionAdjustment)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" sm="12" xs="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-3">{intl.formatMessage({ id: "Commissions" }).toUpperCase()}</CardTitle>
                            <hr className="m-0"/>
                            <div className="table-responsive">
                              <Table className="table-nowrap mb-0">
                                <tbody>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalCommission" }).toUpperCase()} :</th>
                                    <td>{returnPriceWithCurrency(branchCommissionFullDetail.totalCommission)}</td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalPackageCommission" }).toUpperCase()} :</th>
                                    <td>{returnPriceWithCurrency(branchCommissionFullDetail.totalPackageCommission)}</td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase()} :</th>
                                    <td>{returnPriceWithCurrency(branchCommissionFullDetail.totalProductCommission)}</td>
                                  </tr>
                                  <tr>
                                    <th>{intl.formatMessage({ id: "TotalAngPaoBonus" }).toUpperCase()} :</th>
                                    <td>{returnPriceWithCurrency(branchCommissionFullDetail.totalAngPaoBonus)}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <LineBreakWithTittle
                      className="py-2 mt-3"
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "CommissionDetails" })}
                      h4Title
                    />
                    <TableWithEditableInputFields
                      name="commissionDetails"
                      title=""
                      buttonTitle={""}
                      blnEditable={!viewAction && !blnStatusPendingApproval && !blnStatusPendingSecondApproval && !blnStatusPendingFinalApproval}
                      hideAddButton={true}
                      hideDeleteButton={true}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.commissionDetails}
                      validation={validation}
                      disabled={disabledFieldInput} />
                    {/* <div className="mt-4 flex-align-items-center-without-height">
                      <div style={{width: blnStatusRejected || blnStatusPendingApproval || blnStatusPendingFinalApproval ? '40.5%' : '50%'}}/>
                      {displayTotalCommisionUI(finalPackageCommissionDifference, intl.formatMessage({ id: blnStatusGenerated ? "TotalPackageComm" : "FinalPackageComm" }))}
                      {displayTotalCommisionUI(finalProductCommissionDifference, intl.formatMessage({ id: blnStatusGenerated ? "TotalProductComm" : "FinalProductComm" }))}
                      {displayTotalCommisionUI(finalAngPaoBonusDifference, intl.formatMessage({ id: blnStatusGenerated ? "TotalAngPaoBonus" : "FinalAngPaoBonus" }))}
                    </div> */}
                    {
                      (blnStatusPendingApproval || blnStatusPendingSecondApproval || blnStatusPendingFinalApproval || blnStatusRejected) &&
                      <div className="d-print-none">
                        <div className={`py-2 mt-3`}>
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "Reason" })} :
                          </h3>
                        </div>
                        <GeneralTextArea
                          title=""
                          name="reason"
                          row={5}
                          //disabled={!(blnStatusPendingApproval || blnStatusPendingFinalApproval) || blnStatusRejected || !(blnPermissionReviewBranchCommissionFirstApproval || blnPermissionReviewBranchCommissionSecondApproval || blnPermissionReviewBranchCommissionFinalApproval) || disabledFieldInput}
                          disabled={!(blnFirstApprover || blnSecondApprover || blnFinalApprover) || disabledFieldInput}
                          validation={validation} />
                      </div>
                    }
                    <Borderline/>
                    <Row>
                        <Col xl="4" lg="4" sm="4" xs="6">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PreparedBy" })}:`, branchCommissionFullDetail.preparedBy || Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PreparedOn" })}:`, branchCommissionFullDetail.preparedOn !== Constants.invalidDateTime ? moment(branchCommissionFullDetail.preparedOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)}
                        </Col>
                        {/* <Col xl="3" lg="3" sm="6" xs="6">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "CheckedBy" })}:`, branchCommissionFullDetail.checkedBy || Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "CheckedOn" })}:`, branchCommissionFullDetail.checkedOn !== Constants.invalidDateTime ? moment(branchCommissionFullDetail.checkedOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)}
                        </Col> */}
                        <Col xl="4" lg="4" sm="4" xs="6">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedBy" })}:`, branchCommissionFullDetail.checkedBy || Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedOn" })}:`, branchCommissionFullDetail.checkedOn !== Constants.invalidDateTime ? moment(branchCommissionFullDetail.checkedOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)}
                        </Col>
                        <Col xl="4" lg="4" sm="4" xs="6">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedBy" })}:`, branchCommissionFullDetail.actionBy || Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedOn" })}:`, branchCommissionFullDetail.actionOn !== Constants.invalidDateTime ? moment(branchCommissionFullDetail.actionOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)}
                        </Col>
                    </Row>
                    <div className="mt-4" />
                    <div className="flex-direction-row d-print-none">
                      {
                        blnStatusRejected
                        &&
                        !viewAction
                        &&
                        !Boolean(successMessage)
                        &&
                        <MyButton
                          type="submit"
                          class={`btn btn-warning margin-right-8 `}
                          content={intl.formatMessage({ id: "Resubmit" })}
                          disable={validation.isSubmitting}
                          loading={validation.isSubmitting}
                          onClick={() => {
                            validation.setFieldValue("workflowAction", Constants.resubmit)
                          }}
                        />
                      }
                      {
                        (blnFirstApprover || blnSecondApprover || blnFinalApprover)
                        &&
                        !viewAction
                        &&
                        <>
                          <MyButton
                            type="submit"
                            class="btn btn-success margin-right-8"
                            content={intl.formatMessage({ id: "Approve" })}
                            onClick={() => {
                              validation.setFieldValue("workflowAction", blnStatusPendingApproval ? Constants.approvedByFirstApprover : blnStatusPendingSecondApproval ? Constants.approvedBySecondApprover : Constants.approvedByFinalApprover)
                            }}
                            disable={validation.isSubmitting || Boolean(successMessage)}
                            loading={validation.isSubmitting}
                          />
                          <MyButton
                            type="submit"
                            class="btn btn-danger margin-right-8"
                            content={intl.formatMessage({ id: "Reject" })}
                            onClick={() => {
                              validation.setFieldValue("workflowAction", blnStatusPendingApproval ? Constants.rejectedByFirstApprover : blnStatusPendingSecondApproval ? Constants.rejectedBySecondApprover :Constants.rejectedByFinalApprover)
                            }}
                            disable={validation.isSubmitting || Boolean(successMessage)}
                            loading={validation.isSubmitting}
                          />
                        </>
                      }
                      {
                        !Boolean(successMessage)
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={blnStatusPendingApproval || blnStatusPendingSecondApproval || blnStatusPendingFinalApproval || blnStatusFinal || blnStatusRejected || viewAction}
                          // showExtraLeftButton={!blnStatusPendingApproval}
                          // extraLeftDirtyDisabled={false}
                          // extraLeftButtonTitle={intl.formatMessage({ id: "RequestForAmend" })}
                          // extraLeftButtonColor="btn-primary"
                          // extraLeftButtonFunction={() => {
                          //   validation.setFieldValue("workflowAction", Constants.requestForAmend)
                          // }}
                          showExtraRightButton={!blnStatusPendingApproval}
                          extraRightDirtyDisabled={true}
                          extraRightButtonTitle={intl.formatMessage({ id: "Regenerate" })}
                          extraRightButtonColor="btn-warning"
                          extraRightButtonFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.regenerate)
                          }}
                          submitButtonTitle={intl.formatMessage({ id: "SubmitForApproval" })}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.submitForApproval)
                          }}
                          showPrint={blnStatusFinal || viewAction}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={true}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </DetailViewLayout>
      {
        modalEnterPasscode
        &&
        <EnterPasscodeModal 
          intl={intl}
          actionTitle={viewAction ? Constants.view : addAction ? Constants.add : Constants.edit}
          showPrompt={modalEnterPasscode}
          setShowPrompt={setModalEnterPasscode}
          onPrimaryClick={fetchBranchCommissionDetailAPI}
          onCancelClick={()=> {history.goBack()}} />
      }
    </div>
  );
};

export default observer(BranchCommissionDetail);
