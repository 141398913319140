import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchUser, getBrandId, IncludesLocationPathName, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import moment from "moment";
import DeleteModal from "../../app/components/modal/DeleteModal";

const EmployeeLeaveDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { employeeLeaveStore, employeeStore, staticReferenceStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
    setShowDeletePrompt
  } = commonStore;
  const { addEmployeeLeave, updateEmployeeLeave, employeeLeaveDetail, setEmployeeLeaveDetail, getEmployeeLeaveWithId, deleteEmployeeLeave } = employeeLeaveStore;
  const { leaveType, leavePeriodType, getStaticReferenceWithType } = staticReferenceStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "LeaveApplication" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "LeaveApplication" }), urlPath: RoutesList.employeeLeave }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateEmployeeLeave = checkPermission([PermissionConstants.UpdateEmployeeLeave]);
  const blnPermissionDeleteEmployeeLeave = checkPermission([PermissionConstants.DeleteEmployeeLeave]);
  const [blnBranchStaff, setBlnBranchStaff] = useState(getBranchUser());
  const [loadingLeaveType, setLoadingLeaveType] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: employeeLeaveDetail || {
      employeeId: "",
      employeeName: "",
      startDate: "",
      endDate: "",
      isHalfDay: false,
      leavePeriod: "",
      leaveType: getBranchUser() ?  Constants.dayOffWithSpace : "",
      reason: "",
      remark: "",
      workflowAction: "",
    },
    validationSchema: Yup.object({
      employeeId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Employee" }) })),
      leaveType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "LeaveType" }) })),
      startDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "StartDate" }) })),
      endDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EndDate" }) })),
      leavePeriod: Yup.string().test(
        "leavePeriod",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Period" }) }),
        (value: any, context) => {
          if (blnDisplayLeavePeriod) {
            return value;
          }
          return true;
        }
      ).nullable(),
      reason: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.leaveType = newSpaceBeforeCapitalLetter(valuesTemp.leaveType);

      try {
        if (addAction) {
          await addEmployeeLeave(valuesTemp);
        } else {
          await updateEmployeeLeave(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnDisplayLeavePeriod = validation.values.isHalfDay == true;
  const blnEndDate = validation.values.startDate && validation.values.startDate !== "";
  const blnHalfDay = (validation.values.startDate && validation.values.endDate) && moment(validation.values.startDate).format(Constants.displayDateFormat) === moment(validation.values.endDate).format(Constants.displayDateFormat)

  useEffect(() => {
    async function fetchEmployeeLeaveDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getEmployeeDropdown({ branchId: getBranchUser() ? getBranchId() : "", ...getBranchUser() && { isBranchStaff: getBranchUser() }, isActive: true}),
        getStaticReferenceWithType(Constants.leaveType),
        getStaticReferenceWithType(Constants.leavePeriodType)
      ];
      if (id && !addAction) {
        aryAPI.push(getEmployeeLeaveWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setEmployeeLeaveDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateEmployeeLeave], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateEmployeeLeave], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageEmployeeLeave], true)) {
        return;
      }
    }

    fetchEmployeeLeaveDetailAPI();

    return (() => {
      if (id && !addAction) {
        setEmployeeLeaveDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (employeeLeaveDetail) {
      if (employeeLeaveDetail.status == Constants.pending && viewAction && blnPermissionUpdateEmployeeLeave) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.employeeLeave}/edit/${id}`) } })
      }

      let indexDeleteEmployeeLeave = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "DeleteRecord" })}` })
      if (viewAction && blnPermissionDeleteEmployeeLeave && indexDeleteEmployeeLeave < 0) {
        burgerMenuListContent.push({
          label: `${intl.formatMessage({ id: "DeleteRecord" })}`,
          onFunction: async () => {
            setShowDeletePrompt(true);
          }
        })
      }

      let indexEmployee = _.findIndex(employeeDropdownList, { id: employeeLeaveDetail.employeeId })
      if (indexEmployee > -1) {
        setBlnBranchStaff(employeeDropdownList[indexEmployee].employeeType === Constants.branchStaff);
      }
    }
  }, [employeeLeaveDetail, employeeDropdownList])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onChangeDate = () => {
    validation.setFieldValue("isHalfDay", false);
    validation.setFieldValue("leavePeriod", "");
  }
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "EmployeeLeave" }) })}
                  h4Title />
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"employeeId"}
                    title={intl.formatMessage({ id: "Employee" })}
                    className="mb-3 mt-4"
                    specifyReturnFieldName={[{ "field": "employeeName", "value": "preferredName" }, { "field": "employeeId", "value": "id" }]}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={employeeDropdownList}
                    blnValueWithNewSpace={true}
                    disabled={disabledFieldInput || viewAction || !addAction}
                    initialLabel={!addAction ? validation.values.employeeName : undefined}
                    initialValue={!addAction ? validation.values.employeeId : undefined}
                    validationRequired={true}
                    validation={validation}
                    onChangeFunction={(value)=> {
                      // setLoadingLeaveType(true);
                      // let indexEmployee = _.findIndex(employeeDropdownList, {id: value})
                      // if (indexEmployee > -1) {
                      //   let blnBranchStaffTemp = employeeDropdownList[indexEmployee].employeeType === Constants.branchStaff;
                      //   setBlnBranchStaff(blnBranchStaffTemp);
                      //   validation.setFieldValue("leaveType", blnBranchStaffTemp ? Constants.dayOff : "");
                      // }
                      // setTimeout(()=> {
                      //   setLoadingLeaveType(false);
                      // }, 100)
                    }} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  {
                    loadingLeaveType
                    ?
                    <Loading />
                    :
                    <DropDownWithTitle
                      name={"leaveType"}
                      title={intl.formatMessage({ id: "LeaveType" })}
                      specifyReturnFieldName={[
                        {
                          field: "leaveType",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={leaveType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction || blnBranchStaff}
                      initialValue={blnBranchStaff && addAction ? intl.formatMessage({ id: "OffDay" }) : !addAction ? validation.values.leaveType : undefined}
                      validationRequired={true}
                      validation={validation} />
                  }
                </SingleColumnRowParent>
                <Row >
                  <Col xl={5}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "StartDate" })}
                      name="startDate"
                      type="date"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      onChangeFunction={()=> {
                        onChangeDate();
                      }}
                      validation={validation}/>
                  </Col>
                  <Col xl={5}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "EndDate" })}
                      name="endDate"
                      type="date"
                      disabled={disabledFieldInput || viewAction || !blnEndDate}
                      validationRequired={true}
                      minCurrentDate={true}
                      minDateValue={validation.values.startDate ? moment(validation.values.startDate).format(Constants.displayDateFormat) : ""}
                      onChangeFunction={()=> {
                        onChangeDate();
                      }}
                      validation={validation}/>
                  </Col>
                  <Col xl={2} className={!blnHalfDay ? "text-muted" : ""}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "HalfDay" })}
                      name="isHalfDay"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction || !blnHalfDay}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                  </Col>
                </Row>
                {
                  !blnHalfDay &&
                  <p className="font-size-12 error_require_text margin-top--5">
                    {intl.formatMessage({ id: "HalfDayOption" })}
                  </p>
                }
                {
                  blnHalfDay
                  &&
                  blnDisplayLeavePeriod 
                  &&
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"leavePeriod"}
                      title={intl.formatMessage({ id: "LeavePeriod" })}
                      specifyReturnFieldName={[
                        {
                          field: "leavePeriod",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={leavePeriodType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction || !blnDisplayLeavePeriod}
                      initialValue={!addAction ? validation.values.leavePeriod : undefined}
                      validationRequired={blnDisplayLeavePeriod}
                      validation={validation} />
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Reason" })}
                    name="reason"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Remark" })}
                    name="remark"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation} />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <div className="flex-direction-row d-print-none">
              <>
                {
                  !Boolean(successMessage)
                  &&
                  blnPermissionDeleteEmployeeLeave
                  &&
                  viewAction
                  &&
                  <MyButton
                    type="button"
                    class={`btn btn-danger margin-right-8`}
                    content={intl.formatMessage({ id: "Delete" })}
                    disable={loading}
                    loading={loading}
                    onClick={async () => {
                      setShowDeletePrompt(true);
                    }}
                  />
                }
                <GeneralSubmitAndCancelBtn
                  successMessage={successMessage}
                  viewAction={false}
                  validation={validation}
                  showExtraLeftButton={true}
                  extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                  extraLeftButtonColor="btn-primary"
                  extraLeftButtonFunction={() => {
                    validation.setFieldValue("workflowAction", 'Save')
                  }}
                  hidePrimaryButton={viewAction}
                  submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                  onClickFunction={() => {
                    validation.setFieldValue("workflowAction", 'Submit')
                  }}
                  dirtyDisabled={!addAction ? true : false}
                />
              </>
            </div>
          </Form>
        )}
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteEmployeeLeave(id || "");
            setLoading(false);
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(EmployeeLeaveDetail);
