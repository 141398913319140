import { Fragment } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";

//Import Images
import { getJWT } from "../../app/common/function/function";

interface Props {
  permissionDenied?: boolean;
}
const CustomerProfileAccessDenied = (props: Props) => {
  const intl = useIntl();

  return (
    <div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                {/* <h1 className="display-2 font-weight-medium">
                  4
                  <i className="bx bx-buoy bx-spin text-primary display-3" />
                  <i className="bx bx-buoy bx-spin text-primary display-3" />
                </h1> */}
                <h4 className="text-uppercase" style={{marginLeft: props.permissionDenied ? "20px" : "0px"}}>{intl.formatMessage({id: "CustomerProfileAccessDenied"})}</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary "
                    style={{marginLeft: props.permissionDenied ? "20px" : "0px"}}
                    to={getJWT() ? `/${RoutesList.dashboard}` : `/${RoutesList.login}`} 
                  >
                    {intl.formatMessage({id: "BackToPage"})}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default CustomerProfileAccessDenied;
