import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Row, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import { StockOutListObject } from "../../app/models/stockOut";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { stockOutViewAvailable, stockOutStatusColor, checkPermission, stockOutDeleteAvailable, newSpaceBeforeCapitalLetter, arraySpliceInAllValue, stockOutEditAvailable } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const StockOut = () => {
  const intl = useIntl();
  //Use Store
  const { stockOutStore, warehouseStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { stockOutList, getStockOut, deleteStockOut } = stockOutStore;
  const { warehouseDropdownList, getWarehouseDropdown } = warehouseStore;
  const { stockInOutStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<StockOutListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const [warehouseListFinal, setWarehouseListFinal] = useState<ObjectForDropdown[]>([]);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const AllWarehouse = `${intl.formatMessage({ id: "AllWarehouse" })}`;
  const [selectedStockOutStatus, setSelectedStockOutStatus] = useState<number | string>(AllStatus);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState(AllWarehouse);
  const [selectedStockOutStatusId, setSelectedStockOutStatusId] = useState<number | string | undefined>(" ");
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(" ");
  const [stockInOutStatusList, setStockInOutStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionCreateStockOut = checkPermission([PermissionConstants.CreateStockOut]);
  const blnPermissionUpdateStockOut = checkPermission([PermissionConstants.UpdateStockOut]);
  const blnPermissionDeleteStockOut = checkPermission([PermissionConstants.DeleteStockOut]);
  const blnPermissionReviewStockOut = checkPermission([PermissionConstants.ReviewStockOut]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchStockOutListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchStockOutAPI(false),
        getWarehouseDropdown(),
        getStaticReferenceWithType(Constants.stockInOutStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setWarehouseListFinal(arraySpliceInAllValue(resultAPI[1], AllWarehouse, false, "name", "id"));
      setStockInOutStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockOut], true)) {
      return;
    }

    fetchStockOutListAPI();
  }, []);

  useEffect(() => {
    if (
      (selectedStockOutStatusId || selectedStockOutStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchStockOutAPI(true);
    }
  }, [selectedStockOutStatusId, selectedWarehouseId]);

  async function fetchStockOutAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockOut({
      pageNumber: currentPage,
      pageSize: pageSize,
      stockInOutStatus: selectedStockOutStatusId,
      warehouseId: selectedWarehouseId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: StockOutListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  //Table Content UI
  const StockOutColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockOutNo",
      text: `${intl.formatMessage({ id: "StockOutNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "stockOutDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.stockOutDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "warehouseName",
      text: intl.formatMessage({ id: "Warehouse" }).toUpperCase(),
    },
    {
      dataField: "approverName",
      text: intl.formatMessage({ id: "Approver" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.approverName || Constants.emptyData
      ),
    },
    {
      dataField: "approvedDate",
      text: intl.formatMessage({ id: "ApprovedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.approvedDate === Constants.invalidDate ? Constants.emptyData : moment(row.approvedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockOutStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.stockOut}/view/${row.id}`}
              type={`view`}/>
            {
              ((blnPermissionUpdateStockOut && !stockOutViewAvailable(row.status)) || (blnPermissionReviewStockOut && stockOutViewAvailable(row.status)))
              &&
              stockOutEditAvailable(row.status)
              &&
              <TableActionButton 
                to={`/${RoutesList.stockOut}/${stockOutViewAvailable(row.status) ? 'view' : 'edit'}/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeleteStockOut
              &&
              stockOutDeleteAvailable(row.status)
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockOut" }) })}
        title={intl.formatMessage({ id: "StockOut" })}
        addButton={
          blnPermissionCreateStockOut
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "StockOut" }) })}
            onClick={() => { history.push(`/${RoutesList.stockOut}/add`) }}
            linkTo={`/${RoutesList.stockOut}/add`}
            disable={localLoading || loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockOut" }) })}
              name="stockOutOutStatus"
              options={stockInOutStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedStockOutStatus}
              initialValue={selectedStockOutStatusId}
              setFieldLabel={setSelectedStockOutStatus}
              setFieldValue={setSelectedStockOutStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Warehouse" })}
              name="warehouseName"
              options={warehouseListFinal}
              labelField="name"
              valueField="id"
              initialLabel={selectedWarehouseName}
              initialValue={selectedWarehouseId}
              setFieldLabel={setSelectedWarehouseName}
              setFieldValue={setSelectedWarehouseId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={StockOutColumns}
              data={stockOutList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockOut" }) })}
              objSorted={defaultSorted}
              stockInOutStatus={selectedStockOutStatusId}
              warehouseId={selectedWarehouseId}
              requestAPI={getStockOut}
            />
            <DeleteModal
              displayName={selectedRow?.stockOutNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deleteStockOut(
                  selectedRow?.id || "",
                  selectedRow?.stockOutNo || ""
                );
                let currentPageTemp = currentPage;
                if (stockOutList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getStockOut({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(StockOut);
