import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label, Row, Col } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../../app/components/form/GeneralInput";
import GeneralSelectDropdown from "../../../../app/components/form/GeneralSelectDropdown";
import { ProductListObject } from "../../../../app/models/product";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";
import Loading from "../../../../app/components/loading/Loading";
import moment from "moment";
import { returnCurrency } from "../../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  customerName: string;
  branchName: string;
  productDropdownList: ProductListObject[];
  loadingProductDropdown: boolean;
  onClickAddAppolousCustomerProduct: Function;
  loadingAddAppolousCustomerProduct: boolean;
}

export default function AddAppolousCustomerProductModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const defaultAddAppolousCustomerProductDetail = {
    customerId: props.customerId,
    customerName: props.customerName,
    branchName: props.branchName,
    productId: "",
    productName: "",
    quantity: 0,
    price: 0,
    submissionDate: moment().format(Constants.textInputDateAndTimeFormat)
  }
  const [addAppolousCustomerProductDetail, setAddAppolousCustomerProductDetail] = useState(defaultAddAppolousCustomerProductDetail)
  const [errorState, setErrorState] = useState({
    productId: false,
    quantity: false
  })
  const disabledFieldInput = props.loadingAddAppolousCustomerProduct || Boolean(successMessage);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerId: props.customerId,
      customerName: props.customerName,
      branchName: props.branchName,
      productId: "",
      productName: "",
      quantity: 0,
      price: 0,
      submissionDate: moment().format(Constants.textInputDateAndTimeFormat)
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      productId: false,
      quantity: false
    });
  }

  const hideModal = () => {
    setAddAppolousCustomerProductDetail(defaultAddAppolousCustomerProductDetail);
    props.setModal(false);
  }

  const onChangeProduct = (id: string) => {
    let productDropdownListTemp = props.productDropdownList;
    let indexProduct = _.findIndex(productDropdownListTemp, { id: id });
    if (indexProduct > -1) {
      setAddAppolousCustomerProductDetail((values) => ({
        ...values,
        productId: productDropdownListTemp[indexProduct].id || "",
        productName: productDropdownListTemp[indexProduct].name || ""
      }))
      clearErrorState("productId")
    }
  }

  const onChangeSubmissionDate = () => {
    setAddAppolousCustomerProductDetail((values) => ({
      ...values,
      submissionDate: validation.values.submissionDate
    }))
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size={"md"}
        centered
      >
        <div className="modal-header">
        <div className="flex-direction-row" style={{flex:1 }}>
          <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "AddAppolousCustomerProduct" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
          </div>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={12}>
              <GeneralInput
                title={intl.formatMessage({ id: "Branch" })}
                name={"branchName"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "Customer" })}
                name={"customerName"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              {
                props.loadingProductDropdown
                ?
                <div style={{ padding: "20px" }}>
                <Loading />
              </div>
                :
                <>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Product" })}
                    name="productName"
                    className={errorState.productId ? "mb-0" : "mb-3"}
                    options={props.productDropdownList || []}
                    onChange={onChangeProduct}
                    initialLabel={addAppolousCustomerProductDetail.productName}
                    initialValue={addAppolousCustomerProductDetail.productId}
                    labelField={"name"}
                    valueField={"id"}
                    validationRequired={true}
                    disabled={disabledFieldInput}
                  />
                  {errorState.productId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Product" }) })}</FormFeedback>
                  ) : null}
                </>
              }
              <GeneralInputForModal
                title={intl.formatMessage({ id: "Quantity" })}
                name="quantity"
                className={errorState.quantity ? "mb-0" : "mb-3"}
                type="number"
                validationRequired={true}
                field={addAppolousCustomerProductDetail}
                setFieldValue={setAddAppolousCustomerProductDetail}
                onChangeFunction={() => { clearErrorState("quantity") }}
                disabled={disabledFieldInput}
              />
              {errorState.quantity ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" }) })}</FormFeedback>
              ) : null}
              <GeneralInputForModal
                title={intl.formatMessage({ id: "Price" })}
                name="price"
                className={"mb-3"}
                type="number"
                inputGroup={true}
                inputGroupText={returnCurrency()}
                validationRequired={true}
                field={addAppolousCustomerProductDetail}
                setFieldValue={setAddAppolousCustomerProductDetail}
                onChangeFunction={() => { clearErrorState("price") }}
                blnDisableNegativeValue={true}
                disabled={disabledFieldInput}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "ProductSubmissionDate" })}
                name="submissionDate"
                className="mt-2 mb-4"
                placeholder={intl.formatMessage({ id: "ProductSubmissionDate" })}
                type="datetime-local"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
                maxCurrentDate={true}
                onChangeFunction={onChangeSubmissionDate}
              />
              <div className="flex-right">
                <MyButton
                  type="submit"
                  class="btn btn-success"
                  content={Constants.submit}
                  onClick={() => {
                    let errorStateTemp = {
                      productId: false,
                      quantity: false
                    };
                    let blnErrorFound = false;
                    if (!addAppolousCustomerProductDetail.productId) {
                      errorStateTemp.productId = true;
                      blnErrorFound = true;
                    }
                    if (!addAppolousCustomerProductDetail.quantity) {
                      errorStateTemp.quantity = true;
                      blnErrorFound = true;
                    }
                    if (blnErrorFound) {
                      setErrorState(errorStateTemp)
                    }
                    else{
                      props.onClickAddAppolousCustomerProduct(addAppolousCustomerProductDetail);
                    }
                  }}
                  disable={disabledFieldInput}
                  loading={props.loadingAddAppolousCustomerProduct}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
  )
}