import { useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { PreviewCloseCounterDetailsObject } from "../../../app/models/closeCounter";
import { returnPriceWithCurrency, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import { isTablet } from "react-device-detect";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  id: string;
  blnShow: boolean;
  setModal: Function;
  previewCloseCounterDetails: PreviewCloseCounterDetailsObject;
  submitCloseCounter: Function;
}

export default function PreviewCloseCounterModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [blnAllRecordTrue, setBlnAllRecordTrue] = useState(true);
  const [blnMisMatchedRecord, setBlnMisMatchedRecord] = useState(false);
  const [blnMissingTerminalSetup, setBlnMissingTerminalSetup] = useState(false);
  const disabledFieldInput = localLoading || Boolean(successMessage);
  
  useEffect(()=> {
    if (props.previewCloseCounterDetails) {
      let blnMisMatchedRecordTemp = false;
      if (props.previewCloseCounterDetails.settlements) {
        for (let indexSettlements=0; indexSettlements<props.previewCloseCounterDetails.settlements.length; indexSettlements++) {
          if (!props.previewCloseCounterDetails.settlements[indexSettlements].isAmountMatch) {
            setBlnMisMatchedRecord(true);
            blnMisMatchedRecordTemp = true;
          }
        }
      }

      if (!blnMisMatchedRecordTemp && props.previewCloseCounterDetails.isMissingTerminalSetup) {
        setBlnMissingTerminalSetup(true);
      }
    }
  }, [])

  const hideModal = () => {
    props.setModal(false);
  }

  const displaySettlements = () => {
    let aryUITemp : any = [];
    props.previewCloseCounterDetails.settlements.map((valueSettlementsTemp, indexSettlementsTemp)=> {
      if (blnAllRecordTrue || (!blnAllRecordTrue && !valueSettlementsTemp.isAmountMatch)) {
        aryUITemp.push(
          <div className="mb-2"  key={`div_payment_details_${indexSettlementsTemp}`}>
            {
              valueSettlementsTemp.terminals.length > 0
              ?
              <div className="mt-3">
                <div className="flex-align-items-center-without-height mb-3">
                  <p style={{fontSize: "14px", fontWeight: "bold"}} className="mb-0">{valueSettlementsTemp.paymentName}</p>
                  <div style={{flex: 1, height: '1px', backgroundColor: '#ccc', marginLeft: '10px'}}/>
                </div>
                {
                  valueSettlementsTemp.terminals.map((valueTerminalsTemp, indexTerminalsTemp)=> (
                    displayPaymentDetails(valueTerminalsTemp.terminalName, valueTerminalsTemp.amount, !valueTerminalsTemp.isAmountMatch, indexSettlementsTemp, indexTerminalsTemp)
                  ))
                }
              </div>
              :
              displayPaymentDetails(valueSettlementsTemp.paymentName, valueSettlementsTemp.amount, !valueSettlementsTemp.isAmountMatch, indexSettlementsTemp, -1)
            }
          </div>
        )
      }
    }) 

    return aryUITemp;
  }

  const displayPaymentDetails = (paymentName, amount, blnError, indexTemp1, indexTemp2) => {
    if (!blnAllRecordTrue && !blnError) {
      return;
    }

    return (
      <Row key={`payment_details_${indexTemp1}_${indexTemp2}`} className={"mt-2 flex-align-items-center-without-height"}>
        <Col xl="6">
          {
            indexTemp2 > -1
            ?
            <p style={{fontSize: "14px", color: blnError ? Constants.red : Constants.black}} className="mb-0">
              {paymentName}
            </p>
            :
            <p style={{fontSize: "14px", fontWeight: "500", color: blnError ? Constants.red : Constants.black}} className="mb-0">
              {paymentName}
            </p>
          }
        </Col>
        <Col xl="6">
          <div style={{backgroundColor: "#eeeeee", borderRadius: "8px", fontSize: "14px", color: blnError ? Constants.red : Constants.black, padding: "12px", fontWeight: "500"}}>
            {returnPriceWithCurrency(amount)}
          </div>
        </Col>
      </Row>
    )
  }

  const onClickAllRecord = () => {
    setBlnAllRecordTrue(true);
  }

  const onClickMismatchOnly = () => {
    setBlnAllRecordTrue(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size="md"
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">
            {intl.formatMessage({ id: "PreviewCloseCounter" })}
            <span className={`font-size-13 ${blnMisMatchedRecord || blnMissingTerminalSetup ? "text-danger" : "text-success"}`}>{` (${intl.formatMessage({ id: blnMisMatchedRecord || blnMissingTerminalSetup ? "Mismatched" : "Matched" })})`}</span>
          </h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        {
          <div className="modal-body">
            {
              blnMisMatchedRecord
              &&
              <div className="btn-group mt-1 mb-3 flex-wrap" role="group">
                {returnSwitchTabButtonUI("blnAllRecord", "blnAllRecordTrue", blnAllRecordTrue, localLoading, intl.formatMessage({ id: "All" }), onClickAllRecord, "150px")}
                {returnSwitchTabButtonUI("blnAllRecord", "blnAllRecordFalse", !blnAllRecordTrue, localLoading, intl.formatMessage({ id: "MismatchedOnly" }), onClickMismatchOnly, "150px")}
              </div>
            }
            {displaySettlements()}
            {
              blnMissingTerminalSetup
              &&
              <p className="text-danger mb-0 mt-4">{intl.formatMessage({ id: "MissingTerminalSetupAlert" })}</p>
            }
            {
              !Boolean(successMessage)
              &&
              <div className="margin-top-14 flex-right">
                <MyButton
                  type="button"
                  class="btn btn-success"
                  content={intl.formatMessage({ id: "Submit" })}
                  onClick={async () => {
                    setLocalLoading(true);
                    await props.submitCloseCounter(props.id);
                    setLocalLoading(false);
                  }}
                  disable={disabledFieldInput || blnMisMatchedRecord || blnMissingTerminalSetup}
                  loading={localLoading}
                />
              </div>
            }
          </div>
        }
      </Modal>
  )
}