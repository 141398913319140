import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency, returnRefundStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Link } from 'react-router-dom';
import moment from "moment";
import { ExchangeToCreditDetailsGetAndExportObject } from "../../../app/models/reportSales";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";


const ExchangeToCreditDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, staticReferenceStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { exchangeToCreditDetailsList, getExchangeToCreditDetailsList, setExchangeToCreditDetailsList, exportExchangeToCreditDetails } = reportSalesStore; 
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { exchangeType, getStaticReferenceWithType} = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedReturnRefundStatus, setSelectedReturnRefundStatus] = useState<string>(AllStatus);
  const [selectedReturnRefundStatusId, setSelectedReturnRefundStatusId] = useState<number | string>(" ");
  const [returnRefundStatusList, setReturnRefundStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedExchangeType, setSelectedExchangeType] = useState<string>("ALL");
  const [selectedExchangeTypeId, setSelectedExchangeTypeId] = useState<number | string>(0);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerRefund = checkPermission([PermissionConstants.ManageCustomerRefund]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const paramsSearch = useLocation().search;

  const aryExchangeDetails : ObjectForReportRow[] = [
    {label: "ItemName", value: "purchaseName", type: "string"}, 
    {label: "PurchaseType", value: "purchaseType", type: "string"}, 
    {label: "OriginalAmount", value: "originalAmount", type: "currency"}, 
    {label: "Quantity", value: "quantity", type: "string"}, 
    {label: "ExchangeAmount", value: "exchangeAmount", type: "currency"}, 
  ];

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerSalesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  useEffect(() => {
    async function fetchExchangeToCreditDetails() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.returnRefundStatus, AllStatus),
        getStaticReferenceWithType(Constants.exchangeType),
      ];      
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setReturnRefundStatusList(resultAPI[1]);

      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const statusParams = new URLSearchParams(paramsSearch).get('status');
      const exchangeTypeParams = new URLSearchParams(paramsSearch).get('exchangeType');

      if (branchIdParams && fromDateParams && toDateParams && statusParams && exchangeTypeParams) {
        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchName(resultAPI[0][indexBranch].name);
          setSelectedBranchId(resultAPI[0][indexBranch].id);
        }

        let indexReturnRefundStatus = _.findIndex(resultAPI[1], { key: Number(statusParams) });
        if (indexReturnRefundStatus > -1) {
          setSelectedReturnRefundStatus(resultAPI[1][indexReturnRefundStatus].displayValue);
          setSelectedReturnRefundStatusId(resultAPI[1][indexReturnRefundStatus].key);
        }

        let indexExchangeType = _.findIndex(resultAPI[2], { key: Number(exchangeTypeParams) });
        if (indexExchangeType > -1) {
          setSelectedExchangeType(resultAPI[2][indexExchangeType].displayValue);
          setSelectedExchangeTypeId(resultAPI[2][indexExchangeType].key);
        }

        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.exchangeToCreditDetails}` });
        await getExchangeToCreditDetailsList({
          pageNumber: 1, 
          pageSize: pageSize, 
          branchId: branchIdParams, 
          customerId: "",
          customerSalesOrderNo: "",
          dateRange: { fromDate: fromDateTemp, toDate: toDateTemp },
          status: statusParams,
          exchangeType: exchangeTypeParams
        });
      }

		  setLoading(false);
      setInitialCallAPI(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchExchangeToCreditDetails();

    return (()=> {
      setExchangeToCreditDetailsList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchExchangeToCreditDetailsWithLoading = async (initialData?: ExchangeToCreditDetailsGetAndExportObject) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    setCurrentPage(1);
    await getExchangeToCreditDetailsList({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      customerSalesOrderNo: validation.values.customerSalesOrderNo,
      dateRange: selectedDateRange,
      status: selectedReturnRefundStatusId,
      exchangeType: selectedExchangeTypeId
    });
    setLocalLoading(false);
	};

  async function exportExchangeToCreditDetail() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    await exportExchangeToCreditDetails({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      customerSalesOrderNo: validation.values.customerSalesOrderNo,
      dateRange: selectedDateRange,
      status: selectedReturnRefundStatusId,
      exchangeType: selectedExchangeTypeId
    });
    setLocalLoading(false);
  }

  //Table Content UI
  const ExchangeToCreditDetailsColumns = [
    {
      dataField: "customerReturnRefundNo",
      text: intl.formatMessage({ id: "ExchangeNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerRefund
          ?
          <Link to={row.exchangeToCreditId ? `/${RoutesList.exchangeToCredit}/view/${row.exchangeToCreditId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerReturnRefundNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerReturnRefundNo || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "issueDate",
      text: intl.formatMessage({ id: "RequestDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.issueDate ? moment(row.issueDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</>, 
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionViewCustomer
          ?
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerName || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerSalesOrderNo || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={returnRefundStatusColor}/>
      ),
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "TotalExchangeAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalExchangeAmount)}</div>
      ),
    },
    {
      dataField: "requestBy",
      text: intl.formatMessage({ id: "RequestBy" }).toUpperCase()
    },
    {
      dataField: "requestReason",
      text: intl.formatMessage({ id: "RequestReason" }).toUpperCase()
    },
    {
      dataField: "actionOn",
      text: intl.formatMessage({ id: "ActionOn" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.actionOn ? moment(row.actionOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</>, 
    },
    {
      dataField: "actionBy",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase()
    },
    {
      dataField: "reviewRemark",
      text: intl.formatMessage({ id: "ReviewRemark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.reviewRemark || Constants.emptyData}</div>
      ),
    }
  ];

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
  }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ExchangeDetails" })}
        rowFieldName={"exchangeDetails"}
        rowColumns={aryExchangeDetails}
        keyFieldName={"id"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ExchangeToCreditDetails"})})}
        title={intl.formatMessage({ id: "ExchangeToCreditDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportExchangeToCreditDetail()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Exchange-To-Credit-Details.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown 
                  &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SalesOrderDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ReturnRefund" }) })}
                  name="paymentStatus"
                  options={returnRefundStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedReturnRefundStatus}
                  initialValue={selectedReturnRefundStatusId}
                  setFieldLabel={setSelectedReturnRefundStatus}
                  setFieldValue={setSelectedReturnRefundStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "ExchangeType" })}
                  name="exchangeType"
                  options={exchangeType}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedExchangeType}
                  initialValue={selectedExchangeTypeId}
                  setFieldLabel={setSelectedExchangeType}
                  setFieldValue={setSelectedExchangeTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="customerSalesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchExchangeToCreditDetailsWithLoading()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ExchangeToCreditDetailsColumns}
            data={exchangeToCreditDetailsList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ExchangeToCreditDetails"})})}
            objSorted={defaultSorted}
            keyField={"exchangeToCreditId"}
            requestAPI={getExchangeToCreditDetailsList}
            branchId={selectedBranchId}
            customerId={selectedCustomerId}
            customerSalesOrderNo={validation.values.customerSalesOrderNo}
            dateRange={selectedDateRange}
            status={selectedReturnRefundStatusId}
            exchangeType={selectedExchangeTypeId}
            expandRow={expandRow}
            setLocalLoading={setLocalLoading}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ExchangeToCreditDetails);