import { PaginationRequestBody } from "../models/pagination";
import { BrandListObject, BrandAddObject, BrandUpdateObject, BrandDetailObject, SubBrandObject } from "../models/brand";
import { store } from "./store";
import { makeAutoObservable, runInAction, reaction } from "mobx";
import agent from "../api/agent";

export default class BrandStore {
  brandList: BrandListObject[] = [];
  brandDropdownList: BrandListObject[] = [];
  brandDetail: BrandDetailObject | undefined = undefined;
  brandPaginationParams: PaginationRequestBody | undefined = undefined;
  subBrandList: SubBrandObject[] = [];
  
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.brandList = [];
    this.brandDropdownList = [];
    this.brandDetail = undefined;
    this.brandPaginationParams= undefined;
    this.subBrandList = [];
  }

  setBrandList = (brandList: BrandListObject[]) => {
    this.brandList = brandList;
  }

  setBrandDetail = (brandDetail: BrandDetailObject | undefined) => {
    this.brandDetail = brandDetail;
  }

  setBrandPaginationParams = (brandPaginationParams: PaginationRequestBody | undefined) => {
    this.brandPaginationParams = brandPaginationParams;
  }

  getBrand = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setBrandPaginationParams(PaginationRequestBody);
    try{
      const resultBrands = await agent.Brand.brandList(PaginationRequestBody);
      runInAction(() => {
        this.brandList = resultBrands.data;
        store.commonStore.setTotalItem(resultBrands.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.brandList = [];
    }
  }

  getBrandDropdown = async () => {
    try{
      const resultBrandsDropdown = await agent.Brand.brandDropdownList();
      runInAction(() => {
        this.brandDropdownList = resultBrandsDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.brandDropdownList = [];
    }
  }

  getBrandWithId = async (id: string) => {
    try{
      const resultBrandsDetail = await agent.Brand.brandDetail(id);
      runInAction(() => {
        this.brandDetail = resultBrandsDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.brandDetail = undefined;
    }
  }

  getSubBrandDropdown = async () => {
    try{
      const resultSubBrandsDropdown = await agent.Brand.subBrandDropdownList();
      runInAction(() => {
        this.subBrandList = resultSubBrandsDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.subBrandList = [];
    }
  }

  addBrand = async (brandRequestBody: BrandAddObject) => {
    try{
      await agent.Brand.addBrand(brandRequestBody);
      store.commonStore.setSuccessMessage(`BrandAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateBrand = async (brandRequestBody: BrandUpdateObject) => {
    try{
      await agent.Brand.updateBrand(brandRequestBody);
      store.commonStore.setSuccessMessage(`BrandUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteBrand = async (id: string, name: string) => {
    try {
      await agent.Brand.deleteBrand(id);
      store.commonStore.setSuccessMessage(`BrandDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}