import { PaginationRequestBody } from "../models/pagination";
import { PositionListObject, PositionAddObject, PositionUpdateObject, PositionDetailObject } from "../models/position";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class positionStore {
  positionList: PositionListObject[] = [];
  positionDropdownList: PositionListObject[] = [];
  positionDetail: PositionDetailObject | undefined = undefined;
  positionPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.positionList = [];
    this.positionDropdownList = [];
    this.positionDetail = undefined;
    this.positionPaginationParams= undefined;
  }

  setPositionDetail = (positionDetail: PositionDetailObject | undefined) => {
    this.positionDetail = positionDetail;
  }

  setPositionPaginationParams = (positionPaginationParams: PaginationRequestBody | undefined) => {
    this.positionPaginationParams = positionPaginationParams;
  }

  setPositionDropdownList = (positionDropdownList: PositionListObject[]) => {
    this.positionDropdownList = positionDropdownList;
  }
  
  getPosition = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setPositionPaginationParams(PaginationRequestBody);
    try{
      const resultPosition = await agent.Position.positionList(PaginationRequestBody);
      runInAction(() => {
        this.positionList = resultPosition.data;
        store.commonStore.setTotalItem(resultPosition.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.positionList = [];
    }
  }

  getPositionDropdown = async (employeeTypeId: string | undefined | number, isFilterCustomerServicePosition?: boolean) => {
    try{
      let resultPositionDropdown = await agent.Position.positionDropdownList(employeeTypeId);
      
      if(isFilterCustomerServicePosition){
        resultPositionDropdown = resultPositionDropdown.filter(position =>{
          return position.id !== Constants.customerServiceConsultantPositionId;
        })
      }

      runInAction(() => {
        this.positionDropdownList = resultPositionDropdown;
      });
      return Promise.resolve(resultPositionDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.positionDropdownList = [];
    }
  }

  getPositionWithId = async (id: string) => {
    try{
      const resultPositionDetail = await agent.Position.positionDetail(id);
      runInAction(() => {
        this.positionDetail = resultPositionDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.positionDetail = undefined;
    }
  }

  addPosition = async (positionRequestBody: PositionAddObject) => {
    try{
      await agent.Position.addPosition(positionRequestBody);
      store.commonStore.setSuccessMessage(`PositionAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePosition = async (positionRequestBody: PositionUpdateObject) => {
    try{
      await agent.Position.updatePosition(positionRequestBody);
      store.commonStore.setSuccessMessage(`PositionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePosition  = async (id: string, name: string) => {
    try {
      await agent.Position.deletePosition(id);
      store.commonStore.setSuccessMessage(`PositionDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}