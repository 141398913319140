import React from 'react'
import MyButton from './MyButton';
import { Link } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import { Constants } from '../../constants/Constants';

interface Props {
  content: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement> | undefined) => void;
  disable?: boolean;
  class?: string;
  btnColor?: string;
  linkTo?: any;
  loading?: boolean;
}

export default function MyAddButton(props: Props) {
  const displayButton = () => {
    return (
      <MyButton
        type="button"
        content={props.content}
        class={`btn ${props.class} ${props.disable ? "btn-secondary" : props.btnColor ? props.btnColor : "btn-success"}`}
        subContent={<PlusIcon className='me-1' size={Constants.menuIconSize}/>}
        onClick={props.onClick}
        disable={props.disable}
        loading={props.loading}
      />
    )
  }
  
  return (
    props.linkTo && !props.disable
    ?
    <Link to={props.linkTo}>
      {displayButton()}
    </Link>
    :
    <>
      {displayButton()}
    </>
  )
}