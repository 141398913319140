import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label} from "reactstrap";
import BreadCrumb from '../../../app/components/breadCrumb/BreadCrumb';
import Loading from "../../../app/components/loading/Loading";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import _ from "lodash";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getPurchaseTypeIcon, promptInfoMessage, returnSplitBackgroundRow } from "../../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { ObjectForDropdown, breadCrumbObject } from "../../../app/models/common";
import { history } from '../../..'
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AppolousCustomerPackageDetailObject, AppolousCustomerPackageRedeemDetailObject, AppolousCustomerPackageRedeemRequestBody } from "../../../app/models/appolousCustomerPackage";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import MyButton from "../../../app/components/form/MyButton";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import BootstrapTable from "react-bootstrap-table-next";
import AppolousCustomerPackageRedeemDetailModal from "./Modal/AppolousCustomerPackageRedeemDetailModal";
import AppolousCustomerPackagePurchaseCart from "./subView/AppolousCustomerPackagePurchaseCart";
import AppolousCustomerPackageRedeemConfimationModal from "./Modal/AppolousCustomerPackageRedeemConfimationModal";
import MyAddButton from "../../../app/components/form/MyAddButton";
import SelectDropDownModal from "../../../app/components/modal/SelectDropDownModal";
import moment from "moment";

const AppolousCustomerPackagePurchase = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { appolousCustomerPackageStore, employeeStore, serviceTreatmentStore, commonStore } = useStore();
  const { errorMessage, successMessage, setSuccessMessage, setErrorMessage, windowSize } = commonStore;
  const { getAppolousCustomerPackageDetail, redeemAppolousCustomerPackage } = appolousCustomerPackageStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore; 
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown, getServiceTreatmentWithId, getServiceTreatmentBranchMachineActive } = serviceTreatmentStore
  const location = useLocation();

  //Data List
  const [localLoading, setLocalLoading] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [localAppolousCustomerPackageDetail, setLocalAppolousCustomerPackageDetail] = useState<AppolousCustomerPackageDetailObject | undefined>(undefined);
  const [appolousCustomerPackageRedeemDetailModal, setAppolousCustomerPackageRedeemDetailModal] = useState(false);
  const [selectedAppolousCustomerPackageRedeemDetail, setSelectedAppolousCustomerPackageRedeemDetail] = useState<AppolousCustomerPackageRedeemDetailObject | undefined>(undefined);
  const [blnBlockRedemptionSubmit, setBlnBlockRedemptionSubmit] = useState(false);
  const [alternativeServiceDropdownModal, setAlternativeServiceDropdownModal] = useState(false);
  const [appolousCustomerPackagePurchaseConfirmationModal, setAppolousCustomerPackagePurchaseConfirmationModal] = useState(false);
  const [loadingAlternativeService, setLoadingAlternativeService] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: localAppolousCustomerPackageDetail?.customerName || "", urlPath: `${RoutesList.customer}/view/${localAppolousCustomerPackageDetail?.customerId}`}];
  const blnPermissionBackdateAppolousRedemption = checkPermission([PermissionConstants.BackdateAppolousRedemption]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      branchId: getBranchId(),
      branchName: getBranchName(),
      customerId: "",
      customerName: "",
      packageName: "",
      signatureImage: "",
      isBackdate: "",
      submissionDate: "",
      redeemPurchaseDetails: []
    },
    validationSchema: Yup.object({
      redeemPurchaseDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })),
    }),
    onSubmit: async (values) => {
      const valuesTemp : any = _.cloneDeep(values);

      try {
        if (!appolousCustomerPackagePurchaseConfirmationModal){
          let totalRedemptionCount = 0;
          valuesTemp.redeemPurchaseDetails.map((valueRedeemPurchaseDetailsTemp: AppolousCustomerPackageRedeemDetailObject)=> {
            totalRedemptionCount += valueRedeemPurchaseDetailsTemp.count;
          })
          if (totalRedemptionCount > localAppolousCustomerPackageDetail!.availableSessions) {
            setErrorMessage(intl.formatMessage({id: "AppolousCustomerPackageServiceExceedLimit"}));
            return;
          }
          setAppolousCustomerPackagePurchaseConfirmationModal(true);
        }
        else {
          setBlnBlockRedemptionSubmit(true);
          valuesTemp.redeemPurchaseDetails.map((valueRedeemPurchaseDetailsTemp: AppolousCustomerPackageRedeemDetailObject)=> {
            valueRedeemPurchaseDetailsTemp.selectedProductIds = valueRedeemPurchaseDetailsTemp.availableOptionalProductsUsed.map((valueAvailableOptionalProductsUsed)=> (valueAvailableOptionalProductsUsed.value));
            valueRedeemPurchaseDetailsTemp.selectedMachines = valueRedeemPurchaseDetailsTemp.optionalBranchMachinesUsed ? valueRedeemPurchaseDetailsTemp.optionalBranchMachinesUsed.map((valueOptionalBranchMachinesUsed)=> ({branchMachineId: valueOptionalBranchMachinesUsed.value, consumeQuantity: Number(valueOptionalBranchMachinesUsed.consumeQuantity)})) : [];
          })

          valuesTemp.redeemDetails = valuesTemp.redeemPurchaseDetails;
          delete valuesTemp["redeemPurchaseDetails"];
          let resultRedeemAppolousCustomerPackage = await redeemAppolousCustomerPackage(valuesTemp);
          if (resultRedeemAppolousCustomerPackage?.status == Constants.success) {
            setTimeout(()=> {
              history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerPackage}/view/${localAppolousCustomerPackageDetail?.appolousCustomerPackageId}`)
            }, Constants.timeoutNavigation+1000)
          }
        }
      }
      finally {
        setTimeout(()=> {
          setBlnBlockRedemptionSubmit(false);
          validation.setSubmitting(false);
        }, 500);
      }
    },
  }); 

  // const blnDisplayAddAlternativeService = localAppolousCustomerPackageDetail?.services.length === 0 && validation.values.redeemPurchaseDetails.length === 0;
  const blnDisplayAddAlternativeService = localAppolousCustomerPackageDetail?.isRequiredShowAlternativeService;

  //Table Content UI
  const CustomerServicesReedemColumns = [
    { 
      dataField: "appolousCustomerPackageServiceId", //field name from array to display
      text: "appolousCustomerPackageServiceId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      style:{"cursor": "pointer"},
      formatter: (cellContent, row) => <>{row.appolousCustomerPackageServiceId}</>, //Custom UI to display
    },
    {
      dataField: "serviceName",
      text: intl.formatMessage({id: "ServiceTreatmentName"}),
      style: {
        width: '30%',
      }
    },
    {
      dataField: "add",
      text: intl.formatMessage({id: "Action"}).toUpperCase(),
      headerStyle: { textAlign: 'right' }, 
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({id: "Add"})}
            disable={false}/>
        </div>
      ),
    }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (String(e.target).includes(Constants.htmlButton)){
        dismissMessage();
        let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
        
        let indexCustomerOrderDetail = _.findIndex(redeemPurchaseDetailsTemp, {appolousCustomerPackageId: localAppolousCustomerPackageDetail!.appolousCustomerPackageId, appolousCustomerPackageServiceId: row.appolousCustomerPackageServiceId })
        if (indexCustomerOrderDetail > -1) {
          redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count += 1;
          promptInfoMessage(intl.formatMessage({ id: "AddedItemIntoRedemptionCartMessage" }, { quantity: redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count, item: redeemPurchaseDetailsTemp[indexCustomerOrderDetail].serviceName }), Constants.serviceTreatmentOnly);
        }
        else {
          row.optionalProducts = _.sortBy(row.optionalProducts, ['categoryName', 'productName']);
          let optionalProductsTemp = row.optionalProducts.map((valueTemp)=> ({label: valueTemp.productName, value: valueTemp.productId, categoryName: valueTemp.categoryName}))
          let serviceMandatoryProductUsedTemp = [];
          if (row.mandatoryProducts) {
            serviceMandatoryProductUsedTemp = row.mandatoryProducts.map((valueTemp)=> ({label: valueTemp.productName, value: valueTemp.productId}))
          }
          
          let optionalBranchMachinesUsedTemp = [];
          if (row.optionalBranchMachines) {
            optionalBranchMachinesUsedTemp = row.optionalBranchMachines.map((valueTemp)=> ({label: `${valueTemp.branchMachineName} ${valueTemp.serialNumber ? `(${valueTemp.serialNumber})` : ""}`, value: valueTemp.branchMachineId, consumeQuantity: valueTemp.advisableConsumeQuantity || 0}));
          }
          
          let objAppolousCustomerPackageRedeemDetailTemp : AppolousCustomerPackageRedeemDetailObject = {
            appolousCustomerPackageId: localAppolousCustomerPackageDetail!.appolousCustomerPackageId,
            appolousCustomerPackageServiceId: row.appolousCustomerPackageServiceId,
            serviceName: row.serviceName,
            count: 1,
            serviceById: "",
            serviceByName: "",
            selectedProductIds: [],
            availableOptionalProducts: optionalProductsTemp,
            availableOptionalProductsUsed: [],
            mandatoryProducts: serviceMandatoryProductUsedTemp,
            mandatoryProductsUsed: serviceMandatoryProductUsedTemp,
            availableOptionalBranchMachines: optionalBranchMachinesUsedTemp,
            optionalBranchMachinesUsed: [],
            selectedMachines: [],
            remark: ""
          }

          dismissMessage();
          setSelectedAppolousCustomerPackageRedeemDetail(_.cloneDeep(objAppolousCustomerPackageRedeemDetailTemp))
          setAppolousCustomerPackageRedeemDetailModal(true);
        }
        validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp)
      }
    }
  };

  useEffect(() => {
    async function fetchAppolousCustomerPackagePurchaseAPI() {
      let appolousCustomerPackageIdTemp = "";
      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.appolousCustomerPackageId) 
          {
            appolousCustomerPackageIdTemp = location.state.detail.appolousCustomerPackageId;
          }
        }
      }

      if (appolousCustomerPackageIdTemp == "") {
        history.push(`/${RoutesList.notFound}`);
        return;
      }
      
      setLocalLoading(true);
      let aryAPI: any = [
        getAppolousCustomerPackageDetail({appolousCustomerPackageId: appolousCustomerPackageIdTemp, isIncludeRedemption: false, branchId: getBranchId()}, true),
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true })
      ];
      let resultAPI = await Promise.all(aryAPI);
      setLocalAppolousCustomerPackageDetail(resultAPI[0]);
      validation.setFieldValue("customerId", resultAPI[0]?.customerId);
      validation.setFieldValue("customerName", resultAPI[0]?.customerName);
      validation.setFieldValue("packageName", resultAPI[0]?.packageName);
      setLocalLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerOrder], true)) {
      return;
    }    

    fetchAppolousCustomerPackagePurchaseAPI();
  }, [])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const selectCustomerPurchase = (index) => {
    dismissMessage();
    setSelectedAppolousCustomerPackageRedeemDetail(_.cloneDeep(validation.values.redeemPurchaseDetails[index]))
    setAppolousCustomerPackageRedeemDetailModal(true);
  }

  const deleteCustomerPurchase = (index) => {
    let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
    redeemPurchaseDetailsTemp.splice(index, 1);
    validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp);
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "AppolousCustomerPackageRedemption"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({id: "AppolousCustomerPackageRedemption"})}</div>
            </Col>
          </Row>
          <BreadCrumb breadCrumbList={breadCrumbList} activeBreadCrumbTitle={intl.formatMessage({id: "AppolousCustomerPackageRedemption"})}/>      
          {
            localLoading || localAppolousCustomerPackageDetail === undefined
            ?
            <Loading />
            :
            <>
              <Form
                id={Constants.appolousCustomerPackagePurchaseForm}
                className="standard-layout"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                onBlur={() => {
                  dismissMessage();
                }}>
                <Row form={+true}>
                  {" "}
                  {/* +true = Convert boolean to numbers*/}
                  <Col xl={12}>
                    <LineBreakWithTittle 
                      paddingBottom="0px"
                      title={localAppolousCustomerPackageDetail.appolousSalePerformaInvoiceNo || localAppolousCustomerPackageDetail.packageName}
                      h4Title/>
                  </Col>
                </Row>
                <Row>
                  <Col xl="7">
                    <ToolkitProvider
                      keyField={"appolousCustomerPackageServiceId"}
                      columns={CustomerServicesReedemColumns}
                      data={localAppolousCustomerPackageDetail.services}
                      bootstrap4
                      search
                    >
                    {toolkitProps => (
                      <div 
                        className="standard-layout">
                        <GeneralInput
                          title={intl.formatMessage({ id: "Customer" })}
                          name={"customerName"}
                          className="mb-0"
                          type={"text"}
                          disabled={true}
                          validationRequired={false}
                          validation={validation}
                        />
                        <Borderline/>
                        <Row>
                          {
                            <Col xl="4" sm="4" key={`service_treatment_tab_bar`}>
                              <div className="mb-3">
                                <label className="card-radio-label mb-0">
                                  <Input
                                    type="radio"
                                    id={`${intl.formatMessage({id: "Service"})}`}
                                    name="tabBar"
                                    checked={true}
                                    className={`display-none card-radio-input-Service`}
                                  />
                                  <div className="card-radio" style={{ borderRadius: '10px', padding: '12px', borderColor: `${Constants.lightpurple}`}}>
                                    <div className="flex-direction-row flex-justfity-content-center">
                                      <span className="">{getPurchaseTypeIcon(`${intl.formatMessage({id: "Service"})}`)}</span>
                                      <p className="margin-bottom-0 text-truncate" style={{}}>{"Service Treatment"}</p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          }
                        </Row>
                        {
                          <Col className="flex-right">
                            <Label>
                              <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                              <Label style={{color: 'grey'}} className="margin-bottom-0">
                                {`${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableSessions" })} : ${localAppolousCustomerPackageDetail.availableSessions}`}                                    
                              </Label>
                            </Label>
                          </Col>
                        }
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                bordered={true}
                                striped={false}
                                classes={"table align-middle"}
                                keyField={"appolousCustomerPackageServiceId"}
                                rowEvents={ rowEvents }
                                noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ServicesTreatment" })})}
                                {...toolkitProps.baseProps}
                              />
                              {
                                blnDisplayAddAlternativeService
                                &&
                                <div className="flex-right">
                                  <MyAddButton
                                    content={intl.formatMessage({ id: "AlternativeService" })} 
                                    onClick={async ()=> {
                                      setLoadingAlternativeService(true);
                                      if (serviceTreatmentDropdownList.length < 1) {
                                        await getServiceTreatmentDropdown({ branchId: getBranchId(), isActive: true })
                                      }
                                      setLoadingAlternativeService(false);
                                      setAlternativeServiceDropdownModal(true);
                                    }}
                                    loading={validation.isSubmitting || loadingAlternativeService}
                                  />
                                </div>
                              }
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    </ToolkitProvider>
                  </Col>
                  <Col xl="5">
                    {
                      Constants.innerWidthCompare > windowSize.innerWidth
                      ?
                      returnSplitBackgroundRow("margin-left--20px margin-right--20px")
                      :
                      null
                    }
                    <div 
                      className="standard-layout">
                      <AppolousCustomerPackagePurchaseCart
                        validation={validation}
                        disabledCustomerOrder={validation.isSubmitting}
                        selectAppolousCustomerPackagePurchase={selectCustomerPurchase}
                        deleteAppolousCustomerPackagePurchase={deleteCustomerPurchase}
                      />
                      {
                        blnPermissionBackdateAppolousRedemption
                        &&
                        <Col>
                          <GeneralInput
                            title={""}
                            name="isBackdate"
                            className={`mt-2 ${validation.values.isBackdate ? "" : "mb-4"} `}
                            type="checkbox"
                            disabled={validation.isSubmitting}
                            validationRequired={false}
                            validation={validation}
                            onChangeFunctionWithSetValidationField={(valueCheckBox)=> {
                              validation.setFieldValue("submissionDate", valueCheckBox ? moment().format(Constants.textInputDateAndTimeFormat) : "");
                            }}
                            childrenUI={
                              <Label className="margin-bottom-0 margin-left-4">
                                {intl.formatMessage({ id: "IsApplyRedemptionSubmissionDate" })}
                              </Label>
                            }
                          />
                          {
                            validation.values.isBackdate
                            &&
                            <GeneralInput
                              title={""}
                              name="submissionDate"
                              className="mt-2 mb-4"
                              placeholder={intl.formatMessage({ id: "RedemptionSubmissionDate" })}
                              type="datetime-local"
                              disabled={validation.isSubmitting}
                              validationRequired={true}
                              validation={validation}
                              maxCurrentDate={true}
                            />
                          }
                        </Col>
                      }
                      <Row>
                        <Col>
                          {!Boolean(successMessage) &&
                            (
                              <MyButton
                                type="submit"
                                class="btn btn-success"
                                style={{width: '100%'}}
                                content={intl.formatMessage({id: "Redeem"})}
                                disable={
                                  validation.isSubmitting || loadingAlternativeService
                                }
                                loading={
                                  validation.isSubmitting || loadingAlternativeService
                                }
                              />
                            )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          }
        </Container>
      </div>
      {
        appolousCustomerPackageRedeemDetailModal &&
        <AppolousCustomerPackageRedeemDetailModal
          blnShow={appolousCustomerPackageRedeemDetailModal}
          setModal={setAppolousCustomerPackageRedeemDetailModal}
          selectedAppolousCustomerPackageRedeemDetail={selectedAppolousCustomerPackageRedeemDetail}
          setSelectedAppolousCustomerPackageRedeemDetail={setSelectedAppolousCustomerPackageRedeemDetail}
          employeeList={employeeDropdownList}
          servicesList={localAppolousCustomerPackageDetail?.services || []}
          validation={validation}/>
      }
      {
        appolousCustomerPackagePurchaseConfirmationModal
        &&
        <AppolousCustomerPackageRedeemConfimationModal
          blnShow={appolousCustomerPackagePurchaseConfirmationModal}
          setModal={setAppolousCustomerPackagePurchaseConfirmationModal}
          validation={validation}
          blnBlockRedemptionSubmit={blnBlockRedemptionSubmit}/>
      }
      {
        alternativeServiceDropdownModal
        &&
        <SelectDropDownModal
          blnShow={alternativeServiceDropdownModal}
          setModal={setAlternativeServiceDropdownModal}
          title={`${intl.formatMessage({ id: "InputSelectTitle"}, {field : intl.formatMessage({ id: "AlternativeService"})})}`}
          buttonTittle={intl.formatMessage({ id: "Add"})}
          placeholder={"- Please Select -"}
          labelField={"name"}
          valueField={"id"}
          options={serviceTreatmentDropdownList}
          onClickWithSingleValueReturn={async (value)=> {
            if (value) {
              setLoadingAlternativeService(true);
              let aryAPI = [getServiceTreatmentWithId(value), getServiceTreatmentBranchMachineActive({branchId: getBranchId(), serviceTreatmentId: value})];
              let resultServiceTreatmentAPI : any = await Promise.all(aryAPI);
              if (resultServiceTreatmentAPI[0]) {
                let serviceTreatmentDetailTemp = resultServiceTreatmentAPI[0];
                let optionalProductsTemp : ObjectForDropdown[] | any = [];
                let serviceMandatoryProductUsedTemp : ObjectForDropdown[] = [];
                if (serviceTreatmentDetailTemp.serviceProducts) {
                  serviceTreatmentDetailTemp.serviceProducts = _.sortBy(serviceTreatmentDetailTemp.serviceProducts, ['categoryName', 'productName']);
                  serviceTreatmentDetailTemp.serviceProducts.map((valueServiceProduct)=> {
                    if (valueServiceProduct.isOptional) {
                      optionalProductsTemp.push({
                        label: valueServiceProduct.productName,
                        value: valueServiceProduct.productId,
                        categoryName: valueServiceProduct.categoryName
                      })
                    }
                    if (!valueServiceProduct.isOptional) {
                      serviceMandatoryProductUsedTemp.push({
                        label: valueServiceProduct.productName,
                        value: valueServiceProduct.productId
                      })
                    }
                  })
                }
                
                let optionalBranchMachinesUsedTemp = [];
                if (resultServiceTreatmentAPI[1]) {
                  optionalBranchMachinesUsedTemp = resultServiceTreatmentAPI[1].map((valueTemp)=> ({label: `${valueTemp.machineName} ${valueTemp.serialNumber ? `(${valueTemp.serialNumber})` : ""}`, value: valueTemp.branchMachineId, consumeQuantity: valueTemp.advisableConsumeQuantity}));
                }

                let objAppolousCustomerPackageRedeemDetailTemp : AppolousCustomerPackageRedeemDetailObject = {
                  appolousCustomerPackageId: localAppolousCustomerPackageDetail!.appolousCustomerPackageId,
                  serviceName: serviceTreatmentDetailTemp.name,
                  count: 1,
                  serviceById: "",
                  serviceByName: "",
                  selectedProductIds: [],
                  availableOptionalProducts: optionalProductsTemp,
                  availableOptionalProductsUsed: [],
                  mandatoryProducts: serviceMandatoryProductUsedTemp,
                  mandatoryProductsUsed: serviceMandatoryProductUsedTemp,
                  availableOptionalBranchMachines: optionalBranchMachinesUsedTemp,
                  optionalBranchMachinesUsed: [],
                  selectedMachines: [],
                  remark: "",
                  isAlternative: true,
                  alternativeServiceId: serviceTreatmentDetailTemp.id
                }
      
                dismissMessage();
                setSelectedAppolousCustomerPackageRedeemDetail(_.cloneDeep(objAppolousCustomerPackageRedeemDetailTemp))
                setAppolousCustomerPackageRedeemDetailModal(true);
              }
              setLoadingAlternativeService(false);
            }
          }}/>
      }
    </div>
  );
}

export default observer(AppolousCustomerPackagePurchase);
