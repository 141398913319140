import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Constants } from '../../constants/Constants';
import GeneralInput from '../form/GeneralInput';
import * as Yup from "yup";
import { useFormik } from 'formik';
import _ from 'lodash';
import ArrowUpThinIcon from 'mdi-react/ArrowUpThinIcon';
import ArrowDownThinIcon from 'mdi-react/ArrowDownThinIcon';
import InformationIcon from 'mdi-react/InformationIcon';

export default observer(function HelpMenu() {
  //Use INTL
  const intl = useIntl();

  const { commonStore } = useStore();
  const { showHelpMenu, setShowHelpMenu } = commonStore;

  const aryHelpMenuDefault = [
    {
      name: intl.formatMessage({ id: "ERPUserGuide" }), 
      url: "https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/More-Guides.aspx"
    },
    {
      name: intl.formatMessage({ id: "ChangeRequest" }), 
      url: "https://onedocgroup.sharepoint.com/SitePages/Change-Request-Process-Flow.aspx"
    },
    {
      name: intl.formatMessage({ id: "ITAdjustment" }), 
      url: "https://onedocgroup.sharepoint.com/SitePages/IT-Adjustment-Process-Flow.aspx"
    },
    {
      name: intl.formatMessage({ id: "Bugs" }), 
      url: "https://onedocgroup.sharepoint.com/SitePages/Bug-Report-Process-Flow.aspx"
    },
    {
      name: intl.formatMessage({ id: "ReleaseNotes" }), 
      url: "https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Release-Note.aspx"
    },
  ]

  const [aryHelpMenu, setAryHelpMenu] = useState<any>([]);

  useEffect(()=> {
    setAryHelpMenu(aryHelpMenuDefault);
  }, [])

  const toggleHelpMenu = () => {
    setShowHelpMenu(!showHelpMenu);
  }

  const displayContent = (aryTemp) => {
    let aryView: any = [];
    aryTemp.map((valueTemp, index)=> {
      if (valueTemp.url) {
        aryView.push(
          <div 
            key={`share-point-details-${index}`} 
            className={`${index !== 0 ? "mt-3" : ""}`} 
            style={{
              boxShadow: "0 2px 4px rgba(0,0,0,.3)", 
              borderRadius: '8px', 
              padding: '14px'
            }}>
            <Link 
              to={{pathname: `${valueTemp.url}`}}
              target={Constants.supportBlank}
              className="text-info font-size-14">{valueTemp.name}
            </Link> 
          </div>
        )
      }
      else if (valueTemp.urlList) {
        let aryViewContent : any = [];
        valueTemp.urlList.map((valueURLListTemp, indexURLListTemp)=> {
          if (valueTemp.blnOpenTab) {
            aryViewContent.push(
              <div key={`share-point-${index}-${indexURLListTemp}`} className={`${indexURLListTemp === 0 ? "mt-3" : "mt-2"} font-size-13`}>
                <Link 
                  to={{pathname: `${valueURLListTemp.url}`}}
                  target={Constants.supportBlank}
                  className="text-info">{valueURLListTemp.name}
                </Link> 
              </div>
            )
          }
        })

        aryView.push(
          <div 
            key={`share-point-${index}`} 
            className={`${index !== 0 ? "mt-3" : ""} font-size-14`}
            style={{
              boxShadow: "0 2px 4px rgba(0,0,0,.3)", 
              borderRadius: '8px', 
              padding: '14px'
            }}>
            <div 
              className='flex-align-items-center-without-height pointer-clickable'
              onClick={()=> {
                let indexFound = _.findIndex(aryHelpMenu, {name: valueTemp.name})
                if (indexFound > -1) {
                  let aryHelpMenuTemp = _.cloneDeep(aryHelpMenu);
                  aryHelpMenuTemp[indexFound].blnOpenTab = !aryHelpMenuTemp[indexFound].blnOpenTab;
                  setAryHelpMenu(aryHelpMenuTemp);
                }
              }}>
              {valueTemp.name}
              {valueTemp.blnOpenTab ? <ArrowUpThinIcon color={"grey"} size={18}/> : <ArrowDownThinIcon color={"grey"} size={18}/>}
            </div>
            {aryViewContent}
          </div>
        )       
        
        if (index === 0 || index === 2) {
          aryView.push(<hr className='mt-4 mb-4' key={`dash-line_${index}`}/>)
        }
      }
    })
    return aryView;
  }

  return (
    <div key={`help-menu`}>
      <Offcanvas
        isOpen={showHelpMenu}
        direction="end"
        toggle={toggleHelpMenu}
        style={{backgroundColor: "#faf9f8", width: '450px'}}>
        <OffcanvasHeader toggle={toggleHelpMenu} style={{paddingBottom: '0px'}}>
          <div>
            {intl.formatMessage({ id: "Help" })}
            <InformationIcon className="text-primary margin-left-8 margin-bottom-4" id="viewtimeline" size={20}/>
          </div>
          <UncontrolledTooltip placement="top" target="viewtimeline">
            {intl.formatMessage({ id: "HelperMenuNote" })}
          </UncontrolledTooltip>
        </OffcanvasHeader>
        <OffcanvasBody>
          {
            displayContent(aryHelpMenu)
          }
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
})
