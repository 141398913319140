import { PaginationRequestBody } from "../models/pagination";
import { StockRequestAddObject, StockRequestDetailObject, StockRequestForProductDetailsObject, StockRequestListObject, StockRequestUpdateObject } from "../models/stockRequest";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
export default class StockRequestStore {
  stockRequestList: StockRequestListObject[] = [];
  stockRequestDetail: StockRequestDetailObject | undefined = undefined;
  stockRequestProductList : StockRequestForProductDetailsObject[] = [];
  stockRequestPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockRequestList = [];
    this.stockRequestDetail = undefined;
    this.stockRequestProductList = [];
    this.stockRequestPaginationParams = undefined;
  }

  setStockRequestList = (stockRequestList: StockRequestListObject[]) => {
    this.stockRequestList = stockRequestList;
  }
  
  setStockRequestDetail = (stockRequestDetail: StockRequestDetailObject | undefined) => {
    this.stockRequestDetail = stockRequestDetail;
  }

  setStockRequestProductList = (stockRequestProductList : StockRequestForProductDetailsObject[]) => {
    this.stockRequestProductList = stockRequestProductList;
  }

  setStockRequestPaginationParams = (stockRequestPaginationParams: PaginationRequestBody | undefined) => {
    this.stockRequestPaginationParams = stockRequestPaginationParams;
  }

  getStockRequest = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setStockRequestPaginationParams(PaginationRequestBody);
    try {
      const resultStockRequests = await agent.StockRequest.stockRequestList(PaginationRequestBody);
      runInAction(() => {
        this.stockRequestList = resultStockRequests.data;
        store.commonStore.setTotalItem(resultStockRequests.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockRequestList = [];
    }
  };

  getStockRequestWithId = async (id: string) => {
    try{
      const resultStockRequestDetail = await agent.StockRequest.stockRequestDetail(id);
      if (resultStockRequestDetail.stockRequestDetails) {
        resultStockRequestDetail.stockRequestDetails = resultStockRequestDetail.stockRequestDetails.map((valueStockRequestDetail) => ({
          ...valueStockRequestDetail,
          maxReorderQuantityDisplay: valueStockRequestDetail.maxReorderQuantity,
          measurementName: `${valueStockRequestDetail.measurementName}${valueStockRequestDetail.measurementName !== Constants.unit ? ` (${valueStockRequestDetail.unitConvertionRate} Unit)` : ""}`,
          // maxReorderQuantity: Math.trunc(valueStockRequestDetail.maxReorderQuantity/valueStockRequestDetail.unitConvertionRate)
          maxReorderQuantity: resultStockRequestDetail.requestType === Constants.outlet ? valueStockRequestDetail.maxReorderQuantity :  Math.trunc(valueStockRequestDetail.maxReorderQuantity/valueStockRequestDetail.unitConvertionRate),
          adviseOrderQuantity: resultStockRequestDetail.requestType === Constants.outlet ? valueStockRequestDetail.adviseOrderQuantity :  Math.trunc(valueStockRequestDetail.adviseOrderQuantity/valueStockRequestDetail.unitConvertionRate),
        }))
      }
      runInAction(() => {
        this.stockRequestDetail = resultStockRequestDetail;
      });
      return Promise.resolve(resultStockRequestDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockRequestDetail = undefined;
    }
  }

  addStockRequest = async (stockRequestRequestBody: StockRequestAddObject) => {
    try{
      await agent.StockRequest.addStockRequest(stockRequestRequestBody);
      store.commonStore.setSuccessMessage(`StockRequestAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockRequest = async (stockRequestRequestBody: StockRequestUpdateObject) => {
    try{
      await agent.StockRequest.updateStockRequest(stockRequestRequestBody);
      store.commonStore.setSuccessMessage(`StockRequestUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockRequest  = async (id: string, name: string) => {
    try {
      await agent.StockRequest.deleteStockRequest(id);
      store.commonStore.setSuccessMessage(`StockRequestDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getStockRequestProductList = async (id: string, purchaserType: string, productTypes: string[]) => {
    try{
      const resultStockRequestProductList  = await agent.StockRequest.stockRequestProductList(id, purchaserType, productTypes);
      runInAction(() => {
        this.stockRequestProductList = resultStockRequestProductList ;
      });
      return Promise.resolve(resultStockRequestProductList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockRequestProductList = [];
    }
  }
}
