import { useState } from "react";
import { FormFeedback, Modal } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";
import { PositionListObject } from "../../../app/models/position";
import { UserRoleListObject } from "../../../app/models/userRole";

interface Props {
  blnShow: boolean;
  setModal: Function;
  addEmployeePositionMovementHistory: Function;
  validation: any;
  setRefreshPostionMovementHistoryData: Function;
  positionDropdownList: PositionListObject[];
  userRoleDropdownList: UserRoleListObject[];
}

export default observer(function AddPositionMovementHistoryModal(props: Props) {
  const intl = useIntl();
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false);
  const [loadingAddPositionMovement, setLoadingAddPositionMovement] = useState(false);
  const disabledFieldInput = loadingAddPositionMovement || localSuccessMessage;

  const [errorState, setErrorState] = useState({
    position: false,
    role: false,
    startDate: false
  })

  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employeeId: props.validation.values.id,
      employeeName: props.validation.values.preferredName,
      positionId: "",
      roleId: "",
      startDate: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      position: false,
      role: false,
      startDate: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    props.setModal(false);
  }

  const fetchAddEmployeePositionMovementHistoryAPI = async () => {
    setLoadingAddPositionMovement(true);
    let resultAPI = await props.addEmployeePositionMovementHistory(validationLocal.values);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
        props.setRefreshPostionMovementHistoryData(true);
      }, Constants.timeoutNavigation)
    }
    setLoadingAddPositionMovement(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!loadingAddPositionMovement && !localSuccessMessage) {
          hideModal();
        }
      }}
      size='md'
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "AddPositionMovementHistory" })}</h5>
        {
          !loadingAddPositionMovement && !localSuccessMessage
          &&
          <button
            type="button"
            onClick={() => { hideModal(); }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <GeneralInput
          title={`${intl.formatMessage({ id: "Employee" })}`}
          name="employeeName"
          type="text"
          disabled={true}
          validation={validationLocal}
        />
        <DropDownWithTitle
          name={"positionId"}
          className={errorState.position ? "mb-0" : "mb-3"}
          title={intl.formatMessage({ id: "Position" })}
          specifyReturnFieldName={[
            {
              field: "positionId",
              value: "id",
            },
          ]}
          labelField={"name"}
          valueField={"id"}
          options={props.positionDropdownList}
          disabled={disabledFieldInput}
          disableMenuPortalTarget={true}
          validationRequired={true}
          validation={validationLocal}
        />
        {errorState.position ? (
          <FormFeedback className="margin-top--12 mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Position" })})}`}</FormFeedback>
        ) : null}
        <DropDownWithTitle
          name={"roleId"}
          className={errorState.position ? "mb-0" : "mb-3"}
          title={intl.formatMessage({ id: "Role" })}
          specifyReturnFieldName={[
            {
              field: "roleId",
              value: "id",
            },
          ]}
          labelField={"name"}
          valueField={"id"}
          blnValueWithNewSpace={true}
          options={props.userRoleDropdownList}
          disabled={disabledFieldInput}
          disableMenuPortalTarget={true}
          validationRequired={true}
          validation={validationLocal}
        />
        {errorState.role ? (
          <FormFeedback className="margin-top--12 mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Role" })})}`}</FormFeedback>
        ) : null}
        <GeneralInput
          title={`${intl.formatMessage({ id: "StartDate" })}`}
          name="startDate"
          className={errorState.startDate ? "mb-0" : "mb-3"}
          type="date"
          disabled={disabledFieldInput}
          validation={validationLocal}
        />
        {errorState.startDate ? (
          <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "StartDate" })})}`}</FormFeedback>
        ) : null}
        <div className="flex-right">
          {
            !Boolean(localSuccessMessage)
            &&
            <MyButton
              type="button"
              class="btn btn-success"
              style={{ width: '20%' }}
              content={intl.formatMessage({ id: "Confirm" })}
              onClick={() => {
                let errorStateTemp = {
                  position: false,
                  role: false,
                  startDate: false
                };
                let blnErrorFound = false;
                if (!validationLocal.values.positionId) {
                  errorStateTemp.position = true;
                  blnErrorFound = true;
                }
                if (!validationLocal.values.roleId) {
                  errorStateTemp.role = true;
                  blnErrorFound = true;
                }
                if (!validationLocal.values.startDate) {
                  errorStateTemp.startDate = true;
                  blnErrorFound = true;
                }
                
                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  clearErrorState();
                  fetchAddEmployeePositionMovementHistoryAPI()
                }
              }}
              loading={loadingAddPositionMovement}
              disable={loadingAddPositionMovement || Boolean(localSuccessMessage)}
            />
          }
        </div>
      </div>
    </Modal>
  )
});