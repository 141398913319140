import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { PaymentMethodOptionListObject } from '../../app/models/paymentMethodOption';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter, returnSwitchTabButtonUI } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import Loading from "../../app/components/loading/Loading";
import { PaginationRequestBody } from "../../app/models/pagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../app/components/form/GeneralInput";

const PaymentMethodOption = () => {
  const intl = useIntl();
  //Use Store
  const { paymentMethodOptionStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage, prevPath, windowSize } = commonStore;
  const { paymentMethodOptionList, paymentMethodOptionPaginationParams, getPaymentMethodOption, deletePaymentMethodOption, setPaymentMethodOptionPaginationParams } = paymentMethodOptionStore; 
  const { leadStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedPaymentMethodOptionType, setSelectedPaymentMethodOptionType] = useState<string>(AllType);
  const [selectedPaymentMethodOptionTypeId, setSelectedPaymentMethodOptionTypeId] = useState<number | string | undefined>(" ");
  const [paymentMethodOptionTypeList, setPaymentMethodOptionTypeList] = useState<StaticReferenceDataObject[]>([])
  const [selectedRow, setSelectedRow] = useState<PaymentMethodOptionListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const blnPermissionCreatePaymentMethodOption = checkPermission([PermissionConstants.CreatePaymentMethodOption]);
  const blnPermissionUpdatePaymentMethodOption = checkPermission([PermissionConstants.UpdatePaymentMethodOption]);
  const blnPermissionDeletePaymentMethodOption = checkPermission([PermissionConstants.DeletePaymentMethodOption]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let paymentMethodOptionRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    optionType: selectedPaymentMethodOptionTypeId,
    optionTypeName: selectedPaymentMethodOptionType,
    name: validation.values.name,
    ...!blnAllActive && {isActive: blnIsActive}
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchPaymentMethodOptionAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.paymentMethodOption)
      if (blnBackNavigation) {
        if (paymentMethodOptionPaginationParams) {
          setCurrentPage(paymentMethodOptionPaginationParams.pageNumber)
          setSelectedPaymentMethodOptionType(paymentMethodOptionPaginationParams.optionTypeName || "");
          setSelectedPaymentMethodOptionTypeId(paymentMethodOptionPaginationParams.optionType)
          validation.setValues((values)=> ({
            ...values,
            name: paymentMethodOptionPaginationParams.name || ""
          }))
          if(paymentMethodOptionPaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(paymentMethodOptionPaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          paymentMethodOptionRequestBody = paymentMethodOptionPaginationParams;
        }
      }
      else {
        setPaymentMethodOptionPaginationParams(undefined);
      }
      
      let aryAPI: any = [
        fetchPaymentMethodOption(false),
        getStaticReferenceWithType(Constants.paymentMethodOptionType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setPaymentMethodOptionTypeList(resultAPI[1]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePaymentMethodOption], true)) {
      return;
    }

    fetchPaymentMethodOptionAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.paymentMethodOption)
        if (!blnSetParams) {
          setPaymentMethodOptionPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedPaymentMethodOptionTypeId || selectedPaymentMethodOptionTypeId === 0) && !initialCallAPI) {
      fetchPaymentMethodOption(true);
    }
  }, [selectedPaymentMethodOptionTypeId, triggerSearch, blnAllActive, blnIsActive])
  
  async function fetchPaymentMethodOption(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPaymentMethodOption(paymentMethodOptionRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row : PaymentMethodOptionListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }
  
  const blnViewAllFunction = () => {
    setCurrentPage(1);
    setBlnAllActive(true);
  }

  const blnActiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(true);
    setBlnAllActive(false);
  }

  const blnInactiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(false);
    setBlnAllActive(false);
  }

  //Table Content UI
  const PaymentMethodOptionColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text:  intl.formatMessage({ id: "Name" }).toUpperCase()
    },
    {
      dataField: "optionType",
      text:  intl.formatMessage({ id: "OptionType" }).toUpperCase(),
      formatter: (cellContent, row) => <>{`${newSpaceBeforeCapitalLetter(row.optionType)}`}</>, //Custom UI to display
    },
    {
      dataField: "chargeRate",
      text:  intl.formatMessage({ id: "ChargeRate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{`${row.chargeRate}%`}</>, //Custom UI to display
    },
    {
      dataField: "isEPP",
      text:  intl.formatMessage({ id: "EPP" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isEPP ? <div className="rounded-circle green-circle"/> : <div className="rounded-circle red-circle"/>}
        </div>
      ),
    },
    {
      dataField: "isActive",
      text:  intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle"/> : <div className="rounded-circle red-circle"/>}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePaymentMethodOption, PermissionConstants.UpdatePaymentMethodOption, PermissionConstants.DeletePaymentMethodOption]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.paymentMethodOption}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdatePaymentMethodOption
              &&
              <TableActionButton 
                to={`/${RoutesList.paymentMethodOption}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeletePaymentMethodOption
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentMethodOption" }) })}
        title={intl.formatMessage({ id: "PaymentMethodOption" })}
        addButton={
          blnPermissionCreatePaymentMethodOption
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PaymentMethodOption" }) })}
            onClick={() => { history.push(`/${RoutesList.paymentMethodOption}/add`) }}
            linkTo={`/${RoutesList.paymentMethodOption}/add`}
            disable={loading} />
        }>
          {
            !initialCallAPI
            &&
              <Row>
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "OptionType" })}
                    name="paymentOptionMethodType"
                    options={paymentMethodOptionTypeList}
                    blnValueWithNewSpace={true}
                    labelField="displayValue"
                    valueField="key"
                    initialLabel={selectedPaymentMethodOptionType}
                    initialValue={selectedPaymentMethodOptionTypeId}
                    setFieldLabel={setSelectedPaymentMethodOptionType}
                    setFieldValue={setSelectedPaymentMethodOptionTypeId}
                    disabled={localLoading || loading}
                    validationRequired={true}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterName" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28": ""} ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "" : "margin-bottom-4"}`}>           
                <div className="btn-group" role="group">
                  {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                  {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), blnActiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                  {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), blnInactiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "90px" : "")}
                </div>
              </Col>
            </Row>
          }
          {
            localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PaymentMethodOptionColumns}
              data={paymentMethodOptionList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "PaymentMethodOption"})})}
              objSorted={defaultSorted}
              optionType={selectedPaymentMethodOptionTypeId}
              optionTypeName={selectedPaymentMethodOptionType}
              name={validation.values.name}
              isActive={!blnAllActive ? blnIsActive : undefined}
              requestAPI={getPaymentMethodOption}/>
          }
          <DeleteModal
            displayName={selectedRow?.name}
            onDeleteClick={async ()=> {
              setLoading(true);
              await deletePaymentMethodOption(selectedRow?.id || "", selectedRow?.name || "");
              let currentPageTemp = currentPage;
              if (paymentMethodOptionList.length === 1 && currentPage !== 1) {
                setCurrentPage((value)=> value-1)
                currentPageTemp = currentPageTemp-1;
              }
              await getPaymentMethodOption({pageNumber: currentPageTemp, pageSize: pageSize})
              setLoading(false);
            }}/>
        </ListViewLayout>
    </div>
  );
}

export default observer(PaymentMethodOption);
