import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, FormFeedback, Input, Row } from 'reactstrap';
import { useIntl } from "react-intl";
import GeneralInput from '../../../app/components/form/GeneralInput';
import _,{ map } from "lodash";
import { PaymentMethodForBranchObject } from '../../../app/models/branch';
import { CustomerPaymentDetailObject } from '../../../app/models/customerOrder';
import CustomerOrderPaymentModal from '../Modal/CustomerOrderPaymentModal';
import { StaticReferenceDataObject } from '../../../app/models/staticReference';
import { blockNegativeOrZeroPayment, calculateBalanceAmount, newSpaceBeforeCapitalLetter, returnCurrency, returnThousandSeperator } from '../../../app/common/function/function';
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import BookAccountIcon from 'mdi-react/BookAccountIcon';
import CashIcon from 'mdi-react/CashIcon';
import CreditCardCheckOutlineIcon from 'mdi-react/CreditCardCheckOutlineIcon';
import CreditCardOutlineIcon from 'mdi-react/CreditCardOutlineIcon';
import WeatherCloudyIcon from 'mdi-react/WeatherCloudyIcon';
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon';
import BookCheckOutlineIcon from 'mdi-react/BookCheckOutlineIcon';
import BankOutlineIcon from 'mdi-react/BankOutlineIcon';
import { Constants } from '../../../app/constants/Constants';
import { AvailableUnknownPaymentListObject } from '../../../app/models/customer';

interface Props {
  validation: any;
  disabledFieldInput: boolean;
  branchPaymentMethod: PaymentMethodForBranchObject[];
  availableUnknownPaymentList: AvailableUnknownPaymentListObject[];
  setAvailableUnknownPaymentList: Function;
  cardType: StaticReferenceDataObject[];
  paymentFieldName?: string;
  isTransferCreditEnabled: boolean;
  setErrorMessage: Function;
  getPaymentMethodOptionDropdown: Function;
}

export default function PaymentSubview (props: Props) {
  const intl = useIntl();
  const [customerOrderPaymentModal, setCustomerOrderPaymentModal] = useState(false);
  const [selectedCustomerPaymentDetail, setSelectedCustomerPaymentDetail] = useState<CustomerPaymentDetailObject | undefined>(undefined);
  const paymentDetailsFieldName = props.paymentFieldName || "paymentDetails";
  const [paymentMethodListFinal, setPaymentMethodListFinal] = useState<PaymentMethodForBranchObject[]>([]);
  const [disabledNormalPaymentMethod, setDisabledNormalPaymentMethod] = useState(false);
  const [disabledUnknownPaymentMethod, setDisabledUnknownPaymentMethod] = useState(false);

  useEffect(() => {
    if (props.branchPaymentMethod) {
      let paymentMethodListTemp:PaymentMethodForBranchObject[] = [];
      for (var a = 0; a < props.branchPaymentMethod.length; a++) {
        if(props.branchPaymentMethod[a].isTransferCredit){
          if(!props.isTransferCreditEnabled){
            continue;
          }else{
            paymentMethodListTemp.push({...props.branchPaymentMethod[a]})
          }
        }
        else if(props.branchPaymentMethod[a].isCustomerCredit){
          let indexCreditPackage = -1;
          if (props.validation.values.salesOrderDetails) {
            indexCreditPackage = _.findIndex(props.validation.values.salesOrderDetails, { isCreditPackage: true })
          }
          if(!props.validation.values.availableCredits || props.validation.values.availableCredits === 0 || indexCreditPackage > -1){
            continue;
          }else{
            paymentMethodListTemp.push({...props.branchPaymentMethod[a]})
          }
        }else{
          paymentMethodListTemp.push({...props.branchPaymentMethod[a]})
        }
      }
      setPaymentMethodListFinal(paymentMethodListTemp)
    }
  }, [props.branchPaymentMethod])

  useEffect(()=> {
    if (props.validation.values[paymentDetailsFieldName]) {
      if (props.validation.values[paymentDetailsFieldName].length > 0) {
        let blnUnknownPaymentFound = false;
        let blnNormalPaymentFound = false;
        for (let indexPaymentTemp=0; indexPaymentTemp<props.validation.values[paymentDetailsFieldName].length; indexPaymentTemp++) {
          if (props.validation.values[paymentDetailsFieldName][indexPaymentTemp].unknownPaymentId) {
            blnUnknownPaymentFound = true;
          }
          if (!props.validation.values[paymentDetailsFieldName][indexPaymentTemp].unknownPaymentId && !props.validation.values[paymentDetailsFieldName][indexPaymentTemp].isCustomerCredit) {
            blnNormalPaymentFound = true;
          }
        }
        setDisabledNormalPaymentMethod(blnUnknownPaymentFound && !blnNormalPaymentFound);
        setDisabledUnknownPaymentMethod(!blnUnknownPaymentFound && blnNormalPaymentFound);
      }
      else {
        setDisabledNormalPaymentMethod(false);
        setDisabledUnknownPaymentMethod(false);
      }
    }
    else {
      setDisabledNormalPaymentMethod(false);
      setDisabledUnknownPaymentMethod(false);
    }
  }, [props.validation.values[paymentDetailsFieldName]])

  const displayPaymentMethod = (value, index) => {
    let disablePaymentMethodFunctionTemp = !value.isCustomerCredit ? disabledNormalPaymentMethod : false;
    let iconTemp : any = returnPaymentIcon(String(value.name).toLowerCase(), disablePaymentMethodFunctionTemp);

    return (
      <Col xs="12" sm="6" md="4" xl="4" key={value.name}>
        <Card style={{borderRadius: '12px'}}>
          <div className="">
            <label className="card-radio-label mb-0">
              <Input
                type="radio"
                id={value.name}
                name="paymentMethod"
                checked={props.validation.values.paymentMethod == value.name}
                className="card-radio-input"
                disabled={disablePaymentMethodFunctionTemp}
                onChange={event=> {
                  // if ((value.isCustomerCredit || value.isTransferCredit) && props.validation.values.paidNumber < 1) {
                  //   return;
                  // }
                  if (props.validation.values.paidNumber || props.validation.values.paidNumber === 0) {
                    //check is payment = 0 or negative
                    if(!blockNegativeOrZeroPayment(props.validation.values.paidNumber, intl)){
                      let paymentDetailsTemp : any = _.cloneDeep(props.validation.values[paymentDetailsFieldName]);
                      let objPaymentDetails : CustomerPaymentDetailObject = {
                        paymentMethodId: value.id,
                        paymentMethodName: value.name,
                        paidAmount: props.validation.values.paidNumber,
                        isOptionAvailable: value.isOptionAvailable,
                        paymentMethodOptionType: value.optionType,
                        isQRPay: value.isQRPay,
                        isCardInput: value.isCardInput,
                        isCustomerCredit: value.isCustomerCredit,
                        isTransferCredit: value.isTransferCredit
                      }
  
                      if ((value.optionType && value.optionType !== Constants.none) || value.isQRPay || value.isCardInput) {
                        setSelectedCustomerPaymentDetail(objPaymentDetails);
                        setCustomerOrderPaymentModal(true);
                        return; 
                      }
  
                      paymentDetailsTemp.push(objPaymentDetails);
                      let prefillPaidNumber : any = calculateBalanceAmount(props.validation, props.paymentFieldName ? "customerPaymentDetails" : "paymentDetails", "totalPrice")
                      let availableCreditsTemp = props.validation.values?.availableCredits || 0;
                      if (value.isCustomerCredit) {
                        if (availableCreditsTemp < Number(props.validation.values.paidNumber)) {
                          props.setErrorMessage(intl.formatMessage({id: "CustomerCreditOverExceed"}))
                          return;
                        }
                        else {
                          availableCreditsTemp = (availableCreditsTemp - props.validation.values.paidNumber).toFixed(2);
                        }
                      }
                      props.validation.setValues((values) => ({
                        ...values,
                        paidNumber: prefillPaidNumber,
                        [paymentDetailsFieldName]: paymentDetailsTemp,
                        paymentMethod: "",
                        ...values.availableCredits && { availableCredits : availableCreditsTemp}
                      }))
                      return;
                    }
                  }
                  props.validation.setFieldValue("paymentMethod", "");
                }}
              />
              <div 
                className="card-radio" 
                style={{
                  borderRadius: '10px',
                  backgroundColor: disablePaymentMethodFunctionTemp ? Constants.lightgreyfaded : ""
                }}>
                <div>
                  {iconTemp}
                  <p>{value.name} <strong>
                  <br />
                  {value.isCustomerCredit && props.validation.values.availableCredits ? `(${returnThousandSeperator(props.validation.values.availableCredits)})` : ""}</strong></p>
                </div>
              </div>
            </label>                  
          </div>
        </Card>
      </Col>
    )
  }

  const displayAvailableUnknownPaymentMethod = (value, index) => {
    let disableUnknownPaymentMethodFunctionTemp = disabledUnknownPaymentMethod;
    let iconTemp : any = returnPaymentIcon(String(value.paymentMethodName).toLowerCase(), disableUnknownPaymentMethodFunctionTemp);
    
    return (
      <Col xs="12" sm="6" md="4" xl="4" key={`${value.paymentMethodName}_${index}_unknown_payment`}>
        <Card style={{borderRadius: '12px'}}>
          <div className="">
            <label className="card-radio-label mb-0">
              <Input
                type="radio"
                id={value.paymentMethodName}
                name="paymentMethod"
                checked={props.validation.values.paymentMethod == value.paymentMethodName}
                className="card-radio-input"
                disabled={disableUnknownPaymentMethodFunctionTemp}
                onChange={event=> {
                  if (props.validation.values.paidNumber || props.validation.values.paidNumber === 0) {
                    if(!blockNegativeOrZeroPayment(props.validation.values.paidNumber, intl)){
                      let paymentDetailsTemp : any = _.cloneDeep(props.validation.values[paymentDetailsFieldName]);
                      let objPaymentDetails : CustomerPaymentDetailObject = {
                        paymentMethodId: value.id,
                        paymentMethodName: value.paymentMethodName,
                        paidAmount: props.validation.values.paidNumber,
                        last4CardDigit: value.last4CardDigit,
                        last4CardDigitNo: value.last4CardDigitNo,
                        cardType: value.cardType,
                        paymentMethodOptionName: value.optionName,
                        transactionNo: value.transactionNo,
                        unknownPaymentId: value.unknownPaymentId,
                        paymentIssuerBankId: value.paymentIssuerBankId,
                        paymentIssuerBankName: value.paymentIssuerBankName,
                      }
  
                      paymentDetailsTemp.push(objPaymentDetails);
                      let prefillPaidNumber : any = calculateBalanceAmount(props.validation, props.paymentFieldName ? "customerPaymentDetails" : "paymentDetails", "totalPrice")
                      let availableAmountTemp = value.availableAmount || 0;
                      if (availableAmountTemp < Number(props.validation.values.paidNumber)) {
                        props.setErrorMessage(intl.formatMessage({id: "AvailableUnknownPaymentOverExceed"}))
                        return;
                      }
                      else {
                        availableAmountTemp = (availableAmountTemp - props.validation.values.paidNumber).toFixed(2);
                      }
                      
                      let availableUnknownPaymentListTemp = _.cloneDeep(props.availableUnknownPaymentList || []);
                      availableUnknownPaymentListTemp[index].availableAmount = availableAmountTemp;
                      if (props.setAvailableUnknownPaymentList) {
                        props.setAvailableUnknownPaymentList(availableUnknownPaymentListTemp);
                      }

                      props.validation.setValues((values) => ({
                        ...values,
                        paidNumber: prefillPaidNumber,
                        [paymentDetailsFieldName]: paymentDetailsTemp,
                        paymentMethod: "",
                      }))
                      return;
                    }
                  }
                  props.validation.setFieldValue("paymentMethod", "");
                }}
              />
              <div 
                className="card-radio" 
                style={{
                  borderRadius: '10px', 
                  borderColor: !disableUnknownPaymentMethodFunctionTemp ? Constants.lightpink : "",
                  backgroundColor: disableUnknownPaymentMethodFunctionTemp ? Constants.lightgreyfaded : ""
                }}>
                <div>
                  {iconTemp}
                  <p>{value.paymentMethodName} <strong>
                  <br />
                  {`(${returnThousandSeperator(value.availableAmount)})`}</strong></p>
                </div>
              </div>
            </label>                  
          </div>
        </Card>
      </Col>
    )
  }

  const returnPaymentIcon = (valueName: string, disabledText) => {
    let paymentIconDefaultClassName = `text-primary align-middle me-2 mt-2`;
    let paymentIconClassName = `${!disabledText ? "text-primary" : "text-secondary"} align-middle me-2 mt-2`

    if (valueName.includes("qr")) {
      return <QrcodeIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("cheque")) {
      return <BookAccountIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("cash")){
      return <CashIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("credit card")){
      return <CreditCardCheckOutlineIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("card")){
      return <CreditCardOutlineIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("online")){
      return <WeatherCloudyIcon size={24} className={paymentIconClassName}/>;
    }
    else if (valueName.includes("customer")) {
      return <DiamondStoneIcon size={24} className={paymentIconDefaultClassName}/>;
    }
    else if (valueName.includes("transfer credit")) {
      return <BookCheckOutlineIcon size={24} className={paymentIconDefaultClassName}/>;
    }
    else {
      return <BankOutlineIcon size={24} className={paymentIconClassName}/>;
    }
  }
  
  return (
    <div>
      <GeneralInput
        title={intl.formatMessage({id: "Pay"}).toUpperCase()}
        name="paidNumber"
        className={props.validation.touched[paymentDetailsFieldName] && props.validation.errors[paymentDetailsFieldName] ? "mb-0" : "mb-3"}
        type="number"
        placeholder="0"
        inputGroup={true}
        inputGroupText={returnCurrency()}
        validationRequired={true}
        disabled={props.disabledFieldInput}
        validation={props.validation}
      />
      {props.validation.touched[paymentDetailsFieldName] && props.validation.errors[paymentDetailsFieldName] ? (
        <FormFeedback className="mb-3" type="invalid">{props.validation.errors[paymentDetailsFieldName]}</FormFeedback>
      ) : null}
      <Row>
        {map(paymentMethodListFinal, (value, index) => (
          displayPaymentMethod(value, index)
        ))}
        {
          props.availableUnknownPaymentList
          &&
          map(props.availableUnknownPaymentList, (value, index) => (
            displayAvailableUnknownPaymentMethod(value, index)
          ))
        }
      </Row>
      {
        customerOrderPaymentModal
        &&
        <CustomerOrderPaymentModal
          blnShow={customerOrderPaymentModal}
          setModal={setCustomerOrderPaymentModal}
          selectedCustomerOrderDetail={selectedCustomerPaymentDetail}
          setSelectedCustomerPaymentDetail={setSelectedCustomerPaymentDetail}
          cardType={props.cardType}
          validation={props.validation}
          paymentFieldName={paymentDetailsFieldName}
          getPaymentMethodOptionDropdown={props.getPaymentMethodOptionDropdown}/>
      }
    </div>
  )
}
