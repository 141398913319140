import { useEffect, useState } from "react";
import {FormFeedback, Label, Modal} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";
import { AppolousCustomerProductListObject, AppolousCustomerProductRedeemRequestBody } from "../../../../app/models/appolousCustomerProduct";
import { checkPermission, getBranchId } from "../../../../app/common/function/function";
import GeneralTextAreaForModal from "../../../../app/components/form/GeneralTextAreaForModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import SignaturePad from "../../../../app/components/form/SignaturePad";
import { RoutesList } from "../../../../app/constants/RoutesList";
import { history } from "../../../..";
import { ProductForBranchObject } from "../../../../app/models/branch";
import GeneralSelectDropdown from "../../../../app/components/form/GeneralSelectDropdown";
import { PermissionConstants } from "../../../../app/constants/PermissionConstants";
import moment from "moment";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId?: string;
  currentPage?: number;
  setCurrentPage?: Function;
  redeemFunction: Function;
  getAppolousCustomerProducts?: Function;
  selectedAppolousCustomerProductRedeemDetail: AppolousCustomerProductListObject | undefined;
  setSelectedAppolousCustomerProductRedeemDetail: Function;
  setErrorMessage?: Function;
  setLoadingAppolousCustomerProductRedeem?: Function;
  setAlternativeProductMessageModal: Function;
  localBranchProductList: ProductForBranchObject[];
}

export default function AppolousCustomerProductRedeemModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [localLoadingAppolousCustomerProductRedeem, setLocalLoadingAppolousCustomerProductRedeem] = useState(false);
  const blnPermissionBackdateAppolousRedemption = checkPermission([PermissionConstants.BackdateAppolousRedemption]);

  const defaultAppolousCustomerProductRedeemDetail = {
    branchId: getBranchId(),
    productName: "",
    id: "",
    signatureImage: "",
    quantity: 0,
    remark: "",
    isAlternative: false,
    isBackdate: false,
    submissionDate: "",
    isManual:  false,
    alternativeProductId: ""
  }

  const [errorState, setErrorState] = useState({
    quantity: false,
  })

  const [appolousCustomerProductRedeemDetail, setAppolousCustomerProductRedeemDetail] = useState(defaultAppolousCustomerProductRedeemDetail)
  const disabledFieldInput = localLoadingAppolousCustomerProductRedeem || Boolean(successMessage);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      signatureImage: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    if (props.selectedAppolousCustomerProductRedeemDetail) {
      setAppolousCustomerProductRedeemDetail((appolousCustomerProductRedeemDetailTemp)=> ({
        ...appolousCustomerProductRedeemDetailTemp,
        productName: props.selectedAppolousCustomerProductRedeemDetail!.productName,
        id: props.selectedAppolousCustomerProductRedeemDetail!.id,
        isAlternative: props.selectedAppolousCustomerProductRedeemDetail?.isAlternative || false
      }));
    }
  }, [props.selectedAppolousCustomerProductRedeemDetail])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerProduct}/view/${props.selectedAppolousCustomerProductRedeemDetail?.id}`);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      quantity: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setAppolousCustomerProductRedeemDetail(defaultAppolousCustomerProductRedeemDetail);
    props.setSelectedAppolousCustomerProductRedeemDetail(undefined);
    validation.resetForm();
    props.setModal(false);
  }

  const onClickRedeemAppolousCustomerProduct = async (appolousCustomerProductRedeemDetailTemp: AppolousCustomerProductRedeemRequestBody) => {
    setLocalLoadingAppolousCustomerProductRedeem(true);
    let resultRedeemProduct = await props.redeemFunction(appolousCustomerProductRedeemDetailTemp);
    if (resultRedeemProduct.status === Constants.failed) {
      if (resultRedeemProduct.message === "Appolous-026" || resultRedeemProduct.message === "Appolous-035") {
        props.setAlternativeProductMessageModal(true);
      }
    }
    setLocalLoadingAppolousCustomerProductRedeem(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Redeem" })} {intl.formatMessage({ id: "AppolousCustomerProduct" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            appolousCustomerProductRedeemDetail.isAlternative
            ?
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "AlternativeProduct" })}
              name="alternativeProductId"
              styles={{border: "1px solid green", borderColor: 'green', borderRadius: '8px', padding: '8px'}}
              options={props.localBranchProductList}
              labelField="productName"
              valueField="productId"
              blnValueWithNewSpace={true}
              blnReturnFullValue={true}
              disabled={disabledFieldInput}
              validationRequired={true}
              onChange={(valueProduct) => {
                setAppolousCustomerProductRedeemDetail((valueAppolousCustomerProductRedeemDetail)=> ({
                  ...valueAppolousCustomerProductRedeemDetail,
                  alternativeProductId: valueProduct.productId
                }))
              }}
            />
            :
            <GeneralInputForModal
              title={intl.formatMessage({ id: "ProductName" })}
              name="productName"
              className={"mb-3"}
              type="text"
              validationRequired={true}
              field={appolousCustomerProductRedeemDetail}
              setFieldValue={setAppolousCustomerProductRedeemDetail}
              disabled={true}
            />
          }
          <GeneralInputForModal
            title={intl.formatMessage({ id: "Quantity" })}
            name="quantity"
            className={errorState.quantity ? "mb-0" : "mb-3"}
            type="number"
            validationRequired={true}
            field={appolousCustomerProductRedeemDetail}
            setFieldValue={setAppolousCustomerProductRedeemDetail}
            onChangeFunction={()=> {clearErrorState("quantity")}}
            numberMaxValue={props.selectedAppolousCustomerProductRedeemDetail?.balanceQuantity}
            disabled={disabledFieldInput}
            childrenUI={<p className="text-muted margin-bottom-0 mt-1">{`${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedAppolousCustomerProductRedeemDetail?.balanceQuantity}`}</p>}/>
          {errorState.quantity ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" })})}</FormFeedback>
          ) : null}
          <GeneralTextAreaForModal
            title={intl.formatMessage({ id: "Remark" })}
            name="remark"
            row={3}
            field={appolousCustomerProductRedeemDetail}
            setFieldValue={setAppolousCustomerProductRedeemDetail} 
            disabled={disabledFieldInput}/>
          {
            blnPermissionBackdateAppolousRedemption
            &&
            <>
              <GeneralInputForModal
                title={""}
                name="isBackdate"
                className="margin-bottom--12 margin-top--16"
                type="checkbox"
                field={appolousCustomerProductRedeemDetail}
                setFieldValue={setAppolousCustomerProductRedeemDetail}
                disabled={disabledFieldInput}
                validationRequired={false}
                onChangeFunctionWithValueReturn={(valueCheckBox)=> {
                  setAppolousCustomerProductRedeemDetail((appolousCustomerProductRedeemDetailTemp)=> ({
                    ...appolousCustomerProductRedeemDetailTemp,
                    submissionDate: valueCheckBox ? moment().format(Constants.textInputDateAndTimeFormat) : "",
                  }));
                }}
                childrenUI={
                  <Label className="margin-bottom-0 margin-left-4">
                    {intl.formatMessage({ id: "IsApplyRedemptionSubmissionDate" })}
                  </Label>
                }
              />
              {
                appolousCustomerProductRedeemDetail.isBackdate
                &&
                <GeneralInputForModal
                  title={""}
                  name="submissionDate"
                  className="mt-0"
                  placeholder={intl.formatMessage({ id: "RedemptionSubmissionDate" })}
                  type="datetime-local"
                  disabled={validation.isSubmitting}
                  validationRequired={true}
                  field={appolousCustomerProductRedeemDetail}
                  setFieldValue={setAppolousCustomerProductRedeemDetail}
                />
              }
            </>
          }
          <SignaturePad
            className="margin-top--12"
            successMessage={successMessage}
            validation={validation}
            disabled={disabledFieldInput}
            isSubmitting={disabledFieldInput} />
          <div className="float-end">
            {
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-success mt-4"
                content={intl.formatMessage({ id: "Redeem" })}
                onClick={() => {
                  let errorStateTemp = {
                    quantity: false,
                  };
                  let blnErrorFound = false;
                  if (appolousCustomerProductRedeemDetail.quantity < 1) {
                    errorStateTemp.quantity = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickRedeemAppolousCustomerProduct({...appolousCustomerProductRedeemDetail, signatureImage: validation.values.signatureImage});
                  }
                }}
                loading={localLoadingAppolousCustomerProductRedeem}
                disable={disabledFieldInput || validation.values.signatureImage === ""}
              />
            }
          </div>
        </div>
      </Modal>
  )
}