import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts";
import { DatumObject, RowDataObject } from "../../../models/dashboard";

interface Props {
	data?: DatumObject[];
  rowData?: RowDataObject;
  displayCurrency?: boolean;
}

const BarChart = (props: Props) => {
  const series : any = [
    {
      name: "",
      type: "column",
      data: props.data ? props.data.length > 0 ? props.data[0].data : [] :[],
    }
  ]
  const options : any = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#f46a6a", "#556ee6", "#34c38f"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: props.rowData?.categories || [],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      type: "string",
      labels: {
        formatter: function(val) {
          return val ? val.split('\n') : val;
        }
      }
    },
    yaxis: {
      title: {
        text: props.data ? props.data.length > 0 ? props.data[0].name : "" : "",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="220"    
    />
  )
}

export default BarChart