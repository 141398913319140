import React, { useEffect, useState } from 'react'
import {
  Modal,
  Row,
  Col,
  Label
} from "reactstrap";
import _ from 'lodash';
import { AddOptionsValueField, getBranchId, promptInfoMessage, redemptionCalculation } from "../../../app/common/function/function";
import GeneralInputForModal from '../../../app/components/form/GeneralInputForModal';
import { ProductBatchObject, RedeemPurchaseDetailObject } from "../../../app/models/customerOrder";
import { EmployeeListObject } from "../../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import MyButton from '../../../app/components/form/MyButton';
import GeneralSelectDropdown from '../../../app/components/form/GeneralSelectDropdown';
import { useIntl } from "react-intl";
import GeneralTextAreaForModal from '../../../app/components/form/GeneralTextAreaForModal';
import TableWithEditableInputFields from '../../../app/components/table/TableWithEditableInputFields';
import { useFormik } from 'formik';
import { ObjectForTableWithDynamicInputAndCheckBox } from '../../../app/models/common';
import { BranchProductBatchListObject } from '../../../app/models/branch';
import Loading from '../../../app/components/loading/Loading';
import GeneralCheckBoxWithDropdownFormat from '../../../app/components/dropdown/GeneralCheckBoxWithDropdownFormat';
import { Constants } from '../../../app/constants/Constants';

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerPurchaseDetail: RedeemPurchaseDetailObject | undefined;
  setSelectedCustomerPurchaseDetail: Function;
  productRedemptionEntitlement: Function;
  employeeList: EmployeeListObject[];
  customerPurchaseList: any;
  validation: any;
  blnDisableModalView?: boolean;
  loadingDisableModalView?: boolean;
  extraTopUI?: any;
  blnPromptInfoMessage: boolean;
}

const CustomerPurchaseDetailModal = (props: Props) => {
  const intl = useIntl();
  //Use Store
  const { commonStore, branchStore } = useStore();
  const {
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage
  } = commonStore;
  const { getBranchProductBatchLegacy } = branchStore;
  const optionsEmployeeList = AddOptionsValueField(props.employeeList, "preferredName", "id");
  const blnTab = props?.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 0  || props?.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 3 ? 1 : props?.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 1 ? 2 : 3;
  const [productBatchOptions, setProductBatchOptions] = useState<BranchProductBatchListObject[]>([]);
  const fieldsOfProductBatchDetails : ProductBatchObject = { referenceNo: "", productBatchId: "", maximumQuantity: 0, quantity: 0};
  const [reloadTable, setReloadTable] = useState(false);
  const [loadingProductBatch, setLoadingProductBatch] = useState(false);
  const productBatchOnChange = () => {
    setReloadTable(true);
  }
  const aryDynamicInputAndCheckBoxTabProductBatch: ObjectForTableWithDynamicInputAndCheckBox[] = [
    // {
    //   label: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
    //   type: "select",
    //   xl: "6",
    //   inputName: "referenceNo",
    //   inputType: "select",
    //   options: AddOptionsValueField(productBatchOptions, "referenceNo", "referenceNo"),
    //   displayLabel: "referenceNo",
    //   displayValue: "referenceNo",
    //   specifyReturnFieldName: [{ field: "productBatchId", value: "id" }, { field: "referenceNo", value: "referenceNo" }],
    //   validationRequired: true,
    //   disabled: true,
    //   onChangeFunction: productBatchOnChange
    // },
    {
      label: intl.formatMessage({ id: "StockAvailable" }).toUpperCase(),
      type: "input",
      xl: "6",
      inputName: "maximumQuantity",
      inputType: "number",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      type: "input",
      xl: "6",
      inputName: "quantity",
      numberMaxFieldName: "maximumQuantity",
      inputType: "number"
    },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { productBatchDetails: [fieldsOfProductBatchDetails] },
    // validationSchema: Yup.object({}),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    async function getProductBatch() {
      if (props.selectedCustomerPurchaseDetail) {
        setLoadingProductBatch(true);
        // const resultProductBatch = await getBranchProductBatch({productId: props.selectedCustomerPurchaseDetail.customerPurchaseDetailId, branchId: getBranchId(), isRequiredCheckQuantity: true})
        const resultProductBatch = await getBranchProductBatchLegacy({productId: props.selectedCustomerPurchaseDetail.customerPurchaseDetailId, branchId: getBranchId(), isRequiredCheckQuantity: false})
        if (resultProductBatch) {
          setProductBatchOptions(resultProductBatch);
        }
        let blnProductBatchFound = false;
        if (props.selectedCustomerPurchaseDetail) {
          if (props.selectedCustomerPurchaseDetail.productBatchDetails) {
            if (props.selectedCustomerPurchaseDetail.productBatchDetails.length > 0) {
              setReloadTable(true);
              blnProductBatchFound = true;
              validation.setFieldValue("productBatchDetails", props.selectedCustomerPurchaseDetail.productBatchDetails)
              setReloadTable(false);
            }
          }
        }
        if (!blnProductBatchFound) {
          if (resultProductBatch) {
            if (resultProductBatch.length > 0) {
              setReloadTable(true);
              let aryProductBatchDetailsTemp = [{ 
                referenceNo: resultProductBatch[0].referenceNo, 
                productBatchId: resultProductBatch[0].id, 
                maximumQuantity: resultProductBatch[0].actualQuantity, 
                quantity: 0
              }]
              validation.setFieldValue("productBatchDetails", aryProductBatchDetailsTemp);
              setReloadTable(false);
            }
          }
        }
        setLoadingProductBatch(false);
      }
    }
    
    if (blnTab === 2 || blnTab === 3) {
      getProductBatch();
    }
  }, [])

  const disabledFieldInput = Boolean(successMessage) || props.loadingDisableModalView ? true : false;

  useEffect(()=> {
    if (validation.values.productBatchDetails && reloadTable) {
      setReloadTable(true);
      let productBatchOptionsTemp = _.cloneDeep(productBatchOptions);
      productBatchOptionsTemp = productBatchOptionsTemp.map((valueProductBatchOptionsTemp)=> ({
        ...valueProductBatchOptionsTemp,
        disabled: false
      }))
      const resultProductBatchDetailsTemp = validation.values.productBatchDetails.map((valueProductBatchDetailsTemp: any)=> {
        let indexProductBatch = _.findIndex(productBatchOptionsTemp, {id: valueProductBatchDetailsTemp.productBatchId})
        if (indexProductBatch > -1) {
          productBatchOptionsTemp[indexProductBatch] = {...productBatchOptions[indexProductBatch], disabled: true}
          let currentKeyInQuantityTemp = valueProductBatchDetailsTemp.quantity;
          let maximumQuantityTemp = productBatchOptions[indexProductBatch].actualQuantity;
          let objTemp = {...valueProductBatchDetailsTemp, maximumQuantity: maximumQuantityTemp, quantity: currentKeyInQuantityTemp > maximumQuantityTemp ? maximumQuantityTemp : currentKeyInQuantityTemp}
          return (objTemp)
        }
        return ({...valueProductBatchDetailsTemp, maximumQuantity: 0, quantity: 0})
      })
      setProductBatchOptions(productBatchOptionsTemp);
      validation.setFieldValue("productBatchDetails", resultProductBatchDetailsTemp)
    }
    setReloadTable(false)
  }, [validation.values.productBatchDetails])

  const hideModal = () => {
    if (!props.blnDisableModalView) {
      props.setModal(false);
      props.setSelectedCustomerPurchaseDetail(undefined);
    }
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  const onChangeEmployee = (e: any) => {
    let selectedCustomerPurchaseDetail = _.cloneDeep(props.selectedCustomerPurchaseDetail)
    if (selectedCustomerPurchaseDetail) {
      selectedCustomerPurchaseDetail.serviceByName = e.label;
      selectedCustomerPurchaseDetail.serviceById = e.value;
      if (selectedCustomerPurchaseDetail.validationRequired) {
        delete selectedCustomerPurchaseDetail["validationRequired"];
      }
      props.setSelectedCustomerPurchaseDetail(selectedCustomerPurchaseDetail);
    }
  }

  const displayContent = () => {
    return (
      <div className={props.blnDisableModalView ?  "standard-layout" : ""}>
        <div className="modal-header">
          <div>
            <h5 className="modal-title margin-top-0">{props.selectedCustomerPurchaseDetail?.name} {`(${props.selectedCustomerPurchaseDetail?.customerInvoiceNo})`}</h5>
            <span style={{ fontWeight: 400, fontSize: '13px', color: 'grey' }}>
              {
                (props.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 0 || props.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 3)
                  ?
                  `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedCustomerPurchaseDetail?.availableSessions}`
                  :
                  props.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 1
                    ?
                    `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedCustomerPurchaseDetail?.availableQuantity}`
                    :
                    props.selectedCustomerPurchaseDetail?.purchaseDetailRedeemType === 2
                      ?
                      `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedCustomerPurchaseDetail.quantity! - props.selectedCustomerPurchaseDetail.redeemdedQuantity!}`
                      :
                      `(${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "LeftOver" })} ${intl.formatMessage({ id: "OptionalProductCost" })} : ${props.selectedCustomerPurchaseDetail?.optionalAvailableProductCost!})`
              }
            </span>
          </div>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {props.blnDisableModalView && <hr/>}
        {props.extraTopUI ? props.extraTopUI() : <div/>}
        <div className="modal-body">
          {
            loadingProductBatch
            ?
            <Loading />
            :
            <>
              {
                blnTab === 1 //1 == Service
                &&
                <>
                  <Row>
                    <Col xl="12">
                      <GeneralInputForModal
                        title={`${intl.formatMessage({ id: blnTab === 1 ? "Sessions" : "Quantity" })}`}
                        name="count"
                        type="number"
                        field={props.selectedCustomerPurchaseDetail}
                        setFieldValue={props.setSelectedCustomerPurchaseDetail}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={"12"}>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "ServiceBy"})}
                        name=""
                        className="mb-3"
                        options={optionsEmployeeList}
                        onChange={onChangeEmployee}
                        initialLabel={props.selectedCustomerPurchaseDetail?.serviceByName || ""}
                        initialValue={props.selectedCustomerPurchaseDetail?.serviceById || ""}
                        blnReturnFullValue={true}
                        validationRequired={true}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </>
              }
              {
                blnTab === 1 && props?.selectedCustomerPurchaseDetail?.mandatoryProducts
                &&
                (
                  props?.selectedCustomerPurchaseDetail?.mandatoryProducts.length > 0
                    ?
                    <DropDownWithTitleMultiSelect
                      name={"mandatoryProducts"}
                      title={intl.formatMessage({ id: "MandatoryProducts" })}
                      specifyReturnFieldName={[{ "field": "label", "value": "value" }]}
                      returnFieldWithLabel={false}
                      labelField={"label"}
                      valueField={"value"}
                      initialValue={props?.selectedCustomerPurchaseDetail?.mandatoryProductsUsed || []}
                      options={props?.selectedCustomerPurchaseDetail?.mandatoryProducts}
                      disabled={true}
                      validation={props.validation}
                      field={props.selectedCustomerPurchaseDetail}
                      setFieldValue={props.setSelectedCustomerPurchaseDetail} />
                    :
                    null
                )
              }
              <Row>
                {
                  blnTab === 1 && props?.selectedCustomerPurchaseDetail?.availableOptionalProducts
                  &&
                  (
                    props?.selectedCustomerPurchaseDetail?.availableOptionalProducts.length > 0
                      ?
                      <Col xl="6" xs="12">
                        <GeneralCheckBoxWithDropdownFormat
                          name={"serviceProductUsed"}
                          title={intl.formatMessage({ id: "OptionalProducts" })}
                          labelField={"label"}
                          valueField={"value"}
						              sectionHeaderField={"categoryName"}
                          initialValue={props?.selectedCustomerPurchaseDetail?.serviceProductUsed || []}
                          options={props?.selectedCustomerPurchaseDetail?.availableOptionalProducts}
                          disabled={disabledFieldInput}
                          field={props.selectedCustomerPurchaseDetail}
                          setFieldValue={props.setSelectedCustomerPurchaseDetail} />
                      </Col>
                      :
                      null
                  )
                }
                {
                  blnTab === 1 && props?.selectedCustomerPurchaseDetail?.availableOptionalBranchMachines
                  &&
                  (
                    props?.selectedCustomerPurchaseDetail?.availableOptionalBranchMachines.length > 0
                      ?
                      <Col xl="6" xs="12">
                        <GeneralCheckBoxWithDropdownFormat
                          name={"optionalBranchMachinesUsed"}
                          title={intl.formatMessage({ id: "BranchMachines" })}
                          labelField={"label"}
                          valueField={"value"}
                          blnInputUI={true}
                          inputField={"consumeQuantity"}
                          inputType={"number"}
                          blnNumberOnly={true}
                          initialValue={props?.selectedCustomerPurchaseDetail?.optionalBranchMachinesUsed || []}
                          options={props?.selectedCustomerPurchaseDetail?.availableOptionalBranchMachines}
                          disabled={disabledFieldInput}
                          field={props.selectedCustomerPurchaseDetail}
                          setFieldValue={props.setSelectedCustomerPurchaseDetail} />
                      </Col>
                      :
                      null
                  )
                }
              </Row>
              {
                (blnTab === 2 || blnTab === 3)
                &&
                !reloadTable 
                &&
                <>
                  <Label>{`${intl.formatMessage({ id: "Quantity" })}`}</Label>
                  <TableWithEditableInputFields
                    name="productBatchDetails"
                    className='tab_bar_border_top_width margin-bottom-16'
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Batch" }) })}
                    inputField={fieldsOfProductBatchDetails}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabProductBatch}
                    data={validation.values.productBatchDetails}
                    validation={validation}
                    blnEditable={true}
                    hideAddButton={true}
                    hideDeleteButton={true}
                    blnDisabledCustomizedTableScrolling={true}
                    // deleteButtonFunction={productBatchOnChange}
                    disabled={disabledFieldInput} />
                </>
              }
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Description" })}
                name="remark"
                row={5}
                field={props.selectedCustomerPurchaseDetail}
                setFieldValue={props.setSelectedCustomerPurchaseDetail} 
                validationRequired={false}
                disabled={disabledFieldInput}/>
              {!Boolean(successMessage) &&
                <div className="">
                  <MyButton
                    type="button"
                    class={`btn ${!props.blnDisableModalView ? "btn-primary" : "btn-success"}`}
                    content={!props.blnDisableModalView ? intl.formatMessage({ id: "Save" }) : intl.formatMessage({ id: "Redeem" })}
                    onClick={async () => {
                      if (props.selectedCustomerPurchaseDetail) {
                        let selectedCustomerPurchaseDetailFinal = _.cloneDeep(props.selectedCustomerPurchaseDetail);
                        if (blnTab === 2 || blnTab === 3) {
                          let totalCountTemp = 0;
                          let productBatchDetailsTemp : ProductBatchObject[] = [];
                          validation.values.productBatchDetails.map((valueProductBatchDetailsTemp)=> {
                            totalCountTemp += valueProductBatchDetailsTemp.quantity;
                            if (valueProductBatchDetailsTemp.quantity > 0) {
                              productBatchDetailsTemp.push(valueProductBatchDetailsTemp);
                            }
                          })
                          selectedCustomerPurchaseDetailFinal = {...props.selectedCustomerPurchaseDetail, count: totalCountTemp, productBatchDetails: productBatchDetailsTemp}
                        }

                        let blnServiceTreatmentOnly = selectedCustomerPurchaseDetailFinal.purchaseRedeemType === 2 && selectedCustomerPurchaseDetailFinal.purchaseDetailRedeemType === 3;
                        let blnPackage = selectedCustomerPurchaseDetailFinal.purchaseRedeemType === 0;
                        let blnPackageProduct = selectedCustomerPurchaseDetailFinal.purchaseDetailRedeemType === 1;
                        let blnProductRedemptionProceed = true;
                        let blnServiceBy = (selectedCustomerPurchaseDetailFinal.serviceById === null || selectedCustomerPurchaseDetailFinal.serviceById === "");
                        if (blnPackageProduct) {
                          let indexInvoice = _.findIndex(props.customerPurchaseList, { id: selectedCustomerPurchaseDetailFinal.customerInvoiceNo })
                          if (indexInvoice > -1) {
                            let indexPackage = _.findIndex(props.customerPurchaseList[indexInvoice].details, { packageId: selectedCustomerPurchaseDetailFinal.packageId })
                            if (indexPackage > -1) {
                              blnProductRedemptionProceed = props.productRedemptionEntitlement(props.validation.values.redeemPurchaseDetails, props.customerPurchaseList[indexInvoice].details[indexPackage]);
                            }
                          }
                        }
                        if (!blnProductRedemptionProceed) {
                          return;
                        }
                        let totalOptionalProductRedemptionPrice = 0;
                        if (blnTab === 3) {
                          props.validation.values.redeemPurchaseDetails.map((valueDetailsTemp) => {
                            if (valueDetailsTemp.purchaseDetailRedeemType === 4 && valueDetailsTemp.packageId == selectedCustomerPurchaseDetailFinal?.packageId && valueDetailsTemp.customerInvoiceId == selectedCustomerPurchaseDetailFinal?.customerInvoiceId && valueDetailsTemp.customerPurchaseDetailId !== selectedCustomerPurchaseDetailFinal?.customerPurchaseDetailId) {
                              totalOptionalProductRedemptionPrice += (valueDetailsTemp.count * valueDetailsTemp.unitPrice)
                            }
                          })
                        }
                        let blnProceed = !blnPackage ? redemptionCalculation(blnServiceTreatmentOnly ? 1 : 2, blnServiceTreatmentOnly ? selectedCustomerPurchaseDetailFinal.availableSessions : selectedCustomerPurchaseDetailFinal.quantity! - selectedCustomerPurchaseDetailFinal.redeemdedQuantity!, selectedCustomerPurchaseDetailFinal.count) : redemptionCalculation(blnTab, blnTab === 1 ? selectedCustomerPurchaseDetailFinal.availableSessions : blnTab === 2 ? selectedCustomerPurchaseDetailFinal.availableQuantity : selectedCustomerPurchaseDetailFinal?.optionalAvailableProductCost!, blnTab === 3 ? (totalOptionalProductRedemptionPrice + (selectedCustomerPurchaseDetailFinal.unitPrice! * selectedCustomerPurchaseDetailFinal.count)) : selectedCustomerPurchaseDetailFinal.count)
                        if (!blnProceed) {
                          return;
                        }
                        let objSearch: any = {
                          customerPurchaseDetailId: selectedCustomerPurchaseDetailFinal?.customerPurchaseDetailId,
                          customerInvoiceId: selectedCustomerPurchaseDetailFinal?.customerInvoiceId,
                          salesOrderDetailRedeemType: selectedCustomerPurchaseDetailFinal?.salesOrderDetailRedeemType
                        }
                        if ((blnPackage || blnServiceTreatmentOnly) && selectedCustomerPurchaseDetailFinal.count != 0) {
                          if (blnServiceBy) {
                            setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) }))
                            return;
                          }
                        }
                        if (selectedCustomerPurchaseDetailFinal.purchaseRedeemType === 0) {
                          objSearch = {
                            ...objSearch,
                            packageId: selectedCustomerPurchaseDetailFinal.purchaseRedeemType !== 0 ? undefined : selectedCustomerPurchaseDetailFinal?.packageId,
                          }
                        }
                        let indexTemp = _.findIndex(props.validation.values.redeemPurchaseDetails, objSearch)
                        let redeemPurchaseDetailsTemp = _.cloneDeep(props.validation.values.redeemPurchaseDetails)
                        if (indexTemp > -1) {
                          if (selectedCustomerPurchaseDetailFinal.count > 0) {
                            redeemPurchaseDetailsTemp[indexTemp] = selectedCustomerPurchaseDetailFinal;
                          }
                          else {
                            redeemPurchaseDetailsTemp.splice(indexTemp, 1)
                          }
                        }
                        else if (selectedCustomerPurchaseDetailFinal.count > 0) {
                          redeemPurchaseDetailsTemp.push(selectedCustomerPurchaseDetailFinal)
                        }
                        props.validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp);
                        
                        if (!props.blnDisableModalView) {
                          //prompt info message
                          if (props.blnPromptInfoMessage) {
                            promptInfoMessage(intl.formatMessage({ id: selectedCustomerPurchaseDetailFinal.count > 0 ? "AddedItemIntoRedemptionCartMessage" : "RemoveItemIntoRedemptionCartMessage" }, { quantity: selectedCustomerPurchaseDetailFinal.count, item: selectedCustomerPurchaseDetailFinal.name }), selectedCustomerPurchaseDetailFinal.salesOrderDetailRedeemType)
                          }

                          hideModal();
                        }
                      }                    
                    }}
                    disable={
                      props.loadingDisableModalView
                    }
                    loading={
                      props.loadingDisableModalView
                    }
                  />
                </div>
              }
            </>
          }
        </div>
      </div>
    )
  }
  return (
    !props.blnShow
    ?
    <div />
    :
    props.blnDisableModalView
    ?
    <>
      {displayContent()}
    </>
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        // tog_center();
        hideModal();
      }}
      centered
      size="lg"
      onBlur={() => {
        dismissMessage();
      }}
    >
      {displayContent()}
    </Modal>
  )
}

export default observer(CustomerPurchaseDetailModal);