import { Fragment } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { RoutesList } from "../../app/constants/RoutesList"
import { useIntl } from "react-intl";
import { getJWT } from "../../app/common/function/function";
import maintanence from "../../assets/images/coming-soon.svg"

//Import Images
import error from "../../assets/images/error-img.png"
import { Constants } from "../../app/constants/Constants";

const UnderMaintaince = () => {
  const intl = useIntl();

  return (
    <div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>          
            <Col lg="12">
              <div className="text-center mb-5">
                <h4 className="display-2 font-weight-medium" style={{fontSize: "4.5rem"}}>
                  {intl.formatMessage({id: "SiteIsUnderMaintenance"})}
                </h4>
                <p className="display-2 font-weight-medium" style={{fontSize: "1.5rem", color: Constants.lightorange}}>
                  {intl.formatMessage({id: "PleaseCheckBackInSometime"})}
                  <i className="bx bx-time bx-spin display-5" style={{marginLeft: '4px', color: Constants.lightorange}} />
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="4" xl="4">
              <div>
                <img src={maintanence} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default UnderMaintaince;
