import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, getBranchId, getBranchName, getBranchUser, getMonths, getYears, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import CashMultipleIcon from 'mdi-react/CashMultipleIcon';
import AccountMultipleCheckIcon from 'mdi-react/AccountMultipleCheckIcon';
import AccountCashIcon from 'mdi-react/AccountCashIcon';
import classNames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";

const AverageSpendingSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { averageSpendingSummary, getAverageSpendingSummary, setAverageSpendingSummary, exportAverageSpendingSummary } = reportCustomerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "TotalNewCustomerSpending" }).toUpperCase(), intl.formatMessage({ id: "TotalExistingCustomerSpending" }).toUpperCase()]);
  const [activeTab, setActiveTab] = useState("1");
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());

    async function fetchAverageSpendingSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchAverageSpendingSummary();

    return (() => {
      setAverageSpendingSummary(undefined);
    })
  }, [])

  const fetchAverageSpendingSummaryWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportAverageSpendingSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue })
    }
    else {
      await getAverageSpendingSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue });
    }
    setLocalLoading(false);
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Table Content UI
  const AverageSpendingSummaryColumns = [
    {
      dataField: "customerId", //field name from array to display
      text: "customerId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 ">{row.customerNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerNo}</div>
          }
        </>
      )
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerName}</div>
          }
        </>
      )
    },
    {
      dataField: "joinedDate",
      text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.joinedDate).format(Constants.displayDateAndTimeFormat)}`}
          </div>
        </>
      ),
    },
    {
      dataField: "invoiceSales",
      text: intl.formatMessage({ id: "InvoiceSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.invoiceSales)}</div>
      ),
    },
    {
      dataField: "paymentReceived",
      text: intl.formatMessage({ id: "PaymentReceived" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.paymentReceived)}</div>
      ),
    },
    {
      dataField: "customerCredit",
      text: intl.formatMessage({ id: "CustomerCredit" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.customerCredit)}</div>
      ),
    },
    {
      dataField: "revenueEarned",
      text: intl.formatMessage({ id: "RevenueEarned" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.revenueEarned)}</div>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDynamicSalesDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.dynamicSalesDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AverageSpendingSummary" }) })}
        title={intl.formatMessage({ id: "AverageSpendingSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchAverageSpendingSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Average-Spending-Summary.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchAverageSpendingSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              {
                averageSpendingSummary
                &&
                <>
                  <Row className="padding-top-10">
                    <Col xl="3" lg="6" sm="6" xs="12">
                      <CardInfo
                        icon={() => (<CashCheckIcon className="h2 text-success mb-0" />)}
                        title={intl.formatMessage({ id: "TotalPaymentCollection" }).toUpperCase()}
                        value={returnPriceWithCurrency(averageSpendingSummary.totalPaymentCollection)}
                        valueColor="text-green-color" />
                    </Col>
                    {
                      activeTab === '1'
                        ?
                        <>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashMultipleIcon className="h2 text-primary mb-0" />)}
                              title={intl.formatMessage({ id: "TotalNewCustomerSpending" }).toUpperCase()}
                              value={returnPriceWithCurrency(averageSpendingSummary.totalNewCustomerSpending)}
                              cardColor={"card-red"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<AccountMultipleCheckIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalNewCustomerCount" }).toUpperCase()}
                              value={`${averageSpendingSummary.totalNewCustomerCount}`}
                              cardColor={"card-red"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<AccountCashIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "AveragePerNewCustomerSpend" }).toUpperCase()}
                              value={returnPriceWithCurrency(averageSpendingSummary.averageNewCustomerSpending)}
                              cardColor={"card-red"} />
                          </Col>
                        </>
                        :
                        <>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashMultipleIcon className="h2 text-primary mb-0" />)}
                              title={intl.formatMessage({ id: "TotalExistingCustomerSpending" }).toUpperCase()}
                              value={returnPriceWithCurrency(averageSpendingSummary.totalExistingCustomerSpending)}
                              cardColor={"card-blue"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<AccountMultipleCheckIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalExistingCustomerCount" }).toUpperCase()}
                              value={`${averageSpendingSummary.totalExistingCustomerCount}`}
                              cardColor={"card-blue"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<AccountCashIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "AveragePerExistingCustomerSpend" }).toUpperCase()}
                              value={returnPriceWithCurrency(averageSpendingSummary.averageExistingCustomerSpending)}
                              cardColor={"card-blue"} />
                          </Col>
                        </>
                    }
                  </Row>
                  <div style={{ marginBottom: "-20px" }} className="standard-layout">
                    <Nav className="nav nav-tabs nav-tabs-custom">
                      {tabBar.map((value, indexTab) => (
                        <NavItem key={`tabkey_${indexTab}`}>
                          <NavLink
                            disabled={indexTab == 1 && (false)}
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active:
                                activeTab ===
                                String(indexTab + 1),
                            })}
                            onClick={() => {
                              toggle(String(indexTab + 1));
                            }}
                          >
                            {value}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </>
              }
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={AverageSpendingSummaryColumns}
                data={averageSpendingSummary ? activeTab === "1" ? averageSpendingSummary.newCustomerSpendingDetails : averageSpendingSummary.existingCustomerSpendingDetails : []}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: activeTab === "1" ? "TotalNewCustomerSpending" : "TotalExistingCustomerSpending" }) })}
                objSorted={defaultSorted}
                keyField={"customerId"}
                requestAPI={getAverageSpendingSummary}
                branchId={selectedBranchId}
                selectedYear={selectedYear}
                selectedMonth={selectedMonthValue}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AverageSpendingSummary);
