import { useEffect, useState } from "react";
import {Modal} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import { RedemptionCustomerPackage } from "../../../../app/models/appolousCustomerPackage";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";
import GeneralTextAreaForModal from "../../../../app/components/form/GeneralTextAreaForModal";
import GeneralSelectDropdown from "../../../../app/components/form/GeneralSelectDropdown";
import { AddOptionsValueField } from "../../../../app/common/function/function";
import moment from "moment";
import Loading from "../../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedAppolousCustomerPackageRedeemDetail: RedemptionCustomerPackage | undefined;
  setSelectedAppolousCustomerPackageRedeemDetail: Function;
  editFunction: Function;
}

export default function AppolousCustomerPackageRedemptionDetailEditModal(props: Props) {
  const intl = useIntl();
  const { commonStore, employeeStore } = useStore();
  const { successMessage } = commonStore;
  const { getEmployeeDropdown } = employeeStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [localLoadingAppolousCustomerPackageRedemption, setLocalLoadingAppolousCustomerPackageRedemption] = useState(false);
  const [optionsEmployeeList, setOptionsEmployeeList] = useState([]);
  const disabledFieldInput = localLoadingAppolousCustomerPackageRedemption || Boolean(successMessage);

  const defaultAppolousCustomerPackageRedemptionDetail = {
    appolousCustomerPackageRedemptionId: props.selectedAppolousCustomerPackageRedeemDetail?.appolousCustomerPackageRedemptionId || "",
    serviceById: props.selectedAppolousCustomerPackageRedeemDetail?.serviceById,
    serviceByName: props.selectedAppolousCustomerPackageRedeemDetail?.consultantName || "",
    remark: props.selectedAppolousCustomerPackageRedeemDetail?.remark || "",
    createdAt: moment(props.selectedAppolousCustomerPackageRedeemDetail?.createdAt).format(Constants.displayDateAndTimeFormat),
    serviceName: props.selectedAppolousCustomerPackageRedeemDetail?.serviceName
  }

  const [appolousCustomerPackageRedemptionDetail, setAppolousCustomerPackageRedemptionDetail] = useState(defaultAppolousCustomerPackageRedemptionDetail)

  useEffect(()=> {
    async function getEmployeeList() {
      setLocalLoading (true);
      let resultEmployeeList = await getEmployeeDropdown({branchId: props.selectedAppolousCustomerPackageRedeemDetail?.branchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}, true)
      setOptionsEmployeeList(AddOptionsValueField(resultEmployeeList, "preferredName", "id"));
      setLocalLoading(false);
    }
    
    if (props.selectedAppolousCustomerPackageRedeemDetail?.branchId && !props.selectedAppolousCustomerPackageRedeemDetail.appolousId) {
      getEmployeeList();
    }
  }, [props.selectedAppolousCustomerPackageRedeemDetail])

  const hideModal = () => {
    props.setSelectedAppolousCustomerPackageRedeemDetail(undefined);
    props.setModal(false);
  }

  const onChangeEmployee = (e: any) => {
    setAppolousCustomerPackageRedemptionDetail((valueAppolousCustomerPackageRedemptionDetail: any)=> ({
      ...valueAppolousCustomerPackageRedemptionDetail,
      serviceById: e.value,
      serviceByName: e.label
    }));
  }

  const onClickEditAppolousCustomerPackageRedemption = async () => {
    setLocalLoadingAppolousCustomerPackageRedemption(true);
    let appolousCustomerPackageRedemptionDetailTemp = _.cloneDeep(appolousCustomerPackageRedemptionDetail);
    if (props.selectedAppolousCustomerPackageRedeemDetail?.appolousId) {
      appolousCustomerPackageRedemptionDetailTemp.serviceById = Constants.emptyId;
      appolousCustomerPackageRedemptionDetailTemp.serviceByName = "";
    }
    await props.editFunction(appolousCustomerPackageRedemptionDetailTemp);
    setLocalLoadingAppolousCustomerPackageRedemption(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          // tog_center();
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Edit" })} {intl.formatMessage({ id: "AppolousCustomerPackageRedemption" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        {
          localLoading
          ?
          <div className="modal-body">
            <Loading/>
          </div>
          :
          <div className="modal-body">
            <GeneralInputForModal
              title={intl.formatMessage({ id: "Date" })}
              name="createdAt"
              className={"mb-3"}
              type="text"
              field={appolousCustomerPackageRedemptionDetail}
              setFieldValue={setAppolousCustomerPackageRedemptionDetail}
              disabled={true}
            />
            <GeneralInputForModal
              title={intl.formatMessage({ id: "Service" })}
              name="serviceName"
              className={"mb-3"}
              type="text"
              field={appolousCustomerPackageRedemptionDetail}
              setFieldValue={setAppolousCustomerPackageRedemptionDetail}
              disabled={true}
            />
            {
              !props.selectedAppolousCustomerPackageRedeemDetail?.appolousId
              &&
              !localLoading
              &&
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "ServiceBy"})}
                name=""
                className="mb-3"
                options={optionsEmployeeList}
                onChange={onChangeEmployee}
                initialLabel={props.selectedAppolousCustomerPackageRedeemDetail?.consultantName || ""}
                initialValue={props.selectedAppolousCustomerPackageRedeemDetail?.serviceById || ""}
                blnReturnFullValue={true}
                validationRequired={true}
                disabled={disabledFieldInput}
              />
            }
            <GeneralTextAreaForModal
              title={intl.formatMessage({ id: "Remark" })}
              name="remark"
              className={"mb-3"}
              validationRequired={true}
              field={appolousCustomerPackageRedemptionDetail}
              setFieldValue={setAppolousCustomerPackageRedemptionDetail}
              row={3}
              disabled={disabledFieldInput}/>
            <div className="float-end">
              {
                !Boolean(successMessage)
                &&
                <MyButton
                  type="button"
                  class="btn btn-primary"
                  content={intl.formatMessage({ id: "Submit" })}
                  onClick={() => {
                    onClickEditAppolousCustomerPackageRedemption()
                  }}
                  loading={localLoadingAppolousCustomerPackageRedemption}
                  disable={disabledFieldInput}
                />
              }
            </div>
          </div>
        }
      </Modal>
  )
}