import React from 'react'

interface Props {
  title?: string;
  order: string;
  onClickFunction?: Function;
}


export default function SortCaret(props: Props) {
  const { order, onClickFunction } = props;
  const displayContent = () => {
    if (!order) return (<span className="order"><span className="dropdown"><span className="caret"></span></span><span className="dropup"><span className="caret"></span></span></span>);
    else if (order === 'asc') return (<span className="react-bootstrap-table-sort-order"><span className="caret"></span></span>);
    else if (order === 'desc') return (<span className="react-bootstrap-table-sort-order dropup"><span className="caret"></span></span>);
    return null;
  }

  return (
    <span {...onClickFunction && {onClick: ()=> {onClickFunction(order === 'asc' || order === undefined ? 'desc' : 'asc')}}}>
      {props.title && <span>{props.title}</span>} {displayContent()}
    </span>
  )
}
