import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, stockQuantityStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const StockCard = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, categoryStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { stockCardDetailsList, getStockCardDetailsList, setStockCardDetailsList, exportStockCard } = reportStockStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;
  const { productType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);

  const arystockCardSummaryDetail: ObjectForReportRow[] = [
    { label: "Date", value: "actionDate", type: "date" },
    { label: "Employee", value: "actionUserName", type: "string" },
    { label: "Reference", value: "reference", type: "link", routeObject: {
      routePathField: 'referenceEntity',
      routePath: 'view',
      routePathId: 'referenceEntityId'
    } },
    { label: "MovementType", value: "stockMovementType", type: "string" },
    { label: "LiveQuantity", value: "quantity", type: "status" },
    { label: "BalanceOnBookQuantity", value: "balanceQuantity", type: "status" },
    { label: "VirtualWarehouseBalance", value: "balanceReservedQuantity", type: "status" },
    { label: "OnHandQuantity", value: "actualQuantity", type: "status" },
  ];

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: "",
      categoryIds: [],
      productTypes: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchStockCard() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCategoryDropdown(),
        getStaticReferenceWithType(Constants.productType)
      ];

      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockCard();

    return (() => {
      setStockCardDetailsList([]);
    })
  }, [])

  const fetchStockCardWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportStockCard({ branchId: selectedBranchId, name: validation.values.name, sKU: validation.values.sKU, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, categoryIds: validation.values.categoryIds, productTypes: validation.values.productTypes})
    }
    else {
      setCurrentPage(1);
      await getStockCardDetailsList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, name: encodeURIComponent(validation.values.name), sKU: encodeURIComponent(validation.values.sKU), dateRange: selectedDateRange, categoryIds: validation.values.categoryIds, productTypes: validation.values.productTypes });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const StockCardListColumns = [
    {
      dataField: "productName", //field name from array to display
      text: "productName", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.sku || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
    },
    {
      dataField: "productTypes",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "LiveQty" }).toUpperCase(),
    },
    {
      dataField: "defectQuantity",
      text: intl.formatMessage({ id: "DefectQty" }).toUpperCase(),
    },
    {
      dataField: "movementQuantity",
      text: intl.formatMessage({ id: "BookQty" }).toUpperCase(),
    },
    {
      dataField: "reservedQuantity",
      text: intl.formatMessage({ id: "VirtualWarehouseBalance" }).toUpperCase(),
    },
    {
      dataField: "physicalQuantity",
      text: intl.formatMessage({ id: "OnHandQty" }).toUpperCase(),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "StockCardSummaryDetails" })}
        rowFieldName={"stockCardSummaryDetails"}
        rowColumns={arystockCardSummaryDetail}
        keyFieldName={"actionDate"}
        statusColorFunction={stockQuantityStatusColor}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    )
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockCard" }) })}
        title={intl.formatMessage({ id: "StockCard" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchStockCardWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Stock-Card.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchId"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "ActionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="categoryIds"
              title={intl.formatMessage({ id: "Category" })}
              specifyReturnFieldName={[{ "field": "categoryIds", "value": "id" }]}
              returnFieldWithLabel={false}
              labelField={"name"}
              valueField={"id"}
              options={categoryDropdownList}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="productTypes"
              title={intl.formatMessage({ id: "ProductType" })}
              specifyReturnFieldName={[{ "field": "productTypes", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"displayValue"}
              blnValueWithNewSpace={true}
              options={productType}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="3">
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterProductName" })}
            />
          </Col>
          <Col xl="3">
            <GeneralInput
              title={intl.formatMessage({ id: "SKU" })}
              name="sKU"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSKU" })}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchStockCardWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={StockCardListColumns}
                data={stockCardDetailsList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockCard" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                name={encodeURIComponent(validation.values.name)}
                sKU={encodeURIComponent(validation.values.sKU)}
                dateRange={selectedDateRange}
                categoryIds={validation.values.categoryIds}
                productTypes={validation.values.productTypes}
                keyField={"productName"}
                expandRow={expandRow}
                requestAPI={getStockCardDetailsList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockCard);
