import { PaginationRequestBody } from "../models/pagination";
import { UserRoleDetailObject, UserRoleListObject } from "../models/userRole";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class userRoleStore {
  userRoleList: UserRoleListObject[] = [];
  userRoleDropdownList: UserRoleListObject[] = [];
  userRoleDetail: UserRoleDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.userRoleList = [];
    this.userRoleDropdownList = [];
    this.userRoleDetail = undefined;
  }

  setUserRoleDropdownList = (userRoleDropdownList: UserRoleListObject[]) => {
    this.userRoleDropdownList = userRoleDropdownList;
  }

  getUserRole = async () => {
    try{
      const resultUserRole = await agent.UserRole.userRoleList();
      runInAction(() => {
        this.userRoleList = resultUserRole;
      });

      return Promise.resolve(resultUserRole)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.userRoleList = [];
    }
  }

  getUserRoleWithId = async (id: string) => {
    try{
      const resultUserRoleDetail = await agent.UserRole.userRoleDetail(id);
      runInAction(() => {
        this.userRoleDetail = resultUserRoleDetail;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.userRoleDetail = undefined;
      return Promise.resolve({status: Constants.failed});
    }
  }

  getUserRoleDropdown = async (employeeTypeId: string | undefined | number) => {
    try{
      const resultUserRoleDropdown = await agent.UserRole.userRoleDropdownList(employeeTypeId);
      runInAction(() => {
        this.userRoleDropdownList = resultUserRoleDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.userRoleDropdownList = [];
    }
  }
}