import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, returnSubBrandList } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link, useLocation } from 'react-router-dom';
import { SubBrandObject } from "../../../app/models/brand";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";

const CollectOnBehalfDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportPaymentStore, branchStore, paymentMethodStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { collectOnBehalfDetailsList, getCollectOnBehalfDetailsList, setCollectOnBehalfDetailsList, exportCollectOnBehalfDetails } = reportPaymentStore;
  const { isEligibleAllBranch, getBranchDropDown, getBranchDropDownWithoutValidation } = branchStore;
  const { getPaymentMethodDropdown } = paymentMethodStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [paymentMethodListFinal, setPaymentMethodListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] = useState(All);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(" ");
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedPurchaseBranchName, setSelectedPurchaseBranchName] = useState(AllBranch);
  const [selectedPurchaseBranchId, setSelectedPurchaseBranchId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportPaymentReport = checkPermission([PermissionConstants.ExportPaymentReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionViewCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewApplousSales = checkPermission([PermissionConstants.ManageAppolousSales]);

  const paramsSearch = useLocation().search;
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const purchaseBranchParams = new URLSearchParams(paramsSearch).get('purchaseBranchId');
  // const paymentMethodParams = new URLSearchParams(paramsSearch).get('paymentMethodId');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      isIncludeCreditPayment: false
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    
    async function fetchCollectOnBehalfDetailsListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      // let blnParams = (branchParams && paymentMethodParams && fromDateParams && toDateParams) || subBrandParams;
      let blnParams = ((branchParams || purchaseBranchParams) && fromDateParams && toDateParams);
      let aryAPI: any = [
        getBranchDropDown(),
        getBranchDropDownWithoutValidation(),
        getPaymentMethodDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], AllBranch, false, "name", "id"))
      setPaymentMethodListFinal(arraySpliceInAllValue(resultAPI[2], All, false, "name", "id"));
      
      if (blnParams) {
        setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })

        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        let purchaseBranchIdTemp = "";
        if (purchaseBranchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[1]);
          branchListTemp.map((value) => {
            if (purchaseBranchParams == value.id) {
              setSelectedPurchaseBranchName(value.name)
              setSelectedPurchaseBranchId(value.id);
              purchaseBranchIdTemp = value.id;
            }
          })
        }

        // let paymentMethodIdTemp: number | string = " ";
        // if (paymentMethodParams) {
        //   let paymentMethodListTemp: any = _.cloneDeep(resultAPI[1]);
        //   paymentMethodListTemp.map((value) => {
        //     if (paymentMethodParams == value.id) {
        //       setSelectedPaymentMethodName(value.name)
        //       setSelectedPaymentMethodId(value.id);
        //       paymentMethodIdTemp = value.id;
        //     }
        //   })
        // }

        let subBrandIdTemp: number | string = " ";
        if(subBrandParams){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.collectOnBehalfDetails}` });
        await fetchCollectOnBehalfDetailApi(false, branchIdTemp, "", { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" }, subBrandIdTemp, purchaseBranchIdTemp);
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManagePaymentReport], true)) {
      return;
    }

    fetchCollectOnBehalfDetailsListAPI();

    return (() => {
      setCollectOnBehalfDetailsList([]);
    })
  }, []);

  async function fetchCollectOnBehalfDetailApi(blnDropDown: boolean = false, branchIdTemp?: string, paymentMethodIdTemp?: string, dateRange?: DateRangeInterface, subBrandIdTemp?: string, purchaseBranchId?: string) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    if (!selectedBranchId && branchIdTemp == null) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getCollectOnBehalfDetailsList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: branchIdTemp || selectedBranchId,
      dateRange: dateRange || selectedDateRange,
      paymentMethodId: paymentMethodIdTemp || selectedPaymentMethodId,
      subBrandId: subBrandIdTemp || selectedSubBrandId,
      purchaseBranchId: purchaseBranchId || selectedPurchaseBranchId,
      isIncludeCreditPayment: validation.values.isIncludeCreditPayment
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportPaymentCollectOnBehalfDetailsApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }
    
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    await exportCollectOnBehalfDetails({
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      paymentMethodId: selectedPaymentMethodId,
      subBrandId: selectedSubBrandId,
      purchaseBranchId: selectedPurchaseBranchId,
      isIncludeCreditPayment: validation.values.isIncludeCreditPayment
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const CollectOnBehalfDetailsColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "PaymentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "paymentBranch",
      text: intl.formatMessage({ id: "PaymentBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.paymentBranch || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomerSalesOrder && row.documentId && !row.isAppolous
            ?
            <Link to={`/${RoutesList.customerSalesOrder}/view/${row.documentId}`} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.documentNo || Constants.emptyData}</Label>
            </Link>
            :
            blnPermissionViewApplousSales && row.documentId && row.isAppolous
            ?
            <Link to={`/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.documentId}`} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.documentNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.documentNo || Constants.emptyData}</div>
          }
        </>
      )
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={blnPermissionViewCustomer && row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "subBrand",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      hidden: subBrandListFinal.length === 0,
      formatter: (cellContent, row) => (
        <div>
          {row.subBrand || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "purchaseBranch",
      text: intl.formatMessage({ id: "PurchaseBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.purchaseBranch || Constants.emptyData}
        </div>
      ),
    },
    // {
    //   dataField: "isSubBranch",
    //   text: intl.formatMessage({ id: "SubBranch" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.isSubBranch ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
    //     </div>
    //   ),
    // },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "PaidAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.paymentMethodName) || Constants.emptyData}
        </div>
      ),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentCollectOnBehalfDetails" }) })}
        title={intl.formatMessage({ id: "PaymentCollectOnBehalfDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportPaymentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportPaymentCollectOnBehalfDetailsApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Payment-Collect-On-Behalf-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PaymentBranch" })}
                  name="branchName"
                  options={branchListFinal}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PurchaseBranch" })}
                  name="purchaseBranchName"
                  options={branchListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedPurchaseBranchName}
                  initialValue={selectedPurchaseBranchId}
                  setFieldLabel={setSelectedPurchaseBranchName}
                  setFieldValue={setSelectedPurchaseBranchId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PaymentDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PaymentMethod" })}
                  name="paymentMethodId"
                  options={paymentMethodListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedPaymentMethodName}
                  initialValue={selectedPaymentMethodId}
                  setFieldLabel={setSelectedPaymentMethodName}
                  setFieldValue={setSelectedPaymentMethodId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IncludeCreditPayment" })}
                  name="isIncludeCreditPayment"
                  className=""
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchCollectOnBehalfDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CollectOnBehalfDetailsColumns}
              keyField={"randomId"}
              data={collectOnBehalfDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PaymentCollectOnBehalfDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getCollectOnBehalfDetailsList}
              branchId={selectedBranchId}
              paymentMethodId={selectedPaymentMethodId}
              dateRange={selectedDateRange}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} 
              purchaseBranchId={selectedPurchaseBranchId}
              isIncludeCreditPayment={validation.values.isIncludeCreditPayment}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CollectOnBehalfDetails);
