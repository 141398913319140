import { PaginationRequestBody } from "../models/pagination";
import { ChangePaymentModeListObject, ChangePaymentModeAddObject, ChangePaymentModeUpdateObject, ChangePaymentModeDetailObject, ChangePaymentModeOldPaymentListObject, ChangePaymentModeSignObject, ChangePaymentModeCancelObject, ChangePaymentModeReviewFirstApprovalObject, ChangePaymentModeReviewFinalApprovalObject, ChangePaymentModeUpdateAfterCompletedObject } from "../models/changePaymentMode";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class changePaymentModeStore {
  changePaymentModeList: ChangePaymentModeListObject[] = [];
  changePaymentModeDetail: ChangePaymentModeDetailObject | undefined = undefined;
  changePaymentModePaginationParams: PaginationRequestBody | undefined = undefined;
  changePaymentModeOldPaymentList: ChangePaymentModeOldPaymentListObject[] = [];
  changePaymentModeOldPaymentTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.changePaymentModeList = [];
    this.changePaymentModeDetail = undefined;
    this.changePaymentModePaginationParams= undefined;
    this.changePaymentModeOldPaymentList = [];
    this.changePaymentModeOldPaymentTotalItems = 0;
  }

  setChangePaymentModeDetail = (changePaymentModeDetail: ChangePaymentModeDetailObject | undefined) => {
    this.changePaymentModeDetail = changePaymentModeDetail;
  }

  setChangePaymentModePaginationParams = (changePaymentModePaginationParams: PaginationRequestBody | undefined) => {
    this.changePaymentModePaginationParams = changePaymentModePaginationParams;
  }

  setChangePaymentModeOldPaymentList = (changePaymentModeOldPaymentList: ChangePaymentModeOldPaymentListObject[]) => {
    this.changePaymentModeOldPaymentList = changePaymentModeOldPaymentList;
  }

  setChangePaymentModeOldPaymentTotalItems = (changePaymentModeOldPaymentTotalItems: number) => {
    this.changePaymentModeOldPaymentTotalItems = changePaymentModeOldPaymentTotalItems;
  }
  
  getChangePaymentMode = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setChangePaymentModePaginationParams(PaginationRequestBody);
    try{
      const resultChangePaymentMode = await agent.ChangePaymentMode.changePaymentModeList(PaginationRequestBody);
      runInAction(() => {
        this.changePaymentModeList = resultChangePaymentMode.data;
        store.commonStore.setTotalItem(resultChangePaymentMode.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.changePaymentModeList = [];
    }
  }

  getChangePaymentModeWithId = async (id: string) => {
    try{
      const resultChangePaymentModeDetail = await agent.ChangePaymentMode.changePaymentModeDetail(id);
      if (resultChangePaymentModeDetail) {
        if (resultChangePaymentModeDetail.newPaymentDetails) {
          resultChangePaymentModeDetail.newPaymentDetails = resultChangePaymentModeDetail.newPaymentDetails.map((valueNewPaymentTemp, indexNewPaymentTemp)=> ({
            ...valueNewPaymentTemp,
            randomId: `new_payment_index_${indexNewPaymentTemp}`
          }))
          if (resultChangePaymentModeDetail.newPaymentDetails.length > 0) {
            resultChangePaymentModeDetail.transactionDate = resultChangePaymentModeDetail.newPaymentDetails[0].transactionDate;
          }
        }
      }
      runInAction(() => {
        this.changePaymentModeDetail = resultChangePaymentModeDetail;
      });
      return Promise.resolve(resultChangePaymentModeDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.changePaymentModeDetail = undefined;
    }
  }

  addChangePaymentMode = async (changePaymentModeRequestBody: ChangePaymentModeAddObject) => {
    try{
      const resultAddChangePaymentMode = await agent.ChangePaymentMode.addChangePaymentMode(changePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAddChangePaymentMode});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateChangePaymentMode = async (changePaymentModeRequestBody: ChangePaymentModeUpdateObject) => {
    try{
      const resultUpdateChangePaymentMode =  await agent.ChangePaymentMode.updateChangePaymentMode(changePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeUpdateSuccess`)
      return Promise.resolve({status: Constants.success, data: resultUpdateChangePaymentMode});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  deleteChangePaymentMode  = async (id: string, name: string) => {
    try {
      await agent.ChangePaymentMode.deleteChangePaymentMode(id);
      store.commonStore.setSuccessMessage(`ChangePaymentModeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getChangePaymentModeOldPayment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultChangePaymentModeOldPayment = await agent.ChangePaymentMode.changePaymentModeOldPaymentList(PaginationRequestBody);
      runInAction(() => {
        this.changePaymentModeOldPaymentList = resultChangePaymentModeOldPayment.data;
        this.changePaymentModeOldPaymentTotalItems = resultChangePaymentModeOldPayment.pagination.totalItems
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.changePaymentModeOldPaymentList = [];
      this.changePaymentModeOldPaymentTotalItems = 0;
    }
  }

  getChangePaymentModePaymentMethod = async (branchId: string) => {
    try{
      const resultChangePaymentModeOldPayment = await agent.ChangePaymentMode.changePaymentModePaymentMethodList(branchId);
      return Promise.resolve(resultChangePaymentModeOldPayment);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  signChangePaymentMode = async (signChangePaymentModeRequestBody: ChangePaymentModeSignObject) => {
    try{
      await agent.ChangePaymentMode.signChangePaymentMode(signChangePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeSignSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  cancelChangePaymentMode = async (cancelChangePaymentModeRequestBody: ChangePaymentModeCancelObject) => {
    try{
      await agent.ChangePaymentMode.cancelChangePaymentMode(cancelChangePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeCancelSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  reviewFirstApprovalChangePaymentMode = async (reviewFirstApprovalChangePaymentModeRequestBody: ChangePaymentModeReviewFirstApprovalObject) => {
    try{
      await agent.ChangePaymentMode.reviewFirstApprovalChangePaymentMode(reviewFirstApprovalChangePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeReviewFirstApprovalSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  reviewFinalApprovalChangePaymentMode = async (reviewFinalApprovalChangePaymentModeRequestBody: ChangePaymentModeReviewFinalApprovalObject) => {
    try{
      await agent.ChangePaymentMode.reviewFinalApprovalChangePaymentMode(reviewFinalApprovalChangePaymentModeRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeReviewFinalApprovalSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  updateChangePaymentModeAfterCompleted = async (updateChangePaymentModeAfterCompletedRequestBody: ChangePaymentModeUpdateAfterCompletedObject) => {
    try{
      await agent.ChangePaymentMode.updateChangePaymentModeAfterCompleted(updateChangePaymentModeAfterCompletedRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ChangePaymentModeUpdateAfterCompletedSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}