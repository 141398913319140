import { PaginationRequestBody } from "../models/pagination";
import { PromotionListObject, PromotionAddObject, PromotionUpdateObject, PromotionDetailObject, PromotionListWithCodeRequestBody, PromotionDropdownListObject } from "../models/promotion";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";

export default class promotionStore {
  promotionList: PromotionListObject[] = [];
  promotionDropdownList: PromotionDropdownListObject[] = [];
  promotionDropdownWithoutSponsorList: PromotionDropdownListObject[] = [];
  promotionDetail: PromotionDetailObject | undefined = undefined;
  promotionPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.promotionList = [];
    this.promotionDropdownList = [];
    this.promotionDropdownWithoutSponsorList= [];
    this.promotionDetail = undefined;
    this.promotionPaginationParams= undefined;
  }

  setPromotionDetail = (promotionDetail: PromotionDetailObject | undefined) => {
    this.promotionDetail = promotionDetail;
  }

  setPromotionDropdown = (promotionDropdownList: PromotionDropdownListObject[]) => {
    this.promotionDropdownList = promotionDropdownList;
  }

  setPromotionDropdownWithoutSponsorList = (promotionDropdownWithoutSponsorList: PromotionDropdownListObject[]) => {
    this.promotionDropdownWithoutSponsorList = promotionDropdownWithoutSponsorList;
  }

  setPromotionPaginationParams = (promotionPaginationParams: PaginationRequestBody | undefined) => {
    this.promotionPaginationParams = _.cloneDeep(promotionPaginationParams);
  }

  getPromotion = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setPromotionPaginationParams(PaginationRequestBody);
    PaginationRequestBody.promotionCode = encodeURIComponent(PaginationRequestBody.promotionCode || "");
    try {
      const resultPromotion = await agent.Promotion.promotionList(PaginationRequestBody);
      runInAction(() => {
        this.promotionList = resultPromotion.data;
        store.commonStore.setTotalItem(resultPromotion.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.promotionList = [];
    }
  }

  getPromotionDropdown = async () => {
    try{
      const resultPromotionDropdown = await agent.Promotion.promotionDropdownList();
      runInAction(() => {
        this.promotionDropdownList = resultPromotionDropdown;
        this.promotionDropdownWithoutSponsorList = resultPromotionDropdown ? resultPromotionDropdown.filter((promotionValue)=> !promotionValue.isForSponsorProfileOnly) : [];
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.promotionDropdownList = [];
      this.promotionDropdownWithoutSponsorList = [];
    }
  }

  getPromotionWithId = async (id: string) => {
    try {
      const resultPromotionDetail = await agent.Promotion.promotionDetail(id);
      runInAction(() => {
        let aryPromoProductTemp: any = [];
        let aryPromoPackageTemp: any = [];
        let aryPromoServiceTemp: any = [];
        resultPromotionDetail.promotionDetails.map((value) => {
          if (value.purchaseType == Constants.product) {
            aryPromoProductTemp.push({ ...value, maxPercentage: 100 });
          }
          if (value.purchaseType == Constants.package) {
            aryPromoPackageTemp.push({ ...value, maxPercentage: 100 });
          }
          if (value.purchaseType == Constants.service) {
            aryPromoServiceTemp.push({ ...value, maxPercentage: 100 });
          }
        })
        resultPromotionDetail.promotionProduct = aryPromoProductTemp;
        resultPromotionDetail.promotionPackage = aryPromoPackageTemp;
        resultPromotionDetail.promotionService = aryPromoServiceTemp;
        if (resultPromotionDetail.promotionType !== Constants.byPeriod) {
          resultPromotionDetail.startDate = "";
          resultPromotionDetail.endDate = "";
        }
        this.promotionDetail = resultPromotionDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.promotionDetail = undefined;
    }
  }

  addPromotion = async (promotionRequestBody: PromotionAddObject) => {
    try {
      await agent.Promotion.addPromotion(promotionRequestBody);
      store.commonStore.setSuccessMessage(`PromotionAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePromotion = async (promotionRequestBody: PromotionUpdateObject) => {
    try {
      await agent.Promotion.updatePromotion(promotionRequestBody);
      store.commonStore.setSuccessMessage(`PromotionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePromotion = async (id: string, name: string) => {
    try {
      await agent.Promotion.deletePromotion(id);
      store.commonStore.setSuccessMessage(`PromotionDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPromotionListWithCode = async (PromotionRequestBody: PromotionListWithCodeRequestBody) => {
    try {
      const resultPromotionListWithCode = await agent.Promotion.promotionListWithCode(PromotionRequestBody);
      return Promise.resolve({status: Constants.success, data: resultPromotionListWithCode})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}})
    }
  }
}