import { useState } from "react";
import {
  Modal,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useStore } from "../../../app/stores/store";
import { getBranchUser } from "../../../app/common/function/function";
import { CloseCounterAddObject } from "../../../app/models/closeCounter";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import { Constants } from "../../../app/constants/Constants";
import { BranchListObject } from "../../../app/models/branch";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  initialBranchId: string;
  initialBranchName: string;
  branchDropdownList: BranchListObject[];
  addCloseCounter: Function;
}

export default function GenerateCloseCounterModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(moment().format(Constants.textInputDateFormat));
  const [selectedBranchId, setSelectedBranchId] = useState(props.initialBranchId);
  const [selectedBranchName, setSelectedBranchName] = useState(props.initialBranchName);
  const [localLoading, setLocalLoading] = useState(false);
  const disabledFieldInput = localLoading || Boolean(successMessage);
  const branchDropDownList = props.branchDropdownList;
  
  const defaultCloseCounterDetails = {
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    submissionDate: selectedSubmissionDate
  }
  const [closeCounterDetails, setCloseCounterDetails] = useState(defaultCloseCounterDetails)
  const [errorState, setErrorState] = useState({
    branchId: false,
    submissionDate: false
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branchId: false,
      submissionDate: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setCloseCounterDetails(defaultCloseCounterDetails);
    props.setModal(false);
  }

  const onChangeBranch = (id: string) => {
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    if (indexBranch > -1) {
      setCloseCounterDetails((values) => ({
        ...values,
        branchId: branchDropDownList[indexBranch].id,
        branchName: branchDropDownList[indexBranch].name
      }))
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
      clearErrorState("branchId")
    }
  }

  const onClickGenerateCloseCounter = async (closeCounterDetailTemp: CloseCounterAddObject) => {
    setLocalLoading(true);
    await props.addCloseCounter(closeCounterDetailTemp);
    setLocalLoading(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CloseCounter" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        {
          localLoading
            ?
            <Loading />
            :
            <div className="modal-body">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchId"
                className={errorState.branchId ? "mb-0" : "mb-3"}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={disabledFieldInput || getBranchUser()}
                onChange={onChangeBranch}
                validationRequired={true}
              />
              {errorState.branchId ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
              ) : null}
              <GeneralInputForModal
                title={intl.formatMessage({ id: "SubmissionDate" })}
                name="submissionDate"
                className={errorState.submissionDate ? "mb-0" : "mb-3"}
                type="date"
                validationRequired={true}
                field={closeCounterDetails}
                setFieldValue={setCloseCounterDetails}
                disabled={disabledFieldInput}
              />
              {errorState.submissionDate ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubmissionDate" }) })}</FormFeedback>
              ) : null}
              {
                !Boolean(successMessage)
                &&
                <div className="margin-top-14 ">
                  <MyButton
                    type="button"
                    class="btn btn-primary"
                    content={intl.formatMessage({ id: "Generate" })}
                    onClick={() => {
                      let errorStateTemp = {
                        branchId: false,
                        submissionDate: false
                      };

                      let blnErrorFound = false;
                      if (!closeCounterDetails.branchId) {
                        errorStateTemp.branchId = true;
                        blnErrorFound = true;
                      }
                      else if (!closeCounterDetails.submissionDate) {
                        errorStateTemp.submissionDate = true;
                        blnErrorFound = true;
                      }

                      
                      if (blnErrorFound) {
                        setErrorState(errorStateTemp)
                      }
                      else {
                        onClickGenerateCloseCounter({...closeCounterDetails})
                      }
                    }}
                    disable={disabledFieldInput}
                    loading={localLoading}
                  />
                </div>
              }
            </div>
        }
      </Modal>
  )
}