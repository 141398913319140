import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, paymentStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import { ProductUnutilizedBalanceGetObject, ProductUnutilizedBalanceListObject } from "../../../app/models/reportCustomer";
import { history } from "../../../";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { useFormik } from "formik";
import * as Yup from "yup";

const ProductUnutilizedBalance = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, commonStore, productStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { productUnutilizedBalanceList, getProductUnutilizedBalanceList, setProductUnutilizedBalanceList, exportProductUnutilizedBalance } = reportCustomerStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { productDropdownList, getProductDropdown } = productStore;

  //Data List
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const aryUnutilizedProductBalanceDetails : ObjectForReportRow[] = [
    {label: "CustomerSalesOrderNo", value: "customerSalesOrderNo", type: "link"},
    {label: "PaymentStatus", value: "paymentStatus", type: "status"},  
    {label: "Balance", value: "balance", type: "currency"}, 
    {label: "ProductName", value: "productName", type: "string-without-space"}, 
    {label: "PurchasedQuantity", value: "purchasedQuantity", type: "string"}, 
    {label: "RedeemedQuantity", value: "redeemedQuantity", type: "string"}
  ];
  const paramsSearch = useLocation().search;
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productIds: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  useEffect(() => {
    async function fetchProductUnutilizedBalance() {
			setLoading(true);
      setLoadingDropdownRefresh(true)
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

      if (branchIdParams && fromDateParams && toDateParams) {
        let indexBranchId = _.findIndex(resultAPI[0], { id: branchIdParams })
        if (indexBranchId > -1) {
          setSelectedBranchId(resultAPI[0][indexBranchId].id);
          setSelectedBranchName(resultAPI[0][indexBranchId].name);
        }
        setSelectedDateRange({fromDate: moment(fromDateParams).format(Constants.displayDateFormat), toDate: moment(toDateParams).format(Constants.displayDateFormat)})
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.productUnutilizedBalance}` });
        await fetchProductUnutilizedBalanceWithLoading({branchId: branchIdParams, dateRange: {fromDate: moment(fromDateParams).format(Constants.displayDateFormat), toDate: moment(toDateParams).format(Constants.displayDateFormat)}})
      }

			setLoading(false);
      setLoadingDropdownRefresh(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }
    
    fetchProductUnutilizedBalance();

    return (()=> {
      setProductUnutilizedBalanceList([]);
    })
  }, [])

  const fetchProductUnutilizedBalanceWithLoading = async (initialData?: ProductUnutilizedBalanceGetObject) => {
    if (initialData) {
      setLocalLoading(true);
      setCurrentPage(1);
      await getProductUnutilizedBalanceList({ pageNumber: 1, pageSize: pageSize, branchId: initialData.branchId, dateRange: initialData.dateRange, productIds: validation.values.productIds });
      setLocalLoading(false);
      return;
    }

    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    setCurrentPage(1);
    await getProductUnutilizedBalanceList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, productIds: validation.values.productIds, });
    setLocalLoading(false);
	};

  const exportProductUnutilizedBalanceAPI = async () => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    await exportProductUnutilizedBalance({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, productIds: validation.values.productIds });
    setLocalLoading(false);
	};

  //Table Content UI
  const ProductUnutilizedBalanceColumns = [
    { 
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerNo}</div>
          }          
        </>
      )
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "totalPurchasedQuantity",
      text: intl.formatMessage({ id: "TotalPurchasedQuantity" }).toUpperCase()
    },
    {
      dataField: "totalAvailableQuantity",
      text: intl.formatMessage({ id: "TotalAvailableQuantity" }).toUpperCase()
    },
    {
      dataField: "totalRedeemedQuantity",
      text: intl.formatMessage({ id: "TotalRedeemedQuantity" }).toUpperCase()
    }
  ];

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "UnutilizedProductBalanceDetails" })}
        rowFieldName={"unutilizedProductBalanceDetails"}
        rowColumns={aryUnutilizedProductBalanceDetails}
        keyFieldName={"customerSalesOrderId"}
        linkPathName={`/${RoutesList.customerSalesOrder}/view/`}
        linkFieldName={"customerSalesOrderId"}
        statusColorFunction={paymentStatusColor}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ProductUnutilizedBalance"})})}
        title={intl.formatMessage({ id: "ProductUnutilizedBalance" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportProductUnutilizedBalanceAPI()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Product-Unutilized-Balance.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <Row>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange}
                validationRequired={true}
                initialValue={selectedDateRange} />
            </Col>
              <Col xl="3">
                <DropDownWithTitleMultiSelect 
                  title={intl.formatMessage({ id: "Product" })}
                  name={"productIds"} 
                  options={productDropdownList} 
                  valueField={"id"} 
                  labelField={"name"}
                  specifyReturnFieldName={[{ "field": "productIds", "value": "id" }]}
                  returnFieldWithLabel={false} 
                  disabled={localLoading || loading} 
                  validationRequired={false}
                  validation={validation} />
              </Col>
            <Col xl="2">
              <MyButton
                type="button"
                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={()=> {
                  fetchProductUnutilizedBalanceWithLoading()
                }}
              />
            </Col>
          </Row>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ProductUnutilizedBalanceColumns}
            data={productUnutilizedBalanceList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ProductUnutilizedBalance"})})}
            objSorted={defaultSorted}
            keyField={"randomId"}
            requestAPI={getProductUnutilizedBalanceList}
            branchId={selectedBranchId}
            dateRange={selectedDateRange}
            productIds={validation.values.productIds}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ProductUnutilizedBalance);
