import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import { CommissionCalculationListGetAndExportObject, CommissionCalculationListObject, EarnSalesSummaryListGetAndExportObject, EarnSalesSummaryListObject, ContractLiabilityReportListGetAndExportObject, ContractLiabilityReportListObject, UnearnedSalesDetailsListObject, UnearnedSalesDetailsListGetAndExportObject, RecogniseSalesDetailsListObject, RecogniseSalesDetailsListGetAndExportObject, PaymentCollectionObject, PaymentCollectionObjectExportObject, ChangePaymentModeReportListObject, ChangePaymentModeListGetAndExportObject, PaymentCollectionDetailListObject } from "../models/reportAccount";
import { Constants } from "../constants/Constants";

export default class ReportAccountStore {
  commissionCalculationList: CommissionCalculationListObject[] = [];
  commissionCalculationNotes: string = "";
  contractLiabilityReportList: ContractLiabilityReportListObject[] = [];
  earnSalesSummaryList: EarnSalesSummaryListObject[] = [];
  unearnedSalesDetailsList: UnearnedSalesDetailsListObject[] = [];
  recogniseSalesDetailsList: RecogniseSalesDetailsListObject[] = [];
  paymentCollection: PaymentCollectionObject | undefined = undefined;
  paymentCollectionDetailList: PaymentCollectionDetailListObject[] = [];
  changePaymentModeList: ChangePaymentModeReportListObject[] = [];
  paymentCollectionDetailListController: any = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.commissionCalculationList = [];
    this.commissionCalculationNotes = "";
    this.contractLiabilityReportList = [];
    this.earnSalesSummaryList = [];
    this.unearnedSalesDetailsList = [];
    this.recogniseSalesDetailsList = [];
    this.paymentCollection = undefined;
    this.paymentCollectionDetailList = [];
    this.changePaymentModeList = [];
  }

  setCommissionCalculationList = (commissionCalculationList: CommissionCalculationListObject[]) => {
    this.commissionCalculationList = commissionCalculationList;
  }

  setCommissionCalculationNotes = (commissionCalculationNotes: string) => {
    this.commissionCalculationNotes = commissionCalculationNotes;
  }

  setContractLiabilityReportList = (contractLiabilityReportList: ContractLiabilityReportListObject[]) => {
    this.contractLiabilityReportList = contractLiabilityReportList;
  }

  setEarnSalesSummaryList = (earnSalesSummaryList: EarnSalesSummaryListObject[]) => {
    this.earnSalesSummaryList = earnSalesSummaryList;
  }

  setUnearnedSalesDetailsList = (unearnedSalesDetailsList: UnearnedSalesDetailsListObject[]) => {
    this.unearnedSalesDetailsList = unearnedSalesDetailsList;
  }

  setRecogniseSalesDetailsList = (recogniseSalesDetailsList: RecogniseSalesDetailsListObject[]) => {
    this.recogniseSalesDetailsList = recogniseSalesDetailsList;
  }

  setPaymentCollection = (paymentCollection: PaymentCollectionObject | undefined) => {
    this.paymentCollection = paymentCollection;
  }

  setPaymentCollectionDetailList = (paymentCollectionDetailList: PaymentCollectionDetailListObject[]) => {
    this.paymentCollectionDetailList = paymentCollectionDetailList;
  }

  setChangePaymentModeList = (changePaymentModeList: ChangePaymentModeReportListObject[]) => {
    this.changePaymentModeList = changePaymentModeList;
  }

  setPaymentCollectionDetailListController = (paymentCollectionDetailListController: any) => {
    this.paymentCollectionDetailListController = paymentCollectionDetailListController;
  }

  getCommissionCalculationList = async (commissionCalculationListGetObject: CommissionCalculationListGetAndExportObject) => {
    try{
      let resultCommissionCalculation = await agent.ReportAccount.commissionCalculationList(commissionCalculationListGetObject);
      let resultCommissionCalculationList = resultCommissionCalculation.details;

      let totalGroupInvoiceSales = 0;
      let totalGroupEarnedSales = 0;
      let totalGroupSharedSales = 0;
      let totalGroupTotalSales = 0;
      let totalProductInvoiceSales = 0;
      let totalProductEarnedSales = 0;
      let totalProductSharedSales = 0;
      let totalProductTotalSales = 0;
      let finalTotalCommissionSales = 0;
      let finalTotalCommissionSalesAdjustment = 0;
      let finalTotalInvoiceSales = 0;
      let finalTotalPaymentCollection = 0;
      let totalPaymentCollectOnbehalfDeduction = 0;
      let totalPaymentCollectOnbehalfAddition = 0;

      resultCommissionCalculationList = resultCommissionCalculationList.map((valueCommissionCalculation, indexCommissionCalculation)=> {
        totalGroupInvoiceSales += valueCommissionCalculation.groupInvoiceSales;
        totalGroupEarnedSales += valueCommissionCalculation.groupEarnedSales;
        totalGroupSharedSales += valueCommissionCalculation.groupSharedSales;
        totalGroupTotalSales += valueCommissionCalculation.groupTotalSales;
        totalProductInvoiceSales += valueCommissionCalculation.productInvoiceSales;
        totalProductEarnedSales += valueCommissionCalculation.productEarnedSales;
        totalProductSharedSales += valueCommissionCalculation.productSharedSales;
        totalProductTotalSales += valueCommissionCalculation.productTotalSales;
        finalTotalCommissionSales += valueCommissionCalculation.totalCommissionSales;
        finalTotalCommissionSalesAdjustment += valueCommissionCalculation.totalCommissionSalesAdjustment;
        finalTotalInvoiceSales += valueCommissionCalculation.totalInvoiceSales;
        finalTotalPaymentCollection += valueCommissionCalculation.totalPaymentCollection
        totalPaymentCollectOnbehalfDeduction += valueCommissionCalculation.paymentCollectOnbehalfDeduction
        totalPaymentCollectOnbehalfAddition += valueCommissionCalculation.paymentCollectOnbehalfAddition

        return ({
          ...valueCommissionCalculation,
          randomId: `${valueCommissionCalculation.branchName}_${indexCommissionCalculation}`
        })
      })
      
      if (resultCommissionCalculationList.length > 0) {
        resultCommissionCalculationList.splice(0, 0, {
          businessEntityName: Constants.emptyData,
          branchName: Constants.emptyData,
          subBrandName: Constants.emptyData,
          groupInvoiceSales: 0,
          groupEarnedSales: 0,
          groupSharedSales: 0,
          groupTotalSales: 0,
          productInvoiceSales: 0,
          productEarnedSales: 0,
          productSharedSales: 0,
          productTotalSales: 0,
          totalCommissionSales: 0,
          totalCommissionSalesAdjustment: 0,
          totalInvoiceSales: 0,
          totalPaymentCollection: 0,
          paymentCollectOnbehalfDeduction: 0,
          paymentCollectOnbehalfAddition: 0,
          subBrandId: "",
          branchId: "",
          randomId: "index-0"
        });
      } 

      if (resultCommissionCalculationList.length > 2) {
        resultCommissionCalculationList.push({
          businessEntityName: Constants.emptyData,
          branchName: Constants.emptyData,
          subBrandName: Constants.emptyData,
          groupInvoiceSales: totalGroupInvoiceSales,
          groupEarnedSales: totalGroupEarnedSales,
          groupSharedSales: totalGroupSharedSales,
          groupTotalSales: totalGroupTotalSales,
          productInvoiceSales: totalProductInvoiceSales,
          productEarnedSales: totalProductEarnedSales,
          productSharedSales: totalProductSharedSales,
          productTotalSales: totalProductTotalSales,
          totalCommissionSales: finalTotalCommissionSales,
          totalCommissionSalesAdjustment: finalTotalCommissionSalesAdjustment,
          totalInvoiceSales: finalTotalInvoiceSales,
          totalPaymentCollection: finalTotalPaymentCollection,
          paymentCollectOnbehalfDeduction: totalPaymentCollectOnbehalfDeduction,
          paymentCollectOnbehalfAddition: totalPaymentCollectOnbehalfAddition,
          subBrandId: "",
          branchId: "",
          randomId: "index-9999"
        });
      }

      runInAction(() => {
        this.commissionCalculationList = resultCommissionCalculationList;
        this.commissionCalculationNotes = resultCommissionCalculation.notes;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionCalculationList = [];
      this.commissionCalculationNotes = "";
    }
  }

  getContractLiabilityReportList = async (contractLiabilityReportListGetAndExportObject: ContractLiabilityReportListGetAndExportObject) => {
    try{
      let resultContractLiabilityReportList = await agent.ReportAccount.contractLiabilityReportList(contractLiabilityReportListGetAndExportObject);
      resultContractLiabilityReportList.data = resultContractLiabilityReportList.data.map((valueContractLiabilityReportTemp)=> {
        valueContractLiabilityReportTemp.salesOrderDetails = valueContractLiabilityReportTemp.salesOrderDetails.map((valueSalesOrderDetails, index)=> ({
          ...valueSalesOrderDetails,
          randomId: `${valueSalesOrderDetails.itemId}_${index}`
        }));
        return valueContractLiabilityReportTemp;
      });

      runInAction(() => {
        this.contractLiabilityReportList = resultContractLiabilityReportList.data;
        store.commonStore.setTotalItem(resultContractLiabilityReportList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityReportList = [];
    }
  }

  getEarnSalesSummaryList = async (earnSalesSummaryListGetAndExportObject: EarnSalesSummaryListGetAndExportObject) => {
    try{
      let resultEarnSalesSummaryList = await agent.ReportAccount.earnSalesSummaryList(earnSalesSummaryListGetAndExportObject);

      runInAction(() => {
        this.earnSalesSummaryList = resultEarnSalesSummaryList;
      });

      return Promise.resolve({ status: Constants.success, data: resultEarnSalesSummaryList })
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.earnSalesSummaryList = [];
      return Promise.resolve({ status: Constants.failed, data: [] })
    }
  }

  getUnearnedSalesDetailsList = async (unearnedSalesDetailsListGetAndExportObject: UnearnedSalesDetailsListGetAndExportObject) => {
    try{
      let resultUnearnedSalesDetailsList = await agent.ReportAccount.unearnedSalesDetailsList(unearnedSalesDetailsListGetAndExportObject);
      resultUnearnedSalesDetailsList.data = resultUnearnedSalesDetailsList.data.map((valueUnearnedSalesDetailTemp, index)=> ({
        ...valueUnearnedSalesDetailTemp,
        randomId: `${valueUnearnedSalesDetailTemp.itemId}_${index}`
      }));

      runInAction(() => {
        this.unearnedSalesDetailsList = resultUnearnedSalesDetailsList.data;
        store.commonStore.setTotalItem(resultUnearnedSalesDetailsList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.unearnedSalesDetailsList = [];
    }
  }

  getRecogniseSalesDetailsList = async (recogniseSalesDetailsListGetAndExportObject: RecogniseSalesDetailsListGetAndExportObject) => {
    try{
      let resultRecogniseSalesDetailsList = await agent.ReportAccount.recogniseSalesDetailsList(recogniseSalesDetailsListGetAndExportObject);
      resultRecogniseSalesDetailsList.data = resultRecogniseSalesDetailsList.data.map((valueRecogniseSalesDetailTemp, index)=> ({
        ...valueRecogniseSalesDetailTemp,
        randomId: `${valueRecogniseSalesDetailTemp.customerRedemptionId}_${index}`
      }));

      runInAction(() => {
        this.recogniseSalesDetailsList = resultRecogniseSalesDetailsList.data;
        store.commonStore.setTotalItem(resultRecogniseSalesDetailsList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.recogniseSalesDetailsList = [];
    }
  }

  getPaymentCollection = async (paymentCollectionGetObject: PaginationRequestBody | any) => {
    if (paymentCollectionGetObject.dateRange) {
      paymentCollectionGetObject.fromDate = paymentCollectionGetObject.dateRange.fromDate;
      paymentCollectionGetObject.toDate = paymentCollectionGetObject.dateRange.toDate;
    }
    
    try{
      let resultPaymentCollection = await agent.ReportAccount.paymentCollection(paymentCollectionGetObject);
      runInAction(() => {
        this.paymentCollection = resultPaymentCollection;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentCollection = undefined;
    }
  }

  getPaymentCollectionDetailList = async (paymentCollectionGetObject: PaginationRequestBody | any) => {
    if (paymentCollectionGetObject.dateRange) {
      paymentCollectionGetObject.fromDate = paymentCollectionGetObject.dateRange.fromDate;
      paymentCollectionGetObject.toDate = paymentCollectionGetObject.dateRange.toDate;
    }
    
    if (this.paymentCollectionDetailListController) {
      this.paymentCollectionDetailListController.abort()
      this.setPaymentCollectionDetailListController(undefined);
    }

    try{
      const paymentCollectionDetailListControllerTemp = new AbortController();
      this.setPaymentCollectionDetailListController(paymentCollectionDetailListControllerTemp);
      let resultPaymentCollectionDetailList = await agent.ReportAccount.paymentCollectionDetailList(paymentCollectionGetObject, this.paymentCollectionDetailListController.signal);
      resultPaymentCollectionDetailList.data = resultPaymentCollectionDetailList.data.map((valuePaymentCollectionDetails, index)=> ({
        ...valuePaymentCollectionDetails,
        randomId: `${valuePaymentCollectionDetails.documentNos}_${index}`
      }))
      runInAction(() => {
        this.paymentCollectionDetailList = resultPaymentCollectionDetailList.data;
        store.commonStore.setTotalItem(resultPaymentCollectionDetailList.pagination.totalItems);
        this.setPaymentCollectionDetailListController(undefined);
      });
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      if (Array.isArray(error) || error.message) {
        return Promise.resolve({status: Constants.failed})
      }
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentCollectionDetailList = [];
    }
  }

  getChangePaymentModeList = async (changePaymentModeListGetAndExportObject: ChangePaymentModeListGetAndExportObject) => {
    try{
      let resultChangePaymentModeList = await agent.ReportAccount.changePaymentModeList(changePaymentModeListGetAndExportObject);
      if (resultChangePaymentModeList.data.length > 0) {
        resultChangePaymentModeList.data.splice(0, 0, {
          requestDate: "",
          documentNo: "",
          outlet: "",
          customerName: "",
          identityNo: "",
          remark: "",
          status: "",
          isSecondPaymentAdminEnabled: false,
          isMultiplePaymentDetails: false,
          oldPaymentDetails: [],
          newPaymentDetails: []
        });
      }

      resultChangePaymentModeList.data = resultChangePaymentModeList.data.map((valueChangePaymentModeTemp, index)=> {
        let oldPaymentLength = valueChangePaymentModeTemp.oldPaymentDetails.length;
        let newPaymentLength = valueChangePaymentModeTemp.newPaymentDetails.length;
        if (oldPaymentLength !== newPaymentLength) {
          let paymentVariable = oldPaymentLength > newPaymentLength ? "newPaymentDetails" : "oldPaymentDetails";
          let totalDifferent = oldPaymentLength > newPaymentLength ? oldPaymentLength - newPaymentLength : newPaymentLength - oldPaymentLength;
          for (let a=0; a<totalDifferent; a++) {
            valueChangePaymentModeTemp[paymentVariable].push({
              transactionDate: "",
              paymentMode: "",
              cardOrAccountNo: "",
              amount: 0
            }) 
          } 
        }
        return ({
          ...valueChangePaymentModeTemp,
          randomId: `${valueChangePaymentModeTemp.documentNo}_${index}`
        })
      });

      runInAction(() => {
        this.changePaymentModeList = resultChangePaymentModeList.data;
        store.commonStore.setTotalItem(resultChangePaymentModeList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.changePaymentModeList = [];
    }
  }

  exportCommissionCalculation = async (commissionCalculationListExportRequestBody: CommissionCalculationListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/commissionCalculation/export`, commissionCalculationListExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportContractLiabilityReport = async (contractLiabilityReportListGetAndExportObject: ContractLiabilityReportListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/contractliability/export`, contractLiabilityReportListGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportEarnSalesSummary = async (earnSalesSummaryListGetAndExportObject: EarnSalesSummaryListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/earnsalessummary/export`, earnSalesSummaryListGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportUnearnedSalesDetails = async (unearnedSalesDetailsListGetAndExportObject: UnearnedSalesDetailsListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/unearnedsalesdetails/export`, unearnedSalesDetailsListGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportRecogniseSalesDetails = async (recogniseSalesDetailsListGetAndExportObject: RecogniseSalesDetailsListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/recognisesalesdetails/export`, recogniseSalesDetailsListGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportPaymentCollection = async (paymentCollectionObjectExportObject: PaymentCollectionObjectExportObject) => {
    try{
      await downloadExcel(`/report/account/paymentcollection/export`, paymentCollectionObjectExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportChangePaymentMode = async (changePaymentModeListGetAndExportObject: ChangePaymentModeListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/account/changePaymentMode/export`, changePaymentModeListGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}