import { PaginationRequestBody } from "../models/pagination";
import { CustomerTransferRequestAddObject, CustomerTransferRequestDetailObject, CustomerTransferRequestListObject, CustomerTransferRequestUpdateObject } from "../models/customerTransferRequest";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
export default class CustomerTransferRequestStore {
  customerTransferRequestList: CustomerTransferRequestListObject[] = [];
  customerTransferRequestDetail: CustomerTransferRequestDetailObject | undefined = undefined;
  customerTransferRequestPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.customerTransferRequestList = [];
    this.customerTransferRequestDetail = undefined;
    this.customerTransferRequestPaginationParams= undefined;
  }

  setCustomerTransferRequestList = (customerTransferRequestList: CustomerTransferRequestListObject[]) => {
    this.customerTransferRequestList = customerTransferRequestList;
  }
  
  setCustomerTransferRequestDetail = (customerTransferRequestDetail: CustomerTransferRequestDetailObject | undefined) => {
    this.customerTransferRequestDetail = customerTransferRequestDetail;
  }

  setCustomerTransferRequestPaginationParams = (customerTransferRequestPaginationParams: PaginationRequestBody | undefined) => {
    this.customerTransferRequestPaginationParams = customerTransferRequestPaginationParams;
  }

  getCustomerTransferRequest = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCustomerTransferRequestPaginationParams(PaginationRequestBody);
    try {
      const resultCustomerTransferRequests = await agent.CustomerTransferRequest.customerTransferRequestList(PaginationRequestBody);
      runInAction(() => {
        this.customerTransferRequestList = resultCustomerTransferRequests.data;
        store.commonStore.setTotalItem(resultCustomerTransferRequests.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerTransferRequestList = [];
    }
  };

  getCustomerTransferRequestWithId = async (id: string) => {
    try{
      const resultCustomerTransferRequestDetail = await agent.CustomerTransferRequest.customerTransferRequestDetail(id);
      runInAction(() => {
        this.customerTransferRequestDetail = resultCustomerTransferRequestDetail;
      });
      return Promise.resolve(resultCustomerTransferRequestDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerTransferRequestDetail = undefined;
    }
  }

  addCustomerTransferRequest = async (customerTransferRequestRequestBody: CustomerTransferRequestAddObject) => {
    try{
      let resultTransferRequest = await agent.CustomerTransferRequest.addCustomerTransferRequest(customerTransferRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerTransferRequestAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultTransferRequest})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: undefined})
    }
  }

  updateCustomerTransferRequest = async (customerTransferRequestRequestBody: CustomerTransferRequestUpdateObject) => {
    try{
      await agent.CustomerTransferRequest.updateCustomerTransferRequest(customerTransferRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerTransferRequestUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomerTransferRequest  = async (id: string) => {
    try {
      await agent.CustomerTransferRequest.deleteCustomerTransferRequest(id);
      store.commonStore.setSuccessMessage(`CustomerTransferRequestDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
