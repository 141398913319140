import {
  Alert
}  from "reactstrap";
import { ServerError } from '../../models/serverError';
import { useIntl } from 'react-intl';
import englishLanguaugeFile from '../../../lang/en.json';

interface Props {
  title?: string ;
  errorMessage?: string | ServerError | null;
  successMessage?: string;
  isModal?: boolean;
}

export default function AlertMessage(props: Props) {
  const intl = useIntl();
  return (
    <Alert style={props.isModal ? { marginTop: '24px', marginBottom: '0px'} : undefined} color={(props.errorMessage && "danger") || (props.successMessage && "success")} role="alert">
      {props.title && <h4 className="alert-heading">{props.title}</h4>}
      {
        typeof(props.errorMessage) === 'string' && (props.errorMessage in englishLanguaugeFile)
        ?
        intl.formatMessage({id : props.errorMessage})
        :
        props.errorMessage
      }
      {!props.errorMessage && props.successMessage}
    </Alert>
  )
}
