import React, { Fragment } from 'react'
import { CardText, Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Borderline from '../../../app/components/form/BorderLine';
import GeneralTextArea from '../../../app/components/form/GeneralTextArea';
import { RedeemPurchaseDetailObject } from '../../../app/models/customerOrder';
import { useIntl } from "react-intl";
import { Constants } from '../../../app/constants/Constants';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { checkPermission, displayOptionalProducts } from '../../../app/common/function/function';
import GeneralInput from '../../../app/components/form/GeneralInput';
import { PermissionConstants } from '../../../app/constants/PermissionConstants';
import moment from 'moment';
interface Props {
  validation: any;
  disabledCustomerOrder: boolean;
  selectCustomerPurchase?: Function;
  deleteCustomerPurchase?: Function;
  blnTotalBalance?: boolean;
  blnDisplayNote: boolean;
  blnAcknowledgementPage?: boolean;
}

export default function CustomerPurchaseCart (props: Props) {
  const intl = useIntl();
  const blnPermissionBackdateCustomerDeliverOrder= checkPermission([PermissionConstants.BackdateCustomerDeliverOrder]);

  const displayCustomerOrder = () => {
    if (props.validation.values.redeemPurchaseDetails.length < 1) {
      return (
        <div style={{height: '20px'}}>
            <span className="small-text">{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({id: "Item"}).toLowerCase() })}</span> 
            {props.validation.touched.redeemPurchaseDetails && props.validation.errors.redeemPurchaseDetails ? (
              <Label className="error margin-left-8" style={{fontSize: "12px"}}>{`* ${intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })}`}</Label>
            ) : null}
        </div>)
    }

    return (
      <div>
        <Row className='padding-horizontal-8px'>
          {/* <div className="avatar-sm"> */}
          <Col xs="2" md="2" xl="2" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Count" })}</span>
          </Col>
          <Col xs="8" md="8" xl="8" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Name" })}</span>
          </Col>
          {
            !props.disabledCustomerOrder
            &&
            <Col xs="2" md="2" xl="2" className="flex-justfity-content-center flex-align-items-center-without-height text-center">
              <span className="tab-bar-title text-center">{intl.formatMessage({ id: "Action" })}</span>
            </Col>
          }
          {/* </div> */}
        </Row>
        <hr/>
        {
          props.validation.values.redeemPurchaseDetails.map((value: RedeemPurchaseDetailObject, index) => (           
            <Row className="mb-4 padding-horizontal-8px" key={value.customerPurchaseDetailId+value.customerInvoiceId+value.packageId}>
              {/* <div className="avatar-sm"> */}
              <Col xs="2" md="2" xl="2"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectCustomerPurchase && !props.disabledCustomerOrder) props.selectCustomerPurchase(index) }}>
                <span className="avatar-sm circle-with-text rounded-circle" style={{backgroundColor: `${(value.purchaseDetailRedeemType === 0 || value.purchaseDetailRedeemType === 3 ) ? Constants.lightpurple : (value.purchaseDetailRedeemType  === 1 || value.purchaseDetailRedeemType === 2) ? Constants.orange : Constants.brown}`}}>{value.count}</span>
              </Col>
              <Col xs="8" md="8" xl="8"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectCustomerPurchase && !props.disabledCustomerOrder) props.selectCustomerPurchase(index) }}>
                <div>
                  <span>{`${value.name} ${value.purchaseDetailRedeemType === 0 ? ` ${intl.formatMessage({ id: "From" }).toLowerCase()} ${value.packageName} (${value.customerInvoiceNo})` : `(${value.customerInvoiceNo})`}`}</span>
                  <br/>
                  <span style={{fontWeight: 400, fontSize: '13px', color: 'grey'}}>
                    {
                      value.purchaseDetailRedeemType === 0 || value.purchaseDetailRedeemType === 3
                      ?
                      `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${value.availableSessions}`
                      :
                      value.purchaseDetailRedeemType === 1
                      ?
                      `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${value.availableQuantity}`
                      :
                      value.purchaseDetailRedeemType === 2
                      ?
                      `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${value.quantity!-value.redeemdedQuantity!}`
                      :
                      ``
                      // `(${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "LeftOver" })} ${intl.formatMessage({ id: "OptionalProductCost" })} : ${value.optionalProductCost!-value.redeemedProductCost!})`
                    }
                  </span>
                  {value.mandatoryProductsUsed ? value.mandatoryProductsUsed.length > 0 ? displayOptionalProducts(value.mandatoryProductsUsed, intl) : <div/> : <div/>}
                  {value.serviceProductUsed ? value.serviceProductUsed.length > 0 ? displayOptionalProducts(value.serviceProductUsed, intl) : <div/> : <div/>}
                  {(value.purchaseDetailRedeemType === 0 || value.purchaseDetailRedeemType === 3) && !(value.mandatoryProductsUsed ? value.mandatoryProductsUsed.length > 0 ? true : false : false) && !(value.serviceProductUsed ? value.serviceProductUsed.length > 0 ? true : false : false) && displayOptionalProducts([], intl)}
                </div>
              </Col>
              {
                !props.disabledCustomerOrder
                &&
                <Col xs="2" md="2" xl="2" className="text-danger flex-justfity-content-center" onClick={()=> { if (props.deleteCustomerPurchase && !props.disabledCustomerOrder) props.deleteCustomerPurchase(index) }}>
                  <DeleteIcon size={18} id="deletetooltip" className="margin-top-8"/> 
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {intl.formatMessage({ id: "Delete" })}
                  </UncontrolledTooltip>
                </Col>
              }
              {
                value?.validationRequired &&
                <Label className="error margin-top-8 margin-left-8" style={{fontSize: "12px"}}>{`* ${intl.formatMessage({ id: "PleaseFillInRequiredField" })} `}</Label>
              }
              {/* </div> */}
            </Row>
          ))
        }
      </div>
    )
  }

  return (
    <div>
      {/*Item Selected */}
      {
        displayCustomerOrder()
      }
      <Borderline/>
      {
        blnPermissionBackdateCustomerDeliverOrder
        &&
        !props.blnAcknowledgementPage
        &&
        props.blnDisplayNote
        &&
        <GeneralInput
          title={""}
          name="isBackdate"
          type="checkbox"
          disabled={props.disabledCustomerOrder}
          validation={props.validation}
          childrenUI={
            <Label className="margin-bottom-0 margin-left-4">
              {intl.formatMessage({ id: "IsApplyPerformedDateTime" })}
            </Label>
          }
          onChangeFunctionWithSetValidationField={(valueChecked)=> {
            props.validation.setFieldValue("performedDateTime", valueChecked ? moment().format(Constants.textInputDateAndTimeFormat) : "");
          }}
        />
      }
      {
        blnPermissionBackdateCustomerDeliverOrder
        &&
        props.validation.values.isBackdate
        &&
        props.blnDisplayNote
        &&
        <div>
          <GeneralInput
            title={props.blnAcknowledgementPage ? intl.formatMessage({ id: "PerformedDateTime" }) : ""}
            name="performedDateTime"
            type="datetime-local"
            disabled={props.disabledCustomerOrder}
            validationRequired={false}
            validation={props.validation}
          />
        </div>
      }
      {
        ((blnPermissionBackdateCustomerDeliverOrder && !props.blnAcknowledgementPage) || props.validation.values.isBackdate)
        &&
        props.blnDisplayNote
        &&
        <Borderline/>
      }
      {
        props.blnDisplayNote
        &&
        <div>
          <GeneralTextArea
          title={intl.formatMessage({ id: "Note" })}
          name="note"
          row={5}
          disabled={props.disabledCustomerOrder}
          validation={props.validation}/>
          <Borderline/>
        </div>
      }
    </div>
  )
}
