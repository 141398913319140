import { PaginationRequestBody } from "../models/pagination";
import { CashOutListObject, CashOutAddObject, CashOutUpdateObject, CashOutDetailObject } from "../models/cashOut";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import moment from "moment";
import { newSpaceBeforeCapitalLetter } from "../common/function/function";

export default class cashOutStore {
  cashOutList: CashOutListObject[] = [];
  cashOutDropdownList: CashOutListObject[] = [];
  cashOutDetail: CashOutDetailObject | undefined = undefined;
  cashOutPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.cashOutList = [];
    this.cashOutDropdownList = [];
    this.cashOutDetail = undefined;
    this.cashOutPaginationParams= undefined;
  }

  setCashOutDetail = (cashOutDetail: CashOutDetailObject | undefined) => {
    this.cashOutDetail = cashOutDetail;
  }

  setCashOutPaginationParams = (cashOutPaginationParams: PaginationRequestBody | undefined) => {
    this.cashOutPaginationParams = cashOutPaginationParams;
  }
  
  getCashOut = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCashOutPaginationParams(PaginationRequestBody);
    try{
      const resultCashOut = await agent.CashOut.cashOutList(PaginationRequestBody);
      runInAction(() => {
        this.cashOutList = resultCashOut.data;
        store.commonStore.setTotalItem(resultCashOut.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.cashOutList = [];
    }
  }

  getCashOutWithId = async (id: string) => {
    try{
      const resultCashOutDetail = await agent.CashOut.cashOutDetail(id);
      if (resultCashOutDetail) {
        if (resultCashOutDetail.cashOutDetails) {
          resultCashOutDetail.cashOutDetails = resultCashOutDetail.cashOutDetails.map((valueCashOutDetailTemp)=> ({
            ...valueCashOutDetailTemp,
            transactionDate: moment(valueCashOutDetailTemp.transactionDate).format(Constants.textInputDateAndTimeFormat),
            transactionTypeDisplay: newSpaceBeforeCapitalLetter(valueCashOutDetailTemp.transactionType)
          }))
        }
      }
      runInAction(() => {
        this.cashOutDetail = resultCashOutDetail;
      });
      return Promise.resolve(resultCashOutDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.cashOutDetail = undefined;
    }
  }

  addCashOut = async (cashOutRequestBody: CashOutAddObject) => {
    try{
      await agent.CashOut.addCashOut(cashOutRequestBody);
      store.commonStore.setSuccessMessage(`CashOutAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCashOut = async (cashOutRequestBody: CashOutUpdateObject) => {
    try{
      await agent.CashOut.updateCashOut(cashOutRequestBody);
      store.commonStore.setSuccessMessage(`CashOutUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCashOut  = async (id: string, name: string) => {
    try {
      await agent.CashOut.deleteCashOut(id);
      store.commonStore.setSuccessMessage(`CashOutDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}