import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import {
  appendCountryCode,
  appointmentStatusColor,
  checkPermission,
	contructValidationErrorMessage,
	getBranchId,
	getBranchUser,
	getBrandId,
	IncludesLocationPathName,
  newSpaceBeforeCapitalLetter,
  returnSuccessMarkedIcon,
  returnCurrentOnlyDate,
  returnOperatingHours,
  returnSubBrandList,
  returnTableCellUI,
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
	Container,
	Row,
	Col,
	Form,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import moment from "moment";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useLocation } from "react-router-dom";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { burgerMenu, ObjectForDropdown } from "../../app/models/common";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import { BusinessHourForBranchObject } from "../../app/models/branch";
import { AppointmentCustomerPurchasedListObject, AppointmentCustomerTreatmentListObject, AppointmentServiceObject, ExistingCustomerObject, ExistingLeadObject, FutureValidAppointmentObject } from "../../app/models/appointment";
import { history } from "../..";
import { ServiceTreatmentListObject } from "../../app/models/serviceTreatment";
import { EmployeeListObject } from "../../app/models/employee";
import ReferredByCustomerSearchModal from "./Modal/ReferredByCustomerSearchModal";
import AppointmentDuplicatedModal from "./Modal/AppointmentDuplicatedModal";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { isMobile, isTablet } from "react-device-detect";
import { MediaSourceListObject } from "../../app/models/mediaSource";

const AppointmentDetail = () => {
	//Use Params
	const { id } = useParams();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const {
		appointmentStore,
		staticReferenceStore,
		branchStore,
		commonStore,
    employeeStore,
    customerStore,
    serviceTreatmentStore,
    mediaSourceStore,
    leadStore,
    profileStore,
    raceStore,
    settingsStore
	} = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
    windowSize,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const {
    roomList,
		addAppointment,
		updateAppointment,
		appointmentDetail,
    appointmentHistory,
    appointmentErrorPrompt,
    setAppointmentErrorPrompt,
		setAppointmentDetail,
    setRoomList,
    setAppointmentHistory,
		getAppointmentWithId,
    getRoomWithBranchId,
    getAppointmentCustomerTreatmentList,
    getAppointmentHistoryList,
    getAppointmentCustomerPurchasedPackageList
	} = appointmentStore;
  const {
    employeeDropdownList,
    getEmployeeDropdown,
    setEmployeeDropdownList
  } = employeeStore;
	const {
    appointmentSourceType,
    identityType,
    genderType,
		getStaticReferenceWithType
	} = staticReferenceStore;
	const { branchDropDownList, branchBusinessHour, getBranchDropDown, getBranchBusinessHour, setBranchBusinessHour } = branchStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { mediaSourceDropdownList, getMediaSourceDropdown } = mediaSourceStore;
  const { leadDropdownList, getLeadDropdown } = leadStore;
  const { profileDetail } = profileStore;
  const { raceDropdownList, getRaceDropdown } = raceStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${
		addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
	} ${intl.formatMessage({id: "Appointment"})}`;
	const breadCrumbList = [{ title: `${intl.formatMessage({id: "Appointment"})}`, urlPath: RoutesList.appointment }];
  const [timeList, setTimeList] = useState<ObjectForDropdown[]>([]);
  const [refreshTime, setRefreshTime] = useState(false);
  const [refreshEndTime, setRefreshEndTime] = useState(false);
  const [refreshMediaSourceDropdown, setRefreshMediaSourceDropdown] = useState(false);
  const location = useLocation();
  const [selectedServiceTreatmentIds, setSelectedServiceTreatmentIds] = useState<any>([]);
  const [loadingBranchAfterSelect, setLoadingBranchAfterSelect] = useState(false);
  const [appointmentSourceTypeList, setAppointmentSourceTypeList] = useState<ObjectForDropdown[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [customerCriteriaSearchModal, setCustomerCriteriaSearchModal] = useState(false);
  const [leadDisabled, setLeadDisable] = useState(false);
  const [subBrandDisabled, setSubBrandDisable] = useState(false);
  const [existingIdHandling, setExistingIdHandling] = useState(false);
  const optionsServiceTreatment = [
    { label: intl.formatMessage({ id: Constants.allServiceTreatment }), value: Constants.allServiceTreatment },
    { label: intl.formatMessage({ id: Constants.purchasedPackage }), value: Constants.purchasedPackage },
    { label: intl.formatMessage({ id: Constants.followUpOnly }), value: Constants.followUpOnly },
  ];
  const [optionsServiceTreatmentChecked, setOptionsServiceTreatmentChecked] = useState<any>(Constants.allServiceTreatment);
  const [purchaseServiceTreatmentList, setPurchaseServiceTreatmentList] = useState<AppointmentServiceObject[] | undefined>(undefined);
  const [purchasePackageList, setPurchasePackageList] = useState<AppointmentCustomerPurchasedListObject[] | any | undefined>(undefined);
  const [loadingServiceTreamtment, setLoadingServiceTreatment] = useState(false);
  const [loadingServicePackage, setLoadingPackage] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const blnPermissionUpdateAppointment = checkPermission([PermissionConstants.UpdateAppointment]);
  const blnPermissionManageLead = checkPermission([PermissionConstants.ManageLead]);
  const blnPermissionReviseClosedAppointment = checkPermission([PermissionConstants.ReviseClosedAppointment]);
  const [leadConsultantList, setLeadConsultantList] = useState<EmployeeListObject[]>([]);
  const [blnMalaysia, setBlnMalaysia] = useState(true);
  const [blnRefreshMalaysiaUI, setBlnRefreshMalaysiaUI] = useState(false);
  const blnMarcomUser = profileDetail?.roleId === Constants.marComUserRoleId || profileDetail?.roleId === Constants.marComCustomerServiceId 
  const blnCSCUser = profileDetail?.roleId === Constants.customerServiceId
  const blnShowAppolous = generalSettings?.isShowAppolous;
  const blnAppointmentWithBackDate = generalSettings?.isAllowBackDateAppointment;
  const blnMandatoryAppointmentGender = generalSettings?.isMandatoryAppointmentGender;
  const [blnReferralType, setBlnReferralType] = useState(false);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [blnStatusNotShowOrCompletedDetected, setBlnStatusNotShowOrCompletedDetected] = useState(false);
  const [futureValidAppointmentModal, setFutureValidAppointmentModal] = useState(false);
  const [futureValidAppointmentDetail, setFutureValidAppointmentDetail] = useState<FutureValidAppointmentObject | undefined>(undefined);
  const blnTableOrMobile = isTablet || isMobile;
  const [blnRejoinOrCrossBrandModal, setBlnRejoinOrCrossBrandModal] = useState(false);
  const blnIsEditable = (appointmentDetail?.isEditable || addAction);
  const blnConsultantAndServices = (appointmentDetail?.isConsultantAndServicesEditable || blnPermissionReviseClosedAppointment || addAction);

  // validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: appointmentDetail || {
      branchId: "",
      branchName: "",
      consultantId: undefined,
      consultantName: "",
      customerId: "",
      leadId: "",
      leadName: "",
      preferredName: "",
      customerName: "",
      roomId: "",
      roomName: "",
      startDate: returnCurrentOnlyDate(),
      startTime: "",
      appointStartDateTime: "",
      appointEndDateTime: "",
      sourceId: "",
      mediaSourceName: "",
      contactNo: "",
      email: "",
      remark: "",
      appointmentStatus: "Pending",
      serviceTreatmentIds: [],
      serviceTreatmentNames: [],
      isNotifyConsultant: false,
      isNotifyCustomer: false,
      appointmentSourceType: blnMarcomUser ? Constants.lead : Constants.existing,
      identityType: "",
      identityNo: "",
      marcomConsultantId: "",
      marcomConsultantName: "",
      raceName: "",
      raceId: "",
      subBrandId: "",
      subBrandName: "",
      endTime: "",
      referredById: "",
      referredByName: "",
      appolousId: null,
      isFollowUpOnly: false,
      isRejoin: false,
      isCrossBrand: false,
      isPurchasedPackageSelected: false,
      purchasedPackageId: "",
      purchasedPackageName: "",
      isAppolousPackage: false,
      gender: ""
		},
		validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any, context) => {
        if (returnSubBrandList().length > 0 ? true : false) {
          return value;
        }
        else {
          return true;
        }
			}).nullable(),
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})})),
      roomId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Room"})})),
      startDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})})),
      startTime:  Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Time"})})),  
      serviceTreatmentIds: Yup.array().test("serviceTreatmentIds", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) }), (value: any, context) => {
        if (context.parent.appointmentSourceType) {
          return context.parent.appointmentSourceType === Constants.existing && optionsServiceTreatmentChecked === Constants.followUpOnly ? true : value.length > 0;
        }
				return true;
			}),
      appointmentStatus: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "AppointmentStatus"})})),
      customerName: Yup.string().test("customerName", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Name" }) }), (value: any, context) => {
        if (context.parent.appointmentSourceType) {
          if (context.parent.appointmentSourceType === Constants.existing || context.parent.appointmentSourceType === Constants.walkIn || (context.parent.appointmentSourceType === Constants.lead && !leadDisabled)) {
            return value;
          }
          else {
            return true;
          }
        }

				return true;
			}),
      marcomConsultantId: Yup.mixed().test("marcomConsultantId", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MarcomConsultant" }) }), (value: any, context) => {
        if (context.parent.appointmentSourceType) {
          if (addAction && context.parent.appointmentSourceType === Constants.lead && !blnMarcomUser && !leadDisabled && !blnCSCUser) {
            return value;
          }
          else {
            return true;
          }
        }

				return true;
			}),
      sourceId: Yup.string().test("sourceId", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MediaSource" }) }), (value: any, context) => {
        if (context.parent.appointmentSourceType) {
          if ((context.parent.appointmentSourceType === Constants.walkIn || (context.parent.appointmentSourceType === Constants.lead && !leadDisabled)) && addAction) {
            return value;
          }
          else {
            return true;
          }
        }
				return true;
			}),
      email: Yup.string().email()
      .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Email" }) }), (value: any, context) => {
        return true;
      }).nullable(),
      contactNo: Yup.string().test("contactNo", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ContactNo" }) }), (value: any, context) => {
        if (context.parent.appointmentSourceType) {
          if ((context.parent.appointmentSourceType === Constants.walkIn || (context.parent.appointmentSourceType === Constants.lead && !leadDisabled))  && addAction) {
            return value;
          }
          else {
            return true;
          }
        }
				return true;
			}),
      // identityType: Yup.string().test(
      //   "identityType",
      //   intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }),
      //   (value: any, context) => {
      //     if (blnWalkInSourceType && addAction) {
      //       return value;
      //     }
      //     return true;
      //   }
      // ).nullable(),
      // identityNo: Yup.string().test(
      //   "identityNo",
      //   intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }),
      //   (value: any, context) => {
      //     if (blnWalkInSourceType && addAction) {
      //       return value;
      //     }
      //     return true;
      //   }
      // ).nullable(),
      referredByName: Yup.string().test(
        "referredByName",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ReferredBy" }) }),
        (value: any, context) => {
          if (blnReferralType && !blnExistingSourceType) {
            return value;
          }
          return true;
        }
      ).nullable(),
      gender: Yup.string().test(
        "gender",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Gender" }) }),
        (value: any, context) => {
          if (blnMandatoryAppointmentGender && (blnWalkInSourceType || (blnLeadSourceType && addAction && !leadDisabled))) {
            return value;
          }
          return true;
        }
      ).nullable(),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);  
      valuesTemp.appointStartDateTime = moment(`${valuesTemp.startDate} ${valuesTemp.startTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
      // if (!(moment(valuesTemp.startTime, Constants.displayTimeFormat).isBefore(moment(valuesTemp.endTime, Constants.displayTimeFormat)))) {
      //   return;
      // }
      // else {
      valuesTemp.appointEndDateTime = moment(`${valuesTemp.startDate} ${valuesTemp.endTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
      // }

      // if ((valuesTemp.identityType === Constants.identityCard && valuesTemp.appointmentSourceType === Constants.walkIn) || (blnMalaysia && valuesTemp.appointmentSourceType === Constants.lead)) {
      if (blnMalaysia && (valuesTemp.appointmentSourceType === Constants.lead || valuesTemp.appointmentSourceType === Constants.walkIn)) {
        valuesTemp.contactNo = appendCountryCode(valuesTemp.contactNo, "6")
      }

      if (valuesTemp.subBrandId === Constants.emptyId)  {
        valuesTemp.subBrandId = "";
      }

      // Referral could be empty if referral is employee
      // if (blnReferralType && (valuesTemp.referredById === null || valuesTemp.referredById === "")) {
      //   setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Referral" }) }))
      //   return;
      // }
      // else 
      if (!blnReferralType) {
        valuesTemp.referredById = "";
        valuesTemp.referredByName = "";
      }

      if (blnLeadSourceType) {
        delete valuesTemp["customerId"];
      }

			try {
        let resultAPI = addAction ? await addAppointment(valuesTemp) : await updateAppointment(valuesTemp);
        if (resultAPI) {
          if (resultAPI.data) {
            if (resultAPI.data.isCrossBrand || resultAPI.data.isRejoin) {
              setBlnRejoinOrCrossBrandModal(true);
            }
          }
        }
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || (blnStatusNotShowOrCompletedDetected && !blnPermissionReviseClosedAppointment);
  const disabledFieldInputWithLoadingOnly = validation.isSubmitting || Boolean(successMessage);
  const blnExistingSourceType = validation.values.appointmentSourceType === Constants.existing;
  const blnLeadSourceType = validation.values.appointmentSourceType === Constants.lead;
  const blnWalkInSourceType = validation.values.appointmentSourceType === Constants.walkIn;

	useEffect(() => {
		async function fetchAppointmentDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
				getStaticReferenceWithType(Constants.appointmentStatusType), //For appointment status
        getStaticReferenceWithType(Constants.appointmentSourceType), //For appointment source
        getStaticReferenceWithType(Constants.identityType),
        getServiceTreatmentDropdown({branchId: "", isActive: true}),
        // getLeadDropdown({branchId: "", sourceId: "", marcomConsultantId: "", isFilterExistingCustomer: true, isFilterClosedLeads: true}),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.marComCustomerServiceId}`], isActive: true }, true),
        getRaceDropdown(),
        getGeneralSettings(),
        getStaticReferenceWithType(Constants.genderType)
      ];
			
      const resultFinalAPI = await Promise.all(aryAPI);

      setLeadConsultantList(resultFinalAPI[6])

      let resultAppointmentAPI : any = undefined;
      if (id && !addAction) {
				resultAppointmentAPI = await getAppointmentWithId(id);
        await getAppointmentHistoryList(id);
			}

      if (addAction) {
        setAppointmentDetail(undefined);
        let clearRoomAndEmployee = false;
        if (location.state) {
          if (location.state.detail) {
            let startDateTemp = moment(location.state.detail.date).format(Constants.displayDateFormat);
            validation.setValues((values)=> ({
              ...values,
              branchId: location.state.detail.branchId,
              branchName: location.state.detail.branchName,
              ...location.state.detail.consultantId && {consultantId:  location.state.detail.consultantId},
              ...location.state.detail.consultantName && {consultantName:  location.state.detail.consultantName},
              ...location.state.detail.roomId && { roomId: location.state.detail.roomId },
              ...location.state.detail.roomName && { roomName: location.state.detail.roomName },
              ...location.state.detail.date && { startDate: startDateTemp },
              ...location.state.detail.date && { startTime: moment(location.state.detail.date).format(Constants.displayTimeFormat)},
              ...location.state.detail.leadId && { leadId: location.state.detail.leadId},
              ...location.state.detail.leadName && { leadName: location.state.detail.leadName},
              ...location.state.detail.appointmentSourceType && { appointmentSourceType: location.state.detail.appointmentSourceType},
              ...location.state.detail.customerId && { customerId: location.state.detail.customerId},
              ...location.state.detail.customerName && { customerName: location.state.detail.customerName},
              ...location.state.detail.email && { email: location.state.detail.email},
              ...location.state.detail.contactNo && { contactNo: location.state.detail.contactNo},
              ...location.state.detail.subBrandId && { subBrandId: location.state.detail.subBrandId},
              ...location.state.detail.subBrandName && { subBrandName: location.state.detail.subBrandName},
              ...location.state.detail.sourceId && { sourceId: location.state.detail.sourceId},
              ...location.state.detail.mediaSourceName && { mediaSourceName: location.state.detail.mediaSourceName}
            }))
            if (location.state.detail.leadId) {
              setLeadDisable(true)
            }
            if (location.state.detail.subBrandId) {
              setSubBrandDisable(true)
            }
            if (location.state.detail.branchId) {
              fetchDataAfterBranchSelect(location.state.detail.branchId, false, startDateTemp)
            }
            if (location.state.detail.selectedServiceTreatmentIdFromLead) {
              prefillSelectedServiceTreatment([location.state.detail.selectedServiceTreatmentIdFromLead], true, resultFinalAPI[5])
            }
            if (location.state.detail.sourceId) {
              onChangeMediaSource(location.state.detail.sourceId, resultFinalAPI[1])
            }
          }
          else {
            clearRoomAndEmployee = true;
          }
        }
        else {
          clearRoomAndEmployee = true;
        }

        //This logic required is because when user navigate from Appointment List -> It doesn't pass any info, 
        //so it require to clear consultant and room list
        if (clearRoomAndEmployee) {
          setRoomList([]);
          setEmployeeDropdownList([]);
        }
      }

      if (resultAppointmentAPI && resultFinalAPI[1]) {
        let mediaSourceListTemp = _.cloneDeep(resultFinalAPI[1]);
        let indexMediaSource = _.findIndex(mediaSourceListTemp, {id: resultAppointmentAPI.sourceId})
        if (indexMediaSource > -1) {
          if (mediaSourceListTemp[indexMediaSource].isReferral) {
            setBlnReferralType(true);
          }
        }
      }
			setLoading(false);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateAppointment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateAppointment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAppointment], true)) {
        return;
      }
    }

		fetchAppointmentDetailAPI();
    validation.setFieldValue("appointmentSourceTypeFixed", "")
    
    return (()=> {
      if (id && !addAction) {
        setAppointmentDetail(undefined);
        setBranchBusinessHour([]);
        setAppointmentHistory([]);
      }
    })
	}, []);

  useEffect(()=> {
    if (id && !addAction) {
      if (appointmentDetail) {
        if (!appointmentDetail.isPurchasedPackageSelected) {
          prefillSelectedServiceTreatment(appointmentDetail.serviceTreatmentIds);
        }
        else {
          prefillSelectedPurchasedServiceTreatment(appointmentDetail.serviceTreatmentIds, appointmentDetail.customerId, appointmentDetail.purchasedPackageId, appointmentDetail.isAppolousPackage, appointmentDetail.serviceTreatmentNames)
        }
        setTimeout(()=> {
          if (appointmentDetail.customerId === Constants.emptyId) {
            validation.setFieldValue("customerId", "");
          }
          let startDateTemp = moment(appointmentDetail.appointStartDateTime).format(Constants.displayDateFormat);
          validation.setFieldValue("startDate", startDateTemp);
          validation.setFieldValue("startTime", moment(appointmentDetail.appointStartDateTime).format(Constants.displayTimeFormat));
          validation.setFieldValue("endTime", moment(appointmentDetail.appointEndDateTime).format(Constants.displayTimeFormat));
          if (appointmentDetail.branchId) {
            fetchDataAfterBranchSelect(appointmentDetail.branchId, false, startDateTemp)
          }
          if (appointmentDetail.roomId === Constants.emptyId) {
            validation.setFieldValue("roomName", "Not Available")
          }
          if (appointmentDetail.appointmentSourceType === Constants.lead) {
            validation.setFieldValue("customerName", appointmentDetail.leadName)
          }
          if(appointmentDetail.isFollowUpOnly){
            setOptionsServiceTreatmentChecked(Constants.followUpOnly)
          }
          if (appointmentDetail.appointmentSourceType === Constants.walkIn) {
            if (appointmentDetail.identityType == Constants.passport) {
              setBlnMalaysia(false);
              setBlnRefreshMalaysiaUI(true);
              setTimeout(()=> {
                setBlnRefreshMalaysiaUI(false);
              }, 100)
            }
          }

          if (appointmentDetail.appointmentStatus === Constants.notShow || appointmentDetail.appointmentStatus === Constants.completed) {
            setBlnStatusNotShowOrCompletedDetected(true);
          }

          if(viewAction && blnPermissionUpdateAppointment){
            let indexEditAction = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "EditAction" })}`})
            if (indexEditAction < 0) {
              burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.appointment}/edit/${id}`)} })
            }
          }

        }, 100)
      }
    }
  }, [appointmentDetail])

  const processBusinessTimeSlot = (blnRemoveTime: boolean = true, branchBusinessHourObject: BusinessHourForBranchObject[], startDateTemp?: string) => {
    if (branchBusinessHourObject && validation.values.startDate) {
      if (branchBusinessHourObject.length > 0) {
        let selectedDay = moment(startDateTemp || validation.values.startDate).format(Constants.displayDay);
        let indexSelectedDay = _.findIndex(branchBusinessHourObject, {name: selectedDay})
        if (indexSelectedDay > -1) {
          let startTime = branchBusinessHourObject[indexSelectedDay].fromTime;
          let endTime = branchBusinessHourObject[indexSelectedDay].toTime;
          setTimeList(returnOperatingHours(true, false, startTime, endTime));
        }
      }
      setRefreshTime(true);
      if (blnRemoveTime) {
        validation.setFieldValue("startTime", "");
      }
      setTimeout(()=> {
        setRefreshTime(false);
      }, 100)
    }
  }

  useEffect(()=> {
    if (appointmentSourceType) {
      let aryAppointmentSourceTypeTemp : ObjectForDropdown[]= [];
      appointmentSourceType.map((valueAppointmentSourceTypeTemp)=> {
        if (((valueAppointmentSourceTypeTemp.key !== 1 && blnMarcomUser) || (valueAppointmentSourceTypeTemp.key === 1 && getBranchUser())) && addAction) {
          return;
        }

        if (!addAction && validation.values.appointmentSourceType !== valueAppointmentSourceTypeTemp.displayValue) {
          return;
        }
        
        aryAppointmentSourceTypeTemp.push({
          label: newSpaceBeforeCapitalLetter(valueAppointmentSourceTypeTemp.displayValue),
          value: valueAppointmentSourceTypeTemp.displayValue
        })
      })
      setAppointmentSourceTypeList(aryAppointmentSourceTypeTemp)
    }
  }, [appointmentSourceType, validation.values.appointmentSourceType])

  useEffect(()=> {
    if (validation.values.startTime) {
      if (!existingIdHandling && !addAction) {
        setExistingIdHandling(true);
      }
      else {
        setRefreshEndTime(true);
        let endTimeTemp =  moment(validation.values.startTime, Constants.displayTimeFormat).format(Constants.displayTimeFormat);
        if (endTimeTemp) {
          let highestMinutes = 0;
          if(optionsServiceTreatmentChecked !== Constants.followUpOnly)
          {
            validation.values.serviceTreatmentIds.map((selectedValue)=> {
              serviceTreatmentDropdownList.map((valueServiceTreament)=> {
                if (selectedValue == valueServiceTreament.id) {
                  highestMinutes = (valueServiceTreament.minutes + (valueServiceTreament.hours * 60)) > highestMinutes ?  (valueServiceTreament.minutes + (valueServiceTreament.hours * 60)) : highestMinutes;
                }
              })
            })
          }
          else
          {
            //follow up dura default to 15mins, since can overlap
            highestMinutes = 15;
          }

          endTimeTemp = moment(endTimeTemp, Constants.displayTimeFormat).add(highestMinutes, 'minutes').format(Constants.displayTimeFormat)
          validation.setFieldValue("endTime", endTimeTemp);
        }
        setTimeout(() => {
          setRefreshEndTime(false);
        }, 100);
      }
    }
  }, [validation.values.startTime, validation.values.serviceTreatmentIds])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
        if (JSON.stringify(validation.errors) === `{"startTime":"${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Time"})})}"}`) {
          if (validation.values.startTime) {
            return;
          }
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const AppointmentHistoryColumns = [
    {
      dataField: "performedDate",
      text: intl.formatMessage({ id: "PerformedDate" }).toUpperCase(),
      formatter: (cellContent, row, indexRow) =>  
      <div>
        {`${moment(row.performedDate).format(Constants.displayDateAndTimeFormat)}`}
      </div>
    },
    {
      dataField: "oldValue",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <div 
            className="flex-align-items-center-without-height">
            <div style={{width: '50%'}}>
              {intl.formatMessage({ id: "From" }).toUpperCase()}
            </div>
            <div>
              {intl.formatMessage({ id: "ChangedTo" }).toUpperCase()}
            </div>
          </div>
        )
      },
      formatter: (cellContent, row) => {
        let aryViews : any = [];
				row.finalOldValue.map((value, indexFinalOldValue) => {
					aryViews.push(
            <div 
              className="flex-direction-row"
              key={`oldValue_${indexFinalOldValue}`}
              style={{
                marginTop: indexFinalOldValue === 0 ? "-0.75rem" : "", 
                marginBottom: indexFinalOldValue === row.finalOldValue.length-1 ? "-0.75rem" : "", 
                marginLeft: "-0.75rem",
                marginRight: "-0.75rem",
                borderBottom: indexFinalOldValue === row.finalOldValue.length-1 ? "" : "dashed #d7d9de",
                borderBottomWidth: "1px",
              }}>
              <div style={{width: '50%', padding: '16px', borderRight: "dashed #eff2f7", borderRightWidth: "0.25px"}}>
                {value}
              </div>
              <div 
                style={{flex: 1, padding: '16px'}}>
                {row.finalNewValue[indexFinalOldValue]}
              </div>
            </div>
					)
				})
				return aryViews;
      }
    },
    {
      dataField: "performedBy",
      text: intl.formatMessage({ id: "PerformedBy" }).toUpperCase(),
    }
  ]

  const fetchDataAfterBranchSelect = async (branchId: string, removeData: boolean=true, startDateTemp?: string) => {
    setLoadingBranchAfterSelect(true);
    let aryAPI: any = [
      getBranchBusinessHour(branchId),
      getRoomWithBranchId(branchId),
      getEmployeeDropdown({branchId: branchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}),
    ]
    let resultAPI = await Promise.all(aryAPI);

    if (removeData) {
      validation.setValues((values)=> ({
        ...values,
        roomName: "",
        roomId: "",
        consultantName: "",
        consultantId: undefined
      }))
    }
    processBusinessTimeSlot(removeData, resultAPI[0], startDateTemp);
    setLoadingBranchAfterSelect(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(validation.values.branchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const prefillCustomerInfo = (id: string) => {
    if (blnExistingSourceType) {
      let indexCustomer = _.findIndex(customerDropdownList, {id: id});
      if (indexCustomer > -1) {
        validation.setValues((values)=> ({
          ...values,
          customerId: customerDropdownList[indexCustomer].id,
          customerName: customerDropdownList[indexCustomer].preferredName,
          email: customerDropdownList[indexCustomer].email,
          contactNo: customerDropdownList[indexCustomer].mobileNo
        }))
      }

      clearPurchaseServiceTreatment()
    }
    else if (blnLeadSourceType) {
      let indexLead = _.findIndex(leadDropdownList, {id: id});
      if (indexLead > -1) {
        validation.setValues((values)=> ({
          ...values,
          leadId: leadDropdownList[indexLead].id,
          leadName: leadDropdownList[indexLead].name,
          email: leadDropdownList[indexLead].email,
          contactNo: leadDropdownList[indexLead].mobileNo
        }))
      }
    }
    clearCustomerDropdownList();
  }

  const onChangeSourceType = () => {
    setRefreshMediaSourceDropdown(true);
    validation.setValues((values)=> ({
      ...values,
      customerId: "",
      customerName: "",
      leadId: "",
      leadName: "",
      email: "",
      contactNo: "",
      mediaSourceName: "",
      sourceId: "",
      raceId: "",
      raceName: "",
      referredById: "",
      referredByName: ""
    }))
    setBlnReferralType(false);
    clearPurchaseServiceTreatment();
    setTimeout(()=> {
      setRefreshMediaSourceDropdown(false);
    }, 100)
  }

  const onClickSelect =  async (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      email: row.email,
      contactNo: row.mobileNo,
    }))

    clearPurchaseServiceTreatment()
    setDisplayCustomerDropdown(false);

    setTimeout(()=> {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onChangeServiceTreatmentRadio = async (value, removeData: boolean = true) => {
    let isFollowUpOnly = false;
    if (value === Constants.purchasedPackage) {
      if (!purchaseServiceTreatmentList) {
        await fetchAppointmentCustomerTreatment();
      }
    }
    else if (value === Constants.followUpOnly){
      isFollowUpOnly = true;
    }

    if (value === Constants.followUp || value === Constants.allServiceTreatment) {
      setPurchasePackageList(undefined);
      setPurchaseServiceTreatmentList(undefined);
    }

    validation.setFieldValue("isFollowUpOnly", isFollowUpOnly);
    
    if (removeData) {
      setLoadingServiceTreatment(true);
      validation.setFieldValue("isPurchasedPackageSelected", false);
      validation.setFieldValue("purchasedPackageId", "");
      validation.setFieldValue("purchasedPackageName", "");
      validation.setFieldValue("isAppolousPackage", false);
      validation.setFieldValue("serviceTreatmentIds", []);
      validation.setFieldValue("serviceTreatmentNames", []);
      setSelectedServiceTreatmentIds([]);
      setTimeout(()=> {
        setLoadingServiceTreatment(false);
      }, 100)
    }
    setOptionsServiceTreatmentChecked(value);
  }

  const fetchAppointmentCustomerTreatment = async () => {
    setLoadingServiceTreatment(true);
    setLoadingPackage(true);
    let resultAppointmentCustomerPurchasedPackageList = await getAppointmentCustomerPurchasedPackageList(validation.values.customerId);
    if (resultAppointmentCustomerPurchasedPackageList?.status === Constants.success) {
      setPurchasePackageList(resultAppointmentCustomerPurchasedPackageList.data)
    }
    else {
      setPurchasePackageList([]);
    }
      
    setPurchaseServiceTreatmentList([])
    setSelectedServiceTreatmentIds([]);
    setLoadingServiceTreatment(false);
    setLoadingPackage(false);
  }

  const clearPurchaseServiceTreatment = () => {
    onChangeServiceTreatmentRadio(Constants.allServiceTreatment);
  }

  const prefillSelectedServiceTreatment = (aryServiceTreatmentIds: string[], blnFromLead: boolean = false, serviceTreatmentDropdownListTemp : ServiceTreatmentListObject[] = []) => {
    let arySelectedServiceTreatment : any = [];
    let arySelectedServiceTreatmentNameTemp: string[] = [];
    let arySelectedServiceTreatmentIdTemp: string[] = [];
    let finalServiceTreatmentDropdownList = serviceTreatmentDropdownListTemp.length > 0 ? serviceTreatmentDropdownListTemp :  serviceTreatmentDropdownList;
    aryServiceTreatmentIds.map((value)=> {
      for (var a=0; a<finalServiceTreatmentDropdownList.length; a++) {
        if (value === finalServiceTreatmentDropdownList[a].id) {
          arySelectedServiceTreatment.push({
            ...finalServiceTreatmentDropdownList[a],
            label: finalServiceTreatmentDropdownList[a].name,
            value: finalServiceTreatmentDropdownList[a].id
          })
          if (blnFromLead) {
            arySelectedServiceTreatmentNameTemp.push(finalServiceTreatmentDropdownList[a].name);
            arySelectedServiceTreatmentIdTemp.push(finalServiceTreatmentDropdownList[a].id);
          }
        }
      }
    })

    if (blnFromLead) {
      validation.setFieldValue("serviceTreatmentNames", arySelectedServiceTreatmentNameTemp);
      validation.setFieldValue("serviceTreatmentIds", arySelectedServiceTreatmentIdTemp);
    }
    setSelectedServiceTreatmentIds(arySelectedServiceTreatment);
  }

  const prefillSelectedPurchasedServiceTreatment = async (aryServiceTreatmentIds: string[], customerId: string, purchasedPackageId: string, isAppolousPackage: boolean, aryServiceTreatmentNames: string[]) => {
    setLoadingServiceTreatment(true);
    setLoadingPackage(true);

    let appointmentCustomerPurchasedPackageListTemp : any = [];
    let resultAppointmentCustomerPurchasedPackageList = await getAppointmentCustomerPurchasedPackageList(customerId);
    if (resultAppointmentCustomerPurchasedPackageList?.status === Constants.success) {
      appointmentCustomerPurchasedPackageListTemp = resultAppointmentCustomerPurchasedPackageList.data;
      setPurchasePackageList(appointmentCustomerPurchasedPackageListTemp);
    }
    else {
      setPurchasePackageList([]);
    }

    let indexPurchasedPackageTemp = -1;
    let indexPurchasedServiceTemp = -1;
    for (var indexCustomerPurchasedPackageTemp=0; indexCustomerPurchasedPackageTemp<appointmentCustomerPurchasedPackageListTemp.length; indexCustomerPurchasedPackageTemp++) {
      let valueAppointmentCustomerPurchasedPackageTemp = appointmentCustomerPurchasedPackageListTemp[indexCustomerPurchasedPackageTemp];
      if (valueAppointmentCustomerPurchasedPackageTemp.isAppolousPackage === isAppolousPackage) {
        indexPurchasedPackageTemp = indexCustomerPurchasedPackageTemp;
        indexPurchasedServiceTemp = _.findIndex(valueAppointmentCustomerPurchasedPackageTemp.options, {purchasedPackageId: purchasedPackageId});
        if (indexPurchasedServiceTemp > -1) {
          break;
        }
      }
    }

    if (indexPurchasedPackageTemp > -1 && indexPurchasedServiceTemp >-1) {
      let arySelectedServiceTreatment : any = [];
      let finalServiceTreatmentDropdownList = appointmentCustomerPurchasedPackageListTemp[indexPurchasedPackageTemp].options[indexPurchasedServiceTemp].services;
      aryServiceTreatmentIds.map((value, indexServiceTreatment)=> {
        let blnServiceFound = false;
        for (var a=0; a<finalServiceTreatmentDropdownList.length; a++) {
          if (value === finalServiceTreatmentDropdownList[a].serviceTreatmentId) {
            arySelectedServiceTreatment.push({
              ...finalServiceTreatmentDropdownList[a],
              label: finalServiceTreatmentDropdownList[a].name,
              value: finalServiceTreatmentDropdownList[a].id
            })
            blnServiceFound = true;
            break;
          }
        }
        if (!blnServiceFound) {
          arySelectedServiceTreatment.push({
            label: aryServiceTreatmentNames[indexServiceTreatment] || "",
            value: aryServiceTreatmentIds[indexServiceTreatment] || "",
            name: aryServiceTreatmentNames[indexServiceTreatment] || "",
            id: aryServiceTreatmentIds[indexServiceTreatment] || ""
          })
        }
      })
      setSelectedServiceTreatmentIds(arySelectedServiceTreatment);
      setPurchaseServiceTreatmentList(finalServiceTreatmentDropdownList);
    }    
    else {
      let arySelectedServiceTreatment : any = [];
      aryServiceTreatmentNames.map((valueServiceTreatmentNames)=> {
        arySelectedServiceTreatment.push({
          label: valueServiceTreatmentNames,
          value: valueServiceTreatmentNames,
          name: valueServiceTreatmentNames,
          id: valueServiceTreatmentNames
        })
      })
      setSelectedServiceTreatmentIds(arySelectedServiceTreatment);
      setPurchaseServiceTreatmentList([]);
    }
    setOptionsServiceTreatmentChecked(Constants.purchasedPackage);
    setLoadingServiceTreatment(false);
    setLoadingPackage(false);
  }

  const onSelectCustomerCriteriaSearch = (row) => {
    validation.setValues((values) => ({
      ...values,
      referredById: row.id,
      referredByName: row.preferredName
    }))

    setCustomerCriteriaSearchModal(false);
  }

  const onChangeMediaSource = (id: string,  mediaSourceDropdownListTemp: MediaSourceListObject[] | undefined) => {
    let mediaSourceDropdownListFinal = mediaSourceDropdownListTemp ? mediaSourceDropdownListTemp : mediaSourceDropdownList
    let indexMediaSource = _.findIndex(mediaSourceDropdownListFinal, {id: id});
    if (indexMediaSource > -1) {
      if(mediaSourceDropdownListFinal[indexMediaSource].isReferral){
        setBlnReferralType(true);
      }
      else{
        setBlnReferralType(false);
      }
    }
    if (mediaSourceDropdownListTemp) {
      setRefreshMediaSourceDropdown(true);
      setTimeout(()=> {
        setRefreshMediaSourceDropdown(false);
      }, 100)
    }
  }

  const displayServiceTreatmentDropdown = () => {
    return (
      loadingServiceTreamtment
      ?
      <Loading className="margin-bottom-16" fullHeightDisabled={true}/>
      :
      <DropDownWithTitleMultiSelect
        name={"serviceTreatmentIds"}
        title={validation.values.appointmentSourceType !== Constants.existing ? intl.formatMessage({ id: "ServiceTreatment"}) : ""}
        placeholder={intl.formatMessage({ id: "ServiceTreatment"})}
        specifyReturnFieldName={[{"field": "serviceTreatmentNames", "value": "name"},{"field": "serviceTreatmentIds", "value": "id"}]}
        returnFieldWithLabel={false}
        labelField={"name"}
        valueField={"id"}
        options={optionsServiceTreatmentChecked === Constants.allServiceTreatment ? serviceTreatmentDropdownList : optionsServiceTreatmentChecked === Constants.purchasedPackage ? purchaseServiceTreatmentList : []}
        disabled={disabledFieldInput || viewAction || optionsServiceTreatmentChecked === Constants.followUpOnly || !blnConsultantAndServices}
        initialValue={selectedServiceTreatmentIds}
        validationRequired={true}
        validation={validation}/>
    )
  }

  const displayContactNoForEditOrViewPage = () => {
    return (
      id
      &&
      <GeneralInput
        title={intl.formatMessage({ id: "ContactNo"})}
        name="contactNo"
        type="text"
        disabled={true}
        validation={validation}/>
    )
  }

  const displayCustomerDetailUI = (blnMargin: boolean) => {
    return (
      <>
        <LineBreakWithTittle 
          paddingBottom="0px"
          className={blnMargin ? !blnTableOrMobile ? "mt-4" : "mt-3": ""}
          title={intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({id: "Client"})})} 
          h4Title/>
        <GeneralInput
          className="mb-3 mt-4"
          name={"appointmentSourceType"}
          title={intl.formatMessage({ id: "SourceType"})}
          type={Constants.radio}
          options={appointmentSourceTypeList}
          disabled={disabledFieldInput || viewAction || leadDisabled || id}
          validation={validation}
          validationRequired={true}
          onChangeFunction={onChangeSourceType}
        />
        {
          returnSubBrandList().length > 0
          &&
          <SingleColumnRowParent
            blnDoubleTab={true}>
            <DropDownWithTitle
              name={"subBrandId"}
              title={intl.formatMessage({ id: "SubBrand"})}
              subTitle={` ${intl.formatMessage({ id: "PleaseConfirmBrand"})}`}
              titleClassName={"shadow-effect"}
              specifyReturnFieldName={[{"field": "subBrandName", "value": "name"},{"field": "subBrandId", "value": "id"}]}
              labelField={"name"}
              valueField={"id"}
              options={returnSubBrandList()}
              disabled={disabledFieldInput || viewAction || loadingBranchAfterSelect || subBrandDisabled || !blnIsEditable}
              validationRequired={true}
              initialLabel={validation.values.subBrandName}
              initialValue={validation.values.subBrandId}
              validation={validation}/>
          </SingleColumnRowParent>
        }
        {
          blnExistingSourceType && displayCustomerDropdown
          ?
          loadingBranchAfterSelect
          ?
          <Loading
            fullHeightDisabled={true} />
          :
          <Row>
            <Col xl='8' md='6' xs='6'>
              <DropDownWithTitle
                name={"customerName"}
                title={intl.formatMessage({ id: "LinkToCustomer"})}
                placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                specifyReturnFieldName={[{"field": "customerId", "value": "id"}]}
                labelField={"preferredName"}
                valueField={"id"}
                options={customerDropdownList}
                disabled={disabledFieldInput || viewAction || id}
                initialLabel={validation.values.customerName}
                initialValue={validation.values.customerId}
                validation={validation}
                validationRequired={true}
                onChangeField={"id"}
                onChangeFunction={prefillCustomerInfo}
                blnSupportCustomSearch={true}
                onChangeCustomSearch={(value)=> {
                  fetchCustomerDropdown(value);
                }}
                onMenuOpenFunction={()=> {
                  clearCustomerDropdownList();
                  setLoadingCustomer(false);
                }}
                loadingCustomSearch={loadingCustomer}
                setLoadingCustomSearch={setLoadingCustomer}
                blnSupportClickableUI={viewAction || id}
                onClickUIFunction={()=> {
                  if (blnPermissionManageCustomer) {
                    window.open(`/${RoutesList.customer}/view/${validation.values.customerId}`)
                  }
                }}/>
                {displayContactNoForEditOrViewPage()}
            </Col>
            {
              disabledFieldInput || viewAction || id
              ?
              <div/>
              :                    
              <Col xl='4' md='6' xs='6'>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-12"}`}
                  content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
                  loading={loading}
                  disable={loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </Col>
            }
          </Row>
          :
          <div/>
        }
        {
          blnLeadSourceType && (!addAction || (addAction && leadDisabled))
          &&
          <Row>
            <Col xl='8' md='6' xs='6'>
              <DropDownWithTitle
                name={"leadId"}
                title={`${intl.formatMessage({ id: "LinkToLead"})} ${validation.values.isRejoin ? `(${intl.formatMessage({id: "Rejoin"})})` : validation.values.isCrossBrand ? `(${intl.formatMessage({id: "CrossBrand"})})` : ""}`}
                specifyReturnFieldName={[{"field": "leadId", "value": "id"}]}
                className={blnPermissionManageLead ? "text-primary" : ""}
                labelField={"name"}
                valueField={"id"}
                options={leadDropdownList}
                disabled={disabledFieldInput || viewAction || leadDisabled || id}
                initialLabel={validation.values.leadName}
                initialValue={validation.values.leadId}
                validation={validation}
                validationRequired={true}
                onChangeField={"id"}
                onChangeFunction={prefillCustomerInfo}/>
              {displayContactNoForEditOrViewPage()}
            </Col>
            {
              blnPermissionManageLead &&
              <Col xl='4' md='6' xs='6'>
                <MyButton
                  type="button"
                  class={`btn btn-primary margin-top-28`}
                  content={intl.formatMessage({ id: "ViewLead" }).toUpperCase()}
                  onClick={()=> {
                    if (blnPermissionManageLead) {
                      history.push(`/${RoutesList.lead}/view/${validation.values.leadId}`)
                    }
                  }}
                  loading={ loading }
                  disable={ loading || disabledFieldInputWithLoadingOnly } />
              </Col>
            }
          </Row>
        }
        {
          blnLeadSourceType && addAction && !blnMarcomUser && !leadDisabled && !blnCSCUser
          &&
          <DropDownWithTitle
            name={"marcomConsultantId"}
            title={intl.formatMessage({ id: "MarcomConsultant"})}
            specifyReturnFieldName={[{"field": "marcomConsultantId", "value": "id"}, {"field": "marcomConsultantName", "value": "preferredName"}]}
            labelField={"preferredName"}
            valueField={"id"}
            options={leadConsultantList}
            disabled={disabledFieldInput || viewAction || blnMarcomUser || blnCSCUser}
            initialLabel={validation.values.marcomConsultantName}
            initialValue={validation.values.marcomConsultantId}
            validation={validation}
            validationRequired={true}/>
        }
        {
          (blnWalkInSourceType || (blnLeadSourceType && !leadDisabled && addAction))
          &&
          <>
            <GeneralInput
              title={blnWalkInSourceType ? intl.formatMessage({ id: "CustomerName"}) : intl.formatMessage({ id: "LeadName"})}
              name="customerName"
              type="text"
              disabled={disabledFieldInput || viewAction || !blnIsEditable}
              validationRequired={true}
              validation={validation}/>
            {
              !refreshMediaSourceDropdown
              &&
              <Col md='12' xs='12'>
                <DropDownWithTitle
                  name={"sourceId"}
                  title={intl.formatMessage({ id: "MediaSource"})}
                  specifyReturnFieldName={[{"field": "mediaSourceName", "value": "name"}, {"field": "sourceId", "value": "id"}]}
                  labelField={"name"}
                  valueField={"id"}
                  options={blnLeadSourceType ? mediaSourceDropdownList.filter(mediaSource => mediaSource.isReferral !== true) : mediaSourceDropdownList}
                  disabled={disabledFieldInput || viewAction || (!addAction && appointmentDetail?.customerId ? true : false) || !blnIsEditable}
                  validationRequired={true}
                  initialLabel={validation.values.mediaSourceName}
                  initialValue={validation.values.sourceId}
                  onChangeFunction={onChangeMediaSource}
                  validation={validation}/>
              </Col>
            }
            {
              blnReferralType
              &&
              <Row>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    name={"referredByName"}
                    title={intl.formatMessage({ id: "ReferredBy" })}
                    classNameInput="width-100-percentage"
                    type="text"
                    disabled={true}
                    validation={validation}
                    validationRequired={true}
                    validationRequiredForce={!loading && !disabledFieldInput && !viewAction}
                    childrenUI={
                      <MyButton
                        type="button"
                        content={"Select"}
                        class="btn btn-primary margin-left-8"
                        onClick={() => { 
                          setCustomerCriteriaSearchModal(true);
                        }}
                        loading={loading}
                        disable={disabledFieldInput || viewAction || !blnIsEditable}
                      />
                    } />
                </SingleColumnRowParent>
              </Row>
            }
            <Row>
              {
                addAction && blnLeadSourceType &&
                <Col md='6' xs='12'>
                  <DropDownWithTitle
                    name={"raceId"}
                    title={intl.formatMessage({ id: "Race" })}
                    specifyReturnFieldName={[
                      {
                        field: "raceId",
                        value: "id",
                      },
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={raceDropdownList}
                    disabled={disabledFieldInput ||viewAction}
                    initialLabel={validation.values.raceName}
                    initialValue={validation.values.raceId}
                    validationRequired={false}
                    validation={validation}
                  />
                </Col>
              }
              {
                (blnWalkInSourceType || blnLeadSourceType)
                &&
                <Col md={blnLeadSourceType ? '6' : '12'} xs='12'>
                  {
                    <DropDownWithTitle
                      name={"gender"}
                      title={intl.formatMessage({ id: "Gender" })}
                      specifyReturnFieldName={[
                        {
                          field: "gender",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      blnValueWithNewSpace={true}
                      options={genderType}
                      disabled={disabledFieldInput || viewAction || !blnIsEditable}
                      initialValue={validation.values.gender}
                      validationRequired={blnMandatoryAppointmentGender || false}
                      validation={validation}
                    />
                  }
                </Col>
              }
            </Row>
            {
              addAction && blnWalkInSourceType && 
              <Row>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  {
                    <DropDownWithTitle
                      name={"identityType"}
                      title={intl.formatMessage({ id: "IdentityType" })}
                      specifyReturnFieldName={[
                        {
                          field: "identityType",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      blnValueWithNewSpace={true}
                      options={identityType}
                      disabled={disabledFieldInput || viewAction }
                      initialValue={validation.values.identityType}
                      onChangeFunction={()=> {
                        validation.setFieldValue("identityNo", "");
                      }}
                      // validationRequired={true}
                      validation={validation}
                    />
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "IdentityNo" })}
                    name="identityNo"
                    type="text"
                    blnAlphaNumberic={validation.values.identityType === Constants.passport ? false : true}
                    // validationRequired={true}
                    disabled={disabledFieldInput || viewAction || !blnIsEditable}
                    validation={validation}
                  />
                </DoubleColumnRowParent>
              </Row>
            }
            <Row>
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <GeneralInput
                  title={intl.formatMessage({ id: "Email"})}
                  name="email"
                  type="text"
                  disabled={disabledFieldInput || viewAction || !blnIsEditable}
                  validation={validation}/>
              </DoubleColumnRowParent>
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <GeneralInput
                  title={intl.formatMessage({ id: "ContactNo"})}
                  name="contactNo"
                  type="number"
                  blnNumberOnly={true}
                  validationRequired={true}
                  disabled={disabledFieldInput || viewAction || !blnIsEditable}
                  validation={validation}/>
              </DoubleColumnRowParent>
            </Row>
            {
              (blnLeadSourceType || blnWalkInSourceType)
              &&
              !blnRefreshMalaysiaUI
              &&
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <div className="btn-group margin-bottom-8" role="group">
                  <input type="radio" className="btn-check" name="blnCountry" id="blnMalaysia" autoComplete="off" defaultChecked={blnMalaysia} disabled={disabledFieldInput || viewAction || !blnIsEditable}
                    onClick={async ()=> { 
                      setBlnMalaysia(true);
                    }}/>
                  <label style={{zIndex: 0}} className="btn btn-outline-primary" htmlFor="blnMalaysia">
                    {intl.formatMessage({ id: "Malaysia" })}
                  </label>
                  <input type="radio" className="btn-check" name="blnCountry" id="blnOthers" autoComplete="off" defaultChecked={!blnMalaysia} disabled={disabledFieldInput || viewAction || !blnIsEditable}
                    onClick={async ()=> { 
                      setBlnMalaysia(false);
                    }}/>
                  <label style={{zIndex: 0}} className="btn btn-outline-primary" htmlFor="blnOthers">
                    {intl.formatMessage({ id: "Others" })}
                  </label>
                </div>
              </DoubleColumnRowParent>
            }
          </>
        }
      </>
    )
  }
	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={id ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row form={+true}> {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({id: "Appointment"})})}
                  h4Title/>
                {
                  !addAction 
                  &&
                  validation.values.appolousId !== null
                  &&
                  blnShowAppolous
                  &&
                  <SingleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "ViewAppolous" })}
                      name="appolousId"
                      className="mt-4"
                      type="number"
                      disabled={true}
                      validation={validation}
                      onClickFunction={()=> {
                        window.open(`https://app.appolous.com/appointments/${validation.values.appolousId}/edit`)
                      }}/>
                  </SingleColumnRowParent>
                }
                <Row className={validation.values.appolousId !== null ? "mt-3" : "mt-4"}>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"branchId"}
                      title={intl.formatMessage({ id: "Branch"})}
                      specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={disabledFieldInput || viewAction || loadingBranchAfterSelect || !blnIsEditable}
                      validationRequired={true}
                      initialLabel={validation.values.branchName}
                      initialValue={validation.values.branchId}
                      validation={validation}
                      onChangeFunction={fetchDataAfterBranchSelect}/>
                  </DoubleColumnRowParent>
                  {
                    <DoubleColumnRowParent
                      blnDoubleTab={true}>
                      {
                        loadingBranchAfterSelect
                        ?
                        <Loading />
                        :
                        <DropDownWithTitle
                          name={"roomId"}
                          title={intl.formatMessage({ id: "Room"})}
                          specifyReturnFieldName={[{"field": "roomName", "value": "name"},{"field": "roomId", "value": "id"}]}
                          labelField={"name"}
                          valueField={"id"}
                          options={roomList}
                          disabled={disabledFieldInput || viewAction || !blnIsEditable}
                          initialLabel={validation.values.roomName}
                          initialValue={validation.values.roomId}
                          validationRequired={true}
                          validation={validation}/>
                      }
                    </DoubleColumnRowParent>
                  }
                </Row>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  {
                    loadingBranchAfterSelect
                    ?
                    <Loading 
                      fullHeightDisabled={true}/>
                    :
                    <DropDownWithTitle
                      name={"consultantId"}
                      title={intl.formatMessage({ id: "Consultant"})}
                      specifyReturnFieldName={[{"field": "consultantName", "value": "preferredName"},{"field": "consultantId", "value": "id"}]}
                      labelField={"preferredName"}
                      valueField={"id"}
                      options={employeeDropdownList}
                      disabled={disabledFieldInput || viewAction || !blnConsultantAndServices}
                      initialLabel={validation.values.consultantName}
                      initialValue={validation.values.consultantId}
                      // validationRequired={true}
                      validation={validation}/>
                  }
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Date"})}
                      name="startDate"
                      type="date"
                      validationRequired={
                        true
                      }
                      disabled={ disabledFieldInput || viewAction  || !blnIsEditable}
                      minCurrentDate={getBranchUser() && !blnAppointmentWithBackDate}
                      validation={validation}
                      onChangeFunction={()=> {processBusinessTimeSlot(true, branchBusinessHour)}}
                    />
                  </DoubleColumnRowParent>
                  {
                    !refreshTime
                    &&
                    <DoubleColumnRowParent
                      blnDoubleTab={true}>
                      <DropDownWithTitle
                        name={"startTime"}
                        title={intl.formatMessage({ id: "StartTime"})}
                        specifyReturnFieldName={[{"field": "startTime", "value": "value"}]}
                        labelField={"label"}
                        valueField={"value"}
                        options={timeList}
                        disabled={disabledFieldInput || viewAction || !blnIsEditable}
                        initialLabel={validation.values.startTime}
                        initialValue={validation.values.startTime}
                        validationRequired={true}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                  }
                </Row>
                {blnTableOrMobile && displayCustomerDetailUI(true)}
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  className="mb-4 mt-3"
                  title={intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({id: "ServiceTreatment"})})}
                  h4Title/>
                {
                  blnExistingSourceType
                  &&
                  <div className="flex-direction-row">
                    <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                    {optionsServiceTreatment.map((option, index) => (
                      <div key={`radio_${index}`}>
                        <Input
                          type="radio"
                          name="optionsServicesTreatment"
                          checked={option.value === optionsServiceTreatmentChecked}
                          className={`${index > 0 && "margin-left-16"}`}
                          onChange={() => onChangeServiceTreatmentRadio(option.value)}
                          disabled={((option.value === Constants.purchasedPackage || option.value === Constants.followUpOnly) && !validation.values.customerId) || loadingServiceTreamtment || disabledFieldInput || viewAction ? true : false || !blnIsEditable}
                        />
                        &nbsp;
                        <label>{option.label}</label>
                      </div>
                    ))}
                  </div>
                }
                {
                  optionsServiceTreatmentChecked === Constants.purchasedPackage
                  ?
                  <Row>
                    <DoubleColumnRowParent
                      blnDoubleTab={true}>
                      {
                        loadingServicePackage
                        ?
                        <Loading/>
                        :
                        <DropDownWithTitle
                          name={"purchasedPackageId"}
                          title={""}
                          blnHideTitle={true}
                          placeholder={intl.formatMessage({ id: "PurchasedPackage"})}
                          specifyReturnFieldName={[]}
                          labelField={"label"}
                          valueField={"value"}
                          options={purchasePackageList}
                          disabled={disabledFieldInput || viewAction || !blnIsEditable}
                          initialLabel={validation.values.purchasedPackageName}
                          initialValue={validation.values.purchasedPackageId}
                          onChangeAllField={true}
                          onChangeFunction={(valuePurchasedPackage)=> {
                            setLoadingServiceTreatment(true);
                            setSelectedServiceTreatmentIds([]);
                            if (valuePurchasedPackage.services) {
                              setPurchaseServiceTreatmentList(valuePurchasedPackage.services);
                              validation.setValues((values)=> ({
                                ...values,
                                isPurchasedPackageSelected: true,
                                purchasedPackageId: valuePurchasedPackage.purchasedPackageId,
                                purchasedPackageName: valuePurchasedPackage.packageName,
                                isAppolousPackage: valuePurchasedPackage.isAppolousPackage,
                                serviceTreatmentIds: [],
                                serviceTreatmentNames: []
                              }))
                            }
                            else {
                              setPurchaseServiceTreatmentList([]);
                            }
                            setTimeout(()=> {
                              setLoadingServiceTreatment(false);
                            }, 100)
                          }}
                          validationRequired={true}
                          validation={validation}/>    
                      }
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent
                      blnDoubleTab={true}>
                      {displayServiceTreatmentDropdown()}
                    </DoubleColumnRowParent>
                  </Row>
                  :
                  displayServiceTreatmentDropdown()
                }
                {
                  !refreshTime && !refreshEndTime 
                  &&
                  <DropDownWithTitle
                    name={"endTime"}
                    title={intl.formatMessage({ id: "EndTime"})}
                    specifyReturnFieldName={[{"field": "endTime", "value": "value"}]}
                    labelField={"label"}
                    valueField={"value"}
                    options={timeList}
                    disabled={disabledFieldInput || viewAction || !blnIsEditable}
                    initialLabel={validation.values.endTime}
                    initialValue={validation.values.endTime}
                    validationRequired={true}
                    validation={validation}/>
                }
                <div>
                  <h6>{intl.formatMessage({ id: "AppointmentStatus" })}</h6>
                  <div style={{marginTop: '20px', marginBottom: '10px'}}>
                    <span style={{ backgroundColor: appointmentStatusColor(validation.values.appointmentStatus), color: "#FFFFFF", paddingLeft: '18px', paddingRight: '18px', paddingTop: '8px', paddingBottom: '8px', borderRadius: '5px' }}>{newSpaceBeforeCapitalLetter(validation.values.appointmentStatus)}</span>
                  </div>
                </div>
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "Notification"})} 
                  className="margin-top-32"
                  h4Title/>
                <Row className="mt-4">
                  {/* <Col xs={"12"} md={"5"} xl={windowSize.innerWidth ? "5" : "4"}>
                    <GeneralInput
                      title=""
                      name="isNotifyConsultant"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "CheckboxNotifyTitle" }, { field: intl.formatMessage({id: "Consultant"})})}</Label>}/>
                  </Col> */}
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title=""
                      name="isNotifyCustomer"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction || !blnIsEditable}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "CheckboxNotifyTitle" }, { field: intl.formatMessage({id: "Customer"})})}</Label>}/>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} className={`${Constants.innerWidthCompare>windowSize.innerWidth ? "margin-top-16" : ""}`}>
                {!blnTableOrMobile && displayCustomerDetailUI(false)}
                <GeneralTextArea
                  title={intl.formatMessage({ id: "Remark"})}
                  name="remark"
                  row={5}
                  disabled={(blnStatusNotShowOrCompletedDetected ? disabledFieldInputWithLoadingOnly : disabledFieldInput) || viewAction}
                  validation={validation}/>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn 
              className="mt-4"
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
        {
          !loading
          &&
          !addAction
          ?
          <div className="margin-top-32 standard-layout">
            <LineBreakWithTittle 
              paddingBottom="0px"
              title={intl.formatMessage({ id: "History" })} 
              className="mb-4"
              h4Title/>
            <PaginationTableWithoutApi 
              title={intl.formatMessage({ id: "History" })}
              headerClassName={"table-light"}
              options={appointmentHistory}
              columns={AppointmentHistoryColumns}
              pageSize={Constants.maxPageSize}
              keyField={"performedDate"}
              striped={true}/>
          </div>
          :
          null
        }
      </DetailViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          blnHideOtherBranch={false}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect}
          branchName={validation.values.branchName}
          branchId={validation.values.branchId} />
      }
      {
        customerCriteriaSearchModal
        &&
        <ReferredByCustomerSearchModal
          blnShow={customerCriteriaSearchModal}
          setModal={setCustomerCriteriaSearchModal}
          onSelectFunction={onSelectCustomerCriteriaSearch} />
      }
      {
        futureValidAppointmentModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "FutureValidAppointmentAlert"}, { branch: futureValidAppointmentDetail?.branchName, dateAndTime: moment(futureValidAppointmentDetail?.appointmentStartDateTime).format(Constants.displayDateAndTimeFormat), endTime: moment(futureValidAppointmentDetail?.appointmentEndDateTime).format(Constants.displayTimeFormat) })}`}
          primaryActionTitle={`Close`}
          showPrompt={futureValidAppointmentModal}
          setShowPrompt={setFutureValidAppointmentModal}
          onPrimaryClick={()=> {}}
          blnHideCancelButton={true}
        />
      }
      {
        Boolean(appointmentErrorPrompt)
        &&
        <ActionPromptModal 
          title={`${appointmentErrorPrompt}`}
          primaryActionTitle={`Close`}
          showPrompt={Boolean(appointmentErrorPrompt)}
          setShowPrompt={()=> {
            setAppointmentErrorPrompt("");
          }}
          onPrimaryClick={()=> {}}
          blnHideCancelButton={true}
        />
      }
      {
        blnRejoinOrCrossBrandModal
        &&
        <ActionPromptModal 
          title={addAction ? intl.formatMessage({ id: "CreateAppointmentWithRejoinOrCrossBrand" }) : intl.formatMessage({ id: "UpdateAppointmentWithRejoinOrCrossBrand" })}
          showPrompt={true}
          setShowPrompt={()=> {}}
          primaryActionTitle={intl.formatMessage({ id: "Ok" }).toUpperCase()}
          onPrimaryClick={()=> {
            let aryPath = location.pathname.split('/');
            if (aryPath.length > 2) {
              const firstPath = location.pathname.split('/')[1];
              history.push({
                pathname: `/${firstPath}`,
                state: { action: "refreshAppointmentData" }
              });
            }
          }}
          blnHideCancelButton={true}
          replaceIcon={returnSuccessMarkedIcon}/>
      }
		</div>
	);
};

export default observer(AppointmentDetail);
