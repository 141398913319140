import { useState } from "react";
import {
  Modal,
  Button
} from "reactstrap";
import Select from 'react-select';
import _ from 'lodash';
import { AddOptionsValueField, customFilter } from "../../common/function/function";
import MyAddButton from "../form/MyAddButton";
import { Constants } from "../../constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  buttonTittle: string;
  placeholder?: string;
  options: any; //Dropdown data to display
  setOptionsFunction?: any;
  valueField: string,
  labelField: string,
  fieldName?: string;
  fieldInput?: any;
  onClick?: Function;
  onClickWithSingleValueReturn?: Function;
  validation?: any;
  loading?: boolean;
  blnSupportCustomSearch?: boolean;
  onChangeFunction?: Function;
  onChangeCustomSearch?: Function;
  onMenuOpenFunction?: Function;
  loadingCustomSearch?: boolean;
  setLoadingCustomSearch?: Function;
  includeValueField?: string;
}

let localSearchValue = "";
export default function SelectDropDownModal(props: Props) {
  const [selectedValue, setSelectedValue ] = useState("");
  const optionsTemp = AddOptionsValueField(props.options, props.labelField, props.valueField, props.includeValueField);
  
  let timeOut : any =  0;

  const hideModal = () => {
    props.setModal(false);
    setSelectedValue("");
  }

  const localOnChangeCustomSearch = (value) => {
    clearTimeoutFunction();
    localSearchValue = value;

    if (value.length < Constants.dropdownSearchValueLength) {
      if (props.onMenuOpenFunction) {
        props.onMenuOpenFunction();
      }
      return;
    }
    
    timeOut = setTimeout(() => {
      if (props.setLoadingCustomSearch) {
        props.setLoadingCustomSearch(true);
      }
      if (props.onChangeCustomSearch) {
        props.onChangeCustomSearch(value);
      }
    }, Constants.dropdownSearchAPITimeout);
  }

  const clearTimeoutFunction = () => {
    localSearchValue = "";
    if(timeOut) {
      clearTimeout(timeOut)
    }
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Select
          className="mb-3"
          classNamePrefix={props.title}
          options={optionsTemp}
          isOptionDisabled={(option) => option.disabled}
          placeholder={props.placeholder}
          menuPosition="fixed"
          onChange={(e: any)=> {
            setSelectedValue(e);
            if (props.onChangeFunction) { 
              props.onChangeFunction();
            }
          }}
          onInputChange={(value: any)=> {
            if (props.blnSupportCustomSearch && props.onChangeCustomSearch && props.setLoadingCustomSearch) {
              localOnChangeCustomSearch(value);
            }
          }}
          {...(props.blnSupportCustomSearch && props.loadingCustomSearch) && {noOptionsMessage : ()=> ('Loading...')}}
          {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && (!localSearchValue || localSearchValue.length < Constants.dropdownSearchValueLength)) && {noOptionsMessage : ()=> (`Please ${selectedValue ? "re-enter" : "enter"} ${localSearchValue.length < Constants.dropdownSearchValueLength ? Math.abs(localSearchValue.length-Constants.dropdownSearchValueLength) : ""} or more characters to search`)}}
          {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && localSearchValue.length > (Constants.dropdownSearchValueLength-1)) && {noOptionsMessage : ()=> (`No options`)}}
          {...props.blnSupportCustomSearch && {filterOption: customFilter}}
          onMenuOpen={()=> {
            if (props.blnSupportCustomSearch && props.onMenuOpenFunction) {
              props.onMenuOpenFunction();
            }

            setTimeout(()=>{
              const selectedEl = document.getElementsByClassName(`${props.title}__option--is-selected`)[0];
              if(selectedEl){
                selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
              }
            },15)
          }}
        />
        <div className="">
          <MyAddButton
            content={props.buttonTittle}
            onClick={()=> {
              if (selectedValue) {
                delete selectedValue["label"];
                delete selectedValue["value"];
                const aryTemp = _.cloneDeep(props.fieldInput);
                const aryOptionsTemp = _.cloneDeep(props.options);
                const indexSelectedValue = _.findIndex(props.options, selectedValue);
                if (aryTemp) {
                  aryTemp.push(selectedValue);
                }
                if (indexSelectedValue > -1) {
                  aryOptionsTemp[indexSelectedValue] = {...aryOptionsTemp[indexSelectedValue], disabled: true}
                }
                if (props?.validation?.setFieldValue) {
                  props.validation.setFieldValue(props.fieldName, aryTemp);
                }
                if (props.setOptionsFunction) {
                  props.setOptionsFunction(aryOptionsTemp);
                }
                if (props.onClickWithSingleValueReturn) {
                  props.onClickWithSingleValueReturn(selectedValue[props.valueField])
                }
                setTimeout(()=> {
                  if (props.onClick) {
                      props.onClick()
                  }
                }, 1000)
              }
              hideModal();
            }}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  )
}