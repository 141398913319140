import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import LoginForm from '../features/Authentication/LoginForm';
import ForgetPassword from "../features/Authentication/ForgetPassword";
import ForgetPasswordValidator from "../features/Authentication/ForgetPasswordValidator";

// Dashboard
import Dashboard from '../features/Dashboard/Dashboard';

//Branch
import Branch from "../features/Branch/Branch";
import BranchDetail from "../features/Branch/BranchDetail";

//Brand
import Brand from "../features/Brand/Brand";
import BrandDetail from "../features/Brand/BrandDetail";
import BrandSiteMap from "../features/Brand/BrandSiteMap";

//Product
import Product from "../features/Product/Product";
import ProductDetail from '../features/Product/ProductDetail';
import ProductTemporally from "../features/Product/ProductTemporally";

//Service
import ServiceTreatment from "../features/ServiceTreatment/ServiceTreatment";
import ServiceTreatmentDetail from "../features/ServiceTreatment/ServiceTreatmentDetail";

//Package
import Package from "../features/Package/Package";
import PackageDetail from "../features/Package/PackageDetail";

//Employee
import Employee from "../features/Employee/Employee";
import EmployeeDetail from "../features/Employee/EmployeeDetail";
import EmployeeHqTemporally from "../features/Employee/EmployeeHqTemporally";

//Business Entity
import BusinessEntity from "../features/BusinessEntity/BusinessEntity";
import BusinessEntityDetail from "../features/BusinessEntity/BusinessEntityDetail";

//Country
import Country from "../features/Country/Country";
import CountryDetail from "../features/Country/CountryDetail";

//Currency
import Currency from "../features/Currency/Currency";
import CurrencyDetail from "../features/Currency/CurrencyDetail";

//Category
import Category from "../features/Category/Category";
import CategoryDetail from "../features/Category/CategoryDetail";

//Tax
import Tax from "../features/Tax/Tax";
import TaxDetail from "../features/Tax/TaxDetail";

//Race
import Race from "../features/Race/Race";
import RaceDetail from "../features/Race/RaceDetail";

//Sales Category
import SalesCategory from "../features/SalesCategory/SalesCategory";
import SalesCategoryDetail from "../features/SalesCategory/SalesCategoryDetail";

//EPF Contribution
import EPFContribution from "../features/EPFContribution/EPFContribution";

//EIS
import EIS from "../features/EIS/EIS";

//Socso
import Socso from '../features/Socso/Socso';

//PCB
import PCB from "../features/PCB/PCB";

//Appointment
import Appointment from "../features/Appointment/Appointment";
import AppointmentList from "../features/Appointment/AppointmentList";
import AppointmentDetail from "../features/Appointment/AppointmentDetail";
import AppointmentBlockerDetail from "../features/Appointment/AppointmentBlockerDetail";

//Media Source
import MediaSource from "../features/MediaSource/MediaSource";
import MediaSourceDetail
 from "../features/MediaSource/MediaSourceDetail";
 
//Measurement
import Measurement from "../features/Measurement/Measurement";
import MeasurementDetail from "../features/Measurement/MeasurementDetail";

//Customer
import CustomerOrder from "../features/CustomerOrder/CustomerOrder";
import CustomerOrderPayment from "../features/CustomerOrder/CustomerOrderPayment";
import CustomerOrderAcknowledgement from "../features/CustomerOrder/CustomerOrderAcknowledgement";

//Invoice
import InvoiceFromSalesOrder from "../features/Invoice/InvoiceFromSalesOrder";
import InvoiceDetailFromSalesOrder from "../features/Invoice/InvoiceDetailFromSalesOrder";
import ReceiptViewUI from "../features/Invoice/ReceiptViewUI";

//Position
import Position from "../features/Position/Position";
import PositionDetail from "../features/Position/PositionDetail";

//Supplier
import Supplier from "../features/Supplier/Supplier";
import SupplierDetail from "../features/Supplier/SupplierDetail";

//Sales Target
import SalesTarget from "../features/SalesTarget/SalesTarget";
import SalesTargetDetail from "../features/SalesTarget/SalesTargetDetail";

//Warehouse
import Warehouse from "../features/Warehouse/Warehouse";
import WarehouseDetail from "../features/Warehouse/WarehouseDetail";

//Customer Purchase
import CustomerPurchase from "../features/CustomerPurchase/CustomerPurchase";
import CustomerPurchaseAcknowledgement from "../features/CustomerPurchase/CustomerPurchaseAcknowledgement";

import Customer from "../features/Customer/Customer";
import CustomerDetail from "../features/Customer/CustomerDetail";
import CustomerProfile from "../features/Customer/CustomerProfile";
import CustomerProfilePurchase from "../features/Customer/CustomerProfilePurchase";

//Delivery Order
import DeliveryOrder from "../features/DeliveryOrder/DeliveryOrder";
import DeliveryOrderDetail from "../features/DeliveryOrder/DeliveryOrderDetail";
import StockMovement from "../features/StockMovement/StockMovement";
import StockMovementDetail from "../features/StockMovement/StockMovementDetail";

//Payroll Item
import PayrollItem from "../features/PayrollItem/PayrollItem";
import PayrollItemDetail from "../features/PayrollItem/PayrollItemDetail";

//Stock Request
import StockRequest from "../features/StockRequest/StockRequest";
import StockRequestDetail from "../features/StockRequest/StockRequestDetail";
import StockRequestViewDetail from "../features/StockRequest/StockRequestViewDetail";

//Stock Receive
import StockReceive from "../features/StockReceive/StockReceive";
import StockReceiveDetail from "../features/StockReceive/StockReceiveDetail";
import StockReceiveViewDetail from "../features/StockReceive/StockReceiveViewDetail";

//Leave Entitlement
import LeaveEntitlement from "../features/LeaveEntitlement/LeaveEntitlement";
import LeaveEntitlementDetail from "../features/LeaveEntitlement/LeaveEntitlementDetail";

//PurchaseOrder
import PurchaseOrder from "../features/PurchaseOrder/PurchaseOrder";
import PurchaseOrderDetail from "../features/PurchaseOrder/PurchaseOrderDetail";
import PurchaseOrderInvoiceDetail from "../features/PurchaseOrder/PurchaseOrderInvoiceDetail";

import { RoutesList } from "../app/constants/RoutesList";

//Error Page
import NotFound from "../features/Errors/NotFound";
import UnderMaintaince from "../features/Errors/UnderMaintainance";

//Inbound Notice
import InboundNotice from "../features/InboundNotice/InboundNotice";
import InboundNoticeDetail from "../features/InboundNotice/InboundNoticeDetail";
import InboundNoticeInvoiceDetail from "../features/InboundNotice/InboundNoticeInvoiceDetail";

//Goods Receive Note
import GoodsReceiveNote from "../features/GoodsReceiveNote/GoodsReceiveNote";
import GoodsReceiveNoteDetail from "../features/GoodsReceiveNote/GoodsReceiveNoteDetail";
import GoodsReceiveNoteInvoiceDetail from "../features/GoodsReceiveNote/GoodsReceiveNoteInvoiceDetail";

//Purchase Delivery Order
import PurchaseDeliverOrder from "../features/PurchaseDeliverOrder/PurchaseDeliverOrder";
import PurchaseDeliverOrderDetail from "../features/PurchaseDeliverOrder/PurchaseDeliverOrderDetail";
import PurchaseDeliverOrderInvoiceDetail from "../features/PurchaseDeliverOrder/PurchaseDeliverOrderInvoiceDetail";

//Profile
import ProfileDetail from "../features/Profile/ProfileDetail";
import ChangePassword from "../features/Profile/ChangePassword";
import UserRole from "../features/UserRole/UserRole";

//Permission
import Permission from "../features/Permission/Permission";

//Report Sales
import Report from "../features/Report/Report";
import ConsultantSales from "../features/Report/Consultant/ConsultantSales";
import ConsultantSalesSummary from "../features/Report/Consultant/ConsultantSalesSummary";
import ConsultantSalesCommission from "../features/Report/Consultant/ConsultantSalesCommission";
import DailySalesSummary from "../features/Report/DailySales/DailySalesSummary";
import DailySalesDetails from "../features/Report/DailySales/DailySalesDetails";
import SalesByCategory from "../features/Report/DynamicSales/SalesByCategory";
import DynamicSalesDetails from "../features/Report/DynamicSales/DynamicSalesDetails";
import ProductSold from "../features/Report/DynamicSales/ProductSold";
import SalesAdjustmentHistory from "../features/Report/DynamicSales/SalesAdjustmentHistory";
import MonthlySalesSummary from "../features/Report/DailySales/MonthlySalesSummary";
import SalesOverview from "../features/Report/DailySales/SalesOverview";
import SalesOverviewDetails from "../features/Report/DailySales/SalesOverviewDetails";
import SubBranchSalesSummary from "../features/Report/DailySales/SubBranchSalesSummary";
import ConsultantDailySales from "../features/Report/Consultant/ConsultantDailySales";
import DiscountSalesDetails from "../features/Report/DynamicSales/DiscountSalesDetails";
import SalesByEmployee from "../features/Report/DynamicSales/SalesByEmployee";
import SalesByProduct from "../features/Report/DynamicSales/SalesByProduct";
import ProductSalesDetails from "../features/Report/DailySales/ProductSalesDetails";
import ClosingRate from "../features/Report/DailySales/ClosingRate";
import SalesOverviewYearly from "../features/Report/DailySales/SalesOverviewYearly";
import BranchSalesSummary from "../features/Report/DailySales/BranchSalesSummary";
import BranchDailySalesSummary from "../features/Report/DailySales/BranchDailySalesSummary";
import SalesByGroupCode from "../features/Report/DynamicSales/SalesByGroupCode";
import SalesOrderCommissionSummary from "../features/Report/DailySales/SalesOrderCommissionSummary";
import ExchangeToCreditDetails from "../features/Report/DynamicSales/ExchangeToCreditDetails";
import RefundFromCredit from "../features/Report/DynamicSales/RefundFromCredit";
import BranchMonthlySalesSummary from "../features/Report/DailySales/BranchMonthlySalesSummary";
import SalesVariance from "../features/Report/DailySales/SalesVariance";

//Report Marcom
import LeadsSummaryByMedia from "../features/Report/Marcom/LeadsSummaryByMedia";
import LeadsSummaryByEmployee from "../features/Report/Marcom/LeadsSummaryByEmployee";
import LeadsDetails from "../features/Report/Marcom/LeadsDetails";
import PromoCodeHistory from "../features/Report/Marcom/PromoCodeHistory";
import CustomerTurnedUp from "../features/Report/Marcom/CustomerTurnedUp";
import LeadAppointments from "../features/Report/Marcom/LeadAppointments";
import AppointmentOverviewSummary from "../features/Report/Marcom/AppointmentOverviewSummary";
import LeadTurnUp from "../features/Report/Marcom/LeadTurnUp";

//Report Appointment
import AppointmentSummaryBySource from "../features/Report/Appointment/AppointmentSummaryBySource";
import AppointmentSummaryByStatus from "../features/Report/Appointment/AppointmentSummaryByStatus";
import AppointmentDetails from "../features/Report/Appointment/AppointmentDetails";
import RoomUtilization from "../features/Report/Appointment/RoomUtilization";
import RoomOccupancy from "../features/Report/Appointment/RoomOccupancy";

//Report Payment
import CollectionSummary from "../features/Report/Payment/CollectionSummary";
import CollectionDetails from "../features/Report/Payment/CollectionDetails";
import CollectOnBehalf from "../features/Report/Payment/CollectOnBehalf";
import CollectOnBehalfDetails from "../features/Report/Payment/CollectOnBehalfDetails";
import CombinedCollectionSummary from "../features/Report/Payment/CombinedCollectionSummary";

//Report Customer
import PackageUnutilizedBalance from "../features/Report/Customer/PackageUnutilizedBalance";
import ProductUnutilizedBalance from "../features/Report/Customer/ProductUnutilizedBalance";
import AverageSpendingSummary from "../features/Report/Customer/AverageSpendingSummary";
import CreditHistory from "../features/Report/Customer/CreditHistory";
import TransferCreditHistory from "../features/Report/Customer/TransferCreditHistory";
import ExpiringPackage from "../features/Report/Customer/ExpiringPackage";
import WithoutAppointment from "../features/Report/Customer/WithoutAppointment";
import CustomerBirthday from "../features/Report/Customer/CustomerBirthday";
import UpsalesSummary from "../features/Report/Customer/UpsalesSummary";
import WithoutPackage from "../features/Report/Customer/WithoutPackage";
import TopSpender from "../features/Report/Customer/TopSpender";
import CustomerDetailReport from "../features/Report/Customer/CustomerDetailReport";

//Report Services
import ServicesByEmployee from "../features/Report/Services/ServicesByEmployee";

//Report Stock
import StockForecastSummary from "../features/Report/Stock/StockForecastSummary";
import StockLevel from "../features/Report/Stock/StockLevel";
import StockCard from "../features/Report/Stock/StockCard";
import StockReceiveVariant from "../features/Report/Stock/StockReceiveVariant";
import ReservedProductSummary from "../features/Report/Stock/ReservedProductSummary";
import StockLevelHistory from "../features/Report/Stock/StockLevelHistory";
import ReservedProductReversalSummary from "../features/Report/Stock/ReservedProductReversalSummary";

//Report Management
import CompanyRevenueSummary from "../features/Report/Management/CompanyRevenueSummary";
import SalesOrderCost from "../features/Report/Management/SalesOrderCost";
import AccountPost from "../features/Report/Management/AccountPost";

//Report Master Data
import ProductMasterData from "../features/Report/MasterData/ProductMasterData";
import PackageMasterData from "../features/Report/MasterData/PackageMasterData";
import ServiceTreatmentMasterData from "../features/Report/MasterData/ServiceTreatmentMasterData";

//Report Human Resource
import DayOff from "../features/Report/HumanResource/DayOff";
import OutletSchedule from "../features/Report/HumanResource/OutletSchedule";

//Report Appolous
import AppolousPaymentCollectionSummary from "../features/Report/Appolous/AppolousPaymentCollectionSummary";
import AppolousDynamicSalesDetails from "../features/Report/Appolous/AppolousDynamicSalesDetails";
import AppolousProductCommissionVariant from "../features/Report/Appolous/AppolousProductCommissionVariant";
import AppolousFreePackageAndProduct from "../features/Report/Appolous/AppolousFreePackageAndProduct";

//Report Account
import CommissionCalculation from "../features/Report/Account/CommissionCalculation";
import ContractLiabilityReport from "../features/Report/Account/ContractLiabilityReport";
import EarnSalesSummary from "../features/Report/Account/EarnSalesSummary";
import UnearnedSalesDetails from "../features/Report/Account/UnearnedSalesDetails";
import RecogniseSalesDetails from "../features/Report/Account/RecogniseSalesDetails";
import PaymentCollection from "../features/Report/Account/PaymentCollection";
import ChangePaymentModeReport from "../features/Report/Account/ChangePaymentModeReport";

//Package Upgrade
import PackageUpgrade from "../features/PackageUpgrade/PackageUpgrade";
import PackagePayment from "../features/PackageUpgrade/PackagePayment";
import PackageAcknowledgement from "../features/PackageUpgrade/PackageAcknowledgement";

//NotificationTemplate
import NotificationTemplate from "../features/NotificationTemplate/NotificationTemplate";
import NotificationTemplateDetail from "../features/NotificationTemplate/NotificationTemplateDetail";

//Stock Adjustment
import StockAdjustment from "../features/StockAdjustment/StockAdjustment";
import StockAdjustmentDetail from "../features/StockAdjustment/StockAdjustmentDetail";
import StockAdjustmentInvoiceDetail from "../features/StockAdjustment/StockAdjustmentInvoiceDetail";

//Stock In
import StockIn from "../features/StockIn/StockIn";
import StockInDetail from "../features/StockIn/StockInDetail";
import StockInInvoiceDetail from "../features/StockIn/StockInInvoiceDetail";

//Stock Out
import StockOut from "../features/StockOut/StockOut";
import StockOutDetail from "../features/StockOut/StockOutDetail";
import StockOutInvoiceDetail from "../features/StockOut/StockOutInvoiceDetail";

//Stock Return to HQ
import StockReturnToHQ from "../features/StockReturnToHQ/StockReturnToHQ";
import StockReturnToHQDetail from "../features/StockReturnToHQ/StockReturnToHQDetail";
import StockReturnToHQInvoiceDetail from "../features/StockReturnToHQ/StockReturnToHQInvoiceDetail";

//Payment Method
import PaymentMethod from "../features/PaymentMethod/PaymentMethod";
import PaymentMethodDetail from "../features/PaymentMethod/PaymentMethodDetail";

//Payslip
import Payslip from "../features/PaySlip/Payslip";
import PayslipDetail from "../features/PaySlip/PaySlipDetail";

//Additional Form Field
import AdditionalFormField from "../features/AdditionalFormField/AdditionalFormField";
import AdditionalFormFieldDetail from "../features/AdditionalFormField/AdditionalFormFieldDetail";

//Notification
import Notification from "../features/Notification/Notification";

//Stock Take
import StockTake from "../features/StockTake/StockTake";
import StockTakeDetail from "../features/StockTake/StockTakeDetail";

//Audit Log
import AuditLog from "../features/AuditLog/AuditLog";

//Lead
import Lead from "../features/Lead/Lead";
import LeadDetail from "../features/Lead/LeadDetail";
import LeadFollowUp from "../features/Lead/LeadFollowUp";

//Customer Sales Order
import CustomerSalesOrder from "../features/CustomerSalesOrder/CustomerSalesOrder";
import CustomerSalesOrderDetail from "../features/CustomerSalesOrder/CustomerSalesOrderDetail";
import CustomerSalesOrderSiteMap from "../features/CustomerSalesOrder/CustomerSalesOrderSiteMap";
import CustomerSalesOrderCommissionDetail from "../features/CustomerSalesOrder/CustomerSalesOrderCommissionDetail";

//Settings
import AppointmentSettings from "../features/Settings/AppointmentSettings";
import GeneralSettings from "../features/Settings/GeneralSettings";
import SalesSettings from "../features/Settings/SalesSettings";
import CacheSettings from "../features/Settings/CacheSettings";

//Promotion
import Promotion from "../features/Promotion/Promotion";
import PromotionDetail from "../features/Promotion/PromotionDetail";

//CustomerRefund
import CustomerRefund from "../features/CustomerRefund/CustomerRefund";
import CustomerRefundDetail from "../features/CustomerRefund/CustomerRefundDetail";

//CreditAdjustment
import CreditAdjustment from "../features/CreditAdjustment/CreditAdjustment";
import CreditAdjustmentDetail from "../features/CreditAdjustment/CreditAdjustmentDetail";

//EmployeeLeave
import EmployeeLeave from "../features/EmployeeLeave/EmployeeLeave";
import EmployeeLeaveDetail from "../features/EmployeeLeave/EmployeeLeaveDetail";

//CustomerPaymentAdjustment
import PaymentAdjustment from "../features/CustomerPaymentAdjustment/PaymentAdjustment";

//Customer Product Return
import CustomerProductReturn from "../features/CustomerProductReturn/CustomerProductReturn";
import CustomerProrductReturnDetail from "../features/CustomerProductReturn/CustomerProductReturnDetail";
import CustomerProductReturnViewDetail from "../features/CustomerProductReturn/CustomerProductReturnViewDetail";

//Extend Package
import ExtendPackage from "../features/ExtendPackage/ExtendPackage";

//Lead Duplicate
import LeadDuplicate from "../features/LeadDuplicate/LeadDuplicate";
import LeadDuplicateDetail from "../features/LeadDuplicate/LeadDuplicateDetail";

//Payment Method Option
import PaymentMethodOption from "../features/PaymentMethodOption/PaymentMethodOption";
import PaymentMethodOptionDetail from "../features/PaymentMethodOption/PaymentMethodOptionDetail";

//Receipt
import ReceiptDetail from "../features/Receipt/ReceiptDetail";

//Sales Deduction
import SalesAdjustment from "../features/SalesAdjustment/SalesAdjustment";

//Product Commission
import ProductCommissionScheme from "../features/CommissionScheme/Product/ProductCommissionScheme";
import ProductCommissionSchemeDetail from "../features/CommissionScheme/Product/ProductCommissionSchemeDetail";

//Customer Access Request
import CustomerAccessRequest from "../features/CustomerAccessRequest/CustomerAccessRequest";

//Package Commission
import BundledProductCommissionScheme from "../features/CommissionScheme/Package/BundledProductCommissionScheme";
import BundledProductCommissionSchemeDetail from "../features/CommissionScheme/Package/BundledProductCommissionSchemeDetail";

//Customer Transfer Request
import CustomerTransferRequest from "../features/CustomerTransferRequest/CustomerTransferRequest";
import CustomerTransferRequestDetail from "../features/CustomerTransferRequest/CustomerTransferRequestDetail";

//AdditionBonusSalesTarget
import AdditionBonusSalesTarget from "../features/AdditionBonusSalesTarget/AdditionBonusSalesTarget";
import AdditionBonusSalesTargetDetail from "../features/AdditionBonusSalesTarget/AdditionBonusSalesTargetDetail";

//Branch Commission
import BranchCommission from "../features/BranchCommission/BranchCommission";
import BranchCommissionDetail from "../features/BranchCommission/BranchCommissionDetail";

//Contract Liability
import ContractLiability from "../features/ContractLiability/ContractLiability";
import ContractLiabilitySiteMap from "../features/ContractLiability/ContractLiabilitySiteMap";
import ContractLiabilityTimeline from "../features/ContractLiability/ContractLiabilityTimeline";

//Customer Virtual Warehouse
import CustomerVirtualWarehouse from "../features/CustomerVirtualWarehouse/CustomerVirtualWarehouse";
import CustomerVirtualWarehouseDetail from "../features/CustomerVirtualWarehouse/CustomerVirtualWarehouseDetail";

//Product Redemption Note
import ProductRedemptionNote from "../features/ProductRedemptionNote/ProductRedemptionNote";
import ProductRedemptionNoteDetail from "../features/ProductRedemptionNote/ProductRedemptionNoteDetail";
import ProductRedemptionNoteAcknowledgement from "../features/ProductRedemptionNote/ProductRedemptionNoteAcknowledgement";
import ProductRedemptionNoteViewDetail from "../features/ProductRedemptionNote/ProductRedemptionNoteViewDetail";

//Virtual Warehouse Credit Exchange
import VirtualWarehouseCreditExchange from "../features/VirtualWarehouseCreditExchange/VirtualWarehouseCreditExchange";
import VirtualWarehouseCreditExchangeDetail from "../features/VirtualWarehouseCreditExchange/VirtualWarehouseCreditExchangeDetail";
import VirtualWarehouseCreditExchangeViewDetail from "../features/VirtualWarehouseCreditExchange/VirtualWarehouseCreditExchangeViewDetail";

//Product Redemption Note Return
import ProductRedemptionNoteReturn from "../features/ProductRedemptionNoteReturn/ProductRedemptionNoteReturn";
import ProductRedemptionNoteReturnDetail from "../features/ProductRedemptionNoteReturn/ProductRedemptionNoteReturnDetail";
import ProductRedemptionNoteReturnViewDetail from "../features/ProductRedemptionNoteReturn/ProductRedemptionNoteReturnViewDetail";

//DSR Adjustment
import DSRAdjustment from "../features/DSRAdjustment/DSRAdjustment";

//Batch Job
import BatchJob from "../features/BatchJob/BatchJob";
import BatchJobDetail from "../features/BatchJob/BatchJobDetail";

//Customer Sub Branch
import CustomerSubBranchRequest from "../features/CustomerSubBranch/CustomerSubBranchRequest";
import CustomerSubBranchRequestDetail from "../features/CustomerSubBranch/CustomerSubBranchRequestDetail";

//Error
import CustomerProfileAccessDenied from "../features/Errors/CustomerProfileAccessDenied";

//Appolous Customer Package 
import AppolousCustomerPackageDetail from "../features/Appolous/CustomerPackage/AppolousCustomerPackageDetail";
import AppolousCustomerPackageRedemptionDetail from "../features/Appolous/CustomerPackage/AppolousCustomerPackageRedemptionDetail";
import AppolousCustomerPackagePurchase from "../features/Appolous/CustomerPackage/AppolousCustomerPackagePurchase";

//Appolous Customer Sales
import AppolousCustomerSalesDetail from "../features/Appolous/CustomerSales/AppolousCustomerSalesDetail";

//Appolous Customer Product
import AppolousCustomerProductDetail from "../features/Appolous/CustomerProduct/AppolousCustomerProductDetail";

//Virtual Warehouse Stock Movement
import VirtualWarehouseStockMovement from "../features/VirtualWarehouseStockMovement/VirtualWarehouseStockMovement";

//Appointment Target
import AppointmentTarget from "../features/AppointmentTarget/AppointmentTarget";
import AppointmentTargetDetail from "../features/AppointmentTarget/AppointmentTargetDetail";

//Marcom Daily Record
import MarcomDailyRecord from "../features/MarcomDailyRecord/MarcomDailyRecord";

//Cache
import Cache from "../features/Cache/Cache";

//Department
import Department from "../features/Department/Department";
import DepartmentDetail from "../features/Department/DepartmentDetail";

//Change Payment Method
import ChangePaymentMethod from "../features/CustomerChangePaymentMethod/CustomerChangePaymentMethod";

//Appointment Lead
import AppointmentLead from "../features/AppointmentLead/AppointmentLead";
import ApppointmentLeadProfile from "../features/AppointmentLead/ApppointmentLeadProfile";

//Machine
import Machine from "../features/Machine/Machine";
import MachineDetail from '../features/Machine/MachineDetail';
import MachineUsage from "../features/Machine/MachineUsage";

//Change Passcode
import ChangePasscode from "../features/Profile/ChangePasscode";

//Contract Liability Benchmark
import ContractLiabilityBenchmark from "../features/ContractLiabilityBenchmark/ContractLiabilityBenchmark";
import ContractLiabilityBenchmarkDetail from "../features/ContractLiabilityBenchmark/ContractLiabilityBenchmarkDetail";

//Contract Liability Benchmark
import GroupCode from "../features/GroupCode/GroupCode";
import GroupCodeDetail from "../features/GroupCode/GroupCodeDetail";

//Unknown Payment
import UnknownPayment from "../features/UnknownPayment/UnknownPayment";
import UnknownPaymentDetail from "../features/UnknownPayment/UnknownPaymentDetail";
import UnknownPaymentViewDetail from "../features/UnknownPayment/UnknownPaymentViewDetail";

//Cash Out
import CashOut from "../features/CashOut/CashOut";
import CashOutDetail from "../features/CashOut/CashOutDetail";
import CashOutViewDetail from "../features/CashOut/CashOutViewDetail";

//Payment Terminal
import PaymentTerminal from "../features/PaymentTerminal/PaymentTerminal";
import PaymentTerminalDetail from "../features/PaymentTerminal/PaymentTerminalDetail";

//Change Payment Mode
import ChangePaymentMode from "../features/ChangePaymentMode/ChangePaymentMode";
import ChangePaymentModeDetail from "../features/ChangePaymentMode/ChangePaymentModeDetail";
import ChangePaymentModeViewDetail from "../features/ChangePaymentMode/ChangePaymentModeViewDetail";

//Close Counter
import CloseCounter from "../features/CloseCounter/CloseCounter";
import CloseCounterDetail from "../features/CloseCounter/CloseCounterDetail";
import OverrideCloseCounterDetail from "../features/CloseCounter/OverrideCloseCounterDetail";

//Test
import testTimeZone from "../features/Test/testTimeZone";

//Tnc
import Tnc from "../features/Tnc/Tnc";
import TncDetail from "../features/Tnc/TncDetail";

//Marcom Report
import MarcomReport from "../features/MarcomReport/MarcomReport";
import NewVisitVsTurnUp from "../features/Report/Marcom/NewVisitVsTurnUp";

const authProtectedRoutes = [
  { path: `/${RoutesList.dashboard}`, component: Dashboard },
  { path: `/${RoutesList.branch}`, component: Branch },
  { path: `/${RoutesList.branch}/add`, component: BranchDetail },
  { path: `/${RoutesList.branch}/edit/:id`, component: BranchDetail },
  { path: `/${RoutesList.branch}/view/:id`, component: BranchDetail },
  { path: `/${RoutesList.brand}`, component: Brand },
  { path: `/${RoutesList.brand}/add`, component: BrandDetail },
  { path: `/${RoutesList.brand}/edit/:id`, component: BrandDetail },
  { path: `/${RoutesList.brand}/view/:id`, component: BrandDetail },
  { path: `/${RoutesList.brand}/sitemap/:id`, component: BrandSiteMap },
  { path: `/${RoutesList.product}`, component: Product },
  { path: `/stockbalance`, component: ProductTemporally },
  { path: `/${RoutesList.product}/add`, component: ProductDetail },
  { path: `/${RoutesList.product}/edit/:id`, component: ProductDetail },
  { path: `/${RoutesList.product}/view/:id`, component: ProductDetail },
  { path: `/${RoutesList.serviceTreatment}`, component: ServiceTreatment },
  { path: `/${RoutesList.serviceTreatment}/add`, component: ServiceTreatmentDetail },
  { path: `/${RoutesList.serviceTreatment}/edit/:id`, component: ServiceTreatmentDetail },
  { path: `/${RoutesList.serviceTreatment}/view/:id`, component: ServiceTreatmentDetail },
  { path: `/${RoutesList.package}`, component: Package },
  { path: `/${RoutesList.package}/add`, component: PackageDetail },
  { path: `/${RoutesList.package}/edit/:id`, component: PackageDetail },
  { path: `/${RoutesList.package}/view/:id`, component: PackageDetail },
  { path: `/${RoutesList.employee}`, component: Employee},
  { path: `/${RoutesList.employee}/add`, component: EmployeeDetail },
  { path: `/${RoutesList.employee}/edit/:id`, component: EmployeeDetail },
  { path: `/${RoutesList.employee}/view/:id`, component: EmployeeDetail },
  { path: `/${RoutesList.businessEntity}`, component: BusinessEntity},
  { path: `/${RoutesList.businessEntity}/add`, component: BusinessEntityDetail },
  { path: `/${RoutesList.businessEntity}/edit/:id`, component: BusinessEntityDetail },
  { path: `/${RoutesList.businessEntity}/view/:id`, component: BusinessEntityDetail },
  { path: `/${RoutesList.country}`, component: Country},
  { path: `/${RoutesList.country}/add`, component: CountryDetail },
  { path: `/${RoutesList.country}/edit/:id`, component: CountryDetail },
  { path: `/${RoutesList.country}/view/:id`, component: CountryDetail },
  { path: `/${RoutesList.currency}`, component: Currency},
  { path: `/${RoutesList.currency}/add`, component: CurrencyDetail },
  { path: `/${RoutesList.currency}/edit/:id`, component: CurrencyDetail },
  { path: `/${RoutesList.currency}/view/:id`, component: CurrencyDetail },
  { path: `/${RoutesList.category}`, component: Category},
  { path: `/${RoutesList.category}/add`, component: CategoryDetail },
  { path: `/${RoutesList.category}/edit/:id`, component: CategoryDetail },
  { path: `/${RoutesList.category}/view/:id`, component: CategoryDetail },
  { path: `/${RoutesList.salesCategory}`, component: SalesCategory},
  { path: `/${RoutesList.salesCategory}/add`, component: SalesCategoryDetail },
  { path: `/${RoutesList.salesCategory}/edit/:id`, component: SalesCategoryDetail },
  { path: `/${RoutesList.salesCategory}/view/:id`, component: SalesCategoryDetail },
  { path: `/${RoutesList.tax}`, component: Tax},
  { path: `/${RoutesList.tax}/add`, component: TaxDetail },
  { path: `/${RoutesList.tax}/edit/:id`, component: TaxDetail },
  { path: `/${RoutesList.tax}/view/:id`, component: TaxDetail },
  { path: `/${RoutesList.race}`, component: Race},
  { path: `/${RoutesList.race}/add`, component: RaceDetail },
  { path: `/${RoutesList.race}/edit/:id`, component: RaceDetail },
  { path: `/${RoutesList.race}/view/:id`, component: RaceDetail },
  { path: `/${RoutesList.epfcontribution}`, component: EPFContribution},
  { path: `/${RoutesList.eis}`, component: EIS},
  { path: `/${RoutesList.socso}`, component: Socso},
  { path: `/${RoutesList.pcb}`, component: PCB},
  { path: `/${RoutesList.appointment}`, component: Appointment},
  { path: `/${RoutesList.appointmentList}`, component: AppointmentList},
  { path: `/${RoutesList.appointment}/add`, component: AppointmentDetail },
  { path: `/${RoutesList.appointment}/edit/:id`, component: AppointmentDetail },
  { path: `/${RoutesList.appointment}/view/:id`, component: AppointmentDetail },
  { path: `/${RoutesList.appointmentList}/add`, component: AppointmentDetail },
  { path: `/${RoutesList.appointmentList}/edit/:id`, component: AppointmentDetail },
  { path: `/${RoutesList.appointmentList}/view/:id`, component: AppointmentDetail },
  { path: `/${RoutesList.appointment}/blocker/add`, component: AppointmentBlockerDetail },
  { path: `/${RoutesList.appointment}/blocker/edit/:id`, component: AppointmentBlockerDetail },
  { path: `/${RoutesList.appointment}/blocker/view/:id`, component: AppointmentBlockerDetail },
  { path: `/${RoutesList.appointmentList}/blocker/edit/:id`, component: AppointmentBlockerDetail },
  { path: `/${RoutesList.appointmentList}/blocker/view/:id`, component: AppointmentBlockerDetail },
  { path: `/${RoutesList.mediaSource}`, component: MediaSource},
  { path: `/${RoutesList.mediaSource}/add`, component: MediaSourceDetail },
  { path: `/${RoutesList.mediaSource}/edit/:id`, component: MediaSourceDetail },
  { path: `/${RoutesList.mediaSource}/view/:id`, component: MediaSourceDetail },
  { path: `/${RoutesList.measurement}`, component: Measurement},
  { path: `/${RoutesList.measurement}/add`, component: MeasurementDetail },
  { path: `/${RoutesList.measurement}/edit/:id`, component: MeasurementDetail },
  { path: `/${RoutesList.measurement}/view/:id`, component: MeasurementDetail },
  { path: `/${RoutesList.pos}`, component: CustomerOrder},
  { path: `/${RoutesList.posPayment}`, component: CustomerOrderPayment},
  { path: `/${RoutesList.posAcknowledgement}`, component: CustomerOrderAcknowledgement},
  { path: `/${RoutesList.invoice}`, component: InvoiceFromSalesOrder},
  { path: `/${RoutesList.invoice}/view/:id`, component: InvoiceDetailFromSalesOrder},
  { path: `/${RoutesList.receiptUI}/view/:id`, component: ReceiptViewUI},
  { path: `/${RoutesList.position}`, component: Position},
  { path: `/${RoutesList.position}/add`, component: PositionDetail },
  { path: `/${RoutesList.position}/edit/:id`, component: PositionDetail },
  { path: `/${RoutesList.position}/view/:id`, component: PositionDetail },
  { path: `/${RoutesList.supplier}`, component: Supplier},
  { path: `/${RoutesList.supplier}/add`, component: SupplierDetail },
  { path: `/${RoutesList.supplier}/edit/:id`, component: SupplierDetail },
  { path: `/${RoutesList.supplier}/view/:id`, component: SupplierDetail },
  { path: `/${RoutesList.salesTarget}`, component: SalesTarget},
  { path: `/${RoutesList.salesTarget}/add`, component: SalesTargetDetail },
  { path: `/${RoutesList.salesTarget}/edit/:id`, component: SalesTargetDetail },
  { path: `/${RoutesList.salesTarget}/view/:id`, component: SalesTargetDetail },
  { path: `/${RoutesList.warehouse}`, component: Warehouse},
  { path: `/${RoutesList.warehouse}/add`, component: WarehouseDetail },
  { path: `/${RoutesList.warehouse}/edit/:id`, component: WarehouseDetail },
  { path: `/${RoutesList.warehouse}/view/:id`, component: WarehouseDetail },
  { path: `/${RoutesList.customerPurchase}`, component: CustomerPurchase},
  { path: `/${RoutesList.customerPurchaseAcknowledgement}`, component: CustomerPurchaseAcknowledgement},
  { path: `/${RoutesList.customer}`, component: Customer},
  { path: `/${RoutesList.customer}/add`, component: CustomerDetail },
  { path: `/${RoutesList.customer}/edit/:id`, component: CustomerDetail },
  { path: `/${RoutesList.customer}/view/:id`, component: CustomerProfile },
  { path: `/${RoutesList.customerRedemption}`, component: DeliveryOrder},
  { path: `/${RoutesList.customerRedemption}/add`, component: DeliveryOrderDetail },
  { path: `/${RoutesList.customerRedemption}/edit/:id`, component: DeliveryOrderDetail },
  { path: `/${RoutesList.customerRedemption}/view/:id`, component: DeliveryOrderDetail },
  { path: `/${RoutesList.stockMovement}`, component: StockMovement},
  { path: `/${RoutesList.stockMovement}/add`, component: StockMovementDetail },
  { path: `/${RoutesList.stockMovement}/edit/:id`, component: StockMovementDetail },
  { path: `/${RoutesList.stockMovement}/view/:id`, component: StockMovementDetail },
  { path: `/${RoutesList.payrollItem}`, component: PayrollItem},
  { path: `/${RoutesList.payrollItem}/add`, component: PayrollItemDetail },
  { path: `/${RoutesList.payrollItem}/edit/:id`, component: PayrollItemDetail },
  { path: `/${RoutesList.payrollItem}/view/:id`, component: PayrollItemDetail },
  { path: `/${RoutesList.stockRequest}`, component: StockRequest },
  { path: `/${RoutesList.stockRequest}/add`, component: StockRequestDetail },
  { path: `/${RoutesList.stockRequest}/edit/:id`, component: StockRequestDetail },
  { path: `/${RoutesList.stockRequest}/view/:id`, component: StockRequestViewDetail },
  { path: `/${RoutesList.stockReceive}`, component: StockReceive },
  { path: `/${RoutesList.stockReceive}/add`, component: StockReceiveDetail },
  { path: `/${RoutesList.stockReceive}/edit/:id`, component: StockReceiveDetail },
  { path: `/${RoutesList.stockReceive}/view/:id`, component: StockReceiveViewDetail },
  { path: `/${RoutesList.leaveEntitlement}`, component: LeaveEntitlement },
  { path: `/${RoutesList.leaveEntitlement}/add`, component: LeaveEntitlementDetail },
  { path: `/${RoutesList.leaveEntitlement}/edit/:id`, component: LeaveEntitlementDetail },
  { path: `/${RoutesList.leaveEntitlement}/view/:id`, component: LeaveEntitlementDetail },
  { path: `/${RoutesList.payslip}`, component: Payslip },
  { path: `/${RoutesList.payslip}/view/:id`, component: PayslipDetail },
  { path: `/${RoutesList.purchaseOrder}`, component: PurchaseOrder },
  { path: `/${RoutesList.purchaseOrder}/add`, component: PurchaseOrderDetail },
  { path: `/${RoutesList.purchaseOrder}/edit/:id`, component: PurchaseOrderDetail },
  { path: `/${RoutesList.purchaseOrder}/view/:id`, component: PurchaseOrderInvoiceDetail },
  { path: `/${RoutesList.inboundNotice}`, component: InboundNotice },
  { path: `/${RoutesList.inboundNotice}/add`, component: InboundNoticeDetail },
  { path: `/${RoutesList.inboundNotice}/edit/:id`, component: InboundNoticeDetail },
  { path: `/${RoutesList.inboundNotice}/view/:id`, component: InboundNoticeInvoiceDetail },
  { path: `/${RoutesList.goodsReceiveNote}`, component: GoodsReceiveNote },
  { path: `/${RoutesList.goodsReceiveNote}/add`, component: GoodsReceiveNoteDetail },
  { path: `/${RoutesList.goodsReceiveNote}/edit/:id`, component: GoodsReceiveNoteDetail },
  { path: `/${RoutesList.goodsReceiveNote}/view/:id`, component: GoodsReceiveNoteInvoiceDetail },
  { path: `/${RoutesList.purchaseDeliverOrder}`, component: PurchaseDeliverOrder },
  { path: `/${RoutesList.purchaseDeliverOrder}/add`, component: PurchaseDeliverOrderDetail },
  { path: `/${RoutesList.purchaseDeliverOrder}/edit/:id`, component: PurchaseDeliverOrderDetail },
  { path: `/${RoutesList.purchaseDeliverOrder}/view/:id`, component: PurchaseDeliverOrderInvoiceDetail },
  { path: `/${RoutesList.permission}`, component: Permission },
  { path: `/${RoutesList.profile}`, component: ProfileDetail },
  { path: `/${RoutesList.changePassword}`, component: ChangePassword },
  { path: `/${RoutesList.userRole}`, component: UserRole },
  { path: `/${RoutesList.report}`, component: Report },
  { path: `/${RoutesList.report}/${RoutesList.consultantSalesSummary}`, component: ConsultantSalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.consultantSales}`, component: ConsultantSales },
  { path: `/${RoutesList.report}/${RoutesList.consultantSalesCommission}`, component: ConsultantSalesCommission },
  { path: `/${RoutesList.report}/${RoutesList.consultantDailySales}`, component: ConsultantDailySales },
  { path: `/${RoutesList.packageUpgrade}`, component: PackageUpgrade },
  { path: `/${RoutesList.packageUpgradePayment}`, component: PackagePayment },
  { path: `/${RoutesList.packageUpgradeAcknowledgement}`, component: PackageAcknowledgement },
  { path: `/${RoutesList.notificationTemplate}`, component: NotificationTemplate },
  { path: `/${RoutesList.notificationTemplate}/add`, component: NotificationTemplateDetail },
  { path: `/${RoutesList.notificationTemplate}/edit/:id`, component: NotificationTemplateDetail },
  { path: `/${RoutesList.notificationTemplate}/view/:id`, component: NotificationTemplateDetail },
  { path: `/${RoutesList.stockAdjustment}`, component: StockAdjustment },
  { path: `/${RoutesList.stockAdjustment}/add`, component: StockAdjustmentDetail },
  { path: `/${RoutesList.stockAdjustment}/edit/:id`, component: StockAdjustmentDetail },
  { path: `/${RoutesList.stockAdjustment}/view/:id`, component: StockAdjustmentInvoiceDetail },
  { path: `/${RoutesList.stockIn}`, component: StockIn },
  { path: `/${RoutesList.stockIn}/add`, component: StockInDetail },
  { path: `/${RoutesList.stockIn}/edit/:id`, component: StockInDetail },
  { path: `/${RoutesList.stockIn}/view/:id`, component: StockInInvoiceDetail },
  { path: `/${RoutesList.stockOut}`, component: StockOut },
  { path: `/${RoutesList.stockOut}/add`, component: StockOutDetail },
  { path: `/${RoutesList.stockOut}/edit/:id`, component: StockOutDetail },
  { path: `/${RoutesList.stockOut}/view/:id`, component: StockOutInvoiceDetail },
  { path: `/${RoutesList.stockReturnToHQ}`, component: StockReturnToHQ },
  { path: `/${RoutesList.stockReturnToHQ}/add`, component: StockReturnToHQDetail },
  { path: `/${RoutesList.stockReturnToHQ}/edit/:id`, component: StockReturnToHQInvoiceDetail },
  { path: `/${RoutesList.stockReturnToHQ}/view/:id`, component: StockReturnToHQInvoiceDetail },
  { path: `/${RoutesList.paymentMethod}`, component: PaymentMethod },
  { path: `/${RoutesList.paymentMethod}/add`, component: PaymentMethodDetail },
  { path: `/${RoutesList.paymentMethod}/edit/:id`, component: PaymentMethodDetail },
  { path: `/${RoutesList.paymentMethod}/view/:id`, component: PaymentMethodDetail },
  { path: `/${RoutesList.additionalFormField}`, component: AdditionalFormField },
  { path: `/${RoutesList.additionalFormField}/add`, component: AdditionalFormFieldDetail },
  { path: `/${RoutesList.additionalFormField}/edit/:id`, component: AdditionalFormFieldDetail },
  { path: `/${RoutesList.additionalFormField}/view/:id`, component: AdditionalFormFieldDetail },
  { path: `/${RoutesList.notification}`, component: Notification },
  { path: `/${RoutesList.stockTake}`, component: StockTake },
  { path: `/${RoutesList.stockTake}/add`, component: StockTakeDetail },
  { path: `/${RoutesList.stockTake}/edit/:id`, component: StockTakeDetail },
  { path: `/${RoutesList.stockTake}/view/:id`, component: StockTakeDetail },
  { path: `/${RoutesList.auditLog}`, component: AuditLog },
  { path: `/${RoutesList.lead}`, component: Lead },
  { path: `/${RoutesList.lead}/add`, component: LeadDetail },
  { path: `/${RoutesList.lead}/edit/:id`, component: LeadDetail },
  { path: `/${RoutesList.lead}/view/:id`, component: LeadFollowUp },
  { path: `/${RoutesList.customerSalesOrder}`, component: CustomerSalesOrder },
  { path: `/${RoutesList.customerSalesOrder}/view/:id`, component: CustomerSalesOrderDetail },
  { path: `/${RoutesList.customerSalesOrder}/sitemap/:id`, component: CustomerSalesOrderSiteMap },  
  { path: `/${RoutesList.customerSalesOrder}/commission/view/:id`, component: CustomerSalesOrderCommissionDetail },  
  { path: `/${RoutesList.receipt}/view/:id`, component: ReceiptDetail},
  { path: `/${RoutesList.appointmentSettings}`, component: AppointmentSettings },
  { path: `/${RoutesList.generalSettings}`, component: GeneralSettings },
  { path: `/${RoutesList.salesSettings}`, component: SalesSettings },
  { path: `/${RoutesList.promotion}`, component: Promotion },
  { path: `/${RoutesList.promotion}/add`, component: PromotionDetail },
  { path: `/${RoutesList.promotion}/edit/:id`, component: PromotionDetail },
  { path: `/${RoutesList.promotion}/view/:id`, component: PromotionDetail },
  { path: `/${RoutesList.exchangeToCredit}`, component: CustomerRefund },
  { path: `/${RoutesList.exchangeToCredit}/:id`, component: CustomerRefund },
  { path: `/${RoutesList.exchangeToCredit}/view/:id`, component: CustomerRefundDetail },
  { path: `/${RoutesList.creditAdjustment}`, component: CreditAdjustment },
  { path: `/${RoutesList.creditAdjustment}/:id`, component: CreditAdjustment },
  { path: `/${RoutesList.creditAdjustment}/view/:id`, component: CreditAdjustmentDetail },
  { path: `/${RoutesList.report}/${RoutesList.dailySalesSummary}`, component: DailySalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.dailySalesDetails}`, component: DailySalesDetails },
  { path: `/${RoutesList.employeeLeave}`, component: EmployeeLeave },
  { path: `/${RoutesList.employeeLeave}/add`, component: EmployeeLeaveDetail },
  { path: `/${RoutesList.employeeLeave}/edit/:id`, component: EmployeeLeaveDetail },
  { path: `/${RoutesList.employeeLeave}/view/:id`, component: EmployeeLeaveDetail },
  { path: `/${RoutesList.report}/${RoutesList.leadsSummaryByMedia}`, component: LeadsSummaryByMedia },
  { path: `/${RoutesList.report}/${RoutesList.leadsSummaryByEmployee}`, component: LeadsSummaryByEmployee },
  { path: `/${RoutesList.report}/${RoutesList.leadsDetail}`, component: LeadsDetails },
  { path: `/${RoutesList.report}/${RoutesList.appointmentSummaryBySource}`, component: AppointmentSummaryBySource },
  { path: `/${RoutesList.report}/${RoutesList.appointmentSummaryByStatus}`, component: AppointmentSummaryByStatus },
  { path: `/${RoutesList.report}/${RoutesList.appointmentDetails}`, component: AppointmentDetails },
  { path: `/${RoutesList.report}/${RoutesList.collectionSummary}`, component: CollectionSummary },
  { path: `/${RoutesList.report}/${RoutesList.collectionDetails}`, component: CollectionDetails },
  { path: `/${RoutesList.report}/${RoutesList.packageUnutilizedBalance}`, component: PackageUnutilizedBalance },
  { path: `/${RoutesList.report}/${RoutesList.productUnutilizedBalance}`, component: ProductUnutilizedBalance },
  { path: `/${RoutesList.report}/${RoutesList.sales}product`, component: SalesByProduct },
  // { path: `/${RoutesList.report}/${RoutesList.sales}package`, component: SalesByCategory },
  { path: `/${RoutesList.report}/${RoutesList.sales}service`, component: SalesByCategory },
  { path: `/${RoutesList.report}/${RoutesList.sales}salesCategory`, component: SalesByCategory },
  { path: `/${RoutesList.report}/${RoutesList.sales}employee`, component: SalesByEmployee },
  { path: `/${RoutesList.report}/${RoutesList.productSalesDetails}`, component: ProductSalesDetails },
  { path: `/${RoutesList.report}/${RoutesList.dynamicSalesDetails}`, component: DynamicSalesDetails },
  { path: `/${RoutesList.report}/${RoutesList.averageSpendingSummary}`, component: AverageSpendingSummary },
  { path: `/${RoutesList.report}/${RoutesList.services}employee`, component: ServicesByEmployee },
  { path: `/${RoutesList.report}/${RoutesList.stockForecastSummary}`, component: StockForecastSummary },
  { path: `/${RoutesList.report}/${RoutesList.stockCard}`, component: StockCard },
  { path: `/${RoutesList.report}/${RoutesList.stockLevel}`, component: StockLevel },
  { path: `/${RoutesList.report}/${RoutesList.stockReceiveVariant}`, component: StockReceiveVariant },
  { path: `/${RoutesList.report}/${RoutesList.reservedProductSummary}`, component: ReservedProductSummary },
  { path: `/${RoutesList.report}/${RoutesList.creditHistory}`, component: CreditHistory },
  { path: `/${RoutesList.report}/${RoutesList.transferCreditHistory}`, component: TransferCreditHistory },
  { path: `/${RoutesList.report}/${RoutesList.companyRevenue}`, component: CompanyRevenueSummary },
  { path: `/${RoutesList.report}/${RoutesList.salesOrderCost}`, component: SalesOrderCost },
  { path: `/${RoutesList.customerProductReturn}`, component: CustomerProductReturn },
  { path: `/${RoutesList.customerProductReturn}/add`, component: CustomerProrductReturnDetail },
  { path: `/${RoutesList.customerProductReturn}/view/:id`, component: CustomerProductReturnViewDetail },
  { path: `/${RoutesList.report}/${RoutesList.expiringPackage}`, component: ExpiringPackage },
  { path: `/${RoutesList.report}/${RoutesList.withoutAppointment}`, component: WithoutAppointment },
  { path: `/${RoutesList.report}/${RoutesList.promoCodeHistory}`, component: PromoCodeHistory },
  { path: `/${RoutesList.paymentAdjustment}`, component: PaymentAdjustment },
  { path: `/${RoutesList.paymentAdjustment}/:id`, component: PaymentAdjustment },
  { path: `/${RoutesList.report}/${RoutesList.customerBirthday}`, component: CustomerBirthday },
  { path: `/${RoutesList.extendPackage}`, component: ExtendPackage },
  { path: `/${RoutesList.leadDuplicate}`, component: LeadDuplicate },
  { path: `/${RoutesList.leadDuplicate}/view/:id`, component: LeadDuplicateDetail },
  { path: `/${RoutesList.paymentMethodOption}`, component: PaymentMethodOption },
  { path: `/${RoutesList.paymentMethodOption}/add`, component: PaymentMethodOptionDetail },
  { path: `/${RoutesList.paymentMethodOption}/edit/:id`, component: PaymentMethodOptionDetail },
  { path: `/${RoutesList.paymentMethodOption}/view/:id`, component: PaymentMethodOptionDetail },
  { path: `/${RoutesList.report}/${RoutesList.collectOnBehalf}`, component: CollectOnBehalf },
  { path: `/${RoutesList.report}/${RoutesList.collectOnBehalfDetails}`, component: CollectOnBehalfDetails },
  { path: `/${RoutesList.report}/${RoutesList.upsalesSummary}`, component: UpsalesSummary },
  { path: `/${RoutesList.salesAdjustment}`, component: SalesAdjustment },
  { path: `/${RoutesList.productCommissionScheme}`, component: ProductCommissionScheme },
  { path: `/${RoutesList.productCommissionScheme}/add`, component: ProductCommissionSchemeDetail },
  { path: `/${RoutesList.productCommissionScheme}/edit/:id`, component: ProductCommissionSchemeDetail },
  { path: `/${RoutesList.productCommissionScheme}/view/:id`, component: ProductCommissionSchemeDetail },
  { path: `/${RoutesList.report}/${RoutesList.productSold}`, component: ProductSold },
  { path: `/${RoutesList.customer}/${RoutesList.customerProfilePurchase}`, component: CustomerProfilePurchase },
  { path: `/${RoutesList.customerAccessRequest}`, component: CustomerAccessRequest },
  { path: `/${RoutesList.customerAccessRequest}/:id/:branchId`, component: CustomerAccessRequest },
  { path: `/${RoutesList.report}/${RoutesList.accountPost}`, component: AccountPost },
  { path: `/${RoutesList.report}/${RoutesList.salesAdjustmentHistory}`, component: SalesAdjustmentHistory },
  { path: `/${RoutesList.cache}`, component: CacheSettings },
  { path: `/${RoutesList.report}/${RoutesList.productMasterData}`, component: ProductMasterData },
  { path: `/${RoutesList.report}/${RoutesList.packageMasterData}`, component: PackageMasterData },
  { path: `/${RoutesList.report}/${RoutesList.serviceMasterData}`, component: ServiceTreatmentMasterData },
  { path: `/${RoutesList.bundledProductCommissionScheme}`, component: BundledProductCommissionScheme },
  { path: `/${RoutesList.bundledProductCommissionScheme}/add`, component: BundledProductCommissionSchemeDetail },
  { path: `/${RoutesList.bundledProductCommissionScheme}/edit/:id`, component: BundledProductCommissionSchemeDetail },
  { path: `/${RoutesList.bundledProductCommissionScheme}/view/:id`, component: BundledProductCommissionSchemeDetail },
  { path: `/${RoutesList.customerTransferRequest}`, component: CustomerTransferRequest },
  { path: `/${RoutesList.customerTransferRequest}/add`, component: CustomerTransferRequestDetail },
  { path: `/${RoutesList.customerTransferRequest}/edit/:id`, component: CustomerTransferRequestDetail },
  { path: `/${RoutesList.customerTransferRequest}/view/:id`, component: CustomerTransferRequestDetail },
  { path: `/${RoutesList.report}/${RoutesList.customerTurnedUp}`, component: CustomerTurnedUp },
  { path: `/${RoutesList.report}/${RoutesList.leadAppointments}`, component: LeadAppointments },
  { path: `/${RoutesList.contractLiability}/view/:id`, component: ContractLiability },
  { path: `/${RoutesList.contractLiabilitySiteMap}/:id`, component: ContractLiabilitySiteMap },
  { path: `/${RoutesList.contractLiabilityTimeline}/view/:id`, component: ContractLiabilityTimeline },
  { path: `/${RoutesList.report}/${RoutesList.withoutPackage}`, component: WithoutPackage },
  { path: `/${RoutesList.report}/${RoutesList.topSpender}`, component: TopSpender },
  { path: `/${RoutesList.additionBonusSalesTarget}`, component: AdditionBonusSalesTarget },
  { path: `/${RoutesList.additionBonusSalesTarget}/add`, component: AdditionBonusSalesTargetDetail },
  { path: `/${RoutesList.additionBonusSalesTarget}/edit/:id`, component: AdditionBonusSalesTargetDetail },
  { path: `/${RoutesList.additionBonusSalesTarget}/view/:id`, component: AdditionBonusSalesTargetDetail },
  { path: `/${RoutesList.branchCommission}`, component: BranchCommission },
  { path: `/${RoutesList.branchCommission}/add`, component: BranchCommissionDetail },
  { path: `/${RoutesList.branchCommission}/edit/:id`, component: BranchCommissionDetail },
  { path: `/${RoutesList.branchCommission}/view/:id`, component: BranchCommissionDetail },
  { path: `/${RoutesList.report}/${RoutesList.dayOff}`, component: DayOff },
  { path: `/${RoutesList.customerVirtualWarehouse}`, component: CustomerVirtualWarehouse },
  { path: `/${RoutesList.customerVirtualWarehouse}/view/:id`, component: CustomerVirtualWarehouseDetail },
  { path: `/${RoutesList.productRedemptionNote}`, component: ProductRedemptionNote },
  { path: `/${RoutesList.productRedemptionNote}/add`, component: ProductRedemptionNoteDetail },
  { path: `/${RoutesList.productRedemptionNote}/addFromCustomer`, component: ProductRedemptionNoteDetail },
  { path: `/${RoutesList.productRedemptionNote}/edit/:id`, component: ProductRedemptionNoteViewDetail },
  { path: `/${RoutesList.productRedemptionNote}/view/:id`, component: ProductRedemptionNoteViewDetail },
  { path: `/${RoutesList.productRedemptionNoteAcknowledgement}`, component: ProductRedemptionNoteAcknowledgement },
  { path: `/${RoutesList.virtualWarehouseCreditExchange}`, component: VirtualWarehouseCreditExchange },
  { path: `/${RoutesList.virtualWarehouseCreditExchange}/add`, component: VirtualWarehouseCreditExchangeDetail },
  { path: `/${RoutesList.virtualWarehouseCreditExchange}/addFromCustomer`, component: VirtualWarehouseCreditExchangeDetail },
  { path: `/${RoutesList.virtualWarehouseCreditExchange}/edit/:id`, component: VirtualWarehouseCreditExchangeViewDetail },
  { path: `/${RoutesList.virtualWarehouseCreditExchange}/view/:id`, component: VirtualWarehouseCreditExchangeViewDetail },
  { path: `/${RoutesList.productRedemptionNoteReturn}`, component: ProductRedemptionNoteReturn },
  { path: `/${RoutesList.productRedemptionNoteReturn}/add`, component: ProductRedemptionNoteReturnDetail },
  { path: `/${RoutesList.productRedemptionNoteReturn}/view/:id`, component: ProductRedemptionNoteReturnViewDetail },
  { path: `/${RoutesList.report}/${RoutesList.monthlySalesSummary}`, component: MonthlySalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.salesOverview}`, component: SalesOverview },
  { path: `/${RoutesList.report}/${RoutesList.outletSchedule}`, component: OutletSchedule },
  { path: `/${RoutesList.report}/${RoutesList.salesOverviewDetails}`, component: SalesOverviewDetails },
  { path: `/${RoutesList.dsrAdjusment}`, component: DSRAdjustment },
  { path: `/${RoutesList.batchJob}`, component: BatchJob },
  { path: `/${RoutesList.batchJob}/add`, component: BatchJobDetail },
  { path: `/${RoutesList.batchJob}/edit/:id`, component: BatchJobDetail },
  { path: `/${RoutesList.batchJob}/view/:id`, component: BatchJobDetail },
  { path: `/${RoutesList.customerSubBranchRequest}`, component: CustomerSubBranchRequest },
  { path: `/${RoutesList.customerSubBranchRequest}/edit/:id`, component: CustomerSubBranchRequestDetail },
  { path: `/${RoutesList.customerSubBranchRequest}/view/:id`, component: CustomerSubBranchRequestDetail },
  { path: `/${RoutesList.report}/${RoutesList.sharedSalesSummary}`, component: SubBranchSalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.roomUtilization}`, component: RoomUtilization },
  { path: `/${RoutesList.report}/${RoutesList.roomOccupancy}`, component: RoomOccupancy },
  { path: `/${RoutesList.customer}/${RoutesList.appolousCustomerPackage}/view/:id`, component: AppolousCustomerPackageDetail},
  { path: `/${RoutesList.customer}/${RoutesList.appolousCustomerPackageRedemption}/view/:id`, component: AppolousCustomerPackageRedemptionDetail},
  { path: `/${RoutesList.customer}/${RoutesList.appolousCustomerPackageRedemption}/redeem`, component: AppolousCustomerPackagePurchase},
  { path: `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/:id`, component: AppolousCustomerSalesDetail},
  { path: `/${RoutesList.customer}/${RoutesList.appolousCustomerProduct}/view/:id`, component: AppolousCustomerProductDetail},
  { path: `/${RoutesList.report}/${RoutesList.appolousPaymentCollectionSummary}`, component: AppolousPaymentCollectionSummary },
  { path: `/${RoutesList.report}/${RoutesList.appolousDynamicSalesDetails}`, component: AppolousDynamicSalesDetails },
  { path: `/${RoutesList.report}/${RoutesList.appolousProductCommissionVariant}`, component: AppolousProductCommissionVariant },
  { path: `/${RoutesList.reservedProductMovements}`, component: VirtualWarehouseStockMovement},
  { path: `/${RoutesList.appointmentTarget}`, component: AppointmentTarget },
  { path: `/${RoutesList.appointmentTarget}/add`, component: AppointmentTargetDetail },
  { path: `/${RoutesList.appointmentTarget}/edit/:id`, component: AppointmentTargetDetail },
  { path: `/${RoutesList.appointmentTarget}/view/:id`, component: AppointmentTargetDetail },
  { path: `/${RoutesList.marcomDailyRecord}`, component: MarcomDailyRecord },
  { path: `/${RoutesList.report}/${RoutesList.appointmentOverviewSummary}`, component: AppointmentOverviewSummary },
  { path: `/${RoutesList.report}/${RoutesList.appolousFreePackageAndProduct}`, component: AppolousFreePackageAndProduct },
  { path: `/${RoutesList.cacheList}`, component: Cache },
  { path: `/${RoutesList.report}/${RoutesList.discountSalesDetails}`, component: DiscountSalesDetails },
  { path: `/${RoutesList.report}/${RoutesList.combinedCollectionSummary}`, component: CombinedCollectionSummary },
  { path: `/${RoutesList.department}`, component: Department},
  { path: `/${RoutesList.department}/add`, component: DepartmentDetail },
  { path: `/${RoutesList.department}/edit/:id`, component: DepartmentDetail },
  { path: `/${RoutesList.department}/view/:id`, component: DepartmentDetail },
  { path: `/hqstaffdepartment`, component: EmployeeHqTemporally },
  { path: `/${RoutesList.changePaymentMethod}`, component: ChangePaymentMethod },
  { path: `/${RoutesList.changePaymentMethod}/:id`, component: ChangePaymentMethod },
  { path: `/${RoutesList.report}/${RoutesList.closingRate}`, component: ClosingRate },
  { path: `/${RoutesList.report}/${RoutesList.salesOverviewYearly}`, component: SalesOverviewYearly },
  { path: `/${RoutesList.appointmentLead}`, component: AppointmentLead },
  { path: `/${RoutesList.appointmentLead}/view/:id`, component: ApppointmentLeadProfile },
  { path: `/${RoutesList.machine}`, component: Machine },
  { path: `/${RoutesList.machine}/add`, component: MachineDetail },
  { path: `/${RoutesList.machine}/edit/:id`, component: MachineDetail },
  { path: `/${RoutesList.machine}/view/:id`, component: MachineDetail },
  { path: `/${RoutesList.machineUsage}`, component: MachineUsage },
  { path: `/${RoutesList.changePasscode}`, component: ChangePasscode },
  { path: `/${RoutesList.report}/${RoutesList.branchSalesSummary}`, component: BranchSalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.branchDailySalesSummary}`, component: BranchDailySalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.stockLevelHistory}`, component: StockLevelHistory },
  { path: `/${RoutesList.contractLiabilityBenchmark}`, component: ContractLiabilityBenchmark },
  { path: `/${RoutesList.contractLiabilityBenchmark}/add`, component: ContractLiabilityBenchmarkDetail },
  { path: `/${RoutesList.contractLiabilityBenchmark}/edit/:id`, component: ContractLiabilityBenchmarkDetail },
  { path: `/${RoutesList.contractLiabilityBenchmark}/view/:id`, component: ContractLiabilityBenchmarkDetail },
  { path: `/${RoutesList.groupCode}`, component: GroupCode },
  { path: `/${RoutesList.groupCode}/add`, component: GroupCodeDetail },
  { path: `/${RoutesList.groupCode}/edit/:id`, component: GroupCodeDetail },
  { path: `/${RoutesList.groupCode}/view/:id`, component: GroupCodeDetail },
  { path: `/${RoutesList.report}/${RoutesList.salesByGroupCode}`, component: SalesByGroupCode },
  { path: `/${RoutesList.report}/${RoutesList.commissionCalculation}`, component: CommissionCalculation },
  { path: `/${RoutesList.report}/${RoutesList.contractLiability}`, component: ContractLiabilityReport },
  { path: `/${RoutesList.report}/${RoutesList.salesOrderCommissionSummary}`, component: SalesOrderCommissionSummary },
  { path: `/${RoutesList.report}/${RoutesList.earnSalesSummary}`, component: EarnSalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.unearnedSalesDetails}`, component: UnearnedSalesDetails },  
  { path: `/${RoutesList.report}/${RoutesList.recogniseSalesDetails}`, component: RecogniseSalesDetails },  
  { path: `/${RoutesList.report}/${RoutesList.exchangeToCreditDetails}`, component: ExchangeToCreditDetails },
  { path: `/${RoutesList.report}/${RoutesList.refundFromCredit}`, component: RefundFromCredit },
  { path: `/${RoutesList.report}/${RoutesList.paymentCollection}`, component: PaymentCollection },
  { path: `/${RoutesList.report}/${RoutesList.branchMonthlySalesSummary}`, component: BranchMonthlySalesSummary },
  { path: `/${RoutesList.report}/${RoutesList.salesVariance}`, component: SalesVariance },
  { path: `/${RoutesList.report}/${RoutesList.reservedProductReversalSummary}`, component: ReservedProductReversalSummary },
  { path: `/${RoutesList.tnc}`, component: Tnc },
  { path: `/${RoutesList.tnc}/add`, component: TncDetail },
  { path: `/${RoutesList.tnc}/edit/:id`, component: TncDetail },
  { path: `/${RoutesList.tnc}/view/:id`, component: TncDetail },
  { path: `/${RoutesList.unknownPayment}`, component: UnknownPayment },
  { path: `/${RoutesList.unknownPayment}/add`, component: UnknownPaymentDetail },
  { path: `/${RoutesList.unknownPayment}/edit/:id`, component: UnknownPaymentDetail },
  { path: `/${RoutesList.unknownPayment}/view/:id`, component: UnknownPaymentViewDetail },
  { path: `/${RoutesList.cashOut}`, component: CashOut },
  { path: `/${RoutesList.cashOut}/add`, component: CashOutDetail },
  { path: `/${RoutesList.cashOut}/edit/:id`, component: CashOutDetail },
  { path: `/${RoutesList.cashOut}/view/:id`, component: CashOutViewDetail },
  { path: `/${RoutesList.paymentTerminal}`, component: PaymentTerminal },
  { path: `/${RoutesList.paymentTerminal}/add`, component: PaymentTerminalDetail },
  { path: `/${RoutesList.paymentTerminal}/edit/:id`, component: PaymentTerminalDetail },
  { path: `/${RoutesList.paymentTerminal}/view/:id`, component: PaymentTerminalDetail },
  { path: `/${RoutesList.changePaymentMode}`, component: ChangePaymentMode },
  { path: `/${RoutesList.changePaymentMode}/add`, component: ChangePaymentModeDetail },
  { path: `/${RoutesList.changePaymentMode}/edit/:id`, component: ChangePaymentModeDetail },
  { path: `/${RoutesList.changePaymentMode}/view/:id`, component: ChangePaymentModeViewDetail },
  { path: `/${RoutesList.changePaymentMode}/editcompleted/:id`, component: ChangePaymentModeViewDetail },
  { path: `/${RoutesList.report}/${RoutesList.changePaymentMode}`, component: ChangePaymentModeReport },
  { path: `/${RoutesList.closeCounter}`, component: CloseCounter },
  { path: `/${RoutesList.closeCounter}/edit/:id`, component: CloseCounterDetail },
  { path: `/${RoutesList.closeCounter}/view/:id`, component: CloseCounterDetail },
  { path: `/${RoutesList.closeCounter}/override/:id`, component: OverrideCloseCounterDetail },
  { path: `/${RoutesList.report}/${RoutesList.leadsTurnUpRateSummary}`, component: LeadTurnUp },
  { path: `/${RoutesList.report}/${RoutesList.customerDetails}`, component: CustomerDetailReport},
  { path: `/${RoutesList.marcomReport}`, component: MarcomReport},
  { path: `/${RoutesList.report}/${RoutesList.newVisitVsTurnUp}`, component: NewVisitVsTurnUp},
  { path: `/${RoutesList.testTimeZone}`, component: testTimeZone }
]

const publicRoutes = [
  { path: `/${RoutesList.login}`, component: LoginForm },
  { path: `/${RoutesList.forgotPassword}`, component: ForgetPassword },
  { path: `/${RoutesList.forgotPasswordValidator}`, component: ForgetPasswordValidator },
  { path: `/${RoutesList.notFound}`, exact: true, component: NotFound},
  { path: `/${RoutesList.underMaintenance}`, exact: true, component: UnderMaintaince},
  { path: `/${RoutesList.customerProfileAccessDenied}`, component: CustomerProfileAccessDenied },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

export { publicRoutes, authProtectedRoutes }
