import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const SupplierDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { supplierStore, countryStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addSupplier, updateSupplier, supplierDetail, setSupplierDetail, getSupplierWithId } = supplierStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const pageSize = Constants.maxPageSize;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Supplier" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Supplier" }), urlPath: RoutesList.supplier }];
  const [loadingState, setLoadingState] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateSupplier = checkPermission([PermissionConstants.UpdateSupplier]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: supplierDetail || {
      name: "",
      description: "",
      contactPerson: "",
      contactNo: "",
      email: "",
      website: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      isHQ: false,
      displayOrder: 1
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SupplierName" }) })),
      email: Yup.string().email().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Email" }) })),
      postcode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Postcode" }) })),
      addressLine1: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AddressLine1" }) })),
      contactPerson: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ContactPerson" }) })),
      contactNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ContactNo" }) })),
      countryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) })),
      stateId: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length > 0 && !value) {
            return false;
          }
          return true;
        }).nullable(),
      stateName: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }

      try {
        if (addAction) {
          await addSupplier(valuesTemp);
        } else {
          await updateSupplier(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchSupplierDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getCountryDropdown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getSupplierWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setSupplierDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateSupplier], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateSupplier], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageSupplier], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateSupplier){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.supplier}/edit/${id}`)} })
    }

    fetchSupplierDetailAPI();

    return (() => {
      if (id && !addAction) {
        setSupplierDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (supplierDetail) {
        if (supplierDetail.countryId) {
          getStateWithLoading(supplierDetail.countryId, false)
        }
      }
    }
  }, [supplierDetail]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const getStateWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        stateId: "",
        stateName: ""
      }));
    }
    setLoadingState(false);
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <LineBreakWithTittle
              paddingBottom="0px"
              title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Supplier" }) })}
              h4Title />
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SupplierName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ContactPerson" })}
                      name="contactPerson"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ContactNo" })}
                      name="contactNo"
                      type="number"
                      blnNumberOnly={true}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Email" })}
                    name="email"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Website" })}
                    name="website"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Description" })}
                    name="description"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
              <Col xl={6}>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AddressLine1" })}
                    name="addressLine1"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AddressLine2" })}
                    name="addressLine2"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "City" })}
                      name="city"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Postcode" })}
                      name="postcode"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"countryId"}
                      title={intl.formatMessage({ id: "Country" })}
                      specifyReturnFieldName={[
                        {
                          field: "countryId",
                          value: "id",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={countryDropdownList}
                      disabled={disabledFieldInput || viewAction || loadingState}
                      onChangeFunction={getStateWithLoading}
                      initialLabel={!addAction ? validation.values.countryName : undefined}
                      initialValue={!addAction ? validation.values.countryId : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    {loadingState ? (
                      <Loading />
                    ) : (
                      stateList.length > 0
                        ?
                        <DropDownWithTitle
                          name={"stateId"}
                          title={intl.formatMessage({ id: "State" })}
                          specifyReturnFieldName={[
                            {
                              field: "stateId",
                              value: "id",
                            },
                            {
                              field: "stateName",
                              value: "name",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={stateList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={!addAction ? validation.values.stateName : undefined}
                          initialValue={!addAction ? validation.values.stateId : undefined}
                          validationRequired={stateList.length > 0 ? true : false}
                          validation={validation}
                        />
                        :
                        <GeneralInput
                          title={intl.formatMessage({ id: "StateName" })}
                          name="stateName"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                    )}
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Hq" }).toUpperCase()}
                      name="isHQ"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                  </DoubleColumnRowParent>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(SupplierDetail);
