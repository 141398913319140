import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, newSpaceBeforeCapitalLetter, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue, returnSubBrandList, firstDayOfCurrentMonthToLastDayOfCurrentMonth } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";

const ClosingRate = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { closingRateList, getClosingRateList, setClosingRateList, exportClosingRate } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const blnPermissionManageSalesReport = checkPermission([PermissionConstants.ManageSalesReport]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());

    async function fetchClosingRate() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchClosingRate();

    return (() => {
      setClosingRateList([]);
    })
  }, [])

  useEffect(()=> {
    setSelectedDateRange(dateRangeLimit)
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const fetchClosingRateWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportClosingRate({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    } else {
      await getClosingRateList({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const ClosingRateColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "signUpCount",
      text: intl.formatMessage({ id: "SignUp" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageSalesReport
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${Constants.signUp}&subBrandId=${selectedSubBrandId === " " ? "" : selectedSubBrandId}&year=${selectedYear}&month=${selectedMonthValue}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`}
          target={Constants.supportBlank}>
          <div>
            {row.signUpCount}
          </div>
        </Link>
        :
        <div>
          {row.signUpCount}
        </div>
      )
    },
    {
      dataField: "newVisitCount",
      text: intl.formatMessage({ id: "NewVisitWithoutTotal" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageSalesReport
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${newSpaceBeforeCapitalLetter(Constants.newVisit)}&subBrandId=${selectedSubBrandId === " " ? "" : selectedSubBrandId}&year=${selectedYear}&month=${selectedMonthValue}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`}
          target={Constants.supportBlank}>
          <div>
            {row.newVisitCount}
          </div>
        </Link>
        :
        <div>
          {row.newVisitCount}
        </div>
      )
    },
    {
      dataField: "closingRate",
      text: intl.formatMessage({ id: "ClosingRateWithPercentage" }).toUpperCase()
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ClosingRate" }) })}
        title={intl.formatMessage({ id: "ClosingRate" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchClosingRateWithLoading(true)
            }}
          />
        }>
        <Row>
          {
              subBrandListFinal.length > 0
              &&
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
         </Col>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl={"3"}>
            {
              localLoadingDateRange
              ?
              <Loading/>
              :
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRange" })}
                name={"DateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                minMaxDateObj={dateRangeLimit}
                validationRequired={true}
                onChangeFunction={onSelectDateRange}/>
            }
          </Col>
          <Col xl={"3"}>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchClosingRateWithLoading(false)
              }}
            />
          </Col>
        </Row>
        <Row>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ClosingRateColumns}
              data={closingRateList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ClosingRate" }) })}
              objSorted={defaultSorted}
              keyField={"branchName"}
              requestAPI={getClosingRateList}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              subBrandId={selectedSubBrandId}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ClosingRate);
