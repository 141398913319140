import { PaginationRequestBody } from "../models/pagination";
import { BranchForProductObject, ProductAddObject, ProductDetailObject, ProductDropdownRequestBody, ProductExportObject, ProductImportObject, ProductListObject, ProductStockOutObject, ProductUpdateObject, UpdateServiceConsumeQuantityObject } from "../models/product";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import { Constants } from "../constants/Constants";
import { toast } from 'react-toastify';
import _ from "lodash";
export default class ProductStore {
  productList: ProductListObject[] = [];
  productDropdownList: ProductListObject[] = [];
  productDetail: ProductDetailObject | undefined = undefined;
  productBranchAccess: BranchForProductObject[] = [];
  productPaginationParams: PaginationRequestBody | undefined = undefined;
  productTableColumnFromStore: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.productList = [];
    this.productDropdownList = [];
    this.productDetail = undefined;
    this.productBranchAccess = [];
    this.productPaginationParams = undefined;
    this.productTableColumnFromStore = [];
  }

  setProductDetail = (countryDetail: ProductDetailObject | undefined) => {
    this.productDetail = countryDetail;
  }

  setProductPaginationParams = (productPaginationParams: PaginationRequestBody | undefined) => {
    this.productPaginationParams = _.cloneDeep(productPaginationParams);
  }

  setProductTableColumnFromStore = (productTableColumnFromStore: any[]) => {
    this.productTableColumnFromStore = productTableColumnFromStore;
  }

  getProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      this.setProductPaginationParams(PaginationRequestBody);
      PaginationRequestBody.productName = encodeURIComponent(PaginationRequestBody.productName || "");
      PaginationRequestBody.sKU = encodeURIComponent(PaginationRequestBody.sKU || "");
      const resultProducts = await agent.Product.productList(PaginationRequestBody);
      runInAction(() => {
        this.productList = resultProducts.data;
        store.commonStore.setTotalItem(resultProducts.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productList = [];
    }
  };

  getProductDropdown = async (productDropdownRequestBody: ProductDropdownRequestBody = {}, blnDisplayUOM: boolean = false, blnSorting: boolean = false) => {
    try {
      let resultProductsDropdown = await agent.Product.productDropdownList(productDropdownRequestBody);
      if (blnSorting) {
        resultProductsDropdown = _.orderBy(resultProductsDropdown, ["displayOrder", "salesCategoryName", "name"], ['asc', 'desc', 'asc']);
      }
      if (blnDisplayUOM) {
        resultProductsDropdown = resultProductsDropdown.map((valueProductTemp)=> ({
          ...valueProductTemp,
          name: `${valueProductTemp.name} (${valueProductTemp.measurementQuantity > 1 ? `${valueProductTemp.measurementQuantity} ` : ""}${valueProductTemp.measurementName})`
        }))
      }

      runInAction(() => {
        this.productDropdownList = resultProductsDropdown;
      });
      return Promise.resolve(resultProductsDropdown)
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productDropdownList = [];
    }
  };

  getProductWithId = async (id: string) => {
    try{
      const resultProductDetail = await agent.Product.productDetail(id);
      runInAction(() => {
        this.productDetail = resultProductDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productDetail = undefined;
    }
  }

  addProduct = async (productRequestBody: ProductAddObject) => {
    try{
      await agent.Product.addProduct(productRequestBody);
      store.commonStore.setSuccessMessage(`ProductAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateProduct = async (productRequestBody: ProductUpdateObject) => {
    try{
      await agent.Product.updateProduct(productRequestBody);
      store.commonStore.setSuccessMessage(`ProductUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteProduct  = async (id: string, name: string) => {
    try {
      await agent.Product.deleteProduct(id);
      store.commonStore.setSuccessMessage(`ProductDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getProductBranchAccess = async () => {
    try{
      const resultProductBranchAccess = await agent.Product.productBrandAccess();
      runInAction(() => {
        this.productBranchAccess = resultProductBranchAccess;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productBranchAccess = [];
    }
  }

  exportProduct = async (productExportRequestBody: ProductExportObject) => {
    try{
      await downloadExcel(`/product/export`, productExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importProduct = async (productExportRequestBody: ProductImportObject) => {
    try{
      await agent.Product.importProduct(productExportRequestBody);
      store.commonStore.setSuccessMessage(`ProductImportSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  stockOutProduct = async (productStockOutRequestBody: ProductStockOutObject) => {
    try{
      await agent.Product.stockOutProduct(productStockOutRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ProductStockOutSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  updateServiceConsumeQuantity = async (updateServiceConsumeQuantityRequestBody: UpdateServiceConsumeQuantityObject) => {
    try{
      await agent.Product.updateServiceConsumeQuantity(updateServiceConsumeQuantityRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`ProductConsumeQuantityUpdateSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}
