import { useEffect, useState } from "react";
import {
  Modal,
  Label,
  FormFeedback,
  Row
} from "reactstrap";
import Select from 'react-select';
import _ from 'lodash';
import { AddOptionsValueField, newSpaceBeforeCapitalLetter, returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from '../../../app/components/form/GeneralInputForModal';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { StockOutContentObject } from "../../../app/models/stockOut";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedStockOutDetails: StockOutContentObject | undefined;
  setSelectedStockOutDetails: Function;
  optionsProductList: any;
  setOptionsProductList: Function;
  optionsStockOutReason: any;
  getWarehouseProductDetails: Function;
  setErrorMessage: Function;
  validation: any;
}

export default function StockOutProductModal(props: Props) {
  const intl = useIntl();
  const defaultStockOutDetails = {
    productId: "",
    productName: "",
    totalQuantity: 0,
    stockOutReasonDisplay: "",
    stockOutReason: -1,
    remark: "",
    pricingBatchDetails: []
  }
  const [stockOutDetails, setStockOutDetails] = useState(props.selectedStockOutDetails || defaultStockOutDetails)
  const [errorState, setErrorState] = useState({
    productId: false,
    totalQuantity: false,
    stockOutReason: false,
  })
  const [loadingProduct, setLoadingProduct] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "supplierReferenceNo",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "costPrice",
      inputType: "number",
      inputGroup: true,
      inputGroupText: returnCurrency(),
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "AdjustQuantity" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "adjustQuantity",
      numberMaxFieldName: "quantity",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "originalQuantity",
      inputType: "text",
      disabled: true
    }
  ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      pricingBatchDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    if (props.selectedStockOutDetails) {
      setStockOutDetails(props.selectedStockOutDetails);
      validationLocal.setFieldValue("pricingBatchDetails", props.selectedStockOutDetails.pricingBatchDetails);
    }
  }, [props.selectedStockOutDetails])

  useEffect(() => {
    setStockOutDetails(valueTemp => ({
      ...valueTemp,
      pricingBatchDetails: validationLocal.values.pricingBatchDetails
    }));
  }, [validationLocal.values.pricingBatchDetails])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value)=> ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      productId: false,
      totalQuantity: false,
      stockOutReason: false,
    });
  }
  const hideModal = () => {
    clearErrorState();
    setStockOutDetails(defaultStockOutDetails);
    validationLocal.setFieldValue("pricingBatchDetails", []);
    props.setModal(false);
    props.setSelectedStockOutDetails(undefined);
  }

  const onChangeProduct = async (value) => {
    setLoadingProduct(true);
    let resultWarehouseProducts = await props.getWarehouseProductDetails(props.validation.values.warehouseId, value);
    if (resultWarehouseProducts) {
      let pricingBatchDetailsTemp = resultWarehouseProducts.pricingBatchDetails.map((valuePricingBatchDetails)=> {
        return {
          ...valuePricingBatchDetails,
          originalQuantity: valuePricingBatchDetails.quantity,
          adjustQuantity: 0,
          isActive: false,
        }
      })

      setStockOutDetails(valueTemp => ({
        ...valueTemp,
        productId: resultWarehouseProducts.productId,
        productName: resultWarehouseProducts.productName,
        totalQuantity: 0,
        stockOutReason: "",
        remark: "",
        pricingBatchDetails: pricingBatchDetailsTemp
      }));
      validationLocal.setFieldValue("pricingBatchDetails", pricingBatchDetailsTemp)
    }
    clearErrorState("productId");
    setLoadingProduct(false);
  }

  const onChangeStockOutReason = (value) => {
    setStockOutDetails(valueTemp => ({
      ...valueTemp,
      stockOutReasonDisplay: newSpaceBeforeCapitalLetter(value.displayValue),
      stockOutReason: value.key
    }));
    clearErrorState("stockOutReason");
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
      size={"lg"}
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.selectedStockOutDetails?.productName || intl.formatMessage({ id: "Product" })}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <Label>
            <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Product"})}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={errorState.productId ? "mb-0" : "mb-3"}
            options={props.optionsProductList}
            blnValueWithNewSpace={true}
            onChange={onChangeProduct}
            initialLabel={stockOutDetails.productName}
            initialValue={stockOutDetails.productId}
            validationRequired={true}
            disabled={props.selectedStockOutDetails ? true : false}
          />
          {errorState.productId ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Product" }) })}</FormFeedback>
          ) : null}
        </div>
        {
          loadingProduct
          ?
          <Loading />
          :
          stockOutDetails.productId
          &&
          <>
            <Row>
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <div>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "TotalQuantity"})}
                    name="totalQuantity"
                    className={errorState.totalQuantity ? "mb-0" : "mb-3"}
                    type="number"
                    maxLength={4}
                    validationRequired={true}
                    field={stockOutDetails}
                    setFieldValue={setStockOutDetails}
                    onChangeFunction={()=> {clearErrorState("totalQuantity")}}
                  />
                  {errorState.totalQuantity ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TotalQuantity" }) })}</FormFeedback>
                  ) : null}
                </div>
              </DoubleColumnRowParent>
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "StockOutReason"})}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.productId ? "mb-0" : "mb-3"}
                  options={props.optionsStockOutReason}
                  blnValueWithNewSpace={true}
                  onChange={onChangeStockOutReason}
                  initialLabel={stockOutDetails.stockOutReasonDisplay}
                  initialValue={stockOutDetails.stockOutReasonDisplay}
                  blnReturnFullValue={true}
                  validationRequired={true}
                />
                {errorState.stockOutReason ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "StockOutReason" }) })}</FormFeedback>
                ) : null}
              </DoubleColumnRowParent>
            </Row>
            <GeneralTextAreaForModal
              title={intl.formatMessage({ id: "Remark" })}
              name="remark"
              row={2}
              field={stockOutDetails}
              setFieldValue={setStockOutDetails} 
              disabled={false}/>
            <>
              <LineBreakWithTittle
                paddingBottom="0px"
                title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PricingBatch" }) })}
              />
              <TableWithEditableInputFields
                name="pricingBatchDetails"
                title=""
                buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                blnEditable={true}
                aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                data={validationLocal.values.pricingBatchDetails}
                validation={validationLocal}
                disabled={false}
                blnCheckBox={true}
                hideAddButton={true}
                hideDeleteButton={true}/>
            </>
          </>
        }
        <div className="margin-top-14 ">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({ id: "Save" })}
            onClick={()=> {
              let errorStateTemp = {
                productId: false,
                totalQuantity: false,
                stockOutReason: false,
              };

              let blnErrorFound = false;
              if (!stockOutDetails.productId) {
                errorStateTemp.productId = true;
                blnErrorFound = true;
              }
              else {
                if (!stockOutDetails.totalQuantity && stockOutDetails.totalQuantity !== 0) {
                  errorStateTemp.totalQuantity = true;
                  blnErrorFound = true;
                }
                if (!stockOutDetails.stockOutReason && stockOutDetails.stockOutReason !== 0) {
                  errorStateTemp.stockOutReason = true;
                  blnErrorFound = true;
                }

                let totalAdjustQuantity = 0;
                stockOutDetails.pricingBatchDetails.map((valuePricingBatchDetailsTemp)=> {
                  if (valuePricingBatchDetailsTemp.isActive) {
                    totalAdjustQuantity += valuePricingBatchDetailsTemp.adjustQuantity;
                  }
                })
                if (stockOutDetails.totalQuantity !== totalAdjustQuantity) {
                  props.setErrorMessage(intl.formatMessage({ id: "TotalQuantityNotEqualTotalAdjustQuantity" }))
                  blnErrorFound = true;
                }

                if (stockOutDetails.totalQuantity === 0) {
                  props.setErrorMessage(intl.formatMessage({ id: "TotalQuantityCannotLessThanOne" }))
                  blnErrorFound = true;
                }
              }

              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                let stockOutDetailsTemp : any = _.cloneDeep(props.validation.values.stockOutDetails);
                if (props.selectedStockOutDetails) {
                  let indexStockOutDetails = _.findIndex(stockOutDetailsTemp, {productId: props.selectedStockOutDetails.productId})
                  if (indexStockOutDetails > -1) {
                    stockOutDetailsTemp[indexStockOutDetails] = stockOutDetails;
                  }
                }
                else {
                  stockOutDetailsTemp.push(stockOutDetails);
                }
                let indexProduct = _.findIndex(props.optionsProductList, {productId: stockOutDetails.productId})
                if (indexProduct > -1) {
                  props.optionsProductList[indexProduct].disabled = true;
                  props.setOptionsProductList(props.optionsProductList)
                }
                props.validation.setFieldValue("stockOutDetails", stockOutDetailsTemp)
                hideModal();
              }
            }}
          />
        </div>
      </div>
    </Modal>
  )
}