import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { IncludesLocationPathName, checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import _ from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import { Constants } from "../../app/constants/Constants";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import Loading from "../../app/components/loading/Loading";
import { Col, Form, Label, Row } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from "draft-js-import-html";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const TncDetail = () => {
    //Use Params
    const { id } = useParams();

    //Use INTL
    const intl = useIntl();

    const { tncStore, commonStore, staticReferenceStore } = useStore();
    const {
        errorMessage,
        successMessage,
        loading,
        setErrorMessage,
        setSuccessMessage,
        setLoading,
    } = commonStore;
    const { tncDetail, addTnc, updateTnc, getTncWithId, setTncDetail } = tncStore;
    const { getStaticReferenceWithType } = staticReferenceStore;

    const addAction = IncludesLocationPathName("add");
    const viewAction = IncludesLocationPathName("view");
    const editAction = IncludesLocationPathName("edit");
    const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
        } ${intl.formatMessage({ id: "Tnc" })}`;
    const breadCrumbList = [{ title: intl.formatMessage({ id: "Tnc" }), urlPath: RoutesList.tnc }];
    const [burgerMenuListContent] = useState<burgerMenu[]>([]);
    const [blnSubmitting, setBlnSubmitting] = useState(false);
    const blnPermissionUpdateTnc = checkPermission([PermissionConstants.UpdateTnc]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [tncCategoryList, setTncCategoryList] = useState<StaticReferenceDataObject[]>([]);
    const extractTextFromHtml = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: tncDetail || {
            name: "",
            description: "",
            category: "",
            startDate: "",
            endDate: "",
        },
        validationSchema: Yup.object({
            category: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TncCategory" }) })),
            startDate: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TncStartDate" }) })),
        }),
        onSubmit: async (values) => {
            const valuesTemp: any = _.cloneDeep(values);
            const textFormatter = extractTextFromHtml(valuesTemp.description)
            if (!textFormatter) {
                validation.setFieldError("description", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TncDescription" }) }))
                return;
            }
            try {
                if (addAction) {
                    await addTnc(valuesTemp);
                } else {
                    await updateTnc(valuesTemp);
                }
            } finally {
                validation.setSubmitting(false);
            }
        },
    });

    const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

    const handleEditorChange = (state) => {
        setEditorState(state);
        let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        validation.setFieldValue("description", currentContentAsHTML)
    }

    useEffect(() => {
        async function fetchTncDetailAPI() {
            setLoading(true);
            let aryAPI: any = [
                getStaticReferenceWithType(Constants.tncCategoryType)
            ];
            if (id && !addAction) {
                aryAPI.push(getTncWithId(id));
            }
            let resultAPI = await Promise.all(aryAPI);
            if (resultAPI[0]) {
                setTncCategoryList(resultAPI[0]);
            }
            if (resultAPI.length > 1) {
                if (resultAPI[1]) {
                    if (resultAPI[1].description) {
                        let contentState = stateFromHTML(resultAPI[1].description);
                        setEditorState(EditorState.createWithContent(contentState));
                    }
                    if (viewAction && blnPermissionUpdateTnc && resultAPI[1].isLatest && resultAPI[1].isModify) {
                        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.tnc}/edit/${id}`) } })
                    }
                }
            }
            setLoading(false);
        }

        if (addAction) {
            setTncDetail(undefined);
        }

        if (!addAction && !viewAction) {
            if (!checkPermission([PermissionConstants.UpdateTnc], true)) {
                return;
            }
        }
        else if (addAction) {
            if (!checkPermission([PermissionConstants.CreateTnc], true)) {
                return;
            }
        }
        else if (viewAction) {
            if (!checkPermission([PermissionConstants.ManageTnc], true)) {
                return;
            }
        }

        fetchTncDetailAPI();
        return (() => {
            if (id && !addAction) {
                setTncDetail(undefined);
            }
        })
    }, [])

    useEffect(() => {
        if (validation.isSubmitting) {
            setBlnSubmitting(true);
        }

        if (!validation.isSubmitting && blnSubmitting) {
            setBlnSubmitting(false);
            if (Object.keys(validation.errors).length > 0) {
                if (Constants.validationError) {
                    console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
                }
                setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
            }
        }
    }, [validation.isSubmitting, validation.errors])

    return (
        <div>
            <DetailViewLayout
                title={displayTitle}
                breadCrumbList={breadCrumbList}
                burgerMenuList={burgerMenuListContent}
                auditTrailId={viewAction ? id : null}>
                {loading ? (
                    <Loading />
                ) : (
                    <Form
                        className="standard-layout"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        onBlur={() => {
                            if (errorMessage || successMessage) {
                                setErrorMessage("");
                                setSuccessMessage("");
                            }
                        }}
                    >
                        <Row from={+true}>
                            {" "}
                            <Col xl={12}>
                                <LineBreakWithTittle
                                    paddingBottom="0px"
                                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Tnc" }) })}
                                    h4Title />

                                {viewAction &&
                                    <SingleColumnRowParent>
                                        <GeneralInput
                                            title={intl.formatMessage({ id: "TncName" })}
                                            name="name"
                                            className="mb-3 mt-4"
                                            type="text"
                                            disabled={disabledFieldInput || viewAction || addAction || editAction}
                                            validationRequired={true}
                                            validation={validation}
                                        />
                                    </SingleColumnRowParent>
                                }
                                <SingleColumnRowParent>
                                    <DropDownWithTitle
                                        name="category"
                                        className={!viewAction ? "mb-3 mt-4" : ""}
                                        title={intl.formatMessage({ id: "TncCategory" })}
                                        specifyReturnFieldName={[
                                            {
                                                field: "category",
                                                value: "displayValue",
                                            },
                                        ]}
                                        labelField={"displayValue"}
                                        valueField={"displayValue"}
                                        options={tncCategoryList}
                                        blnValueWithNewSpace={true}
                                        disabled={disabledFieldInput || viewAction || editAction}
                                        initialValue={!addAction ? validation.values.category : undefined}
                                        validationRequired={true}
                                        validation={validation}
                                    />
                                </SingleColumnRowParent>
                                <SingleColumnRowParent>
                                    <GeneralInput
                                        title={intl.formatMessage({ id: "TncStartDate" })}
                                        name="startDate"
                                        type="date"
                                        disabled={disabledFieldInput || viewAction}
                                        validationRequired={true}
                                        validation={validation}
                                        minCurrentDate={true}
                                    />
                                </SingleColumnRowParent>
                                <SingleColumnRowParent>
                                    <GeneralInput
                                        title={intl.formatMessage({ id: "TncEndDate" })}
                                        name="endDate"
                                        type="date"
                                        disabled={disabledFieldInput || viewAction || editAction || addAction}
                                        validationRequired={true}
                                        validation={validation}
                                    />
                                </SingleColumnRowParent>
                                <SingleColumnRowParent>
                                    <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                                    <Label>{intl.formatMessage({ id: "TncDescription" })} </Label>
                                    <Editor editorStyle={{ height: '200px' }}
                                        name="description"
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        readOnly={disabledFieldInput || viewAction}
                                        validationRequired={true}
                                    />

                                </SingleColumnRowParent>
                            </Col>
                        </Row>
                        <GeneralSubmitAndCancelBtn
                            className={"mb-3 mt-3"}
                            successMessage={successMessage}
                            viewAction={viewAction}
                            validation={validation}
                        />
                    </Form>
                )}
            </DetailViewLayout>
        </div>
    );
};

export default observer(TncDetail);