import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import SortCaret from '../../app/components/table/SortCaret';
import { history } from "../..";
import { PackageImportFailedDetailObject, PackageImportObject, PackageListObject } from '../../app/models/package';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, returnBlobImage, returnPriceWithCurrency } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";
import { PaginationRequestBody } from "../../app/models/pagination";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import ImportPackageModal from "./Modal/ImportPackageModal";
import englishLanguageFile from '../../lang/en.json'; 
import { SalesCategoryListObject } from "../../app/models/salesCategory";
import { ProductListObject } from "../../app/models/product";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import { GroupCodeListObject } from "../../app/models/groupCode";

const Package = () => {
  const intl = useIntl();
  //Use Store
  const { packageStore, productStore, serviceTreatmentStore, salesCategoryStore, groupCodeStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, windowSize, setShowDeletePrompt, setErrorMessage, setSuccessMessage, setLoadingModal } = commonStore;
  const { packageList, packagePaginationParams, getPackage, deletePackage, setPackagePaginationParams, exportPackage, importPackage, packageTableColumnFromStore, setPackageTableColumnFromStore } = packageStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;
  const { getProductDropdown } = productStore;
  const { getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { getGroupCodeDropDown } = groupCodeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PackageListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreatePackage = checkPermission([PermissionConstants.CreatePackage]);
  const blnPermissionUpdatePackage = checkPermission([PermissionConstants.UpdatePackage]);
  const blnPermissionDeletePackage = checkPermission([PermissionConstants.DeletePackage]);
  const blnPermissionExportPackage = checkPermission([PermissionConstants.ExportPackage]);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [importFileModal, setImportFileModal] = useState(false);
  const [importedPackageDetails, setImportedPackageDetails] = useState<PackageImportFailedDetailObject[]>([]);
  const [modalImportedPackageDetails, setModalImportedPackageDetails] = useState<boolean>(false);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const AllProducts = `${intl.formatMessage({ id: "AllProducts" })}`;
  const [productList, setProductList] = useState<ProductListObject[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>(AllProducts);
  const [selectedProductId, setSelectedProductId] = useState< string>(" ");
  const AllServiceTreatments = `${intl.formatMessage({ id: "AllServiceTreatment" })}`;
  const [serviceTreatmentList, setServiceTreatmentList] = useState<ProductListObject[]>([]);
  const [selectedServiceTreatment, setSelectedServiceTreatment] = useState<string>(AllServiceTreatments);
  const [selectedServiceTreatmentId, setSelectedServiceTreatmentId] = useState< string>(" ");
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [selectedSalesCategory, setSelectedSalesCategory] = useState<string>(AllSalesCategory);
  const [selectedSalesCategoryId, setSelectedSalesCategoryId] = useState<string>(" ");
  const AllGroupCode = `${intl.formatMessage({ id: "AllGroupCode" })}`;
  const [groupCodeList, setGroupCodeList] = useState<GroupCodeListObject[]>([]);
  const [selectedGroupCode, setSelectedGroupCode] = useState<string>(AllGroupCode);
  const [selectedGroupCodeId, setSelectedGroupCodeId] = useState<string>(" ");
  const All = `${intl.formatMessage({ id: "All" })}`;
  const MandatoryProduct = `${intl.formatMessage({ id: "MandatoryProductOnly" })}`;
  const OptionalProduct = `${intl.formatMessage({ id: "OptionalProductOnly" })}`;
  const [productOptionsList, setProductOptionsList] = useState<ObjectForDropdown[]>([{label: All, value: All}, {label: MandatoryProduct, value: MandatoryProduct}, {label: OptionalProduct, value: OptionalProduct}])
  const [selectedProductOption, setSelectedProductOption] = useState<string>(All);
  const [selectedProductOptionId, setSelectedProductOptionId] = useState<any>(All);
  const [displayProductOption, setDisplayProductOption] = useState(true);
  const [disabledProductOption, setDisabledProductOption] = useState(true);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let packageRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.name,
    salesCategoryId: selectedSalesCategoryId,
    salesCategoryName: selectedSalesCategory,
    serviceTreatmentId: selectedServiceTreatmentId,
    serviceTreatmentName: selectedServiceTreatment,
    productId: selectedProductId,
    productName: selectedProduct,
    groupCodeId: selectedGroupCodeId,
    groupCodeName: selectedGroupCode,
    ...selectedProductOptionId !== All && {isOptionalProduct: selectedProductOptionId === MandatoryProduct ? false : true},
    ...!blnAllActive && {isActive: blnIsActive}
  }

  useEffect(() => {
    async function fetchPackageListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.package)
      if (blnBackNavigation) {
        if (packagePaginationParams) {
          setCurrentPage(packagePaginationParams.pageNumber)
          setSelectedSalesCategoryId(packagePaginationParams.salesCategoryId || "");
          setSelectedSalesCategory(packagePaginationParams.salesCategoryName || AllSalesCategory);
          setSelectedGroupCodeId(packagePaginationParams.groupCodeId || "");
          setSelectedGroupCode(packagePaginationParams.groupCodeName || AllGroupCode);
          setSelectedServiceTreatmentId(packagePaginationParams.serviceTreatmentId || "");
          setSelectedServiceTreatment(packagePaginationParams.serviceTreatmentName || AllServiceTreatments);
          setSelectedProductId(packagePaginationParams.productId || "");
          setSelectedProduct(packagePaginationParams.productName || AllProducts);
          setSelectedProductOption(typeof(packagePaginationParams.isOptionalProduct) !== 'boolean' ? All : !packagePaginationParams.isOptionalProduct ? MandatoryProduct : OptionalProduct);
          setSelectedProductOptionId(typeof(packagePaginationParams.isOptionalProduct) !== 'boolean' ? All : !packagePaginationParams.isOptionalProduct ? MandatoryProduct : OptionalProduct);
          if (packagePaginationParams.productId && packagePaginationParams.productId !== " ") {
            setDisabledProductOption(false)
          }
          validation.setValues((values)=> ({
            ...values,
            name: packagePaginationParams.name || "",
            isActive: packagePaginationParams.isActive || false,
            isOptionalProduct: packagePaginationParams.isOptionalProduct || false
          }))
          if(packagePaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(packagePaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          packageRequestBody = packagePaginationParams;
        }
      }
      else {
        setPackagePaginationParams(undefined);
      }


      let aryAPI: any = [
        getPackage(packageRequestBody),
        getProductDropdown({ productType: 1 }),
        getServiceTreatmentDropdown({branchId: "" }),
        getSalesCategoryDropdown(),
        getGroupCodeDropDown({})
      ];
      
      let resultAPI = await Promise.all(aryAPI);
      setProductList(arraySpliceInAllValue(resultAPI[1], AllProducts, false, "name", "id"))
      setServiceTreatmentList(arraySpliceInAllValue(resultAPI[2], AllServiceTreatments, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[3], AllSalesCategory, false, "name", "id"))
      setGroupCodeList(arraySpliceInAllValue(resultAPI[4], AllGroupCode, false, "name", "id"))
      
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePackage], true)) {
      return;
    }

    fetchPackageListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.package)
        if (!blnSetParams) {
          setPackagePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchPackageAPI(true);
    }
  }, [triggerSearch, blnAllActive, blnIsActive, selectedSalesCategoryId, selectedGroupCodeId, selectedServiceTreatmentId, selectedProductId, selectedProductOptionId])

  async function fetchPackageAPI(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPackage(packageRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: PackageListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const durationFormatter = (cell, row) => {
    return `${cell.hours} hours ${cell.minutes} minutes`
  }

  const exportPackageReport = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await (exportPackage({ exportType: 0, active: true })) // 0 = export all records, active = true to retrieve all active packages 
    }
    setLocalLoading(false);
  }

  const importPackageFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: PackageImportObject = { file: fileTemp };
        let resultImportPackage = await importPackage(valuesTemp);
        if (resultImportPackage) {
          if (resultImportPackage.length > 0) {
            resultImportPackage = resultImportPackage.map((valueResultImportPackage)=> ({
              ...valueResultImportPackage,
              errorCode:  valueResultImportPackage.errorCode in englishLanguageFile ? intl.formatMessage({ id: valueResultImportPackage.errorCode }) : valueResultImportPackage.errorCode 
            }))
            setImportFileModal(false);
            setModalImportedPackageDetails(true);
            setImportedPackageDetails(resultImportPackage);
          }
          else {
            setImportFileModal(false);
            setSuccessMessage(`PackageImportSuccess`);
            fetchPackageAPI();
          }
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  //Table Content UI
  const PackageColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "sessions",
      text: intl.formatMessage({ id: "Sessions" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.sessions}</>, //Custom UI to display
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.sellingPrice)}</div>
      ),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{row.salesCategoryName}</div>
      ),
    },
    {
      dataField: "groupCodeName",
      text: intl.formatMessage({ id: "GroupCode" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{row.groupCodeName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "isUpgradeable",
      text: intl.formatMessage({ id: "Upgradeable" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isUpgradeable ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isPrepaidPackage",
      text: intl.formatMessage({ id: "PrepaidPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isPrepaidPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isCreditPackage",
      text: intl.formatMessage({ id: "CreditPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isCreditPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isProductBundledPackage",
      text: intl.formatMessage({ id: "ProductBundledPackage" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isProductBundledPackage ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePackage, PermissionConstants.UpdatePackage, PermissionConstants.DeletePackage]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.package}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionUpdatePackage
              &&
              <TableActionButton
                to={`/${RoutesList.package}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeletePackage
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
        title={intl.formatMessage({ id: "Package" })}
        addButton={
          <>
            {
              blnPermissionCreatePackage
              &&
              <MyAddButton
                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
                onClick={() => { history.push(`/${RoutesList.package}/add`) }}
                linkTo={`/${RoutesList.package}/add`}
                disable={loading} />
            }
            {/* <MyButton
              content={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
              onClick={() => { setImportFileModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={localLoading || loading} /> */}
            {
              blnPermissionExportPackage
              &&
              <MyButton
                content={intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "All" }) })}
                onClick={() => { exportPackageReport(true) }}
                class={'btn btn-info margin-left-8'}
                disable={localLoading || loading} />
            }
          </>
        }
        addButtonXL="6">
        <Row>
          {
            !initialCallAPI
            &&
            <>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterPackageName" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "ServiceTreatment" })}
                  name="serviceTreatment"
                  options={serviceTreatmentList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedServiceTreatment}
                  initialValue={selectedServiceTreatmentId}
                  setFieldLabel={setSelectedServiceTreatment}
                  setFieldValue={setSelectedServiceTreatmentId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Product" })}
                  name="product"
                  options={productList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedProduct}
                  initialValue={selectedProductId}
                  setFieldLabel={setSelectedProduct}
                  setFieldValue={setSelectedProductId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={(valueProduct) => {
                    if (valueProduct !== " ") {
                      setDisabledProductOption(false);
                    }
                    else {
                      setDisabledProductOption(true);
                      setSelectedProductOptionId(All);
                      setSelectedProductOption(All);
                      setDisplayProductOption(false);
                      setTimeout(()=> {
                        setDisplayProductOption(true);
                      }, 100)
                    }
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  displayProductOption
                  &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "ProductOption" })}
                    name="isOptionalProduct"
                    options={productOptionsList}
                    initialLabel={selectedProductOption}
                    initialValue={selectedProductOptionId}
                    setFieldLabel={setSelectedProductOption}
                    setFieldValue={setSelectedProductOptionId}
                    disabled={localLoading || loading || disabledProductOption}
                    validationRequired={true}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
                }
              </DoubleColumnRowParent>
            </>
          }
        </Row>
        <Row>
          {
            !initialCallAPI
            &&
            <>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SalesCategory" })}
                  name="salesCategory"
                  options={salesCategoryList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSalesCategory}
                  initialValue={selectedSalesCategoryId}
                  setFieldLabel={setSelectedSalesCategory}
                  setFieldValue={setSelectedSalesCategoryId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "GroupCode" })}
                  name="groupCode"
                  options={groupCodeList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedGroupCode}
                  initialValue={selectedGroupCodeId}
                  setFieldLabel={setSelectedGroupCode}
                  setFieldValue={setSelectedGroupCodeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-26" : "margin-bottom-4"}`}>
                <div className="btn-group d-flex mb-3" role="group">
                  <input type="radio" className="btn-check" name="blnViewAll" id="blnViewAll" autoComplete="off" disabled={localLoading || loading } checked={blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnAllActive(true);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnViewAll">{intl.formatMessage({ id: "All" })}</label>
                  <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveTrue" autoComplete="off" disabled={localLoading || loading} checked={blnIsActive && !blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnIsActive(true);
                      setBlnAllActive(false);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveTrue">{intl.formatMessage({ id: "Active" })}</label>
                  <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveFalse" autoComplete="off" disabled={localLoading || loading} checked={!blnIsActive && !blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnIsActive(false);
                      setBlnAllActive(false);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveFalse">{intl.formatMessage({ id: "Inactive" })}</label>
                </div>
              </Col>
            </> 
          }
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PackageColumns}
              data={packageList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
              objSorted={defaultSorted}
              blnSupportColumnFilter={true}
              tableColumnsFromStore={packageTableColumnFromStore}
              setTableColumnsFromStore={setPackageTableColumnFromStore}
              name={validation.values.name}
              isActive={!blnAllActive ? blnIsActive : undefined}
              salesCategoryId={selectedSalesCategoryId}
              salesCategoryName={selectedSalesCategory}
              groupCodeId={selectedGroupCodeId}
              groupCodeName={selectedGroupCode}
              serviceTreatmentId={selectedServiceTreatmentId}
              serviceTreatmentName={selectedServiceTreatment}
              productId={selectedProductId}
              productName={selectedProduct}
              isOptionalProduct={selectedProductOptionId !== All ? selectedProductOptionId === MandatoryProduct ? false : true : undefined}
              requestAPI={getPackage} />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deletePackage(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (packageList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getPackage({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
        <ImportFileModal
          blnShow={importFileModal}
          setModal={setImportFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
          btnFunction={importPackageFunction}
        />
        {
          modalImportedPackageDetails
          &&
          <ImportPackageModal
            blnShow={modalImportedPackageDetails}
            setModal={setModalImportedPackageDetails}
            importedPackageDetails={importedPackageDetails}
            setImportedPackageDetails={setImportedPackageDetails} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Package);
