import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { calculateBalanceAmount, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, reformatSubmitValueWithClass, returnPriceWithCurrency } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import CustomerOrderCart from "./subView/CustomerOrderCart";
import { useIntl } from "react-intl";
import PaymentSubview from "./subView/PaymentSubview";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import CustomerOrderConfimSalesModal from "./Modal/CustomerOrderConfimSalesModal";
import { CustomerSalesOrderFormValues } from "../../app/models/customerSalesOrder";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { EmployeeListObject } from "../../app/models/employee";
import moment from "moment";
import { AvailableUnknownPaymentListObject } from "../../app/models/customer";

const CustomerOrderPayment = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { branchStore, customerStore, employeeStore, settingsStore, staticReferenceStore, customerSalesOrderStore, paymentMethodOptionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { customerDropdownList, getCustomerAvailablePayment } = customerStore;
  const { employeeDropdownList } = employeeStore;
  const { branchPaymentMethod, getBranchPaymentMethod } = branchStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { submitCustomerSalesOrder } = customerSalesOrderStore;
  const { generalSettings, getGeneralSettings} = settingsStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const location = useLocation();
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "P.O.S" })}`, urlPath: RoutesList.pos }];
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [customerConfirmSalesModal, setCustomerConfirmSalesModal] = useState(false);
  const [blnBlockPaymentSubmit, setBlnBlockPaymentSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const [availableUnknownPaymentList, setAvailableUnknownPaymentList] = useState<AvailableUnknownPaymentListObject[]>([]);
  const blnPermissionBackdateProductRedemptionNote= checkPermission([PermissionConstants.BackdateProductRedemptionNote]);
  // const blnPermissionBackdateCustomerSalesOrderPayment = checkPermission([PermissionConstants.BackdateCustomerSalesOrderPayment]);
  const blnPermissionUseUnknownCustomerPayment = checkPermission([PermissionConstants.UseUnknownCustomerPayment]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      paymentMethod: "",
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      paidNumber: 0,
      minimumPaymentAmount: 0,
      roundedMinimumPaymentAmount: 0,
      salesOrderDetails: [],
      paymentDetails: [],
      customerCartId: "",
      cartHeaderId: "",
      availableCredits: 0,
      blnDifferentBranch: false,
      originalBranchName: "",
      hasRounding: false,
      isPaymentBackdate: false,
      paymentDate: "",
      blnAccessGrantedCustomer: false
    },
    validationSchema: Yup.object({
      paymentDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentMethod" }).toLowerCase() })),
      consultantIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() })),
      paymentDate: Yup.string().test("paymentDate", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentDate" }) }), (value: any, context) => {
        if (context.parent.isPaymentBackdate) {
          return value;
        }
				return true;
			}).nullable(),
    }),
    onSubmit: async (values: any) => {
      try {
        if (!values.isPaymentBackdate) {
          values.paymentDate = undefined;
        }
        else {
          values.paymentDate = moment(values.paymentDate).format(Constants.defaultDateFormat);
        }

        let totalPaid : any = 0;
        values.paymentDetails = values.paymentDetails.map((valueCustomerPaymentDetailsTemp) => {
          totalPaid += Number(valueCustomerPaymentDetailsTemp.paidAmount)
          return ({
            ...valueCustomerPaymentDetailsTemp,
            transactionNo: valueCustomerPaymentDetailsTemp.transactionNo ? String(valueCustomerPaymentDetailsTemp.transactionNo): "",
          })
        })
        
        totalPaid = totalPaid.toFixed(2);
        if (totalPaid > values.totalPrice) {
          setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalPrice" }))
          return;
        }
        else if (totalPaid < values.minimumPaymentAmount && !values.hasRounding) {
          setErrorMessage(intl.formatMessage({ id: "CustomerOrderMinimumPaymentRequire" }, { amount: returnPriceWithCurrency(values.minimumPaymentAmount) }))
          return;
        }
        else if (totalPaid < values.roundedMinimumPaymentAmount && values.hasRounding) {
          setErrorMessage(intl.formatMessage({ id: "CustomerOrderMinimumPaymentRequire" }, { amount: returnPriceWithCurrency(values.roundedMinimumPaymentAmount) }))
          return;
        }
        else if (!customerConfirmSalesModal){
          setCustomerConfirmSalesModal(true);
        }
        else {
          setBlnBlockPaymentSubmit(true);
          await fetchSubmitPos(values);
        }
      }
      catch (e) {
      }
      finally {
        setTimeout(()=> {
          setBlnBlockPaymentSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  }); 

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPackageAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchPaymentMethod(getBranchId()),
        getStaticReferenceWithType(Constants.cardType),
        getGeneralSettings()
      ];
      if (location.state) {
        aryAPI.push(getCustomerAvailablePayment(location.state.detail.customerId))
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 3) {
        validation.setFieldValue("availableCredits", resultAPI[3].availableCustomerCredits || 0);
        setAvailableUnknownPaymentList(resultAPI[3].availableUnknownPayments || []);
      }  
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.CreateCustomerSalesOrder], true)) {
      return;
    }
    
    fetchPackageAPI();
  }, [])

  useEffect(() => {
    if (location.state) {
      validation.setValues({
        ...location.state.detail,
        paidNumber: location.state.detail.totalPrice,
        minimumPaymentAmount: location.state.detail.minimumPaymentAmount || 0,
        roundedMinimumPaymentAmount: location.state.detail.roundedMinimumPaymentAmount || 0,
        isPaymentBackdate: false,
        paymentDate: ""
      })
      if (location.state.detail.employeeDropdownListForOtherBranch) {
        setLocalEmployeeDropdownList(location.state.detail.employeeDropdownListForOtherBranch);
      }
      else {
        setLocalEmployeeDropdownList(employeeDropdownList)
      }
    }
    else {
      history.push(`/${RoutesList.pos}`)
    }
  }, [location]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const deletePaymentOrder = (index) => {
    let paymentDetailsTemp: any = _.cloneDeep(validation.values.paymentDetails);
    if (paymentDetailsTemp[index].unknownPaymentId) {
      let availableUnknownPaymentListTemp = _.cloneDeep(availableUnknownPaymentList);
      let indexAvailableUnknownPaymentTemp = _.findIndex(availableUnknownPaymentListTemp, {unknownPaymentId : paymentDetailsTemp[index].unknownPaymentId})
      if (indexAvailableUnknownPaymentTemp > -1) {
        availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount = Number(availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount) + Number(paymentDetailsTemp[index].paidAmount)
        setAvailableUnknownPaymentList(availableUnknownPaymentListTemp);
      }
    }
    paymentDetailsTemp.splice(index, 1);
    validation.setFieldValue("paymentDetails", paymentDetailsTemp);
    let prefillPaidNumber : any = calculateBalanceAmount(validation, "paymentDetails", "totalPrice", paymentDetailsTemp);
    validation.setFieldValue("paidNumber", prefillPaidNumber);
  }

  const prefillCustomerInfo = (id: string) => {
    let indexCustomer = _.findIndex(customerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownList[indexCustomer].id,
        customerName: customerDropdownList[indexCustomer].preferredName,
        customerNo: customerDropdownList[indexCustomer].customerNo,
        customerMobileNo: customerDropdownList[indexCustomer].mobileNo,
        availableCredits: customerDropdownList[indexCustomer].availableCredits
      }))
    }
  }

  const onClickSelect = (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      customerNo: row.customerNo,
      customerMobileNo: row.mobileNo,
      availableCredits: row.availableCredits
    }))
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const fetchSubmitPos = async (values) => {
    const valuesTemp: any = reformatSubmitValueWithClass(values, CustomerSalesOrderFormValues);

    valuesTemp.consultantIds = valuesTemp.consultantIds.map((valueTemp) => valueTemp.value)
    valuesTemp.branchId = getBranchId();
    valuesTemp.cartHeaderId = valuesTemp.customerCartId;

    let result = await submitCustomerSalesOrder(valuesTemp);

    if (result?.status == Constants.success) {
      let valuesFinal: any = _.cloneDeep(values);
      if (result?.data) {
        valuesFinal = {
          ...values,
          invoiceId: result?.data?.invoiceId,
          salesOrderId: result?.data?.salesOrderId,
          customerDeliveryOrderId: result?.data?.customerDeliveryOrderId
        }
      }
      setTimeout(() => {
        setSuccessMessage("")
        // history.push({
        //   pathname: `/${RoutesList.posAcknowledgement}`,
        //   state: { detail: valuesFinal }
        // });
        history.push({ pathname: `/${RoutesList.customerSalesOrder}/view/${result?.data?.salesOrderId}`});
      }, Constants.dismissSuccessMessage)

    }
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "P.O.S" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "SalesSummary" }).toUpperCase()}</div>
            </Col>
          </Row>
          <BreadCrumb
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={intl.formatMessage({ id: "SalesSummary" })} />
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading && !successMessage ? (
            <Loading />
          ) : (
            <Form
              id={Constants.paymentOrderForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5" md="5" sm="12" xs="12">
                  <div className="standard-layout">
                    <CustomerOrderCart
                      validation={validation}
                      disabledCustomerOrder={true}
                      disabledNote={false}
                      disabledVoidedSalesOrderNo={true}
                      disabledCustomerPayment={disabledFieldInput}
                      deletePaymentOrder={deletePaymentOrder}
                      blnShowMinimumPaymentRequirement={true}
                      blnDisplayRounding={true} />
                  </div>
                </Col>
                <Col xl="7" md="7" sm="12" xs="12" className={`${Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : ""}`}>
                  <div className={`standard-layout ${Constants.innerWidthCompare770 > windowSize.innerWidth ? "padding-top-0 margin-top--40" : ""}`}>
                    <PaymentSubview
                      validation={validation}
                      disabledFieldInput={disabledFieldInput}
                      branchPaymentMethod={branchPaymentMethod}
                      availableUnknownPaymentList={blnPermissionUseUnknownCustomerPayment ? availableUnknownPaymentList : []}
                      setAvailableUnknownPaymentList={setAvailableUnknownPaymentList}
                      cardType={cardType}
                      isTransferCreditEnabled={generalSettings?.isTransferCreditEnabled || false}
                      setErrorMessage={setErrorMessage}
                      getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}
                    />
                    <Borderline />
                    {/* {
                      blnPermissionBackdateCustomerSalesOrderPayment
                      &&
                      <>
                        <Col>
                          <GeneralInput
                            title={""}
                            name="isPaymentBackdate"
                            type="checkbox"
                            disabled={disabledFieldInput}
                            validationRequired={false}
                            validation={validation}
                            childrenUI={
                              <Label className="margin-bottom-0 margin-left-4">
                                {intl.formatMessage({ id: "IsApplyPaymentDate" })}
                              </Label>
                            }/>
                          {
                            validation.values.isPaymentBackdate
                            &&
                            <GeneralInput
                              title={""}
                              name="paymentDate"
                              placeholder={intl.formatMessage({ id: "PaymentDate" })}
                              type="datetime-local"
                              disabled={disabledFieldInput}
                              validationRequired={true}
                              validation={validation}
                              minCurrentDate={!generalSettings?.isAllowCrossMonthSales}
                              minDateValue={generalSettings?.isAllowCrossMonthSales || false ? undefined : moment().startOf('month').format(Constants.displayDateFormat)}
                              maxCurrentDate={true}
                            />
                          }
                        </Col>
                        <Borderline />
                      </>
                    } */}
                    <Row>
                      <Col xl='6' md='6' xs='6'>
                        {
                          displayCustomerDropdown &&
                          <DropDownWithTitle
                            name={"customerId"}
                            title={intl.formatMessage({ id: "Customer" })}
                            placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                            specifyReturnFieldName={[
                              {
                                field: "customerId",
                                value: "id",
                              }
                            ]}
                            labelField={"preferredName"}
                            valueField={"id"}
                            options={customerDropdownList}
                            disabled={disabledFieldInput || true}
                            initialLabel={validation.values.customerName}
                            initialValue={validation.values.customerId}
                            validation={validation}
                            validationRequired={true}
                            onChangeField={"id"}
                            onChangeFunction={prefillCustomerInfo}
                          />
                        }
                        {
                          validation.values.originalBranchName !== "" && validation.values.originalBranchName !== getBranchName()
                          &&
                          <p className="text-danger margin-top--12">*{intl.formatMessage({ id: "OriginalBranch" })}: {validation.values.originalBranchName} { validation.values.blnAccessGrantedCustomer && ` (${intl.formatMessage({ id: "AccessGranted" })})`}</p>
                        }
                      </Col>
                      {/* <Col xl='4' md='4' xs='4'>
                        <MyButton
                          type="button"
                          class={`btn btn-primary ${Constants.innerWidthCompare<windowSize.innerWidth ? "margin-top-28" : "margin-top-16 margin-bottom-16"}`}
                          content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
                          loading={loading}
                          disable={loading}
                          onClick={() => { setCustomerSearchModal(true) }}
                        />
                      </Col> */}
                    {/* </Row> */}
                      <Col xl='6'>
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          // className={"mt-2"}
                          title={"Consultants"}
                          specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                          returnFieldWithLabel={true}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={localEmployeeDropdownList}
                          disabled={disabledFieldInput || validation.values.blnDifferentBranch}
                          initialValue={validation.values.consultantIds}
                          validationRequired={true}
                          validation={validation} />
                      </Col>
                    </Row>
                    <p className="margin-left-8 mb-0" style={{ color: 'grey' }}>
                      {validation.values.customerNo} | {validation.values.customerMobileNo}
                    </p>
                    <Borderline />
                    <Row>
                      <Col xl={4}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="button"
                              class="btn btn-back"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Back" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                history.push({
                                  pathname: `/${RoutesList.pos}`,
                                  state: {
                                    detail: {
                                      customerCartId: validation.values.customerCartId
                                    }
                                  }
                                });
                              }}
                            />
                          )}
                      </Col>
                      <Col xl={8}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-primary"
                              style={{ width: '100%', marginTop: Constants.innerWidthCompare>windowSize.innerWidth ? "8px" : "0px" }}
                              content={intl.formatMessage({ id: "ConfirmSales" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            customerSearchModal
            &&
            <CustomerSearchModal
              blnShow={customerSearchModal}
              blnHideOtherBranch={false}
              setModal={setCustomerSearchModal}
              title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
              onSelectFunction={onClickSelect} />
          }
          {
            customerConfirmSalesModal
            &&
            <CustomerOrderConfimSalesModal
              blnShow={customerConfirmSalesModal}
              setModal={setCustomerConfirmSalesModal}
              validation={validation}
              employeeDropdownList={localEmployeeDropdownList}
              blnBlockPaymentSubmit={blnBlockPaymentSubmit}
              blnPermissionBackdateProductRedemptionNote={blnPermissionBackdateProductRedemptionNote} />
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(CustomerOrderPayment);
