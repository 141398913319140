import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { CloseCounterListObject } from '../../app/models/closeCounter';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, closeCounterStatusColor } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import moment from "moment";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GenerateCloseCounterModal from "./Modal/GenerateCloseCounterModal";
import { BranchListObject } from "../../app/models/branch";
import FileArrowLeftRightOutlineIcon from "mdi-react/FileArrowLeftRightOutlineIcon";
import { DateRangeInterface } from "../../app/models/common";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";

const CloseCounter = () => {
  const intl = useIntl();
  //Use Store
  const { closeCounterStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { getBranchDropDown } = branchStore;
  const { closeCounterList, closeCounterPaginationParams, getCloseCounter, addCloseCounter, deleteCloseCounter, setCloseCounterPaginationParams } = closeCounterStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CloseCounterListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [branchListFinal, setBranchListFinal] = useState<BranchListObject[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchName());
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchId());
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCloseCounterStatus, setSelectedCloseCounterStatus] = useState<string>(AllStatus);
  const [selectedCloseCounterStatusId, setSelectedCloseCounterStatusId] = useState<number | string | undefined>(" ");
  const [closeCounterStatusList, setCloseCounterStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [addCloseCounterModal, setAddCloseCounterModal] = useState(false);
  const blnPermissionCreateCloseCounter =  checkPermission([PermissionConstants.CreateCloseCounter]);
  const blnPermissionUpdateCloseCounter =  checkPermission([PermissionConstants.UpdateCloseCounter]);
  const blnPermissionOverrideCloseCounter =  checkPermission([PermissionConstants.OverrideCloseCounter]);
  const blnPermissionDeleteCloseCounter =  checkPermission([PermissionConstants.DeleteCloseCounter]);

  let closeCounterRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    submissionFromDate: selectedDateRange.fromDate,
    submissionToDate: selectedDateRange.toDate,
    status: selectedCloseCounterStatusId,
    statusName: selectedCloseCounterStatus
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCloseCounterAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.closeCounter)
      if (blnBackNavigation) {
        if (closeCounterPaginationParams) {
          setCurrentPage(closeCounterPaginationParams.pageNumber)
          setSelectedBranchId(closeCounterPaginationParams.branchId || "");
          setSelectedBranchName(closeCounterPaginationParams.branchName || "");
          setSelectedCloseCounterStatusId(closeCounterPaginationParams.status);
          setSelectedCloseCounterStatus(closeCounterPaginationParams.statusName || AllStatus);
          setSelectedDateRange({fromDate: closeCounterPaginationParams.submissionFromDate || "", toDate: closeCounterPaginationParams.submissionToDate || ""})
          closeCounterRequestBody = closeCounterPaginationParams;
        }
      }
      else {
        setCloseCounterPaginationParams(undefined);
      }
      
      let aryAPI: any = [
        getCloseCounter(closeCounterRequestBody),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.closeCounterStatus, AllStatus),
      ]
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(resultAPI[1])
      setCloseCounterStatusList(resultAPI[2]);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCloseCounter], true)) {
      return;
    }

    fetchCloseCounterAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.closeCounter)
        if (!blnSetParams) {
          setCloseCounterPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedCloseCounterStatusId || selectedCloseCounterStatusId === 0) && !initialCallAPI) 
    {
      fetchCloseCounterAPI(true);
    }
  }, [selectedCloseCounterStatusId, selectedBranchId, selectedDateRange]);

  useEffect(() => {
    if (successMessage && addCloseCounterModal) {
      setTimeout(() => {
        setSuccessMessage("");
        setAddCloseCounterModal(false);
        fetchCloseCounterAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const onClickDelete = async (row: CloseCounterListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchCloseCounterAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCloseCounter({
      ...closeCounterRequestBody,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CloseCounterColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.submissionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.submissionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "isOverridden",
      text: intl.formatMessage({ id: "Overridden" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isOverridden ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={closeCounterStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageCloseCounter, PermissionConstants.UpdateCloseCounter, PermissionConstants.DeleteCloseCounter]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.closeCounter}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateCloseCounter
              &&
              row.status === Constants.draft
              &&
              <TableActionButton 
                to={`/${RoutesList.closeCounter}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionOverrideCloseCounter
              &&
              row.status !== Constants.draft
              &&
              <TableActionButton
                type="edit"
                to={`/${RoutesList.closeCounter}/override/${row.id}`}
                iconReplaceUI={<FileArrowLeftRightOutlineIcon size={20} className={"text-success pointer-clickable"} id="overrideCloseCounterTooltip" />}
                iconId="overrideCloseCounterTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "Override" })}`} />
            }
            {
              blnPermissionDeleteCloseCounter
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];
  
  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CloseCounter" }) })}
        title={intl.formatMessage({ id: "CloseCounter" })}
        addButton={
          blnPermissionCreateCloseCounter
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "CloseCounter" }) })}
            onClick={() => { setAddCloseCounterModal(true) }}
            disable={loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                name={"dateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                onChangeFunction={onSelectDateRange} />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Status" })}
                name="closeCounterStatus"
                options={closeCounterStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedCloseCounterStatus}
                initialValue={selectedCloseCounterStatusId}
                setFieldLabel={setSelectedCloseCounterStatus}
                setFieldValue={setSelectedCloseCounterStatusId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CloseCounterColumns}
          data={closeCounterList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CloseCounter" }) })}
          objSorted={defaultSorted}
          branchId={selectedBranchId}
          branchName={selectedBranchName}
          status={selectedCloseCounterStatusId}
          statusName={selectedCloseCounterStatus}
          submissionFromDate={selectedDateRange.fromDate}
          submissionToDate={selectedDateRange.toDate}
          requestAPI={getCloseCounter} />
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCloseCounter(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (closeCounterList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCloseCounter({ ...closeCounterRequestBody, pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
        {
          addCloseCounterModal
          &&
          <GenerateCloseCounterModal
            blnShow={addCloseCounterModal}
            setModal={setAddCloseCounterModal}
            initialBranchId={selectedBranchId}
            initialBranchName={selectedBranchName}
            branchDropdownList={branchListFinal}
            addCloseCounter={addCloseCounter}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CloseCounter);
