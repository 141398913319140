import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { PayslipListObject } from '../../app/models/payslip';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, getBranchId, getBranchUser, getMonths, getYears, returnPriceWithCurrency } from "../../app/common/function/function";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import GeneratePayslipModal from "./Modal/GeneratePayslipModal";
import TableActionButton from "../../app/components/table/TableActionButton";

const Payslip = () => {
  const intl = useIntl();
  //Use Store
  const { payslipStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { payslipList, getPayslip, addPayslip } = payslipStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PayslipListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const pageSize = Constants.defaultPageSize;
  const [payslipModal, setPayslipModal] = useState(false);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setMonthList(getMonths());
    setYearList(getYears());

    async function fetchEmployeeDropdown() {
      setLoading(true);
      let aryAPI: any = [
        getEmployeeDropdown({ branchId: getBranchUser() ? getBranchId() : " ", isBranchStaff: true })
      ];
      await Promise.all(aryAPI);
      setLoading(false);
    }

    fetchEmployeeDropdown();
  }, [])

  useEffect(() => {
    if (selectedYear && selectedMonthValue) {
      if (!checkPermission([PermissionConstants.ManagePayslip], true)) {
        return;
      }
      fetchPayslipAPI(true);
    }
  }, [selectedYear, selectedMonthValue])

  async function fetchPayslipAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPayslip({ pageNumber: currentPage, pageSize: pageSize, year: selectedYear, month: selectedMonthValue })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickView = async () => {
    setPayslipModal(true);
  }

  //Table Content UI
  const PayslipColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "employeeFullName",
      text: intl.formatMessage({ id: "EmployeeName" }).toUpperCase(),
    },
    {
      dataField: "month",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => <div className="text-center">{`${(moment.months(row.month-1))} ${row.year}`}</div>, //Custom UI to display
    },
    {
      dataField: "basic",
      headerStyle: { textAlign: 'center' },
      text: intl.formatMessage({ id: "Salary" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{`${returnPriceWithCurrency(row.basic)}`}</div>
      ),
    },
    {
      dataField: "netPay",
      headerStyle: { textAlign: 'center' },
      text: intl.formatMessage({ id: "NetPay" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{`${returnPriceWithCurrency(row.netPay)}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.UpdatePayslip, PermissionConstants.DeletePayslip]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.payslip}/view/${row.id}`}
              type={'view'}/>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Payslip" }) })}
        title={intl.formatMessage({ id: "Payslip" })}
        addButton={
          checkPermission([PermissionConstants.CreatePayslip])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "GenerateWithModuleName" }, { moduleName: intl.formatMessage({ id: "Payslip" }) })}
            onClick={() => onClickView()}
            disable={loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PayslipColumns}
              data={payslipList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Payslip" }) })}
              objSorted={defaultSorted}
              requestAPI={getPayslip} />
        }
        {
          payslipModal
          &&
          <GeneratePayslipModal
            blnShow={payslipModal}
            setModal={setPayslipModal}
            optionYearList={yearList}
            optionMonthList={monthList}
            employeeList={employeeDropdownList}
            addPayslip={addPayslip}
            setErrorMessage={setErrorMessage} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Payslip);
