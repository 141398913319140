import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { NotificationTemplateListObject } from "../../app/models/notificationTemplate";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const NotificationTemplate = () => {
  const intl = useIntl();
  //Use Store
  const { notificationTemplateStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { notificationTemplateList, notificationTemplatePaginationParams, getNotificationTemplate, deleteNotificationTemplate, setNotificationTemplatePaginationParams } = notificationTemplateStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<NotificationTemplateListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateNotificationTemplate = checkPermission([PermissionConstants.CreateNotificationTemplate]);
  const blnPermissionUpdateNotificationTemplate = checkPermission([PermissionConstants.UpdateNotificationTemplate]);
  const blnPermissionDeleteNotificationTemplate = checkPermission([PermissionConstants.DeleteNotificationTemplate]);

  let notificationTemplateRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchNotificationTemplateAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.notificationTemplate)
      if (blnBackNavigation) {
        if (notificationTemplatePaginationParams) {
          setCurrentPage(notificationTemplatePaginationParams.pageNumber)
          notificationTemplateRequestBody = notificationTemplatePaginationParams;
        }
      }
      else {
        setNotificationTemplatePaginationParams(undefined);
      }
      await getNotificationTemplate(notificationTemplateRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageNotificationTemplate], true)) {
      return;
    }

    fetchNotificationTemplateAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.notificationTemplate)
        if (!blnSetParams) {
          setNotificationTemplatePaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: NotificationTemplateListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const NotificationTemplateColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
      formatter: (cellContent, row) => (
        newSpaceBeforeCapitalLetter(row.name)
      ),
    },
    {
      dataField: "templateKey",
      text: intl.formatMessage({ id: "TemplateType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        newSpaceBeforeCapitalLetter(row.templateKey)
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageNotificationTemplate, PermissionConstants.UpdateNotificationTemplate, PermissionConstants.DeleteNotificationTemplate]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.notificationTemplate}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateNotificationTemplate
              &&
              <TableActionButton 
                to={`/${RoutesList.notificationTemplate}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteNotificationTemplate
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "NotificationTemplate" }) })}
        title={intl.formatMessage({ id: "NotificationTemplate" })}
        addButton={
          blnPermissionCreateNotificationTemplate
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "NotificationTemplate" }) })}
            onClick={() => { history.push(`/${RoutesList.notificationTemplate}/add`) }}
            linkTo={`/${RoutesList.notificationTemplate}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={NotificationTemplateColumns}
          data={notificationTemplateList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "NotificationTemplate" }) })}
          objSorted={defaultSorted}
          requestAPI={getNotificationTemplate} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteNotificationTemplate(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (notificationTemplateList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getNotificationTemplate({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(NotificationTemplate);
