import { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { RoutesList } from "../../app/constants/RoutesList"
import { useIntl } from "react-intl";
import { getJWT } from "../../app/common/function/function";

//Import Images
import error from "../../assets/images/profile-img2.png"
import { Constants } from "../../app/constants/Constants";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";

const NotFound = () => {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { setLoading } = commonStore;

  useEffect(()=> {
    setLoading(false);
  }, [])

  return (
    <div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>          
            <Col lg="12">
              <div className="text-center mb-5">
                <h4 className="display-2 font-weight-medium" style={{fontSize: "4.5rem"}}>
                  {intl.formatMessage({id: "ServerError"})} 5<i className="bx bx-buoy bx-spin text-primary display-3" /><i className="bx bx-buoy bx-spin text-primary display-3" />
                </h4>
                <p className="display-2 mt-3" style={{fontSize: "1.5rem", color: Constants.lightorange}}>
                  {intl.formatMessage({id: "ServerErrorMessage1"})}
                  {/* <i className="bx bx-time bx-spin text-primary display-5" style={{marginLeft: '4px'}} /> */}
                </p>
                <p className="display-2" style={{fontSize: "1.5rem", color: Constants.lightorange}}>
                  {intl.formatMessage({id: "ServerErrorMessage2"})}
                  {/* <i className="bx bx-time bx-spin text-primary display-5" style={{marginLeft: '4px'}} /> */}
                </p>
                <div className="mt-2 text-center">
                  <Link
                    className="btn btn-primary "
                    to={getJWT() ? `/${RoutesList.dashboard}` : `/${RoutesList.login}`} 
                  >
                    {intl.formatMessage({id: "BackToPage"})}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default observer(NotFound);
