import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from '../../app/components/form/GeneralInput';
import classNames from "classnames";
import _ from 'lodash';
import { AddOptionsValueField, checkPermission, contructValidationErrorMessage, getBranchId, getBrandId, newSpaceBeforeCapitalLetter, returnOperatingHours, returnSubBrandList } from '../../app/common/function/function';
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import {
  Row,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Label
} from "reactstrap";
import { IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import TableWithTimeSlot from "../../app/components/table/TableWithTimeSlot";
import TableWithCheckBox from "../../app/components/table/TableWithCheckBox";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { burgerMenu, ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import { LeadTemplateMappingsObject, RoomForBranchObject } from "../../app/models/branch";
import moment from "moment";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import GeneralColorPicker from "../../app/components/form/GeneralColorPicker";

const BranchDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { branchStore, countryStore, businessEntityStore, brandStore, profileStore, staticReferenceStore, commonStore } = useStore();
  const { brandDropdownList, getBrandDropdown } = brandStore;
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { businessEntityDropdownList, getBusinessEntityDropDown } = businessEntityStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const { branchDetail, branchBusinessHour, branchPaymentMethod, addBranch, getBranchWithId, getBranchBusinessHour, getBranchPaymentMethod, setBranchDetail } = branchStore;
  const { profileDetail } = profileStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const isSystemUser = profileDetail?.isSystemUser;

  const addAction = IncludesLocationPathName('add');
  const viewAction = IncludesLocationPathName('view');
  const displayTitle = `${addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})} ${intl.formatMessage({id: "Branch"})}` ;
  const breadCrumbList = [{title: intl.formatMessage({id: "Branch"}), urlPath: RoutesList.branch}];
  const operatingHours = returnOperatingHours(true);
  const appointmentSlot = operatingHours;
  const [activeTab, setActiveTab] = useState("1");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingBusinessEntity, setLoadingBusinessEntity] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const tabBar = [intl.formatMessage({id: "Business"}).toUpperCase(), intl.formatMessage({id: "Payment"}).toUpperCase(), intl.formatMessage({id: "Rooms"}).toUpperCase(), intl.formatMessage({id: "LeadTemplateMapping"}).toUpperCase()];
  const subBrandList = AddOptionsValueField(returnSubBrandList(), "name", "id");
  const [leadPlatformTypeFinalList, setLeadPlatformTypeFinalList] = useState<any>([]);

  const aryDynamicInputAndCheckBoxTab : ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({id: "Name"}).toUpperCase(),
      type: "input",
      xl: "8",
      inputName: "name",
      inputType: "text",
      validationRequired: true
    },
    {
      label: intl.formatMessage({id: "DisplayOrder"}).toUpperCase(),
      type: "input",
      xl: "4",
      textAlign: true,
      inputName: "displayOrder",
      inputType: "number",
    },
  ];

  const aryDynamicInputAndCheckBoxTabWithSubBrand : ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({id: "Name"}).toUpperCase(),
      type: "input",
      xl: "4",
      inputName: "name",
      inputType: "text",
      validationRequired: true
    },
    {
      label: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      type: "select",
      xl: "4",
      inputName: "subBrandName",
      inputType: "select",
      options: subBrandList,
      displayLabel: "subBrandName",
      displayValue: "subBrandId",
      specifyReturnFieldName: [{ field: "subBrandName", value: "name" }, { field: "subBrandId", value: "id" }],
    },
    {
      label: intl.formatMessage({id: "DisplayOrder"}).toUpperCase(),
      type: "input",
      xl: "4",
      textAlign: true,
      inputName: "displayOrder",
      inputType: "number",
    }
  ];
  const fieldsOfRoom : RoomForBranchObject = { name: "", isActive: true, displayOrder: 1, subBrandId: null, subBrandName: null};

  const aryDynamicInputAndCheckBoxTabForMarcomTemplate : ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "PlatformType" }).toUpperCase(),
      type: "select",
      xl: "6",
      inputName: "platformType",
      inputType: "select",
      options: leadPlatformTypeFinalList,
      displayLabel: "platformTypeDisplay",
      displayValue: "platformType",
      specifyReturnFieldName: [{ field: "platformTypeDisplay", value: "label" }, { field: "platformType", value: "value" }],
      validationRequired: true
    },
    {
      label: intl.formatMessage({id: "Name"}).toUpperCase(),
      type: "input",
      xl: "6",
      inputName: "name",
      inputType: "text",
      validationRequired: true
    }
  ];
  const fieldsOfLeadTemplateMappings : LeadTemplateMappingsObject = { platformType: "", name: "" };

  const pageSize = Constants.maxPageSize;
  const [loadingState, setLoadingState] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const blnPermissionUpdateBranch = checkPermission([PermissionConstants.UpdateBranch]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: (branchDetail || {
      businessEntityId: '',
      businessEntityName: '',
      name: '',
      branchCode: '',
      email: '',
      prefix: '',
      contactNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      countryId: '',
      countryName: '',
      stateId: '',
      stateName: '',
      lastAppointmentSlot: '',
      businessHours: branchBusinessHour,
      paymentMethods: branchPaymentMethod,
      rooms: [],
      supportCategories: [],
      brandId: "",
      brandName: "",
      monthlySalesTarget: 0,
      colorCode: "",
      displayOrder: 1,
      leadTemplateMappings: [],
      isHQ: false,
    }),
    validationSchema: Yup.object({
      businessEntityId: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({id: "BusinessEntity"})})),
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "BranchName"})})),
      lastAppointmentSlot: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({id: "LastAppointmentSlot"})})),
      prefix: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Prefix"})})),
      branchCode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "BranchCode"})})),
      contactNumber: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "ContactNumber"})})),
      monthlySalesTarget: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MonthlySalesTarget" }) })),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "DisplayOrder"})})),
    }),
    onSubmit: async (values) => {
      const valuesTemp : any = _.cloneDeep(values);
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }
      
      try {
        valuesTemp.lastAppointmentSlot = moment(valuesTemp.lastAppointmentSlot, 'hh:mm A').format();
        
        if (valuesTemp.rooms) {
          let emptyRoomNameFound = false;
          valuesTemp.rooms = valuesTemp.rooms.map((valueRooms)=> {
            let emptyRoomName = false;
            if (!valueRooms.name) {
              emptyRoomName = true;
              emptyRoomNameFound = true;
            }
            return ({
              ...valueRooms,
              backgroundColor: emptyRoomName ? Constants.lightorange : ""
            })
          })

          if (emptyRoomNameFound) {
            setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RoomName" })}))
            validation.setFieldValue("rooms", valuesTemp.rooms);
            return;
          }
          else {
            validation.setFieldValue("rooms", valuesTemp.rooms);
          }
        }

        if (valuesTemp.leadTemplateMappings) {
          let emptyLeadTemplateMappingsPlatformTypeFound = false;
          let emptyLeadTemplateMappingsNameFound = false;
          valuesTemp.leadTemplateMappings = valuesTemp.leadTemplateMappings.map((valueLeadTemplateMappings)=> {
            let emptyLeadTemplateMappingsPlatformType = false;
            let emptyLeadTemplateMappingsName = false;
            if (!valueLeadTemplateMappings.platformTypeDisplay) {
              emptyLeadTemplateMappingsPlatformType = true;
              emptyLeadTemplateMappingsPlatformTypeFound = true;
            }
            if (!valueLeadTemplateMappings.name) {
              emptyLeadTemplateMappingsName = true;
              emptyLeadTemplateMappingsNameFound = true;
            }
            return ({
              ...valueLeadTemplateMappings,
              backgroundColor: emptyLeadTemplateMappingsPlatformType || emptyLeadTemplateMappingsName ? Constants.lightorange : ""
            })
          })

          if (emptyLeadTemplateMappingsPlatformTypeFound || emptyLeadTemplateMappingsNameFound) {
            if (emptyLeadTemplateMappingsPlatformTypeFound) {
              setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PlatformTypeLeadTemplateMapping" })}))
            }
            if (emptyLeadTemplateMappingsNameFound) {
              setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "NameLeadTemplateMapping" })}))
            }
            validation.setFieldValue("leadTemplateMappings", valuesTemp.leadTemplateMappings);
            return;
          }
          else {
            validation.setFieldValue("leadTemplateMappings", valuesTemp.leadTemplateMappings);
          }
        }

        if (addAction) {
          await addBranch(valuesTemp)
        }
        else {
          await branchStore.updateBranch(valuesTemp)
        }
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
		async function fetchBranchAPI() {
			setLoading(true);
      let aryAPI : any = [
        isSystemUser ? getBrandDropdown() : undefined,
        !viewAction ? getBusinessEntityDropDown(getBrandId()) : undefined,
        getBranchBusinessHour(""),
        getBranchPaymentMethod(""),
        getCountryDropdown()
      ];

      if (id && !addAction) {
        aryAPI.push(getBranchWithId(id));
      }
      

      let resultMarcomPlatformType : any = await getStaticReferenceWithType(Constants.leadPlatformType)
      if (resultMarcomPlatformType) {
        resultMarcomPlatformType = resultMarcomPlatformType.map((valueResultMarcomPlatformType)=> ({
          ...valueResultMarcomPlatformType,
          label: newSpaceBeforeCapitalLetter(valueResultMarcomPlatformType.displayValue),
          value: valueResultMarcomPlatformType.displayValue
        }))
      }
      setLeadPlatformTypeFinalList(resultMarcomPlatformType);

      await Promise.all(aryAPI);
			setLoading(false);
      setInitialCallAPI(false);
		} 

    if (addAction) {
			setBranchDetail(undefined);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateBranch], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateBranch], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageBranch], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateBranch){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.branch}/edit/${id}`)} })
    }

    fetchBranchAPI();
    
    return (()=> {
      if (id && !addAction) {
        setBranchDetail(undefined);
      }
    })
	}, []);

  useEffect(() => {
    if (id && !addAction) {
      if (branchDetail) {
        if (branchDetail.countryId) {
          getStateWithLoading(branchDetail.countryId, false)
        }
        if (branchDetail.leadTemplateMappings) {
          branchDetail.leadTemplateMappings = branchDetail.leadTemplateMappings.map((valueLeadTemplateMappings)=> ({
            ...valueLeadTemplateMappings,
            platformTypeDisplay: newSpaceBeforeCapitalLetter(valueLeadTemplateMappings.platformType)
          }));
          // processLeadTemplateMappings(branchDetail.leadTemplateMappings)
        }
      }
    }
	}, [branchDetail]);

  useEffect(() => {
    async function getBusinessEntityDropdown () {
      setLoadingBusinessEntity(true);
      await getBusinessEntityDropDown(validation.values.brandId);
      setLoadingBusinessEntity(false);
    }

    if (!initialCallAPI) {
      getBusinessEntityDropdown();
    }
  }, [validation.values.brandId])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  // useEffect(()=> {
  //   if (validation.values.leadTemplateMappings && !initialCallAPI) {
  //     processLeadTemplateMappings(validation.values.leadTemplateMappings);
  //   }
  // }, [validation.values.leadTemplateMappings])

  const getStateWithLoading = async (id: string, removeData: boolean=true) => {
		setLoadingState(true);
		await getStateWithId(id);
    if (removeData) {
      validation.setValues((values)=> ({
        ...values,
        stateId: "",
        stateName: ""
      }))
    }
		setLoadingState(false);
	};

  const processLeadTemplateMappings = (leadTemplateMappingsTemp) => {
    let leadPlatformTypeFinalListTemp = _.cloneDeep(leadPlatformTypeFinalList);
    leadPlatformTypeFinalListTemp = leadPlatformTypeFinalListTemp.map((valueLeadPlatformTypeTemp)=> {
      let indexLeadTemplateMappings = _.findIndex(leadTemplateMappingsTemp, {platformType: valueLeadPlatformTypeTemp.displayValue})
      return ({
        ...valueLeadPlatformTypeTemp,
        disabled: indexLeadTemplateMappings > -1 ? true : false
      }) 
    })
    setLeadPlatformTypeFinalList(leadPlatformTypeFinalListTemp);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={()=> {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}> {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({id: "Branch"})})}
                  h4Title/>
                {
                  isSystemUser
                  &&
                  addAction
                  &&
                  <DropDownWithTitle
                    name={"brandId"}
                    className={"mt-4"}
                    title={intl.formatMessage({ id: "Brand"})}
                    specifyReturnFieldName={[{"field": "brandId", "value": "id"}, {"field": "brandName", "value": "name"}]}
                    labelField={"name"}
                    valueField={"name"}
                    options={brandDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    initialValue={validation.values.brandName}
                    onChangeFunction={()=> {
                      validation.setFieldValue("businessEntityId", "");
                      validation.setFieldValue("businessEntityName", "");
                    }}
                    validation={validation}/>
                }
                {
                  loadingBusinessEntity
                  ?
                  <Loading 
                    fullHeightDisabled={true}/>
                  :
                  <DropDownWithTitle
                    name={"businessEntityId"}
                    className={(!isSystemUser || !addAction) ? "mt-4" : ""}
                    title={intl.formatMessage({ id: "BusinessEntityEntitledToBranch"})}
                    specifyReturnFieldName={[{"field": "businessEntityId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"name"}
                    options={businessEntityDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    initialValue={validation.values.businessEntityName}
                    validation={validation}/>
                }
                <GeneralInput
                  title={intl.formatMessage({ id: "BranchName"})}
                  name="name"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}/>
                <GeneralInput
                  title={intl.formatMessage({ id: "BranchCode"})}
                  name="branchCode"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}/>
                <GeneralInput
                  title={intl.formatMessage({ id: "Email"})}
                  name="email"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}/>
                <GeneralInput
                  title={intl.formatMessage({ id: "Prefix"})}
                  name="prefix"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}/>
                <GeneralInput
                  title={intl.formatMessage({ id: "ContactNumber"})}
                  name="contactNumber"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}/>

                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine1"})}
                  name="addressLine1"
                  type="text"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine2"})}
                  name="addressLine2"
                  type="text"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "City"})}
                      name="city"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PostCode"})}
                      name="postcode"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"countryId"}
                      title={intl.formatMessage({ id: "Country"})}
                      specifyReturnFieldName={[
                        {
                          field: "countryId",
                          value: "id",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={countryDropdownList}
                      disabled={
                        disabledFieldInput ||
                        viewAction ||
                        loadingState
                      }
                      onChangeFunction={
                        getStateWithLoading
                      }
                      initialLabel={
                        !addAction
                          ? validation
                              .values
                              .countryName
                          : undefined
                      }
                      initialValue={
                        !addAction
                          ? validation
                              .values
                              .countryId
                          : undefined
                      }
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    {loadingState ? (
                      <Loading />
                    ) : (
                      stateList.length > 0
                      ?
                      <DropDownWithTitle
                        name={"stateId"}
                        title={intl.formatMessage({ id: "State"})}
                        specifyReturnFieldName={[
                          {
                            field: "stateId",
                            value: "id",
                          },
                          {
                            field: "stateName",
                            value: "name",
                          },
                        ]}
                        labelField={"name"}
                        valueField={"id"}
                        options={stateList}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialLabel={
                          !addAction
                            ? validation
                                .values
                                .stateName
                            : undefined
                        }
                        initialValue={
                          !addAction
                            ? validation
                                .values
                                .stateId
                            : undefined
                        }
                        validationRequired={stateList.length > 0 ? true : false}
                        validation={
                          validation
                        }
                      />
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "StateName"})}
                        name="stateName"
                        type="text"
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        validationRequired={true}
                        validation={validation}
                      />
                    )}
                  </DoubleColumnRowParent>
                </Row>
                <DropDownWithTitle
                  name={"lastAppointmentSlot"}
                  title={intl.formatMessage({ id: "LastAppointmentSlot"})}
                  specifyReturnFieldName={[{"field": "lastAppointmentSlot", "value": "value"}]}
                  labelField={"label"}
                  valueField={"value"}
                  options={appointmentSlot}
                  disabled={disabledFieldInput || viewAction}
                  initialValue={!addAction ? moment(validation.values.lastAppointmentSlot).format("hh:mm A") : undefined}
                  validationRequired={true}
                  validation={validation}/>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "MonthlySalesTarget" })}
                    name="monthlySalesTarget"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralColorPicker
                    title={intl.formatMessage({ id: "ColorCode"})}
                    name="colorCode"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    intl={intl}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>  
                  <GeneralInput
                    title={intl.formatMessage({ id: "HQ"})}
                    name="isHQ"
                    type="checkbox"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes"})}</Label>}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "DisplayOrder"})}
                      name="displayOrder"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                </SingleColumnRowParent>
              </Col>  
              <Col xl={7}>
                <Nav tabs className="">
                  {
                    tabBar.map((value, indexTab)=> (
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active: activeTab === String(indexTab+1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab+1));
                          }}
                        >
                          {value}
                        </NavLink>
                      </NavItem>
                    ))
                  }
                </Nav>
                {
                  activeTab === '1' 
                  &&
                  <TableWithTimeSlot
                    name="businessHours"
                    title={intl.formatMessage({ id: "OperatingHours"})}
                    blnEditable={!viewAction}
                    timeSlot={operatingHours}
                    data={validation.values.businessHours}
                    validation={validation}
                    disabled={disabledFieldInput}/>
                } 
                { 
                  activeTab === '2' 
                  &&
                  <TableWithCheckBox
                    name="paymentMethods"
                    title={intl.formatMessage({ id: "PaymentAvailableInBranch"})}
                    blnEditable={!viewAction}
                    blnSelectAll={true}
                    data={validation.values.paymentMethods}
                    blnSingleSelect={false}
                    validation={validation}
                    disabled={disabledFieldInput}/>
                }
                {
                  activeTab === '3' 
                  &&
                  <TableWithEditableInputFields
                    name="rooms"
                    title={intl.formatMessage({ id: "RoomIncludeInBranch"})}
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Room" }) })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={subBrandList.length > 0 ? aryDynamicInputAndCheckBoxTabWithSubBrand : aryDynamicInputAndCheckBoxTab}
                    data={validation.values.rooms}
                    inputField={fieldsOfRoom}
                    supportBackgroundColor={true}
                    validation={validation}
                    blnCheckBox={true}
                    disabled={disabledFieldInput}/>
                }
                {
                  activeTab === '4' 
                  &&
                  <TableWithEditableInputFields
                    name="leadTemplateMappings"
                    title={intl.formatMessage({ id: "LeadTemplateMappingsIncludeInBranch"})}
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "LeadTemplateMapping" }) })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabForMarcomTemplate}
                    data={validation.values.leadTemplateMappings}
                    inputField={fieldsOfLeadTemplateMappings}
                    supportBackgroundColor={true}
                    validation={validation}
                    disabled={disabledFieldInput}/>
                }
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn 
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
}

export default observer(BranchDetail);
