import { PaginationRequestBody } from "../models/pagination";
import { MarcomDailyRecordAddObject, MarcomDailyRecordListObject } from "../models/marcomDailyRecord";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class MarcomDailyRecordStore {
  marcomDailyRecordList: MarcomDailyRecordListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.marcomDailyRecordList = [];
  }
  
  getMarcomDailyRecord = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultMarcomDailyRecord = await agent.MarcomDailyRecord.marcomDailyRecordList(PaginationRequestBody);
      runInAction(() => {
        this.marcomDailyRecordList = resultMarcomDailyRecord.data;
        store.commonStore.setTotalItem(resultMarcomDailyRecord.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.marcomDailyRecordList = [];
    }
  }
  addMarcomDailyRecord = async (dsrRequestBody: MarcomDailyRecordAddObject) => {
    try{
      await agent.MarcomDailyRecord.addMarcomDailyRecord(dsrRequestBody);
      store.commonStore.setSuccessMessage(`MarcomDailyRecordAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteMarcomDailyRecord  = async (id: string, name: string) => {
    try {
      await agent.MarcomDailyRecord.deleteMarcomDailyRecord(id);
      store.commonStore.setSuccessMessage(`MarcomDailyRecordDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}