import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { SalesAdjustmentListObject } from '../../app/models/salesAdjustment';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnRouteFromModule, returnSubBrandList } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import SalesAdjustmentDetailModal from "./Modal/SalesAdjustmentDetailModal";
import CustomerRefundDetailModal from "../CustomerRefund/Modal/CustomerRefundDetailModal";
import DeleteModal from "../../app/components/modal/DeleteModal";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import { history } from "../..";
import Loading from "../../app/components/loading/Loading";
import CreditAdjustmentModal from "../Customer/Modal/CreditAdjustmentModal";
import PaymentAdjustmentDetailModal from "../CustomerPaymentAdjustment/Modal/PaymentAdjustmentDetailModal";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";

const SalesAdjustment = () => {
  const intl = useIntl();
  //Use Store
  const { salesAdjustmentStore, branchStore, customerRefundStore, creditAdjustmentStore, customerPaymentAdjustmentStore, staticReferenceStore, settingsStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, windowSize, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { salesAdjustmentList, getSalesAdjustment, addSalesAdjustment, getSalesAdjustmentWithId, updateSalesAdjustment, deleteSalesAdjustment } = salesAdjustmentStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { getCustomerRefundWithId } = customerRefundStore;
  const { creditAdjustmentDetail, getCreditAdjustmentWithId } = creditAdjustmentStore;
  const { getCustomerPaymentAdjustmentWithId } = customerPaymentAdjustmentStore;
  const { createSalesAdjustmentType, getStaticReferenceWithType } = staticReferenceStore;
  const { generalSettings, getGeneralSettings } = settingsStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<SalesAdjustmentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [salesAdjustmentTypeList, setSalesAdjustmentTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedSalesAdjustmentTypeOptions, setSelectedSalesAdjustmentTypeOptions] = useState<string[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [salesAdjustmentDetailModal, setSalesAdjustmentDetailModal] = useState(false);
  const [selectedSalesAdjustmentDetail, setSelectedSalesAdjustmentDetail] = useState<any>([]);
  const [blnAction, setBlnAction] = useState("");
  const [creditAdjustmentModal, setCreditAdjustmentModal] = useState(false);

  const [customerRefundDetailModal, setCustomerRefundDetailModal] = useState(false);
  const [selectedCustomerRefundDetail, setSelectedCustomerRefundDetail] = useState<any>([]);
  const [paymentAdjustmentDetailModal, setPaymentAdjustmentDetailModal] = useState(false);
  const [selectedCustomerPaymentAdjustmentDetail, setSelectedCustomerPaymentAdjustmentDetail] = useState<any>([]);
  const blnPermissionCreateSalesAdjustment = checkPermission([PermissionConstants.CreateSalesAdjustment]);
  const blnPermissionUpdateSalesAdjustment = checkPermission([PermissionConstants.UpdateSalesAdjustment]);
  const blnPermissionDeleteSalesAdjustment = checkPermission([PermissionConstants.DeleteSalesAdjustment]);
  const blnPermissionManageCustomerRefund = checkPermission([PermissionConstants.ManageCustomerRefund]);
  const blnPermissionManageCustomerProductReturn = checkPermission([PermissionConstants.ManageCustomerProductReturn]);
  const blnPermissionManageCreditAdjustment = checkPermission([PermissionConstants.ManageCreditAdjustment]);
  const blnPermissionManagePaymentAdjustment = checkPermission([PermissionConstants.ManagePaymentAdjustment]);
  const blnPermissionManageAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
      referenceNo: "",
      salesAdjustmentTypes: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchSalesAdjustmentListAPI() {
      setLoading(true)
      let aryAPI: any = [
        fetchSalesAdjustmentAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.salesAdjustmentType),
        getStaticReferenceWithType(Constants.createSalesAdjustmentType, AllType),
        getGeneralSettings()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))
      setSalesAdjustmentTypeList(resultAPI[2]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesAdjustment], true)) {
      return;
    }

    fetchSalesAdjustmentListAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchSalesAdjustmentAPI(true);
    }
  }, [selectedBranchId, selectedDateRange, triggerSearch])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        if(selectedSalesAdjustmentDetail){
          setSelectedSalesAdjustmentDetail([]);
        }
        setSuccessMessage("");
        setSalesAdjustmentDetailModal(false);
        fetchSalesAdjustmentAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchSalesAdjustmentAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getSalesAdjustment({
      pageNumber: currentPage,
      pageSize: pageSize,
      salesAdjustmentTypes: validation.values.salesAdjustmentTypes,
      branchId: selectedBranchId,
      salesOrderNo: validation.values.salesOrderNo,
      referenceNo: validation.values.referenceNo,
      dateRange: selectedDateRange
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickViewDetail = async (salesAdjustmentId: any) => {
    setLocalLoading(true);
    let resultSalesAdjustmentDetail = await getSalesAdjustmentWithId(salesAdjustmentId);
    setSelectedSalesAdjustmentDetail(resultSalesAdjustmentDetail)
    setSalesAdjustmentDetailModal(true);
    setLocalLoading(false);
  }

  const onClickViewRefundDetail = async (customerRefundId: any) => {
    setLocalLoading(true);
    let resultPreviewRefundCredit = await getCustomerRefundWithId(customerRefundId);
    setSelectedCustomerRefundDetail(resultPreviewRefundCredit)
    setCustomerRefundDetailModal(true);
    setLocalLoading(false);
  }

  const onClickViewCreditAdjustment = async (creditAdjustmentId: any) => {
    setLoading(true);
    await getCreditAdjustmentWithId(creditAdjustmentId)
    setCreditAdjustmentModal(true);
    setLoading(false);
  }

  const onClickViewPaymentAdjustment = async (paymentAdjustmentId: any) => {
    setLoading(true);
    let resultPaymentAdjustment = await getCustomerPaymentAdjustmentWithId(paymentAdjustmentId)
    setSelectedCustomerPaymentAdjustmentDetail(resultPaymentAdjustment);
    setPaymentAdjustmentDetailModal(true);
    setLoading(false);
  }

  const viewReferenceNo = (row) => {
    if (row.salesAdjustmentType == Constants.refundSalesOrderToCredit) {
      return (
        blnPermissionManageCustomerRefund
          ?
          <Link to="#" onClick={() => onClickViewRefundDetail(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType == Constants.encashFromCredit) {
      return (
        blnPermissionManageCreditAdjustment
          ?
          <Link to="#" onClick={() => onClickViewCreditAdjustment(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType == Constants.productReturnCredit) {
      return (
        blnPermissionManageCustomerProductReturn
          ?
          <Link to={row.referenceId ? `/${returnRouteFromModule(row.salesAdjustmentType)}/view/${row.referenceId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType == Constants.customerCreditPayment) {
      return (
        <MyButton
        type={"button"}
        content={`${intl.formatMessage({ id: "ViewDetails" })}`}
        subContent={<CashCheckIcon size={20} />}
        onClick={() => {
          history.push({
            pathname:`/${RoutesList.receipt}/view/${row.referenceId}`,
            state: {
              blnSalesAdjustment: true
            }
          });
        }}
        style={{border: 'none', backgroundColor: 'transparent', fontWeight:'500', display: 'contents'}}
        class="text-primary margin-bottom-0 padding-0"
        />
      );
    }
    else if (row.salesAdjustmentType == Constants.paymentAdjustment) {
      return (
        blnPermissionManagePaymentAdjustment
          ?
          <Link to="#" onClick={() => onClickViewPaymentAdjustment(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType == Constants.appolousSale) {
      return (
        blnPermissionManageAppolousSales
          ?
          <Link to={row.referenceId ? `/${returnRouteFromModule(row.salesAdjustmentType)}/view/${row.referenceId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else {
      return (
          <div>{row.referenceNo}</div>
      );
    }
  }

  const onClickDelete = async (row: SalesAdjustmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const SalesAdjustmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "salesAdjustmentNo",
      text: intl.formatMessage({ id: "SalesAdjustmentNo" }).toUpperCase(),
    },
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performedDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performedDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {
            row.salesOrderNo ?
              <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo}</Label>
              </Link>
              :
              <div>{Constants.emptyData}</div>
          }
        </>
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
          </div>
        )
      }
    },
    {
      dataField: "salesAdjustmentType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.salesAdjustmentType)}
        </div>
      ),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.amount)}
        </div>
      ),
    },
    {
      dataField: "productAmount",
      text: intl.formatMessage({ id: "ProductAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(isNaN(row.productAmount) ?  0 : row.productAmount)}</div>
      ),
    },
    {
      dataField: "productCommission",
      text: intl.formatMessage({ id: "ProductComm" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(isNaN(row.productCommission) ?  0 : row.productCommission)}</div>
      ),
    },
    {
      dataField: "referenceNo",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {
            row.referenceNo && row.referenceId ?
              viewReferenceNo(row)
              :
              <div>{Constants.emptyData}</div>
          }
        </>
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to="#"
              onClick={() => {
                onClickViewDetail(row.id);
                setBlnAction(Constants.view)
              }}
              type="viewModal" />
            {
              blnPermissionUpdateSalesAdjustment 
              &&
              (!generalSettings?.isAllowCrossMonthSales && (moment(row.performedDateTime).format(Constants.displayYearAndMonthFormat) === moment().format(Constants.displayYearAndMonthFormat)) || generalSettings?.isAllowCrossMonthSales ? true : false)
              &&
              (row.salesAdjustmentType == Constants.manualAdditionAdjustment || row.salesAdjustmentType == Constants.manualDeductionAdjustment || row.salesAdjustmentType == Constants.appolousSale)
              &&
              <TableActionButton
                to="#"
                onClick={() => {
                  onClickViewDetail(row.id);
                  setBlnAction(Constants.update);
                }}
                type="edit" />
            }
            {
              blnPermissionDeleteSalesAdjustment
              &&
              (!generalSettings?.isAllowCrossMonthSales && (moment(row.performedDateTime).format(Constants.displayYearAndMonthFormat) === moment().format(Constants.displayYearAndMonthFormat)) || generalSettings?.isAllowCrossMonthSales ? true : false)
              &&
              (row.salesAdjustmentType == Constants.manualAdditionAdjustment || row.salesAdjustmentType == Constants.manualDeductionAdjustment)
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesAdjustment" }) })}
        title={intl.formatMessage({ id: "SalesAdjustment" })}
        addButton={
          blnPermissionCreateSalesAdjustment
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "SalesAdjustment" }) })}
            onClick={() => {
              setSalesAdjustmentDetailModal(true);
              setBlnAction(Constants.add);
            }}
            disable={loading || localLoading} />
        }>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={(value) => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
                // getCustomerWithLoading(value);
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <DropDownWithTitleMultiSelect
              name="salesAdjustmentTypes"
              title={intl.formatMessage({ id: "SalesAdjustmentType" })}
              specifyReturnFieldName={[{ "field": "salesAdjustmentTypes", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"key"}
              options={salesAdjustmentTypeList}
              disabled={localLoading || loading}
              blnValueWithNewSpace={true}
              initialValue={selectedSalesAdjustmentTypeOptions}
              validationRequired={false}
              validation={validation}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange} />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
              name="salesOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "ReferenceNo" })}
              name="referenceNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterReferenceNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
            <Loading/>
            :
            <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={SalesAdjustmentColumns}
            data={salesAdjustmentList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesAdjustment" }) })}
            objSorted={defaultSorted}
            branchId={selectedBranchId}
            salesAdjustmentTypes={validation.values.salesAdjustmentTypes}
            salesOrderNo={validation.values.salesOrderNo}
            referenceNo={validation.values.referenceNo}
            dateRange={selectedDateRange}
            requestAPI={getSalesAdjustment} />
        }

        <DeleteModal
          displayName={selectedRow?.salesAdjustmentNo}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteSalesAdjustment(selectedRow?.id || "", selectedRow?.salesAdjustmentNo || "");
            let currentPageTemp = currentPage;
            if (salesAdjustmentList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getSalesAdjustment({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
      {
        customerRefundDetailModal &&
        <CustomerRefundDetailModal
          blnShow={customerRefundDetailModal}
          setModal={setCustomerRefundDetailModal}
          selectedCustomerRefundDetail={selectedCustomerRefundDetail}
          setSelectedCustomerRefundDetail={setSelectedCustomerRefundDetail}
        />
      }
      {
        salesAdjustmentDetailModal &&
        <SalesAdjustmentDetailModal
          blnShow={salesAdjustmentDetailModal}
          setModal={setSalesAdjustmentDetailModal}
          branchDropDownList={branchDropDownList}
          addSalesAdjustment={addSalesAdjustment}
          updateSalesAdjustment={updateSalesAdjustment}
          blnAction={blnAction}
          selectedSalesAdjustmentDetail={selectedSalesAdjustmentDetail}
          createSalesAdjustmentType={createSalesAdjustmentType}
          setSelectedSalesAdjustmentDetail={setSelectedSalesAdjustmentDetail}
          blnAllowCrossMonthSales={generalSettings?.isAllowCrossMonthSales || false} />
      }
      {
        creditAdjustmentModal &&
        <CreditAdjustmentModal
          blnShow={creditAdjustmentModal}
          setModal={setCreditAdjustmentModal}
          selectedCreditAdjustmentDetail={creditAdjustmentDetail}
          blnView={true}
          setErrorMessage={setErrorMessage} />
      }
      {
        paymentAdjustmentDetailModal &&
        <PaymentAdjustmentDetailModal
          blnShow={paymentAdjustmentDetailModal}
          setModal={setPaymentAdjustmentDetailModal}
          selectedCustomerPaymentAdjustmentDetail={selectedCustomerPaymentAdjustmentDetail}
          setSelectedCustomerPaymentAdjustmentDetail={setSelectedCustomerPaymentAdjustmentDetail}
        />
      }
    </div>
  );
}

export default observer(SalesAdjustment);
