import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import GeneralInput from "../../app/components/form/GeneralInput";
import { AddOptionsValueField, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnAdvancedSearchIcon, returnBlobImage, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { GeneralAttachmentListInterface, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import moment from "moment";
import OldPaymentDetailsModal from "./Modal/OldPaymentDetailsModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import NewPaymentModal from "./Modal/NewPaymentModal";
import { ChangePaymentModePaymentMethodListObject, IssuerBankListObject, OldAndNewPaymentDetailListObject, PaymentMethodOptionListObject } from "../../app/models/changePaymentMode";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import PaymentAdditionalInfoModal from "./Modal/PaymentAdditionalInfoModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";

const ChangePaymentModeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { changePaymentModeStore, branchStore, staticReferenceStore, settingsStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const {
    errorMessage,
    successMessage,
    loading,
    windowSize,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { addChangePaymentMode, updateChangePaymentMode, changePaymentModeDetail, setChangePaymentModeDetail, getChangePaymentModeWithId, getChangePaymentModePaymentMethod } = changePaymentModeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ChangePaymentMode" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "ChangePaymentMode" }), urlPath: RoutesList.changePaymentMode }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateChangePaymentMode =  checkPermission([PermissionConstants.UpdateChangePaymentMode]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [oldPaymentDetailsModal, setOldPaymentDetailsModal] = useState(false);
  const [newPaymentModal, setNewPaymentModal] = useState(false);
  const [paymentAdditionalInfoModal, setPaymentAdditionalInfoModal] = useState(false);
  const [changePaymentModeAcknowledgementModal, setChangePaymentModeAcknowledgementModal] = useState(false);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = useState<OldAndNewPaymentDetailListObject | undefined>(undefined);
  const [selectedOldPaymentIndex, setSelectedOldPaymentIndex] = useState<number | undefined>(undefined);
  const [triggerDeleteOldPayment, setTriggerDeleteOldPayment] = useState<number>(-1);
  const [selectedNewPaymentIndex, setSelectedNewPaymentIndex] = useState<number | undefined>(undefined);
  const [triggerDeleteNewPayment, setTriggerDeleteNewPayment] = useState<number>(-1);
  const [branchPaymentMethodList, setBranchPaymentMethodList] = useState<ChangePaymentModePaymentMethodListObject[]>([]);
  const [changePaymentModeId, setChangePaymentModeId] = useState("");
  const [blnSuccess, setBlnSuccess] = useState(false);

  const OldPaymentDetailsColumn = [
    {
      dataField: "transactionDate",
      text: intl.formatMessage({ id: "TransactionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.transactionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.transactionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <>
          {
            blnPermissionManageCustomerSalesOrder
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
          }
        </>
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMode" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <p className={`margin-bottom-0`}>{`${row.paymentMethodName}${row.cardType ? ` (${row.cardType})` : ""}`}</p>
          <p className="payment-method-sub-text">{`${row?.paymentMethodOptionName ? `${row.paymentMethodOptionName}` : ""}${row.paymentIssuerBankName ? ` - ${row.paymentIssuerBankName}` : ""}`}</p>
        </>
      )
    },
    // {
    //   dataField: "transactionNo",
    //   text: `${intl.formatMessage({ id: "TransactionNo" })}/${intl.formatMessage({ id: "ApprovalCode" })}`.toUpperCase(),
    //   formatter: (cellContent, row) => (
    //     <div>
    //       {row.transactionNo || Constants.emptyData}
    //     </div>
    //   )
    // },
    {
      dataField: "bankName",
      text: `${intl.formatMessage({ id: "BankName"})}`.toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.bankName || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "cardOrAccountNo",
      text: `${intl.formatMessage({ id: "CardOrAccountNo"})}`.toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.cardOrAccountNo || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.amount)}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: "center"},
      formatter: (cellContent, row, index) => (
        <div className="d-flex gap-3 flex-justfity-content-center">
          <TableActionButton
            onClick={()=> {
              setSelectedPaymentDetail(row);
              setPaymentAdditionalInfoModal(true)
            }}
            type={'edit'} />
          <TableActionButton
            type={'delete'}
            onClick={()=> { 
              setSelectedOldPaymentIndex(index)
              setTriggerDeleteOldPayment((triggerDeleteOldPayment)=> (triggerDeleteOldPayment+1))
            }} />
        </div>
      ),
    }
  ];

  const NewPaymentDetailsColumn = [
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMode" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <p className={`margin-bottom-0`}>{`${row.paymentMethodName}${row.cardType ? ` (${row.cardType})` : ""}`}</p>
          <p className="payment-method-sub-text">{`${row?.paymentMethodOptionName ? `${row.paymentMethodOptionName}` : ""}${row.paymentIssuerBankName ? ` - ${row.paymentIssuerBankName}` : ""}`}</p>
        </>
      )
    },
    {
      dataField: "bankName",
      text: `${intl.formatMessage({ id: "BankName"})}`.toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.bankName || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "cardOrAccountNo",
      text: `${intl.formatMessage({ id: "CardOrAccountNo"})}`.toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.cardOrAccountNo || Constants.emptyData}
        </div>
      )
    },
    // {
    //   dataField: "transactionNo",
    //   text: `${intl.formatMessage({ id: "TransactionNo" })}/${intl.formatMessage({ id: "ApprovalCode" })}`.toUpperCase(),
    //   formatter: (cellContent, row) => (
    //     <div>
    //       {row.transactionNo || Constants.emptyData}
    //     </div>
    //   )
    // },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.amount)}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: "center"},
      formatter: (cellContent, row, index) => (
        <div className="d-flex gap-3 flex-justfity-content-center">
          <TableActionButton
            onClick={()=> {
              setSelectedNewPaymentIndex(index+1);
              setNewPaymentModal(true);
            }}
            type={'edit'} />
          <TableActionButton
            type={'delete'}
            onClick={()=> { 
              setSelectedNewPaymentIndex(index)
              setTriggerDeleteNewPayment((triggerDeleteNewPayment)=> (triggerDeleteNewPayment+1))
            }} />
        </div>
      ),
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: changePaymentModeDetail || {
      changePaymentModeNo: "",
      branchId: getBranchUser() ? getBranchId() : "",
      branchName: getBranchUser() ? getBranchName() : "",
      customerId: "",
      customerName: "",
      customerIdentityNo: "",
      customerContactNo: "",
      status: "",
      preparedBy: "",
      preparedOn: "",
      remark: "",
      customerSignature: "",
      firstApproverSignature: "",
      reviewedBy: "",
      reviewedOn: "",
      actionedBy: "",
      actionedOn: "",
      supportingDocumentFileIds: [],
      supportingDocumentAttachments: [],
      oldPaymentDetails: [],
      newPaymentDetails: [],
      financeTransactionFileIds: [],
      financeTransactionAttachments: [],
      aryAttachments: [],
      financeRemark: "",
      transactionDate: ""
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
      customerId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })),   
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (valuesTemp.workflowAction === Constants.submit) {
        let blnErrorFound = false;
        if (valuesTemp.aryAttachments.length === 0) {
          validation.setFieldError("aryAttachments", intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "SupportingDocuments" })}))
          blnErrorFound = true;
        }
        if (!valuesTemp.transactionDate) {
          validation.setFieldError("transactionDate", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SubmissionDate" }) }))
          blnErrorFound = true;
        }
        if (valuesTemp.oldPaymentDetails.length === 0) {
          validation.setFieldError("oldPaymentDetails", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "1stPaymentDetails" }) }))
          blnErrorFound = true;
        }
        if (valuesTemp.newPaymentDetails.length === 0) {
          validation.setFieldError("newPaymentDetails", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "2ndPaymentDetails" }) }))
          blnErrorFound = true;
        }
        if (blnErrorFound) {
          return;
        }
      }

      let supportingDocumentsTemp: any = [];
      let supportingDocumentFileIdsTemp: any = [];
      await Promise.all(valuesTemp.aryAttachments.map(async (valueAttachmentTemp) => {
        if (valueAttachmentTemp.preview !== "") {
          let fileTemp: any = await returnBlobImage(valueAttachmentTemp.preview);
          let fileWithFileName = new File([fileTemp], valueAttachmentTemp.path);
          supportingDocumentsTemp.push(fileWithFileName);
        }
        if (valueAttachmentTemp.id) {
          supportingDocumentFileIdsTemp.push(valueAttachmentTemp.id);
        }
      }))
      delete valuesTemp["aryAttachments"];
      valuesTemp.supportingDocuments = supportingDocumentsTemp;
      valuesTemp.supportingDocumentFileIds = supportingDocumentFileIdsTemp;
      
      valuesTemp.newPaymentDetails = valuesTemp.newPaymentDetails.map((valueNewPaymentDetail)=> ({
        ...valueNewPaymentDetail,
        transactionDate: valuesTemp.transactionDate
      }))
      delete valuesTemp["transactionDate"];

      try {
        let resultAddOrUpdateAPI: any = undefined;
        if (addAction) {
          resultAddOrUpdateAPI = await addChangePaymentMode(valuesTemp);
        } else {
          resultAddOrUpdateAPI = await updateChangePaymentMode(valuesTemp);
        }
        if (resultAddOrUpdateAPI) {
          if (resultAddOrUpdateAPI.status) {
            if (resultAddOrUpdateAPI.status === Constants.success) {
              if (resultAddOrUpdateAPI.data) {
                if (resultAddOrUpdateAPI.data.isSignatureRequired) {
                  setChangePaymentModeId(resultAddOrUpdateAPI.data.id);
                  setChangePaymentModeAcknowledgementModal(true);
                  return;
                }
              }

              setBlnSuccess(true);
              setTimeout(()=> {
                history.push(`/${RoutesList.changePaymentMode}`)
              }, Constants.timeoutNavigation)
            }
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || blnSuccess;
  const disabledCustomerField = disabledFieldInput || viewAction || !validation.values.customerId;

  useEffect(() => {
    async function fetchChangePaymentModeDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.cardType),
        getGeneralSettings()
      ]
      if (id && !addAction) {
        aryAPI.push(getChangePaymentModeWithId(id));
      }
      
      let resultAPI = await Promise.all(aryAPI);
            
      if (resultAPI.length > 3) {
        if (resultAPI[3]) {
          if (resultAPI[3].status !== Constants.draft && resultAPI[3].status !== Constants.reviseRequired && resultAPI[3].status !== Constants.pendingSign) {
            history.push(`/${RoutesList.notFound}`)
            setLoading(false);
            return;
          }
          if (resultAPI[3].supportingDocumentAttachments) {
            let aryAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
            resultAPI[3].supportingDocumentAttachments.map((valueSupportingDocumentAttachmentTemp)=> {
              aryAttachmentsTemp.push({
                id: valueSupportingDocumentAttachmentTemp.attachmentFileId,
                preview: "",
                path: valueSupportingDocumentAttachmentTemp.attachmentFileName,
                webUrl: valueSupportingDocumentAttachmentTemp.attachmentWebUrl,
              })
            })
            validation.setFieldValue("aryAttachments", aryAttachmentsTemp);
          }
          else {
            validation.setFieldValue("aryAttachments", []);
          }
          let resultChangePaymentModePaymentMethodTemp = await getChangePaymentModePaymentMethod(resultAPI[3].branchId);
          setBranchPaymentMethodList(resultChangePaymentModePaymentMethodTemp ? resultChangePaymentModePaymentMethodTemp : [])
          if (resultChangePaymentModePaymentMethodTemp) {
            let newPaymentDetailsTemp = resultAPI[3].newPaymentDetails.map((valueNewPaymentDetailTemp)=> {
              let indexPaymentMethodTemp = _.findIndex(resultChangePaymentModePaymentMethodTemp, {id: valueNewPaymentDetailTemp.paymentMethodId})
              let blnCardInputTemp = false;
              let blnQRPayTemp = false;
              let optionTypeTitleTemp = ""
              let optionTypeListTemp : PaymentMethodOptionListObject[] = [];
              let blnEPPTemp = false;
              let issuerBanksTemp: IssuerBankListObject[] = [];

              if (indexPaymentMethodTemp > -1) {
                let paymentMethodDetailFoundTemp : ChangePaymentModePaymentMethodListObject | undefined = resultChangePaymentModePaymentMethodTemp ? resultChangePaymentModePaymentMethodTemp[indexPaymentMethodTemp] : undefined;
                if (paymentMethodDetailFoundTemp) {
                  blnCardInputTemp = paymentMethodDetailFoundTemp.isCardInput;
                  blnQRPayTemp = paymentMethodDetailFoundTemp.isQRPay;
                  if (paymentMethodDetailFoundTemp.isOptionAvailable && paymentMethodDetailFoundTemp.paymentMethodOptionType !== Constants.none) {
                    optionTypeTitleTemp = newSpaceBeforeCapitalLetter(paymentMethodDetailFoundTemp.paymentMethodOptionType)
                    optionTypeListTemp = AddOptionsValueField(paymentMethodDetailFoundTemp.paymentMethodOptions, "name", "id")

                    let indexPaymentMethodOptionIdTemp = _.findIndex(optionTypeListTemp, {id : valueNewPaymentDetailTemp.paymentMethodOptionId})
                    if (indexPaymentMethodOptionIdTemp > -1) {
                      blnEPPTemp = optionTypeListTemp[indexPaymentMethodOptionIdTemp].isEPP;
                      issuerBanksTemp = optionTypeListTemp[indexPaymentMethodOptionIdTemp].issuerBanks;
                    }
                  }
                }
              }

              return ({
                ...valueNewPaymentDetailTemp,
                optionTypeTitle: optionTypeTitleTemp,
                optionTypeList: optionTypeListTemp,
                isCardInput: blnCardInputTemp,
                isQRPay: blnQRPayTemp,
                issuerBanks: issuerBanksTemp,
                isEPP: blnEPPTemp
              })
            })
            validation.setFieldValue("newPaymentDetails", newPaymentDetailsTemp);
          }
        }
      }
      else if (addAction && getBranchUser()) {
        let resultChangePaymentModePaymentMethodTemp = await getChangePaymentModePaymentMethod(getBranchId());
        setBranchPaymentMethodList(resultChangePaymentModePaymentMethodTemp ? resultChangePaymentModePaymentMethodTemp : [])
      }
      setLoading(false);
    }

    if (addAction) {
      setChangePaymentModeDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateChangePaymentMode], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateChangePaymentMode], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageChangePaymentMode], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateChangePaymentMode){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.changePaymentMode}/edit/${id}`)} })
    }

    fetchChangePaymentModeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setChangePaymentModeDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(()=> {
    if (selectedNewPaymentIndex !== undefined) {
      if (triggerDeleteNewPayment > -1 && selectedNewPaymentIndex > -1) {
        let newPaymentsTemp = _.cloneDeep(validation.values.newPaymentDetails);
        newPaymentsTemp.splice(selectedNewPaymentIndex, 1);
        validation.setFieldValue("newPaymentDetails", newPaymentsTemp);
      }
    }
  }, [triggerDeleteNewPayment])

  useEffect(()=> {
    if (selectedOldPaymentIndex !== undefined) {
      if (triggerDeleteOldPayment > -1 && selectedOldPaymentIndex > -1) {
        let oldPaymentsTemp = _.cloneDeep(validation.values.oldPaymentDetails);
        oldPaymentsTemp.splice(selectedOldPaymentIndex, 1);
        validation.setFieldValue("oldPaymentDetails", oldPaymentsTemp);
      }
    }
  }, [triggerDeleteOldPayment])

  const onClickSelect =  async (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      customerIdentityNo: row.identityNo,
      customerContactNo: row.mobileNo
    }))
    setDisplayCustomerDropdown(false);

    setTimeout(()=> {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onChangeBranch = async (valueBranchId: string) => {
    validation.setFieldValue("customerId", "");
    validation.setFieldValue("customerName", "");
    validation.setFieldValue("customerIdentityNo", "");
    validation.setFieldValue("customerContactNo", "");
    validation.setFieldValue("oldPaymentDetails", []);

    let resultChangePaymentModePaymentMethod = await getChangePaymentModePaymentMethod(valueBranchId);
    setBranchPaymentMethodList(resultChangePaymentModePaymentMethod ? resultChangePaymentModePaymentMethod : [])
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"branchId"}
                    className="mb-3 mt-4"
                    title={intl.formatMessage({ id: "Branch"})}
                    specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={branchDropDownList}
                    disabled={disabledFieldInput || viewAction || getBranchUser()}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    onChangeFunction={onChangeBranch}
                    validation={validation}
                    validationRequired={true}/>
                </SingleColumnRowParent>
                {
                  displayCustomerDropdown
                  &&
                  <>
                    <SingleColumnRowParent>
                      <Row>
                        <Col xl='11' md='11' xs='10'>
                          <GeneralInput
                            title={intl.formatMessage({ id: "Customer" })}
                            name="customerName"
                            type="text"
                            disabled={disabledCustomerField}
                            validationRequired={true}
                            validation={validation}
                          />
                        </Col>
                        <Col xl='1' md='1' xs='2'>
                          <MyButton
                            type="button"
                            class={`btn btn-primary margin-top-28`}
                            content={""}
                            subContent={returnAdvancedSearchIcon()}
                            disable={disabledFieldInput || !validation.values.branchId}
                            onClick={() => { setCustomerSearchModal(true) }}
                          />
                        </Col>
                      </Row>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "IdentityNo" })}
                        name="customerIdentityNo"
                        type="text"
                        disabled={true}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "MobileNo" })}
                        name="customerContactNo"
                        type="text"
                        disabled={true}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                  </>
                }
                {/* {displayBreakTitle(intl.formatMessage({ id: "1stPaymentDetails" }))} */}
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "1stPaymentDetails" })}
                  className="fw-bold"
                  titleSize="16px"
                  paddingBottom="12px"
                />
                <MyButton
                  type="button"
                  class={`btn btn-success`}
                  style={{ padding: "8px", paddingBottom: "4px", paddingTop: "4px"}}
                  content={intl.formatMessage({ id: "AddPayment" })}
                  disable={disabledFieldInput || !validation.values.customerId}
                  onClick={()=> {
                    setOldPaymentDetailsModal(true);
                  }}
                />
                <div className="margin-top-16">
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "1stPaymentDetails" })}
                    headerClassName={"table-light"}
                    options={validation.values.oldPaymentDetails}
                    columns={OldPaymentDetailsColumn}
                    pageSize={Constants.defaultPageSize}
                    blnHideTotal={true}
                    keyField={"paymentHistoryId"}/>
                </div>
                {/* {displayBreakTitle(intl.formatMessage({ id: "2ndPaymentDetails" }))} */}
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "2ndPaymentDetails" })}
                  className={`fw-bold ${validation.values.oldPaymentDetails.length > 0 ? "mt-2" : "margin-top-36"}`}
                  blnSkipMarginTop={true}
                  titleSize="16px"
                  paddingBottom="12px"
                />
                <div className="margin-top-16">
                  <Row>
                    <DoubleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "TransactionDate"})}
                        name="transactionDate"
                        type="date"
                        validationRequired={true}
                        disabled={disabledFieldInput}
                        maxCurrentDate={true}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent>
                      <MyButton
                        type="button"
                        class={`btn btn-success ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                        style={{ padding: "15px", paddingBottom: "7.5px", paddingTop: "7.5px" }}
                        content={intl.formatMessage({ id: "AddPayment" })}
                        disable={disabledFieldInput || !validation.values.customerId}
                        onClick={()=> {
                          setSelectedNewPaymentIndex(undefined);
                          setNewPaymentModal(true)
                        }}
                      />
                    </DoubleColumnRowParent>
                  </Row>
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "2ndPaymentDetails" })}
                    headerClassName={"table-light"}
                    options={validation.values.newPaymentDetails}
                    columns={NewPaymentDetailsColumn}
                    pageSize={Constants.defaultPageSize}
                    blnHideTotal={true}
                    keyField={"randomId"}/>
                </div>
                {/* {displayBreakTitle(intl.formatMessage({ id: "AdditionalInformation" }))} */}
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "AdditionalInformation" })}
                  className={`fw-bold ${validation.values.newPaymentDetails.length > 0 ? "mt-2" : "margin-top-36"}`}
                  blnSkipMarginTop={true}
                  titleSize="16px"
                  paddingBottom="12px"
                />
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Remark" })}
                    name="remark"
                    row={5}
                    disabled={disabledFieldInput}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralAttachment
                    title={intl.formatMessage({ id: "SupportingDocuments" })}
                    name="aryAttachments"
                    validation={validation}
                    disabled={disabledFieldInput || viewAction}
                    blnMultipleFile={true}
                    validationRequired={true}/>
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              className="mt-2"
              successMessage={blnSuccess ? "1" : ""}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={!validation.values.status || validation.values.status === Constants.draft}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", Constants.save)
              }}
              hidePrimaryButton={false}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", Constants.submit)
              }}
              extraLeftDirtyDisabled={true}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          blnHideOtherBranch={false}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect}
          branchName={validation.values.branchName}
          branchId={validation.values.branchId} />
      }
      {
        oldPaymentDetailsModal
        &&
        <OldPaymentDetailsModal
          blnShow={oldPaymentDetailsModal}
          setModal={setOldPaymentDetailsModal}
          customerId={validation.values.customerId}
          setPaymentAdditionalInfoModal={setPaymentAdditionalInfoModal}
          setSelectedPaymentDetail={setSelectedPaymentDetail}
          isEnableAdminChangePaymentMode={generalSettings?.isEnableAdminChangePaymentMode}
          validation={validation}/>
      }
      {
        newPaymentModal &&
        <NewPaymentModal
          blnShow={newPaymentModal}
          setModal={setNewPaymentModal}
          cardType={cardType}
          branchPaymentMethod={branchPaymentMethodList}
          selectedNewPaymentIndex={selectedNewPaymentIndex}
          setSelectedNewPaymentIndex={setSelectedNewPaymentIndex}
          validation={validation}/>
      }
      {
        paymentAdditionalInfoModal
        &&
        <PaymentAdditionalInfoModal 
          blnShow={paymentAdditionalInfoModal}
          setModal={setPaymentAdditionalInfoModal}
          setOldPaymentDetailsModal={setOldPaymentDetailsModal}
          paymentDetail={selectedPaymentDetail}
          validation={validation}/>
      }
      {
        changePaymentModeAcknowledgementModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "ChangePaymentModeAcknowledgementMessage" })}`}
          primaryActionTitle={`Yes`}
          showPrompt={changePaymentModeAcknowledgementModal}
          setShowPrompt={setChangePaymentModeAcknowledgementModal}
          onPrimaryClick={()=> {
            history.push({
              pathname: `/${RoutesList.changePaymentMode}/view/${changePaymentModeId}`
            }); 
          }}
          onCancelClick={()=> {
            history.push({
              pathname: `/${RoutesList.changePaymentMode}`
            }); 
          }}
        />
      }
    </div>
  );
};

export default observer(ChangePaymentModeDetail);
