import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { StockForecastSummaryListObject, StockForecastSummaryListGetObject, StockCardDetailsListObject, StockLevelDetailsListObject, StockLevelExportObject, StockForecastSummaryExportObject, StockCardExportObject, StockReceiveVariantListObject, StockReceiveVariantExportObject, ReservedProductSummaryObject, ReservedProductSummaryExportObject, StockLevelHistoryListObject, StockLevelHistoryExportObject, ReservedProductReversalSummaryListObject, ReservedProductReversalSummaryExportObject } from "../models/reportStock";
import { downloadExcel, newSpaceBeforeCapitalLetter } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class ReportStockStore {
  stockForecastSummaryList: StockForecastSummaryListObject | undefined = undefined;
  stockCardDetailsList: StockCardDetailsListObject[] = [];
  stockLevelDetailsList: StockLevelDetailsListObject[] = [];
  stockReceiveVariantList: StockReceiveVariantListObject[] = []; 
  reservedProductSummaryList: ReservedProductSummaryObject[] = [];
  stockLevelHistoryList: StockLevelHistoryListObject[] = [];
  reservedProductReversalSummaryList: ReservedProductReversalSummaryListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.stockForecastSummaryList = undefined;
    this.stockCardDetailsList = [];
    this.stockLevelDetailsList = [];
    this.stockReceiveVariantList = [];
    this.reservedProductSummaryList = [];
    this.stockLevelHistoryList = [];
    this.reservedProductReversalSummaryList = [];
  }

  setStockForecastSummaryList = (stockForecastSummaryListObject: StockForecastSummaryListObject | undefined) => {
    this.stockForecastSummaryList = stockForecastSummaryListObject;
  }

  setStockCardDetailsList = (stockCardDetailsListObject: StockCardDetailsListObject[]) => {
    this.stockCardDetailsList = stockCardDetailsListObject;
  }

  setStockLevelDetailsList = (stockLevelDetailsListObject: StockLevelDetailsListObject[]) => {
    this.stockLevelDetailsList = stockLevelDetailsListObject;
  }

  setStockReceiveVariantList = (stockReceiveVariantList: StockReceiveVariantListObject[]) => {
    this.stockReceiveVariantList = stockReceiveVariantList;
  }

  setReservedProductSummaryList = (reservedProductSummaryList: ReservedProductSummaryObject[]) => {
    this.reservedProductSummaryList = reservedProductSummaryList;
  }

  setStockLevelHistoryList = (stockLevelHistoryList: StockLevelHistoryListObject[]) => {
    this.stockLevelHistoryList = stockLevelHistoryList;
  }

  setReservedProductReversalSummaryList = (reservedProductReversalSummaryList: ReservedProductReversalSummaryListObject[]) => {
    this.reservedProductReversalSummaryList = reservedProductReversalSummaryList;
  }

  getStockForecastSummaryList = async (stockForecastSummaryListGetObject: StockForecastSummaryListGetObject) => {
    try {
      let resultstockForecastSummary = await agent.ReportStock.stockForecastSummaryList(stockForecastSummaryListGetObject);

      runInAction(() => {
        this.stockForecastSummaryList = resultstockForecastSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockForecastSummaryList = undefined;
    }
  }

  getStockCardDetailsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      let resultStockCard = await agent.ReportStock.stockCardDetailsList(PaginationRequestBody);
      resultStockCard.data = resultStockCard.data.map((valueResultStockCard)=> {
        valueResultStockCard.stockCardSummaryDetails = valueResultStockCard.stockCardSummaryDetails.map((valueStockCardSummaryDetails)=> ({
          ...valueStockCardSummaryDetails,
          reference: newSpaceBeforeCapitalLetter(valueStockCardSummaryDetails.reference),
          referenceEntityId: valueStockCardSummaryDetails.stockMovementType === Constants.appolousProductRedemption || valueStockCardSummaryDetails.stockMovementType === Constants.reverseAppolousProductRedemption ? Constants.emptyId : valueStockCardSummaryDetails.referenceEntityId
        }))
        return valueResultStockCard;
      }); 
      runInAction(() => {
        this.stockCardDetailsList = resultStockCard.data;
        store.commonStore.setTotalItem(resultStockCard.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockCardDetailsList = [];
    }
  }

  getStockLevelDetailsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      let resultStockLevelDetails = await agent.ReportStock.stockLevelDetailsList(PaginationRequestBody);

      runInAction(() => {
        this.stockLevelDetailsList = resultStockLevelDetails.data;
        store.commonStore.setTotalItem(resultStockLevelDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockLevelDetailsList = [];
    }
  }

  getStockReceiveVariantList = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      let resultStockReceiveVariant = await agent.ReportStock.stockReceiveVariant(PaginationRequestBody);

      runInAction(() => {
        this.stockReceiveVariantList = resultStockReceiveVariant.data;
        store.commonStore.setTotalItem(resultStockReceiveVariant.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockReceiveVariantList = [];
    }
  }

  getReservedProductSummaryList = async (ReservedProductSummaryExportObject: ReservedProductSummaryExportObject) => {
    try {
      let resultReservedProductSummaryList = await agent.ReportStock.reservedProductSummaryList(ReservedProductSummaryExportObject);

      runInAction(() => {
        this.reservedProductSummaryList = resultReservedProductSummaryList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.reservedProductSummaryList = [];
    }
  }

  getStockLevelHistoryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      let resultStockLevelHistory = await agent.ReportStock.stockLevelHistoryList(PaginationRequestBody);

      runInAction(() => {
        this.stockLevelHistoryList = resultStockLevelHistory.data;
        store.commonStore.setTotalItem(resultStockLevelHistory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockLevelHistoryList = [];
    }
  }

  getReservedProductReversalSummaryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      let resultReservedProductReversalSummaryList = await agent.ReportStock.reversedProductReversalSummary(PaginationRequestBody);

      runInAction(() => {
        this.reservedProductReversalSummaryList = resultReservedProductReversalSummaryList.data;
        store.commonStore.setTotalItem(resultReservedProductReversalSummaryList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.reservedProductReversalSummaryList = [];
    }
  }

  exportStockLevel = async (stockLevelExportRequestBody: StockLevelExportObject) => {
    try{
      await downloadExcel(`/report/stock/stocklevel/export`, stockLevelExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportStockForecastSummary = async (stockForecastSummaryExportRequestBody: StockForecastSummaryExportObject) => {
    try{
      await downloadExcel(`/report/stock/forecastsummary/export`, stockForecastSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportStockCard = async (stockCardExportRequestBody: StockCardExportObject) => {
    try{
      await downloadExcel(`/report/stock/stockcard/export`, stockCardExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportStockReceiveVariant = async (stockReceiveVariantExportRequestBody: StockReceiveVariantExportObject) => {
    try{
      await downloadExcel(`/report/stock/stockreceivevariant/export`, stockReceiveVariantExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportReservedProductSummary = async (reservedProductSummaryExportRequestBody: ReservedProductSummaryExportObject) => {
    try{
      await downloadExcel(`/report/inventory/reservedproductsummary/export`, reservedProductSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportStockLevelHistory = async (stockLevelHistoryExportRequestBody: StockLevelHistoryExportObject) => {
    try{
      await downloadExcel(`/report/stock/stocklevelhistory/export`, stockLevelHistoryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportReservedProductReversalSummary = async (reservedProductReversalSummaryExportRequestBody: ReservedProductReversalSummaryExportObject) => {
    try{
      await downloadExcel(`/report/stock/reservedproductreversalsummary/export`, reservedProductReversalSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}