import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import classNames from "classnames";
import { checkPermission, compareArrayWithField, contructValidationErrorMessage, convertTimeToMinutes, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnCurrency, returnHours, returnMinutes } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Nav, NavItem, NavLink, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { ProductForServiceTreatmentObject, ServiceMachinesObject } from "../../app/models/serviceTreatment";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import TableWithSelectAllCheckBoxAndInput from "../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";

const ServiceTreatmentDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { serviceTreatmentStore, categoryStore, salesCategoryStore, taxStore, productStore, machineStore, groupCodeStore, settingsStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    windowSize,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addServiceTreatment, updateServiceTreatment, serviceTreatmentDetail, setServiceTreatmentDetail, getServiceTreatmentWithId, serviceTreatmentBranchAccess, getServiceTreatmentBranchAccess } = serviceTreatmentStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;
  const { salesCategoryDropdownList, getSalesCategoryDropdown } = salesCategoryStore;
  const { taxDropdownList, getTaxDropdown } = taxStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { machineDropdownList, getMachineDropdown } = machineStore;
  const { groupCodeDropdownList, getGroupCodeDropDown } = groupCodeStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const [productListForServiceTreatment, setProductListForServiceTreatment] = useState<ProductForServiceTreatmentObject[]>([]);
  const [machineListForServiceTreatment, setMachineListForServiceTreatment] = useState<ServiceMachinesObject[]>([]);
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ServiceTreatment" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "ServiceTreatment" }), urlPath: RoutesList.serviceTreatment }];
  const [activeTab, setActiveTab] = useState("1");
  const [tabBar, setTabBar] = useState<any[]>([intl.formatMessage({ id: "Product" }).toUpperCase(), intl.formatMessage({ id: "Product(Optional)" }).toUpperCase()]);
  const aryHours = returnHours();
  const aryMinutes = returnMinutes();
  const [treatmentSellingPrice, setTreatmentSellingPrice] = useState(0);
  const [productModal, setProductModal] = useState(false);
  const [optionalProductModal, setOptionalProductModal] = useState(false);
  const [machineModal, setMachineModal] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [{ label: intl.formatMessage({ id: "Name" }).toUpperCase(), type: "label", xl: "6", inputName: "productName", inputType: "text" }, { label: intl.formatMessage({ id: "ConsumeQuantity" }), type: "input", xl: "6", inputName: "consumeQuantity", inputType: "text" }];
  const aryDynamicInputAndCheckBoxTabBranch: ObjectForTableWithDynamicInputAndCheckBox[] = [{ label: intl.formatMessage({ id: "SellingPrice" }), type: "input", xl: "12", inputName: "sellingPrice", inputType: "number", inputGroup: true, inputGroupText: returnCurrency(), disabled: true }];
  const aryDynamicInputAndCheckBoxTabMachine: ObjectForTableWithDynamicInputAndCheckBox[] = [
    { 
      label: intl.formatMessage({ id: "Name" }).toUpperCase(), 
      type: "label", 
      xl: "3", 
      inputName: "machineName", 
      inputType: "text"
    }, 
    { 
      label: intl.formatMessage({ id: "ModelNumber" }).toUpperCase(), 
      type: "label", 
      xl: "3", 
      inputName: "modelNumber", 
      inputType: "text"
    }, 
    { 
      label: intl.formatMessage({ id: "ConsumptionType" }).toUpperCase(), 
      type: "label", 
      xl: "3", 
      inputName: "consumptionType", 
      inputType: "text"
    }, 
    { 
      label: intl.formatMessage({ id: "AdviseConsumeQuantityDuration" }), 
      type: "input", 
      xl: "3", 
      inputName: "advisableConsumeQuantity", 
      inputType: "number",
      disabledField: "isByDuration",
      valueOnTime: true
    }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateServiceTreatment = checkPermission([PermissionConstants.UpdateServiceTreatment]);
  const [loadingBranchTab, setLoadingBranchTab] = useState(false);
  const [triggerSellingPrice, setTriggerSellingPrice] = useState(0);
  const blnShowAppolous = generalSettings?.isShowAppolous;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: serviceTreatmentDetail || {
      name: "",
      description: "",
      sellingPrice: undefined,
      floorPrice: undefined,
      hours: undefined,
      minutes: undefined,
      isConsultationService: false,
      isActive: true,
      salesCategoryId: "",
      salesCategoryName: "",
      taxId: "",
      taxName: "",
      displayOrder: 1,
      staffCost: 0,
      staffCostAccountNo: "",
      depreciationCost: 0,
      depreciationAccountNo: "",
      commissionAccountNo: "",
      consumableAccountNo: "",
      serviceProducts: [],
      optionalServiceProducts: [],
      serviceMachines: [],
      branchServiceTreatments: serviceTreatmentBranchAccess,
      groupCodeId: "",
      groupCodeName: "",
      appolousId: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ServiceTreatmentName" }) })),
      salesCategoryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesCategory" }) })),
      taxId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Tax" }) })),
      sellingPrice: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SellingPrice" }) })),
      floorPrice: Yup.number()
      .required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FloorPrice" }) }))
      .lessThan(treatmentSellingPrice + 0.01, intl.formatMessage({ id: "LessThanTreatmentSellingPrice" }, { field: treatmentSellingPrice}).toUpperCase()),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let blnFloorPriceValid = true;
      for (let a=0; a<valuesTemp.branchServiceTreatments.length; a++) {
        if (valuesTemp.branchServiceTreatments[a].sellingPrice < valuesTemp.floorPrice && valuesTemp.branchServiceTreatments[a].isActive) {
          blnFloorPriceValid = false;
          break;
        }
      }

      if (!blnFloorPriceValid) {
        setErrorMessage(intl.formatMessage({ id: "BranchServicePriceLowerThanFloorPrice" }, { field: treatmentSellingPrice }))
        return;
      }

      valuesTemp.serviceProducts = valuesTemp.serviceProducts.map((productTemp) => ({
        ...productTemp,
        isOptional: false
      }))
      valuesTemp.optionalServiceProducts = valuesTemp.optionalServiceProducts.map((productTemp) => ({
        ...productTemp,
        isOptional: true
      }))
      valuesTemp.serviceProducts = _.concat(valuesTemp.serviceProducts, valuesTemp.optionalServiceProducts);
      delete valuesTemp.optionalServiceProducts
      try {
        if (addAction) {
          await addServiceTreatment(valuesTemp);
        } else {
          await updateServiceTreatment(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnHQStaff = !getBranchUser();
  const blnShowAppolousViewId = viewAction && validation.values.appolousId !== null;

  useEffect(() => {
    async function fetchServiceTreatmentDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getServiceTreatmentBranchAccess(),
        getProductDropdown({ productType: 0, isActive: true }, true),
        getCategoryDropdown(),
        getSalesCategoryDropdown(),
        getTaxDropdown(),
        getGeneralSettings(),
        getMachineDropdown(),
        getGroupCodeDropDown({isActive: true})
      ];

      if (id && !addAction) {
        aryAPI.push(getServiceTreatmentWithId(id))
      }
      
      let resultAPI = await Promise.all(aryAPI);
      if (addAction && resultAPI[5]) {
        validation.setFieldValue("commissionAccountNo", resultAPI[5].commissionAccountNo)
        validation.setFieldValue("consumableAccountNo", resultAPI[5].consumableAccountNo)
        validation.setFieldValue("depreciationAccountNo", resultAPI[5].depreciationAccountNo)
        validation.setFieldValue("staffCostAccountNo", resultAPI[5].staffCostAccountNo)
      }
      setLoading(false);
    }

    if (addAction) {
      setServiceTreatmentDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateServiceTreatment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateServiceTreatment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageServiceTreatment], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateServiceTreatment) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.serviceTreatment}/edit/${id}`) } })
    }

    if(!getBranchUser()){
      tabBar.push(intl.formatMessage({ id: "Branch" }).toUpperCase());
      tabBar.push(intl.formatMessage({ id: "Machine" }).toUpperCase());
    }

    fetchServiceTreatmentDetailAPI();

    return (() => {
      if (id && !addAction) {
        setServiceTreatmentDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    let aryProductTemp: ProductForServiceTreatmentObject[] = [];
    let arySelectedProduct: any = []
    if (id && !addAction) {
      arySelectedProduct = compareArrayWithField(serviceTreatmentDetail?.serviceProducts || [], productDropdownList, "productId", "id")
    }

    productDropdownList.map((value) => {
      let indexSelectedProductTemp = _.findIndex(arySelectedProduct, { productId: value.id })
      aryProductTemp.push({
        productId: value.id,
        productName: value.name,
        consumeQuantity: 1,
        isOptional: true,
        disabled: indexSelectedProductTemp >= 0 ? true : false,
        measurementName: value.measurementName,
        measurementQuantity: value.measurementQuantity
      })
    })  

    setProductListForServiceTreatment(aryProductTemp);
  }, [productDropdownList, serviceTreatmentDetail])

  useEffect(() => {
    let aryMachineTemp: ServiceMachinesObject[] = [];
    let arySelectedMachine: any = []
    if (id && !addAction) {
      arySelectedMachine = compareArrayWithField(serviceTreatmentDetail?.serviceMachines || [], machineDropdownList, "machineId", "id")
    }

    machineDropdownList.map((value) => {
      let indexSelectedProductTemp = _.findIndex(arySelectedMachine, { machineId: value.id })
      aryMachineTemp.push({
        machineId: value.id,
        machineNameDisplay: `${value.name} (${value.modelNumber})`,
        machineName: value.name,
        modelNumber: value.modelNumber,
        consumptionType: newSpaceBeforeCapitalLetter(value.consumptionType),
        advisableConsumeQuantity: value.consumptionType === Constants.byDuration ? convertTimeToMinutes(serviceTreatmentDetail?.hours, serviceTreatmentDetail?.minutes) : 1,
        disabled: indexSelectedProductTemp >= 0 ? true : false,
        isByDuration: value.consumptionType === Constants.byDuration
      })
    })  

    setMachineListForServiceTreatment(aryMachineTemp);
  }, [machineDropdownList, serviceTreatmentDetail])

  useEffect(() => {
    if (serviceTreatmentDetail) {
      let productsTemp = _.filter(serviceTreatmentDetail?.serviceProducts, { isOptional: false });
      let optionalProductstemp = _.filter(serviceTreatmentDetail?.serviceProducts, { isOptional: true });
      validation.setFieldValue("serviceProducts", productsTemp)
      validation.setFieldValue("optionalServiceProducts", optionalProductstemp)
      setTreatmentSellingPrice(serviceTreatmentDetail.sellingPrice)
    }
  }, [serviceTreatmentDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors]);

  useEffect(()=> {
    if (triggerSellingPrice > 0) {
      if (validation.values.sellingPrice !== undefined) {
        setLoadingBranchTab(true);
        let branchServiceTreatmentsTemp = _.cloneDeep(validation.values.branchServiceTreatments);
        branchServiceTreatmentsTemp = branchServiceTreatmentsTemp.map((valueBranchServiceTreatmentsTemp)=> ({
          ...valueBranchServiceTreatmentsTemp,
          sellingPrice: Number(validation.values.sellingPrice)
        }))
        validation.setFieldValue("branchServiceTreatments", branchServiceTreatmentsTemp)
        setTreatmentSellingPrice(validation.values.sellingPrice);
        setTimeout(()=> {
          setLoadingBranchTab(false);
        }, 100)
      }
    }
  }, [triggerSellingPrice])

  useEffect(()=> {
    let finalDurationTemp = convertTimeToMinutes(validation.values.hours, validation.values.minutes);
    let serviceMachinesTemp = _.cloneDeep(validation.values.serviceMachines)
    serviceMachinesTemp = serviceMachinesTemp.map((valueServiceMachineTemp)=> ({
      ...valueServiceMachineTemp,
      advisableConsumeQuantity: valueServiceMachineTemp.isByDuration ? finalDurationTemp : valueServiceMachineTemp.advisableConsumeQuantity
    }))
    validation.setFieldValue("serviceMachines", serviceMachinesTemp)

    let machineListForServiceTreatmentTemp = _.cloneDeep(machineListForServiceTreatment)
    machineListForServiceTreatmentTemp = machineListForServiceTreatmentTemp.map((valueMachineForServiceTreatmentTemp)=> ({
      ...valueMachineForServiceTreatmentTemp,
      advisableConsumeQuantity: valueMachineForServiceTreatmentTemp.isByDuration ? finalDurationTemp : valueMachineForServiceTreatmentTemp.advisableConsumeQuantity
    }))
    setMachineListForServiceTreatment(machineListForServiceTreatmentTemp);
  }, [validation.values.hours, validation.values.minutes])

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
                  h4Title />                
                {
                  blnShowAppolous
                  &&
                  <SingleColumnRowParent blnDoubleTab={true}>
                    {
                      blnShowAppolousViewId
                      &&
                      <GeneralInputWithClickableUI
                        title={intl.formatMessage({ id: "ViewAppolous" })}
                        name="appolousId"
                        className="mt-4"
                        type="number"
                        disabled={true}
                        validation={validation}
                        onClickFunction={()=> {
                          window.open(`https://app.appolous.com/services/${validation.values.appolousId}/edit`)
                        }}/>
                    }
                    {
                      !viewAction
                      &&
                      <GeneralInput
                        title={intl.formatMessage({ id: "AppolousId" })}
                        name="appolousId"
                        type="number"
                        className={`mt-4`}
                        blnNumberOnly={true}
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={false}
                        validation={validation} />
                    }
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "ServiceTreatmentName" })}
                    name="name"
                    className={`mb-3 ${viewAction && validation.values.appolousId === null ? "mt-4" : "mt-3"}`}
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SellingPrice" })}
                    name="sellingPrice"
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    disabled={disabledFieldInput || viewAction}
                    onChangeFunction={()=> {
                      setTriggerSellingPrice((valueTriggerSellingPrice)=> valueTriggerSellingPrice+1);
                    }}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "FloorPrice" })}
                    name="floorPrice"
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"hours"}
                      className={`${windowSize.innerWidth > 751 ? "" : "margin-bottom--16"}`}
                      title={intl.formatMessage({ id: "Duration" })}
                      specifyReturnFieldName={[{ "field": "hours", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      placeholder={"- Hours -"}
                      options={aryHours}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={!addAction ? `${validation.values.hours} hrs` : undefined}
                      initialValue={!addAction ? validation.values.hours : undefined}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"minutes"}
                      title={""}
                      // className={`${windowSize.innerWidth>751 ? "mb-3 mt-4" : ""}`}
                      specifyReturnFieldName={[{ "field": "minutes", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      placeholder={"- Minutes -"}
                      options={aryMinutes}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={!addAction ? `${validation.values.minutes} mins` : undefined}
                      initialValue={!addAction ? validation.values.minutes : undefined}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"salesCategoryId"}
                    title={intl.formatMessage({ id: "SalesCategory" })}
                    specifyReturnFieldName={[{ "field": "salesCategoryId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={salesCategoryDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.salesCategoryName : undefined}
                    initialValue={!addAction ? validation.values.salesCategoryId : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                   <DropDownWithTitle
                    name={"groupCodeId"}
                    title={intl.formatMessage({ id: "GroupCode" })}
                    specifyReturnFieldName={[{ "field": "groupCodeId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={groupCodeDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.groupCodeName : undefined}
                    initialValue={!addAction ? validation.values.groupCodeId : undefined}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"taxId"}
                    title={intl.formatMessage({ id: "Tax" })}
                    specifyReturnFieldName={[{ "field": "taxId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={taxDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={!addAction ? validation.values.taxId : undefined}
                    initialLabel={!addAction ? validation.values.taxName : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "ConsultationService" })}
                    name="isConsultationService"
                    type="checkbox"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    childrenUI={<Label className="margin-bottom-0 margin-left-4">Yes</Label>} />
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    childrenUI={<Label className="margin-bottom-0 margin-left-4">Yes</Label>} />
                </SingleColumnRowParent>
                {
                  blnHQStaff &&
                  <>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Additional" }) })}
                      className="mt-4"
                      h4Title />
                    <Row>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "StaffCost" })}
                          name="staffCost"
                          type="number"
                          inputGroup={true}
                          inputGroupText={returnCurrency()}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "DepreciationCost" })}
                          name="depreciationCost"
                          type="number"
                          inputGroup={true}
                          inputGroupText={returnCurrency()}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                    </Row>
                    <Row>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "StaffCostAccountNo" })}
                          name="staffCostAccountNo"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "DepreciationAccountNo" })}
                          name="depreciationAccountNo"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                    </Row>
                    <Row>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "CommissionAccountNo" })}
                          name="commissionAccountNo"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "ConsumableAccountNo" })}
                          name="consumableAccountNo"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </DoubleColumnRowParent>
                    </Row>
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <GeneralTextArea
                        title={intl.formatMessage({ id: "Description" })}
                        name="description"
                        row={5}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation} />
                    </SingleColumnRowParent>
                  </>
                }
              </Col>
              <Col xl={7}>
                <Nav tabs className="">
                  {tabBar.map((value, indexTab) => (
                    <NavItem
                      key={`tabkey_${indexTab}`}
                    >
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={classNames({
                          active:
                            activeTab ===
                            String(
                              indexTab + 1
                            ),
                        })}
                        onClick={() => {
                          toggle(
                            String(
                              indexTab + 1
                            )
                          );
                        }}
                      >
                        {value}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                {activeTab === "1" && (
                  <TableWithEditableInputFields
                    name="serviceProducts"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                    blnEditable={!viewAction}
                    blnSupportWithModal={true}
                    blnBtnModalBottom={true}
                    options={productListForServiceTreatment}
                    optionsId={"productId"}
                    setOptionsFunction={setProductListForServiceTreatment}
                    addButtonFunction={() => setProductModal(true)}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validation.values.serviceProducts}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
                {activeTab === "2" && (
                  <TableWithEditableInputFields
                    name="optionalServiceProducts"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                    blnEditable={!viewAction}
                    blnSupportWithModal={true}
                    blnBtnModalBottom={true}
                    options={productListForServiceTreatment}
                    optionsId={"productId"}
                    setOptionsFunction={setProductListForServiceTreatment}
                    addButtonFunction={() => setOptionalProductModal(true)}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validation.values.optionalServiceProducts}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
                {activeTab === "3" && 
                  !loadingBranchTab && (
                  <TableWithSelectAllCheckBoxAndInput
                    name="branchServiceTreatments"
                    className="tab_bar_border padding-top-0"
                    title=""
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabBranch}
                    data={validation.values.branchServiceTreatments}
                    validation={validation}
                    selectAllTextFieldDisplay={"branchName"}
                    selectAllTextFieldDisplayXLSize={6}
                    disabled={disabledFieldInput} />
                )}
                {activeTab === "4" && 
                  !loadingBranchTab && (
                  <TableWithEditableInputFields
                    name="serviceMachines"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Machine" }) })}
                    blnEditable={!viewAction}
                    blnSupportWithModal={true}
                    blnBtnModalBottom={true}
                    options={machineListForServiceTreatment}
                    optionsId={"machineId"}
                    setOptionsFunction={setMachineListForServiceTreatment}
                    addButtonFunction={() => setMachineModal(true)}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabMachine}
                    data={validation.values.serviceMachines}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={productListForServiceTreatment}
          setOptionsFunction={setProductListForServiceTreatment}
          fieldInput={validation.values.serviceProducts}
          fieldName={"serviceProducts"}
          validation={validation} />
        <SelectDropDownModal
          blnShow={optionalProductModal}
          setModal={setOptionalProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "OptionalProduct" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={productListForServiceTreatment}
          setOptionsFunction={setProductListForServiceTreatment}
          fieldInput={validation.values.optionalServiceProducts}
          fieldName={"optionalServiceProducts"}
          validation={validation} />
        <SelectDropDownModal
          blnShow={machineModal}
          setModal={setMachineModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Machine" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Machine" }) })}
          placeholder={"- Please Select -"}
          labelField={"machineNameDisplay"}
          valueField={"machineId"}
          options={machineListForServiceTreatment}
          setOptionsFunction={setMachineListForServiceTreatment}
          fieldInput={validation.values.serviceMachines}
          fieldName={"serviceMachines"}
          validation={validation} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(ServiceTreatmentDetail);
