import { PaginationRequestBody } from "../models/pagination";
import { PaymentMethodListObject, PaymentMethodAddObject, PaymentMethodUpdateObject, PaymentMethodDetailObject } from "../models/paymentMethod";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class paymentMethodStore {
  paymentMethodList: PaymentMethodListObject[] = [];
  paymentMethodDropdownList: PaymentMethodListObject[] = [];
  paymentMethodDetail: PaymentMethodDetailObject | undefined = undefined;
  paymentMethodPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.paymentMethodList = [];
    this.paymentMethodDropdownList = [];
    this.paymentMethodDetail = undefined;
    this.paymentMethodPaginationParams= undefined;
  }

  setPaymentMethodDetail = (paymentMethodDetail: PaymentMethodDetailObject | undefined) => {
    this.paymentMethodDetail = paymentMethodDetail;
  }

  setPaymentMethodPaginationParams = (paymentMethodPaginationParams: PaginationRequestBody | undefined) => {
    this.paymentMethodPaginationParams = paymentMethodPaginationParams;
  }
  
  getPaymentMethod = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setPaymentMethodPaginationParams(PaginationRequestBody);
    try{
      const resultPaymentMethod = await agent.PaymentMethod.paymentMethodList(PaginationRequestBody);
      runInAction(() => {
        this.paymentMethodList = resultPaymentMethod.data;
        store.commonStore.setTotalItem(resultPaymentMethod.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodList = [];
    }
  }

  getPaymentMethodDropdown = async () => {
    try{
      const resultPaymentMethodDropdown = await agent.PaymentMethod.paymentMethodDropdownList();
      runInAction(() => {
        this.paymentMethodDropdownList = resultPaymentMethodDropdown;
      });
      return Promise.resolve(resultPaymentMethodDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodDropdownList = [];
    }
  }

  getPaymentMethodWithId = async (id: string) => {
    try{
      const resultPaymentMethodDetail = await agent.PaymentMethod.paymentMethodDetail(id);
      runInAction(() => {
        this.paymentMethodDetail = resultPaymentMethodDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodDetail = undefined;
    }
  }

  addPaymentMethod = async (paymentMethodRequestBody: PaymentMethodAddObject) => {
    try{
      await agent.PaymentMethod.addPaymentMethod(paymentMethodRequestBody);
      store.commonStore.setSuccessMessage(`PaymentMethodAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePaymentMethod = async (paymentMethodRequestBody: PaymentMethodUpdateObject) => {
    try{
      await agent.PaymentMethod.updatePaymentMethod(paymentMethodRequestBody);
      store.commonStore.setSuccessMessage(`PaymentMethodUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePaymentMethod  = async (id: string, name: string) => {
    try {
      await agent.PaymentMethod.deletePaymentMethod(id);
      store.commonStore.setSuccessMessage(`PaymentMethodDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}