import { PaginationRequestBody } from "../models/pagination";
import { CreditAdjustmentListObject, CreditAdjustmentPreviewObject, CreditAdjustmentRequestObject, CreditAdjustmentDetailObject, CreditAdjustmentReviewObject } from "../models/creditAdjustment";
import { store } from "./store";
import { makeAutoObservable, runInAction, reaction } from "mobx";
import agent from "../api/agent";

export default class CreditAdjustmentStore {
  creditAdjustmentList: CreditAdjustmentListObject[] = [];
  creditAdjustmentDetail: CreditAdjustmentDetailObject | undefined = undefined;
  creditAdjustmentPaginationParams: PaginationRequestBody | undefined = undefined;
  
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.creditAdjustmentList = [];
    this.creditAdjustmentDetail = undefined;
    this.creditAdjustmentPaginationParams = undefined;
  }

  setCreditAdjustmentList = (creditAdjustmentList: CreditAdjustmentListObject[]) => {
    this.creditAdjustmentList = creditAdjustmentList;
  }

  setCreditAdjustmentDetail = (creditAdjustmentDetail: CreditAdjustmentDetailObject | undefined) => {
    this.creditAdjustmentDetail = creditAdjustmentDetail;
  }

  setCreditAdjustmentPaginationParams = (creditAdjustmentPaginationParams: PaginationRequestBody | undefined) => {
    this.creditAdjustmentPaginationParams = creditAdjustmentPaginationParams;
  }

  getCreditAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setCreditAdjustmentPaginationParams(PaginationRequestBody);
      const resultCreditAdjustment = await agent.CreditAdjustment.creditAdjustmentList(PaginationRequestBody);
      runInAction(() => {
        this.creditAdjustmentList = resultCreditAdjustment.data;
        store.commonStore.setTotalItem(resultCreditAdjustment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.creditAdjustmentList = [];
    }
  }

  getCreditAdjustmentWithId = async (id: string) => {
    try{
      const resultCreditAdjustmentsDetail = await agent.CreditAdjustment.creditAdjustmentDetail(id);
      runInAction(() => {
        this.creditAdjustmentDetail = resultCreditAdjustmentsDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.creditAdjustmentDetail = undefined;
    }
  }

  previewCreditAdjustment = async (creditAdjustmentRequestBody: CreditAdjustmentPreviewObject) => {
    try{
      const resultPreviewCreditAdjustmentsDetail =  await agent.CreditAdjustment.previewCreditAdjustment(creditAdjustmentRequestBody);
      return Promise.resolve({resultPreviewCreditAdjustmentsDetail});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  requestCreditAdjustment = async (creditAdjustmentRequestBody: CreditAdjustmentRequestObject) => {
    try{
      await agent.CreditAdjustment.requestCreditAdjustment(creditAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`CreditAdjustmentRequestSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  creditAdjustmentReview = async (creditAdjustmentRequestBody: CreditAdjustmentReviewObject) => {
    try{
      await agent.CreditAdjustment.creditAdjustmentReview(creditAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`CreditAdjustmentReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCreditAdjustment = async (id: string, name: string) => {
    try {
      await agent.CreditAdjustment.deleteCreditAdjustment(id);
      store.commonStore.setSuccessMessage(`CreditAdjustmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}