import { PaginationRequestBody } from "../models/pagination";
import { StockTakeListObject, StockTakeAddObject, StockTakeUpdateObject, StockTakeDetailObject, StockTakePreloadObject } from "../models/stockTake";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class stockTakeStore {
  stockTakeList: StockTakeListObject[] = [];
  stockTakeDetail: StockTakeDetailObject | undefined = undefined;
  stockTakePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.stockTakeList = [];
    this.stockTakeDetail = undefined;
    this.stockTakePaginationParams= undefined;
  }

  setStockTakeDetail = (stockTakeDetail: StockTakeDetailObject | undefined) => {
    this.stockTakeDetail = stockTakeDetail;
  }

  setStockTakePaginationParams = (stockTakePaginationParams: PaginationRequestBody | undefined) => {
    this.stockTakePaginationParams = stockTakePaginationParams;
  }

  getStockTake = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setStockTakePaginationParams(PaginationRequestBody);
    try{
      const resultStockTake = await agent.StockTake.stockTakeList(PaginationRequestBody);
      runInAction(() => {
        this.stockTakeList = resultStockTake.data;
        store.commonStore.setTotalItem(resultStockTake.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockTakeList = [];
    }
  }

  getStockTakeWithId = async (id: string) => {
    try{
      const resultStockTakeDetail = await agent.StockTake.stockTakeDetail(id);
      runInAction(() => {
        this.stockTakeDetail = resultStockTakeDetail;
      });
      return Promise.resolve(resultStockTakeDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockTakeDetail = undefined;
    }
  }

  addStockTake = async (stockTakeRequestBody: StockTakeAddObject) => {
    try{
      let resultStockTake:StockTakePreloadObject = await agent.StockTake.addStockTake(stockTakeRequestBody);
      if(!resultStockTake.hasDiscrepancy){
        store.commonStore.setSuccessMessage(`StockTakeAddSuccess`)
      }
      return Promise.resolve(resultStockTake);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockTake = async (stockTakeRequestBody: StockTakeUpdateObject) => {
    try{
      let resultStockTake:StockTakePreloadObject = await agent.StockTake.updateStockTake(stockTakeRequestBody);
      if(!resultStockTake.hasDiscrepancy){
      store.commonStore.setSuccessMessage(`StockTakeUpdateSuccess`)
    }
      return Promise.resolve(resultStockTake);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockTake  = async (id: string, name: string) => {
    try {
      await agent.StockTake.deleteStockTake(id);
      store.commonStore.setSuccessMessage(`StockTakeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}