import { useState } from "react";
import {
  Modal,
  Label,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useStore } from "../../../app/stores/store";
import { EmployeeLeaveDeleteWithDateRangeObject } from "../../../app/models/employeeLeave";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  optionYearList: any;
  optionMonthList: any;
  deleteEmployeeLeaveWithDateRange: Function;
  setErrorMessage: Function;
}

export default function DeleteEmployeeLeaveModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { loading, setLoading, successMessage } = commonStore;
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const disabledFieldInput = loading || Boolean(successMessage);

  const defaultEmployeeLeaveDetails = {
    year: selectedYear,
    month: selectedMonthValue,
  }
  const [employeeLeaveDetails, setEmployeeLeaveDetails] = useState(defaultEmployeeLeaveDetails)
  const [errorState, setErrorState] = useState({
    year: false,
    month: false,
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      year: false,
      month: false,
    });
  }
  const hideModal = () => {
    clearErrorState();
    setEmployeeLeaveDetails(defaultEmployeeLeaveDetails);
    props.setModal(false);
  }

  const onChangeYear = async (value) => {
    setEmployeeLeaveDetails(valueTemp => ({
      ...valueTemp,
      year: Number(value)
    }));
    clearErrorState("year");
  }

  const onChangeMonth = (value) => {
    setEmployeeLeaveDetails(valueTemp => ({
      ...valueTemp,
      month: Number(value),
    }));
    clearErrorState("month");
  }

  const onClickDeleteEmployeeLeaveWithDateRange = async (employeeLeaveDetailTemp: EmployeeLeaveDeleteWithDateRangeObject) => {
    setLoading(true);
    await props.deleteEmployeeLeaveWithDateRange(employeeLeaveDetailTemp);
    setLoading(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "DeleteEmployeeLeave" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <Label>
            <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Year" })}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={errorState.year ? "mb-0" : "mb-3"}
            options={props.optionYearList}
            onChange={onChangeYear}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={disabledFieldInput}
            validationRequired={true}
          />
          {errorState.year ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
          ) : null}
          <Label>
            <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Month" })}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={errorState.month ? "mb-0" : "mb-3"}
            options={props.optionMonthList}
            onChange={onChangeMonth}
            initialLabel={selectedMonth}
            initialValue={selectedMonthValue}
            setFieldLabel={setSelectedMonth}
            setFieldValue={setSelectedMonthValue}
            disabled={disabledFieldInput}
            validationRequired={true}
          />
          {errorState.month ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })}</FormFeedback>
          ) : null}
          {
            !Boolean(successMessage)
            &&
            <div className="margin-top-14 ">
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: "Delete" })}
                onClick={() => {
                  let errorStateTemp = {
                    year: false,
                    month: false,
                    branchId: false
                  };

                  let blnErrorFound = false;
                  if (!employeeLeaveDetails.year) {
                    errorStateTemp.year = true;
                    blnErrorFound = true;
                  }
                  else {
                    if (!employeeLeaveDetails.month) {
                      errorStateTemp.month = true;
                      blnErrorFound = true;
                    }
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickDeleteEmployeeLeaveWithDateRange(employeeLeaveDetails)
                  }
                }}
                disable={disabledFieldInput}
                loading={loading}
              />
            </div>
          }
        </div>
      </Modal>
  )
}