import { PaginationRequestBody } from "../models/pagination";
import { BranchProductForHQStockReturnObject, StockReturnToHQAddObject, StockReturnToHQDetailObject, StockReturnToHQListObject, StockReturnToHQUpdateObject } from "../models/stockReturnToHQ";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class StockReturnToHQStore {
  stockReturnToHQList: StockReturnToHQListObject[] = [];
  stockReturnToHQDetail: StockReturnToHQDetailObject | undefined = undefined;
  stockReturnToHQPaginationParams: PaginationRequestBody | undefined = undefined;
  
  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockReturnToHQList = [];
    this.stockReturnToHQDetail = undefined;
    this.stockReturnToHQPaginationParams = undefined;
  }

  setStockReturnToHQDetail = (stockReturnToHQDetail: StockReturnToHQDetailObject | undefined) => {
    this.stockReturnToHQDetail = stockReturnToHQDetail;
  }

  setStockReturnToHQPaginationParams = (stockReturnToHQPaginationParams: PaginationRequestBody | undefined) => {
    this.stockReturnToHQPaginationParams = stockReturnToHQPaginationParams;
  }

  getStockReturnToHQ = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setStockReturnToHQPaginationParams(PaginationRequestBody);
    try {
      const resultStockReturnToHQs = await agent.StockReturnToHQ.stockReturnToHQList(PaginationRequestBody);
      runInAction(() => {
        this.stockReturnToHQList = resultStockReturnToHQs.data;
        store.commonStore.setTotalItem(resultStockReturnToHQs.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockReturnToHQList = [];
    }
  };

  getBranchProductForHQStockReturnList = async (branchId: string) => {
    try {
      const resultBranchProductForHQStockReturnList = await agent.StockReturnToHQ.branchProductForHQStockReturnList(branchId);
      return Promise.resolve(resultBranchProductForHQStockReturnList);
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };

  getStockReturnToHQWithId = async (id: string) => {
    try{
      const resultStockReturnToHQDetail = await agent.StockReturnToHQ.stockReturnToHQDetail(id);
      runInAction(() => {
        this.stockReturnToHQDetail = resultStockReturnToHQDetail;
      });

      return Promise.resolve(resultStockReturnToHQDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockReturnToHQDetail = undefined;
    }
  }

  addStockReturnToHQ = async (stockReturnToHQRequestBody: StockReturnToHQAddObject) => {
    try{
      await agent.StockReturnToHQ.addStockReturnToHQ(stockReturnToHQRequestBody);
      store.commonStore.setSuccessMessage(`StockReturntoHQAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockReturnToHQ = async (stockReturnToHQRequestBody: StockReturnToHQUpdateObject) => {
    try{
      await agent.StockReturnToHQ.updateStockReturnToHQ(stockReturnToHQRequestBody);
      store.commonStore.setSuccessMessage(`StockReturntoHQUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockReturnToHQ  = async (id: string, name: string) => {
    try {
      await agent.StockReturnToHQ.deleteStockReturnToHQ(id);
      store.commonStore.setSuccessMessage(`StockReturntoHQDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
