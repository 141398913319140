import { useEffect, useState } from "react";
import { Col, Input, Label, Modal, Row, Table, UncontrolledTooltip } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { checkPermission, getBranchName, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import moment from "moment";
import { observer } from 'mobx-react-lite';
import Borderline from "../../../app/components/form/BorderLine";
import { Link } from 'react-router-dom';
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { CustomerCriteriaSearchResponseObject } from "../../../app/models/customer";
import GeneralImageWithBase64 from "../../../app/components/form/GeneralImageWithBase64";

interface Props {
  blnShow: boolean;
  setModal: Function;
  onSelectFunction: Function;
}

interface SearchValueObject {
  searchValue: string,
}

export default observer(function ReferredByCustomerSearchModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setErrorMessage, setSuccessMessage } = commonStore;
  const { getCustomerCriteriaSearch, setCustomerCriteriaSearchDetail } = customerStore;
  const { searchCustomerCriteriaType, identityType, getStaticReferenceWithType } = staticReferenceStore;

  const [loadingSearchType, setLoadingSearchType] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [refreshCustomerSearchType, setRefreshCustomerSearchType] = useState(false);

  const [selectedIdentityType, setSelectedIdentityType] = useState(Constants.identityCard);
  const [selectedIdentityTypeValue, setSelectedIdentityTypeValue] = useState(0);
  const [selectedReferredCustomerDetail, setSelectedReferredCustomerDetail] = useState<CustomerCriteriaSearchResponseObject>();

  const [checked, setCheckBoxChecked] = useState<any>(Constants.identityType);
  const [searchValueObj, setSearchValueObj] = useState<SearchValueObject>({ searchValue: "" })
  const [blnMalaysia, setBlnMalaysia] = useState(true);

  const blnIdentityType = checked === Constants.identityType;

  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  useEffect(() => {
    async function fetchCustomerSearchType() {
      setLoadingSearchType(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.searchCustomerCriteriaType),
        getStaticReferenceWithType(Constants.identityType),
      ];
      await Promise.all(aryAPI);
      setLoadingSearchType(false);
    }

    fetchCustomerSearchType();

    return (() => {
      setCustomerCriteriaSearchDetail(undefined);
    })
  }, [])
  
  const clearSearchValueObj = (blnMobileNo: boolean = false) => {
    setSearchValueObj({searchValue: blnMobileNo ? "6" :""})
  }

  const hideModal = () => {
    clearSearchValueObj();
    props.setModal(false);
  }

  const fetchCustomerSearchWithLoading = async () => {
    if (!searchValueObj.searchValue) {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: newSpaceBeforeCapitalLetter(blnIdentityType ? selectedIdentityType : Constants.mobileNumber) }));
      return;
    }
    else {
      setLocalLoading(true);
      let resultCustomerCriteriaSearch = await getCustomerCriteriaSearch({
        searchCriteria: blnIdentityType ? 0 : 1, // identityType = 0, mobileNumber = 1
        ...(blnIdentityType && (selectedIdentityTypeValue || selectedIdentityTypeValue === 0)) && { identityType: selectedIdentityTypeValue },
        searchValue: searchValueObj.searchValue || " "
      })
      setSelectedReferredCustomerDetail(resultCustomerCriteriaSearch);
      setLocalLoading(false);
    }

  };

  async function handleChangeRadioBox(value) {
    setRefreshCustomerSearchType(true);
    setCheckBoxChecked(value);
    clearSearchValueObj(value === Constants.mobileNumber ? true : false);
    setRefreshCustomerSearchType(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size='xl'
        centered
      >
        {
          loadingSearchType
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CustomerSearch" })}</h5>
                <button
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="standard-layout">
                  <Row className="align-items-md-center margin-top--8 margin-bottom--24">
                    <Col xl="6">
                      <Row className="mb-4">
                        <Label>{intl.formatMessage({ id: "SearchBy" })}</Label>
                        <div className="flex-direction-row">
                          {searchCustomerCriteriaType.map((option, index) => (
                            <div key={`radio_${index}`}>
                              <Input
                                type="radio"
                                name="searchCriteria"
                                checked={option.displayValue === checked}
                                className={`${index > 0 && "margin-left-16"}`}
                                onChange={() => handleChangeRadioBox(option.displayValue)}
                                disabled={localLoading || loading}
                              />
                              &nbsp;
                              <label>{newSpaceBeforeCapitalLetter(option.displayValue)}</label>
                            </div>
                          ))}
                        </div>
                      </Row>
                    </Col>
                    {
                      !refreshCustomerSearchType
                      &&
                      <Col xl="6">
                        <Row className={`align-items-md-center flex-right ${localLoading && "margin-right-32"}`}>
                          {
                            blnIdentityType &&
                            <Col xl="5">
                              <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "IdentityType" })}
                                name="identityType"
                                options={identityType}
                                labelField="displayValue"
                                valueField="key"
                                initialLabel={selectedIdentityType}
                                initialValue={selectedIdentityTypeValue}
                                setFieldLabel={setSelectedIdentityType}
                                setFieldValue={setSelectedIdentityTypeValue}
                                blnValueWithNewSpace={true}
                                disabled={localLoading || loading || loadingSearchType}
                                validationRequired={true}
                                onChange={() => {
                                  setErrorMessage("");
                                  setSuccessMessage("");
                                  clearSearchValueObj();
                                }}
                              />
                            </Col>
                          }
                            <Col xl="5">
                              <GeneralInput
                                title={newSpaceBeforeCapitalLetter(blnIdentityType ? selectedIdentityType : checked)}
                                name="searchValue"
                                type="text"
                                disabled={localLoading || loading || loadingSearchType}
                                validationRequired={false}
                                blnModal={true}
                                field={searchValueObj}
                                setFieldValue={setSearchValueObj}
                              />
                            </Col>
                            <Col xl="2">
                              <MyButton
                                type="button"
                                class="btn btn-primary margin-top-12"
                                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                                loading={localLoading || loading}
                                disable={localLoading || loading}
                                onClick={() => {
                                  fetchCustomerSearchWithLoading()
                                }}
                              />
                            </Col>
                        </Row>
                      </Col>
                    }
                  </Row>
                </div>
                {
                  localLoading
                    ?
                    <Loading className={`mt-4 margin-left-16`} />
                    :
                    selectedReferredCustomerDetail &&
                    <>
                      <Borderline />
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "CustomerDetail" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "IdentityNo" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "MobileNo" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "JoinedDate" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Branch" })}</th>
                              {/* <th className="text-center">{intl.formatMessage({ id: "SubBrand" })}</th> */}
                              <th className="text-center">{intl.formatMessage({ id: "LastVisitDate" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Action" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {
                                  blnPermissionViewCustomer && selectedReferredCustomerDetail.branchName === getBranchName()
                                    ?
                                    <Link to={selectedReferredCustomerDetail.id ? `/${RoutesList.customer}/view/${selectedReferredCustomerDetail.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                      <Label className="margin-bottom-0 pointer-clickable">{`${selectedReferredCustomerDetail.preferredName} (${selectedReferredCustomerDetail.customerNo})`}</Label>
                                    </Link>
                                    :
                                    `${selectedReferredCustomerDetail.preferredName} (${selectedReferredCustomerDetail.customerNo})`
                                }
                              </td>
                              <td className="text-center">{selectedReferredCustomerDetail.identityNo}</td>
                              <td className="text-center">{selectedReferredCustomerDetail.mobileNo}</td>
                              <td className="text-center">{moment(selectedReferredCustomerDetail.joinedDate).format(Constants.displayDateFormat)}</td>
                              <td className="text-center">{selectedReferredCustomerDetail.branchName}</td>
                              {/* <td className={selectedReferredCustomerDetail.badges && selectedReferredCustomerDetail.badges.length > 0 ? '': 'text-center'}>
                                <div className="flex-justfity-content-center">
                                  {
                                    selectedReferredCustomerDetail.badges && selectedReferredCustomerDetail.badges.length > 0
                                    ?
                                    selectedReferredCustomerDetail.badges.map((valueBagde, index) => (
                                      <div id={`brand_bagde_${index}_888`} key={`brand_bagde_${index}_888`}>
                                        <GeneralImageWithBase64
                                          index={index}
                                          className={index > 0 ? "margin-left-8" : ""}
                                          imageWidth='30px'
                                          base64Image={valueBagde.icon}
                                        />
                                        <UncontrolledTooltip placement="top" target={`brand_bagde_${index}_888`}>
                                          {valueBagde.name}
                                        </UncontrolledTooltip>
                                      </div>
                                    ))
                                    :
                                    Constants.emptyData
                                  }
                                </div>
                              </td> */}
                              <td className="text-center">
                                <div>
                                  {selectedReferredCustomerDetail.lastVisitDate !== Constants.invalidDateTime ? moment(selectedReferredCustomerDetail.lastVisitDate).format(Constants.displayDateFormat) : Constants.emptyData}
                                </div>
                                <div className="small-text">
                                  {selectedReferredCustomerDetail.lastVisitDate !== Constants.invalidDateTime ? moment(selectedReferredCustomerDetail.lastVisitDate).format(Constants.displayTimeFormat) : ""}
                                </div>
                              </td>
                              <td className="text-center">
                                <MyButton
                                  type="button"
                                  content={"Select"}
                                  class="btn btn-primary margin-left-8"
                                  onClick={() => {
                                    props.onSelectFunction(selectedReferredCustomerDetail);
                                  }}
                                  loading={loading}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                }
              </div>
            </div>
        }
      </Modal>
  )
});