import { PaginationRequestBody } from "../models/pagination";
import { PCBListObject, PCBAddObject, PCBUpdateObject, PCBDetailObject } from "../models/pcb";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class PCBStore {
  pcbList: PCBListObject[] = [];
  pcbDetail: PCBDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.pcbList = [];
    this.pcbDetail = undefined;
  }

  setPCBDetail = (pcbDetail: PCBDetailObject | undefined) => {
    this.pcbDetail = pcbDetail;
  }
  
  getPCB = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPCB = await agent.PCB.pcbList(PaginationRequestBody);
      runInAction(() => {
        this.pcbList = resultPCB.data;
        store.commonStore.setTotalItem(resultPCB.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.pcbList = [];
    }
  }

  getPCBWithId = async (id: string) => {
    try{
      const resultPCBDetail = await agent.PCB.pcbDetail(id);
      runInAction(() => {
        this.pcbDetail = resultPCBDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.pcbDetail = undefined;
    }
  }

  addPCB = async (pcbRequestBody: PCBAddObject) => {
    try{
      await agent.PCB.addPCB(pcbRequestBody);
      store.commonStore.setSuccessMessage(`PCBAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePCB = async (pcbRequestBody: PCBUpdateObject) => {
    try{
      await agent.PCB.updatePCB(pcbRequestBody);
      store.commonStore.setSuccessMessage(`PCBUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePCB  = async (id: string, name: string) => {
    try {
      await agent.PCB.deletePCB(id);
      store.commonStore.setSuccessMessage(`PCBDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}