import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, compareParamsArrayWithDefaultArray, convertParamsUrlToArray, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, paymentStatusColor, returnPriceWithCurrency, returnSubBrandList, salesOrderStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import { DailySalesDetailsGetObject } from "../../../app/models/reportSales";
import { history } from "../../../";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { SubBrandObject } from "../../../app/models/brand";

const DailySalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { dailySalesDetailsList, getDailySalesDetailsList, setDailySalesDetailsList, exportDailySalesDetails } = reportSalesStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const {salesOrderStatus, getStaticReferenceWithType} = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<any>([]);
  const [selectedSalesCategoryOptions, setSelectedSalesCategoryOptions] = useState<any>([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>(AllStatus);
  const [selectedPaymentStatusId, setSelectedPaymentStatusId] = useState<number | string | undefined>(" ");
  const [paymentStatusList, setPaymentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const aryPaymentHistories : ObjectForReportRow[] = [
    {label: "PaymentDate", value: "paymentDate", type: "date"}, 
    {label: "PaymentMethodName", value: "paymentMethodName", type: "string"}, 
    {label: "OptionName", value: "optionName", type: "string"}, 
    {label: "Last4CardDigit", value: "last4CardDigitNo", type: "string"}, 
    {label: "CardType", value: "cardType", type: "string"}, 
    {label: "PaidAmount", value: "paidAmount", type: "currency"}
  ];
  const paramsSearch = useLocation().search;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      statuses: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  useEffect(() => {
    async function fetchDailySalesDetails() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.salesOrderStatus),
        getStaticReferenceWithType(Constants.paymentStatus, AllStatus),
      ];      
      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setPaymentStatusList(resultAPI[2]);
      
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const statusesParams = convertParamsUrlToArray("statuses", paramsSearch);
      const paymentStatusParams = new URLSearchParams(paramsSearch).get('paymentStatus');
      const subBrandIdParams = new URLSearchParams(paramsSearch).get('subBrandId');
      
      if (branchIdParams && fromDateParams && toDateParams && (statusesParams.length > 0 || paymentStatusParams) ) {
        let indexBranchId = _.findIndex(resultAPI[0], { id: branchIdParams })
        if (indexBranchId > -1) {
          setSelectedBranchId(resultAPI[0][indexBranchId].id);
          setSelectedBranchName(resultAPI[0][indexBranchId].name);
        }
        
        let statusesParamsIdTemp: string[] = [];
        if (statusesParams) {
          let statusTemp = compareParamsArrayWithDefaultArray(statusesParams, resultAPI[1], "displayValue", "displayValue", "key");
          setSelectedStatusOptions(statusTemp);
          statusesParamsIdTemp = statusTemp.map((valueStatusTemp => valueStatusTemp.key))
          validation.setFieldValue("statuses", statusesParamsIdTemp)
        }

        let indexPaymentStatus = _.findIndex(resultAPI[2], { displayValue: paymentStatusParams })
        if(paymentStatusParams && paymentStatusParams !== ""){
          if (indexPaymentStatus > -1) {
            setSelectedPaymentStatusId(resultAPI[2][indexPaymentStatus].key);
            setSelectedPaymentStatus(resultAPI[2][indexPaymentStatus].displayValue);
          }
        }

        if(subBrandIdParams){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandIdParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }
        setSelectedDateRange({fromDate: moment(fromDateParams).format(Constants.displayDateFormat), toDate: moment(toDateParams).format(Constants.displayDateFormat)})
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.dailySalesDetails}` });
        await fetchDailySalesDetailsWithLoading({branchId: branchIdParams, dateRange: {fromDate: moment(fromDateParams).format(Constants.displayDateFormat), toDate: moment(toDateParams).format(Constants.displayDateFormat)}, statuses: statusesParams,  ...(paymentStatusParams) && {paymentStatus: paymentStatusParams}, subBrandId: subBrandIdParams || selectedSubBrandId })
      }

			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchDailySalesDetails();

    return (()=> {
      setDailySalesDetailsList([]);
    })
  }, [])

  const fetchDailySalesDetailsWithLoading = async (initialData?: DailySalesDetailsGetObject) => {
    if (initialData) {
      setLocalLoading(true);
      await getDailySalesDetailsList({pageNumber: 1, pageSize: pageSize, branchId: initialData.branchId, dateRange: initialData.dateRange, statuses: initialData.statuses, ...(initialData.paymentStatus || initialData.paymentStatus === 0) && { paymentStatus: initialData.paymentStatus }, subBrandId: initialData.subBrandId});
      setLocalLoading(false);
      return;
    }

    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    setCurrentPage(1);
    await getDailySalesDetailsList({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      dateRange: selectedDateRange,
      statuses: validation.values.statuses,
      paymentStatus: selectedPaymentStatusId,
      subBrandId: selectedSubBrandId
    });
    setLocalLoading(false);
	};

  async function exportDailySalesDetail() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    await exportDailySalesDetails({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      statuses: validation.values.statuses,
      ...(selectedPaymentStatusId || selectedPaymentStatusId === 0) && { paymentStatus: selectedPaymentStatusId },
      subBrandId: selectedSubBrandId
    });
    setLocalLoading(false);
  }

  //Table Content UI
  const DailySalesDetailsColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "salesOrderDate",
      text: intl.formatMessage({ id: "SalesOrderDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.salesOrderDate ? moment(row.salesOrderDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</>, 
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      ),
    },
    {
      dataField: "totalPackagesAmount",
      text: intl.formatMessage({ id: "TotalPackagesAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPackagesAmount)}</div>
      ),
    },
    {
      dataField: "totalProductsAmount",
      text: intl.formatMessage({ id: "TotalProductsAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalProductsAmount)}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.balance)}</div>
      ),
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.paymentStatus}
          statusColorFunction={paymentStatusColor}/>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor}/>
      ),
    },
  ];

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "PaymentHistory" })}
        rowFieldName={"paymentHistories"}
        rowColumns={aryPaymentHistories}
        keyFieldName={"id"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "DailySalesDetails"})})}
        title={intl.formatMessage({ id: "DailySalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportDailySalesDetail()
            }}
          />
        }>
        {
          !loading
          &&
          <>
            <Row>
            {
              subBrandListFinal.length > 0
              &&
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SalesOrderDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
            <Col xl={'3'} md={'6'} xs={'12'}>
                <DropDownWithTitleMultiSelect
                  name={"statuses"}
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "SalesOrder" }) })}
                  specifyReturnFieldName={[{ "field": "statuses", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={salesOrderStatus}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  initialValue={selectedStatusOptions}
                  validationRequired={false}
                  validation={validation} />
              </Col>
            <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Payment" }) })}
                  name="paymentStatus"
                  options={paymentStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedPaymentStatus}
                  initialValue={selectedPaymentStatusId}
                  setFieldLabel={setSelectedPaymentStatus}
                  setFieldValue={setSelectedPaymentStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchDailySalesDetailsWithLoading()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={DailySalesDetailsColumns}
            data={dailySalesDetailsList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "DailySalesDetails"})})}
            objSorted={defaultSorted}
            keyField={"id"}
            requestAPI={getDailySalesDetailsList}
            branchId={selectedBranchId}
            dateRange={selectedDateRange}
            statuses={validation.values.statuses}
            paymentStatus={selectedPaymentStatusId}
            subBrandId={selectedSubBrandId}
            expandRow={expandRow}
            setLocalLoading={setLocalLoading}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(DailySalesDetails);