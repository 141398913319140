import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Card,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _, { map } from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import { CustomerPaymentDetailObject } from "../../app/models/customerOrder";
import Borderline from "../../app/components/form/BorderLine";
import CustomerOrderCart from "./subView/CustomerOrderCart";
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import SignaturePad from "../../app/components/form/SignaturePad";
// users
import user9 from '../../assets/images/users/avatar-9.png';
import { checkPermission } from "../../app/common/function/function";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import AutoGeneratedCustomerRedemptionModal from "../ProductRedemptionNote/Modal/AutoGeneratedCustomerRedemptionModal";

const CustomerOrderAcknowledgement = () => {
  const intl = useIntl();
  //Use Store
  const { customerSalesOrderStore, deliveryOrderStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { signCustomerSalesOrder } = customerSalesOrderStore;
  const { signDeliveryOrder } = deliveryOrderStore;
  const [loadingCart, setLoadingCart] = useState(false);
  const location = useLocation();
  const breadCrumbList = [{ title: `${intl.formatMessage({id: "P.O.S"})}`, urlPath: RoutesList.pos }];
  const [showSignaturePad, setShowSignaturePad] = useState(true);
  const [blnSignCustomerOrder, setBlnSignCustomerOrder] = useState(false);
  const [blnSignDeliverOrder, setBlnSignDeliverOrder] = useState(false);
  const blnPermissionUpdateCustomerSalesOrder = checkPermission([PermissionConstants.UpdateCustomerSalesOrder])
  const blnPermissionUpdateCustomerDeliverOrder = checkPermission([PermissionConstants.UpdateCustomerDeliverOrder])
  const [autoRegenerateRedemptionNoticeModal, setAutoRegenerateRedemptionNoticeModal] = useState(false);
  const [autoRegenerateRedemptionResponse, setAutoRegenerateRedemptionResponse] = useState<any>([]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			paymentMethod: "",
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      totalBalance: 0,
      salesOrderDetails: [],
      paymentDetails: [],
      invoiceId: "",
      salesOrderId: "",
	    customerDeliveryOrderId: "",
      signatureImage: "",
      subBrandName: "",
      subBrandId: ""
		},
		validationSchema: Yup.object({}),
		onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
      }
      else {
        try {
          let blnSignCustomerOrderTemp = false;
          let blnSignDeliverOrderTemp = false;
          let resultAutoGenerateRedemptionTemp: any = [];
          if (!blnSignCustomerOrder && blnPermissionUpdateCustomerSalesOrder) {
            let resultSignCustomerSalesOrder : any = await signCustomerSalesOrder({id: valuesTemp.salesOrderId, signatureImage: valuesTemp.signatureImage})
            if (resultSignCustomerSalesOrder) {
              if (resultSignCustomerSalesOrder.status) {
                if (resultSignCustomerSalesOrder.status === Constants.success) {
                  setBlnSignCustomerOrder(true);
                  blnSignCustomerOrderTemp = true;
                  if(resultSignCustomerSalesOrder.data.isProductDOAutoGenerated){
                    resultAutoGenerateRedemptionTemp = resultSignCustomerSalesOrder.data;
                    setAutoRegenerateRedemptionResponse(resultSignCustomerSalesOrder.data);
                  }
                }
              }
            }
          }
          else {
            blnSignCustomerOrderTemp = true;
            setBlnSignCustomerOrder(true);
          }

          if (!blnSignCustomerOrderTemp) {
            return;
          }

          if (valuesTemp.customerDeliveryOrderId && !blnSignDeliverOrder && blnPermissionUpdateCustomerDeliverOrder)  {
            let resultSignDeliveryOrder : any = await signDeliveryOrder({customerDeliveryOrderIds: [valuesTemp.customerDeliveryOrderId], signatureImage: valuesTemp.signatureImage})
            if (resultSignDeliveryOrder) {
              if (resultSignDeliveryOrder.status) {
                if (resultSignDeliveryOrder.status === Constants.success) {
                  setBlnSignDeliverOrder(true);
                  blnSignDeliverOrderTemp = true;
                }
              }
            }
          }
          else {
            setBlnSignDeliverOrder(true);
            blnSignDeliverOrderTemp = true;
          }
          if (blnSignCustomerOrderTemp && blnSignDeliverOrderTemp) {
            setShowSignaturePad(false);
          }
          if(resultAutoGenerateRedemptionTemp.isProductDOAutoGenerated){
            setAutoRegenerateRedemptionNoticeModal(true);
          }
        } finally {
          validation.setSubmitting(false);
        }
      }
    }
	});

  useEffect(() => {
    if (location.state) {
      setLoadingCart(true);
      let detailTemp = location.state.detail; 
      let paidAmount = 0;
      if (detailTemp.paymentDetails) {
        detailTemp.paymentDetails.map((value: CustomerPaymentDetailObject)=> {
          paidAmount += Number(value.paidAmount);
        })
      }
      detailTemp.totalBalance = detailTemp.totalSellingPrice - paidAmount;
      validation.setValues(location.state.detail);

      if (!blnPermissionUpdateCustomerSalesOrder && (!location.state.detail.customerDeliveryOrderId || (location.state.detail.customerDeliveryOrderId && !blnPermissionUpdateCustomerDeliverOrder))) {
        setShowSignaturePad(false);
      }
      setTimeout(()=> {
        setLoadingCart(false);
      }, 100)
    }
    else {
      history.push(`/${RoutesList.pos}`)
    }
  }, [location]);

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        setSuccessMessage("");
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const displayConsultant = (value, index) => {
    return (
      <Col xl="4" key={value.name+'index'}>
        <Card style={{borderRadius: '12px'}}>
          <div className="">
            <label className="card-radio-label mb-0">
              <div className="card-radio" style={{borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                  <img
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                    src={user9}
                  />
                  <p style={{textAlign: 'center'}} className="margin-bottom-0">{value.label}</p>
                </div>
              </div>
            </label>                  
          </div>
        </Card>
      </Col>
    )
  }

  return (
    <div>
      <div 
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "P.O.S"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({ id: "SalesSummary" }).toUpperCase()}</div>
            </Col>
          </Row>
          <BreadCrumb 
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={intl.formatMessage({ id: "ModuleNameWithAcknowledgement" }, { moduleName: intl.formatMessage({id: "SalesSummary"})})}/>
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading && !successMessage ? (
            <Loading />
					) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={()=> {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5">
                  <div className="standard-layout">
                    {
                      !loadingCart
                      &&
                      <CustomerOrderCart
                        validation={validation}
                        disabledCustomerOrder={true}
                        disabledCustomerPayment={true}
                        disabledNote={true}
                        disabledVoidedSalesOrderNo={true}
                        blnTotalBalance={true}/>
                    }
                  </div>
                </Col>
                <Col xl="7">
                  <div className={`standard-layout ${Constants.innerWidthCompare>windowSize.innerWidth ? "padding-top-0 margin-top--30" : ""}`}>
                    <div className="flex-justfity-content-center">
                      <Label style={{fontSize: '30px'}}>{intl.formatMessage({ id: "DepositReceived"}).toUpperCase()}</Label>
                    </div>
                    <div className="text-center">
                      {
                        validation.values.salesOrderId &&                  
                          <Link to={`/${RoutesList.customerSalesOrder}/view/${validation.values.salesOrderId}`} target={Constants.blank}>
                            <MyButton
                              type="button"
                              content={intl.formatMessage({ id: "ViewCustomerSalesOrder"}).toUpperCase()}
                              class="btn btn-primary mt-2 me-1"
                              subContent={<i className="bx bx-cart me-2" />}
                            />
                          </Link>
                      }
                      {
                        validation.values.invoiceId &&
                        <Link to={`/${RoutesList.receiptUI}/view/${validation.values.invoiceId}`} target={Constants.blank}>
                          <MyButton
                            type="button"
                            content={intl.formatMessage({ id: "PrintReceipt"}).toUpperCase()}
                            class="btn btn-primary mt-2 me-1"
                            subContent={<i className="bx bx-shopping-bag me-2" />}
                          />
                        </Link>
                      }
                      {
                        validation.values.customerDeliveryOrderId &&
                          <Link to={`/${RoutesList.customerRedemption}/${showSignaturePad ? "edit" : "view"}/${validation.values.customerDeliveryOrderId}`} target={Constants.blank}>
                            <MyButton
                              type="button"
                              content={intl.formatMessage({ id: "CustomerRedemption"}).toUpperCase()}
                              class="btn btn-primary mt-2 me-1"
                              subContent={<i className="bx bx-shopping-bag me-2" />}
                            />
                          </Link>
                      }
                    </div>
                    <Borderline/>
                    <Label>
                      <Label className="margin-bottom-0 margin-top-0">{intl.formatMessage({ id: "Customer"})}</Label>
                    </Label>
                    <div className="d-flex mt-3">
                      <div className="me-3">
                        <Link to={`/${RoutesList.customer}/view/${validation.values.customerId}`} target={Constants.blank}>
                          <img
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                            src={user9}
                          />
                        </Link>
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <Link to={`/${RoutesList.customer}/view/${validation.values.customerId}`} target={Constants.blank} style={{fontSize: "13px"}}>{validation.values.customerName}</Link>
                          <p style={{fontSize: "11px"}} className="mb-1">{intl.formatMessage({ id: "CustomerNo"})}: {validation.values.customerNo}</p>
                          <p style={{fontSize: "11px"}} className="mb-0">{intl.formatMessage({ id: "MobileNo"})}: {validation.values.customerMobileNo}</p>
                        </div>
                      </div>
                    </div>
                    <Borderline/>
                    <Label>
                      <Label className="margin-bottom-0 margin-top-0">{intl.formatMessage({ id: "Consultant"})}</Label>
                    </Label>
                    <Row>
                      {map(validation.values.consultantIds, (value, index) => (
                        displayConsultant(value,index)
                      ))}
                    </Row>
                    <Borderline/>
                    {
                      showSignaturePad &&
                      <>
                        <SignaturePad
                          title={intl.formatMessage({ id: "CustomerSignature"})}
                          successMessage={successMessage}
                          validation={validation}
                          disabled={false}
                          isSubmitting={validation.isSubmitting} />
                      </>
                    }
                    <Row style={{marginTop: "3rem"}}>
                      <Col>
                        {
                          showSignaturePad &&
                          <MyButton
                            type="submit"
                            class="btn btn-info"
                            style={{width: '100%'}}
                            content={intl.formatMessage({ id: "Sign"}).toUpperCase()}
                            disable={validation.isSubmitting || Boolean(successMessage) || !validation.values.signatureImage}
                            loading={validation.isSubmitting || Boolean(successMessage)}
                            />
                        }
                        {
                          !showSignaturePad &&
                          <MyButton
                          type="button"
                          class="btn btn-info"
                          style={{width: '100%'}}
                          content={intl.formatMessage({ id: "Done"}).toUpperCase()}
                          onClick={()=> history.push(`/${RoutesList.dashboard}`)}
                          disable={validation.isSubmitting}
                          loading={validation.isSubmitting}
                          />
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        {
          autoRegenerateRedemptionNoticeModal
          &&
          <AutoGeneratedCustomerRedemptionModal
            blnShow={autoRegenerateRedemptionNoticeModal}
            setModal={setAutoRegenerateRedemptionNoticeModal}
            deliverOrderNo={autoRegenerateRedemptionResponse.deliverOrderNo}
            onRedeemClick={() => { 
              history.push({
                pathname: `/${RoutesList.productRedemptionNote}/add`,
                state: { 
                  detail: { 
                    customerId: validation.values.customerId,
                    deliverOrderId: autoRegenerateRedemptionResponse.deliverOrderId
                  }}
              }); 
            }}
            onCancelClick={() => {
              setAutoRegenerateRedemptionNoticeModal(false);
            }}
          />
        }
        </Container>
      </div>
    </div>
  );
}

export default observer(CustomerOrderAcknowledgement);
