import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { PaymentTerminalListObject } from '../../app/models/paymentTerminal';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row } from "reactstrap";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";

const PaymentTerminal = () => {
  const intl = useIntl();
  //Use Store
  const { paymentTerminalStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { paymentTerminalList, paymentTerminalPaginationParams, getPaymentTerminal, deletePaymentTerminal, setPaymentTerminalPaginationParams } = paymentTerminalStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PaymentTerminalListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreatePaymentTerminal = checkPermission([PermissionConstants.CreatePaymentTerminal]);
  const blnPermissionUpdatePaymentTerminal = checkPermission([PermissionConstants.UpdatePaymentTerminal]);
  const blnPermissionDeletePaymentTerminal = checkPermission([PermissionConstants.DeletePaymentTerminal]);
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  let paymentTerminalRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.name
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchPaymentTerminalAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.paymentTerminal)
      if (blnBackNavigation) {
        if (paymentTerminalPaginationParams) {
          setCurrentPage(paymentTerminalPaginationParams.pageNumber)
          validation.setValues((values)=> ({
            ...values,
            name: paymentTerminalPaginationParams.name || "",
          }))
          paymentTerminalRequestBody = paymentTerminalPaginationParams;
        }
      }
      else {
        setPaymentTerminalPaginationParams(undefined);
      }
      await getPaymentTerminal(paymentTerminalRequestBody)
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePaymentTerminal], true)) {
      return;
    }

    fetchPaymentTerminalAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.paymentTerminal)
        if (!blnSetParams) {
          setPaymentTerminalPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchPaymentTerminalApi(true);
    }
  }, [triggerSearch])

  const onClickDelete = async (row: PaymentTerminalListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  } 

  async function fetchPaymentTerminalApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPaymentTerminal(paymentTerminalRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const PaymentTerminalColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '75%'
      },
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePaymentTerminal, PermissionConstants.UpdatePaymentTerminal, PermissionConstants.DeletePaymentTerminal]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.paymentTerminal}/view/${row.id}`}
              type={`view`}/>
            {
              blnPermissionUpdatePaymentTerminal
              &&
              <TableActionButton 
                to={`/${RoutesList.paymentTerminal}/edit/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeletePaymentTerminal
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentTerminal" }) })}
        title={intl.formatMessage({ id: "PaymentTerminal" })}
        addButton={
          blnPermissionCreatePaymentTerminal
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PaymentTerminal" }) })}
            onClick={() => { history.push(`/${RoutesList.paymentTerminal}/add`) }}
            linkTo={`/${RoutesList.paymentTerminal}/add`}
            disable={loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={PaymentTerminalColumns}
            data={paymentTerminalList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PaymentTerminal" }) })}
            objSorted={defaultSorted}
            name={validation.values.name}
            requestAPI={getPaymentTerminal} />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deletePaymentTerminal(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (paymentTerminalList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getPaymentTerminal({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(PaymentTerminal);
