import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { AppolousPaymentCollectionSummaryObject, AppolousPaymentCollectionSummaryListGetObject, AppolousPaymentCollectionSummaryExportObject, AppolousDynamicSalesListObject, AppolousProductCommissionVariantListObject, AppolousFreePackageAndProductListObject } from "../models/reportAppolous";
import { downloadExcel, returnPriceWithCurrency } from "../common/function/function";
import _ from "lodash";
import { Constants } from "../constants/Constants";

export default class reportAppolousStore {
  appolousPaymentCollectionSummaryList: AppolousPaymentCollectionSummaryObject | undefined = undefined;
  appolousDynamicSalesList: AppolousDynamicSalesListObject[] = [];
  appolousProductCommissionVariantList: AppolousProductCommissionVariantListObject[] = [];
  appolousFreePackageAndProductList: AppolousFreePackageAndProductListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appolousPaymentCollectionSummaryList = undefined;
    this.appolousDynamicSalesList = [];
    this.appolousProductCommissionVariantList = [];
    this.appolousFreePackageAndProductList = [];
  }

  setAppolousPaymentCollectionSummaryList = (appolousAppolousPaymentCollectionSummaryObject: AppolousPaymentCollectionSummaryObject | undefined) => {
    this.appolousPaymentCollectionSummaryList = appolousAppolousPaymentCollectionSummaryObject;
  }

  setAppolousDynamicSalesList = (appolousDynamicSalesList: AppolousDynamicSalesListObject[]) => {
    this.appolousDynamicSalesList = appolousDynamicSalesList;
  }

  setAppolousProductCommissionVariantList = (appolousProductCommissionVariantList: AppolousProductCommissionVariantListObject[]) => {
    this.appolousProductCommissionVariantList = appolousProductCommissionVariantList;
  }

  setAppolousFreePackageAndProductList = (appolousFreePackageAndProductList: AppolousFreePackageAndProductListObject[]) => {
    this.appolousFreePackageAndProductList = appolousFreePackageAndProductList;
  }

  getAppolousPaymentCollectionSummaryList = async (appolousAppolousPaymentCollectionSummaryListGetObject: AppolousPaymentCollectionSummaryListGetObject) => {
    try{
      let resultAppolousPaymentCollectionSummary = await agent.ReportAppolous.appolousPaymentCollectionSummaryList(appolousAppolousPaymentCollectionSummaryListGetObject);
      
      resultAppolousPaymentCollectionSummary.paymentCollectionDetails = resultAppolousPaymentCollectionSummary.paymentCollectionDetails.map((valuePaymentCollectionDetailsTemp, index)=> ({
        ...valuePaymentCollectionDetailsTemp,
        randomId: `${valuePaymentCollectionDetailsTemp.appolousSalesId}_${index}`
      }))
      
      runInAction(() => {
        this.appolousPaymentCollectionSummaryList = resultAppolousPaymentCollectionSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousPaymentCollectionSummaryList = undefined;
    }
  }

  getAppolousDynamicSalesList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultAppolousDynamicSalesList = await agent.ReportAppolous.appolousDynamicSalesList(PaginationRequestBody);

      if (resultAppolousDynamicSalesList) {
        if (resultAppolousDynamicSalesList.data) {
          let appolousDynamicSalesListTemp = _.cloneDeep(resultAppolousDynamicSalesList.data);
          appolousDynamicSalesListTemp = appolousDynamicSalesListTemp.map((valueAppolousDynamicSalesListTemp)=> {
            valueAppolousDynamicSalesListTemp.details = valueAppolousDynamicSalesListTemp.details.map((valueAppolousDynamicSalesDetailsTemp)=> {
              valueAppolousDynamicSalesDetailsTemp.discount = valueAppolousDynamicSalesDetailsTemp.discountType === Constants.percentage ? `${valueAppolousDynamicSalesDetailsTemp.discount}%` : valueAppolousDynamicSalesDetailsTemp.discountType === Constants.amount ? returnPriceWithCurrency(valueAppolousDynamicSalesDetailsTemp.discount) : '-';
              return valueAppolousDynamicSalesDetailsTemp;
            })
            return valueAppolousDynamicSalesListTemp;
          })
          resultAppolousDynamicSalesList.data = appolousDynamicSalesListTemp;
        }
      }

      runInAction(() => {
        this.appolousDynamicSalesList = resultAppolousDynamicSalesList.data;
        store.commonStore.setTotalItem(resultAppolousDynamicSalesList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousDynamicSalesList = [];
    }
  }

  getAppolousProductCommissionVariantList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultProductCommissionVariantList = await agent.ReportAppolous.appolousProductCommissionVariantList(PaginationRequestBody);
      resultProductCommissionVariantList.data = resultProductCommissionVariantList.data.map((valueResultProductCommissionVariantListTemp, index)=> {
        let productCommissionVariantDetailsTemp : any = _.cloneDeep(valueResultProductCommissionVariantListTemp.details);
        productCommissionVariantDetailsTemp = productCommissionVariantDetailsTemp.map((valueProductCommissionVariantDetailsTemp, indexDetails)=> ({
          ...valueProductCommissionVariantDetailsTemp,
          randomId: `${valueProductCommissionVariantDetailsTemp.productName}_${index}_${indexDetails}`
        }))
        return({
          ...valueResultProductCommissionVariantListTemp,
          details: productCommissionVariantDetailsTemp,
          randomId: `${valueResultProductCommissionVariantListTemp.performaInvoiceNo}_${index}`
        })
      })

      runInAction(() => {
        this.appolousProductCommissionVariantList = resultProductCommissionVariantList.data;
        store.commonStore.setTotalItem(resultProductCommissionVariantList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousProductCommissionVariantList = [];
    }
  }

  getAppolousFreePackageAndProductList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultAppolousFreePackageAndProduct = await agent.ReportAppolous.appolousFreePackageAndProductList(PaginationRequestBody);
      resultAppolousFreePackageAndProduct.data = resultAppolousFreePackageAndProduct.data.map((valueresultAppolousFreePackageAndProduct, index)=> {
        return({
          ...valueresultAppolousFreePackageAndProduct,
          randomId: `${valueresultAppolousFreePackageAndProduct.customerId}_${index}`, 
        })
      })
      runInAction(() => {
        this.appolousFreePackageAndProductList = resultAppolousFreePackageAndProduct.data;
        store.commonStore.setTotalItem(resultAppolousFreePackageAndProduct.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousFreePackageAndProductList = [];
    }
  }

  exportAppolousPaymentCollectionSummary = async (appolousPaymentCollectionSummaryExportRequestBody: AppolousPaymentCollectionSummaryExportObject) => {
    try{
      await downloadExcel(`/report/appolouspayment/collectionsummary/export`, appolousPaymentCollectionSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppolousDynamicSalesList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/appolous/dynamicsales/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppolousProductCommissionVariant = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/appolous/productcommissionvariant/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppolousFreePackageAndProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/appolous/freepackageandproduct/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}