import { useState } from "react";
import { Modal } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: any;
  onClickAddTag: Function;
  loadingTag: boolean;
}

export default function AddTagModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [tagDetails, setTagDetails] = useState({tag: ""});
  const disabledFieldInput = props.loadingTag || Boolean(successMessage);

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "AddTag" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            <div>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Tag" })}
                  name="tag"
                  type="text"
                  field={tagDetails}
                  setFieldValue={setTagDetails}
                  disabled={disabledFieldInput}/>
              </SingleColumnRowParent>             
              <div className="">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-success"
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        props.onClickAddTag({customerId: props.customerId, tagValue: tagDetails.tag});
                      }}
                      disable={disabledFieldInput}
                      loading={props.loadingTag}
                    />
                  </>
                }
              </div>
            </div>
          }
        </div>
      </Modal>
  )
}