import { PaginationRequestBody } from "../models/pagination";
import { DSRAdjustmentAddObject, DSRAdjustmentListObject } from "../models/dsrAdjustment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class DSRAdjustmentStore {
  dsrAdjustmentList: DSRAdjustmentListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.dsrAdjustmentList = [];
  }
  
  getDSRAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultDSRAdjustment = await agent.DSRAdjustment.dsrAdjustmentList(PaginationRequestBody);
      runInAction(() => {
        this.dsrAdjustmentList = resultDSRAdjustment.data;
        store.commonStore.setTotalItem(resultDSRAdjustment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dsrAdjustmentList = [];
    }
  }
  addDSRAdjustment = async (dsrRequestBody: DSRAdjustmentAddObject) => {
    try{
      await agent.DSRAdjustment.addDSRAdjustment(dsrRequestBody);
      store.commonStore.setSuccessMessage(`DSRAdjustmentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteDSRAdjustment  = async (id: string, name: string) => {
    try {
      await agent.DSRAdjustment.deleteDSR(id);
      store.commonStore.setSuccessMessage(`DSRAdjustmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}