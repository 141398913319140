import { PaginationRequestBody } from "../models/pagination";
import { NotificationTemplateListObject, NotificationTemplateAddObject, NotificationTemplateUpdateObject, NotificationTemplateDetailObject } from "../models/notificationTemplate";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class NotificationTemplateStore {
  notificationTemplateList: NotificationTemplateListObject[] = [];
  notificationTemplateDropdownList: NotificationTemplateListObject[] = [];
  notificationTemplateDetail: NotificationTemplateDetailObject | undefined = undefined;
  notificationTemplatePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.notificationTemplateList = [];
    this.notificationTemplateDropdownList = [];
    this.notificationTemplateDetail = undefined;
    this.notificationTemplatePaginationParams= undefined;
  }

  setNotificationTemplateDetail = (notificationTemplateDetail: NotificationTemplateDetailObject | undefined) => {
    this.notificationTemplateDetail = notificationTemplateDetail;
  }
  
  setNotificationTemplatePaginationParams = (notificationTemplatePaginationParams: PaginationRequestBody | undefined) => {
    this.notificationTemplatePaginationParams = notificationTemplatePaginationParams;
  }

  getNotificationTemplate = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setNotificationTemplatePaginationParams(PaginationRequestBody);
    try{
      const resultNotificationTemplate = await agent.NotificationTemplate.notificationTemplateList(PaginationRequestBody);
      runInAction(() => {
        this.notificationTemplateList = resultNotificationTemplate.data;
        store.commonStore.setTotalItem(resultNotificationTemplate.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationTemplateList = [];
    }
  }

  getNotificationTemplateDropdown = async () => {
    try{
      const resultNotificationTemplateDropdown = await agent.NotificationTemplate.notificationTemplateDropdownList();
      runInAction(() => {
        this.notificationTemplateDropdownList = resultNotificationTemplateDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationTemplateDropdownList = [];
    }
  }

  getNotificationTemplateWithId = async (id: string) => {
    try{
      const resultNotificationTemplateDetail = await agent.NotificationTemplate.notificationTemplateDetail(id);
      runInAction(() => {
        this.notificationTemplateDetail = resultNotificationTemplateDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationTemplateDetail = undefined;
    }
  }

  addNotificationTemplate = async (notificationTemplateRequestBody: NotificationTemplateAddObject) => {
    try{
      await agent.NotificationTemplate.addNotificationTemplate(notificationTemplateRequestBody);
      store.commonStore.setSuccessMessage(`NotificationTemplateAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateNotificationTemplate = async (notificationTemplateRequestBody: NotificationTemplateUpdateObject) => {
    try{
      await agent.NotificationTemplate.updateNotificationTemplate(notificationTemplateRequestBody);
      store.commonStore.setSuccessMessage(`NotificationTemplateUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteNotificationTemplate  = async (id: string, name: string) => {
    try {
      await agent.NotificationTemplate.deleteNotificationTemplate(id);
      store.commonStore.setSuccessMessage(`NotificationTemplateDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
