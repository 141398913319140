import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithSelectAllCheckBoxAndInput from "../../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import CustomerConvertCreditModal from "../../Invoice/Modal/CustomerConvertCreditModal";
import { Constants } from "../../../app/constants/Constants";
import { checkPermission, getPurchaseTypeIcon, returnCurrency, returnPriceWithCurrency, returnThousandSeperator } from "../../../app/common/function/function";
import GeneralTextArea from "../../../app/components/form/GeneralTextArea";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from "../../..";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerRefundCreditList: any;
  //previewCustomerRefundCredit: Function;
  convertCustomerRefundCredit: Function;
  getSalesOrderId: string;
  //blnIsItemizedRefund: boolean;
}

export default observer(function CustomerRefundCreditModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage, setErrorMessage } = commonStore;
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [totalItemizedRefundAmount, setTotalItemizedRefundAmount] = useState(0);
  const blnPermissionConvertCustomerSalesOrderCredit = checkPermission([PermissionConstants.ConvertCustomerSalesOrderCredit]);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "Type" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "purchaseType",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Qty" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "quantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalSessions" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "remainingSession",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalProduct" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "remainingProduct",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalOptionalProductCost" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "remainingOptionalProductCost",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "ExchangeableAmount" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "exchangeAmount",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      },
    ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      previewRefundDetail: [],
      reason: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const hideModal = () => {
    validationLocal.setFieldValue("previewRefundDetail", initRefundCreditList(false));
    props.setModal(false);
  }

  useEffect(() => {
    if (props.customerRefundCreditList) {
      if (props.customerRefundCreditList.refundDetails && props.customerRefundCreditList.refundDetails.length > 0) {
        validationLocal.setFieldValue("previewRefundDetail", initRefundCreditList());
      }
    }
  }, [props.customerRefundCreditList])

  useEffect(() => {
    if (validationLocal.values.previewRefundDetail) {
      calculateItemizedItemRefundAmount()
    }
  }, [validationLocal.values.previewRefundDetail])

  const blnRefundPartial = !props.customerRefundCreditList.isItemizedRefund;

  const initRefundCreditList = (blnRefund?: boolean) => {
    let valueTemp: any = props.customerRefundCreditList.refundDetails;
    let previewRefundDetailTemp = valueTemp.map((value) => {
      return {
        ...value,
        remainingSession: value.remainingSession || Constants.emptyData,
        remainingProduct: value.remainingProduct || Constants.emptyData,
        remainingOptionalProductCost: value.remainingOptionalProductCost !== null ? value.remainingOptionalProductCost : 0,
        isActive: blnRefund || !props.customerRefundCreditList.isItemizedRefund
      }
    })
    return previewRefundDetailTemp;
  }

  const calculateItemizedItemRefundAmount = () => {
    let valueTemp: any = validationLocal.values.previewRefundDetail;
    let totalAmountTemp = 0;
    for (var a = 0; a < valueTemp.length; a++) {
      if (valueTemp[a].isActive && valueTemp[a].isActive == true) {
        totalAmountTemp += valueTemp[a].exchangeAmount;
      }
    }
    setTotalItemizedRefundAmount(totalAmountTemp)
  }

  const fetchConvertCreditApi = async (value) => {
    setLoadingConvert(true);
    let result = await props.convertCustomerRefundCredit({ salesOrderId: props.getSalesOrderId, reason: validationLocal.values.reason, refundDetails: value });
    if (result.status === Constants.success) {
      //temporary solution
      setTimeout(() => {
        hideModal();
        history.push(`/${RoutesList.customerSalesOrder}/view/${props.getSalesOrderId}`)
      }, Constants.dismissSuccessMessage)
    }
    setLoadingConvert(false);
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end" style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <h6 className="text-end" style={{ fontSize: '15px' }}>
            {value}
          </h6>
        </Col>
      </Row>
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingConvert && !successMessage) {
            hideModal();
          }
        }}
        size={"xl"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          !props.customerRefundCreditList
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: blnRefundPartial ? "ExchangeToCredit" : "ExchangeableItem" })}</h5>
                </div>
                {
                  !loadingConvert && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => { hideModal(); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                {
                  blnRefundPartial &&
                  <>
                    <p style={{ fontSize: "15px" }}>{intl.formatMessage({ id: "PartialRefundMessage" })}</p> 
                    <p className='' style={{ color: 'red', fontSize: "15px" }}>*{intl.formatMessage({ id: "PleaseReferBelow" })}</p>
                  </>
                }
                {
                  !blnRefundPartial &&
                  <div className="">
                    <TableWithSelectAllCheckBoxAndInput
                      name="previewRefundDetail"
                      title=""
                      className="margin-top--8"
                      blnEditable={true}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validationLocal.values.previewRefundDetail}
                      validation={validationLocal}
                      selectAllTextFieldDisplay={"purchaseName"}
                      selectAllTextFieldDisplayXLSize={2.5}
                      iconTypeName={"purchaseType"}
                      blnTableViewCss={true}
                      disabled={blnRefundPartial || loadingConvert || Boolean(successMessage)} />
                  </div>
                }
                <GeneralTextArea
                  title={intl.formatMessage({ id: "Reason" })}
                  className='mt-4'
                  name="reason"
                  row={5}
                  disabled={loadingConvert || Boolean(successMessage)}
                  validationRequired={true}
                  validation={validationLocal} />
                <hr />
                <Col className="mt-3">
                  {displayHeaderRowData(`${intl.formatMessage({ id: "SubtotalExchangeableAmount" })}`, returnPriceWithCurrency(props.customerRefundCreditList.totalRefundAmount || totalItemizedRefundAmount))}
                  {displayHeaderRowData(`${intl.formatMessage({ id: "CreditEarned" })}`, returnThousandSeperator(props.customerRefundCreditList.totalRefundAmount || totalItemizedRefundAmount))}
                </Col>
                <div className="mb-3 mt-4 flex-right">
                  {
                    blnPermissionConvertCustomerSalesOrderCredit
                    &&
                    !Boolean(successMessage)
                    &&
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Confirm" })}
                      onClick={() => {
                        if (!validationLocal.values.reason) {
                          setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`)
                          return;
                        }
                        if (!blnRefundPartial) {
                          let valueTemp: any = validationLocal.values.previewRefundDetail;
                          let selectedPreviewCustomerRefundCreditList: any = [];
                          for (var a = 0; a < valueTemp.length; a++) {
                            if (valueTemp[a].isActive && valueTemp[a].isActive == true) {
                              selectedPreviewCustomerRefundCreditList.push({
                                id: valueTemp[a].purchaseId,
                                purchaseType: valueTemp[a].purchaseType,
                              })
                            }
                          }
                          if (selectedPreviewCustomerRefundCreditList && selectedPreviewCustomerRefundCreditList.length > 0) {
                            fetchConvertCreditApi(selectedPreviewCustomerRefundCreditList)
                          } else {
                            setErrorMessage(`${intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({ id: "Item" }) })}`)
                          }
                        }
                        else {
                          fetchConvertCreditApi([])
                        }
                      }}
                      loading={loadingConvert}
                      disable={loadingConvert || Boolean(successMessage)}
                    />
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})