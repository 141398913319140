import React, { useEffect, useState } from 'react'
import { Label } from 'reactstrap';
import Select from "react-select";
import { AddOptionsValueField, convertValueWithSpaceInOptionsArray, customFilter, newSpaceBeforeCapitalLetter } from '../../common/function/function';
import { Constants } from '../../constants/Constants';

interface Props {
  title: string;
  name: string;
  className?: string;
  styles?: any;
  placeholder?: string;
  options: any;
  labelField?: string;
  valueField?: string;
  disabled?: boolean;
  initialLabel?: string | number;
  initialValue?: string | number;
  setFieldValue?: any;
  setFieldLabel?: any;
  validationRequired?: boolean;
  onChange?: Function;
  onBlur?: Function;
  blnReturnFullValue?: boolean;
  blnValueWithNewSpace?: boolean;
  blnSupportCustomSearch?: boolean;
  blnSupportGroupDropdown?: boolean;
  onChangeCustomSearch?: Function;
  onMenuOpenFunction?: Function;
  loadingCustomSearch?: boolean;
  setLoadingCustomSearch?: Function;
  dropDownStyles?: any;
}

let localSearchValue = "";
export default function GeneralSelectDropdown(props: Props) {
  let optionsTemp = props.blnSupportGroupDropdown ? props.options : AddOptionsValueField(props.options, (props.labelField || "label"), (props.valueField || "value"));
  let initialLabelDisplay = props.initialLabel || undefined;
  let initialValueDisplay = props.initialValue || props.initialValue === 0 ? props.initialValue : undefined;  
  let timeOut : any =  0;

  if (props.blnValueWithNewSpace) {
    optionsTemp = convertValueWithSpaceInOptionsArray(optionsTemp, true);
    initialLabelDisplay = props.initialLabel ? newSpaceBeforeCapitalLetter(props.initialLabel) : undefined;
    initialValueDisplay = props.initialValue || props.initialValue === 0 ? newSpaceBeforeCapitalLetter(props.initialValue) : undefined;
  }
  
  const localOnChangeCustomSearch = (value) => {
    clearTimeoutFunction();
    localSearchValue = value;

    if (value.length < Constants.dropdownSearchValueLength) {
      if (props.onMenuOpenFunction) {
        props.onMenuOpenFunction();
      }
      return;
    }

    timeOut = setTimeout(() => {
      if (props.setLoadingCustomSearch) {
        props.setLoadingCustomSearch(true);
      }
      if (props.onChangeCustomSearch) {
        props.onChangeCustomSearch(value);
      }
    }, Constants.dropdownSearchAPITimeout);
  }

  const clearTimeoutFunction = () => {
    localSearchValue = "";
    if(timeOut) {
      clearTimeout(timeOut)
    }
  } 

  const formatGroupLabel = (data: any) => {
    return (
      <div className="groupStyles">
      	<span style={{fontSize: '13px'}}>{data.label}</span>
    	</div>
    )
  }
  
  return (
    <div className={props.className || "mb-3"} style={props.styles}>
      {
        props.title 
        && 
        <Label>
         {!props.disabled && props.validationRequired && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
          <Label className="margin-bottom-0">{props.title}</Label>
        </Label>
      }
      <Select
        name={props.name}
        classNamePrefix={props.name}
        placeholder={props.placeholder || "- Please Select -"}
        isDisabled={props.disabled}
        onChange={(e: any)=> {
          if (!e) {
            e = {
              label: "",
              value: ""
            }
          }

          if (props.setFieldValue) {
            props.setFieldValue(e.value)
          }
          if (props.setFieldLabel) {
            props.setFieldLabel(e.label)
          }
          if (props.onChange) {
            if (props.initialValue !== e.value) {
              props.onChange(props.blnReturnFullValue ? e : e.value);
            }
          }
        }}
        onInputChange={(value: any)=> {
          if (props.blnSupportCustomSearch && props.onChangeCustomSearch && props.setLoadingCustomSearch) {
            localOnChangeCustomSearch(value);
          }
        }}
        isOptionDisabled={(option: any) => option.disabled}
        onBlur={()=> {
          if (props.onBlur) {
            props.onBlur()
          }
        }}
        isClearable={props.validationRequired ? false : true}
        menuPosition="fixed"
        defaultValue={initialValueDisplay || props.initialValue === 0 ? { label: initialLabelDisplay || initialValueDisplay, value: initialValueDisplay } : null}
        options={optionsTemp || []}
        {...(props.blnSupportCustomSearch && props.loadingCustomSearch) && {noOptionsMessage : ()=> ('Loading...')}}
        {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && (!localSearchValue || localSearchValue.length < Constants.dropdownSearchValueLength)) && {noOptionsMessage : ()=> (`Please ${initialLabelDisplay ? "re-enter" : "enter"} ${localSearchValue.length < Constants.dropdownSearchValueLength ? Math.abs(localSearchValue.length-Constants.dropdownSearchValueLength) : ""} or more characters to search`)}}
        {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && localSearchValue.length > (Constants.dropdownSearchValueLength-1)) && {noOptionsMessage : ()=> (`No options`)}}
        {...props.blnSupportCustomSearch && {filterOption: customFilter}}
        onMenuOpen={()=> {
          if (props.blnSupportCustomSearch && props.onMenuOpenFunction) {
            props.onMenuOpenFunction();
          }

	        setTimeout(()=>{
            const selectedEl = document.getElementsByClassName(`${props.name}__option--is-selected`)[0];
            if(selectedEl){
              selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
            }
          },15)
        }}
        {...props.blnSupportGroupDropdown && {formatGroupLabel: formatGroupLabel}}
        {...props.dropDownStyles && {styles: props.dropDownStyles}}
      />
    </div>
  )
}