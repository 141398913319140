import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import { DayOffExportObject, DayOffListObject, OutletScheduleExportObject, OutletScheduleListObject } from "../models/reportHumanResource";

export default class ReportCustomerStore {
  dayOffList: DayOffListObject[] = [];
  outletScheduleList: OutletScheduleListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.dayOffList = [];
    this.outletScheduleList = [];
  }

  setDayOffList = (dayOffList: DayOffListObject[]) => {
    this.dayOffList = dayOffList;
  }

  setOutletScheduleList = (outletScheduleList: OutletScheduleListObject[]) => {
    this.outletScheduleList = outletScheduleList;
  }

  getDayOffList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resutlDayOff = await agent.ReportHumanResource.dayOffList(PaginationRequestBody);
      runInAction(() => {
        this.dayOffList = resutlDayOff;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dayOffList = [];
    }
  }

  getOutletScheduleList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultOutletSchedule = await agent.ReportHumanResource.outletScheduleList(PaginationRequestBody);
      runInAction(() => {
        this.outletScheduleList = resultOutletSchedule;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.outletScheduleList = [];
    }
  }

  exportDayOff = async (dayOffExportObject: DayOffExportObject) => {
    try{
      await downloadExcel(`/report/hr/dayoff/export`, dayOffExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportOutletSchedule = async (outletScheduleExportObject: OutletScheduleExportObject) => {
    try{
      await downloadExcel(`/report/hr/outletschedule/export`, outletScheduleExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}