import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency, returnSubBrandList, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { SubBrandObject } from "../../../app/models/brand";
import { BranchListObject } from "../../../app/models/branch";
import { GroupCodeListObject } from "../../../app/models/groupCode";

const SalesByGroupCode = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, groupCodeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { getSalesByGroupCodeList, exportSalesByGroupCode } = reportSalesStore; 
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getGroupCodeDropDown } = groupCodeStore;

  //Data List
  const defaultColumns = {
    dataField: "branchName",
    text: intl.formatMessage({ id: "Branch" }).toUpperCase()
  };
  const [salesByGroupCodeColumns, setSalesByGroupCodeColumns] = useState<any>([defaultColumns]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const [groupCodeDropdownFullList, setGroupCodeDropdownFullList] = useState<GroupCodeListObject[]>([]);
  const [groupCodeDropdownFinalList, setGroupCodeDropdownFinalList] = useState<GroupCodeListObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingGroupCodes, setLoadingGroupCodes] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [salesByGroupCodeList, setSalesByGroupCodeList] = useState<any[]>([]);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const tabButtonWidth = windowSize.innerWidth >= Constants.innerWidthCompare ? `${windowSize.innerWidth/15}px` : "";

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      groupCodes: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  useEffect(() => {
    async function fetchSalesByGroupCode() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getGroupCodeDropDown({})
      ];      
      let resultAPI = await Promise.all(aryAPI);

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));
      let groupCodeDropdownTemp = _.cloneDeep(resultAPI[1]);
      groupCodeDropdownTemp.push({
        id: Constants.emptyId,
        isDelete: false,
        name: intl.formatMessage({ id: "OthersSales" }),
        displayOrder: 0,
        isActive: true
      })
      setGroupCodeDropdownFullList(groupCodeDropdownTemp);
      setGroupCodeDropdownFinalList(groupCodeDropdownTemp);

			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchSalesByGroupCode();

    return (()=> {
      setSalesByGroupCodeList([]);
    })
  }, [])

  const fetchSalesByGroupCodeWithLoading = async () => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    setCurrentPage(1);
    
    let resultSalesByGroupCodeListTemp = await getSalesByGroupCodeList({
      branchId: selectedBranchId, 
      fromDate: selectedDateRange.fromDate,
      toDate: selectedDateRange.toDate,
      groupCodes: validation.values.groupCodes,
      subBrandId: selectedSubBrandId,
      ...!blnAllActive && {isActiveGroupCode: blnIsActive}
    });

    let salesByGroupCodeColumnsTemp: any = [defaultColumns];
    let salesByGroupCodeListTemp : any = [];
    let objTotalTemp : any = {};
    if (resultSalesByGroupCodeListTemp) {
      resultSalesByGroupCodeListTemp.map((valueSalesByGroupCodeTemp, indexSalesByGroupCode) => {
        let objSalesByGroupCodeTemp : any = {
          branchId: valueSalesByGroupCodeTemp.branchId,
          branchName: valueSalesByGroupCodeTemp.branchName
        }
        if (valueSalesByGroupCodeTemp.groupCodeSalesFigures) {
          let arySalesByGroupCodeKey = Object.keys(valueSalesByGroupCodeTemp.groupCodeSalesFigures)
          arySalesByGroupCodeKey.map((valueSalesByGroupCodeKeyTemp)=> {
            objSalesByGroupCodeTemp = { 
              ...objSalesByGroupCodeTemp,
              [valueSalesByGroupCodeKeyTemp]: valueSalesByGroupCodeTemp.groupCodeSalesFigures[valueSalesByGroupCodeKeyTemp]
            }

            if (objTotalTemp[valueSalesByGroupCodeKeyTemp]) {
              objTotalTemp[valueSalesByGroupCodeKeyTemp] += valueSalesByGroupCodeTemp.groupCodeSalesFigures[valueSalesByGroupCodeKeyTemp];
            }
            else {
              objTotalTemp = { ...objTotalTemp, [valueSalesByGroupCodeKeyTemp]: valueSalesByGroupCodeTemp.groupCodeSalesFigures[valueSalesByGroupCodeKeyTemp]};
            }
            
            if (indexSalesByGroupCode === 0) {
              salesByGroupCodeColumnsTemp.push({
                dataField: valueSalesByGroupCodeKeyTemp,
                text: valueSalesByGroupCodeKeyTemp,
                formatter: (cellContent, row, index) => <span style={{fontWeight: index == salesByGroupCodeListTemp.length-1 && index !== 0 ? Constants.boldFontWeight : Constants.normalFontWeight}}>{row[valueSalesByGroupCodeKeyTemp] == 0 && index !== salesByGroupCodeListTemp.length-1 ? Constants.emptyDataV2 : returnPriceWithCurrency(row[valueSalesByGroupCodeKeyTemp])}</span>, //Custom UI to display
              })
            }
          })
        }
        salesByGroupCodeListTemp.push(objSalesByGroupCodeTemp);
      })
    }

    if(salesByGroupCodeListTemp.length > 1) {
      salesByGroupCodeListTemp.push({
        branchId: Constants.emptyData,
        branchName: "",
        ...objTotalTemp
      })
    }

    setSalesByGroupCodeColumns(salesByGroupCodeColumnsTemp);
    setSalesByGroupCodeList(salesByGroupCodeListTemp);
    setLocalLoading(false);
	};

  async function exportDailySalesDetail() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    await exportSalesByGroupCode({
      branchId: selectedBranchId,
      fromDate: selectedDateRange.fromDate,
      toDate: selectedDateRange.toDate,
      groupCodes: validation.values.groupCodes,
      subBrandId: selectedSubBrandId,
      ...!blnAllActive && {isActiveGroupCode: blnIsActive}
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const blnViewAllFunction = () => {
    setBlnAllActive(true);
    filterGroupCodeList(true)
  }

  const blnActiveOnlyFunction = () => {
    setBlnIsActive(true);
    setBlnAllActive(false);
    filterGroupCodeList(false, true)
  }

  const blnInactiveOnlyFunction = () => {
    setBlnIsActive(false);
    setBlnAllActive(false);
    filterGroupCodeList(false, false)
  }

  const filterGroupCodeList = (blnAllActive, blnActive?: boolean) => {
    let groupCodeDropdownFinalListTemp : GroupCodeListObject[] = [];
    if (blnAllActive) {
      groupCodeDropdownFinalListTemp = _.cloneDeep(groupCodeDropdownFullList);
    }
    else {
      groupCodeDropdownFinalListTemp = groupCodeDropdownFullList.filter(valueGroupCodeTemp => (blnActive ? valueGroupCodeTemp.isActive : !valueGroupCodeTemp.isActive))
    }
    validation.setFieldValue("groupCodes", []);
    setGroupCodeDropdownFinalList(groupCodeDropdownFinalListTemp);
    setLoadingGroupCodes(true);
    setTimeout(()=> {
      setLoadingGroupCodes(false);
    }, 100)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "SalesByGroupCode"})})}
        title={intl.formatMessage({ id: "SalesByGroupCode" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportDailySalesDetail()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-By-Group-Code.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <Col xl={'3'} md={'6'} xs={'12'}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchDropDownListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SalesOrderDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <div>
                  <Label className="margin-top-0">{intl.formatMessage({ id: "GroupCodes" })}</Label>
                </div>
                <div className="btn-group flex-1" role="group">
                  {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, tabButtonWidth)}
                  {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), blnActiveOnlyFunction, tabButtonWidth)}
                  {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), blnInactiveOnlyFunction, tabButtonWidth)}
                </div>
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'} className={`${windowSize.innerWidth >= Constants.innerWidthCompare770 ? "margin-top-26": "margin-top-12"}`}>
                {
                  loadingGroupCodes
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"groupCodes"}
                    specifyReturnFieldName={[{ "field": "groupCodes", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"name"}
                    valueField={"id"}
                    options={groupCodeDropdownFinalList}
                    disabled={localLoading || loading}
                    blnValueWithNewSpace={true}
                    validationRequired={false}
                    validation={validation} />
                }
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-26" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchSalesByGroupCodeWithLoading()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={salesByGroupCodeColumns}
            data={salesByGroupCodeList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "SalesByGroupCode"})})}
            objSorted={defaultSorted}
            keyField={"branchId"}
            requestAPI={getSalesByGroupCodeList}
            setLocalLoading={setLocalLoading}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesByGroupCode);