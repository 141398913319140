import { useEffect, useState } from "react";
import {
  Label,
  Modal,
  Row} from "reactstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { isTablet } from "react-device-detect";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { Constants } from "../../../app/constants/Constants";
import { DateRangeInterface } from "../../../app/models/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { PaginationRequestBody } from "../../../app/models/pagination";
import MyButton from "../../../app/components/form/MyButton";
import { checkPermission, returnPriceWithCurrency } from "../../../app/common/function/function";
import moment from "moment";
import { RoutesList } from "../../../app/constants/RoutesList";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  setPaymentAdditionalInfoModal: Function;
  setSelectedPaymentDetail: Function;
  isEnableAdminChangePaymentMode: boolean | undefined;
  validation: any;
}

export default function OldPaymentDetailsModal(props: Props) {
  const intl = useIntl();
  const { changePaymentModeStore, commonStore } = useStore();
  const { changePaymentModeOldPaymentList, changePaymentModeOldPaymentTotalItems, setChangePaymentModeOldPaymentList, setChangePaymentModeOldPaymentTotalItems, getChangePaymentModeOldPayment } = changePaymentModeStore; 
  const { setErrorMessage } = commonStore;
  const [loadingOldPayment, setLoadingOldPayment] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dateRangeLimit = {
    fromDate: !props.isEnableAdminChangePaymentMode ? moment().subtract(3, 'month').format(Constants.displayDateFormat) : "1901-01-01", 
    toDate: moment().format(Constants.displayDateFormat)
  }
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({
    fromDate: moment().subtract(3, 'month').format(Constants.displayDateFormat),
    toDate: moment().format(Constants.displayDateFormat)
  });
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const [localOldPaymentDetailsColumn, setLocalOldPaymentDetailsColumn] = useState([
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          <Label className="margin-bottom-0 margin-left-4">{row.salesOrderNo || Constants.emptyData}</Label>
        }
      </>
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <p className={`margin-bottom-0`}>{`${row.paymentMethodName}${row.cardType ? ` (${row.cardType} | ${row.last4CardDigitNo})` : ""}`}</p>
          <p className="payment-method-sub-text">{`${row?.paymentMethodOptionName ? `${row.paymentMethodOptionName}` : ""}${row.paymentIssuerBankName ? ` - ${row.paymentIssuerBankName}` : ""}`}</p>
        </>
      )
    },
    {
      dataField: "transactionNo",
      text: `${intl.formatMessage({ id: "TransactionNo" })}\n/${intl.formatMessage({ id: "ApprovalCode" })}`.toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.transactionNo || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount)}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      // headerStyle: { width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <div>
          <MyButton
            type="button"
            class={`btn btn-info`}
            style={{ padding: "8px", paddingBottom: "4px", paddingTop: "4px"}}
            content={intl.formatMessage({ id: "Select" })}
            onClick={()=> {
              let oldPaymentDetailsTemp = _.cloneDeep(props.validation.values.oldPaymentDetails);
              let indexOldPaymentFound = _.findIndex(oldPaymentDetailsTemp, { id: row.id })
              if (indexOldPaymentFound > -1) {
                setErrorMessage(intl.formatMessage({ id: "OldPaymentHasBeenSelected" }))
              }
              else {
                props.setSelectedPaymentDetail(row);
                props.setPaymentAdditionalInfoModal(true);
              }
            }}
          />
        </div>
      ),
    },
  ])
  const pageSize = Constants.defaultPageSize;
  const disabledFieldInput = loadingOldPayment;

  // validation
  const localValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let oldPaymentDetailsRequestBody: PaginationRequestBody = {
    pageSize: pageSize,
    pageNumber: 1,
    customerId: props.customerId,
    salesOrderNo: localValidation.values.salesOrderNo,
    paymentFromDate: selectedDateRange.fromDate,
    paymentToDate: selectedDateRange.toDate
  }
  
  useEffect(() => {
    async function fetchBranchCommissionAPI() {
      fetchChangePaymentModeOldPayment();
      setInitialCallAPI(false);
    }

    fetchBranchCommissionAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchChangePaymentModeOldPayment();
    }
  }, [triggerSearch, selectedDateRange])

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setTriggerSearch((value)=> value+1)
  }

  async function fetchChangePaymentModeOldPayment() {
    setLoadingOldPayment(true)
    await getChangePaymentModeOldPayment(oldPaymentDetailsRequestBody);
    setLoadingOldPayment(false);
  }

  const hideModal = () => {
    props.setModal(false);

  }

  return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size="xl"
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CustomerPaymentHistories" })}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <Row>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "TransactionDate"})})}
              name={"dateRange"}
              className="mb-0"
              disabled={loadingOldPayment}
              initialValue={selectedDateRange}
              onChangeFunction={onSelectDateRange}
              minMaxDateObj={dateRangeLimit}
              blnMinMaxDateObjWithBothViews={true} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
              name="salesOrderNo"
              className="mb-0"
              type="text"
              disabled={loadingOldPayment}
              validation={localValidation}
              placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
              onBlurFunction={() => {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <PaginationTable
          blnRemoveBackground={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={changePaymentModeOldPaymentTotalItems}
          tableColumns={localOldPaymentDetailsColumn}
          data={changePaymentModeOldPaymentList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "OldPayments" }) })}
          objSorted={[]}
          customerId={props.customerId}
          salesOrderNo={localValidation.values.salesOrderNo}
          paymentFromDate={selectedDateRange.fromDate}
          paymentToDate={selectedDateRange.toDate}
          requestAPI={getChangePaymentModeOldPayment} />
      </div>
    </Modal>
  )
}