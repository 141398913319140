import { useEffect, useState } from "react";
import { Modal, FormFeedback, Table, Col, Row, Label } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import { checkPermission, deliveryOrderStatusColor, getBranchId, returnPriceWithCurrency } from "../../../app/common/function/function";
import { CustomerPaymentDetailObject } from "../../../app/models/customerOrder";
import moment from "moment";
import TableActionButton from "../../../app/components/table/TableActionButton";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import Borderline from "../../../app/components/form/BorderLine";
import { PaymentMethodForBranchObject } from "../../../app/models/branch";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import Loading from "../../../app/components/loading/Loading";
import { CustomerPaymentAdjustmentWithSalesOrderDetailObject, CustomerPaymentSalesOrderRedemptionsObject } from "../../../app/models/customerPaymentAdjustment";
import TableWithSelectAllCheckBoxAndInput from "../../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EmployeeListObject } from "../../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Link } from 'react-router-dom';
import SelectWithTableListModal from "../../../app/components/modal/SelectWithTableListModal";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { isTablet } from "react-device-detect";
interface Props {
  blnShow: boolean;
  setModal: Function;
  salesOrderId: string;
  branchId: string;
  customerBranchId: string;
  blnEditPayment: boolean;
  setBlnEditPayment: Function;
  editPayment: CustomerPaymentDetailObject[];
  setEditPayment: Function;
  selectedInvoicePaymentIndex: number | undefined;
  setSelectedInvoicePaymentIndex: Function;
  blnShowSalesOrderPaymentModal: boolean;
  setSalesOrderPaymentModal: Function;
  loadingPayment: boolean;
  fetchPaymentMethod: Function;
  requestCustomerPaymentAdjustment: Function;
  customerSalesOrderOldPaymentTotalPaid?: any;
  customerSalesOrderOldPaymentTotalBalance?: any;
  branchPaymentMethod: PaymentMethodForBranchObject[];
  cardType: StaticReferenceDataObject[];
  setCustomerSalesOrderPaymentModal: Function;
  employeeDropdownListForDifferentBranch: EmployeeListObject[];
}

export default function PaymentAdjustmentModal(props: Props) {
  const intl = useIntl();
  const { commonStore, customerPaymentAdjustmentStore, employeeStore } = useStore();
  const { successMessage, setErrorMessage } = commonStore;
  const { getCustomerPaymentAdjustmentWithSalesOrder } = customerPaymentAdjustmentStore;
  const { getEmployeeDropdown } = employeeStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingRequestAdjustPayment, setLoadingRequestAdjustPayment] = useState(false);
  const [totalDraftPayment, setTotalDraftPayment] = useState(0);
  const [totalSelectedPreviousPayment, setTotalSelectedPreviousPayment] = useState(0);
  const [customerPaymentRedemptionsModal, setCustomerPaymentRedemptionsModal] = useState(false);
  const [customerPaymentRedemptionsList, setCustomerPaymentRedemptionsList] = useState<CustomerPaymentSalesOrderRedemptionsObject[]>([]);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState("");
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);

  const defaultAdjustmentPaymentDetails = {
    salesOrderId: props.salesOrderId,
    branchId: getBranchId(),
    remark: "",
    payments: []
  }

  const CustomerPaymentRedemptionsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {moment(row.performedDateTime).format(Constants.displayDateAndTimeFormat)}
        </div>,
    },
    {
      dataField: "deliverOrderNo",
      text: intl.formatMessage({ id: "DeliverOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomerDeliverOrder
        ?
        <Link to={row.id ? `/${RoutesList.customerRedemption}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 pointer-clickable">{row.deliverOrderNo || Constants.emptyData}</Label>
        </Link>
        :
        <div>
          {row.deliverOrderNo}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={deliveryOrderStatusColor}/>
      )
    },
  ];
  
  const [paymentAdjustmentDetails, setPaymentAdjustmentDetails] = useState(defaultAdjustmentPaymentDetails)
  const blnPermissionRequestPaymentAdjustment = checkPermission([PermissionConstants.RequestPaymentAdjustment]);
  // const blnDifferentBranch = props.branchId !== props.customerBranchId
  const blnDifferentBranch = false;

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "Date" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "paymentDateDisplay",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Time" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "paymentTimeDisplay",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "PaymentBranch" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "paymentMethodDisplay",
      inputSubTextName: "paymentMethodSubTextDisplay",
      inputType: "text",
    },
    {
      label: `${intl.formatMessage({ id: "TransactionNo"})}/${intl.formatMessage({ id: "ApprovalCode"})}`.toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "transactionNoDisplay",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "paidAmountDisplay",
      inputType: "text",
      disabled: true,
      flexRight: true,
    },
  ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerSalesOrderOldPaymentHistory: [],
      consultantIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    async function getPaymentHistoryWithDifferentBranch() {
      setLocalLoading(true);
      let aryAPI: any = [
        getCustomerPaymentAdjustmentWithSalesOrder({salesOrderId: props.salesOrderId, branchId: getBranchId()})
      ];
      let resultAPI = await Promise.all(aryAPI);
      let resultCustomerPaymentAdjustmentWithSalesOrder : CustomerPaymentAdjustmentWithSalesOrderDetailObject[] = resultAPI[0] || [];
      if (resultCustomerPaymentAdjustmentWithSalesOrder) {
        let resultCustomerPaymentAdjustmentWithSalesOrderTemp : CustomerPaymentAdjustmentWithSalesOrderDetailObject[] = resultCustomerPaymentAdjustmentWithSalesOrder.map((valueCustomerPaymentAdjustmentWithSalesOrderTemp)=> ({
          ...valueCustomerPaymentAdjustmentWithSalesOrderTemp,
          paymentDateDisplay: moment(valueCustomerPaymentAdjustmentWithSalesOrderTemp.paymentDate).format(Constants.displayDateFormat),
          paymentTimeDisplay: moment(valueCustomerPaymentAdjustmentWithSalesOrderTemp.paymentDate).format(Constants.displayTimeFormat),
          paymentMethodDisplay: `${valueCustomerPaymentAdjustmentWithSalesOrderTemp.paymentMethodName}${valueCustomerPaymentAdjustmentWithSalesOrderTemp.cardType ? ` (${valueCustomerPaymentAdjustmentWithSalesOrderTemp.cardType} | ${valueCustomerPaymentAdjustmentWithSalesOrderTemp.last4CardDigit})` : ""}`,
          paymentMethodSubTextDisplay: `${valueCustomerPaymentAdjustmentWithSalesOrderTemp.optionName ? `${valueCustomerPaymentAdjustmentWithSalesOrderTemp.optionName}` : ""}${valueCustomerPaymentAdjustmentWithSalesOrderTemp.paymentIssuerBankName ? ` - ${valueCustomerPaymentAdjustmentWithSalesOrderTemp.paymentIssuerBankName}` : ""}`,
          transactionNoDisplay: valueCustomerPaymentAdjustmentWithSalesOrderTemp.transactionNo || Constants.emptyData,
          paidAmountDisplay: returnPriceWithCurrency(valueCustomerPaymentAdjustmentWithSalesOrderTemp.paidAmount),
          isActive: false,
          disabledCheckField: !valueCustomerPaymentAdjustmentWithSalesOrderTemp.isAdjustable,
          redemptions: valueCustomerPaymentAdjustmentWithSalesOrderTemp.redemptions
        }))
        validationLocal.setFieldValue("customerSalesOrderOldPaymentHistory", resultCustomerPaymentAdjustmentWithSalesOrderTemp);
      }
      setLocalLoading(false);
    }

    getPaymentHistoryWithDifferentBranch();
  }, [])

  useEffect(() => {
    let totalDraftPaymentTemp = 0;
    props.editPayment.map((value) => {
      totalDraftPaymentTemp += Number(value.paidAmount)
    })
    setTotalDraftPayment(totalDraftPaymentTemp);
  }, [props.editPayment])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        hideModal();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(()=> {
    let totalSelectedPreviousPaymentTemp = 0;
    let selectedPaymentDateTemp = "";
    validationLocal.values.customerSalesOrderOldPaymentHistory.map((valueCustomerSalesOrderOldPaymentHistoryTemp : CustomerPaymentAdjustmentWithSalesOrderDetailObject)=> {
      if (valueCustomerSalesOrderOldPaymentHistoryTemp.isActive) {
        if(valueCustomerSalesOrderOldPaymentHistoryTemp.redemptions.length > 0){
          setCustomerPaymentRedemptionsList(valueCustomerSalesOrderOldPaymentHistoryTemp.redemptions);
          setCustomerPaymentRedemptionsModal(true);
          valueCustomerSalesOrderOldPaymentHistoryTemp.isActive = false;
        }else{
          totalSelectedPreviousPaymentTemp += valueCustomerSalesOrderOldPaymentHistoryTemp.paidAmount
          selectedPaymentDateTemp = ` (${moment(valueCustomerSalesOrderOldPaymentHistoryTemp.paymentDate).format(Constants.displayDateFormat)})`;
        }
      }
    })
    setTotalSelectedPreviousPayment(totalSelectedPreviousPaymentTemp);
    setSelectedPaymentDate(selectedPaymentDateTemp);
  }, [validationLocal.values.customerSalesOrderOldPaymentHistory])

  const blnDisplayTableData = props.editPayment && props.editPayment.length > 0;

  const emptyTableData = () => {
    return (
      <tr><td colSpan={7} style={{ color: "gray", textAlign: "center" }}>{intl.formatMessage({ id: "NoAvailableData" })}</td></tr>
    );
  };

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        <td colSpan={colSpan} />
        <td className="text-end" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const displayCustomRowDataWithoutTable = (label, value, textColor?: string, leftExtraLabel?: string) => {
    return (
      <Row>
        <Col xl="5" md="5" xs="5"/>
        <Col style={{padding: '0.75rem 0.75rem'}} className="flex-right">
          <p style={{fontWeight: "bold", textAlign: 'right', color: textColor ? textColor : undefined}} className="mb-0">{`${label} ${leftExtraLabel ? leftExtraLabel : ""}`}</p>
        </Col>
        <Col style={{padding: '0.75rem 0.75rem', paddingRight: '45px'}} className="flex-right">
          <p  style={{fontWeight: "500", color: textColor ? textColor : undefined}} className="mb-0">{value}</p>
        </Col>
      </Row>
    )
  }

  const hideModal = () => {
    props.setEditPayment([]);
    props.setModal(false);
  }

  const onClickRequestChangePaymentMethod = async (paymentDetailTemp: any) => {
    if (!paymentAdjustmentDetails.remark) {
      setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`)
    }
    else if (paymentDetailTemp.length > 0) {
      paymentDetailTemp = paymentDetailTemp.map((valuePaymentDetailTemp) => {
        return ({
          ...valuePaymentDetailTemp,
          transactionNo: valuePaymentDetailTemp.transactionNo ? String(valuePaymentDetailTemp.transactionNo): "",
        })
      })

      let paymentHistoryIdsTemp : string[] = [];
      for (let a=0; a<validationLocal.values.customerSalesOrderOldPaymentHistory.length; a++) {
        let valueCustomerSalesOrderOldPaymentHistory : CustomerPaymentAdjustmentWithSalesOrderDetailObject = validationLocal.values.customerSalesOrderOldPaymentHistory[a];
        if (valueCustomerSalesOrderOldPaymentHistory.isActive && !valueCustomerSalesOrderOldPaymentHistory.disabledCheckField) {
          paymentHistoryIdsTemp.push(valueCustomerSalesOrderOldPaymentHistory.id);
        }
      }
      if (paymentHistoryIdsTemp.length < 1) {
        setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PreviousPayment" }) }))
        return;
      }

      if (blnDifferentBranch) {
        if (validationLocal.values.consultantIds.length < 1) {
          setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }) }))
          return;
        }
      }
      let requestPaymentAdjustmentTemp = _.cloneDeep({ ...paymentAdjustmentDetails, payments: paymentDetailTemp, paymentHistoryIds: paymentHistoryIdsTemp, consultantIds: validationLocal.values.consultantIds})
      setLoadingRequestAdjustPayment(true);
      await props.requestCustomerPaymentAdjustment(requestPaymentAdjustmentTemp);
      setLoadingRequestAdjustPayment(false);
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AdjustPayment" }) }))
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingRequestAdjustPayment && !successMessage) {
            hideModal();
          }
        }}
        size={"xl"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0"> {intl.formatMessage({ id: "AdjustPayment" })}</h5>
          {
            !loadingRequestAdjustPayment && !successMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            localLoading
            ?
            <Loading />
            :
            <>
              {
                blnDifferentBranch
                &&
                <>
                  <h3 className="font-size-15 font-weight-bold">
                    <span style={{color: "red"}}>*</span>
                    {intl.formatMessage({ id: "Consultants" })}
                  </h3>
                  <DropDownWithTitleMultiSelect
                    name={"consultantIds"}
                    className={"margin-bottom-28"}
                    specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={props.employeeDropdownListForDifferentBranch}
                    disabled={false}
                    initialValue={[]}
                    validationRequired={true}
                    validation={validationLocal} />
                </>
              }
              {
                validationLocal.values.customerSalesOrderOldPaymentHistory.length > 0 &&
                <>
                  <h3 className="font-size-15 font-weight-bold">
                    <span style={{color: "red"}}>*</span>
                    {intl.formatMessage({ id: "PreviousPayments" })}
                  </h3>
                  <h5 className="font-size-13 mb-3 text-danger margin-left-8">
                    {intl.formatMessage({ id: "PreviousPaymentSameDate" })}
                  </h5>
                  <TableWithSelectAllCheckBoxAndInput
                    name="customerSalesOrderOldPaymentHistory"
                    title=""
                    className="margin-top--16"
                    blnEditable={true}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validationLocal.values.customerSalesOrderOldPaymentHistory}
                    validation={validationLocal}
                    hideSelectAllTitle={true}
                    selectAllTextFieldDisplay={""}
                    selectAllTextFieldDisplayXLSize={0.5}
                    blnTableViewCss={true}
                    disabled={loadingRequestAdjustPayment || Boolean(successMessage)}
                    disabledCheckFieldName="disabledCheckField"
                    hideSelectAllButton={true}
                    expandRowTitle={intl.formatMessage({ id: "PaymentDate" })}
                    blnExpandRow={true}
                    expandRowTitleField={"paymentDate"}
                    expandRowBlockField={"redemptions"} />
                  {displayCustomRowDataWithoutTable(`${intl.formatMessage({ id: "Paid" })}`, returnPriceWithCurrency(props.customerSalesOrderOldPaymentTotalPaid))}
                  <div className="table-border-line margin-bottom-2" />
                  {displayCustomRowDataWithoutTable(`${intl.formatMessage({ id: "Balance" })}`, returnPriceWithCurrency(props.customerSalesOrderOldPaymentTotalBalance))}
                  <div className="table-border-line margin-bottom-2" />
                  {displayCustomRowDataWithoutTable(`${intl.formatMessage({ id: "TotalAmountToAdjust" })}`, returnPriceWithCurrency(totalSelectedPreviousPayment), Constants.blue, selectedPaymentDate)}
                  <div className="table-border-line margin-bottom-28" />
                </>
              }

              <div className="table-responsive d-print-none ">
                <h3 className="font-size-15 font-weight-bold mb-3">
                  <span style={{color: "red"}}>*</span>
                  {intl.formatMessage({ id: "NewPayments" })}
                </h3>
                <Table className="">
                  <thead className="table-light">
                    <tr>
                      <th>{intl.formatMessage({ id: "Date" })}</th>
                      <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                      <th className="text-center">{intl.formatMessage({ id: "TransactionNo"})}/{intl.formatMessage({ id: "ApprovalCode"})}</th>
                      <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                      {props.editPayment.length > 0 && <th className="text-end">{intl.formatMessage({ id: "Action" })}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      blnDisplayTableData ?
                        <>
                          {
                            props.editPayment.map((item, index) => (
                              <tr key={index}>
                                <td>{moment(new Date()).format(Constants.displayDateFormat)}</td>
                                <td>
                                  <p className={`margin-bottom-0`}>{`${item.paymentMethodName}${item.cardType ? ` (${item.cardType} | ${item.last4CardDigit})` : ""}`}</p>
                                  <p className="payment-method-sub-text">{`${item?.paymentMethodOptionName ? `${item.paymentMethodOptionName}` : ""}${item.paymentIssuerBankName ? ` - ${item.paymentIssuerBankName}` : ""}`}</p>
                                </td>
                                <td className="text-center">{item.transactionNo || Constants.emptyData}</td>
                                <td className="text-end">{returnPriceWithCurrency(item.paidAmount)}</td>
                                {
                                  !Boolean(successMessage)
                                  &&
                                  <td className="text-end">
                                      <>
                                      <div className="d-flex gap-2 flex-right">
                                        <TableActionButton
                                          to="#"
                                          onClick={() => {
                                            let adjustPaymentTemp = _.cloneDeep(props.editPayment)
                                            adjustPaymentTemp.splice(index, 1);
                                            props.setEditPayment(adjustPaymentTemp);
                                          }}
                                          type="delete"
                                          disabled={loadingRequestAdjustPayment} />
                                      </div>
                                    </>
                                  </td>
                                  }
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={2} />
                            <td className="text-center" style={{ fontWeight: 'bold' }}>
                              {intl.formatMessage({ id: "Total" })}
                            </td>
                            <td className="text-end">
                              {returnPriceWithCurrency(totalDraftPayment)}
                            </td>
                            {props.editPayment.length > 0 && <th className="text-end"></th>}
                          </tr>
                        </>
                        :
                        emptyTableData()
                    }
                  </tbody>
                </Table>
                <div className='mb-2 flex-right'>
                  <MyButton
                    type="button"
                    class="btn btn-success margin-left-8"
                    content={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Payment" }) })}
                    onClick={() => {
                      if (props.branchPaymentMethod.length > 0 && props.cardType.length > 0) {
                        props.setCustomerSalesOrderPaymentModal(true);
                      }
                      else { 
                        props.fetchPaymentMethod();
                      }
                    }}
                    disable={loadingRequestAdjustPayment || Boolean(successMessage)}
                    loading={loadingRequestAdjustPayment || props.loadingPayment}
                  />
                </div>
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Reason" })}
                  name="remark"
                  row={4}
                  validationRequired={true}
                  field={paymentAdjustmentDetails}
                  setFieldValue={setPaymentAdjustmentDetails}
                  disabled={loadingRequestAdjustPayment || Boolean(successMessage)} />
              </div>
              {
                blnPermissionRequestPaymentAdjustment
                &&
                !Boolean(successMessage)
                &&
                <div className={`float-end d-print-none`}>
                  <MyButton
                    type="button"
                    class="btn btn-primary margin-left-8"
                    content={intl.formatMessage({ id: "Submit" })}
                    onClick={() => {
                      onClickRequestChangePaymentMethod(props.editPayment);
                    }}
                    disable={loadingRequestAdjustPayment || Boolean(successMessage)}
                    loading={loadingRequestAdjustPayment || props.loadingPayment}
                  />
                </div>
              }
            </>
          }
        </div>
        {/* CustomerPaymentRedemptionsList */}
        {
          customerPaymentRedemptionsModal &&
          <SelectWithTableListModal
            blnShow={customerPaymentRedemptionsModal}
            setModal={setCustomerPaymentRedemptionsModal}
            title={intl.formatMessage({ id: "CustomerRedemptionsList" })}
            options={customerPaymentRedemptionsList}
            keyField={"id"}
            fieldName={"id"}
            columns={CustomerPaymentRedemptionsColumns}
            validation={validationLocal}
            modalSize={"lg"}
            alertMessage={intl.formatMessage({ id: "PaymentAdjustmentRedemptionsListAlertMessage" })}/>
        }
      </Modal>
  )
}