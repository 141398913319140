import { PaginationRequestBody } from "../models/pagination";
import { CustomerCartListObject, CustomerCartDetailObject, CustomerCartAddObject, CustomerCartUpdateObject } from "../models/customerCart";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class customerCartStore {
  customerCartList: CustomerCartListObject[] = [];
  customerCartDetail: CustomerCartDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerCartList = [];
  }

  setCustomerCartList = (customerCartList: CustomerCartListObject[]) => {
    this.customerCartList = customerCartList;
  }

  setCustomerCartDetail = (customerCartDetail: CustomerCartDetailObject | undefined) => {
    this.customerCartDetail = customerCartDetail;
  }
  
  getCustomerCart = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerCart = await agent.CustomerCart.customerCartList(PaginationRequestBody);
      runInAction(() => {
        this.customerCartList = resultCustomerCart.data;
        store.commonStore.setTotalItem(resultCustomerCart.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerCartList = [];
    }
  }

  getCustomerCartWithId = async (id: string) => {
    try{
      const resultCustomerCartDetail = await agent.CustomerCart.customerCartDetail(id);
      return Promise.resolve(resultCustomerCartDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addCustomerCart = async (customerCartRequestBody: CustomerCartAddObject) => {
    try{
      const resultCustomerCart = await agent.CustomerCart.addCustomerCart(customerCartRequestBody);
      return Promise.resolve({status: Constants.success, data : resultCustomerCart})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, customerCartId: ""});
    }
  }

  updateCustomerCart = async (customerCartRequestBody: CustomerCartUpdateObject) => {
    try{
      const resultCustomerCart = await agent.CustomerCart.updateCustomerCart(customerCartRequestBody);
      return Promise.resolve({status: Constants.success, data: resultCustomerCart})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  deleteCustomerCart  = async (id: string) => {
    try {
      await agent.CustomerCart.deleteCustomerCart(id);
      store.commonStore.setSuccessMessage(`CustomerCartDeleteSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  deleteCustomerCartByBranch  = async (branchId: string) => {
    try {
      await agent.CustomerCart.deleteCustomerCartByBranch(branchId);
      store.commonStore.setSuccessMessage(`CustomerCartDeleteByBranchSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
}