import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { appointmentStatusColor, arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getMonths, getYears, returnSubBrandList } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import { useLocation } from "react-router-dom";
import { history } from "../../../";
import { SubBrandObject } from "../../../app/models/brand";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";

const RoomUtilization = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppointmentStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { roomUtilizationList, getRoomUtilizationList, setRoomUtilizationList, exportRoomUtilization } = reportAppointmentStore; 

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportAppointmentReport = checkPermission([PermissionConstants.ExportAppointmentReport]);
  const blnPermissionManageAppointment = checkPermission([PermissionConstants.ManageAppointment]);

  const paramsSearch = useLocation().search;
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const yearParams = new URLSearchParams(paramsSearch).get('year');
  const monthParams = new URLSearchParams(paramsSearch).get('month');
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId'); 

  const aryRoomUtilizationDetail : ObjectForReportRow[] = [
    {label: "AppointmentDate", value: "appointStartDateTime", secondValue: "appointEndDateTime", type: "date-time-range"}, 
    {label: "SpendHours", value: "utilizedHours", type: "string"}, 
    {label: "Name", value: "customerName", type: "string-without-space"}, 
    {label: "Email", value: "email", type: "string"}, 
    {label: "ContactNo", value: "contactNo", type: "string"}, 
    {label: "MediaSource", value: "mediaSourceName", type: "string"}, 
    {label: "MadeBy", value: "madeBy", type: "string"}, 
    {label: "MadeOn", value: "madeOn", type: "date"}, 
    {label: "SourceType", value: "appointmentSourceType", type: "string"}, 
    {label: "Status", value: "appointmentStatus", type: "status"}, 
  ];
 
  const aryRoomUtilizationDetailWithPermission : ObjectForReportRow[] = [
    ...aryRoomUtilizationDetail,
    {label: "Action", value: "appointmentId", type: "action"}
  ]

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    setYearList(getYears());
    setMonthList(getMonths());
    
    async function fetchRoomUtilization() {
			setLoading(true);
      setLoadingDropdownRefresh(true);

      let aryAPI: any = [
        getBranchDropDown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      if (yearParams && monthParams && branchParams) {
        setSelectedYear(Number(yearParams));
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, { value: Number(monthParams) });
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }
        let indexBranch = _.findIndex(resultAPI[0], { id: branchParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        let subBrandIdTemp: number | string = " ";
        if (subBrandParams) {
          let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
          subBrandListTemp.map((value) => {
            if (subBrandParams == value.id) {
              setSelectedSubBrandName(value.name)
              setSelectedSubBrandId(value.id);
              subBrandIdTemp = value.id;
            }
          })
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.roomUtilization}` });
        await getRoomUtilizationList({ branchId: branchParams, year: Number(yearParams), month: Number(monthParams), subBrandId: subBrandIdTemp });
      }

			setLoading(false);
      setLoadingDropdownRefresh(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageAppointmentReport], true)) {
      return;
    }
    
    fetchRoomUtilization();

    return (()=> {
      setRoomUtilizationList([]);
    })
  }, [])

  const fetchRoomUtilizationWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }
    
    setLocalLoading(true);
    if (blnExport) {
      await exportRoomUtilization({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId })
    }
    else {
      await getRoomUtilizationList({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
	};

  //Table Content UI
  const RoomUtilizationColumns = [
    { 
      dataField: "roomId", //field name from array to display
      text: "roomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "roomName",
      text: intl.formatMessage({ id: "Room" }).toUpperCase(),
    },
    {
      dataField: "totalHeadCount",
      text: intl.formatMessage({ id: "TotalHeadCount" }).toUpperCase(),
    },
    {
      dataField: "totalHours",
      text: intl.formatMessage({ id: "TotalOperatedHours" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.totalHours.toFixed(2)}
        </div>
      ),
    },
    {
      dataField: "utilizedHours",
      text: intl.formatMessage({ id: "UtilizedHours" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.utilizedHours.toFixed(2)}
        </div>
      ),
    },
    {
      dataField: "utilizedPercentage",
      text: intl.formatMessage({ id: "UtilizedPercentage" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.utilizedPercentage.toFixed(2)}
        </div>
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "AppointmentDetails" })}
        rowFieldName={"appointments"}
        rowColumns={blnPermissionManageAppointment ? aryRoomUtilizationDetailWithPermission : aryRoomUtilizationDetail}
        keyFieldName={"randomId"}
        statusColorFunction={appointmentStatusColor}
        actionTitle={intl.formatMessage({ id: "View" })}
        actionPathName={`/${RoutesList.appointment}/view/`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "RoomOccupancyDetails"})})}
        title={intl.formatMessage({ id: "RoomOccupancyDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppointmentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchRoomUtilizationWithLoading(true)
            }}
          />
        }>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  // styles={{marginLeft: '25px'}}
                  options={monthList}
                  initialLabel={selectedMonth}
                  initialValue={selectedMonthValue}
                  setFieldLabel={setSelectedMonth}
                  setFieldValue={setSelectedMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchRoomUtilizationWithLoading(false)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={RoomUtilizationColumns}
            data={roomUtilizationList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "RoomOccupancyDetails"})})}
            objSorted={defaultSorted}
            keyField={"roomId"}
            branchId={selectedBranchId}
            selectedYear={selectedYear}
            selectedMonth={selectedMonthValue}
            subBrandId={selectedSubBrandId}
            requestAPI={getRoomUtilizationList}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(RoomUtilization);
