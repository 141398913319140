import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Link } from 'react-router-dom';
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { ProductListObject } from "../../../app/models/product";

const ReservedProductReversalSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, staticReferenceStore, customerStore, productStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { reservedProductReversalSummaryList, getReservedProductReversalSummaryList, setReservedProductReversalSummaryList, exportReservedProductReversalSummary } = reportStockStore; 
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getProductDropdown } = productStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface | undefined>(undefined);
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [selectedProduct, setSelectedProduct] = useState<string>(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState<string>(" ");
  const [localProductDropdownList, setLocalProductDropdownList] = useState<ProductListObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  useEffect(() => {
    async function fetchReservedProductReversalSummary() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown({ productType: 1, isActive: true }, false, true),
        getProductDropdown({ productType: 2, isActive: true }, false, true)
      ];      
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      let productListTemp : ProductListObject[] = _.merge(resultAPI[1], resultAPI[2]);
      productListTemp = _.orderBy(productListTemp, ["displayOrder", "salesCategoryName", "name"], ['asc', 'desc', 'asc']);
      setLocalProductDropdownList(arraySpliceInAllValue(productListTemp, AllProduct, false, "name", "id"));
		  setLoading(false);
      setInitialCallAPI(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }
    
    fetchReservedProductReversalSummary();

    return (()=> {
      setReservedProductReversalSummaryList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchReservedProductReversalSummaryWithLoading = async () => {
    setLocalLoading(true);
    setCurrentPage(1);
    await getReservedProductReversalSummaryList({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      salesOrderNo: validation.values.salesOrderNo,
      productId: selectedProductId,
      dateRange: selectedDateRange,
    });
    setLocalLoading(false);
	};

  async function exportExchangeToCreditDetail() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }

    setLocalLoading(true);
    await exportReservedProductReversalSummary({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      salesOrderNo: validation.values.salesOrderNo,
      dateRange: selectedDateRange,
      productId: selectedProductId
    });
    setLocalLoading(false);
  }

  //Table Content UI
  const ReservedProductReversalSummaryColumns = [
    {
      dataField: "virtualWarehouseReversalNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase()
    },
    {
      dataField: "reservedDate",
      text: intl.formatMessage({ id: "ReservedDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.reservedDate ? moment(row.reservedDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</>, 
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionViewCustomer
          ?
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerName || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: { minWidth: "150px" }
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      headerStyle: { minWidth: "200px" }
    },
    {
      dataField: "virtualWarehouseNo",
      text: intl.formatMessage({ id: "ReservedProductNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerVirtualWarehouse
          ?
          <Link to={row.virtualWarehouseId ? `/${RoutesList.customerVirtualWarehouse}/view/${row.virtualWarehouseId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.virtualWarehouseNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.virtualWarehouseNo || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.salesOrderNo || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase()
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase()
    },
  ];

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
  }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ReservedProductReversalSummary"})})}
        title={intl.formatMessage({ id: "ReservedProductReversalSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportExchangeToCreditDetail()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Reserved-Product-Reversal-Summary.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown 
                  &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "ReservedDate" }) })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Product" })}
                  name="product"
                  options={localProductDropdownList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedProduct}
                  initialValue={selectedProductId}
                  setFieldLabel={setSelectedProduct}
                  setFieldValue={setSelectedProductId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="salesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchReservedProductReversalSummaryWithLoading()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ReservedProductReversalSummaryColumns}
            data={reservedProductReversalSummaryList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ReservedProductReversalSummary"})})}
            objSorted={defaultSorted}
            keyField={"virtualWarehouseReversalNo"}
            requestAPI={getReservedProductReversalSummaryList}
            branchId={selectedBranchId}
            customerId={selectedCustomerId}
            salesOrderNo={validation.values.salesOrderNo}
            dateRange={selectedDateRange}
            productId={selectedProductId}
            setLocalLoading={setLocalLoading}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ReservedProductReversalSummary);