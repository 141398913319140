import _ from 'lodash';
import React, { useEffect } from 'react'
import SignatureCanvas from "react-signature-canvas";
import { FormFeedback, Label } from 'reactstrap';
import MyButton from './MyButton';

interface Props {
  title?: string;
  successMessage: string;
  validation: any;
  disabled: boolean;
  isSubmitting: boolean;
  className?: string;
  signatureFieldName?: string;
  errorFieldName?: string;
}


export default function SignaturePad(props: Props) {
  let canvasRef;
  let signatureFieldName = props.signatureFieldName || "signatureImage"
  let errorFieldName = props.errorFieldName || "signatureError"

  useEffect(() => {
    if (canvasRef && !props.isSubmitting) {
      canvasRef.fromDataURL(props.validation.values[signatureFieldName]);
    }
  }, [props.isSubmitting])
  
  return (
    <div style={{ pageBreakInside: "avoid"}} className={props.className || ""}>
      <div className="py-2 margin-top-36">
        <div className='flex-direction-row'>
          {!props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
          <h3 className="font-size-15 font-weight-bold">{props.title || "Receiver's Signature"}</h3>
        </div>
      </div>
      <div 
        style={{ width: "400px", height: "180px", borderBottom: "1px solid #000" }}>
        {
          props.disabled || props.isSubmitting
          ?
          <img src={props.validation.values[signatureFieldName]} width={400} height={180}/>
          :
          <SignatureCanvas
            ref={ref => (canvasRef = ref)}
            onEnd={e => {
              let valuesTemp = _.cloneDeep(props.validation.values);
              valuesTemp[signatureFieldName] = canvasRef.toDataURL();
              valuesTemp[errorFieldName] = false
              props.validation.setValues(valuesTemp)
            }}
            canvasProps={{
              width: 400,
              height: 180
            }}
          />
        }
      </div>
      {props.validation.values?.[errorFieldName] ? (
        <FormFeedback type="invalid">{"Please Sign Your Signature before submit"}</FormFeedback>
      ) : null}
      {!Boolean(props.successMessage) && !props.disabled &&
        (
          <div className="d-print-none">
            <MyButton
              type="button"
              class="btn btn-danger mt-4"
              content="Clear Signature"
              disable={props.validation.isSubmitting || props.isSubmitting}
              loading={props.validation.isSubmitting || props.isSubmitting}
              onClick={()=> {
                if (canvasRef) {
                  props.validation.setFieldValue([signatureFieldName], "")
                  canvasRef.clear()
                }
              }}
            />
          </div>
        )
      }
    </div>
  )
}
