import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { SocsoListObject } from '../../app/models/socso';
import DeleteModal from "../../app/components/modal/DeleteModal";
import SocsoDetail from "./SocsoDetail";
import { checkPermission, getYears } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import MyAddButton from "../../app/components/form/MyAddButton";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";

const Socso = () => {
  const intl = useIntl();
  //Use Store
  const { socsoStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { socsoList, getSocso, deleteSocso } = socsoStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<SocsoListObject | undefined>(undefined);
  const [socsoDetailModal, setSocsoDetailModal] = useState(false);
  const [selectedSocsoDetailID, setSelectedSocsoDetailID] = useState("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewSocso, setViewSocso] = useState(false);
  const pageSize = Constants.defaultPageSize;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
  }, [])

  useEffect(() => {
    if (selectedYear) {
      if (!checkPermission([PermissionConstants.ManageSocso], true)) {
        return;
      }
      fetchSocsoAPI(true);
    }
  }, [selectedYear])

  async function fetchSocsoAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getSocso({ pageNumber: currentPage, pageSize: pageSize, year: selectedYear })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: SocsoListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const SocsoColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.from}`}</div>
      ),
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.to}`}</div>
      ),
    },
    {
      dataField: "employer",
      text: intl.formatMessage({ id: "Employer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.employer}`}</div>
      ),
    },
    {
      dataField: "employee",
      text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.employee}`}</div>
      ),
    },
    {
      dataField: "employerOnly",
      text: intl.formatMessage({ id: "EmployerOnly" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.employerOnly}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageSocso, PermissionConstants.UpdateSocso, PermissionConstants.DeleteSocso]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManageSocso])
              &&
              <TableActionButton 
                type={'view'}
                onClick={() => {
                  setSelectedSocsoDetailID(row.id);
                  setViewSocso(true);
                  setSocsoDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.UpdateSocso])
              &&
              <TableActionButton 
                type={'edit'}
                onClick={() => {
                  setSelectedSocsoDetailID(row.id);
                  setViewSocso(false);
                  setSocsoDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.DeleteSocso])
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Socso" }) })}
        title={intl.formatMessage({ id: "Socso" })}
        addButton={
          checkPermission([PermissionConstants.CreateSocso])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Socso" }) })}
            onClick={() => {
              setSelectedSocsoDetailID("")
              setSocsoDetailModal(true);
            }}
            disable={localLoading || loading} />
        }>
        <SingleColumnRowParent
          blnDropDown={true}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Years" })}
            name="years"
            options={yearList}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
        </SingleColumnRowParent>
        {
          localLoading && !socsoDetailModal
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={SocsoColumns}
              data={socsoList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Socso" }) })}
              objSorted={defaultSorted}
              isModal={socsoDetailModal}
              selectedYear={selectedYear}
              requestAPI={getSocso} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteSocso(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (socsoList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getSocso({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear })
            setLoading(false);
          }} />
        {
          socsoDetailModal &&
          <SocsoDetail
            id={selectedSocsoDetailID}
            blnShow={socsoDetailModal}
            viewSocso={viewSocso}
            setModal={setSocsoDetailModal}
            reloadData={fetchSocsoAPI}
            yearList={yearList}
            selectedYear={selectedYear}
          />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Socso);
