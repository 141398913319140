import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { PCBListObject } from '../../app/models/pcb';
import DeleteModal from "../../app/components/modal/DeleteModal";
import PCBDetail from "./PCBDetail";
import { checkPermission, getYears } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import MyAddButton from "../../app/components/form/MyAddButton";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";


const PCB = () => {
  const intl = useIntl();
  //Use Store
  const { pcbStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { pcbList, getPCB, deletePCB } = pcbStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PCBListObject | undefined>(undefined);
  const [pcbDetailModal, setPCBDetailModal] = useState(false);
  const [selectedPCBDetailID, setSelectedPCBDetailID] = useState("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewPCB, setViewPCB] = useState(false);
  const pageSize = Constants.defaultPageSize;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
  }, [])

  useEffect(() => {
    if (selectedYear) {
      if (!checkPermission([PermissionConstants.ManagePcb], true)) {
        return;
      }
      fetchPCBAPI(true);
    }
  }, [selectedYear])

  async function fetchPCBAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPCB({ pageNumber: currentPage, pageSize: pageSize, year: selectedYear })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: PCBListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const PCBColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.from}`}</div>
      ),
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.to}`}</div>
      ),
    },
    {
      dataField: "firstChargeable",
      text: intl.formatMessage({ id: "FirstChargeable" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.firstChargeable}`}</div>
      ),
    },
    {
      dataField: "rate",
      text: intl.formatMessage({ id: "Rate" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.rate}`}</div>
      ),
    },
    {
      dataField: "category1",
      text: intl.formatMessage({ id: "Category1" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.category1}`}</div>
      ),
    },
    {
      dataField: "category2",
      text: intl.formatMessage({ id: "Category2" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.category2}`}</div>
      ),
    },
    {
      dataField: "category3",
      text: intl.formatMessage({ id: "Category3" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.category3}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePcb, PermissionConstants.UpdatePcb, PermissionConstants.DeletePcb]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManagePcb])
              &&
              <TableActionButton 
                type={'view'}
                onClick={() => {
                  setSelectedPCBDetailID(row.id)
                  setViewPCB(true);
                  setPCBDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.UpdatePcb])
              &&
              <TableActionButton 
                type={'edit'}
                onClick={() => {
                  setSelectedPCBDetailID(row.id)
                  setViewPCB(false);
                  setPCBDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.DeletePcb])
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PCB" }) })}
        title={intl.formatMessage({ id: "PCB" })}
        addButton={
          checkPermission([PermissionConstants.CreatePcb])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PCB" }) })}
            onClick={() => {
              setSelectedPCBDetailID("")
              setPCBDetailModal(true);
            }}
            disable={localLoading || loading} />
        }>
        <SingleColumnRowParent
          blnDropDown={true}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Years" })}
            name="years"
            options={yearList}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
        </SingleColumnRowParent>
        {
          localLoading && !pcbDetailModal
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PCBColumns}
              data={pcbList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PCB" }) })}
              objSorted={defaultSorted}
              isModal={pcbDetailModal}
              selectedYear={selectedYear}
              requestAPI={getPCB} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deletePCB(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (pcbList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getPCB({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear })
            setLoading(false);
          }} />
        {
          pcbDetailModal &&
          <PCBDetail
            id={selectedPCBDetailID}
            blnShow={pcbDetailModal}
            setModal={setPCBDetailModal}
            reloadData={fetchPCBAPI}
            yearList={yearList}
            selectedYear={selectedYear}
            viewPCB={viewPCB}
          />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(PCB);
