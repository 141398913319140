import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, returnPriceWithCurrency, checkPermission, getBranchId, getBranchName, getBranchUser, isBranchManager } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import CardInfo from "../../../app/components/form/CardInfo";
import AccountSwitchOutlineIcon from 'mdi-react/AccountSwitchOutlineIcon';
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';
import { history } from "../../..";
import _ from "lodash";
import EnterPasscodeModal from "../../../app/components/modal/EnterPasscodeModal";

const ConsultantSalesCommission = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, branchCommissionStore, settingsStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, successMessage, errorMessage, setErrorMessage } = commonStore;
  const { consultantSalesCommission, setConsultantSalesCommission, getConsultantSalesCommission, exportConsultantSalesCommission, exportCommissionReportDetails } = reportSalesStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { addBranchCommission } = branchCommissionStore;
  const { generalSettings, getGeneralSettings } = settingsStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionCreateBranchCommission = checkPermission([PermissionConstants.CreateBranchCommission]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnIsShowAppolous = generalSettings?.isShowAppolous;
  const [modalEnterPasscode, setModalEnterPasscode] = useState<boolean>(false);
  const [actionCallAPI, setActionCallAPI] = useState<string>(Constants.apply);

  const aryConsultantSalesConsultantDetail : ObjectForReportRow[] = [
    {label: "Branch", value: "branchName", type: "string"}, 
    {label: "BranchSales", value: "entitledBranchSales", type: "currency"}, 
    {label: "PackageSales", value: "entitledPackageSales", type: "currency"},
    {label: "ProductSales", value: "entitledProductSales", type: "currency"},  
    {label: "ProductCommission", value: "productCommission", type: "currency"},
    {label: "AngPaoBonus", value: "angpaoBonus", type: "currency"},
    {label: "ProratedInfo", value: "proratedInfo", type: "string"}, 
    {label: "Action", value: "routeUrl", type: "action"}
  ];

  const paramsSearch = useLocation().search;
  const yearParams = new URLSearchParams(paramsSearch).get('year');
  const monthParams = new URLSearchParams(paramsSearch).get('month');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchConsultantSalesSummary() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getGeneralSettings()
      ];
      
      let resultAPI = await Promise.all(aryAPI);
      if (yearParams && monthParams && branchParams) {
        setSelectedYear(Number(yearParams));
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, {value: Number(monthParams)});
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }
        let indexBranch = _.findIndex(resultAPI[0], {id: branchParams});
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.consultantSalesCommission}` });
        await getConsultantSalesCommission({pageSize: pageSize, pageNumber: 1, branchId: branchParams, year: Number(yearParams), month: Number(monthParams)});
      }
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchConsultantSalesSummary();

    return (()=> {
      setConsultantSalesCommission(undefined);
    })
  }, [])

  useEffect(()=> {
    if (errorMessage === "Account-007" && !modalEnterPasscode) {
      setModalEnterPasscode(true);
    } 
  }, [errorMessage])

  const fetchConsultantSalesCommissionWithLoading = async (passcode: number | string, action?: string) => {
    if (selectedBranchId) {
      setLocalLoading(true);
      setCurrentPage(1);
      let actionTemp = action ? action : actionCallAPI;
      if (actionTemp === Constants.export) {
        await exportConsultantSalesCommission({pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear || 0, month: selectedMonthValue, passcode: passcode})
      }
      else if (actionTemp === Constants.generate) {
        await addBranchCommission({branchId: selectedBranchId, year: selectedYear || 0, month: selectedMonthValue, passcode: passcode})
      }
      else {
        await getConsultantSalesCommission({pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, passcode: passcode});
      }
      setLocalLoading(false);
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
    }
	};

  async function exportCommissionReportDetailsAPI () {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    setLocalLoading(true);
    let selectedDateRange = {fromDate: moment(`${selectedYear}-${selectedMonthValue}`, "YYYY-M").startOf('month').format(Constants.displayDateFormat), toDate: moment(`${selectedYear}-${selectedMonthValue}`, "YYYY-M").endOf('month').format(Constants.displayDateFormat)};
    await exportCommissionReportDetails({ branchId: selectedBranchId, customerId: "", fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, salesOrderNo: "" })
    setLocalLoading(false);
  }

  //Table Content UI
  const ConsultantSalesSummaryColumns = [
    { 
      dataField: "employeeId", //field name from array to display
      text: "employeeId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "employeeName",
      text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
    },
    {
      dataField: "postionName",
      text: intl.formatMessage({ id: "Position" }).toUpperCase(),
    },
    {
      dataField: "totalPackageCommission",
      text: intl.formatMessage({ id: "TotalPackageCommission" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPackageCommission)}</div>
      ),
    },
    {
      dataField: "totalProductCommission",
      text: intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalProductCommission)}</div>
      ),
    },
    {
      dataField: "totalAngPaoBonus",
      text: intl.formatMessage({ id: "TotalAngPaoBonus" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAngPaoBonus)}</div>
      ),
    },
    {
      dataField: "commissionInfo",
      text: intl.formatMessage({ id: "CommissionInfo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.commissionInfo || Constants.emptyData}</div>
      ),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ConsultantSalesCommission" })}
        rowFieldName={"commissionDetails"}
        rowColumns={aryConsultantSalesConsultantDetail}
        keyFieldName={"branchId"}
        actionTitle={intl.formatMessage({ id: "ViewConsultantSales" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.consultantSales}`}
      />
    ),
    showExpandColumn: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  const displayPaymentAmountData = (fullPaymentTitle: string, valueFullPaymentAmount: number, partialPaymentTitle: string, valuePartialPaymentAmount: number, appolousPaymentTitle: string, valueAppolousPaymentAmount: number ) => {
    return (
      <>
        <Row className="list-inline" style={{marginTop:"10px",marginBottom: "-10px"}}> 
          <Col className="list-inline-item me-3"> 
          <h6 className="font-size-13 text-muted">
            {fullPaymentTitle}: <br/>
            {returnPriceWithCurrency(valueFullPaymentAmount)} 
          </h6> </Col>&nbsp; 
          <Col className="list-inline-item me-3"> 
            <h6 className="font-size-13 text-muted"> 
              {partialPaymentTitle}: <br/>
              {" "} {returnPriceWithCurrency(valuePartialPaymentAmount)} 
            </h6> 
          </Col>
        </Row> 
        {
          blnIsShowAppolous
          &&
          <Row className="mt-3">
            <Col className="list-inline-item me-3"> 
              <h6 className="font-size-13 text-muted"> 
                {appolousPaymentTitle}: <br/>
                {" "} {returnPriceWithCurrency(valueAppolousPaymentAmount)} 
              </h6> 
            </Col>
          </Row>
        }
      </>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ConsultantSalesCommission"})})}
        title={intl.formatMessage({ id: "ConsultantSalesCommission" })}
        breadCrumbList={breadCrumbList}
        addButton={
          <>
            <MyButton
              type="button"
              class="btn btn-success margin-right-8"
              content={intl.formatMessage({ id: "ExportDetails" }).toUpperCase()}
              disable={localLoading || loading}
              onClick={() => {
                exportCommissionReportDetailsAPI()
              }}
            />
            {
              blnPermissionExportSalesReport
              &&
              <MyButton
                type="button"
                class="btn btn-success"
                content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                disable={localLoading || loading}
                onClick={()=> {
                  if (!isBranchManager()) {
                    fetchConsultantSalesCommissionWithLoading("", Constants.export)
                  }
                  else {
                    setActionCallAPI(Constants.export);
                    setModalEnterPasscode(true);
                  }
                }}/>
            }
          </>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Consultant-Sales-Commission.aspx`}>
        {
          !loading
          &&
          <Row>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    if (!isBranchManager()) {
                      fetchConsultantSalesCommissionWithLoading("", Constants.apply)
                    }
                    else {
                      setActionCallAPI(Constants.apply);
                      setModalEnterPasscode(true);
                    }
                  }}
                />
                {          
                  blnPermissionCreateBranchCommission
                  &&
                  <MyButton
                    content={intl.formatMessage({ id: "Generate" })}
                    class={`btn btn-success ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"} margin-left-8`}
                    onClick={()=> {
                      if (!isBranchManager()) {
                        fetchConsultantSalesCommissionWithLoading("", Constants.generate)
                      }
                      else {
                        setActionCallAPI(Constants.generate);
                        setModalEnterPasscode(true);
                      }
                    }}
                    disable={localLoading || loading || Boolean(successMessage)}
                    loading={loading} />
                }
              </>
            </Col>
          </Row>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <>
            {
              consultantSalesCommission
              &&
              <>
                <Row className="padding-top-10">
                <Col xl="4" lg="4" sm="12" xs="12">
                  <div onClick={async () => {
                    window.open(`/${RoutesList.report}/${RoutesList.sharedSalesSummary}${consultantSalesCommission.subBranchSalesRouteUrl}`)
                  }}>
                  <CardInfo
                    title={intl.formatMessage({ id: "TotalBranchSales" }).toUpperCase()}
                    value={returnPriceWithCurrency(consultantSalesCommission.totalBranchSales)}
                    blnArrow={true}
                    iconUI={<AccountSwitchOutlineIcon className="margin-left-8" size={20} color={Constants.black}/>}
                    iconId="sub-branch-sales-tool-tip"
                    iconToolTipText={`${intl.formatMessage({ id: "SubBranchSalesToolTip" })}`}
                    cardColor={"card-blue"}
                    displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "ActualAmt" }), consultantSalesCommission.totalActualBranchSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), consultantSalesCommission.totalBranchSales, intl.formatMessage({ id: "AppolousSalesAmt" }), consultantSalesCommission.totalAppolousSalesAdjustment )} />
                  </div>
                </Col>
                <Col xl="4" lg="4" sm="12" xs="12">
                  <CardInfo
                    title={intl.formatMessage({ id: "TotalNettSales" }).toUpperCase()}
                    value={returnPriceWithCurrency(consultantSalesCommission.totalBranchPackageSales)}
                    displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "ActualAmt" }), consultantSalesCommission.totalActualBranchPackageSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), consultantSalesCommission.totalBranchPackageSales, intl.formatMessage({ id: "AppolousSalesAmt" }), consultantSalesCommission.totalAppolousPackageSalesAdjustment )}/>
                </Col>
                <Col xl="4" lg="4" sm="12" xs="12">
                  <CardInfo
                    title={intl.formatMessage({ id: "TotalProductSales" }).toUpperCase()}
                    value={returnPriceWithCurrency(consultantSalesCommission.totalBranchProductSales)}
                    cardColor={"card-yellow"}
                    displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "FullPayment" }), consultantSalesCommission.totalActualBranchProductSales, intl.formatMessage({ id: "PartialPayment" }), consultantSalesCommission.totalPartialPaymentBranchProductSales, intl.formatMessage({ id: "AppolousSalesAmt" }), consultantSalesCommission.totalAppolousProductSalesAdjustment )}/>
                </Col>
              </Row>
              <Row>
                <Col xl="6" lg="6" sm="12" xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3 flex-direction-row">
                        {intl.formatMessage({ id: "SalesAdjustment" }).toUpperCase()}
                        {
                          <div onClick={async () => {
                            window.open(`/${RoutesList.report}/${RoutesList.salesAdjustmentHistory}${consultantSalesCommission.salesAdjustmentRouteUrl}`)
                          }}>
                            <div className="pointer-clickable">
                              <ArrowTopRightBoldBoxOutlineIcon className="margin-left-4" id="viewTotalAppolousPackageSalesAdjustment" size={18} color={Constants.tabBlue}/>
                              <UncontrolledTooltip placement="top" target={"viewTotalAppolousPackageSalesAdjustment"}>
                                {intl.formatMessage({ id: "ViewSalesAdjustmentHistoryReport" })}
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        }
                      </CardTitle>
                      <hr className="m-0"/>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalSalesAdjustment" }).toUpperCase()} :</th>
                              <td className={`${(consultantSalesCommission.totalPackageSalesAdjustment + consultantSalesCommission.totalProductSalesAdjustment) !== 0 ? (consultantSalesCommission.totalPackageSalesAdjustment + consultantSalesCommission.totalProductSalesAdjustment) > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                {returnPriceWithCurrency(consultantSalesCommission.totalPackageSalesAdjustment + consultantSalesCommission.totalProductSalesAdjustment)}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalPackageSalesAdjustment" }).toUpperCase()} :</th>
                              <td className={`${consultantSalesCommission.totalPackageSalesAdjustment !== 0 ? consultantSalesCommission.totalPackageSalesAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                {returnPriceWithCurrency(consultantSalesCommission.totalPackageSalesAdjustment)}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalProductSalesAdjustment" }).toUpperCase()} :</th>
                              <td className={`${consultantSalesCommission.totalProductSalesAdjustment !== 0 ? consultantSalesCommission.totalProductSalesAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                {returnPriceWithCurrency(consultantSalesCommission.totalProductSalesAdjustment)}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalProductCommissionAdjustment" }).toUpperCase()} :</th>
                              <td className={`${consultantSalesCommission.totalProductCommissionAdjustment !== 0 ? consultantSalesCommission.totalProductCommissionAdjustment > 0 ? "text-green-color fw-bold" : "text-red-color fw-bold" : ""} flex-direction-row`}>
                                {returnPriceWithCurrency(consultantSalesCommission.totalProductCommissionAdjustment)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6" lg="6" sm="12" xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">{intl.formatMessage({ id: "Commissions" }).toUpperCase()}</CardTitle>
                      <hr className="m-0"/>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalCommission" }).toUpperCase()} :</th>
                              <td>{returnPriceWithCurrency(consultantSalesCommission.totalCommission)}</td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalPackageCommission" }).toUpperCase()} :</th>
                              <td>{returnPriceWithCurrency(consultantSalesCommission.totalPackageCommission)}</td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase()} :</th>
                              <td>{returnPriceWithCurrency(consultantSalesCommission.totalProductCommission)}</td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: "TotalAngPaoBonus" }).toUpperCase()} :</th>
                              <td>{returnPriceWithCurrency(consultantSalesCommission.totalAngPaoBonus)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              </>
            }
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ConsultantSalesSummaryColumns}
              data={consultantSalesCommission ? consultantSalesCommission.consultantCommissions : []}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ConsultantSalesCommission"})})}
              objSorted={defaultSorted}
              keyField={"employeeId"}
              requestAPI={getConsultantSalesCommission}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              expandRow={expandRow}
              hideSearchBar={true}/>
          </>
        }
      </ListViewLayout>
      {
        modalEnterPasscode
        &&
        <EnterPasscodeModal 
          intl={intl}
          actionTitle={actionCallAPI}
          showPrompt={modalEnterPasscode}
          setShowPrompt={setModalEnterPasscode}
          onPrimaryClick={fetchConsultantSalesCommissionWithLoading} />
      }
    </div>
  );
}

export default observer(ConsultantSalesCommission);
