import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import { CustomerAccessRequestListObject } from "../../app/models/customerAccessRequest";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import moment from "moment";
import { customerAccessRequestStatusColor, checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, returnPriceWithCurrency } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomerAccessRequestDetailModal from "./Modal/CustomerAccessRequestDetailModal";
import GeneralInput from "../../app/components/form/GeneralInput";
import { Link, useParams } from "react-router-dom";
import { RoutesList } from "../../app/constants/RoutesList";

const CustomerAccessRequest = () => {
  const intl = useIntl();

  //Use Params
  const { id, branchId } = useParams();

  //Use Store
  const { customerAccessRequestStore, staticReferenceStore, commonStore, branchStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { customerAccessRequestList, getCustomerAccessRequest, getCustomerAccessRequestWithId, updateCustomerAccessRequest, deleteCustomerAccessRequest, setCustomerAccessRequestList } = customerAccessRequestStore;
  const { accessRequestStatus, getStaticReferenceWithType } = staticReferenceStore;
  const { branchForHeaderDropDown } = branchStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CustomerAccessRequestListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Requests" }), urlPath: "" }];
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCustomerAccessRequestStatus, setSelectedCustomerAccessRequestStatus] = useState<number | string>(AllStatus);
  const [selectedCustomerAccessRequestStatusId, setSelectedCustomerAccessRequestStatusId] = useState<number | string | undefined>(" ");
  const [customerAccessRequestStatusList, setCustomerAccessRequestStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedOriginalBranchId, setSelectedOriginalBranchId] = useState(getBranchId());
  const [selectedRequestBranchId, setSelectedRequestBranchId] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [customerAccessRequestDetailModal, setCustomerAccessRequestDetailModal] = useState(false);
  const [selectedCustomerAccessRequestDetail, setSelectedCustomerAccessRequestDetail] = useState<any>([]);
  const blnPermissionDeleteCustomerAccessRequest = checkPermission([PermissionConstants.DeleteCustomerAccessRequest]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchCustomerAccessRequestListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchCustomerAccessRequestAPI(false),
        getStaticReferenceWithType(Constants.accessRequestStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setCustomerAccessRequestStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id && branchId) {
        let indexBranchTemp = -1;
        if (!getBranchUser()) {
          indexBranchTemp = _.findIndex(branchForHeaderDropDown, { id: branchId});
        }

        if ((getBranchUser() && getBranchId() === branchId) || indexBranchTemp > -1) {
          await onClickViewDetail(id);
        }
        
        window.history.replaceState(null, "", `/${RoutesList.customerAccessRequest}`);
      }
    }

    if (!checkPermission([PermissionConstants.ManageCustomerAccessRequest], true)) {
      return;
    }

    fetchCustomerAccessRequestListAPI();

    return (()=> {
      setCustomerAccessRequestList([]);
    })
  }, []);

  useEffect(() => {
    if (
      (selectedCustomerAccessRequestStatusId || selectedCustomerAccessRequestStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchCustomerAccessRequestAPI(true);
    }
  }, [selectedCustomerAccessRequestStatusId, selectedRequestBranchId, selectedOriginalBranchId, triggerSearch]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setCustomerAccessRequestDetailModal(false);
        fetchCustomerAccessRequestAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchCustomerAccessRequestAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerAccessRequest({
      pageNumber: currentPage,
      pageSize: pageSize,
      requestBranchId: selectedRequestBranchId,
      originalBranchId: selectedOriginalBranchId,
      customerAccessRequestStatus: selectedCustomerAccessRequestStatusId,
      customerId: "",
      customerName: validation.values.customerName,
      customerNo: validation.values.customerNo
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CustomerAccessRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "requestBranchName",
      text: intl.formatMessage({ id: "RequestBranch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.requestBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            && 
            row.originalBranchId == getBranchId()
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
    },
    {
      dataField: "originalBranchName",
      text: intl.formatMessage({ id: "OriginalBranch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.originalBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.from !== Constants.invalidDate ? `${moment(row.from).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.from !== Constants.invalidDate ? moment(row.from).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.to !== Constants.invalidDate ? `${moment(row.to).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.to !== Constants.invalidDate ? moment(row.to).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "approveBy",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.approveBy || Constants.emptyData}</div>,
    },
    {
      dataField: "accessRequestStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.accessRequestStatus}
          statusColorFunction={customerAccessRequestStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to="#"
              onClick={() => onClickViewDetail(row.id)}
              type="viewModal" />
            {
              blnPermissionDeleteCustomerAccessRequest
              &&
              row.accessRequestStatus == Constants.pending
              &&
              row.requestBranchId == getBranchId()
              &&
              <TableActionButton
                onClick={() => onClickDelete(row)}
                type={'delete'} />
            }
          </div>

        </>
      ),
    },
  ];

  const onClickDelete = async (row: CustomerAccessRequestListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickViewDetail = async (customerAccessRequestId: any) => {
    setLoading(true);
    let resultPreviewRefundCredit = await getCustomerAccessRequestWithId(customerAccessRequestId);
    setSelectedCustomerAccessRequestDetail(resultPreviewRefundCredit)
    setCustomerAccessRequestDetailModal(true);
    setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerAccessRequest" }) })}
        title={intl.formatMessage({ id: "CustomerAccessRequest" })}
        breadCrumbList={breadCrumbList}>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerAccessRequest" }) })}
              name="customerAccessRequestStatus"
              options={customerAccessRequestStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedCustomerAccessRequestStatus}
              initialValue={selectedCustomerAccessRequestStatusId}
              setFieldLabel={setSelectedCustomerAccessRequestStatus}
              setFieldValue={setSelectedCustomerAccessRequestStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-26" : "margin-bottom-4"}`}>
            <div className="btn-group" role="group">
              <input type="radio" className="btn-check" name="blnRequestFromOwn" id="blnRequestFromOwnFalse" autoComplete="off" defaultChecked disabled={localLoading || loading}
                onClick={()=> { 
                  setCurrentPage(1);
                  setSelectedOriginalBranchId(getBranchId())
                  setSelectedRequestBranchId("")
                }}/>
              <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth}} htmlFor="blnRequestFromOwnFalse">{intl.formatMessage({ id: "RequestByOtherBranch" })}</label>
              <input type="radio" className="btn-check" name="blnRequestFromOwn" id="blnRequestFromOwnTrue" autoComplete="off" disabled={localLoading || loading}
                onClick={()=> { 
                  setCurrentPage(1);
                  setSelectedOriginalBranchId("")
                  setSelectedRequestBranchId(getBranchId())
                }}/>
              <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth}} htmlFor="blnRequestFromOwnTrue">{intl.formatMessage({ id: "RequestByOwnBranch" })}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerName" })}
              name="customerName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterCustomerName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerNo" })}
              name="customerNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterCustomerNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
        </Row>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerAccessRequestColumns}
              data={customerAccessRequestList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerAccessRequest" }) })}
              objSorted={defaultSorted}
              requestBranchId={selectedRequestBranchId}
              originalBranchId={selectedOriginalBranchId}
              customerAccessRequestStatus={selectedCustomerAccessRequestStatusId}
              customerName={validation.values.customerName}
              customerNo={validation.values.customerNo}
              requestAPI={getCustomerAccessRequest}
            />
          </>
        )}
      </ListViewLayout>
      <DeleteModal
        displayName={""}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteCustomerAccessRequest(selectedRow?.id || "" || "");
          let currentPageTemp = currentPage;
          if (customerAccessRequestList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getCustomerAccessRequest({ pageNumber: currentPageTemp, pageSize: pageSize })
          setLoading(false);
        }} />
      {
        customerAccessRequestDetailModal &&
        <CustomerAccessRequestDetailModal
          blnShow={customerAccessRequestDetailModal}
          setModal={setCustomerAccessRequestDetailModal}
          selectedCustomerAccessRequestDetail={selectedCustomerAccessRequestDetail}
          setSelectedCustomerAccessRequestDetail={setSelectedCustomerAccessRequestDetail}
          updateCustomerAccessRequest={updateCustomerAccessRequest}
        />
      }
    </div>
  );
};

export default observer(CustomerAccessRequest);
