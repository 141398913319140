import { PaginationRequestBody } from "../models/pagination";
import { SalesTargetListObject, SalesTargetAddObject, SalesTargetUpdateObject, SalesTargetDetailObject, SalesTargetDuplicatedRequestObject } from "../models/salesTarget";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class SalesTargetStore {
  salesTargetList: SalesTargetListObject[] = [];
  salesTargetDetail: SalesTargetDetailObject | undefined = undefined;
  salesTargetPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.salesTargetList = [];
    this.salesTargetDetail = undefined;
    this.salesTargetPaginationParams= undefined;
  }

  setSalesTargetDetail = (salesTargetDetail: SalesTargetDetailObject | undefined) => {
    this.salesTargetDetail = salesTargetDetail;
  }

  setSalesTargetPaginationParams = (salesTargetPaginationParams: PaginationRequestBody | undefined) => {
    this.salesTargetPaginationParams = salesTargetPaginationParams;
  }

  getSalesTarget = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setSalesTargetPaginationParams(PaginationRequestBody);
    try{
      const resultSalesTarget = await agent.SalesTarget.salesTargetList(PaginationRequestBody);
      runInAction(() => {
        this.salesTargetList = resultSalesTarget.data;
        store.commonStore.setTotalItem(resultSalesTarget.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesTargetList = [];
    }
  }

  getSalesTargetWithId = async (id: string) => {
    try{
      const resultSalesTargetDetail = await agent.SalesTarget.salesTargetDetail(id);
      runInAction(() => {
        this.salesTargetDetail = resultSalesTargetDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesTargetDetail = undefined;
    }
  }

  addSalesTarget = async (salesTargetRequestBody: SalesTargetAddObject) => {
    try{
      const resutlAddSalesTarget = await agent.SalesTarget.addSalesTarget(salesTargetRequestBody);
      if (resutlAddSalesTarget.hasExactMatchSalesTarget) {
        return Promise.resolve({message: resutlAddSalesTarget.message});
      }
      else {
        store.commonStore.setSuccessMessage(`SalesTargetAddSuccess`);
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateSalesTarget = async (salesTargetRequestBody: SalesTargetUpdateObject) => {
    try{
      await agent.SalesTarget.updateSalesTarget(salesTargetRequestBody);
      store.commonStore.setSuccessMessage(`SalesTargetUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteSalesTarget  = async (id: string, name: string) => {
    try {
      await agent.SalesTarget.deleteSalesTarget(id);
      store.commonStore.setSuccessMessage(`SalesTargetDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  duplicateSalesTarget = async (salesTargetDuplicatedRequestObject: SalesTargetDuplicatedRequestObject) => {
    try {
      await agent.SalesTarget.salesTargetDuplicate(salesTargetDuplicatedRequestObject);
      store.commonStore.setSuccessMessage(`Sales Target successfully duplicated for year ${salesTargetDuplicatedRequestObject.startYear} till year ${salesTargetDuplicatedRequestObject.endYear}.`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}