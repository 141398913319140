import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Input, Label } from "reactstrap";
import "flatpickr/dist/themes/dark.css";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { DateRangeMonthDetailInterface, DateRangeMonthMinMaxInterface } from "../../models/common";
import Picker from 'react-month-picker'
import '../../../assets/css/monthRange.css';
import moment from "moment";
import { getCurrentMonthRangeForSingleYear } from "../../common/function/function";

interface Props {
	title: string;
	name: string;
	className?: string;
	disabled: boolean;
	onChangeFunction: Function;
  initialValue: DateRangeMonthDetailInterface[]; //Framework doenst suppor't empty value
  minMaxMonthObj?: DateRangeMonthMinMaxInterface;
}

export default function GeneralMonthRangeWithinSingleYear(props: Props) {
  const refMonthCalendar : any = useRef(null);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [localMonthRange, setLocalMonthRange] = useState(props.initialValue.length > 0 ? props.initialValue : getCurrentMonthRangeForSingleYear())
  const [refreshMonthCalendar, setRefreshMonthCalendar] = useState(false);
  const [selectedFirstMonth, setSelectedFirstMonth] = useState<DateRangeMonthDetailInterface | undefined>(undefined);
  const [cssBetweenRange, setCssBetweenRange] = useState("");

  useEffect(()=> {
    let cssBetweenRangeTemp = "";
    localMonthRange.map((valueMonthRangeTemp, indexMonthRangeTemp)=> {
      if (indexMonthRangeTemp !== 0 && indexMonthRangeTemp !== localMonthRange.length-1) {
        cssBetweenRangeTemp += `${cssBetweenRangeTemp} [data-id="0:${valueMonthRangeTemp.month}"] { 
          background-color: #eef0fc !important;
          color: #666  !important;
        }`
      }
    })
    setCssBetweenRange(cssBetweenRangeTemp)
  }, [localMonthRange])

  const handlePromptMonthRange = () => {
    refMonthCalendar.current.show()
  }

  const handleDismiss = (value) => {
    setSelectedFirstMonth(undefined);
    props.onChangeFunction(value);
  }

  const handleMultiChange = (yearTemp, monthTemp) => {
    let selectedFirstMonthTemp = selectedFirstMonth;
    // let selectedFirstMonthTemp = selectedFirstMonth ? monthTemp >= selectedFirstMonth.month ? selectedFirstMonth : undefined : undefined;
    let startMonthTemp = selectedFirstMonthTemp ? monthTemp >= selectedFirstMonthTemp.month ? selectedFirstMonthTemp.month : monthTemp : monthTemp;
    let blnEndMonthTemp = selectedFirstMonthTemp ? monthTemp >= selectedFirstMonthTemp.month ? monthTemp : selectedFirstMonthTemp.month : monthTemp;
    let localMonthRangeTemp : DateRangeMonthDetailInterface[] = [];
    for (var indexMonth = startMonthTemp; blnEndMonthTemp>=indexMonth; indexMonth++) {
      localMonthRangeTemp.push({
        year: yearTemp,
        month: indexMonth
      })
    }

    if (selectedFirstMonthTemp) {
      setSelectedFirstMonth(undefined);
    }
    else {
      setSelectedFirstMonth({
        year: yearTemp,
        month: monthTemp
      })
    }
    
    setLocalMonthRange(localMonthRangeTemp);
    setRefreshMonthCalendar(true);
    setTimeout(()=> {
      setRefreshMonthCalendar(false)
      handlePromptMonthRange();
    }, 1)
  }

  const returnDisplayText = m => {
    if (m && m.year && m.month) return (months[m.month-1] + ' ' + m.year)
    return '?'
  }

	return (
		<div>
      
      <style type="text/css">
        {/* {
          selectedFirstMonth
          ?
          ` 
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover { 
              background-color: #9abede !important;
            } 
          `
          :
          ""
        } */}
        {cssBetweenRange}
      </style>
			<div className={props.className || "mb-3"}>
				{props.title && (
					<Label>
						<Label className="margin-bottom-0" style={{ color: "red" }}>{`*`}</Label>
						<Label className="margin-bottom-0">{props.title}</Label>
					</Label>
				)}
				<br />
        <div className="edit">
          {
            !refreshMonthCalendar
            ?
            <Picker
              ref={refMonthCalendar}
              years={props.minMaxMonthObj ? props.minMaxMonthObj : { min: { year: Number(moment().format('yyyy')), month: 1 }, max: { year: Number(moment().format('yyyy')), month: 12 } }}
              lang={months}
              value={localMonthRange}
              onDismiss={handleDismiss}
              onChange={handleMultiChange}
              disabled={props.disabled}>
              <Input
                value={`${returnDisplayText(localMonthRange[0])} - ${returnDisplayText(localMonthRange[localMonthRange.length-1])}`}
                onClick={handlePromptMonthRange}
                readOnly
                disabled={props.disabled}/>
            </Picker>
            :
            <Input
              readOnly
              disabled={props.disabled}/>
          }
        </div>
			</div>
		</div>
	);
}
