import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import ActionPromptModal from "./ActionPromptModal";
interface Props {
	title?: string;
  displayName?: string;
	onCancelClick: () => void;
}

export default observer(function CancelModal(props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { showCancelPrompt , setShowCancelPrompt, loading } = commonStore;

	return (
    <ActionPromptModal 
      title={props.title || `Are you sure want to cancel this item?`}
      primaryActionTitle={`Yes, cancel it!`}
      showPrompt={showCancelPrompt}
      setShowPrompt={setShowCancelPrompt}
      onPrimaryClick={()=> {props.onCancelClick()}}
      />
	);
})
