import { Fragment, useState } from "react";
import {
  Col,
  Row,
  Card,
  Modal
} from "reactstrap";
import _ from 'lodash';
import Loading from '../../components/loading/Loading';
import AlertMessage from '../alert/AlertMessage';

interface Props {
  blnShow: boolean;
  setModal: Function;
  strTitle: string;
  placeholder?: string;
  options: any; //Dropdown data to display
  fieldToPickFromOption?: string;
  store?: any;
  functionFromStore?: string;
  requestBody?: any;
  fieldNameForRequestBody?: string;
}

export default function SelectCardModal(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setErrorMessage] : any = useState("");

  const hideModal = () => {
    props.setModal(false);
    setErrorMessage("");
  }

  return (
    <Modal
      isOpen={props.blnShow}
      style={{marginTop: '150px'}}
      toggle={() => {
        if (!isSubmitting) {
          hideModal();
        }
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.strTitle}</h5>
        <button
          type="button"
          onClick={() => {
            if (!isSubmitting) {
              hideModal();
            }
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          isSubmitting
          ?
          <Loading/>
          :
          <div>
            {
              error && <AlertMessage errorMessage={error}/>
            }
            <Row>
              {_.map(props.options, (value, index) => {
                const nameIcon = value.name?.charAt(0)
                return (
                  <div key={`modal_card_${index}`}>
                    <Col xl="12" sm="12" md="12">
                      <Card className="model_card_border" 
                        onClick={async ()=> {
                          setErrorMessage("");
                          setIsSubmitting(true);
                          try {
                            if (props.store && props.functionFromStore && props.fieldToPickFromOption && !isSubmitting){
                              let objTemp = {};
                              if (props.requestBody) {
                                objTemp = {...props.requestBody};
                              }
                              objTemp = {...objTemp, [props.fieldNameForRequestBody || props.fieldToPickFromOption]: value[props.fieldToPickFromOption]}

                              await props.store[props.functionFromStore](objTemp)                          
                            }
                          }
                          catch (error: any) {
                            setErrorMessage(Array.isArray(error) ? error[0] : error.message)
                            setIsSubmitting(false);
                            console.error(error);
                          }
                        }}>
                        <Row>
                          <div className="text-center p-4">
                            <div className="avatar-sm mx-auto mb-3 mt-1 ">
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft bg-" +
                                  " primary text-" +
                                  " font-size-16"
                                }
                              >
                                {nameIcon}
                              </span>
                            </div>
                            <h5 className="text-truncate">{value.name}</h5>
                          </div>
                        </Row>
                      </Card>
                    </Col>
                  </div>
                )
              })}
            </Row>
          </div>
        }
      </div>
    </Modal>
  )
}