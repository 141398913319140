import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/components/form/TextInput';
import MyButton from '../../app/components/form/MyButton';
import SelectCardModal from '../../app/components/modal/SelectCardModal';
import { BrandListObject } from '../../app/models/brand';
import { UserFormValues } from '../../app/models/user';
import { getJWT, IncludesLocationPathName } from '../../app/common/function/function';
import { history } from '../../';
import { useLocation } from "react-router-dom";
import { Constants } from '../../app/constants/Constants';
import { RoutesList } from "../../app/constants/RoutesList";
import AlertMessage from '../../app/components/alert/AlertMessage';
import { useIntl } from "react-intl";
import ForgetPasswordRequestModal from './Modal/ForgetPasswordModal';

const LoginForm = () => {
  const intl = useIntl();
  const { userStore, brandStore, commonStore } = useStore();
  const { login, loginWithBrand, clearCredential, forgetPasswordRequest } = userStore;
  const { setBrandList } = brandStore;
  const { errorMessage, setErrorMessage } = commonStore;
  const [brand, setBrand] = useState<BrandListObject[]>([]);
  const [credModal, setCredModal] = useState<UserFormValues>({ username: "", password: "", brandId: "" });
  const [brandModal, setBrandModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const [loginTriggered, setLoginTriggred] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (getJWT()) {
      history.push(`/${RoutesList.dashboard}`);
    }
    else{
      setShowLogin(true);
      clearCredential();
    }
  }, [])

  useEffect(() => {
    if (location?.state && !loginTriggered) {
      if (location.state === Constants.tokenExpired) {
        setTimeout(()=> {
          setErrorMessage(`${intl.formatMessage({ id: "SessionExpired-001"})}`);
        }, 500)
      } 
    }
  }, [location]);

  return (
    <div>
      {
        showLogin 
        &&
        <>
          <div className="account-pages my-5 pt-sm-5">
            <MetaTags>
              <title>{intl.formatMessage({ id: "Login" })}</title>
            </MetaTags>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {(errorMessage) && (
                    <AlertMessage
                      errorMessage={errorMessage}
                    />
                  )}
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">{intl.formatMessage({ id: "WelcomeBack"})}</h5>
                            <p>{intl.formatMessage({ id: "SignInToContinueToOneDoc"})}</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <br />
                      <div className="p-2">
                        <Formik
                          initialValues={{ username: '', password: '', error: null }}
                          onSubmit={(values, { setErrors, setSubmitting }) => {
                            setLoginTriggred(true);
                            setErrorMessage("");
                            setCredModal(values);
                            login(values)
                            .then(async resultLogin => {
                              setErrorMessage("");
                              setBrandList(resultLogin);
                              setBrand(resultLogin);
                              if (resultLogin.length === 1) {
                                await loginWithBrand({...values, brandId: resultLogin[0].id})
                              }
                              else {
                                setBrandModal(true);
                              }
                            })
                            .catch(error => {
                              setErrors({ error: `${intl.formatMessage({ id: "InvalidUsernameOrPasssord"})}` }); 
                            })
                            .finally(()=> {
                              setSubmitting(false);
                            })
                          }}
                          validationSchema={Yup.object({
                            username: Yup.string().required(intl.formatMessage({ id: "LoginValidationEnter" }, { field: intl.formatMessage({id: "Username"})})),
                            password: Yup.string().required(intl.formatMessage({ id: "LoginValidationEnter" }, { field: intl.formatMessage({id: "Password"})})),
                          })}
                        >
                          {({ handleSubmit, isSubmitting, errors, dirty, isValid }) => (

                            <Form
                              className="form-horizontal"
                              onSubmit={handleSubmit}
                              onBlur={()=> {setErrorMessage("")}}
                              autoComplete='off'
                            >
                              <ErrorMessage
                                name='error'
                                render={() => <Alert color="danger">{errors.error}</Alert>}
                              />

                                {/* <span className="mb-3">{isSubmitting ? "error" : null}</span> */}

                              <div className={!errors["username"] ? "mb-3" : ""}>
                                <TextInput 
                                  name='username' 
                                  placeholder={intl.formatMessage({ id: "LoginInputPlaceHolderEnter" }, { field: intl.formatMessage({id: "Username"})})} 
                                  label={intl.formatMessage({ id: "Username"})} 
                                  disabled={isSubmitting}/>
                              </div>

                              <div className={!errors["password"] ? "mb-3" : ""}>
                                <TextInput 
                                  name='password' 
                                  type='password' 
                                  placeholder={intl.formatMessage({ id: "LoginInputPlaceHolderEnter" }, { field: intl.formatMessage({id: "Password"})})} label={intl.formatMessage({ id: "Password"})} 
                                  disabled={isSubmitting}/>
                              </div>

                              <div className="mt-3 d-grid">
                                <MyButton content='Log in'
                                  class='btn btn-primary btn-block'
                                  type='submit'
                                  disable={(dirty && !isValid) || isSubmitting}
                                  loading={isSubmitting} />
                              </div>

                              <div className="mt-4 text-center">
                                <div className="text-muted" onClick={() => setShowForgetPasswordModal(true)}>
                                  <i className="mdi mdi-lock me-1" />
                                  {intl.formatMessage({ id: "ForgotPassword" })}
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <SelectCardModal
                blnShow={brandModal}
                setModal={setBrandModal}
                strTitle={"Select Brand"}
                options={brand}
                fieldNameForRequestBody="brandId"
                fieldToPickFromOption="id"
                store={userStore}
                functionFromStore={"loginWithBrand"}
                requestBody={credModal}
              />
              {
                showForgetPasswordModal
                &&
                <ForgetPasswordRequestModal
                  blnShow={showForgetPasswordModal}
                  setModal={setShowForgetPasswordModal}
                  forgetPasswordRequest={forgetPasswordRequest}
                  setErrorMessage={setErrorMessage}
                />
              }
            </Container>
          </div>
        </>
      }
    </div>
  )
}

export default observer(LoginForm);
