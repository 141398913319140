import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { GoodsReceiveNoteListObject } from "../../app/models/goodsReceiveNote";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { goodsReceiveNoteViewAvailable, goodsReceiveNoteStatusColor, goodsReceiveNoteDeleteAvailable, newSpaceBeforeCapitalLetter, goodsReceiveNoteEditViewAvailable, checkPermission, goodsReceiveNoteEditAvailable } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const GoodsReceiveNote = () => {
  const intl = useIntl();

  //Use Store
  const { goodsReceiveNoteStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { goodsReceiveNoteList, getGoodsReceiveNote, deleteGoodsReceiveNote } =
    goodsReceiveNoteStore;
  const { goodsReceiveNoteStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<GoodsReceiveNoteListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedGoodsReceiveNoteStatus, setSelectedGoodsReceiveNoteStatus] = useState<number | string>(AllStatus);
  const [selectedGoodsReceiveNoteStatusId, setSelectedGoodsReceiveNoteStatusId] = useState<number | string | undefined>(" ");
  const [goodsReceiveNoteStatusList, setGoodsReceiveNoteStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionUpdateGRN = checkPermission([PermissionConstants.UpdateGRN]);
  const blnPermissionDeleteGRN = checkPermission([PermissionConstants.DeleteGRN]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchGoodsReceiveNoteListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchGoodsReceiveNoteAPI(false),
        getStaticReferenceWithType(Constants.goodsReceiveNoteStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setGoodsReceiveNoteStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageGRN], true)) {
      return;
    }

    fetchGoodsReceiveNoteListAPI();
  }, []);

  useEffect(() => {
    if (
      (selectedGoodsReceiveNoteStatusId || selectedGoodsReceiveNoteStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchGoodsReceiveNoteAPI(true);
    }
  }, [selectedGoodsReceiveNoteStatusId]);

  async function fetchGoodsReceiveNoteAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getGoodsReceiveNote({
      pageNumber: currentPage,
      pageSize: pageSize,
      gRNStatus: selectedGoodsReceiveNoteStatusId,
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: GoodsReceiveNoteListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  //Table Content UI
  const GoodsReceiveNoteColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "grnReferenceNo",
      text: intl.formatMessage({ id: "GoodsReceiveNoteNo" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "deliveryDate",
      text: intl.formatMessage({ id: "DeliveryDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.deliveryDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "grnStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.grnStatus}
          statusColorFunction={goodsReceiveNoteStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.goodsReceiveNote}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateGRN
              &&
              goodsReceiveNoteEditAvailable(row.grnStatus)
              &&
              <TableActionButton 
                to={`/${RoutesList.goodsReceiveNote}/${goodsReceiveNoteViewAvailable(row.grnStatus) ? 'view' : 'edit'}/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteGRN
              &&
              !goodsReceiveNoteViewAvailable(row.grnStatus)
              &&
              <TableActionButton 
                to="#"
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}
        title={intl.formatMessage({ id: "GoodsReceiveNote" })}
        addButton={
          checkPermission([PermissionConstants.CreateGRN])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}
            onClick={() => { history.push(`/${RoutesList.goodsReceiveNote}/add`) }}
            linkTo={`/${RoutesList.goodsReceiveNote}/add`}
            disable={localLoading || loading} />
        }>
        <SingleColumnRowParent blnDropDown={true}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "GoodsReceiveNote" }) })}
            name="goodsReceiveNoteStatus"
            options={goodsReceiveNoteStatusList}
            blnValueWithNewSpace={true}
            labelField="displayValue"
            valueField="key"
            initialLabel={selectedGoodsReceiveNoteStatus}
            initialValue={selectedGoodsReceiveNoteStatusId}
            setFieldLabel={setSelectedGoodsReceiveNoteStatus}
            setFieldValue={setSelectedGoodsReceiveNoteStatusId}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
        </SingleColumnRowParent>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={GoodsReceiveNoteColumns}
              data={goodsReceiveNoteList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}
              objSorted={defaultSorted}
              gRNStatus={selectedGoodsReceiveNoteStatusId}
              requestAPI={getGoodsReceiveNote}
            />
            <DeleteModal
              displayName={selectedRow?.grnReferenceNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deleteGoodsReceiveNote(
                  selectedRow?.id || "",
                  selectedRow?.grnReferenceNo || ""
                );
                let currentPageTemp = currentPage;
                if (goodsReceiveNoteList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getGoodsReceiveNote({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(GoodsReceiveNote);
