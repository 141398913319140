import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from "mdi-react/CashCheckIcon";
import { Link, useLocation } from 'react-router-dom';
import { BranchListObject } from "../../../app/models/branch";
import moment from "moment";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";

const AppolousPaymentCollectionSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppolousStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { appolousPaymentCollectionSummaryList, getAppolousPaymentCollectionSummaryList, exportAppolousPaymentCollectionSummary, setAppolousPaymentCollectionSummaryList } = reportAppolousStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportAppolousReport = checkPermission([PermissionConstants.ExportAppolousReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const paramsSearch = useLocation().search;
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCollectionsSummary() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))

      if (branchParams) {
        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.appolousPaymentCollectionSummary}` });
        await fetchCollectionsSummaryWithLoading(false, branchIdTemp);
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppolousReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setAppolousPaymentCollectionSummaryList(undefined);
    })
  }, [])

  const fetchCollectionsSummaryWithLoading = async (blnExport: boolean, branchIdTemp?: string) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportAppolousPaymentCollectionSummary({ branchId: selectedBranchId === " " ? undefined : selectedBranchId, fromDate: decodeURIComponent(selectedDateRange.fromDate), toDate: decodeURIComponent(selectedDateRange.toDate)})
    }
    else {
      await getAppolousPaymentCollectionSummaryList({ branchId: branchIdTemp || selectedBranchId, dateRange: selectedDateRange });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const collectionsSummaryDetailsColumns = [
    {
      dataField: "paymentMethodName", //field name from array to display
      text: "paymentMethodName", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalAmount) || Constants.emptyData}
        </div>
      ),
    },
  ];

  const collectionsDetailsColumns = [
    {
      dataField: "customerSalesOrderId", //field name from array to display
      text: "customerSalesOrderId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "employeeName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.employeeName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "performaInvoiceNo",
      text: `${intl.formatMessage({ id: "InvoiceNo" }).toUpperCase()}.`,
      formatter: (cellContent, row) => <>
        <Link to={row.appolousSalesId ? `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.appolousSalesId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.performaInvoiceNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "PaidAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.paymentMethodName) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentDetails",
      text: intl.formatMessage({ id: "Details" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.paymentDetails || Constants.emptyData}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppolousPaymentCollectionSummary" }) })}
        title={intl.formatMessage({ id: "AppolousPaymentCollectionSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppolousReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCollectionsSummaryWithLoading(true)
            }}
          />
        }>
        {
          !loadingDropdownRefresh
          &&
          <Row>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchId"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PaymentDate"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange}
                validationRequired={true}
                initialValue={selectedDateRange} />
            </Col>
            <Col xl="2">
              <MyButton
                type="button"
                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchCollectionsSummaryWithLoading(false)
                }}
              />
            </Col>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <>
              {
                <Row >
                  <Col xl={appolousPaymentCollectionSummaryList ? "9" : "12"}>
                    <PaginationTable
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      totalItem={totalItem}
                      tableColumns={collectionsSummaryDetailsColumns}
                      data={appolousPaymentCollectionSummaryList ? appolousPaymentCollectionSummaryList.paymentCollectionSummaryDetails : []}
                      emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousPaymentCollectionSummary" }) })}
                      objSorted={defaultSorted}
                      keyField={"paymentMethodName"}
                      branchId={selectedBranchId}
                      dateRange={selectedDateRange}
                      requestAPI={getAppolousPaymentCollectionSummaryList}
                      hideSearchBar={true} />
                  </Col>
                  {
                    appolousPaymentCollectionSummaryList
                    &&
                    <Col xl="3" lg="6" sm="12" xs="12" className={windowSize.innerWidth > Constants.innerWidthCompare ? "" : "padding-top-10"}>
                      <CardInfo
                        icon={() => (<CashCheckIcon className="h2 text-success mb-0" />)}
                        title={intl.formatMessage({ id: "TotalPaymentsReceived" }).toUpperCase()}
                        value={returnPriceWithCurrency(appolousPaymentCollectionSummaryList.totalPayments)}
                        valueColor={"text-green-color"} />
                    </Col>
                  }
                </Row>
              }
              {
                appolousPaymentCollectionSummaryList && appolousPaymentCollectionSummaryList.paymentCollectionDetails &&
                <div className={`standard-layout  ${windowSize.innerWidth > Constants.innerWidthCompare ? `margin-top-36` : ""}`}>
                  <PaginationTableWithoutApi
                    title={intl.formatMessage({ id: "AppolousPaymentCollectionSummary" })}
                    keyField={"randomId"}
                    columns={collectionsDetailsColumns}
                    options={appolousPaymentCollectionSummaryList ? appolousPaymentCollectionSummaryList.paymentCollectionDetails : []} />
                </div>
              }
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppolousPaymentCollectionSummary);
