import { PaginationRequestBody } from "../models/pagination";
import { AppointmentTargetListObject, AppointmentTargetAddObject, AppointmentTargetUpdateObject, AppointmentTargetDetailObject, AppointmentTargetDuplicatedRequestObject } from "../models/appointmentTarget";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class AppointmentTargetStore {
  appointmentTargetList: AppointmentTargetListObject[] = [];
  appointmentTargetDetail: AppointmentTargetDetailObject | undefined = undefined;
  appointmentTargetPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appointmentTargetList = [];
    this.appointmentTargetDetail = undefined;
    this.appointmentTargetPaginationParams= undefined;
  }

  setAppointmentTargetDetail = (appointmentTargetDetail: AppointmentTargetDetailObject | undefined) => {
    this.appointmentTargetDetail = appointmentTargetDetail;
  }

  setAppointmentTargetPaginationParams = (appointmentTargetPaginationParams: PaginationRequestBody | undefined) => {
    this.appointmentTargetPaginationParams = appointmentTargetPaginationParams;
  }

  getAppointmentTarget = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setAppointmentTargetPaginationParams(PaginationRequestBody);
    try{
      const resultAppointmentTarget = await agent.AppointmentTarget.appointmentTargetList(PaginationRequestBody);
      runInAction(() => {
        this.appointmentTargetList = resultAppointmentTarget.data;
        store.commonStore.setTotalItem(resultAppointmentTarget.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentTargetList = [];
    }
  }

  getAppointmentTargetWithId = async (id: string) => {
    try{
      const resultAppointmentTargetDetail = await agent.AppointmentTarget.appointmentTargetDetail(id);
      runInAction(() => {
        this.appointmentTargetDetail = resultAppointmentTargetDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentTargetDetail = undefined;
    }
  }

  addAppointmentTarget = async (appointmentTargetRequestBody: AppointmentTargetAddObject) => {
    try{
      const resultAddAppointmentTarget = await agent.AppointmentTarget.addAppointmentTarget(appointmentTargetRequestBody);
      if (resultAddAppointmentTarget.hasExactMatchAppointmentTarget)
      {
        return Promise.resolve({message:resultAddAppointmentTarget.message});
      } else {
        store.commonStore.setSuccessMessage(`AppointmentTargetAddSuccess`);
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateAppointmentTarget = async (appointmentTargetRequestBody: AppointmentTargetUpdateObject) => {
    try{
      await agent.AppointmentTarget.updateAppointmentTarget(appointmentTargetRequestBody);
      store.commonStore.setSuccessMessage(`AppointmentTargetUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteAppointmentTarget  = async (id: string, name: string) => {
    try {
      await agent.AppointmentTarget.deleteAppointmentTarget(id);
      store.commonStore.setSuccessMessage(`AppointmentTargetDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  duplicateAppointmentTarget = async (appointmentTargetDuplicatedRequestObject: AppointmentTargetDuplicatedRequestObject) => {
    try {
      await agent.AppointmentTarget.appointmentTargetDuplicate(appointmentTargetDuplicatedRequestObject);
      store.commonStore.setSuccessMessage(`Appointment Target successfully duplicated for year ${appointmentTargetDuplicatedRequestObject.startYear} till year ${appointmentTargetDuplicatedRequestObject.endYear}.`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}