import React, { useEffect, useState } from 'react'
import { Col, Row, Label } from 'reactstrap'
import GeneralSelectDropdown from '../../../app/components/form/GeneralSelectDropdown'
import { useIntl } from 'react-intl';
import { BranchListObject } from '../../../app/models/branch';
import { observer } from 'mobx-react-lite';
import ListViewLayout from '../../../app/components/layout/ListViewLayout';
import MyButton from '../../../app/components/form/MyButton';
import { RoutesList } from '../../../app/constants/RoutesList';
import GeneralDateRange from '../../../app/components/form/GeneralDateRange';
import PaginationTable from '../../../app/components/table/PaginationTable';
import { useStore } from '../../../app/stores/store';
import { Constants } from '../../../app/constants/Constants';
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from '../../../app/common/function/function';
import { PermissionConstants } from '../../../app/constants/PermissionConstants';
import { DateRangeInterface } from '../../../app/models/common';
import Loading from '../../../app/components/loading/Loading';
import moment from 'moment';
import GeneralInputWithCallAPI from '../../../app/components/form/GeneralInputWithCallAPI';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import _ from 'lodash';
import { history } from "../../../";

const CustomerDetailReport = () => {
    const { reportCustomerStore, branchStore, commonStore } = useStore();
    const { totalItem, windowSize, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
    const { customerDetailsList, setCustomerDetailsList, getCustomerDetailsReportList, exportCustomerDetailsReportList } = reportCustomerStore;
    const { isEligibleAllBranch, getBranchDropDown } = branchStore;
    const intl = useIntl();

    const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
    const [localLoading, setLocalLoading] = useState(false);
    const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
    const [localSearchLoading, setLocalSearchLoading] = useState(false);
    const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
    const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
    const [selectedLastVisitDateRange, setSelectedLastVisitDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
    const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
    const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = Constants.defaultPageSize;
    const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
    const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

    const paramsSearch = useLocation().search;
    const branchParams = new URLSearchParams(paramsSearch).get('branchId');
    const joinedFromDateParams = new URLSearchParams(paramsSearch).get('joinedFromDate');
    const joinedToDateParams = new URLSearchParams(paramsSearch).get('joinedToDate');

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            searchValue: ""
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
        },
    });

    let customerDetailsGetAndExportRequestBody = {
        pageNumber: currentPage,
        pageSize: pageSize,
        branchName: selectedBranchName,
        branchId: selectedBranchId,
        joinedFromDate: selectedDateRange.fromDate,
        joinedToDate: selectedDateRange.toDate,
        lastVisitFromDate: selectedLastVisitDateRange.fromDate,
        lastVisitToDate: selectedLastVisitDateRange.toDate,
    }

    const defaultSorted = [
    ];

    useEffect(() => {
        async function fetchMCustomerDetailReportAPI() {
            setLoading(true);
            setLoadingDropdownRefresh(true);
            let blnParams = branchParams && joinedFromDateParams && joinedToDateParams;
            let aryAPI: any = [
                getBranchDropDown()
            ];
            let resultAPI = await Promise.all(aryAPI);
            setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));

            if (blnParams) {
                setSelectedDateRange({fromDate:  moment(joinedFromDateParams).format(Constants.displayDateFormat), toDate:  moment(joinedToDateParams).format(Constants.displayDateFormat)})
                let branchIdTemp: number | string = " ";
                let branchListTemp: any = _.cloneDeep(resultAPI[0]);
                branchListTemp.map((value) => {
                    if (branchParams === value.id) {
                        setSelectedBranchName(value.name)
                        setSelectedBranchId(value.id);
                        branchIdTemp = value.id;
                    }
                })
                history.replace({ pathname: `/${RoutesList.report}/${RoutesList.customerDetails}` });
                await fetchCustomerMonthSpendListWithLoading(false, undefined, {branchId: branchIdTemp, joinedFromDate: moment(joinedFromDateParams).format(Constants.displayDateFormat), joinedToDate: moment(joinedToDateParams).format(Constants.displayDateFormat)});
            }
            setLoading(false);
            setLoadingDropdownRefresh(false);
        }

        if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
            return;
        }

        fetchMCustomerDetailReportAPI();

        return (() => {
            setCustomerDetailsList([])
        })
    }, [])

    const fetchCustomerMonthSpendListWithLoading = async (blnExport: boolean, searchValueTemp?: string, paramsRequestBody?: any) => {
        setLocalLoading(true);
        if (blnExport) {
            await exportCustomerDetailsReportList(customerDetailsGetAndExportRequestBody)
        }
        else {
            if (searchValueTemp === undefined) {
                setLocalSearchLoading(true)
                validation.setFieldValue("searchValue", "");
                searchValueTemp = ""
            }
            setCurrentPage(1)
            await getCustomerDetailsReportList({ 
                ...customerDetailsGetAndExportRequestBody, 
                pageNumber: 1, 
                pageSize: pageSize,
                searchValue: searchValueTemp,
                ...paramsRequestBody ? paramsRequestBody : undefined
            })
        }
        setLocalLoading(false);
        setLocalSearchLoading(false)
    };

    const onSelectDateRange = (objDateRange: any) => {
        setCurrentPage(1);
        setErrorMessage("");
        setSuccessMessage("");
        setSelectedDateRange(objDateRange);
    }

    const onSelectLastVisitDateRange = (objDateRange: any) => {
        setCurrentPage(1);
        setErrorMessage("");
        setSuccessMessage("");
        setSelectedLastVisitDateRange(objDateRange);
    }

    const CustomerDetailsColumns = [
        {
            dataField: "branchId", //field name from array to display
            text: "branchId", //display name
            sort: true, //sort function enable
            hidden: true, //hide UI
        },
        {
            dataField: "branchName",
            text: intl.formatMessage({ id: "BranchName" }).toUpperCase()
        },
        {
            dataField: "customerNo",
            text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    {
                        blnPermissionViewCustomer
                            ?
                            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 margin-left-4 ">{row.customerNo || Constants.emptyData}</Label>
                            </Link>
                            :
                            <div>{row.customerNo}</div>
                    }
                </>
            )
        },
        {
            dataField: "preferredName",
            text: intl.formatMessage({ id: "PreferredName" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    {
                        blnPermissionViewCustomer
                            ?
                            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 margin-left-4 ">{row.preferredName || Constants.emptyData}</Label>
                            </Link>
                            :
                            <div>{row.preferredName}</div>
                    }
                </>
            )
        },
        {
            dataField: "mobileNo",
            text: intl.formatMessage({ id: "MobileNo" }).toUpperCase()
        },
        {
            dataField: "email",
            text: intl.formatMessage({ id: "Email" }).toUpperCase()
        },
        {
            dataField: "joinedDate",
            text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <div>{moment(row.joinedDate).format(Constants.displayDateAndTimeFormat)}</div>
            )
        },
        {
            dataField: "lastVisitDate",
            text: intl.formatMessage({ id: "LastVisitDate" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    {`${row.lastVisitDate !== Constants.invalidDateTime ?
                        moment(row.lastVisitDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}`}
                </>
            )
        },
        {
            dataField: "joinMonthSpendingAmount",
            text: intl.formatMessage({ id: "JoinMonthSpendingAmount" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>{returnPriceWithCurrency(row.joinMonthSpendingAmount)}</>
            )
        },
    ]
    return (
        <div>
            <ListViewLayout
                metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerDetails" }) })}
                title={intl.formatMessage({ id: "CustomerDetails" })}
                breadCrumbList={breadCrumbList}
                addButton={
                    blnPermissionExportCustomerReport
                    &&
                    <MyButton
                        type="button"
                        class="btn btn-success"
                        content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                        disable={localLoading || loading}
                        onClick={() => {
                            fetchCustomerMonthSpendListWithLoading(true)
                        }}
                    />
                }
                hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Customer-Details.aspx`}>
                {
                    !loadingDropdownRefresh
                    &&
                    <Row>
                        <Col xl="3">
                            <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "Branch" })}
                                name="branchId"
                                options={branchDropDownListFinal}
                                initialLabel={selectedBranchName}
                                initialValue={selectedBranchId}
                                setFieldLabel={setSelectedBranchName}
                                setFieldValue={setSelectedBranchId}
                                disabled={localLoading || loading || getBranchUser()}
                                validationRequired={true}
                            />
                        </Col>
                        <Col xl="3">
                            <GeneralDateRange
                                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "JoinedDate" }) })}
                                name={''}
                                disabled={localLoading || loading}
                                onChangeFunction={onSelectDateRange}
                                initialValue={selectedDateRange}
                            />
                        </Col>
                        <Col xl="3">
                            <GeneralDateRange
                                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "LastVisitDate" }) })}
                                name={''}
                                disabled={localLoading || loading}
                                onChangeFunction={onSelectLastVisitDateRange}
                                initialValue={selectedLastVisitDateRange}
                            />
                        </Col>
                        <Col xl="2">
                            <MyButton
                                type="button"
                                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                                loading={localLoading || loading}
                                disable={localLoading || loading}
                                onClick={() => {
                                    fetchCustomerMonthSpendListWithLoading(false)
                                }}
                            />
                        </Col>
                    </Row>
                }
                <div className={"standard-layout"}>
                    {
                        !localSearchLoading
                        &&
                        <Row className="flex-right">
                            <Col xl="2">
                                <GeneralInputWithCallAPI
                                    name="searchValue"
                                    className={"mb-0"}
                                    onChangeCustomSearch={async (searchValueTemp) => {
                                        validation.setFieldValue("searchValue", searchValueTemp);
                                        fetchCustomerMonthSpendListWithLoading(false, searchValueTemp);
                                    }}
                                    loadingCustomSearch={localLoading}
                                    setLoadingCustomSearch={setLocalLoading} />
                            </Col>
                        </Row>
                    }
                    {
                        localLoading
                            ?
                            <Loading />
                            :
                            <PaginationTable
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                totalItem={totalItem}
                                tableColumns={CustomerDetailsColumns}
                                keyField={"customerNo"}
                                data={customerDetailsList}
                                objSorted={defaultSorted}
                                branchId={selectedBranchId}
                                joinedFromDate={selectedDateRange.fromDate}
                                joinedToDate={selectedDateRange.toDate}
                                lastVisitFromDate={selectedLastVisitDateRange.fromDate}
                                lastVisitToDate={selectedLastVisitDateRange.toDate}
                                searchValue={validation.values.searchValue}
                                requestAPI={getCustomerDetailsReportList}
                                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerDetails" }) })}
                                blnRemoveBackground={true} />
                    }</div>
            </ListViewLayout>
        </div>
    )
}

export default observer(CustomerDetailReport)