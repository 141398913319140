import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { EISListObject } from '../../app/models/eis';
import DeleteModal from "../../app/components/modal/DeleteModal";
import EISDetail from "./EISDetail";
import { checkPermission, getYears } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";

const EIS = () => {
  const intl = useIntl();
  //Use Store
  const { eisStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { eisList, getEIS, deleteEIS } = eisStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<EISListObject | undefined>(undefined);
  const [eisDetailModal, setEISDetailModal] = useState(false);
  const [selectedEISDetailID, setSelectedEISDetailID] = useState("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewEIS, setViewEIS] = useState(false);
  const pageSize = Constants.defaultPageSize;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
  }, [])

  useEffect(() => {
    if (selectedYear) {
      if (!checkPermission([PermissionConstants.ManageEis], true)) {
        return;
      }
      fetchEISAPI(true);
    }
  }, [selectedYear])

  async function fetchEISAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getEIS({ pageNumber: currentPage, pageSize: pageSize, year: selectedYear })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: EISListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const EISColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.from}`}</div>
      ),
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.to}`}</div>
      ),
    },
    {
      dataField: "employer",
      text: `${intl.formatMessage({ id: "Employer" }).toUpperCase()} (${intl.formatMessage({ id: "Sens" }).toLowerCase()})`,
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.employer}`}</div>
      ),
    },
    {
      dataField: "employee",
      text: `${intl.formatMessage({ id: "Employee" }).toUpperCase()} (${intl.formatMessage({ id: "Sens" }).toLowerCase()})`,
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.employee}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageEis, PermissionConstants.UpdateEis, PermissionConstants.DeleteEis]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManageEis])
              &&
              <TableActionButton 
                to="#"
                type="viewModal"
                onClick={() => {
                  setSelectedEISDetailID(row.id)
                  setViewEIS(true);
                  setEISDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.UpdateEis])
              &&
              <TableActionButton 
                to="#"
                type="edit"
                onClick={() => {
                  setSelectedEISDetailID(row.id)
                  setViewEIS(false);
                  setEISDetailModal(true);
                }}/>
            }
            {
              checkPermission([PermissionConstants.DeleteCurrency])
              &&
              <TableActionButton 
                to="#"
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "EIS" }) })}
        title={intl.formatMessage({ id: "EIS" })}
        addButton={
          checkPermission([PermissionConstants.CreateEis])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "EIS" }) })}
            onClick={() => {
              setSelectedEISDetailID("")
              setEISDetailModal(true);
            }}
            disable={localLoading || loading} />
        }>
        <SingleColumnRowParent
          blnDropDown={true}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Years" })}
            name="years"
            options={yearList}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
        </SingleColumnRowParent>
        {
          localLoading && !eisDetailModal
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={EISColumns}
              data={eisList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "EIS" }) })}
              objSorted={defaultSorted}
              isModal={eisDetailModal}
              selectedYear={selectedYear}
              requestAPI={getEIS} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteEIS(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (eisList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getEIS({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear })
            setLoading(false);
          }} />
        {
          eisDetailModal &&
          <EISDetail
            id={selectedEISDetailID}
            blnShow={eisDetailModal}
            setModal={setEISDetailModal}
            reloadData={fetchEISAPI}
            yearList={yearList}
            selectedYear={selectedYear}
            viewEIS={viewEIS}
          />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(EIS);
