import { PaginationRequestBody } from "../models/pagination";
import { PayslipDetailObject, PayslipListObject, PayslipAddObject } from "../models/payslip";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class payslipStore {
  payslipList: PayslipListObject[] = [];
  payslipDetail: PayslipDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.payslipList = [];
    this.payslipDetail = undefined;
  }

  setPayslipDetail = (payslipDetail: PayslipDetailObject | undefined) => {
    this.payslipDetail = payslipDetail;
  }

  getPayslip = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPayslip = await agent.Payslip.payslipList(PaginationRequestBody);
      runInAction(() => {
        this.payslipList = resultPayslip.data;
        store.commonStore.setTotalItem(resultPayslip.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.payslipList = [];
    }
  }

  getPayslipWithId = async (id: string) => {
    try{
      const resultPayslipDetail = await agent.Payslip.payslipDetail(id);
      runInAction(() => {
        this.payslipDetail = resultPayslipDetail;
      });
      return Promise.resolve(resultPayslipDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.payslipDetail = undefined;
    }
  }

  addPayslip = async (payslipRequestBody: PayslipAddObject) => {
    try{
      await agent.Payslip.addPayslip(payslipRequestBody);
      store.commonStore.setSuccessMessage(`PayslipGenerateSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}