import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, removeAllSpace } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { WithContext as ReactTags } from 'react-tag-input';

const AdditionalFormFieldDetail = () => {
	//Use Params
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { staticReferenceStore, additionalFormFieldStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addAdditionalFormField, updateAdditionalFormField, additionalFormFieldDetail, setAdditionalFormFieldDetail, getAdditionalFormFieldWithId } = additionalFormFieldStore;
	const { controlType, formType, getStaticReferenceWithType } = staticReferenceStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "AdditionalFormField" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "AdditionalFormField" }), urlPath: RoutesList.additionalFormField }];
	const KeyCodes = { comma: 188, enter: 13, };
	const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [blnSubmitting, setBlnSubmitting] = useState(false);
	const [tags, setTags] = useState<any>([]);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: additionalFormFieldDetail || {
			name: "",
			controlType: "",
			placeHolder: "",
			formType: "",
			isRequired: false,
			isActive: false,
			displayOrder: 1,
			rows: 0,
			options: []
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FieldName" }) })),
			controlType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ControlType" }) })),
			formType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "FormType" }) })),
			displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			if (valuesTemp.options) {
				let tagTemp = valuesTemp.options;
				let optionFinal: any = [];
				for (var a = 0; a < tagTemp.length; a++) {
					optionFinal.push(tagTemp[a].text)
				}
				valuesTemp.options = optionFinal
			}
			try {
				if (addAction) {
					await addAdditionalFormField(valuesTemp);
				} else {
					await updateAdditionalFormField(valuesTemp);
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
	const blnShowRows = validation.values.controlType !== "" && (validation.values.controlType === Constants.multilineText);
	const blnShowOptions = validation.values.controlType !== "" && (validation.values.controlType === Constants.dropDownlist || validation.values.controlType === Constants.checkboxes || validation.values.controlType === Constants.radioList)

	useEffect(() => {
		async function fetchAdditionalFormFieldDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
				getStaticReferenceWithType(Constants.controlType),
				getStaticReferenceWithType(Constants.formType),
			];
			if (id && !addAction) {
				aryAPI.push(getAdditionalFormFieldWithId(id));
			}
			await Promise.all(aryAPI);
			setLoading(false);
		}

		if (addAction) {
			setAdditionalFormFieldDetail(undefined);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateAdditionalFormField], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateAdditionalFormField], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAdditionalFormField], true)) {
        return;
      }
    }

		fetchAdditionalFormFieldDetailAPI();

		return (() => {
			if (id && !addAction) {
				setAdditionalFormFieldDetail(undefined);
			}
		})
	}, []);

	useEffect(() => {
		let aryOptionList: any = [];
		if (additionalFormFieldDetail) {
			let aryDetailOptionTemp = additionalFormFieldDetail.options
			for (var a = 0; a < aryDetailOptionTemp.length; a++) {
				aryOptionList.push({
					id: aryDetailOptionTemp[a],
					text: aryDetailOptionTemp[a]
				})
			}
			setTags(aryOptionList)
      validation.setFieldValue("options", aryOptionList);
		}
	}, [additionalFormFieldDetail])

	useEffect(() => {
		if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	const onChangeControlType = () => {
		try {
			if (!blnShowOptions) {
				setTags([]);
				validation.setFieldValue("options", [])
			}
			else if (!blnShowRows) {
				validation.setFieldValue("rows", 0)
			}
			else {
				setTags([]);
				validation.setFieldValue("options", [])
				validation.setFieldValue("rows", 0)
			}
		}
		catch (e) {
			setErrorMessage(String(e));
		}
	}

	const handleDelete = (i) => {
		let tagTemp = tags.filter((tag, index) => index !== i);
		setTags(tagTemp);
		validation.setFieldValue("options", tagTemp)
	};

	const handleAddition = (tag) => {
		let tagTemp: any = tags
		tagTemp.push(tag)
		setTags(tagTemp);
		validation.setFieldValue("options", tagTemp)
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
		validation.setFieldValue("options", newTags)
	};

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}
        auditTrailId={viewAction ? id : null}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl={12}>
								<LineBreakWithTittle
									paddingBottom="0px"
									title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "AdditionalFormField" }) })}
									h4Title />
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "FieldName" })}
										name="name"
										className="mb-3 mt-4"
										type="text"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent >
									<DropDownWithTitle
										name={"controlType"}
										title={intl.formatMessage({ id: "Type" })}
										specifyReturnFieldName={[
											{
												field: "controlType",
												value: "displayValue",
											},
										]}
										labelField={"displayValue"}
										valueField={"displayValue"}
										options={controlType}
										blnValueWithNewSpace={true}
										disabled={disabledFieldInput || viewAction}
										onChangeFunction={onChangeControlType}
										initialValue={!addAction ? validation.values.controlType : undefined}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent >
									<DropDownWithTitle
										name={"formType"}
										title={intl.formatMessage({ id: "FormType" })}
										specifyReturnFieldName={[
											{
												field: "formType",
												value: "displayValue",
											},
										]}
										labelField={"displayValue"}
										valueField={"displayValue"}
										options={formType}
										blnValueWithNewSpace={true}
										disabled={disabledFieldInput || viewAction}
										initialValue={!addAction ? validation.values.formType : undefined}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "PlaceHolder" })}
										name="placeHolder"
										type="text"
										placeholder="Text appear when the field is empty"
										disabled={disabledFieldInput || viewAction}
										validation={validation}
									/>
								</SingleColumnRowParent>
								{blnShowRows &&
									<SingleColumnRowParent>
										<GeneralInput
											title={intl.formatMessage({ id: "Rows" })}
											name="rows"
											type="number"
											validationRequired={true}
											disabled={disabledFieldInput || viewAction}
											validation={validation}
										/>
									</SingleColumnRowParent>
								}
								{blnShowOptions &&
									<SingleColumnRowParent>
										<Label>
											<Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
											<Label className="margin-bottom-0">{intl.formatMessage({ id: "Options" })}</Label>
										</Label>
										<ReactTags
											tags={tags}
											delimiters={delimiters}
											handleDelete={handleDelete}
											handleAddition={handleAddition}
											handleDrag={handleDrag}
											inputFieldPosition="inline"
											editable
                      readOnly={disabledFieldInput || viewAction}
											placeholder="Press enter to add new option"
										/>
									</SingleColumnRowParent>
								}
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "DisplayOrder" })}
										name="displayOrder"
										className={blnShowOptions ? "mt-3" : ""}
										type="number"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<Col sm="6" md="6" xl="6">
									<Row className={blnShowOptions ? "mt-2" : ""}>
										<DoubleColumnRowParent>
											<GeneralInput
												title={intl.formatMessage({ id: "RequiredField" })}
												name="isRequired"
												type="checkbox"
												disabled={disabledFieldInput || viewAction}
												validation={validation}
												childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
										</DoubleColumnRowParent>
										<DoubleColumnRowParent>
											<GeneralInput
												title={intl.formatMessage({ id: "Active" })}
												name="isActive"
												type="checkbox"
												disabled={disabledFieldInput || viewAction}
												validation={validation}
												childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
										</DoubleColumnRowParent>
									</Row>
								</Col>
							</Col>
						</Row>
						<GeneralSubmitAndCancelBtn
							successMessage={successMessage}
							viewAction={viewAction}
							validation={validation}
						/>
					</Form>
				)}
			</DetailViewLayout>
		</div>
	);
};

export default observer(AdditionalFormFieldDetail);
