import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label, Nav, NavItem, NavLink } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { IssuerBanksListObject } from "../../app/models/paymentMethodOption";
import classNames from "classnames";

const PaymentMethodOptionalDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { staticReferenceStore, paymentMethodOptionStore, paymentTerminalStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
    windowSize
  } = commonStore;
  const {
    addPaymentMethodOption,
    updatePaymentMethodOption,
    paymentMethodOptionDetail,
    setPaymentMethodOptionDetail,
    getPaymentMethodOptionWithId,
  } = paymentMethodOptionStore;
  const { paymentMethodOptionType, getStaticReferenceWithType } = staticReferenceStore;
  const { paymentTerminalDropdownList, getPaymentTerminalDropdown } = paymentTerminalStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PaymentMethodOption" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PaymentMethodOption" }), urlPath: RoutesList.paymentMethodOption }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const tabBar = [intl.formatMessage({ id: "IssuerBanks" }).toUpperCase()];
  const blnPermissionUpdatePaymentMethodOption = checkPermission([PermissionConstants.UpdatePaymentMethodOption]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: paymentMethodOptionDetail || {
      id: "",
      name: "",
      optionType: "",
      chargeRate: 0,
      isActive: false,
      isEPP: false,
      displayOrder: 0,
      issuerBanks: [],
      paymentTerminalName: "",
      paymentTerminalId: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentMethodOptionName" }) })),
      optionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentMethodOptionType" }) })),
      chargeRate: Yup.number().min(0).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ChargeRate" }) })),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.isEPP) {
        valuesTemp.issuerBanks = [];
      }
      else { 
        let blnIssuerBankError = false;
        let cashOutDetailsTemp = valuesTemp.issuerBanks.map((valueIssuerBankTemp: IssuerBanksListObject) => {
          let backgroundColor = valueIssuerBankTemp.eppMonth < 1 || valueIssuerBankTemp.chargeRate < 0 ? Constants.orange : "";
          if (valueIssuerBankTemp.eppMonth < 1 || valueIssuerBankTemp.chargeRate < 0) {
            blnIssuerBankError = true;
          }
          return ({
            ...valueIssuerBankTemp,
            backgroundColor: backgroundColor
          })
        })

        validation.setFieldValue("issuerBanks", cashOutDetailsTemp)
        if (blnIssuerBankError) {
          setErrorMessage(intl.formatMessage({ id: "IssuerBanksError" }))
          return;
        }
      }

      try {
        if (addAction) {
          await addPaymentMethodOption(valuesTemp);
        } else {
          await updatePaymentMethodOption(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const fieldsOfIssuerBanks: IssuerBanksListObject = { name: "", eppMonth: 0, chargeRate: validation.values.chargeRate };
  const aryDynamicInputAndCheckBoxTabIssuerBanks: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: `${intl.formatMessage({ id: "Name" })}`,
      type: "input",
      xl: "4",
      inputName: "name",
      inputType: "text",
      validationRequired: true
    },
    {
      label: `${intl.formatMessage({ id: "EPPMonths" })}`,
      type: "input",
      xl: "4",
      inputName: "eppMonth",
      inputType: "number",
      validationRequired: true
    },
    {
      label: `${intl.formatMessage({ id: "ChargeRate" })}(%)`,
      type: "input",
      xl: "4",
      inputName: "chargeRate",
      inputType: "number",
      validationRequired: true
    },
  ];
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPaymentMethodOptionDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.paymentMethodOptionType),
        getPaymentTerminalDropdown()
      ];
      if (id && !addAction) {
        aryAPI.push(getPaymentMethodOptionWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPaymentMethodOptionDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePaymentMethodOption], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePaymentMethodOption], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePaymentMethodOption], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdatePaymentMethodOption){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.paymentMethodOption}/edit/${id}`)} })
    }

    fetchPaymentMethodOptionDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPaymentMethodOptionDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PaymentMethodOption" }) })}
                  h4Title
                />
                <SingleColumnRowParent
                  blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PaymentMethodOptionName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <DropDownWithTitle
                    name={"optionType"}
                    title={intl.formatMessage({ id: "PaymentMethodOptionType" })}
                    specifyReturnFieldName={[
                      {
                        field: "optionType",
                        value: "displayValue",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    options={paymentMethodOptionType}
                    blnValueWithNewSpace={true}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={!addAction ? validation.values.optionType : undefined}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <DropDownWithTitle
                    name={"paymentTerminalName"}
                    title={intl.formatMessage({ id: "PaymentTerminal" })}
                    specifyReturnFieldName={[
                      {
                        field: "paymentTerminalName",
                        value: "name",
                      },
                      {
                        field: "paymentTerminalId",
                        value: "id",
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={paymentTerminalDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.paymentTerminalName : undefined}
                    initialValue={!addAction ? validation.values.paymentTerminalId : undefined}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <GeneralInput
                    title={`${intl.formatMessage({ id: "ChargeRate" })}(%)`}
                    name="chargeRate"
                    type="number"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "EPP" })}
                    name="isEPP"
                    type="checkbox"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        {intl.formatMessage({ id: "Yes" })}
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        {intl.formatMessage({ id: "Yes" })}
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
              {
                validation.values.isEPP
                &&
                <Col xl={6}>
                  <Nav tabs className="">
                    {tabBar.map((value, indexTab) => (
                      <NavItem
                        key={`tabkey_${value}`}
                      >
                        <NavLink
                          style={{ cursor: "pointer", }}
                          className={classNames({ active: value.toUpperCase() })}>
                          {value}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TableWithEditableInputFields
                    name="issuerBanks"
                    className="mb-3"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "IssuerBanks" })})}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabIssuerBanks}
                    data={validation.values.issuerBanks}
                    inputField={fieldsOfIssuerBanks}
                    supportBackgroundColor={true}
                    validation={validation}
                    blnValidation={true}
                    blnDisabledCustomizedTableScrolling={true}
                    disabled={disabledFieldInput} />
                </Col>
              }
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PaymentMethodOptionalDetail);
