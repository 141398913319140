import React, { Fragment } from 'react'
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { calculateBalanceAmount, calculatePOSTotalPrice, checkPermission, displayOptionalProducts, displayPromotionCode, newSpaceBeforeCapitalLetter, returnCurrency, returnMalaysiaCurrencyRounding, returnPriceWithCurrency, returnSubBrandList } from '../../../app/common/function/function';
import Borderline from '../../../app/components/form/BorderLine';
import GeneralTextArea from '../../../app/components/form/GeneralTextArea';
import { Constants } from '../../../app/constants/Constants';
import { CustomerOrderDetailObject, CustomerPaymentDetailObject } from '../../../app/models/customerOrder';
import { useIntl } from "react-intl";
import DeleteIcon from 'mdi-react/DeleteIcon';
import GeneralInput from '../../../app/components/form/GeneralInput';
import DropDownWithTitle from '../../../app/components/dropdown/DropDownWithTitle';
import { PermissionConstants } from '../../../app/constants/PermissionConstants';
import moment from 'moment';
import _ from 'lodash';

interface Props {
  validation: any;
  disabledCustomerOrder: boolean;
  disabledCustomerPayment: boolean;
  disabledNote: boolean;
  disabledVoidedSalesOrderNo: boolean;
  selectCustomerOrder?: Function;
  deleteCustomerOrder?: Function;
  deletePaymentOrder?: Function;
  blnTotalBalance?: boolean;
  loadingSubBrand?: boolean;
  blnShowMinimumPaymentRequirement?: boolean;
  blnAllowCrossMonthSales?: boolean;
  blnDisplayRounding?: boolean;
}

export default function CustomerOrderCart(props: Props) {
  const intl = useIntl();
  const blnPermissionBackDateCustomerCart = checkPermission([PermissionConstants.BackdateCustomerSalesOrder]);

  const displayCustomerOrder = () => {
    if (props.validation.values.salesOrderDetails.length < 1) {
      return (
        <div style={{ height: '20px' }}>
          <span className="small-text">{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({ id: "Item" }).toLowerCase() })}</span>
          {props.validation.touched.salesOrderDetails && props.validation.errors.salesOrderDetails ? (
            <Label className="error margin-left-8" style={{ fontSize: "12px" }}>{`* ${intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })}`}</Label>
          ) : null}
        </div>)
    }

    return (
      props.validation.values.salesOrderDetails.map((value: CustomerOrderDetailObject, index) => {
        let serviceProductUsed = value.serviceProductUsedTemp || value.serviceProductUsed;
        return (
          <div className='flex-direction-row mb-3' key={`${value.itemId}_${value.promotionItemType}`}>
            {/* <div className="avatar-sm"> */}
            <Col xs="8" md="8" xl="8" style={{ alignItems: 'center', display: 'flex' }} onClick={() => { if (props.selectCustomerOrder && !props.disabledCustomerOrder) props.selectCustomerOrder(index) }}>
              <span className="avatar-sm circle-with-text rounded-circle" style={{ minWidth: '32px', backgroundColor: `${value.purchaseType == Constants.service ? Constants.lightpurple : value.purchaseType == Constants.package ? Constants.lightgreen : Constants.orange}` }}>{value.count}</span>
              <div className="margin-left-16">
                <span>{value.itemName} {displayPromotionCode(value.promotionCode)}</span>
                {serviceProductUsed ? displayOptionalProducts(serviceProductUsed, intl) : undefined}
                {value.expiredDate && <p className="margin-bottom-0" style={{ fontSize: '10px' }}>{`${intl.formatMessage({ id: "ExpiredOn" })} ${value.expiredDate}`}</p>}
                {value.discountValue ? <p className="margin-bottom-0" style={{ fontSize: '10px' }}>{`${intl.formatMessage({ id: "With" })} ${value.discountType == Constants.amount ? returnCurrency() : ""} ${value.discountValue} ${value.discountType == Constants.percentage ? "%" : ""} ${intl.formatMessage({ id: "Discount" }).toLowerCase()}`} </p> : null}
              </div>
            </Col>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'end', display: 'flex', padding: '0px', marginRight: props.disabledCustomerOrder ? "0px" : "20px" }} onClick={() => { if (props.selectCustomerOrder && !props.disabledCustomerOrder) props.selectCustomerOrder(index) }}>
              <p className="margin-bottom-0">{returnPriceWithCurrency(calculatePOSTotalPrice(value.count, value.price, value.discountType, value.discountValue))}</p>
            </div>
            {
              !props.disabledCustomerOrder
              &&
              <div style={{ alignItems: 'center', display: 'flex' }} className="text-danger flex-right" onClick={() => { if (props.deleteCustomerOrder && !props.disabledCustomerOrder) props.deleteCustomerOrder(index) }}>
                <DeleteIcon size={18} id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {intl.formatMessage({ id: "Delete" })}
                </UncontrolledTooltip>
              </div>
            }
            {
              value?.validationRequired &&
              <Label className="error margin-top-8 margin-left-8" style={{ fontSize: "12px" }}>{`* ${intl.formatMessage({ id: "PleaseFillInRequiredField" })} `}</Label>
            }
            {/* </div> */}
          </div>
        )
      })
    )
  }

  const displayCustomerPayment = () => {
    if (props.validation.values.paymentDetails.length < 1 && (!props.blnShowMinimumPaymentRequirement || !props.validation.values.minimumPaymentAmount || (props.validation.values.minimumPaymentAmount && props.validation.values.minimumPaymentAmount < 1))) {
      return (<div />)
    }

    return (
      <div>
        {
          props.validation.values.paymentDetails.map((value: CustomerPaymentDetailObject, index) => (
            <div className="flex-direction-row mb-4" key={`${value.paymentMethodId}_${index}`}>
              {/* <div className="avatar-sm"> */}
              <Col xs="8" md="8" xl="8" style={{ alignItems: 'center' }}>
                <p className={`margin-bottom-0 ${props.disabledCustomerPayment && "small-text"}`}>{`${value.paymentMethodName}${value.last4CardDigit && value.cardType ? ` (${value.cardType} | ${value.last4CardDigit})` : ""}`}</p>
                <p className={`${props.disabledCustomerPayment && "small-text"} payment-method-sub-text`}>{`${value?.paymentMethodOptionName ? `${value.paymentMethodOptionName}` : ""}${value.paymentIssuerBankName ? ` - ${value.paymentIssuerBankName}` : ""}`}</p>
              </Col>
              <div style={{ flex: 1, alignItems: 'center', justifyContent: 'end', display: 'flex', padding: '0px', marginRight: props.disabledCustomerPayment ? "0px" : "20px" }}>
                <p className={`margin-bottom-0 ${props.disabledCustomerPayment && "small-text"}`}>{returnPriceWithCurrency(value.paidAmount)}</p>
              </div>
              {
                !props.disabledCustomerPayment
                &&
                <div style={{ alignItems: 'center', display: 'flex' }} className="text-danger flex-right"
                  onClick={() => {
                    if (props.deletePaymentOrder && !props.disabledCustomerPayment) {
                      if (value.isCustomerCredit) {
                        let finalAvailableCreditsTemp = Number(props.validation.values?.availableCredits) + Number(value.paidAmount);
                        props.validation.setFieldValue("availableCredits", finalAvailableCreditsTemp.toFixed(2))
                      }
                      props.deletePaymentOrder(index)
                    }
                  }}>
                  <DeleteIcon size={18} id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {intl.formatMessage({ id: "Delete" })}
                  </UncontrolledTooltip>
                </div>
              }
              {/* </div> */}
            </div>
          ))
        }
        {/* {
          props.validation.values.minimumPaymentAmount > 0
          &&
          props.blnShowMinimumPaymentRequirement
          &&
          <div className='text-danger' style={{fontSize: '12px'}}>
            {intl.formatMessage({ id: "CustomerOrderMinimumPaymentRequire" }, {amount: returnPriceWithCurrency(props.validation.values.minimumPaymentAmount)})}
          </div>
        }
        <Borderline /> */}
      </div>
    )
  }

  return (
    <div>
      {/*Item Selected */}
      {
        displayCustomerOrder()
      }
      <Borderline />
      <Row>
        <Col>
          <span className="small-text">{`${intl.formatMessage({ id: "SubTotal" })} (${props.validation.values.totalCount} ${intl.formatMessage({ id: "Items" }).toLowerCase()})`}</span>
        </Col>
        <Col className="flex-right">
          <span className="small-text">{returnPriceWithCurrency(props.validation.values.totalSellingPrice)}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="small-text">{intl.formatMessage({ id: "Tax" })}</span>
        </Col>
        <Col className="flex-right">
          <span className="small-text">{returnPriceWithCurrency(props.validation.values.totalTax)}</span>
        </Col>
      </Row>
      {
        Constants.supportMalaysiaRounding && props.blnDisplayRounding
        ?
        <Row>
          <Col className="flex-direction-row">
            <span className="small-text" style={{marginTop: '2px'}}>{intl.formatMessage({ id: "Rounding" })}</span>
            <GeneralInput
              title={""}
              name="hasRounding"
              className="margin-left-8"
              type="checkbox"
              disabled={props.disabledCustomerPayment}
              validation={props.validation}
              onChangeFunctionWithSetValidationField={(valueRounding)=> {
                let valuesTemp = _.cloneDeep(props.validation.values);
                valuesTemp.hasRounding = valueRounding;
                if (valueRounding) {
                  let finalPriceAfterRounding = returnMalaysiaCurrencyRounding(valuesTemp.totalPrice);
                  let totalRoundingPrice = Math.round((finalPriceAfterRounding - valuesTemp.totalPrice) * 100) / 100;
                  valuesTemp.totalPriceBeforeRounding = Number(valuesTemp.totalPrice);
                  valuesTemp.totalPrice = Number(finalPriceAfterRounding);
                  valuesTemp.totalRoundingPrice = totalRoundingPrice;
                }
                else {
                  valuesTemp.totalPrice = valuesTemp.totalPriceBeforeRounding;
                  valuesTemp.totalRoundingPrice = 0;
                  valuesTemp.hasRounding = valueRounding;
                }
                let prefillPaidNumber : any = calculateBalanceAmount({values: valuesTemp}, "paymentDetails", "totalPrice", valuesTemp.paymentDetails);
                valuesTemp.paidNumber = prefillPaidNumber;
                props.validation.setValues(valuesTemp);                
              }} />
          </Col>
          <Col className="flex-right">
            <span className="small-text">{returnPriceWithCurrency(props.validation.values.totalRoundingPrice || 0)}</span>
          </Col>
        </Row>
        :
        undefined
      }
      <Borderline />
      <Row>
        <Col>
          <Label className="mb-0">{intl.formatMessage({ id: "Total" })}</Label>
        </Col>
        <Col className="flex-right">
          <Label className="mb-0">{returnPriceWithCurrency(props.validation.values.totalPrice)}</Label>
        </Col>
      </Row>
      <Borderline />
      {displayCustomerPayment()}
      {
        props.blnTotalBalance
        &&
        <>
          <Row>
            <Col>
              <Label className="mb-0">{intl.formatMessage({ id: "Balance" }).toUpperCase()}</Label>
            </Col>
            <Col className="flex-right">
              <Label className="mb-0">{returnPriceWithCurrency(props.validation.values.totalBalance)}</Label>
            </Col>
          </Row>
          <Borderline />
        </>
      }
      {
        returnSubBrandList().length > 0
        &&
        !props.loadingSubBrand
        &&
        <>
          <DropDownWithTitle
            name={"subBrandId"}
            title={intl.formatMessage({ id: "SubBrand"})}
            subTitle={` ${intl.formatMessage({ id: "PleaseConfirmBrand"})}`}
            titleClassName={"shadow-effect"}
            specifyReturnFieldName={[{"field": "subBrandName", "value": "name"},{"field": "subBrandId", "value": "id"}]}
            labelField={"name"}
            valueField={"id"}
            options={returnSubBrandList()}
            disabled={props.disabledCustomerOrder}
            validationRequired={true}
            initialLabel={props.validation.values.subBrandName}
            initialValue={props.validation.values.subBrandId}
            validation={props.validation}/>
          <Borderline />
        </>
      }
      <GeneralTextArea
        title={intl.formatMessage({ id: "Note" })}
        name="note"
        row={5}
        disabled={props.disabledNote}
        validation={props.validation} />
      <Borderline />
      {/* <Row>
        <Col>        
          <GeneralInput
            title={""}
            name="checkboxVoidCustomerSalesOrderNo"
            type="checkbox"
            disabled={props.disabledVoidedSalesOrderNo}
            validationRequired={false}
            validation={props.validation}
            onChangeFunction={(valueCheckBox)=> {
              props.validation.setFieldValue("voidedSalesOrderNo", "")
              props.validation.setFieldValue("submissionDate", "")
              props.validation.setFieldValue("isBackdate", false)
            }}
            childrenUI={
              <Label className="margin-bottom-0 margin-left-4">
                {intl.formatMessage({ id: "IsRecreateForVoidedSalesOrder" })}
              </Label>
            }
          />
        </Col>
        {
          blnPermissionBackDateCustomerCart
          &&
          <Col>
            <GeneralInput
              title={""}
              name="isBackdate"
              type="checkbox"
              disabled={props.disabledVoidedSalesOrderNo}
              validationRequired={false}
              validation={props.validation}
              onChangeFunction={(valueCheckBox)=> {
                props.validation.setFieldValue("voidedSalesOrderNo", "")
                props.validation.setFieldValue("submissionDate", valueCheckBox ? moment().format(Constants.textInputDateAndTimeFormat) : "");
                props.validation.setFieldValue("checkboxVoidCustomerSalesOrderNo", false)
              }}
              childrenUI={
                <Label className="margin-bottom-0 margin-left-4">
                  {intl.formatMessage({ id: "IsApplySalesOrderSubmissionDate" })}
                </Label>
              }
            />
          </Col>
        }
      </Row>
      {
        props.validation.values.checkboxVoidCustomerSalesOrderNo
        &&
        <GeneralInput
          title={""}
          name="voidedSalesOrderNo"
          placeholder={intl.formatMessage({ id: "VoidedCustomerSalesOrderNo" })}
          type="text"
          disabled={props.disabledVoidedSalesOrderNo}
          validationRequired={false}
          validation={props.validation}
        />
      }
      {
        blnPermissionBackDateCustomerCart
        &&
        props.validation.values.isBackdate
        &&
        <GeneralInput
          title={""}
          name="submissionDate"
          placeholder={intl.formatMessage({ id: "SalesOrderSubmissionDate" })}
          type="datetime-local"
          disabled={props.disabledVoidedSalesOrderNo}
          validationRequired={false}
          validation={props.validation}
          minCurrentDate={!props.blnAllowCrossMonthSales}
          minDateValue={props.blnAllowCrossMonthSales ? undefined : moment().startOf('month').format(Constants.displayDateFormat)}
          maxCurrentDate={true}
        />
      }
      <Borderline /> */}
    </div>
  )
}
