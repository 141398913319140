import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import {
  checkPermission,
	contructValidationErrorMessage,
	IncludesLocationPathName,
  returnCurrentOnlyDate,
  returnOperatingHours,
  returnTableCellUI,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
	Row,
	Col,
	Form,
  Label
} from "reactstrap";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import moment from "moment";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { burgerMenu, DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import LoadingModal from "../../app/components/modal/LoadingModal";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";

const AppointmentBlockerDetail = () => {
	//Use Params
	const { id } = useParams();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const {
		appointmentStore,
		branchStore,
		commonStore
	} = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const {
    roomList,
		addAppointmentBlocker,
		updateAppointmentBlocker,
		appointmentBlockerDetail,
		setAppointmentBlockerDetail,
    setRoomList,
		getAppointmentBlockerWithId,
    getRoomWithBranchId,
    deleteAppointment
	} = appointmentStore;
	const { branchDropDownList, branchBusinessHour, setBranchBusinessHour, getBranchDropDown, getBranchBusinessHour } = branchStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${
		addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
	} ${intl.formatMessage({id: "AppointmentBlocker"})}`;
	const breadCrumbList = [{ title: `${intl.formatMessage({id: "Appointment"})}`, urlPath: RoutesList.appointment }];
  const [timeList, setTimeList] = useState<ObjectForDropdown[]>([]);
  const [refreshTime, setRefreshTime] = useState(false);
  const [loadingBranchAfterSelect, setLoadingBranchAfterSelect] = useState(false);
  const [initialRunEdit, setInitialRunEdit] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [deleteAppointmentBlockerModal, setDeleteAppointmentBlockerModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [loadingDeleteAppointment, setLoadingDeleteAppointment] = useState(false);
  const blnPermissionUpdateAppointment = checkPermission([PermissionConstants.UpdateAppointment]);
  const blnPermissionDeleteBlocker = checkPermission([PermissionConstants.DeleteBlocker]);
  const [blockerRoomOccupiedModal, setBlockerRoomOccupiedModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: returnCurrentOnlyDate(), toDate: returnCurrentOnlyDate()});
  const dateRangeLimit = {
    fromDate: moment().format(Constants.displayDateFormat),
    toDate: "9999-12-31"
  }
  const location = useLocation();

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: appointmentBlockerDetail || {
      branchId: "",
      branchName: "",
      roomId: "",
      roomName: "",
      title: "",
      isAllDay: false,
      startDate: returnCurrentOnlyDate(),
      startTime: "",
      endTime: "",
      appointStartDateTime: "",
      appointEndDateTime: "",
      remark: "",
      ignoreOverlap: false,
      madeBy: "",
      editedBy: "",
      madeOn: "",
      editedOn: ""
		},
		validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})})),
      roomId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Room"})})),      
      startDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})})),
      startTime: Yup.string()
      .test("isAllDay", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "StartTime"})}), (value: any, context) => {
        if (!context.parent.isAllDay && !value) {
          return false;
        }
        return true;
      }).nullable(),
      endTime: Yup.string()
      .test("isAllDay", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "EndTime"})}), (value: any, context) => {
        if (!context.parent.isAllDay && !value) {
          return false;
        }
        return true;
      }).nullable(),
      title: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Title"})})),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.appointStartDateTime = moment(`${addAction ? selectedDateRange.fromDate : valuesTemp.startDate} ${valuesTemp.startTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
      valuesTemp.appointEndDateTime = moment(`${addAction ? selectedDateRange.toDate : valuesTemp.startDate} ${valuesTemp.endTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
   
			try {
        let resultAddOrUpdateBlockerAPI : any = undefined;
				if (addAction) {
					resultAddOrUpdateBlockerAPI = await addAppointmentBlocker(valuesTemp);
				} else {
					resultAddOrUpdateBlockerAPI= await updateAppointmentBlocker(valuesTemp);
				}
        if (resultAddOrUpdateBlockerAPI.status === Constants.failed) {
          if (resultAddOrUpdateBlockerAPI.data?.message) {
            if (resultAddOrUpdateBlockerAPI.data.message === 'Appointment-004') {
              setBlockerRoomOccupiedModal(true);
            }
          }
        }
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		async function fetchAppointmentDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
        getBranchDropDown(),
			];
			if (id && !addAction) {
        setInitialRunEdit(true);
				aryAPI.push(getAppointmentBlockerWithId(id));
			}
			await Promise.all(aryAPI);

      if (addAction) {
        setAppointmentBlockerDetail(undefined);
        let clearRoom = false;
        if (location.state) {
          if (location.state.detail) {
            validation.setValues((values)=> ({
              ...values,
              branchId: location.state.detail.branchId,
              branchName: location.state.detail.branchName
            }))
            fetchDataAfterBranchSelect(location.state.detail.branchId, false)
          }
          else {
            clearRoom = true;
          }
        }
        else {
          clearRoom = true;
        }
        //This logic required is because when user navigate from Appointment List -> It doesn't pass any info, 
        //so it require to clear  room list
        if (clearRoom) {
          setRoomList([]);
        }
      }
			setLoading(false);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateAppointment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateAppointment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAppointment], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateAppointment){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.appointment}/blocker/edit/${id}`)} })
    }

		fetchAppointmentDetailAPI();

    return (()=> {
      if (id && !addAction) {
        setAppointmentBlockerDetail(undefined);
        setBranchBusinessHour([]);
      }
    })
	}, []);

  useEffect(()=> {
    if (id && !addAction) {
      if (appointmentBlockerDetail) {
        validation.setFieldValue("startDate", moment(appointmentBlockerDetail.appointStartDateTime).format(Constants.displayDateFormat));
        validation.setFieldValue("startTime", moment(appointmentBlockerDetail.appointStartDateTime).format(Constants.displayTimeFormat));
        validation.setFieldValue("endTime", moment(appointmentBlockerDetail.appointEndDateTime).format(Constants.displayTimeFormat));
        if (appointmentBlockerDetail.branchId) {
          fetchDataAfterBranchSelect(appointmentBlockerDetail.branchId, false)
        }
        if (appointmentBlockerDetail.roomId === Constants.emptyId) {
          validation.setFieldValue("roomName", "Not Available")
        }
      }
    }
  }, [appointmentBlockerDetail])

  useEffect(()=> {
    if (validation.values.isAllDay) {
      validation.setValues({
        ...validation.values,
        startTime: "",
        endTime: ""
      })
    }
  }, [validation.values.isAllDay])

  useEffect(()=> {
    onChangeDate();
  }, [branchBusinessHour])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const AppointmentHistoryColumns = [
    {
      dataField: "performedDate",
      text: intl.formatMessage({ id: "PerformedDate" }).toUpperCase(),
      formatter: (cellContent, row, indexRow) =>  
      <div>
        {`${moment(row.performedDate).format(Constants.displayDateAndTimeFormat)}`}
      </div>
    },
    {
      dataField: "oldValue",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => {
        let aryViews : any = [];
				row.finalOldValue.map((value, indexFinalOldValue) => {
					aryViews.push(
						returnTableCellUI(`oldValue_${indexFinalOldValue}`, indexFinalOldValue, row.finalOldValue.length, value)
					)
				})
				return aryViews;
      }
    },
    {
      dataField: "newValue",
      text: intl.formatMessage({ id: "ChangedTo" }).toUpperCase(),
      formatter: (cellContent, row) => {
        let aryViews : any = [];
				row.finalNewValue.map((value, indexFinalNewValue) => {
					aryViews.push(
						returnTableCellUI(`newValue_${indexFinalNewValue}`, indexFinalNewValue, row.finalNewValue.length, value)
					)
				})
				return aryViews;
      }
    },
    {
      dataField: "performedBy",
      text: intl.formatMessage({ id: "PerformedBy" }).toUpperCase(),
    }
  ]
  
  const fetchDataAfterBranchSelect = async (branchId: string, removeData: boolean=true) => {
    setLoadingBranchAfterSelect(true);
    let aryAPI: any = [
      getRoomWithBranchId(branchId),
      getBranchBusinessHour(branchId)
    ]
    await Promise.all(aryAPI);
    if (removeData) {
      validation.setFieldValue("roomName", "");
      validation.setFieldValue("roomId", "");
    }
    setLoadingBranchAfterSelect(false);
  }

  const onChangeDate = (startDateTemp : string = validation.values.startDate) => {
    if (branchBusinessHour && startDateTemp) {
      if (branchBusinessHour.length > 0) {
        let selectedDay = moment(startDateTemp).format(Constants.displayDay);
        let indexSelectedDay = _.findIndex(branchBusinessHour, {name: selectedDay})
        if (indexSelectedDay > -1) {
          let startTime = branchBusinessHour[indexSelectedDay].fromTime;
          let endTime = branchBusinessHour[indexSelectedDay].toTime;
          setTimeList(returnOperatingHours(true, false, startTime, endTime));
        }
      }
      setRefreshTime(true);
      if (initialRunEdit) {
        setInitialRunEdit(false);
      }
      else {
        validation.setFieldValue("startTime", "");
        validation.setFieldValue("endTime", "");
      }
      setTimeout(()=> {
        setRefreshTime(false);
      }, 100)
    }
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const displayRowData = (displayText:string, value: string) => {
    return (
      <Row className="flex-align-items-center-without-height">
        <Col xl={4} lg={4} md={4} xs={4}>
          <Label className="mb-0">
            {displayText}
          </Label> 
        </Col>
        <Col xl={8} lg={8} md={8} xs={8}>
          <div className="flex-direction-row flex-align-items-center-without-height">
            <Label className="mb-0">
              {value}
            </Label>
          </div>
        </Col>
      </Row>
    )
  }

	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={id ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row form={+true}> {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({id: "AppointmentBlocker"})})}
                  h4Title/>
                <Row className="mt-4">
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"branchId"}
                      title={intl.formatMessage({ id: "Branch"})}
                      specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={disabledFieldInput || viewAction || loadingBranchAfterSelect}
                      validationRequired={true}
                      initialLabel={validation.values.branchName}
                      initialValue={validation.values.branchId}
                      validation={validation}
                      onChangeFunction={fetchDataAfterBranchSelect}/>
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    {
                      loadingBranchAfterSelect
                      ?
                      <Loading />
                      :
                      <DropDownWithTitle
                        name={"roomId"}
                        title={intl.formatMessage({ id: "Room"})}
                        specifyReturnFieldName={[{"field": "roomName", "value": "name"},{"field": "roomId", "value": "id"}]}
                        labelField={"name"}
                        valueField={"id"}
                        options={roomList}
                        disabled={disabledFieldInput || viewAction}
                        initialLabel={validation.values.roomName}
                        initialValue={validation.values.roomId}
                        validationRequired={true}
                        validation={validation}/>
                    }
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent>
                    {
                      addAction
                      ?
                      <GeneralDateRange
                        title={intl.formatMessage({ id: "DateRange" })}
                        name={"DateRange"}
                        disabled={disabledFieldInput || viewAction}
                        onChangeFunction={onSelectDateRange}
                        validationRequired={true}
                        initialValue={selectedDateRange}
                        minMaxDateObj={dateRangeLimit}
                        blnMinMaxDateObjWithBothViews={true} />
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "Date"})}
                        name="startDate"
                        type="date"
                        validationRequired={
                          true
                        }
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        minCurrentDate={true}
                        validation={validation}
                        onChangeFunction={(startDateTemp)=> {
                          onChangeDate(startDateTemp)
                        }}/> 
                    }
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "AllDay"})}
                      name="isAllDay"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">Yes</Label>}/>
                  </DoubleColumnRowParent>
                </Row>
                {
                  !validation.values.isAllDay && !refreshTime
                  &&
                  <Row>
                    <DoubleColumnRowParent>
                      <DropDownWithTitle
                        name={"startTime"}
                        title={intl.formatMessage({ id: "StartTime"})}
                        specifyReturnFieldName={[{"field": "startTime", "value": "value"}]}
                        labelField={"label"}
                        valueField={"value"}
                        options={timeList}
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={true}
                        initialLabel={validation.values.startTime}
                        initialValue={validation.values.startTime}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent>
                      <DropDownWithTitle
                        name={"endTime"}
                        title={intl.formatMessage({ id: "EndTime"})}
                        specifyReturnFieldName={[{"field": "endTime", "value": "value"}]}
                        labelField={"label"}
                        valueField={"value"}
                        options={timeList}
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={true}
                        initialLabel={validation.values.endTime}
                        initialValue={validation.values.endTime}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                  </Row>
                }
                <SingleColumnRowParent>
                  <GeneralInput
                    title="Title"
                    name="title"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title="Remark"
                    name="remark"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}/>
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn 
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              showExtraRightButton={blnPermissionDeleteBlocker && !addAction ? true : false}
              extraRightButtonColor="btn btn-danger"
              extraRightButtonTitle="Delete"
              extraRightButtonFunction={()=> {
                setDeleteAppointmentBlockerModal(true);
              }}
              extraRightDirtyDisabled={true}
              extraRightButtonWithoutForm={true}
            />
          </Form>
        )}
        {
          !loading && !addAction
          ?
          <div className="margin-top-32 standard-layout">
            <LineBreakWithTittle 
              paddingBottom="0px"
              title={intl.formatMessage({ id: "History" })} 
              className="mb-4"
              h4Title/>
            <Col xl="6" lg="12" md="12" xs="12">
              {displayRowData(intl.formatMessage({ id: "MadeBy" }), appointmentBlockerDetail?.madeBy || Constants.emptyDataV2)}
              <hr/>
              {displayRowData(intl.formatMessage({ id: "EditedBy" }), appointmentBlockerDetail?.editedBy || Constants.emptyDataV2)}
              <hr/>
              {displayRowData(intl.formatMessage({ id: "MadeOn" }), appointmentBlockerDetail?.madeOn ? moment(appointmentBlockerDetail.madeOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyDataV2)}
              <hr/>
              {displayRowData(intl.formatMessage({ id: "EditedOn" }), appointmentBlockerDetail?.editedOn ? moment(appointmentBlockerDetail.editedOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyDataV2)}
              <div style={{height: "10px"}}/>
            </Col>
          </div>
          :
          null
        }
      </DetailViewLayout>
      {
        deleteAppointmentBlockerModal
        &&
        <ActionPromptModal
          title={`${intl.formatMessage({ id: "DeleteAppointmentBlocker" }, {br: <br/>})}`}
          primaryActionTitle={`Confirm`}
          showPrompt={deleteAppointmentBlockerModal}
          setShowPrompt={setDeleteAppointmentBlockerModal}
          onPrimaryClick={async () => {
            setDeleteAppointmentBlockerModal(false);
            setLoadingDeleteAppointment(true);
            await deleteAppointment(id, "");
            setLoadingDeleteAppointment(false);
          }}
        />
      }
      {
        loadingDeleteAppointment
        &&
        <LoadingModal/>
      }
      {
        blockerRoomOccupiedModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "BlockerAppointmentRoomOccupied" })}`}
          primaryActionTitle={`Yes`}
          showPrompt={blockerRoomOccupiedModal}
          setShowPrompt={setBlockerRoomOccupiedModal}
          onPrimaryClick={()=> {
            validation.setFieldValue("ignoreOverlap", true);
            validation.submitForm();
          }}
        />
      }
    </div>
	);
};

export default observer(AppointmentBlockerDetail);
