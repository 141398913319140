import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, displayPromotionCode, getPurchaseTypeIcon, paymentAdjustmentStatusColor, returnPriceWithCurrency } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { RefundDetail } from "../../../app/models/customerRefund";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from '../../..';
import { PaymentObject } from "../../../app/models/customerPaymentAdjustment";
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralTextArea from "../../../app/components/form/GeneralTextArea";
import GeneralInputWithClickableUI from "../../../app/components/form/GeneralInputWithClickableUI";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerPaymentAdjustmentDetail: any;
  setSelectedCustomerPaymentAdjustmentDetail: Function;
  reviewCustomerPaymentAdjustment?: Function;
  setAutoRegenerateRedemptionResponse?: any;
}

export default observer(function PaymentAdjustmentDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingReview, setLoadingReview] = useState(false);
  const blnPermissionReviewPaymentAdjustment = checkPermission([PermissionConstants.ReviewPaymentAdjustment]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const defaultReviewCustomerPaymentAdjustmentDetails = {
    id: "",
    remark: "",
    workflowAction: "",
  }
  const [reviewCustomerRefundDetails, setReviewCustomerRefundDetails] = useState(defaultReviewCustomerPaymentAdjustmentDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })
  const [totalPreviousPayment, setTotalPreviousPayment] = useState(0);
  const [totalNewPayment, setTotalNewPayment] = useState(0);

  const hideModal = () => {
    setReviewCustomerRefundDetails(defaultReviewCustomerPaymentAdjustmentDetails);
    props.setModal(false);
  }

  useEffect(() => {
    if (props.selectedCustomerPaymentAdjustmentDetail) {
      validationLocal.setFieldValue("branchName", props.selectedCustomerPaymentAdjustmentDetail.branchName);
      validationLocal.setFieldValue("adjustmentDate", moment(props.selectedCustomerPaymentAdjustmentDetail.adjustmentDate).format(Constants.displayDateAndTimeFormat));
      validationLocal.setFieldValue("customerSalesOrderId", props.selectedCustomerPaymentAdjustmentDetail.customerSalesOrderId);
      validationLocal.setFieldValue("customerSalesOrderNo", props.selectedCustomerPaymentAdjustmentDetail.customerSalesOrderNo);
      validationLocal.setFieldValue("customerPaymentAdjustmentNo", props.selectedCustomerPaymentAdjustmentDetail.customerPaymentAdjustmentNo);
      validationLocal.setFieldValue("remark", props.selectedCustomerPaymentAdjustmentDetail.remark || Constants.emptyData);
      validationLocal.setFieldValue("reviewRemark", props.selectedCustomerPaymentAdjustmentDetail.reviewRemark || Constants.emptyData);

      let totalPreviousPaymentTemp = 0;
      if (props.selectedCustomerPaymentAdjustmentDetail.previousPayments) {
        props.selectedCustomerPaymentAdjustmentDetail.previousPayments.map((value) => {
          totalPreviousPaymentTemp += Number(value.paidAmount)
        })
        setTotalPreviousPayment(totalPreviousPaymentTemp);
      }
      let totalNewPaymentTemp = 0;
      if (props.selectedCustomerPaymentAdjustmentDetail.newPayments) {
        props.selectedCustomerPaymentAdjustmentDetail.newPayments.map((value) => {
          totalNewPaymentTemp += Number(value.paidAmount)
        })
        setTotalNewPayment(totalNewPaymentTemp);
      }
    }
  }, [props.selectedCustomerPaymentAdjustmentDetail])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      adjustmentDate: "",
      branchName: "",
      customerSalesOrderNo: "",
      customerSalesOrderId: "",
      customerPaymentAdjustmentNo: "",
      remark: "",
      reviewRemark: "",
      previousPayments: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const blnProcessingStatus = props.selectedCustomerPaymentAdjustmentDetail.status == Constants.processing;

  const displayHeaderRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={2} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const onClickReviewPaymentAdjustment = async (valueAction: string) => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...reviewCustomerRefundDetails, id: props.selectedCustomerPaymentAdjustmentDetail.id, workflowAction: valueAction })
    setReviewCustomerRefundDetails(reviewCreditAdjustmentTemp);
    if (props.reviewCustomerPaymentAdjustment) {
      setLoadingReview(true);
      let resultReviewPaymentAdjustment = await props.reviewCustomerPaymentAdjustment(reviewCreditAdjustmentTemp)
      if(resultReviewPaymentAdjustment && resultReviewPaymentAdjustment.status === Constants.success){
        props.setAutoRegenerateRedemptionResponse(resultReviewPaymentAdjustment.data)
      }
      setLoadingReview(false);
    }
  }

  const displayPaymentInfo = (value) => {
    return (
      value.cardType
      ?
      <>
        <p className={`margin-bottom-0`}>{`${value.cardType} | ${value.last4CardDigitNo}`}</p>
        <p className="payment-method-sub-text">{`${value.optionType ? `${value.optionType}` : ""}${value.paymentIssuerBankName ? ` - ${value.paymentIssuerBankName}` : ""}`}</p>
      </>
      :
      value.optionType
      ?
      <p className={`margin-bottom-0`}>{`${value.optionType}${value.paymentIssuerBankName ? ` - ${value.paymentIssuerBankName}` : ""}`}</p>
      :
      Constants.emptyData
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReview && !successMessage) {
            hideModal();
          }
        }}
        size={"lg"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          !props.selectedCustomerPaymentAdjustmentDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{flex:1 }}>
                  <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "PaymentAdjustment" }) })}</h5>
                  <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedCustomerPaymentAdjustmentDetail.status}
                    statusColorFunction={paymentAdjustmentStatusColor}/>
                </div>
                {
                  !loadingReview && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
              <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Branch" })}
                      name="branchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Date" })}
                      name="adjustmentDate"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PaymentAdjustmentNo" })}
                      name="customerPaymentAdjustmentNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "SalesOrderNo" })}
                      name="customerSalesOrderNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                      onClickFunction={()=> {
                        if (blnPermissionManageCustomerSalesOrder) {
                          window.open(`/${RoutesList.customerSalesOrder}/view/${validationLocal.values.customerSalesOrderId}`)
                        }
                      }}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <GeneralTextArea
                  title="Remark"
                  name="remark"
                  row={3}
                  disabled={true}
                  validation={validationLocal}/>
                <Borderline />
                {
                  props.selectedCustomerPaymentAdjustmentDetail.previousPayments && props.selectedCustomerPaymentAdjustmentDetail.previousPayments.length > 0 &&
                  <>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "PreviousPayments" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentInfo" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.selectedCustomerPaymentAdjustmentDetail.previousPayments.map((value: PaymentObject, index) => (
                              <tr key={index}>
                                <td>{value.paymentMethodName}</td>
                                <td>
                                  {displayPaymentInfo(value)}
                                </td>
                                <td className="text-end">{returnPriceWithCurrency(value.paidAmount)}</td>
                              </tr>
                            ))
                          }
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(totalPreviousPayment))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                }
                <Borderline />
                {
                  props.selectedCustomerPaymentAdjustmentDetail.newPayments && props.selectedCustomerPaymentAdjustmentDetail.newPayments.length > 0 &&
                  <>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "NewPayments" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentInfo" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.selectedCustomerPaymentAdjustmentDetail.newPayments.map((value: PaymentObject, index) => (
                              <tr key={index}>
                                <td>{value.paymentMethodName}</td>
                                <td>
                                  {displayPaymentInfo(value)}
                                </td>
                                <td className="text-end">{returnPriceWithCurrency(value.paidAmount)}</td>
                              </tr>
                            ))
                          }
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(totalNewPayment))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                }
                <>
                  <Borderline />
                  <GeneralTextAreaForModal
                    title={intl.formatMessage({ id: "Review" })}
                    name={blnProcessingStatus ? "remark" : "reviewRemark"}
                    className={errorState.remark ? "mb-0" : "mb-3"}
                    row={4}
                    validationRequired={errorState.remark}
                    field={blnProcessingStatus ? reviewCustomerRefundDetails : props.selectedCustomerPaymentAdjustmentDetail}
                    setFieldValue={setReviewCustomerRefundDetails}
                    disabled={loadingReview || !blnProcessingStatus || Boolean(successMessage) || !blnPermissionReviewPaymentAdjustment} />
                  {errorState.remark ? (
                    <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                  ) : null}
                </>
                <div className="mb-2 mt-4 flex-right">
                  {/* <MyButton
                    type="button"
                    class={`btn btn-back margin-right-8`}
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Back" })}
                    onClick={() => {
                      hideModal();
                    }}
                    disable={loadingReview || Boolean(successMessage)}
                    loading={loadingReview}
                  /> */}
                  {
                    blnProcessingStatus
                    &&
                    blnPermissionReviewPaymentAdjustment
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-danger margin-right-8"
                        style={{ width: '20%' }}
                        content={Constants.reject}
                        onClick={() => {
                          let errorStateTemp = {
                            remark: false,
                          };
                          let blnErrorFound = false;
                          if (!reviewCustomerRefundDetails.remark) {
                            errorStateTemp.remark = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickReviewPaymentAdjustment(Constants.reject);
                          }
                        }}
                        disable={loadingReview || !blnProcessingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      <MyButton
                        type="submit"
                        class="btn btn-success"
                        style={{ width: '20%' }}
                        content={Constants.approve}
                        onClick={() => {
                          onClickReviewPaymentAdjustment(Constants.approve);
                        }}
                        disable={loadingReview || !blnProcessingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})