import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { AddOptionsValueField, batchJobHistoryStatusColor, batchJobStatusColor, checkPermission, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Nav, NavItem, NavLink, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import { burgerMenu } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import moment from "moment";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import TableActionButton from "../../app/components/table/TableActionButton";
import { BatchJobHistoryDetailObject } from "../../app/models/batchJob";
import BatchJobHistoryDetailModal from "./Modal/BatchJobHistoryDetailModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const BatchJobDetail = () => {
	//Use Params
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { batchJobStore, staticReferenceStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addBatchJob, updateBatchJob, batchJobDetail, setBatchJobDetail, getBatchJobWithId, getBatchClassDropdownList, getBatchJobHistory, batchJobHistoryList, batchJobHistoryTotalItems, getBatchJobHistoryWithId, refreshBatchJob } = batchJobStore;
	const { batchJobRunningType, getStaticReferenceWithType } = staticReferenceStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "BatchJob" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "BatchJob" }), urlPath: RoutesList.batchJob }];
	const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
	const [blnSubmitting, setBlnSubmitting] = useState(false);
	const blnPermissionUpdateBatchJob = checkPermission([PermissionConstants.UpdateBatchJob]);
	const [optionsBatchClass, setOptionsBatchClass] = useState<any>([]);
	const [batchJobHistoryModal, setBatchJobHistoryModal] = useState(false);
	const [loadingBatchJobHistory, setLoadingBatchJobHistory] = useState(false);
	const [batchJobHistoryDetailModal, setBatchJobHistoryDetailModal] = useState(false);
	const [selectedBatchJobHistoryDetail, setSelectedBatchJobHistoryDetail] = useState<BatchJobHistoryDetailObject | undefined>(undefined);
	const pageSize = Constants.defaultPageSize;
  const [refreshBatchJobModal, setRefreshBatchJobModal] = useState(false);
	
	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: batchJobDetail || {
			name: "",
			description: "",
			className: "",
			batchJobRunningType: "",
			isActive: false,
			startRunningDateTime: "",
			lastRunningDateTime: "",
			nextRunningDateTime: "",
			batchJobStatus: "",
			lastExceptionMessage: "",
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BatchJobName" }) })),
			className: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ClassName" }) })),
			batchJobRunningType: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "BatchJobRunningType" }) })),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			try {
				if (addAction) {
					await addBatchJob(valuesTemp);
				} else {
					await updateBatchJob(valuesTemp);
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const BatchJobHistoryColumns = [
		{
			dataField: "id", //field name from array to display
			text: "id", //display name
			sort: true, //sort function enable
			hidden: true, //hide UI
			formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
		},
		{
			dataField: "batchJobName",
			text: intl.formatMessage({ id: "Name" }).toUpperCase(),
		},
		{
			dataField: "executeDateTime",
			text: intl.formatMessage({ id: "Date" }).toUpperCase(),
			formatter: (cellContent, row) =>
				<>
					<div>
						{row.executeDateTime !== Constants.invalidDateTime ? moment(row.executeDateTime).format(Constants.displayDateFormat) : Constants.emptyData}
					</div>
					<div className="small-text">
						{row.executeDateTime !== Constants.invalidDateTime ? moment(row.executeDateTime).format(Constants.displayTimeFormat) : ""}
					</div>
				</>,
		},
		{
			dataField: "jobStatus",
			text: intl.formatMessage({ id: "Status" }).toUpperCase(),
			headerStyle: { textAlign: "center" },
			formatter: (cellContent, row) => (
				<GeneralStatus
					status={row.jobStatus}
					statusColorFunction={batchJobHistoryStatusColor} />
			),
		},
		{
			dataField: "exceptionMessage",
			text: intl.formatMessage({ id: "ExceptionMessage" }).toUpperCase(),
			formatter: (cellContent, row) => 
				<div className="text-truncate">
					{row.exceptionMessage || Constants.emptyData}
				</div>
		},
		{
			dataField: "",
			isDummyField: true,
			text: intl.formatMessage({ id: "Action" }).toUpperCase(),
			headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
			style: {
				width: '5%'
			},
			formatter: (cellContent, row) => (
				<>
					<div className="d-flex gap-3 flex-justfity-content-center">
						<TableActionButton
							onClick={() => onClickBatchJobHistoryDetail(row.id)}
							type="view" />
					</div>
				</>
			),
		},
	];

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		async function fetchBatchJobDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
				getStaticReferenceWithType(Constants.batchJobRunningType),
				getBatchClassDropdownList(),
			];
			if (id && !addAction) {
				aryAPI.push(getBatchJobWithId(id));
			}
			let resultAPI = await Promise.all(aryAPI);
			if (resultAPI[1]) {
				let aryBatchClassDropdownTemp: any = [];
				for (var a = 0; a < resultAPI[1].length; a++) {
					aryBatchClassDropdownTemp.push({
						label: resultAPI[1][a],
						value: resultAPI[1][a]
					})
				}
				setOptionsBatchClass(aryBatchClassDropdownTemp)
			}
			setLoading(false);
		}

		if (addAction) {
			setBatchJobDetail(undefined);
		}

		if (!addAction && !viewAction) {
			if (!checkPermission([PermissionConstants.UpdateBatchJob], true)) {
				return;
			}
		}
		else if (addAction) {
			if (!checkPermission([PermissionConstants.CreateBatchJob], true)) {
				return;
			}
		}
		else if (viewAction) {
			if (!checkPermission([PermissionConstants.ManageBatchJob], true)) {
				return;
			}
		}

		if (viewAction && blnPermissionUpdateBatchJob) {
			burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.batchJob}/edit/${id}`) } })
		}

		fetchBatchJobDetailAPI();

		return (() => {
			if (id && !addAction) {
				setBatchJobDetail(undefined);
			}
		})
	}, []);

	useEffect(() => {
		if (validation.isSubmitting) {
			setBlnSubmitting(true);
		}

		if (!validation.isSubmitting && blnSubmitting) {
			setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	useEffect(() => {
		if (batchJobDetail && viewAction) {
			let indexBatchJobHistory = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "ViewBatchJobHistory" })}` })
			if (indexBatchJobHistory < 0) {
				burgerMenuListContent.push({
					label: `${intl.formatMessage({ id: "ViewBatchJobHistory" })}`,
					onFunction: async () => {
						if (loadingBatchJobHistory) {
							return;
						}
						setLoading(true);
						await getBatchJobHistory({
							pageNumber: 1,
							pageSize: pageSize,
							batchJobId: id
						})
						setLoading(false);
						setBatchJobHistoryModal(true);
					}
				})
			}

			validation.setFieldValue("lastRunningDateTime", batchJobDetail.lastRunningDateTime !== Constants.infiniteDateTimeWithValue ? moment(batchJobDetail.lastRunningDateTime).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)
			validation.setFieldValue("startRunningDateTime", moment(batchJobDetail.startRunningDateTime).format(Constants.displayDateAndTimeFormat))
			validation.setFieldValue("nextRunningDateTime", moment(batchJobDetail.nextRunningDateTime).format(Constants.displayDateAndTimeFormat))
		}


	}, [batchJobDetail])

	const onClickBatchJobHistoryDetail = async (batchJobHistoryId: any) => {
		setLoadingBatchJobHistory(true);
		let resultBatchJobHistoryDetail = await getBatchJobHistoryWithId(batchJobHistoryId);
		setSelectedBatchJobHistoryDetail(resultBatchJobHistoryDetail)
		setBatchJobHistoryDetailModal(true);
		setLoadingBatchJobHistory(false);
	}

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}
				burgerMenuList={burgerMenuListContent}
				auditTrailId={viewAction ? id : null}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl={12}>
								<LineBreakWithTittle
									paddingBottom="0px"
									title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "BatchJob" }) })}
									h4Title />
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "BatchJobName" })}
										name="name"
										className="mb-3 mt-4"
										type="text"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<DropDownWithTitle
										name={"className"}
										title={intl.formatMessage({ id: "ClassName" })}
										specifyReturnFieldName={[
											{
												field: "className",
												value: "value",
											},
										]}
										labelField={"label"}
										valueField={"value"}
										options={optionsBatchClass}
										disabled={disabledFieldInput || viewAction}
										initialValue={!addAction ? validation.values.className : undefined}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<DropDownWithTitle
										name={"batchJobRunningType"}
										title={intl.formatMessage({ id: "BatchJobRunningType" })}
										specifyReturnFieldName={[
											{
												field: "batchJobRunningType",
												value: "displayValue",
											},
										]}
										labelField={"displayValue"}
										valueField={"displayValue"}
										options={batchJobRunningType}
										blnValueWithNewSpace={true}
										disabled={disabledFieldInput || viewAction}
										initialValue={!addAction ? validation.values.batchJobRunningType : undefined}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								{
									!viewAction
										?
										<SingleColumnRowParent>
											<GeneralInput
												name="startRunningDateTime"
												title={intl.formatMessage({ id: "StartRunningDateTime" })}
												type="datetime-local"
												disabled={disabledFieldInput}
												validationRequired={false}
												validation={validation}
											/>
										</SingleColumnRowParent>
										:
										<>
											<Row>
												<DoubleColumnRowParent>
													<GeneralInput
														name="startRunningDateTime"
														title={intl.formatMessage({ id: "StartRunningDateTime" })}
														type="text"
														disabled={true}
														validationRequired={false}
														validation={validation}
													/>
												</DoubleColumnRowParent>
												<DoubleColumnRowParent>
													<GeneralInput
														name="lastRunningDateTime"
														title={intl.formatMessage({ id: "LastRunningDateTime" })}
														type="text"
														disabled={true}
														validationRequired={false}
														validation={validation}
													/>
												</DoubleColumnRowParent>
											</Row>
											<SingleColumnRowParent>
												<GeneralInput
													name="nextRunningDateTime"
													title={intl.formatMessage({ id: "NextRunningDateTime" })}
													type="text"
													disabled={true}
													validationRequired={false}
													validation={validation}
												/>
											</SingleColumnRowParent>
										</>
								}
								<SingleColumnRowParent>
									<GeneralTextArea
										title={intl.formatMessage({ id: "Description" })}
										name="description"
										row={5}
										disabled={disabledFieldInput || viewAction}
										validation={validation} />
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "Active" })}
										name="isActive"
										type="checkbox"
										disabled={
											disabledFieldInput || viewAction
										}
										validation={validation}
										childrenUI={
											<Label className="margin-bottom-0 margin-left-4">
												{intl.formatMessage({ id: "Yes" })}
											</Label>
										}
									/>
								</SingleColumnRowParent>
								{/* {
									viewAction
									&&
									<div>
                  <h6>{intl.formatMessage({ id: "Status" })}</h6>
                  <div style={{marginTop: '20px', marginBottom: '10px'}}>
                    <span style={{ backgroundColor: batchJobStatusColor(validation.values.batchJobStatus), color: "#FFFFFF", paddingLeft: '18px', paddingRight: '18px', paddingTop: '8px', paddingBottom: '8px', borderRadius: '5px' }}>{newSpaceBeforeCapitalLetter(validation.values.batchJobStatus)}</span>
                  </div>
                </div>
								} */}
							</Col>
						</Row>
						<div className="flex-direction-row d-print-none">
							{
								!Boolean(successMessage)
								&&
								blnPermissionUpdateBatchJob
								&&
								viewAction
								&&
								<MyButton
									type="button"
									class={`btn btn-warning margin-right-8`}
									content={intl.formatMessage({ id: "Refresh" })}
									disable={loading}
									loading={loading}
									onClick={async () => {
										setRefreshBatchJobModal(true);
									}}
								/>
							}
							<GeneralSubmitAndCancelBtn
								successMessage={successMessage}
								className="width-100-percentage"
								viewAction={false}
								validation={validation}
								hidePrimaryButton={viewAction}
							/>
						</div>
					</Form>
				)}
				{/* BatchJobHistory */}
				{
					batchJobHistoryModal &&
					<SelectWithTableListModal
						blnShow={batchJobHistoryModal}
						setModal={setBatchJobHistoryModal}
						title={intl.formatMessage({ id: "BatchJobHistory" })}
						options={batchJobHistoryList}
						keyField={"id"}
						fieldName={"randomId"}
						columns={BatchJobHistoryColumns}
						validation={validation}
						blnPagination={true}
						totalItem={batchJobHistoryTotalItems}
						requestAPI={getBatchJobHistory}
						paginationSubmitField={{ batchJobId: id }} />
				}
				{
					batchJobHistoryDetailModal
					&&
					<BatchJobHistoryDetailModal
						blnShow={batchJobHistoryDetailModal}
						setModal={setBatchJobHistoryDetailModal}
						selectedBatchJobHistoryDetail={selectedBatchJobHistoryDetail}
						setSelectedBatchJobHistoryDetail={setSelectedBatchJobHistoryDetail}
					/>
				}
				{
          refreshBatchJobModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "RefreshBatchJobAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={refreshBatchJobModal}
            setShowPrompt={setRefreshBatchJobModal}
            onPrimaryClick={async () => { 										
							setLoading(true);
							await refreshBatchJob(id);
							setLoading(false);
						}}
          />
        }
			</DetailViewLayout>
		</div>
	);
};

export default observer(BatchJobDetail);
