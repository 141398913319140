import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, checkPermission, getBranchUser, getBranchId, getBranchName, getDays, displayTypeWithColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";

const OutletSchedule = () => {
  const intl = useIntl();
  //Use Store
  const { reportHumanResourceStore, branchStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { outletScheduleList, setOutletScheduleList, getOutletScheduleList, exportOutletSchedule } = reportHumanResourceStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportHumanResourceReport = checkPermission([PermissionConstants.ExportHumanResourceReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchOutletSchedule() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      
      await Promise.all(aryAPI);
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageHumanResourceReport], true)) {
      return;
    }
    
    fetchOutletSchedule();

    return (()=> {
      setOutletScheduleList([]);
    })
  }, [])

  const fetchOutletScheduleWithLoading = async (blnExport: boolean) => {
    if (selectedBranchId) {
      setCurrentPage(1);
      setLocalLoading(true);
      if (blnExport) {
        await exportOutletSchedule({branchId: selectedBranchId, year: selectedYear || 0, month: selectedMonthValue})
      }
      else {
        await getOutletScheduleList({pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue});
        setOutletScheduleColumns(returnOutletScheduleColumns());
      }
      setLocalLoading(false);
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
    }
	};

  //Table Content UI
  const returnOutletScheduleColumns = () => {
    let aryOutletScheduleColumnsTemp : any = [
      {
        dataField: "hrmsReferenceCode",
        text: intl.formatMessage({ id: "EmployeeId" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div className="text-center">{row.hrmsReferenceCode ? `${row.hrmsReferenceCode}` : Constants.emptyData}</div>
        ),
      },
      {
        dataField: "employeeName",
        text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
      },
      {
        dataField: "status",
        text: intl.formatMessage({ id: "Status" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div className="text-center">{row.status ? `${row.status}` : Constants.emptyData}</div>
        )
      }
    ];

    let arrayDays = getDays(selectedYear, selectedMonthValue);
    for (let a= 1; a<=arrayDays.length; a++) {
      const dateToCheck = moment(`${selectedYear}-${selectedMonthValue}-${a}`, Constants.displayDateFormat);
      const isSaturday = dateToCheck.day() === 6;
      const isSunday = dateToCheck.day() === 0;
      aryOutletScheduleColumnsTemp.push({
        dataField: `day_${a}`,
        text: ("0"+a).slice(-2),
        style: {
          backgroundColor: isSaturday ? Constants.verylightpurple : isSunday ? Constants.lightredfaded : ""
        },
      })
    }
    return aryOutletScheduleColumnsTemp;
  }

  const [outletScheduleColumns, setOutletScheduleColumns] = useState(returnOutletScheduleColumns());

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "OutletSchedule"})})}
        title={intl.formatMessage({ id: "OutletSchedule" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportHumanResourceReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchOutletScheduleWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Outlet-Schedule.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={()=> {
                fetchOutletScheduleWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <div>
            {/* {
              outletScheduleList.length > 0
              &&
              <>
                <Row className="padding-top-10">
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      title={intl.formatMessage({ id: "NormalWorkingDayShiftNo" }).toUpperCase()}
                      value={intl.formatMessage({ id: "NL" })}/>
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      title={intl.formatMessage({ id: "RestDay" }).toUpperCase()}
                      value={intl.formatMessage({ id: "RD" })}/>
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      title={intl.formatMessage({ id: "PublicHoliday" }).toUpperCase()}
                      value={intl.formatMessage({ id: "PH" })}/>
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      title={intl.formatMessage({ id: "OffDay" }).toUpperCase()}
                      value={intl.formatMessage({ id: "OD" })}/>
                  </Col>
                </Row>
              </>
            } */}
            <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : "flex-direction-row"} style={{paddingTop: "24px", paddingRight: "42px", marginTop: "8px", backgroundColor: 'white', marginBottom: "-12px"}}>
              {displayTypeWithColor(Constants.verylightpurple, intl.formatMessage({id: "Saturday"}))}
              {displayTypeWithColor(Constants.lightredfaded, intl.formatMessage({id: "Sunday"}))}
            </div>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              bordered={true}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={outletScheduleColumns}
              data={outletScheduleList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "OutletSchedule"})})}
              objSorted={defaultSorted}
              keyField={"employeeName"}
              requestAPI={getOutletScheduleList}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              hideSearchBar={true}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(OutletSchedule);
