import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import { AddOptionsValueField, removeAllSpace } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";
import { BranchObject } from "../../../app/models/branch";
import { EmployeeListObject } from "../../../app/models/employee";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: any;
  customerName: string;
  subBranchId: string;
  subBranchName: string;
  subBranchConsultantId: string;
  subBranchConsultantName: string;
  setSubBranchRequestAcknowledgementModal: Function;
  setSubBranchRequestId: Function;
  setSubBranchRequestAction?: Function;
  deleteSubBranch: boolean;
}

export default function SubBranchRequestModal(props: Props) {
  const intl = useIntl();
  const { commonStore, branchStore, employeeStore, customerSubBranchRequestStore } = useStore();
  const { successMessage } = commonStore;
  const { getBranchDropDown } = branchStore;
  const { getEmployeeDropdown } = employeeStore;
  const { getCustomerSubBranchRequestWithId, addCustomerSubBranchRequest, updateCustomerSubBranchRequest} = customerSubBranchRequestStore;
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [loadingSubBranchRequest, setLoadingSubBranchRequest] = useState(false);
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [branchDropdownLocal, setBranchDropdownLocal] = useState<BranchObject[]>([])
  const [employeeDropdownLocal, setEmployeeDropdownLocal] = useState<EmployeeListObject[]>([])

  const defaultSubBranchDetails = {
    customerId: props.customerId || "",
    customerName: props.customerName || "",
    toSubBranchId: "",
    toSubBranchName: "",
    subBranchConsultantId: "",
    subBranchConsultantName: "",
    requestReason: ""
  }

  const [subBranchDetails, setSubBranchDetails] = useState(defaultSubBranchDetails)
  const [errorState, setErrorState] = useState({
    toSubBranchId: false,
    subBranchConsultantId: false,
    requestReason: false
  })
  const disabledFieldInput = loadingSubBranchRequest || Boolean(successMessage);

  useEffect(()=> {
    async function fetchSubBranch() {
      setLoadingLocal(true);
      let resultAPI : any = await getBranchDropDown({}, false, true, false, true, true);
      let branchDropdownLocalTemp = _.cloneDeep(resultAPI);
      if (props.subBranchId) {
        let indexFound = _.findIndex(branchDropdownLocalTemp, {id: props.subBranchId})
        if (indexFound > -1) {
          branchDropdownLocalTemp.splice(indexFound, 1);
        }
      }
      setBranchDropdownLocal(branchDropdownLocalTemp);
      setLoadingLocal(false);
    }

    if (!props.deleteSubBranch) {
      fetchSubBranch();
    }
  }, [])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        hideModal();
        if (props.setSubBranchRequestAction) {
          props.setSubBranchRequestAction(props.deleteSubBranch ? Constants.delete : Constants.add);
        }
        props.setSubBranchRequestAcknowledgementModal(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      toSubBranchId: false,
      subBranchConsultantId: false,
      requestReason: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setSubBranchDetails(defaultSubBranchDetails);
    props.setModal(false);
  }

  const onChangeSubBranch = async (branchDetail) => {
    if (branchDetail) {
      setLoadingEmployee(true);
      let resultAPI : EmployeeListObject[] | undefined = await getEmployeeDropdown({ branchId: branchDetail.id, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true }, true)
      if (resultAPI) {
        setEmployeeDropdownLocal(resultAPI);
        setSubBranchDetails((valueSubBranchDetails)=> ({
          ...valueSubBranchDetails,
          toSubBranchId: branchDetail.id,
          toSubBranchName: branchDetail.name,
          subBranchConsultantId: "",
          subBranchConsultantName: "",
        }))
      }
      setLoadingEmployee(false);
    }
    clearErrorState("toSubBranchId");
  }

  const onChangeEmployee = (employeeDetail) => {
    if (employeeDetail) {
      setSubBranchDetails((valueSubBranchDetails)=> ({
        ...valueSubBranchDetails,
        subBranchConsultantId: employeeDetail.id,
        subBranchConsultantName: employeeDetail.preferredName,
      }))
    }
    clearErrorState("subBranchConsultantId");
  }

  const onClickSubBranch = async (subBranchDetailTemp: any) => {
    setLoadingSubBranchRequest(true);
    let resultAPI = await addCustomerSubBranchRequest(subBranchDetailTemp)
    if (resultAPI.status === Constants.success) {
      if (resultAPI.data) {
        if (resultAPI.data.id) {
          props.setSubBranchRequestId(resultAPI.data.id)
        }
      }
    } 
    setLoadingSubBranchRequest(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{!props.subBranchId ? intl.formatMessage({ id: "AddSubBranch" }) : props.deleteSubBranch ? intl.formatMessage({ id: "DeleteSubBranch" }) : intl.formatMessage({ id: "UpdateSubBranch" })}</h5>
          {
            !loadingSubBranchRequest && !successMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            loadingLocal
            ?
            <Loading/>
            :
            <div>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "CustomerName" })}
                  name="customerName"
                  type="text"
                  field={subBranchDetails}
                  setFieldValue={setSubBranchDetails}
                  disabled={true}/>
              </SingleColumnRowParent>
              {
                !props.deleteSubBranch
                &&
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBranch" })}
                    name="subBranchName"
                    className={errorState.toSubBranchId ? "mb-0" : "mb-3"}
                    options={branchDropdownLocal}
                    onChange={onChangeSubBranch}
                    initialLabel={subBranchDetails.toSubBranchName}
                    initialValue={subBranchDetails.toSubBranchId}
                    labelField={"name"}
                    valueField={"id"}
                    blnReturnFullValue={true}
                    blnValueWithNewSpace={true}
                    validationRequired={true}
                    disabled={disabledFieldInput}
                  />
                  {errorState.toSubBranchId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                  ) : null}
                </SingleColumnRowParent>    
              }
              {
                !props.deleteSubBranch
                &&
                <SingleColumnRowParent blnDoubleTab={true}>
                  {
                    loadingEmployee
                    ?
                    <Loading/>
                    :
                    <>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "Consultant" })}
                        name="subBranchConsultantName"
                        className={errorState.subBranchConsultantId ? "mb-0" : "mb-3"}
                        options={employeeDropdownLocal}
                        onChange={onChangeEmployee}
                        initialLabel={subBranchDetails.subBranchConsultantName}
                        initialValue={subBranchDetails.subBranchConsultantId}
                        labelField={"preferredName"}
                        valueField={"id"}
                        blnReturnFullValue={true}
                        blnValueWithNewSpace={true}
                        validationRequired={true}
                        disabled={disabledFieldInput}
                      />
                      {errorState.subBranchConsultantId ? (
                        <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) })}</FormFeedback>
                      ) : null}
                    </>
                  }
                </SingleColumnRowParent>
              }
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Reason" })}
                  name="requestReason"
                  row={5}
                  validationRequired={true}
                  field={subBranchDetails}
                  setFieldValue={setSubBranchDetails}
                  disabled={disabledFieldInput} 
                  onChangeFunction={()=> {clearErrorState("requestReason")}}/>
                {errorState.requestReason ? (
                  <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}</FormFeedback>
                ) : null}
              </SingleColumnRowParent>
              {
                subBranchDetails.toSubBranchName
                &&
                  <p className="d-print-none text-danger"><strong>{intl.formatMessage({ id: "RequestSubBranchWarningMessage" }, { branchName: subBranchDetails.toSubBranchName || Constants.emptyData})}</strong></p>
              }
              <div className="mt-3 mb-2 flex-right flex-direction-row">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        let errorStateTemp = {
                          toSubBranchId: false,
                          subBranchConsultantId: false,
                          requestReason: false
                        };
                        let blnErrorFound = false;
                        if (!props.deleteSubBranch) {
                          if (!subBranchDetails.toSubBranchId) {
                            errorStateTemp.toSubBranchId = true;
                            blnErrorFound = true;
                          }
                          if (!subBranchDetails.subBranchConsultantId) {
                            errorStateTemp.subBranchConsultantId = true;
                            blnErrorFound = true;
                          }
                        }
                        if (!subBranchDetails.requestReason) {
                          errorStateTemp.requestReason = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          onClickSubBranch(subBranchDetails)
                        }
                      }}
                      disable={loadingSubBranchRequest || loadingEmployee || loadingLocal || Boolean(successMessage)}
                      loading={loadingSubBranchRequest}
                    />
                  </>
                }
              </div>
            </div>
          }
        </div>
      </Modal>
  )
}