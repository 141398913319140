import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, getMonths, getYears, IncludesLocationPathName, newSpaceBeforeCapitalLetter, replaceAllStringWithArray, returnThousandSeperatorInString } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const AdditionBonusSalesTargetDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { additionBonusSalesTargetStore, staticReferenceStore, positionStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addAdditionBonusSalesTarget, updateAdditionBonusSalesTarget, additionBonusSalesTargetDetail, setAdditionBonusSalesTargetDetail, getAdditionBonusSalesTargetWithId } = additionBonusSalesTargetStore;
  const { commissionTypeList, additionBonusType, getStaticReferenceWithType } = staticReferenceStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "AdditionBonusSalesTarget" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: RoutesList.additionBonusSalesTarget }, { title: intl.formatMessage({ id: "AdditionBonusSalesTarget" }), urlPath: RoutesList.additionBonusSalesTarget }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateAdditionBonusSalesTarget = checkPermission([PermissionConstants.UpdateAdditionBonusSalesTarget]);
  const [selectedPositionIds, setSelectedPositionIds] = useState<any>([]);
  const [showApplicableToFutureMonths, setShowApplicableToFutureMonths] = useState(false);
  const [refreshCommissionValue, setRefreshCommissionValue] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: additionBonusSalesTargetDetail || {
      name: "",
      year: undefined,
      month: undefined,
      from: undefined,
      to: undefined,
      commissionType: "",
      commissionValue: undefined,
      additionBonusType: "",
      entitlePositionIds: [],
      isActive: true,
      isApplicableToFutureMonths: false
    },
    validationSchema: Yup.object({
      entitlePositionIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Position" }) })),
      from: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "From" }) })),
      to: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "To" }) })),
      year: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })),
      month: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })),
      commissionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionType" }) })),
      commissionValue: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CommissionValue" }) })),
      additionBonusType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "AdditionBonusType" }) })),
        
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (valuesTemp.commissionType && valuesTemp.commissionValue === 0) {
        valuesTemp.commissionValue = 0;
      }
      validateNumberFieldWithSeparator(valuesTemp);
      
      try {
        if (addAction) {
          await addAdditionBonusSalesTarget(valuesTemp);
        } else {
          setShowApplicableToFutureMonths(true);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnAdditionBonusSalesTargetName = !addAction && viewAction;

  useEffect(() => {
    async function fetchAdditionBonusSalesTargetDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.commissionType),
        getStaticReferenceWithType(Constants.additionBonusType),
        getPositionDropdown(1)
      ];
      if (id && !addAction) {
        aryAPI.push(getAdditionBonusSalesTargetWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setAdditionBonusSalesTargetDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateAdditionBonusSalesTarget], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateAdditionBonusSalesTarget], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAdditionBonusSalesTarget], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateAdditionBonusSalesTarget) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.additionBonusSalesTarget}/edit/${id}`) } })
    }

    setMonthList(getMonths());
    setYearList(getYears());
    fetchAdditionBonusSalesTargetDetailAPI();

    return (() => {
      if (id && !addAction) {
        setAdditionBonusSalesTargetDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (additionBonusSalesTargetDetail) {
        if (additionBonusSalesTargetDetail.entitlePositionIds) {
          let arySelectedPositionIdTemp: any = [];
          additionBonusSalesTargetDetail.entitlePositionIds.map((value) => {
            for (var a = 0; a < positionDropdownList.length; a++) {
              if (value === positionDropdownList[a].id) {
                arySelectedPositionIdTemp.push({
                  ...positionDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(positionDropdownList[a].name),
                  value: positionDropdownList[a].id
                })
              }
            }
          })
          setSelectedPositionIds(arySelectedPositionIdTemp);
        }
        validation.setFieldValue("from", returnThousandSeperatorInString(additionBonusSalesTargetDetail.from.toString()));
        validation.setFieldValue("to", returnThousandSeperatorInString(additionBonusSalesTargetDetail.to.toString()));
        if(additionBonusSalesTargetDetail.commissionType === Constants.byAmount){
          validation.setFieldValue("commissionValue", returnThousandSeperatorInString(additionBonusSalesTargetDetail.commissionValue.toString()));
        }
      }
    }
  }, [additionBonusSalesTargetDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onClickUpdateAdditionBonusSalesTarget = async (isApplicableToFutureMonths: boolean) => {
    validation.setSubmitting(true);
    try {
      let valuesTemp : any = _.cloneDeep(validation.values);
      validateNumberFieldWithSeparator(valuesTemp);
      valuesTemp = { ...valuesTemp, isApplicableToFutureMonths: isApplicableToFutureMonths}
      await updateAdditionBonusSalesTarget(valuesTemp);
      setShowApplicableToFutureMonths(false);
    }
    finally {
      validation.setSubmitting(false);
    }
  }

  const validateNumberFieldWithSeparator = (additionBonusSalesTargetDetailTemp: any) => {
    if(additionBonusSalesTargetDetailTemp.from.includes(",")){
      let fromTemp = removeThousandSeperator(additionBonusSalesTargetDetailTemp.from);
      additionBonusSalesTargetDetailTemp.from = fromTemp;
    }
    if(additionBonusSalesTargetDetailTemp.to.includes(",")){
      let toTemp = removeThousandSeperator(additionBonusSalesTargetDetailTemp.to);
      additionBonusSalesTargetDetailTemp.to = toTemp;
    }
    if(additionBonusSalesTargetDetailTemp.commissionType === Constants.byAmount){
      if(additionBonusSalesTargetDetailTemp.commissionValue.includes(",")){
        let commissionValueTemp = removeThousandSeperator(additionBonusSalesTargetDetailTemp.commissionValue);
        additionBonusSalesTargetDetailTemp.commissionValue = commissionValueTemp;
      }
    }
    return additionBonusSalesTargetDetailTemp;
  }

  const removeThousandSeperator = (value) =>{
    return Number(replaceAllStringWithArray(value, [','], ""));
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                {
                  blnAdditionBonusSalesTargetName &&
                  <>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "AdditionBonusSalesTarget" }) })}
                      h4Title />
                    <SingleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "AdditionBonusSalesTargetName" })}
                        name="name"
                        className="mb-3 mt-4"
                        type="text"
                        disabled={disabledFieldInput || blnAdditionBonusSalesTargetName}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                  </>
                }
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"year"}
                      title={intl.formatMessage({ id: "Year" })}
                      specifyReturnFieldName={[{ "field": "year", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      options={yearList}
                      disabled={disabledFieldInput || !addAction}
                      initialLabel={!addAction ? `${validation.values.year}` : undefined}
                      initialValue={!addAction ? validation.values.year : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"month"}
                      title={intl.formatMessage({ id: "Month" })}
                      specifyReturnFieldName={[{ "field": "month", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      placeholder={""}
                      options={monthList}
                      disabled={disabledFieldInput || !addAction}
                      initialLabel={!addAction ? `${moment(validation.values.month, "MM").format("MMM")}` : undefined}
                      initialValue={!addAction ? validation.values.month : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "From" })}
                      name="from"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.from){
                          let fromTemp:any = replaceAllStringWithArray(validation.values.from, [','], "");
                          const formattedValue:any = returnThousandSeperatorInString(fromTemp);
                          validation.setFieldValue("from", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "To" })}
                      name="to"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.to){
                          let toTemp:any = replaceAllStringWithArray(validation.values.to, [','], "");
                          const formattedValue:any = returnThousandSeperatorInString(toTemp);
                          validation.setFieldValue("to", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"commissionType"}
                      title={intl.formatMessage({ id: "CommissionType" })}
                      specifyReturnFieldName={[{ "field": "commissionType", "value": "displayValue" }]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={commissionTypeList}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.commissionType : undefined}
                      onChangeFunction={()=> {
                        setRefreshCommissionValue(true);
                        validation.setFieldValue("commissionValue", "");
                        setTimeout(() => {
                          setRefreshCommissionValue(false);
                        }, 100);
                      }}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    {
                      refreshCommissionValue
                      ?
                      <Loading/>
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "CommissionValue" })}
                        name="commissionValue"
                        type="text"
                        blnNumberOnlyWithDecimal={validation.values.commissionType === Constants.byAmount ? true : false}
                        disabled={disabledFieldInput || viewAction}
                        onBlurFunction={() => {
                          if(validation.values.commissionValue && validation.values.commissionType === Constants.byAmount){
                            let commissionValueTemp:any = replaceAllStringWithArray(validation.values.commissionValue, [','], "");
                            const formattedValue:any = returnThousandSeperatorInString(commissionValueTemp);
                            validation.setFieldValue("commissionValue", formattedValue.toString())
                          }
                        }}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                    <DropDownWithTitle
                      name={"additionBonusType"}
                      title={intl.formatMessage({ id: "AdditionBonusType" })}
                      specifyReturnFieldName={[{ "field": "additionBonusType", "value": "displayValue" }]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={additionBonusType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.additionBonusType : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </SingleColumnRowParent>
                <SingleColumnRowParent>
                <DropDownWithTitleMultiSelect
                    name={"entitlePositionIds"}
                    title={intl.formatMessage({ id: "Position" })}
                    specifyReturnFieldName={[{ "field": "entitlePositionIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"name"}
                    valueField={"id"}
                    options={positionDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={selectedPositionIds}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>

                <GeneralInput
                  title={intl.formatMessage({ id: "Active" })}
                  name="isActive"
                  type="checkbox"
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}
                  childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
      <ActionPromptModal 
        title={intl.formatMessage({ id: "UpdateSalesTargetApplicableToFutureMonths"})}
        cancelActionTitle={intl.formatMessage({ id: "No"})}
        showPrompt={showApplicableToFutureMonths}
        setShowPrompt={setShowApplicableToFutureMonths}
        blnShowCloseButton={true}
        onPrimaryClick={()=> {onClickUpdateAdditionBonusSalesTarget(true)}}
        onCancelClick={()=> {onClickUpdateAdditionBonusSalesTarget(false)}}/>
    </div>
  );
};

export default observer(AdditionBonusSalesTargetDetail);
