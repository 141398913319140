import { PaginationRequestBody } from "../models/pagination";
import { StockInAddObject, StockInDetailObject, StockInForProductDetailsObject, StockInListObject, StockInUpdateObject } from "../models/stockIn";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class StockInStore {
  stockInList: StockInListObject[] = [];
  stockInDetail: StockInDetailObject | undefined = undefined;
  stockInProductList : StockInForProductDetailsObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockInList = [];
    this.stockInDetail = undefined;
    this.stockInProductList = [];
  }

  setStockInDetail = (stockInDetail: StockInDetailObject | undefined) => {
    this.stockInDetail = stockInDetail;
  }

  getStockIn = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultStockIns = await agent.StockIn.stockInList(PaginationRequestBody);
      runInAction(() => {
        this.stockInList = resultStockIns.data;
        store.commonStore.setTotalItem(resultStockIns.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockInList = [];
    }
  };

  getStockInWithId = async (id: string) => {
    try{
      const resultStockInDetail = await agent.StockIn.stockInDetail(id);
      runInAction(() => {
        this.stockInDetail = resultStockInDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockInDetail = undefined;
    }
  }

  addStockIn = async (stockInRequestBody: StockInAddObject) => {
    try{
      await agent.StockIn.addStockIn(stockInRequestBody);
      store.commonStore.setSuccessMessage(`StockInAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockIn = async (stockInRequestBody: StockInUpdateObject) => {
    try{
      await agent.StockIn.updateStockIn(stockInRequestBody);
      store.commonStore.setSuccessMessage(`StockInUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockIn  = async (id: string, name: string) => {
    try {
      await agent.StockIn.deleteStockIn(id);
      store.commonStore.setSuccessMessage(`StockInDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
