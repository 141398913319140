import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TransferCreditHistoryListObject, TransferCreditObject } from "../models/transferCredit";
import { PaginationRequestBody } from "../models/pagination";

export default class customerStore {
  customerTransferCreditHistoryList: TransferCreditHistoryListObject[] = [];
  customerTransferCreditHistoryTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }
  reset = () => {
    this.customerTransferCreditHistoryList = [];
    this.customerTransferCreditHistoryTotalItems = 0;
  }

  getCustomerTransferCreditHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerTransferCreditHistory = await agent.TransferCredit.transferCreditHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.customerTransferCreditHistoryList = resultCustomerTransferCreditHistory.data;
        this.customerTransferCreditHistoryTotalItems = resultCustomerTransferCreditHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerTransferCreditHistoryList = [];
      this.customerTransferCreditHistoryTotalItems = 0;
    }
  }

  transferCustomerCredit = async (transferCreditRequestBody: TransferCreditObject) => {
    try{
      await agent.TransferCredit.transferCustomerCredit(transferCreditRequestBody);
      store.commonStore.setSuccessMessage(`CreditTransferSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}