import React, { Fragment } from 'react'
import { CardText, Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Borderline from '../../../app/components/form/BorderLine';
import GeneralTextArea from '../../../app/components/form/GeneralTextArea';
import { RedeemPurchaseDetailObject } from '../../../app/models/customerOrder';
import { useIntl } from "react-intl";
import { Constants } from '../../../app/constants/Constants';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { checkPermission } from '../../../app/common/function/function';
import { ProductRedemptionEligibleListObject, RedeemDetailObject } from '../../../app/models/productRedemptionNote';
import { PermissionConstants } from '../../../app/constants/PermissionConstants';
import GeneralInput from '../../../app/components/form/GeneralInput';
interface Props {
  validation: any;
  disabledCustomerOrder: boolean;
  selectCustomerPurchase?: Function;
  deleteCustomerPurchase?: Function;
  blnTotalBalance?: boolean;
  blnDisplayNote: boolean;
}

export default function CustomerPurchaseCart (props: Props) {
  const intl = useIntl();
  const blnPermissionBackdateProductRedemptionNote= checkPermission([PermissionConstants.BackdateProductRedemptionNote]);

  const displayCustomerOrder = () => {
    if (props.validation.values.redeemDetails.length < 1) {
      return (
        <div style={{height: '20px'}}>
            <span className="small-text">{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({id: "Item"}).toLowerCase() })}</span> 
            {props.validation.touched.redeemDetails && props.validation.errors.redeemDetails ? (
              <Label className="error margin-left-8" style={{fontSize: "12px"}}>{`* ${intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })}`}</Label>
            ) : null}
        </div>)
    }

    return (
      <div>
        <Row className='padding-horizontal-8px'>
          {/* <div className="avatar-sm"> */}
          <Col xs="2" md="2" xl="2" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Count" })}</span>
          </Col>
          <Col xs="8" md="8" xl="8" className="flex-align-items-center-without-height">
            <span className="tab-bar-title">{intl.formatMessage({ id: "Name" })}</span>
          </Col>
          {
            !props.disabledCustomerOrder
            &&
            <Col xs="2" md="2" xl="2" className="flex-justfity-content-center flex-align-items-center-without-height text-center">
              <span className="tab-bar-title text-center">{intl.formatMessage({ id: "Action" })}</span>
            </Col>
          }
          {/* </div> */}
        </Row>
        <hr/>
        {
          props.validation.values.redeemDetails.map((value: ProductRedemptionEligibleListObject, index) => (           
            <Row className="mb-4 padding-horizontal-8px" key={value.virtualWarehouseId}>
              {/* <div className="avatar-sm"> */}
              <Col xs="2" md="2" xl="2"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectCustomerPurchase && !props.disabledCustomerOrder) props.selectCustomerPurchase(index) }}>
                <span className="avatar-sm circle-with-text rounded-circle" style={{backgroundColor: Constants.orange }}>{value.quantity}</span>
              </Col>
              <Col xs="8" md="8" xl="8"  style={{alignItems: 'center', display: 'flex'}} onClick={()=> { if (props.selectCustomerPurchase && !props.disabledCustomerOrder) props.selectCustomerPurchase(index) }}>
                <div>
                  <span>{`${value.productName} (${value.virtualWarehouseNo})`}</span>
                </div>
              </Col>
              {
                !props.disabledCustomerOrder
                &&
                <Col xs="2" md="2" xl="2" className="text-danger flex-justfity-content-center" onClick={()=> { if (props.deleteCustomerPurchase && !props.disabledCustomerOrder) props.deleteCustomerPurchase(index) }}>
                  <DeleteIcon size={18} id="deletetooltip" className="margin-top-8"/> 
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {intl.formatMessage({ id: "Delete" })}
                  </UncontrolledTooltip>
                </Col>
              }
              {/* </div> */}
            </Row>
          ))
        }
      </div>
    )
  }

  return (
    <div>
      {/*Item Selected */}
      {
        displayCustomerOrder()
      }
      <Borderline/>
      {
        blnPermissionBackdateProductRedemptionNote
        &&
        props.validation.values.isBackdate
        &&
        <>
          <GeneralInput
            title={intl.formatMessage({ id: "PerformedDateTime" })}
            name="performedDateTime"
            type="datetime-local"
            disabled={true}
            validationRequired={false}
            validation={props.validation}
          />
          <Borderline/>
        </>
      }
      {
        props.blnDisplayNote
        &&
        <div>
          <GeneralTextArea
          title={intl.formatMessage({ id: "Note" })}
          name="note"
          row={5}
          disabled={props.disabledCustomerOrder}
          validation={props.validation}/>
          <Borderline/>
        </div>
      }
    </div>
  )
}
