import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { AdditionBonusSalesTargetListObject } from "../../app/models/additionBonusSalesTarget";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getMonths, getYears, newSpaceBeforeCapitalLetter, returnThousandSeperator } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import MyButton from "../../app/components/form/MyButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import AdditionBonusSalesTargetDuplicateModal from "./Modal/AdditionBonusSalesTargetDuplicateModal";


const AdditionBonusSalesTarget = () => {
  const intl = useIntl();
  //Use Store
  const { additionBonusSalesTargetStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { additionBonusSalesTargetList, additionBonusSalesTargetPaginationParams, getAdditionBonusSalesTarget, deleteAdditionBonusSalesTarget, setAdditionBonusSalesTargetPaginationParams } = additionBonusSalesTargetStore;
  const { additionBonusType, getStaticReferenceWithType } = staticReferenceStore;
  //Data List
  const [selectedRow, setSelectedRow] = useState<AdditionBonusSalesTargetListObject | undefined>(undefined);
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>();
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedAdditionBonusType, setSelectedAdditionBonusType] = useState<string>(AllType);
  const [selectedAdditionBonusTypeId, setSelectedAdditionBonusTypeId] = useState<number | string | undefined>(" ");
  const [additionBonusTypeList, setAdditionBonusTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [salesTargetDuplicateModal, setSalesTargetDuplicateModal] = useState(false);

  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: "" }];
  const blnPermissionManageAdditionBonusSalesTarget = checkPermission([PermissionConstants.ManageAdditionBonusSalesTarget]);
  const blnPermissionCreateAdditionBonusSalesTarget = checkPermission([PermissionConstants.CreateAdditionBonusSalesTarget]);
  const blnPermissionUpdateAdditionBonusSalesTarget = checkPermission([PermissionConstants.UpdateAdditionBonusSalesTarget]);
  const blnPermissionDeleteAdditionBonusSalesTarget = checkPermission([PermissionConstants.DeleteAdditionBonusSalesTarget]);
  const [showDropdown, setShowDropdown] = useState(false);

  let additionBonusSalesTargetRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize, 
    year: selectedYear,
    selectedMonthDisplay: selectedMonth, 
    month: selectedMonthValue,
    additionBonusType: selectedAdditionBonusTypeId,
    additionBonusTypeName: selectedAdditionBonusType
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchAdditionBonusSalesTargetListAPI() {
      setLocalLoading(true)

      let blnSetDate = false;
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.additionBonusSalesTarget)
      if (blnBackNavigation) {
        if (additionBonusSalesTargetPaginationParams) {
          setCurrentPage(additionBonusSalesTargetPaginationParams.pageNumber )
          setSelectedMonth(additionBonusSalesTargetPaginationParams.selectedMonthDisplay || moment().format("MMM"))
          setSelectedMonthValue(additionBonusSalesTargetPaginationParams.month || Number(moment().format("M")))
          setSelectedYear(additionBonusSalesTargetPaginationParams.year || Number(moment().format('yyyy')))
          setSelectedAdditionBonusTypeId(additionBonusSalesTargetPaginationParams.additionBonusType)
          setSelectedAdditionBonusType(additionBonusSalesTargetPaginationParams.additionBonusTypeName || AllType)
          additionBonusSalesTargetRequestBody = additionBonusSalesTargetPaginationParams;
          blnSetDate = true;
        }
      }
      else {
        setAdditionBonusSalesTargetPaginationParams(undefined);
      }
      
      if (!blnSetDate) {
        setSelectedMonth(moment().format("MMM"))
        setSelectedMonthValue(Number(moment().format("M")))
        setSelectedYear(Number(moment().format('yyyy')))
      }

      let aryAPI: any = [
        getStaticReferenceWithType(Constants.additionBonusType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setAdditionBonusTypeList(resultAPI[0]);

      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageAdditionBonusSalesTarget], true)) {
      return;
    }

    fetchAdditionBonusSalesTargetListAPI();
    setMonthList(getMonths());
    setYearList(getYears());

    setShowDropdown(true);

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.additionBonusSalesTarget)
        if (!blnSetParams) {
          setAdditionBonusSalesTargetPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedYear && selectedMonthValue)) {
      fetchAdditionBonusSalesTargetAPI(true);
    }
  }, [selectedYear, selectedMonthValue])

  useEffect(() => {
    if (
      (selectedAdditionBonusTypeId || selectedAdditionBonusTypeId === 0) &&
      !initialCallAPI
    ) {
      fetchAdditionBonusSalesTargetAPI(true);
    }
  }, [selectedAdditionBonusTypeId]);

  async function fetchAdditionBonusSalesTargetAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getAdditionBonusSalesTarget(additionBonusSalesTargetRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: AdditionBonusSalesTargetListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const AdditionBonusSalesTargetColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <div>
        {returnThousandSeperator(row.from)}
      </div>,
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <div>
        {returnThousandSeperator(row.to)}
      </div>,
    },
    {
      dataField: "commissionType",
      text: intl.formatMessage({ id: "CommissionType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.commissionType)}</div>, //Custom UI to display
    },
    {
      dataField: "commissionValue",
      text: intl.formatMessage({ id: "CommissionValue" }).toUpperCase(),
    },
    {
      dataField: "additionBonusType",
      text: intl.formatMessage({ id: "AdditionBonusType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.additionBonusType)}</div>, //Custom UI to display
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.additionBonusSalesTarget}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateAdditionBonusSalesTarget
              &&
              <TableActionButton 
                to={`/${RoutesList.additionBonusSalesTarget}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteAdditionBonusSalesTarget
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AdditionBonusSalesTarget" }) })}
        title={intl.formatMessage({ id: "AdditionBonusSalesTarget" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateAdditionBonusSalesTarget
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "AdditionBonusSalesTarget" }) })}
              onClick={() => { history.push(`/${RoutesList.additionBonusSalesTarget}/add`) }}
              linkTo={`/${RoutesList.additionBonusSalesTarget}/add`}
              disable={localLoading || loading} />
            <MyButton
              content={intl.formatMessage({ id: "DuplicateWithModuleName" }, { moduleName: intl.formatMessage({ id: "AdditionBonusSalesTarget" }) })}
              onClick={() => { setSalesTargetDuplicateModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={loading} />
          </>
        }
        addButtonXL={"6"}>
        {
          showDropdown
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
              title={intl.formatMessage({ id: "AdditionBonusType" })}
                name="additionBonusType"
                options={additionBonusTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedAdditionBonusType}
                initialValue={selectedAdditionBonusTypeId}
                setFieldLabel={setSelectedAdditionBonusType}
                setFieldValue={setSelectedAdditionBonusTypeId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AdditionBonusSalesTargetColumns}
              data={additionBonusSalesTargetList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AdditionBonusSalesTarget" }) })}
              objSorted={defaultSorted}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              selectedMonthDisplay={selectedMonth}
              additionBonusType={selectedAdditionBonusTypeId}
              additionBonusTypeName={selectedAdditionBonusType}
              requestAPI={getAdditionBonusSalesTarget} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteAdditionBonusSalesTarget(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (additionBonusSalesTargetList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getAdditionBonusSalesTarget({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear, month: selectedMonthValue })
            setLoading(false);
          }} />
        {
          salesTargetDuplicateModal
          &&
          <AdditionBonusSalesTargetDuplicateModal 
            blnShow={salesTargetDuplicateModal}
            setModal={setSalesTargetDuplicateModal}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AdditionBonusSalesTarget);
