import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label, Row, Col } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";
import moment from "moment";
import { history } from '../../..';
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  customerName: string;
  currentJoinedDate: string;
  onClikUpdateCustomerJoinedDate: Function;
  loadingEditCustomerJoinedDate: boolean;
}

export default function EditCustomerJoinedDateModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;

  const [errorState, setErrorState] = useState({
    joinedDate: false,
  })

  const disabledFieldInput = props.loadingEditCustomerJoinedDate || Boolean(successMessage);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerId: props.customerId,
      customerName: props.customerName,
      currentJoinedDate: props.currentJoinedDate,
      joinedDate: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size={"md"}
        centered
      >
        <div className="modal-header">
        <div className="flex-direction-row" style={{flex:1 }}>
          <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "EditCustomerJoinedDate" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
          </div>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={12}>
              <GeneralInput
                title={intl.formatMessage({ id: "CustomerName" })}
                name={"customerName"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "CurrentJoinedDate" })}
                name={"currentJoinedDate"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "NewJoinedDate" })}
                name={"joinedDate"}
                className={errorState.joinedDate ? "mb-0" : "mb-3"}
                minCurrentDate={true}
                minDateValue={moment().startOf('month').format(Constants.displayDateFormat)}
                maxCurrentDate={true}
                type="date"
                validationRequired={true}
                validation={validation}
                disabled={disabledFieldInput}
              />
              {errorState.joinedDate ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "NewJoinedDate" }) })}</FormFeedback>
              ) : null}
              <MyButton
                type="submit"
                class="btn btn-success"
                style={{ width: '20%' }}
                content={Constants.submit}
                onClick={() => {
                  let errorStateTemp = {
                    joinedDate: false,
                  };
                  let blnErrorFound = false;
                  if (!validation.values.joinedDate) {
                    errorStateTemp.joinedDate = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else{
                    props.onClikUpdateCustomerJoinedDate(validation.values);
                  }
                }}
                disable={disabledFieldInput}
                loading={props.loadingEditCustomerJoinedDate}
              />
            </Col>
          </Row>
        </div>
      </Modal>
  )
}