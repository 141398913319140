import React, { useState, useEffect } from "react";
import { Modal, Table } from "reactstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { checkPermission } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { DeliveryOrderObject } from "../../../app/models/contractLiability";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerRedemptionDetail: DeliveryOrderObject | undefined;
  setSelectedCustomerRedemptionDetail: Function;
}

export default function CustomerRedemptionDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const blnPermissionManageCustomerDeliverOrder =  checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);

  const hideModal = () => {
    props.setSelectedCustomerRedemptionDetail(undefined);
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="md"
        centered>
        {
          !props.selectedCustomerRedemptionDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "Redemptions" }) })}</h5>
                </div>
                {
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body mb-3">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{intl.formatMessage({ id: "CustomerRedemption" })} :</th>
                      <td>
                        {
                          blnPermissionManageCustomerDeliverOrder
                          ?
                          <Link to={`/${RoutesList.customerRedemption}/view/${props.selectedCustomerRedemptionDetail.customerDeliverOrderId}`} target={Constants.blank} rel="noopener noreferrer">
                            <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.customerDeliverOrderNo}</p>
                          </Link>
                          :
                          <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.customerDeliverOrderNo}</p>
                        }
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{intl.formatMessage({ id: "Date" })} :</th>
                      <td>
                        <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.performDateTime}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{intl.formatMessage({ id: "ActualAmount" })} :</th>
                      <td>
                        <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.deliverOrderAmount}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{intl.formatMessage({ id: "OccupiedAmount" })} :</th>
                      <td>
                        <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.usedAmount}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{intl.formatMessage({ id: "RedeemDetails" })} :</th>
                      <td>
                        <p className="mb-1 text-end">{props.selectedCustomerRedemptionDetail.redeemDetails}</p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
        }
      </Modal>
  )
}