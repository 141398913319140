import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { PurchaseOrderListObject } from "../../app/models/purchaseOrder";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { purchaseOrderViewAvailable, purchaseOrderStatusColor, newSpaceBeforeCapitalLetter, purchaseOrderEditViewAvailable, checkPermission, purchaseOrderEditAvailable } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const PurchaseOrder = () => {
  const intl = useIntl();
  //Use Store
  const { purchaseOrderStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { purchaseOrderList, getPurchaseOrder, deletePurchaseOrder } =
    purchaseOrderStore;
  const { purchaseOrderStatus, createPurchaseOrderType, purchasePaymentMethod, getStaticReferenceWithType } =
    staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PurchaseOrderListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const AllMethod = `${intl.formatMessage({ id: "AllMethod" })}`;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedPurchaseOrderStatus, setSelectedPurchaseOrderStatus] = useState<number | string>(AllStatus);
  const [selectedPurchaseOrderStatusId, setSelectedPurchaseOrderStatusId] = useState<number | string | undefined>(" ");
  const [selectedPurchaseOrderType, setSelectedPurchaseOrderType] = useState<number | string>(AllType);
  const [selectedPurchaseOrderTypeId, setSelectedPurchaseOrderTypeId] = useState<number | string | undefined>(" ");
  const [selectedPurchasePaymentMethod, setSelectedPurchasePaymentMethod] = useState<number | string>(AllMethod);
  const [selectedPurchasePaymentMethodId, setSelectedPurchasePaymentMethodId] = useState<number | string | undefined>(" ");
  const [purchaseOrderStatusList, setPurchaseOrderStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [purchasePaymentMethodList, setPurchasePaymentMethodList] = useState<StaticReferenceDataObject[]>([]);
  const [purchaseOrderTypeList, setPurchaseOrderTypeList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionCreatePurchaseOrder = checkPermission([PermissionConstants.CreatePurchaseOrder]);
  const blnPermissionUpdatePurchaseOrder = checkPermission([PermissionConstants.UpdatePurchaseOrder]);
  const blnPermissionReviewPurchaseOrder = checkPermission([PermissionConstants.ReviewPurchaseOrder])
  const blnPermissionDeletePurchaseOrder = checkPermission([PermissionConstants.DeletePurchaseOrder]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchPurchaseOrderListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchPurchaseOrderAPI(false),
        getStaticReferenceWithType(Constants.purchaseOrderStatus, AllStatus),
        getStaticReferenceWithType(Constants.createPurchaseOrderType, AllType),
        getStaticReferenceWithType(Constants.purchasePaymentMethod, AllMethod),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setPurchaseOrderStatusList(resultAPI[1]);
      setPurchaseOrderTypeList(resultAPI[2]);
      setPurchasePaymentMethodList(resultAPI[3]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePurchaseOrder], true)) {
      return;
    }

    fetchPurchaseOrderListAPI();
  }, []);

  useEffect(() => {
    if (
      (selectedPurchaseOrderStatusId || selectedPurchaseOrderStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchPurchaseOrderAPI(true);
    }
  }, [selectedPurchaseOrderStatusId]);

  useEffect(() => {
    if (
      (selectedPurchaseOrderTypeId || selectedPurchaseOrderTypeId === 0) &&
      !initialCallAPI
    ) {
      fetchPurchaseOrderAPI(true);
    }
  }, [selectedPurchaseOrderTypeId]);

  useEffect(() => {
    if (
      (selectedPurchasePaymentMethodId || selectedPurchasePaymentMethodId === 0) &&
      !initialCallAPI
    ) {
      fetchPurchaseOrderAPI(true);
    }
  }, [selectedPurchasePaymentMethodId]);

  async function fetchPurchaseOrderAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPurchaseOrder({
      pageNumber: currentPage,
      pageSize: pageSize,
      purchaseOrderStatus: selectedPurchaseOrderStatusId,
      purchaseOrderType: selectedPurchaseOrderTypeId,
      purchasePaymentMethod: selectedPurchasePaymentMethodId,
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: PurchaseOrderListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  //Table Content UI
  const PurchaseOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "purchaseOrderNo",
      text: `${intl.formatMessage({ id: "PurchaseOrderNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "RequestedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.requestedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "purchaseFromSupplierName",
      text: intl.formatMessage({ id: "PurchaseFrom" }).toUpperCase(),
    },
    {
      dataField: "receiverName",
      text: intl.formatMessage({ id: "Receiver" }).toUpperCase(),
    },
    {
      dataField: "purchaseOrderType",
      text: intl.formatMessage({ id: "PurchaseOrderType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.purchaseOrderType)}</div>,
    },
    {
      dataField: "purchasePaymentMethod",
      text: intl.formatMessage({ id: "PurchasePaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.purchasePaymentMethod)}</div>,
    },
    {
      dataField: "purchaseOrderStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.purchaseOrderStatus}
          statusColorFunction={purchaseOrderStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.purchaseOrder}/view/${row.id}`}
              type={'view'}/>
            {
              (blnPermissionUpdatePurchaseOrder || blnPermissionReviewPurchaseOrder)
              &&
              purchaseOrderEditAvailable(row.purchaseOrderStatus)
              &&
              <TableActionButton 
                to={`/${RoutesList.purchaseOrder}/${purchaseOrderViewAvailable(row.purchaseOrderStatus) ? 'view' : 'edit'}/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeletePurchaseOrder
              &&
              !purchaseOrderViewAvailable(row.purchaseOrderStatus)
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PurchaseOrder" }) })}
        title={intl.formatMessage({ id: "PurchaseOrder" })}
        addButton={
          blnPermissionCreatePurchaseOrder
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PurchaseOrder" }) })}
            onClick={() => { history.push(`/${RoutesList.purchaseOrder}/add`) }}
            linkTo={`/${RoutesList.purchaseOrder}/add`}
            disable={localLoading || loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "PurchaseOrder" }) })}
              name="purchaseOrderStatus"
              options={purchaseOrderStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedPurchaseOrderStatus}
              initialValue={selectedPurchaseOrderStatusId}
              setFieldLabel={setSelectedPurchaseOrderStatus}
              setFieldValue={setSelectedPurchaseOrderStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "PurchasePaymentMethod" })}
              name="purchasePaymentMethod"
              options={purchasePaymentMethodList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedPurchasePaymentMethod}
              initialValue={selectedPurchasePaymentMethodId}
              setFieldLabel={setSelectedPurchasePaymentMethod}
              setFieldValue={setSelectedPurchasePaymentMethodId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "PurchaseOrderType" })}
              name="purchaseOrderType"
              options={purchaseOrderTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedPurchaseOrderType}
              initialValue={selectedPurchaseOrderTypeId}
              setFieldLabel={setSelectedPurchaseOrderType}
              setFieldValue={setSelectedPurchaseOrderTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PurchaseOrderColumns}
              data={purchaseOrderList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PurchaseOrder" }) })}
              objSorted={defaultSorted}
              purchaseOrderStatus={selectedPurchaseOrderStatusId}
              purchaseOrderType={selectedPurchaseOrderTypeId}
              purchasePaymentMethod={selectedPurchasePaymentMethodId}
              requestAPI={getPurchaseOrder}
            />
            <DeleteModal
              displayName={selectedRow?.purchaseOrderNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deletePurchaseOrder(
                  selectedRow?.id || "",
                  selectedRow?.purchaseOrderNo || ""
                );
                let currentPageTemp = currentPage;
                if (purchaseOrderList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getPurchaseOrder({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(PurchaseOrder);
