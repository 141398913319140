import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import BranchStore from "./branchStore";
import BrandStore from './brandStore';
import BusinessEntityStore from "./businessEntityStore";
import ProductStore from './productStore';
import ServiceTreatmentStore from './serviceTreatmentStore';
import PackageStore from './packageStore';
import EmployeeStore from './employeeStore'
import CurrencyStore from "./currencyStore";
import CountryStore from "./countryStore";
import StaticReferenceStore from "./staticReferenceStore";
import CategoryStore from './categoryStore';
import SalesCategoryStore from './salesCategoryStore';
import TaxStore from "./taxStore";
import MeasurementStore from "./measurementStore";
import RaceStore from "./raceStore";
import EPFContributionStore from "./epfContributionStore";
import EISStore from './eisStore';
import SocsoStore from './socsoStore';
import PCBStore from "./pcbStore";
import AppointmentStore from "./appointmentStore";
import UserRoleStore from './userRoleStore';
import CustomerStore from './customerStore';
import MediaSourceStore from './mediaSourceStore';
import CustomerOrderStore from './customerOrderStore';
import InvoiceStore from "./invoiceStore";
import PositionStore from "./positionStore";
import SupplierStore from './supplierStore';
import SalesTargetStore from './salesTargetStore';
import WarehouseStore from './warehouseStore';
import DeliveryStore from './deliveryOrderStore';
import StockMovementStore from './stockMovementStore';
import PayrollItemStore from "./payrollItemStore";
import StockRequestStore from "./stockRequestStore";
import StockReceiveStore from "./stockReceiveStore";
import LeaveEntitlementStore from "./leaveEntitlementStore";
import PurchaseOrderStore from "./purchaseOrderStore";
import InboundNoticeStore from "./inboundNoticeStore";
import GoodsReceiveNoteStore from "./goodsReceiveNoteStore";
import PurchaseDeliverOrderStore from "./purchaseDeliverOrderStore";
import PermissionStore from "./permissionStore";
import ProfileStore from "./profileStore";
import ConsultantSalesStore from "./reportSalesStore";
import NotificationTemplateStore from "./notificationTemplateStore";
import StockAdjustmentStore from "./stockAdjustmentStore";
import StockInStore from "./stockInStore";
import StockOutStore from "./stockOutStore";
import StockReturnToHQStore from './stockReturnToHQStore';
import PaymentMethodStore from "./paymentMethodStore";
import PayslipStore from "./payslipStore";
import AdditionalFormFieldStore from "./additionalFormFieldStore"
import NotificationStore from "./notificationStore";
import StockTakeStore from "./stockTakeStore";
import TestTimeZoneStore from "./testTimeZoneStore";
import CustomerCartStore from "./customerCartStore";
import AuditLogStore from "./auditLogStore";
import LeadStore from "./leadStore";
import CustomerSalesOrderStore from "./customerSalesOrderStore";
import SettingsStore from "./settingStore";
import PromotionStore from "./promotionStore";
import CustomerRefundStore from "./customerRefundStore";
import CreditAdjustmentStore from "./creditAdjustmentStore";
import TransferCreditStore from "./transferCreditStore";
import ReportSalesStore from "./reportSalesStore";
import EmployeeLeaveStore from "./employeeLeaveStore";
import ReportMarcomStore from "./reportMarcomStore";
import ReportAppointmentStore from "./reportAppointmentStore";
import ReportPaymentStore from "./reportPaymentStore";
import ReportCustomerStore from "./reportCustomerStore";
import DashboardStore from "./dashboardStore";
import ReportServicesStore from "./reportServicesStore";
import ReportStockStore from "./reportStockStore";
import ReportManagementStore from "./reportManagementStore";
import ReportHumanResource from "./reportHumanResourceStore";
import CustomerPaymentAdjustmentStore from "./customerPaymentAdjustmentStore";
import CustomerProductReturnStore from "./customerProductReturnStore";
import ExtendPackageStore from "./extendPackageStore";
import PaymentMethodOptionStore from "./paymentMethodOptionStore";
import SalesAdjustmentStore from "./salesAdjustmentStore";
import CommissionSchemeStore from "./commissionSchemeStore";
import CustomerAccessRequestStore from "./customerAccessRequestStore";
import ReportMasterDataStore from "./reportMasterDataStore";
import CustomerTransferRequestStore from "./customerTransferRequestStore"
import AdditionBonusSalesTargetStore from "./additionBonusSalesTargetStore";
import CustomerVirtualWarehouseStore from "./customerVirtualWarehouseStore";
import ProductRedemptionNoteStore from "./productRedemptionNoteStore";
import BranchCommissionStore from "./branchCommissionStore";
import ContractLiabilityStore from "./contractLiabilityStore";
import VirtualWarehouseCreditExchangeStore from "./virtualWarehouseCreditExchangeStore";
import ProductRedemptionNoteReturnStore from './productRedemptionNoteReturnStore';
import DSRAdjustmentStore from "./dsrAdjustmentStore";
import BatchJobStore from "./batchJobStore";
import CustomerSubBranchRequestStore from "./customerSubBranchRequestStore";
import AppolousCustomerPackageStore from "./appolousCustomerPackageStore";
import AppolousCustomerProductStore from "./appolousCustomerProductStore";
import AppolousCustomerSalesStore from "./appolousCustomerSalesStore";
import ReportAppolousStore from "./reportAppolousStore";
import VirtualWarehouseStockMovementStore from "./virtualWarehouseStockMovementStore";
import AppointmentTargetStore from './appointmentTargetStore';
import MarcomDailyRecordStore from './marcomDailyRecordStore';
import CacheStore from './cacheStore';
import DepartmentStore from './departmentStore';
import CustomerChangePaymentMethodStore from './customerChangePaymentMethodStore';
import AppointmentLeadStore from './appointmentLeadStore';
import MachineStore from './machineStore';
import ContractLiabilityBenchmarkStore from "./contractLiabilityBenchmarkStore";
import GroupCodeStore from "./groupCodeStore";
import UnknownPaymentStore from "./unknownPaymentStore";
import ReportAccountStore from "./reportAccountStore";
import TncStore from "./tncStore";
import CashOutStore from "./cashOutStore";
import PaymentTerminalStore from "./paymentTerminalStore";
import ChangePaymentModeStore from "./changePaymentModeStore";
import CloseCounterStore from "./closeCounterStore";

interface Store{
    commonStore: CommonStore;
    userStore: UserStore;
    branchStore: BranchStore;
    brandStore: BrandStore;
    businessEntityStore: BusinessEntityStore;
    productStore: ProductStore;
    serviceTreatmentStore: ServiceTreatmentStore;
    packageStore: PackageStore;
    employeeStore: EmployeeStore;
    currencyStore: CurrencyStore;
    countryStore: CountryStore;
    staticReferenceStore: StaticReferenceStore;
    categoryStore: CategoryStore;
    salesCategoryStore: SalesCategoryStore;
    taxStore: TaxStore;
    measurementStore: MeasurementStore;
    raceStore: RaceStore;
    epfContributionStore: EPFContributionStore;
    eisStore: EISStore;
    socsoStore: SocsoStore;
    pcbStore: PCBStore;
    appointmentStore: AppointmentStore;
    userRoleStore: UserRoleStore;
    customerStore: CustomerStore;
    mediaSourceStore: MediaSourceStore;
    customerOrderStore: CustomerOrderStore;
    invoiceStore: InvoiceStore;
    positionStore: PositionStore;
    supplierStore: SupplierStore;
    salesTargetStore: SalesTargetStore;
    warehouseStore: WarehouseStore;
    deliveryOrderStore: DeliveryStore;
    stockMovementStore: StockMovementStore;
    payrollItemStore: PayrollItemStore;
    stockRequestStore: StockRequestStore;
    stockReceiveStore: StockReceiveStore;
    leaveEntitlementStore: LeaveEntitlementStore;
    purchaseOrderStore: PurchaseOrderStore;
    inboundNoticeStore: InboundNoticeStore;
    goodsReceiveNoteStore: GoodsReceiveNoteStore;
    purchaseDeliverOrderStore: PurchaseDeliverOrderStore;
    permissionStore: PermissionStore;
    profileStore: ProfileStore;
    reportSalesStore: ReportSalesStore;
    notificationTemplateStore: NotificationTemplateStore;
    stockAdjustmentStore: StockAdjustmentStore;
    stockInStore: StockInStore;
    stockOutStore: StockOutStore;
    stockReturnToHQStore: StockReturnToHQStore;
    paymentMethodStore: PaymentMethodStore;
    payslipStore: PayslipStore;
    additionalFormFieldStore: AdditionalFormFieldStore;
    notificationStore: NotificationStore;
    stockTakeStore: StockTakeStore;
    auditLogStore: AuditLogStore;
    customerCartStore: CustomerCartStore;
    leadStore: LeadStore;
    customerSalesOrderStore: CustomerSalesOrderStore;
    testTimeZoneStore: TestTimeZoneStore;
    settingsStore: SettingsStore;
    promotionStore: PromotionStore;
    customerRefundStore: CustomerRefundStore;
    creditAdjustmentStore: CreditAdjustmentStore;
    transferCreditStore: TransferCreditStore;
    reportMarcomStore: ReportMarcomStore;
    reportAppointmentStore: ReportAppointmentStore; 
    reportCustomerStore: ReportCustomerStore;
    employeeLeaveStore: EmployeeLeaveStore;
    reportPaymentStore: ReportPaymentStore;
    dashboardStore: DashboardStore;
    reportServicesStore: ReportServicesStore;
    reportStockStore: ReportStockStore;
    reportManagementStore: ReportManagementStore;
    customerPaymentAdjustmentStore: CustomerPaymentAdjustmentStore;
    customerProductReturnStore: CustomerProductReturnStore;
    extendPackageStore: ExtendPackageStore;
    paymentMethodOptionStore: PaymentMethodOptionStore;
    salesAdjustmentStore: SalesAdjustmentStore;
    commissionSchemeStore: CommissionSchemeStore;
    customerAccessRequestStore: CustomerAccessRequestStore;
    reportMasterDataStore: ReportMasterDataStore;
    customerTransferRequestStore: CustomerTransferRequestStore;
    additionBonusSalesTargetStore :AdditionBonusSalesTargetStore;
    branchCommissionStore: BranchCommissionStore;
    reportHumanResourceStore: ReportHumanResource;
    customerVirtualWarehouseStore: CustomerVirtualWarehouseStore;
    productRedemptionNoteStore: ProductRedemptionNoteStore;
    contractLiabilityStore: ContractLiabilityStore;
    virtualWarehouseCreditExchangeStore: VirtualWarehouseCreditExchangeStore;
    productRedemptionNoteReturnStore: ProductRedemptionNoteReturnStore;
    dsrAdjustmentStore: DSRAdjustmentStore;
    batchJobStore: BatchJobStore;
    customerSubBranchRequestStore: CustomerSubBranchRequestStore;
    appolousCustomerPackageStore: AppolousCustomerPackageStore;
    appolousCustomerProductStore: AppolousCustomerProductStore;
    appolousCustomerSalesStore: AppolousCustomerSalesStore;
    reportAppolousStore: ReportAppolousStore;
    virtualWarehouseStockMovementStore: VirtualWarehouseStockMovementStore;
    appointmentTargetStore: AppointmentTargetStore;
    marcomDailyRecordStore: MarcomDailyRecordStore;
    cacheStore: CacheStore;
    departmentStore: DepartmentStore;
    customerChangePaymentMethodStore: CustomerChangePaymentMethodStore;
    appointmentLeadStore: AppointmentLeadStore;
    machineStore: MachineStore;
    contractLiabilityBenchmarkStore: ContractLiabilityBenchmarkStore;
    groupCodeStore: GroupCodeStore;
    reportAccountStore: ReportAccountStore;
    tncStore: TncStore;
    unknownPaymentStore: UnknownPaymentStore;
    cashOutStore: CashOutStore;
    paymentTerminalStore: PaymentTerminalStore;
    changePaymentModeStore: ChangePaymentModeStore;
    closeCounterStore: CloseCounterStore;
}

export const store: Store ={    
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    branchStore: new BranchStore(),
    brandStore: new BrandStore(),
    businessEntityStore: new BusinessEntityStore(),
    productStore: new ProductStore(),
    serviceTreatmentStore: new ServiceTreatmentStore(),
    packageStore: new PackageStore(),
    employeeStore: new EmployeeStore(),
    currencyStore: new CurrencyStore(),
    countryStore: new CountryStore(),
    staticReferenceStore: new StaticReferenceStore(),
    categoryStore: new CategoryStore(),
    salesCategoryStore: new SalesCategoryStore(),
    taxStore: new TaxStore(),
    measurementStore: new MeasurementStore(),
    raceStore: new RaceStore(),
    epfContributionStore: new EPFContributionStore(),
    eisStore: new EISStore(),
    socsoStore: new SocsoStore(),
    pcbStore: new PCBStore(),
    appointmentStore: new AppointmentStore(),
    userRoleStore: new UserRoleStore(),
    customerStore: new CustomerStore(),
    mediaSourceStore: new MediaSourceStore(),
    customerOrderStore: new CustomerOrderStore(),
    invoiceStore: new InvoiceStore(),
    positionStore: new PositionStore(),
    supplierStore: new SupplierStore(),
    salesTargetStore: new SalesTargetStore(),
    warehouseStore: new WarehouseStore(),
    deliveryOrderStore: new DeliveryStore(),
    stockMovementStore: new StockMovementStore(),
    payrollItemStore: new PayrollItemStore(),
    stockRequestStore: new StockRequestStore(),
    stockReceiveStore: new StockReceiveStore(),
    leaveEntitlementStore: new LeaveEntitlementStore(),
    purchaseOrderStore: new PurchaseOrderStore(),
    inboundNoticeStore: new InboundNoticeStore(),
    goodsReceiveNoteStore: new GoodsReceiveNoteStore(),
    purchaseDeliverOrderStore: new PurchaseDeliverOrderStore(),
    permissionStore: new PermissionStore(),
    profileStore: new ProfileStore(),
    reportSalesStore: new ReportSalesStore(),
    notificationTemplateStore: new NotificationTemplateStore(),
    stockAdjustmentStore: new StockAdjustmentStore(),
    stockInStore: new StockInStore(),
    stockOutStore: new StockOutStore(),
    stockReturnToHQStore: new StockReturnToHQStore(),
    paymentMethodStore: new PaymentMethodStore(),
    payslipStore: new PayslipStore(),
    additionalFormFieldStore: new AdditionalFormFieldStore(),
    notificationStore: new NotificationStore(),
    stockTakeStore: new StockTakeStore(),
    auditLogStore: new AuditLogStore(),
    customerCartStore: new CustomerCartStore(),
    leadStore: new LeadStore(),
    customerSalesOrderStore: new CustomerSalesOrderStore(),
    testTimeZoneStore:  new TestTimeZoneStore(),
    settingsStore: new SettingsStore(),
    promotionStore: new PromotionStore(),
    customerRefundStore: new CustomerRefundStore(),
    creditAdjustmentStore: new CreditAdjustmentStore(),
    transferCreditStore: new TransferCreditStore(),
    reportMarcomStore: new ReportMarcomStore(),
    reportAppointmentStore: new ReportAppointmentStore(), 
    employeeLeaveStore:  new EmployeeLeaveStore(),
    reportPaymentStore: new ReportPaymentStore(), 
    reportCustomerStore: new ReportCustomerStore(),
    dashboardStore: new DashboardStore(),
    reportServicesStore: new ReportServicesStore(),
    reportStockStore: new ReportStockStore(),
    reportManagementStore: new ReportManagementStore(),
    customerPaymentAdjustmentStore: new CustomerPaymentAdjustmentStore(),
    customerProductReturnStore: new CustomerProductReturnStore(),
    extendPackageStore: new ExtendPackageStore(),
    paymentMethodOptionStore: new PaymentMethodOptionStore(),
    salesAdjustmentStore: new SalesAdjustmentStore(),
    commissionSchemeStore: new CommissionSchemeStore(),
    customerAccessRequestStore: new CustomerAccessRequestStore(),
    reportMasterDataStore: new ReportMasterDataStore(),
    customerTransferRequestStore: new CustomerTransferRequestStore(),
    additionBonusSalesTargetStore: new AdditionBonusSalesTargetStore(),
    customerVirtualWarehouseStore: new CustomerVirtualWarehouseStore(),
    productRedemptionNoteStore: new ProductRedemptionNoteStore(),
    branchCommissionStore: new BranchCommissionStore(),
    reportHumanResourceStore: new ReportHumanResource(),
    contractLiabilityStore: new ContractLiabilityStore(),
    virtualWarehouseCreditExchangeStore: new VirtualWarehouseCreditExchangeStore(),
    productRedemptionNoteReturnStore: new ProductRedemptionNoteReturnStore(),
    dsrAdjustmentStore: new DSRAdjustmentStore(),
    batchJobStore: new BatchJobStore(),
    customerSubBranchRequestStore: new CustomerSubBranchRequestStore(),
    appolousCustomerPackageStore: new AppolousCustomerPackageStore(),
    appolousCustomerProductStore: new AppolousCustomerProductStore(),
    appolousCustomerSalesStore: new AppolousCustomerSalesStore(),
    reportAppolousStore: new ReportAppolousStore(),
    virtualWarehouseStockMovementStore: new VirtualWarehouseStockMovementStore(),
    appointmentTargetStore: new AppointmentTargetStore(),
    marcomDailyRecordStore: new MarcomDailyRecordStore(),
    cacheStore: new CacheStore(),
    departmentStore: new DepartmentStore(),
    customerChangePaymentMethodStore: new CustomerChangePaymentMethodStore(),
    appointmentLeadStore: new AppointmentLeadStore(),
    machineStore: new MachineStore(),
    contractLiabilityBenchmarkStore: new ContractLiabilityBenchmarkStore(),
    groupCodeStore: new GroupCodeStore(),
    reportAccountStore: new ReportAccountStore(),
    tncStore: new TncStore(),
    unknownPaymentStore: new UnknownPaymentStore(),
    cashOutStore: new CashOutStore(),
    paymentTerminalStore: new PaymentTerminalStore(),
    changePaymentModeStore: new ChangePaymentModeStore(),
    closeCounterStore: new CloseCounterStore()
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}