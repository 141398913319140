import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import MyButton from "../../../app/components/form/MyButton";
import GeneralInput from "../../../app/components/form/GeneralInput";
import ReportExpandRow from "../subView/ReportExpandRow";

const ServiceTreatmentMasterData = () => {
  const intl = useIntl();
  //Use Store
  const { reportMasterDataStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, windowSize  } = commonStore;
  const { serviceTreatmentMasterDataList, getServiceTreatmentMasterDataList, setServiceTreatmentMasterDataList, exportServiceTreatmentMasterData } = reportMasterDataStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMasterDataReport = checkPermission([PermissionConstants.ExportMasterDataReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageMasterDataReport], true)) {
      return;
    }

    return (() => {
      setServiceTreatmentMasterDataList([]);
    })
  }, []);


  const fetchServiceTreatmentMasterDataListWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportServiceTreatmentMasterData({ pageNumber: 1, pageSize: pageSize, name: validation.values.name });
    }
    else{
      setCurrentPage(1);
      await getServiceTreatmentMasterDataList({ pageNumber: 1, pageSize: pageSize, serviceName: encodeURIComponent(validation.values.name) });
    }
    setLocalLoading(false);
  };

  const durationFormatter = (cell, row) => {
    return `${cell.hours} hours ${cell.minutes} minutes`
  }

  //Table Content UI
  const ServiceTreatmentMasterDataColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.name || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.sellingPrice)}
        </div>
      ),
    },
    {
      dataField: "floorPrice",
      text: intl.formatMessage({ id: "FloorPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.floorPrice)}
        </div>
      ),
    },
    {
      dataField: "duration",
      text: intl.formatMessage({ id: "Duration" }).toUpperCase(),
      formatter: (cellContent, row) => <div className="text-center">{row.duration}</div>, //Custom UI to display
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.salesCategoryName)}</div>
      ),
    },
    {
      dataField: "taxName",
      text: intl.formatMessage({ id: "Tax" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.taxName}`}</div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "description",
      text: intl.formatMessage({ id: "Description" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.description || Constants.emptyData}</div>
      ),
    },
    {
      ...!getBranchUser() &&{
        dataField: "additionalInfo",
        text: intl.formatMessage({ id: "AdditionalInfo" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div style={{width:"150px"}}>{row.additionalInfo || Constants.emptyData}</div>
        ),
      }
    },
    {
      dataField: "mandatoryProducts",
      text: intl.formatMessage({ id: "MandatoryProducts" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"150px"}}>{row.mandatoryProducts || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "optionalProducts",
      text: intl.formatMessage({ id: "OptionalProducts" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"150px"}}>{row.optionalProducts || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "availableBranches",
      text: intl.formatMessage({ id: "AvailableBranches" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"400px"}}>{`${row.availableBranches}`}</div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ServiceTreatmentMasterData" }) })}
        title={intl.formatMessage({ id: "ServiceTreatmentMasterData" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMasterDataReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchServiceTreatmentMasterDataListWithLoading(true)
            }}
          />
        }>
        <Row>
          <DoubleColumnRowParent>
          <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterServiceTreatmentName" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchServiceTreatmentMasterDataListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ServiceTreatmentMasterDataColumns}
              keyField={"randomId"}
              data={serviceTreatmentMasterDataList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ServiceTreatmentMasterData" }) })}
              objSorted={defaultSorted}
              requestAPI={getServiceTreatmentMasterDataList}
              serviceName={encodeURIComponent(validation.values.name)}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ServiceTreatmentMasterData);
