import React, { useEffect, useState } from 'react'
import { ArrayForCheckBoxTable } from '../../models/common';
import {
  Label,
  Input,
  Row,
  Col,
  InputGroup
} from "reactstrap";
import Select from "react-select"
import LineBreakWithTittle from '../form/LineBreakWithTittle';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { returnCurrency } from '../../common/function/function';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
interface Props {
  name?: string;
  className?: string;
  title?: string;
  blnSelectAll?: boolean;
  blnTimeSlot?: boolean;
  blnValidation?: boolean;
  blnPrice?: boolean;
  blnTextInput?: boolean;
  blnSectionHeader?: boolean;
  blnEditable?: boolean;
  blnSingleSelect?: boolean;
  disabled?: boolean;
  hyperLinkModule?: string;
  specifyReturnFieldName?: any; //[{"field": "", "value": ""}]
  textInputFieldName?: string;
  textInputDisplayName?: string;
  paddingXL?: string;
  contentPaddingXL?: string;
  data: ArrayForCheckBoxTable[] | any;
  displayContentField?: string;
  isCheckedField?: string;
  setFieldValue: Function;
}

export default function TableCheckBoxWithSectionHeader(props: Props) {
  const [aryData, setData] = useState(_.cloneDeep(props.data));
  const [blnSelectAllChecked, setSelectAllChecked] = useState(false);
  const [aryFromTime, setFromTime] = useState([{ value: '9.00am', label: '9.00am' }, { value: '10.00am', label: '10.00am' }])
  const [aryToTime, setToTime] = useState([{ value: '5.00pm', label: '5.00pm' }, { value: '6.00pm', label: '6.00pm' }])
  const isCheckFieldDisplay = props.isCheckedField || "isActive"

  useEffect(()=> {
    checkAllTicked();
  }, [])

  const markedAll = (checked: boolean) => {
    for (var a=0; a<aryData.length; a++) {
      if (props.blnSectionHeader) {
        for (var b=0;b<aryData[a].data.length; b++){
          aryData[a].data[b][isCheckFieldDisplay] = checked;
        }
      }
      else {
        aryData[a][isCheckFieldDisplay] = checked;
      }
    }

    if (!props.blnSingleSelect) {
      props.setFieldValue(props.name, aryData);
    }
  } 

  const onChangeChecked = (e: React.ChangeEvent<any>, sectionIndex: number, rowIndex: number) => {
    if (props.blnSingleSelect) {
      markedAll(false);
    }
    
    if (props.blnSectionHeader) {
      aryData[sectionIndex].data[rowIndex][isCheckFieldDisplay] = e.target.checked
    }
    else {
      aryData[rowIndex][isCheckFieldDisplay] = e.target.checked
    }

    if (props.specifyReturnFieldName) {
      if (props.blnSectionHeader) {
        props.specifyReturnFieldName.map((valueTemp)=> {
          props.setFieldValue(valueTemp.field, aryData[sectionIndex].data[rowIndex][valueTemp.value])
        })
      }
      else {
        props.specifyReturnFieldName.map((valueTemp)=> {
          props.setFieldValue(valueTemp.field, aryData[rowIndex][valueTemp.value])
        })
      }
    }
    else {
      props.setFieldValue(props.name, aryData);
    }

    if (!props.blnSingleSelect) {
      checkAllTicked();
    }
  }

  const onChangedDropdown = (value: any, sectionIndex: number, rowIndex: number, blnFromTime) => {
    if (blnFromTime) {
      aryData[sectionIndex].data[rowIndex].fromTime = value
    }
    else {
      aryData[sectionIndex].data[rowIndex].toTime = value
    }
    
    props.setFieldValue(props.name, aryData);
  }

  const onChangeTextInput = (value: any, sectionIndex: number, rowIndex: number) => {
    if (props.blnSectionHeader) {
      aryData[sectionIndex].data[rowIndex][props.textInputFieldName || ""] = value
    }
    else {
      aryData[rowIndex][props.textInputFieldName || ""] = value
    }

    props.setFieldValue(props.name, aryData);
  }

  const checkAllTicked = () => {
    let blnAllChcked = true;
    for (var a=0; a<aryData.length; a++) {
      if (props.blnSectionHeader) {
        for (var b=0;b<aryData[a].data.length; b++){
          if (aryData[a].data[b][isCheckFieldDisplay] == false) {
            blnAllChcked = false;
            break;
          }
        }
      }
      else {
        if (aryData[a][isCheckFieldDisplay] == false) {
          blnAllChcked = false;
          break;
        }
      }
      if (!blnAllChcked) 
        break;
    }
    setSelectAllChecked(blnAllChcked)
  }

  const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: base.borderColor,
    })
  }

  const displayCheckBox = (sectionIndex, rowIndex, rowValue) => {
    return (
      <Col xl={props.paddingXL || (props.blnTimeSlot ? "4" : props.blnTextInput ? "6" : "12")} style={{marginBottom: "8px"}}>
        {
          props.blnEditable 
          ?
          <Input
            type="checkbox"
            onChange={(e)=> onChangeChecked(e, sectionIndex, rowIndex)}
            checked={rowValue[isCheckFieldDisplay]}
            disabled={props.disabled}
          />
          :
          <MenuRightIcon className=" margin-top--3" size={21}/>
          // <Label>{` - `}</Label>
        }
        {
          props.hyperLinkModule
          ?
          <Link to={`/${props.hyperLinkModule}/view/${rowValue.id}`}>
            <Label className="margin-bottom-0 margin-left-4">{props.displayContentField ? rowValue[props.displayContentField] : rowValue.label}</Label>
          </Link>
          :
          <Label className="margin-bottom-0 margin-left-4">{props.displayContentField ? rowValue[props.displayContentField] : rowValue.label}</Label>
        }
      </Col>
    )
  }

  const displayTimeDropDown = (sectionIndex, rowIndex, blnFromTime) => {
    if (blnFromTime) {
      let blnClassNamePrex = `fromTime_${sectionIndex}_${rowIndex}`;
      return (
        props.blnTimeSlot
        &&
        <Col xl="4">
          <Select
            name="fromTime"
            classNamePrefix={blnClassNamePrex}
            defaultValue={aryFromTime[0]}
            options={aryFromTime}
            styles={selectStyle}
            menuPosition="fixed"
            onChange={(e)=> onChangedDropdown(e?.value, sectionIndex, rowIndex, true)}
            value={{value: aryData[sectionIndex].data[rowIndex].fromTime, label: aryData[sectionIndex].data[rowIndex].fromTime}}
            onMenuOpen={()=> {
              setTimeout(()=>{
                const selectedEl = document.getElementsByClassName(`${blnClassNamePrex}__option--is-selected`)[0];
                if(selectedEl){
                  selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
                }
              },15)
            }}
          />
        </Col>
      )
    }
    else {
      let blnClassNamePrex = `toTime_${sectionIndex}_${rowIndex}`
      return (
        props.blnTimeSlot
        &&
        <Col xl="4">
          <Select
            name="toTime"
            classNamePrefix={blnClassNamePrex}
            defaultValue={aryToTime[0]}
            options={aryToTime}
            styles={selectStyle}
            menuPosition="fixed"
            onChange={(e)=> onChangedDropdown(e?.value, sectionIndex, rowIndex, false)}
            value={{value: aryData[sectionIndex].data[rowIndex].toTime, label: aryData[sectionIndex].data[rowIndex].toTime}}
            onMenuOpen={()=> {
              setTimeout(()=>{
                const selectedEl = document.getElementsByClassName(`${blnClassNamePrex}__option--is-selected`)[0];
                if(selectedEl){
                  selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
                }
              },15)
            }}
          />
        </Col>
      )
    }
  }

  const displayTextInput = (sectionIndex, rowIndex) => {
    return (
      props.blnTextInput &&
      <Col className="g-0" xl={props.paddingXL || "6"}>
        <InputGroup className="margin-bottom-2">
          {
            props.blnPrice &&
            <span className="input-group-text">
              {returnCurrency()}
            </span>
          }
          <Input
            name={props.textInputFieldName}
            label={props.textInputDisplayName}
            type="text"
            onChange={(e)=> onChangeTextInput(e?.target?.value, sectionIndex, rowIndex)}
            placeholder={props.blnPrice ? "if different" : ""}
            disabled={props.disabled}
          />
        </InputGroup>
      </Col>
    )
  }

  const displayEmptyRow = () => {
    return (
      // <Label>There's no content available</Label>
      <LineBreakWithTittle title={`There's no content available`} color='grey'/>
    )
  }

  return (
    <div className={`${props.className} tab_bar_border`}>
      {
        props.title
        &&
        <Label>
          {props.blnValidation && <Label style={{color: 'red'}}>{`*`}</Label>}
          <Label className="margin-bottom-0">{props.title}</Label>
        </Label>
      }
      {
        props.blnSelectAll 
        && 
        <Row>
          <Col xl={props.paddingXL || (props.blnTextInput ? "6" : "12")}>
            <Input
              type="checkbox"
              onChange={(e)=>{
                setSelectAllChecked(e.target.checked)
                markedAll(e.target.checked)
              }}
              checked={blnSelectAllChecked}
              disabled={props.disabled}
            />
            <Label className="margin-bottom-0 margin-left-4">Select All</Label>
          </Col>
          {
            props.blnTextInput &&
            <Col xl={props.paddingXL || "6"}>
              <h6>
                {props.textInputDisplayName}
              </h6>
            </Col>
          }
        </Row>
      }

      {
        <Row>
          {
            aryData.length > 0
            ?
              aryData.map((sectionValue, sectionIndex)=> {
                if (props.blnSectionHeader) {
                  return (
                    <Col key={`${sectionValue.sectionHeaderTitle}${sectionIndex}`} xl={props.contentPaddingXL || "12"}>
                      <LineBreakWithTittle title={sectionValue.sectionHeaderTitle}/>
                      {
                        sectionValue.data.length > 0
                        ?
                        sectionValue.data.map((rowValue, rowIndex)=> {
                          return (
                            <Row className={`flex-align-items-center ${props.blnTimeSlot && 'margin-bottom-4'}`} key={`${props.displayContentField ? rowValue[props.displayContentField] : rowValue.label}${rowIndex}`}>
                              {displayCheckBox(sectionIndex, rowIndex, rowValue)}
                              {displayTimeDropDown(sectionIndex, rowIndex, true)}
                              {displayTimeDropDown(sectionIndex, rowIndex, false)}
                              {displayTextInput(sectionIndex, rowIndex)}
                            </Row>
                          )
                        })
                        :
                        displayEmptyRow()
                      }
                    </Col>
                  )
                }
                else {
                  const rowIndex = sectionIndex;
                  const rowValue = sectionValue;
                  return (
                    <Col key={`${sectionValue.sectionHeaderTitle}${sectionIndex}`} xl={props.contentPaddingXL || "12"}>
                      <Row className={`flex-align-items-center ${props.blnTimeSlot && 'margin-bottom-4'}`} key={`${props.displayContentField ? rowValue[props.displayContentField] : rowValue.label}${rowIndex}`}>
                        {displayCheckBox(sectionIndex, rowIndex, rowValue)}
                        {displayTimeDropDown(sectionIndex, rowIndex, true)}
                        {displayTimeDropDown(sectionIndex, rowIndex, false)}
                        {displayTextInput(sectionIndex, rowIndex)}
                      </Row>
                    </Col>
                  )
                }
              })
            :
            displayEmptyRow()
          }
        </Row>        
      }
    </div>
  )
}
