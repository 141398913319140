import { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
} from "reactstrap";
import classNames from "classnames";
import { useIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReactToPrint from 'react-to-print';

//Import Chart
import DashedLine from "../../app/components/Charts/apex/DashedLine"
import Doughnut from "../../app/components/Charts/echart/doughnutchart"

//Import common component
import ChartWithTitle from '../../app/components/Charts/ChartWithTitle';

//Import Dummy Data
import { appointmentStatusColor, checkPermission, convertArrayToStringDisplay, getBranchId, getBranchUser, newSpaceBeforeCapitalLetter, returnPendingSignOrderLinkPath, returnPriceWithCurrency, returnSubBrandList } from "../../app/common/function/function";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ChartObject, UpcomingAppointmentObject, WeeklyPendingOrders } from "../../app/models/dashboard";
import { Constants } from "../../app/constants/Constants";
import Loading from "../../app/components/loading/Loading";
import moment from "moment";
import BarChart from "../../app/components/Charts/apex/BarChart";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import CalendarDayIcon from 'mdi-react/CalendarDayIcon';
import ClockTimeElevenOutlineIcon from 'mdi-react/ClockTimeElevenOutlineIcon';
import HomeVariantIcon from "mdi-react/HomeVariantIcon"
import { Link } from 'react-router-dom';
import { RoutesList } from "../../app/constants/RoutesList";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import PackageVariantClosedIcon from "mdi-react/PackageVariantClosedIcon";
import ArrangeBringForwardIcon from "mdi-react/ArrangeBringForwardIcon";
import ClipboardEditIcon from "mdi-react/ClipboardEditIcon";
import AccountCircleIcon from "mdi-react/AccountCircleIcon";
import AccountIcon from "mdi-react/AccountIcon";
import CartCheckIcon from "mdi-react/CartCheckIcon";
import PieChartFromEChart from "../../app/components/Charts/echart/PieChartFromEChart";
import PrinterIcon from "mdi-react/PrinterIcon";
import ArrowExpandAllIcon from "mdi-react/ArrowExpandAllIcon";
import DSRModal from "./Modal/DSRModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { CloseCounterPendingObject } from "../../app/models/closeCounter";
import PendingCloseCounterModal from "../CustomerOrder/Modal/PendingCloseCounterModal";

const Dashboard = () => {
  const intl = useIntl();
  //Use Store
  const { dashboardStore, closeCounterStore, commonStore } = useStore();
  const { loading, setLoading, windowSize } = commonStore;
  const { servicePerformedCountChart, salesSummaryChart, salesCategorySummaryChart, hourlySalesSummaryChart, salesPaymentSummaryChart, newCustomerCountChart, dailyCustomerBirthdayChart, appointmentCountChart, todayUpcomingAppointmentChart, weeklyUpcomingAppointmentChart, topSellingProductsChart, topSellingPackagesChart, topSellingServicesChart, topCustomerSalesChart, weeklyPendingOrdersChart, dailySalesSummaryChart, referralSpendingSummaryChart, salesByEmployeeSummary,
    getServicePerformedCountChart, getSalesSummaryChart, getSalesCategorySummaryChart, getHourlySalesSummaryChart, getSalesPaymentSummaryChart, getNewCustomerCountChart, getDailyCustomerBirthdayChart, getAppointmentCountChart, getTodayUpcomingAppointmentChart, getWeeklyUpcomingAppointmentChart, getTopSellingProductsChart, getTopSellingPackagesChart, getTopSellingServicesChart, getTopCustomerSalesChart, getWeeklyPendingOrdersChart, getDailySalesSummaryChart, getReferralSpendingSummaryChart, getSalesByEmployeeSummary, reset } = dashboardStore;
  const { getPendingCloseCounter } = closeCounterStore;
   
  const [tabBar, setTabBar] = useState([
    // intl.formatMessage({ id: "TopClients" }).toUpperCase(), 
    intl.formatMessage({ id: "TopServices" }).toUpperCase(),
    intl.formatMessage({ id: "TopPackages" }).toUpperCase(), 
    intl.formatMessage({ id: "TopProducts" }).toUpperCase()
  ]);
  const subBrandListWithAll = returnSubBrandList(true);

  const blnPermissionManageSalesReport = checkPermission([PermissionConstants.ManageSalesReport]);
  const blnPermissionManagePaymentReport = checkPermission([PermissionConstants.ManagePaymentReport]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const refDailySalesReport : any = useRef(null);
  const refReferralSpendingSummary : any = useRef(null);

  const [loadingServicePerformedCountChart, setLoadingServicePerformedCountChart] = useState(false);
  const [loadingSalesSummaryChart, setLoadingSalesSummaryChart] = useState(false);
  const [loadingSalesCategorySummaryChart, setLoadingSalesCategorySummaryChart] = useState(false);
  const [loadingHourlySalesSummaryChart, setLoadingHourlySalesSummaryChart] = useState(false);
  const [loadingSalesPaymentSummaryChart, setLoadingSalesPaymentSummaryChart] = useState(false);
  const [loadingNewCustomerCountChart, setLoadingNewCustomerCountChart] = useState(false);
  const [loadingDailyCustomerBirthdayChart, setLoadingDailyCustomerBirthdayChart] = useState(false);
  const [loadingAppointmentCountChart, setLoadingAppointmentCountChart] = useState(false);
  const [loadingTodayUpcomingAppointmentChart, setLoadingTodayUpcomingAppointmentChart] = useState(false);
  const [loadingWeeklyUpcomingAppointmentChart, setLoadingWeeklyUpcomingAppointmentChart] = useState(false);
  const [loadingTopSellingProductsChart, setLoadingTopSellingProductsChart] = useState(false);
  const [loadingTopSellingPackagesChart, setLoadingTopSellingPackagesChart] = useState(false);
  const [loadingTopSellingServicesChart, setLoadingTopSellingServicesChart] = useState(false);
  const [loadingTopCustomerSalesChart, setLoadingTopCustomerSalesChart] = useState(false);
  const [loadingWeeklyPendingOrdersChart, setLoadingWeeklyPendingOrdersChart] = useState(false);
  const [loadingDailySalesSummaryChart, setLoadingDailySalesSummaryChart] = useState(false);
  const [loadingReferralSpendingSummary, setLoadingReferralSpendingSummary] = useState(false);
  const [loadingSalesByEmployeeSummary, setLoadingSalesByEmployeeSummary] = useState(false);
  
  const [dsrModal, setDSRModal] = useState(false);
  const [rssModal, setRSSModal] = useState(false);
  const blnSmallWindowSize = Constants.innerWidthCompare > windowSize.innerWidth;
  const blnSubBrandExist = returnSubBrandList().length > 0;
  const replaceHyperLinkColor = Constants.dark;
  const [activeMonthlyTop10ListsTab, setActiveMonthlyTop10ListsTab] = useState("1");
  const [activeDailySalesTab, setActiveDailySalesTab] = useState("1");
  const [activeReferralSpendingTab, setActiveReferralSpendingTab] = useState("1");

  const [blnShowPendingSignModal, setBlnShowPendingSignModal] = useState(false);
  const [blnShowPendingCloseCounterModal, setBlnShowPendingCloseCounterModal] = useState(false);
  const [pendingCloseCounterDetail, setPendingCloseCounterDetail] = useState<CloseCounterPendingObject | undefined>(undefined);

  const toggle = (tab: string, activeTab, setActiveTab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  async function fetchServicePerformedCountChart () {
    // setLoadingServicePerformedCountChart(true);
    // await getServicePerformedCountChart({ branchId: getBranchId() });
    // setLoadingServicePerformedCountChart(false);
  }

  async function fetchSalesSummaryChart () {
    // setLoadingSalesSummaryChart(true);
    // await getSalesSummaryChart({ branchId: getBranchId() });
    // setLoadingSalesSummaryChart(false);
  }

  async function fetchSalesCategorySummaryChart () {
    setLoadingSalesCategorySummaryChart(true);
    await getSalesCategorySummaryChart({ branchId: getBranchId() });
    setLoadingSalesCategorySummaryChart(false);
  }

  async function fetchHourlySalesSummaryChart () {
    // setLoadingHourlySalesSummaryChart(true);
    // await getHourlySalesSummaryChart({ branchId: getBranchId() });
    // setLoadingHourlySalesSummaryChart(false);
  }

  async function fetchSalesPaymentSummaryChart () {
    setLoadingSalesPaymentSummaryChart(true);
    await getSalesPaymentSummaryChart({ branchId: getBranchId() });
    setLoadingSalesPaymentSummaryChart(false);
  }

  async function fetchNewCustomerCountChart () {
    setLoadingNewCustomerCountChart(true);
    await getNewCustomerCountChart({ branchId: getBranchId() });
    setLoadingNewCustomerCountChart(false);
  }

  async function fetchDailyCustomerBirthdayChart () {
    setLoadingDailyCustomerBirthdayChart(true);
    await getDailyCustomerBirthdayChart({ branchId: getBranchId() });
    setLoadingDailyCustomerBirthdayChart(false);
  }

  async function fetchAppointmentCountChart () {
    setLoadingAppointmentCountChart(true);
    await getAppointmentCountChart({ branchId: getBranchId() });
    setLoadingAppointmentCountChart(false);
  }

  async function fetchTodayUpcomingAppointmentChart () {
    // setLoadingTodayUpcomingAppointmentChart(true);
    // await getTodayUpcomingAppointmentChart({ branchId: getBranchId() });
    // setLoadingTodayUpcomingAppointmentChart(false);
  }

  async function fetchWeeklyUpcomingAppointmentChart () {
    // setLoadingWeeklyUpcomingAppointmentChart(true);
    // await getWeeklyUpcomingAppointmentChart({ branchId: getBranchId() });
    // setLoadingWeeklyUpcomingAppointmentChart(false);
  }

  async function fetchTopSellingProductsChart () {
    setLoadingTopSellingProductsChart(true);
    await getTopSellingProductsChart({ branchId: getBranchId(), purchaseType: Constants.product });
    setLoadingTopSellingProductsChart(false);
  }

  async function fetchTopSellingPackagesChart () {
    setLoadingTopSellingPackagesChart(true);
    await getTopSellingPackagesChart({ branchId: getBranchId(), purchaseType: Constants.package });
    setLoadingTopSellingPackagesChart(false);
  }

  async function fetchTopSellingServicesChart () {
    setLoadingTopSellingServicesChart(true);
    await getTopSellingServicesChart({ branchId: getBranchId(), purchaseType: Constants.service });
    setLoadingTopSellingServicesChart(false);
  }

  async function fetchTopCustomerSalesChart () {
    // setLoadingTopCustomerSalesChart(true);
    // await getTopCustomerSalesChart({ branchId: getBranchId() });
    // setLoadingTopCustomerSalesChart(false);
  }

  async function fetchWeeklyPendingOrdersChart () {
    setLoadingWeeklyPendingOrdersChart(true);
    let resultAPI = await getWeeklyPendingOrdersChart({ branchId: getBranchId(), isFilterOutTodayOrder: false });
    if (resultAPI.status === Constants.success) {
      if (resultAPI.data.length > 0) {
        setBlnShowPendingSignModal(true);
      }
    }
    setLoadingWeeklyPendingOrdersChart(false);
  }

  async function fetchDailySalesSummaryChart (subBrandId: string = "") {
    setLoadingDailySalesSummaryChart(true);
    await getDailySalesSummaryChart({ branchId: getBranchId(), subBrandId: subBrandId });
    setLoadingDailySalesSummaryChart(false);
  }

  async function fetchReferralSpendingSummaryChart (subBrandId: string = "") {
    setLoadingReferralSpendingSummary(true);
    await getReferralSpendingSummaryChart({ branchId: getBranchId(), subBrandId: subBrandId });
    setLoadingReferralSpendingSummary(false);
  }

  async function fetchSalesByEmployeeSummary () {
    setLoadingSalesByEmployeeSummary(true);
    await getSalesByEmployeeSummary({ branchId: getBranchId() });
    setLoadingSalesByEmployeeSummary(false);
  }

  async function fetchPendingCloseCounter () {    
    let resultPendingCloseCounter = await getPendingCloseCounter(getBranchId());
    if (resultPendingCloseCounter) {
      if (resultPendingCloseCounter.isInvalidCashOut || resultPendingCloseCounter.isInvalidCloseCounter) {
        setPendingCloseCounterDetail(resultPendingCloseCounter);
        setBlnShowPendingCloseCounterModal(true);
      }
    }
  }

  const returnSubBrandIdForTab = (activeTab) => {
    return `&subBrandId=${Number(activeTab) > 1 ? subBrandListWithAll[Number(activeDailySalesTab)-1].id : ""}`
  }

  useEffect(() => {
    async function fetchDashboardAPI() {
      let aryAPI: any = [
        fetchServicePerformedCountChart(),
        fetchSalesSummaryChart(),
        fetchSalesCategorySummaryChart(),
        fetchHourlySalesSummaryChart(),
        fetchSalesPaymentSummaryChart(),
        fetchNewCustomerCountChart(),
        fetchDailyCustomerBirthdayChart(),
        fetchAppointmentCountChart(),
        fetchTodayUpcomingAppointmentChart(),
        fetchWeeklyUpcomingAppointmentChart(),
        fetchTopSellingProductsChart(),
        fetchTopSellingPackagesChart(),
        fetchTopSellingServicesChart(),
        fetchTopCustomerSalesChart(),
        fetchWeeklyPendingOrdersChart(),
        fetchDailySalesSummaryChart(),
        fetchReferralSpendingSummaryChart(),
        fetchSalesByEmployeeSummary(),
        fetchPendingCloseCounter()
      ];
      await Promise.all(aryAPI);     
    }

    fetchDashboardAPI();

    return (() => {
      reset();
    })
  }, [])

  //Table Content UI
  const SalesPaymentSummaryColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == salesPaymentSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight}}>
          {row.branchName}
        </span>
      ),
    },
    {
      dataField: "todayPayment",
      text: intl.formatMessage({ id: "TodayPayment" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == salesPaymentSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight}}>
          {returnPriceWithCurrency(row.todayPayment)}
        </span>
      ),
    },
    {
      dataField: "accumulatedPayment",
      text: intl.formatMessage({ id: "MTDActual" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == salesPaymentSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight}}>
          {returnPriceWithCurrency(row.accumulatedPayment)}
        </span>
      ),
    }
  ];

  const DailyCustomerBirthdayColumns = [
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div>
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <div>{row.customerName || Constants.emptyData}</div>
          </Link>
        </div>
        :
        <div>{row.customerName}</div>
      ),
    },
    {
      dataField: "birthday",
      text: intl.formatMessage({ id: "Birthday" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.birthday).format(Constants.displayDateTextInputFormat)}
        </div>
      ),
    },
  ];

  const MonthlyOfTopClientsColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "TotalSales" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{returnPriceWithCurrency(row.amount)}</div>,
    },
  ];

  const MonthlyOfTopServicesColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "TotalPerformed" }).toUpperCase(),
    },
  ];

  const MonthlyOfTopPackgesProductsColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "TotalSold" }).toUpperCase(),
    },
  ];

  const returnDailySalesSummaryFontWeight = (index) => {
    return index == dailySalesSummaryChart.length -1 && !getBranchUser() ? Constants.boldFontWeight : Constants.normalFontWeight;
  }

  const DailySalesSummaryColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
          {row.branchName}
        </span>
      ),
    },
    {
      dataField: "accumulatedPayment",
      text: intl.formatMessage({ id: "MTDActual" }).toUpperCase(),
      // headerStyle: { width: '130px' },
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.accumulatedPayment)}
        </span>
      ),
    },
    {
      dataField: "cashRefund",
      text: intl.formatMessage({ id: "CashRefund" }).toUpperCase(),
      // headerStyle: { width: '130px' },
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.cashRefund)}
        </span>
      ),
    },
    // {
    //   dataField: "accumulatedCollectOnBehalf",
    //   text: intl.formatMessage({ id: "MTDCollectOnBehalf" }).toUpperCase(),
    //   // headerStyle: { width: '130px' },
    //   formatter: (cellContent, row, index) => (
    //     <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
    //       {returnPriceWithCurrency(row.accumulatedCollectOnBehalf)}
    //     </span>
    //   ),
    // },
    {
      dataField: "referralCount",
      text: intl.formatMessage({ id: "Referral" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        index == dailySalesSummaryChart.length -1 && !getBranchUser()
        ?
        <span style={{fontWeight: Constants.boldFontWeight }}>
          {row.referralCount}
        </span>
        :
        blnPermissionManageSalesReport
        &&
        (!getBranchUser() || (getBranchUser() && row.branchId === getBranchId()))
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${Constants.referral}${returnSubBrandIdForTab(activeDailySalesTab)}`}
          target={Constants.blank}
          style={{color: replaceHyperLinkColor}}>
          <div>
            {row.referralCount}
          </div>
        </Link>
        :
        <div>
          {row.referralCount}
        </div>
      ),
    },
    {
      dataField: "resignUpgradeAmount",
      text: intl.formatMessage({ id: "Resign/Upgrade" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        index == dailySalesSummaryChart.length -1 && !getBranchUser()
        ?
        <span style={{fontWeight: Constants.boldFontWeight }}>
          {returnPriceWithCurrency(row.resignUpgradeAmount)}
        </span>
        :
        blnPermissionManageSalesReport
        &&
        (!getBranchUser() || (getBranchUser() && row.branchId === getBranchId()))
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${Constants.resignUpgrade}${returnSubBrandIdForTab(activeDailySalesTab)}`}
          target={Constants.blank}
          style={{color: replaceHyperLinkColor}}>
          <div>
            {returnPriceWithCurrency(row.resignUpgradeAmount)}
          </div>
        </Link>
        :
        <div>
          {returnPriceWithCurrency(row.resignUpgradeAmount)}
        </div>
      ),
    },
    {
      dataField: "consultationCount",
      text: intl.formatMessage({ id: "Consultation" }).toUpperCase(),
      headerStyle: { width: '110px', overflow: 'hidden' },
      formatter: (cellContent, row, index) => (
        index == dailySalesSummaryChart.length -1 && !getBranchUser()
        ?
        <span style={{fontWeight: Constants.boldFontWeight }}>
          {row.consultationCount}
        </span>
        :
        blnPermissionManageSalesReport
        &&
        (!getBranchUser() || (getBranchUser() && row.branchId === getBranchId()))
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${Constants.consultation}${returnSubBrandIdForTab(activeDailySalesTab)}`}
          target={Constants.blank}
          style={{color: replaceHyperLinkColor}}>
          <div>
            {row.consultationCount}
          </div>
        </Link>
        :
        <div>
          {row.consultationCount}
        </div>
      ),
    },
    {
      dataField: "newVisitCount",
      text: intl.formatMessage({ id: "NewVisit" }).toUpperCase(),
      headerStyle: { width: '110px', overflow: 'hidden' },
      formatter: (cellContent, row, index) => (
        index == dailySalesSummaryChart.length -1 && !getBranchUser()
        ?
        <span style={{fontWeight: Constants.boldFontWeight }}>
          {row.newVisitCount}
        </span>
        :
        blnPermissionManageSalesReport
        &&
        (!getBranchUser() || (getBranchUser() && row.branchId === getBranchId()))
        ?
        <Link
          to={`/${RoutesList.report}/${RoutesList.salesOverviewDetails}?branchId=${row.branchId}&type=${newSpaceBeforeCapitalLetter(Constants.newVisit)}${returnSubBrandIdForTab(activeDailySalesTab)}`}
          target={Constants.blank}
          style={{color: replaceHyperLinkColor}}>
          <div>
            {row.newVisitCount}
          </div>
        </Link>
        :
        <div>
          {row.newVisitCount}
        </div>
      ),
    },
    {
      dataField: "averageNewVisitPerDay",
      text: intl.formatMessage({ id: "AverageNewVisitPerDay" }).toUpperCase(),
      headerStyle: { width: '120px', overflow: 'hidden' },
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
          {row.averageNewVisitPerDay}
        </span>
      ),
    },
    {
      dataField: "cancellationCount",
      text: intl.formatMessage({ id: "DailyCancellation" }).toUpperCase(),
      headerStyle: { width: '110px', overflow: 'hidden' },
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnDailySalesSummaryFontWeight(index)}}>
          {row.cancellationCount}
        </span>
      ),
    },
  ];

  const ReferralSpendingSummaryColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == referralSpendingSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight}}>
          {row.branchName}
        </span>
      ),
    },
    {
      dataField: "referralCount",
      text: intl.formatMessage({ id: "Count" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == referralSpendingSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight, paddingLeft: "4px"}}>
          {row.referralCount}
        </span>
      )
    },
    {
      dataField: "spendingAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: index == referralSpendingSummaryChart.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight}}>
          {returnPriceWithCurrency(row.spendingAmount)}
        </span>
      )
    }
  ];

  const returnSalesByEmployeeSummaryFontWeight = (index) => {
    return index == salesByEmployeeSummary.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight;
  }

  const SalesByEmployeeSummaryColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {row.name}
        </span>
      )
    },
    {
      dataField: "dailyReceived",
      text: intl.formatMessage({ id: "DailyReceived" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.dailyReceived)}
        </span>
      )
    },
    {
      dataField: "mtdReceived",
      text: intl.formatMessage({ id: "mtdReceived" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.mtdReceived)}
        </span>
      )
    }
  ];

  const displayChart = (valueChart: ChartObject | undefined, blnCurrency?: boolean) => {
    if (valueChart) {
      if (valueChart.dashboardType === Constants.dashLineChart) {
        return (
          <DashedLine
            data={valueChart?.data || []}
            rowData={valueChart?.rowData}
            displayCurrency={blnCurrency} />
        )
      }
      else if (valueChart.dashboardType === Constants.doughnutChart) {
        return (
          <Doughnut
            data={valueChart?.data || []}
            rowData={valueChart?.rowData} />
        )
      }
      else if (valueChart.dashboardType === Constants.pieChart) {
        return (
          <PieChartFromEChart
            data={valueChart?.data || []}
            rowData={valueChart?.rowData}
            windowSize={windowSize} />
        )
      }
      else if (valueChart.dashboardType === Constants.barChart) {
        return (
          <BarChart
            data={valueChart?.data || []}
            rowData={valueChart?.rowData} />
        )
      }
    }

    return (<div>{intl.formatMessage({ id: "TechnicalIssue" })}</div>)
  }

  const displayLocalTable = (title, keyField, columns, data, loadingLocalTable: boolean, blnOffMarginTop: boolean = false, localTableHeight?: string, supportModal?: boolean, bordered?: boolean, rowColorCodeField?: string) => {
    let blnDailySalesSummary = title === intl.formatMessage({ id: "DailySalesSummary" });
    let blnReferralSpendingSummary = title === intl.formatMessage({ id: "ReferralSpendingSummary" });
    let blnDisplayPrintAndExpandButton = blnDailySalesSummary || blnReferralSpendingSummary;
    const blnHandlePrintAndExpandButton = Constants.innerWidthCompare770 > windowSize.innerWidth;

    return (
      <div className={blnSmallWindowSize && !blnOffMarginTop ? "margin-top-18" : ""}>
        {
          !supportModal
          &&
          <div className="flex-align-items-center-without-height mb-2">
            <div className="h4" {...blnHandlePrintAndExpandButton && {style: {flex: 1}}}>{title}</div>
            {
              blnDisplayPrintAndExpandButton
              &&
              !getBranchUser()
              &&
              <div className="d-print-none margin-left-12" {...blnHandlePrintAndExpandButton && {style: {width: "70px"}}}>
                <style type="text/css" media="print">{"\
                  @page {\ size: 22in 16in;;\ }\
                "}</style>
                <div className="btn btn-success" style={{padding: '8px', paddingTop: blnDisplayPrintAndExpandButton ? '4px' : '8px', paddingBottom: '4px'}}>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <div>
                          <PrinterIcon size={12}/>
                        </div>
                      )
                    }}
                    content={() => blnDailySalesSummary ? refDailySalesReport.current : refReferralSpendingSummary.current}
                  />
                </div>
                <div 
                  className="btn btn-secondary" style={{marginLeft: '8px', padding: '8px', paddingTop: '4px', paddingBottom: '4px'}}
                  onClick={()=> {
                    blnDailySalesSummary ? setDSRModal(true) : setRSSModal (true);
                  }}>
                  <ArrowExpandAllIcon size={12}/>
                </div>
              </div>
            }
          </div>
        }
        {
          blnDisplayPrintAndExpandButton
          &&
          !loadingLocalTable
          &&
          blnSubBrandExist
          &&
          <Nav 
            className="nav nav-tabs nav-tabs-custom" 
            style={{
              backgroundColor: 'white', 
              paddingTop: !supportModal ? '16px' : '0px', 
              paddingLeft: '16px',
              marginTop: !supportModal ? '16px' : '0px',
              marginBottom: '0px',
              borderTopLeftRadius:'10px',
              borderTopRightRadius: '10px'
            }}>
            {subBrandListWithAll.map((value, indexSubBrand) => (
              displaySubBrandTabBar(
                blnDailySalesSummary ? `daily_sales_summary_${indexSubBrand}` : `referral_spending_summary_${indexSubBrand}`, 
                value, 
                indexSubBrand, 
                blnDailySalesSummary ? activeDailySalesTab : activeReferralSpendingTab, 
                blnDailySalesSummary ? setActiveDailySalesTab : setActiveReferralSpendingTab, 
                blnDailySalesSummary ? fetchDailySalesSummaryChart : fetchReferralSpendingSummaryChart
              )
            ))}
          </Nav>
        }
        {
          loadingLocalTable
          ?
          <Loading/>
          :
          <div style={{backgroundColor: 'white', borderRadius: '10px', borderTopLeftRadius: blnDisplayPrintAndExpandButton && blnSubBrandExist ? '0px' : '10px', borderTopRightRadius: blnDisplayPrintAndExpandButton && blnSubBrandExist ? '0px' : '10px'}}>
            <div style={{ backgroundColor: 'white', marginLeft: '20px', marginRight: '20px', paddingTop: '0px', overflowY: !supportModal ? 'scroll' : undefined, height: !supportModal ? localTableHeight ? localTableHeight : '342px' : undefined }}>
              <ToolkitProvider
                keyField={keyField}
                data={data}
                columns={columns}
                bootstrap4
                search>
                {toolkitProps => (
                  <BootstrapTable
                    {...blnDailySalesSummary ? {ref : refDailySalesReport} : blnReferralSpendingSummary ? {ref: refReferralSpendingSummary} : null}
                    responsive
                    bordered={false}
                    striped={bordered ? false : true}
                    headerClasses={`bootstrap-table-sticky-header-white-bg ${bordered ? "" : ""}`}
                    classes={`table align-middle margin-top-16 ${blnDailySalesSummary ? "table-sticky-first-column" : ""}`}
                    keyField={keyField}
                    noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: title })}
                    {...rowColorCodeField ? { rowStyle: (row) => {
                      return { backgroundColor: row[rowColorCodeField] || Constants.white, color: 'black' };
                    }} : undefined}
                    {...toolkitProps.baseProps}
                  />
                )}
              </ToolkitProvider>
            </div>
          </div>
        }
      </div>
    )
  }

  const displaySubBrandTabBar = (key, value, index, activeTab, setActiveTab, fetchFunction) => {
    return (
      <NavItem key={`${key}`}>
        <NavLink
          disabled={index == 1 && (false)}
          style={{ cursor: "pointer" }}
          className={classNames({
            active:
              activeTab ===
              String(index + 1),
          })}
          onClick={() => {
            toggle(String(index + 1), activeTab, setActiveTab);
            fetchFunction(value.id);
          }}
        >
          {value.name}
        </NavLink>
      </NavItem>
    )
  }

  const displayLocalAppointmentTable = (title: string, aryAppointment: UpcomingAppointmentObject[]) => {
    return (
      <div style={{ backgroundColor: 'white', borderRadius: '10px', overflowY: 'scroll', height: ' 300px' }}>
        <Table className="table mb-4" striped={true}>
          <tbody>
            {
              aryAppointment.length > 0
                ?
                aryAppointment.map((valueUpcomingAppointmentChartTemp: UpcomingAppointmentObject, index: number) =>
                  <tr key={`${title}_${index}`}>
                    <td>
                      <Link
                        to={checkPermission([PermissionConstants.ManageAppointment]) ? `/${RoutesList.appointment}/view/${valueUpcomingAppointmentChartTemp.appointmentId}` : ""}
                        className="flex-justfity-content-center"
                        target={Constants.blank}
                        onClick={() => {
                        }}>
                        <div style={{ flex: 'auto', width: '100%' }}>
                          <span>
                            {convertArrayToStringDisplay(valueUpcomingAppointmentChartTemp.serviceNames)}
                          </span>
                          <br />
                          <div className="flex-align-items-center-without-height">
                            <CalendarDayIcon className="mb-0" size={15} />
                            <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                              {moment(valueUpcomingAppointmentChartTemp.startDateTime).format(Constants.displayDateAndTimeFormat)}
                            </span>
                            <ClockTimeElevenOutlineIcon className="margin-left-8 mb-0" size={15} />
                            <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                              {valueUpcomingAppointmentChartTemp.durationInHours}h
                            </span>
                          </div>
                          <div>
                            <HomeVariantIcon className="mb-0" size={15} />
                            <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                              {valueUpcomingAppointmentChartTemp.branchName}
                            </span>
                          </div>
                        </div>
                        <GeneralStatus
                          status={valueUpcomingAppointmentChartTemp.status}
                          statusColorFunction={appointmentStatusColor}
                          className={"align-items-center "} />
                      </Link>
                    </td>
                  </tr>
                )
                :
                <tr key={`${title}_empty`}>
                  <td data-toggle="collapse" className="react-bs-table-no-data">{intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: title })}</td>
                </tr>
            }
          </tbody>
        </Table>
      </div>
    )
  }

  const displayLocalTableWithTab = (blnOffMarginTop: boolean = false) => {
    return (
      <div className={blnSmallWindowSize && !blnOffMarginTop ? "margin-top-24" : ""}>
        <div className="h4 mb-3">{intl.formatMessage({ id: "MonthlyTop10Lists" })}</div>
        <div className="standard-layout" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
          <Nav className="nav nav-tabs nav-tabs-custom">
            {tabBar.map((value, indexTab) => (
              <NavItem key={`tabkey_${indexTab}`}>
                <NavLink
                  disabled={indexTab == 1 && (false)}
                  style={{ cursor: "pointer" }}
                  className={classNames({
                    active:
                      activeMonthlyTop10ListsTab ===
                      String(indexTab + 1),
                  })}
                  onClick={() => {
                    toggle(String(indexTab + 1), activeMonthlyTop10ListsTab, setActiveMonthlyTop10ListsTab);
                  }}
                >
                  {/* {value} */}
                  {returnTabBarIcon(value)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent
            activeTab={activeMonthlyTop10ListsTab}
            className="">
            {/* <TabPane tabId="1">
              <Col lg="12">
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', overflowY: 'scroll', height: '300px' }}>
                {
                  loadingTopCustomerSalesChart
                  ?
                  <Loading
                    fullHeightDisabled={true}/>
                  :
                  topCustomerSalesChart.length >  0
                  ?
                  returnTopSellingChartWithProgressBar(topCustomerSalesChart, "info", intl.formatMessage({ id: "TotalSales" }).toUpperCase(), "amount")
                  :
                  <ToolkitProvider
                    keyField={"id"}
                    data={topCustomerSalesChart}
                    columns={MonthlyOfTopClientsColumns}
                    bootstrap4
                    search>
                    {toolkitProps => (
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={true}
                        classes={"table align-middle margin-top-16"}
                        keyField={"id"}
                        noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: "Clients" })}
                        {...toolkitProps.baseProps}
                      />
                    )}
                  </ToolkitProvider>
                }
                </div>
              </Col>
            </TabPane> */}
            <TabPane tabId="1">
              <Col lg="12">
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', overflowY: 'scroll', height: '300px' }}>
                  {
                    loadingTopSellingServicesChart
                    ?
                    <Loading
                      fullHeightDisabled={true}/>
                    :
                    topSellingServicesChart.length > 0
                      ?
                      returnTopSellingChartWithProgressBar(topSellingServicesChart, "primary", intl.formatMessage({ id: "TotalPerformed" }).toUpperCase(), "count")
                      :
                      <ToolkitProvider
                        keyField={"id"}
                        data={topSellingServicesChart}
                        columns={MonthlyOfTopServicesColumns}
                        bootstrap4
                        search>
                        {toolkitProps => (
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={true}
                            classes={"table align-middle margin-top-16"}
                            keyField={"id"}
                            noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: "Services" })}
                            {...toolkitProps.baseProps}
                          />
                        )}
                      </ToolkitProvider>
                  }
                </div>
              </Col>
            </TabPane>
            <TabPane tabId="2">
              <Col lg="12">
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', overflowY: 'scroll', height: '300px' }}>
                  {
                    loadingTopSellingPackagesChart
                    ?
                    <Loading
                      fullHeightDisabled={true}/>
                    :
                    topSellingPackagesChart.length > 0
                      ?
                      returnTopSellingChartWithProgressBar(topSellingPackagesChart, "success", intl.formatMessage({ id: "TotalSold" }).toUpperCase(), "count")
                      :
                      <ToolkitProvider
                        keyField={"id"}
                        data={topSellingPackagesChart}
                        columns={MonthlyOfTopPackgesProductsColumns}
                        bootstrap4
                        search>
                        {toolkitProps => (
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={true}
                            classes={"table align-middle margin-top-16"}
                            keyField={"id"}
                            noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: "Packages" })}
                            {...toolkitProps.baseProps}
                          />
                        )}
                      </ToolkitProvider>
                  }
                </div>
              </Col>
            </TabPane>
            <TabPane tabId="3">
              <Col lg="12">
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', overflowY: 'scroll', height: '300px' }}>
                  {
                    loadingTopSellingProductsChart
                    ?
                    <Loading 
                      fullHeightDisabled={true}/>
                    :
                    topSellingProductsChart.length > 0
                      ?
                      returnTopSellingChartWithProgressBar(topSellingProductsChart, "warning", intl.formatMessage({ id: "TotalSold" }).toUpperCase(), "count")
                      :
                      <ToolkitProvider
                        keyField={"id"}
                        data={topSellingProductsChart}
                        columns={MonthlyOfTopPackgesProductsColumns}
                        bootstrap4
                        search>
                        {toolkitProps => (
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={true}
                            classes={"table align-middle margin-top-16"}
                            keyField={"id"}
                            noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: "Products" })}
                            {...toolkitProps.baseProps}
                          />
                        )}
                      </ToolkitProvider>
                  }
                </div>
              </Col>
            </TabPane>
          </TabContent>
        </div>
      </div>
    )
  }

  const displayLocalOrdersTable = (title: string, aryOrders: WeeklyPendingOrders[]) => {
    return (
      <div style={{ backgroundColor: 'white', borderRadius: '10px', overflowY: 'scroll', height: '303px' }}>
        <Table className="table mb-4" striped={true}>
          <tbody>
            {
              aryOrders.length > 0
                ?
                aryOrders.map((valuePendingOrdersChartTemp: WeeklyPendingOrders, index: number) =>
                  <tr key={`${title}_${index}`}>
                    <td>
                      <Link
                        to={returnPendingSignOrderLinkPath(valuePendingOrdersChartTemp.pendingSignType, valuePendingOrdersChartTemp.id)}
                        className="flex-justfity-content-center"
                        target={Constants.blank}
                        onClick={() => {
                        }}>
                        <div style={{ flex: 'auto', width: '100%' }}>
                          <Row>
                            <Col xs="6">
                              <span>{valuePendingOrdersChartTemp.orderNo}</span>
                              <div className="flex-align-items-center-without-height">
                                <CalendarDayIcon className="mb-0" size={15} />
                                <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                                  {moment(valuePendingOrdersChartTemp.performDateTime).format(Constants.displayDateAndTimeFormat)}
                                </span>
                                <AccountIcon className="margin-left-8 mb-0" size={15} />
                                <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                                  {valuePendingOrdersChartTemp.customerPreferredName}
                                </span>
                              </div>
                              <div>
                                <HomeVariantIcon className="mb-0" size={15} />
                                <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                                  {valuePendingOrdersChartTemp.branchName}
                                </span>
                              </div>
                              <div>
                                <CartCheckIcon className="mb-0" size={15} />
                                <span style={{ color: '#636c72' }} className="margin-left-4 margin-top-4">
                                  {valuePendingOrdersChartTemp.details}
                                </span>
                              </div>
                            </Col>
                            <Col xs="6">
                              <GeneralStatus
                              className="text-end mt-2"
                              blnDisableCenter
                              status={valuePendingOrdersChartTemp.pendingSignType === 0 ? intl.formatMessage({ id: "CustomerSalesOrder" }) : valuePendingOrdersChartTemp.pendingSignType == 1 ? intl.formatMessage({ id: "CustomerRedemption" }) : intl.formatMessage({ id: "RedemptionNote" })}
                              statusBackgroundColor={valuePendingOrdersChartTemp.pendingSignType === 0 ? Constants.darkorange : valuePendingOrdersChartTemp.pendingSignType == 1 ? Constants.lightblue : Constants.lavender}/>
                            </Col>
                          </Row>
                        </div>
                      </Link>
                    </td>
                  </tr>
                )
                :
                <tr key={`${title}_empty`}>
                  <td data-toggle="collapse" className="react-bs-table-no-data">{intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: title })}</td>
                </tr>
            }
          </tbody>
        </Table>
      </div>
    )
  }

  const returnSummaryWithTitle = (moduleName: string) => {
    return intl.formatMessage({ id: "ModuleWithSummary" }, { moduleName: intl.formatMessage({ id: moduleName }) })
  }

  const returnTabBarIcon = (valueTab: string) => {
    let iconTabBar: any = null;
    if (valueTab === intl.formatMessage({ id: "TopPackages" }).toUpperCase()) {
      iconTabBar = <PackageVariantClosedIcon size={Constants.menuIconSize} />;
    }
    else if (valueTab === intl.formatMessage({ id: "TopProducts" }).toUpperCase()) {
      iconTabBar = <ArrangeBringForwardIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "TopServices" }).toUpperCase()) {
      iconTabBar = <ClipboardEditIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "TopClients" }).toUpperCase()) {
      iconTabBar = <AccountCircleIcon size={Constants.menuIconSize} />
    }

    return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
  }

  const returnTopSellingChartWithProgressBar = (arrTemp, barColor: string, tableHeaderName, fieldName: string) => {
    let largestValueTemp = 0;
      for (let i = 0; i < arrTemp.length; i++) {
        if (largestValueTemp < arrTemp[i][fieldName]) {
          largestValueTemp = arrTemp[i][fieldName];
        }
      }

    return (
      <div className="table-responsive">
        <table className="table-striped table align-middle table-nowrap">
          <thead>
            <tr>
              <th style={{ width: "35%" }}>{intl.formatMessage({ id: "Name" }).toUpperCase()}</th>
              <th>{tableHeaderName}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              arrTemp.map((item, key) => (
                <tr key={key}>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{item.name}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{fieldName === "amount" ? returnPriceWithCurrency(item[fieldName]) : item[fieldName] }</h5>
                  </td>
                  <td>
                    <Progress
                      value={(item[fieldName] / largestValueTemp * 100).toFixed()}
                      color={barColor}
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }

  const returnTableCellUIHeightValue = () => {
    if(blnSmallWindowSize){
      return '40.5px';
    }else{
      return '34.5px';
    }
  }

  return (
    <div>
      <div className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Dashboard" }) })}</title>
        </MetaTags>
        {
          loading
            ?
            <Loading />
            :
            <Container fluid>
              <Row className={blnSmallWindowSize ? "margin-bottom-8" : "margin-bottom-28"}>
                <Col xl="12">
                  {
                    displayLocalTable(intl.formatMessage({ id: "DailySalesSummary" }), "branchName", DailySalesSummaryColumns, dailySalesSummaryChart, loadingDailySalesSummaryChart, false, "400px", false, true, "branchColorCode")
                  }
                </Col>
              </Row>
              <Row className={blnSmallWindowSize ? "margin-bottom-32 margin-top-32" : "margin-bottom-28"}>
                <Col xl="6">
                  {
                    displayLocalTable(intl.formatMessage({ id: "SalesByEmployeeSummary" }), "name", SalesByEmployeeSummaryColumns, salesByEmployeeSummary, loadingSalesByEmployeeSummary)
                  }
                </Col>
                <Col xl="6">
                  <ChartWithTitle
                    column_xl={'12'}
                    column_sm={'11'}
                    heading_txt={""}
                    body_txt={""}
                    headerPositionTop={true}
                    blnOffMarginTop={true}
                    content={displayLocalOrdersTable(intl.formatMessage({ id: "WeeklyPendingSignOrders" }), weeklyPendingOrdersChart)}
                    contentTopOfScope={<div className="h4 mb-3">{intl.formatMessage({ id: "WeeklyPendingSignOrders" })}</div>}
                    loading={loadingWeeklyPendingOrdersChart} />
                </Col>
              </Row>
              <Row className={blnSmallWindowSize ? "margin-bottom-8" : "margin-bottom-28"}>
                <Col xl="6">
                  {
                    displayLocalTable(intl.formatMessage({ id: "ReferralSpendingSummary" }), "branchName", ReferralSpendingSummaryColumns, referralSpendingSummaryChart, loadingReferralSpendingSummary, false, blnSubBrandExist ? "687px" : "739px", false)
                  }
                </Col>
                <Col xl="6" className={blnSmallWindowSize ? "margin-top-32" : blnSubBrandExist ? "margin-top-7" : ""}>
                    {/* SalesPaymentSummary */}
                    {
                      displayLocalTable(intl.formatMessage({ id: "SalesPaymentSummary" }), "branchName", SalesPaymentSummaryColumns, salesPaymentSummaryChart, loadingSalesPaymentSummaryChart)
                    }
                    <div className="margin-top-16">
                      <ChartWithTitle
                        column_xl={'12'}
                        column_sm={'11'}
                        heading_txt={salesCategorySummaryChart?.dashboardTitle ? returnPriceWithCurrency(salesCategorySummaryChart?.dashboardTitle) : salesCategorySummaryChart?.totalItems ? returnPriceWithCurrency(salesCategorySummaryChart?.totalItems) : ""}
                        body_txt={intl.formatMessage({ id: "SalesByCategory" })}
                        headerPositionTop={true}
                        content={displayChart(salesCategorySummaryChart)}
                        contentTopOfScope={<div className="h4 mb-3">{returnSummaryWithTitle("SalesByCategory")}</div>}
                        blnOffMarginTop
                        loading={loadingSalesCategorySummaryChart}
                      />
                    </div>
                  </Col> 
              </Row>
              <Row className={blnSmallWindowSize ? "margin-bottom-8" : "margin-bottom-28"}>
                <Col xl="6" className={blnSmallWindowSize ? "margin-top--20" : ""}>
                  <ChartWithTitle
                    column_xl={'12'}
                    column_sm={'11'}
                    heading_txt={appointmentCountChart?.dashboardTitle || appointmentCountChart?.totalItems || ""}
                    body_txt={intl.formatMessage({ id: "AppointmentCount" })}
                    headerPositionTop={true}
                    content={displayChart(appointmentCountChart)}
                    contentTopOfScope={<div className="h4 mb-3">{returnSummaryWithTitle("AppointmentCount")}</div>}
                    loading={loadingAppointmentCountChart} />
                </Col>
                <ChartWithTitle
                    column_xl={'6'}
                    column_sm={'11'}
                    heading_txt={newCustomerCountChart?.dashboardTitle || newCustomerCountChart?.totalItems || ""}
                    headerStyle={{marginTop: blnSmallWindowSize ? "24px" : ""}}
                    body_txt={intl.formatMessage({ id: "NewCustomerCount" })}
                    headerPositionTop={true}
                    content={displayChart(newCustomerCountChart)}
                    blnOffMarginTop={true}
                    contentTopOfScope={<div className="h4 mb-3">{returnSummaryWithTitle("NewCustomerCount")}</div>}
                    loading={loadingNewCustomerCountChart}/>
              </Row>
              <Row className={blnSmallWindowSize ? "margin-bottom-8" : "margin-bottom-28"}>
                <Col xl="6" style={{marginTop: blnSmallWindowSize ? "24px" : ""}}>
                  {displayLocalTable(intl.formatMessage({ id: "CustomerBirthday" }), "customerId", DailyCustomerBirthdayColumns, dailyCustomerBirthdayChart, loadingDailyCustomerBirthdayChart, false, "376px")}
                </Col>
                <Col xl="6" className={blnSmallWindowSize ? "margin-top-6" : ""}>
                  {displayLocalTableWithTab()}
                </Col>
              </Row>
            </Container>
        }
      </div>
      {
        dsrModal
        &&
        <DSRModal
          blnShow={dsrModal}
          setModal={setDSRModal}
          title={intl.formatMessage({ id: "DailySalesSummary" })}
          childen={()=> (displayLocalTable(intl.formatMessage({ id: "DailySalesSummary" }), "branchName", DailySalesSummaryColumns, dailySalesSummaryChart, loadingDailySalesSummaryChart, false, "400px", true, true, "branchColorCode"))}/>
      }
      {
        rssModal
        &&
        <DSRModal
          blnShow={rssModal}
          setModal={setRSSModal}
          title={intl.formatMessage({ id: "ReferralSpendingSummary" })}
          childen={()=> (displayLocalTable(intl.formatMessage({ id: "ReferralSpendingSummary" }), "branchName", ReferralSpendingSummaryColumns, referralSpendingSummaryChart, loadingReferralSpendingSummary, false, "400px", true))}/>
      }
      {
        blnShowPendingSignModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "PendingSignOrdersAlert" })}`}
          primaryActionTitle={`Close`}
          showPrompt={blnShowPendingSignModal}
          setShowPrompt={setBlnShowPendingSignModal}
          blnHideCancelButton={true}
          blnShowCloseButton={true}
          onPrimaryClick={()=> {}}
        />
      }
      {
        blnShowPendingCloseCounterModal
        &&
        <PendingCloseCounterModal 
          blnShow={blnShowPendingCloseCounterModal}
          setModal={setBlnShowPendingCloseCounterModal}
          pendingCloseCounterDetail={pendingCloseCounterDetail!}
          disabledCloseFunction={false}/>
      }
    </div>
  )
}

export default observer(Dashboard);