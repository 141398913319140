import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, capitalizeFirstLetter, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { useLocation } from 'react-router-dom';
import { BranchListObject } from "../../../app/models/branch";

const SalesByCategory = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { salesByCategoryList, getSalesByCategoryList, setSalesByCategoryList, exportSalesByCategory } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [resultCategory, setResultCategory] = useState("");
  const location = useLocation();
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesByCategory() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);

      let aryPath = location.pathname.split('/');
      let resultCategoryTemp = "";
      if (aryPath.length > 2) {
        if(aryPath[2]){
          resultCategoryTemp = aryPath[2].slice(6);
          setResultCategory(resultCategoryTemp)
          displayTableContent(resultCategoryTemp);
        }
      }
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSalesByCategory();
    return (() => {
      setSalesByCategoryList([]);
    })
  }, [])

  const fetchSalesByCategoryWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportSalesByCategory({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate}, resultCategory)
    }else{
      await getSalesByCategoryList({ branchId: selectedBranchId, dateRange: selectedDateRange , category: resultCategory});
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const SalesByColumns = [
    {
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "name",
      text: newSpaceBeforeCapitalLetter(resultCategory).toUpperCase(),
    },
    {
      dataField: "totalEarnedSales",
      text: intl.formatMessage({ id: "EarnedSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalEarnedSales)}
        </div>
      )
    },
    {
      dataField: "totalPaymentReceived",
      text: intl.formatMessage({ id: "PaymentReceived" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalPaymentReceived)}
        </div>
      )
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalAmount)}
        </div>
      )
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: "300px" },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDynamicSalesDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.dynamicSalesDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];
  const [salesByColumnsFinal, setSalesByColumnsFinal] = useState<any[]>(SalesByColumns);

  const displayTableContent = (resultCategoryTemp:any) => {
    let salesByColumnTemp: any[] = [];
    if (resultCategoryTemp !== 'employee') {  
      salesByColumnsFinal.map((value, index) => {
        if (value.dataField !== 'totalPaymentReceived') {
          salesByColumnTemp.push({
            ...value,
            text: value.dataField == 'name' ? newSpaceBeforeCapitalLetter(resultCategoryTemp).toUpperCase() : value.text
          })
        }
      })
      setSalesByColumnsFinal(salesByColumnTemp)
    }
    else{
      salesByColumnsFinal.map((value, index) => {
        salesByColumnTemp.push({
            ...value,
            text: value.dataField == 'totalAmount' ? intl.formatMessage({ id: "InvoiceSales" }).toUpperCase() : value.dataField == 'name' ? newSpaceBeforeCapitalLetter(resultCategoryTemp).toUpperCase() : value.text,
        })
      })
      setSalesByColumnsFinal(salesByColumnTemp)
    }
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={`${intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: `SalesBy${capitalizeFirstLetter(resultCategory)}`  })  })} `}
        title={`${intl.formatMessage({ id: "SalesBy" })} ${newSpaceBeforeCapitalLetter(capitalizeFirstLetter(resultCategory))}`}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchSalesByCategoryWithLoading(true)
            }}
          />
        }
        hyperlink={resultCategory === Constants.service.toLowerCase() ? `https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-By-Service.aspx` : ""}>
        <Row>
            <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchSalesByCategoryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={salesByCategoryList.length}
              tableColumns={salesByColumnsFinal}
              data={salesByCategoryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Sales" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              keyField={"name"}
              requestAPI={getSalesByCategoryList}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesByCategory);
