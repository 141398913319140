import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getMonths, getYears, returnSubBrandList } from '../../../app/common/function/function';
import { PermissionConstants } from '../../../app/constants/PermissionConstants';
import { RoutesList } from '../../../app/constants/RoutesList';
import { Constants } from '../../../app/constants/Constants';
import { Col, Label, Row, Nav, NavItem, NavLink } from 'reactstrap';
import moment from 'moment';
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import MyButton from '../../../app/components/form/MyButton';
import { useStore } from '../../../app/stores/store';
import GeneralSelectDropdown from '../../../app/components/form/GeneralSelectDropdown';
import { ObjectForDropdown } from '../../../app/models/common';
import { SubBrandObject } from '../../../app/models/brand';
import Loading from '../../../app/components/loading/Loading';
import AccountMultipleCheck from 'mdi-react/AccountMultipleCheckIcon';
import CardInfo from '../../../app/components/form/CardInfo';
import classNames from "classnames";
import PaginationTableWithoutApi from '../../../app/components/table/PaginationTableWithoutApi';

const NewVisitVsTurnUp = () => {
    const intl = useIntl();
    const { commonStore, branchStore, reportMarcomStore } = useStore();
    const { windowSize, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
    const { branchDropDownList, getBranchDropDown } = branchStore;
    const { newVisitVsTurnUpDetail, setNewVisitVsTurnUpDetail, getNewVisitVsTurnUpDetail, exportNewVisitVsTurnUpList } = reportMarcomStore;

    const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
    const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
    const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
    const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
    const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
    const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
    const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
    const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
    const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
    const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
    const [localLoading, setLocalLoading] = useState(false);
    const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
    const [activeTab, setActiveTab] = useState("1");
    const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "TotalNewVisit" }).toUpperCase(), intl.formatMessage({ id: "TotalTurnUp" }).toUpperCase()]);
    const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
    const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);
    const column_xl = subBrandListFinal.length > 0 ? "3" : "4";

    //Default Sorting Type
    const defaultSorted = [
        // {
        //   dataField: "id",
        //   order: "desc",
        // },
    ];

    useEffect(() => {
        setYearList(getYears());
        setMonthList(getMonths());

        async function fetchNewVisitVsTurnUpSummary() {
            setLoading(true);

            if (returnSubBrandList().length > 0) {
                setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
            }
            let aryAPI: any = [
                getBranchDropDown()
            ];
            await Promise.all(aryAPI);
            setLoading(false);
        }

        if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
            return;
        }

        fetchNewVisitVsTurnUpSummary();

        return (() => {
            setNewVisitVsTurnUpDetail(undefined);
        })
    }, [])

    const fetchNewVisitVsTurnUpWithLoading = async (blnExport: boolean) => {
        if (!selectedBranchId) {
            setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
            return;
        }
        setLocalLoading(true);
        if (blnExport) {
            await exportNewVisitVsTurnUpList({ subBrandId: selectedSubBrandId, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue });
        }
        else {
            await getNewVisitVsTurnUpDetail({ subBrandId: selectedSubBrandId, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue });
        }
        setLocalLoading(false);
    };

    const toggle = (tab: string) => {
        setLocalLoading(true)
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        setTimeout(() => {
            setLocalLoading(false)
        }, 1000)
    };

    //Table Content UI  
    const NewVisitVsTurnUpColumns = [
        {
            dataField: "subBrandName",
            text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
            hidden: subBrandListFinal.length < 1 ? true : false
        },
        {
            dataField: "branchName",
            text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
        },
        {
            dataField: "customerNo",
            text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    {
                        blnPermissionViewCustomer
                            ?
                            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 margin-left-4">{row.customerNo || Constants.emptyData} </Label>
                            </Link>
                            :
                            <div>{row.customerNo || Constants.emptyData}</div>
                    }
                </>
            )
        },
        {
            dataField: "customerName",
            text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    {
                        blnPermissionViewCustomer
                            ?
                            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 margin-left-4">{row.customerName || Constants.emptyData} </Label>
                            </Link>
                            :
                            <div>{row.customerName || Constants.emptyData}</div>
                    }
                </>
            )
        },
        {
            dataField: "mediaSource",
            text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
        },
        {
            dataField: "joinedDate",
            text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    <div className="">
                        {`${moment(row.joinedDate).format(Constants.textInputDateFormat)}`}
                    </div>
                </>
            ),
        },
        {
            dataField: "lastVisitDate",
            text: intl.formatMessage({ id: "LastVisitDate" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    <div className="">
                        {`${moment(row.lastVisitDate).format(Constants.textInputDateFormat)}`}
                    </div>
                </>
            ),
        },
        {
            dataField: "mobileNo",
            text: intl.formatMessage({ id: "MobileNo" }).toUpperCase()
        },
        {
            dataField: "email",
            text: intl.formatMessage({ id: "Email" }).toUpperCase()
        },
        {
            dataField: "customerType",
            text: intl.formatMessage({ id: "CustomerType" }).toUpperCase()
        }
    ];

    return (
        <div>
            <ListViewLayout
                metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "NewVisitVsTurnUp" }) })}
                title={intl.formatMessage({ id: "NewVisitVsTurnUp" })}
                breadCrumbList={breadCrumbList}
                addButton={
                    blnPermissionExportMarcomReport
                    &&
                    <MyButton
                        type="button"
                        class="btn btn-success"
                        content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                        disable={localLoading || loading}
                        onClick={() => {
                            fetchNewVisitVsTurnUpWithLoading(true)
                        }} />
                }
                hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/New-Visit-VS-Turn-Up.aspx`}>
                <div className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "flex-align-items-center-without-height" : ""}`}>
                    <Row className={`flex-1`}>
                        {
                            subBrandListFinal.length > 0
                            &&
                            <Col xl="3">
                                <GeneralSelectDropdown
                                    title={intl.formatMessage({ id: "SubBrand" })}
                                    name="subBrand"
                                    options={subBrandListFinal}
                                    labelField="name"
                                    valueField="id"
                                    initialLabel={selectedSubBrandName}
                                    initialValue={selectedSubBrandId}
                                    disabled={localLoading || loading}
                                    setFieldLabel={setSelectedSubBrandName}
                                    setFieldValue={setSelectedSubBrandId}
                                    onChange={(value) => {
                                        setErrorMessage("");
                                        setSuccessMessage("");
                                    }}
                                    validationRequired={true}
                                />
                            </Col>
                        }
                        <Col xl={column_xl}>
                            <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "Branch" })}
                                name="branchName"
                                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                                options={branchDropDownList}
                                labelField={"name"}
                                valueField={"id"}
                                initialLabel={selectedBranchName}
                                initialValue={selectedBranchId}
                                disabled={localLoading || loading || getBranchUser()}
                                setFieldLabel={setSelectedBranchName}
                                setFieldValue={setSelectedBranchId}
                                validationRequired={true}
                            />
                        </Col>
                        <Col xl={column_xl}>
                            <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "Years" })}
                                name="years"
                                options={yearList}
                                initialLabel={selectedYear}
                                initialValue={selectedYear}
                                setFieldValue={setSelectedYear}
                                disabled={localLoading || loading}
                                validationRequired={true}
                            />
                        </Col>
                        <Col xl={column_xl}>
                            <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "Month" })}
                                name="months"
                                options={monthList}
                                initialLabel={selectedMonth}
                                initialValue={selectedMonthValue}
                                disabled={localLoading || loading}
                                setFieldLabel={setSelectedMonth}
                                setFieldValue={setSelectedMonthValue}
                                validationRequired={true}
                            />
                        </Col>
                    </Row>
                    <MyButton
                        type="button"
                        class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-14 margin-left-16" : "margin-bottom-14"}`}
                        content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                        loading={localLoading || loading}
                        disable={localLoading || loading}
                        onClick={() => {
                            fetchNewVisitVsTurnUpWithLoading(false)
                        }}
                    />
                    <div style={{ width: "100px" }}></div>
                </div>
                {
                    localLoading
                        ?
                        <Loading />
                        :
                        <>
                            {
                                newVisitVsTurnUpDetail
                                &&
                                <>
                                    <Row className="padding-top-10">
                                        <Col xl="4" lg="6" sm="6" xs="12">
                                            <CardInfo
                                                icon={() => <AccountMultipleCheck className="h2 text-primary mb-0" />}
                                                title={intl.formatMessage({ id: "TotalNewVisit" }).toUpperCase()}
                                                value={`${newVisitVsTurnUpDetail.totalNewVisit}`}
                                                cardColor={"card-red"} />
                                        </Col>
                                        <Col xl="4" lg="6" sm="6" xs="12">
                                            <CardInfo
                                                icon={() => <AccountMultipleCheck className="h2 text-warning mb-0" />}
                                                title={intl.formatMessage({ id: "TotalTurnUp" }).toUpperCase()}
                                                value={`${newVisitVsTurnUpDetail.totalTurnUp}`}
                                                cardColor={"card-red"} />
                                        </Col>
                                    </Row>
                                    <div style={{ marginBottom: "-20px" }} className="standard-layout">
                                        <Nav className="nav nav-tabs nav-tabs-custom">
                                            {tabBar.map((value, indexTab) => (
                                                <NavItem key={`tabkey_${indexTab}`}>
                                                    <NavLink
                                                        disabled={indexTab == 1 && (false)}
                                                        style={{ cursor: "pointer" }}
                                                        className={classNames({
                                                            active:
                                                                activeTab ===
                                                                String(indexTab + 1),
                                                        })}
                                                        onClick={() => {
                                                            toggle(String(indexTab + 1));
                                                        }}
                                                    >
                                                        {value}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </div>
                                </>

                            }
                            <div className="standard-layout">
                                <PaginationTableWithoutApi
                                    title={intl.formatMessage({ id: activeTab === "1" ? "TotalNewVisit" : "TotalTurnUp" })}
                                    keyField={"customerId"}
                                    columns={NewVisitVsTurnUpColumns}
                                    pageSize={Constants.defaultPageSize}
                                    options={newVisitVsTurnUpDetail ? activeTab === "1" ? newVisitVsTurnUpDetail.newVisitDetails : newVisitVsTurnUpDetail.turnUpDetails : []}
                                    blnDisplayForMainListing={true} />
                            </div>
                        </>
                }
            </ListViewLayout>
        </div>
    );
}

export default observer(NewVisitVsTurnUp);