import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { PaymentMethodListObject } from '../../app/models/paymentMethod';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const PaymentMethod = () => {
  const intl = useIntl();
  //Use Store
  const { paymentMethodStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath } = commonStore;
  const { paymentMethodList, paymentMethodPaginationParams, getPaymentMethod, deletePaymentMethod, setPaymentMethodPaginationParams } = paymentMethodStore; 

  //Data List
  const [selectedRow, setSelectedRow] = useState<PaymentMethodListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreatePaymentMethod = checkPermission([PermissionConstants.CreatePaymentMethod]);
  const blnPermissionUpdatePaymentMethod = checkPermission([PermissionConstants.UpdatePaymentMethod]);
  const blnPermissionDeletePaymentMethod = checkPermission([PermissionConstants.DeletePaymentMethod]);

  let paymentMethodRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchPaymentMethodAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.paymentMethod)
      if (blnBackNavigation) {
        if (paymentMethodPaginationParams) {
          setCurrentPage(paymentMethodPaginationParams.pageNumber)
          paymentMethodRequestBody = paymentMethodPaginationParams;
        }
      }
      else {
        setPaymentMethodPaginationParams(undefined);
      }
      await getPaymentMethod(paymentMethodRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManagePaymentMethod], true)) {
      return;
    }

    fetchPaymentMethodAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.paymentMethod)
        if (!blnSetParams) {
          setPaymentMethodPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row : PaymentMethodListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const PaymentMethodColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text:  intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '70%'
      },
    },
    {
      dataField: "isAllowEPP",
      text:  intl.formatMessage({ id: "AllowEPP" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isAllowEPP ? <div className="rounded-circle green-circle"/> : <div className="rounded-circle red-circle"/>}
        </div>
      ),
    },
    {
      dataField: "isActive",
      text:  intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle"/> : <div className="rounded-circle red-circle"/>}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePaymentMethod, PermissionConstants.UpdatePaymentMethod, PermissionConstants.DeletePaymentMethod]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.paymentMethod}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdatePaymentMethod
              &&
              <TableActionButton 
                to={`/${RoutesList.paymentMethod}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeletePaymentMethod
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentMethod" }) })}
        title={intl.formatMessage({ id: "PaymentMethod" })}
        addButton={
          blnPermissionCreatePaymentMethod
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PaymentMethod" }) })}
            onClick={() => { history.push(`/${RoutesList.paymentMethod}/add`) }}
            linkTo={`/${RoutesList.paymentMethod}/add`}
            disable={loading} />
        }>
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={PaymentMethodColumns}
            data={paymentMethodList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "PaymentMethod"})})}
            objSorted={defaultSorted}
            requestAPI={getPaymentMethod}/>
          <DeleteModal
            displayName={selectedRow?.name}
            onDeleteClick={async ()=> {
              setLoading(true);
              await deletePaymentMethod(selectedRow?.id || "", selectedRow?.name || "");
              let currentPageTemp = currentPage;
              if (paymentMethodList.length === 1 && currentPage !== 1) {
                setCurrentPage((value)=> value-1)
                currentPageTemp = currentPageTemp-1;
              }
              await getPaymentMethod({pageNumber: currentPageTemp, pageSize: pageSize})
              setLoading(false);
            }}/>
</ListViewLayout>
    </div>
  );
}

export default observer(PaymentMethod);
