import { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Label,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { AddOptionsValueField, newSpaceBeforeCapitalLetter, returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";
import Loading from "../../../app/components/loading/Loading";
import { PaymentMethodListObject } from "../../../app/models/paymentMethod";

interface Props {
  blnShow: boolean;
  setModal: Function;
  cardType: StaticReferenceDataObject[];
  branchPaymentMethod: PaymentMethodListObject[];
  selectedSettlementDetailsIndex: number | undefined;
  setSelectedSettlementDetailsIndex: Function;
  getPaymentMethodOptionDropdown: Function;
  validation: any;
}

export default function SettlementDetailsModal(props: Props) {
  const intl = useIntl();
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);

  let selectedIndex = props.selectedSettlementDetailsIndex ? props.selectedSettlementDetailsIndex-1 : -1
  const [state, setState] = useState(
    {
      id: "",
      paymentMethodId: "",
      paymentMethodName: "",
      paymentMethodOptionId: undefined,
      optionName: "",
      cardType: "",
      amount: 0,
      isCardInput: false,
      isQRPay: false,
      optionTypeTitle: "",
      optionTypeList: []
    }
  );

  const errorStateDefault = {
    cardType: false,
    amount: false,
    paymentMethodName: false,
    paymentMethodOptionId: false,
  }

  const [errorState, setErrorState] = useState(_.cloneDeep(errorStateDefault))
  const optionsPaymentMethod = _.cloneDeep(AddOptionsValueField(props.branchPaymentMethod, "name", "id"));
  const optionsCardType = AddOptionsValueField(props.cardType, "displayValue", "displayValue");
  const cardValid = state.isCardInput

  useEffect(()=> {
    async function getOptionPaymentMethod() {
      if (selectedIndex > -1) {
        setLoadingPaymentMethod(true);
        let selectedSettlementDetailsTemp = {..._.cloneDeep(props.validation.values.closeCounterSettlements[selectedIndex]), optionTypeTitle: "", optionTypeList: []};
        let indexPaymentMethodTemp = _.findIndex(props.branchPaymentMethod, { id: selectedSettlementDetailsTemp.paymentMethodId });
        if (indexPaymentMethodTemp > -1) {
          let selectedPaymentMethodTemp = props.branchPaymentMethod[indexPaymentMethodTemp];
          if (selectedPaymentMethodTemp.isOptionAvailable && selectedPaymentMethodTemp.optionType !== Constants.none) {
            let resultOptionType = await props.getPaymentMethodOptionDropdown(selectedPaymentMethodTemp.id);
            selectedSettlementDetailsTemp.optionTypeTitle = newSpaceBeforeCapitalLetter(selectedPaymentMethodTemp.optionType)
            selectedSettlementDetailsTemp.optionTypeList = AddOptionsValueField(resultOptionType, "name", "id")
          }
          selectedSettlementDetailsTemp.isCardInput = selectedPaymentMethodTemp.isCardInput && selectedPaymentMethodTemp.isAllowEPP;
          selectedSettlementDetailsTemp.isQRPay = selectedPaymentMethodTemp.isQRPay;
        }
        setState(selectedSettlementDetailsTemp);
        setLoadingPaymentMethod(false);
      }
    }

    getOptionPaymentMethod();
  }, [])

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedSettlementDetailsIndex(undefined);
  }

  const onChangePaymentMethod = async (e: any) => {
    setLoadingPaymentMethod(true);
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodId = e.id;
    stateTemp.paymentMethodName = e.name;
    stateTemp.cardType = "";
    stateTemp.optionName = "";
    stateTemp.paymentMethodOptionId = undefined;
    stateTemp.isCardInput = e.isCardInput && e.isAllowEPP;
    stateTemp.isQRPay = e.isQRPay;
    stateTemp.optionTypeTitle = "";
    stateTemp.optionTypeList = [];

    if (e.isOptionAvailable && e.optionType !== Constants.none) {
      let resultOptionType = await props.getPaymentMethodOptionDropdown(e.id);
      stateTemp.optionTypeTitle = newSpaceBeforeCapitalLetter(e.optionType)
      stateTemp.optionTypeList = AddOptionsValueField(resultOptionType, "name", "id")
    }

    setState(stateTemp);
    setLoadingPaymentMethod(false);
  }

  const onChangeCardType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.cardType = e.displayValue;
    setState(stateTemp);
  }

  const onChangeOptionType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.optionName = e.label;
    stateTemp.paymentMethodOptionId = e.value;

    setState(stateTemp);
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "SettlementDetails" })}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          loadingPaymentMethod
          ?
          <Loading className="margin-bottom-16" />
          :
          <>
            <div>
              <Label>
                <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "PaymentMethod" })}</Label>
              </Label>
              <GeneralSelectDropdown
                title=""
                name=""
                className={errorState.paymentMethodName ? "mb-0" : "mb-3"}
                options={optionsPaymentMethod}
                onChange={onChangePaymentMethod}
                blnReturnFullValue={true}
                onBlur={()=> {
                  if (errorState.paymentMethodName) {
                    setErrorState((value) => ({...value, paymentMethodName: false}))
                  }
                }}
                initialLabel={state.paymentMethodName}
                initialValue={state.paymentMethodId}
                validationRequired={true}
              />
              {errorState.paymentMethodName ? (
                <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "PaymentMethod"})})}`}</FormFeedback>
              ) : null}
            </div>
            {
              cardValid
              &&
              <Row>
                <Col>
                  <Label>
                    <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                    <Label className="margin-bottom-0">{intl.formatMessage({ id: "CreditCardType" })}</Label>
                  </Label>
                  <GeneralSelectDropdown
                    title=""
                    name=""
                    className={errorState.cardType ? "mb-0" : "mb-3"}
                    options={optionsCardType}
                    blnValueWithNewSpace={true}
                    blnReturnFullValue={true}
                    onChange={onChangeCardType}
                    onBlur={()=> {
                      if (errorState.cardType) {
                        setErrorState((value) => ({...value, cardType: false}))
                      }
                    }}
                    initialLabel={state.cardType}
                    initialValue={state.cardType}
                    validationRequired={true}
                  />
                  {errorState.cardType ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "CardType"})})}`}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            }
            {
              state.optionTypeList.length > 0
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{state.optionTypeTitle}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodOptionId ? "mb-0" : "mb-3"}
                  options={state.optionTypeList}
                  blnReturnFullValue={true}
                  onChange={onChangeOptionType}
                  onBlur={()=> {
                    if (errorState.paymentMethodOptionId) {
                      setErrorState((value) => ({...value, paymentMethodOptionId: false}))
                    }
                  }}
                  initialLabel={state.optionName}
                  initialValue={state.paymentMethodOptionId}
                  validationRequired={true}
                />
                {errorState.paymentMethodOptionId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: state.optionTypeTitle})}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              <Row>
                <Col xl={12}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "Amount" })}
                    name="amount"
                    className={errorState.amount ? "mb-0" : "mb-3"}
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.amount) {
                        setErrorState((value) => ({...value, amount: false}))
                      }
                    }}
                    blnDisableNegativeValue={true}
                  />
                  {errorState.amount ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Amount"})})}`}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            }
          </>
        }
        <div className="">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({ id: "Save" })}
            disable={loadingPaymentMethod}
            onClick={()=> {
              let errorStateTemp = _.cloneDeep(errorStateDefault);

              let blnErrorFound = false;
              if (!state.paymentMethodName) {
                errorStateTemp.paymentMethodName = true;
                blnErrorFound = true;
              }

              if (cardValid) {
                if (!state.cardType) {
                  errorStateTemp.cardType = true;
                  blnErrorFound = true;
                }
              }

              if (!state.amount) {
                errorStateTemp.amount = true;
                blnErrorFound = true;
              }

              if (state.optionTypeList.length > 0 && !state.paymentMethodOptionId) {
                errorStateTemp.paymentMethodOptionId = true;
                blnErrorFound = true;
              }

              state.amount = Number(state.amount);

              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                let settlementDetailssTemp = _.cloneDeep(props.validation.values.closeCounterSettlements);
                if (selectedIndex > -1) {
                  settlementDetailssTemp[selectedIndex] = state;
                }
                else {
                  settlementDetailssTemp.push(state);
                }
                settlementDetailssTemp = settlementDetailssTemp.map((valueSettlementDetailsTemp, indexSettlementDetailsTemp)=> ({
                  ...valueSettlementDetailsTemp,
                  randomId: `settlements_index_${indexSettlementDetailsTemp}`
                }))
                props.validation.setFieldValue("closeCounterSettlements", settlementDetailssTemp)
                hideModal();
              }
            }}/>
          </div>
        </div>
      </Modal>
  )
}