import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import { StockAdjustmentDetailObject, StockAdjustmentListObject } from "../../app/models/stockAdjustment";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { stockAdjustmentStatusColor, checkPermission, stockAdjustmentEditAvailable, stockAdjustmentViewAvailable, comparePathForPagination, comparePathForSetParams, getBranchUser, getBranchName, getBranchId, arraySpliceInAllValue } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import MyButton from "../../app/components/form/MyButton";
import PrinterIcon from "mdi-react/PrinterIcon";
import Borderline from "../../app/components/form/BorderLine";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";

const StockAdjustment = () => {
  const intl = useIntl();
  //Use Store
  const { stockAdjustmentStore, staticReferenceStore, commonStore, branchStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { stockAdjustmentList, stockAdjustmentPaginationParams, stockAdjustmentPrintAllList, getStockAdjustment, getStockAdjustmentPrintAll, deleteStockAdjustment, setStockAdjustmentPaginationParams, exportStockAdjustment, setStockAdjustmentPrintAllList } = stockAdjustmentStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  
  //Data List
  const [selectedRow, setSelectedRow] = useState<StockAdjustmentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedStockAdjustmentStatus, setSelectedStockAdjustmentStatus] = useState<string>(AllStatus);
  const [selectedStockAdjustmentStatusId, setSelectedStockAdjustmentStatusId] = useState<number | string | undefined>(" ");
  const [stockAdjustmentStatusList, setStockAdjustmentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: "", toDate: ""});
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const blnPermissionCreateStockAdjustment = checkPermission([PermissionConstants.CreateStockAdjustment]);
  const blnPermissionUpdateStockAdjustment = checkPermission([PermissionConstants.UpdateStockAdjustment]);
  const blnPermissionDeleteStockAdjustment = checkPermission([PermissionConstants.DeleteStockAdjustment]);
  const blnPermissionReviewStockAdjustment = checkPermission([PermissionConstants.ReviewStockAdjustment]);
  const blnPermissionPrintAllStockAdjustment = checkPermission([PermissionConstants.PrintAllStockAdjustment]);

  let stockAdjustmentRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    stockAdjustmentStatus: selectedStockAdjustmentStatusId,
    stockAdjustmentStatusName: selectedStockAdjustmentStatus,
    dateRange: selectedDateRange,
    branchId: selectedBranchId,
    branchName: selectedBranchName
  }
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchStockAdjustmentListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.stockAdjustment)
      if (blnBackNavigation) {
        if (stockAdjustmentPaginationParams) {
          setCurrentPage(stockAdjustmentPaginationParams.pageNumber)
          setSelectedBranchId(stockAdjustmentPaginationParams.branchId || "")
          setSelectedBranchName(stockAdjustmentPaginationParams.branchName || "")
          setSelectedStockAdjustmentStatus(stockAdjustmentPaginationParams.stockAdjustmentStatusName || "");
          setSelectedStockAdjustmentStatusId(stockAdjustmentPaginationParams.stockAdjustmentStatus)
          setSelectedDateRange(stockAdjustmentPaginationParams.dateRange || {fromDate: "", toDate: ""});
          stockAdjustmentRequestBody = stockAdjustmentPaginationParams;
        }
      }
      else {
        setStockAdjustmentPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchStockAdjustmentAPI(false),
        getStaticReferenceWithType(Constants.stockAdjustmentStatus, AllStatus),
        getBranchDropDown(),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setStockAdjustmentStatusList(resultAPI[1]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[2], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockAdjustment], true)) {
      return;
    }

    fetchStockAdjustmentListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.stockAdjustment)
        if (!blnSetParams) {
          setStockAdjustmentPaginationParams(undefined);
        }
      }
      setStockAdjustmentPrintAllList([]);
    })
  }, []);

  useEffect(() => {
    if (
      (selectedStockAdjustmentStatusId || selectedStockAdjustmentStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchStockAdjustmentAPI(true);
    }
  }, [selectedStockAdjustmentStatusId, selectedDateRange, selectedBranchId]);

  async function fetchStockAdjustmentAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockAdjustment(stockAdjustmentRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportStockAdjustmentAPI() {
    setLocalLoading(true);
    await exportStockAdjustment({...stockAdjustmentRequestBody, status: selectedStockAdjustmentStatusId});
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickDelete = async (row: StockAdjustmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  //Table Content UI
  const StockAdjustmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockAdjustmentNo",
      text: `${intl.formatMessage({ id: "StockAdjustmentNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "stockAdjustDate",
      text: intl.formatMessage({ id: "StockAdjustDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.stockAdjustDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.stockAdjustDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockAdjustmentStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.stockAdjustment}/view/${row.id}`}
              type={`view`}/>
            {
              ((blnPermissionUpdateStockAdjustment && !stockAdjustmentViewAvailable(row.status)) || blnPermissionReviewStockAdjustment)
              &&
              stockAdjustmentEditAvailable(row.status)
              &&
              <TableActionButton 
                to={`/${RoutesList.stockAdjustment}/${stockAdjustmentViewAvailable(row.status) ? 'view' : 'edit'}/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeleteStockAdjustment
              &&
              !stockAdjustmentViewAvailable(row.status)
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  const displayStockAdjustmentDisplayAll = () => {
    let aryViews: any = [];
    stockAdjustmentPrintAllList.map((valueStockAdjustmentDetailTemp, indexStockAdjustmentDetailTemp)=> {
      aryViews.push(
        <Row 
          key={`stock_adjustment_detail_${indexStockAdjustmentDetailTemp}`} 
          style={{...indexStockAdjustmentDetailTemp !== stockAdjustmentPrintAllList.length-1 && {pageBreakAfter: "always"}}}>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <Row>
                    <Col>
                      <div className="logo-lg" style={{ lineHeight: '20px' }}>
                        <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                          {intl.formatMessage({ id: "StockAdjustment" })}
                        </span>
                        <br />
                        <br />
                      </div>
                    </Col>
                  </Row>
                  <Col>
                    {displayHeaderRowData(`${intl.formatMessage({id: "StockAdjustmentNo"})}:`, valueStockAdjustmentDetailTemp.stockAdjustmentNo)}
                    {displayHeaderRowData(`${intl.formatMessage({id: "BranchName"})}:`, valueStockAdjustmentDetailTemp.branchName)}
                    {
                      valueStockAdjustmentDetailTemp.status !== Constants.processing && valueStockAdjustmentDetailTemp.status !== Constants.rejected
                      &&
                      <>
                        {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedName"})}:`, valueStockAdjustmentDetailTemp.approverName)}
                        {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedDate"})}:`, moment(valueStockAdjustmentDetailTemp.approvedDate).format(Constants.displayDateFormat))}
                      </>
                    }
                  </Col>
                </div>
                <Borderline />
                <address>
                  <strong>{intl.formatMessage({id: "Status"})}:</strong>
                  <br />
                  <div style={{ marginTop: '8px' }}>
                    <GeneralStatus
                      status={valueStockAdjustmentDetailTemp.status}
                      statusColorFunction={stockAdjustmentStatusColor}
                      blnDisableCenter/>
                  </div>
                </address>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 font-weight-bold">
                    {intl.formatMessage({id: "Product(s)"})}
                  </h3>
                </div>
                <div className="table-responsive">
                  <Table className="table-fixed" style={{ width: '100%' }}>
                    <thead className="table-light">
                      <tr>
                        <th>{intl.formatMessage({id: "Name"})}</th>
                        <th className="text-center">{intl.formatMessage({id: "AdjustQuantity"})}</th>
                        <th className="text">{intl.formatMessage({id: "Remarks"})}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        valueStockAdjustmentDetailTemp.stockAdjustmentDetails.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{`${item.productName}`}</td>
                              <td className="text-center">{item.adjustQuantity}</td>
                              <td className="">{item.remark || Constants.emptyData}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
                <div className={`py-2 mt-3`}>
                  <h3 className="font-size-15 font-weight-bold">
                  {intl.formatMessage({id: "Note"})} :
                  </h3>
                </div>
                <GeneralTextAreaForModal
                  title=""
                  name="note"
                  className="margin-top-0"
                  row={5}
                  disabled={true}
                  field={valueStockAdjustmentDetailTemp}
                  setFieldValue={() => { }} />
                {
                  valueStockAdjustmentDetailTemp.status !== Constants.pending
                  &&
                  <>
                    <div className={`py-2 mt-3`}>
                      <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({id: "ReviewRemark"})} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="reviewRemark"
                      className="margin-top-0"
                      row={5}
                      disabled={true}
                      field={valueStockAdjustmentDetailTemp}
                      setFieldValue={() => { }} />
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    })

    return (
      <div className="display-none">
        <style type="text/css" media="print">{`
          .display-none {
            display: block;
          }
        `}</style>
        {aryViews}
      </div>
    );
  }

  return (
    <div>
      <div className="display-stock-adjusment-print-all">
        <style type="text/css" media="print">{`
          .display-stock-adjusment-print-all {
            display: none;
          }`}
        </style>
        <ListViewLayout
          metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockAdjustment" }) })}
          title={intl.formatMessage({ id: "StockAdjustment" })}
          addButton={
            <>
              {
                blnPermissionCreateStockAdjustment
                &&
                <MyAddButton
                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "StockAdjustment" }) })}
                  onClick={() => { history.push(`/${RoutesList.stockAdjustment}/add`) }}
                  linkTo={`/${RoutesList.stockAdjustment}/add`}
                  disable={localLoading || loading} />
              }
              {
                <MyButton
                  type="button"
                  class="btn btn-info margin-left-8"
                  content={intl.formatMessage({ id: "Export" })}
                  disable={localLoading || loading}
                  onClick={()=> {
                    exportStockAdjustmentAPI()
                  }}
                />
              }
              {
                blnPermissionPrintAllStockAdjustment
                &&
                <>
                  <MyButton
                    type="button"
                    class="btn btn-info margin-left-8"
                    content={intl.formatMessage({ id: "PrintAll" })}
                    disable={localLoading || loading}
                    subContent={<PrinterIcon size={16}/>}
                    onClick={async ()=> {
                      setLocalLoading(true);
                      await getStockAdjustmentPrintAll(stockAdjustmentRequestBody);
                      setTimeout(async ()=> {
                        setLocalLoading(false);
                        window.print();
                      }, 1000)
                    }}
                  />
                </>
              }
            </>
          }>
          {
            !initialCallAPI
            &&
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent >
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockAdjustment" }) })}
                  name="stockAdjustmentStatus"
                  options={stockAdjustmentStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedStockAdjustmentStatus}
                  initialValue={selectedStockAdjustmentStatusId}
                  setFieldLabel={setSelectedStockAdjustmentStatus}
                  setFieldValue={setSelectedStockAdjustmentStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange 
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "StockAdjustDate"})})}
                  name={"DateRange"}
                  initialValue={selectedDateRange}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}/>
              </DoubleColumnRowParent>
            </Row>
          }
          {localLoading ? (
            <Loading />
          ) : (
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={StockAdjustmentColumns}
                data={stockAdjustmentList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockAdjustment" }) })}
                objSorted={defaultSorted}
                stockAdjustmentStatus={selectedStockAdjustmentStatusId}
                stockAdjustmentStatusName={selectedStockAdjustmentStatus}
                dateRange={selectedDateRange}
                branchId={selectedBranchId}
                branchName={selectedBranchName}
                requestAPI={getStockAdjustment}
              />
              <DeleteModal
                displayName={selectedRow?.stockAdjustmentNo}
                onDeleteClick={async () => {
                  setLoading(true);
                  await deleteStockAdjustment(
                    selectedRow?.id || "",
                    selectedRow?.stockAdjustmentNo || ""
                  );
                  let currentPageTemp = currentPage;
                  if (stockAdjustmentList.length === 1 && currentPage !== 1) {
                    setCurrentPage((value) => value - 1);
                    currentPageTemp = currentPageTemp - 1;
                  }
                  await getStockAdjustment({
                    pageNumber: currentPageTemp,
                    pageSize: pageSize,
                  });
                  setLoading(false);
                }}
              />
            </>
          )}
        </ListViewLayout>
      </div>
      {
        stockAdjustmentPrintAllList.length > 0
        &&
        displayStockAdjustmentDisplayAll()
      }
    </div>
  );
};

export default observer(StockAdjustment);
