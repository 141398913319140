import { useState } from "react";
import { observer } from 'mobx-react-lite';
import agent from "../../app/api/agent";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import MyButton from "../../app/components/form/MyButton";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import { downloadExcel, returnBlobImage } from "../../app/common/function/function";
import { EmployeeImportObject } from "../../app/models/employee";
import { useStore } from "../../app/stores/store";
import LoadingModal from "../../app/components/modal/LoadingModal";

const EmployeeHqTemporally = () => {
  const { commonStore } = useStore();
  const { setErrorMessage, setSuccessMessage } = commonStore;
  const [importFileModal, setImportFileModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const importEmployeeFunction = async (excelFile) => {
    if (excelFile) {
      setLoadingModal(true);
      try {
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: EmployeeImportObject = { file: fileTemp };
        await agent.Employee.importEmployeeDepartment(valuesTemp);
        setSuccessMessage('Import Employee With Department Succesfully')
        setImportFileModal(false);
      }
      catch (e: any) {
        if (e.message) {
          setErrorMessage(e.message);
        }
        else {
          setErrorMessage('Import Employee With Department Error')
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage('No Excel File Found')
    }
  }

  return (
    <DetailViewLayout
				title={"Export / Import Employee For Department"}
				breadCrumbList={[]}
        auditTrailId={""}>
      <div className="display-flex">
        <MyButton
          content={"Export Employee With Department"}
          onClick={async () => { 
            setLoadingModal(true);
            await downloadExcel(`/employee/department/export`, {});
            setLoadingModal(false);
          }}
          class={'btn btn-success'}
          disable={false} />
        <MyButton
          content={"Import Employee With Department"}
          onClick={() => { 
            setImportFileModal(true);
          }}
          class={'btn btn-info margin-left-16'}
          disable={false} />
      </div>
      <ImportFileModal
        blnShow={importFileModal}
        setModal={setImportFileModal}
        title={"Import Employee With Department"}
        btnFunction={importEmployeeFunction}
      />
      {
        loadingModal
        &&
        <LoadingModal/>
      }
    </DetailViewLayout>
  );
}

export default observer(EmployeeHqTemporally);
