import { useState, useEffect } from "react";
import {
  Modal,
  FormFeedback
} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import { Constants } from "../../../app/constants/Constants";
import { observer } from "mobx-react-lite";

interface Props {
  blnShow: boolean;
  setModal: Function;
  forgetPasswordRequest: Function;
  setErrorMessage: Function;
}

export default observer (function ForgetPasswordRequestModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { loading, setLoading, successMessage } = commonStore;
  const defaultForgetPasswordRequestDetails = {
    username: ""
  }
  const [forgetPasswordRequestDetails, setForgetPasswordRequestDetails] = useState(defaultForgetPasswordRequestDetails)
  const [errorState, setErrorState] = useState({
    username: false
  })
  const disabledFieldInput = loading || Boolean(successMessage);

  useEffect(()=> {
    return (()=> {
      if (loading) {
        setLoading(false);
      }
    })
  })
  
  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      username: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setForgetPasswordRequestDetails(defaultForgetPasswordRequestDetails);
    props.setModal(false);
  }

  const onClickForgetPasswordRequest = async (usernameTemp) => {
    setLoading(true);
    let result = await props.forgetPasswordRequest(usernameTemp);
    if (result.status = Constants.success) {
      setTimeout(() => {
        hideModal()
      }, Constants.dismissSuccessMessage)
    }
    setLoading(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ForgetPassword" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          }
        </div>
        <div className="modal-body">
          <GeneralInputForModal
            title={intl.formatMessage({ id: "Username" })}
            name="username"
            className={errorState.username ? "mb-0" : "mb-3"}
            type="text"
            validationRequired={true}
            field={forgetPasswordRequestDetails}
            setFieldValue={setForgetPasswordRequestDetails}
            onBlur={() => {
              if (errorState.username) {
                setErrorState((value) => ({ ...value, username: false }))
              }
            }}
            disabled={disabledFieldInput}
          />
          {errorState.username ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
          ) : null}

          <div className="margin-top-14 ">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Submit" })}
              disable={disabledFieldInput}
              loading={loading}
              onClick={() => {
                let errorStateTemp = {
                  username: false
                };

                let blnErrorFound = false;
                if (!forgetPasswordRequestDetails.username) {
                  errorStateTemp.username = true;
                  blnErrorFound = true;
                }

                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  onClickForgetPasswordRequest(forgetPasswordRequestDetails)
                }
              }}

            />
          </div>
        </div>
      </Modal>
  )
})