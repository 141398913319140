import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { useLocation, Link } from "react-router-dom"
import moment from "moment";
import { history } from "../../../";

const SalesOrderCost = () => {
  const intl = useIntl();
  //Use Store
  const { reportManagementStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { salesOrderCostList, getSalesOrderCostList, setSalesOrderCostList, exportSalesOrderCost } = reportManagementStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportManagementReport = checkPermission([PermissionConstants.ExportManagementReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const aryCustomerOrderCostSummaryDetails: ObjectForReportRow[] = [
    { label: "Date", value: "performedDateTime", type: "date" },
    { label: "CustomerRedemptionNo", value: "customerRedemptionNo", type: "link" },
    { label: "RedeemType", value: "redeemType", type: "string" },
    { label: "TotalCost", value: "totalCost", type: "currency" },
    { label: "TotalStaffCost", value: "totalStaffCost", type: "currency" },
    { label: "TotalConsumableCost", value: "totalConsumableCost", type: "currency" },
    { label: "TotalSales", value: "totalSales", type: "currency" },
    { label: "TotalProfit", value: "totalProfit", type: "currency" },
  ];

  const paramsSearch = useLocation().search;
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesOrderCostListApi() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let blnParams = branchParams && fromDateParams && toDateParams;

      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      if (blnParams) {
        setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })

        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.salesOrderCost}` });
        await fetchSalesOrderCostApi(false, branchIdTemp, { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" });
      }
      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageManagementReport], true)) {
      return;
    }

    fetchSalesOrderCostListApi();

    return (() => {
      setSalesOrderCostList([]);
    })
  }, [])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchSalesOrderCostApi(blnDropDown: boolean = false, branchIdTemp?: string, dateRange?: DateRangeInterface) {
    if (!selectedBranchId && !branchIdTemp) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getSalesOrderCostList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: branchIdTemp || selectedBranchId,
      dateRange: dateRange || selectedDateRange
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportSalesOrderCostApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportSalesOrderCost({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange
    });
    setLocalLoading(false)
  }


  //Table Content UI
  const SalesOrderCostListColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "customerOrderNo",
      text: intl.formatMessage({ id: "CustomerOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <Link to={row.customerOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerOrderNo || Constants.emptyData}</Label>
          </Link>
        </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "totalSales",
      text: intl.formatMessage({ id: "TotalSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalSales)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "totalRefundAmount",
      text: intl.formatMessage({ id: "TotalRefundAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalRefundAmount)}</div>
      ),
    },
    {
      dataField: "totalUnearnedRevenue",
      text: intl.formatMessage({ id: "TotalUnearnedRevenue" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalUnearnedRevenue)}</div>
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "CustomerSalesOrderCostSummaryDetails" })}
        rowFieldName={"customerOrderCostSummaryDetails"}
        rowColumns={aryCustomerOrderCostSummaryDetails}
        keyFieldName={"randomId"}
        linkPathName={`/${RoutesList.customerRedemption}/view/`}
        linkFieldName={"customerDeliverOrderId"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesOrderCost" }) })}
        title={intl.formatMessage({ id: "SalesOrderCost" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportManagementReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportSalesOrderCostApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-Order-Cost.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchId"
                  className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-3" : ""}`}
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              {/* <Col xl="3">
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRange" })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col> */}
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchSalesOrderCostApi(true)
                  }}
                />
              </Col>
            </Row></>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={SalesOrderCostListColumns}
                data={salesOrderCostList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesOrderCost" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                keyField={"randomId"}
                expandRow={expandRow}
                requestAPI={getSalesOrderCostList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesOrderCost);
