import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, returnRefundStatusColor, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const CreditAdjustmentDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { creditAdjustmentStore, customerSalesOrderStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { creditAdjustmentDetail, setCreditAdjustmentDetail, getCreditAdjustmentWithId } = creditAdjustmentStore;
  // const { reviewCreditAdjustmentCredit } = customerSalesOrderStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CreditAdjustment" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CreditAdjustment" }), urlPath: RoutesList.exchangeToCredit }];
  const creditAdjustmentFullDetail = creditAdjustmentDetail || {
    customerId: "",
    customerName: "",
    adjustmentType: "",
    creditAdjustmentAmount: 0,
    reason: "",
    remark: "",
    accountHolderName: "",
    identityNo: "",
    bankAccountType: "",
    bankAccountNo: "",
    status: "",
    subTotalAdjustmentAmount: 0,
    processingFees: 0,
    totalAdjustmentAmount: 0,
    isIgnoreProcessingFee: false,
    subBrandId: "",
    subBrandName: "",
    branchName: "",
    creditAdjustmentNo: "",
    requestDate: "",
    rounding: 0
  }
  const blnCashType = removeAllSpace(creditAdjustmentFullDetail.adjustmentType) == Constants.cashAdjustment;

  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  async function fetchCreditAdjustmentDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCreditAdjustmentWithId(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCreditAdjustment], true)) {
      return;
    }

    fetchCreditAdjustmentDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setCreditAdjustmentDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }
  
  const displayTableRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={blnCashType ? 5 : 1} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="creditAdjustment-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CreditAdjustment" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {creditAdjustmentFullDetail.branchName} {creditAdjustmentFullDetail.subBrandName ? `(${creditAdjustmentFullDetail.subBrandName})` : ""}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CreditAdjustmentNo" })}:`, creditAdjustmentFullDetail.creditAdjustmentNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DateAndTime" })}:`, `${moment(creditAdjustmentFullDetail.requestDate).format(Constants.displayDateFormat)}\n${moment(creditAdjustmentFullDetail.requestDate).format(Constants.displayTimeFormat)}`)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <div style={{fontSize: "14px"}}>
                          {
                            blnPermissionManageCustomer 
                            ? 
                            <Link to={creditAdjustmentFullDetail.customerId ? `/${RoutesList.customer}/view/${creditAdjustmentFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                             <Label className="margin-bottom-0 pointer-clickable">{creditAdjustmentFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                            :  
                            creditAdjustmentFullDetail.customerName
                          }
                        </div>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={creditAdjustmentFullDetail.status}
                              statusColorFunction={returnRefundStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "CreditAdjustmentDetails" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "CreditAdjustmentType" })}</th>
                            <th className={!blnCashType ? "text-end" : ""}>{intl.formatMessage({ id: "CreditAdjustmentAmount" })}</th>
                            {blnCashType && <th>{intl.formatMessage({ id: "AccountHolderName" })}</th>}
                            {blnCashType && <th>{intl.formatMessage({ id: "IdentityNo" })}</th>}
                            {blnCashType && <th>{intl.formatMessage({ id: "BankAccountType" })}</th>}
                            {blnCashType && <th className="text-end">{intl.formatMessage({ id: "BankAccountNo" })}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="">{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.adjustmentType)}</td>
                            <td className={!blnCashType ? "text-end" : ""}>{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.subTotalAdjustmentAmount)}</td>
                            {blnCashType && <td className="">{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.accountHolderName)}</td>}
                            {blnCashType && <td className="">{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.identityNo)}</td>}
                            {blnCashType && <td className="">{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.bankAccountType)}</td>}
                            {blnCashType && <td className="text-end">{newSpaceBeforeCapitalLetter(creditAdjustmentFullDetail.bankAccountNo)}</td>}
                          </tr>
                          {displayTableRowData(`${intl.formatMessage({ id: "SubTotalAdjustmentAmount" })}`, returnPriceWithCurrency(creditAdjustmentFullDetail.subTotalAdjustmentAmount))}
                          {displayTableRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(creditAdjustmentFullDetail.processingFees))}
                          {displayTableRowData(`${intl.formatMessage({ id: "Rounding" })}`, returnPriceWithCurrency(creditAdjustmentFullDetail.rounding))}
                          {displayTableRowData(`${intl.formatMessage({ id: "TotalAdjustmentAmount" })}`, returnPriceWithCurrency(creditAdjustmentFullDetail.totalAdjustmentAmount))}
                        </tbody>
                      </Table>
                    </div>
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Reason" })}
                      name="reason"
                      className="mt-3"
                      row={5}
                      field={creditAdjustmentFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true}
                    />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={`${"mb-3"} mt-4`}
                      row={4}
                      field={creditAdjustmentFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true} />
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      <GeneralSubmitAndCancelBtn
                        className="width-100-percentage"
                        successMessage={successMessage}
                        viewAction={false}
                        hidePrimaryButton={true}
                        showPrint={true}
                        cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CreditAdjustmentDetail);
