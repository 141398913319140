import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import MyButton from "../../../app/components/form/MyButton";
import AlertMessage from "../../../app/components/alert/AlertMessage";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Modal, Button, Input, Label } from "reactstrap";
import { Constants } from '../../../app/constants/Constants';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import { CustomerPurchasePackage,RedeemPurchaseDetailObject } from "../../../app/models/customerOrder";
import { contructValidationErrorMessage, getBranchId, getPurchaseTypeIcon, promptInfoMessage, redemptionCalculation, returnSplitBackgroundRow } from "../../../app/common/function/function"; 
import Borderline from "../../../app/components/form/BorderLine";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import CustomerPurchaseCart from "../subView/CustomerPurchaseCart";
import CustomerPurchaseDetailModal from "./CustomerPurchaseDetailModal";
import { EmployeeListObject } from "../../../app/models/employee";
import { useIntl } from "react-intl";

interface Props {
  blnShow: boolean;
  setModal: Function;
  reloadData: Function;
  selectedCustomer: string;
  selectedCustomerId: string;
  selectedPackageDetail: CustomerPurchasePackage | undefined;
  setSelectedPurchaseDetail: Function;
  employeeList: EmployeeListObject[];
  customerPurchaseList: any;
  validation: any;
  selectedRedeemPurchaseDetails: RedeemPurchaseDetailObject[];
  productRedemptionEntitlement: Function;
  blnDisableModalView?: boolean;
  disableModalViewFunction?: Function;
  loadingDisableModalView?: boolean;
}

const CustomerPurchasePackageDetail = (props: Props) => {
  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const { commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
    setSuccessMessage,
    windowSize,
	} = commonStore;
	const displayTitle = !props.blnDisableModalView ? `${intl.formatMessage({id: "RedeemCustomerPurchase"})}` : props.selectedPackageDetail?.customerInvoiceNo || Constants.emptyData;
  const services = `${intl.formatMessage({id: "Service"})}`;
  const products = `${intl.formatMessage({id: "Product"})}`;
  const optionalProducts = `${intl.formatMessage({id: "OptionalProducts"})}`;
  const [aryTab, setAryTab] = useState<any>([]);
  const [customerPurchaseDetailModal, setCustomerPurchaseDetailModal] = useState(false);
  const [selectedCustomerPurchaseDetail, setSelectedCustomerPurchaseDetail] = useState<RedeemPurchaseDetailObject | undefined>(undefined);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
      tabBar: services,
      customerId: props.selectedCustomerId,
      branchId: getBranchId(),
      isBackdate: false,
      performedDateTime: "",
      note: "",
      redeemPurchaseDetails: props.selectedRedeemPurchaseDetails
    },
		validationSchema: Yup.object({
			redeemPurchaseDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({id: "Item"}).toLowerCase()})),
		}),
		onSubmit: async (values) => {
      let blnProceed = true;
			const valuesTemp: any = _.cloneDeep(values);
      let redeemPurchaseDetailsFromParent = _.cloneDeep(props.validation.values.redeemPurchaseDetails)
      valuesTemp.redeemPurchaseDetails.map((valueTemp)=> {
        let blnFound = false;
        for (var a=0; a<redeemPurchaseDetailsFromParent.length; a++) {
          if (valueTemp.customerPurchaseDetailId == redeemPurchaseDetailsFromParent[a].customerPurchaseDetailId && valueTemp.packageId == redeemPurchaseDetailsFromParent[a].packageId && valueTemp.customerInvoiceId == redeemPurchaseDetailsFromParent[a].customerInvoiceId) {
            blnFound=true;
            redeemPurchaseDetailsFromParent[a] = valueTemp
            break;
          }
        }
        if (!blnFound) {
          redeemPurchaseDetailsFromParent.push(valueTemp);
        }
      })
      let redeemPurchaseDetailsServicesChecking = _.cloneDeep(valuesTemp.redeemPurchaseDetails);
      redeemPurchaseDetailsServicesChecking = redeemPurchaseDetailsServicesChecking.map((value, index) => {
        // if (valuesTemp.tabBar.includes(services) ) {
        if ((!value.serviceById || !value.serviceByName) && value.purchaseDetailRedeemType === 0) {
          blnProceed = false;
          value = {...value, validationRequired: true}
        }
        else {
          if (value.validationRequired) {
            delete value["validationRequired"];
          }
        }
        // }
        return value
      })
      if(!blnProceed){
        props.validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsServicesChecking);
      }else{
        props.validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsFromParent)
      }

      // for (let indexRedeemPurchaseDetail=0; indexRedeemPurchaseDetail<valuesTemp.redeemPurchaseDetails.length; indexRedeemPurchaseDetail++) {
      //   let redeemPurchaseDetailTemp = valuesTemp.redeemPurchaseDetails[indexRedeemPurchaseDetail];
      //   promptInfoMessage(intl.formatMessage({ id: "AddedItemIntoRedemptionCartMessage" }, { quantity: redeemPurchaseDetailTemp.count, item: redeemPurchaseDetailTemp.name }), redeemPurchaseDetailTemp.salesOrderDetailRedeemType)
      // }

      if (!props.blnDisableModalView) {
        dismissModal();
      }
      else {
        if (props.disableModalViewFunction && blnProceed) {
          props.validation.setFieldValue("isBackdate", valuesTemp.isBackdate)
          props.validation.setFieldValue("performedDateTime", valuesTemp.performedDateTime)          
          await props.disableModalViewFunction(redeemPurchaseDetailsFromParent);
        }
      }
		},
	});

  //Convert Search framework into SearchBar for naming.
  const { SearchBar } = Search;

  //Table Content UI
  const CustomerServicesReedemColumns = [
    { 
      dataField: "serviceTreatmentId", //field name from array to display
      text: "serviceTreatmentId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      style:{"cursor": "pointer"},
      formatter: (cellContent, row) => <>{row.serviceTreatmentId}</>, //Custom UI to display
    },
    {
      dataField: "serviceTreatmentName",
      text: intl.formatMessage({id: "ServiceTreatmentName"}),
      style: {
        width: '30%',
      }
    },
    {
      dataField: "availableSessions",
      text: intl.formatMessage({id: "AvailableSessions"}),
      style: {
        width: '30%',
      }
    },
    {
      dataField: "add",
      text: intl.formatMessage({id: "Action"}).toUpperCase(),
      headerStyle: { textAlign: 'right' }, 
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={row.availableSessions == 0 ? intl.formatMessage({id: "FullyRedeemed"}) : intl.formatMessage({id: "Add"})}
            disable={row.availableSessions == 0 ? true : false}/>
        </div>
      ),
    }
  ];

  const CustomerProductReedemColumns = [
    { 
      dataField: "productId", //field name from array to display
      text: "productId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      style:{"cursor": "pointer"},
      formatter: (cellContent, row) => <>{row.productId}</>, //Custom UI to display
    },
    {
      dataField: "productName",
      text: intl.formatMessage({id: "ProductName"}),
      style: {
        width: '30%',
      }
    },
    {
      dataField: "availableQuantity",
      text: intl.formatMessage({id: "AvailableQuantity"}),
      style: {
        width: '30%',
      }    
    },
    {
      dataField: "add",
      text: intl.formatMessage({id: "Action"}).toUpperCase(),
      headerStyle: { textAlign: 'right' }, 
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={props.selectedPackageDetail?.paymentStatus === Constants.partial ? intl.formatMessage({id: "NotEligible"}) : row.availableQuantity == 0 ? intl.formatMessage({id: "FullyRedeemed"}) : intl.formatMessage({id: "Add"})}
            disable={props.selectedPackageDetail?.paymentStatus === Constants.partial || row.availableQuantity == 0 ? true : false}/>
        </div>
      ),
    }
  ];

  const CustomerOptionalProductReedemColumns = [
    { 
      dataField: "productId", //field name from array to display
      text: "productId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      style:{"cursor": "pointer"},
      formatter: (cellContent, row) => <>{row.productId}</>, //Custom UI to display
    },
    {
      dataField: "productName",
      text: intl.formatMessage({id: "ProductName"}),
      style: {
        width: '30%',
      }
    },
    {
      dataField: "unitPrice",
      text: intl.formatMessage({id: "UnitPrice"}),
      style: {
        width: '30%',
      }    
    },
    {
      dataField: "add",
      text: intl.formatMessage({id: "Action"}).toUpperCase(),
      headerStyle: { textAlign: 'right' }, 
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={props.selectedPackageDetail?.paymentStatus === Constants.partial ? intl.formatMessage({id: "NotEligible"}) : row.optionalProductCost == 0 ? intl.formatMessage({id: "FullyRedeemed"}) : intl.formatMessage({id: "Add"})}
            disable={props.selectedPackageDetail?.paymentStatus === Constants.partial || row.optionalProductCost == 0 ? true : false}/>
        </div>
      ),
    }
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (String(e.target).includes(Constants.htmlButton)){
        dismissMessage();
        let blnTab = validation.values.tabBar == services ? 1 : validation.values.tabBar == products ? 2 : 3; //Services Tab = 1, Product Tab = 2, Optional Product = 3
        let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
        //Service redemption checking when try to redeem product
        if (blnTab !== 1) {
          let blnProceed = props.productRedemptionEntitlement(validation.values.redeemPurchaseDetails, props.selectedPackageDetail);
          if (!blnProceed) {
            return;
          }
        }
        let totalOptionalProductRedemptionPrice = 0;
        if (blnTab === 3) {
          redeemPurchaseDetailsTemp.map((valueDetailsTemp)=> {
            if (valueDetailsTemp.purchaseDetailRedeemType === 4) {
              totalOptionalProductRedemptionPrice += (valueDetailsTemp.count * valueDetailsTemp.unitPrice)
            }
          })
        }
        
        let indexCustomerOrderDetail = _.findIndex(redeemPurchaseDetailsTemp, {customerPurchaseDetailId: blnTab === 1 ? row.serviceTreatmentId : row.productId})
        if (indexCustomerOrderDetail > -1) {
          redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count += 1;
          let  blnProceed = redemptionCalculation(blnTab, blnTab === 1 ? redeemPurchaseDetailsTemp[indexCustomerOrderDetail].availableSessions : blnTab === 2 ? redeemPurchaseDetailsTemp[indexCustomerOrderDetail].availableQuantity : props.selectedPackageDetail?.optionalAvailableProductCost!, blnTab === 3 ? (totalOptionalProductRedemptionPrice + row.unitPrice) : redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count)

          if (!blnProceed) {
            return;
          }

          if (blnTab === 2 ||blnTab === 3) {
            dismissMessage();
            setSelectedCustomerPurchaseDetail(_.cloneDeep(redeemPurchaseDetailsTemp[indexCustomerOrderDetail]))
            setCustomerPurchaseDetailModal(true);
            return;
          }
          else {
            promptInfoMessage(intl.formatMessage({ id: "AddedItemIntoRedemptionCartMessage" }, { quantity: redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count, item: redeemPurchaseDetailsTemp[indexCustomerOrderDetail].name }), redeemPurchaseDetailsTemp[indexCustomerOrderDetail].salesOrderDetailRedeemType)
          }
        }
        else {
          let  blnProceed = redemptionCalculation(blnTab, blnTab === 1 ? row.availableSessions : blnTab === 2 ? row.availableQuantity : props.selectedPackageDetail?.optionalAvailableProductCost!, blnTab === 3 ? (totalOptionalProductRedemptionPrice + row.unitPrice) : 1);
          if (!blnProceed) {
            return;
          }

          let objOrderDetails : RedeemPurchaseDetailObject = {
            name: blnTab === 1 ? row.serviceTreatmentName : row.productName,
            customerInvoiceId: props.selectedPackageDetail?.customerInvoiceId || "",
            customerInvoiceNo: props.selectedPackageDetail?.customerInvoiceNo || "",
            purchaseRedeemType: 0, // Package 
            customerPurchaseId: props.selectedPackageDetail?.id || "",
            purchaseDetailRedeemType: blnTab === 1 ? 0 : blnTab === 2 ? 1 : 4, // 0 = Package Service, 1 = Package Product, 4 = Package Optional Product
            packageId: props.selectedPackageDetail?.packageId || "",
            packageName: props.selectedPackageDetail?.packageName || "",
            customerPurchaseDetailId: blnTab === 1 ? row.serviceTreatmentId : row.productId,
            salesOrderDetailRedeemType: row.salesOrderDetailRedeemType,
            count: 1
          }
          
          if (blnTab === 1) {
            row.availableProducts = _.sortBy(row.availableProducts, ['categoryName', 'productName']);
            let serviceProductUsedTemp = row.availableProducts.map((valueTemp)=> ({label: valueTemp.productName, value: valueTemp.productId, categoryName: valueTemp.categoryName}))
            let serviceMandatoryProductUsedTemp = [];
            if (row.mandatoryProducts) {
              serviceMandatoryProductUsedTemp = row.mandatoryProducts.map((valueTemp)=> ({label: valueTemp.label, value: valueTemp.value}))
            }
            
            let optionalBranchMachinesUsedTemp = [];
            if (row.optionalBranchMachines) {
              optionalBranchMachinesUsedTemp = row.optionalBranchMachines.map((valueTemp)=> ({label: `${valueTemp.branchMachineName} ${valueTemp.serialNumber ? `(${valueTemp.serialNumber})` : ""}`, value: valueTemp.branchMachineId, consumeQuantity: valueTemp.advisableConsumeQuantity || 0}));
            }
            
            objOrderDetails = {
              ...objOrderDetails,
              serviceById: null,
              serviceByName: null,
              availableOptionalProducts: serviceProductUsedTemp,
              serviceProductUsed: [],
              availableSessions: row.availableSessions,
              redeemedSessions: row.redeemedSessions,
              mandatoryProducts: row?.mandatoryProducts || [],
              mandatoryProductsUsed: serviceMandatoryProductUsedTemp,
              availableOptionalBranchMachines: optionalBranchMachinesUsedTemp,
              optionalBranchMachinesUsed: [],
              quantity: row.quantity
            }
          }
          else {
            objOrderDetails = {
              ...objOrderDetails,
              availableQuantity: row.availableQuantity,
              unitPrice: row.unitPrice,
              optionalProductCost: props.selectedPackageDetail?.optionalProductCost,
              redeemedProductCost: props.selectedPackageDetail?.redeemedProductCost,
              optionalAvailableProductCost: props.selectedPackageDetail?.optionalAvailableProductCost,
              productBatchDetails: []
            }
          }

          dismissMessage();
          setSelectedCustomerPurchaseDetail(_.cloneDeep(objOrderDetails))
          setCustomerPurchaseDetailModal(true);
        }
        validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp)
      }
    }
  };

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || props.loadingDisableModalView ? true : false;

  useEffect(()=> {
    let blnServicesTab = false; 
    let blnProductTab = false; 
    let blnOptionalProductTab = false;
    if (props.selectedPackageDetail && aryTab.length === 0) {
     if (props.selectedPackageDetail?.availableServices && props.selectedPackageDetail?.availableServices.length > 0) {
      aryTab.push(services);
      blnServicesTab = true;
     }
     if (props.selectedPackageDetail?.availableProducts && props.selectedPackageDetail?.availableProducts.length > 0) {
      aryTab.push(products);
      blnProductTab = true;
     }
     if (props.selectedPackageDetail?.availableOptionalProducts && props.selectedPackageDetail?.availableOptionalProducts.length > 0) {
      aryTab.push(optionalProducts);
      blnOptionalProductTab = true;
     }

     if (!blnServicesTab) {
      if (blnProductTab) {
        validation.setFieldValue("tabBar", products);
        return;
      }
      if (blnOptionalProductTab) {
        validation.setFieldValue("tabBar", optionalProducts);
        return;
      }
     }
    }
  }, [props.selectedPackageDetail])

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        dismissModal()
      }, Constants.timeoutNavigation)
    }
  }, [successMessage])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const dismissModal = () => {
    if (!props.blnDisableModalView) {
      props.setModal(false);
      props.setSelectedPurchaseDetail(undefined);
      setAryTab([]);
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  function handleChangeRadioBox(event) {
    dismissMessage();
    validation.setFieldValue("tabBar", event.target.id)
  }

  const deleteCustomerPurchase = (index) => {
    let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
    redeemPurchaseDetailsTemp.splice(index, 1);
    validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp);
  }

  const selectCustomerPurchase = (index) => {
    dismissMessage();
    setSelectedCustomerPurchaseDetail(_.cloneDeep(validation.values.redeemPurchaseDetails[index]))
    setCustomerPurchaseDetailModal(true);
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  } 

  const displayContent = () => {
    return (
      <div className="">
				<MetaTags>
					<title>{displayTitle}</title>
				</MetaTags>
				<div>
					{/* {(errorMessage || successMessage) && !customerPurchaseDetailModal && (
            <div style={{paddingLeft: '24px', paddingRight: '24px'}}>
              <AlertMessage
                errorMessage={errorMessage}
                successMessage={successMessage}
                isModal={true}
              />
            </div>
					)} */}
					{loading && !successMessage ? (
            <Loading />
					) : (
						<Form
							className="standard-layout"
							onSubmit={(e) => {
								e.preventDefault();
								validation.handleSubmit();
								return false;
							}}
							onBlur={()=> {
                dismissMessage();
              }}
						>
							<Row form={+true}>
								{" "}
								{/* +true = Convert boolean to numbers*/}
								<Col xl={12}>
                  <LineBreakWithTittle 
                    paddingBottom="0px"
                    title={displayTitle}
                    h4Title/>
								</Col>
							</Row>
							<Row>
                <Col xl="7">
                  <ToolkitProvider
                    keyField={validation.values.tabBar == services ? "serviceTreatmentId" : "productId"}
                    columns={validation.values.tabBar == services ? CustomerServicesReedemColumns : validation.values.tabBar == products ? CustomerProductReedemColumns : CustomerOptionalProductReedemColumns}
                    data={validation.values.tabBar == services ? props.selectedPackageDetail?.availableServices : validation.values.tabBar == products ? props.selectedPackageDetail?.availableProducts : props.selectedPackageDetail?.availableOptionalProducts}
                    bootstrap4
                    search
                  >
                  {toolkitProps => (
                    <div 
                      className="standard-layout">
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "Customer" })}
                        name="customer"
                        styles={{width: "100%"}}
                        options={[{label: props.selectedCustomer, value: props.selectedCustomerId}]}
                        labelField="preferredName"
                        valueField="id"
                        initialLabel={props.selectedCustomer}
                        initialValue={props.selectedCustomerId}
                        setFieldLabel={""}
                        setFieldValue={""}
                        disabled={true}
                      />
                      <Borderline/>
                      <Row>
                        {
                          aryTab.map((value)=> (
                            <Col xl="4" sm="4" key={`${value}_tab_bar`}>
                              <div className="mb-3">
                                <label className="card-radio-label mb-0">
                                  <Input
                                    type="radio"
                                    id={value}
                                    name="tabBar"
                                    checked={validation.values.tabBar==value}
                                    className={`display-none ${validation.values.tabBar == services ? `card-radio-input-Service` : validation.values.tabBar == products ? `card-radio-input-${products}` : `card-radio-input-OptionalProducts`}`}
                                    onChange={event=> {
                                      handleChangeRadioBox(event)
                                    }}
                                  />
                                  <div className="card-radio" style={{ borderRadius: '10px', padding: '12px', borderColor: `${value == services ? Constants.lightpurple : value == products ? Constants.orange : Constants.brown}` }}>
                                    <div className="flex-direction-row flex-justfity-content-center">
                                      <span className="">{getPurchaseTypeIcon(value)}</span>
                                      <p className="margin-bottom-0" style={{}}>{value == services ? "Service Treatment" : value}</p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                      {/* <Col className="flex-right">
                        <div className="search-box ms-2 margin-bottom-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar 
                              {...toolkitProps.searchProps}/>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col> */}
                      {
                        <Col className="flex-right">
                          <Label>
                            <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                            <Label style={{color: 'grey'}} className="margin-bottom-0">
                              {
                                validation.values.tabBar === services
                                ?
                                `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableSessions" })} : ${props.selectedPackageDetail?.availableSessions}`                         
                                :
                                validation.values.tabBar === products
                                ?
                                `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedPackageDetail?.availableProductsRedeem}`                         
                                :
                                `${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "LeftOver" })} ${intl.formatMessage({ id: "OptionalProductCost" })} : ${props.selectedPackageDetail?.optionalAvailableProductCost!}`                         
                              }
                            </Label>
                          </Label>
                        </Col>
                      }
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={true}
                              striped={false}
                              classes={"table align-middle"}
                              keyField={validation.values.tabBar == services ? "serviceTreatmentId" : "productId"}
                              rowEvents={ rowEvents }
                              noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: validation.values.tabBar == services ? "ServicesTreatment" : "Products"})})}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  </ToolkitProvider>
                </Col>
                <Col xl="5">
                  {
                    Constants.innerWidthCompare > windowSize.innerWidth
                    ?
                    returnSplitBackgroundRow("margin-left--20px margin-right--20px")
                    :
                    null
                  }
                  <div 
                    className="standard-layout">
                    <CustomerPurchaseCart
                      validation={validation}
                      disabledCustomerOrder={disabledFieldInput}
                      selectCustomerPurchase={selectCustomerPurchase}
                      deleteCustomerPurchase={deleteCustomerPurchase}
                      blnDisplayNote={props.blnDisableModalView ? true : false}
                    />
                    <Row>
                      {
                        !props.blnDisableModalView
                        &&                      
                        <Col>
                          {!Boolean(successMessage) &&
                            (
                              <MyButton
                                type="button"
                                class="btn btn-danger"
                                style={{width: '100%'}}
                                content={intl.formatMessage({ id: "Cancel" })}
                                disable={
                                  validation.isSubmitting
                                }
                                onClick={()=> dismissModal()}
                              />
                            )}
                        </Col>
                      }
                      <Col>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-success"
                              style={{width: '100%'}}
                              content={!props.blnDisableModalView ? intl.formatMessage({ id: "AddToCart" }) : intl.formatMessage({id: "Redeem"}) }
                              disable={
                                validation.isSubmitting || props.loadingDisableModalView
                              }
                              loading={
                                validation.isSubmitting || props.loadingDisableModalView
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
						</Form>
					)}
				</div>
        {
          customerPurchaseDetailModal
          &&
          <CustomerPurchaseDetailModal
            blnShow={customerPurchaseDetailModal}
            setModal={setCustomerPurchaseDetailModal}
            selectedCustomerPurchaseDetail={selectedCustomerPurchaseDetail}
            setSelectedCustomerPurchaseDetail={setSelectedCustomerPurchaseDetail}
            productRedemptionEntitlement={props.productRedemptionEntitlement}
            employeeList={props.employeeList}
            customerPurchaseList={props.customerPurchaseList}
            blnPromptInfoMessage={true}
            validation={validation}/>
        }
			</div>
    )
  }

	return (
    props.blnDisableModalView
    ?
    <div>
      {displayContent()}  
    </div>
    :
		<Modal
      isOpen={props.blnShow}
      toggle={()=> {
        dismissModal()
      }}
      centered
      modalClassName="my-modal"
    >
      {displayContent()}
		</Modal>
	);
};

export default observer(CustomerPurchasePackageDetail);
