import _ from 'lodash';
import InformationIcon from 'mdi-react/InformationIcon';
import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { FormFeedback, Input, InputGroup, Label, Row, Tooltip } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { Constants } from '../../constants/Constants';
import { ObjectForDropdown } from '../../models/common';
import DoubleColumnRowParent from './DoubleColumnRowParent';
import { replaceAllStringWithArray } from '../../common/function/function';
import CloseIcon from 'mdi-react/CloseIcon';
import { isMobile, isTablet } from 'react-device-detect';

interface Props {
  title: string;
  name: string;
  className?: string;
  classNameInput?: string;
  type: InputType | any;
  placeholder?: string;
  disabled?: boolean;
  validationRequired?: boolean;
  validationRequiredForce?: boolean;
  inputGroup?: boolean;
  inputGroupText?: string;
  validation?: any;
  childrenUI?: any;
  timeStep?: string;
  onChangeFunction?: Function;
  onChangeFunctionWithSetValidationField?: Function;
  minCurrentDate?: boolean;
  maxCurrentDate?: boolean;
  maxCurrentDateWithHour?: boolean;
  maxDateOfTheMonth?: boolean;
  backendMaxDate?: boolean;
  maxLength?: number;
  options?: ObjectForDropdown[];
  blnMultipleCheckbox?: boolean;
  field?: any;
  setFieldValue?: Function;
  blnModal?: boolean;
  enableAutoComplete?: boolean;
  toolTipMessage?: string;
  minDateValue?: string | any;
  blnNumberOnly?: boolean;
  onBlurFunction?: Function;
  blnAlphaNumberic?: boolean;
  blnNumberOnlyWithDecimal?: boolean;
}

export default function GeneralInput(props: Props) {
  const valueFinal = returnValueFinal(props.name);
  const [showToolTip, setShowToolTip] = useState(false);
  const [blnOnChange, setBlnOnChange] = useState(false);

  function returnValueFinal (fieldName) {
    return props.blnModal ? props.field[fieldName] : props.validation.values[fieldName]
  }

  function handleChange(event) {
    if (props.onBlurFunction) {
      setBlnOnChange(true);
    }

    if (props.maxLength) {
      if(event.target.value.length==(props.maxLength+1)) return false;
    }

    if (props.blnAlphaNumberic) {
      const alphanumericRegex = Constants.textAlphaNumericRegex;
      if (!alphanumericRegex.test(event.target.value) && event.target.value !== '') {
        return false;
      }
    }
    if (props.blnNumberOnlyWithDecimal) {
      let eventTargetValueTemp = replaceAllStringWithArray(event.target.value, [','], "");
      const numberOnlyWithTwoDecimalsRegex = Constants.numberOnlyWithTwoDecimalPlacesRegex;
      if (!numberOnlyWithTwoDecimalsRegex.test(eventTargetValueTemp)) {
        return false;
      }
    }

    let blnDisabledNumberise = props.name !== Constants.contactNo && props.name !== Constants.mobileNo && props.name !== Constants.supplierContactNo && props.name !== Constants.warehouseContactNo && props.name !== Constants.emergencyContact && props.name !== Constants.last4CardDigitNo;
    let valueChangeFinal = props.type == 'checkbox' ? event.target.checked : (props.type == 'number' && blnDisabledNumberise && event.target.value) ? Number(event.target.value) : event.target.value;

    if (props.blnMultipleCheckbox) {
      let aryCurrentValue = valueFinal ? JSON.parse(valueFinal) : [];
      if (event.target.checked) {
        aryCurrentValue.push(event.target.name);
      }
      else {
        var indexCurrentValue = aryCurrentValue.indexOf(event.target.name);
        if (indexCurrentValue > -1) {
          aryCurrentValue.splice(indexCurrentValue, 1);
        }
      }
      if (props.blnModal) {
        onChangeModalValue(JSON.stringify(aryCurrentValue));
      }
      else {
        props.validation.setFieldValue(props.name, JSON.stringify(aryCurrentValue))
      }
    }
    else if (props.onChangeFunction) {
      if (props.blnModal) {
        onChangeModalValue(valueChangeFinal);
      }
      else {
        props.validation.values[props.name] = valueChangeFinal;
      }
      props.onChangeFunction(valueChangeFinal);
    }
    else if (props.onChangeFunctionWithSetValidationField) {
      props.validation.setFieldValue(props.name, valueChangeFinal);
      props.onChangeFunctionWithSetValidationField(valueChangeFinal);
    }
    else {
      if (props.blnModal) {
        onChangeModalValue(valueChangeFinal);
      }
      else {
        if (props.name === "firstName" || props.name === "middleName" || props.name === "lastName") {
          if (props.validation.values.firstName !== null && props.validation.values.middleName !== null && props.validation.values.lastName !== null && props.validation.values.preferredName !== null) {
            let firstNameTemp = (props.name === "firstName" ? valueChangeFinal : props.validation.values?.firstName) || "";
            let middleNameTemp = (props.name === "middleName" ? valueChangeFinal : props.validation.values?.middleName) || "";
            let lastNameTemp = (props.name === "lastName" ? valueChangeFinal : props.validation.values?.lastName) || "";
            let finalNameTemp = `${firstNameTemp}${middleNameTemp ? ` ${middleNameTemp} ` : " "}${lastNameTemp}`;
            if (`${props.validation.values?.firstName || ""}${props.validation.values?.middleName ? ` ${props.validation.values?.middleName } ` : " "}${props.validation.values?.lastName || ""}` === props.validation.values?.preferredName || !props.validation.values.preferredName) {
              props.validation.setFieldValue("preferredName", finalNameTemp);
            }
          }
        }
        props.validation.setFieldValue(props.name, valueChangeFinal);
      }
    }
  }

  const onChangeModalValue = (valueTemp) => {
    let fieldTemp = _.cloneDeep(props.field);
    fieldTemp[props.name] = valueTemp;
    if (props.setFieldValue) {
      props.setFieldValue(fieldTemp);
    }
  }

  const triggerOnBlurFunction = () => {
    if (!blnOnChange) return;

    setBlnOnChange(false);
    if (props.onBlurFunction) {
      props.onBlurFunction();
    }
  }

  return (
    <div>
      <div className={props.className || "mb-3"}>
        {props.title && (
          <Label>
            {props.validationRequired && (props.validationRequiredForce || !props.disabled) && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
            <Label className="margin-bottom-0">{props.title}</Label>
            {
              props.toolTipMessage
              &&
              <>
                <InformationIcon 
                  id={`${props.name}_tooltip`}
                  color='#556ee6'
                  className='margin-left-4'
                  style={{paddingBottom: "4px"}}
                  />
                <Tooltip
                  placement="right"
                  isOpen={showToolTip}
                  target={`${props.name}_tooltip`}
                  toggle={() => {
                    setShowToolTip(!showToolTip);
                  }}>
                  {props.toolTipMessage}
                </Tooltip>
              </>
            }
          </Label>
        )}
        {props.title && (
          <br />
        )}
        {
          props.inputGroup
          ?
          <Row className="g-0">
            <InputGroup>
              <span className="input-group-text">
                {props.inputGroupText}
              </span>
              <Input
                name={props.name}
                type={props.type}
                onChange={(props.onChangeFunction && handleChange) || handleChange}
                value={valueFinal}
                disabled={props.disabled}
                placeholder={props.placeholder}
                step={props.timeStep}
                maxLength={props.maxLength}
                onWheel={event => { event.currentTarget.blur(); }}
                {...!props.blnModal && {onBlur : !props.blnModal && props.validation.handleBlur}}
                {...!props.blnModal && {onBlurCapture : !props.blnModal && (props.onBlurFunction ? triggerOnBlurFunction : ()=> {} )}}
                {...!props.blnModal && {invalid : !props.blnModal && !props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}}
                {...!props.enableAutoComplete && {autoComplete: "new-password"}}
                onKeyPress={(event) => {
                  if (props.blnNumberOnly) {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                }}
                // invalid={!props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}
              />
            </InputGroup>
          </Row>
          :
          props.type === Constants.radio || props.blnMultipleCheckbox
          ?
          props.options
          &&
          props.options.map((option, index) => (
            <DoubleColumnRowParent key={`radio_${option.value}_${index}`}>
              <Input
                key={`radio_${option.value}_${index}_input`}
                name={props.type === Constants.radio ? props.name : option.label}
                className={props.classNameInput}
                onChange={props.type === Constants.radio ? () => handleChange({target: { value : option.value}}) : handleChange}
                type={props.type}
                defaultChecked={props.type === Constants.radio ? option.value === valueFinal : returnValueFinal(option.label)}
                disabled={props.disabled}
                placeholder={props.placeholder}
                // invalid={!props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}
                onWheel={(event) => { event.currentTarget.blur(); }}
                {...!props.enableAutoComplete && {autoComplete: "new-password"}}
                {...!props.blnModal && {onBlur : !props.blnModal && props.validation.handleBlur}}
                {...!props.blnModal && {onBlurCapture : !props.blnModal && (props.onBlurFunction ? triggerOnBlurFunction : ()=> {} )}}
                {...!props.blnModal && {invalid : !props.blnModal && !props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}}
                onKeyPress={(event) => {
                  if (props.blnNumberOnly) {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                }}
              />
              &nbsp;<label>{option.label}</label>
            </DoubleColumnRowParent>
          ))
          :
          <div className='flex-direction-row'>
            <Input
              name={props.name}
              className={`${props.classNameInput}`}
              type={props.type}
              onChange={(props.onChangeFunction && handleChange) || handleChange}
              value={
                (props.type === 'date' || props.type === 'datetime-local')
                ? 
                valueFinal 
                ? 
                moment(valueFinal).isValid() 
                ? 
                props.type === 'date'
                ?
                moment(valueFinal).format("YYYY-MM-DD") 
                :
                moment(valueFinal).format("YYYY-MM-DDTHH:mm") 
                : 
                valueFinal 
                : 
                valueFinal 
                : 
                valueFinal
              }
              checked={valueFinal}
              disabled={props.disabled}
              placeholder={props.placeholder}
              // invalid={!props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}
              step={props.timeStep}
              maxLength={props.maxLength}
              {...props.type === 'checkbox' && {style: {minWidth: '12px'}}}
              {...props.type === 'date' && {min: props.minCurrentDate ? props.minDateValue ? new Date(props.minDateValue).toISOString().split("T")[0] : new Date().toISOString().split("T")[0] : undefined}}
              {...props.type === 'datetime-local' && {min: props.minCurrentDate ? props.minDateValue ? new Date(props.minDateValue).toISOString().slice(0, -8) : new Date().toISOString().slice(0, -8) : undefined}}
              {...props.maxCurrentDate && {max: props.type === 'date' ? moment().toISOString().split("T")[0] : moment().toISOString().slice(0, -8)}}
              {...props.maxCurrentDateWithHour && {max:  moment().endOf('day').format(Constants.textInputDateAndTimeFormat)}}
              {...props.maxDateOfTheMonth && {max: props.type === 'date' ? moment().endOf('month').toISOString().split("T")[0] : moment().endOf('month').toISOString().slice(0, -8)}}
              {...props.backendMaxDate && {max: moment('9999', 'YYYY').endOf('year').toISOString().split("T")[0]}}
              onWheel={(event) => { event.currentTarget.blur(); }}
              {...!props.enableAutoComplete && {autoComplete: "new-password"}}
              {...!props.blnModal && {onBlur : !props.blnModal && props.validation.handleBlur}}
              {...!props.blnModal && {onBlurCapture : !props.blnModal && (props.onBlurFunction ? triggerOnBlurFunction : ()=> {} )}}
              {...!props.blnModal && {invalid : !props.blnModal && !props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}}
              onKeyPress={(event) => {
                if (props.blnNumberOnly) {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }

                if (props.onBlurFunction && event.key === Constants.enter) {
                  triggerOnBlurFunction();        
                }
              }}
            />
            {props?.childrenUI}
            {
              props.type === 'date'
              &&
              (isTablet || isMobile)
              &&
              !props.validationRequired
              &&
              !props.disabled
              &&
              <div 
                className="margin-left-8 pointer-clickable align-self-center"
                style={{
                  position: "absolute",
                  right: "20px",
                }}
                onClick={()=> {
                  let eventTemp = { 
                    target: {
                      value: ""
                    }
                  }
                  handleChange(eventTemp);
                }}>
                <CloseIcon 
                  size={22}
                  style={{color: "#cccccc"}}/>
              </div>
            }
          </div>
        }
        
        {!props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}
      </div>
    </div>
  )
}

