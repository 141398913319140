import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import MyButton from "../../../app/components/form/MyButton";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import _ from "lodash";
import { Constants } from "../../../app/constants/Constants";
import { UpdateCustomerSalesOrderObject } from "../../../app/models/customerOrder";
import { history } from "../../..";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  showPrompt: boolean;
  setShowPrompt: Function;
  title: string;
  intl: any;
  changePaymentModeNo: string;
  loading: boolean;
  buttonFunction: any;
  buttonTitle: string;
  defaultRemark?: string;
}

export default observer(function CancelOrRejectChangePaymentMode(props: Props) {
  const { commonStore } = useStore();
  const { successMessageWithoutNavigation } = commonStore;
  const [cancelChangePaymentModeDetail, setCancelChangePaymentModeDetail] = useState({remark: props.defaultRemark || ""});
  
  const hideModal = () => {
    props.setShowPrompt(false);
  }

	return (
		<Modal
      isOpen={props.showPrompt}
      toggle={() => {
        if (!props.loading || Boolean(successMessageWithoutNavigation)) {
          hideModal();
        }
      }}
      size="lg"
      centered>
      {
        <div className="padding-horizontal-20px">
          <div className="modal-header">
            <div>
              <h5 className="modal-title margin-top-0">{props.title}</h5>
            </div>
            {
              (!props.loading || Boolean(successMessageWithoutNavigation))
              && 
              <button
                type="button"
                onClick={() => { hideModal(); }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
          <div className="modal-body">
            <GeneralTextAreaForModal
              title={props.intl.formatMessage({ id: "Remark" })}
              name="remark"
              row={5}
              validationRequired={false}
              field={cancelChangePaymentModeDetail}
              setFieldValue={setCancelChangePaymentModeDetail}
              disabled={props.loading} />
          </div>
          {
            !Boolean(successMessageWithoutNavigation)
            &&
            <div className="mb-3 flex-right flex-direction-row">
              <MyButton
                type="button"
                class={`btn ${props.buttonTitle === Constants.cancel ? "btn-warning" : "btn-danger"}`}
                style={{ width: '20%' }}
                content={props.buttonTitle}
                onClick={async () => {
                  props.buttonFunction(cancelChangePaymentModeDetail.remark);
                }}
                disable={props.loading || Boolean(successMessageWithoutNavigation)}
                loading={props.loading}
              />
            </div>
          }
        </div>
      }
    </Modal>
	);
})
