import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table, Label } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { AddOptionsValueField, checkPermission, getBranchId, getBranchName, getBranchUser, getDays, paymentStatusColor, removeAllSpace, returnNewExpiredDate, returnPriceWithCurrency, returnSubBrandList, salesOrderStatusColor } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import moment from "moment";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { RoutesList } from "../../../app/constants/RoutesList";
import { Link } from 'react-router-dom';
import SelectWithTableListModal from "../../../app/components/modal/SelectWithTableListModal";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../../app/models/common";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  optionYearList: any;
  optionMonthList: any;
  addDSRAdjustment: Function;
}

export default observer(function DSRAdjustmentDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { customerStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { customerDropdownList, getCustomerDropdown, getCustomerExpiredPackage, getCustomerSalesOrder, customerSalesOrderList, customerSalesOrderTotalItems, setCustomerDropdownList } = customerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { successMessage, setErrorMessage } = commonStore;
  const pageSize = Constants.defaultPageSize;

  const [loadingAPI, setLoadingAPI] = useState(false);
  const [loadingAddDSR, setLoadingAddDSR] = useState(false);
  const [loadingSalesOrder, setLoadingSalesOrder] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingDay, setLoadingDay] = useState(false);

  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDay, setSelectedDay] = useState<number | string>(Number(moment().format('D')));
  const [dayList, setDayList] = useState<ObjectForDropdown[]>(getDays(selectedYear, selectedMonthValue));
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [salesOrderListModal, setSalesOrderListModal] = useState(false);
  const [dailySalesRecordOptionType, setDailySalesRecordOptionType] = useState<any>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const subBrandList = returnSubBrandList();

  const [errorState, setErrorState] = useState({
    year: false,
    month: false,
    day: false,
    branchId: false,
    customerId: false,
    salesOrderId: false,
    type: false,
    subBrandId: false
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      year: false,
      month: false,
      day: false,
      branchId: false,
      customerId: false,
      salesOrderId: false,
      type: false,
      subBrandId: false
    });
  }

  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const SalesOrderColumns = [
    {
      dataField: "customerSalesOrderNo",
      text: `${intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase()}.`,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerSalesOrder
              ?
              <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>
                {row.customerSalesOrderNo}
              </div>
          }
        </>
      )
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalAmount)}
        </>
      )
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalAmount - row.balance)}
        </>
      )
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.balance)}
        </>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "salesOrderStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.salesOrderStatus)}
            statusBackgroundColor={salesOrderStatusColor(row.salesOrderStatus)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Select" })} />
          </div>
        </>
      ),
    },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      year: selectedYear,
      month: selectedMonthValue,
      day: selectedDay,
      branchId: getBranchUser() ? selectedBranchId : "",
      branchName: getBranchUser() ? selectedBranchName : "",
      customerId: "",
      customerName: "",
      salesOrderId: "",
      salesOrderNo: "",
      type: "",
      subBrandName: "",
      subBrandId: ""
    },
    onSubmit: async (values) => {
    },
  }); 

  const blnNewJoinType = validation.values.type === Constants.newJoin;

  const hideModal = () => {
    validation.resetForm();
    props.setModal(false);
  }

  useEffect(() => {
    async function fetchDsrAPI() {
      setLoadingAPI(true)
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.dailySalesRecordType)
      ];
      let resultAPI = await Promise.all(aryAPI);

      if (resultAPI[1]) {
        let dailySalesRecordTypeTemp = AddOptionsValueField(resultAPI[1], "displayValue", "displayValue");
        setDailySalesRecordOptionType(dailySalesRecordTypeTemp.filter(valueDailySalesRecordTypeTemp => valueDailySalesRecordTypeTemp.displayValue !== Constants.resign && valueDailySalesRecordTypeTemp.displayValue !== Constants.upgrade && valueDailySalesRecordTypeTemp.displayValue !== Constants.closeProgram));
      }
      setLoadingAPI(false);
    }

    fetchDsrAPI();
  }, [])

  const fetchSalesOrderWithLoading = async (id: string) => {
    let indexCustomer = _.findIndex(customerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownList[indexCustomer].id,
        customerName: customerDropdownList[indexCustomer].preferredName,
        salesOrderId: "",
        salesOrderNo: "",
      }))
    }
    clearErrorState("customerId");
    clearCustomerDropdownList();
  };

  const fetchCustomerWithLoading = async (id: string, removeData: boolean = true) => {
    setDisplayCustomerDropdown(false);
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    let objTemp : any = {};
    if (indexBranch > -1) {
      objTemp = { ...objTemp, branchId: branchDropDownList[indexBranch].id, branchName: branchDropDownList[indexBranch].name};
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
    }

    if (removeData) {
      objTemp = {
        ...objTemp,
        customerId: "",
        customerName: "",
        salesOrderId: "",
        salesOrderNo: "",
      }
    }

    validation.setValues((values)=> ({
      ...values,
      ...objTemp
    }))

    setTimeout(()=> {
      setDisplayCustomerDropdown(true);
    }, 100)
    clearErrorState("branchId");
  };

  const onClickFetchSalesOrderAPI = async(customerId: string) =>{
    setLoadingSalesOrder(true);
    await getCustomerSalesOrder({ pageNumber: 1, pageSize: pageSize, customerId: customerId })
    setLoadingSalesOrder(false);
    setSalesOrderListModal(true)
  }

  const onChangeYear = (value) => {
    validation.setValues((values) => ({
      ...values,
      year: Number(value),
      day: ""
    }))
    setLoadingDay(true);
    setDayList(getDays(value, selectedMonthValue));
    setTimeout(()=> {
      setLoadingDay(false)
    }, 100)
    clearErrorState("year");
  }

  const onChangeMonth = (value) => {
    validation.setValues((values) => ({
      ...values,
      month: Number(value),
      day: ""
    }))
    setLoadingDay(true);
    setDayList(getDays(selectedYear, value));
    setTimeout(()=> {
      setLoadingDay(false)
    }, 100)
    clearErrorState("month");
  }

  const onChangeDay = (value) => {
    validation.setValues((values) => ({
      ...values,
      day: Number(value)
    }))
    clearErrorState("day");
  }

  const onChangeDailySalesRecordType = (type) => {
    let typeTemp = removeAllSpace(type);

    if (typeTemp === Constants.newJoin) {
      validation.setFieldValue("salesOrderId", "");
      validation.setFieldValue("salesOrderNo", "");
    }
    else {
      validation.setFieldValue("subBrandId", "");
      validation.setFieldValue("subBrandName", "");
    }

    validation.setFieldValue("type", typeTemp);
    clearErrorState("type");
  }

  const onChangeSalesOrderModal = (row) => {
    validation.setValues((values) => ({
      ...values,
      salesOrderId: row.id,
      salesOrderNo: row.customerSalesOrderNo
    }))

    clearErrorState("salesOrderId");
    setSalesOrderListModal(false);
  }

  const onChangeSubBrand = (valueSubBrand) => {
    validation.setValues((values) => ({
      ...values,
      subBrandName: valueSubBrand.name,
      subBrandId: valueSubBrand.id
    }))
    clearErrorState("subBrandId");
  }

  const onClickSubmitDSRAdjustment = async (addDSRAdjustmentTemp: any) => {
    if (props.addDSRAdjustment) {
      setLoadingAddDSR(true);
      await props.addDSRAdjustment(addDSRAdjustmentTemp)
      setLoadingAddDSR(false);
    }
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingAddDSR && !successMessage) {
            hideModal();
          }
        }}
        size={"md"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          loadingAPI
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="">
              <div className="modal-header">
                <div className="flex-direction-row" style={{ flex: 1 }}>
                  <h5 className="modal-title margin-top-0" style={{ flex: 1 }}>{intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "DSRAdjustment" }) })}</h5>
                </div>
                {
                  !loadingAddDSR && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"year"}
                      title={intl.formatMessage({ id: "Year" })}
                      className={errorState.year ? "mb-0" : "mb-3"}
                      specifyReturnFieldName={[]}
                      labelField={"label"}
                      valueField={"value"}
                      options={props.optionYearList}
                      initialLabel={selectedYear}
                      initialValue={selectedYear}
                      setFieldValue={setSelectedYear}
                      disabled={Boolean(successMessage) || loadingAddDSR}
                      validationRequired={true}
                      validation={validation}
                      onChangeFunction={onChangeYear}
                      disableMenuPortalTarget={true} />
                    {errorState.year ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
                    ) : null}
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"month"}
                      title={intl.formatMessage({ id: "Month" })}
                      className={errorState.month ? "mb-0" : "mb-3"}
                      specifyReturnFieldName={[]}
                      labelField={"label"}
                      valueField={"value"}
                      options={props.optionMonthList}
                      initialLabel={selectedMonth}
                      initialValue={selectedMonthValue}
                      setFieldValue={setSelectedMonthValue}
                      disabled={Boolean(successMessage) || loadingAddDSR}
                      validationRequired={true}
                      validation={validation}
                      onChangeFunction={onChangeMonth}
                      disableMenuPortalTarget={true} />
                    {errorState.month ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })}</FormFeedback>
                    ) : null}
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent blnDoubleTab={true}>
                  {
                    loadingDay
                    ?
                    <Loading/>
                    :
                    <>
                      <DropDownWithTitle
                        name={"day"}
                        title={intl.formatMessage({ id: "Day" })}
                        specifyReturnFieldName={[]}
                        labelField={"label"}
                        valueField={"value"}
                        options={dayList}
                        initialLabel={validation.values.day}
                        initialValue={validation.values.day}
                        disabled={Boolean(successMessage) || loadingAddDSR}
                        validationRequired={true}
                        validation={validation}
                        onChangeFunction={onChangeDay}
                        disableMenuPortalTarget={true} />
                      {errorState.day ? (
                        <FormFeedback className="mb-3 margin-top--12" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Day" }) })}</FormFeedback>
                      ) : null}
                    </>
                  }
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchId"
                    className={errorState.branchId ? "mb-0" : "mb-3"}
                    options={branchDropDownList}
                    labelField={"name"}
                    valueField={"id"}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    disabled={loadingAPI || Boolean(successMessage) || loadingCustomer || getBranchUser() || loadingAddDSR}
                    onChange={fetchCustomerWithLoading}
                    validationRequired={true}
                  />
                  {errorState.branchId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                  ) : null}
                </SingleColumnRowParent>
                <Label>
                  <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "Type" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.type ? "mb-0" : "mb-3"}
                  options={dailySalesRecordOptionType}
                  onChange={onChangeDailySalesRecordType}
                  initialLabel={validation.values.type}
                  initialValue={validation.values.type}
                  blnValueWithNewSpace={true}
                  disabled={Boolean(successMessage) || loadingAddDSR}
                  validationRequired={true}
                />
                {errorState.type ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Type" }) })}</FormFeedback>
                ) : null}
                {
                  blnNewJoinType && subBrandList.length > 0
                  &&
                  <>
                    <Label>
                      <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                      <Label className="margin-bottom-0">{intl.formatMessage({ id: "SubBrand" })}</Label>
                    </Label>
                    <GeneralSelectDropdown
                      title=""
                      name=""
                      className={errorState.subBrandId ? "mb-0" : "mb-3"}
                      options={subBrandList}
                      labelField="name"
                      valueField="id"
                      initialLabel={validation.values.subBrandName}
                      initialValue={validation.values.subBrandId}
                      onChange={onChangeSubBrand}
                      blnReturnFullValue={true}
                      disabled={Boolean(successMessage) || loadingAddDSR}
                      validationRequired={true}
                    />
                    {errorState.subBrandId ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) })}</FormFeedback>
                    ) : null}
                  </>
                }
                {
                  displayCustomerDropdown 
                  &&
                    <>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralSelectDropdown
                          title={intl.formatMessage({ id: "Customer" })}
                          name="customerId"
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          className={errorState.customerId ? "mb-0" : "mb-3"}
                          options={customerDropdownList}
                          labelField={"preferredName"}
                          valueField={"id"}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          disabled={loadingAPI || Boolean(successMessage) || loadingAddDSR || !selectedBranchId}
                          onChange={fetchSalesOrderWithLoading}
                          validationRequired={true}
                          blnSupportCustomSearch={true}
                          onChangeCustomSearch={(value)=> {
                            fetchCustomerDropdown(value);
                          }}
                          onMenuOpenFunction={()=> {
                            clearCustomerDropdownList();
                            setLoadingCustomer(false);
                          }}
                          loadingCustomSearch={loadingCustomer}
                          setLoadingCustomSearch={setLoadingCustomer}
                        />
                        {errorState.customerId ? (
                          <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })}</FormFeedback>
                        ) : null}
                      </SingleColumnRowParent>
                      {
                        !blnNewJoinType
                        &&
                        <>
                          <SingleColumnRowParent blnDoubleTab={true}>
                            <GeneralInput
                              name={"salesOrderNo"}
                              title={intl.formatMessage({ id: "SalesOrder" })}
                              className={errorState.salesOrderId ? "mb-0" : "mb-3"}
                              classNameInput="width-100-percentage"
                              type="text"
                              disabled={
                                true
                              }
                              validation={validation}
                              validationRequired={true}
                              childrenUI={
                                <MyButton
                                  type="button"
                                  content={"Select"}
                                  class="btn btn-primary margin-left-8"
                                  onClick={() => { 
                                    onClickFetchSalesOrderAPI(validation.values.customerId) 
                                  }}
                                  loading={loadingSalesOrder}
                                  disable={!validation.values.customerId || loadingAddDSR || loadingSalesOrder || Boolean(successMessage)}
                                />
                              } />
                            {errorState.salesOrderId ? (
                              <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesOrder" }) })}</FormFeedback>
                            ) : null}
                          </SingleColumnRowParent>
                        </>
                      }
                    </>
                }
                <div className="mb-2 mt-4 flex-right">
                  {
                    !Boolean(successMessage)
                    &&
                    !loadingAddDSR
                    &&
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '25%' }}
                      content={Constants.submit}
                      onClick={() => {
                        const valuesTemp: any = _.cloneDeep(validation.values);
                        let errorStateTemp = {
                          year: false,
                          month: false,
                          day: false,
                          branchId: false,
                          customerId: false,
                          salesOrderId: false,
                          type: false,
                          subBrandId: false
                        };
                        let blnErrorFound = false;
                        if (!valuesTemp.day) {
                          errorStateTemp.day = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.branchId) {
                          errorStateTemp.branchId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.customerId) {
                          errorStateTemp.customerId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.salesOrderId && !blnNewJoinType) {
                          errorStateTemp.salesOrderId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.subBrandId && blnNewJoinType && subBrandList.length > 0) {
                          errorStateTemp.subBrandId = true;
                          blnErrorFound = true;
                        }
                        if (!valuesTemp.type) {
                          errorStateTemp.type = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          onClickSubmitDSRAdjustment(valuesTemp);
                        }
                      }}
                      disable={loadingAddDSR || Boolean(successMessage)}
                      loading={loadingAddDSR}
                    />
                  }
                </div>
              </div>
            </div>
        }
        {/* SalesOrderList */}
        {
          salesOrderListModal &&
          <SelectWithTableListModal
            blnShow={salesOrderListModal}
            setModal={setSalesOrderListModal}
            title={intl.formatMessage({ id: "SalesOrderList" })}
            options={customerSalesOrderList}
            keyField={"id"}
            fieldName={"id"}
            columns={SalesOrderColumns}
            validation={validation}
            onSelectFunction={onChangeSalesOrderModal}
            blnPagination={true}
            totalItem={customerSalesOrderTotalItems}
            requestAPI={getCustomerSalesOrder}
            paginationSubmitField={{ customerId: validation.values.customerId }} />
        }
      </Modal>
  )
})
