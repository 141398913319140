import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const MonthlySalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize } = commonStore;
  const { monthlySalesSummaryList, getMonthlySalesSummaryList, setMonthlySalesSummaryList, exportMonthlySalesSummary } = reportSalesStore; 
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType} = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>(AllStatus);
  const [selectedPaymentStatusId, setSelectedPaymentStatusId] = useState<number | string | undefined>(" ");
  const [paymentStatusList, setPaymentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const paramsSearch = useLocation().search;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  const aryMonthlySalesSummaryDetail : ObjectForReportRow[] = [
    {label: "Month", value: "month", type: "string"}, 
    {label: "TotalPaymentReceived", value: "totalPaymentReceived", type: "currency"}, 
    {label: "TotalInvoiceSales", value: "totalInvoiceSales", type: "currency"}, 
    {label: "TotalPackageSales", value: "totalPackageSales", type: "currency"}, 
    {label: "TotalProductSales", value: "totalProductSales", type: "currency"}, 
    {label: "Action", value: "routeUrl", type: "action"}
  ];
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {   
    setYearList(getYears()); 

    async function fetchMonthlySalesSummary() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.paymentStatus, AllStatus),
      ];
      
      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));
      setPaymentStatusList(resultAPI[1]);

      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const yearParams = new URLSearchParams(paramsSearch).get('year');

      if (yearParams) {
        setSelectedYear(Number(yearParams));

        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.monthlySalesSummary}` });
        await getMonthlySalesSummaryList({ branchId: branchIdParams || selectedBranchId, year: Number(yearParams), paymentStatus: selectedPaymentStatusId });
      }

			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchMonthlySalesSummary();

    return (()=> {
      setMonthlySalesSummaryList([]);
    })
  }, [])

  const fetchMonthlySalesSummaryWithLoading = async (blnExport : boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportMonthlySalesSummary({branchId: selectedBranchId, year: selectedYear, ...(selectedPaymentStatusId || selectedPaymentStatusId === 0) && { paymentStatus: selectedPaymentStatusId }});
    }else{
      await getMonthlySalesSummaryList({branchId: selectedBranchId, year: selectedYear, paymentStatus: selectedPaymentStatusId });
    }
    setLocalLoading(false);
	};

  //Table Content UI
  const MonthlySalesSummaryColumns = [
    { 
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "DailySalesSummary" })}
        rowFieldName={"monthlySalesDetails"}
        rowColumns={aryMonthlySalesSummaryDetail}
        keyFieldName={"month"}
        actionTitle={intl.formatMessage({ id: "ViewDailySalesSummary" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.dailySalesSummary}`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "MonthlySalesSummary"})})}
        title={intl.formatMessage({ id: "MonthlySalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchMonthlySalesSummaryWithLoading(true)
            }}
          />
        }>
        {
          !loading
          &&
          <>
            <Row>
                <Col xl="3">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchName"
                    className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                    options={branchDropDownListFinal}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    disabled={localLoading || loading || getBranchUser()}
                    validationRequired={true}
                    setFieldLabel={setSelectedBranchName}
                    setFieldValue={setSelectedBranchId}
                  />
                </Col>
                <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Payment" }) })}
                  name="paymentStatus"
                  options={paymentStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedPaymentStatus}
                  initialValue={selectedPaymentStatusId}
                  setFieldLabel={setSelectedPaymentStatus}
                  setFieldValue={setSelectedPaymentStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchMonthlySalesSummaryWithLoading(false)
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={MonthlySalesSummaryColumns}
            data={monthlySalesSummaryList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "MonthlySalesSummary"})})}
            objSorted={defaultSorted}
            keyField={"branchId"}
            requestAPI={getMonthlySalesSummaryList}
            branchId={selectedBranchId}
            selectedYear={selectedYear}
            paymentStatus={selectedPaymentStatusId}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(MonthlySalesSummary);
