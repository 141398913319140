import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo.svg";
import logoLightSvg from "../../../assets/images/logo-light.svg";

// Reactstrap
import { DropdownToggle, DropdownMenu, ButtonDropdown, Button, DropdownItem, Input } from "reactstrap";
import { isMobile, isTablet } from 'react-device-detect';

//common
import NotificationDropdown from './Header/NotificationDropdown';
import ProfileMenu from './Header/ProfileMenu';
import { IncludesLocationPathName, getBrandName, getBranchName, EqualLocationPathName, getBranchUser, validDisplayBranchDropdown, returnSupportStickyHeaderPath } from '../../common/function/function';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { BranchListObject } from '../../models/branch';
import MenuIcon from 'mdi-react/MenuIcon';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import _ from 'lodash';
import { RoutesList } from '../../constants/RoutesList';

interface IProps {
  path: string;
}

const HeaderMenu = (props: IProps) => {
  //Use Store
  const { commonStore, branchStore } = useStore();
  const { branchForHeaderDropDown } = branchStore;
  const { windowSize, setBranchRefresh, showHelpMenu, setShowHelpMenu } = commonStore;
  const [drp_primary1, setDrp_primary1] = useState(false)
  const document: any = window.document; //Added by Jason 07/05/2022 //https://code-examples.net/en/q/18ca285
  var Element_Copy: any = Element; //Added by Jason 07/05/2022 //https://code-examples.net/en/q/18ca285
  const isMobileApplicable = (window.screen.width <= 998 && isMobile && !isTablet) || (isTablet && windowSize.innerHeight > windowSize.innerWidth);
  const isMobileForHelpMenu = (window.screen.width <= 998 && isMobile && !isTablet);
  const blnValidDisplayBranchDropdown = validDisplayBranchDropdown();
  const [localSearchTerms, setLocalSearchTerms] = useState("");
  const [localBranchForHeaderDropDown, setLocalBranchForHeaderDropDown] = useState<BranchListObject[]>([]);
  const blnSupportStickyHeaderPath = returnSupportStickyHeaderPath(props.path);

  useEffect(()=> {
    if (!drp_primary1) {
      onClearSearch();
    }
  }, [drp_primary1])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element_Copy.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  //Toogle Side Menu
  function tToggle() {
    var body = document.body;
    if (isMobileApplicable) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  // const selectBranch = (strBranch: string) => {
  //   setSelectedBranchName(strBranch)
  //   setDrp_primary1(!drp_primary1)
  // }

  const displayBranchItem = (value : BranchListObject, index) => {
    return (
      <DropdownItem 
        key={`${value.name}_${index}`} 
        onClick={()=> {
          window.localStorage.setItem("branchId", value.id)
          window.localStorage.setItem("branchName", value.name)
          
          setBranchRefresh(true);
        }}>
          {value.name}
      </DropdownItem>
    )
  }

  const onClearSearch = () => {
    setLocalBranchForHeaderDropDown([]);
    setLocalSearchTerms("");
  }
  
  return (
    <div>
      <header id="page-topbar" className={blnSupportStickyHeaderPath ? "page-topbar-sticky-header" : ""}>
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-22 header-item "
              id="vertical-menu-btn">
              <MenuIcon size={22}/>
            </button>

            {
              !getBranchUser() && branchForHeaderDropDown.length > 0 &&
              <ButtonDropdown
                isOpen={drp_primary1}
                toggle={() => setDrp_primary1(!drp_primary1)}
                className={"d-inline-block"}
                style={{ alignSelf: 'center' }}
                disabled={!blnValidDisplayBranchDropdown}>
                <DropdownToggle caret color={!blnValidDisplayBranchDropdown ? "secondary" : "primary"} style={{height: "38px"}}>
                  {getBranchName()}
                  {blnValidDisplayBranchDropdown ? <i style={{marginLeft: '16px'}} className="mdi mdi-chevron-down"/> : <i />}
                </DropdownToggle>
                <DropdownMenu 
                  style={{
                    maxHeight: windowSize.innerHeight * 76 / 100,
                    overflow: 'auto'
                  }}>
                  <DropdownItem header>{getBrandName()}</DropdownItem>
                  {
                    !getBranchUser()
                    &&
                    <div style={{paddingTop: '4px', paddingBottom: '4px', paddingLeft: '16px', paddingRight: '16px', borderTopWidth: '1px solid'}}>
                      <Input
                        type="text"
                        placeholder='Type to search branch...'
                        onChange={(e)=> {
                          let searchTerm = e.target.value;
                          if (searchTerm) {
                            let branchDropdownTemp = _.cloneDeep(branchForHeaderDropDown);
                            const filteredItems = branchDropdownTemp.filter((valueBranchDropdownTemp) =>
                              valueBranchDropdownTemp.name.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            setLocalBranchForHeaderDropDown(filteredItems);
                          }
                          else {
                            setLocalBranchForHeaderDropDown([]);
                          }
                          setLocalSearchTerms(searchTerm);
                        }}>
                      </Input>
                    </div>
                  }
                  {
                    localBranchForHeaderDropDown.length > 0 || localSearchTerms !== ""
                    ?
                    localBranchForHeaderDropDown.map((value, index)=> displayBranchItem(value, index))
                    :
                    branchForHeaderDropDown.map((value, index)=> displayBranchItem(value, index))
                  }
                </DropdownMenu>
              </ButtonDropdown>
            }
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <NotificationDropdown />
            {
              !isMobileForHelpMenu
              &&
              <button
                type="button"
                onClick={() => {
                  setShowHelpMenu(!showHelpMenu);
                }}
                className="btn header-item">
                <HelpCircleOutlineIcon size={22}/>
              </button>
            }
            <ProfileMenu
              isMobileApplicable={isMobileForHelpMenu} />
          </div>
        </div>
      </header>
    </div>
  )
}


export default observer(HeaderMenu);