import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PackageMasterDataListObject, ProductMasterDataListObject, ServiceTreatmentMasterDataListObject } from "../models/reportMasterData";
import { downloadExcel, replaceAllStringWithArray } from "../common/function/function";

export default class ReportMasterDataStore {
  productMasterDataList: ProductMasterDataListObject[] = [];
  packageMasterDataList: PackageMasterDataListObject[] = [];
  serviceTreatmentMasterDataList: ServiceTreatmentMasterDataListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.productMasterDataList = [];
    this.packageMasterDataList = [];
    this.serviceTreatmentMasterDataList = [];
  }

  setProductMasterDataList = (productMasterDataListObject: ProductMasterDataListObject[]) => {
    this.productMasterDataList = productMasterDataListObject;
  }

  setPackageMasterDataList = (packageMasterDataListObject: PackageMasterDataListObject[]) => {
    this.packageMasterDataList = packageMasterDataListObject;
  }

  setServiceTreatmentMasterDataList = (serviceTreatmentMasterDataListObject: ServiceTreatmentMasterDataListObject[]) => {
    this.serviceTreatmentMasterDataList = serviceTreatmentMasterDataListObject;
  }

  getProductMasterDataList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
    const resultMasterDataProduct:any = await agent.ReportMasterData.productMasterDataList(PaginationRequestBody);
    
    resultMasterDataProduct.data = resultMasterDataProduct.data.map((valueResultMasterDataProduct, index)=> {
      valueResultMasterDataProduct.productDetails = valueResultMasterDataProduct.productDetails.map((valueMasterDataProductDetails, indexProductDetails)=> ({
        ...valueMasterDataProductDetails,
        randomId: `${valueResultMasterDataProduct.name}_${index}_${indexProductDetails}`
      }))
      return({
        ...valueResultMasterDataProduct,
        randomId: `${valueResultMasterDataProduct.name}_${index}`
      })
    })
      runInAction(() => {
        this.productMasterDataList = resultMasterDataProduct.data;
        store.commonStore.setTotalItem(resultMasterDataProduct.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productMasterDataList = [];
    }
  }

  exportProductMasterData = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/masterData/product/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPackageMasterDataList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
    const resultMasterDataPackage:any = await agent.ReportMasterData.packageMasterDataList(PaginationRequestBody);
    
    resultMasterDataPackage.data = resultMasterDataPackage.data.map((valueResultMasterDataPackage, index)=> {
      return({
        ...valueResultMasterDataPackage,
        randomId: `${valueResultMasterDataPackage.name}_${index}`, 
        branchPackages: replaceAllStringWithArray(valueResultMasterDataPackage.branchPackages, ["\r\n - ", "\n -"], ", ")
      })
    })
      runInAction(() => {
        this.packageMasterDataList = resultMasterDataPackage.data;
        store.commonStore.setTotalItem(resultMasterDataPackage.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.packageMasterDataList = [];
    }
  }

  exportPackageMasterData = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/masterData/package/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getServiceTreatmentMasterDataList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
    const resultMasterDataServiceTreatment:any = await agent.ReportMasterData.serviceTreatmentMasterDataList(PaginationRequestBody);
    
    resultMasterDataServiceTreatment.data = resultMasterDataServiceTreatment.data.map((valueResultMasterDataServiceTreatment, index)=> {
      return({
        ...valueResultMasterDataServiceTreatment,
        randomId: `${valueResultMasterDataServiceTreatment.name}_${index}`, 
        availableBranches: replaceAllStringWithArray(valueResultMasterDataServiceTreatment.availableBranches, ["\r\n - ", "\n- "], ", "),
        mandatoryProducts: (valueResultMasterDataServiceTreatment.mandatoryProducts !== "\r\n" && valueResultMasterDataServiceTreatment.mandatoryProducts !== "\n") ? valueResultMasterDataServiceTreatment.mandatoryProducts : "",
        optionalProducts: (valueResultMasterDataServiceTreatment.optionalProducts !== "\r\n" && valueResultMasterDataServiceTreatment.optionalProducts !== "\n") ? valueResultMasterDataServiceTreatment.optionalProducts : "",
      })
    })
      runInAction(() => {
        this.serviceTreatmentMasterDataList = resultMasterDataServiceTreatment.data;
        store.commonStore.setTotalItem(resultMasterDataServiceTreatment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.serviceTreatmentMasterDataList = [];
    }
  }

  exportServiceTreatmentMasterData = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/masterData/servicetreatment/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}