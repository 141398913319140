
import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { DayOffConfirmationDetailObject, EmployeeLeaveConfirmationDetailObject, EmployeeLeaveDeleteWithDateRangeObject, EmployeeLeaveImportObject, EmployeeLeaveListObject } from "../../app/models/employeeLeave";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, firstDayOfCurrentMonthToLastDayOfCurrentMonth, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, getBrandId, getMonths, getYears, leaveStatusColor, newSpaceBeforeCapitalLetter, returnBlobImage } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import moment from "moment";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from "reactstrap";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import Loading from "../../app/components/loading/Loading";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInputForModal from "../../app/components/form/GeneralInputForModal";
import ImportEmployeeLeaveModal from "./Modal/ImportEmployeeLeaveModal";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ImportDayOffLeaveModal from "./Modal/ImportDayOffLeaveModal";
import DeleteEmployeeLeaveModal from "./Modal/DeleteEmployeeLeaveModal";
import { DepartmentDropdownListObject } from "../../app/models/department";

const EmployeeLeave = () => {
  const intl = useIntl();
  //Use Store
  const { employeeLeaveStore, employeeStore, branchStore, staticReferenceStore, departmentStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, prevPath, windowSize, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { employeeLeaveList, employeeLeavePaginationParams, getEmployeeLeave, deleteEmployeeLeave, deleteEmployeeLeaveWithDateRange, importEmployeeLeave, exportEmployeeLeave, setEmployeeLeavePaginationParams, exportDayOffLeave, importDayOffLeave } = employeeLeaveStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { leaveType, leaveStatus, getStaticReferenceWithType } = staticReferenceStore;
  const { getDepartmentDropdown } = departmentStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<EmployeeLeaveListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState<string>(AllStatus);
  const [selectedLeaveStatusId, setSelectedLeaveStatusId] = useState<number | string | undefined>(" ");
  const [leaveStatusList, setLeaveStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState<string>(AllType);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState<number | string | undefined>(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const [leaveTypeList, setLeaveTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [employeeListFinal, setEmployeeListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(All);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(" ");
  const [importFileModal, setImportFileModal] = useState(false);
  const [importDayOffFileModal, setImportDayOffFileModal] = useState(false);
  const [blnHQStaff, setBlnHQStaff] = useState(getBranchUser() ? false : true);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedBranchDropdownName, setSelectedBranchDropdownName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchDropdownId, setSelectedBranchDropdownId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [departmentListFinal, setDepartmentListFinal] = useState<DepartmentDropdownListObject[]>([]);
  const AllDepartment = `${intl.formatMessage({ id: "AllDepartment" })}`;
  const [selectedDepartmentName, setSelectedDepartmentName] = useState(AllDepartment);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(" ");
  
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedImportYear, setSelectedImportYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedImportMonth, setSelectedImportMonth] = useState<string>(moment().format("MMM"));
  const [selectedImportMonthValue, setSelectedImportMonthValue] = useState<number>(Number(moment().format("M")));
  //const [objEmployeeImport, setObjEmployeeImport] = useState({isDeleteExisting: false, isExcludeDayOff: false});
  const [objEmployeeImport, setObjEmployeeImport] = useState({ isDeleteExisting: false });
  const [importedEmployeeLeaveDetails, setImportedEmployeeLeaveDetails] = useState<EmployeeLeaveConfirmationDetailObject[]>([]);
  const [importedEmployeeLeaveDeletedLeaveIds, setImportedEmployeeLeaveDeletedLeaveIds] = useState<any>([]);
  const [importedDayOffLeaveDetails, setImportedDayOffLeaveDetails] = useState<DayOffConfirmationDetailObject[]>([]);
  const [importedDayOffDeletedLeaveIds, setImportedDayOffDeletedLeaveIds] = useState<any>([]);
  const [modalImportedEmployeeLeaveDetails, setModalImportedEmployeeLeaveDetails] = useState<boolean>(false);
  const [modalImportedDayOffLeaveDetails, setModalImportedDayOffLeaveDetails] = useState<boolean>(false);
  const [modalDeleteEmployeeLeave, setModalDeleteEmployeeLeave] = useState<boolean>(false);
  const [importMenu, setImportMenu] = useState(false);
  const [exportMenu, setExportMenu] = useState(false);

  const blnPermissionCreateEmployeeLeave = checkPermission([PermissionConstants.CreateEmployeeLeave]);
  const blnPermissionUpdateEmployeeLeave = checkPermission([PermissionConstants.UpdateEmployeeLeave]);
  const blnPermissionDeleteEmployeeLeave = checkPermission([PermissionConstants.DeleteEmployeeLeave]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      referenceNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let employeeLeaveRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    employeeId: selectedEmployeeId,
    employeeName: selectedEmployeeName,
    leaveStatus: selectedLeaveStatusId,
    leaveStatusName: selectedLeaveStatus,
    leaveType: selectedLeaveTypeId,
    leaveTypeName: selectedLeaveType,
    branchId: !blnHQStaff ? selectedBranchDropdownId : "",
    branchName: !blnHQStaff ? selectedBranchDropdownName : "",
    departmentId: blnHQStaff ? selectedDepartmentId : "",
    departmentName: blnHQStaff ? selectedDepartmentName : "",
    dateRange: selectedDateRange,
    isFilterHQStaff: blnHQStaff,
    referenceNo: validation.values.referenceNo,
    ...!blnAllActive && {isFilterActiveStaff: blnIsActive}
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchEmployeeLeaveListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.employeeLeave)
      let blnFilterHQLocal : any = undefined;
      let blnFilterActiveStaff : any = undefined;
      let branchIdTemp = "";
      let departmentIdTemp = "";

      if (blnBackNavigation) {
        if (employeeLeavePaginationParams) {
          setCurrentPage(employeeLeavePaginationParams.pageNumber)
          setSelectedEmployeeId(employeeLeavePaginationParams.employeeId || "")
          setSelectedEmployeeName(employeeLeavePaginationParams.employeeName || "")
          setSelectedLeaveStatusId(employeeLeavePaginationParams.leaveStatus)
          setSelectedLeaveStatus(employeeLeavePaginationParams.leaveStatusName || "")
          setSelectedLeaveTypeId(employeeLeavePaginationParams.leaveType)
          setSelectedLeaveType(employeeLeavePaginationParams.leaveTypeName || "")
          setSelectedBranchDropdownId(employeeLeavePaginationParams.branchId || " ")
          setSelectedBranchDropdownName(employeeLeavePaginationParams.branchName || allBranch)
          branchIdTemp = employeeLeavePaginationParams.branchId || "";
          setSelectedDepartmentId(employeeLeavePaginationParams.departmentId || " ")
          setSelectedDepartmentName(employeeLeavePaginationParams.departmentName || AllDepartment)
          departmentIdTemp = employeeLeavePaginationParams.departmentId || "";
          setBlnHQStaff(employeeLeavePaginationParams.isFilterHQStaff || false)
          setSelectedDateRange(employeeLeavePaginationParams.dateRange || firstDayOfMonthToCurrentDay())
          validation.setFieldValue("referenceNo", employeeLeavePaginationParams.referenceNo)
          employeeLeaveRequestBody = employeeLeavePaginationParams;
          blnFilterHQLocal = employeeLeavePaginationParams.isFilterHQStaff;
          if(employeeLeavePaginationParams.hasOwnProperty('isFilterActiveStaff')){
            blnFilterActiveStaff = employeeLeavePaginationParams.isFilterActiveStaff;
            setBlnIsActive(employeeLeavePaginationParams.isFilterActiveStaff || false);
            setBlnAllActive(false);
          }
        }
      }
      else {
        setEmployeeLeavePaginationParams(undefined);
      }

      let aryAPI: any = [
        fetchEmployeeLeaveAPI(false),
        getBranchUser() //branch user no need call this api
        ?
        undefined
        :
        blnFilterHQLocal !== undefined 
        ?
        getEmployeeDropdown({ branchId: !blnFilterHQLocal ? branchIdTemp : "", departmentId: blnFilterHQLocal ? departmentIdTemp : "", isBranchStaff: !blnFilterHQLocal, isActive: blnFilterActiveStaff}) 
        : //Initial Calling
        getEmployeeDropdown({ branchId: "", isBranchStaff: !blnHQStaff }),
        getStaticReferenceWithType(Constants.leaveStatus, AllStatus),
        getStaticReferenceWithType(Constants.leaveType, AllType),
        getBranchDropDown(),
        getBranchUser() ? undefined : getDepartmentDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setEmployeeListFinal(arraySpliceInAllValue(resultAPI[1], All, false, "preferredName", "id"))
      setLeaveStatusList(resultAPI[2]);
      setLeaveTypeList(resultAPI[3]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[4], isEligibleAllBranch ? allBranch : "", true))
      setDepartmentListFinal(arraySpliceInAllValue(resultAPI[5], AllDepartment, false, "name", "id"))

      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageEmployeeLeave], true)) {
      return;
    }

    fetchEmployeeLeaveListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.employeeLeave)
        if (!blnSetParams) {
          setEmployeeLeavePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (
      (selectedLeaveStatusId || selectedLeaveStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchEmployeeLeaveAPI(true);
    }
  }, [selectedLeaveStatusId]);

  useEffect(() => {
    if (
      (selectedLeaveTypeId || selectedLeaveTypeId === 0) &&
      !initialCallAPI
    ) {
      fetchEmployeeLeaveAPI(true);
    }
  }, [selectedLeaveTypeId]);

  useEffect(() => {
    if (!initialCallAPI) {
      fetchEmployeeLeaveAPI(true);
    }
  }, [selectedDateRange, triggerSearch])

  useEffect(()=> {
     const fetchEmployeeWhenTypeChange = async () => {
      setLocalLoading(true);
      setLoadingEmployee(true);
      setCurrentPage(1);
      setSelectedEmployeeId(" ");
      setSelectedEmployeeName(All);
      let aryAPI: any = [
        fetchEmployeeLeaveAPI(true, 1, true),
        getEmployeeDropdown({ 
          branchId: !blnHQStaff? selectedBranchDropdownId : "", 
          departmentId: blnHQStaff ? selectedDepartmentId : "",
          isBranchStaff: !blnHQStaff ? true : false, 
          isActive: getBranchUser() ? true : blnAllActive ? undefined : blnIsActive 
        }),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setEmployeeListFinal(arraySpliceInAllValue(resultAPI[1], All, false, "preferredName", "id"))
      setLocalLoading(false);
      setLoadingEmployee(false);
     }
    if (!initialCallAPI) {
      fetchEmployeeWhenTypeChange()
    }
  }, [blnHQStaff, blnIsActive, blnAllActive, selectedBranchDropdownId, selectedDepartmentId])

  async function fetchEmployeeLeaveAPI(blnDropDown?: boolean, pageSizeInsert?: number, blnEmptyEmployeeId?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getEmployeeLeave({
      ...employeeLeaveRequestBody,
      ...pageSizeInsert && { pageNumber: pageSizeInsert },
      ...blnEmptyEmployeeId && { employeeId: " " },
      ...blnEmptyEmployeeId && { employeeName: All }
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: EmployeeLeaveListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickDeleteWithDateRange = async (employeeLeaveDeleteWithDateRangeObject: EmployeeLeaveDeleteWithDateRangeObject) => {
    let resultAPI = await deleteEmployeeLeaveWithDateRange(employeeLeaveDeleteWithDateRangeObject);
    if (resultAPI.status === Constants.success) {
      fetchEmployeeLeaveAPI(true, 1);
      setModalDeleteEmployeeLeave(false);
    }
  }

  //Table Content UI
  const EmployeeLeaveColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "employeeName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {`${row.employeeName} (${row.employeeNo})`}
        </div>
      ),
    },
    {
      dataField: "leaveType",
      text: intl.formatMessage({ id: "LeaveType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {newSpaceBeforeCapitalLetter(row.leaveType)}
        </div>
      ),
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.startDate).format(Constants.displayDateFormat)}`}
          </div>
        </>
      ),
    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.endDate).format(Constants.displayDateFormat)}`}
          </div>
        </>
      ),
    },
    {
      dataField: "totalLeaveDays",
      text: intl.formatMessage({ id: "No.OfDays" }).toUpperCase(),
    },
    {
      dataField: "appliedOn",
      text: intl.formatMessage({ id: "DateApplied" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.appliedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.appliedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "referenceNo",
      text: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={leaveStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageEmployeeLeave, PermissionConstants.UpdateEmployeeLeave, PermissionConstants.DeleteEmployeeLeave]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.employeeLeave}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionUpdateEmployeeLeave
              &&
              row.status !== Constants.approved
              &&
              <TableActionButton
                to={`/${RoutesList.employeeLeave}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteEmployeeLeave
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const exportEmployeeLeaveReport = async (blnExport: boolean, blnIsDayOff: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      if (!blnIsDayOff) {
        await (exportEmployeeLeave({ exportType: 2, branchId: getBranchId() }))
      }
      else {
        await (exportDayOffLeave({ exportType: 2, branchId: getBranchId() }))
      }
    }
    setLocalLoading(false);
  }

  const importEmployeeLeaveFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: EmployeeLeaveImportObject = {
          isDeleteExisting: objEmployeeImport.isDeleteExisting,
          //isExcludeDayOff: objEmployeeImport.isExcludeDayOff,
          year: selectedImportYear || 0,
          month: selectedImportMonthValue || 0,
          branchId: selectedBranchId,
          file: fileTemp
        };
        let resultImportEmployeeLeave = await importEmployeeLeave(valuesTemp);
        if (resultImportEmployeeLeave?.employeeLeaveDetails) {
          let employeeLeaveDetailsTemp = _.cloneDeep(resultImportEmployeeLeave.employeeLeaveDetails);
          employeeLeaveDetailsTemp = employeeLeaveDetailsTemp.map((valueEmployeeLeaveDetailsTemp) => ({
            ...valueEmployeeLeaveDetailsTemp,
            employeeNameDisplay: `${valueEmployeeLeaveDetailsTemp.employeeName} ${valueEmployeeLeaveDetailsTemp.employeeNo ? `(${valueEmployeeLeaveDetailsTemp.employeeNo})` : ""}`,
            leaveTypeDisplay: `${newSpaceBeforeCapitalLetter(valueEmployeeLeaveDetailsTemp.leaveType)}`,
            startDateDisplay: `${moment(valueEmployeeLeaveDetailsTemp.startDate).format(Constants.displayDateFormat)}`,
            endDateDisplay: `${moment(valueEmployeeLeaveDetailsTemp.endDate).format(Constants.displayDateFormat)}`,
            backgroundColor: valueEmployeeLeaveDetailsTemp.isInvalidEmployeeNo ? Constants.leadPink : valueEmployeeLeaveDetailsTemp.isDuplicatedEmployeeLeave ? Constants.leadYellow : "",
            totalLeaveDaysDisplay: `${valueEmployeeLeaveDetailsTemp.totalLeaveDays} ${valueEmployeeLeaveDetailsTemp.isHalfDay ? valueEmployeeLeaveDetailsTemp.leavePeriod : ""}`,
            isRequiredToAddEmployeeLeave: !valueEmployeeLeaveDetailsTemp.isInvalidEmployeeNo && !valueEmployeeLeaveDetailsTemp.isDuplicatedEmployeeLeave ? true : false,
            checkFieldDisabled: valueEmployeeLeaveDetailsTemp.isInvalidEmployeeNo || valueEmployeeLeaveDetailsTemp.isDuplicatedEmployeeLeave
          }))
          setImportedEmployeeLeaveDetails(employeeLeaveDetailsTemp);
          setImportedEmployeeLeaveDeletedLeaveIds(resultImportEmployeeLeave.deletedLeaveIds);
          setImportFileModal(false);
          setModalImportedEmployeeLeaveDetails(true);
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const importDayOffLeaveFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: EmployeeLeaveImportObject = {
          isDeleteExisting: objEmployeeImport.isDeleteExisting,
          //isExcludeDayOff: objEmployeeImport.isExcludeDayOff,
          year: selectedImportYear || 0,
          month: selectedImportMonthValue || 0,
          branchId: selectedBranchId,
          file: fileTemp
        };
        let resultImportDayOffLeave = await importDayOffLeave(valuesTemp);
        if (resultImportDayOffLeave?.dayOffDetails) {
          let dayOffLeaveDetailsTemp = _.cloneDeep(resultImportDayOffLeave.dayOffDetails);
          dayOffLeaveDetailsTemp = dayOffLeaveDetailsTemp.map((valueDayOffLeaveDetailsTemp) => ({
            ...valueDayOffLeaveDetailsTemp,
            employeeNameDisplay: `${valueDayOffLeaveDetailsTemp.employeeName} ${valueDayOffLeaveDetailsTemp.employeeNo ? `(${valueDayOffLeaveDetailsTemp.employeeNo})` : ""}`,
            leaveTypeDisplay: `${newSpaceBeforeCapitalLetter(valueDayOffLeaveDetailsTemp.leaveType)}`,
            startDateDisplay: `${moment(valueDayOffLeaveDetailsTemp.startDate).format(Constants.displayDateFormat)}`,
            backgroundColor: valueDayOffLeaveDetailsTemp.isInvalidEmployeeNo ? Constants.leadPink : valueDayOffLeaveDetailsTemp.isDuplicatedEmployeeLeave ? Constants.leadYellow : "",
            isRequiredToAddEmployeeLeave: !valueDayOffLeaveDetailsTemp.isInvalidEmployeeNo && !valueDayOffLeaveDetailsTemp.isDuplicatedEmployeeLeave ? true : false,
            checkFieldDisabled: valueDayOffLeaveDetailsTemp.isInvalidEmployeeNo || valueDayOffLeaveDetailsTemp.isDuplicatedEmployeeLeave
          }))
          setImportedDayOffLeaveDetails(dayOffLeaveDetailsTemp);
          setImportedDayOffDeletedLeaveIds(resultImportDayOffLeave.deletedLeaveIds);
          setImportDayOffFileModal(false);
          setModalImportedDayOffLeaveDetails(true);
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const refreshDataAfterEmployeeLeaveImport = (blnIsDayOff: boolean) => {
    setCurrentPage(1);
    fetchEmployeeLeaveAPI(true, 1);
    setTimeout(() => {
      if (!blnIsDayOff) {
        setModalImportedEmployeeLeaveDetails(false);
        setImportedEmployeeLeaveDetails([]);
      }
      else {
        setModalImportedDayOffLeaveDetails(false);
        setImportedDayOffLeaveDetails([]);
      }
    }, 2000)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const importGeneralUI = () => {
    return (
      <div>
        <Row>
          <Col>
            <GeneralInputForModal
              title={intl.formatMessage({ id: "DeleteExisting" })}
              name="isDeleteExisting"
              type="checkbox"
              field={objEmployeeImport}
              setFieldValue={setObjEmployeeImport}
              childrenUI={
                <Label className="margin-bottom-0 margin-left-4">
                  Yes
                </Label>
              }
            // onChangeFunctionWithValueReturn={(valueIsDeleteExisting)=> {
            //   setObjEmployeeImport((valueObjEmployeeImport)=> ({
            //     ...valueObjEmployeeImport,
            //     isExcludeDayOff: valueIsDeleteExisting
            //   }))
            // }}
            />
          </Col>
          {/* {
            objEmployeeImport.isDeleteExisting
            &&
            <Col>
              <GeneralInputForModal
                title={intl.formatMessage({ id: "ExcludeDayOff" })}
                name="isExcludeDayOff"
                type="checkbox"
                field={objEmployeeImport}
                setFieldValue={setObjEmployeeImport}
                childrenUI={
                  <Label className="margin-bottom-0 margin-left-4">
                    Yes
                  </Label>
                }
              />
            </Col>
          } */}
        </Row>
        {
          objEmployeeImport.isDeleteExisting
          &&
          <>
            <Col xl="12">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Row>
              <Col xl="6">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedImportYear}
                  initialValue={selectedImportYear}
                  setFieldValue={setSelectedImportYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl="6">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  // styles={{marginLeft: '25px'}}
                  options={monthList}
                  initialLabel={selectedImportMonth}
                  initialValue={selectedImportMonthValue}
                  setFieldLabel={setSelectedImportMonth}
                  setFieldValue={setSelectedImportMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
            </Row>
          </>
        }
      </div>
    )
  }

  const returnImportExportDropdownButton = (blnImport: boolean) => {
    return (
      <ButtonDropdown
        isOpen={blnImport ? importMenu : exportMenu}
        toggle={() => blnImport ? setImportMenu(!importMenu) : setExportMenu(!exportMenu)}
        className={"d-inline-block"}
        disable={loading ? true : undefined}
        style={{ alignSelf: 'center' }}>
        <DropdownToggle
          className={`btn ${blnImport ? 'btn-primary' : 'btn-info'} margin-left-8`}
          id={`${blnImport ? `page-header-import-dropdown` : `page-header-export-dropdown`}`}
          tag="button"
          style={{ minWidth: '120px', height: "36px" , borderRadius:'0.25rem' }}>
          <span className="ms-2 me-1">{blnImport ? intl.formatMessage({ id: "Import" }) : intl.formatMessage({ id: "Export" })}</span>
          <ChevronDownIcon size={Constants.menuIconSize}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => blnImport ? setImportFileModal(true) : exportEmployeeLeaveReport(true, false)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Leave" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "LeaveTemplate" }) })
            }
          </DropdownItem>
          <DropdownItem tag="a" onClick={() => blnImport ? setImportDayOffFileModal(true) : exportEmployeeLeaveReport(true, true)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "DayOff" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "DayOffTemplate" }) })
            }
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeaveApplication" }) })}
        title={intl.formatMessage({ id: "LeaveApplication" })}
        addButton={
          blnPermissionCreateEmployeeLeave
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Leave" }) })}
              onClick={() => { history.push(`/${RoutesList.employeeLeave}/add`) }}
              linkTo={`/${RoutesList.employeeLeave}/add`}
              disable={loading} />
            {
              !getBranchUser()
              &&
              <>
                {/* import */}
                {returnImportExportDropdownButton(true)} 
                {/* export */}
                {returnImportExportDropdownButton(false)}
                {/* delete */}
                <MyButton
                  type={"button"}
                  class={`btn btn-danger margin-left-8`}
                  content={intl.formatMessage({ id: "DeleteByDate" })}
                  disable={loading ? true : false}
                  onClick={()=> {
                    setModalDeleteEmployeeLeave(true);
                  }}
                />
              </>
            }
          </>
        }
        addButtonXL={"6"}>
        {
          !getBranchUser()
          &&
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              {
                loadingEmployee
                ?
                <Loading/>
                :
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Employee" })}
                  name="employeeName"
                  options={employeeListFinal}
                  labelField={"preferredName"}
                  valueField={"id"}
                  initialLabel={selectedEmployeeName}
                  initialValue={selectedEmployeeId}
                  setFieldLabel={setSelectedEmployeeName}
                  setFieldValue={setSelectedEmployeeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    onClickSearch()
                  }}
                />
              }
            </DoubleColumnRowParent>
            {
              blnHQStaff
              &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Department" })}
                  name="departmentName"
                  options={departmentListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedDepartmentName}
                  initialValue={selectedDepartmentId}
                  setFieldLabel={setSelectedDepartmentName}
                  setFieldValue={setSelectedDepartmentId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}/>
              </DoubleColumnRowParent>
            }
            {
              !blnHQStaff
              &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchNameDropdown"
                  options={branchListFinal}
                  initialLabel={selectedBranchDropdownName}
                  initialValue={selectedBranchDropdownId}
                  setFieldLabel={setSelectedBranchDropdownName}
                  setFieldValue={setSelectedBranchDropdownId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
            }
            <Col xl={'6'} md={'12'} xs={'12'} className={windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : ""}>
              <div className="btn-group" role="group">
                <input type="radio" className="btn-check v" name="blnStaffType" id="blnHQStaff" autoComplete="off" defaultChecked={blnHQStaff} disabled={localLoading || loading}
                  onClick={async ()=> {
                    setCurrentPage(1); 
                    setBlnHQStaff(true);
                  }}/>
                <label style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.actionButtonWidth}} className="btn btn-outline-primary" htmlFor="blnHQStaff">
                  {intl.formatMessage({ id: "HQStaff" })}
                </label>
                <input type="radio" className="btn-check" name="blnStaffType" id="blnBranchStaff" autoComplete="off" defaultChecked={!blnHQStaff} disabled={localLoading || loading}
                  onClick={async ()=> {
                    setCurrentPage(1);
                    setBlnHQStaff(false);
                  }}/>
                <label style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.actionButtonWidth}} className="btn btn-outline-primary" htmlFor="blnBranchStaff">
                  {intl.formatMessage({ id: "BranchStaff" })}
                </label>
              </div>
              <div className={`btn-group ${windowSize.innerWidth >= 566 ? "margin-left-16" : ""}`} role="group">
                <input type="radio" className="btn-check" name="blnViewAll" id="blnViewAll" autoComplete="off" disabled={localLoading || loading } defaultChecked={blnAllActive} checked={blnAllActive}
                  onClick={() => {
                    setCurrentPage(1);
                    setBlnAllActive(true);
                  }} />
                <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnViewAll">{intl.formatMessage({ id: "All" })}</label>
                <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveTrue" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnIsActive} checked={blnIsActive && !blnAllActive}
                  onClick={() => {
                    setCurrentPage(1);
                    setBlnIsActive(true);
                    setBlnAllActive(false);
                  }} />
                <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: '120px', maxWidth: '120px' }} htmlFor="blnIsActiveTrue">{intl.formatMessage({ id: "ActiveStaff" })}</label>
                <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveFalse" autoComplete="off" disabled={localLoading || loading} defaultChecked={!blnIsActive} checked={!blnIsActive && !blnAllActive}
                  onClick={() => {
                    setCurrentPage(1);
                    setBlnIsActive(false);
                    setBlnAllActive(false);
                  }} />
                <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveFalse">{intl.formatMessage({ id: "Inactive" })}</label>
              </div>
            </Col>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Leave" }) })}
                name="leaveStatus"
                options={leaveStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedLeaveStatus}
                initialValue={selectedLeaveStatusId}
                setFieldLabel={setSelectedLeaveStatus}
                setFieldValue={setSelectedLeaveStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LeaveType" })}
                name="leaveType"
                options={leaveTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedLeaveType}
                initialValue={selectedLeaveTypeId}
                setFieldLabel={setSelectedLeaveType}
                setFieldValue={setSelectedLeaveTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRange" })}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange}
                validationRequired={true}
                initialValue={selectedDateRange} />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "ReferenceNo" })}
                name="referenceNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterReferenceNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={EmployeeLeaveColumns}
              data={employeeLeaveList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "EmployeeLeave" }) })}
              objSorted={defaultSorted}
              leaveStatus={selectedLeaveStatusId}
              leaveStatusName={selectedLeaveStatus}
              employeeId={selectedEmployeeId}
              employeeName={selectedEmployeeName}
              leaveType={selectedLeaveTypeId}
              leaveTypeName={selectedLeaveType}
              branchId={!blnHQStaff ? selectedBranchDropdownId : ""}
              branchName={!blnHQStaff ? selectedBranchDropdownName : ""}
              departmentId={blnHQStaff ? selectedDepartmentId : ""}
              departmentName={blnHQStaff ? selectedDepartmentName : ""}
              dateRange={selectedDateRange}
              referenceNo={validation.values.referenceNo}
              isFilterHQStaff={blnHQStaff}
              isFilterActiveStaff={!blnAllActive ? blnIsActive : undefined}
              requestAPI={getEmployeeLeave} />
        }
        <ImportFileModal
          blnShow={importFileModal}
          setModal={setImportFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "EmployeeLeave" }) })}
          btnFunction={importEmployeeLeaveFunction}
          // ExtraTopComponent={importGeneralUI}
        />
        <ImportFileModal
          blnShow={importDayOffFileModal}
          setModal={setImportDayOffFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "DayOff" }) })}
          btnFunction={importDayOffLeaveFunction}
          // ExtraTopComponent={importGeneralUI}
        />
        {
          modalImportedEmployeeLeaveDetails
          &&
          <ImportEmployeeLeaveModal
            blnShow={modalImportedEmployeeLeaveDetails}
            setModal={setModalImportedEmployeeLeaveDetails}
            importedEmployeeLeaveDetails={importedEmployeeLeaveDetails}
            setImportedEmployeeLeaveDetails={setImportedEmployeeLeaveDetails}
            refreshData={refreshDataAfterEmployeeLeaveImport}
            importedEmployeeLeaveDeletedLeaveIds={importedEmployeeLeaveDeletedLeaveIds} />
        }
        {
          modalImportedDayOffLeaveDetails
          &&
          <ImportDayOffLeaveModal
            blnShow={modalImportedDayOffLeaveDetails}
            setModal={setModalImportedDayOffLeaveDetails}
            importedDayOffLeaveDetails={importedDayOffLeaveDetails}
            setImportedDayOffLeaveDetails={setImportedDayOffLeaveDetails}
            refreshData={refreshDataAfterEmployeeLeaveImport}
            importedDayOffDeletedLeaveIds={importedDayOffDeletedLeaveIds} />
        }
        {
          modalDeleteEmployeeLeave
          &&
          <DeleteEmployeeLeaveModal
            blnShow={modalDeleteEmployeeLeave}
            setModal={setModalDeleteEmployeeLeave}
            optionYearList={yearList}
            optionMonthList={monthList}
            deleteEmployeeLeaveWithDateRange={onClickDeleteWithDateRange}
            setErrorMessage={setErrorMessage}/>
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteEmployeeLeave(selectedRow?.id || "");
            let currentPageTemp = currentPage;
            if (employeeLeaveList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getEmployeeLeave(employeeLeaveRequestBody)
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(EmployeeLeave);
