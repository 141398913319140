import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  contructValidationErrorMessage,
  getBranchId,
  IncludesLocationPathName,
  removeAllSpace,
  returnCurrency,
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ProductListObject } from "../../app/models/product";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { PurchaseOrderDetailObject, PurchaseOrderProductDetailsObject } from "../../app/models/purchaseOrder";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { ProductForBranchObject } from "../../app/models/branch";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const PurchaseOrderDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { staticReferenceStore, purchaseOrderStore, supplierStore, warehouseStore, measurementStore, branchStore, productStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addPurchaseOrder, updatePurchaseOrder, purchaseOrderDetail, setPurchaseOrderDetail, getPurchaseOrderWithId } = purchaseOrderStore;
  const { branchDropDownList, getBranchDropDown, getBranchProductList } = branchStore;
  const { supplierDropdownList, getSupplierDropdown } = supplierStore;
  const { warehouseDropdownList, getWarehouseDropdown, getWarehousePurchaseOrderDropdown } = warehouseStore;
  const { measurementDropdownList, getMeasurementDropdown } = measurementStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { createPurchaseOrderType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PurchaseOrder" })}`;
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "PurchaseOrder" })}`, urlPath: RoutesList.purchaseOrder }];
  const [activeTab, setActiveTab] = useState(1);
  const [loadingPurchaseOrder, setLoadingPurchaseOrder] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [productPurchaseOrderlist, setProductPurchaseOrderlist] = useState<PurchaseOrderProductDetailsObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [aryDynamicInputAndCheckBoxTabFinal, setAryDynamicInputAndCheckBoxTabFinal] = useState<ObjectForTableWithDynamicInputAndCheckBox[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "productName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "costPrice",
        inputType: "number",
        inputGroup: true,
        inputGroupText: `${returnCurrency()} `
      },
      {
        label: intl.formatMessage({ id: "OrderUom" }).toUpperCase(),
        type: "select",
        xl: "3",
        inputName: "additionalMeasurementId",
        inputType: "select",
        options: AddOptionsValueField(measurementDropdownList, "name", "id"),
        displayLabel: "additionalMeasurementName",
        displayValue: "additionalMeasurementId",
        specifyReturnFieldName: [{ field: "additionalMeasurementId", value: "id" }]
      },
      {
        label: intl.formatMessage({ id: "OrderUomQty" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "additionalMeasurementQuantity",
        inputType: "number"
      },
      {
        label: intl.formatMessage({ id: "Quantity(Unit)" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "quantity",
        inputType: "number",
        // numberMaxFieldName: "allowedQuantity",
      },
      {
        label: intl.formatMessage({ id: "TaxRate" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "taxRate",
        inputType: "number",
      },
      {
        label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "remark",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "MarkUp" }).toUpperCase(),
        type: "input",
        xl: "1",
        inputName: "isMarkup",
        inputType: "checkbox",
        checkBoxText: intl.formatMessage({ id: "Yes" })
      },
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: purchaseOrderDetail || {
      purchaseOrderType: "",
      purchaseFromSupplierId: undefined,
      purchaseFromSupplierName: "",
      toBranchId: "",
      toBranchName: "",
      warehouseId: undefined,
      warehouseName: "",
      note: "",
      purchaseOrderDetails: [],
      workflowAction: "",
      purchaseOrderStatus: ""
    },
    validationSchema: Yup.object({
      purchaseOrderType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PurchaseOrderType" }) })),
      toBranchId: Yup.string().test(
        "toBranchId",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }),
        (value: any, context) => {
          if (!blnExternalSupplierType && !blnPendingAllocationStatus) {
            return value;
          }
          return true;
        }
      ).nullable(),
      purchaseFromSupplierId: Yup.string().test(
        "purchaseFromSupplierId",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Supplier" }) }),
        (value: any, context) => {
          if (!blnOutletType && !blnPendingAllocationStatus) {
            return value;
          }
          return true;
        }
      ).nullable(),
      warehouseId: Yup.string().test(
        "warehouseId",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Warehouse" }) }),
        (value: any, context) => {
          if (!blnOutletType && !blnPendingAllocationStatus) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let purchaseOrderDetailsTemp = _.cloneDeep(valuesTemp.purchaseOrderDetails);
      let blnBlock = true;
      let blnEmptyQuantityBlock = false;
      purchaseOrderDetailsTemp = purchaseOrderDetailsTemp.map((value, index) => {
        if (value.quantity === "") {
          blnEmptyQuantityBlock = true
        }
        else if (!blnEmptyQuantityBlock && value.quantity > 0) {
          blnBlock = false
        }
      })
      if (blnBlock) {
        setErrorMessage(blnEmptyQuantityBlock ? "Please do not leave blank for Quantity" : "Please input Quantity")
        return;
      }
      try {
        if (addAction) {
          await addPurchaseOrder(valuesTemp);
        } else {
          await updatePurchaseOrder(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnExternalSupplierType = (validation.values.purchaseOrderType !== "" && validation.values.purchaseOrderType === Constants.purchaseFromExternalSupplier);
  const blnOutletType = (validation.values.purchaseOrderType !== "" && validation.values.purchaseOrderType === Constants.purchaseForOutlet);
  const blnStatusProcessing = (validation.values.purchaseOrderStatus === Constants.processing);
  const blnPartialCompleteStatus = validation.values.purchaseOrderStatus === Constants.partialComplete;
  const blnVoidStatus = validation.values.purchaseOrderStatus === Constants.void;
  const blnStatusCompletedOrPartialComplete = (validation.values.purchaseOrderStatus === Constants.completed || validation.values.purchaseOrderStatus === Constants.partialComplete);
  const blnPendingAllocationStatus = validation.values.purchaseOrderStatus === Constants.pendingAllocation;
  const blnPermissionUpdatePurchaseOrder = checkPermission([PermissionConstants.UpdatePurchaseOrder]);
  const blnPermissionReviewPurchaseOrder = checkPermission([PermissionConstants.ReviewPurchaseOrder])

  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;

  useEffect(() => {
    async function fetchPurchaseOrderDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.createPurchaseOrderType),
        getBranchDropDown(),
        getSupplierDropdown(),
        getWarehouseDropdown(),
        getProductDropdown(),
        getMeasurementDropdown()
      ];
      if (id && !addAction) {
        aryAPI.push(getPurchaseOrderWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPurchaseOrderDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePurchaseOrder, PermissionConstants.ReviewPurchaseOrder], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePurchaseOrder], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePurchaseOrder], true)) {
        return;
      }
    }

    if(viewAction && (blnPermissionUpdatePurchaseOrder || PermissionConstants.ReviewPurchaseOrder)){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.purchaseOrder}/edit/${id}`)} })
    }

    fetchPurchaseOrderDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPurchaseOrderDetail(undefined)
      }
    })
  }, []); 

  useEffect(() => {
    if (id && !addAction) {
      if (purchaseOrderDetail) {
        if (purchaseOrderDetail.purchaseOrderType === Constants.purchaseForOutlet) {
          validation.setFieldValue("toBranchId", purchaseOrderDetail.receiverId);
          validation.setFieldValue("toBranchName", purchaseOrderDetail.receiverName);
          fetchProductPurchaseOrderDropdownAPI(purchaseOrderDetail.receiverId, purchaseOrderDetail.purchaseOrderDetails)
        }
        else {
          onChangeRequestType(purchaseOrderDetail.purchaseOrderType, purchaseOrderDetail.purchaseOrderDetails)
        }
      }
    }
  }, [purchaseOrderDetail])

  const onChangeRequestType = (requestType, purchaseOrderDetailsTemp?: PurchaseOrderProductDetailsObject[]) => {
    try {
      if (removeAllSpace(requestType) === Constants.purchaseForOutlet) {
        validation.setFieldValue("purchaseOrderDetails", [])
      }
      else {
        let purchaseOrderProductListTemp = productDropdownList.map((valueProductList) => {
          let productIdFound = false;
          if (purchaseOrderDetailsTemp) {
            purchaseOrderDetailsTemp.map((valuePurchaseOrderDetail) => {
              if (valuePurchaseOrderDetail.productId === valueProductList.id) {
                productIdFound = true;
              }
            })
          }

          return {
            productId: valueProductList.id,
            productName: valueProductList.name,
            costPrice: valueProductList.costPrice,
            additionalMeasurementId: undefined,
            additionalMeasurementQuantity: 0,
            quantity: 0,
            taxRate: 0,
            remark: "",
            disabled: productIdFound
          }
        })

        if (!purchaseOrderDetailsTemp) {
          validation.setFieldValue("purchaseOrderDetails", [])
        }
        setProductPurchaseOrderlist(purchaseOrderProductListTemp);
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }

  const onChangeBranch = (branchId) => {
    try {
      if (branchId && blnOutletType) {
        fetchProductPurchaseOrderDropdownAPI(branchId)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }

  const fetchProductPurchaseOrderDropdownAPI = async (branchId: string, purchaseOrderDetailsTemp?: PurchaseOrderProductDetailsObject[]) => {
    setLoadingPurchaseOrder(true);
    try {
      let purchaseOrderDetailObject = await getBranchProductList(branchId);
      if (purchaseOrderDetailObject) {
        let productListTemp = purchaseOrderDetailObject.map((valuePurchaseOrderDetail: ProductForBranchObject) => {
          let productIdFound = false;
          if (purchaseOrderDetailsTemp) {
            purchaseOrderDetailsTemp = purchaseOrderDetailsTemp.map((valuePurchaseOrderDetailTemp) => {
              if (valuePurchaseOrderDetailTemp.productId === valuePurchaseOrderDetail.productId) {
                productIdFound = true;
                return ({ ...valuePurchaseOrderDetailTemp })
              }
              return valuePurchaseOrderDetailTemp;
            })
          }

          return ({
            productId: valuePurchaseOrderDetail.productId,
            productName: valuePurchaseOrderDetail.productName,
            quantity: 0,
            taxRate: 0,
            remark: "",
            disabled: purchaseOrderDetailsTemp ? productIdFound : true
          })
        });

        if (!purchaseOrderDetailsTemp) {
          validation.setFieldValue("purchaseOrderDetails", productListTemp)
        }
        else {
          validation.setFieldValue("purchaseOrderDetails", purchaseOrderDetailsTemp)
        }
        setProductPurchaseOrderlist(productListTemp);
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
    setLoadingPurchaseOrder(false);
  }

  useEffect(() => {
    if (blnExternalSupplierType) {
      validation.setFieldValue("toBranchId", undefined);
      let aryDynamicInputAndCheckBoxTabTemp: ObjectForTableWithDynamicInputAndCheckBox[] = []
      aryDynamicInputAndCheckBoxTab.map((value, index) => {
        if (blnStatusCompletedOrPartialComplete ? value.inputName !== 'isMarkup' && value.inputName !== 'allowedQuantity' && value.inputName !== 'quantity' && value.inputName !== 'receivedDate' : (value.inputName !== 'receivedQuantity' && value.inputName !== 'isMarkup' && value.inputName !== 'allowedQuantity' && value.inputName !== 'quantity' && value.inputName !== 'receivedDate')) {
          aryDynamicInputAndCheckBoxTabTemp.push({
            ...value,
            xl: index === 0 ? blnStatusCompletedOrPartialComplete ? "2" : "1.8" : "1.67"
          })
        }
        if (value.inputName === 'quantity') {
          aryDynamicInputAndCheckBoxTabTemp.push({
            ...value,
            numberMaxFieldName: "",
            xl: index === 0 ? blnStatusCompletedOrPartialComplete ? "2" : "1.8" : "1.67"
          })
        }
      })
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTabTemp)
    }
    else {
      validation.setFieldValue("purchaseFromSupplierId", undefined);
      let aryDynamicInputAndCheckBoxTabTemp: ObjectForTableWithDynamicInputAndCheckBox[] = []
      aryDynamicInputAndCheckBoxTab.map((value, index) => {
        if (blnStatusCompletedOrPartialComplete ? value.inputName !== 'costPrice' && value.inputName !== 'additionalMeasurementId' && value.inputName !== 'additionalMeasurementQuantity' && value.inputName !== 'taxRate' && value.inputName !== 'allowedQuantity' : (value.inputName !== 'receivedQuantity' && value.inputName !== 'costPrice' && value.inputName !== 'additionalMeasurementId' && value.inputName !== 'additionalMeasurementQuantity' && value.inputName !== 'taxRate')) {
          aryDynamicInputAndCheckBoxTabTemp.push({
            ...value,
            xl: index === 0 ? "4.2" : index === aryDynamicInputAndCheckBoxTab.length - 1 ? "1.2" : blnStatusCompletedOrPartialComplete ? "2.2" : blnStatusProcessing ? "3.4" : "3"
          })
        }
      })
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTabTemp)
    }
  }, [blnExternalSupplierType, blnOutletType]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisable = () => {
    if (!validation.values.purchaseOrderType) {
      return true;
    }
    else if (blnOutletType && !validation.values.toBranchId) {
      return true;
    }
    else if (blnExternalSupplierType && (!validation.values.purchaseFromSupplierId || !validation.values.warehouseId)) {
      return true;
    }
    return false;
  }
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PurchaseOrder" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PurchaseOrder" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent >
                          <DropDownWithTitle
                            name={"purchaseOrderType"}
                            title={intl.formatMessage({ id: "PurchaseOrderType" })}
                            className="mb-3 mt-4"
                            specifyReturnFieldName={[
                              {
                                field: "purchaseOrderType",
                                value: "displayValue",
                              },
                            ]}
                            labelField={"displayValue"}
                            valueField={"displayValue"}
                            options={createPurchaseOrderType}
                            blnValueWithNewSpace={true}
                            disabled={disabledFieldInput || viewAction || blnPendingAllocationStatus}
                            onChangeFunction={onChangeRequestType}
                            initialValue={!addAction ? validation.values.purchaseOrderType : undefined}
                            validationRequired={true}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        {blnExternalSupplierType && (
                          <SingleColumnRowParent>
                            <DropDownWithTitle
                              name={"purchaseFromSupplierId"}
                              title={intl.formatMessage({ id: "Supplier" })}
                              specifyReturnFieldName={[
                                {
                                  field: "purchaseFromSupplierId",
                                  value: "id",
                                },
                                {
                                  field: "purchaseFromSupplierName",
                                  value: "name",
                                }
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={supplierDropdownList}
                              disabled={disabledFieldInput || viewAction}
                              initialLabel={!addAction ? validation.values.purchaseFromSupplierName : undefined}
                              initialValue={!addAction ? validation.values.purchaseFromSupplierId : undefined}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        )}
                        {blnOutletType && (
                          <SingleColumnRowParent>
                            <DropDownWithTitle
                              name={"toBranchId"}
                              title={intl.formatMessage({ id: "Branch" })}
                              specifyReturnFieldName={[
                                {
                                  field: "toBranchId",
                                  value: "id",
                                },
                                {
                                  field: "toBranchName",
                                  value: "name",
                                }
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={branchDropDownList}
                              disabled={disabledFieldInput || viewAction}
                              onChangeFunction={onChangeBranch}
                              initialLabel={!addAction ? validation.values.toBranchName : undefined}
                              initialValue={!addAction ? validation.values.toBranchId : undefined}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        )}
                        {
                          blnExternalSupplierType
                          &&
                          <SingleColumnRowParent>
                            <DropDownWithTitle
                              name={"warehouseId"}
                              title={intl.formatMessage({ id: "Warehouse" })}
                              specifyReturnFieldName={[
                                {
                                  field: "warehouseId",
                                  value: "id",
                                },
                                {
                                  field: "warehouseName",
                                  value: "name",
                                }
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={warehouseDropdownList}
                              disabled={disabledFieldInput || viewAction}
                              initialLabel={!addAction ? validation.values.warehouseName : undefined}
                              initialValue={!addAction ? validation.values.warehouseId : undefined}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        }
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title="Note"
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="purchaseOrderDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={true}
                        blnBtnModalBottom={true}
                        loading={loadingPurchaseOrder}
                        options={productPurchaseOrderlist}
                        optionsId={blnOutletType ? "warehouseProductPricingBatchId" : "productId"}
                        setOptionsFunction={setProductPurchaseOrderlist}
                        addButtonFunction={() => setProductModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabFinal}
                        data={validation.values.purchaseOrderDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting || loadingPurchaseOrder
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-3" />
            <div className="flex-direction-row">
              {!addAction && !Boolean(successMessage) && !blnVoidStatus && !blnPartialCompleteStatus && !blnPendingAllocationStatus && !blnTab1 && !viewAction && blnPermissionUpdatePurchaseOrder && 
                <MyButton
                  type="submit"
                  class={`btn btn-warning margin-right-8`}
                  content={Constants.cancel}
                  disable={
                    validation.isSubmitting
                  }
                  loading={
                    validation.isSubmitting
                  }
                  onClick={() => {
                    validation.setFieldValue("workflowAction", Constants.cancel)
                  }}
                />
              }
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={(blnPendingAllocationStatus && blnPermissionReviewPurchaseOrder) || (!blnPendingAllocationStatus && blnPermissionUpdatePurchaseOrder)}
                extraLeftButtonTitle={blnPendingAllocationStatus ? intl.formatMessage({ id: "Reject" }) : intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor={blnPendingAllocationStatus ? "btn-danger" : "btn-primary"}
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", blnPendingAllocationStatus ? Constants.reject : Constants.save)
                }}
                extraLeftDirtyDisabled={true}
                hidePrimaryButton={blnStatusProcessing || blnTab1 ? true : blnPendingAllocationStatus && blnPermissionReviewPurchaseOrder ? false : !blnPendingAllocationStatus && blnPermissionUpdatePurchaseOrder ? false : true}
                submitButtonTitle={blnPendingAllocationStatus ? intl.formatMessage({ id: "Approve" }) : intl.formatMessage({ id: "Submit" })}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", blnPendingAllocationStatus ? Constants.approve : Constants.submit)
                }}
              />
            </div>
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={AddOptionsValueField(productPurchaseOrderlist, "name", "id")}
          setOptionsFunction={setProductPurchaseOrderlist}
          fieldInput={validation.values.purchaseOrderDetails}
          fieldName={"purchaseOrderDetails"}
          validation={validation} />
      </DetailViewLayout>
    </div >
  );
};

export default observer(PurchaseOrderDetail);
