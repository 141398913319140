import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { WarehouseListObject } from '../../app/models/warehouse';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";

const Warehouse = () => {
  const intl = useIntl();
  //Use Store
  const { warehouseStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt } = commonStore;
  const { warehouseList, getWarehouse, deleteWarehouse } = warehouseStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<WarehouseListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateWarehouse = checkPermission([PermissionConstants.CreateWarehouse]);
  const blnPermissionUpdateWarehouse = checkPermission([PermissionConstants.UpdateWarehouse]);
  const blnPermissionDeleteWarehouse = checkPermission([PermissionConstants.DeleteWarehouse]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchWarehouseAPI() {
      setLoading(true);
      await getWarehouse({ pageNumber: currentPage, pageSize: pageSize })
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageWarehouse], true)) {
      return;
    }

    fetchWarehouseAPI();
  }, [])

  const onClickDelete = async (row: WarehouseListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const WarehouseColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "contactPerson",
      text: intl.formatMessage({ id: "ContactPerson" }),
      formatter: (cellContent, row) => <>{`${row.contactPerson}`}</>,
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }),
      formatter: (cellContent, row) => <>{`${row.contactNo}`}</>,
    },
    {
      dataField: "stateName",
      text: intl.formatMessage({ id: "StateName" }),
    },
    {
      dataField: "countryName",
      text: intl.formatMessage({ id: "CountryName" }),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageWarehouse, PermissionConstants.UpdateWarehouse, PermissionConstants.DeleteWarehouse]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.warehouse}/view/${row.id}`}
              type={`view`}/>
            {
              blnPermissionUpdateWarehouse
              &&
              <TableActionButton 
                to={`/${RoutesList.warehouse}/edit/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeleteWarehouse
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Warehouse" }) })}
        title={intl.formatMessage({ id: "Warehouse" })}
        addButton={
          blnPermissionCreateWarehouse
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Warehouse" }) })}
            onClick={() => { history.push(`/${RoutesList.warehouse}/add`) }}
            linkTo={`/${RoutesList.warehouse}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={WarehouseColumns}
          data={warehouseList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Warehouse" }) })}
          objSorted={defaultSorted}
          requestAPI={getWarehouse} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteWarehouse(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (warehouseList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getWarehouse({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Warehouse);
