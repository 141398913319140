
import React from 'react'
import { newSpaceBeforeCapitalLetter } from '../../common/function/function';
interface Props {
  status: string;
  statusColorFunction?: Function;
  statusBackgroundColor?: string;
  blnDisableCenter?: boolean;
  className?: string;
  additionalTextLeft?: string;
}

export default function GeneralStatus(props: Props) {
  return (
    <div className={`${!props.blnDisableCenter ? "flex-justfity-content-center" : ""} ${props.className ? props.className : ""}`}>
      <span style={{ backgroundColor: props.statusBackgroundColor || (props.statusColorFunction ? props.statusColorFunction(props.status) : ""), color: "#FFFFFF", paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px', borderRadius: '5px' }}>{props.additionalTextLeft}{newSpaceBeforeCapitalLetter(props.status)}</span>
    </div>
  )
}