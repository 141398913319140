import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, purchaseDeliverOrderStatusColor } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import MyButton from "../../app/components/form/MyButton";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import TableEmptyText from "../../app/components/table/TableEmptyText";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const PurchaseDeliverOrderInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { purchaseDeliverOrderStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { updatePurchaseDeliverOrder, purchaseDeliverOrderDetail, setPurchaseDeliverOrderDetail, getPurchaseDeliverOrderWithId } = purchaseDeliverOrderStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PurchaseDeliverOrder" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PurchaseDeliverOrder" }), urlPath: RoutesList.purchaseDeliverOrder }];
  const invoiceFullDetail = purchaseDeliverOrderDetail || {
    id: "",
    isDelete: false,
    purchaseOrderId: "",
    purchaseDeliverOrderNo: "",
    businessEntityName: "",
    supplierName: "",
    supplierContactNo: "",
    receiverName: "",
    receiverContactNo: "",
    status: "",
    purchaseDeliverOrderDetails: [{
      productId: "",
      productName: "",
      quantity: 0,
      receivedQuantity: 0,
      remark: "",
    }]
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: purchaseDeliverOrderDetail || {
      purchaseOrderId: "",
      purchaseOrderNo: "",
      warehouseId: "",
      warehouseName: "",
      purchaseDeliverOrderDetails: [],
      status: "",
      workflowAction: "",
      supplierName: "",
      supplierContactNo: "",
      receiverName: "",
      receiverContactNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      let purchaseDeliverOrderDetailsTemp : any = [];
      valuesTemp.purchaseDeliverOrderDetails.map((valuePurchaseDeliverOrderDetailsTemp)=> {
        valuePurchaseDeliverOrderDetailsTemp.purchaseDeliverOrderBatches.map((valueBatchDetailsTemp)=> {
          purchaseDeliverOrderDetailsTemp.push({
            productId: valuePurchaseDeliverOrderDetailsTemp.productId,
            quantity: valueBatchDetailsTemp.quantity,
            remark: valueBatchDetailsTemp.remark || "",
            warehouseProductPricingBatchId: valueBatchDetailsTemp.warehouseProductPricingBatchId
          })
        })      
      })

      valuesTemp.purchaseDeliverOrderDetails = purchaseDeliverOrderDetailsTemp;

      try {
        if (invoiceFullDetail.status == Constants.processing || invoiceFullDetail.status == Constants.partialReceived) {
          await updatePurchaseDeliverOrder(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  async function fetchPurchaseDeliverOrderDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getPurchaseDeliverOrderWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePurchaseDeliverOrder], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePurchaseDeliverOrder], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePurchaseDeliverOrder], true)) {
        return;
      }
    }
    
    fetchPurchaseDeliverOrderDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPurchaseDeliverOrderDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 ">
          {label}
        </h6>
        <h6 className="">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  const blnProcessingStatus = invoiceFullDetail.status == Constants.processing;
  const blnPartialReceivedStatus = invoiceFullDetail.status == Constants.partialReceived;
  const blnReceivedOrCancelledStatus = invoiceFullDetail.status == Constants.received || invoiceFullDetail.status == Constants.cancelled;
  const blnAcknowledgedStatus = invoiceFullDetail.status == Constants.acknowledged;
  const blnPendingStatus = invoiceFullDetail.status == Constants.pending;
  const blnPermissionUpdatePurchaseDeliverOrder = checkPermission([PermissionConstants.UpdatePurchaseDeliverOrder]);

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "PurchaseDeliverOrder" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PurchaseDeliverOrderNo" })}:`, invoiceFullDetail.purchaseDeliverOrderNo)}
                          {/* {displayHeaderRowData("Date:", moment(invoiceFullDetail.requestedDate).format(Constants.displayDateFormat))}
                          {displayHeaderRowData("Time:", moment(invoiceFullDetail.requestedDate).format(Constants.displayTimeFormat))} */}
                        </Col>
                        <hr />
                        <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Billing" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Company" })}:`, invoiceFullDetail.supplierName)}
                              {displayBillingShippingRowData("Contact No.:", invoiceFullDetail.supplierContactNo)}
                              {/* {displayBillingShippingRowData("Name:", invoiceFullDetail.purchaseFromContactPerson)}
                              {displayBillingShippingRowData("Address:", invoiceFullDetail.purchaseFromAddress)}
                              {displayBillingShippingRowData("Payment method:", invoiceFullDetail.purchasePaymentMethod)} */}
                            </div>
                          </Col>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Receiver" })}:`, invoiceFullDetail.receiverName)}
                              {displayBillingShippingRowData("Contact No.:", invoiceFullDetail.receiverContactNo)}
                              {/* {displayBillingShippingRowData("Shipping Contact Person:", invoiceFullDetail.shippingContactPerson)}
                              {displayBillingShippingRowData("Shipping Address:", invoiceFullDetail.shippingAddress)} */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <br /><br />

                      <address>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.status}
                            statusColorFunction={purchaseDeliverOrderStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th/>
                              <th/>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.purchaseDeliverOrderDetails.length > 0
                              ?
                              invoiceFullDetail.purchaseDeliverOrderDetails.map((item, key) => {
                                return (
                                  <Fragment key={`${item.productName}_${key}`}>
                                    <tr key={key}>
                                      <td>{item.productName}</td>
                                      <td/>
                                      <td/>
                                    </tr>
                                    {
                                      item.purchaseDeliverOrderBatches
                                      ?
                                      item.purchaseDeliverOrderBatches.map((purchaseDeliverOrderBatchesTemp, index) => (
                                        <tr key={`${purchaseDeliverOrderBatchesTemp.supplierReferenceNo}_${index}`}>
                                          <td style={{borderRightWidth: '1px', width: '30%'}}>
                                            <i className="bx bx-subdirectory-right"></i> {`${intl.formatMessage({id: "WarehouseReferenceNo"})} : ${purchaseDeliverOrderBatchesTemp.warehouseReferenceNo}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            {`${intl.formatMessage({id: "Quantity"})} : ${purchaseDeliverOrderBatchesTemp.quantity}`}
                                          </td>
                                          <td style={{ width: "40px" }}><Input type='checkbox' className="margin-bottom-8 margin-top-0" style={{ width: "18px", height: "18px" }} disabled={true}></Input></td>
                                        </tr>
                                      ))
                                      :
                                      null
                                    }
                                  </Fragment>
                                )
                              })
                              :
                              <TableEmptyText 
                                moduleName="PurchaseDeliverOrder"/>
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-3" />
                      <div className="flex-direction-row d-print-none">
                        {!Boolean(successMessage) && !blnReceivedOrCancelledStatus && !blnPartialReceivedStatus && !blnAcknowledgedStatus && !blnPendingStatus && blnPermissionUpdatePurchaseDeliverOrder && 
                          <MyButton
                            type="submit"
                            class={`btn btn-warning margin-right-8`}
                            content={Constants.cancel}
                            disable={
                              validation.isSubmitting
                            }
                            loading={
                              validation.isSubmitting
                            }
                            onClick={() => {
                              validation.setFieldValue("workflowAction", Constants.cancel)
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={blnAcknowledgedStatus || blnReceivedOrCancelledStatus || blnPendingStatus || !blnPermissionUpdatePurchaseDeliverOrder}
                          submitButtonTitle={blnProcessingStatus ? Constants.acknowledge : blnPartialReceivedStatus ? Constants.complete : Constants.submit}
                          showPrint={true}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", blnProcessingStatus ? Constants.acknowledge : blnPartialReceivedStatus ? Constants.complete : Constants.submit)
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PurchaseDeliverOrderInvoiceDetail);
