import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { checkPermission, virtualWarehouseStatusColor, displayPromotionCode, displayWatermark, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, totalCountStatusColor, productRedemptionStatusColor, returnRouteFromModule, contructValidationErrorMessage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { Link } from 'react-router-dom';
import TableEmptyText from "../../app/components/table/TableEmptyText";
import MiniCard from "../../app/components/form/MiniCard";
import PaginationTable from "../../app/components/table/PaginationTable";

const CustomerVirtualWarehouseDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { customerVirtualWarehouseStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage
  } = commonStore;
  const { customerVirtualWarehouseDetail, customerVirtualWarehouseMovementsList, customerVirtualWarehouseMovementsTotalItem, setCustomerVirtualWarehouseDetail, getCustomerVirtualWarehouseWithId, getCustomerVirtualWarehouseMovement } = customerVirtualWarehouseStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerVirtualWarehouse" })}`;
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "VirtualWarehouse" })}`, urlPath: RoutesList.customerVirtualWarehouse }, { title: `${intl.formatMessage({ id: "CustomerVirtualWarehouse" })}`, urlPath: RoutesList.customerVirtualWarehouse }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionManageProductRedemptionNoteReturn = checkPermission([PermissionConstants.ManageProductRedemptionNoteReturn]);
  const blnPermissionManageVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [currentPageMovement, setCurrentPageMovement] = useState(1);
  const [loadingMovement, setLoadingMovement] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerVirtualWarehouseDetail || {
      id: "",
      virtualWarehouseNo: "",
      businessEntityName: "",
      branchName: "",
      customerId: "",
      customerName: "",
      deliverOrderId: "",
      deliverOrderNo: "",
      productName: "",
      quantity: 0,
      totalQuantity: 0,
      reservedQuantity: 0,
      redeemedQuantity: 0,
      creditExchangedQuantity: 0,
      returnedQuantity: 0,
      expiredQuantity: 0,
      reversedQuantity: 0,
      status: "",
      isExpired: false,
      redemptions: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const CustomerVirtualWarehouseMovementsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.actionDate).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "moveFrom",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
    },
    {
      dataField: "moveTo",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
    },
    {
      dataField: "movementType",
      text: intl.formatMessage({ id: "MovementType" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {newSpaceBeforeCapitalLetter(row.movementType)}
        </>,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    // {
    //   dataField: "reservedQuantity",
    //   text: intl.formatMessage({ id: "ReservedQuantity" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.reservedQuantity}
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "redeemedQuantity",
    //   text: intl.formatMessage({ id: "RedeemedQuantity" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.redeemedQuantity}
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "creditExchangedQuantity",
    //   text: intl.formatMessage({ id: "CreditExchangedQuantity" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.creditExchangedQuantity}
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "returnedQuantity",
    //   text: intl.formatMessage({ id: "ReturnedQuantity" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.returnedQuantity}
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "referenceEntityName",
    //   text: intl.formatMessage({ id: "ReferenceEntityName" }).toUpperCase(),
    //   formatter: (cellContent, row) =>
    //     <>
    //     {
    //       returnBooleanPermission(row.referenceEntity)
    //       ?
    //       <Link to={row.referenceEntityId ? `/${returnRouteFromModule(row.referenceEntity)}/view/${row.referenceEntityId}` : ""} target={Constants.blank} rel="noopener noreferrer">
    //         <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.referenceEntityName}</Label>
    //       </Link>
    //       :
    //       <div>{row.referenceEntityName}</div>
    //     }
    //     </>,
    // },
    // {
    //   dataField: "referenceEntity",
    //   text: intl.formatMessage({ id: "ReferenceEntity" }).toUpperCase(),
    //   formatter: (cellContent, row) =>
    //     <>
    //       {newSpaceBeforeCapitalLetter(row.referenceEntity)}
    //     </>,
    // },
    {
      dataField: "actionUserName",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
    },   
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"400px"}}>{row.action || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {row.remark || Constants.emptyData}
        </>,
    }
  ];

  async function fetchCustomerVirtualWarehouseDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCustomerVirtualWarehouseWithId(id),
        getCustomerVirtualWarehouseMovement({ pageNumber: 1, pageSize: pageSize, virtualWarehouseId: id})
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse], true)) {
      return;
    }

    fetchCustomerVirtualWarehouseDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCustomerVirtualWarehouseDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    if (localLoading) {
      validation.setSubmitting(true);
    }
    else {
      validation.setSubmitting(false);
    }
  }, [localLoading])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const returnBooleanPermission = (referenceEntity) => {
    if (referenceEntity == Constants.productRedemptionNote){
      return blnPermissionManageProductRedemptionNote
    }
    else if (referenceEntity === Constants.customerDeliveryOrder){
      return blnPermissionManageCustomerDeliverOrder
    }
    else if (referenceEntity === Constants.productRedemptionNoteReturn){
      return blnPermissionManageProductRedemptionNoteReturn;
    }
    else if (referenceEntity ===  Constants.customerVirtualWarehouseCreditExchange){
      return blnPermissionManageVirtualWarehouseCreditExchange;
    }
  }
  
  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end text-muted">
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <h6 className="text-end text-muted">
            {value}
          </h6>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}>
        {loading && !successMessage ? (
          <Loading />
        ) :
          (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="">
                      <div className="customerVirtualWarehouse-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "CustomerVirtualWarehouse" })}
                              </span>
                              <br />
                              <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                                {validation.values.virtualWarehouseNo}
                              </h3>
                              <h3 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {validation.values.businessEntityName}
                              </h3>
                              <h3 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {validation.values.branchName}
                              </h3>
                              <h3 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {`${intl.formatMessage({ id: "CustomerRedemptionNo" })}: `}
                                {
                                  blnPermissionManageCustomerDeliverOrder ?
                                  <Link to={validation.values.deliverOrderId ? `/${RoutesList.customerRedemption}/view/${validation.values.deliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                    <Label className="margin-bottom-0 pointer-clickable">{validation.values.deliverOrderNo || Constants.emptyData}</Label>
                                  </Link>
                                  :
                                  `${validation.values.deliverOrderNo}`
                                }
                              </h3>
                              <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {`${intl.formatMessage({ id: "Customer" })}: `}                              
                                {
                                  blnPermissionViewCustomer ?
                                    <Link to={validation.values.customerId ? `/${RoutesList.customer}/view/${validation.values.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                      <Label className="margin-bottom-0 pointer-clickable">{validation.values.customerName || Constants.emptyData}</Label>
                                    </Link>
                                  :
                                  `${validation.values.customerName}`
                                }
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "Product" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted">{validation.values.productName}</span>
                          </address>
                        </Col>
                        {/* <Col xs="6" className="text-end">
                          <address>
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "ShipTo" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted"> 
                            {
                              blnPermissionViewCustomer ?
                              <Link to={validation.values.customerId ? `/${RoutesList.customer}/view/${validation.values.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0">{validation.values.customerName || Constants.emptyData}</Label>
                              </Link>
                            :
                              `${validation.values.customerName}`
                            }
                            </span>
                          </address>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                            <br />
                            <div style={{ marginTop: '8px' }}>
                              <GeneralStatus 
                                status={validation.values.status}
                                statusColorFunction={virtualWarehouseStatusColor}
                                blnDisableCenter/>
                            </div>
                          </address>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <MiniCard
                          title={intl.formatMessage({ id: "TotalQty" })}
                          value={validation.values.totalQuantity}
                          size={2.4}
                          borderColor={Constants.lightblue}/>
                        {/* <MiniCard
                          title={intl.formatMessage({ id: "ReservedQty" })}
                          value={validation.values.reservedQuantity}
                          size={2.4}
                          borderColor={Constants.green}/> */}
                        <MiniCard
                          title={intl.formatMessage({ id: "RedeemedQty" })}
                          value={validation.values.redeemedQuantity}
                          size={2.4}
                          borderColor={Constants.green}/>
                        <MiniCard
                          title={intl.formatMessage({ id: "CreditExchangedQty" })}
                          value={validation.values.creditExchangedQuantity}
                          size={2.4}
                          borderColor={Constants.orange}/>
                        <MiniCard
                          title={intl.formatMessage({ id: "ReturnedQty" })}
                          value={validation.values.returnedQuantity}
                          size={2.4}
                          borderColor={Constants.orange}/>
                        <MiniCard
                          title={intl.formatMessage({ id: "ReversedQty" })}
                          value={validation.values.reversedQuantity}
                          size={2.4}
                          borderColor={Constants.chocalate}/>
                        {
                          validation.values.isExpired
                          &&
                          <MiniCard
                            title={intl.formatMessage({ id: "ExpiredQty" })}
                            value={validation.values.expiredQuantity}
                            size={2.4}
                            borderColor={Constants.red}/>
                        }
                      </Row>
                      {displayWatermark()}
                      <div className="py-2 margin-top-36">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "ProductRedemptions" }) })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "ProductRedemptionNo" })}</th>
                              <th>{intl.formatMessage({ id: "BusinessEntity" })}</th>
                              <th>{intl.formatMessage({ id: "Branch" })}</th>
                              <th>{intl.formatMessage({ id: "Customer" })}</th>
                              <th>{intl.formatMessage({ id: "PerformedDateTime" })}</th>
                              <th>{intl.formatMessage({ id: "Note" })}</th>
                              <th>{intl.formatMessage({ id: "Status" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              validation.values.redemptions.length > 0
                              ?
                              validation.values.redemptions.map((item, key) => (
                                <tr key={key}>
                                  <td>
                                    {
                                      blnPermissionManageProductRedemptionNote ?
                                      <Link to={item.id ? `/${RoutesList.productRedemptionNote}/view/${item.id}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                        <Label className="margin-bottom-0">{item.productRedemptionNo || Constants.emptyData}</Label>
                                      </Link>
                                      :
                                      `${item.productRedemptionNo}`
                                    }
                                  </td>
                                  <td>{item.businessEntityName}</td>
                                  <td>{item.branchName}</td>
                                  <td>{item.customerName}</td>
                                  <td>{moment(item.performedDateTime).format(Constants.displayDateFormat)}</td>
                                  <td>{item.note || Constants.emptyData}</td>
                                  <td>
                                    <GeneralStatus
                                      status={(item.status)}
                                      blnDisableCenter={true}
                                      statusBackgroundColor={productRedemptionStatusColor(item.status)}/>
                                  </td>
                                </tr>
                              ))
                              :
                              <TableEmptyText 
                                moduleName="ProductRedemptions"/>
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div style={{backgroundColor: Constants.localBackgroundColor, height: '20px', marginTop: '18px', marginLeft: '-100px', marginRight: '-100px'}}></div>
                      <div className="margin-top-36 margin-bottom--10">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Movements" })}
                        </h3>
                      </div>
                      <PaginationTable
                        title={""}
                        currentPage={currentPageMovement}
                        setCurrentPage={setCurrentPageMovement}
                        pageSize={pageSize}
                        totalItem={customerVirtualWarehouseMovementsTotalItem}
                        tableColumns={CustomerVirtualWarehouseMovementsColumns}
                        data={customerVirtualWarehouseMovementsList}
                        localLoading={loadingMovement}
                        setLocalLoading={setLoadingMovement}
                        emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Movements" }) })}
                        objSorted={defaultSorted}
                        hideSearchBar={true}
                        virtualWarehouseId={id}
                        blnRemoveBackground={true}
                        requestAPI={getCustomerVirtualWarehouseMovement} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CustomerVirtualWarehouseDetail);
