import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CacheListObject } from "../models/cache";
import { newSpaceBeforeCapitalLetter } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class cacheStore {
  cacheList: CacheListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.cacheList = [];
  }

  getCache = async (blnShowAll: boolean) => {
    try{
      let resultCache : any = await agent.Cache.cacheList(blnShowAll);
      resultCache = resultCache.map((valueCache, indexCache)=> ({
        id: `${valueCache}_${indexCache}`,
        name: valueCache
      }))
      runInAction(() => {
        this.cacheList = resultCache;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.cacheList = [];
    }
  }

  getCacheWithId = async (cacheKey: string) => {
    try{
      let resultCacheDetail : any = await agent.Cache.cacheDetail(cacheKey);
      return Promise.resolve({status: Constants.success, data: resultCacheDetail});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: ""});
    }
  }
  
  deleteCache  = async (name: string) => {
    try {
      await agent.Cache.deleteCache(name);
      store.commonStore.setSuccessMessage(`CacheDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCacheSize = async () => {
    try {
      let resultCacheSize = await agent.Cache.cacheSize();
      store.commonStore.setSuccessMessage(`Cache size is ${resultCacheSize}`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}