import React from "react"
import { Route, Redirect } from "react-router-dom"
import NotFound from "../features/Errors/NotFound";

interface IProps {
  exact?: boolean;
  path: string;
  layout: React.ComponentType<any>;
  component: React.ComponentType<any>;
  isAuthProtected: boolean;
  isLogin?: boolean;
  displayPermissionDenied: boolean;
}

const Authmiddleware = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    isLogin,
    displayPermissionDenied,
    path,
    ...rest
  }: IProps) => (
  <Route
    {...rest}
    render={props => {
      // if (isAuthProtected && !isLogin) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/login", state: { from: props.location } }}
      //     />
      //   )
      // }
      return (
        displayPermissionDenied
        ?
        <NotFound permissionDenied={true}/>
        :
        <Layout path={path}>
          {
            <div key={`${path}`}>
              <Component {...props}/>
            </div>
          }
        </Layout>
      )
    }}
  />
)

export default Authmiddleware;
