import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ServicesByEmployeeListObject, ServicesByEmployeeListGetObject, ServicesByEmployeeListExportObject } from "../models/reportServices";
import { downloadExcel } from "../common/function/function";

export default class ReportServicesStore {
  servicesByEmployeeList: ServicesByEmployeeListObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.servicesByEmployeeList = undefined;
  }

  setServicesByEmployeeList = (servicesByEmployeeListObject: ServicesByEmployeeListObject | undefined) => {
    this.servicesByEmployeeList = servicesByEmployeeListObject;
  }

  getServicesByEmployeeList = async (servicesByEmployeeListGetObject: ServicesByEmployeeListGetObject) => {
    try{
    let resultServicesByEmployee = await agent.ReportServices.servicesByEmployeeList(servicesByEmployeeListGetObject);
    
      runInAction(() => {
        this.servicesByEmployeeList = resultServicesByEmployee;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.servicesByEmployeeList = undefined;
    }
  }

  exportServicesByEmployee = async (servicesByEmployeeExportObject: ServicesByEmployeeListExportObject) => {
    try{
      await downloadExcel(`/report/services/byemployee/export`, servicesByEmployeeExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}