import { PaginationRequestBody } from "../models/pagination";
import { CustomerAccessRequestAddObject, CustomerAccessRequestDetailObject, CustomerAccessRequestExtendObject, CustomerAccessRequestListObject, CustomerAccessRequestUpdateObject } from "../models/customerAccessRequest";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
export default class CustomerAccessRequestStore {
  customerAccessRequestList: CustomerAccessRequestListObject[] = [];
  customerAccessRequestDetail: CustomerAccessRequestDetailObject | undefined = undefined;
  customerAccessRequestTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.customerAccessRequestList = [];
    this.customerAccessRequestDetail = undefined;
  }

  setCustomerAccessRequestList = (customerAccessRequestList: CustomerAccessRequestListObject[]) => {
    this.customerAccessRequestList = customerAccessRequestList;
  }
  
  setCustomerAccessRequestDetail = (customerAccessRequestDetail: CustomerAccessRequestDetailObject | undefined) => {
    this.customerAccessRequestDetail = customerAccessRequestDetail;
  }

  getCustomerAccessRequest = async (PaginationRequestBody: PaginationRequestBody, blnSetLocalTotalItem: boolean = false) => {
    try {
      const resultCustomerAccessRequests = await agent.CustomerAccessRequest.customerAccessRequestList(PaginationRequestBody);
      runInAction(() => {
        this.customerAccessRequestList = resultCustomerAccessRequests.data;
        if (!blnSetLocalTotalItem) {
          store.commonStore.setTotalItem(resultCustomerAccessRequests.pagination.totalItems);
        }
        else {
          this.customerAccessRequestTotalItems = resultCustomerAccessRequests.pagination.totalItems;
        }
      });
      return Promise.resolve({status: Constants.success, data: resultCustomerAccessRequests})
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerAccessRequestList = [];
      return Promise.resolve({status: Constants.failed, data: []})
    }
  };

  getCustomerAccessRequestWithId = async (id: string) => {
    try{
      const resultCustomerAccessRequestDetail = await agent.CustomerAccessRequest.customerAccessRequestDetail(id);
      runInAction(() => {
        this.customerAccessRequestDetail = resultCustomerAccessRequestDetail;
      });
      return Promise.resolve(resultCustomerAccessRequestDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerAccessRequestDetail = undefined;
    }
  }

  addCustomerAccessRequest = async (customerAccessRequestRequestBody: CustomerAccessRequestAddObject) => {
    try{
      await agent.CustomerAccessRequest.addCustomerAccessRequest(customerAccessRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerAccessRequestAddSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateCustomerAccessRequest = async (customerAccessRequestRequestBody: CustomerAccessRequestUpdateObject) => {
    try{
      await agent.CustomerAccessRequest.updateCustomerAccessRequest(customerAccessRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerAccessRequestUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomerAccessRequest  = async (id: string) => {
    try {
      await agent.CustomerAccessRequest.deleteCustomerAccessRequest(id);
      store.commonStore.setSuccessMessage(`CustomerAccessRequestDeleteSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  extendCustomerAccessRequest = async (customerAccessExtendRequestBody: CustomerAccessRequestExtendObject) => {
    try{
      await agent.CustomerAccessRequest.extendCustomerAccessRequest(customerAccessExtendRequestBody);
      store.commonStore.setSuccessMessage(`CustomerAccessRequestExtendSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
}
