import { PaginationRequestBody } from "../models/pagination";
import { MachineListObject, MachineAddObject, MachineUpdateObject, MachineDetailObject, MachineDropdownListObject, BranchMachineAddObject, BranchMachineListRequestObject, BranchMachineUpdateObject, MachineUsageListObject } from "../models/machine";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";

export default class MachineStore {
  machineList: MachineListObject[] = [];
  machineDropdownList: MachineDropdownListObject[] = [];
  machineDetail: MachineDetailObject | undefined = undefined;
  machinePaginationParams: PaginationRequestBody | undefined = undefined;
  machineUsageList: MachineUsageListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.machineList = [];
    this.machineDropdownList = [];
    this.machineDetail = undefined;
    this.machinePaginationParams= undefined;
    this.machineUsageList = [];
  }

  setMachineDetail = (machineDetail: MachineDetailObject | undefined) => {
    this.machineDetail = machineDetail;
  }
  
  setMachinePaginationParams = (machinePaginationParams: PaginationRequestBody | undefined) => {
    this.machinePaginationParams = _.cloneDeep(machinePaginationParams);
  }

  setMachineUsageList = (machineUsageList: MachineUsageListObject[]) => {
    this.machineUsageList = machineUsageList;
  }

  getMachine = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setMachinePaginationParams(PaginationRequestBody);
    PaginationRequestBody.name = encodeURIComponent(PaginationRequestBody.name || "");
    try{
      const resultMachine = await agent.Machine.machineList(PaginationRequestBody);
      runInAction(() => {
        this.machineList = resultMachine.data;
        store.commonStore.setTotalItem(resultMachine.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.machineList = [];
    }
  }

  getMachineUsage = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultMachineUsage = await agent.Machine.machineUsage(PaginationRequestBody);
      runInAction(() => {
        this.machineUsageList = resultMachineUsage.data;
        store.commonStore.setTotalItem(resultMachineUsage.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.machineUsageList = [];
    }
  }

  getMachineDropdown = async () => {
    try{
      const resultMachineDropdown = await agent.Machine.machineDropdownList();
      runInAction(() => {
        this.machineDropdownList = resultMachineDropdown;
      });
      return Promise.resolve(resultMachineDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.machineDropdownList = [];
    }
  }

  getMachineWithId = async (id: string) => {
    try{
      const resultMachineDetail = await agent.Machine.machineDetail(id);
      runInAction(() => {
        this.machineDetail = resultMachineDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.machineDetail = undefined;
    }
  }

  addMachine = async (machineRequestBody: MachineAddObject) => {
    try{
      await agent.Machine.addMachine(machineRequestBody);
      store.commonStore.setSuccessMessage(`MachineAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateMachine = async (machineRequestBody: MachineUpdateObject) => {
    try{
      await agent.Machine.updateMachine(machineRequestBody);
      store.commonStore.setSuccessMessage(`MachineUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteMachine  = async (id: string, name: string) => {
    try {
      await agent.Machine.deleteMachine(id);
      store.commonStore.setSuccessMessage(`MachineDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getBranchMachine = async (branchMachineListRequestBody: BranchMachineListRequestObject) => {
    try{
      const resultBranchMachine = await agent.Machine.branchMachineList(branchMachineListRequestBody);
      return Promise.resolve(resultBranchMachine);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addBranchMachine = async (branchMachineRequestBody: BranchMachineAddObject) => {
    try{
      await agent.Machine.addBranchMachine(branchMachineRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`BranchMachineAddSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateBranchMachine = async (branchMachineRequestBody: BranchMachineUpdateObject) => {
    try{
      await agent.Machine.updateBranchMachine(branchMachineRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`BranchMachineUpdateSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  resetBranchMachine = async (branchMachineId: string) => {
    try{
      await agent.Machine.resetBranchMachine(branchMachineId);
      store.commonStore.setSuccessMessageWithoutNavigation(`BranchMachineResetSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
}