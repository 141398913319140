import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, getBranchId, getBranchName, getBranchUser, getMonths, getYears, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import CashRefundIcon from 'mdi-react/CashRefundIcon';
import AccountCashIcon from 'mdi-react/AccountCashIcon';
import classNames from "classnames";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { Link, useLocation } from "react-router-dom";
import { history } from "../../..";

const SubBranchSalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { subBranchSalesSummary, getSubBranchSalesSummary, setSubBranchSalesSummary, exportSubBranchSalesSummary } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "EarnedSales" }).toUpperCase(), intl.formatMessage({ id: "SharedSales" }).toUpperCase()]);
  const [activeTab, setActiveTab] = useState("1");
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  const paramsSearch = useLocation().search;
  const yearParams = new URLSearchParams(paramsSearch).get('year');
  const monthParams = new URLSearchParams(paramsSearch).get('month');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());

    async function fetchSubBranchSalesSummary() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      if (yearParams && monthParams && branchParams) {
        setSelectedYear(Number(yearParams));
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, { value: Number(monthParams) });
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }
        let indexBranch = _.findIndex(resultAPI[0], { id: branchParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.sharedSalesSummary}` });
        await getSubBranchSalesSummary({ branchId: branchParams, year: Number(yearParams), month: Number(monthParams) });
      }
      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSubBranchSalesSummary();

    return (() => {
      setSubBranchSalesSummary(undefined);
    })
  }, [])

  const fetchSubBranchSalesSummaryWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportSubBranchSalesSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, salesOrderNo: validation.values.salesOrderNo })
    }
    else {
      await getSubBranchSalesSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, salesOrderNo: validation.values.salesOrderNo });
    }
    setLocalLoading(false);
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Table Content UI
  const SubBranchSalesSummaryColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "purchaseBranchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "shareBranchName",
      text: intl.formatMessage({ id: "SharedBranch" }).toUpperCase(),
    },
    {
      dataField: "salesOrderNo",
      text: `${intl.formatMessage({ id: "DocumentNo" }).toUpperCase()}.`,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerSalesOrder && !row.isAppolous
              ?
              <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
              </Link>
              :
              blnPermissionManageAppolousSales && row.isAppolous
              ?
              <Link to={row.salesOrderId ? `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.salesOrderNo}</div>
          }
        </>
      )
    },
    {
      dataField: "date",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.date !== Constants.invalidDate ? moment(row.date).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.date !== Constants.invalidDate ? moment(row.date).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "transactionReceivedAmount",
      text: intl.formatMessage({ id: "ReceivedAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.transactionReceivedAmount)}</div>
      ),
    },
    {
      dataField: "shareBranchPackageSalesAmount",
      text: intl.formatMessage({ id: "SplittedTransactionPackageSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.shareBranchPackageSalesAmount)} ${row.shareBranchPackageSalesAdjustmentAmount !== 0 ? `(${row.shareBranchPackageSalesAdjustmentAmount})` : ""}`}</div>
      ),
    },
    {
      dataField: "transactionPackageSalesAmount",
      text: intl.formatMessage({ id: "FinalPackageSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.shareBranchPackageSalesAmount + row.shareBranchPackageSalesAdjustmentAmount)}`}</div>
      ),
    },
    {
      dataField: "shareBranchProductSalesAmount",
      text: intl.formatMessage({ id: "SplittedTransactionProductSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.shareBranchProductSalesAmount)} ${row.shareBranchProductSalesAdjustmentAmount !== 0 ? `(${row.shareBranchProductSalesAdjustmentAmount})` : ""}`}</div>
      ),
    },
    {
      dataField: "transactionProductSalesAmount",
      text: intl.formatMessage({ id: "FinalProductSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.shareBranchProductSalesAmount + row.shareBranchProductSalesAdjustmentAmount)}`}</div>
      ),
    },
    {
      dataField: "shareBranchProductCommissionAmount",
      text: intl.formatMessage({ id: "SplittedProductCommission" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.shareBranchProductCommissionAmount)}</div>
      ),
    },
    {
      dataField: "isAppolous",
      text: intl.formatMessage({ id: "Appolous" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isAppolous ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SharedSalesSummary" }) })}
        title={intl.formatMessage({ id: "SharedSalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchSubBranchSalesSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Shared-Sales-Summary.aspx`}>
        <Row>
          {
            !loadingDropdownRefresh
            &&
            <>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  // styles={{marginLeft: '25px'}}
                  options={monthList}
                  initialLabel={selectedMonth}
                  initialValue={selectedMonthValue}
                  setFieldLabel={setSelectedMonth}
                  setFieldValue={setSelectedMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
            </>
          }
        </Row>
        <Row>
          <Col xl={"3"}>
            <GeneralInput
              title={intl.formatMessage({ id: "DocumentNo" })}
              name="salesOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchSubBranchSalesSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              {
                subBranchSalesSummary
                &&
                <>
                  <Row className="padding-top-10">
                    {
                      activeTab === '1'
                        ?
                        <>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashCheckIcon className="h2 text-primary mb-0" />)}
                              title={intl.formatMessage({ id: "TotalSalesEarned" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchSalesEarned)}
                              valueColor={subBranchSalesSummary.totalSubBranchSalesEarned !== 0 ? subBranchSalesSummary.totalSubBranchSalesEarned > 0 ? "text-green-color" : "text-red-color" : ""}
                              />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashCheckIcon className="h2 text-success mb-0" />)}
                              title={intl.formatMessage({ id: "TotalPackageSalesEarned" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchPackageSalesEarned)}
                              />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashCheckIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalProductSalesEarned" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchProductSalesEarned)}
                              />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<AccountCashIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalProductCommissionEarned" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchProductCommissionEarned)}
                              />
                          </Col>
                        </>
                        :
                        <>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashRefundIcon className="h2 text-primary mb-0" />)}
                              title={intl.formatMessage({ id: "TotalSalesShared" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchSalesShared)}
                              valueColor={subBranchSalesSummary.totalSubBranchSalesShared !== 0 ? subBranchSalesSummary.totalSubBranchSalesShared > 0 ? "text-red-color" : "" : ""}
                              cardColor={"card-red"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashRefundIcon className="h2 text-success mb-0" />)}
                              title={intl.formatMessage({ id: "TotalPackageSalesShared" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchPackageSalesShared)}
                              cardColor={"card-red"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashRefundIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalProductSalesShared" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchProductSalesShared)}
                              cardColor={"card-red"} />
                          </Col>
                          <Col xl="3" lg="6" sm="6" xs="12">
                            <CardInfo
                              icon={() => (<CashRefundIcon className="h2 text-warning mb-0" />)}
                              title={intl.formatMessage({ id: "TotalProductCommissionShared" }).toUpperCase()}
                              value={returnPriceWithCurrency(subBranchSalesSummary.totalSubBranchProductCommissionShared)}
                              cardColor={"card-red"} />
                          </Col>
                        </>
                    }
                  </Row>

                  <div style={{ marginBottom: "-20px" }} className="standard-layout">
                    <Nav className="nav nav-tabs nav-tabs-custom">
                      {tabBar.map((value, indexTab) => (
                        <NavItem key={`tabkey_${indexTab}`}>
                          <NavLink
                            disabled={indexTab == 1 && (false)}
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active:
                                activeTab ===
                                String(indexTab + 1),
                            })}
                            onClick={() => {
                              toggle(String(indexTab + 1));
                            }}
                          >
                            {value}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </>
              }
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={SubBranchSalesSummaryColumns}
                data={subBranchSalesSummary ? activeTab === "1" ? subBranchSalesSummary.earnedSubBranchSalesDetails : subBranchSalesSummary.sharedSubBranchSalesDetails : []}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: activeTab === "1" ? "EarnedSales" : "SharedSales" }) })}
                objSorted={defaultSorted}
                keyField={"randomId"}
                requestAPI={getSubBranchSalesSummary}
                branchId={selectedBranchId}
                selectedYear={selectedYear}
                selectedMonth={selectedMonthValue}
                customerSalesOrderNo={validation.values.salesOrderNo}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SubBranchSalesSummary);
