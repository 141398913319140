import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";
import { ChangePasscodeObject, ChangePasswordObject, ProfileDetailObject, ProfileUpdateObject } from "../models/profile";
import moment from "moment";

export default class ProfileStore {
  profileDetail: ProfileDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.profileDetail = undefined;
  }
  
  setProfileDetail = (profileDetail: ProfileDetailObject | undefined) => {
    this.profileDetail = profileDetail;
  }
    
  getProfileDetail = async () => {
    try{
      const resultProfile = await agent.Profile.profileDetail();
      runInAction(() => {
        let resultProfileTemp = _.cloneDeep({...resultProfile, lastLoginDateTime: moment(resultProfile.lastLoginDateTime).format(Constants.displayDateAndTimeFormat)})
        if (resultProfile.branchId) {
          if (resultProfile.branchId !== Constants.emptyId) {
            store.userStore.setBranchId(resultProfile.branchId);
            store.userStore.setBranchName(resultProfile.branchName);
          }
        } 
        this.profileDetail = resultProfileTemp;
      });
    }
    catch(error){
        console.error(error);
    }
  }

  updateProfile = async (profileRequestBody: ProfileUpdateObject | any) => {
    try{
      const resultUpdateProfile : any = await agent.Profile.updateProfile(profileRequestBody);
      store.commonStore.setSuccessMessage(`ProfileUpdateSuccess`);
      runInAction(()=> {
        if (resultUpdateProfile) {
          this.profileDetail = resultUpdateProfile;
        }
      })
      return Promise.resolve({status: Constants.success, data: resultUpdateProfile});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}});
    }
  }

  changePassword = async (profileRequestBody: ChangePasswordObject) => {
    try{
      await agent.Profile.changePassword(profileRequestBody);
      store.commonStore.setSuccessMessage(`PasswordUpdateSuccess`)
      setTimeout(()=> (
        store.userStore.logout(false, false)
      ), 2000)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  changePasscode = async (profileRequestBody: ChangePasscodeObject) => {
    try{
      await agent.Profile.changePasscode(profileRequestBody);
      store.commonStore.setSuccessMessage(`PasscodeUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}