import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import _ from 'lodash';
import {
  Container,
  Row,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import GeneralInput from '../../app/components/form/GeneralInput';
import GeneralTextArea from '../../app/components/form/GeneralTextArea';
import MyButton from '../../app/components/form/MyButton';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import Loading from "../../app/components/loading/Loading";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import TableWithSelectAllCheckBoxAndInput from "../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import classnames from "classnames"
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { ProductListObject } from "../../app/models/product";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { WarehouseDetailObject, WarehouseProductObject } from "../../app/models/warehouse";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import WarehouseProductModal from "./Modal/WarehouseProductModal";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const WarehouseDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { warehouseStore, countryStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addWarehouse, updateWarehouse, warehouseDetail, setWarehouseDetail, getWarehouseWithId,getWarehouseProducts, getWarehouseProductDetails } = warehouseStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const pageSize = Constants.maxPageSize;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Warehouse" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Warehouse" }), urlPath: RoutesList.warehouse }];
  const [activeTab, setActiveTab] = useState("1");
  const tabBar = addAction ? [intl.formatMessage({ id: "Basic" }).toUpperCase()] : [intl.formatMessage({ id: "Basic" }).toUpperCase(), intl.formatMessage({ id: "Products" }).toUpperCase()];
  const [loadingState, setLoadingState] = useState(false);

  const [productModal, setProductModal] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState<WarehouseProductObject | undefined>(undefined);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateWarehouse = checkPermission([PermissionConstants.UpdateWarehouse]);

  const viewProductBatchDetails = (index) => {
    if (validation.values.warehouseProducts.length > index) {
      setSelectedProductDetails(validation.values.warehouseProducts[index])
      setProductModal(true);
    }
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "ProductName" }),
      type: "label",
      xl: "3.85",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Quantity" }),
      type: "label",
      xl: "5.85",
      inputName: "quantity",
      inputType: "text",
    },
    {
      label: `${intl.formatMessage({ id: "BatchDetails" }).toUpperCase()}`,
      type: "dynamicButton",
      xl: "2.3",
      textAlign: true,
      inputName: "",
      inputType: "row",
      rowButtonTitle: "View Product Batch Details",
      dynamicButtonFunction: viewProductBatchDetails
    }
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: warehouseDetail || {
      name: "",
      description: "",
      contactPerson: "",
      contactNo: "",
      email: "",
      website: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      displayOrder: 1,
      warehouseProducts: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "WarehouseName" }) })),
      email: Yup.string().email().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Email" }) })),
      postcode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Postcode" }) })),
      addressLine1: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AddressLine1" }) })),
      contactPerson: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ContactPerson" }) })),
      contactNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ContactNo" }) })),
      countryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) })),
      stateId: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length > 0 && !value) {
            return false;
          }
          return true;
        }).nullable(),
      stateName: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        })
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }

      try {
        if (addAction) {
          await addWarehouse(valuesTemp);
        } else {
          await updateWarehouse(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchWarehouseDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getCountryDropdown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getWarehouseWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setWarehouseDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateWarehouse], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateWarehouse], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageWarehouse], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateWarehouse){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.warehouse}/edit/${id}`)} })
    }

    fetchWarehouseDetailAPI();

    return (() => {
      if (id && !addAction) {
        setWarehouseDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (warehouseDetail) {
        if (warehouseDetail.countryId) {
          getStateWithLoading(warehouseDetail.countryId, false)
        }
        onLoadWarehouseProduct(id, warehouseDetail.warehouseProducts)
      }
    }
  }, [warehouseDetail]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onLoadWarehouseProduct = async (value, warehouseProductsDetail?: WarehouseProductObject[]) => {
    setLoadingWarehouse(true);
    let resultWarehouseProducts = await getWarehouseProducts(value);

    validation.setFieldValue("warehouseProducts", resultWarehouseProducts)
    setLoadingWarehouse(false);
  }

  const getStateWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        stateId: "",
        stateName: ""
      }));
    }
    setLoadingState(false);
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const displayFooterButtons = () => {
    return (
      <GeneralSubmitAndCancelBtn
        successMessage={successMessage}
        viewAction={viewAction}
        validation={validation}
      />
    )
  }

  const handleMeasurementUnit = (measurementType: string = "") => {
    if (measurementType.toUpperCase() == 'UNIT') {
      validation.setFieldValue("measurementQuantity", 1)
    }
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {
          loading
            ?
            <Loading />
            :
            <Form
              className="checkout-tabs"
              onSubmit={(e) => {
                e.preventDefault();
                if (Object.keys(validation.errors).length > 0) {
                  setActiveTab("1");
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }, 100)
                }
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="2" sm="12">
                  {/* <Nav className="flex-column vertical-icon"> */}
                  <Nav className="flex-column" pills>
                    {
                      tabBar.map((value, indexTab) => (
                        <NavItem key={`tabkey_${indexTab}`}>
                          <NavLink
                            className={classnames({ active: activeTab === String(indexTab + 1) })}
                            onClick={() => {
                              toggle(String(indexTab + 1));
                            }}
                          >
                            <i className={`${indexTab === 0 ? "bx bx-cube d-block" : "bx bx-shopping-bag"} check-nav-icon mt-4 mb-2`} />
                            <p className="font-weight-bold mb-4">{value}</p>
                          </NavLink>
                        </NavItem>
                      ))
                    }
                  </Nav>
                </Col>
                <Col xl="10">
                  <TabContent
                    activeTab={activeTab}
                    className="mt-4 mt-md-0 content-layout-white"
                  >
                    <TabPane tabId="1">
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Warehouse" }) })}
                        h4Title />
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "WarehouseName" })}
                          name="name"
                          className="mb-3 mt-4"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <Row>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "ContactPerson" })}
                            name="contactPerson"
                            type="text"
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "ContactNo" })}
                            name="contactNo"
                            type="number"
                            blnNumberOnly={true}
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                      <Row>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "Email" })}
                            name="email"
                            type="text"
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "Website" })}
                            name="website"
                            type="text"
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "AddressLine1" })}
                          name="addressLine1"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "AddressLine2" })}
                          name="addressLine2"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <Row>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "City" })}
                            name="city"
                            type="text"
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "Postcode" })}
                            name="postcode"
                            type="text"
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                      <Row>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <DropDownWithTitle
                            name={"countryId"}
                            title={intl.formatMessage({ id: "Country" })}
                            specifyReturnFieldName={[
                              {
                                field: "countryId",
                                value: "id",
                              },
                            ]}
                            labelField={"name"}
                            valueField={"id"}
                            options={countryDropdownList}
                            disabled={disabledFieldInput || viewAction || loadingState}
                            onChangeFunction={getStateWithLoading}
                            initialLabel={!addAction ? validation.values.countryName : undefined}
                            initialValue={!addAction ? validation.values.countryId : undefined}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          {loadingState ? (
                            <Loading />
                          ) : (
                            stateList.length > 0
                              ?
                              <DropDownWithTitle
                                name={"stateId"}
                                title={intl.formatMessage({ id: "State" })}
                                specifyReturnFieldName={[
                                  {
                                    field: "stateId",
                                    value: "id",
                                  },
                                  {
                                    field: "stateName",
                                    value: "name",
                                  },
                                ]}
                                labelField={"name"}
                                valueField={"id"}
                                options={stateList}
                                disabled={disabledFieldInput || viewAction}
                                initialLabel={!addAction ? validation.values.stateName : undefined}
                                initialValue={!addAction ? validation.values.stateId : undefined}
                                validationRequired={stateList.length > 0 ? true : false}
                                validation={validation}
                              />
                              :
                              <GeneralInput
                                title={intl.formatMessage({ id: "StateName" })}
                                name="stateName"
                                type="text"
                                disabled={disabledFieldInput || viewAction}
                                validationRequired={true}
                                validation={validation}
                              />
                          )}
                        </DoubleColumnRowParent>
                      </Row>
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "Description" })}
                          name="description"
                          row={5}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "DisplayOrder" })}
                          name="displayOrder"
                          type="number"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      {displayFooterButtons()}
                    </TabPane>
                    <TabPane tabId="2">
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title />
                      <TableWithEditableInputFields
                          name="warehouseProducts"
                          title=""
                          buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                          blnEditable={false}
                          blnSupportWithModal={true}
                          blnBtnModalBottom={true}
                          hideAddButton={true}
                          addButtonFunction={() => setProductModal(true)}
                          aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                          data={validation.values.warehouseProducts}
                          validation={validation}
                          disabled={disabledFieldInput} />
                      <div className="mt-3" />

                      {displayFooterButtons()}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Form>
        }
        {
          productModal
          &&
          <WarehouseProductModal 
            blnShow={productModal}
            setModal={setProductModal}
            selectedProductDetails={selectedProductDetails}
            setSelectedProductDetails={setSelectedProductDetails}
            getWarehouseProductDetails={getWarehouseProductDetails}
            getWarehouseId={id}
            />
        }
      </DetailViewLayout>
    </div>
  );
}

export default observer(WarehouseDetail);
