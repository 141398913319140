import React, { useState, useEffect } from "react";
import { Col, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { newSpaceBeforeCapitalLetter, replaceAllStringWithArray } from "../../../app/common/function/function";
import { ObjectForTableWithDynamicInputAndCheckBox, WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import { UserRoleDetailObject, UserRoleListObject } from "../../../app/models/userRole";
import MyButton from "../../../app/components/form/MyButton";
import { WarehouseProductDetails, WarehouseProductObject } from "../../../app/models/warehouse";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedProductDetails: WarehouseProductObject | undefined;
  setSelectedProductDetails: Function;
  getWarehouseProductDetails: Function;
  getWarehouseId: string;
}

export default observer(function UserRolePermissionsListingModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { warehouseStore, commonStore } = useStore();
  const { warehouseDetail } = warehouseStore;
  const { setLoading } = commonStore;

  //Data List
  const defaultStockOutDetails = {
    productId: "",
    productName: "",
    pricingBatchDetails: []
  }

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedProductDetails(undefined);
  }

  useEffect(() => {
    if (props.selectedProductDetails) {
      fetchApi(props.selectedProductDetails.productId)
      // validationLocal.setFieldValue("pricingBatchDetails", props.selectedProductDetails.pricingBatchDetails);
    }
  }, [props.selectedProductDetails])

  const [loadingProduct, setLoadingProduct] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "WarehouseReferenceNo" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "warehouseReferenceNo",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "supplierReferenceNo",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "ReceivedDate" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "receivedDate",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "quantity",
        inputType: "text",
        disabled: true
      }
    ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      productId: "",
      productName: "",
      pricingBatchDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const fetchApi = async (value) => {
    setLoadingProduct(true);
    let resultWarehouseProducts = await props.getWarehouseProductDetails(props.getWarehouseId, value);
    if (resultWarehouseProducts) {
      let pricingBatchDetailsTemp = resultWarehouseProducts.pricingBatchDetails.map((valuePricingBatchDetails) => {
        return {
          ...valuePricingBatchDetails,
          supplierReferenceNo: valuePricingBatchDetails.supplierReferenceNo || Constants.emptyData,
          receivedDate: moment(valuePricingBatchDetails.receivedDate).format(Constants.displayDateFormat)
        }
      })

      validationLocal.setFieldValue("pricingBatchDetails", pricingBatchDetailsTemp)
    }
    setLoadingProduct(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="xl"
        centered>
        {
          !props.selectedProductDetails
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{props.selectedProductDetails.productName}</h5>
                </div>
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <LineBreakWithTittle
                  className="margin-top-8"
                    blnSkipMarginTop={true}
                    paddingBottom="0px"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PricingBatch" }) })}
                  />
                  <TableWithEditableInputFields
                    name="pricingBatchDetails"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                    blnEditable={true}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validationLocal.values.pricingBatchDetails}
                    validation={validationLocal}
                    disabled={false}
                    hideAddButton={true}
                    hideDeleteButton={true} />

                {/* <div className="mb-3 mt-4 flex-right">
                  <MyButton
                    type="button"
                    class="btn btn-back"
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Back" })}
                    onClick={() => {
                      hideModal();
                    }}
                  />
                </div> */}
              </div>
            </div>
        }
      </Modal>
  )
})