import React, { useEffect, useState } from 'react'
import {
  Modal,
  Row,
  Col,
  Label
} from "reactstrap";
import _ from 'lodash';
import { AddOptionsValueField, getBranchId, promptInfoMessage, redemptionCalculation } from "../../../../app/common/function/function";
import GeneralInputForModal from '../../../../app/components/form/GeneralInputForModal';
import { ProductBatchObject, RedeemPurchaseDetailObject } from "../../../../app/models/customerOrder";
import { EmployeeListObject } from "../../../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import MyButton from '../../../../app/components/form/MyButton';
import GeneralSelectDropdown from '../../../../app/components/form/GeneralSelectDropdown';
import { useIntl } from "react-intl";
import GeneralTextAreaForModal from '../../../../app/components/form/GeneralTextAreaForModal';
import TableWithEditableInputFields from '../../../../app/components/table/TableWithEditableInputFields';
import { useFormik } from 'formik';
import { ObjectForTableWithDynamicInputAndCheckBox } from '../../../../app/models/common';
import { BranchProductBatchListObject } from '../../../../app/models/branch';
import Loading from '../../../../app/components/loading/Loading';
import { AppolousCustomerPackageRedeemDetailObject, ServiceCustomerPackage } from '../../../../app/models/appolousCustomerPackage';
import GeneralCheckBoxWithDropdownFormat from '../../../../app/components/dropdown/GeneralCheckBoxWithDropdownFormat';
import { Constants } from '../../../../app/constants/Constants';

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedAppolousCustomerPackageRedeemDetail: AppolousCustomerPackageRedeemDetailObject | undefined;
  setSelectedAppolousCustomerPackageRedeemDetail: Function;
  employeeList: EmployeeListObject[];
  servicesList: ServiceCustomerPackage[];
  validation: any;
}

const AppolousCustomerPackageRedeemDetailModal = (props: Props) => {
  const intl = useIntl();
  //Use Store
  const { commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage
  } = commonStore;
  const optionsEmployeeList = AddOptionsValueField(props.employeeList, "preferredName", "id");
  const [reloadTable, setReloadTable] = useState(false);

  const disabledFieldInput = Boolean(successMessage);

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedAppolousCustomerPackageRedeemDetail(undefined);
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  const onChangeEmployee = (e: any) => {
    let selectedAppolousCustomerPackageRedeemDetailTemp = _.cloneDeep(props.selectedAppolousCustomerPackageRedeemDetail)
    if (selectedAppolousCustomerPackageRedeemDetailTemp) {
      selectedAppolousCustomerPackageRedeemDetailTemp.serviceByName = e.label;
      selectedAppolousCustomerPackageRedeemDetailTemp.serviceById = e.value;
      props.setSelectedAppolousCustomerPackageRedeemDetail(selectedAppolousCustomerPackageRedeemDetailTemp);
    }
  }

  const displayContent = () => {
    return (
      <div>
        <div className="modal-header">
          <div>
            <h5 className="modal-title margin-top-0">{props.selectedAppolousCustomerPackageRedeemDetail?.serviceName}</h5>
          </div>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
            <>
              <Row>
                <Col xl="12">
                  <GeneralInputForModal
                    title={`${intl.formatMessage({ id: "Sessions" })}`}
                    name="count"
                    type="number"
                    field={props.selectedAppolousCustomerPackageRedeemDetail}
                    setFieldValue={props.setSelectedAppolousCustomerPackageRedeemDetail}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={"12"}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "ServiceBy"})}
                    name=""
                    className="mb-3"
                    options={optionsEmployeeList}
                    onChange={onChangeEmployee}
                    initialLabel={props.selectedAppolousCustomerPackageRedeemDetail?.serviceByName || ""}
                    initialValue={props.selectedAppolousCustomerPackageRedeemDetail?.serviceById || ""}
                    blnReturnFullValue={true}
                    validationRequired={true}
                    disabled={false}
                  />
                </Col>
              </Row>
              {
                props?.selectedAppolousCustomerPackageRedeemDetail?.mandatoryProducts
                &&
                (
                  props?.selectedAppolousCustomerPackageRedeemDetail?.mandatoryProducts.length > 0
                    ?
                    <DropDownWithTitleMultiSelect
                      name={"mandatoryProducts"}
                      title={intl.formatMessage({ id: "MandatoryProducts" })}
                      specifyReturnFieldName={[{ "field": "label", "value": "value" }]}
                      returnFieldWithLabel={false}
                      labelField={"label"}
                      valueField={"value"}
                      initialValue={props.selectedAppolousCustomerPackageRedeemDetail?.mandatoryProductsUsed || []}
                      options={props.selectedAppolousCustomerPackageRedeemDetail?.mandatoryProducts}
                      disabled={true}
                      validation={props.validation}
                      field={props.selectedAppolousCustomerPackageRedeemDetail}
                      setFieldValue={props.setSelectedAppolousCustomerPackageRedeemDetail} />
                    :
                    null
                )
              }
              <Row>
                {
                  props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalProducts
                  &&
                  (
                    props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalProducts.length > 0
                      ?
                      <Col xl="6" xs="12">
                        <GeneralCheckBoxWithDropdownFormat
                          name={"availableOptionalProductsUsed"}
                          title={intl.formatMessage({ id: "OptionalProducts" })}
                          labelField={"label"}
                          valueField={"value"}
						              sectionHeaderField={"categoryName"}
                          initialValue={props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalProductsUsed || []}
                          options={props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalProducts}
                          disabled={disabledFieldInput}
                          field={props.selectedAppolousCustomerPackageRedeemDetail}
                          setFieldValue={props.setSelectedAppolousCustomerPackageRedeemDetail} />
                      </Col>
                      :
                      null
                  )
                }
                {
                  props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalBranchMachines
                  &&
                  (
                    props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalBranchMachines.length > 0
                      ?
                      <Col xl="6" xs="12">
                        <GeneralCheckBoxWithDropdownFormat
                          name={"optionalBranchMachinesUsed"}
                          title={intl.formatMessage({ id: "BranchMachines" })}
                          labelField={"label"}
                          valueField={"value"}
                          blnInputUI={true}
                          inputField={"consumeQuantity"}
                          inputType={"number"}
                          blnNumberOnly={true}
                          initialValue={props?.selectedAppolousCustomerPackageRedeemDetail?.optionalBranchMachinesUsed || []}
                          options={props?.selectedAppolousCustomerPackageRedeemDetail?.availableOptionalBranchMachines}
                          disabled={disabledFieldInput}
                          field={props.selectedAppolousCustomerPackageRedeemDetail}
                          setFieldValue={props.setSelectedAppolousCustomerPackageRedeemDetail} />
                      </Col>
                      :
                      null
                  )
                }
              </Row>
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Remark" })}
                name="remark"
                row={5}
                field={props.selectedAppolousCustomerPackageRedeemDetail}
                setFieldValue={props.setSelectedAppolousCustomerPackageRedeemDetail} 
                validationRequired={false}
                disabled={disabledFieldInput}/>
              {!Boolean(successMessage) &&
                <div className="">
                  <MyButton
                    type="button"
                    class={`btn btn-primary`}
                    content={intl.formatMessage({ id: "Save" })}
                    onClick={async () => {
                      if (props.selectedAppolousCustomerPackageRedeemDetail) {
                        let selectedAppolousCustomerPackageRedeemDetailFinal = _.cloneDeep(props.selectedAppolousCustomerPackageRedeemDetail);                    
                        let blnServiceByNotFound = (selectedAppolousCustomerPackageRedeemDetailFinal.serviceById === null || selectedAppolousCustomerPackageRedeemDetailFinal.serviceById === "");
                                     
                        let objSearch: any = {
                          appolousCustomerPackageId: selectedAppolousCustomerPackageRedeemDetailFinal.appolousCustomerPackageId,
                          appolousCustomerPackageServiceId: selectedAppolousCustomerPackageRedeemDetailFinal.appolousCustomerPackageServiceId
                        }

                        if (blnServiceByNotFound) {
                          setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) }))
                          return;
                        }

                        let indexTemp = _.findIndex(props.validation.values.redeemPurchaseDetails, objSearch)
                        let redeemPurchaseDetailsTemp = _.cloneDeep(props.validation.values.redeemPurchaseDetails)
                        if (indexTemp > -1) {
                          if (selectedAppolousCustomerPackageRedeemDetailFinal.count > 0) {
                            redeemPurchaseDetailsTemp[indexTemp] = selectedAppolousCustomerPackageRedeemDetailFinal;
                          }
                          else {
                            redeemPurchaseDetailsTemp.splice(indexTemp, 1)
                          }
                        }
                        else if (selectedAppolousCustomerPackageRedeemDetailFinal.count > 0) {
                          redeemPurchaseDetailsTemp.push(selectedAppolousCustomerPackageRedeemDetailFinal)
                        }
                        
                        promptInfoMessage(intl.formatMessage({ id: selectedAppolousCustomerPackageRedeemDetailFinal.count > 0 ? "AddedItemIntoRedemptionCartMessage" : "RemoveItemIntoRedemptionCartMessage" }, { quantity: selectedAppolousCustomerPackageRedeemDetailFinal.count, item: selectedAppolousCustomerPackageRedeemDetailFinal.serviceName }), Constants.serviceTreatmentOnly);
                        props.validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp);
                        hideModal();
                      }                    
                    }}
                  />
                </div>
              }
            </>
          }
        </div>
      </div>
    )
  }

  return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
      size="lg"
      onBlur={() => {
        dismissMessage();
      }}
    >
      {displayContent()}
    </Modal>
  )
}

export default observer(AppolousCustomerPackageRedeemDetailModal);