import React, { useState, useEffect } from "react";
import { Col, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox, WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import { BranchForProductObject } from "../../../app/models/product";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { returnCurrency } from "../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedProductDetails: BranchForProductObject | undefined;
  setSelectedProductDetails: Function;
  getBranchProductBatch: Function;
  getProductName: string;
  getProductId: string;
}

export default observer(function ProductBatchesDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedProductDetails(undefined);
  }

  useEffect(() => {
    if (props.selectedProductDetails) {
      validationLocal.setFieldValue("productName", props.getProductName);
      validationLocal.setFieldValue("branchName", props.selectedProductDetails.branchName);
      fetchApi(props.selectedProductDetails.branchId);
    }
  }, [props.selectedProductDetails])

  const [loadingProduct, setLoadingProduct] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
        type: "label",
        xl: "2.3",
        inputName: "referenceNo",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "costPrice",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
      },
      {
        label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "quantity",
        inputType: "text",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "ReservedQuantity" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "reservedQuantity",
        inputType: "text",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "actualQuantity",
        inputType: "text",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "DefectQuantity" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "defectQuantity",
        inputType: "text",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "ReceivedDate" }).toUpperCase(),
        type: "label",
        xl: "2.2",
        inputName: "receivedDate",
        inputType: "text",
      }
    ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchName: "",
      productName: "",
      branchProductBatchDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const fetchApi = async (value) => {
    setLoadingProduct(true);
    let resultBranchProducts = await props.getBranchProductBatch({productId: props.getProductId, branchId: value, isRequiredCheckQuantity: false})
    if (resultBranchProducts) {
      let branchProductBatchDetailsTemp = resultBranchProducts.map((valueBranchProductBatchDetails) => {
        return {
          ...valueBranchProductBatchDetails,
          warehouseSupplierReferenceNo: valueBranchProductBatchDetails.warehouseSupplierReferenceNo || Constants.emptyData,
          referenceNo: valueBranchProductBatchDetails.referenceNo || Constants.emptyData,
          receivedDate: valueBranchProductBatchDetails.receivedDate !== Constants.invalidDateTime ? moment(valueBranchProductBatchDetails.receivedDate).format(Constants.displayDateFormat) : Constants.emptyData,
          expiryDate: valueBranchProductBatchDetails.expiryDate !== Constants.invalidDateTime ? moment(valueBranchProductBatchDetails.expiryDate).format(Constants.displayDateFormat) : Constants.emptyData
        }
      })

      validationLocal.setFieldValue("branchProductBatchDetails", branchProductBatchDetailsTemp)
    }
    setLoadingProduct(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="xl"
        centered>
        {
          !props.selectedProductDetails || loadingProduct
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ProductBatchDetails" })}</h5>
                </div>
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <Row style={{ fontSize: '15px' }}>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "ProductName" })}
                    name="productName"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={validationLocal}
                  />
                </DoubleColumnRowParent>
                <DoubleColumnRowParent blnDoubleTab={true}>
                <GeneralInput
                    title={intl.formatMessage({ id: "BranchName" })}
                    name="branchName"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={validationLocal}
                  />
                </DoubleColumnRowParent>
              </Row>
                  <LineBreakWithTittle
                  className="margin-top-8"
                    blnSkipMarginTop={true}
                    paddingBottom="0px"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PricingBatch" }) })}
                  />
                  <TableWithEditableInputFields
                    name="branchProductBatchDetails"
                    title=""
                    className="mb-4"
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                    blnEditable={true}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validationLocal.values.branchProductBatchDetails}
                    validation={validationLocal}
                    disabled={true}
                    hideAddButton={true}
                    hideDeleteButton={true} />

                {/* <div className="mb-3 mt-4 flex-right">
                  <MyButton
                    type="button"
                    class="btn btn-back"
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Back" })}
                    onClick={() => {
                      hideModal();
                    }}
                  />
                </div> */}
              </div>
            </div>
        }
      </Modal>
  )
})