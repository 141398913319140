import { useEffect, useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import Loading from "../../../app/components/loading/Loading";
import { MediaSourceListObject } from "../../../app/models/mediaSource";
import ReferredByCustomerSearchModal from "../../Appointment/Modal/ReferredByCustomerSearchModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: any;
  customerName: string;
  sourceId: string;
  sourceName: string;
  referredById: string;
  referredByName: string;
  onClickUpdateCustomerMediaSource: Function;
  loadingEditCustomerMediaSource: boolean;
}

export default function EditCustomerMediaSourceModal(props: Props) {
  const intl = useIntl();
  const { commonStore, mediaSourceStore } = useStore();
  const { successMessage } = commonStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [mediaSourceDropdownLocal, setMediaSourceDropdownLocal] = useState<MediaSourceListObject[]>([])
  const [blnReferralType, setBlnReferralType] = useState(props.referredById ? true : false);
  const [customerCriteriaSearchModal, setCustomerCriteriaSearchModal] = useState(false);

  const defaultMediaSourceEditDetails = {
    customerId: props.customerId || "",
    customerName: props.customerName || "",
    sourceId: props.sourceId || "",
    sourceName: props.sourceName || "",
    referredById: props.referredById || "",
    referredByName: props.referredByName || ""
  }

  const [mediaSourceEditDetails, setMediaSourceEditDetails] = useState(defaultMediaSourceEditDetails)
  const [errorState, setErrorState] = useState({
    referredBy: false,
  })
  const disabledFieldInput = props.loadingEditCustomerMediaSource || Boolean(successMessage);

  useEffect(()=> {
    async function fetchMediaSource() {
      setLoadingLocal(true);
      let resultAPI : MediaSourceListObject[] = await getMediaSourceDropdown() || [];
      let indexSource = _.findIndex(resultAPI, {id: defaultMediaSourceEditDetails.sourceId})
      if (indexSource > -1) {
        setBlnReferralType(resultAPI[indexSource].isReferral);
      }
      setMediaSourceDropdownLocal(_.cloneDeep(resultAPI));
      setLoadingLocal(false);
    }

    fetchMediaSource();
  }, [])

  const onChangeMediaSource = (mediaSourceDetails) => {
    if (mediaSourceDetails) {
      setMediaSourceEditDetails((valueMediaSourceEditDetails)=> ({
        ...valueMediaSourceEditDetails,
        sourceId: mediaSourceDetails.id,
        sourceName: mediaSourceDetails.name,
        referredById: "",
        referredByName: ""
      }))
      setBlnReferralType(mediaSourceDetails.isReferral);
    }
  }

  const onSelectCustomerCriteriaSearch = (row) => {
    setMediaSourceEditDetails((valueMediaSourceEditDetails)=> ({
      ...valueMediaSourceEditDetails,
      referredById: row.id,
      referredByName: row.preferredName
    }))

    setCustomerCriteriaSearchModal(false);
    clearErrorState();
  }

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      referredBy: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setMediaSourceEditDetails(defaultMediaSourceEditDetails);
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "EditSource" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            loadingLocal
            ?
            <Loading/>
            :
            <div>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "CustomerName" })}
                  name="customerName"
                  type="text"
                  field={mediaSourceEditDetails}
                  setFieldValue={setMediaSourceEditDetails}
                  disabled={true}/>
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Source" })}
                  name="sourceName"
                  className={"mb-3"}
                  options={mediaSourceDropdownLocal}
                  onChange={onChangeMediaSource}
                  initialLabel={mediaSourceEditDetails.sourceName}
                  initialValue={mediaSourceEditDetails.sourceId}
                  labelField={"name"}
                  valueField={"id"}
                  blnReturnFullValue={true}
                  validationRequired={true}
                  disabled={disabledFieldInput}
                />
              </SingleColumnRowParent>    
              {
                (blnReferralType || mediaSourceEditDetails.referredById)
                &&
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "ReferredBy" })}
                    name="referredByName"
                    className={"mb-3"}
                    type="text"
                    field={mediaSourceEditDetails}
                    setFieldValue={setMediaSourceEditDetails}
                    validationRequired={true}
                    validationRequiredForce={!props.loadingEditCustomerMediaSource && !Boolean(successMessage)}
                    disabled={true}
                    childrenUISideBySide={
                      <MyButton
                        type="button"
                        content={"Select"}
                        class="btn btn-primary margin-left-8"
                        onClick={() => { 
                          setCustomerCriteriaSearchModal(true);
                        }}
                        loading={loadingLocal}
                        disable={disabledFieldInput}
                      />
                    }
                  />
                  {errorState.referredBy ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ReferredBy" }) })}</FormFeedback>
                  ) : null}
                </SingleColumnRowParent>
              }
              <div className="">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-success"
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        let errorStateTemp = {
                          referredBy: false,
                        };
                        let blnErrorFound = false;
                        if (!mediaSourceEditDetails.referredByName && blnReferralType) {
                          errorStateTemp.referredBy = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          props.onClickUpdateCustomerMediaSource(mediaSourceEditDetails);
                        }
                      }}
                      disable={props.loadingEditCustomerMediaSource || loadingLocal || Boolean(successMessage)}
                      loading={props.loadingEditCustomerMediaSource}
                    />
                  </>
                }
              </div>
            </div>
          }
        </div>
        {
          customerCriteriaSearchModal
          &&
          <ReferredByCustomerSearchModal
            blnShow={customerCriteriaSearchModal}
            setModal={setCustomerCriteriaSearchModal}
            onSelectFunction={onSelectCustomerCriteriaSearch} />
        }
      </Modal>
  )
}