import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, purchaseOrderStatusColor, returnPriceWithCurrency } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import MyButton from "../../app/components/form/MyButton";
import { CustomerPaymentDetailObject } from "../../app/models/customerOrder";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const PurchaseOrderInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { purchaseOrderStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { updatePurchaseOrder, purchaseOrderDetail, setPurchaseOrderDetail, getPurchaseOrderWithId } = purchaseOrderStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PurchaseOrder" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PurchaseOrder" }), urlPath: RoutesList.purchaseOrder }];
  const invoiceFullDetail = purchaseOrderDetail || {
    id: "",
    purchaseOrderNo: "",
    requestedDate: "",
    completedDate: "",
    purchaseOrderType: "",
    purchasePaymentMethod: "",
    businessEntityId: "",
    businessEntityName: "",
    purchaseFromId: "",
    purchaseFromName: "",
    receiverId: "",
    receiverName: "",
    purchaseOrderStatus: "",
    subTotalAmount: 0,
    subTotalDiscountAmount: 0,
    subTotalTaxAmount: 0,
    totalAmount: 0,
    purchaseType: "",
    purchaseFromAddress: "",
    purchaseFromContactPerson: "",
    purchaseFromContactNo: "",
    purchaseFromEmail: "",
    shippingAddress: "",
    shippingContactPerson: "",
    shippingContactNo: "",
    shippingEmail: "",
    note: "",
    isRequireInvoice: false,
    isFullyDeliver: false,
    purchaseOrderDetails: [{
      quantity: 0,
      receivedQuantity: 0,
      remainingQuantity: 0,
      productId: "",
      productName: "",
      costPrice: 0,
      taxRate: 0,
      remark: "",
      disabled: false
    }
    ],
    createPurchaseOrderType: "",
    toBranchId: "",
    toBranchName: "",
    purchaseFromSupplierId: "",
    purchaseFromSupplierName: "",
    warehouseId: "",
    warehouseName: "",
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: purchaseOrderDetail || {
      purchaseOrderType: "",
      purchaseFromSupplierId: undefined,
      purchaseFromSupplierName: "",
      toBranchId: undefined,
      toBranchName: "",
      warehouseId: "",
      warehouseName: "",
      note: "",
      purchaseOrderDetails: [],
      workflowAction: "",
      purchaseOrderStatus: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (invoiceFullDetail.purchaseOrderStatus == Constants.partialComplete || invoiceFullDetail.purchaseOrderStatus == Constants.processing) {
          await updatePurchaseOrder(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnPurchaseFromExternalSupplierType = invoiceFullDetail.purchaseOrderType === Constants.purchaseFromExternalSupplier;
  const blnOutletType = invoiceFullDetail.purchaseOrderType === Constants.purchaseForOutlet;
  const blnPartialCompleteStatus = invoiceFullDetail.purchaseOrderStatus === Constants.partialComplete;
  const blnVoidStatus = invoiceFullDetail.purchaseOrderStatus === Constants.void;
  const blnProcessingStatus = invoiceFullDetail.purchaseOrderStatus === Constants.processing;
  const blnPendingStatus = invoiceFullDetail.purchaseOrderStatus === Constants.pending;
  const blnPendingAllocationStatus = invoiceFullDetail.purchaseOrderStatus === Constants.pendingAllocation;
  const blnStatusCompletedOrCancelled = (invoiceFullDetail.purchaseOrderStatus === Constants.completed || invoiceFullDetail.purchaseOrderStatus === Constants.cancelled);
  const blnPermissionUpdatePurchaseOrder = checkPermission([PermissionConstants.UpdatePurchaseOrder]);
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || blnStatusCompletedOrCancelled;

  async function fetchPurchaseOrderDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getPurchaseOrderWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePurchaseOrder], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePurchaseOrder], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePurchaseOrder], true)) {
        return;
      }
    }
    
    fetchPurchaseOrderDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPurchaseOrderDetail(undefined);
      }
    })
  }, []);


  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 ">
          {label}
        </h6>
        <h6 className="">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        <td colSpan={colSpan} />
        <td className="text-end" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "PurchaseOrder" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PurchaseOrderNo" })}:`, invoiceFullDetail.purchaseOrderNo)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(invoiceFullDetail.requestedDate).format(Constants.displayDateFormat))}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(invoiceFullDetail.requestedDate).format(Constants.displayTimeFormat))}
                        </Col>
                        <hr />
                        <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Billing" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Company" })}:`, invoiceFullDetail.purchaseFromSupplierName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Name" })}:`, invoiceFullDetail.purchaseFromContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "ContactNo" })}.:`, invoiceFullDetail.purchaseFromContactNo)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Address" })}:`, invoiceFullDetail.purchaseFromAddress)}
                              {/* {displayBillingShippingRowData(`${intl.formatMessage({id: "PaymentMethod"})}:`, invoiceFullDetail.purchasePaymentMethod)} */}
                            </div>
                          </Col>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Receiver" })}:`, invoiceFullDetail.receiverName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "ShippingContactPerson" })}:`, invoiceFullDetail.shippingContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "ContactNo" })}.:`, invoiceFullDetail.shippingContactNo)}
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "Address" })}:`, invoiceFullDetail.shippingAddress)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <br /><br />

                      <address>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.purchaseOrderStatus}
                            statusColorFunction={purchaseOrderStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th></th>
                              <th className="text-center">{intl.formatMessage({ id: "OrderedQuantity" })}</th>
                              {blnPurchaseFromExternalSupplierType && <th className="text-center">{intl.formatMessage({ id: "OrderUom" })}</th>}
                              {blnPurchaseFromExternalSupplierType && <th className="text-center">{intl.formatMessage({ id: "OrderUomQty" })}</th>}
                              {blnPurchaseFromExternalSupplierType &&<th className="text-center">{intl.formatMessage({ id: "Price" })}</th>}
                              {blnPurchaseFromExternalSupplierType &&<th className="text-center">{intl.formatMessage({ id: "Tax" })}</th>}
                              <th className="text-end">{blnOutletType ? (blnPartialCompleteStatus || blnStatusCompletedOrCancelled) ? intl.formatMessage({ id: "ReceivedQuantity" }) : "" : intl.formatMessage({ id: "Total" })} </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.purchaseOrderDetails.map((item, key) => (
                                <tr key={key}>
                                  <td>{blnOutletType && item.warehouseSupplierReferenceNo ? item.productName.concat(`(${item.warehouseSupplierReferenceNo})`) : item.productName}</td>
                                  <td></td>
                                  <td className="text-center">{item.quantity}</td>
                                  {blnPurchaseFromExternalSupplierType && <td className="text-center">{item.additionalMeasurementName || Constants.emptyData}</td>}
                                  {blnPurchaseFromExternalSupplierType && <td className="text-center">{item.additionalMeasurementQuantity}</td>}
                                  {blnPurchaseFromExternalSupplierType && <td className="text-center">{returnPriceWithCurrency(item.costPrice)}</td>}
                                  {blnPurchaseFromExternalSupplierType && <td className="text-center">{`${item.taxRate}%`}</td>}
                                  <td className="text-end">{blnOutletType ? (blnPartialCompleteStatus || blnStatusCompletedOrCancelled) ? item.receivedQuantity : "" : returnPriceWithCurrency(item.costPrice * item.quantity)}</td>
                                  <td style={{ width: "40px" }}><Input type='checkbox' className="margin-bottom-8 margin-top-0" style={{ width: "18px", height: "18px" }} disabled={true}></Input></td>
                                </tr>
                              ))
                            }
                            {
                              !blnOutletType &&
                              <>
                                {displayCustomRowData(blnPurchaseFromExternalSupplierType ? 6 : 2, `${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(invoiceFullDetail.subTotalAmount))}
                                {displayCustomRowData(blnPurchaseFromExternalSupplierType ? 6 : 2, `${intl.formatMessage({ id: "SubTotalDiscount" })}`, returnPriceWithCurrency(invoiceFullDetail.subTotalDiscountAmount))}
                                {displayCustomRowData(blnPurchaseFromExternalSupplierType ? 6 : 2, `${intl.formatMessage({ id: "SubTotalTax" })}`, returnPriceWithCurrency(invoiceFullDetail.subTotalTaxAmount))}
                                {displayCustomRowData(blnPurchaseFromExternalSupplierType ? 6 : 2, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(invoiceFullDetail.totalAmount))}
                              </>
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                      <div className="mt-4" />
                      <div className="flex-direction-row d-print-none">
                        {!Boolean(successMessage) && !blnStatusCompletedOrCancelled && !blnVoidStatus && !blnPartialCompleteStatus && blnPermissionUpdatePurchaseOrder &&  !blnPendingStatus && !blnPendingAllocationStatus &&
                          <MyButton
                            type="submit"
                            class={`btn btn-warning margin-right-8`}
                            content={Constants.cancel}
                            disable={
                              validation.isSubmitting
                            }
                            loading={
                              validation.isSubmitting
                            }
                            onClick={() => {
                              validation.setFieldValue("workflowAction", Constants.cancel)
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          submitButtonTitle={blnPartialCompleteStatus ? Constants.complete : Constants.submit}
                          hidePrimaryButton={blnStatusCompletedOrCancelled || blnProcessingStatus || blnPendingStatus || blnPendingAllocationStatus || !blnPermissionUpdatePurchaseOrder}
                          showPrint={true}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", blnPartialCompleteStatus ? Constants.complete : Constants.submit)
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PurchaseOrderInvoiceDetail);
