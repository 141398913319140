import { useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedProductId: any;
  selectedProductName: any;
  fetchUpdateServiceConsumeQuantity: Function;
}

export default function UpdateServiceConsumeQuantityModal(props: Props) {
  const intl = useIntl();
  const [loadingUpdateServiceConsumeQuantity, setLoadingUpdateServiceConsumeQuantity] = useState(false);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false)

  const defaultUpdateServiceConsumeQuantityDetails = {
    productId: props.selectedProductId,
    productName: props.selectedProductName,
    serviceConsumeQuantity: 0,
  }
  const [updateServiceConsumeQuantityDetails, setUpdateServiceConsumeQuantityDetails] = useState(defaultUpdateServiceConsumeQuantityDetails)
  const [errorState, setErrorState] = useState({
    serviceConsumeQuantity: false
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      serviceConsumeQuantity: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setUpdateServiceConsumeQuantityDetails(defaultUpdateServiceConsumeQuantityDetails);
    props.setModal(false);
  }

  const onClickUpdateServiceConsumeQuantity = async () => {
    let updateServiceConsumeQuantityDetailsTemp = _.cloneDeep({ ...updateServiceConsumeQuantityDetails })
    setLoadingUpdateServiceConsumeQuantity(true);
    let resultAPI = await props.fetchUpdateServiceConsumeQuantity(updateServiceConsumeQuantityDetailsTemp);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true);
      setTimeout(() => {
        props.setModal(false);
        setLocalSuccessMessage(false);
      }, Constants.timeoutNavigation)
    }
    setLoadingUpdateServiceConsumeQuantity(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingUpdateServiceConsumeQuantity && !localSuccessMessage) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "UpdateServiceConsumeQuantity" })}</h5>
          {
            !loadingUpdateServiceConsumeQuantity && !localSuccessMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <SingleColumnRowParent blnDoubleTab={true}>
            <GeneralInputForModal
              title={intl.formatMessage({ id: "Product" })}
              name="productName"
              className={"mb-3"}
              type="text"
              validationRequired={true}
              field={updateServiceConsumeQuantityDetails}
              setFieldValue={setUpdateServiceConsumeQuantityDetails}
              disabled={true}
            />
          </SingleColumnRowParent>
          <SingleColumnRowParent blnDoubleTab={true}>
            <GeneralInputForModal
              title={intl.formatMessage({ id: "ServiceConsumeQuantity" })}
              name="serviceConsumeQuantity"
              className={errorState.serviceConsumeQuantity ? "mb-0" : "mb-3"}
              type="number"
              validationRequired={true}
              field={updateServiceConsumeQuantityDetails}
              setFieldValue={setUpdateServiceConsumeQuantityDetails}
              onChangeFunction={() => { clearErrorState("serviceConsumeQuantity") }}
              disabled={loadingUpdateServiceConsumeQuantity || Boolean(localSuccessMessage)}
            />
            {errorState.serviceConsumeQuantity ? (
              <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ServiceConsumeQuantity" }) })}</FormFeedback>
            ) : null}
          </SingleColumnRowParent>
          <div className="margin-top-14 flex-right">
            {
              !Boolean(localSuccessMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: 'Submit' })}
                onClick={() => {
                  let errorStateTemp = {
                    serviceConsumeQuantity: false
                  };
                  let blnErrorFound = false;
                  if (updateServiceConsumeQuantityDetails.serviceConsumeQuantity < 0 || String(updateServiceConsumeQuantityDetails.serviceConsumeQuantity) === "") {
                    errorStateTemp.serviceConsumeQuantity = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickUpdateServiceConsumeQuantity()
                  }
                }}
                loading={loadingUpdateServiceConsumeQuantity}
                disable={loadingUpdateServiceConsumeQuantity || Boolean(localSuccessMessage)}
              />
            }
          </div>
        </div>
      </Modal>
  )
}