import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  getBranchId,
  IncludesLocationPathName,
  newSpaceBeforeCapitalLetter,
  returnAdvancedSearchIcon,
  productRedemptionNoteReturnStatusColor,
  productRedemptionStatusColor,
  contructValidationErrorMessage,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { ProductRedemptionNoteReturnDetailsObject } from "../../app/models/productRedemptionNoteReturn";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import GeneralInput from "../../app/components/form/GeneralInput";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import moment from "moment";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";

const ProductRedemptionNoteReturnDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { productRedemptionNoteReturnStore, commonStore, staticReferenceStore, customerStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading} = commonStore;
  const { addProductRedemptionNoteReturn, productRedemptionNoteReturnDetail, productRedemptionNoteReturnEligibleList, setProductRedemptionNoteReturnDetail, getProductRedemptionNoteReturnWithId, getProductRedemptionNoteReturnEligibleWithId } = productRedemptionNoteReturnStore;
  const { getStaticReferenceWithType} = staticReferenceStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ProductRedemptionNoteReturn" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: RoutesList.productRedemptionNoteReturn }, { title: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }), urlPath: RoutesList.productRedemptionNoteReturn }];
  const [productRedemptionNoteReturnEligibleModal, setProductRedemptionNoteReturnEligibleModal] = useState(false);
  const [loadingProductRedemptionNoteReturnEligible, setLoadingProductRedemptionNoteReturnEligible] = useState(false); 
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);

  const [productReturnDetailList, setProductReturnDetailList] = useState<ProductRedemptionNoteReturnDetailsObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [productRedemptionNoteReturnTypeList, setProductRedemptionNoteReturnTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionViewProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);

  const productOnChange = () => {
    setReloadTable(true);
    let productReturnDetailsTemp = _.cloneDeep(validation.values.productReturnDetails);
    productReturnDetailsTemp = productReturnDetailsTemp.map((valueProductReturnDetailsTemp)=> ({
      ...valueProductReturnDetailsTemp,
      returnTypeName: !valueProductReturnDetailsTemp.isDefect ? newSpaceBeforeCapitalLetter(Constants.returnAndRefund) : valueProductReturnDetailsTemp.returnTypeName,
      returnType: !valueProductReturnDetailsTemp.isDefect ? Constants.returnAndRefund : valueProductReturnDetailsTemp.returnType,
    }))
    validation.setFieldValue("productReturnDetails", productReturnDetailsTemp)
    setTimeout(()=> {
      setReloadTable(false);
    }, 100)
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "MaxQty" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "maxQuantity",
      inputType: "number",
      disabled: true,
    },
    {
      label: intl.formatMessage({ id: "ReturnQty" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "quantity",
      inputType: "number",
      numberMaxFieldName: "maxQuantity"
    },
    {
      label: intl.formatMessage({ id: "IsDefect" }).toUpperCase(),
      type: "input",
      xl: "1",
      inputName: "isDefect",
      inputType: "checkbox",
      checkBoxText: intl.formatMessage({ id: "Yes" }),
      disabledField: "isDefectDisable",
      onChangeFunction: productOnChange
    },
    {
      label: intl.formatMessage({ id: "ReturnType" }).toUpperCase(),
      type: "select",
      xl: "3",
      inputName: "returnType",
      inputType: "select",
      options: AddOptionsValueField(productRedemptionNoteReturnTypeList, "displayValue", "key"),
      displayLabel: "returnTypeName",
      displayValue: "returnType",
      specifyReturnFieldName: [{ field: "returnTypeName", value: "displayValue" }, { field: "returnType", value: "key" }],
      validationRequired: true,
      disabledField: "isDefectDisable",
      reverseDisabledField: "isDefect"
    },
    {
      label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "remark",
      inputType: "text",
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: productRedemptionNoteReturnDetail || {
      returnBranchId: getBranchId(),
      customerName: "",
      customerId: "",
      productRedemptionNoteId: "",
      productRedemptionNoteNo: "",
      note: "",
      productReturnDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (productRedemptionNoteReturnValidation()) {
          return;
        }

        await addProductRedemptionNoteReturn(valuesTemp);
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  //Table Content UI
  const ProductRedemptionNoteColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productRedemptionNo",
      text: intl.formatMessage({ id: "OrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewProductRedemptionNote
            ?
            <Link className="d-block text-primary mb-2 h6" 
              to={`/${RoutesList.productRedemptionNote}/view/${row.id}`}
              target={Constants.blank} 
              rel="noopener noreferrer">
              {row.productRedemptionNo}
            </Link>
            :
            <div>{row.productRedemptionNo}</div>
          }
        </>
      ),
    },
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performedDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performedDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus 
          status={row.status}
          statusColorFunction={productRedemptionStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({id: "Select"})}/>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    async function fetchProductRedemptionNoteReturnDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.customerProductReturnType)
      ];

      let resultApi = await Promise.all(aryAPI);
      setProductRedemptionNoteReturnTypeList(resultApi[0].map((valueTemp: StaticReferenceDataObject)=> ({displayValue: newSpaceBeforeCapitalLetter(valueTemp.displayValue), key: valueTemp.displayValue})))
      setLoading(false);
    }

    if (addAction) {
      setProductRedemptionNoteReturnDetail(undefined);
    }

    if (addAction) {
      if (!checkPermission([PermissionConstants.RequestProductRedemptionNoteReturn], true)) {
        return;
      }
    }

    fetchProductRedemptionNoteReturnDetailAPI();

    return (() => {
      if (id && !addAction) {
        setProductRedemptionNoteReturnDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onChangeProductRedemptionNoteModal = (row) => {
    validation.setFieldValue("productRedemptionNoteId", row.id);
    validation.setFieldValue("productRedemptionNoteNo", row.productRedemptionNo);
    setProductRedemptionNoteReturnEligibleModal(false);
    
    let productReturnDetailTemp = _.cloneDeep(row.details);
    productReturnDetailTemp = productReturnDetailTemp.map((valueProductReturnDetailTemp)=> ({
      ...valueProductReturnDetailTemp,
      isDefect: valueProductReturnDetailTemp.unitPrice === 0 ? true : false,
      isDefectDisable: valueProductReturnDetailTemp.unitPrice === 0 ? true : false,
      returnTypeName: valueProductReturnDetailTemp.unitPrice === 0 ? newSpaceBeforeCapitalLetter(Constants.returnAndExchange) : newSpaceBeforeCapitalLetter(Constants.returnAndRefund),
      returnType: valueProductReturnDetailTemp.unitPrice === 0 ?  Constants.returnAndExchange : Constants.returnAndRefund,
    }))
    setProductReturnDetailList(productReturnDetailTemp);
    validation.setFieldValue("productReturnDetails", []);
  }

  const productRedemptionNoteReturnValidation = () => {
    let blnEmptyData = false;

    if (validation.values.productReturnDetails.length == 0) {
      setErrorMessage(`${intl.formatMessage({ id: "ValidationSelect" }, {field: "Product"})}`) 
      return true;
    }

    validation.values.productReturnDetails.map((productReturnDetailsTemp) => {
      if (!productReturnDetailsTemp.quantity) {
        blnEmptyData = true;
      }
    })

    if (blnEmptyData) {
      setErrorMessage(`${intl.formatMessage({ id: "EmptyQuantity" }, {field: "Order"})}`) 
    }
    
    return blnEmptyData;
  }

  const onClickSelect = (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      productRedemptionNoteId: "",
      productRedemptionNoteNo: "",
      productReturnDetails: [],
    }))
    fetchProductRedemptionNoteWithLoading(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const fetchProductRedemptionNoteWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingProductRedemptionNoteReturnEligible(true);
    let indexCustomer = _.findIndex(customerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownList[indexCustomer].id,
        customerName: customerDropdownList[indexCustomer].preferredName,
        productRedemptionNoteId: "",
        productRedemptionNoteNo: "",
        productReturnDetails: [],
      }))
    }
    await getProductRedemptionNoteReturnEligibleWithId(id);
    setLoadingProductRedemptionNoteReturnEligible(false);
    clearCustomerDropdownList();
    setProductReturnDetailList([]);
  };

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row form={+true}>
              {" "}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }) })}
                  h4Title
                />
                <Row>
                  <Col xl="6">
                    <SingleColumnRowParent
                      blnDoubleTab>
                      <Row className="mt-3">
                        <Col xl='8' md='8' xs='8'>
                          {displayCustomerDropdown &&
                            <DropDownWithTitle
                              name={"customerId"}
                              title={intl.formatMessage({ id: "Customer" })}
                              placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                              specifyReturnFieldName={[
                                {
                                  field: "customerId",
                                  value: "id",
                                }
                              ]}
                              labelField={"preferredName"}
                              valueField={"id"}
                              options={customerDropdownList}
                              disabled={disabledFieldInput}
                              initialLabel={validation.values.customerName}
                              initialValue={validation.values.customerId}
                              validationRequired={true}
                              validation={validation}
                              onChangeField={"id"}
                              onChangeFunction={fetchProductRedemptionNoteWithLoading}
                              blnSupportCustomSearch={true}
                              onChangeCustomSearch={(value)=> {
                                fetchCustomerDropdown(value);
                              }}
                              onMenuOpenFunction={()=> {
                                clearCustomerDropdownList();
                                setLoadingCustomer(false);
                              }}
                              loadingCustomSearch={loadingCustomer}
                              setLoadingCustomSearch={setLoadingCustomer}
                            />
                          }
                        </Col>
                        <Col xl='4' md='4' xs='4'>
                          <MyButton
                            type="button"
                            class={`btn btn-primary margin-top-28`}
                            content={""}
                            subContent={returnAdvancedSearchIcon()}
                            loading={loading}
                            disable={loading || disabledFieldInput}
                            onClick={() => { setCustomerSearchModal(true) }}
                          />
                        </Col>
                      </Row>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent  
                      blnDoubleTab>
                      <Col xl='10' md='10' xs='10'>
                        <GeneralInput
                          name={"productRedemptionNoteNo"}
                          title={intl.formatMessage({ id: "ProductRedemptionNote" })}
                          classNameInput="width-100-percentage"
                          type="text"
                          disabled={true}
                          validation={validation}
                          childrenUI={
                            addAction
                            &&
                            <MyButton
                              type="button"
                              content={"Select"}
                              class="btn btn-success margin-left-8"
                              onClick={() => { setProductRedemptionNoteReturnEligibleModal(true) }}
                              loading={loadingProductRedemptionNoteReturnEligible}
                              disable={disabledFieldInput || viewAction || loadingProductRedemptionNoteReturnEligible || !addAction || productRedemptionNoteReturnEligibleList.length === 0}
                            />
                          }/>
                      </Col>
                    </SingleColumnRowParent>
                  </Col>
                  <Col xl="4" md="10" xs="12">
                    <GeneralTextArea
                      title={intl.formatMessage({ id: "Note" })}
                      name="note"
                      className="mt-3"
                      row={5}
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <hr/>
                <Col>
                  {/* <LineBreakWithTittle
                    paddingBottom="0px"
                    className="mt-4"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    h4Title
                  /> */}
                  {
                    !reloadTable 
                    &&
                    <TableWithEditableInputFields
                      name="productReturnDetails"
                      className="mt-4"
                      headerColor={Constants.placeholderColor}
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      blnHideBorder={true}
                      options={productReturnDetailList}
                      optionsId={"productRedemptionNoteDetailId"}
                      setOptionsFunction={setProductReturnDetailList}
                      addButtonFunction={() => setProductModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.productReturnDetails}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  }
                </Col>                
              <div className="mt-3" />
              <div className="flex-direction-row d-print-none">
                <GeneralSubmitAndCancelBtn
                  successMessage={successMessage}
                  viewAction={viewAction}
                  validation={validation}
                  dirtyDisabled={true}
                  hidePrimaryButton={false}
                  submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                  onClickFunction={() => {
                    validation.setFieldValue("workflowAction", 'Submit')
                  }}
                />
              </div>
              </Col>
            </Row>
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productRedemptionNoteDetailId"}
          options={AddOptionsValueField(productReturnDetailList, "productName", "productRedemptionNoteDetailId")}
          setOptionsFunction={setProductReturnDetailList}
          fieldInput={validation.values.productReturnDetails}
          fieldName={"productReturnDetails"}
          validation={validation} />
        <SelectWithTableListModal
          blnShow={productRedemptionNoteReturnEligibleModal}
          setModal={setProductRedemptionNoteReturnEligibleModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "ProductRedemptionNote" }) })}
          options={productRedemptionNoteReturnEligibleList}
          keyField={"id"}
          fieldName={"id"}
          onSelectFunction={onChangeProductRedemptionNoteModal}
          columns={ProductRedemptionNoteColumns}
          validation={validation}/>
        {
          customerSearchModal
          &&
          <CustomerSearchModal
            blnShow={customerSearchModal}
            blnHideOtherBranch={false}
            setModal={setCustomerSearchModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
            onSelectFunction={onClickSelect} />
        }
      </DetailViewLayout>
    </div >
  );
};

export default observer(ProductRedemptionNoteReturnDetail);
