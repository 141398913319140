import { observer } from 'mobx-react-lite';
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { useIntl } from "react-intl";

interface Props {
  blnShow: boolean;
  setModal: Function;
  deliverOrderNo: any;
  onRedeemClick: () => void;
  onCancelClick: () => void;
}

export default observer(function AutoGeneratedCustomerRedemptionModal(props: Props) {
  const intl = useIntl();

  return (
    <ActionPromptModal
      title={`${intl.formatMessage({ id: "AutoCustomerRedemptionGeneratedMessage" }, { deliverOrderNo: `(${props.deliverOrderNo})` })}`}
      bottomTitle={`${intl.formatMessage({ id: "AutoCustomerRedemptionGeneratedTermAndConditions" })}`}
      primaryActionTitle={`${intl.formatMessage({ id: "AutoCustomerRedemptionGeneratedYesButton" })}`}
      cancelActionTitle={`${intl.formatMessage({ id: "AutoCustomerRedemptionGeneratedNoButton" })}`}
      showPrompt={props.blnShow}
      setShowPrompt={props.setModal}
      onPrimaryClick={() => { props.onRedeemClick() }}
      onCancelClick={() => { props.onCancelClick() }}
    />
  );
})