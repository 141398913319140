import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { history } from "../../..";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  blnShow: boolean;
}

export default function TodayCloseCounterSubmittedModal(props: Props) {
  const intl = useIntl();

	return (
		<div>
			<Modal 
        isOpen={props.blnShow}
        centered={true}>
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>
								<h4 style={{whiteSpace:"pre-wrap"}}>
									{intl.formatMessage({ id: "TodayCloseCounterSubmittedAlert" })}
								</h4>
							</div>
						</Col>
					</Row>
          <div className={`text-center flex-wrap mt-4 mb-2`}>
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "BackToDashoard" })}
              class="btn btn-primary btn-lg me-2"
              onClick={() => {
                history.push(`/${RoutesList.dashboard}`);
              }}/>
          </div>
				</ModalBody>
			</Modal>
		</div>
	);
}
