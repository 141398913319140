import { useState } from "react";
import { Modal } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import { CustomerAccessRequestListObject } from "../../../app/models/customerAccessRequest";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedExtendCustomerForAccess: CustomerAccessRequestListObject | undefined;
  setSelectedExtendCustomerForAccess: Function;
  extendCustomerAccessRequest: Function;
  fetchCustomerAccessRequestList: Function;
}

export default observer(function ExtendCustomerAccessRequestModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingCreate, setLoadingCreate] = useState(false);

  const defaultExtendCustomerAccessRequestDetails = {
    id: props.selectedExtendCustomerForAccess?.id || "",
    previousTo: moment(props.selectedExtendCustomerForAccess?.to),
    to: moment(props.selectedExtendCustomerForAccess?.to),
    requestBranchName: props.selectedExtendCustomerForAccess?.requestBranchName || ""
  }
  const [extendCustomerAccessRequestDetails, setExtendCustomerAccessRequestDetails] = useState(defaultExtendCustomerAccessRequestDetails)
  const disabledFieldInput = loadingCreate || Boolean(successMessage);
  const hideModal = () => {
    if (props.setSelectedExtendCustomerForAccess) {
      props.setSelectedExtendCustomerForAccess(undefined);
    }
    setExtendCustomerAccessRequestDetails(defaultExtendCustomerAccessRequestDetails);
    props.setModal(false);
  }

  const onClickExtendCustomerAccessRequest = async () => {
    let extendCustomerAccessRequestDetailsTemp : any = _.cloneDeep(extendCustomerAccessRequestDetails);
    extendCustomerAccessRequestDetailsTemp.to = moment(extendCustomerAccessRequestDetails.to, Constants.textInputDateFormat).format(Constants.defaultDateFormat)
    setLoadingCreate(true);
    let resultAPI = await props.extendCustomerAccessRequest(extendCustomerAccessRequestDetailsTemp)
    if(resultAPI.status === Constants.success){
      props.fetchCustomerAccessRequestList();
      setTimeout(()=> {
        hideModal();
      }, Constants.dismissSuccessMessage)
    }
    setLoadingCreate(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size="lg"
        centered>
        {
          !props.selectedExtendCustomerForAccess
          ?
          <div style={{ padding: "20px" }}>
            <Loading />
          </div>
          :
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ExtendAccessExpiry" })}</h5>
              </div>
              {
                !disabledFieldInput
                &&
                <button
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
            <div className="modal-body">
              <SingleColumnRowParent blnDoubleTab>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Branch" })}
                  name="requestBranchName"
                  type="text"
                  disabled={true}
                  field={extendCustomerAccessRequestDetails}
                  setFieldValue={setExtendCustomerAccessRequestDetails}
                />
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "ValidTill" })}
                  name="to"
                  type="date"
                  disabled={disabledFieldInput}
                  field={extendCustomerAccessRequestDetails}
                  setFieldValue={setExtendCustomerAccessRequestDetails}
                  minCurrentDate={true}
                  minDateValue={moment(props.selectedExtendCustomerForAccess?.to).format(Constants.displayDateFormat)}
                />
              </SingleColumnRowParent>
              <div className="mb-2 mt-4 flex-right">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-primary margin-right-8"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        onClickExtendCustomerAccessRequest();
                      }}
                      disable={disabledFieldInput}
                      loading={loadingCreate}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        }
      </Modal>
  )
})