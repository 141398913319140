import { useEffect, useState } from "react";
import {
  Col,
  Label,
  Modal, Row
} from "reactstrap";
import _ from 'lodash';
import { AppointmentDetailObject } from "../../../app/models/appointment";
import MyButton from "../../../app/components/form/MyButton";
import Loading from "../../../app/components/loading/Loading";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { history } from "../../..";
import SelectDropDownModal from "../../../app/components/modal/SelectDropDownModal";
import DeleteModal from "../../../app/components/modal/DeleteModal";
import { checkPermission, getBranchId, getBranchUser, newSpaceBeforeCapitalLetter, returnNameFromArray } from "../../../app/common/function/function";
import { WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import { Link } from 'react-router-dom';
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import NotShowModal from "./NotShowModal";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  blnListView?: boolean;
  setModal: Function;
  selectedAppointmentDetail: AppointmentDetailObject | undefined;
  setSelectedAppointmentDetail: Function;
  refreshAppointmentData: Function;
  windowSize: WindowObject;
  selectedBranchId: string;
  setObjReschedule?: Function;
}

export default observer(function AppointmentDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { appointmentStore, staticReferenceStore, customerStore, settingsStore, commonStore } = useStore();
  const { errorMessage, loading, setLoading, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { updateAppointmentStatus, linkAppointmentCustomer, unlinkAppointmentCustomer, appointmentPin, getAppointmentWithId, deleteAppointment, updatedAppointmentStatus, setUpdatedAppointmentStatus } = appointmentStore; 
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { appointmentStatusType } = staticReferenceStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const linkToCustomerText = `${intl.formatMessage({ id: "Name"})}`;
  const nextAppointmentText = `${intl.formatMessage({ id: "NextAppointment"})}`;
  const blnCustomerIdExist = props.selectedAppointmentDetail?.customerId && props.selectedAppointmentDetail?.customerId !== Constants.emptyId;
  const blnLeadIdExist = props.selectedAppointmentDetail?.leadId;
  const blnExistingAppointmentSourceType = props.selectedAppointmentDetail?.appointmentSourceType === Constants.existing;
  const [customerModal, setCustomerModal] = useState(false);
  const [unlinkCustomerModal, setUnlinkCustomerModal] = useState(false);
  const [notShowModal, setNotShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<AppointmentDetailObject | undefined>(undefined);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageLead = checkPermission([PermissionConstants.ManageLead]);
  const blnPermissionCreateCustomer = checkPermission([PermissionConstants.CreateCustomer]);
  const blnPermissionCreateAppointment = checkPermission([PermissionConstants.CreateAppointment]);
  const blnPermissionUpdateAppointment = checkPermission([PermissionConstants.UpdateAppointment]);
  const blnPermissionDeleteAppointment = checkPermission([PermissionConstants.DeleteAppointment]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionReviseClosedAppointment = checkPermission([PermissionConstants.ReviseClosedAppointment]);
  const blnShowAppolous = generalSettings?.isShowAppolous;
  const blnStatusNotShow = props.selectedAppointmentDetail?.appointmentStatus === Constants.notShow;
  const blnStatusCompleted = props.selectedAppointmentDetail?.appointmentStatus === Constants.completed;
  const blnDisplayReschedule = props.selectedAppointmentDetail?.isAbleRescheduleOrDelete && blnPermissionUpdateAppointment;
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [blnPinChanged, setBlnPinChanged] = useState(false);
  const blnAppointmentDateIsToday = props.selectedAppointmentDetail?.appointStartDateTime ? moment().isSame(moment(props.selectedAppointmentDetail?.appointStartDateTime), 'day') : false;

  useEffect(() => {
    async function fetchGeneralSettingsAPI() {
      setLoading(true)
      let aryAPI: any = [
        getGeneralSettings()
      ];
      await Promise.all(aryAPI);
      setLoading(false);
    }

    fetchGeneralSettingsAPI();
  }, [])

  const hideModal = async () => {
    if (errorMessage) {
      setErrorMessage("");
    }
    if (successMessage) {
      setSuccessMessage("");
    }
    if(updatedAppointmentStatus || blnPinChanged){
      props.refreshAppointmentData();
    }
    setUpdatedAppointmentStatus(undefined);
    setBlnPinChanged(false);
    props.setModal(false);
    props.setSelectedAppointmentDetail(undefined);
  }

  const linkAppointmentCustomerFunction = async (id?: string) => {
    setLoading(true);
    let result : any = undefined;
    if (blnCustomerIdExist && blnExistingAppointmentSourceType) {
      result = await unlinkAppointmentCustomer(props.selectedAppointmentDetail?.id || "")
    }
    else {
      result = await linkAppointmentCustomer(props.selectedAppointmentDetail?.id || "", (id || ""))
    }
    setTimeout(()=> {
      setSuccessMessage("");
    }, Constants.dismissSuccessMessage)
    if (result?.status == Constants.success) {
      await getAppointmentWithId(props.selectedAppointmentDetail?.id || "")
      if (props.refreshAppointmentData) {
        props.refreshAppointmentData();
      }
    }
    setLoading(false);
  }

  const onClickDelete = async (row : AppointmentDetailObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(props.selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const displayRowData = (displayText:string, value: string) => {
    return (
      <Row className="flex-align-items-center-without-height">
        <Col xl={4}>
          <Label className="mb-0">
            {displayText === linkToCustomerText ? `${linkToCustomerText} (${props.selectedAppointmentDetail?.appointmentSourceType ? newSpaceBeforeCapitalLetter(props.selectedAppointmentDetail.appointmentSourceType) : "" })` : displayText}
          </Label> 
        </Col>
        <Col xl={8}>
          <div className="flex-direction-row flex-align-items-center-without-height">
            <Label className="mb-0">
              {
                displayText === linkToCustomerText 
                ? 
                (
                  blnPermissionManageCustomer && blnCustomerIdExist 
                  ? 
                  <Link 
                    to={`/${RoutesList.customer}/view/${props.selectedAppointmentDetail?.customerId}`} 
                    target={Constants.supportBlank}
                    className="text-info">{value}
                  </Link> 
                  : 
                  blnPermissionManageLead && blnLeadIdExist
                  ?
                  <Link 
                    to={`/${RoutesList.lead}/view/${props.selectedAppointmentDetail?.leadId}`} 
                    target={Constants.supportBlank}
                    className="text-info">{value}
                  </Link> 
                  :
                  value || `${intl.formatMessage({ id: "NotLinked"})}`
                ) 
                : 
                displayText === nextAppointmentText && props.selectedAppointmentDetail?.nextAppointment && (!getBranchUser() || (getBranchUser() && props.selectedAppointmentDetail?.nextAppointment?.branchId === getBranchId()))
                ?
                <Link
                  onClick={()=> {
                    history.push(`/${RoutesList.appointment}?appointmentId=${props.selectedAppointmentDetail?.nextAppointment?.appointmentId}&branchId=${props.selectedAppointmentDetail?.nextAppointment?.branchId }&branchName=${props.selectedAppointmentDetail?.nextAppointment?.branchName}&startDate=${moment(props.selectedAppointmentDetail?.nextAppointment?.appointmentStartDateTime).format(Constants.displayDateFormat).toString()}&endDate=${moment(props.selectedAppointmentDetail?.nextAppointment?.appointmentStartDateTime).add(1, 'days').format(Constants.displayDateFormat).toString()}`)
                    window.location.reload();
                  }}
                  className="text-info">{value}
                </Link> 
                :
                (value) || Constants.emptyData
              }
              {displayText === linkToCustomerText && props.selectedAppointmentDetail?.gender ? ` (${props.selectedAppointmentDetail.gender})`: ""}
            </Label>
            {
              displayText === linkToCustomerText
              &&
              blnPermissionCreateCustomer
              &&
              (!blnStatusNotShow || blnPermissionReviseClosedAppointment)
              &&
              <div className="flex-direction-row flex-right" style={{flex: 1, zIndex: 2}}>
                {/* {
                  ((blnCustomerIdExist && blnExistingAppointmentSourceType) || (!blnLeadIdExist && !blnCustomerIdExist))
                  &&
                  <MyButton
                    key={displayText}
                    type="button"
                    class={`btn btn-success margin-left-16 ${Constants.innerWidthCompare>props.windowSize.innerWidth ? "margin-top-8" : ""}`}
                    // style={{width: '100%', height: '50px', paddingLeft: '10%'}}
                    content={(blnCustomerIdExist && blnExistingAppointmentSourceType) ? `${intl.formatMessage({ id: "UnlinkCustomer"})}` : `${intl.formatMessage({ id: "LinkToCustomer"})}`}
                    disable={
                      Boolean(successMessage) || loading
                    }
                    loading={
                      loading
                    }
                    onClick={async ()=> {
                      if (!blnCustomerIdExist) {
                        // setLoading(true);
                        // await getCustomerDropdown(props.selectedBranchId)
                        // setLoading(false);
                        setCustomerModal (true);
                      }
                      else {
                        setUnlinkCustomerModal(true);
                        // linkAppointmentCustomerFunction();
                      }
                    }}
                  />
                } */}
                {
                  !blnCustomerIdExist
                  &&
                  blnAppointmentDateIsToday
                  &&
                  <MyButton
                    key={`${displayText}_button`}
                    type="button"
                    class={`btn btn-success margin-left-16 ${Constants.innerWidthCompare>props.windowSize.innerWidth ? "margin-top-8" : ""}`}
                    // style={{width: '100%', height: '50px', paddingLeft: '10%'}}
                    content={`${intl.formatMessage({ id: "CreateNewCustomer"})}`}
                    disable={
                      Boolean(successMessage) || loading
                    }
                    loading={
                      loading
                    }
                    onClick={async ()=> {
                      history.push({
                        pathname: `/${RoutesList.customer}/add`,
                        state: { 
                          appointmentId: props.selectedAppointmentDetail?.id || ""
                        }
                      });
                    }}
                  />
                }
              </div>
            }
            {
              blnShowAppolous
              &&
              displayText === intl.formatMessage({ id: "ViewAppolous"})
              &&
              <MyButton
                key={`${displayText}_button`}
                type="button"
                class={`btn btn-primary margin-left-32`}
                // style={{width: '100%', height: '50px', paddingLeft: '10%'}}
                content={`${intl.formatMessage({ id: "View"})}`}
                disable={
                  Boolean(successMessage) || loading
                }
                loading={
                  loading
                }
                onClick={async ()=> {
                  window.open(`https://app.appolous.com/appointments?appointment=${props.selectedAppointmentDetail?.appolousId}&date=${moment(props.selectedAppointmentDetail?.madeOn).format(Constants.displayDateFormat)}&branch=${props.selectedAppointmentDetail?.branchAppolousId}`)
                }}
              />
            }
          </div>
        </Col>
      </Row>
    )
  }

  const displayStatusButton = (displayText: string) => {
    // let blnDisablePending = displayText === Constants.pending && props.selectedAppointmentDetail?.appointmentStatus !== Constants.pending;
    let blnDisablePending = false;
    let blnCurrentStatus = updatedAppointmentStatus ? updatedAppointmentStatus === displayText : props.selectedAppointmentDetail?.appointmentStatus === displayText;
    return (
      <MyButton
        key={displayText}
        type="button"
        class={`btn button-status-height mt-4 ${successMessage && displayText === updatedAppointmentStatus ? `btn-current-selected-${displayText}` : blnCurrentStatus ? `btn-current-blink btn-color-${displayText}` : blnDisablePending ? `btn-disabled-color-${displayText}` : `btn-not-current btn-color-${displayText} `}`}
        style={{width: '100%', height: '50px', paddingLeft: '10%'}}
        content={newSpaceBeforeCapitalLetter(displayText)}
        disable={
          Boolean(successMessage) || loading || blnDisablePending || (props.selectedAppointmentDetail?.appointmentStatus !== displayText && (!blnPermissionUpdateAppointment || ((blnStatusNotShow || blnStatusCompleted) && !blnPermissionReviseClosedAppointment)))
        }
        loading={
          loading
        }
        onClick={async ()=> {
          if (!blnCurrentStatus) {
            if (displayText === Constants.notShow) {
              setNotShowModal(true);
              return;
            }

            setLoading(true);
            let resultAPI = await updateAppointmentStatus({id: props.selectedAppointmentDetail?.id || "", appointmentStatus: displayText})
            if (resultAPI.status === Constants.success) {
              props.setSelectedAppointmentDetail({...props.selectedAppointmentDetail, appointmentStatus: displayText});
            }
            setLoading(false);
            setTimeout(()=> {
              setSuccessMessage("");
            }, Constants.dismissSuccessMessage)
          }
        }}
      />
    )
  }

  const onClickNotShowFunction = async (updateAppointmentStatusRequestBody) => {
    let resultAPI = await updateAppointmentStatus(updateAppointmentStatusRequestBody)
    if (resultAPI.status === Constants.success) {
      props.setSelectedAppointmentDetail({
        ...props.selectedAppointmentDetail, 
        appointmentStatus: updateAppointmentStatusRequestBody.appointmentStatus, 
        remark: `${props.selectedAppointmentDetail?.remark || ""} \n ${updateAppointmentStatusRequestBody.remark}`
      });
      setTimeout(()=> {
        setSuccessMessage("");
        setNotShowModal(false);
      }, Constants.dismissSuccessMessage)
    }
  }

  return (
    !props.blnShow
    ?
    <div/>
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!Boolean(successMessage) && !loading) {
          hideModal();
        }
      }}
      size="xl"
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered>
      {
        !props.selectedAppointmentDetail
        ?
        <div style={{padding: "20px"}}>
          <Loading />
        </div>
        :
        <div className="padding-horizontal-20px">
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <div className="margin-top-48">
              <AlertMessage 
                errorMessage={errorMessage}
                successMessage={successMessage}/>
            </div>
          } */}
          <div className="modal-header">
            <div>
              {
                ((blnPermissionManageCustomer && blnCustomerIdExist) || (blnPermissionManageLead && blnLeadIdExist))
                ?
                <Link to={blnPermissionManageCustomer && blnCustomerIdExist ? `/${RoutesList.customer}/view/${props.selectedAppointmentDetail?.customerId}` : `/${RoutesList.lead}/view/${props.selectedAppointmentDetail?.leadId}`} target={Constants.supportBlank} rel="noopener noreferrer">
                  <h5 className={`modal-title margin-top-0 text-primary`}>{props.selectedAppointmentDetail.customerName} {props.selectedAppointmentDetail.isRejoin ? `(${intl.formatMessage({id: "Rejoin"})})` : props.selectedAppointmentDetail.isCrossBrand ? `(${intl.formatMessage({id: "CrossBrand"})})` : ""}</h5>
                </Link>
                :
                <h5 className={`modal-title margin-top-0`}>{props.selectedAppointmentDetail.customerName}</h5>
              }
              <p className="text-muted mb-0 text-truncate">{props.selectedAppointmentDetail.contactNo} {`${props.selectedAppointmentDetail.email ? `| ${props.selectedAppointmentDetail.email}` : ""}`}</p>
            </div>
            <div className="flex-direction-row flex-right">
              {
                blnPermissionUpdateAppointment
                &&
                <MyButton
                  type="button"
                  class={`btn ${props.selectedAppointmentDetail.isPin ? "btn-danger" : "btn-success"} margin-right-8`}
                  content={intl.formatMessage({ id: props.selectedAppointmentDetail.isPin ? "Unpin" : "Pin"})}
                  disable={
                    Boolean(successMessage) || loading
                  }
                  loading={
                    loading
                  }
                  onClick={async ()=> {
                    setLoading(true);
                    let resultAPI = await appointmentPin({isPin: !props.selectedAppointmentDetail?.isPin, id: props.selectedAppointmentDetail?.id || "" })
                    if (resultAPI.status === Constants.success) {
                      setBlnPinChanged(true);
                      props.setSelectedAppointmentDetail({...props.selectedAppointmentDetail, isPin: !props.selectedAppointmentDetail?.isPin});
                    }
                    setLoading(false);
                  }}/>
              }
              {/* <Link to={`/${props.blnListView ? RoutesList.appointmentList : RoutesList.appointment}/view/${props.selectedAppointmentDetail?.id || ""}`}>
                  <MyButton
                    type="button"
                    class={`btn btn-primary margin-right-8`}
                    content={intl.formatMessage({ id: "View"})}
                    disable={
                      Boolean(successMessage) || loading
                    }
                    loading={
                      loading
                    }
                    onClick={async ()=> {
                      hideModal();
                      history.push(`/${props.blnListView ? RoutesList.appointmentList : RoutesList.appointment}/view/${props.selectedAppointmentDetail?.id || ""}`)
                    }}/>
                </Link> */}
              {
                blnPermissionUpdateAppointment
                &&
                <Link to={`/${props.blnListView ? RoutesList.appointmentList : RoutesList.appointment}/edit/${props.selectedAppointmentDetail?.id || ""}`}>
                  <MyButton
                    type="button"
                    class={`btn btn-success margin-right-8`}
                    content={intl.formatMessage({ id: "Edit"})}
                    disable={
                      Boolean(successMessage) || loading
                    }
                    loading={
                      loading
                    }
                    onClick={async ()=> {
                      hideModal();
                      history.push(`/${props.blnListView ? RoutesList.appointmentList : RoutesList.appointment}/edit/${props.selectedAppointmentDetail?.id || ""}`)
                    }}/>
                </Link>
              }
              {
                blnPermissionDeleteAppointment
                &&
                props.selectedAppointmentDetail.isAbleRescheduleOrDelete
                &&
                <MyButton
                  type="button"
                  class={`btn btn-danger margin-right-16`}
                  content={intl.formatMessage({ id: "Delete"})}
                  disable={
                    Boolean(successMessage) || loading
                  }
                  loading={
                    loading
                  }
                  onClick={async ()=> {
                    if (props.selectedAppointmentDetail) {
                      onClickDelete(props.selectedAppointmentDetail)
                    }
                  }}
                />
              }
            </div>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col xs={"6"} md={"6"} xl={"8"} >
                {displayRowData("Start Date And Time", moment(props.selectedAppointmentDetail.appointStartDateTime).format(Constants.displayDateAndTimeWithDayFormat))}
                <hr/>
                {
                  props.selectedAppointmentDetail.subBrandName
                  && 
                  <>
                    {displayRowData(`${intl.formatMessage({ id: "SubBrand"})}`, props.selectedAppointmentDetail.subBrandName)}
                    <hr />
                  </>
                }
                {displayRowData(`${intl.formatMessage({ id: "Branch"})}`, props.selectedAppointmentDetail.branchName)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "Services"})}`, props.selectedAppointmentDetail.serviceTreatmentNames.length > 0 ? returnNameFromArray(props.selectedAppointmentDetail.serviceTreatmentNames) : intl.formatMessage({id: "FollowUpOnly"}))}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "Duration"})}`, `${props.selectedAppointmentDetail.duration} ${intl.formatMessage({ id: "Minutes"}).toLowerCase()}`)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "Consultant"})}`, props.selectedAppointmentDetail.consultantName)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "Room"})}`, props.selectedAppointmentDetail.roomName)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "Source"})}`, props.selectedAppointmentDetail.mediaSourceName)}
                <hr/>
                {
                  props.selectedAppointmentDetail.mediaSourceName === Constants.referral
                  &&
                  <>
                    {displayRowData(`${intl.formatMessage({ id: "ReferredBy"})}`, props.selectedAppointmentDetail.referredByName)}
                    <hr/>
                  </>
                }
                {displayRowData(`${intl.formatMessage({ id: "Remark"})}`, props.selectedAppointmentDetail.remark)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "MadeBy"})}`, props.selectedAppointmentDetail.madeBy)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "EditedBy"})}`, props.selectedAppointmentDetail.editedBy)}
                <hr/>
                {displayRowData(`${intl.formatMessage({ id: "MadeOn"})}`, moment(props.selectedAppointmentDetail.madeOn).format(Constants.displayDateAndTimeFormat))}
                <hr />
                {displayRowData(linkToCustomerText, props.selectedAppointmentDetail.customerName)}
                <hr />
                {displayRowData(nextAppointmentText, props.selectedAppointmentDetail.nextAppointment ? moment(props.selectedAppointmentDetail.nextAppointment.appointmentStartDateTime).format(Constants.displayDateAndTimeWithDayFormat) : "-")}
                {
                  props.selectedAppointmentDetail.appolousId !== null
                  &&
                  blnShowAppolous
                  &&
                  <>              
                    <hr/>
                    {displayRowData(`${intl.formatMessage({ id: "ViewAppolous"})}`, String(props.selectedAppointmentDetail.appolousId))}
                  </>
                }
              </Col>
              <Col xs={"6"} md={"6"} xl={"4"} >
                {
                  appointmentStatusType.map((value)=> 
                    displayStatusButton(value.displayValue)
                  )
                }
                <div className="flex-direction-row flex-right">
                  {
                    blnDisplayReschedule
                    &&
                    <MyButton
                      type="button"
                      class={`btn btn-primary mt-4`}
                      content={intl.formatMessage({ id: "Reschedule"})}
                      style={{ width: '100%' }}
                      disable={
                        Boolean(successMessage) || loading
                      }
                      loading={
                        loading
                      }
                      onClick={()=> {
                        if (props.setObjReschedule) {
                          props.setObjReschedule({
                            appointmentId: props.selectedAppointmentDetail?.id,
                            customerName: props.selectedAppointmentDetail?.customerName,
                            fromDateAndTime: moment(props.selectedAppointmentDetail?.appointStartDateTime).format(Constants.displayDateAndTimeFormat),
                            startDate: "", 
                            startTime: "",
                            actionType: Constants.update
                          })
                          window.scrollTo(0, 0);
                          hideModal();
                        }
                      }}
                    />
                  }
                </div>
                <div className="flex-direction-row flex-right">
                  {
                    blnPermissionCreateAppointment
                    &&
                    <MyButton
                      type="button"
                      class={`btn btn-info ${blnDisplayReschedule ? "mt-2" : "mt-4"}`}
                      content={intl.formatMessage({ id: "CopyAppointment"})}
                      style={{ width: '100%' }}
                      disable={
                        Boolean(successMessage) || loading
                      }
                      loading={
                        loading
                      }
                      onClick={()=> {
                        if (props.setObjReschedule) {
                          props.setObjReschedule({
                            appointmentId: props.selectedAppointmentDetail?.id,
                            customerName: props.selectedAppointmentDetail?.customerName,
                            fromDateAndTime: moment(props.selectedAppointmentDetail?.appointStartDateTime).format(Constants.displayDateAndTimeFormat),
                            startDate: "", 
                            startTime: "",
                            actionType: Constants.copy
                          })
                          window.scrollTo(0, 0);
                          hideModal();
                        }
                      }}
                    />
                  }
                </div>
                <div className="flex-direction-row flex-right">
                  {
                    blnCustomerIdExist
                    &&
                    blnPermissionManageCustomerSalesOrder
                    &&
                    (!blnStatusNotShow || blnPermissionReviseClosedAppointment)
                    &&
                    (props.selectedAppointmentDetail.IsAccessible || props.selectedAppointmentDetail.customerBranchId === getBranchId() || props.selectedAppointmentDetail.customerSubBranchId === getBranchId())
                    &&
                    <MyButton
                      type="button"
                      class={`btn btn-success ${!blnDisplayReschedule && !blnPermissionCreateAppointment ? "mt-4" : "mt-2"}`}
                      content={intl.formatMessage({ id: "MakeAPayment"})}
                      style={{ width: '100%' }}
                      disable={
                        Boolean(successMessage) || loading
                      }
                      loading={
                        loading
                      }
                      onClick={async ()=> {
                        history.push({
                          pathname: `/${RoutesList.pos}`,
                          state: { 
                            detail:{
                              consultantId: props.selectedAppointmentDetail?.consultantId || "",
                              consultantName: props.selectedAppointmentDetail?.consultantName || "",
                              customerId: props.selectedAppointmentDetail?.customerId || ""
                            }
                          }
                        });
                      }}
                    />
                  }
                </div>
              </Col>
            </Row>            
          </div>
          <SelectDropDownModal
            blnShow={customerModal}
            setModal={setCustomerModal}
            title={`${intl.formatMessage({ id: "InputSelectTitle"}, {field : intl.formatMessage({ id: "Customer"})})}`}
            buttonTittle={intl.formatMessage({ id: "LinkCustomer"})}
            placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
            labelField={"preferredName"}
            valueField={"id"}
            onClickWithSingleValueReturn={linkAppointmentCustomerFunction}
            options={customerDropdownList}
            blnSupportCustomSearch={true}
            onChangeCustomSearch={(value)=> {
              fetchCustomerDropdown(value);
            }}
            onMenuOpenFunction={()=> {
              clearCustomerDropdownList();
              setLoadingCustomer(false);
            }}
            loadingCustomSearch={loadingCustomer}
            setLoadingCustomSearch={setLoadingCustomer}/>
          <DeleteModal
            displayName={`${intl.formatMessage({ id: "Appointment"})} ${intl.formatMessage({ id: "From"}).toLowerCase()} ${selectedRow?.customerName}`}
            onDeleteClick={async ()=> {
              setLoading(true);
              let resultTemp = await deleteAppointment(selectedRow?.id || "", `${intl.formatMessage({ id: "Appointment"})} ${intl.formatMessage({ id: "From"}).toLowerCase()} ${selectedRow?.customerName}`);
              if (props.refreshAppointmentData && resultTemp?.status == Constants.success) {
                setLoading(false);
                props.refreshAppointmentData(true);
              }
              setTimeout(()=> {
                setSuccessMessage("");
                hideModal();
              }, Constants.dismissSuccessMessage)
            }}/>
          {
            unlinkCustomerModal
            &&
            <ActionPromptModal 
              title={`${intl.formatMessage({ id: "UnlinkAppointmentMessage" },  { name: props.selectedAppointmentDetail.customerName })}`}
              primaryActionTitle={`Yes`}
              showPrompt={unlinkCustomerModal}
              setShowPrompt={setUnlinkCustomerModal}
              onPrimaryClick={()=> {
                linkAppointmentCustomerFunction();
                setUnlinkCustomerModal(false);
              }}
            />
          }
          {
            notShowModal
            &&
            <NotShowModal
              blnShow={notShowModal}
              setModal={setNotShowModal}
              appointmentId={props.selectedAppointmentDetail.id}
              appointmentStatusUpdateFunction={onClickNotShowFunction}/>
          }
        </div>
      }
    </Modal>
  )
})