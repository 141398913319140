import React from "react";
import { ObjectForDropdown } from "../../../app/models/common";
import { useIntl } from "react-intl";

interface Props {
  title: string;
  subTitle: string;
  aryReport: ObjectForDropdown[];
  className?: string;
}

export default function ReportListingWithClick(props: Props) {
  const intl = useIntl();
  
  return (
    <div className={`standard-layout ${props.className ? props.className : ""}`}>
      <div className="h4">{props.title}</div>
      <p className="text-muted">
        {props.subTitle}
      </p>
      <hr/>
      {
        props.aryReport.length > 0
        ?
        props.aryReport.map((valueReport, index)=> (
          <div style={{marginTop: index === 0 ? "32px" : "0px"}} key={`${valueReport.label}_${index}`}>
            <div
              className="d-block text-primary mb-2 h6 pointer-clickable"
              onClick={()=> {
                valueReport.value();
              }}>
              {valueReport.label}
            </div>
            {
              props.aryReport.length -1 !== index
              &&
              <hr style={{opacity: "0.1"}}/>
            }
          </div>
        ))
        :
        <span style={{color: 'grey'}}>
          {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: props.title})}
        </span>
      }
    </div>
  )
}