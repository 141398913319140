import { PaginationRequestBody } from "../models/pagination";
import { StockOutAddObject, StockOutDetailObject, StockOutListObject, StockOutUpdateObject } from "../models/stockOut";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class StockOutStore {
  stockOutList: StockOutListObject[] = [];
  stockOutDetail: StockOutDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockOutList = [];
    this.stockOutDetail = undefined;
  }

  setStockOutDetail = (stockOutDetail: StockOutDetailObject | undefined) => {
    this.stockOutDetail = stockOutDetail;
  }

  getStockOut = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultStockOuts = await agent.StockOut.stockOutList(PaginationRequestBody);
      runInAction(() => {
        this.stockOutList = resultStockOuts.data;
        store.commonStore.setTotalItem(resultStockOuts.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockOutList = [];
    }
  };

  getStockOutWithId = async (id: string) => {
    try{
      const resultStockOutDetail = await agent.StockOut.stockOutDetail(id);
      runInAction(() => {
        this.stockOutDetail = resultStockOutDetail;
      });

      return Promise.resolve(resultStockOutDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockOutDetail = undefined;
    }
  }

  addStockOut = async (stockOutRequestBody: StockOutAddObject) => {
    try{
      await agent.StockOut.addStockOut(stockOutRequestBody);
      store.commonStore.setSuccessMessage(`StockOutAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockOut = async (stockOutRequestBody: StockOutUpdateObject) => {
    try{
      await agent.StockOut.updateStockOut(stockOutRequestBody);
      store.commonStore.setSuccessMessage(`StockOutUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockOut  = async (id: string, name: string) => {
    try {
      await agent.StockOut.deleteStockOut(id);
      store.commonStore.setSuccessMessage(`StockOutDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
