import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { history } from '../..';
import { useLocation } from "react-router-dom";
import { RoutesList } from "../../app/constants/RoutesList";

const ForgetPasswordValidator = () => {
  const paramsSearch = useLocation().search;

  useEffect(() => {
    const useridParams = new URLSearchParams(paramsSearch).get('u');
    let codeParams = "";
    const fullParams = window.location.href;
    const codeIndex = fullParams.indexOf('code=');
    if (codeIndex !== -1) {
      const startIndex = codeIndex + 5;
      codeParams = String(fullParams).substring(startIndex, fullParams.length);
    }

    if(useridParams && codeParams){
      history.push({
        pathname: `/${RoutesList.forgotPassword}`,
        state: { 
          detail: {
            userId: useridParams,
            code: codeParams
          }
        }
      });
    }else{
      history.push(RoutesList.serverError)
    }
  }, [])

  return (
    <div>
      
    </div>
  )
}

export default observer(ForgetPasswordValidator);
