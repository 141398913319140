import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { isTablet } from "react-device-detect";

interface Props {
  title: string;
  childen: any;
  blnShow: boolean;
  setModal: Function;
}

export default observer(function DSRModal (props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { loading, successMessage } = commonStore;

	return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        props.setModal(false);
      }}
      size="xl"
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered>
      {
        <div className="">
          <div className="modal-header">
            <div className="flex-direction-row" style={{flex:1 }}>
              <h5 className="modal-title margin-top-0" style={{flex:1 }}>{props.title}</h5>
            </div>
            {
              <button
                type="button"
                onClick={() => {
                  props.setModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
          <div className="modal-body">
            <div style={{overflowX: 'scroll'}}>
              {
                props.childen
                &&
                props.childen()
              }    
            </div>          
          </div>
        </div>
      }
    </Modal>
  )
})
