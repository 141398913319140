import React, { useEffect, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import MetisMenu from "metismenujs";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { MenuConstants } from '../../constants/MenuConstants';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

const SidebarContent = (props: any) => {
  const ref : any = useRef();

  //Use Store
  const { commonStore, permissionStore } = useStore();
  const { showPendingMenu } = commonStore;
  const { menuList } = permissionStore;  
  const isMobileApplicable = window.screen.width <= 998 && isMobile;

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem : any = null;
      const ul = document.getElementById("side-menu");
      const items = ul?.getElementsByTagName("a") || [];
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: HTMLDivElement) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  } 

  const displayFullHeader = (displayName: string, routeName: string = "") => {
    let icon = "";
    
    if (displayName === MenuConstants.Dashboard) {
      icon = "bx bxs-dashboard";
    }
    else if (displayName === MenuConstants.Appointment) {
      icon = "bx bx-calendar";
    }
    else if (displayName === MenuConstants.Sales) {
      icon = "bx bxs-notepad";
    }
    else if (displayName === MenuConstants.Customer) {
      icon = "bx bxs-user-detail"
    }
    else if (displayName === MenuConstants.Inventory) {
      icon = "bx bxs-package"
    }
    else if (displayName === MenuConstants.HumanResource) {
      icon = "bx bx-group"
    }
    else if (displayName === MenuConstants.Business) {
      icon = "bx bxs-business"
    }
    else if (displayName === MenuConstants.Settings) {
      icon = "bx bx-cog"
    }
    else if (displayName === MenuConstants.Purchase) {
      icon = "bx bxs-cube"
    }
    else if (displayName === MenuConstants.Report) {
      icon = "bx bxs-report"
    }
    else if (displayName === MenuConstants.Marcom) {
       icon = "bx bxs-user-check"
    }
    else if (displayName === MenuConstants.Closing) {
      icon = "bx bxs-lock closing-menu-color"
    }
    
    return (
      <Link 
        to={`${routeName || "/#"}`} 
        className={routeName ? "" : "has-arrow"}
        onClick={()=> {
          if (routeName) {
            dismissMenuFromMobile();
          }
        }}>
        {icon && <i className={icon}></i>}
        <span className={icon ? `side-menu-header ${displayName === MenuConstants.Closing ? "closing-menu-color" : ""}` : "side-menu-content"}>{displayName}</span>
      </Link>
    )
  }

  const displayMenuHeader = (displayName: string) => {
    return (
      <span className='side-menu-header'>{displayName}</span>
    )
  }

  const displayMenuContent = (to: string, displayName: string) => {
    return (
      <Link 
        className="side-menu-content"
        to={to}
        key={displayName}
        onClick={()=> {
          dismissMenuFromMobile();
        }}>
        {displayName}
      </Link>
    )
  }

  const dismissMenuFromMobile = () => {
    if (isMobileApplicable) {
      var body = document.body;
      body.classList.toggle("sidebar-enable"); 
    }
  }

  const aryHideMenuItems : string[] = [MenuConstants.PurchaseOrder, MenuConstants.InboundNotice, MenuConstants.StockIn, MenuConstants.StockOut, MenuConstants.GoodsReceiveNote, MenuConstants.PurchaseDeliverOrder, MenuConstants.Warehouse, MenuConstants.Supplier];
  // const aryHideMenuItems : string[] = [];

  return (
    <SimpleBar className="h-100 simplebar-content-wrapper" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {
            menuList.map((valueMenu)=> {
              // if (valueMenu.menuName === MenuConstants.Purchase && !showPendingMenu) {
              //   return;
              // }
              
              return (
                valueMenu.isActive 
                ?
                valueMenu.route
                ?
                <li key={valueMenu.menuName}>
                  {displayFullHeader(valueMenu.menuName, valueMenu.route)}
                </li>
                :
                <li key={valueMenu.menuName}>
                  {displayFullHeader(valueMenu.menuName)}
                  <ul className="sub-menu" aria-expanded="false">
                    {
                      valueMenu.subContent.map((valueSubContent, indexSubContent) => {
                        let indexFound = aryHideMenuItems.indexOf(valueSubContent.name);
                        if (indexFound > -1) {
                          return;
                        }
                        
                        if (!valueSubContent.isSubMenu) {
                          return valueSubContent.isActive &&
                            <li key={valueSubContent.name}>
                              {displayMenuContent(valueSubContent.route, valueSubContent.name)}
                            </li>
                        }
                        else if (valueSubContent.isSubMenu && valueSubContent.isActive && valueSubContent.subContent){
                          return (
                            <li key={valueSubContent.name}>
                              {displayFullHeader(valueSubContent.name)}
                              <ul className="sub-menu" aria-expanded="false" key={`sub_content_${indexSubContent}`}>
                              {
                                valueSubContent.subContent.map((valueSubContentLevel2) => {
                                  let indexFound = aryHideMenuItems.indexOf(valueSubContentLevel2.name);
                                  if (indexFound > -1) {
                                    return;
                                  }
                                  
                                  return valueSubContentLevel2.isActive &&
                                    <li key={valueSubContentLevel2.name}>
                                      {displayMenuContent(valueSubContentLevel2.route, valueSubContentLevel2.name)}
                                    </li>
                                })
                              }
                            </ul>
                          </li>
                          )
                        }
                      })
                    }
                  </ul>
                </li>
                :
                null
              )}
            )
          }            
          {/*Settings*/}
          {/* {
            showPendingMenu
            &&
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-cog"></i>
                {displayMenuHeader("Settings (Pending)")}
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Account Settings"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Appointment Settings"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Sales Settings"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Inventory Settings"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("SMS Settings"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    <Link to={`/${RoutesList.dashboard}`} >{("Additional Form Fields")}</Link>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Additional Form Fields"))}
                  </li>
                }                
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Payment Methods"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Sources"))}
                  </li>
                }
                {
                  showPendingMenu
                  &&
                  <li>
                    {displayMenuContent(`/${RoutesList.dashboard}`, ("Titles"))}
                  </li>
                }
              </ul>
            </li>
          } */}
        </ul>
      </div>
    </SimpleBar>
  )
}

export default  observer(withRouter(SidebarContent));
