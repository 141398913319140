import { PaginationRequestBody } from "../models/pagination";
import { CustomerProductReturnAddObject, CustomerProductReturnDetailObject, CustomerProductReturnEligibleListObject, CustomerProductReturnListObject, CustomerProductReturnUpdateObject } from "../models/customerProductReturn";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class CustomerProductReturnStore {
  customerProductReturnList: CustomerProductReturnListObject[] = [];
  customerProductReturnDetail: CustomerProductReturnDetailObject | undefined = undefined;
  customerProductReturnPaginationParams: PaginationRequestBody | undefined = undefined;
  customerProductReturnEligibleList: CustomerProductReturnEligibleListObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.customerProductReturnList = [];
    this.customerProductReturnDetail = undefined;
    this.customerProductReturnPaginationParams = undefined;
    this.customerProductReturnEligibleList = [];
  }

  setCustomerProductReturnList = (customerProductReturnList: CustomerProductReturnListObject[]) => {
    this.customerProductReturnList = customerProductReturnList;
  }
  
  setCustomerProductReturnDetail = (customerProductReturnDetail: CustomerProductReturnDetailObject | undefined) => {
    this.customerProductReturnDetail = customerProductReturnDetail;
  }

  setCustomerProductReturnPaginationParams = (customerProductReturnPaginationParams: PaginationRequestBody | undefined) => {
    this.customerProductReturnPaginationParams = customerProductReturnPaginationParams;
  }

  setCustomerProductReturnEligibleList = (customerProductReturnEligibleList: CustomerProductReturnEligibleListObject[]) => {
    this.customerProductReturnEligibleList = customerProductReturnEligibleList; 
  }

  getCustomerProductReturn = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCustomerProductReturnPaginationParams(PaginationRequestBody);
    try {
      const resultCustomerProductReturns = await agent.CustomerProductReturn.customerProductReturnList(PaginationRequestBody);
      runInAction(() => {
        this.customerProductReturnList = resultCustomerProductReturns.data;
        store.commonStore.setTotalItem(resultCustomerProductReturns.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerProductReturnList = [];
    }
  };

  getCustomerProductReturnWithId = async (id: string) => {
    try{
      const resultCustomerProductReturnDetail = await agent.CustomerProductReturn.customerProductReturnDetail(id);
      runInAction(() => {
        this.customerProductReturnDetail = resultCustomerProductReturnDetail;
      });
      return Promise.resolve(resultCustomerProductReturnDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerProductReturnDetail = undefined;
    }
  }

  getCustomerProductReturnEligibleWithId = async (customerId: string) => {
    try{
      let resultCustomerProductReturnEligible = await agent.CustomerProductReturn.customerProductReturnEligibleList(customerId);
      if (resultCustomerProductReturnEligible) {
        resultCustomerProductReturnEligible = resultCustomerProductReturnEligible.map((valueCustomerProductReturnEligibleTemp) => {
          if (valueCustomerProductReturnEligibleTemp.details) {
            valueCustomerProductReturnEligibleTemp.details = valueCustomerProductReturnEligibleTemp.details.map((valueCustomerProductReturnEligibleDetailTemp)=> ({
              ...valueCustomerProductReturnEligibleDetailTemp,
              maxQuantity: valueCustomerProductReturnEligibleDetailTemp.availableCount,
              quantity: 0
            }))
          }
          return valueCustomerProductReturnEligibleTemp;
        })
      }
      runInAction(() => {
        this.customerProductReturnEligibleList = resultCustomerProductReturnEligible;
      });
      return Promise.resolve(resultCustomerProductReturnEligible);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerProductReturnEligibleList = [];
    }
  }

  addCustomerProductReturn = async (customerProductReturnRequestBody: CustomerProductReturnAddObject) => {
    try{
      await agent.CustomerProductReturn.addCustomerProductReturn(customerProductReturnRequestBody);
      store.commonStore.setSuccessMessage(`CustomerProductReturnAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCustomerProductReturn = async (customerProductReturnRequestBody: CustomerProductReturnUpdateObject) => {
    try{
      await agent.CustomerProductReturn.updateCustomerProductReturn(customerProductReturnRequestBody);
      store.commonStore.setSuccessMessage(`CustomerProductReturnUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
