import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, getBrandId, getMonths, getYears, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnSubBrandList } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const AppointmentTargetDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { appointmentTargetStore, staticReferenceStore, branchStore, employeeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addAppointmentTarget, updateAppointmentTarget, appointmentTargetDetail, setAppointmentTargetDetail, getAppointmentTargetWithId } = appointmentTargetStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "AppointmentTarget" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "AppointmentTarget" }), urlPath: RoutesList.appointmentTarget }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateAppointmentTarget = checkPermission([PermissionConstants.UpdateAppointmentTarget]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<any>([]);
  const [showApplicableToFutureMonths, setShowApplicableToFutureMonths] = useState(false);
  const [confirmAppointmentTargetSubmissionMessage, setConfirmAppointmentTargetSubmissionMessage] = useState("");
  const [showConfirmAppointmentTargetSubmission, setShowConfirmAppointmentTargetSubmission] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: appointmentTargetDetail || {
      year: 0,
      month: 0,
      branchId: "",
      branchName: "",
      marcomCsIds: [],
      target: 0,
      isActive: false,
      subBrandId: "",
      subBrandName: "",
      isApplicableToFutureMonths: false
    },
    validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any, context) => {
        if (returnSubBrandList().length > 0 ? true : false) {
          return value;
        }
        else {
          return true;
        }
      }).nullable(),
      marcomCsIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Marcom" }) })),
      year: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })),
      month: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })),
      target: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Target" }) })),
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (valuesTemp.commissionType && valuesTemp.commissionValue === 0) {
        valuesTemp.commissionValue = 0;
      }
      try {
        if (addAction) {
          let resultAddAppointmentTarget = await addAppointmentTarget(valuesTemp);
          if (resultAddAppointmentTarget) {
            if (resultAddAppointmentTarget.message) {
              setConfirmAppointmentTargetSubmissionMessage(resultAddAppointmentTarget.message);
              setShowConfirmAppointmentTargetSubmission(true);
            }
          }
        } else {
          setShowApplicableToFutureMonths(true);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const onClickUpdateAppointmentTarget = async (isApplicableToFutureMonths: boolean) => {
    validation.setSubmitting(true);
    try {
      let valuesTemp: any = _.cloneDeep(validation.values)
      valuesTemp = { ...valuesTemp, isApplicableToFutureMonths }
      await updateAppointmentTarget(valuesTemp);
      setShowApplicableToFutureMonths(false);
    } finally {
      validation.setSubmitting(false);
    }
  }

  const onClickAddAppointmentTarget = async (isConfirmAppointmentTargetSubmission: boolean) => {
    validation.setSubmitting(true);
    try {
      let valuesTemp: any = _.cloneDeep(validation.values)
      valuesTemp = { ...valuesTemp, isConfirmAppointmentTargetSubmission }
      await addAppointmentTarget(valuesTemp);
      setShowConfirmAppointmentTargetSubmission(false);
    } finally {
      validation.setSubmitting(false);
    }
  }

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnAppointmentTargetName = !addAction && viewAction;

  useEffect(() => {
    async function fetchAppointmentTargetDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.marComCustomerServiceId}`], isActive: true })
      ];
      if (id && !addAction) {
        aryAPI.push(getAppointmentTargetWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setAppointmentTargetDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateAppointmentTarget], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateAppointmentTarget], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAppointmentTarget], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateAppointmentTarget) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.appointmentTarget}/edit/${id}`) } })
    }

    setMonthList(getMonths());
    setYearList(getYears());
    fetchAppointmentTargetDetailAPI();

    return (() => {
      if (id && !addAction) {
        setAppointmentTargetDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (appointmentTargetDetail) {
        if (appointmentTargetDetail.marcomCsIds) {
          let arySelectedPositionIdTemp: any = [];
          appointmentTargetDetail.marcomCsIds.map((value) => {
            for (var a = 0; a < employeeDropdownList.length; a++) {
              if (value === employeeDropdownList[a].id) {
                arySelectedPositionIdTemp.push({
                  ...employeeDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(employeeDropdownList[a].preferredName),
                  value: employeeDropdownList[a].id
                })
              }
            }
          })
          setSelectedEmployeeIds(arySelectedPositionIdTemp);
        }
      }
    }
  }, [appointmentTargetDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
        setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                {
                  blnAppointmentTargetName &&
                  <>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "AppointmentTarget" }) })}
                      h4Title />
                    <SingleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "AppointmentTargetName" })}
                        name="name"
                        className="mb-3 mt-4"
                        type="text"
                        disabled={disabledFieldInput || blnAppointmentTargetName}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                  </>
                }
                {
                  returnSubBrandList().length > 0
                  &&
                  <SingleColumnRowParent>
                    <DropDownWithTitle
                      name={"subBrandId"}
                      title={intl.formatMessage({ id: "SubBrand" })}
                      specifyReturnFieldName={[{ "field": "subBrandName", "value": "name" }, { "field": "subBrandId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={returnSubBrandList()}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialLabel={validation.values.subBrandName}
                      initialValue={validation.values.subBrandId}
                      validation={validation} />
                  </SingleColumnRowParent>
                }
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"year"}
                      title={intl.formatMessage({ id: "Year" })}
                      specifyReturnFieldName={[{ "field": "year", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      options={yearList}
                      disabled={disabledFieldInput || viewAction || !addAction}
                      initialLabel={!addAction ? `${validation.values.year}` : undefined}
                      initialValue={!addAction ? validation.values.year : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"month"}
                      title={intl.formatMessage({ id: "Month" })}
                      specifyReturnFieldName={[{ "field": "month", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      placeholder={""}
                      options={monthList}
                      disabled={disabledFieldInput || viewAction || !addAction}
                      initialLabel={!addAction ? `${moment(validation.values.month, "MM").format("MMM")}` : undefined}
                      initialValue={!addAction ? validation.values.month : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"branchId"}
                    title={intl.formatMessage({ id: "Branch" })}
                    specifyReturnFieldName={[
                      {
                        field: "branchId",
                        value: "id",
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={branchDropDownList}
                    blnValueWithNewSpace={true}
                    disabled={disabledFieldInput || viewAction || !addAction}
                    initialLabel={!addAction ? validation.values.branchName : undefined}
                    initialValue={!addAction ? validation.values.branchId : undefined}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitleMultiSelect
                    name={"marcomCsIds"}
                    title={intl.formatMessage({ id: "Marcom" })}
                    specifyReturnFieldName={[{ "field": "marcomCsIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={employeeDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={selectedEmployeeIds}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Target" })}
                    name="target"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  {
                    addAction
                    &&
                    <DoubleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "ApplicableToFutureMonths" })}
                        name="isApplicableToFutureMonths"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                    </DoubleColumnRowParent>
                  }
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Active" })}
                      name="isActive"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                  </DoubleColumnRowParent>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        showApplicableToFutureMonths
        &&
        <ActionPromptModal
          title={intl.formatMessage({ id: "UpdateSalesTargetApplicableToFutureMonths" })}
          showPrompt={showApplicableToFutureMonths}
          cancelActionTitle={intl.formatMessage({ id: "No" })}
          setShowPrompt={setShowApplicableToFutureMonths}
          blnShowCloseButton={true}
          onPrimaryClick={() => onClickUpdateAppointmentTarget(true)}
          onCancelClick={() => onClickUpdateAppointmentTarget(false)}
        />
      }
      {
        showConfirmAppointmentTargetSubmission
        &&
        <ActionPromptModal
          title={confirmAppointmentTargetSubmissionMessage}
          showPrompt={showConfirmAppointmentTargetSubmission}
          cancelActionTitle={intl.formatMessage({ id: "No" })}
          setShowPrompt={setShowConfirmAppointmentTargetSubmission}
          blnShowCloseButton={true}
          onPrimaryClick={() => onClickAddAppointmentTarget(true)}
        />
      }

    </div>
  );
};

export default observer(AppointmentTargetDetail);
