import { PaginationRequestBody } from "../models/pagination";
import { EPFContributionListObject, EPFContributionAddObject, EPFContributionUpdateObject, EPFContributionDetailObject } from "../models/epfContribution";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class EPFContributionStore {
  epfContributionList: EPFContributionListObject[] = [];
  epfContributionDropdownList: EPFContributionListObject[] = [];
  epfContributionDetail: EPFContributionDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.epfContributionList = [];
    this.epfContributionDropdownList = [];
    this.epfContributionDetail = undefined;
  }

  setEPFContributionDetail = (epfContributionDetail: EPFContributionDetailObject | undefined) => {
    this.epfContributionDetail = epfContributionDetail;
  }
  
  getEPFContribution = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultEPFContribution = await agent.EPFContribution.epfContributionList(PaginationRequestBody);
      runInAction(() => {
        this.epfContributionList = resultEPFContribution.data;
        store.commonStore.setTotalItem(resultEPFContribution.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.epfContributionList = [];
    }
  }

  getEPFContributionDropdown = async () => {
    try{
      const resultEPFContributionDropdown = await agent.EPFContribution.epfContributionDropdownList();
      runInAction(() => {
        this.epfContributionDropdownList = resultEPFContributionDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.epfContributionDropdownList = [];
    }
  }

  getEPFContributionWithId = async (id: string) => {
    try{
      const resultEPFContributionDetail = await agent.EPFContribution.epfContributionDetail(id);
      runInAction(() => {
        this.epfContributionDetail = resultEPFContributionDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.epfContributionDetail = undefined;
    }
  }

  addEPFContribution = async (epfContributionRequestBody: EPFContributionAddObject) => {
    try{
      await agent.EPFContribution.addEPFContribution(epfContributionRequestBody);
      store.commonStore.setSuccessMessage(`EPFContributionAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateEPFContribution = async (epfContributionRequestBody: EPFContributionUpdateObject) => {
    try{
      await agent.EPFContribution.updateEPFContribution(epfContributionRequestBody);
      store.commonStore.setSuccessMessage(`EPFContributionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteEPFContribution  = async (id: string, name: string) => {
    try {
      await agent.EPFContribution.deleteEPFContribution(id);
      store.commonStore.setSuccessMessage(`EPFContributionDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}