import { PaginationRequestBody } from "../models/pagination";
import { AppolousCustomerPackageAddRequestBody, AppolousCustomerPackageDetailObject, AppolousCustomerPackageDetailRequestBody, AppolousCustomerPackageEditRequestBody, AppolousCustomerPackageExtendExpiryRequestBody, AppolousCustomerPackageListObject, AppolousCustomerPackageRedeemRequestBody, AppolousCustomerPackageRedemptionEditRequestBody, AppolousCustomerPackageRedemptionObject, AppolousCustomerPackageStatusUpdateRequestBody } from "../models/appolousCustomerPackage";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { convertArrayToStringDisplay } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class AppolousCustomerPackageStore {
  appolousCustomerPackageList: AppolousCustomerPackageListObject[] = [];
  appolousCustomerPackageDetail: AppolousCustomerPackageDetailObject | undefined = undefined;
  appolousCustomerPackageRedemptionDetail: AppolousCustomerPackageRedemptionObject | undefined = undefined;
  appolousCustomerPackageListTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.appolousCustomerPackageList = [];
    this.appolousCustomerPackageDetail = undefined;
    this.appolousCustomerPackageRedemptionDetail = undefined;
    this.appolousCustomerPackageListTotalItems = 0;
  }

  setAppolousCustomerPackageList = (appolousCustomerPackageList: AppolousCustomerPackageListObject[]) => {
    this.appolousCustomerPackageList = appolousCustomerPackageList;
  }

  setAppolousCustomerPackageDetail = (appolousCustomerPackageDetail: AppolousCustomerPackageDetailObject | undefined) => {
    this.appolousCustomerPackageDetail = appolousCustomerPackageDetail;
  }

  setAppolousCustomerPackageRedemptionDetail= (appolousCustomerPackageRedemptionDetail: AppolousCustomerPackageRedemptionObject | undefined) => {
    this.appolousCustomerPackageRedemptionDetail = appolousCustomerPackageRedemptionDetail;
  }

  getAppolousCustomerPackage = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultAppolousCustomerPackage = await agent.AppolousCustomerPackage.appolousCustomerPackage(PaginationRequestBody);
      runInAction(() => {
        this.appolousCustomerPackageList = resultAppolousCustomerPackage.data;
        this.appolousCustomerPackageListTotalItems = resultAppolousCustomerPackage.pagination.totalItems;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerPackageList = [];
      this.appolousCustomerPackageListTotalItems = 0;
    }
  };

  getAppolousCustomerPackageDetail = async (AppolousCustomerPackageDetailRequestBody: AppolousCustomerPackageDetailRequestBody, blnSkipSetData: boolean = false) => {
    try {
      const resultAppolousCustomerProductDetail = await agent.AppolousCustomerPackage.appolousCustomerPackageDetail(AppolousCustomerPackageDetailRequestBody);
      
      runInAction(() => {
        if (!blnSkipSetData) {
          this.appolousCustomerPackageDetail = resultAppolousCustomerProductDetail;
        }
      });
      
      return Promise.resolve(resultAppolousCustomerProductDetail);
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerPackageDetail = undefined;
    }
  };

  getAppolousCustomerPackageRedemptionDetail = async (appolousCustomerPackageRedemptionId: string) => {
    try {
      const resultAppolousCustomerPackageRedemptionDetail = await agent.AppolousCustomerPackage.appolousCustomerPackageRedemptionDetail(appolousCustomerPackageRedemptionId);
      if (resultAppolousCustomerPackageRedemptionDetail) {
        if (resultAppolousCustomerPackageRedemptionDetail.consumableProductNames) {
          resultAppolousCustomerPackageRedemptionDetail.consumableProductNamesDisplay = convertArrayToStringDisplay(resultAppolousCustomerPackageRedemptionDetail.consumableProductNames);
        }
      }
      runInAction(() => {
        this.appolousCustomerPackageRedemptionDetail = resultAppolousCustomerPackageRedemptionDetail;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerPackageRedemptionDetail = undefined;
    }
  };

  extendExpiryAppolousCustomerPackage = async (appolousCustomerPackageExtendExpiryRequestBody: AppolousCustomerPackageExtendExpiryRequestBody) => {
    try {
      await agent.AppolousCustomerPackage.extendExpiryAppolousCustomerPackage(appolousCustomerPackageExtendExpiryRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageExtendExpirySuccess`)
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };

  statusUpdateAppolousCustomerPackage = async (appolousCustomerPackageStatusUpdateRequestBody: AppolousCustomerPackageStatusUpdateRequestBody) => {
    try {
      await agent.AppolousCustomerPackage.statusUpdateAppolousCustomerPackage(appolousCustomerPackageStatusUpdateRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageStatusUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  editAppolousCustomerPackage = async (appolousCustomerPackageEditRequestBody: AppolousCustomerPackageEditRequestBody) => {
    try {
      await agent.AppolousCustomerPackage.editAppolousCustomerPackage(appolousCustomerPackageEditRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageEditSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  editAppolousCustomerPackageDetail = async (appolousCustomerPackageRedemptionEditRequestBody: AppolousCustomerPackageRedemptionEditRequestBody) => {
    try {
      await agent.AppolousCustomerPackage.editAppolousCustomerPackageDetail(appolousCustomerPackageRedemptionEditRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageDetailEditSuccess`)
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };

  redeemAppolousCustomerPackage = async (appolousCustomerPackageRedeemRequestBody: AppolousCustomerPackageRedeemRequestBody) => {
    try {
      await agent.AppolousCustomerPackage.redeemAppolousCustomerPackage(appolousCustomerPackageRedeemRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageRedeemSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  voidAppolousCustomerPackageRedemption = async (appolousCustomerPackageRedemptionId: string) => {
    try {
      await agent.AppolousCustomerPackage.voidAppolousCustomerPackageRedemption(appolousCustomerPackageRedemptionId);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageRedemptionVoidSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  addAppolousCustomerPackage = async (appolousCustomerPackageAddRequestBody: AppolousCustomerPackageAddRequestBody) => {
    try{
      await agent.AppolousCustomerPackage.addAppolousCustomerPackage(appolousCustomerPackageAddRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerPackageAddSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };
}
