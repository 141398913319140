import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Col, Label, Row } from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import SortCaret from '../../app/components/table/SortCaret';
import { history } from "../..";
import { ServiceTreatmentImportFailedDetailObject, ServiceTreatmentImportObject, ServiceTreatmentListObject } from '../../app/models/serviceTreatment';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, returnBlobImage, returnPriceWithCurrency } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import englishLanguageFile from '../../lang/en.json';
import ImportServiceTreatmentModal from "./Modal/ImportServiceTreatmentModal";
import { ProductListObject } from "../../app/models/product";
import { SalesCategoryListObject } from "../../app/models/salesCategory";
import { ObjectForDropdown } from "../../app/models/common";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { GroupCodeListObject } from "../../app/models/groupCode";

const ServiceTreatment = () => {
  const intl = useIntl();
  //Use Store
  const { serviceTreatmentStore, salesCategoryStore, productStore, groupCodeStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, prevPath, windowSize, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { serviceTreatmentList, serviceTreatmentPaginationParams, getServiceTreatment, deleteServiceTreatment, setServiceTreatmentPaginationParams, exportServiceTreatment, importServiceTreatment, serviceTreatmentTableColumnFromStore, setServiceTreatmentTableColumnFromStore } = serviceTreatmentStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;
  const { getProductDropdown } = productStore;
  const { getGroupCodeDropDown } = groupCodeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<ServiceTreatmentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateServiceTreatment = checkPermission([PermissionConstants.CreateServiceTreatment]);
  const blnPermissionUpdateServiceTreatment = checkPermission([PermissionConstants.UpdateServiceTreatment]);
  const blnPermissionDeleteServiceTreatment = checkPermission([PermissionConstants.DeleteServiceTreatment]);
  const blnPermissionExportServiceTreatment = checkPermission([PermissionConstants.ExportServiceTreatment]);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);

  const [importFileModal, setImportFileModal] = useState(false);
  const [importedServiceTreatmentDetails, setImportedServiceTreatmentDetails] = useState<ServiceTreatmentImportFailedDetailObject[]>([]);
  const [modalImportedServiceTreatmentDetails, setModalImportedServiceTreatmentDetails] = useState<boolean>(false);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const AllProducts = `${intl.formatMessage({ id: "AllProducts" })}`;
  const [productList, setProductList] = useState<ProductListObject[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>(AllProducts);
  const [selectedProductId, setSelectedProductId] = useState< string>(" ");
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [selectedSalesCategory, setSelectedSalesCategory] = useState<string>(AllSalesCategory);
  const [selectedSalesCategoryId, setSelectedSalesCategoryId] = useState<string>(" ");
  const AllGroupCode = `${intl.formatMessage({ id: "AllGroupCode" })}`;
  const [groupCodeList, setGroupCodeList] = useState<GroupCodeListObject[]>([]);
  const [selectedGroupCode, setSelectedGroupCode] = useState<string>(AllGroupCode);
  const [selectedGroupCodeId, setSelectedGroupCodeId] = useState<string>(" ");
  const All = `${intl.formatMessage({ id: "All" })}`;
  const MandatoryProduct = `${intl.formatMessage({ id: "MandatoryProductOnly" })}`;
  const OptionalProduct = `${intl.formatMessage({ id: "OptionalProductOnly" })}`;
  const [productOptionsList, setProductOptionsList] = useState<ObjectForDropdown[]>([{label: All, value: All}, {label: MandatoryProduct, value: MandatoryProduct}, {label: OptionalProduct, value: OptionalProduct}])
  const [selectedProductOption, setSelectedProductOption] = useState<string>(All);
  const [selectedProductOptionId, setSelectedProductOptionId] = useState<any>(All);
  const [displayProductOption, setDisplayProductOption] = useState(true);
  const [disabledProductOption, setDisabledProductOption] = useState(true);
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let serviceTreatmentRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.name,
    salesCategoryId: selectedSalesCategoryId,
    salesCategoryName: selectedSalesCategory,
    productId: selectedProductId,
    productName: selectedProduct,
    groupCodeId: selectedGroupCodeId,
    groupCodeName: selectedGroupCode,
    ...selectedProductOptionId !== All && {isOptionalProduct: selectedProductOptionId === MandatoryProduct ? false : true},
    ...!blnAllActive && {isActive: blnIsActive}
  }

  useEffect(() => {
    async function fetchServiceTreatmentListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.serviceTreatment)
      if (blnBackNavigation) {
        if (serviceTreatmentPaginationParams) {
          setCurrentPage(serviceTreatmentPaginationParams.pageNumber)
          setSelectedSalesCategoryId(serviceTreatmentPaginationParams.salesCategoryId || "");
          setSelectedSalesCategory(serviceTreatmentPaginationParams.salesCategoryName || AllSalesCategory);
          setSelectedGroupCodeId(serviceTreatmentPaginationParams.groupCodeId || "");
          setSelectedGroupCode(serviceTreatmentPaginationParams.groupCodeName || AllGroupCode);
          setSelectedProductId(serviceTreatmentPaginationParams.productId || "");
          setSelectedProduct(serviceTreatmentPaginationParams.productName || AllProducts);
          setSelectedProductOption(typeof(serviceTreatmentPaginationParams.isOptionalProduct) !== 'boolean' ? All : !serviceTreatmentPaginationParams.isOptionalProduct ? MandatoryProduct : OptionalProduct);
          setSelectedProductOptionId(typeof(serviceTreatmentPaginationParams.isOptionalProduct) !== 'boolean' ? All : !serviceTreatmentPaginationParams.isOptionalProduct ? MandatoryProduct : OptionalProduct);
          if (serviceTreatmentPaginationParams.productId && serviceTreatmentPaginationParams.productId !== " ") {
            setDisabledProductOption(false)
          }
          validation.setValues((values)=> ({
            ...values,
            name: serviceTreatmentPaginationParams.name || "",
            isActive: serviceTreatmentPaginationParams.isActive || false
          }))
          if(serviceTreatmentPaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(serviceTreatmentPaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          serviceTreatmentRequestBody = serviceTreatmentPaginationParams;
        }
      }
      else {
        setServiceTreatmentPaginationParams(undefined);
      }

      let aryAPI: any = [
        getServiceTreatment(serviceTreatmentRequestBody),
        getProductDropdown({ productType: 0 }),
        getSalesCategoryDropdown(),
        getGroupCodeDropDown({})
      ];

      let resultAPI = await Promise.all(aryAPI);
      setProductList(arraySpliceInAllValue(resultAPI[1], AllProducts, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[2], AllSalesCategory, false, "name", "id"))
      setGroupCodeList(arraySpliceInAllValue(resultAPI[3], AllGroupCode, false, "name", "id"))

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageServiceTreatment], true)) {
      return;
    }

    fetchServiceTreatmentListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.serviceTreatment)
        if (!blnSetParams) {
          setServiceTreatmentPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchServiceTreatmentAPI(true);
    }
  }, [triggerSearch, blnAllActive, blnIsActive, selectedSalesCategoryId, selectedGroupCodeId, selectedProductId, selectedProductOptionId])

  async function fetchServiceTreatmentAPI(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getServiceTreatment(serviceTreatmentRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: ServiceTreatmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const durationFormatter = (cell, row) => {
    return `${cell.hours} hours ${cell.minutes} minutes`
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const importServiceTreatmentFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: ServiceTreatmentImportObject = { file: fileTemp };
        let resultImportServiceTreatment = await importServiceTreatment(valuesTemp);
        if (resultImportServiceTreatment) {
          if (resultImportServiceTreatment.length > 0) {
            resultImportServiceTreatment = resultImportServiceTreatment.map((valueResultImportServiceTreatment)=> ({
              ...valueResultImportServiceTreatment,
              errorCode:  valueResultImportServiceTreatment.errorCode in englishLanguageFile ? intl.formatMessage({ id: valueResultImportServiceTreatment.errorCode }) : valueResultImportServiceTreatment.errorCode 
            }))
            setImportFileModal(false);
            setModalImportedServiceTreatmentDetails(true);
            setImportedServiceTreatmentDetails(resultImportServiceTreatment);
          }
          else {
            setImportFileModal(false);
            setSuccessMessage(`ServiceTreatmentImportSuccess`);
            getServiceTreatment({ pageNumber: 1, pageSize: pageSize });
          }
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const exportServiceTreatmentReport = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await (exportServiceTreatment({ exportType: 0 })) //export all records
    }
    setLocalLoading(false);
  }

  //Table Content UI
  const ServiceTreatmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "duration",
      text: intl.formatMessage({ id: "Duration" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      dataFormat: durationFormatter,
      formatter: (cellContent, row) => <div className="text-center">{`${row.hours} hours ${row.minutes} minutes`}</div>, //Custom UI to display
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.sellingPrice)}</div>
      ),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{row.salesCategoryName}</div>
      ),
    },
    {
      dataField: "groupCodeName",
      text: intl.formatMessage({ id: "GroupCode" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{row.groupCodeName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageServiceTreatment, PermissionConstants.UpdateServiceTreatment, PermissionConstants.DeleteServiceTreatment]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.serviceTreatment}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionUpdateServiceTreatment
              &&
              <TableActionButton
                to={`/${RoutesList.serviceTreatment}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteServiceTreatment
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
        title={intl.formatMessage({ id: "ServiceTreatment" })}
        addButton={
          <>
            {
              blnPermissionCreateServiceTreatment
              &&
              <MyAddButton
                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
                onClick={() => { history.push(`/${RoutesList.serviceTreatment}/add`) }}
                linkTo={`/${RoutesList.serviceTreatment}/add`}
                disable={loading} />
            }
            {/* <MyButton
              content={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
              onClick={() => { setImportFileModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={loading} /> */}
            {
              blnPermissionExportServiceTreatment
              &&
              <MyButton
                content={intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "All" }) })}
                onClick={() => { exportServiceTreatmentReport(true) }}
                class={'btn btn-info margin-left-8'}
                disable={loading} />
            }
          </>
        }
        addButtonXL="6">
        <Row>
          {
            !initialCallAPI
            &&
            <>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterServiceTreatmentName" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Product" })}
                  name="product"
                  options={productList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedProduct}
                  initialValue={selectedProductId}
                  setFieldLabel={setSelectedProduct}
                  setFieldValue={setSelectedProductId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={(valueProduct) => {
                    if (valueProduct !== " ") {
                      setDisabledProductOption(false);
                    }
                    else {
                      setDisabledProductOption(true);
                      setSelectedProductOptionId(All);
                      setSelectedProductOption(All);
                      setDisplayProductOption(false);
                      setTimeout(()=> {
                        setDisplayProductOption(true);
                      }, 100)
                    }
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  displayProductOption
                  &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "ProductOption" })}
                    name="isOptionalProduct"
                    options={productOptionsList}
                    initialLabel={selectedProductOption}
                    initialValue={selectedProductOptionId}
                    setFieldLabel={setSelectedProductOption}
                    setFieldValue={setSelectedProductOptionId}
                    disabled={localLoading || loading || disabledProductOption}
                    validationRequired={true}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
                }
              </DoubleColumnRowParent>
            </>
          }
        </Row>
        <Row>
          {
            !initialCallAPI
            &&
            <>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SalesCategory" })}
                  name="salesCategory"
                  options={salesCategoryList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSalesCategory}
                  initialValue={selectedSalesCategoryId}
                  setFieldLabel={setSelectedSalesCategory}
                  setFieldValue={setSelectedSalesCategoryId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "GroupCode" })}
                  name="groupCode"
                  options={groupCodeList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedGroupCode}
                  initialValue={selectedGroupCodeId}
                  setFieldLabel={setSelectedGroupCode}
                  setFieldValue={setSelectedGroupCodeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-26" : "margin-bottom-4"}`}>
                <div className="btn-group d-flex mb-3" role="group">
                  <input type="radio" className="btn-check" name="blnViewAll" id="blnViewAll" autoComplete="off" disabled={localLoading || loading } checked={blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnAllActive(true);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnViewAll">{intl.formatMessage({ id: "All" })}</label>
                  <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveTrue" autoComplete="off" disabled={localLoading || loading} checked={blnIsActive && !blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnIsActive(true);
                      setBlnAllActive(false);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveTrue">{intl.formatMessage({ id: "Active" })}</label>
                  <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveFalse" autoComplete="off" disabled={localLoading || loading} checked={!blnIsActive && !blnAllActive}
                    onChange={()=> {}}
                    onClick={() => {
                      setCurrentPage(1);
                      setBlnIsActive(false);
                      setBlnAllActive(false);
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveFalse">{intl.formatMessage({ id: "Inactive" })}</label>
                </div>
              </Col>
            </>
          }
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ServiceTreatmentColumns}
              data={serviceTreatmentList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
              objSorted={defaultSorted}
              blnSupportColumnFilter={true}
              tableColumnsFromStore={serviceTreatmentTableColumnFromStore}
              setTableColumnsFromStore={setServiceTreatmentTableColumnFromStore}
              name={validation.values.name}
              isActive={!blnAllActive ? blnIsActive : undefined}
              salesCategoryId={selectedSalesCategoryId}
              salesCategoryName={selectedSalesCategory}
              groupCodeId={selectedGroupCodeId}
              groupCodeName={selectedGroupCode}
              productId={selectedProductId}
              productName={selectedProduct}
              isOptionalProduct={selectedProductOptionId !== All ? selectedProductOptionId === MandatoryProduct ? false : true : undefined}
              requestAPI={getServiceTreatment} />
        }
        <ImportFileModal
          blnShow={importFileModal}
          setModal={setImportFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "ServiceTreatment" }) })}
          btnFunction={importServiceTreatmentFunction}
        />
        {
          modalImportedServiceTreatmentDetails
          &&
          <ImportServiceTreatmentModal
            blnShow={modalImportedServiceTreatmentDetails}
            setModal={setModalImportedServiceTreatmentDetails}
            importedServiceTreatmentDetails={importedServiceTreatmentDetails}
            setImportedServiceTreatmentDetails={setImportedServiceTreatmentDetails} />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteServiceTreatment(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (serviceTreatmentList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getServiceTreatment({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(ServiceTreatment);
