import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from "mdi-react/CashCheckIcon";
import { BranchListObject } from "../../../app/models/branch";

const ServicesByEmployee = () => {
  const intl = useIntl();
  //Use Store
  const { reportServicesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { servicesByEmployeeList, getServicesByEmployeeList, setServicesByEmployeeList, exportServicesByEmployee } = reportServicesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportServiceReport = checkPermission([PermissionConstants.ExportServiceReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCollectionsSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageServiceReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setServicesByEmployeeList(undefined);
    })
  }, [])

  const fetchCollectionsSummaryWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportServicesByEmployee({ branchId: selectedBranchId, dateRange: selectedDateRange })
    }
    else { 
      await getServicesByEmployeeList({ branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const CollectionsSummaryDetailsColumns = [
    {
      dataField: "paymentMethodName", //field name from array to display
      text: "paymentMethodName", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalServicesPerformed" }).toUpperCase(),
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: '250px' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDynamicSalesDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.dynamicSalesDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ServicesPerformedByEmployee" }) })}
        title={intl.formatMessage({ id: "ServicesPerformedByEmployee" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportServiceReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCollectionsSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Services-Performed-By-Employee.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchId"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PerformDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchCollectionsSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={CollectionsSummaryDetailsColumns}
                data={servicesByEmployeeList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ServicesPerformedByEmployee" }) })}
                objSorted={defaultSorted}
                keyField={"name"}
                requestAPI={getServicesByEmployeeList}
                hideSearchBar={true} />
            </>

        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ServicesByEmployee);
