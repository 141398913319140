import { useEffect, useState } from "react";
import {
  Modal,
  Col,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import { returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { OldAndNewPaymentDetailListObject } from "../../../app/models/changePaymentMode";

interface Props {
  blnShow: boolean;
  setModal: Function;
  setOldPaymentDetailsModal: Function;
  paymentDetail: OldAndNewPaymentDetailListObject | undefined;
  validation: any;
}

export default function PaymentAdditionalInfoModal (props: Props) {
  const intl = useIntl();

  const [state, setState] = useState(props.paymentDetail!);

  const errorStateDefault = {
    amount: false,
    cardOrAccountNo: false
  }

  const [errorState, setErrorState] = useState(_.cloneDeep(errorStateDefault))

  useEffect(()=> {
    setState((stateTemp)=> ({
      ...stateTemp,
      transactionDate: stateTemp.transactionDate || stateTemp.paymentDate,
      paymentHistoryId: stateTemp.paymentHistoryId || stateTemp.id,
      amount: stateTemp.amount || stateTemp.paidAmount
    }))
  }, [])

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">
          {`${state.paymentMethodName}${state.cardType ? ` (${state.cardType} | ${state.last4CardDigitNo})` : ""}`}
          <p className="payment-method-sub-text" style={{fontSize: "13px"}}>
            {`${state?.paymentMethodOptionName ? `${state.paymentMethodOptionName}` : ""}${state.paymentIssuerBankName ? ` - ${state.paymentIssuerBankName}` : ""}`}
          </p>
        </h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Col>
          <GeneralInputForModal
            title={intl.formatMessage({ id: "BankName" })}
            name="bankName"
            type="text"
            field={state}
            setFieldValue={setState}
          />
        </Col>
        <Col>
          <GeneralInputForModal
            title={intl.formatMessage({ id: "CardOrAccountNo" })}
            name="cardOrAccountNo"
            className={errorState.cardOrAccountNo ? "mb-0" : "mb-3"}
            type="text"
            field={state}
            setFieldValue={setState}
            onBlur={()=> {
              if (errorState.cardOrAccountNo) {
                setErrorState((value) => ({...value, cardOrAccountNo: false}))
              }
            }}
          />
          {errorState.cardOrAccountNo ? (
            <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "CardOrAccountNo"})})}`}</FormFeedback>
          ) : null}
        </Col>
        <Col>
          <GeneralInputForModal
            title={intl.formatMessage({ id: "Amount" })}
            name="amount"
            className={errorState.amount ? "mb-0" : "mb-3"}
            type="number"
            inputGroup={true}
            inputGroupText={returnCurrency()}
            validationRequired={true}
            field={state}
            setFieldValue={setState}
            onBlur={()=> {
              if (errorState.amount) {
                setErrorState((value) => ({...value, amount: false}))
              }
            }}
            blnDisableNegativeValue={true}/>
          {errorState.amount ? (
            <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Amount"})})}`}</FormFeedback>
          ) : null}
        </Col>        
        <div className="">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({ id: "Save" })}
            onClick={()=> {
              let errorStateTemp = _.cloneDeep(errorStateDefault);

              let blnErrorFound = false;
              if (state.bankName && !state.cardOrAccountNo) {
                errorStateTemp.cardOrAccountNo = true;
                blnErrorFound = true;
              }

              if (!state.amount) {
                errorStateTemp.amount = true;
                blnErrorFound = true;
              }

              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                let oldPaymentDetailTemp = _.cloneDeep(props.validation.values.oldPaymentDetails);
                state.amount = Number(state.amount);

                let indexSelectedPaymentTemp = _.findIndex(oldPaymentDetailTemp, { paymentHistoryId: state.paymentHistoryId});
                if (indexSelectedPaymentTemp > -1) {
                  oldPaymentDetailTemp[indexSelectedPaymentTemp] = state;
                }
                else {
                  oldPaymentDetailTemp.push(state);
                }
                oldPaymentDetailTemp = oldPaymentDetailTemp.map((valueNewPaymentTemp, indexNewPaymentTemp)=> ({
                  ...valueNewPaymentTemp,
                }))
                props.validation.setFieldValue("oldPaymentDetails", oldPaymentDetailTemp)
                hideModal();
                if (props.setOldPaymentDetailsModal) {
                  props.setOldPaymentDetailsModal(false);
                }
              }
            }}/>
          </div>
        </div>
      </Modal>
  )
}