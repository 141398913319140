import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnRefundStatusColor, returnSubBrandList } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Link } from 'react-router-dom';
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const RefundFromCredit = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { refundFromCreditList, getRefundFromCreditList, setRefundFromCreditList, exportRefundFromCredit } = reportSalesStore; 
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedReturnRefundStatus, setSelectedReturnRefundStatus] = useState<string>(AllStatus);
  const [selectedReturnRefundStatusId, setSelectedReturnRefundStatusId] = useState<number | string>(" ");
  const [returnRefundStatusList, setReturnRefundStatusList] = useState<StaticReferenceDataObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCreditAdjustment = checkPermission([PermissionConstants.ManageCreditAdjustment]);
  const paramsSearch = useLocation().search;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchRefundFromCredit() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.returnRefundStatus, AllStatus),
      ];      
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setReturnRefundStatusList(resultAPI[1]);

      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const statusParams = new URLSearchParams(paramsSearch).get('status');

      if (branchIdParams && fromDateParams && toDateParams && statusParams) {
        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchName(resultAPI[0][indexBranch].name);
          setSelectedBranchId(resultAPI[0][indexBranch].id);
        }

        let indexReturnRefundStatus = _.findIndex(resultAPI[1], { key: Number(statusParams) });
        if (indexReturnRefundStatus > -1) {
          setSelectedReturnRefundStatus(resultAPI[1][indexReturnRefundStatus].displayValue);
          setSelectedReturnRefundStatusId(resultAPI[1][indexReturnRefundStatus].key);
        }

        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.refundFromCredit}` });
        await getRefundFromCreditList({
          pageNumber: 1, 
          pageSize: pageSize, 
          branchId: branchIdParams, 
          customerId: "",
          dateRange: { fromDate: fromDateTemp, toDate: toDateTemp },
          status: statusParams,
        });
      }

		  setLoading(false);
      setInitialCallAPI(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchRefundFromCredit();

    return (()=> {
      setRefundFromCreditList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchRefundFromCreditWithLoading = async () => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    setCurrentPage(1);
    await getRefundFromCreditList({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      dateRange: selectedDateRange,
      status: selectedReturnRefundStatusId,
    });
    setLocalLoading(false);
	};

  async function exportExchangeToCreditDetail() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    await exportRefundFromCredit({
      pageNumber: 1, 
      pageSize: pageSize, 
      branchId: selectedBranchId, 
      customerId: selectedCustomerId,
      dateRange: selectedDateRange,
      status: selectedReturnRefundStatusId
    });
    setLocalLoading(false);
  }

  const tableFixedWidth = 150;
  const returnHeaderStyle = { width: `${tableFixedWidth}px`, minWidth: `${tableFixedWidth}px`, maxWidth: `${tableFixedWidth}px` };

  //Table Content UI
  const RefundFromCreditColumns = [
    {
      dataField: "creditAdjustmentNo",
      text: intl.formatMessage({ id: "RefundNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCreditAdjustment
          ?
          <Link to={row.id ? `/${RoutesList.creditAdjustment}/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.creditAdjustmentNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.creditAdjustmentNo || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "requestDate",
      text: intl.formatMessage({ id: "RequestDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => <>{row.requestDate ? moment(row.requestDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</>, 
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionViewCustomer
          ?
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerName || Constants.emptyData}
          </div>
        }
      </>
    },
    {
      dataField: "accountHolderName",
      text: intl.formatMessage({ id: "AccountHolderName" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.accountHolderName || Constants.emptyData}
        </div>
      </>
    },
    {
      dataField: "identityNo",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.identityNo || Constants.emptyData}
        </div>
      </>
    },
    {
      dataField: "bankAccountNo",
      text: intl.formatMessage({ id: "BankAccountNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.bankAccountNo || Constants.emptyData}
        </div>
      </>
    },
    {
      dataField: "bankAccountType",
      text: intl.formatMessage({ id: "BankAccountType" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.bankAccountType || Constants.emptyData}
        </div>
      </>
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { ...returnHeaderStyle, textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={returnRefundStatusColor}/>
      ),
    },
    {
      dataField: "subTotalAdjustmentAmount",
      text: intl.formatMessage({ id: "SubTotalRefundAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.subTotalAdjustmentAmount)}</div>
      ),
    },
    {
      dataField: "processingFees",
      text: intl.formatMessage({ id: "ProcessingFees" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.processingFees)}</div>
      ),
    },
    {
      dataField: "rounding",
      text: intl.formatMessage({ id: "Rounding" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.rounding)}</div>
      ),
    },
    {
      dataField: "totalAdjustmentAmount",
      text: intl.formatMessage({ id: "TotalRefundAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAdjustmentAmount)}</div>
      ),
    },
    {
      dataField: "requestReason",
      text: intl.formatMessage({ id: "RequestReason" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.requestReason || Constants.emptyData}
        </div>
      </>
    },
    {
      dataField: "reviewRemark",
      text: intl.formatMessage({ id: "ReviewRemark" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => 
      <>
        <div>
          {row.reviewRemark || Constants.emptyData}
        </div>
      </>
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        headerStyle: returnHeaderStyle,
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName)}
          </div>
        )
      }
    }
  ];

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
  }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "RefundFromCredit"})})}
        title={intl.formatMessage({ id: "RefundFromCredit" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportExchangeToCreditDetail()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Refund-From-Credit.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown 
                  &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SalesOrderDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ReturnRefund" }) })}
                  name="paymentStatus"
                  options={returnRefundStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedReturnRefundStatus}
                  initialValue={selectedReturnRefundStatusId}
                  setFieldLabel={setSelectedReturnRefundStatus}
                  setFieldValue={setSelectedReturnRefundStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}/>
              </Col>
              <Col xl={'3'} md={'6'} xs={'12'}>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchRefundFromCreditWithLoading()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={RefundFromCreditColumns}
            data={refundFromCreditList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "RefundFromCredit"})})}
            objSorted={defaultSorted}
            keyField={"id"}
            requestAPI={getRefundFromCreditList}
            branchId={selectedBranchId}
            customerId={selectedCustomerId}
            dateRange={selectedDateRange}
            status={selectedReturnRefundStatusId}
            setLocalLoading={setLocalLoading}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(RefundFromCredit);