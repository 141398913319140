import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue, returnSubBrandList, firstDayOfMonthToCurrentDay } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";

const BranchDailySalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { branchDailySalesSummaryList, getBranchDailySalesSummaryList, setBranchDailySalesSummaryList, exportBranchDailySalesSummary } = reportSalesStore; 
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const paramsSearch = useLocation().search;
  const [initialData, setInitialData] = useState(true);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  const aryBranchDailySalesSummaryDetail : ObjectForReportRow[] = [
    {label: "Day", value: "day", type: "string"}, 
    {label: "SubBrand", value: "subBrandName", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: false}},
    {label: "BranchSales", value: "totalSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
    {label: "PaymentReceived", value: "totalPaymentReceived", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "paymentRouteUrl" }}, 
    {label: "SalesVariance", value: "totalSalesVariance", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "salesVarianceRouteUrl" }},
    {label: "GroupSales", value: "groupSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
    {label: "ProductSales", value: "productSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
  ];

  const aryBranchDailySalesSummaryDetailWithoutSubBrand : ObjectForReportRow[] = [
    {label: "Day", value: "day", type: "string"}, 
    {label: "BranchSales", value: "totalSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
    {label: "PaymentReceived", value: "totalPaymentReceived", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "paymentRouteUrl" }}, 
    {label: "SalesVariance", value: "totalSalesVariance", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "salesVarianceRouteUrl" }},
    {label: "GroupSales", value: "groupSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
    {label: "ProductSales", value: "productSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true}}, 
  ];
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchBranchDailySalesSummary() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      
      let resultAPI = await Promise.all(aryAPI);

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));

      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const subBrandIdParams = new URLSearchParams(paramsSearch).get('subBrandId');

      if (branchIdParams && fromDateParams && toDateParams) {
        let yearParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("YYYY"));
        let monthParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("M"));
        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedYear(yearParams);
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, { value: monthParams });
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }

        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        if(subBrandIdParams){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandIdParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.branchDailySalesSummary}` });
        await getBranchDailySalesSummaryList({ branchId: branchIdParams, fromDate: fromDateTemp, toDate: toDateTemp, subBrandId: subBrandIdParams || selectedSubBrandId });
      }

      setLoading(false);
      setInitialData(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchBranchDailySalesSummary();

    return (()=> {
      setBranchDailySalesSummaryList([]);
    })
  }, [])
  
  useEffect(()=> {
    if (!initialData) {
      setSelectedDateRange(dateRangeLimit)
      setLocalLoadingDateRange(true);
      setTimeout(()=> {
        setLocalLoadingDateRange(false);
      }, 100)
    }
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const fetchBranchDailySalesSummaryWithLoading = async (blnExport : boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportBranchDailySalesSummary({branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId});
    }else{
      await getBranchDailySalesSummaryList({branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
	};

  //Table Content UI
  const BranchDailySalesSummaryColumns = [
    { 
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "DayDetails" })}
        rowFieldName={"dayDetails"}
        rowColumns={returnSubBrandList().length > 0 ?  aryBranchDailySalesSummaryDetail : aryBranchDailySalesSummaryDetailWithoutSubBrand}
        keyFieldName={"day"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "BranchDailySalesSummary"})})}
        title={intl.formatMessage({ id: "BranchDailySalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchBranchDailySalesSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Branch-Daily-Sales-Summary.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
            {
              subBrandListFinal.length > 0
              &&
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchDropDownListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  // styles={{marginLeft: '25px'}}
                  options={monthList}
                  initialLabel={selectedMonth}
                  initialValue={selectedMonthValue}
                  setFieldLabel={setSelectedMonth}
                  setFieldValue={setSelectedMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl={"3"}>
                {
                  localLoadingDateRange
                  ?
                  <Loading/>
                  :
                  <GeneralDateRange
                    title={intl.formatMessage({ id: "DateRange" })}
                    name={"DateRange"}
                    disabled={localLoading || loading}
                    initialValue={selectedDateRange}
                    minMaxDateObj={dateRangeLimit}
                    validationRequired={true}
                    onChangeFunction={onSelectDateRange}/>
                }
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchBranchDailySalesSummaryWithLoading(false)
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={BranchDailySalesSummaryColumns}
            data={branchDailySalesSummaryList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "BranchDailySalesSummary"})})}
            objSorted={defaultSorted}
            keyField={"branchName"}
            requestAPI={getBranchDailySalesSummaryList}
            branchId={selectedBranchId}
            dateRange={selectedDateRange}
            subBrandId={selectedSubBrandId}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(BranchDailySalesSummary);
