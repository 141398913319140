import { PaginationRequestBody } from "../models/pagination";
import { AppointmentLeadFollowUpListObject, AppointmentLeadFollowUpAddObject, AppointmentLeadFollowUpUpdateObject, AppointmentLeadFollowUpDetailObject, AppointmentLeadListObject, AppointmentLeadDetailObject } from "../models/appointmentLead";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { downloadExcel } from "../common/function/function";

export default class appointmentLeadFollowUpStore {
  appointmentLeadList: AppointmentLeadListObject[] = [];
  appointmentLeadDetail: AppointmentLeadDetailObject | undefined = undefined;
  appointmentLeadFollowUpList: AppointmentLeadFollowUpListObject[] = [];
  appointmentLeadFollowUpDetail: AppointmentLeadFollowUpDetailObject | undefined = undefined;
  appointmentLeadPaginationParams: PaginationRequestBody | undefined = undefined;
  appointmentLeadFollowUpTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appointmentLeadList = [];
    this.appointmentLeadDetail = undefined;
    this.appointmentLeadFollowUpList = [];
    this.appointmentLeadFollowUpDetail = undefined;
    this.appointmentLeadPaginationParams= undefined;
    this.appointmentLeadFollowUpTotalItems = 0;
  }

  setAppointmentLeadDetail = (appointmentLeadDetail: AppointmentLeadDetailObject | undefined) => {
    this.appointmentLeadDetail = appointmentLeadDetail;
  }

  setAppointmentLeadFollowUpDetail = (appointmentLeadFollowUpDetail: AppointmentLeadFollowUpDetailObject | undefined) => {
    this.appointmentLeadFollowUpDetail = appointmentLeadFollowUpDetail;
  }

  setAppointmentLeadPaginationParams = (appointmentLeadPaginationParams: PaginationRequestBody | undefined) => {
    this.appointmentLeadPaginationParams = appointmentLeadPaginationParams;
  }

  getAppointmentLead = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setAppointmentLeadPaginationParams(PaginationRequestBody);
    try{
      const resultAppointmentLeadFollowUp = await agent.AppointmentLead.appointmentLeadList(PaginationRequestBody);
      runInAction(() => {
        this.appointmentLeadList = resultAppointmentLeadFollowUp.data;
        store.commonStore.setTotalItem(resultAppointmentLeadFollowUp.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentLeadList = [];
    }
  }

  getAppointmentLeadWithId = async (id: string) => {
    try{
      const resultAppointmentLeadDetail = await agent.AppointmentLead.appointmentLeadDetail(id);
      runInAction(() => {
        this.appointmentLeadDetail = resultAppointmentLeadDetail;
      });
      return Promise.resolve(resultAppointmentLeadDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentLeadDetail = undefined;
    }
  }

  getAppointmentLeadFollowUp = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultAppointmentLeadFollowUp = await agent.AppointmentLead.appointmentLeadFollowUpList(PaginationRequestBody);
      runInAction(() => {
        this.appointmentLeadFollowUpList = resultAppointmentLeadFollowUp.data;
        this.appointmentLeadFollowUpTotalItems = resultAppointmentLeadFollowUp.pagination.totalItems
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentLeadFollowUpList = [];
      this.appointmentLeadFollowUpTotalItems = 0;
    }
  }

  getAppointmentLeadFollowUpWithId = async (id: string) => {
    try{
      const resultFollowUpDetail = await agent.AppointmentLead.appointmentLeadFollowUpDetail(id);
      runInAction(() => {
        this.appointmentLeadFollowUpDetail = resultFollowUpDetail;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentLeadFollowUpDetail = undefined;
      return Promise.resolve({status: Constants.failed});
    }
  }

  addAppointmentLeadFollowUp = async (appointmentLeadFollowUpRequestBody: AppointmentLeadFollowUpAddObject) => {
    try{
      await agent.AppointmentLead.addAppointmentLeadFollowUp(appointmentLeadFollowUpRequestBody);
      store.commonStore.setSuccessMessage("Lead Follow Up has added successfully.")
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateAppointmentLeadFollowUp = async (appointmentLeadFollowUpRequestBody: AppointmentLeadFollowUpUpdateObject) => {
    try{
      await agent.AppointmentLead.updateAppointmentLeadFollowUp(appointmentLeadFollowUpRequestBody);
      store.commonStore.setSuccessMessage(`LeadFollowUpUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteAppointmentLeadFollowUp  = async (id: string) => {
    try {
      await agent.AppointmentLead.deleteAppointmentLeadFollowUp(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`LeadFollowUpDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  } 
}