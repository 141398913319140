import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, paymentStatusColor, returnPriceWithCurrency, purchasedItemStatusColor, salesOrderStatusColor, displayPromotionCode, displayWatermark, escapedNewLineToLineBreakTag, deliveryOrderStatusColor, returnSubBrandList } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Label, Tooltip } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import MyButton from "../../app/components/form/MyButton";
import CustomerSalesOrderPaymentModal from "./Modal/CustomerSalesOrderPaymentModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SalesOrderPaymentDetailObject } from "../../app/models/customerSalesOrder";
import { history } from "../..";
import CustomerRefundCreditModal from "./Modal/CustomerRefundCreditModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import PaymentAdjustmentModal from "./Modal/PaymentAdjustmentModal";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import SignaturePad from "../../app/components/form/SignaturePad";
import Borderline from "../../app/components/form/BorderLine";
import CustomerEditOrCancelModal from "./Modal/CustomerEditOrCancelModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { burgerMenu } from "../../app/models/common";
import { EmployeeListObject } from "../../app/models/employee";
import AutoGeneratedCustomerRedemptionModal from "../ProductRedemptionNote/Modal/AutoGeneratedCustomerRedemptionModal";
import CustomerSalesOrderVoidModal from "./Modal/CustomerSalesOrderVoidModal";
import InformationIcon from 'mdi-react/InformationIcon';
import RefreshCircleIcon from 'mdi-react/RefreshIcon';
import PencilIcon from 'mdi-react/PencilIcon'
import ChangePaymentMethodModal from "./Modal/ChangePaymentMethodModal";
import GeneralInputForModal from "../../app/components/form/GeneralInputForModal";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import PaginationTable from "../../app/components/table/PaginationTable";
import parse from 'html-react-parser';

const CustomerSalesOrderDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { customerSalesOrderStore, branchStore, staticReferenceStore, customerPaymentAdjustmentStore, customerChangePaymentMethodStore, employeeStore, paymentMethodOptionStore, settingsStore, deliveryOrderStore, commonStore, tncStore } = useStore();
  const { errorMessage, successMessage, loading, hideComponentForPrint, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { branchPaymentMethod, getBranchPaymentMethod, setBranchPaymentMethod } = branchStore;
  const { customerSalesOrderDetail, setCustomerSalesOrderDetail, getCustomerSalesOrderWithId, submitAdditionalPayment, setCustomerRefundCreditList, getCustomerRefundCreditList, customerRefundCreditList, convertCustomerRefundCredit, updateCustomerSalesOrderConsultant, signCustomerSalesOrder, updateCustomerSalesOrder, requestVoidCustomerSalesOrder, reviewVoidCustomerSalesOrder, updateCustomerSalesOrderStatus, updateCustomerSalesOrderSubBrand, customerSalesOrderProductRedemptionList, getCustomerSalesOrderProductRedemptionList, setCustomerSalesOrderProductRedemptionList, customerSalesOrderProductRedemptionTotalItems } = customerSalesOrderStore;
  const { employeeDropdownList, setEmployeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { requestCustomerPaymentAdjustment } = customerPaymentAdjustmentStore;
  const { requestCustomerChangePaymentMethod } = customerChangePaymentMethodStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const { salesSettings, generalSettings, getSalesSettings, getGeneralSettings } = settingsStore;
  const { regenerateProductDeliverOrder } = deliveryOrderStore;
  const { tncText, getTncWithCategory } = tncStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerSalesOrder" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CustomerSalesOrder" }), urlPath: RoutesList.customerSalesOrder }];
  const [blnCreditPackage, setBlnCreditPackage] = useState(false);
  const customerSalesOrderFullDetail = customerSalesOrderDetail || {
    id: "",
    customerSalesOrderNo: "",
    customerInvoiceNo: "",
    branchName: "",
    branchId: "",
    customerName: "",
    customerId: "",
    customerBranchId: "",
    customerBranchName: "",
    businessEntityName: "",
    companyNumber: "",
    consultantIds: [],
    consultants: [],
    consultantBranchId: "",
    status: "",
    subTotalAmount: 0,
    taxAmount: 0,
    discountAmount: 0,
    totalAmount: 0,
    totalPayment: 0,
    balance: 0,
    deposit: 0,
    rounding: 0,
    totalRefundAmount: 0,
    note: "",
    isPaymentAdjustmentAllowed: false,
    isUnderPaymentAdjustment: false,
    isVoidAllowed: false,
    issueDate: "",
    submissionDate: "",
    signatureImage: "",
    voidDate: "",
    reviewedOn: "",
    reviewerUserName: "",
    isAllowRegenerateProductDO: false,
    isAccessOwnBranchCustomer: false,
    hasActiveCustomerAccess: false,
    isEditConsultantAllowed: false,
    voidedSalesOrderId: "",
    voidedSalesOrderNo: "",
    subBrandId: "",
    subBrandName: "",
    salesOrderDetails: [{
      id: "",
      purchaseType: "",
      count: 0,
      itemId: "",
      itemName: "",
      itemDesc: "",
      discountType: "",
      discountValue: 0,
      expiredDate: "",
      unitPrice: 0,
      taxRate: 0,
      taxAmount: 0,
      taxedPrice: 0,
      discountedPrice: 0,
      discountAmount: 0,
      totalPrice: 0,
      remark: "",
      serviceById: "",
      serviceProductUsedIds: [],
      serviceProductUsed: [],
      additionalData: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
      additionalFormData: [{
        id: "",
        name: "",
        controlType: "",
        rows: 0,
        options: [],
        placeHolder: "",
        isRequired: false,
        isActive: false,
        displayOrder: 0,
        value: "",
      }]
    }],
    paymentHistories: [{
      id: "",
      paymentDate: "",
      paymentMethodName: "",
      optionName: "",
      paidAmount: 0,
      last4CardDigit: 0,
      cardType: "",
      transactionNo: "",
    }],
    customerDeliverOrders: [{
      redeemType: "",
      performUserId: "",
      performUserName: "",
      packageId: "",
      packageName: "",
      redeemDetailId: "",
      redeemDetailName: "",
      count: 0,
      description: "",
    }],
    customerInvoices: [{
      id: "",
      invoiceNo: "",
      issueDate: "",
      branchId: "",
      branchName: "",
      customerId: "",
      customerName: "",
      consultantIds: [],
      consultants: [],
      paymentStatus: "",
      balance: 0,
      subTotalAmount: 0,
      subTotalPackagesAmount: 0,
      subTotalServicesAmount: 0,
      subTotalProductsAmount: 0,
      subTotalDiscountAmount: 0,
      subTotalTaxAmount: 0,
      totalAmount: 0,
      refundAmount: 0,
      brandName: "",
      businessEntityName: "",
      branchAddress: "",
      branchContactNo: "",
      issueBy: "",
      note: "",
      invoiceFooterNote: "",
      signatureImage: "",
      signDate: "",
      invoiceDetails: [{
        id: "",
        customerInvoiceId: "",
        purchaseType: "",
        currencyId: "",
        currencyName: "",
        currencyFormat: "",
        count: 0,
        itemId: "",
        itemName: "",
        itemDesc: "",
        expiredDate: "",
        costPrice: 0,
        unitPrice: 0,
        taxRate: 0,
        taxAmount: 0,
        taxedPrice: 0,
        discountValue: "",
        discountedPrice: 0,
        discountAmount: 0,
        totalPrice: 0,
        remark: "",
      }],
      paymentHistories: [{
        id: "",
        title: "",
        paymentDate: "",
        customerInvoiceId: "",
        paymentMethodId: "",
        paymentMethodName: "",
        paidAmount: 0,
        branchId: "",
        branchName: "",
        last4CardDigit: 0,
        cardType: "",
        optionName: "",
        transactionNo: "",
      }]
    }],
    totalInvoiceAmount: 0,
    totalCreditPayment: 0,
    isAllowUpdateStatus: false,
    isChangePaymentMethodAllowed: false,
    isUnderChangePaymentMethod: false,
    isAllowUpdateSubBrand: false,
    purchaseBranchId: "",
    purchaseBranchName: "",
    isUnderChangePaymentMode: false
  }
  const [selectedInvoicePaymentIndex, setSelectedInvoicePaymentIndex] = useState<number | undefined>(undefined);
  const [customerSalesOrderPaymentModal, setCustomerSalesOrderPaymentModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [additionalPayment, setAdditionalPayment] = useState<SalesOrderPaymentDetailObject[]>([])
  const [totalDraftPayment, setTotalDraftPayment] = useState(0);
  const paidStatus = customerSalesOrderFullDetail.balance == 0;
  const blnHQStaff = !getBranchUser();
  const [blnEditConsultant, setBlnEditConsultant] = useState(false);
  const [selectedConsultantOptions, setSelectedConsultantOptions] = useState<any>([]);
  const totalPaymentWithoutCredit = customerSalesOrderFullDetail.totalPayment - customerSalesOrderFullDetail.totalCreditPayment;

  const today = moment(new Date()).format(Constants.displayDateFormat);
  var now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const [refundModal, setRefundModal] = useState(false);
  const [paymentAdjustmentModal, setPaymentAdjustmentModal] = useState(false);
  const [changePaymentMethodModal, setChangePaymentMethodModal] = useState(false);
  const [blnEditPayment, setBlnEditPayment] = useState(false);
  const [editPayment, setEditPayment] = useState<SalesOrderPaymentDetailObject[]>([])
  const blnPermissionRequestPaymentAdjustment = checkPermission([PermissionConstants.RequestPaymentAdjustment]);
  const blnPermissionManageCustomerRefund = checkPermission([PermissionConstants.ManageCustomerRefund]);
  const blnPermissionUpdateCustomerSalesOrder = checkPermission([PermissionConstants.UpdateCustomerSalesOrder])
  const blnPermissionRequestVoidCustomerSalesOrder = checkPermission([PermissionConstants.RequestVoidCustomerSalesOrder]);
  const blnPermissionReviewVoidCustomerSalesOrder = checkPermission([PermissionConstants.ReviewVoidCustomerSalesOrder]);
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionCreateCustomerDeliverOrder = checkPermission([PermissionConstants.CreateCustomerDeliverOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionBackdateCustomerSalesOrderPayment = checkPermission([PermissionConstants.BackdateCustomerSalesOrderPayment]);
  const blnPermissionCreateCustomerSalesOrderPayment = checkPermission([PermissionConstants.CreateCustomerSalesOrderPayment]);
  const [showSignaturePad, setShowSignaturePad] = useState(true);
  const [cancelCustomerSalesOrderModal, setCancelCustomerSalesOrderModal] = useState(false);
  const [editAndCancelCustomerSalesOrderModal, setEditAndCancelCustomerSalesOrderModal] = useState(false);
  const [voidSalesModal, setVoidSalesModal] = useState(false);
  const [regenerateProductRedemptionsModal, setRegenerateProductRedemptionsModal] = useState(false);
  const [viewType, setViewType] = useState(blnHQStaff ? Constants.fullVersion : Constants.customerVersion);
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  // const blnDifferentBranch = (customerSalesOrderDetail?.branchId || "") !== (customerSalesOrderDetail?.customerBranchId || "");
  const blnDifferentBranch = false;
  const [employeeDropdownListForDifferentBranch, setEmployeeDropdownListForDifferentBranch] = useState<EmployeeListObject[]>([]);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false)
  const [autoRegenerateRedemptionNoticeModal, setAutoRegenerateRedemptionNoticeModal] = useState(false);
  const [autoRegenerateRedemptionResponse, setAutoRegenerateRedemptionResponse] = useState<any>([]);
  const blnAccessOwnBranchAndActiveAccess = (customerSalesOrderFullDetail.isAccessOwnBranchCustomer || customerSalesOrderFullDetail.hasActiveCustomerAccess);
  const blnPermissionUpdateCustomerSalesOrderStatus = checkPermission([PermissionConstants.UpdateCustomerSalesOrderStatus]);
  const blnPermissionRequestChangePaymentMethod = checkPermission([PermissionConstants.RequestChangePaymentMethod]);
  const blnPermissionUpdateCustomerSalesOrderSubBrand = checkPermission([PermissionConstants.UpdateCustomerSalesOrderSubBrand]);
  const blnIsAllowUpdateStatus = customerSalesOrderFullDetail.isAllowUpdateStatus;
  const blnIsAllowUpdateSubBrand = customerSalesOrderFullDetail.isAllowUpdateSubBrand;
  const [showRefreshStatusPrompt, setShowRefreshStatusPrompt] = useState(false);
  const [displayRedeemCreditView, setDisplayRedeemCreditView] = useState(false);
  const [paymentBackdateDetails, setPaymentBackdateDetails] = useState({isPaymentBackdate: false, paymentDate: ""})
  const [blnUpdateSubBrand, setBlnUpdateSubBrand] = useState(false);
  const [currentPageProductRedemption, setCurrentPageProductRedemption] = useState(1);
  const [loadingProductRedemption, setLoadingProductRedemption] = useState(false);
  const productRedemptionPageSize = 5;

  const ProductRedemptionColumns = [
    {
      dataField: "performDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <div>
        <Link to={row.documentId ? `/${row.isRedeemFromVirtualWarehouse ? RoutesList.productRedemptionNote : RoutesList.customerRedemption}/view/${row.documentId}` : ""} target={Constants.blank} rel="noopener noreferrer">
          <Label className="mb-1 pointer-clickable">{row.documentNo || Constants.emptyData}</Label>
        </Link>
      </div>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={deliveryOrderStatusColor} />
      )
    },
    {
      dataField: "detail",
      text: intl.formatMessage({ id: "Details" }).toUpperCase()
    },
    {
      dataField: "isRedeemFromVirtualWarehouse",
      text: intl.formatMessage({ id: "VirtualWarehouse" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isRedeemFromVirtualWarehouse ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    }
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerSalesOrderDetail || {
      id: "",
      consultantIds: [],
      consultants: [],
      signatureImage: "",
      cartId: "",
      reviewRemark: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  const validationForAddPayment = useFormik({
    enableReinitialize: true,
    initialValues: {
      consultantIds: [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  const validationForUpdateSubBrand = useFormik({
    enableReinitialize: true,
    initialValues: {
      salesOrderId: customerSalesOrderFullDetail.id,
      subBrandId: customerSalesOrderFullDetail.subBrandId,
      subBrandName: customerSalesOrderFullDetail.subBrandName
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchCustomerSalesOrderDetailAPI(blnCallEmployee = false) {
    setLoading(true);
    if (id && !addAction) {
      let aryAPICustomerSalesOrder: any = [
        getCustomerSalesOrderWithId(id),
        getCustomerRefundCreditList(id),
        getSalesSettings(),
        getCustomerSalesOrderProductRedemptionList({pageNumber: 1, pageSize: productRedemptionPageSize, salesOrderId: id}),
        getGeneralSettings()
      ];
      let resultCustomerSalesOrderTemp = await Promise.all(aryAPICustomerSalesOrder);
      if (resultCustomerSalesOrderTemp[0]) {
        // const blnDifferentBranchTemp = resultCustomerSalesOrderTemp[0].branchId !== resultCustomerSalesOrderTemp[0].customerBranchId;
        let aryAPI: any = [
          // getEmployeeDropdown({ branchId: resultCustomerSalesOrderTemp[0].consultantBranchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true })
          getEmployeeDropdown({ branchId: resultCustomerSalesOrderTemp[0].purchaseBranchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true }),
          getTncWithCategory(Constants.salesOrder, moment(resultCustomerSalesOrderTemp[0].submissionDate).format(Constants.displayDateFormat))
        ];
        // if (blnDifferentBranchTemp) {
        //   aryAPI.push(getEmployeeDropdown({ branchId: resultCustomerSalesOrderTemp[0].customerBranchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true }, true))
        // }
        let resultAPI = await Promise.all(aryAPI);
        // if (blnDifferentBranchTemp && resultAPI.length > 1) {
        //   setEmployeeDropdownListForDifferentBranch(resultAPI[1] || [])
        // }
        if (resultCustomerSalesOrderTemp[0].salesOrderDetails) {
          let indexCreditPackage = _.findIndex(resultCustomerSalesOrderTemp[0].salesOrderDetails, { isCreditPackage: true })
          if (indexCreditPackage > -1) {
            setBlnCreditPackage(true);
          }
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }

    fetchCustomerSalesOrderDetailAPI(true);
    
    return (() => {
      if (id && !addAction) {
        setCustomerSalesOrderDetail(undefined);
        setCustomerRefundCreditList(undefined);
        setEmployeeDropdownList([]);
        setBranchPaymentMethod([]);
        setCustomerSalesOrderProductRedemptionList([]);
      }
    })
  }, []);

  useEffect(() => {
    if(customerSalesOrderDetail){
      let indexRegenerateProductRedemption = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "RegenerateCustomerProductRedemption" })}`})
      if (viewAction && blnPermissionCreateCustomerDeliverOrder  && customerSalesOrderDetail.isAllowRegenerateProductDO && indexRegenerateProductRedemption < 0 && blnAccessOwnBranchAndActiveAccess) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "RegenerateCustomerProductRedemption" })}`, 
        onFunction: async() => { 
          setRegenerateProductRedemptionsModal(true);
        }})
      }

      let indexViewRedemptionRecord = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerRedemptionList" })}`})
      if (viewAction && blnPermissionManageCustomerDeliverOrder && indexViewRedemptionRecord < 0 && blnAccessOwnBranchAndActiveAccess) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerRedemptionList" })}`, 
        onFunction: () => { 
          history.push({
            pathname: `/${RoutesList.customerRedemption}`,
            state: { 
              detail: {
                salesOrderNo: customerSalesOrderFullDetail.customerSalesOrderNo,
                salesOrderId: customerSalesOrderFullDetail.id,
              }
            }
          });
        }})
      }

      let indexViewSiteMap = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewSiteMap" })}`})
      if (indexViewSiteMap < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewSiteMap" })}`, 
          onFunction: () => {
            history.push (`/${RoutesList.customerSalesOrder}/sitemap/${id}`)
          },
        })
      }

      let indexViewCommission = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCommissionSummary" })}`})
      if (viewAction  && indexViewCommission < 0 && blnAccessOwnBranchAndActiveAccess && !blnPendingStatus && !blnVoidedStatus && !blnCancelledStatus) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCommissionSummary" })}`, 
        onFunction: () => { 
          history.push({
            pathname: `/${RoutesList.customerSalesOrder}/commission/view/${id}`
          });
        }})
      }

      let indexViewAs = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewAs" })}`})
      if (indexViewAs < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewAs" })}`, 
          onFunction: () => {},
          blnSubTitle: true
        })
      }
      
      let indexViewFullVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewFullVersion" })}`})
      if (indexViewFullVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewFullVersion" })}`, 
          onFunction: () => {
            setViewType(Constants.fullVersion);
          },
          blnSubView: true
        })
      }

      let indexViewCustomerVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`})
      if (indexViewCustomerVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerVersion)
          },
          blnSubView: true
        })
      }
      
      let indexViewCustomerWithoutQuantityVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`})
      if (indexViewCustomerWithoutQuantityVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerWithoutQuantityVersion)
          },
          blnSubView: true
        })
      }

      let indexViewRedeemCreditVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewRedeemCreditVersion" })}`})
      if (indexViewRedeemCreditVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewRedeemCreditVersion" })}`, 
          onFunction: () => { 
            setDisplayRedeemCreditView((valueDisplayRedeemCredit)=> !valueDisplayRedeemCredit);
          },
          blnSubView: true
        })
      }
    }
  }, [customerSalesOrderDetail])

  useEffect(() => {
    let totalDraftPaymentTemp = 0;
    additionalPayment.map((value) => {
      totalDraftPaymentTemp += Number(value.paidAmount)
    })
    setTotalDraftPayment(totalDraftPaymentTemp);
  }, [additionalPayment])

  useEffect(() => {
    let arySelectedConsultantId: string[] = [];
    let arySelectedConsultantOptions: any = [];
    if (id && !addAction) {
      if (customerSalesOrderDetail) {
        if (customerSalesOrderDetail.consultantIds) {
          customerSalesOrderDetail.consultantIds.map((value) => {
            for (var a = 0; a < employeeDropdownList.length; a++) {
              if (value === employeeDropdownList[a].id) {
                arySelectedConsultantId.push(employeeDropdownList[a].id);
                arySelectedConsultantOptions.push({
                  ...employeeDropdownList[a],
                  label: employeeDropdownList[a].preferredName,
                  value: employeeDropdownList[a].id
                })
              }
            }
          })
        }
        setSelectedConsultantOptions(arySelectedConsultantOptions);
        validation.setFieldValue("consultantIds", arySelectedConsultantId);
      }
    }
  }, [customerSalesOrderDetail, employeeDropdownList])

  useEffect(() => {
    if (successMessage && !editAndCancelCustomerSalesOrderModal) {
      setTimeout(() => {
        if (cancelCustomerSalesOrderModal) {
          setCancelCustomerSalesOrderModal(false);
        }
        setSuccessMessage("");

        if (showSignaturePad && !blnPendingStatus) {
          setShowSignaturePad(false);
        }
        history.push(`/${RoutesList.customerSalesOrder}/view/${id}`);
        fetchCustomerSalesOrderDetailAPI(true);
      }, Constants.dismissSuccessMessage)
    }
    else if (successMessage && voidSalesModal) {
      setTimeout(() => {
        setSuccessMessage("");
        setVoidSalesModal(false);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const blnPendingOrInProcessingOrCompletedStatus = (customerSalesOrderFullDetail.status == Constants.pending || customerSalesOrderFullDetail.status == Constants.inProgress || customerSalesOrderFullDetail.status == Constants.completed);
  const blnCreditExchangeSubmittedOrCreditExchangedStatus = (customerSalesOrderFullDetail.status == Constants.creditExchangeSubmitted || customerSalesOrderFullDetail.status == Constants.creditExchanged);
  const blnCancelledStatus = customerSalesOrderFullDetail.status == Constants.cancelled;
  const blnRefunded = customerSalesOrderFullDetail.totalRefundAmount > 0;
  const blnIsWithinToday = moment(customerSalesOrderFullDetail.submissionDate).format(Constants.displayDateFormat) === today;
  const blnPendingStatus = customerSalesOrderFullDetail.status == Constants.pending;
  const blnInProcessingStatus = customerSalesOrderFullDetail.status == Constants.inProgress;
  const blnVoidRequestSubmittedStatus = customerSalesOrderFullDetail.status == Constants.voidRequestSubmitted;
  const blnVoidedStatus = customerSalesOrderFullDetail.status == Constants.voided;

  const fetchPaymentMethod = async () => {
    setLocalLoading(true);
    let aryAPI: any = [
      getBranchPaymentMethod(getBranchId()),
      getStaticReferenceWithType(Constants.cardType)
    ];
    await Promise.all(aryAPI);
    setCustomerSalesOrderPaymentModal(true);
    setLocalLoading(false);
  }

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
        <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const onClickUpdateConsultant = (values) => {
    const valuesTemp: any = _.cloneDeep(values);
    async function fetchUpdateInvoiceConsultantAPI() {
      setLoading(true);
      let resultUpdateInvoiceConsultant = await updateCustomerSalesOrderConsultant({ salesOrderId: valuesTemp.id, consultantIds: valuesTemp.consultantIds })
      setLoading(false);
      if (resultUpdateInvoiceConsultant?.status === Constants.success) {
        setBlnEditConsultant(false);
        fetchCustomerSalesOrderDetailAPI();
      }
    }

    if (valuesTemp.consultantIds.length < 1) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) }))
      return;
    }

    if (blnEditConsultant) {
      fetchUpdateInvoiceConsultantAPI();
    }
  }

  const onClickVoidSalesOrder = async (reviewRemark: string) => {
    setLocalLoading(true);
    let resultAPI = await requestVoidCustomerSalesOrder({ id: id, reviewRemark: reviewRemark })
    if (resultAPI.status === Constants.success) {
      setVoidSalesModal(false);
    }
    setLocalLoading(false);
  }

  const onClickReviewSalesOrder = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewVoidCustomerSalesOrder({ id: id, reviewRemark: validation.values.reviewRemark, workflowAction: valueAction })
    setLocalLoading(false);
  }

  const onClickRegenerateCustomerProductRedemptions = async () => {
    setLoading(true);
    let resultAPI = await regenerateProductDeliverOrder(id);
    if (resultAPI && resultAPI.status === Constants.success){
      setTimeout(() => {
        setLocalSuccessMessage(true);
        setRegenerateProductRedemptionsModal(false);
        if(resultAPI?.data.isProductDOAutoGenerated){
          setAutoRegenerateRedemptionResponse(resultAPI.data)
          setAutoRegenerateRedemptionNoticeModal(true)
        }
      }, Constants.timeoutNavigation)
    }
    setLoading(false);
  }

  const onClickRequestCustomerPaymentAdjustment = async (valuePaymentAdjustment) => {
    let resultRequestCustomerPaymentAdjustment = await requestCustomerPaymentAdjustment(valuePaymentAdjustment);
    if (resultRequestCustomerPaymentAdjustment) {
      if(resultRequestCustomerPaymentAdjustment.isProductDOAutoGenerated){
        setTimeout(()=> {
          setAutoRegenerateRedemptionResponse(resultRequestCustomerPaymentAdjustment)
          setAutoRegenerateRedemptionNoticeModal(true)
        }, Constants.timeoutNavigation)
      }
    }
  }

  const onClickRequestCustomerChangePaymentMethod = async (valueChangePaymentMethod) => {
    await requestCustomerChangePaymentMethod(valueChangePaymentMethod);
  }

  const onClickUpdateSubBrand = (values) => {
    const valuesTemp: any = _.cloneDeep(values);
    async function fetchUpdateSubBrandAPI() {
      setLoading(true);
      let resultUpdateSubBrand = await updateCustomerSalesOrderSubBrand(valuesTemp)
      setLoading(false);
      if (resultUpdateSubBrand.status === Constants.success) {
        setBlnUpdateSubBrand(false);
        fetchCustomerSalesOrderDetailAPI();
      }
    }

    if (blnUpdateSubBrand) {
      fetchUpdateSubBrandAPI();
    }
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title margin-bottom-16 ">
                      <Row style={{ alignItems: 'center' }}>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CustomerSalesOrder" })} 
                              <span className="text-danger">
                                {customerSalesOrderFullDetail.isUnderPaymentAdjustment && ` (${intl.formatMessage({ id: "UnderPaymentAdjustment" })})`}
                                {customerSalesOrderFullDetail.isUnderChangePaymentMethod && ` (${intl.formatMessage({ id: "UnderChangePaymentMethod" })})`}
                                {customerSalesOrderFullDetail.isUnderChangePaymentMode &&  ` (${intl.formatMessage({ id: "UnderChangePaymentMode" })})`}
                              </span>
                            </span>
                            <br />
                            <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                              {customerSalesOrderFullDetail.customerSalesOrderNo}
                              {
                                customerSalesOrderFullDetail.customerInvoiceNo
                                &&
                                <span className="d-print-none">
                                  <Link 
                                    to={`/${RoutesList.invoice}/view/${customerSalesOrderFullDetail.id}`} 
                                    target={Constants.blank} 
                                    rel="noopener noreferrer"
                                    style={{
                                      marginLeft: "2px"
                                    }}>
                                    <FileDocumentIcon size={22} id="invoicetooltip" color={Constants.tabBlue} style={{marginTop: "-6px"}}/> 
                                    <UncontrolledTooltip placement="top" target="invoicetooltip">
                                      {`${intl.formatMessage({ id: "ViewInvoice" })} (${customerSalesOrderFullDetail.customerInvoiceNo})`}
                                    </UncontrolledTooltip>
                                  </Link>
                                </span>
                              }
                              {
                                customerSalesOrderFullDetail.voidedSalesOrderNo
                                &&
                                <Link to={`/${RoutesList.customerSalesOrder}/view/${customerSalesOrderFullDetail.voidedSalesOrderId}`} target={Constants.supportBlank} rel="noopener noreferrer">
                                  <span className="text-danger d-print-none" style={{fontSize: '13px'}}>
                                    {` (${customerSalesOrderFullDetail.voidedSalesOrderNo})`}
                                  </span>
                                </Link>
                              }
                            </h3>
                            {/* {
                              customerSalesOrderFullDetail.customerInvoiceNo
                              &&
                              <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                <Link to={`/${RoutesList.invoice}/view/${customerSalesOrderFullDetail.id}`} target={Constants.blank} rel="noopener noreferrer">
                                  {customerSalesOrderFullDetail.customerInvoiceNo}
                                </Link>
                              </h4>
                            } */}
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {customerSalesOrderFullDetail.businessEntityName} {`${customerSalesOrderFullDetail.companyNumber ? `(${customerSalesOrderFullDetail.companyNumber})` : ""}`}
                            </h4>
                            <div className="flex-align-items-center-without-height">
                              <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {customerSalesOrderFullDetail.purchaseBranchName} {!blnUpdateSubBrand && customerSalesOrderFullDetail.subBrandName ? `(${customerSalesOrderFullDetail.subBrandName}) ` : ""}
                              </h4>
                              {
                                !blnUpdateSubBrand
                                && 
                                customerSalesOrderFullDetail.subBrandName
                                &&
                                customerSalesOrderFullDetail.isAllowUpdateSubBrand 
                                && 
                                blnPermissionUpdateCustomerSalesOrderSubBrand
                                &&
                                <TableActionButton
                                  to={"#"}
                                  type={'edit'}
                                  className={"d-print-none"}
                                  iconReplaceUI={<PencilIcon size={18} id="updateSubBrand" />}
                                  iconId={"updateSubBrand"}
                                  iconToolTipText={intl.formatMessage({ id: "UpdateSubBrand" })}
                                  onClick={() => setBlnUpdateSubBrand(true)} />
                              }
                            </div>
                            <div className="flex-align-items-center-without-height">
                              {
                                blnUpdateSubBrand
                                &&
                                <Col xl={"3"} md={"6"} xs={"12"} style={{marginTop: "0.25rem", marginBottom: "-0.75rem"}}>
                                  <DropDownWithTitle
                                    name={"subBrandId"}
                                    title={""}
                                    specifyReturnFieldName={[{ "field": "subBrandId", "value": "id" }, { "field": "subBrandName", "value": "name" }]}
                                    labelField={"name"}
                                    valueField={"id"}
                                    options={returnSubBrandList()}
                                    disabled={false}
                                    initialLabel={validationForUpdateSubBrand.values.subBrandName}
                                    initialValue={validationForUpdateSubBrand.values.subBrandId}
                                    blnHideTitle={true}
                                    validationRequired={true}
                                    validation={validationForUpdateSubBrand} />
                                </Col>
                              }
                              {
                                blnUpdateSubBrand
                                &&
                                <div className="margin-left-8">
                                  <TableActionButton
                                    to={"#"}
                                    type={'save'}
                                    onClick={async () => { onClickUpdateSubBrand(validationForUpdateSubBrand.values) }} />
                                </div>
                              }
                              {
                                blnUpdateSubBrand
                                &&
                                <div className="margin-left-8">
                                  <TableActionButton
                                    to={"#"}
                                    type={'cancel'}
                                    onClick={async () => {
                                      setBlnUpdateSubBrand(false)
                                      validationForUpdateSubBrand.setFieldValue("subBrandId", customerSalesOrderFullDetail.subBrandId)
                                      validationForUpdateSubBrand.setFieldValue("subBrandName", customerSalesOrderFullDetail.subBrandName)
                                    }} />
                                </div>
                              }
                            </div>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(customerSalesOrderFullDetail.submissionDate).format(Constants.displayDateFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(customerSalesOrderFullDetail.submissionDate).format(Constants.displayTimeFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {intl.formatMessage({ id: "Customer" })}:                             {
                              blnPermissionViewCustomer ?
                                <Link to={customerSalesOrderFullDetail.customerId ? `/${RoutesList.customer}/view/${customerSalesOrderFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{customerSalesOrderFullDetail.customerName || Constants.emptyData}</Label>
                                </Link>
                              :
                                `${customerSalesOrderFullDetail.customerName}`
                              }
                            </h4>
                          </div>
                        </Col>
                        <Col xs="6" className="text-end text-muted">
                          <address>
                            {
                              customerSalesOrderFullDetail.isEditConsultantAllowed
                              &&
                              blnPendingOrInProcessingOrCompletedStatus
                              &&
                              blnPermissionUpdateCustomerSalesOrder
                              &&
                              (!blnEditConsultant)
                              &&
                              (!localLoading)
                              &&
                              (!Boolean(successMessage) || localSuccessMessage)
                              &&
                              <TableActionButton
                                to={"#"}
                                type={'edit'}
                                className={"d-print-none"}
                                onClick={() => setBlnEditConsultant(true)} />
                            }
                            {
                              customerSalesOrderFullDetail.consultants &&
                              customerSalesOrderFullDetail.consultants.length > 0 &&
                              <>
                                &nbsp;&nbsp;
                                <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Consultant" })}:</strong>
                                {
                                  !blnEditConsultant
                                  &&
                                  customerSalesOrderFullDetail.consultants.map((value, index) => (
                                    <div key={`${value}_${index}`}>
                                      <span className="margin-bottom-0 font-weight-bold" style={{ fontSize: '15px', marginTop: '5px' }}>
                                        <>{index + 1}. {value}</>
                                      </span>
                                      {index !== customerSalesOrderFullDetail.consultants.length - 1 && <br />}
                                    </div>
                                  ))
                                }
                                {
                                  blnEditConsultant
                                  &&
                                  <div className="flex-direction-row flex-right flex-align-items-center-without-height margin-top-8">
                                    <TableActionButton
                                      to={"#"}
                                      type={'save'}
                                      onClick={async () => { onClickUpdateConsultant(validation.values) }} />
                                    &nbsp;
                                    <TableActionButton
                                      to={"#"}
                                      type={'cancel'}
                                      onClick={async () => {
                                        setBlnEditConsultant(false)
                                        let selectedConsultantIdTemp = selectedConsultantOptions.map((valueSelectedConsultantOptions) => {
                                          return valueSelectedConsultantOptions.id;
                                        })
                                        validation.setFieldValue("consultantIds", selectedConsultantIdTemp)
                                      }} />
                                    &nbsp;&nbsp;&nbsp;
                                    <SingleColumnRowParent blnDoubleTab={true}>
                                      <DropDownWithTitleMultiSelect
                                        name={"consultantIds"}
                                        className={"margin-bottom-0"}
                                        specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                                        returnFieldWithLabel={false}
                                        labelField={"preferredName"}
                                        valueField={"id"}
                                        options={employeeDropdownList}
                                        disabled={false}
                                        initialValue={selectedConsultantOptions}
                                        validationRequired={false}
                                        validation={validation} />
                                    </SingleColumnRowParent>
                                  </div>
                                }
                              </>
                            }
                          </address>
                        </Col>
                      </Row>
                    </div>
                    <Borderline/>
                    <Row>
                      <Col xs="6">
                        <address className="font-size-14">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          {
                            blnPermissionUpdateCustomerSalesOrderStatus && blnIsAllowUpdateStatus
                            ?
                            <span className="margin-left-2" onClick={()=> {setShowRefreshStatusPrompt(true)}}>
                              <UncontrolledTooltip placement="top" target={"updateCustomerSalesOrderStatus"}>
                                {intl.formatMessage({ id: "RefreshStatus" })}
                              </UncontrolledTooltip>
                              <span><RefreshCircleIcon size={22} className={`${localLoading ? "text-secondary" : "text-danger"} pointer-clickable`} id="updateCustomerSalesOrderStatus" /></span>
                            </span>
                            :
                            null
                          }
                          <br />
                          <div className="" style={{ marginTop: '8px' }}>
                            <GeneralStatus
                              status={customerSalesOrderFullDetail.status}
                              statusColorFunction={salesOrderStatusColor}
                              blnDisableCenter />
                          </div>
                        </address>
                      </Col>
                      {
                        blnVoidedStatus
                        &&
                        <Col xs="6" className="text-end text-muted">
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted">{`${intl.formatMessage({id: "VoidRequestApprovedBy"})}: ${customerSalesOrderFullDetail.reviewerUserName}`}</h4>
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({id: "VoidRequestApprovedDate"})}: ${moment(customerSalesOrderFullDetail.reviewedOn).format(Constants.displayDateFormat)}`}</h4>
                          <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({id: "VoidedDate"})}: ${moment(customerSalesOrderFullDetail.voidDate).format(Constants.displayDateFormat)}`}</h4>
                        </Col>
                      }
                    </Row>
                    {/* watermark */}
                    {displayWatermark()}
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "OrderSummary" })}
                        {
                          customerSalesOrderFullDetail.isAllowRegenerateProductDO
                          &&
                          <span className="text-danger d-print-none" style={{marginLeft: '8px', fontSize: '12px'}}>*{intl.formatMessage({ id: "ProductRedemptionPendingGenerate" })}</span>
                        }
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{intl.formatMessage({ id: "Type" })}</th>
                            {<th className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? intl.formatMessage({ id: "Quantity" }) : ""}</th>}
                            {
                              viewType === Constants.fullVersion &&
                              <>
                                <th className="text-center">{intl.formatMessage({ id: "Status" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "UnitPrice" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "DiscountedPrice" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "Tax" })}</th>
                                <th className="text-end">{intl.formatMessage({ id: "Total" })}</th>
                              </>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            customerSalesOrderFullDetail.salesOrderDetails.map((item, key) => (
                              <tr key={key}>
                                <td>{getPurchaseTypeIcon(item.purchaseType)}{item.itemName} {displayPromotionCode(item.promotionCode)} <i className="text-muted">{(!blnHQStaff && item.status !== Constants.active) && `(${newSpaceBeforeCapitalLetter(item.status)})`}</i></td>
                                <td className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{item.purchaseType}</td>
                                {<td className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? item.count : ""}</td>}
                                {
                                  viewType === Constants.fullVersion &&
                                  <>
                                    <td className="text-center">
                                      <GeneralStatus
                                        status={item.status}
                                        statusColorFunction={purchasedItemStatusColor} />
                                    </td>
                                    <td className="text-center">{returnPriceWithCurrency(item.originalPrice)}</td>
                                    <td className="text-center">{returnPriceWithCurrency(item.discountAmount)}</td>
                                    <td className="text-center">{returnPriceWithCurrency(item.taxAmount)}</td>
                                    <td className="text-end">{returnPriceWithCurrency(item.totalPrice)}</td>
                                  </>
                                }
                              </tr>
                            ))
                          }
                          {
                            viewType === Constants.fullVersion
                            ?
                            <>
                              <tr>
                                <td colSpan={6} />
                                <td className="text-end" style={{ fontWeight: 'bold', minWidth: '150px' }}>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotal" })}`}
                                  </div>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotalDiscount" })}`}
                                  </div>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotalTax" })}`}
                                  </div>
                                  {
                                    Constants.supportMalaysiaRounding
                                    &&
                                    <div>
                                      {`${intl.formatMessage({ id: "Rounding" })}`}
                                    </div>
                                  }
                                  {
                                    customerSalesOrderFullDetail.totalCreditPayment
                                    &&
                                    displayRedeemCreditView
                                    ?
                                    <div>
                                      {`${intl.formatMessage({ id: "RedeemedCredit" })}`}
                                    </div>
                                    :
                                    null
                                  }
                                </td>
                                <td className="text-end" style={{ minWidth: '150px' }}>
                                  <div>
                                    {`${returnPriceWithCurrency(customerSalesOrderFullDetail.subTotalAmount)}`}
                                  </div>
                                  <div>
                                    {`${returnPriceWithCurrency(customerSalesOrderFullDetail.discountAmount)}`}
                                  </div>
                                  <div>
                                    {`${returnPriceWithCurrency(customerSalesOrderFullDetail.taxAmount)}`}
                                  </div>
                                  {
                                    Constants.supportMalaysiaRounding
                                    &&
                                    <div>
                                      {`${returnPriceWithCurrency(customerSalesOrderFullDetail.rounding)}`}
                                    </div>
                                  }
                                  {
                                    customerSalesOrderFullDetail.totalCreditPayment
                                    &&
                                    displayRedeemCreditView
                                    ?
                                    <div>
                                      {`-${returnPriceWithCurrency(customerSalesOrderFullDetail.totalCreditPayment)}`}
                                    </div>
                                    :
                                    null
                                  }
                                </td>
                              </tr>
                              {displayCustomRowData(6, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? customerSalesOrderFullDetail.totalAmount : customerSalesOrderFullDetail.totalInvoiceAmount))}
                            </>
                            :
                            <>
                              {
                                customerSalesOrderFullDetail.totalCreditPayment
                                &&
                                displayRedeemCreditView
                                ?
                                <>
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(customerSalesOrderFullDetail.totalAmount))}
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "RedeemedCredit" })}`, `-${returnPriceWithCurrency(customerSalesOrderFullDetail.totalCreditPayment)}`)}
                                </>
                                :
                                null
                              }
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? customerSalesOrderFullDetail.totalAmount : customerSalesOrderFullDetail.totalInvoiceAmount))}
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Paid" })}`, blnRefunded && customerSalesOrderFullDetail.status == Constants.partial ? returnPriceWithCurrency(customerSalesOrderFullDetail.totalRefundAmount) : returnPriceWithCurrency(!displayRedeemCreditView ? customerSalesOrderFullDetail.totalPayment : totalPaymentWithoutCredit))}
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Balance" })}`, returnPriceWithCurrency(customerSalesOrderFullDetail.balance))}
                              {
                                blnRefunded &&
                                <>
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "RefundAmount" })}`, returnPriceWithCurrency(customerSalesOrderFullDetail.totalRefundAmount))}
                                </>
                              }
                            </>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "PaymentHistory" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Date" })}</th>
                            <th>{intl.formatMessage({ id: "Time" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentBranch" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "TransactionNo" })}/{intl.formatMessage({ id: "ApprovalCode" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                            {
                              !hideComponentForPrint
                              &&
                              <th/>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {customerSalesOrderFullDetail.paymentHistories && customerSalesOrderFullDetail.paymentHistories.length > 0 &&
                            customerSalesOrderFullDetail.paymentHistories.map((item, key) => (
                              displayRedeemCreditView && item.isCreditPayment
                              ?
                              null
                              :
                              <tr key={key}>
                                <td>{moment(item.paymentDate).format(Constants.displayDateFormat)}</td>
                                <td>{moment(item.paymentDate).format(Constants.displayTimeFormat)}</td>
                                <td>{item.branchName || Constants.emptyData}</td>
                                <td>
                                  <p className={`margin-bottom-0`}>{`${item.paymentMethodName}${item.cardType ? ` (${item.cardType} | ${item.last4CardDigitNo})` : ""}`}</p>
                                  <p className="payment-method-sub-text">{`${item?.optionName ? `${item.optionName}` : ""}${item.paymentIssuerBankName ? ` - ${item.paymentIssuerBankName}` : ""}`}</p>
                                </td>
                                <td className="text-center">{item.transactionNo || Constants.emptyData}</td>
                                <td className="text-end">{returnPriceWithCurrency(item.paidAmount)}</td>
                                {
                                  !hideComponentForPrint
                                  &&
                                  <td className="text-end">
                                    <Link
                                      to={`/${RoutesList.receipt}/view/${item.id}`}
                                      className="text-info"
                                      target={Constants.blank} rel="noopener noreferrer">
                                      <p><CashCheckIcon size={20} /> {`${intl.formatMessage({ id: "ViewDetails" })}`}</p>
                                    </Link>
                                  </td>
                                }
                              </tr>
                            ))
                          }
                          {
                            // customerSalesOrderFullDetail.status !== Constants.refunded &&
                            <>
                              {displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "Paid" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? customerSalesOrderFullDetail.totalPayment : totalPaymentWithoutCredit))}
                              {displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "Balance" })}`, returnPriceWithCurrency(customerSalesOrderFullDetail.balance))}
                              {
                                blnRefunded &&
                                displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "TotalExchangedAmount" })}`, returnPriceWithCurrency(customerSalesOrderFullDetail.totalRefundAmount))
                              }
                            </>
                          }
                        </tbody>
                      </Table>
                    </div>
                    {
                      additionalPayment.length > 0
                      &&
                      !paidStatus
                      &&
                      <div className="py-2 mt-3 d-print-none flex-direction-row">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "DraftPaymentSummary" })}
                        </h3>
                        {
                          blnPermissionBackdateCustomerSalesOrderPayment
                          &&
                          <GeneralInputForModal
                            title={""}
                            name="isPaymentBackdate"
                            className={"mb-0 margin-top--18 margin-left-16"}
                            type="checkbox"
                            validationRequired={true}
                            field={paymentBackdateDetails}
                            setFieldValue={setPaymentBackdateDetails}
                            disabled={localLoading}
                            childrenUI={
                              <Label className="margin-bottom-0 margin-left-4">
                                {`(${intl.formatMessage({ id: "IsApplyPaymentDate" })})`}
                              </Label>
                          }/>
                        }
                      </div>
                    }
                    {
                      paymentBackdateDetails.isPaymentBackdate
                      &&
                      <div style={{maxWidth: '250px'}}>
                        <GeneralInputForModal
                          title={""}
                          name="paymentDate"
                          className="margin-top--24 mb-3"
                          placeholder={intl.formatMessage({ id: "PaymentDate" })}
                          type="datetime-local"
                          disabled={localLoading}
                          validationRequired={true}
                          field={paymentBackdateDetails}
                          setFieldValue={setPaymentBackdateDetails}
                          minCurrentDate={!generalSettings?.isAllowCrossMonthSales || false}
                          minDateValue={generalSettings?.isAllowCrossMonthSales || false ? undefined : moment().startOf('month').format(Constants.displayDateFormat)}
                          maxCurrentDate={true}
                        />
                      </div>
                    }
                    {
                      additionalPayment.length > 0
                      &&
                      !paidStatus
                      &&
                      <div className="table-responsive d-print-none">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th >{intl.formatMessage({ id: "Title" })}</th>
                              <th>{intl.formatMessage({ id: "Date" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "PaymentMethod" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "TransactionNo" })}/{intl.formatMessage({ id: "ApprovalCode" })}</th>
                              <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                              { additionalPayment.length > 0 && <th className="text-end">{intl.formatMessage({ id: "Action" })}</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              additionalPayment.map((item, index) => (
                                <tr key={index}>
                                  <td>{"Draft Payment"}</td>
                                  <td>
                                    { 
                                      paymentBackdateDetails.isPaymentBackdate
                                      ?
                                      paymentBackdateDetails.paymentDate 
                                      ? 
                                      moment(paymentBackdateDetails.paymentDate, Constants.textInputDateAndTimeFormat).format(Constants.displayDateAndTimeFormat)
                                      :
                                      Constants.emptyData
                                      :
                                      moment(new Date()).format(Constants.displayDateFormat)
                                    }
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{`${item.paymentMethodName}`} {item.cardType ? `(${item.cardType} | ${item.paymentMethodOptionName} | ${item.last4CardDigit})` : item.paymentMethodOptionName ? `(${item.paymentMethodOptionName})` : ""}</td>
                                  <td className="text-center">{item.transactionNo || Constants.emptyData}</td>
                                  <td className="text-end">{returnPriceWithCurrency(item.paidAmount)}</td>
                                  {
                                    (!Boolean(successMessage) || localSuccessMessage)
                                    &&
                                    <td className="text-end">
                                        <>
                                        <div className="d-flex gap-2 flex-right">
                                          <TableActionButton
                                            to="#"
                                            onClick={() => {
                                              setSelectedInvoicePaymentIndex(index + 1)
                                              setCustomerSalesOrderPaymentModal(true);
                                            }}
                                            type="viewModal"
                                            disabled={localLoading} />
                                          <TableActionButton
                                            to="#"
                                            onClick={() => {
                                              let additionalPaymentTemp = _.cloneDeep(additionalPayment)
                                              additionalPaymentTemp.splice(index, 1);
                                              setAdditionalPayment(additionalPaymentTemp);
                                            }}
                                            type="delete"
                                            disabled={localLoading} />
                                        </div>
                                      </>
                                    </td>
                                  }
                                </tr>
                              ))
                            }
                            <tr>
                              <td colSpan={3} />
                              <td className="text-center" style={{ fontWeight: 'bold' }}>
                                {intl.formatMessage({ id: "Total" })}
                              </td>
                              <td className="text-end">
                                {returnPriceWithCurrency(totalDraftPayment)}
                              </td>
                              {additionalPayment.length > 0 && <th className="text-end"></th>}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    }
                    {
                      blnDifferentBranch
                      &&
                      additionalPayment.length > 0
                      &&
                      !paidStatus
                      &&
                      <div className="flex-right">
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          title={intl.formatMessage({ id: "Consultants" })}
                          className={"margin-bottom-28 minWidth-250px"}
                          specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                          returnFieldWithLabel={false}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={employeeDropdownListForDifferentBranch}
                          disabled={false}
                          initialValue={[]}
                          validationRequired={true}
                          validation={validationForAddPayment}
                          displayToolTip={() => (
                            <InformationIcon id="consultants-edit-tooltip" color='#556ee6' className='margin-left-4' style={{ paddingBottom: "4px" }}/>
                          )} />
                        <UncontrolledTooltip placement="top" target={"consultants-edit-tooltip"}>
                          {intl.formatMessage({ id: "EditConsultantDifferentBranch" } ,{ customerBranchName: customerSalesOrderFullDetail.customerBranchName, branchName: customerSalesOrderFullDetail.branchName })}
                        </UncontrolledTooltip>
                      </div>
                    }
                    {
                      <div className={`${additionalPayment.length > 0 && "flex-right"} d-print-none`}>
                        {
                          (!blnCreditExchangeSubmittedOrCreditExchangedStatus && !blnCancelledStatus && !blnVoidedStatus && !blnVoidRequestSubmittedStatus && !blnPendingStatus)
                            &&
                            blnPermissionRequestPaymentAdjustment
                            &&
                            !customerSalesOrderFullDetail.isUnderPaymentAdjustment
                            &&
                            customerSalesOrderFullDetail.isPaymentAdjustmentAllowed
                            &&
                            blnAccessOwnBranchAndActiveAccess
                            &&
                            additionalPayment.length === 0 ?
                            <MyButton
                              type="button"
                              class="btn btn-primary"
                              content={intl.formatMessage({ id: "EditPayment" })}
                              loading={localLoading}
                              onClick={() => {
                                setBlnEditPayment(true);
                                setPaymentAdjustmentModal(true);
                              }}
                              disable={Boolean(successMessage) || localSuccessMessage}
                            />
                            :
                            null
                        }
                        {
                          blnPermissionRequestChangePaymentMethod
                          &&
                          !customerSalesOrderFullDetail.isUnderPaymentAdjustment
                          &&
                          customerSalesOrderFullDetail.isChangePaymentMethodAllowed
                          &&
                          blnAccessOwnBranchAndActiveAccess
                          &&
                          additionalPayment.length === 0 
                          ?
                          <MyButton
                            type="button"
                            class="btn btn-info margin-left-8"
                            content={intl.formatMessage({ id: "ChangePaymentMethod" })}
                            loading={localLoading}
                            onClick={() => {
                              setBlnEditPayment(true);
                              setChangePaymentMethodModal(true);
                            }}
                            disable={Boolean(successMessage) || localSuccessMessage}
                          />
                          :
                          null
                        }
                        {
                          (!blnCreditExchangeSubmittedOrCreditExchangedStatus && !blnCancelledStatus && !blnVoidedStatus && !blnVoidRequestSubmittedStatus && !blnPendingStatus)
                          &&
                          blnPermissionUpdateCustomerSalesOrder
                          &&
                          blnAccessOwnBranchAndActiveAccess
                          &&
                          !customerSalesOrderFullDetail.isUnderPaymentAdjustment
                          &&
                          !paidStatus
                          &&
                          blnPermissionCreateCustomerSalesOrderPayment
                          &&
                          <>
                            <MyButton
                              type="button"
                              class="btn btn-success margin-left-8"
                              content={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Payment" }) })}
                              loading={localLoading}
                              onClick={() => {
                                setBlnEditPayment(false);
                                if (branchPaymentMethod.length > 0 && cardType.length > 0) {
                                  setCustomerSalesOrderPaymentModal(true);
                                }
                                else {
                                  fetchPaymentMethod();
                                }
                              }}
                              disable={Boolean(successMessage) || localSuccessMessage}
                            />
                            {
                              additionalPayment.length > 0
                              &&
                              <MyButton
                                type="button"
                                class="btn btn-primary margin-left-8"
                                content={intl.formatMessage({ id: "MakePayment" })}
                                loading={localLoading}
                                onClick={async () => {
                                  if (paymentBackdateDetails.isPaymentBackdate && !paymentBackdateDetails.paymentDate) {
                                    setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentDate" }) }))
                                    return;
                                  }
                                  else if (additionalPayment.length > 0) {
                                    let totalPayment = 0;
                                    let additionalPaymentTemp: any = [];

                                    additionalPaymentTemp = additionalPayment.map((valueAdditionalPaymentTemp) => {
                                      totalPayment += Number(valueAdditionalPaymentTemp.paidAmount)
                                      return ({
                                        ...valueAdditionalPaymentTemp,
                                        transactionNo: valueAdditionalPaymentTemp.transactionNo ? String(valueAdditionalPaymentTemp.transactionNo) : "",
                                      })
                                    })

                                    if (totalPayment > customerSalesOrderFullDetail.balance) {
                                      setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalBalance" }))
                                      return;
                                    }

                                    if (blnDifferentBranch) {
                                      if (validationForAddPayment.values.consultantIds.length < 1) {
                                        setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }) }))
                                        return;
                                      }
                                    }
                                    
                                    let objTemp : any = {
                                      salesOrderId: customerSalesOrderFullDetail.id,
                                      branchId: getBranchId(),
                                      payments: additionalPaymentTemp,
                                      consultantIds: validationForAddPayment.values.consultantIds || []
                                    }

                                    if (paymentBackdateDetails.isPaymentBackdate) {
                                      objTemp = { ...objTemp, paymentDate : moment(paymentBackdateDetails.paymentDate).format(Constants.defaultDateFormat)}
                                    }

                                    setLocalLoading(true);
                                    let resultPaymentSuccess = await submitAdditionalPayment(objTemp);
                                    setLocalLoading(false);
                                    if (resultPaymentSuccess && resultPaymentSuccess.status == Constants.success) {
                                      fetchCustomerSalesOrderDetailAPI();
                                      setAdditionalPayment([]);
                                      if(resultPaymentSuccess.data.isProductDOAutoGenerated){
                                        setAutoRegenerateRedemptionResponse(resultPaymentSuccess.data);
                                        setAutoRegenerateRedemptionNoticeModal(true);
                                      }
                                      // history.push(`/${RoutesList.invoice}/view/${resultPaymentSuccess.invoiceId}`);
                                    }
                                  }
                                }}
                                disable={Boolean(successMessage) || localSuccessMessage}
                              />
                            }
                          </>
                        }
                      </div>
                    }
                    {/* <div className={`py-2 ${!(additionalPayment.length > 0) ? 'mt-3' : 'mt-5'}`}>
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "CustomerInvoices" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light">
                          <tr>
                            <th style={{ width: "25%" }}>{intl.formatMessage({ id: "InvoiceNo" })}</th>
                            <th>{intl.formatMessage({ id: "Date" })}</th>
                            <th>{intl.formatMessage({ id: "Time" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Status" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Total" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Balance" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerSalesOrderFullDetail.customerInvoices && customerSalesOrderFullDetail.customerInvoices.length > 0 &&
                            customerSalesOrderFullDetail.customerInvoices.map((item, key) => (
                              <tr key={key}>
                                <td style={{ width: "25%" }}><Link
                                  to={`/${RoutesList.invoice}/view/${item.id}`}
                                  className="text-info"
                                  onClick={() => history.push(`/${RoutesList.invoice}/view/${item.id}`)}
                                >{item.invoiceNo}</Link></td>
                                <td>{moment(item.issueDate).format(Constants.displayDateFormat)}</td>
                                <td>{moment(item.issueDate).format(Constants.displayTimeFormat)}</td>
                                <td className="text-center">
                                  <GeneralStatus
                                    status={item.paymentStatus}
                                    statusColorFunction={paymentStatusColor} />
                                </td>
                                <td className="text-center">{returnPriceWithCurrency(item.totalAmount)}</td>
                                <td className="text-end">{returnPriceWithCurrency(item.balance)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div> */}
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Note" })} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="note"
                      className="margin-top-0"
                      row={5}
                      disabled={true}
                      field={customerSalesOrderFullDetail}
                      setFieldValue={() => { }} />
                    {/* <p className="text-center margin-top-16" style={{ fontSize: '14px', pageBreakInside: "avoid"}}>{salesSettings?.invoiceFooterNote ? escapedNewLineToLineBreakTag(salesSettings.invoiceFooterNote) : ""}</p> */}
                    <p className="text-center margin-top-16" style={{ fontSize: '14px', pageBreakInside: "avoid"}}>{tncText?.description ? (parse(tncText.description)) : ""}</p>
                    <SignaturePad
                      title={intl.formatMessage({ id: "CustomerSignature" })}
                      successMessage={successMessage}
                      validation={validation}
                      disabled={Boolean(customerSalesOrderFullDetail.signatureImage) || Boolean(successMessage) || localSuccessMessage || (!blnPermissionUpdateCustomerSalesOrder && !Boolean(customerSalesOrderFullDetail.signatureImage)) || blnCancelledStatus || !blnAccessOwnBranchAndActiveAccess}
                      isSubmitting={localLoading} />
                    {
                      (blnVoidRequestSubmittedStatus || blnVoidedStatus) &&
                      <>
                        <div className={`py-2 mt-5`}>
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ReviewComment" })} :
                          </h3>
                        </div>
                        <GeneralTextArea
                          title=""
                          name="reviewRemark"
                          row={5}
                          disabled={localLoading || blnVoidedStatus || !blnPermissionReviewVoidCustomerSalesOrder || Boolean(successMessage) || localSuccessMessage}
                          validation={validation} />
                      </>
                    }
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        blnPermissionManageCustomerRefund
                        &&
                        !customerSalesOrderFullDetail.isUnderPaymentAdjustment
                        &&
                        (customerRefundCreditList && customerRefundCreditList.isRefundable)
                        &&
                        (!Boolean(successMessage) || !localSuccessMessage)
                        && 
                        <MyButton
                          type="button"
                          class={`btn btn-primary margin-right-8`}
                          style={{ width: '200px' }}
                          content={intl.formatMessage({ id: "ExchangeToCredit" })}
                          disable={localLoading || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                          onClick={() => {
                            setRefundModal(true)
                          }}
                        />
                      }
                      {
                        !customerSalesOrderFullDetail.signatureImage &&
                        showSignaturePad &&
                        blnPermissionUpdateCustomerSalesOrder &&
                        !blnCancelledStatus &&
                        blnAccessOwnBranchAndActiveAccess &&
                        (!Boolean(successMessage) || !localSuccessMessage) && 
                        <MyButton
                          type="button"
                          class="btn btn-info"
                          content={intl.formatMessage({ id: "Sign" }).toUpperCase()}
                          disable={localLoading || Boolean(successMessage) || !validation.values.signatureImage || localSuccessMessage}
                          loading={localLoading}
                          onClick={async () => {
                            const valuesTemp: any = _.cloneDeep(validation.values);
                            if (!valuesTemp.signatureImage) {
                              validation.setFieldValue("signatureError", true)
                            }
                            else {
                              let valuesFinal = {
                                signatureImage: valuesTemp.signatureImage,
                                id: valuesTemp.id
                              }
                              try {
                                setLocalLoading(true);
                                let resultSignCustomerSalesOrder:any = await signCustomerSalesOrder(valuesFinal);
                                if (resultSignCustomerSalesOrder) {
                                  if (resultSignCustomerSalesOrder.status) {
                                    if (resultSignCustomerSalesOrder.status === Constants.success) {
                                      if(resultSignCustomerSalesOrder.data.isProductDOAutoGenerated){
                                        setAutoRegenerateRedemptionResponse(resultSignCustomerSalesOrder.data);
                                        setAutoRegenerateRedemptionNoticeModal(true); 
                                      }
                                    }
                                  }
                                }
                              } finally {
                                setLocalLoading(false);
                              }
                            }
                          }}
                        />
                      }
                      {
                        blnPermissionUpdateCustomerSalesOrder &&
                        blnPendingStatus &&
                        blnAccessOwnBranchAndActiveAccess &&
                        (!Boolean(successMessage) || !localSuccessMessage) &&
                        <MyButton
                          type="button"
                          class="btn btn-warning margin-left-8"
                          content={intl.formatMessage({ id: "Cancel" }).toUpperCase()}
                          disable={localLoading || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                          onClick={async () => {
                            setCancelCustomerSalesOrderModal(true);
                          }}
                        />
                      }
                      {
                        blnPermissionUpdateCustomerSalesOrder &&
                        blnPendingStatus &&          
                        blnAccessOwnBranchAndActiveAccess &&              
                        (!Boolean(successMessage) || !localSuccessMessage) &&
                        <MyButton
                          type="button"
                          class="btn btn-editandcancel margin-left-8"
                          style={{ width: '250px' }}
                          content={intl.formatMessage({ id: "CancelAndRecreate" }).toUpperCase()}
                          disable={localLoading || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                          onClick={async () => {
                            setEditAndCancelCustomerSalesOrderModal(true);
                          }}
                        />
                      }
                      {
                        customerSalesOrderFullDetail.isVoidAllowed 
                        &&
                        blnPermissionRequestVoidCustomerSalesOrder 
                        &&
                        blnInProcessingStatus 
                        &&
                        customerSalesOrderFullDetail.isAccessOwnBranchCustomer
                        &&
                        (!Boolean(successMessage) || !localSuccessMessage)
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-danger margin-left-8"
                          content={intl.formatMessage({ id: "VoidSales" })}
                          style={{ width: '150px' }}
                          disable={localLoading || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                          onClick={async () => {
                            setVoidSalesModal(true);
                          }}
                        />
                      }
                      {
                        blnPermissionReviewVoidCustomerSalesOrder
                        &&
                        blnVoidRequestSubmittedStatus
                        &&
                        (!Boolean(successMessage) || !localSuccessMessage)
                        &&
                        <>
                        <MyButton
                          type="button"
                          class="btn btn-success margin-right-8"
                          content={intl.formatMessage({ id: "Approve" })}
                          onClick={() => {
                            onClickReviewSalesOrder(Constants.approve);
                          }}
                          disable={localLoading || !blnPermissionReviewVoidCustomerSalesOrder || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                        />
                        <MyButton
                          type="button"
                          class="btn btn-danger"
                          content={intl.formatMessage({ id: "Reject" })}
                          onClick={() => {
                            onClickReviewSalesOrder(Constants.reject);
                          }}
                          disable={localLoading || !blnPermissionReviewVoidCustomerSalesOrder || Boolean(successMessage) || localSuccessMessage}
                          loading={localLoading}
                        />
                      </>
                      }
                      {
                        (!successMessage || !localSuccessMessage) &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={true}
                        />
                      }
                    </div>
                    {
                      !customerSalesOrderFullDetail.isVoidAllowed 
                      &&
                      blnInProcessingStatus
                      &&
                      !customerSalesOrderFullDetail.isUnderPaymentAdjustment
                      &&
                      <p className="d-print-none text-danger mt-4"><strong>{intl.formatMessage({ id: "CustomerSalesOrderVoidGeneralMessage" })}</strong></p>
                      }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              customerSalesOrderProductRedemptionList.length > 0 &&
              <Row className="d-print-none">
                <div>
                  <Col className="standard-layout">
                    <h5 className="mb-4">{intl.formatMessage({ id: "ProductRedemptions" })}</h5>
                    <div className="margin-top--16">
                      <PaginationTable
                        title={""}
                        currentPage={currentPageProductRedemption}
                        setCurrentPage={setCurrentPageProductRedemption}
                        pageSize={productRedemptionPageSize}
                        totalItem={customerSalesOrderProductRedemptionTotalItems}
                        tableColumns={ProductRedemptionColumns}
                        data={customerSalesOrderProductRedemptionList}
                        localLoading={loadingProductRedemption}
                        setLocalLoading={setLoadingProductRedemption}
                        emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductRedemptions" }) })}
                        objSorted={[]}
                        hideSearchBar={true}
                        salesOrderId={id}
                        requestAPI={getCustomerSalesOrderProductRedemptionList}
                        keyField={"randomId"} 
                        blnRemoveBackground={true}/>
                    </div>
                  </Col>
                </div>
              </Row>
            }
          </div>
        )}
        {
          customerSalesOrderPaymentModal &&
          <CustomerSalesOrderPaymentModal
            blnShow={customerSalesOrderPaymentModal}
            setModal={setCustomerSalesOrderPaymentModal}
            cardType={cardType}
            branchPaymentMethod={branchPaymentMethod}
            additionalPayment={additionalPayment}
            setAdditionalPayment={setAdditionalPayment}
            selectedInvoicePaymentIndex={selectedInvoicePaymentIndex}
            setSelectedInvoicePaymentIndex={setSelectedInvoicePaymentIndex}
            invoiceBalance={customerSalesOrderFullDetail.balance}
            getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}
            blnEditPayment={blnEditPayment}
            setBlnEditPayment={setBlnEditPayment}
            editPayment={editPayment}
            setEditPayment={setEditPayment}
            blnCreditPackage={blnCreditPackage || changePaymentMethodModal}/>
        }
        {
          refundModal
          &&
          <CustomerRefundCreditModal
            blnShow={refundModal}
            setModal={setRefundModal}
            customerRefundCreditList={customerRefundCreditList}
            convertCustomerRefundCredit={convertCustomerRefundCredit}
            getSalesOrderId={id}
          />
        }
        {
          paymentAdjustmentModal
          &&
          <PaymentAdjustmentModal
            blnShow={paymentAdjustmentModal}
            setModal={setPaymentAdjustmentModal}
            salesOrderId={id}
            branchId={customerSalesOrderFullDetail.branchId}
            customerBranchId={customerSalesOrderFullDetail.customerBranchId}
            blnEditPayment={blnEditPayment}
            setBlnEditPayment={setBlnEditPayment}
            editPayment={editPayment}
            setEditPayment={setEditPayment}
            selectedInvoicePaymentIndex={selectedInvoicePaymentIndex}
            setSelectedInvoicePaymentIndex={setSelectedInvoicePaymentIndex}
            blnShowSalesOrderPaymentModal={customerSalesOrderPaymentModal}
            setSalesOrderPaymentModal={setCustomerSalesOrderPaymentModal}
            loadingPayment={localLoading}
            fetchPaymentMethod={fetchPaymentMethod}
            requestCustomerPaymentAdjustment={onClickRequestCustomerPaymentAdjustment}
            // customerSalesOrderOldPaymentHistory={customerSalesOrderFullDetail.paymentHistories}
            customerSalesOrderOldPaymentTotalPaid={customerSalesOrderFullDetail.totalPayment}
            customerSalesOrderOldPaymentTotalBalance={customerSalesOrderFullDetail.balance}
            branchPaymentMethod={branchPaymentMethod}
            employeeDropdownListForDifferentBranch={employeeDropdownListForDifferentBranch}
            cardType={cardType}
            setCustomerSalesOrderPaymentModal={setCustomerSalesOrderPaymentModal}
          />
        }
        {
          changePaymentMethodModal
          &&
          <ChangePaymentMethodModal
            blnShow={changePaymentMethodModal}
            setModal={setChangePaymentMethodModal}
            salesOrderId={id}
            branchId={customerSalesOrderFullDetail.branchId}
            customerBranchId={customerSalesOrderFullDetail.customerBranchId}
            blnEditPayment={blnEditPayment}
            setBlnEditPayment={setBlnEditPayment}
            editPayment={editPayment}
            setEditPayment={setEditPayment}
            selectedInvoicePaymentIndex={selectedInvoicePaymentIndex}
            setSelectedInvoicePaymentIndex={setSelectedInvoicePaymentIndex}
            blnShowSalesOrderPaymentModal={customerSalesOrderPaymentModal}
            setSalesOrderPaymentModal={setCustomerSalesOrderPaymentModal}
            loadingPayment={localLoading}
            fetchPaymentMethod={fetchPaymentMethod}
            requestCustomerChangePaymentMethod={onClickRequestCustomerChangePaymentMethod}
            customerSalesOrderOldPaymentTotalPaid={customerSalesOrderFullDetail.totalPayment}
            customerSalesOrderOldPaymentTotalBalance={customerSalesOrderFullDetail.balance}
            branchPaymentMethod={branchPaymentMethod}
            employeeDropdownListForDifferentBranch={employeeDropdownListForDifferentBranch}
            cardType={cardType}
            setCustomerSalesOrderPaymentModal={setCustomerSalesOrderPaymentModal}
            isAllowCrossMonthSales={generalSettings?.isAllowCrossMonthSales || false}
          />
        }
        {
          cancelCustomerSalesOrderModal
          &&
          <CustomerEditOrCancelModal
            showPrompt={cancelCustomerSalesOrderModal}
            setShowPrompt={setCancelCustomerSalesOrderModal}
            blnEdit={false}
            intl={intl}
            validation={validation}/>
        }
        {
          editAndCancelCustomerSalesOrderModal
          &&
          <CustomerEditOrCancelModal
            showPrompt={editAndCancelCustomerSalesOrderModal}
            setShowPrompt={setEditAndCancelCustomerSalesOrderModal}
            blnEdit={true}
            intl={intl}
            validation={validation} />
        }
        {
          voidSalesModal
          &&
          <CustomerSalesOrderVoidModal
            blnShow={voidSalesModal}
            setModal={setVoidSalesModal}
            onPrimaryClick={onClickVoidSalesOrder}
            localLoading={localLoading}
            />
        }
        {
          regenerateProductRedemptionsModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "RegenerateDeliverOrderAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={regenerateProductRedemptionsModal}
            setShowPrompt={setRegenerateProductRedemptionsModal}
            onPrimaryClick={() => { onClickRegenerateCustomerProductRedemptions() }}
          />
        }
        {
          autoRegenerateRedemptionNoticeModal
          &&
          <AutoGeneratedCustomerRedemptionModal
            blnShow={autoRegenerateRedemptionNoticeModal}
            setModal={setAutoRegenerateRedemptionNoticeModal}
            deliverOrderNo={autoRegenerateRedemptionResponse.deliverOrderNo}
            onRedeemClick={() => { 
              history.push({
                pathname: `/${RoutesList.productRedemptionNote}/add`,
                state: { 
                  detail: { 
                    customerId: customerSalesOrderFullDetail.customerId,
                    deliverOrderId: autoRegenerateRedemptionResponse.deliverOrderId
                  }}
              }); 
            }}
            onCancelClick={() => {
              window.location.reload();
            }}/>
        }
        {
          showRefreshStatusPrompt
          &&
          <ActionPromptModal 
            title={`Are you sure want to refresh status?`}
            primaryActionTitle={`Yes`}
            showPrompt={showRefreshStatusPrompt}
            setShowPrompt={setShowRefreshStatusPrompt}
            localLoading={localLoading}
            onPrimaryClick={async ()=> {
              setLocalLoading(true);
              let resultUpdateCustomerSalesOrderStatus = await updateCustomerSalesOrderStatus(customerSalesOrderFullDetail.id);
              if (resultUpdateCustomerSalesOrderStatus.status === Constants.success) {
                setShowRefreshStatusPrompt(false);
                fetchCustomerSalesOrderDetailAPI();
              }
              setShowRefreshStatusPrompt(false);
              setLocalLoading(false);
            }}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(CustomerSalesOrderDetail);
