import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import { EmployeePositionMovementHistory, UpdateEmployeePositionMovementHistoryObject } from "../../../app/models/employee";
import moment from "moment";
import { PositionListObject } from "../../../app/models/position";
import { isTablet } from "react-device-detect";
import { UserRoleListObject } from "../../../app/models/userRole";
import { newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  updateEmployeePositionMovementHistory: Function;
  validation: any;
  setRefreshPostionMovementHistoryData: Function;
  positionDropdownList: PositionListObject[];
  userRoleDropdownList: UserRoleListObject[];
}

export default observer(function UpdatePositionMovementHistoryModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { commonStore } = useStore();
  const { setErrorMessage } = commonStore;
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false);
  const [loadingUpdatePositionMovement, setLoadingUpdatePositionMovement] = useState(false);
  const isMinStartDateAllowed = props.validation.values.positionMovementHistories.length > 1;

  const positionMovementDateOnChange = () => {
		let positionMovementHistoriesTemp : EmployeePositionMovementHistory[] = _.cloneDeep(validationLocal.values.positionMovementHistories);

		positionMovementHistoriesTemp[0].isStartDateUpdated = true;
    if(positionMovementHistoriesTemp.length > 1){
      positionMovementHistoriesTemp[1].displayEndDate = `${moment(props.validation.values.positionMovementHistories[1].endDate).format(Constants.displayInputDateFormat)}  →  ${moment(positionMovementHistoriesTemp[0].startDate).subtract(1, 'days').format(Constants.displayInputDateFormat)}`;
      positionMovementHistoriesTemp[1].endDate = moment(positionMovementHistoriesTemp[0].startDate).subtract(1, 'days').format(Constants.displayDateFormat);
      positionMovementHistoriesTemp[1].backgroundColor = Constants.lightorange;
    }
    validationLocal.setFieldValue("positionMovementHistories", positionMovementHistoriesTemp)
	}

	const aryDynamicInputAndCheckBoxPositionMovementHistoriesTab: ObjectForTableWithDynamicInputAndCheckBox[] =
		[
      {
        label: intl.formatMessage({ id: "PositionName" }).toUpperCase(),
        type: "select",
        xl: "3",
        inputName: "positionName",
        inputType: "select",
        optionsLocalField: { fieldName: "positionDropdownList", label: "name", value: "id"},
        displayLabel: "positionName",
        displayValue: "positionId",
        specifyReturnFieldName: [{ field: "positionName", value: "name" }, { field: "positionId", value: "id" }],
        validationRequired: true,
        disabledField: "fieldDisable"
      },
      {
        label: intl.formatMessage({ id: "RoleName" }).toUpperCase(),
        type: "select",
        xl: "3",
        inputName: "roleName",
        inputType: "select",
        optionsLocalField: { fieldName: "userRoleDropdownList", label: "name", value: "id"},
        displayLabel: "roleName",
        displayValue: "roleId",
        specifyReturnFieldName: [{ field: "roleName", value: "name" }, { field: "roleId", value: "id" }],
        validationRequired: true,
        disabledField: "fieldDisable"
      },
			{
				label: intl.formatMessage({ id: "StartDate" }),
				type: "input",
				xl: "3",
				inputName: "startDate",
				inputType: "date",
        validationRequired: true,
        disabled: props.validation.values.positionMovementHistories.length > 1 ? false : true,
        onChangeFunction: positionMovementDateOnChange
			},
			{
				label: intl.formatMessage({ id: "EndDate" }),
				type: "input",
				xl: "3",
				inputName: "displayEndDate",
				inputType: "text",
				inputValueForSuccessColor: Constants.present,
        supportInputBackgroundColor: true,
        valueOnTime: true,
				disabled: true
			}
		];
  
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      positionMovementHistories: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    if(props.validation.values){
      let userRoleDropdownListTemp : UserRoleListObject[] = [];
      if (props.userRoleDropdownList) {
        userRoleDropdownListTemp = props.userRoleDropdownList.map((valueUserRoleTemp)=> ({
          ...valueUserRoleTemp,
          name: newSpaceBeforeCapitalLetter(valueUserRoleTemp.name)
        }))
      }

      let positionMovementHistoriesTemp = props.validation.values.positionMovementHistories.map((valuePositionMovementHistories, indexPositionMovementHistories)=> ({
        ...valuePositionMovementHistories,
        positionDropdownList: props.positionDropdownList,
        userRoleDropdownList: userRoleDropdownListTemp,
        fieldDisable: indexPositionMovementHistories === 0 ? false : true,
      }))
      validationLocal.setFieldValue("positionMovementHistories", positionMovementHistoriesTemp)
    }
  }, [])

  const hideModal = () => {
    props.setModal(false);
  }

  const fetchUpdateEmployeePositionMovementHistoryAPI = async () => {
    let positionMovementHistoriesTemp : EmployeePositionMovementHistory[] = _.cloneDeep(validationLocal.values.positionMovementHistories);
    let updateEmployeePositionMovementHistoryObject : UpdateEmployeePositionMovementHistoryObject = {
      id: positionMovementHistoriesTemp[0].id,
      employeeId: positionMovementHistoriesTemp[0].employeeId,
      positionId: positionMovementHistoriesTemp[0].positionId,
      roleId: positionMovementHistoriesTemp[0].roleId,
      startDate: positionMovementHistoriesTemp[0].startDate
    }

    setLoadingUpdatePositionMovement(true);
    let resultAPI = await props.updateEmployeePositionMovementHistory(updateEmployeePositionMovementHistoryObject);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
        props.setRefreshPostionMovementHistoryData(true);
      }, Constants.timeoutNavigation)
    }
    setLoadingUpdatePositionMovement(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!loadingUpdatePositionMovement && !localSuccessMessage) {
          hideModal();
        }
      }}
      size='xl'
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "UpdatePositionMovementHistory" })}</h5>
        {
          !loadingUpdatePositionMovement && !localSuccessMessage
          &&
          <button
            type="button"
            onClick={() => { hideModal(); }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className="standard-layout">
          <LineBreakWithTittle
            paddingBottom="0px"
            title={intl.formatMessage({ id: "PositionMovementHistories" })}
            h4Title />
          <TableWithEditableInputFields
            name="positionMovementHistories"
            title=""
            buttonTitle={""}
            blnEditable={true}
            aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxPositionMovementHistoriesTab}
            data={validationLocal.values.positionMovementHistories.slice(0,2)}
            validation={validationLocal}
            disabled={loadingUpdatePositionMovement || Boolean(localSuccessMessage)}
            hideAddButton={true}
            hideDeleteButton={true}
            blnDisabledCustomizedTableScrolling={true}
            minCurrentDate={isMinStartDateAllowed ? true : false}
            minDateValue={isMinStartDateAllowed ? moment(props.validation.values.positionMovementHistories[1].startDate).add(1, 'days').format(Constants.displayDateFormat) : ""} 
            maxDateValue={isMinStartDateAllowed ? moment(props.validation.values.positionMovementHistories[0].endDate).format(Constants.displayDateFormat) : ""} 
          />
        </div>
        <div className="mb-3 mt-4 flex-right">
          {
            !Boolean(localSuccessMessage)
            &&
            <MyButton
              type="button"
              class="btn btn-success"
              style={{ width: '20%' }}
              content={intl.formatMessage({ id: "Confirm" })}
              onClick={() => {
                // let blnErrorFound = false;
                // let positionMovementHistoriesTemp : EmployeePositionMovementHistory[] = _.cloneDeep(validationLocal.values.positionMovementHistories);
                // if(positionMovementHistoriesTemp && positionMovementHistoriesTemp.length > 0){
                //   if (!positionMovementHistoriesTemp[0].isStartDateUpdated) {
                //     blnErrorFound = true;
                //   }
                // }

                // if (!blnErrorFound) {
                  fetchUpdateEmployeePositionMovementHistoryAPI()
                // }
                // else {
                //   setErrorMessage(`${(intl.formatMessage({ id: "NoNewValueDetectedForFieldName" }, { field: intl.formatMessage({ id: "StartDate" }) }))}`)
                //   return;
                // }
              }}
              loading={loadingUpdatePositionMovement}
              disable={loadingUpdatePositionMovement || Boolean(localSuccessMessage)}
            />
          }
        </div>
      </div>
    </Modal>
  )
});