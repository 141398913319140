import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { MachineListObject } from '../../app/models/machine';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchUser, newSpaceBeforeCapitalLetter, renderBranchQuantities } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import TagMultipleOutlineIcon from "mdi-react/TagMultipleOutlineIcon";

const Machine = () => {
  const intl = useIntl();
  //Use Store
  const { machineStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { machineList, machinePaginationParams, getMachine, deleteMachine, setMachinePaginationParams } = machineStore;
  const { machineConsumptionType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<MachineListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateMachine = checkPermission([PermissionConstants.CreateMachine]);
  const blnPermissionUpdateMachine = checkPermission([PermissionConstants.UpdateMachine]);
  const blnPermissionDeleteMachine = checkPermission([PermissionConstants.DeleteMachine]);
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [consumptionTypeList, setConsumptionTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedConsumptionType, setSelectedConsumptionType] = useState<string>(AllType);
  const [selectedConsumptionTypeId, setSelectedConsumptionTypeId] = useState<number | string | undefined>(" ");

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      modelNumber: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let machineRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.name,
    modelNumber: validation.values.modelNumber,
    consumptionType: selectedConsumptionTypeId,
    consumptionTypeName: selectedConsumptionType,
    branchId: getBranchUser() ? getBranchId() : ""
  }
  
  useEffect(() => {
    async function fetchMachineListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.machine)
      if (blnBackNavigation) {
        if (machinePaginationParams) {
          setCurrentPage(machinePaginationParams.pageNumber)
          setSelectedConsumptionTypeId(machinePaginationParams.consumptionType);
          setSelectedConsumptionType(machinePaginationParams.consumptionTypeName || AllType);
          validation.setValues((values)=> ({
            ...values,
            name: machinePaginationParams.name || "",
            modelNumber: machinePaginationParams.modelNumber || ""
          }))
          machineRequestBody = machinePaginationParams;
        }
      }
      else {
        setMachinePaginationParams(undefined);
      }

      let aryAPI: any = [
        getMachine(machineRequestBody),
        getStaticReferenceWithType(Constants.machineConsumptionType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setConsumptionTypeList(resultAPI[1]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageMachine], true)) {
      return;
    }

    fetchMachineListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.machine)
        if (!blnSetParams) {
          setMachinePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedConsumptionTypeId || selectedConsumptionTypeId === 0 ) && !initialCallAPI) {
      fetchMachineApi(true);
    }
  }, [selectedConsumptionTypeId, triggerSearch])

  async function fetchMachineApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getMachine(machineRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: MachineListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const MachineColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "modelNumber",
      text: intl.formatMessage({ id: "ModelNumber" }).toUpperCase(),
    },
    {
      dataField: "consumptionType",
      text: intl.formatMessage({ id: "ConsumptionType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.consumptionType)}
        </div>
      ),
    },
    {
      dataField: "lifespanQuantity",
      text: intl.formatMessage({ id: "Lifespan" }).toUpperCase(),
    },
    {
      dataField: "reloadQuantity",
      text: intl.formatMessage({ id: "Reload" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <div className="d-flex gap-1 flex-justfity-content-center">
          {`${row.quantity}`}
          <TagMultipleOutlineIcon
            id={`customerLockedCreditBottom_${row.id}`}
            className="pointer-help"
            color='#556ee6'
            style={{ paddingBottom: "4px" }}
          />
          <UncontrolledTooltip placement="right" target={`customerLockedCreditBottom_${row.id}`}>
            {renderBranchQuantities(row.branchQuantities, intl)}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageMachine, PermissionConstants.UpdateMachine, PermissionConstants.DeleteMachine]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.machine}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateMachine
              &&
              <TableActionButton 
                to={`/${RoutesList.machine}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteMachine
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Machine" }) })}
        title={intl.formatMessage({ id: "Machine" })}
        addButton={
          blnPermissionCreateMachine
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Machine" }) })}
            onClick={() => { history.push(`/${RoutesList.machine}/add`) }}
            linkTo={`/${RoutesList.machine}/add`}
            disable={loading} />
        }>
          <Row>
          {
            !initialCallAPI
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "ConsumptionType" })}
                name="consumptionType"
                options={consumptionTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedConsumptionType}
                initialValue={selectedConsumptionTypeId}
                setFieldLabel={setSelectedConsumptionType}
                setFieldValue={setSelectedConsumptionTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterMachineName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "ModelNumber" })}
              name="modelNumber"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterModelNumber" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={MachineColumns}
          data={machineList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Machine" }) })}
          objSorted={defaultSorted}
          name={validation.values.name}
          modelNumber={validation.values.modelNumber}
          consumptionType={selectedConsumptionTypeId}
          consumptionTypeName={selectedConsumptionType}
          branchId={getBranchUser() ? getBranchId() : ""}
          requestAPI={getMachine} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteMachine(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (machineList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getMachine({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Machine);
