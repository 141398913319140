import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { StockOutContentObject, StockoutProductDetailObject } from "../../app/models/stockOut";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import StockOutProductModal from "./Modal/StockOutProductModal";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const StockOutDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockOutStore, warehouseStore, staticReferenceStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockOut, updateStockOut, stockOutDetail, setStockOutDetail, getStockOutWithId } = stockOutStore;
  const { warehouseDropdownList, getWarehouseDropdown, getWarehouseProducts, getWarehouseProductDetails } = warehouseStore;
  const { stockOutReason, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockOut" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockOut" }), urlPath: RoutesList.stockOut }];
  const [activeTab, setActiveTab] = useState(1);

  const [productStockOutlist, setProductStockOutlist] = useState<StockoutProductDetailObject[]>([]);
  const [selectedStockOutDetails, setSelectedStockOutDetails] = useState<StockOutContentObject | undefined>(undefined);
  const [productModal, setProductModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  const viewStockOutDetails = (index) => {
    if (validation.values.stockOutDetails.length > index) {
      setSelectedStockOutDetails(validation.values.stockOutDetails[index])
      setProductModal(true);
    }
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "2.5",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "TotalQuantity" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "totalQuantity",
      inputType: "number",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "StockOutReason" }).toUpperCase(),
      type: "select",
      xl: "2.5",
      inputName: "stockOutReason",
      inputType: "select",
      options: AddOptionsValueField(stockOutReason, "displayValue", "displayValue"),
      displayLabel: "stockOutReasonDisplay",
      displayValue: "stockOutReasonDisplay",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "remark",
      inputType: "text",
      disabled: true
    },
    {
      label: `${intl.formatMessage({ id: "AdditionalDetails" }).toUpperCase()}`,
      type: "dynamicButton",
      xl: "2",
      inputName: "stockOutDetails",
      inputType: "row",
      rowButtonTitle: "View Stock Out Details",
      dynamicButtonFunction: viewStockOutDetails
    }
  ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockOutDetail || {
      isDelete: true,
      stockOutNo: "",
      stockOutDate: "",
      warehouseId: "",
      warehouseName: "",
      note: "",
      status: "",
      approverId: "",
      approverName: "",
      approvedDate: "",
      stockOutDetails: []
    },
    validationSchema: Yup.object({
      warehouseId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Warehouse" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      try {
        if (addAction) {
          await addStockOut(valuesTemp);
        } else {
          await updateStockOut(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnStatusProcessing = (validation.values.status === Constants.processing);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnPermissionUpdateStockOut = checkPermission([PermissionConstants.UpdateStockOut]);

  useEffect(() => {
    async function fetchStockOutDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getWarehouseDropdown(),
        getStaticReferenceWithType(Constants.stockOutReason),
      ];
      if (id && !addAction) {
        aryAPI.push(getStockOutWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStockOutDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockOut], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockOut], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockOut], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateStockOut){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockOut}/edit/${id}`)} })
    }

    fetchStockOutDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockOutDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    try {
      if (stockOutDetail) {
        onChangeWarehouse(stockOutDetail.warehouseId, stockOutDetail.stockOutDetails)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }, [stockOutDetail])

  useEffect(()=> {
    if (!productModal) {
      setRefreshModal(true);
      setTimeout(()=> {
        setRefreshModal(false);
      }, 100)
    }
  }, [productModal])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onChangeWarehouse = async (value, stockOutDetails?: StockOutContentObject[]) => {
    setLoadingWarehouse(true);
    let resultWarehouseProducts = await getWarehouseProducts(value);
    if (stockOutDetails && resultWarehouseProducts) {
      resultWarehouseProducts.map((valueResultWarehouseProductsTemp)=> {
        stockOutDetails.map((valueStockOutDetailsTemp)=> {
          if (valueResultWarehouseProductsTemp.productId === valueStockOutDetailsTemp.productId) {
            valueResultWarehouseProductsTemp.disabled = true;
          }
        })
      })
    }
    setProductStockOutlist(resultWarehouseProducts || []);
    setLoadingWarehouse(false);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisable = () => {
    if (!validation.values.warehouseId || loadingWarehouse) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockOut" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockOut" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent>
                          <DropDownWithTitle
                            name={"warehouseId"}
                            title={intl.formatMessage({ id: "Warehouse" })}
                            className="mb-3 mt-4"
                            specifyReturnFieldName={[
                              {
                                field: "warehouseId",
                                value: "id",
                              }
                            ]}
                            labelField={"name"}
                            valueField={"id"}
                            options={warehouseDropdownList}
                            blnValueWithNewSpace={true}
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={onChangeWarehouse}
                            initialLabel={!addAction ? validation.values.warehouseName : undefined}
                            initialValue={!addAction ? validation.values.warehouseId : undefined}
                            validationRequired={true}
                            validation={validation}
                            loading={loadingWarehouse}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title={intl.formatMessage({ id: "Note" })}
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      {
                        !refreshModal
                        &&
                        <TableWithEditableInputFields
                          name="stockOutDetails"
                          title=""
                          buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                          blnEditable={!viewAction}
                          blnSupportWithModal={true}
                          blnBtnModalBottom={true}
                          options={productStockOutlist}
                          optionsId={"productId"}
                          setOptionsFunction={setProductStockOutlist}
                          addButtonFunction={() => setProductModal(true)}
                          aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                          data={validation.values.stockOutDetails}
                          validation={validation}
                          disabled={disabledFieldInput} />
                      }
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={true}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", 'Save')
              }}
              hidePrimaryButton={blnStatusProcessing || blnTab1}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", 'Submit')
              }}
            />
          </Form>
        )}
        {
          productModal
          &&
          <StockOutProductModal 
            blnShow={productModal}
            setModal={setProductModal}
            selectedStockOutDetails={selectedStockOutDetails}
            setSelectedStockOutDetails={setSelectedStockOutDetails}
            optionsProductList={AddOptionsValueField(productStockOutlist, "productName", "productId")}
            setOptionsProductList={setProductStockOutlist}
            optionsStockOutReason={AddOptionsValueField(stockOutReason, "displayValue", "displayValue")}
            getWarehouseProductDetails={getWarehouseProductDetails}
            setErrorMessage={setErrorMessage}
            validation={validation}/>
        }        
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockOutDetail);
