import { PaginationRequestBody } from "../models/pagination";
import { PayrollItemListObject, PayrollItemAddObject, PayrollItemUpdateObject, PayrollItemDetailObject } from "../models/payrollItem";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class payrollItemStore {
  payrollItemList: PayrollItemListObject[] = [];
  payrollItemDropdownList: PayrollItemListObject[] = [];
  payrollItemDetail: PayrollItemDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.payrollItemList = [];
    this.payrollItemDropdownList = [];
    this.payrollItemDetail = undefined;
  }

  setPayrollItemDetail = (payrollItemDetail: PayrollItemDetailObject | undefined) => {
    this.payrollItemDetail = payrollItemDetail;
  }
  
  getPayrollItem = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPayrollItem = await agent.PayrollItem.payrollItemList(PaginationRequestBody);
      runInAction(() => {
        this.payrollItemList = resultPayrollItem.data;
        store.commonStore.setTotalItem(resultPayrollItem.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.payrollItemList = [];
    }
  }

  getPayrollItemDropdown = async () => {
    try{
      const resultPayrollItemDropdown = await agent.PayrollItem.payrollItemDropdownList();
      runInAction(() => {
        this.payrollItemDropdownList = resultPayrollItemDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.payrollItemDropdownList = [];
    }
  }

  getPayrollItemWithId = async (id: string) => {
    try{
      const resultPayrollItemDetail = await agent.PayrollItem.payrollItemDetail(id);
      runInAction(() => {
        this.payrollItemDetail = resultPayrollItemDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.payrollItemDetail = undefined;
    }
  }

  addPayrollItem = async (payrollItemRequestBody: PayrollItemAddObject) => {
    try{
      await agent.PayrollItem.addPayrollItem(payrollItemRequestBody);
      store.commonStore.setSuccessMessage(`PayrollItemAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePayrollItem = async (payrollItemRequestBody: PayrollItemUpdateObject) => {
    try{
      await agent.PayrollItem.updatePayrollItem(payrollItemRequestBody);
      store.commonStore.setSuccessMessage(`PayrollItemUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePayrollItem  = async (id: string, name: string) => {
    try {
      await agent.PayrollItem.deletePayrollItem(id);
      store.commonStore.setSuccessMessage(`PayrollItemDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}