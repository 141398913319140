import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody } from "reactstrap";
import { Constants } from "../../constants/Constants";
import { returnWidthPercentage } from "../../common/function/function";


interface Props {
  title: string;
  value: string | number;
  subValue?: string;
  subValueTextColor?: string;
  borderColor?: string;
  iconColor?: string;
  size: number;
  displayCardSubContent?: any;
}

export default function MiniCard(props: Props) {
  return (
    <div style={{width: returnWidthPercentage(props.size)}}>
      <Card className="mini-stats-wid" style={{borderWidth: '4px', borderColor: props.borderColor || Constants.lightblue, borderStyle: "double"}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium mb-2">
                {props.title}
              </p>
              <h4 className="mb-0">{props.value} {props.subValue ? <span className={`${props.subValueTextColor ? props.subValueTextColor : ""}`}>({props.subValue})</span> : ""}</h4>
              {
                props.displayCardSubContent &&
                <div>
                  <hr/>
                  {props.displayCardSubContent}
                </div>
              }
            </div>

            {/* <div className={`mini-stat-icon avatar-sm align-self-center rounded-circle`}>
              <span className="avatar-title" style={{backgroundColor: props.iconColor}}>
              </span>
            </div> */}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
