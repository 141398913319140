import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Row } from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { AppointmentTargetListObject } from '../../app/models/appointmentTarget';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getMonths, getYears, newSpaceBeforeCapitalLetter, returnSubBrandList, returnThousandSeperator } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import MyButton from "../../app/components/form/MyButton";
import AppointmentTargetDuplicateModal from "./Modal/AppointmentTargetDuplicateModal";
import { PaginationRequestBody } from "../../app/models/pagination";
import { SubBrandObject } from "../../app/models/brand";

const AppointmentTarget = () => {
  const intl = useIntl();
  //Use Store
  const { appointmentTargetStore, positionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { appointmentTargetList, appointmentTargetPaginationParams, getAppointmentTarget, deleteAppointmentTarget, setAppointmentTargetPaginationParams } = appointmentTargetStore;
  const { positionDropdownList, getPositionDropdown} = positionStore;
  //Data List
  const [selectedRow, setSelectedRow] = useState<AppointmentTargetListObject | undefined>(undefined);
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>();
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [appointmentTargetDuplicateModal, setAppointmentTargetDuplicateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  
  const blnPermissionCreateAppointmentTarget = checkPermission([PermissionConstants.CreateAppointmentTarget]);
  const blnPermissionUpdateAppointmentTarget = checkPermission([PermissionConstants.UpdateAppointmentTarget]);
  const blnPermissionDeleteAppointmentTarget = checkPermission([PermissionConstants.DeleteAppointmentTarget]);
  const [showDropdown, setShowDropdown] = useState(false);

  let appointmentTargetRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize, 
    year: selectedYear,
    selectedMonthDisplay: selectedMonth, 
    month: selectedMonthValue,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchAppointmentTargetListAPI() {
      setLoading(true)

      let blnSetDate = false;
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.appointmentTarget)
      if (blnBackNavigation) {
        if (appointmentTargetPaginationParams) {
          setCurrentPage(appointmentTargetPaginationParams.pageNumber )
          setSelectedMonth(appointmentTargetPaginationParams.selectedMonthDisplay || moment().format("MMM"))
          setSelectedMonthValue(appointmentTargetPaginationParams.month || Number(moment().format("M")))
          setSelectedYear(appointmentTargetPaginationParams.year || Number(moment().format('yyyy')))
          setSelectedSubBrandName(appointmentTargetPaginationParams.subBrandName || AllSubBrand)
          setSelectedSubBrandId(appointmentTargetPaginationParams.subBrandId || " ")
          appointmentTargetRequestBody = appointmentTargetPaginationParams;
          blnSetDate = true;
        }
      }
      else {
        setAppointmentTargetPaginationParams(undefined);
      }
      
      if (!blnSetDate) {
        setSelectedMonth(moment().format("MMM"))
        setSelectedMonthValue(Number(moment().format("M")))
        setSelectedYear(Number(moment().format('yyyy')))
      }
      
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      
      setShowDropdown(true);

      // let aryAPI: any = [
      //   getPositionDropdown("1")
      // ];
      // let resultAPI = await Promise.all(aryAPI);
      // setPositionListFinal(arraySpliceInAllValue(resultAPI[0], AllPosition, false, "name", "id"))
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointmentTarget], true)) {
      return;
    }

    fetchAppointmentTargetListAPI();
    setMonthList(getMonths());
    setYearList(getYears());

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.appointmentTarget)
        if (!blnSetParams) {
          setAppointmentTargetPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedYear && selectedMonthValue) {
      fetchAppointmentTargetAPI(true);
    }
  }, [selectedYear, selectedMonthValue, selectedSubBrandId])

  async function fetchAppointmentTargetAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getAppointmentTarget(appointmentTargetRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: AppointmentTargetListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const AppointmentTargetColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    },
    {
      dataField: "month",
      text: intl.formatMessage({ id: "Month" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <div>
          {moment(row.month, "MM").format("MMM")}
        </div>,
    }, 
    {
      dataField: "target",
      text: intl.formatMessage({ id: "Target" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      hidden: returnSubBrandList().length < 1,
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.appointmentTarget}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateAppointmentTarget
              &&
              <TableActionButton 
                to={`/${RoutesList.appointmentTarget}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteAppointmentTarget
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppointmentTarget" }) })}
        title={intl.formatMessage({ id: "AppointmentTarget" })}
        addButton={
          blnPermissionCreateAppointmentTarget
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "AppointmentTarget" }) })}
              onClick={() => { history.push(`/${RoutesList.appointmentTarget}/add`) }}
              linkTo={`/${RoutesList.appointmentTarget}/add`}
              disable={localLoading || loading} />
            <MyButton
              content={intl.formatMessage({ id: "DuplicateWithModuleName" }, { moduleName: intl.formatMessage({ id: "AppointmentTarget" }) })}
              onClick={() => { setAppointmentTargetDuplicateModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={loading} />
          </>
        }
        addButtonXL={"4"}>
        {
          showDropdown
          &&
          <Row>
            {
              subBrandListFinal.length > 0
              &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            }
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentTargetColumns}
              data={appointmentTargetList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppointmentTarget" }) })}
              objSorted={defaultSorted}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              selectedMonthDisplay={selectedMonth}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} 
              requestAPI={getAppointmentTarget} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteAppointmentTarget(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (appointmentTargetList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getAppointmentTarget({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear, month: selectedMonthValue })
            setLoading(false);
          }} />
        {
          appointmentTargetDuplicateModal
          &&
          <AppointmentTargetDuplicateModal 
            blnShow={appointmentTargetDuplicateModal}
            setModal={setAppointmentTargetDuplicateModal}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppointmentTarget);
