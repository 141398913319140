import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const LeaveEntitlementDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { leaveEntitlementStore, staticReferenceStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addLeaveEntitlement, updateLeaveEntitlement, leaveEntitlementDetail, setLeaveEntitlementDetail, getLeaveEntitlementWithId } = leaveEntitlementStore;
  const { leaveType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "LeaveEntitlement" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "LeaveEntitlement" }), urlPath: RoutesList.leaveEntitlement }];
  const pageSize = Constants.maxPageSize;
  const [selectedLeaveTypeIds, setSelectedLeaveTypeIds] = useState<any>([]);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: leaveEntitlementDetail || {
      name: "",
      defaultQuantity: 0,
      chargeTypes: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LeaveEntitlementName" }) })),
      defaultQuantity: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DefaultQuantity" }) })),
      chargeTypes: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "LeaveType" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      //valuesTemp.chargeTypes = valuesTemp.chargeTypes.map((valueTemp)=> valueTemp.value)
      try {
        if (addAction) {
          await addLeaveEntitlement(valuesTemp);
        } else {
          await updateLeaveEntitlement(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchLeaveEntitlementDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.leaveType)
      ];
      if (id && !addAction) {
        aryAPI.push(getLeaveEntitlementWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setLeaveEntitlementDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateLeaveEntitlement], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateLeaveEntitlement], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageLeaveEntitlement], true)) {
        return;
      }
    }

    if(viewAction && checkPermission([PermissionConstants.UpdateLeaveEntitlement])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.leaveEntitlement}/edit/${id}`)} })
    }

    fetchLeaveEntitlementDetailAPI();

    return (() => {
      if (id && !addAction) {
        setLeaveEntitlementDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    let arySelectedLeaveTypeIdTemp: any = [];
    if (id && !addAction) {
      if (leaveEntitlementDetail) {
        leaveEntitlementDetail.chargeTypes.map((value) => {
          for (var a = 0; a < leaveType.length; a++) {
            if (value === leaveType[a].displayValue) {
              arySelectedLeaveTypeIdTemp.push({
                ...leaveType[a],
                label: newSpaceBeforeCapitalLetter(leaveType[a].displayValue),
                value: leaveType[a].key
              })
            }
          }
        })
        setSelectedLeaveTypeIds(arySelectedLeaveTypeIdTemp);
      }
    }
  }, [leaveEntitlementDetail, leaveType])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "LeaveEntitlement" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "LeaveEntitlementName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DefaultQuantity" })}
                    name="defaultQuantity"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitleMultiSelect
                    name={"chargeTypes"}
                    title={intl.formatMessage({ id: "LeaveType" })}
                    specifyReturnFieldName={[{ "field": "chargeTypes", "value": "displayValue" }]}
                    returnFieldWithLabel={false}
                    blnValueWithNewSpace={true}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={leaveType}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={selectedLeaveTypeIds}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>

              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(LeaveEntitlementDetail);
