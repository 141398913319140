import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, returnPriceWithCurrency, checkPermission, getBranchUser, getBranchId, getBranchName, returnSubBrandList, arraySpliceInAllValue, paymentStatusColor, firstDayOfCurrentMonthToLastDayOfCurrentMonth } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { Link, useLocation } from 'react-router-dom';
import _ from "lodash";
import { ConsultantSalesRequestObject } from "../../../app/models/reportSales";
import ConsultantSalesDetail from "./Modal/ConsultantSalesDetail";
import { history } from "../../../";
import { SubBrandObject } from "../../../app/models/brand";
import { EmployeeListObject } from "../../../app/models/employee";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";

const ConsultantSales = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, employeeStore, commonStore, profileStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { consultantSalesList, setConsultantSalesList, getConsultantSales, exportConsultantSales } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { getEmployeeDropdownForConsultantSales } = employeeStore;
  const { profileDetail } = profileStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
	const [loadingEmployee, setLoadingEmployee] = useState(false);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(getBranchUser() ? profileDetail?.id : " ");
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(getBranchUser() ? profileDetail?.preferredName : All);
  const [consultantList, setConsultantList] = useState<EmployeeListObject[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [consultantSalesDetailModal, setConsultantSalesDetailModal] = useState(false);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const paramsSearch = useLocation().search;
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  const blnPermissionCreateConsultantSales = checkPermission([PermissionConstants.CreateConsultantSales]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchConsultantSales() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];

      const yearParams = new URLSearchParams(paramsSearch).get('year');
      const monthParams = new URLSearchParams(paramsSearch).get('month');
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const consultantIdParams = new URLSearchParams(paramsSearch).get('consultantId');
      const subBrandIdParams = new URLSearchParams(paramsSearch).get('subBrandId');
      if (yearParams && monthParams && branchIdParams && consultantIdParams) {
        let dateRangeParams = {
          fromDate: String(moment(`${yearParams}-${monthParams}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
          toDate: String(moment(`${yearParams}-${monthParams}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
        }
        aryAPI.push(fetchEmployeeWithLoading(branchIdParams, dateRangeParams))
      }
       
      let resultAPI = await Promise.all(aryAPI);

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }

      if (yearParams && monthParams && branchIdParams && consultantIdParams) {
        let indexBranchId = _.findIndex(resultAPI[0], { id: branchIdParams })
        let indexEmployeeId = _.findIndex(resultAPI[1], { id: consultantIdParams})

        if(subBrandIdParams){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandIdParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }

        if (indexBranchId > -1) {
          setSelectedBranchId(resultAPI[0][indexBranchId].id);
          setSelectedBranchName(resultAPI[0][indexBranchId].name);
        }
        if (indexEmployeeId > -1) {
          setSelectedEmployeeId(resultAPI[1][indexEmployeeId].id);
          setSelectedEmployeeName(resultAPI[1][indexEmployeeId].preferredName);
        }

        setSelectedYear(Number(yearParams));
        setSelectedMonth(moment(monthParams).format("MMM"));
        setSelectedMonthValue(Number(monthParams));

        let dateRangeLimitParamsTemp = {
          fromDate: String(moment(`${yearParams}-${monthParams}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
          toDate: String(moment(`${yearParams}-${monthParams}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
        }
        setSelectedDateRange(dateRangeLimitParamsTemp);

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.consultantSales}` });
        await fetchConsultantSalesWithLoading({ branchId: branchIdParams, consultantId: consultantIdParams, month: Number(monthParams), year: Number(yearParams), fromDate: dateRangeLimitParamsTemp.fromDate, toDate: dateRangeLimitParamsTemp.toDate, ...(subBrandIdParams) && {subBrandId: subBrandIdParams} });
      }
      
			setLoading(false);
      setInitialCallAPI(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchConsultantSales();

    return (()=> {
      setConsultantSalesList([]);
    })
  }, [])

  useEffect(()=> {
    setSelectedDateRange(dateRangeLimit)
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
    if (!initialCallAPI) {
      fetchEmployeeWithLoading("", dateRangeLimit)
    }
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
    fetchEmployeeWithLoading("", objDateRange);
  }

  const fetchEmployeeWithLoading = async (branchId: string, dateRange: DateRangeInterface) => {
    if (branchId === "" && selectedBranchId === "") {
      return [];
    }
		setLoadingEmployee(true);
		let employeeDropdownTemp = await getEmployeeDropdownForConsultantSales({branchId: branchId || selectedBranchId, dateRange: dateRange || selectedDateRange});
    setSelectedEmployeeName(All);
    setSelectedEmployeeId(" ");
    setConsultantList(arraySpliceInAllValue(employeeDropdownTemp, All, true, "preferredName", "id"));
		setLoadingEmployee(false);

    return employeeDropdownTemp;
	};

  const fetchConsultantSalesWithLoading = async (initialData?: ConsultantSalesRequestObject) => { //branchId and employeeId is for initial call only.
    if (initialData) {
      setLocalLoading(true);
      setCurrentPage(1);
      await getConsultantSales({pageSize: pageSize, pageNumber: 1, consultantId: initialData.consultantId, branchId: initialData.branchId, year: initialData.year, month: initialData.month, dateRange: { fromDate: initialData.fromDate, toDate: initialData.toDate }, subBrandId: initialData.subBrandId || selectedSubBrandId});
      setLocalLoading(false);
    }
    else if (selectedEmployeeId && selectedBranchId) {
      setLocalLoading(true);
      setCurrentPage(1);
      await getConsultantSales({ pageSize: pageSize, pageNumber: 1, consultantId: selectedEmployeeId, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, dateRange: selectedDateRange, ...(selectedSubBrandId) && { subBrandId: selectedSubBrandId }, salesOrderNo: validation.values.salesOrderNo });
      setLocalLoading(false);
    }
    else {
      if (!selectedBranchId) {
        setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      }
    }
	};

  async function exportConsultantSalesApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
    }

    if (selectedEmployeeId && selectedBranchId){
      setLocalLoading(true);
      await exportConsultantSales({ pageSize: pageSize, pageNumber: 1, consultantId: selectedEmployeeId, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, ...(selectedSubBrandId) && { subBrandId: selectedSubBrandId }, salesOrderNo: validation.values.salesOrderNo })
      setLocalLoading(false);
    }
  }

  //Table Content UI
  const ConsultantSalesColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "salesOrderDate",
      text: intl.formatMessage({ id: "SalesOrderDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.salesOrderDate !== Constants.invalidDateTime ? `${moment(row.salesOrderDate).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.salesOrderDate !== Constants.invalidDateTime ? moment(row.salesOrderDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "PaymentDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{moment(row.paymentDate).format(Constants.displayDateFormat)}</>, 
    },
    {
      dataField: "paymentBranch",
      text: intl.formatMessage({ id: "PaymentBranch" }).toUpperCase(),
    },
    {
      dataField: "transactionPackageSalesAmount",
      text: intl.formatMessage({ id: "PackageSalesAmt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.transactionPackageSalesAmount)}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "SalesOrderAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "transactionProductSalesAmount",
      text: intl.formatMessage({ id: "ProductSalesAmt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.transactionProductSalesAmount)}</div>
      ),
    },
    {
      dataField: "transactionReceivedAmount",
      text: intl.formatMessage({ id: "ReceivedAmt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.transactionReceivedAmount)}</div>
      ),
    },
    {
      dataField: "productCommissionAmount",
      text: intl.formatMessage({ id: "ProductCommAmt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productCommissionAmount)}</div>
      ),
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.paymentStatus}
          statusColorFunction={paymentStatusColor}/>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ConsultantSales"})})}
        title={intl.formatMessage({ id: "ConsultantSales" })}
        breadCrumbList={breadCrumbList}
        addButton={
          <>
            {/* {
              blnPermissionCreateConsultantSales
              &&
              <MyAddButton
                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "ConsultantSales" }) })}
                onClick={() => {
                  setConsultantSalesDetailModal(true);
                }}
                disable={localLoading || loading} />
            } */}
            {
              blnPermissionExportSalesReport
              &&
              <MyButton
                type="button"
                class="btn btn-success margin-left-4"
                content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                disable={localLoading || loading}
                onClick={() => {
                  exportConsultantSalesApi()
                }}
                />
            }
          </>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Consultant-Sales.aspx`}>
        {
          !loading
          &&
          <>
          <Row>
            {
              subBrandListFinal.length > 0
              &&
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                // className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || loadingEmployee || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                onChange={fetchEmployeeWithLoading}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              {
                loadingEmployee
                ?
                <Loading />
                :
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Employee" })}
                  name="employee"
                  // className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                  options={consultantList}
                  initialLabel={!selectedBranchId ? "" : selectedEmployeeName}
                  initialValue={!selectedBranchId ? "" : selectedEmployeeId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedEmployeeName}
                  setFieldValue={setSelectedEmployeeId}
                  validationRequired={true}
                />
              }
            </Col>
          </Row>
          <Row>
          <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              {
                localLoadingDateRange
                ?
                <Loading/>
                :
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRange" })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  initialValue={selectedDateRange}
                  minMaxDateObj={dateRangeLimit}
                  validationRequired={true}
                  onChangeFunction={onSelectDateRange}/>
              }
            </Col>
          </Row>
          <Row>
            <Col xl={"3"}>
              <GeneralInput
                title={intl.formatMessage({ id: "SalesOrderNo" })}
                name="salesOrderNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSalesOrderNo" })}
              />
            </Col>
            <Col xl="3">
              <MyButton
                type="button"
                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchConsultantSalesWithLoading()
                }}
              />
            </Col>
          </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ConsultantSalesColumns}
            data={consultantSalesList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ConsultantSales"})})}
            objSorted={defaultSorted}
            requestAPI={getConsultantSales}
            consultantId={selectedEmployeeId}
            branchId={selectedBranchId}
            selectedYear={selectedYear}
            selectedMonth={selectedMonthValue}
            dateRange={selectedDateRange}
            subBrandId={selectedSubBrandId}
            salesOrderNo={validation.values.salesOrderNo}
            hideSearchBar={true}/>
        }
        {
          consultantSalesDetailModal &&
          <ConsultantSalesDetail
            blnShow={consultantSalesDetailModal}
            setModal={setConsultantSalesDetailModal}
            yearList={yearList}
            monthList={monthList}
            branchDropdownList={branchDropDownList}
          />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ConsultantSales);
