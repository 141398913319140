import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, getYears, getMonths, getDays, returnSubBrandList, displaySummaryDetailsTableCellUI } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import MyButton from "../../../app/components/form/MyButton";
import { SubBrandObject } from "../../../app/models/brand";

const AppointmentOverviewSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { appointmentOverviewSummaryList, getAppointmentOverviewSummary, setAppointmentOverviewSummaryList, exportAppointmentOverviewSummary } = reportMarcomStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [dayList, setDayList] = useState<ObjectForDropdown[]>([]);
  const [selectedDay, setSelectedDay] = useState<number | string>(moment().format("D"));
  const [refreshDayDropdown, setRefreshDayDropdown] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      promoCode: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());
    setDayList(getDays(selectedYear, selectedMonthValue)); 

    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }

    async function fetchAppointmentOverviewSummaryListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown({isHQ: false}, false, false, false, true, false)
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchAppointmentOverviewSummaryListAPI();

    return (() => {
      setAppointmentOverviewSummaryList([]);
    })
  }, []);

  const fetchAppointmentOverviewSummaryListWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportAppointmentOverviewSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, day: selectedDay, subBrandId: selectedSubBrandId });
    }
    else{
      await getAppointmentOverviewSummary({ branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, day: selectedDay, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const AppointmentOverviewSummaryColumns = [
    {
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.branchId}</>, //Custom UI to display
    },
    {
    dataField: "subBrandName",
    text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
    formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "subBrandName")
      },
    },
    {
      dataField: "marcomCsNames",
      text: intl.formatMessage({ id: "CS" }).toUpperCase(),
      style: { width: "100px" },
      formatter: (cellContent, row) => {
        return (
          <div style={{width:"100px"}}>
            {displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "marcomCsNames")}
          </div>
        )
      },
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"100px"}}>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "target",
      text: intl.formatMessage({ id: "Target" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "target")
      },
    },
    {
      dataField: "mtdTarget",
      text: intl.formatMessage({ id: "MTDTarget" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "mtdTarget")
      },
    },
    {
      dataField: "leadAppt",
      text: intl.formatMessage({ id: "LeadAppt" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "leadAppt")
      },
    },
    {
      dataField: "appointmentOverMTD",
      text: intl.formatMessage({ id: "APPT(150%)" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "appointmentOverMTD", true)
      }
    },
    {
      dataField: "zeroConsultation",
      text: intl.formatMessage({ id: "Consultation(RM0)" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "zeroConsultation")
      },
    },
    {
      dataField: "normalConsultation",
      text: intl.formatMessage({ id: "Consultation(RM50)" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "normalConsultation")
      },
    },
    {
      dataField: "totalTurnedUp",
      text: intl.formatMessage({ id: "TotalTurnUp" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "totalTurnedUp")
      },
    },
    {
      dataField: "turnedUpPercentage",
      text: intl.formatMessage({ id: "TurnUp(100%)" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "turnedUpPercentage", true)
      },
    },
    {
      dataField: "variance",
      text: intl.formatMessage({ id: "Variance" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "variance")
      },
    },
    {
      dataField: "telerate",
      text: intl.formatMessage({ id: "Tele(%)" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "telerate", true)
      }
    },
    {
      dataField: "stillNeed",
      text: intl.formatMessage({ id: "StillNeed" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "stillNeed")
      },
    },
    {
      dataField: "dailyNeeded",
      text: intl.formatMessage({ id: "DailyNeeded" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.appointmentOverviewSummaryDetails, "dailyNeeded")
      },
    },
  ];

  const handleDayDropdown = (year, month) => {
    const latestYear = year || selectedYear;
    const latestMonth = month || selectedMonthValue;
    const dayListTemp = getDays(latestYear, latestMonth);
    setRefreshDayDropdown(true);
    setDayList(dayListTemp);
    if (selectedDay) {
      let indexValidDayFound = _.findIndex(dayListTemp, {value: Number(selectedDay)})
      if (indexValidDayFound === -1) {
        setSelectedDay(dayListTemp[dayListTemp.length-1].value);
      }
    }
    setTimeout(()=> {
      setRefreshDayDropdown(false);
    }, 100)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppointmentOverviewSummary" }) })}
        title={intl.formatMessage({ id: "AppointmentOverviewSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchAppointmentOverviewSummaryListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Appointment-Overview-Summary-(M33).aspx`}>
        <Row>
          {
            returnSubBrandList().length > 0
            &&
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                labelField="name"
                valueField="id"
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </Col>
          }
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              onChange={(valueYear)=> {
                handleDayDropdown(valueYear, "")
              }}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              onChange={(valueMonth)=> {
                handleDayDropdown("", valueMonth)
              }}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
        </Row>
        <Row>
          {
            !refreshDayDropdown
            &&
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Day" })}
                name="day"
                options={dayList}
                initialLabel={selectedDay}
                initialValue={selectedDay}
                setFieldValue={setSelectedDay}
                disabled={localLoading || loading}
              />
            </Col>
          }
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading }
              disable={localLoading || loading }
              onClick={() => {
                fetchAppointmentOverviewSummaryListWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentOverviewSummaryColumns}
              bordered={true}
              keyField={"branchId"}
              data={appointmentOverviewSummaryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppointmentOverviewSummary" }) })}
              objSorted={defaultSorted}
              requestAPI={getAppointmentOverviewSummary}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} 
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppointmentOverviewSummary);
