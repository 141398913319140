import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

// Import css
import "./assets/scss/theme.scss";

// Import All Routes 
import { authProtectedRoutes, publicRoutes } from "./routes/routesList"

// Import all middleware
import Authmiddleware from "./routes/Authmiddleware"

// Error Page
import NotFound from './features/Errors/NotFound';

// Server Error
import ServerError from './features/Errors/ServerError';

// Import Layout
import NonAuthLayout from './app/components/layout/NonAuthLayout';
import VerticalLayout from './app/components/layout/VerticalLayout';
import { useStore } from './app/stores/store';
import { observer } from 'mobx-react-lite';
import { history } from "./";

//Import Constants
import { Constants } from './app/constants/Constants';
import { RoutesList } from './app/constants/RoutesList';
import { displayWatermarkForENV, getBranchUser, getDisplayName, getJWT, validDisplayBranchDropdown } from './app/common/function/function';
import { IntlProvider } from 'react-intl';

//Languages File 
import englishLanguageFile from './lang/en.json'; 
import chineseLanguageFile from './lang/cn.json'; 

//Loading
import Loading from './app/components/loading/Loading';

//Toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import HelpMenu from './app/components/layout/HelpMenu';
import SelectCardModal from './app/components/modal/SelectCardModal';

function App() {
  const { userStore, brandStore, branchStore, permissionStore, commonStore, deliveryOrderStore, profileStore, virtualWarehouseCreditExchangeStore, stockAdjustmentStore } = useStore();
  const { isLogin, logout, switchBrandModal, setUrlPathAfter401, refreshToken, setSwitchBrandModal } = userStore;
  const { profileDetail, getProfileDetail } = profileStore;
  const { locale, errorMessage, successMessageWithoutNavigation, successMessage, displayName, displayPermissionDenied, prevPath, showHelpMenu, branchRefresh, hideComponentForPrint, blnDevAndUatEnv, setDisplayName, setErrorMessage, setSuccessMessage, setSuccessMessageWithoutNavigation, setWindowSize, setLoadNavigation, setDisplayPermissionDenied, setPrevPath, setTotalItem, setBranchRefresh, setHideComponentForPrint, setBlnDevAndUatEnv } = commonStore;
  const { getSubBrandDropdown } = brandStore;
  const { branchForHeaderDropDown, getBranchDropDown } = branchStore;
  const { customerIdFromCustomerPurchase } = deliveryOrderStore;
  const { customerIdFromVirtualWarehouseCreditExchange } = virtualWarehouseCreditExchangeStore;
  const { getPermissionUser } = permissionStore;
  const { setStockAdjustmentPrintAllList } = stockAdjustmentStore;
  const [initialApp, setInitialApp] = useState(true);
  const location = useLocation();
  const languageFile = locale==Constants.en ? englishLanguageFile : chineseLanguageFile;
  const hostName = window.location.hostname || "";
  const blnLocalHost = hostName.includes('localhost');
  const blnDev = hostName.includes('onedoc-dev');
  const blnUAT = hostName.includes('onedoc-uat');
  const blnUAT2 = hostName.includes('uat.onedocgroup');
  const colorEnvDisplay = blnLocalHost ? Constants.green : blnDev ? Constants.pink : blnUAT2 ? Constants.lightpurple : Constants.orange;
  const envDisplay = blnLocalHost ? "Local Host" : blnDev ? "Dev" : "UAT";
  const blnDisplayEnvWaterMark = window.localStorage.getItem('env_watermark') !== "false"; 
  
  useEffect(()=> {
    async function fetchInitialData() {
      // let blnValid = location.pathname !== '/login' && location.pathname !== '/'
      if (!userStore.refreshTokenTimeout && getJWT()) {
        refreshToken()
        .then(async (value)=> {
          if (value === Constants.success) {
            //Need to check on userStore -> loginWithBrand
            let aryAPI: any = [
              fetchBranch(),
              getPermissionUser(),
              getProfileDetail(),
              getSubBrandDropdown()
            ];
            await Promise.all(aryAPI)
          } 
        })
        .finally(()=> {
          setTimeout(()=> {
            setInitialApp(false);
          }, 100)
        });
      }
      else {
        setInitialApp(false);
      }

      if (!getJWT() && location?.pathname !== `/${RoutesList.forgotPassword}` && location?.pathname !== `/${RoutesList.forgotPasswordValidator}` ) {
        if (location.pathname && location?.pathname !== `/${RoutesList.login}` && location?.pathname !== `/`) {
          setUrlPathAfter401(location.pathname);
        }
        logout(false, false, false);
      }
    }

    fetchInitialData();
    setBlnDevAndUatEnv(blnDev || blnUAT || blnUAT2);

    moment.tz.setDefault('Asia/Kuala_Lumpur');
  }, []);

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
    setLoadNavigation(false);
    
    if (!initialApp) {
      fetchBranch();
    }

    if (!displayName) {
      setDisplayName(getDisplayName());
    }
    else {
      if (displayName.toUpperCase() !== getDisplayName().toUpperCase()) {
        window.location.reload();
      }
    }

    if (displayPermissionDenied) {
      setDisplayPermissionDenied(false);
    }

    if (location.pathname !== prevPath) {
      setTotalItem(0); //To reset pagination page number into 0
      setPrevPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    function handleBeforePrint() {
      const links = document.querySelectorAll('a') || [];
      links.forEach((link) => {
        link.removeAttribute('href'); // Removes hyperlink attribute before printing
        if (link.rel === "noopener noreferrer" && !link.target) {
          link.style.color = "";
        }
      });
    };

    function handleAfterPrint() {
      setHideComponentForPrint(false);
      setStockAdjustmentPrintAllList([]);

      const links = document.querySelectorAll('a') || [];
      links.forEach((link) => {
        if (link.rel === "noopener noreferrer" && !link.target) {
          link.style.color = Constants.lightpurple;
        }
      });
    };

    function handleSwitchBrand() {
      let blnReloadWindow = window.localStorage.getItem("refreshTab") === "true"
      if (blnReloadWindow) {
        window.location.reload()
      }
    }

    setWindowSize(getWindowSize());
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);
    window.addEventListener('storage', handleSwitchBrand);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
      window.removeEventListener('storage', handleSwitchBrand);
    };
  }, []);

  useEffect(() => {
    if (branchRefresh) {
      setBranchRefresh(false);
      window.location.reload();
    }
  }, [branchRefresh])

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function fetchBranch() {
    // if (validDisplayBranchDropdown(location?.pathname)) {
      if (getJWT() && branchForHeaderDropDown.length < 1 && !getBranchUser()) {
        getBranchDropDown({}, true);
      }
    // }
  }

  useEffect(()=> {
    if (errorMessage && !location?.pathname?.includes("login")) {
      toast.error(errorMessage in languageFile ? languageFile[errorMessage] : errorMessage);
      setErrorMessage("");
    }
  }, [errorMessage])

  useEffect(() => {
    if (successMessageWithoutNavigation) {
      toast.success(successMessageWithoutNavigation in languageFile ? languageFile[successMessageWithoutNavigation] : successMessageWithoutNavigation);
      setSuccessMessageWithoutNavigation("");
      return;
    }

    if (successMessage) {
      toast.success(successMessage in languageFile ? languageFile[successMessage] : successMessage);
      let pathNameTemp = location.pathname || "";
      let blnPathRun = false;
      let customerProfileNavigationId = ""
      if (pathNameTemp.includes('/')) {
        try {
          let aryPath = location.pathname.split('/');
          if (aryPath.length > 2) {
            const firstPath = location.pathname.split('/')[1];
            const secondPath = location.pathname.split('/')[2];
            if (firstPath === RoutesList.invoice) {
              return;
            }
            if (firstPath === RoutesList.report) {
              return;
            }
            if (secondPath === RoutesList.customerProfilePurchase) {
              return;
            }
            if (firstPath === RoutesList.customer && secondPath === 'view') {
              return;
            }
            if (firstPath === RoutesList.customerRedemption && secondPath === 'edit') {
              customerProfileNavigationId = customerIdFromCustomerPurchase;
            }
            if (firstPath === RoutesList.virtualWarehouseCreditExchange && secondPath === 'addFromCustomer') {
              customerProfileNavigationId = customerIdFromVirtualWarehouseCreditExchange;
            }
            if (firstPath === RoutesList.customerTransferRequest && secondPath === 'view') {
              return;
            }
            if (firstPath === RoutesList.customer && secondPath === RoutesList.appolousCustomerPackageRedemption) {
              return;
            }
            if (firstPath === RoutesList.customer && secondPath === RoutesList.appolousCustomerPackage) {
              return;
            }
            if (firstPath === RoutesList.customer && secondPath === RoutesList.appolousCustomerProduct) {
              return;
            }
            setLoadNavigation(true);
            blnPathRun = true;
            setTimeout(()=> {
              setSuccessMessage("");
              if (commonStore.loadNavigation) {
                if (firstPath == RoutesList.appointment || firstPath == RoutesList.appointmentList) {
                  history.push({
                    pathname: `/${firstPath}`,
                    state: { action: "refreshAppointmentData" }
                  });
                }
                else if (customerProfileNavigationId) {
                  history.push(`/${RoutesList.customer}/view/${customerProfileNavigationId}`)
                }
                else {
                  history.push(`/${firstPath}`)
                }
              }
            }, Constants.timeoutNavigation)
          }
        }
        catch (e) {
          setErrorMessage("There's an error in routing.");
        }
      }

      if (!blnPathRun) {
        setTimeout(()=> {
          setSuccessMessage("");
        }, Constants.timeoutNavigation)
      }
    }
  }, [successMessage, successMessageWithoutNavigation])

  return (
    <div>
      <IntlProvider 
        messages={languageFile} 
        locale={locale}>
        {
          !initialApp       
          ?
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                displayPermissionDenied={displayPermissionDenied}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                isLogin={isLogin}
                displayPermissionDenied={displayPermissionDenied}
                exact
              />
            ))}

            <Route path={`/${RoutesList.serverError}`} render={()=> <ServerError />} />
            <Route path="*" render={()=> <NotFound />} />
          </Switch>
          :
          <Loading 
            initialLoading={true}/>
        }
        {
          (blnLocalHost || blnDev || blnUAT || blnUAT2)
          &&
          <div style={{backgroundColor: colorEnvDisplay}} className='env-color-display d-print-none'>{envDisplay}</div>
        }
        {
          blnDevAndUatEnv
          && 
          blnDisplayEnvWaterMark
          &&
          displayWatermarkForENV(envDisplay, colorEnvDisplay)
        }
        {showHelpMenu && <HelpMenu/>}
        {
          switchBrandModal
          &&
          <SelectCardModal
            blnShow={switchBrandModal}
            setModal={setSwitchBrandModal}
            strTitle={"Switch Brand"}
            options={profileDetail?.otherAvailableBrands || []}
            fieldNameForRequestBody="id"
            fieldToPickFromOption="id"
            store={userStore}
            functionFromStore={"getSwitchBrand"}
          />
        }
      </IntlProvider>
      <ToastContainer 
        autoClose={Constants.timeoutNavigation}
        theme="colored"
        pauseOnHover={true}
        closeOnClick/>
    </div>
  );
}

export default observer(App);
