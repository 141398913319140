import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import {
  appendCountryCode,
  checkPermission,
  contructValidationErrorMessage,
  convertAdditionalFormDataRead,
  convertAdditonalFormDataSubmit,
  getBranchId,
  getBranchName,
  getBranchUser,
  IncludesLocationPathName,
  returnSubBrandList,
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Form,
  Label
} from "reactstrap";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import AdditionalFormField from "../../app/components/form/AdditionalFormField";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import EmployeeAdvanceSearch from "../Employee/Modal/EmployeeAdvanceSearch";
import { EmployeeAdvanceSearchObject, EmployeeListObject } from "../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";
import ReferredByCustomerSearchModal from "../Appointment/Modal/ReferredByCustomerSearchModal";

const CustomerDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const {
    customerStore,
    staticReferenceStore,
    raceStore,
    branchStore,
    countryStore,
    employeeStore,
    mediaSourceStore,
    additionalFormFieldStore,
    settingsStore,
    commonStore,
  } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    windowSize,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const {
    addCustomer,
    updateCustomer,
    customerDetail,
    setCustomerDetail,
    getCustomerWithId,
    getCustomerWithAppointmentId
  } = customerStore;
  const {
    titleType,
    identityType,
    genderType,
    getStaticReference,
  } = staticReferenceStore;
  const { raceDropdownList, getRaceDropdown } = raceStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown, setStateList } = countryStore;
  const { employeeDropdownList, getEmployeeDropdown, setEmployeeDropdownList, getEmployeeForCustomerInfo } = employeeStore;
  const { mediaSourceDropdownList, getMediaSourceDropdown } = mediaSourceStore;
  const { additionalFormFieldFormTypeList, setAdditionalFormFieldFormTypeList, getAdditionalFormFieldDropdown } = additionalFormFieldStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const editAction = IncludesLocationPathName("edit");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Customer" })}`;
  const location = useLocation();
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }];
  const [loadingState, setLoadingState] = useState(false);
  const [loadingConsultant, setLoadingConsultant] = useState(false);
  const [showEmployeeSearchModal, setShowEmployeeSearchModal] = useState(false);
  const [loadingSearchEmployee, setLoadingSearchEmployee] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [employeeDropdownForSubBranchList, setEmployeeDropdownForSubBranchList] = useState<EmployeeListObject[]>([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(true);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [blnSubBranch, setBlnSubBranch] = useState(false);
  const [selectedBagdes, setSelectedBagdes] = useState<any>([]);
  const [disabledSubBrandOption, setDisabledSubBrandOption] = useState(false);
  const [blnEditMediaSourceWithAppointmentId, setBlnEditMediaSourceWithAppointmentId] = useState(false);
  const blnPermissionUpdateCustomer = checkPermission([PermissionConstants.UpdateCustomer]);
  const blnPermissionEditCustomerMediaSource = checkPermission([PermissionConstants.EditCustomerMediaSource]);
  const [appointmentId, setAppointmentId] = useState("");
  const [blnReferralType, setBlnReferralType] = useState(false);
  const [customerCriteriaSearchModal, setCustomerCriteriaSearchModal] = useState(false);
  const blnShowAppolous = generalSettings?.isShowAppolous;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerDetail || {
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      preferredName: "",
      identityNo: "",
      identityType: "",
      dob: "1990-01-01",
      gender: "",
      mobileNo: "",
      email: "noemail@gmail.com",
      raceId: "",
      raceName: "",
      branchId: "",
      branchName: "",
      occupation: "",
      emergencyContact: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      sourceId: "",
      mediaSource: "",
      consultantId: "",
      consultantName: "",
      medicalHistory: "",
      drugAllergy: "",
      pastAestheticProceduce: "",
      additionalFormData: [],
      employeeId: "",
      badges: [],
      subBrandName: "",
      subBrandId: "",
      subBranchName: "",
      subBranchId: "",
      subBranchConsultantName: "",
      subBranchConsultantId: "",
      referredById: "",
      referredByName: "",
      isGeneralProfile: false,
      isSponsorProfile: false,
      appolousId: null
    },
    validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any, context) => {
        if (returnSubBrandList().length > 0 && addAction ? true : false) {
          return value;
        }
        else {
          return true;
        }
      }).nullable(),
      dob: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "DateOfBirth" }) })),
      raceId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Race" }) })).nullable(),
      title: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Title" }) })),
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
      consultantId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) })),
      countryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) })),
      stateId: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length > 0 && !value) {
            return false;
          }
          return true;
        }).nullable(),
      stateName: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        }),
      sourceId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MediaSource" }) })),
      gender: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Gender" }) })),
      firstName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FirstName" }) })),
      lastName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LastName" }) })),
      preferredName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PreferredName" }) })),
      identityType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) })),
      identityNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) })),
      mobileNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MobileNo" }) })),
      email: Yup.string().email().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Email" }) })),
      referredByName: Yup.string().test(
        "referredByName",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ReferredBy" }) }),
        (value: any, context) => {
          if (blnReferralType) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (valuesTemp.countryName === Constants.malaysia) {
        valuesTemp.mobileNo = appendCountryCode(valuesTemp.mobileNo, "6")
      }

      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }

      if (returnSubBrandList().length > 0 && editAction && values.badges.length < 1) {
        setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Badge" }) }))
        return;
      }

      let resultAdditionalFormData = convertAdditonalFormDataSubmit(addAction ? additionalFormFieldFormTypeList : (values.additionalFormData || []), valuesTemp);
      if (resultAdditionalFormData.status === Constants.failed) {
        setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: resultAdditionalFormData.data }))
        return;
      }

      try {
        if (addAction) {
          let resultAPI = await addCustomer(resultAdditionalFormData.data);
          if (resultAPI) {
            if (resultAPI.status === Constants.success) {
              setTimeout(() => {
                history.push(`/${RoutesList.customer}/view/${resultAPI.data}`);
                return;
              }, Constants.timeoutNavigation)
            }
          }
        } else {
          await updateCustomer(resultAdditionalFormData.data);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCustomerDetailAPI() {
      setLoading(true);
      let strAppointmentId = "";
      if (location) {
        if (location.state) {
          if (location.state.appointmentId) {
            strAppointmentId = location.state.appointmentId;
            setAppointmentId(strAppointmentId);
          }
        }
      }

      if (getBranchUser() && !strAppointmentId && addAction) {
        history.push(`/${RoutesList.notFound}`)
      }

      let aryAPI: any = [
        getStaticReference(),
        getRaceDropdown(),
        getBranchDropDown(),
        getCountryDropdown(),
        getMediaSourceDropdown(),
        getGeneralSettings()
      ];

      if (addAction) {
        aryAPI.push(getAdditionalFormFieldDropdown(Constants.customerForm));
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EmployeeAdvanceSearch" })}`, onFunction: () => { setShowEmployeeSearchModal(true) } })
      }
      if (id && !addAction) {
        aryAPI.push(getCustomerWithId(id));
      }
      if (strAppointmentId) {
        aryAPI.push(getCustomerWithAppointmentId(strAppointmentId))
      }
      let resultAPI = await Promise.all(aryAPI);
      if (strAppointmentId) {
        if (resultAPI[resultAPI.length - 1].preferredName) {
          let customerWithAppointmentDetail = _.cloneDeep(resultAPI[resultAPI.length - 1]);
          let aryKey = Object.keys(customerWithAppointmentDetail);
          aryKey.map((valueKeyTemp) => {
            if (!customerWithAppointmentDetail[valueKeyTemp]) {
              customerWithAppointmentDetail = { ...customerWithAppointmentDetail, [valueKeyTemp]: "" }
            }
          })
          let indexSource = _.findIndex(resultAPI[4], { id: customerWithAppointmentDetail.sourceId })
          if (indexSource > -1) {
            setBlnReferralType(resultAPI[4][indexSource].isReferral);
          }
          validation.setValues(customerWithAppointmentDetail);
          if (resultAPI[resultAPI.length - 1].countryId) {
            await getStateWithLoading(resultAPI[resultAPI.length - 1].countryId, false)
          }
          if (resultAPI[resultAPI.length - 1].branchId) {
            await getConsultantWithLoading(resultAPI[resultAPI.length - 1].branchId, false)
          }
          if (resultAPI[resultAPI.length - 1].raceId) {
            validation.setFieldValue("raceId", resultAPI[resultAPI.length - 1].raceId);
            validation.setFieldValue("raceName", resultAPI[resultAPI.length - 1].raceName);
          }
          else {
            validation.setFieldValue("raceId", "");
          }
          if (resultAPI[resultAPI.length - 1].subBrandId) {
            if (resultAPI[resultAPI.length - 1].subBrandId !== Constants.emptyId) {
              setDisabledSubBrandOption(true);
            }
          }
          validation.setFieldValue("title", "");
          validation.setFieldValue("dob", '1990-01-01');
        }
      }

      if (addAction && !strAppointmentId) {
        if (getBranchUser()) {
          validation.setFieldValue("branchId", getBranchId())
          validation.setFieldValue("branchName", getBranchName())
          await getConsultantWithLoading(getBranchId(), false)
        }
        else {
          setEmployeeDropdownList([]);
        }
      }

      if (id && !addAction) {
        if (resultAPI[6]) {
          if (resultAPI[6].branchId !== getBranchId()) {
            history.push(`/${RoutesList.notFound}`);
          }

          if (resultAPI[6].subBranchId) {
            let resultEmployeeSubBranchAPI = await getEmployeeDropdown({ branchId: resultAPI[6].subBranchId, isBranchStaff: true, isActive: true }, true);
            if (resultEmployeeSubBranchAPI) {
              setEmployeeDropdownForSubBranchList(resultEmployeeSubBranchAPI);
              setBlnSubBranch(true);
            }
          }
        }
      }

      setLoading(false);
    }

    if (addAction) {
      setCustomerDetail(undefined);
      setStateList([]);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCustomer], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCustomer], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomer], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateCustomer) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.customer}/edit/${id}`) } })
    }

    fetchCustomerDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCustomerDetail(undefined);
      }
      else {
        setAdditionalFormFieldFormTypeList([]);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
        setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(() => {
    if (id && !addAction) {
      if (customerDetail) {
        if (customerDetail.countryId) {
          getStateWithLoading(customerDetail.countryId, false)
        }
        if (customerDetail.branchId) {
          getConsultantWithLoading(customerDetail.branchId, false)
        }
        if (customerDetail.additionalFormData) {
          let objTemp: any = convertAdditionalFormDataRead(customerDetail.additionalFormData);
          validation.setValues((values) => ({
            ...customerDetail,
            ...objTemp
          }))
        }

        let arySelectedBadges: any = [];
        if (customerDetail.badges) {
          arySelectedBadges = customerDetail.badges.map((valueBadges, index) => ({
            ...valueBadges,
            label: valueBadges.icon,
            value: valueBadges.id
          }))
        }
        setSelectedBagdes(arySelectedBadges);
      }
    }
  }, [customerDetail]);

  const getStateWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        stateId: "",
        stateName: ""
      }))
    }
    setLoadingState(false);
  };

  const getConsultantWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingConsultant(true);
    await getEmployeeDropdown({
      branchId: id,
      isBranchStaff: true,
      isActive: true
    });
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        consultantId: "",
        consultantName: ""
      }))
    }
    setLoadingConsultant(false);
  };

  const getEmployeeInfo = async (searchObj: EmployeeAdvanceSearchObject) => {
    setLoadingSearchEmployee(true);
    if (!searchObj.searchType) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SearchType" }) }))
    }
    else if (!searchObj.searchValue) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SearchValue" }) }))
    }
    else {
      let resultEmployeeForCustomerInfo: any = await getEmployeeForCustomerInfo(searchObj.searchType === Constants.identityNo ? searchObj.searchValue : "", searchObj.searchType === Constants.employeeNo ? searchObj.searchValue : "")
      let blnFound = false;
      if (resultEmployeeForCustomerInfo.status === Constants.success) {
        if (resultEmployeeForCustomerInfo.data) {
          if (resultEmployeeForCustomerInfo.data.existed) {
            let resultEmployeeForCustomerInfoTemp = _.cloneDeep(resultEmployeeForCustomerInfo.data)
            resultEmployeeForCustomerInfoTemp = { ...resultEmployeeForCustomerInfoTemp, employeeId: resultEmployeeForCustomerInfoTemp.id }
            delete resultEmployeeForCustomerInfoTemp[id];

            blnFound = true;
            validation.setValues((value) => ({
              ...value,
              ...resultEmployeeForCustomerInfoTemp,
            }))
            await getStateWithLoading(resultEmployeeForCustomerInfo.data.countryId, false)
            setShowCustomerDropdown(false);
            setTimeout(() => {
              setShowCustomerDropdown(true);
            }, 100)
          }
        }
      }
      if (!blnFound) {
        setErrorMessage(intl.formatMessage({ id: "RecordNotFound" }))
      }
      else {
        setShowEmployeeSearchModal(false);
      }
    }
    setLoadingSearchEmployee(false);
  }

  const onSelectCustomerCriteriaSearch = (row) => {
    validation.setValues((values) => ({
      ...values,
      referredById: row.id,
      referredByName: row.preferredName
    }))

    setCustomerCriteriaSearchModal(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Consultant" }) })}
                  h4Title
                />
                {
                  returnSubBrandList().length > 0
                    ?
                    addAction
                      ?
                      <DropDownWithTitle
                        name={"subBrandId"}
                        className="mt-4"
                        title={intl.formatMessage({ id: "SubBrand" })}
                        specifyReturnFieldName={[{ "field": "subBrandName", "value": "name" }, { "field": "subBrandId", "value": "id" }]}
                        labelField={"name"}
                        valueField={"id"}
                        options={returnSubBrandList()}
                        disabled={disabledFieldInput || viewAction || disabledSubBrandOption}
                        validationRequired={true}
                        initialLabel={validation.values.subBrandName}
                        initialValue={validation.values.subBrandId}
                        validation={validation} />
                      :
                      <DropDownWithTitleMultiSelect
                        name={"badges"}
                        className="margin-top-20"
                        title={intl.formatMessage({ id: "Badges" })}
                        specifyReturnFieldName={[{ "field": "badges", "value": "id" }]}
                        returnAllField={true}
                        returnFieldWithLabel={true}
                        blnDisplayImage={true}
                        labelField={"icon"}
                        valueField={"id"}
                        options={returnSubBrandList()}
                        disabled={disabledFieldInput || viewAction || getBranchUser()}
                        initialValue={selectedBagdes}
                        validationRequired={true}
                        validation={validation} />
                    :
                    <div />
                }
                <Row className={returnSubBrandList().length > 0 ? "" : "margin-top-20"}>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"branchId"}
                        title={intl.formatMessage({ id: "Branch" })}
                        specifyReturnFieldName={[{ "field": "branchName", "value": "name" }, { "field": "branchId", "value": "id" }]}
                        labelField={"name"}
                        valueField={"id"}
                        options={branchDropDownList}
                        disabled={disabledFieldInput || viewAction || loadingConsultant || editAction}
                        validationRequired={true}
                        initialLabel={validation.values.branchName}
                        initialValue={validation.values.branchId}
                        validation={validation}
                        onChangeFunction={
                          getConsultantWithLoading
                        } />
                    }
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      loadingConsultant || !showCustomerDropdown ? (
                        <Loading />
                      ) : (
                        <DropDownWithTitle
                          name={"consultantId"}
                          title={intl.formatMessage({ id: "Consultant" })}
                          specifyReturnFieldName={[{ "field": "consultantName", "value": "preferredName" }, { "field": "consultantId", "value": "id" }]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={employeeDropdownList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={validation.values.consultantName}
                          initialValue={validation.values.consultantId}
                          validationRequired={true}
                          validation={validation} />
                      )
                    }
                  </Col>
                </Row>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  className="mt-4"
                  title={`${intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Personal" }) })} ${validation.values.employeeId ? `(${intl.formatMessage({ id: "Employee" })})` : ""}`}
                  h4Title
                />
                {
                  !addAction &&
                  <GeneralInput
                    title={intl.formatMessage({ id: "CustomerNo" })}
                    className="mt-4 mb-3"
                    name="customerNo"
                    type="text"
                    validationRequired={true}
                    disabled={true}
                    validation={validation}
                  />
                }
                {
                  !addAction
                  &&
                  validation.values.appolousId !== null
                  &&
                  blnShowAppolous
                  &&
                  <GeneralInputWithClickableUI
                    title={intl.formatMessage({ id: "ViewAppolous" })}
                    className="mb-3"
                    name="appolousId"
                    type="number"
                    disabled={true}
                    validation={validation}
                    onClickFunction={() => {
                      window.open(`https://app.appolous.com/clients/${validation.values.appolousId}/edit`)
                    }} />
                }
                <Row className={addAction ? "mt-4" : ""}>
                  <Col xs={"12"} md={"4"} xl={"4"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "FirstName" })}
                      name="firstName"
                      type="text"
                      validationRequired={
                        true
                      }
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"4"} xl={"4"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "MiddleName" })}
                      name="middleName"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"4"} xl={"4"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "LastName" })}
                      name="lastName"
                      type="text"
                      validationRequired={
                        true
                      }
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                </Row>
                <GeneralInput
                  title={intl.formatMessage({ id: "PreferredName" })}
                  name="preferredName"
                  type="text"
                  validationRequired={true}
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"raceId"}
                        title={intl.formatMessage({ id: "Race" })}
                        specifyReturnFieldName={[
                          {
                            field: "raceId",
                            value: "id",
                          },
                        ]}
                        labelField={"name"}
                        valueField={"id"}
                        options={raceDropdownList}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialLabel={validation.values.raceName}
                        initialValue={validation.values.raceId}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"title"}
                        title={intl.formatMessage({ id: "Title" })}
                        specifyReturnFieldName={[
                          {
                            field: "title",
                            value: "displayValue",
                          },
                        ]}
                        labelField={
                          "displayValue"
                        }
                        valueField={
                          "displayValue"
                        }
                        blnValueWithNewSpace={true}
                        options={titleType}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialValue={validation.values.title}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"identityType"}
                        title={intl.formatMessage({ id: "IdentityType" })}
                        specifyReturnFieldName={[
                          {
                            field: "identityType",
                            value: "displayValue",
                          },
                        ]}
                        labelField={
                          "displayValue"
                        }
                        valueField={
                          "displayValue"
                        }
                        blnValueWithNewSpace={true}
                        options={identityType}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialValue={validation.values.identityType}
                        onChangeFunction={() => {
                          validation.setFieldValue("identityNo", "");
                        }}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "IdentityNo" })}
                      name="identityNo"
                      type="text"
                      blnAlphaNumberic={validation.values.identityType === Constants.passport ? false : true}
                      validationRequired={
                        true
                      }
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DateOfBirth" })}
                      name="dob"
                      type="date"
                      backendMaxDate={true}
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"gender"}
                        title={intl.formatMessage({ id: "Gender" })}
                        specifyReturnFieldName={[
                          {
                            field: "gender",
                            value: "displayValue",
                          },
                        ]}
                        labelField={
                          "displayValue"
                        }
                        valueField={
                          "displayValue"
                        }
                        blnValueWithNewSpace={true}
                        options={genderType}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialValue={validation.values.gender}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "MobileNo" })}
                      name="mobileNo"
                      type="number"
                      blnNumberOnly={true}
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "EmergencyContact" })}
                      name="emergencyContact"
                      type="number"
                      blnNumberOnly={true}
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Email" })}
                    name="email"
                    type="text"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </Row>
              </Col>
              <Col xl={6} className={`${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-8" : ""}`}>
                {
                  blnSubBranch
                  &&
                  <>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "SubBranch" }) })}
                      h4Title />
                    <Row className={""}>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        {
                          showCustomerDropdown
                          &&
                          <DropDownWithTitle
                            name={"subBranchId"}
                            title={intl.formatMessage({ id: "SubBranch" })}
                            specifyReturnFieldName={[{ "field": "subBranchName", "value": "name" }, { "field": "subBranchId", "value": "id" }]}
                            labelField={"name"}
                            valueField={"id"}
                            options={branchDropDownList}
                            disabled={true}
                            validationRequired={true}
                            initialLabel={validation.values.subBranchName}
                            initialValue={validation.values.subBranchId}
                            validation={validation} />
                        }
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        {
                          !showCustomerDropdown ? (
                            <Loading />
                          ) : (
                            <DropDownWithTitle
                              name={"subBranchConsultantId"}
                              title={intl.formatMessage({ id: "SubBranchConsultant" })}
                              specifyReturnFieldName={[{ "field": "subBranchConsultantName", "value": "preferredName" }, { "field": "subBranchConsultantId", "value": "id" }]}
                              labelField={"preferredName"}
                              valueField={"id"}
                              options={employeeDropdownForSubBranchList}
                              disabled={disabledFieldInput || viewAction}
                              initialLabel={validation.values.subBranchConsultantName}
                              initialValue={validation.values.subBranchConsultantId}
                              validationRequired={true}
                              validation={validation} />
                          )
                        }
                      </Col>
                    </Row>
                  </>
                }
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Address" }) })}
                  className={blnSubBranch ? "mt-4" : ""}
                  h4Title />
                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine1" })}
                  name="addressLine1"
                  type="text"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine2" })}
                  name="addressLine2"
                  type="text"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "City" })}
                      name="city"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PostCode" })}
                      name="postcode"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      showCustomerDropdown
                      &&
                      <DropDownWithTitle
                        name={"countryId"}
                        title={intl.formatMessage({ id: "Country" })}
                        specifyReturnFieldName={[
                          {
                            field: "countryId",
                            value: "id",
                          },
                          {
                            field: "countryName",
                            value: "name",
                          },
                        ]}
                        labelField={"name"}
                        valueField={"id"}
                        options={countryDropdownList}
                        disabled={
                          disabledFieldInput ||
                          viewAction ||
                          loadingState
                        }
                        onChangeFunction={
                          getStateWithLoading
                        }
                        initialLabel={validation.values.countryName}
                        initialValue={validation.values.countryId}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {loadingState ? (
                      <Loading />
                    ) : (
                      stateList.length > 0 && showCustomerDropdown
                        ?
                        <DropDownWithTitle
                          name={"stateId"}
                          title={intl.formatMessage({ id: "State" })}
                          specifyReturnFieldName={[
                            {
                              field: "stateId",
                              value: "id",
                            },
                            {
                              field: "stateName",
                              value: "name",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={stateList}
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          initialLabel={validation.values.stateName}
                          initialValue={validation.values.stateId}
                          validationRequired={stateList.length > 0 ? true : false}
                          validation={
                            validation
                          }
                        />
                        :
                        <GeneralInput
                          title={intl.formatMessage({ id: "StateName" })}
                          name="stateName"
                          type="text"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validationRequired={true}
                          validation={validation}
                        />
                    )}
                  </Col>
                </Row>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Occupation" }) })}
                  className="mt-4"
                  h4Title />
                <GeneralInput
                  title={intl.formatMessage({ id: "Occupation" })}
                  name="occupation"
                  type="text"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  validation={validation}
                />
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Other" }) })}
                  className="mt-4"
                  h4Title />
                {
                  appointmentId && addAction && blnPermissionEditCustomerMediaSource && !blnEditMediaSourceWithAppointmentId
                    ?
                    <GeneralInput
                      name={"mediaSource"}
                      title={intl.formatMessage({ id: "MediaSource" })}
                      classNameInput="width-100-percentage"
                      type="text"
                      disabled={true}
                      validation={validation}
                      childrenUI={
                        <MyButton
                          type="button"
                          content={"Edit"}
                          class="btn btn-success margin-left-8"
                          onClick={() => {
                            setBlnEditMediaSourceWithAppointmentId(true);
                          }}
                          loading={loading}
                          disable={disabledFieldInput}
                        />
                      } />
                    :
                    <DropDownWithTitle
                      name={"sourceId"}
                      title={intl.formatMessage({ id: "MediaSource" })}
                      specifyReturnFieldName={[{ "field": "mediaSource", "value": "name" }, { "field": "sourceId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={mediaSourceDropdownList}
                      disabled={disabledFieldInput || viewAction || (!blnPermissionEditCustomerMediaSource && appointmentId ? true : false) || editAction}
                      validationRequired={true}
                      initialLabel={validation.values.mediaSource}
                      initialValue={validation.values.sourceId}
                      onChangeAllField={true}
                      onChangeFunction={(valueMediaSource) => {
                        setBlnReferralType(valueMediaSource.isReferral);
                        validation.setFieldValue("referredById", "");
                        validation.setFieldValue("referredByName", "");
                      }}
                      validation={validation} />
                }
                {
                  (
                    (
                      validation.values.referredByName
                      &&
                      (
                        (appointmentId && !blnEditMediaSourceWithAppointmentId)
                        ||
                        (!appointmentId && (editAction || viewAction))
                      )
                    )
                    ||
                    (addAction && !blnEditMediaSourceWithAppointmentId && !validation.values.referredByName)
                  )
                  &&
                  <GeneralInput
                    title={intl.formatMessage({ id: "ReferredBy" })}
                    name="referredByName"
                    type="text"
                    disabled={true}
                    validation={validation}
                  />
                }
                {
                  blnReferralType && appointmentId && blnEditMediaSourceWithAppointmentId
                    ?
                    <GeneralInput
                      name={"referredByName"}
                      title={intl.formatMessage({ id: "ReferredBy" })}
                      classNameInput="width-100-percentage"
                      type="text"
                      disabled={true}
                      validation={validation}
                      validationRequired={true}
                      validationRequiredForce={!disabledFieldInput}
                      childrenUI={
                        <MyButton
                          type="button"
                          content={"Select"}
                          class="btn btn-primary margin-left-8"
                          onClick={() => {
                            setCustomerCriteriaSearchModal(true);
                          }}
                          loading={disabledFieldInput}
                          disable={disabledFieldInput}
                        />
                      } />
                    :
                    null
                }
                {
                  !getBranchUser()
                  &&
                  <Row>
                    <div className="flex-direction-row flex-wrap">
                      <GeneralInput
                        title={intl.formatMessage({ id: "GeneralProfile" })}
                        name="isGeneralProfile"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction || getBranchUser()}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                      <GeneralInput
                        title={intl.formatMessage({ id: "SponsoredProfile" })}
                        name="isSponsorProfile"
                        className="margin-left-36"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction || getBranchUser()}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                    </div>
                  </Row>
                }
                <AdditionalFormField
                  additionalFormFieldFormTypeList={addAction ? additionalFormFieldFormTypeList : (validation.values.additionalFormData || [])}
                  disabledFieldInput={disabledFieldInput}
                  addAction={addAction}
                  viewAction={viewAction}
                  validation={validation} />
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        showEmployeeSearchModal
        &&
        <EmployeeAdvanceSearch
          title={intl.formatMessage({ id: "EmployeeAdvanceSearch" })}
          blnShow={showEmployeeSearchModal}
          setModal={setShowEmployeeSearchModal}
          onSelectFunction={getEmployeeInfo}
          localLoading={loadingSearchEmployee}
          setLocalLoading={setLoadingSearchEmployee} />
      }
      {
        customerCriteriaSearchModal
        &&
        <ReferredByCustomerSearchModal
          blnShow={customerCriteriaSearchModal}
          setModal={setCustomerCriteriaSearchModal}
          onSelectFunction={onSelectCustomerCriteriaSearch} />
      }
    </div>
  );
};

export default observer(CustomerDetail);
