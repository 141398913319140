import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, appointmentStatusColor, newSpaceBeforeCapitalLetter, getBrandId, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, convertArrayToStringDisplay, getBranchName, getBranchId, returnSubBrandList, convertParamsUrlToArray, compareParamsArrayWithDefaultArray, firstDayOfYearToLastDayOfYear, AddOptionsValueField, firstDayOfFiveYearAgoToLastDayOfCurrentYear } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { EmployeeListObject } from "../../../app/models/employee";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { useLocation } from "react-router-dom";
import CustomerSearchModal from "../../CustomerPurchase/Modal/CustomerSearchModal";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { SubBrandObject } from "../../../app/models/brand";
import { Link } from "react-router-dom"

const AppointmentDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppointmentStore, mediaSourceStore, branchStore, staticReferenceStore, employeeStore, serviceTreatmentStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { appointmentDetailsList, getAppointmentDetailList, setAppointmentDetailsList, exportAppointmentDetail } = reportAppointmentStore;
  const { mediaSourceDropdownList, getMediaSourceDropdown } = mediaSourceStore;
  const { branchDropDownList, isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { appointmentStatusType, appointmentSourceType, getStaticReferenceWithType } = staticReferenceStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { getEmployeeDropdown } = employeeStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [appointmentStatusList, setAppointmentStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfFiveYearAgoToLastDayOfCurrentYear());
  const [selectedSubmissionDateRange, setSelectedSubmissionDateRange] = useState<DateRangeInterface | undefined>(firstDayOfFiveYearAgoToLastDayOfCurrentYear());
  const [consultantList, setConsultantList] = useState<EmployeeListObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedConsultantName, setSelectedConsultantName] = useState(All);
  const [selectedConsultantId, setSelectedConsultantId] = useState(" ");
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [selectedSourceTypeOptions, setSelectedSourceTypeOptions] = useState<any>([]);
  const [selectedAppointmentStatusesOptions, setSelectedAppointmentStatusesOptions] = useState<string[]>([]);

  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAppointmentReport = checkPermission([PermissionConstants.ExportAppointmentReport]);
  const blnPermissionManageAppointment = checkPermission([PermissionConstants.ManageAppointment]);

  const paramsSearch = useLocation().search;
  const sourceParams = new URLSearchParams(paramsSearch).get('mediaSourceId');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const statusParams = new URLSearchParams(paramsSearch).get('status');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId');
  const sourceTypesParams = convertParamsUrlToArray("sourceTypes", paramsSearch);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      contactNo: "",
      serviceTreatmentIds: [],
      sourceTypes: [],
      appointmentStatusIds: [],
      appointmentStatusNames: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    
    async function fetchAppointmentListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let blnParams = (sourceParams || branchParams || statusParams || subBrandParams || sourceTypesParams.length > 0) && fromDateParams && toDateParams;
      let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
        getStaticReferenceWithType(Constants.appointmentStatusType),
        getStaticReferenceWithType(Constants.appointmentSourceType),
        getServiceTreatmentDropdown(),
        getEmployeeDropdown({ brandId: getBrandId() }),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"))
      setAppointmentStatusList(resultAPI[2]);

      if (resultAPI[5] && resultAPI[5].length > 0) {
        let consultantListTemp: any = _.cloneDeep(resultAPI[5]);
        consultantListTemp = consultantListTemp.map((value) => ({ label: value.preferredName, value: value.id }))
        consultantListTemp.splice(0, 0, { label: All, value: " " });
        setConsultantList(consultantListTemp)
      }

      if (blnParams) {
        setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })

        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        let mediaSourcesIdTemp: number | string = " ";
        if (sourceParams) {
          let mediaSourcesListTemp: any = _.cloneDeep(resultAPI[1]);
          mediaSourcesListTemp.map((value) => {
            if (sourceParams == value.id) {
              setSelectedMediaSourceName(value.name)
              setSelectedMediaSourceId(value.id);
              mediaSourcesIdTemp = value.id;
            }
          })
        }

        let appointmentStatusIdsTemp: string[] = [];
        if (statusParams) {
          let appointmentStatusTypeTemp: any = _.cloneDeep(resultAPI[2]);
          appointmentStatusTypeTemp.map((value) => {
            if (statusParams == value.displayValue) {
              validation.setFieldValue("appointmentStatusIds", [value.key]);
              validation.setFieldValue("appointmentStatusNames", [value.displayValue]);
              appointmentStatusIdsTemp = [value.key];
              setSelectedAppointmentStatusesOptions(AddOptionsValueField([value], "displayValue", "key"))
            }
          })
        }

        let subBrandIdTemp: number | string = " ";
        if (subBrandParams) {
          let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
          subBrandListTemp.map((value) => {
            if (subBrandParams == value.id) {
              setSelectedSubBrandName(value.name)
              setSelectedSubBrandId(value.id);
              subBrandIdTemp = value.id;
            }
          })
        }

        let sourceTypesIdTemp: string[] = [];
        if(sourceTypesParams){
          let sourceTypesTemp = compareParamsArrayWithDefaultArray(sourceTypesParams, resultAPI[3], "displayValue", "displayValue", "key");
          setSelectedSourceTypeOptions(sourceTypesTemp);
          sourceTypesIdTemp = sourceTypesTemp.map((valueSourceTypeTemp => valueSourceTypeTemp.key))
          validation.setFieldValue("sourceTypes", sourceTypesIdTemp)
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.appointmentDetails}` });
        await fetchAppointmentDetailApi(false, branchIdTemp, mediaSourcesIdTemp, { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" }, appointmentStatusIdsTemp, subBrandIdTemp, sourceTypesIdTemp);
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointmentReport], true)) {
      return;
    }

    fetchAppointmentListAPI();

    return (() => {
      setAppointmentDetailsList([]);
    })
  }, []);

  async function fetchAppointmentDetailApi(blnDropDown: boolean = false, branchIdTemp?: string, mediaSourceTemp?: string, dateRange?: DateRangeInterface, appointmentStatusIdsTemp?: string[], subBrandIdTemp?: string, sourceTypesIdTemp?: string[]) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getAppointmentDetailList({
      pageNumber: 1,
      pageSize: pageSize,
      mediaSourceId: mediaSourceTemp || selectedMediaSourceId,
      branchId: branchIdTemp || selectedBranchId,
      dateRange: dateRange || selectedDateRange,
      fromSubmissionDate: selectedSubmissionDateRange?.fromDate,
      toSubmissionDate: selectedSubmissionDateRange?.toDate,
      statuses: appointmentStatusIdsTemp || validation.values.appointmentStatusIds,
      consultantId: selectedConsultantId,
      name: validation.values.name,
      contactNo: validation.values.contactNo,
      sourceTypes: sourceTypesIdTemp || validation.values.sourceTypes,
      serviceTreatmentIds: validation.values.serviceTreatmentIds,
      subBrandId: subBrandIdTemp || selectedSubBrandId,
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportAppointmentDetailApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    await exportAppointmentDetail({
      pageNumber: 1,
      pageSize: pageSize,
      mediaSourceId: selectedMediaSourceId,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      fromSubmissionDate: selectedSubmissionDateRange?.fromDate,
      toSubmissionDate: selectedSubmissionDateRange?.toDate,
      statuses: validation.values.appointmentStatusIds,
      consultantId: selectedConsultantId,
      name: validation.values.name,
      contactNo: validation.values.contactNo,
      sourceTypes: validation.values.sourceTypes,
      serviceTreatmentIds: validation.values.serviceTreatmentIds,
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onSelectSubmissonDateRange = (objDateRange: any) => {
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedSubmissionDateRange(objDateRange);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "",
      text: intl.formatMessage({ id: "AppointmentDateTime" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageAppointment
            ?
            <Link to={row.appointmentId ? `/${RoutesList.appointment}/view/${row.appointmentId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <div className="">
                {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
              </div>
              <div className="small-text">
                {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)} - {moment(row.appointEndDateTime).format(Constants.displayTimeFormat)}
              </div>
            </Link>
            :
            <div>
              <div className="">
                {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
              </div>
              <div className="small-text">
                {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)} - {moment(row.appointEndDateTime).format(Constants.displayTimeFormat)}
              </div>
            </div>
          }          
       </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.customerName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "CustomerContactNo" }).toUpperCase(),
      hidden: true,
      formatter: (cellContent, row) => (
        <div className="" style={{width: "120px"}}>
          {row.customerContactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "leadContactNo",
      text: intl.formatMessage({ id: "LeadContactNo" }).toUpperCase(),
      hidden: true,
      formatter: (cellContent, row) => (
        <div className="" style={{width: "120px"}}>
          {row.leadContactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {convertArrayToStringDisplay(row.serviceTreatmentNames, false , true) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.consultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSourceName",
      text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.mediaSourceName) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
    },
    {
      dataField: "appointmentSourceType",
      text: intl.formatMessage({ id: "SourceType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.appointmentSourceType) || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor} />
      ),
    },
  ];

  const displayDateRangeFilter = () => {
    return (
      <>
        <DoubleColumnRowParent>
          <GeneralDateRange
            title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "AppointmentDate"})})}
            name={"DateRange"}
            disabled={localLoading || loading}
            onChangeFunction={onSelectDateRange}
            initialValue={selectedDateRange}
            validationRequired={true} />
        </DoubleColumnRowParent>
        <DoubleColumnRowParent>
          <GeneralDateRange
            title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
            name={"SubmissionDateRange"}
            disabled={localLoading || loading}
            validationRequired={true}
            onChangeFunction={onSelectSubmissonDateRange}
            initialValue={selectedSubmissionDateRange} />
        </DoubleColumnRowParent>
      </>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppointmentDetails" }) })}
        title={intl.formatMessage({ id: "AppointmentDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppointmentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              exportAppointmentDetailApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Appointment-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            {/* <Row>
              <Col xl={'4'} md={'6'} xs={'6'}>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
                }
              </Col>
              <Col xl={'4'} md={'6'} xs={'6'}>
                <MyButton
                  type="button"
                  class="btn btn-primary margin-top-28 "
                  content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </Col>
            </Row> */}
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Consultant" })}
                  name={"consultantId"}
                  options={consultantList}
                  initialLabel={selectedConsultantName}
                  initialValue={selectedConsultantId}
                  setFieldLabel={setSelectedConsultantName}
                  setFieldValue={setSelectedConsultantId}
                  disabled={localLoading || loading}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              {subBrandListFinal.length < 1 && displayDateRangeFilter()}
            </Row>
            {subBrandListFinal.length > 0 && <Row>{displayDateRangeFilter()}</Row>}
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "MediaSource" })}
                  name="mediaSource"
                  styles={{}}
                  options={mediaSourceListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedMediaSourceName}
                  initialValue={selectedMediaSourceId}
                  setFieldLabel={setSelectedMediaSourceName}
                  setFieldValue={setSelectedMediaSourceId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <DropDownWithTitleMultiSelect
                  name="appointmentStatuses"
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Appointment" }) })}
                  specifyReturnFieldName={[{ "field": "appointmentStatusIds", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={appointmentStatusList}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  initialValue={selectedAppointmentStatusesOptions}
                  validationRequired={false}
                  validation={validation}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent >
                <DropDownWithTitleMultiSelect
                  name="appointmentSourceType"
                  title={intl.formatMessage({ id: "AppointmentSourceType" })}
                  specifyReturnFieldName={[{ "field": "sourceTypes", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={appointmentSourceType}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  initialValue={selectedSourceTypeOptions}
                  validationRequired={false}
                  validation={validation}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "Name" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "ContactNo" })}
                  name="contactNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "ContactNo" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <DropDownWithTitleMultiSelect
                  name={"serviceTreatmentIds"}
                  title={intl.formatMessage({ id: "ServiceTreatments" })}
                  specifyReturnFieldName={[{ "field": "serviceTreatmentIds", "value": "id" }, { "field": "serviceTreatmentNames", "value": "name" }]}
                  returnFieldWithLabel={false}
                  labelField={"name"}
                  valueField={"id"}
                  options={serviceTreatmentDropdownList}
                  disabled={localLoading || loading}
                  validationRequired={false}
                  validation={validation} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchAppointmentDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentColumns}
              keyField={"randomId"}
              data={appointmentDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppointmentDetails" }) })}
              objSorted={defaultSorted}
              blnSupportColumnFilter={true}
              requestAPI={getAppointmentDetailList}
              branchId={selectedBranchId}
              mediaSourceId={selectedMediaSourceId}
              consultantId={selectedConsultantId}
              statuses={validation.values.appointmentStatusIds}
              customerName={validation.values.name}
              dateRange={selectedDateRange}
              fromSubmissionDate={selectedSubmissionDateRange?.fromDate}
              toSubmissionDate={selectedSubmissionDateRange?.toDate}
              name={validation.values.name}
              contactNo={validation.values.contactNo}
              sourceTypes={validation.values.sourceTypes}
              serviceTreatmentIds={validation.values.serviceTreatmentIds}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect}
          blnHideOtherBranch={true} />
      }
    </div>
  );
}

export default observer(AppointmentDetails);
