import { useEffect, useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  appointmentId: any;
  appointmentStatusUpdateFunction: Function;
}

export default function NotShowModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingNotShow, setLoadingNotShow] = useState(false);
  const disabledFieldInput = loadingNotShow || Boolean(successMessage); 
  const defaultNotShowDetails = {
    id: props.appointmentId,
    appointmentStatus: Constants.notShow,
    remark: ""
  }

  const [notShowDetails, setNotShowDetails] = useState(defaultNotShowDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      remark: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setNotShowDetails(defaultNotShowDetails);
    props.setModal(false);
  }

  const onClickNotShow = async (appointmentDetailTemp: any) => {
    setLoadingNotShow(true);
    await props.appointmentStatusUpdateFunction(appointmentDetailTemp);
    setLoadingNotShow(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ReasonForNotShow" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }

        </div>
        <div className="modal-body">
          <GeneralTextAreaForModal
            title={""}
            name="remark"
            className={errorState.remark ? "mb-0" : "mb-3"}
            row={4}
            validationRequired={true}
            field={notShowDetails}
            setFieldValue={setNotShowDetails}
            onChangeFunction={() => { clearErrorState("remark") }}
            disabled={disabledFieldInput} />
          {errorState.remark ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}</FormFeedback>
          ) : null}
          <div className="margin-top-14 ">
            {
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: `Submit` })}
                onClick={() => {
                  let errorStateTemp = {
                    remark: false,
                  };
                  let blnErrorFound = false;
                  if (!notShowDetails.remark) {
                    errorStateTemp.remark = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickNotShow(notShowDetails)
                  }
                }}
                loading={loadingNotShow}
                disable={loadingNotShow}
              />
            }
          </div>
        </div>
      </Modal>
  )
}