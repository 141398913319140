import React from 'react'
import HeaderMenu from './HeaderMenu'
import SidebarMenu from './SidebarMenu'

interface IProps {
    children: React.ComponentType<any>;
    path: string;
}

export default function VerticalLayout(props: IProps) {
    return (
        <div>
            {/* <div id="preloader">
                <div id="status">
                <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                </div>
                </div>
            </div> */}

            <div id="layout-wrapper">
                <HeaderMenu path={props.path}/>
                <SidebarMenu />
                <div className="main-content">{props.children}</div>
                {/* <Footer /> */}
            </div>
        </div>
    )
}
