import { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Label
} from "reactstrap";
import _ from 'lodash';
import { AddOptionsValueField, blnDecimal, convertAdditonalFormDataSubmit, returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from '../../../app/components/form/GeneralInputForModal';
import { CustomerOrderDetailObject } from "../../../app/models/customerOrder";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { EmployeeListObject } from "../../../app/models/employee";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { Constants } from "../../../app/constants/Constants";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { AdditionalFormFieldWithFormType } from "../../../app/models/additionalFormField";
import AdditionalFormField from "../../../app/components/form/AdditionalFormField";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerOrderDetail: CustomerOrderDetailObject | undefined;
  setSelectedCustomerOrderDetail: Function;
  discountType: StaticReferenceDataObject[];
  employeeList: EmployeeListObject[];
  calulateTotalPrice: Function;
  validation: any;
  additionalFormFieldFormTypeList?: AdditionalFormFieldWithFormType[];
  maxPrepaidPackageInSalesOrder: number;
}

export default function CustomerOrderDetailModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage
  } = commonStore;
  const optionsDiscountType = AddOptionsValueField(props.discountType, "displayValue", "displayValue");
  const optionsEmployeeList = AddOptionsValueField(props.employeeList, "preferredName", "id");
  const blnService = props?.selectedCustomerOrderDetail?.purchaseType == Constants.service;
  const finalPrice = props.calulateTotalPrice(props.selectedCustomerOrderDetail?.count, props.selectedCustomerOrderDetail?.price, props.selectedCustomerOrderDetail?.discountType, props.selectedCustomerOrderDetail?.discountValue).toFixed(2);
  const blnPromoItem = props.selectedCustomerOrderDetail?.promotionItemType;
  const blnPackage = props?.selectedCustomerOrderDetail?.purchaseType == Constants.package;

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedCustomerOrderDetail(undefined);
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  const onChangeEmployee = (e: any) => {
    let selectedCustomerOrderDetail = _.cloneDeep(props.selectedCustomerOrderDetail)
    if (selectedCustomerOrderDetail) {
      selectedCustomerOrderDetail.serviceByName = e.label;
      selectedCustomerOrderDetail.serviceById = e.value;
      if (selectedCustomerOrderDetail.validationRequired) {
        delete selectedCustomerOrderDetail["validationRequired"];
      }
      props.setSelectedCustomerOrderDetail(selectedCustomerOrderDetail);
    }
  }

  const onChangeDiscountType = (e: any) => {
    let selectedCustomerOrderDetail = _.cloneDeep(props.selectedCustomerOrderDetail)
    if (selectedCustomerOrderDetail) {
      selectedCustomerOrderDetail.discountType = e.value;
      selectedCustomerOrderDetail.discountValue = 0;
      props.setSelectedCustomerOrderDetail(selectedCustomerOrderDetail);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        centered
        onBlur={() => {
          dismissMessage();
        }}>
        <div className="modal-header">
          <h5 className="mb-0 modal-title margin-top-0">
            {props.selectedCustomerOrderDetail?.itemName}
            {blnPromoItem ? <span className="modal-title margin-top-0 margin-left-4" style={{color: Constants.pink}}>{`(${props.selectedCustomerOrderDetail?.promotionCode})`}</span> : ""}
          </h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <GeneralInputForModal
            title={intl.formatMessage({ id: "Quantity" })}
            name="count"
            className="mb-0"
            type="number"
            numberMaxValue={props.selectedCustomerOrderDetail?.availableQuantity}
            field={props.selectedCustomerOrderDetail}
            setFieldValue={props.setSelectedCustomerOrderDetail}
            validationRequired={true}
          />
          <div className="mb-3">
            <span style={{ fontWeight: 400, fontSize: '13px', color: 'grey' }}>
              {
                props.selectedCustomerOrderDetail?.availableQuantity
                ?
                `* ${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${props.selectedCustomerOrderDetail?.availableQuantity}`
                :
                ""
              }
            </span>
          </div>
          <Row>
            {
              blnPromoItem
              &&
              <Col xl="6">
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "OriginalPrice" })}
                  name="originalPrice"
                  type="number"
                  inputGroup={true}
                  inputGroupText={returnCurrency()}
                  disabled={true}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerOrderDetail}
                />
              </Col>
            }
            <Col xl={props.selectedCustomerOrderDetail?.isCreditPackage ? "12" : "6"}>
              <GeneralInputForModal
                title={blnPromoItem ? intl.formatMessage({ id: "DiscountedPrice" }) : intl.formatMessage({ id: "Price" })}
                name="price"
                type="number"
                inputGroup={true}
                inputGroupText={returnCurrency()}
                disabled={true}
                field={props.selectedCustomerOrderDetail}
                setFieldValue={props.setSelectedCustomerOrderDetail}
              />
            </Col>
            {
              !blnPromoItem
              &&
              !props.selectedCustomerOrderDetail?.isCreditPackage
              &&
              <Col xl="6">
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "FloorPrice" })}
                  name="floorPrice"
                  type="number"
                  inputGroup={true}
                  inputGroupText={returnCurrency()}
                  disabled={true}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerOrderDetail}
                />
              </Col>
            }
          </Row>
          {
            !blnPromoItem
            &&
            !props.selectedCustomerOrderDetail?.isCreditPackage
            &&
            <Row>
              <Col xl="6">
                <Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "DiscountType" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className="mb-3"
                  options={optionsDiscountType}
                  blnValueWithNewSpace={true}
                  onChange={onChangeDiscountType}
                  disabled={props.selectedCustomerOrderDetail?.promotionCode ? true : false}
                  initialLabel={props.selectedCustomerOrderDetail?.discountType}
                  initialValue={props.selectedCustomerOrderDetail?.discountType}
                  validationRequired={true}
                  blnReturnFullValue={true}
                />
              </Col>
              <Col xl="6">
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "DiscountOf" })}
                  name="discountValue"
                  type="number"
                  disabled={props.selectedCustomerOrderDetail?.promotionCode ? true : false}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerOrderDetail}
                />
              </Col>
            </Row>
          }
          <Row>
            {/* {
              blnService
              &&
              <Col xl={props?.selectedCustomerOrderDetail?.expiredDate ? "6" : "12"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "ServiceBy" })}
                  name=""
                  className="mb-3"
                  options={optionsEmployeeList}
                  onChange={onChangeEmployee}
                  initialLabel={props.selectedCustomerOrderDetail?.serviceByName}
                  initialValue={props.selectedCustomerOrderDetail?.serviceById}
                  blnReturnFullValue={true}
                  validationRequired={true}
                  disabled={false}
                />
              </Col>
            } */}
            {/* {
              props?.selectedCustomerOrderDetail?.expiredDate
              &&
              <Col xl="12">
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "ExpiredDate" })}
                  name="expiredDate"
                  type="date"
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerOrderDetail}
                />
              </Col>
            } */}
          </Row>
          {/* {
            blnService && props?.selectedCustomerOrderDetail?.availableOptionalProducts
            &&
            (
              props?.selectedCustomerOrderDetail?.availableOptionalProducts.length > 0
                ?
                <DropDownWithTitleMultiSelect
                  name={"serviceProductUsed"}
                  title={intl.formatMessage({ id: "OptionalProducts" })}
                  specifyReturnFieldName={[{ "field": "serviceProductUsed", "value": "value" }]}
                  returnFieldWithLabel={true}
                  labelField={"label"}
                  valueField={"value"}
                  initialValue={props?.selectedCustomerOrderDetail?.serviceProductUsed || []}
                  options={props?.selectedCustomerOrderDetail?.availableOptionalProducts}
                  disabled={false}
                  validation={props.validation}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerOrderDetail} />
                :
                null
            )
          } */}
          {/* <GeneralTextAreaForModal
            title={intl.formatMessage({ id: "Description" })}
            name="remark"
            row={5}
            field={props.selectedCustomerOrderDetail}
            setFieldValue={props.setSelectedCustomerOrderDetail}
            validationRequired={false}
            disabled={false}
          /> */}
          {
            blnService
            &&
            <AdditionalFormField 
              additionalFormFieldFormTypeList={props.additionalFormFieldFormTypeList || []}
              disabledFieldInput={false}
              addAction={false}
              viewAction={false}
              field={props.selectedCustomerOrderDetail}
              setFieldValue={props.setSelectedCustomerOrderDetail}
              blnModal={true}/>
          }
          <Label>
            <Label className="margin-bottom-0 mt-2">
              {`${intl.formatMessage({ id: "FinalPriceWillBeRM" })}${finalPrice}`}
            </Label>
          </Label>
          <div className="">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "Save" })}
              class="btn btn-success"
              onClick={() => {
                if (props.selectedCustomerOrderDetail) {
                  // let blnServiceBy = (props.selectedCustomerOrderDetail.serviceById === null || props.selectedCustomerOrderDetail.serviceById === "" || props.selectedCustomerOrderDetail.serviceById === undefined);
                  // if (blnService) {
                  //   if (blnServiceBy) {
                  //     setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Consultant" }) }))
                  //     return;
                  //   }
                  // }

                  if (!props.selectedCustomerOrderDetail.discountValue) {
                    props.selectedCustomerOrderDetail.discountValue = 0;
                  }

                  if (!props.selectedCustomerOrderDetail.count) {
                    setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" }) }))
                    return;
                  } 

                  if (blnDecimal(props.selectedCustomerOrderDetail.count)) {
                    setErrorMessage(intl.formatMessage({ id: "DecimalOnQuantityBlock" }))
                    return;
                  }

                  if (finalPrice < (props.selectedCustomerOrderDetail.count * props.selectedCustomerOrderDetail.floorPrice)) {
                    setErrorMessage(intl.formatMessage({ id: "FinalPriceCouldNotLowerThanFloorPrice" }))
                    return;
                  }

                  if (blnPromoItem) {
                    if (props.selectedCustomerOrderDetail.availableQuantity! < props.selectedCustomerOrderDetail.count) {
                      setErrorMessage(intl.formatMessage({id: "PromoItemsMaximumCount"}))
                      return;
                    }
                  }

                  if (blnPackage) {
                    if (props.selectedCustomerOrderDetail.isPrepaidPackage && (props.maxPrepaidPackageInSalesOrder < props.selectedCustomerOrderDetail.count)) {
                      setErrorMessage(intl.formatMessage({id: "PrepaidPackageMaximumCount"}))
                      return;
                    }
                  }

                  let resultAdditionalFormData = blnService ? convertAdditonalFormDataSubmit(props.additionalFormFieldFormTypeList || [], props.selectedCustomerOrderDetail) : {status: Constants.success, data:props.selectedCustomerOrderDetail};
                  if (resultAdditionalFormData.status === Constants.failed) {
                    setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: resultAdditionalFormData.data }))
                    return;
                  }
                  let indexTemp = _.findIndex(props.validation.values.salesOrderDetails, { itemId: props.selectedCustomerOrderDetail.itemId, promotionItemType : props.selectedCustomerOrderDetail.promotionItemType})
                  let salesOrderDetailsTemp = _.cloneDeep(props.validation.values.salesOrderDetails)
                  if (indexTemp > -1) {
                    salesOrderDetailsTemp[indexTemp] = resultAdditionalFormData.data;
                  }
                  else {
                    salesOrderDetailsTemp.push(resultAdditionalFormData.data)
                  }
                  props.validation.setFieldValue("salesOrderDetails", salesOrderDetailsTemp)
                }

                hideModal();
              }}
            />
          </div>
        </div>
      </Modal>
  )
}