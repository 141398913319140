import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label, Row, Col } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../../app/components/form/GeneralInput";
import GeneralSelectDropdown from "../../../../app/components/form/GeneralSelectDropdown";
import { PackageListObject } from "../../../../app/models/package";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";
import Loading from "../../../../app/components/loading/Loading";
import moment from "moment";
import { returnExpiredDate } from "../../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  customerName: string;
  branchName: string;
  packageDropdownList: PackageListObject[];
  loadingPackageDropdown: boolean;
  onClickAddAppolousCustomerPackage: Function;
  loadingAddAppolousCustomerPackage: boolean;
}

export default function AddAppolousCustomerPackageModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const defaultAddAppolousCustomerPackageDetail = {
    customerId: props.customerId,
    customerName: props.customerName,
    packageId: "",
    packageName: "",
    totalSessions: 0,
    expiredDate: returnExpiredDate(Constants.textInputDateFormat)
  }
  const [addAppolousCustomerPackageDetail, setAddAppolousCustomerPackageDetail] = useState(defaultAddAppolousCustomerPackageDetail)
  const [errorState, setErrorState] = useState({
    packageId: false,
    totalSessions: false,
    expiredDate: false
  })
  const today = moment(new Date()).format(Constants.displayDateFormat);
  const disabledFieldInput = props.loadingAddAppolousCustomerPackage || Boolean(successMessage);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerId: props.customerId,
      customerName: props.customerName,
      branchName: props.branchName,
      packageId: "",
      packageName: "",
      totalSessions: 0,
      expiredDate: returnExpiredDate(Constants.textInputDateFormat)
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      packageId: false,
      totalSessions: false,
      expiredDate: false
    });
  }

  const hideModal = () => {
    setAddAppolousCustomerPackageDetail(defaultAddAppolousCustomerPackageDetail);
    props.setModal(false);
  }

  const onChangePackage = (id: string) => {
    let packageDropdownListTemp = props.packageDropdownList;
    let indexPackage = _.findIndex(packageDropdownListTemp, { id: id });
    if (indexPackage > -1) {
      setAddAppolousCustomerPackageDetail((values) => ({
        ...values,
        packageId: packageDropdownListTemp[indexPackage].id || "",
        packageName: packageDropdownListTemp[indexPackage].name || ""
      }))
      clearErrorState("packageId")
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size={"md"}
        centered
      >
        <div className="modal-header">
        <div className="flex-direction-row" style={{flex:1 }}>
          <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "AddAppolousCustomerPackage" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
          </div>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={12}>
              <GeneralInput
                title={intl.formatMessage({ id: "Branch" })}
                name={"branchName"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "Customer" })}
                name={"customerName"}
                className={"mb-3"}
                type="text"
                validation={validation}
                disabled={true}
              />
              {
                props.loadingPackageDropdown
                ?
                <div style={{ padding: "20px" }}>
                <Loading />
              </div>
                :
                <>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Package" })}
                    name="packageName"
                    className={errorState.packageId ? "mb-0" : "mb-3"}
                    options={props.packageDropdownList || []}
                    onChange={onChangePackage}
                    initialLabel={addAppolousCustomerPackageDetail.packageName}
                    initialValue={addAppolousCustomerPackageDetail.packageId}
                    labelField={"name"}
                    valueField={"id"}
                    validationRequired={true}
                    disabled={disabledFieldInput}
                  />
                  {errorState.packageId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Package" }) })}</FormFeedback>
                  ) : null}
                </>
              }
              <GeneralInputForModal
                title={intl.formatMessage({ id: "TotalSessions" })}
                name="totalSessions"
                className={errorState.totalSessions ? "mb-0" : "mb-3"}
                type="number"
                validationRequired={true}
                field={addAppolousCustomerPackageDetail}
                setFieldValue={setAddAppolousCustomerPackageDetail}
                onChangeFunction={() => { clearErrorState("totalSessions") }}
                disabled={disabledFieldInput}
              />
              {errorState.totalSessions ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TotalSessions" }) })}</FormFeedback>
              ) : null}
              <GeneralInput
                title={intl.formatMessage({ id: "ExpiredDate" })}
                name={"expiredDate"}
                className={errorState.expiredDate ? "mb-0" : "mb-3"}
                minCurrentDate={true}
                minDateValue={today}
                type="date"
                validationRequired={true}
                validation={validation}
                disabled={disabledFieldInput}
              />
              {errorState.expiredDate ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "NewJoinedDate" }) })}</FormFeedback>
              ) : null}
              <div className="flex-right">
                <MyButton
                  type="submit"
                  class="btn btn-success"
                  content={Constants.submit}
                  onClick={() => {
                    let errorStateTemp = {
                      packageId: false,
                      totalSessions: false,
                      expiredDate: false
                    };
                    let blnErrorFound = false;
                    if (!addAppolousCustomerPackageDetail.packageId) {
                      errorStateTemp.packageId = true;
                      blnErrorFound = true;
                    }
                    if (!addAppolousCustomerPackageDetail.totalSessions) {
                      errorStateTemp.totalSessions = true;
                      blnErrorFound = true;
                    }
                    if (blnErrorFound) {
                      setErrorState(errorStateTemp)
                    }
                    else{
                      props.onClickAddAppolousCustomerPackage(addAppolousCustomerPackageDetail);
                    }
                  }}
                  disable={props.loadingAddAppolousCustomerPackage|| Boolean(successMessage)}
                  loading={props.loadingAddAppolousCustomerPackage}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
  )
}