import React, { useEffect, useState } from 'react';
import { ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledTooltip } from 'reactstrap';
import MetaTags from "react-meta-tags";
import { observer } from 'mobx-react-lite';
import AuditLog from '../../../features/AuditLog/AuditLog';
import { useIntl } from 'react-intl';
import { PermissionConstants } from '../../constants/PermissionConstants';
import { checkPermission } from '../../common/function/function';
import LineBreakWithTittle from '../form/LineBreakWithTittle';
import { burgerMenu } from '../../models/common';
import ViewSequentialIcon from 'mdi-react/ViewSequentialIcon';
import { Constants } from '../../constants/Constants';
interface Props {
  title: string;
  children: any;
  auditTrailId?: string;
  burgerMenuList?: burgerMenu[];
  blnShow: boolean;
}

export default observer(function ModalViewLayout(props: Props) {
  const [dropdown, setDropdown] = useState(false);
  const [auditTrailModal, setAuditTrailModal] = useState(false);
  const intl = useIntl();
  const displayAuditTrailMenu = props.auditTrailId && checkPermission([PermissionConstants.ManageAuditLog]);
  const burgerMenuListTemp = props.burgerMenuList || [];

  const displayBurgetMenuContent = (burgerMenuTemp: burgerMenu[]) => {
    let aryViews: any = [];
    burgerMenuTemp.map((valueBurgerMenuTemp, index) => {
      aryViews.push(
        <DropdownItem 
          key={`dropdown_item_${index}`}
          onClick={()=> {valueBurgerMenuTemp.onFunction()}}>
          {valueBurgerMenuTemp.label}
        </DropdownItem>
      )
    })

    return aryViews;
  }

  return (
    <Modal
      isOpen={props.blnShow}
      centered>
      <div className="">
        <MetaTags>
          <title>{props.title}</title>
        </MetaTags>
        <Container 
          fluid 
          className='standard-layout'>
          <div className='flex-direction-row flex-align-items-center-without-height'>
            <LineBreakWithTittle
              className={`flex-1 ${(displayAuditTrailMenu || burgerMenuListTemp.length > 0) && `padding-right-8`}`}
              paddingBottom="0px"
              title={props.title}
              h4Title />
            {
              (displayAuditTrailMenu || burgerMenuListTemp.length > 0)
              &&
              <ButtonDropdown
                isOpen={dropdown}
                toggle={() => setDropdown(!dropdown)}
                className={"d-inline-block flex-right flex"}
                style={{ alignSelf: 'center' }}>
                <DropdownToggle caret color="primary" style={{height: "38px"}}>
                  <ViewSequentialIcon size={Constants.menuIconSize}/>
                </DropdownToggle>
                <DropdownMenu>
                  {props.auditTrailId && <DropdownItem onClick={()=> {setAuditTrailModal(true)}}>{intl.formatMessage({ id: "AuditLog" })}</DropdownItem>}
                  {displayBurgetMenuContent(burgerMenuListTemp)}
                </DropdownMenu>
              </ButtonDropdown>
            }
          </div>
          <div>{props.children}</div>
        </Container>
        {
          auditTrailModal
          &&
          <AuditLog 
            blnModal={auditTrailModal}
            setModal={setAuditTrailModal}
            auditLogId={props.auditTrailId}/>
        }
      </div>
    </Modal>
  )
})
