import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ChartObject, ChartRequestBodyObject, DailyCustomerBirthdayObject, DailySalesSummaryObject, MonthlyOfTopObject, MonthlyOfTopSalesObject, ReferralSpendingSummary, SalesByEmployeeSummaryObject, SalesPaymentSummaryObject, UpcomingAppointmentObject, WeeklyPendingOrders } from "../models/dashboard";
import _ from "lodash";
import { getBranchUser, returnPriceWithCurrency } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class dashboardStore {
  servicePerformedCountChart: ChartObject | undefined = undefined;
  salesSummaryChart : ChartObject | undefined = undefined;
  salesCategorySummaryChart : ChartObject | undefined = undefined;
  hourlySalesSummaryChart : ChartObject | undefined = undefined;
  salesPaymentSummaryChart: SalesPaymentSummaryObject[] = [];
  newCustomerCountChart: ChartObject | undefined = undefined;
  dailyCustomerBirthdayChart: DailyCustomerBirthdayObject[] = [];
  appointmentCountChart: ChartObject | undefined = undefined;
  todayUpcomingAppointmentChart: UpcomingAppointmentObject[] = [];
  weeklyUpcomingAppointmentChart : UpcomingAppointmentObject[] = [];
  topSellingProductsChart: MonthlyOfTopObject[] = [];
  topSellingPackagesChart: MonthlyOfTopObject[] = [];
  topSellingServicesChart: MonthlyOfTopObject[] = [];
  topCustomerSalesChart: MonthlyOfTopSalesObject[] = [];
  weeklyPendingOrdersChart : WeeklyPendingOrders[] = [];
  dailySalesSummaryChart: DailySalesSummaryObject[] = [];
  referralSpendingSummaryChart: ReferralSpendingSummary[] = [];
  salesByEmployeeSummary: SalesByEmployeeSummaryObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.servicePerformedCountChart = undefined;
    this.salesSummaryChart = undefined;
    this.salesCategorySummaryChart = undefined;
    this.hourlySalesSummaryChart = undefined;
    this.salesPaymentSummaryChart = [];
    this.newCustomerCountChart = undefined;
    this.dailyCustomerBirthdayChart = [];
    this.appointmentCountChart = undefined;
    this.todayUpcomingAppointmentChart = [];
    this.weeklyUpcomingAppointmentChart = [];
    this.topSellingProductsChart = [];
    this.topSellingPackagesChart = [];
    this.topSellingServicesChart = [];
    this.topCustomerSalesChart = [];
    this.weeklyPendingOrdersChart = [];
    this.dailySalesSummaryChart = [];
    this.referralSpendingSummaryChart = [];
    this.salesByEmployeeSummary = [];
  }

  setServicePerformedCount = (servicePerformedCountChart: ChartObject | undefined) => {
    this.servicePerformedCountChart = servicePerformedCountChart;
  }

  setSalesSummaryChart = (salesSummaryChart: ChartObject | undefined) => {
    this.salesSummaryChart = salesSummaryChart;
  }

  setSalesCategorySummaryChart =  (salesCategorySummaryChart: ChartObject | undefined) => {
    this.salesCategorySummaryChart = salesCategorySummaryChart;
  }

  setHourlySalesSummaryChart =  (hourlySalesSummaryChart: ChartObject | undefined) => {
    this.hourlySalesSummaryChart = hourlySalesSummaryChart;
  }

  setSalesPaymentSummaryChart =  (salesPaymentSummaryChart: SalesPaymentSummaryObject[]) => {
    this.salesPaymentSummaryChart = salesPaymentSummaryChart;
  }

  setNewCustomerCountChart =  (newCustomerCountChart: ChartObject) => {
    this.newCustomerCountChart = newCustomerCountChart;
  }

  setDailyCustomerBirthdayChart =  (dailyCustomerBirthdayChart: DailyCustomerBirthdayObject[]) => {
    this.dailyCustomerBirthdayChart = dailyCustomerBirthdayChart;
  }
  
  setAppointmentCountChart =  (appointmentCountChart: ChartObject) => {
    this.appointmentCountChart = appointmentCountChart;
  }

  setTodayUpcomingAppointmentChart =  (todayUpcomingAppointmentChart: UpcomingAppointmentObject[]) => {
    this.todayUpcomingAppointmentChart = todayUpcomingAppointmentChart;
  }
  
  setWeeklyUpcomingAppointmentChart =  (weeklyUpcomingAppointmentChart: UpcomingAppointmentObject[]) => {
    this.weeklyUpcomingAppointmentChart = weeklyUpcomingAppointmentChart;
  }

  setTopSellingProductsChart =  (topSellingProductsChart: MonthlyOfTopObject[]) => {
    this.topSellingProductsChart = topSellingProductsChart;
  }

  setTopSellingPackagesChart =  (topSellingPackagesChart: MonthlyOfTopObject[]) => {
    this.topSellingPackagesChart = topSellingPackagesChart;
  }

  setTopSellingServicesChart =  (topSellingServicesChart: MonthlyOfTopObject[]) => {
    this.topSellingServicesChart = topSellingServicesChart;
  }

  setTopCustomerSalesChart =  (topCustomerSalesChart: MonthlyOfTopSalesObject[]) => {
    this.topCustomerSalesChart = topCustomerSalesChart;
  }

  setWeeklyPendingOrders = (weeklyPendingOrdersChart: WeeklyPendingOrders[]) => {
    this.weeklyPendingOrdersChart = weeklyPendingOrdersChart;
  }

  setDailySalesSummary = (dailySalesSummaryChart: DailySalesSummaryObject[]) => {
    this.dailySalesSummaryChart = dailySalesSummaryChart;
  }

  setReferralSpendingSummaryChart = (referralSpendingSummaryChart: ReferralSpendingSummary[]) => {
    this.referralSpendingSummaryChart = referralSpendingSummaryChart;
  }

  setSalesByEmployeeSummary = (salesByEmployeeSummary: SalesByEmployeeSummaryObject[]) => {
    this.salesByEmployeeSummary = salesByEmployeeSummary;
  }

  getServicePerformedCountChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultServicePerformedCountChart = await agent.Dashboard.servicePerformedCount(chartRequestBodyObject);
      runInAction(() => {
        this.servicePerformedCountChart = this.addCountIntoField(resultServicePerformedCountChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.servicePerformedCountChart = undefined;
    }
  }

  getSalesSummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultSalesSummaryChart = await agent.Dashboard.salesSummary(chartRequestBodyObject);
      runInAction(() => {
        this.salesSummaryChart = this.addCountIntoField(resultSalesSummaryChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesSummaryChart = undefined;
    }
  }

  getSalesCategorySummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resutlSalesCategorySummaryChart = await agent.Dashboard.salesCategorySummary(chartRequestBodyObject);
      if (resutlSalesCategorySummaryChart.data && resutlSalesCategorySummaryChart.rowData) {
        if (resutlSalesCategorySummaryChart.data.length > 0 && resutlSalesCategorySummaryChart.rowData.categories) {
          if (resutlSalesCategorySummaryChart.data[0].data) {
            resutlSalesCategorySummaryChart.rowData.categories = resutlSalesCategorySummaryChart.rowData.categories.map((valueCategories, indexCategory)=> {
              if (resutlSalesCategorySummaryChart.data[0].data.length > indexCategory) {
                return `${valueCategories} - ${returnPriceWithCurrency(resutlSalesCategorySummaryChart.data[0].data[indexCategory])}`
              }
              return valueCategories
            })
          }
        }
      }
      runInAction(() => {
        this.salesCategorySummaryChart = this.addCountIntoField(resutlSalesCategorySummaryChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesCategorySummaryChart = undefined;
    }
  }

  getHourlySalesSummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resutlHourlySalesSummaryChart = await agent.Dashboard.hourlySalesSummary(chartRequestBodyObject);
      runInAction(() => {
        this.hourlySalesSummaryChart = this.addCountIntoField(resutlHourlySalesSummaryChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.hourlySalesSummaryChart = undefined;
    }
  }

  getSalesPaymentSummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultSalesPaymentSummaryChart = await agent.Dashboard.salesPaymentSummary(chartRequestBodyObject);
      if (resultSalesPaymentSummaryChart) {
        let totalTodayPayment = 0;
        let totalTodayCollectOnBehalf = 0;
        let totalAccumulatedPayment = 0;
        let totalAccumulatedCollectOnBehalf = 0;

        resultSalesPaymentSummaryChart.map((valueResultSalesPaymentSummaryChart)=> {
          totalTodayPayment += valueResultSalesPaymentSummaryChart.todayPayment;
          totalTodayCollectOnBehalf += valueResultSalesPaymentSummaryChart.todayCollectOnBehalf;
          totalAccumulatedPayment += valueResultSalesPaymentSummaryChart.accumulatedPayment;
          totalAccumulatedCollectOnBehalf += valueResultSalesPaymentSummaryChart.accumulatedCollectOnBehalf;
        })
        resultSalesPaymentSummaryChart.push({
          branchName: "TOTAL",
          todaySales: 0,
          todayPayment:totalTodayPayment,
          todayCollectOnBehalf: totalTodayCollectOnBehalf,
          accumulatedSales: 0,
          accumulatedPayment: totalAccumulatedPayment,
          accumulatedCollectOnBehalf: totalAccumulatedCollectOnBehalf
        })
      }
      runInAction(() => {
        this.salesPaymentSummaryChart = resultSalesPaymentSummaryChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.hourlySalesSummaryChart = undefined;
    }
  }

  getNewCustomerCountChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultNewCustomerCountChart = await agent.Dashboard.newCustomer(chartRequestBodyObject);
      runInAction(() => {
        this.newCustomerCountChart = this.addCountIntoField(resultNewCustomerCountChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.newCustomerCountChart = undefined;
    }
  }

  getDailyCustomerBirthdayChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultDailyCustomerBirthdayChart = await agent.Dashboard.dailyCustomerBirthday(chartRequestBodyObject);
      runInAction(() => {
        this.dailyCustomerBirthdayChart = resultDailyCustomerBirthdayChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dailyCustomerBirthdayChart = [];
    }
  }

  getAppointmentCountChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultAppointmentCountChart = await agent.Dashboard.appointmentCount(chartRequestBodyObject);
      runInAction(() => {
        this.appointmentCountChart = this.addCountIntoField(resultAppointmentCountChart);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentCountChart = undefined;
    }
  }

  getTodayUpcomingAppointmentChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultTodayUpcomingAppoointmentChart = await agent.Dashboard.todayUpcomingAppointment(chartRequestBodyObject);
      resultTodayUpcomingAppoointmentChart.sort((a, b) => (a.startDateTime > b.startDateTime) ? 1 : -1)
      runInAction(() => {
        this.todayUpcomingAppointmentChart = resultTodayUpcomingAppoointmentChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.todayUpcomingAppointmentChart = [];
    }
  }

  getWeeklyUpcomingAppointmentChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultWeeklyUpcomingAppoointmentChart = await agent.Dashboard.weeklyUpcomingAppointment(chartRequestBodyObject);
      resultWeeklyUpcomingAppoointmentChart.sort((a, b) => (a.startDateTime > b.startDateTime) ? 1 : -1)
      runInAction(() => {
        this.weeklyUpcomingAppointmentChart = resultWeeklyUpcomingAppoointmentChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.weeklyUpcomingAppointmentChart = [];
    }
  }

  getTopSellingProductsChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultTopSellingProductsChart = await agent.Dashboard.topSelling(chartRequestBodyObject);
      runInAction(() => {
        this.topSellingProductsChart = resultTopSellingProductsChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.topSellingProductsChart = [];
    }
  }

  getTopSellingPackagesChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultTopSellingPackagesChart = await agent.Dashboard.topSelling(chartRequestBodyObject);
      runInAction(() => {
        this.topSellingPackagesChart = resultTopSellingPackagesChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.topSellingPackagesChart = [];
    }
  }

  getTopSellingServicesChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultTopSellingServicesChart = await agent.Dashboard.topSelling(chartRequestBodyObject);
      runInAction(() => {
        this.topSellingServicesChart = resultTopSellingServicesChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.topSellingServicesChart = [];
    }
  }

  getTopCustomerSalesChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultTopCustomerSalesChart = await agent.Dashboard.topCustomerSales(chartRequestBodyObject);
      runInAction(() => {
        this.topCustomerSalesChart = resultTopCustomerSalesChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.topCustomerSalesChart = [];
    }
  }

  getWeeklyPendingOrdersChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultWeeklyPendingOrdersChart = await agent.Dashboard.weeklyPendingOrders(chartRequestBodyObject);
      runInAction(() => {
        this.weeklyPendingOrdersChart = resultWeeklyPendingOrdersChart;
      });
      return Promise.resolve({status: Constants.success, data: resultWeeklyPendingOrdersChart});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.weeklyPendingOrdersChart = [];
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  getDailySalesSummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultDailySalesSummaryChart : any = await agent.Dashboard.dailySalesSummary(chartRequestBodyObject);
      if (resultDailySalesSummaryChart && !getBranchUser()) {
        let totalAccumulatedPayment = 0;
        let totalCashRefund = 0;
        let totalAccumulatedCollectOnBehalf = 0;
        let totalReferralCount = 0;
        let totalResignUpgradeCount = 0;
        let totalResignUpgradeAmount = 0;
        let totalConsultationCount = 0;
        let totalNewVisitCount = 0;
        let totalCloseProgramCount = 0;
        // let totalCloseProgramPercentage = 0;
        let totalAverageNewVisitPerDay = 0;
        let totalCancellationCount = 0;

        resultDailySalesSummaryChart.map((valueDailySalesSummaryChart)=> {
          totalAccumulatedPayment += valueDailySalesSummaryChart.accumulatedPayment;
          totalCashRefund += valueDailySalesSummaryChart.cashRefund;
          totalAccumulatedCollectOnBehalf += valueDailySalesSummaryChart.accumulatedCollectOnBehalf;
          totalReferralCount += valueDailySalesSummaryChart.referralCount;
          totalResignUpgradeCount += valueDailySalesSummaryChart.resignUpgradeCount;
          totalResignUpgradeAmount += valueDailySalesSummaryChart.resignUpgradeAmount;
          totalConsultationCount += valueDailySalesSummaryChart.consultationCount;
          totalNewVisitCount += valueDailySalesSummaryChart.newVisitCount;
          totalCloseProgramCount += valueDailySalesSummaryChart.closeProgramCount;
          totalAverageNewVisitPerDay += valueDailySalesSummaryChart.averageNewVisitPerDay;
          totalCancellationCount += valueDailySalesSummaryChart.cancellationCount;
        })        

        resultDailySalesSummaryChart.push({
          branchName: "TOTAL",
          accumulatedPayment: totalAccumulatedPayment,
          cashRefund: totalCashRefund,
          accumulatedCollectOnBehalf: totalAccumulatedCollectOnBehalf,
          referralCount: totalReferralCount,
          resignUpgradeCount: totalResignUpgradeCount,
          resignUpgradeAmount: totalResignUpgradeAmount,
          consultationCount: totalConsultationCount,
          newVisitCount: totalNewVisitCount,
          closeProgramCount: totalCloseProgramCount,
          averageNewVisitPerDay: totalAverageNewVisitPerDay,
          closeProgramPercentage: "-",
          monthlySalesTarget: 0,
          dailySalesTarget: 0,
          cancellationCount: totalCancellationCount
        })
      }
      runInAction(() => {
        this.dailySalesSummaryChart = resultDailySalesSummaryChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dailySalesSummaryChart = [];
    }
  }

  getReferralSpendingSummaryChart = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultReferralSpendingSummaryChart = await agent.Dashboard.referralSpendingSummary(chartRequestBodyObject);
      let totalReferralCount = 0;
      let totalSpendingAmount = 0;
      resultReferralSpendingSummaryChart.map((valueResultSalesPaymentSummaryChart)=> {
        totalReferralCount += valueResultSalesPaymentSummaryChart.referralCount;
        totalSpendingAmount += valueResultSalesPaymentSummaryChart.spendingAmount;
      })
      resultReferralSpendingSummaryChart.push({
        branchName: "TOTAL",
        referralCount: totalReferralCount,
        spendingAmount: totalSpendingAmount,
      })
      runInAction(() => {
        this.referralSpendingSummaryChart = resultReferralSpendingSummaryChart;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.referralSpendingSummaryChart = [];
    }
  }

  getSalesByEmployeeSummary = async (chartRequestBodyObject: ChartRequestBodyObject) => {
    try{
      const resultSalesByEmployeeSummary = await agent.Dashboard.salesByEmployeeSummary(chartRequestBodyObject);
      let totalDailyReceived = 0;
      let totalMtdReceived = 0;

      resultSalesByEmployeeSummary.map((valueSalesByEmployeeSummary)=> {
        totalDailyReceived += valueSalesByEmployeeSummary.dailyReceived;
        totalMtdReceived += valueSalesByEmployeeSummary.mtdReceived;
      })        

      resultSalesByEmployeeSummary.push({
        name: "TOTAL",
        dailyReceived: totalDailyReceived,
        mtdReceived: totalMtdReceived
      })

      runInAction(() => {
        this.salesByEmployeeSummary = resultSalesByEmployeeSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesByEmployeeSummary = [];
    }
  }

  addCountIntoField = (result: ChartObject) => {
    let resultTemp = _.cloneDeep(result);
    if (resultTemp) {
      if (resultTemp.data) {
        let finalCount : number = 0;
        resultTemp.data.map((valueDataTemp)=> {
          valueDataTemp.data.map((valueSubDataTemp)=> {
            finalCount += valueSubDataTemp
          })
        })
        resultTemp.totalItems = String(finalCount);
      }
    }
    return resultTemp;
  }
}