import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { checkPermission, virtualWarehouseCreditExchangeStatusColor, displayWatermark, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, contructValidationErrorMessage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useFormik } from "formik";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { Link } from 'react-router-dom';
import MyButton from "../../app/components/form/MyButton";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import CancelModal from "../../app/components/modal/CancelModal";

const VirtualWarehouseCreditExchangeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { virtualWarehouseCreditExchangeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    setShowCancelPrompt
  } = commonStore;
  const { virtualWarehouseCreditExchangeDetail, setVirtualWarehouseCreditExchangeDetail, getVirtualWarehouseCreditExchangeWithId, reviewVirtualWarehouseCreditExchange, cancelVirtualWarehouseCreditExchange } = virtualWarehouseCreditExchangeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "VirtualWarehouseCreditExchange" })}`;
  const [breadCrumbList, setBreadCrumbList] = useState([{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: RoutesList.virtualWarehouseCreditExchange }, { title: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }), urlPath: RoutesList.virtualWarehouseCreditExchange }]);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.UpdateVirtualWarehouseCreditExchange]);
  const blnPermissionReviewVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.ReviewVirtualWarehouseCreditExchange]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManagePurchaseDeliverOrder = checkPermission([PermissionConstants.ManagePurchaseDeliverOrder]);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);
  const [voidSalesModal, setVoidSalesModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const location = useLocation();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: virtualWarehouseCreditExchangeDetail || {
      id: "",
      isDelete: false,
      virtualWarehouseCreditExchangeNo: "",
      customerId: "",
      customerName: "",
      status: "",
      subTotalExchangeAmount: 0,
      processingFees: 0,
      totalExchangeAmount: 0,
      note: "",
      reviewedOn: "",
      reviewerUserId: "",
      reviewerUserName: "",
      reviewRemark: "",
      creditExchangeDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  async function fetchVirtualWarehouseCreditExchangeDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getVirtualWarehouseCreditExchangeWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateVirtualWarehouseCreditExchange], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange], true)) {
        return;
      }
    }

    fetchVirtualWarehouseCreditExchangeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setVirtualWarehouseCreditExchangeDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (localLoading) {
      validation.setSubmitting(true);
    }
    else {
      validation.setSubmitting(false);
    }
  }, [localLoading])

  useEffect(() => {
    if (virtualWarehouseCreditExchangeDetail) {
      let indexEditAction = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "EditAction" })}` })
      if (indexEditAction < 0 && viewAction && blnPermissionUpdateVirtualWarehouseCreditExchange && !blnRejectedStatus && !blnApprovedStatus && !blnCancelledStatus) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.virtualWarehouseCreditExchange}/edit/${id}`) } })
      }
    }
  }, [virtualWarehouseCreditExchangeDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const blnCancelledStatus = virtualWarehouseCreditExchangeDetail?.status == Constants.cancelled;
  const blnPendingStatus = virtualWarehouseCreditExchangeDetail?.status == Constants.pending;
  const blnRejectedStatus = virtualWarehouseCreditExchangeDetail?.status == Constants.rejected;
  const blnApprovedStatus = virtualWarehouseCreditExchangeDetail?.status == Constants.approved;

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted">
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
        <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const onClickReviewSalesOrder = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewVirtualWarehouseCreditExchange({ id: id, reviewRemark: validation.values.reviewRemark, workflowAction: valueAction })
    setLocalLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading && !successMessage ? (
          <Loading />
        ) :
          (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="">
                      <div className="virtualWarehouseCreditExchange-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "VirtualWarehouseCreditExchange" })}
                              </span>
                              <br />
                              <br />
                              {displayHeaderRowData(`${intl.formatMessage({ id: "VirtualWarehouseCreditExchangeNo" })}:`, validation.values.virtualWarehouseCreditExchangeNo)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "Customer" })}:</strong>
                            <br />
                            <span className="font-size-15 text-muted">
                              {
                                blnPermissionViewCustomer ?
                                  <Link to={validation.values.customerId ? `/${RoutesList.customer}/view/${validation.values.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                    <Label className="margin-bottom-0 pointer-clickable">{validation.values.customerName || Constants.emptyData}</Label>
                                  </Link>
                                  :
                                  `${validation.values.customerName}`
                              }
                            </span>
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                            <br />
                            <div style={{ marginTop: '8px' }}>
                              <GeneralStatus
                                status={validation.values.status}
                                statusColorFunction={virtualWarehouseCreditExchangeStatusColor}
                                blnDisableCenter />
                            </div>
                          </address>
                        </Col>
                        {
                          (blnRejectedStatus || blnApprovedStatus)
                          &&
                          <Col xs="6" className="text-end text-muted">
                            <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted">{`${intl.formatMessage({ id: "ReviewedBy" })}: ${validation.values.reviewerUserName}`}</h4>
                            <h4 className="margin-bottom-2 font-size-14 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>{`${intl.formatMessage({ id: "ReviewedOn" })}: ${moment(validation.values.reviewedOn).format(Constants.displayDateAndTimeFormat)}`}</h4>
                          </Col>
                        }
                      </Row>
                      {/* watermark */}
                      {displayWatermark()}
                      <div className="py-2 margin-top-36">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "CreditExchange" }) })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "VirtualWarehouseNo" })}</th>
                              <th>{intl.formatMessage({ id: "Product" })}</th>
                              <th>{intl.formatMessage({ id: "CustomerRedemptionNo" })}</th>
                              <th>{intl.formatMessage({ id: "Branch" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "UnitPrice" })}</th>
                              <th className="text-end">{intl.formatMessage({ id: "ExchangeAmount" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              validation.values.creditExchangeDetails.map((item, key) => (
                                <tr key={key}>
                                  <td>
                                    {
                                      blnPermissionManageCustomerVirtualWarehouse
                                        ?
                                        <Link
                                          to={`/${RoutesList.customerVirtualWarehouse}/view/${item.virtualWarehouseId}`}
                                          className="text-info pointer-clickable"
                                          target={Constants.blank} rel="noopener noreferrer">
                                          {item.virtualWarehouseNo}
                                        </Link>
                                        :
                                        item.virtualWarehouseNo
                                    }
                                  </td>
                                  <td>{getPurchaseTypeIcon(Constants.product)}{item.productName}</td>
                                  <td>
                                    {
                                      blnPermissionManagePurchaseDeliverOrder
                                        ?
                                        <Link
                                          to={`/${RoutesList.customerRedemption}/view/${item.deliverOrderId}`}
                                          className="text-info pointer-clickable"
                                          target={Constants.blank} rel="noopener noreferrer">
                                          {item.deliverOrderNo}
                                        </Link>
                                        :
                                        item.deliverOrderNo
                                    }
                                  </td>
                                  <td>{item.branchName}</td>
                                  <td className="text-center">{item.quantity}</td>
                                  <td className="text-center">{returnPriceWithCurrency(item.unitPrice)}</td>
                                  <td className="text-end">{returnPriceWithCurrency(item.exchangeAmount)}</td>
                                </tr>
                              ))
                            }
                            {displayCustomRowData(5, `${intl.formatMessage({ id: "SubTotalExchangeAmount" })}`, returnPriceWithCurrency(validation.values.subTotalExchangeAmount))}
                            {displayCustomRowData(5, `${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(validation.values.processingFees))}
                            {displayCustomRowData(5, `${intl.formatMessage({ id: "TotalExchangeAmount" })}`, returnPriceWithCurrency(validation.values.totalExchangeAmount))}
                          </tbody>
                        </Table>
                      </div>
                      {
                        <>
                          <div className={`py-2 mt-5`}>
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({ id: "Reason" })} :
                            </h3>
                          </div>
                          <GeneralTextArea
                            title=""
                            name="note"
                            row={5}
                            disabled={true}
                            validation={validation} />
                        </>
                      }
                      {
                        <>
                          <div className={`py-2 mt-3`}>
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({ id: "ReviewComment" })} :
                            </h3>
                          </div>
                          <GeneralTextArea
                            title=""
                            name="reviewRemark"
                            row={5}
                            disabled={loading || localLoading || viewAction || blnApprovedStatus || blnRejectedStatus || blnCancelledStatus || !blnPermissionReviewVirtualWarehouseCreditExchange || Boolean(successMessage)}
                            validation={validation} />
                        </>
                      }
                      {
                        <div className="d-print-none flex-direction-row" style={{ marginTop: "3rem" }}>
                          {
                            !viewAction
                            &&
                            blnPendingStatus
                            &&
                            !Boolean(successMessage)
                            &&
                            <>
                              {
                                blnPermissionReviewVirtualWarehouseCreditExchange
                                &&
                                <>
                                  <MyButton
                                    type="button"
                                    class="btn btn-success margin-right-8"
                                    content={intl.formatMessage({ id: "Approve" })}
                                    onClick={() => {
                                      onClickReviewSalesOrder(Constants.approve);
                                    }}
                                    disable={loading || localLoading || !blnPermissionReviewVirtualWarehouseCreditExchange || Boolean(successMessage)}
                                    loading={loading || localLoading}
                                  />
                                  <MyButton
                                    type="button"
                                    class="btn btn-danger margin-right-8"
                                    content={intl.formatMessage({ id: "Reject" })}
                                    onClick={() => {
                                      onClickReviewSalesOrder(Constants.reject);
                                    }}
                                    disable={loading || localLoading || !blnPermissionReviewVirtualWarehouseCreditExchange || Boolean(successMessage)}
                                    loading={loading || localLoading}
                                  />
                                </>
                              }
                              {
                                blnPermissionUpdateVirtualWarehouseCreditExchange
                                &&
                                <MyButton
                                  type="button"
                                  class="btn btn-warning margin-right-8"
                                  content={Constants.cancel}
                                  onClick={async () => {
                                    setShowCancelPrompt(true);
                                  }}
                                  disable={loading || localLoading || !blnPermissionUpdateVirtualWarehouseCreditExchange || Boolean(successMessage)}
                                  loading={loading || localLoading}
                                />
                              }
                            </>
                          }
                          <GeneralSubmitAndCancelBtn
                            successMessage={successMessage}
                            className="width-100-percentage"
                            viewAction={false}
                            validation={validation}
                            hidePrimaryButton={true}
                            showPrint={true}
                            cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                            cancelButtonBesidePrint={false}
                          />
                        </div>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        <CancelModal
          onCancelClick={async () => {
            setLocalLoading(true);
            await cancelVirtualWarehouseCreditExchange(id)
            setLocalLoading(false);
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(VirtualWarehouseCreditExchangeDetail);
