import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { CashOutListObject } from '../../app/models/cashOut';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency, cashOutStatusColor, arraySpliceInAllValue } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import moment from "moment";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const CashOut = () => {
  const intl = useIntl();
  //Use Store
  const { cashOutStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { cashOutList, cashOutPaginationParams, getCashOut, deleteCashOut, setCashOutPaginationParams } = cashOutStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CashOutListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCashOutStatus, setSelectedCashOutStatus] = useState<string>(AllStatus);
  const [selectedCashOutStatusId, setSelectedCashOutStatusId] = useState<number | string | undefined>(" ");
  const [cashOutStatusList, setCashOutStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionCreateCashOut =  checkPermission([PermissionConstants.CreateCashOut]);
  const blnPermissionUpdateCashOut =  checkPermission([PermissionConstants.UpdateCashOut]);
  const blnPermissionDeleteCashOut =  checkPermission([PermissionConstants.DeleteCashOut]);

  let cashOutRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    submissionFromDate: selectedDateRange.fromDate,
    submissionToDate: selectedDateRange.toDate,
    status: selectedCashOutStatusId,
    statusName: selectedCashOutStatus
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCashOutAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.cashOut)
      if (blnBackNavigation) {
        if (cashOutPaginationParams) {
          setCurrentPage(cashOutPaginationParams.pageNumber)
          setSelectedBranchId(cashOutPaginationParams.branchId || "");
          setSelectedBranchName(cashOutPaginationParams.branchName || "");
          setSelectedCashOutStatusId(cashOutPaginationParams.status)
          setSelectedCashOutStatus(cashOutPaginationParams.statusName || AllStatus)
          setSelectedDateRange({fromDate: cashOutPaginationParams.submissionFromDate || "", toDate: cashOutPaginationParams.submissionToDate || ""})
          cashOutRequestBody = cashOutPaginationParams;
        }
      }
      else {
        setCashOutPaginationParams(undefined);
      }
      
      let aryAPI: any = [
        getCashOut(cashOutRequestBody),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.cashOutStatus, AllStatus),
      ]
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))
      setCashOutStatusList(resultAPI[2]);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCashOut], true)) {
      return;
    }

    fetchCashOutAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.cashOut)
        if (!blnSetParams) {
          setCashOutPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedCashOutStatusId || selectedCashOutStatusId === 0) && !initialCallAPI) 
    {
      fetchCashOutAPI(true);
    }
  }, [selectedDateRange, selectedCashOutStatusId, selectedBranchId]);

  const onClickDelete = async (row: CashOutListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchCashOutAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCashOut({
      ...cashOutRequestBody,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CashOutColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.submissionDate).format(Constants.displayDateFormat)}`}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName || Constants.emptyData}`}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {returnPriceWithCurrency(row.totalAmount)}    
        </div>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={cashOutStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageCashOut, PermissionConstants.UpdateCashOut, PermissionConstants.DeleteCashOut]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.status === Constants.submitted
              &&
              <TableActionButton 
                to={`/${RoutesList.cashOut}/view/${row.id}`}
                type="view"/>
            }
            {
              blnPermissionUpdateCashOut
              &&
              row.status !== Constants.submitted
              &&
              <TableActionButton 
                to={`/${RoutesList.cashOut}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteCashOut
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];
  
  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }
  
  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CashOut" }) })}
        title={intl.formatMessage({ id: "CashOut" })}
        addButton={
          blnPermissionCreateCashOut
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "CashOut" }) })}
            onClick={() => { history.push(`/${RoutesList.cashOut}/add`) }}
            linkTo={`/${RoutesList.cashOut}/add`}
            disable={loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "CashCollectionDate"})})}
                name={"dateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                onChangeFunction={onSelectDateRange} />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Status" })}
                name="cashOutStatus"
                options={cashOutStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedCashOutStatus}
                initialValue={selectedCashOutStatusId}
                setFieldLabel={setSelectedCashOutStatus}
                setFieldValue={setSelectedCashOutStatusId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CashOutColumns}
          data={cashOutList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CashOut" }) })}
          objSorted={defaultSorted}
          branchId={selectedBranchId}
          branchName={selectedBranchName}
          submissionFromDate={selectedDateRange.fromDate}
          submissionToDate={selectedDateRange.toDate}
          status={selectedCashOutStatusId}
          statusName={selectedCashOutStatus}
          requestAPI={getCashOut} />
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCashOut(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (cashOutList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCashOut({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(CashOut);
