
import React from 'react'
import { newSpaceBeforeCapitalLetter } from '../../common/function/function';
interface Props {
  id?: string;
  index?: number;
  className?: string;
  imageWidth?: string;
  base64Image: string;
}

export default function GeneralImageWithBase64(props: Props) {
  return (
    <img
      {...props.id && {id: props.id}}
      className={props.className}
      key={`tab_image_text_input_${props.index}`}
      width={props.imageWidth || "40px"}
      src={`data:image/png;base64,${props.base64Image}`}
    />
  )
}