import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, getBranchId, getBranchName, stockQuantityStatusColor, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, returnThousandSeperator } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const CreditHistory = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { creditHistoryList, getCreditHistoryList, setCreditHistoryList, exportCreditHistory } = reportCustomerStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { creditHistoryEntityType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [selectedMovementType, setSelectedMovementType] = useState("");
  const [selectedMovementTypeId, setSelectedMovementTypeId] = useState("");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  const paramsSearch = useLocation().search;

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];

  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCollectionListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.creditHistoryEntityType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const movementTypeParams = new URLSearchParams(paramsSearch).get('movementType');

      if (branchIdParams && fromDateParams && toDateParams && movementTypeParams) {
        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchName(resultAPI[0][indexBranch].name);
          setSelectedBranchId(resultAPI[0][indexBranch].id);
        }

        let indexMovementType = _.findIndex(resultAPI[1], { key: Number(movementTypeParams) });
        if (indexMovementType > -1) {
          setSelectedMovementType(resultAPI[1][indexMovementType].displayValue);
          setSelectedMovementTypeId(resultAPI[1][indexMovementType].key);
        }

        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.creditHistory}` });
        await getCreditHistoryList({
          pageNumber: 1,
          pageSize: pageSize,
          branchId: branchIdParams,
          dateRange: { fromDate: fromDateTemp, toDate: toDateTemp },
          movementType:  movementTypeParams,
          customerId: ""
        });
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchCollectionListAPI();

    return (() => {
      setCreditHistoryList([]);
    })
  }, []);

  useEffect(() => {
    async function getCustomerAndConsultanttDropdownFunction () {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerAndConsultanttDropdownFunction();
    }
  }, [selectedBranchId])

  async function fetchCollectionDetailApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getCreditHistoryList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      movementType:  selectedMovementTypeId,
      customerId: selectedCustomerId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportCreditHistoryAPI () {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportCreditHistory({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      movementType:  selectedMovementTypeId,
      customerId: selectedCustomerId
    });
    setLocalLoading(false)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const CollectionColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      )
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            <div>{row.customerNo || Constants.emptyData}</div>
          }          
        </>
      )
    },
    {
      dataField: "entityType",
      text: intl.formatMessage({ id: "EntityType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.entityType)}</div>
    },
    {
      dataField: "credits",
      text: intl.formatMessage({ id: "Credits" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={returnThousandSeperator(row.credits)}
          statusBackgroundColor={stockQuantityStatusColor(row.credits)}/>
      ),
    },
    {
      dataField: "actionUser",
      text: intl.formatMessage({ id: "ActionUser" }).toUpperCase()
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "CreatedAt" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{moment(row.createdAt).format(Constants.displayDateFormat)}</div>,
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditHistory" }) })}
        title={intl.formatMessage({ id: "CreditHistory" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportCreditHistoryAPI()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Credit-History.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "CreatedAt"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              {
                <Col xl={'4'} md={'6'} xs={'12'}>
                  {
                    displayCustomerDropdown &&
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customer"
                      placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                      options={customerDropdownList}
                      labelField="preferredName"
                      valueField="id"
                      initialLabel={selectedCustomer}
                      initialValue={selectedCustomerId}
                      setFieldLabel={setSelectedCustomer}
                      setFieldValue={setSelectedCustomerId}
                      disabled={localLoading || loading}
                      onChange={() => {
                        clearCustomerDropdownList();
                        setErrorMessage("");
                        setSuccessMessage("");
                      }}
                      blnSupportCustomSearch={true}
                      onChangeCustomSearch={(value)=> {
                        fetchCustomerDropdown(value);
                      }}
                      onMenuOpenFunction={()=> {
                        clearCustomerDropdownList();
                        setLoadingCustomer(false);
                      }}
                      loadingCustomSearch={loadingCustomer}
                      setLoadingCustomSearch={setLoadingCustomer}
                    />
                  }
                </Col>
              }
              {
                !getBranchUser()
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "MovementType" })}
                    name="movementType"
                    options={creditHistoryEntityType}
                    labelField="displayValue"
                    valueField="key"
                    initialLabel={selectedMovementType}
                    initialValue={selectedMovementTypeId}
                    setFieldLabel={setSelectedMovementType}
                    setFieldValue={setSelectedMovementTypeId}
                    blnValueWithNewSpace={true}
                    disabled={localLoading || loading}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading || loadingCustomer}
                  disable={localLoading || loading || loadingCustomer}
                  onClick={() => {
                    fetchCollectionDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CollectionColumns}
              keyField={"id"}
              data={creditHistoryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditHistory" }) })}
              objSorted={defaultSorted}
              requestAPI={getCreditHistoryList}
              branchId={selectedBranchId}
              movementType={selectedMovementTypeId}
              customerId={selectedCustomerId}
              dateRange={selectedDateRange}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CreditHistory);
