import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { getBranchId, getBranchName, getBranchUser, removeAllSpace } from "../../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralInput from "../../../app/components/form/GeneralInput";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedProductId: any;
  selectedProductName: any;
  optionsStockOutReason: any;
  stockOutProduct: Function;
  setErrorMessage: Function;
  fetchProductApi?: Function;
}

export default function ProductStockOutModal(props: Props) {
  const intl = useIntl();
  const { branchStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingStockOut, setLoadingStockOut] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false)

  const defaultStockOutDetails = {
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    productId: props.selectedProductId,
    productName: props.selectedProductName,
    quantity: 0,
    reason: "",
    remark: ""
  }
  const [stockOutDetails, setStockOutDetails] = useState(defaultStockOutDetails)
  const [errorState, setErrorState] = useState({
    branchId: false,
    quantity: false,
    reason: false
  })

  useEffect(() => {
    async function fetchExtendPackageAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        !getBranchUser() ? getBranchDropDown() : undefined,
      ];
      await Promise.all(aryAPI);
      setLocalLoading(false);
    }

    fetchExtendPackageAPI();
  }, [])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branchId: false,
      quantity: false,
      reason: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setStockOutDetails(defaultStockOutDetails);
    props.setModal(false);
  }

  const onChangeBranch = async (id: string) => {
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    if (indexBranch > -1) {
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
      clearErrorState("branchId")
    }
  };

  const onChangeReason = (type) => {
    let typeTemp = removeAllSpace(type)

    setStockOutDetails(valueTemp => ({
      ...valueTemp,
      reason: typeTemp,
    }));

    clearErrorState("reason");
  }

  const onClickStockOut = async () => {
    let stockOutDetailsTemp = _.cloneDeep({ ...stockOutDetails, productId: props.selectedProductId, branchId: selectedBranchId })
    setLoadingStockOut(true);
    let resultAPI = await props.stockOutProduct(stockOutDetailsTemp);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
        if (props.fetchProductApi) {
          props.fetchProductApi(true);
        }
      }, Constants.timeoutNavigation)
    }
    setLoadingStockOut(false);
  }


  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingStockOut && !localSuccessMessage) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "StockOut" })}</h5>
          {
            !loadingStockOut && !localSuccessMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            localLoading
            ?
            <Loading/>
            :
            <>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchId"
                  className={errorState.branchId ? "mb-0" : "mb-3"}
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={loadingStockOut || Boolean(localSuccessMessage) || getBranchUser()}
                  onChange={onChangeBranch}
                  validationRequired={true}
                />
                {errorState.branchId ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                ) : null}
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Product" })}
                  name="productName"
                  className={"mb-3"}
                  type="text"
                  validationRequired={true}
                  field={stockOutDetails}
                  setFieldValue={setStockOutDetails}
                  disabled={true}
                />
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Quantity" })}
                  name="quantity"
                  className={errorState.quantity ? "mb-0" : "mb-3"}
                  type="number"
                  validationRequired={true}
                  field={stockOutDetails}
                  setFieldValue={setStockOutDetails}
                  onChangeFunction={() => { clearErrorState("quantity") }}
                  disabled={loadingStockOut || Boolean(localSuccessMessage)}
                />
                {errorState.quantity ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" }) })}</FormFeedback>
                ) : null}
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab={true}>
                <Label>
                  <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "Reason" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.reason ? "mb-0" : "mb-3"}
                  options={props.optionsStockOutReason}
                  onChange={onChangeReason}
                  initialLabel={stockOutDetails.reason}
                  initialValue={stockOutDetails.reason}
                  blnValueWithNewSpace={true}
                  disabled={loadingStockOut || Boolean(localSuccessMessage)}
                  validationRequired={true}
                />
                {errorState.reason ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Reason" }) })}</FormFeedback>
                ) : null}
              </SingleColumnRowParent>
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Remark" })}
                name="remark"
                className={"mb-3"}
                row={4}
                field={stockOutDetails}
                setFieldValue={setStockOutDetails}
                disabled={loadingStockOut || Boolean(localSuccessMessage)} />
              <div className="margin-top-14 flex-right">
                {
                  !Boolean(localSuccessMessage)
                  &&
                  <MyButton
                    type="button"
                    class="btn btn-primary"
                    content={intl.formatMessage({ id: 'Submit' })}
                    onClick={() => {
                      let errorStateTemp = {
                        branchId: false,
                        quantity: false,
                        reason: false
                      };
                      let blnErrorFound = false;
                      if (!selectedBranchId) {
                        errorStateTemp.branchId = true;
                        blnErrorFound = true;
                      }
                      if (!stockOutDetails.quantity) {
                        errorStateTemp.quantity = true;
                        blnErrorFound = true;
                      }
                      if (!stockOutDetails.reason) {
                        errorStateTemp.reason = true;
                        blnErrorFound = true;
                      }
                      if (blnErrorFound) {
                        setErrorState(errorStateTemp)
                      }
                      else {
                        onClickStockOut()
                      }
                    }}
                    loading={loadingStockOut}
                    disable={loadingStockOut || Boolean(localSuccessMessage)}
                  />
                }
              </div>
            </>
          }
        </div>
      </Modal>
  )
}