import { useEffect, useState } from "react";
import {
  Modal,
  Label,
  FormFeedback,
  Row
} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useStore } from "../../../app/stores/store";
import { getBranchId, getBranchName, getBranchUser, isBranchManager } from "../../../app/common/function/function";
import { BranchCommissionAddObject } from "../../../app/models/branchCommission";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import EnterPasscodeModal from "../../../app/components/modal/EnterPasscodeModal";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  optionYearList: any;
  optionMonthList: any;
  addBranchCommission: Function;
  setErrorMessage: Function;
}

export default function GenerateBranchCommissionModal(props: Props) {
  const intl = useIntl();
  const { branchStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { loading, setLoading, successMessage } = commonStore;
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [branchCommissionModalLoading, setBranchCommissionModalLoading] = useState(false);
  const disabledFieldInput = loading || Boolean(successMessage);
  const [modalEnterPasscode, setModalEnterPasscode] = useState<boolean>(false);

  const defaultBranchCommissionDetails = {
    year: selectedYear,
    month: selectedMonthValue,
    branchId: selectedBranchId
  }
  const [branchCommissionDetails, setBranchCommissionDetails] = useState(defaultBranchCommissionDetails)
  const [errorState, setErrorState] = useState({
    year: false,
    month: false,
    branchId: false
  })

  useEffect(() => {
    async function fetchBranchCommissionAPI() {
      setBranchCommissionModalLoading(true)
      let aryAPI: any = [
        getBranchDropDown()
      ];
      await Promise.all(aryAPI);
      setBranchCommissionModalLoading(false);
    }

    fetchBranchCommissionAPI();
  }, [])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      year: false,
      month: false,
      branchId: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setBranchCommissionDetails(defaultBranchCommissionDetails);
    props.setModal(false);
  }

  const onChangeYear = async (value) => {
    setBranchCommissionDetails(valueTemp => ({
      ...valueTemp,
      year: Number(value)
    }));
    clearErrorState("year");
  }

  const onChangeMonth = (value) => {
    setBranchCommissionDetails(valueTemp => ({
      ...valueTemp,
      month: Number(value),
    }));
    clearErrorState("month");
  }

  const onChangeBranch = (id: string) => {
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    if (indexBranch > -1) {
      setBranchCommissionDetails((values) => ({
        ...values,
        branchId: branchDropDownList[indexBranch].id,
        branchName: branchDropDownList[indexBranch].name
      }))
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
      clearErrorState("branchId")
    }
  }

  const onClickGenerateBranchCommission = async (branchCommissionDetailTemp: BranchCommissionAddObject) => {
    setLoading(true);
    await props.addBranchCommission(branchCommissionDetailTemp);
    setLoading(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "BranchCommission" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        {
          branchCommissionModalLoading
            ?
            <Loading />
            :
            <div className="modal-body">
              <Label>
                <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "Year" })}</Label>
              </Label>
              <GeneralSelectDropdown
                title=""
                name=""
                className={errorState.year ? "mb-0" : "mb-3"}
                options={props.optionYearList}
                onChange={onChangeYear}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={disabledFieldInput}
                validationRequired={true}
              />
              {errorState.year ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
              ) : null}
              <Label>
                <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "Month" })}</Label>
              </Label>
              <GeneralSelectDropdown
                title=""
                name=""
                className={errorState.month ? "mb-0" : "mb-3"}
                options={props.optionMonthList}
                onChange={onChangeMonth}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={disabledFieldInput}
                validationRequired={true}
              />
              {errorState.month ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })}</FormFeedback>
              ) : null}
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchId"
                className={errorState.branchId ? "mb-0" : "mb-3"}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={disabledFieldInput || getBranchUser()}
                onChange={onChangeBranch}
                validationRequired={true}
              />
              {errorState.branchId ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
              ) : null}
              {
                !Boolean(successMessage)
                &&
                <div className="margin-top-14 ">
                  <MyButton
                    type="button"
                    class="btn btn-primary"
                    content={intl.formatMessage({ id: "Generate" })}
                    onClick={() => {
                      let errorStateTemp = {
                        year: false,
                        month: false,
                        branchId: false
                      };

                      let blnErrorFound = false;
                      if (!branchCommissionDetails.year) {
                        errorStateTemp.year = true;
                        blnErrorFound = true;
                      }
                      else {
                        if (!branchCommissionDetails.month) {
                          errorStateTemp.month = true;
                          blnErrorFound = true;
                        }
                      }
                      if (!branchCommissionDetails.branchId) {
                        errorStateTemp.branchId = true;
                        blnErrorFound = true;
                      }
                      if (blnErrorFound) {
                        setErrorState(errorStateTemp)
                      }
                      else if (isBranchManager()) {
                        setModalEnterPasscode(true);
                      } 
                      else {
                        onClickGenerateBranchCommission({...branchCommissionDetails, passcode: ""})
                      }
                    }}
                    disable={disabledFieldInput}
                    loading={loading}
                  />
                </div>
              }
            </div>
        }
        {
          modalEnterPasscode
          &&
          <EnterPasscodeModal 
            intl={intl}
            actionTitle={Constants.generate}
            showPrompt={modalEnterPasscode}
            setShowPrompt={setModalEnterPasscode}
            onPrimaryClick={(passcode)=> {
              onClickGenerateBranchCommission({...branchCommissionDetails, passcode: passcode})
            }} />
        }
      </Modal>
  )
}