import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import MyButton from "../../app/components/form/MyButton";
import { AvailableProductPurchaseObject, CustomerPurchasePackage, CustomerPurchaseProduct, MandatoryProductsObject, RedeemPurchaseDetailObject } from "../../app/models/customerOrder";
import { checkPermission, contructValidationErrorMessage, displayPromotionCode, getBranchId, getBranchName, getPurchaseTypeIcon, newSpaceBeforeCapitalLetter, paymentStatusColor, redemptionCalculation, returnPriceWithCurrency } from "../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../..";
import { useIntl } from "react-intl";
import { OptionalProductObject } from "../../app/models/customerSalesOrder";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import CustomerPurchaseDetailModal from "../CustomerPurchase/Modal/CustomerPurchaseDetailModal";
import CustomerPurchasePackageDetail from "../CustomerPurchase/Modal/CustomerPurchasePackageDetail";
import CustomerPurchaseCart from "../CustomerPurchase/subView/CustomerPurchaseCart";
import { useLocation } from "react-router-dom";
import { breadCrumbObject } from "../../app/models/common";
import CustomerPurchaseConfimationModal from "../CustomerPurchase/Modal/CustomerPurchaseConfimationModal";
import moment from "moment";
import GeneralInput from "../../app/components/form/GeneralInput";
import Borderline from "../../app/components/form/BorderLine";
import GeneralInputForModal from "../../app/components/form/GeneralInputForModal";

const CustomerProfilePurchase = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { employeeStore, customerSalesOrderStore, commonStore } = useStore();
  const { errorMessage, successMessage, setSuccessMessage, setErrorMessage } = commonStore;
  const { getCustomerSalesOrderRedeem, submitCustomerSalesOrderRedeem } = customerSalesOrderStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedCustomerNo, setSelectedCustomerNo] = useState<string>("");
  const [selectedCustomerMobileNo, setSelectedCustomerMobileNo] = useState<string>("");
  const [customerPurchasePackageDetailModal, setCustomerPurchasePackageDetailModal] = useState(false);
  const [selectedPackageDetail, setSelectedPackageDetail] = useState<CustomerPurchasePackage | undefined>(undefined);
  const [selectedRedeemPurchaseDetails, setSelectedRedeemPurchaseDetails] = useState<RedeemPurchaseDetailObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [customerPurchaseList, setCustomerPurchaseList] = useState<any>([])
  const [customerPurchaseDetailModal, setCustomerPurchaseDetailModal] = useState(false);
  const [objBackDate, setObjBackDate] = useState({isBackdate: false, performedDateTime: ""});

  const [selectedCustomerPurchaseDetail, setSelectedCustomerPurchaseDetail] = useState<RedeemPurchaseDetailObject | undefined>(undefined);
  const location = useLocation();
  const [customerSalesOrderRedeemList, setCustomerSalesOrderRedeemList] = useState<any>(undefined);
  const [breadCrumbList, setBreadCrumbList] = useState<breadCrumbObject[]>([]);
  const [itemType, setItemType] = useState("");
  const [blnBlockRedemptionSubmit, setBlnBlockRedemptionSubmit] = useState(false);
  const [customerPurchaseConfirmationModal, setCustomerPurchaseConfirmationModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionBackdateCustomerDeliverOrder = checkPermission([PermissionConstants.BackdateCustomerDeliverOrder]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      customerNo: selectedCustomerNo,
      customerMobileNo: selectedCustomerMobileNo,
      branchId: getBranchId(),
      branchName: getBranchName(),
      isBackdate: false,
      performedDateTime: "",
      note: "",
      redeemPurchaseDetails: []
    },
    validationSchema: Yup.object({
      redeemPurchaseDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })),
    }),
    onSubmit: async (values) => {
      try {
        const valuesTemp: any = _.cloneDeep(values);

        let blnValidationRequired = false;
        let redeemPurchaseDetailsTemp = valuesTemp.redeemPurchaseDetails.map((value)=> {
          if (value.availableOptionalProducts) {
            delete value["availableOptionalProducts"];
          }
          if (value.availableQuantity) {
            delete value["availableQuantity"];
          }
          if (value.availableSessions) {
            delete value["availableSessions"];
          }
          if (value.selectedId) {
            delete value["selectedId"];
          }
          if (value.serviceProductUsed) {
            value.selectedProductIds = value.serviceProductUsed.map((valueProductUsed)=> valueProductUsed.value)
            delete value["serviceProductUsed"];
          }
          if (value.mandatoryProductsUsed) {
            delete value["mandatoryProductsUsed"];
          }
          if (value.mandatoryProducts) {
            delete value["mandatoryProducts"];
          }
          if (value.customerInvoiceId) {
            value.salesOrderId = value.customerInvoiceId;
            delete value["customerInvoiceId"];
          }
          if (value.customerPurchaseId) {
            value.salesOrderDetailId = value.customerPurchaseId;
            delete value["customerPurchaseId"];
          }
          if (value.customerPurchaseDetailId) {
            value.salesOrderDetailRedeemId = value.customerPurchaseDetailId;
            delete value["customerPurchaseDetailId"];
          }
          if (value.availableOptionalBranchMachines) {
            delete value["availableOptionalBranchMachines"];
          }
          if (value.optionalBranchMachinesUsed) {
            value.selectedMachines = value.optionalBranchMachinesUsed.map((valueOptionalBranchMachinesUsed)=> ({branchMachineId: valueOptionalBranchMachinesUsed.value, consumeQuantity: Number(valueOptionalBranchMachinesUsed.consumeQuantity)}));
            delete value["optionalBranchMachinesUsed"];
          }
          
          if (value.validationRequired) {
            blnValidationRequired = true;
          }
          return value;
        })

        if (blnValidationRequired) {
          if (Constants.validationError) {
            console.log (`Validation Errors :: Line 116`)
          }
          setErrorMessage(intl.formatMessage({ id: "ValidationError"}))
          return;
        }

        valuesTemp.redeemCustomerSalesOrderRequestDetail = redeemPurchaseDetailsTemp;
        delete valuesTemp["redeemPurchaseDetails"];

        if (customerPurchaseDetailModal) {
          valuesTemp.isBackdate= objBackDate.isBackdate;
          valuesTemp.performedDateTime= objBackDate.performedDateTime;
          validation.values.isBackdate = objBackDate.isBackdate;
          validation.values.performedDateTime = objBackDate.performedDateTime;
        }
        if (valuesTemp.performedDateTime) {
          valuesTemp.performedDateTime = moment(valuesTemp.performedDateTime).format(Constants.defaultDateFormat);
        }

        if (!customerPurchaseConfirmationModal){
          setCustomerPurchaseConfirmationModal(true);
        }
        else {
          setBlnBlockRedemptionSubmit(true);
          let result = await submitCustomerSalesOrderRedeem(valuesTemp);
          if (result?.status == Constants.success) {
            setTimeout(()=> {
              if (result?.data?.redeemedDeliverOrders) {
                if (result.data.redeemedDeliverOrders.length > 0) {
                  history.push(`/${RoutesList.customerRedemption}/edit/${result.data.redeemedDeliverOrders[0].customerDeliverOrderId}`, {detail : { customerId: valuesTemp.customerId }})
                }
              }
            }, Constants.timeoutNavigation+1000)
          }
        }
      }
      finally {
        setTimeout(()=> {
          setBlnBlockRedemptionSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    async function fetchCustomerPurchaseListAPI() {
      let blnParamsFound = false;
      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.customerName && location.state.detail.customerId && location.state.detail.customerNo
            && location.state.detail.customerMobileNo && location.state.detail.customerSalesOrderId && location.state.detail.customerSalesOrderDetailId) 
          {
            blnParamsFound = true;
            setSelectedCustomer(location.state.detail.customerName);
            setSelectedCustomerId(location.state.detail.customerId);
            setSelectedCustomerNo(location.state.detail.customerNo);
            setSelectedCustomerMobileNo(location.state.detail.customerMobileNo);
            setItemType(location.state.detail.itemType);
            setBreadCrumbList([
              { title: intl.formatMessage({id: "Customer"}), urlPath: RoutesList.customer },
              { title: `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "CustomerProfile"})}`, urlPath: `${RoutesList.customer}/view/${location.state.detail.customerId}` },
            ])
          }
        }
      }

      if (!blnParamsFound) {
        navigateToCustomerPurchase();
        return;
      }
      
      setLocalLoading(true);
      setCustomerSalesOrderRedeemList([]);
      let aryAPI: any = [
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true }),
        getCustomerSalesOrderRedeem({ pageNumber: 1, pageSize: Constants.maxPageSize, salesOrderId: location.state.detail.customerSalesOrderId, salesOrderDetailId: location.state.detail.customerSalesOrderDetailId, branchId: getBranchId() })
      ];
      let resultAPI = await Promise.all(aryAPI);
      setLocalLoading(false);
      setCustomerSalesOrderRedeemList(resultAPI[1]);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.CreateCustomerDeliverOrder], true)) {
      return;
    }    

    fetchCustomerPurchaseListAPI();

    return (()=> {
      setCustomerSalesOrderRedeemList([]);
    })
  }, [])

  useEffect(()=> {
    if (customerSalesOrderRedeemList) {
      let customerPurchaseListTemp: any = [];
      customerSalesOrderRedeemList.map((valueCustomerSalesOrderRedeemListTemp)=> {
        let aryDetailTemp : any = [];
        valueCustomerSalesOrderRedeemListTemp.redeemCustomerSalesOrderDetail.map((valueRedeemCustomerSalesOrderDetailTemp)=> {
          let indexDetail = _.findIndex(aryDetailTemp, {id: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId})
          if (indexDetail > -1) {
            let detailTemp : any = {
              ...aryDetailTemp[indexDetail],
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && { availableSessions : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct && { maxRedeemProductQuantity : valueRedeemCustomerSalesOrderDetailTemp.packageTotalRedeemCount, redeemedProductQuantity: valueRedeemCustomerSalesOrderDetailTemp.packageRedeemedCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && { optionalProductCost : valueRedeemCustomerSalesOrderDetailTemp.totalPrice, redeemedProductCost: valueRedeemCustomerSalesOrderDetailTemp.redeemedPrice, optionalAvailableProductCost: valueRedeemCustomerSalesOrderDetailTemp.availablePrice},
              availableProducts: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageProduct, aryDetailTemp[indexDetail].availableProducts) : aryDetailTemp[indexDetail].availableProducts,
              availableServices: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageService, aryDetailTemp[indexDetail].availableServices) : aryDetailTemp[indexDetail].availableServices,
              status: valueRedeemCustomerSalesOrderDetailTemp.status,
              paymentStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus
            }

            aryDetailTemp[indexDetail] = detailTemp;
          }
          else {
            let detailTemp : any = {
              id: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId,
              customerInvoiceNo: valueCustomerSalesOrderRedeemListTemp.salesOrderNo,
              customerInvoiceId: valueCustomerSalesOrderRedeemListTemp.salesOrderId,
              salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
              type: valueRedeemCustomerSalesOrderDetailTemp.purchaseType,
              packageId:  valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId,
              packageName: valueRedeemCustomerSalesOrderDetailTemp.packageName,
              productName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
              quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
              redeemdedQuantity: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
              availableProducts: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageProduct, []) : [],
              availableServices: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageService, []) : [],
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && { availableSessions : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct && { maxRedeemProductQuantity : valueRedeemCustomerSalesOrderDetailTemp.packageTotalRedeemCount, redeemedProductQuantity: valueRedeemCustomerSalesOrderDetailTemp.packageRedeemedCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && { optionalProductCost : valueRedeemCustomerSalesOrderDetailTemp.totalPrice, redeemedProductCost: valueRedeemCustomerSalesOrderDetailTemp.redeemedPrice, optionalAvailableProductCost: valueRedeemCustomerSalesOrderDetailTemp.availablePrice},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.productOnly && { productId : valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId },
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.serviceTreatmentOnly && { serviceTreatmentId : valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId, availableSessions : valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount, optionalProducts: valueRedeemCustomerSalesOrderDetailTemp.optionalProducts, mandatoryProducts: valueRedeemCustomerSalesOrderDetailTemp?.mandatoryProducts || [], optionalBranchMachines: valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines || []},
              status: valueRedeemCustomerSalesOrderDetailTemp.status,
              paymentStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus
            }
            aryDetailTemp.push(detailTemp)
          }
        })
        customerPurchaseListTemp.push({
          id: valueCustomerSalesOrderRedeemListTemp.salesOrderNo,
          customerInvoiceBalance: valueCustomerSalesOrderRedeemListTemp.balance,
          customerInvoiceStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus,
          details: aryDetailTemp
        })
      })

      if (customerPurchaseListTemp.length > 0) {
        if (customerPurchaseListTemp[0].details) {
          if (customerPurchaseListTemp[0].details.length > 0) {
            let valueTemp = _.cloneDeep(customerPurchaseListTemp[0].details[0]);
            if (itemType === Constants.package) {
              let availableProducts : any = [];
              let availableOptionalProducts : any = [];
                valueTemp.availableProducts.map((valueProducts)=> {
                if (valueProducts.isOptional) {
                  availableOptionalProducts.push(valueProducts)
                }
                else {
                  availableProducts.push(valueProducts);
                }
              })
              valueTemp.availableProducts = availableProducts;
              valueTemp.availableOptionalProducts = availableOptionalProducts;
              setSelectedPackageDetail(valueTemp);
              setCustomerPurchasePackageDetailModal(true);
            }
            else if (itemType === Constants.serviceTreatmentOnly) {
              let  blnProceed = redemptionCalculation(1, valueTemp.availableSessions, 1);
              if (!blnProceed) {
                navigateToCustomerPurchase();
                return;
              }
              let serviceProductUsedTemp = valueTemp.optionalProducts.map((optionalProductsTemp)=> ({label: optionalProductsTemp.productName, value: optionalProductsTemp.productId}))
              let serviceMandatoryProductUsedTemp = valueTemp.mandatoryProducts.map((mandatoryProductsTemp)=> ({
                                                      label: `${mandatoryProductsTemp.productName}`, 
                                                      value: `${mandatoryProductsTemp.productName}`
                                                    }))
              let optionalBranchMachinesUsedTemp = valueTemp.optionalBranchMachines.map((valueOptionalBranchMachines)=> ({label: `${valueOptionalBranchMachines.branchMachineName} ${valueOptionalBranchMachines.serialNumber ? `(${valueOptionalBranchMachines.serialNumber})` : ""}`, value: valueOptionalBranchMachines.branchMachineId, consumeQuantity: valueOptionalBranchMachines.advisableConsumeQuantity || 0}));

              let objOrderDetails : RedeemPurchaseDetailObject = {
                name: valueTemp.productName,
                customerInvoiceId: valueTemp.customerInvoiceId,
                customerInvoiceNo: valueTemp.customerInvoiceNo || "",
                purchaseRedeemType: 2, // Service 
                customerPurchaseId: valueTemp.id || "",
                purchaseDetailRedeemType: 3, // 0 = Package Service, 1 = Package Product, 2 = Product Only, 3 = Service Only, 4 = Package Optional Product
                packageId: valueTemp.packageId,
                packageName: valueTemp.packageName,
                customerPurchaseDetailId: valueTemp.serviceTreatmentId,
                salesOrderDetailRedeemType: valueTemp.salesOrderDetailRedeemType,
                count: 1,
                serviceById: null,
                serviceByName: null,
                availableOptionalProducts: serviceProductUsedTemp,
                mandatoryProducts: serviceMandatoryProductUsedTemp,
                mandatoryProductsUsed: serviceMandatoryProductUsedTemp,
                serviceProductUsed: [],
                availableOptionalBranchMachines: optionalBranchMachinesUsedTemp,
                optionalBranchMachinesUsed: [],
                availableSessions: valueTemp.availableSessions,
                redeemedSessions: valueTemp.redeemdedQuantity,
                quantity: valueTemp.quantity
              }
              setSelectedCustomerPurchaseDetail(_.cloneDeep(objOrderDetails))
              setCustomerPurchaseDetailModal(true);
            }
            else {
              let  blnProceed = redemptionCalculation(2, valueTemp.quantity-valueTemp.redeemdedQuantity, 1);
              if (!blnProceed) {
                navigateToCustomerPurchase();
                return;
              }
              let objOrderDetails : RedeemPurchaseDetailObject = {
                name: valueTemp.productName,
                customerInvoiceId: valueTemp.customerInvoiceId,
                customerInvoiceNo: valueTemp.customerInvoiceNo,
                salesOrderDetailRedeemType: valueTemp.salesOrderDetailRedeemType,
                purchaseRedeemType: 1, //Product
                customerPurchaseId: valueTemp.id || "",
                purchaseDetailRedeemType: 2, //Product Only
                customerPurchaseDetailId: valueTemp.productId,
                redeemdedQuantity: valueTemp.redeemdedQuantity,
                quantity: valueTemp.quantity,
                count: 1
              }
              setSelectedCustomerPurchaseDetail(_.cloneDeep(objOrderDetails))
              setCustomerPurchaseDetailModal(true);
            }
          }
        }
      }
    }
  }, [customerSalesOrderRedeemList])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(()=> {
    if (itemType !== Constants.package && !initialCallAPI && !localLoading && !customerPurchaseConfirmationModal) {
      validation.handleSubmit();
    }
  }, [validation.values])

  const productRedemptionEntitlement = (redeemPurchaseDetails: RedeemPurchaseDetailObject[], selectedPackageDetail: CustomerPurchasePackage) => {
    if (selectedPackageDetail.paymentStatus === Constants.partial) {
      setErrorMessage(`${intl.formatMessage({id: "RequiredFullRedemption"})}`)
      return false;
    }

    return true;
  }

  const convertPackageServiceOrProduct = (valueRedeemCustomerSalesOrderDetailTemp, typeOfPackage, aryExisting) => {
    let aryTemp : any = _.cloneDeep(aryExisting) || [];

    if ((valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct) && typeOfPackage === Constants.packageProduct) {
      aryTemp.push({
        productId: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId,
        productName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
        isOptional: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct ? false : true,
        quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
        availableQuantity: valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount,
        redeemedQuantity: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
        salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
        ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && {unitPrice: valueRedeemCustomerSalesOrderDetailTemp.unitPrice}
      })
    }
    else if (valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && typeOfPackage === Constants.packageService) {
      let availableProductsTemp : OptionalProductObject[] = [];
      valueRedeemCustomerSalesOrderDetailTemp.optionalProducts.map((valueOptionalProductsTemp)=> {
        availableProductsTemp.push({
          ...valueOptionalProductsTemp,
        })
      })

      let mandatoryProductsTemp = [];
      if (valueRedeemCustomerSalesOrderDetailTemp.mandatoryProducts) {
        mandatoryProductsTemp = valueRedeemCustomerSalesOrderDetailTemp.mandatoryProducts.map((valueMandatoryProducts: MandatoryProductsObject)=> {
          return ({
            label:`${valueMandatoryProducts. productName}`,
            value:`${valueMandatoryProducts. productName}`,
          })
        })
      }
      
      let optionalBranchMachinesUsedTemp = [];
      if (valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines) {
        optionalBranchMachinesUsedTemp = valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines.map((valueTemp)=> ({label: `${valueTemp.branchMachineName} ${valueTemp.serialNumber ? `(${valueTemp.serialNumber})` : ""}`, value: valueTemp.branchMachineId, consumeQuantity: 0}));
      }

      aryTemp.push({
        serviceTreatmentId: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId,
        serviceTreatmentName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
        quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
        availableSessions: valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount,
        redeemedSessions: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
        salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
        availableProducts: availableProductsTemp,
        mandatoryProducts: mandatoryProductsTemp,
        optionalBranchMachines: valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines || [],
      })
    }
    return aryTemp;
  } 

  const navigateToCustomerPurchase = () => {
    history.push(`/${RoutesList.customerPurchase}`);
  }

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "CustomerProfilePurchase"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({id: "CustomerProfilePurchase"})}</div>
            </Col>
          </Row>
          <BreadCrumb breadCrumbList={breadCrumbList} activeBreadCrumbTitle={intl.formatMessage({id: "CustomerProfilePurchase"})}/>      
          {
            localLoading && !customerPurchasePackageDetailModal
            ?
            <Loading />
            :
            <>
              <Form
                id={Constants.redemptionOrderForm}
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                onBlur={() => {
                  dismissMessage();
                }}>
              </Form>
              {
                customerPurchasePackageDetailModal &&
                <CustomerPurchasePackageDetail
                  blnShow={customerPurchasePackageDetailModal}
                  setModal={setCustomerPurchasePackageDetailModal}
                  reloadData={()=> {}}
                  selectedCustomer={selectedCustomer}
                  selectedCustomerId={selectedCustomerId}
                  selectedRedeemPurchaseDetails={selectedRedeemPurchaseDetails}
                  selectedPackageDetail={selectedPackageDetail}
                  setSelectedPurchaseDetail={setSelectedPackageDetail}
                  productRedemptionEntitlement={productRedemptionEntitlement}
                  employeeList={employeeDropdownList}
                  customerPurchaseList={customerPurchaseList}
                  validation={validation}
                  blnDisableModalView={true}
                  disableModalViewFunction={()=> {validation.handleSubmit()}}
                  loadingDisableModalView={validation.isSubmitting}
                />
              }
              {
                customerPurchaseDetailModal &&
                <CustomerPurchaseDetailModal
                  blnShow={customerPurchaseDetailModal}
                  setModal={setCustomerPurchaseDetailModal}
                  selectedCustomerPurchaseDetail={selectedCustomerPurchaseDetail}
                  setSelectedCustomerPurchaseDetail={setSelectedCustomerPurchaseDetail}
                  productRedemptionEntitlement={productRedemptionEntitlement}
                  employeeList={employeeDropdownList}
                  customerPurchaseList={customerPurchaseList}
                  validation={validation}
                  blnDisableModalView={true}
                  blnPromptInfoMessage={true}
                  extraTopUI={()=> 
                    (
                      <>
                        {
                          blnPermissionBackdateCustomerDeliverOrder
                          &&
                          <GeneralInputForModal
                            title={""}
                            name="isBackdate"
                            className="margin-top--12"
                            type="checkbox"
                            field={objBackDate}
                            setFieldValue={setObjBackDate}
                            disabled={validation.isSubmitting}
                            childrenUI={
                              <Label className="margin-bottom-0 margin-left-4">
                                {intl.formatMessage({ id: "IsApplyPerformedDateTime" })}
                              </Label>
                            }
                            onChangeFunctionWithValueReturn={(valueChecked)=> {
                              setObjBackDate((valueTemp)=> ({...valueTemp, "performedDateTime": valueChecked ? moment().format(Constants.textInputDateAndTimeFormat) : ""}))
                            }}
                          />
                        }
                        {
                          blnPermissionBackdateCustomerDeliverOrder
                          &&
                          objBackDate.isBackdate
                          &&
                          <div>
                            <GeneralInputForModal
                              title={""}
                              name="performedDateTime"
                              className="margin-top--8"
                              type="datetime-local"
                              field={objBackDate}
                              setFieldValue={setObjBackDate}
                              disabled={validation.isSubmitting}
                            />
                          </div>
                        }
                        {
                          (blnPermissionBackdateCustomerDeliverOrder || validation.values.isBackdate)
                          &&
                          <Borderline/>
                        }
                      </>
                    )
                  }
                  loadingDisableModalView={validation.isSubmitting}/>
              }
              {
                customerPurchaseConfirmationModal &&
                <CustomerPurchaseConfimationModal
                  blnShow={customerPurchaseConfirmationModal}
                  setModal={setCustomerPurchaseConfirmationModal}
                  validation={validation}
                  blnBlockRedemptionSubmit={blnBlockRedemptionSubmit}
                  blnPermissionBackdateCustomerDeliverOrder={blnPermissionBackdateCustomerDeliverOrder} />
              }
            </>
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(CustomerProfilePurchase);
