import { PaginationRequestBody } from "../models/pagination";
import { CustomerChangePaymentMethodListObject, CustomerChangePaymentMethodRequestObject, CustomerChangePaymentMethodReviewObject, CustomerChangePaymentMethodDetailObject, CustomerChangePaymentMethodWithSalesOrderDetailObject, CustomerChangePaymentMethodWithSalesOrderObject } from "../models/customerChangePaymentMethod";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class customerChangePaymentMethodStore {
  customerChangePaymentMethodList: CustomerChangePaymentMethodListObject[] = [];
  customerChangePaymentMethodDetail: CustomerChangePaymentMethodDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerChangePaymentMethodList = [];
    this.customerChangePaymentMethodDetail = undefined;
  }

  setCustomerChangePaymentMethodDetail = (customerChangePaymentMethodDetail: CustomerChangePaymentMethodDetailObject | undefined) => {
    this.customerChangePaymentMethodDetail = customerChangePaymentMethodDetail;
  }
  
  getCustomerChangePaymentMethod = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerChangePaymentMethod = await agent.CustomerChangePaymentMethod.customerChangePaymentMethodList(PaginationRequestBody);
      runInAction(() => {
        this.customerChangePaymentMethodList = resultCustomerChangePaymentMethod.data;
        store.commonStore.setTotalItem(resultCustomerChangePaymentMethod.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerChangePaymentMethodList = [];
    }
  }

  getCustomerChangePaymentMethodWithId = async (id: string) => {
    try{
      const resultCustomerChangePaymentMethodDetail = await agent.CustomerChangePaymentMethod.customerChangePaymentMethodDetail(id);
      runInAction(() => {
        this.customerChangePaymentMethodDetail = resultCustomerChangePaymentMethodDetail;
      });
      return Promise.resolve(resultCustomerChangePaymentMethodDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerChangePaymentMethodDetail = undefined;
    }
  }

  getCustomerChangePaymentMethodWithSalesOrder = async (CustomerChangePaymentMethodWithSalesOrderRequestBody: CustomerChangePaymentMethodWithSalesOrderObject) => {
    try{
      const resultCustomerChangePaymentMethodWithSalesOrder = await agent.CustomerChangePaymentMethod.customerChangePaymentMethodWithSalesOrder(CustomerChangePaymentMethodWithSalesOrderRequestBody);
      return Promise.resolve(resultCustomerChangePaymentMethodWithSalesOrder);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  requestCustomerChangePaymentMethod = async (customerChangePaymentMethodRequestBody: CustomerChangePaymentMethodRequestObject) => {
    try{
      let resultRequestCustomerChangePaymentMethod = await agent.CustomerChangePaymentMethod.requestCustomerChangePaymentMethod(customerChangePaymentMethodRequestBody);
      store.commonStore.setSuccessMessage(`ChangePaymentMethodRequestSuccess`)
      return Promise.resolve(resultRequestCustomerChangePaymentMethod);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewCustomerChangePaymentMethod = async (customerChangePaymentMethodRequestBody: CustomerChangePaymentMethodReviewObject) => {
    try{
      let resultReviewChangePaymentMethod = await agent.CustomerChangePaymentMethod.customerChangePaymentMethodReview(customerChangePaymentMethodRequestBody);
      store.commonStore.setSuccessMessage(`ChangePaymentMethodReviewSuccess`)
      
      if(resultReviewChangePaymentMethod){
        return Promise.resolve({status: Constants.success, data: resultReviewChangePaymentMethod});
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomerChangePaymentMethod  = async (id: string, name: string) => {
    try {
      await agent.CustomerChangePaymentMethod.deleteCustomerChangePaymentMethod(id);
      store.commonStore.setSuccessMessage(`ChangePaymentMethodDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}