import React, { useEffect, useState } from 'react'
import {
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import LineBreakWithTittle from '../form/LineBreakWithTittle';
import _ from 'lodash';

interface Props {
  name: string;
  title: string;
  blnEditable: boolean;
  blnSelectAll: boolean;
  blnValidation?: boolean;
  data: any;
  blnSingleSelect: boolean;
  validation: any;
  disabled: boolean;
  customDisplayField?: string;
  customCheckedField?: string;
  className?: string;
}

export default function TableWithCheckBox(props: Props) {
  const [aryData, setData] = useState(_.cloneDeep(props.data));
  const [blnSelectAllChecked, setSelectAllChecked] = useState(false);
	const checkedField = props.customCheckedField || "isActive";

  useEffect(()=> {
    checkAllTicked();
  }, [])

  const markedAll = (checked: boolean) => {
    for (var a=0; a<aryData.length; a++) {
      aryData[a][checkedField] = checked;
    }

    if (!props.blnSingleSelect) {
      props.validation.setFieldValue(props.name, aryData);
    }
  } 

  const onChangeChecked = (e: React.ChangeEvent<any>, rowIndex: number) => {
    if (props.blnSingleSelect) {
      markedAll(false);
    }
       
    aryData[rowIndex][checkedField] = e.target.checked;
		props.validation.setFieldValue(props.name, aryData);

    if (!props.blnSingleSelect) {
      checkAllTicked();
    }
  }

  const checkAllTicked = () => {
    let blnAllChcked = true;
    for (var a=0; a<aryData.length; a++) {
      if (aryData[a][checkedField] == false) {
        blnAllChcked = false;
        break;
      }

      if (!blnAllChcked) 
        break;
    }
    setSelectAllChecked(blnAllChcked)
  }

  const displayCheckBox = (rowIndex, rowValue) => {
		return (
			<Col xl={"12"}>
        <div className="flex-direction-row">
          <Input
            type="checkbox"
            onChange={(e) => onChangeChecked(e, rowIndex)}
            checked={rowValue[checkedField]}
            disabled={props.disabled || !props.blnEditable}
            onBlur={props.validation.handleBlur}
          />
          <Label className="margin-left-4">{props.customDisplayField ? rowValue[props.customDisplayField] : rowValue.name}</Label>
        </div>
			</Col>
		);
	};

  const displayEmptyRow = () => {
    return (
      // <Label>There's no content available</Label>
      <LineBreakWithTittle title={`There's no content available`} color='grey'/>
    )
  }

  return (
    <div className={`${props.className} tab_bar_border`}>
      <Label className='mb-3'>
        {props.blnValidation && <Label style={{color: 'red'}}>{`*`}</Label>}
        <Label className="margin-bottom-0">{props.title}</Label>
      </Label>
      {
        (props.blnSelectAll || props.blnEditable)
        && 
        <>
          <Row className='margin-bottom-2'>
            <Col xl={"12"}>
              <Input
                type="checkbox"
                onChange={(e)=>{
                  setSelectAllChecked(e.target.checked)
                  markedAll(e.target.checked)
                }}
                checked={blnSelectAllChecked}
                disabled={props.disabled || !props.blnEditable}
                onBlur={props.validation.handleBlur}
              />
              <Label className="margin-bottom-4 margin-left-4">Select All</Label>
            </Col>
          </Row>
          <div className='splitLine'/>
        </>
      }

      {
        <Row>
          {
            aryData.length > 0
            ?
              aryData.map((valueTemp, index)=> {
                return (
                  <Col key={`${valueTemp.id} ${index}`} xl={"12"}>
                    <Row className={`flex-align-items-center'}`} key={`${valueTemp.id} ${index}`}>
                      {displayCheckBox(index, valueTemp)}
                    </Row>
                  </Col>
                )
              })
            :
            displayEmptyRow()
          }
        </Row>        
      }
    </div>
  )
}
