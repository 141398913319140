import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { IncludesLocationPathName, goodsReceiveNoteStatusColor, newSpaceBeforeCapitalLetter, checkPermission } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const GoodsReceiveNoteInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { goodsReceiveNoteStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
  } = commonStore;
  const { goodsReceiveNoteDetail, setGoodsReceiveNoteDetail, getGoodsReceiveNoteWithId } = goodsReceiveNoteStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
} ${intl.formatMessage({id: "GoodsReceiveNote"})}`;
  const breadCrumbList = [{ title: intl.formatMessage({id: "GoodsReceiveNote"}), urlPath: RoutesList.goodsReceiveNote }];
  const invoiceFullDetail = goodsReceiveNoteDetail || {
    id: "",
    grnReferenceNo: "",
    createdAt: "",
    deliveryDate: "",
    receivedDate: "",
    inboundNoticeId: "",
    purchaseOrderId: "",
    purchaseOrderNo: "",
    businessEntityId: "",
    businessEntityName: "",
    supplierName: "",
    warehouseName: "",
    grnStatus: "",
    note: "",
    supplierContactPerson: "",
    supplierContactNo: "",
    receiverContactPerson: "",
    receiverContactNo: "",
    inboundNoticeNo: "",
    grnDetails: [{
      productId: "",
      productName: "",
      quantity: 0,
      receivedQuantity: 0,
      allowedQuantity: 0,
      remark: "",
    }],
  }

  const paidStatus = invoiceFullDetail.grnStatus == Constants.paid;

  async function fetchGoodsReceiveNoteDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getGoodsReceiveNoteWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateGRN], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateGRN], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageGRN], true)) {
        return;
      }
    }
    
    fetchGoodsReceiveNoteDetailAPI();

    return (() => {
      if (id && !addAction) {
        setGoodsReceiveNoteDetail(undefined);
      }
    })
  }, []);


  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 ">
          {label}
        </h6>
        <h6 className="">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <MetaTags>
          <title>{displayTitle}</title>
        </MetaTags>
        <Container fluid>
          <BreadCrumb
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={displayTitle}
          />
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading ? (
            <Loading />
          ) : (
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "GoodsReceiveNote" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({id: "GoodsReceiveNoteReferenceNo"})}:`, invoiceFullDetail.grnReferenceNo)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Date"})}:`, moment(invoiceFullDetail.createdAt).format(Constants.displayDateFormat))}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Time"})}:`, moment(invoiceFullDetail.createdAt).format(Constants.displayTimeFormat))}
                        </Col>
                        <hr />
                        <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Billing" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Company"})}:`, invoiceFullDetail.supplierName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Name"})}:`, invoiceFullDetail.supplierContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ContactNo"})}.:`, invoiceFullDetail.supplierContactNo)}
                              {/* {displayBillingShippingRowData(`${intl.formatMessage({id: "Address"})}:`, invoiceFullDetail.purchaseFromAddress)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "PaymentMethod"})}:`, invoiceFullDetail.purchasePaymentMethod)} */}
                            </div>
                          </Col>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Receiver"})}:`, invoiceFullDetail.warehouseName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ShippingContactPerson"})}:`, invoiceFullDetail.receiverContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ContactNo"})}.:`, invoiceFullDetail.receiverContactNo)}
                              {/* {displayBillingShippingRowData(`${intl.formatMessage({id: "ShippingAddress"})}:`, invoiceFullDetail.shippingAddress)} */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <br /><br />

                      <address>
                        <strong>{intl.formatMessage({id: "Status"})}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.grnStatus}
                            statusColorFunction={goodsReceiveNoteStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "Product(s)"})}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({id: "Name"})}</th>
                              <th></th>
                              <th></th>
                              <th className="text-center">{intl.formatMessage({id: "OrderedQuantity"})}</th>
                              <th className="text-center">{intl.formatMessage({id: "ReceivedQuantity"})}</th>
                              <th className="text-end">{intl.formatMessage({id: "RemainingQuantity"})}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.grnDetails.map((item, key) => (
                                <Fragment key={`${item.productName}_${key}`}>
                                  <tr key={key}>
                                    <td>{item.productName}</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center">{item.quantity}</td>
                                    <td className="text-center">{item.receivedQuantity}</td>
                                    <td className="text-end">{(item.quantity - item.receivedQuantity) < 0 ? 0 : item.quantity - item.receivedQuantity}</td>
                                    <td style={{ width: "40px" }}><Input type='checkbox' className="margin-top-0" style={{width:"18px", height:"18px"}} disabled={true}></Input></td>
                                  </tr>
                                  {
                                    item.additionalDetails
                                    ?
                                    item.additionalDetails.map((additionalDetailsTemp, index) => (
                                      <tr key={`${additionalDetailsTemp.supplierReferenceNo}_${index}`}>
                                        <td style={{borderRightWidth: '1px'}}>
                                          <i className="bx bx-subdirectory-right"></i> {`${intl.formatMessage({id: "SupplierReferenceNo"})} : ${additionalDetailsTemp.supplierReferenceNo}`}
                                        </td>
                                        <td style={{borderRightWidth: '1px'}}>
                                          {`${intl.formatMessage({id: "Quantity"})} : ${additionalDetailsTemp.quantity}`}
                                        </td>
                                        <td style={{borderRightWidth: '1px'}}>
                                          {`${intl.formatMessage({id: "ReceivedQuantity"})} : ${additionalDetailsTemp.receivedQuantity}`}
                                        </td>
                                        <td style={{borderRightWidth: '1px'}}>
                                          {`${intl.formatMessage({id: "IsFreeOfCharge"})} : ${additionalDetailsTemp.isFOC ? intl.formatMessage({id: "Yes"}) : intl.formatMessage({id: "No"})}`}
                                        </td>
                                        <td colSpan={3}/>
                                      </tr>
                                    ))
                                    :
                                    null
                                  }
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={`py-2 mt-3`}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "Note"})} :
                        </h3>
                      </div>
                      <GeneralTextAreaForModal
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        field={invoiceFullDetail}
                        setFieldValue={() => { }} />
                      <div className="d-print-none">
                        <GeneralSubmitAndCancelBtn
                          className="flex-right"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({id: "Back"})}
                          // cancelButtonBesidePrint={true}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}

        </Container>
      </div>
    </div>
  );
};

export default observer(GoodsReceiveNoteInvoiceDetail);
