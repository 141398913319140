import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from '../../app/components/form/GeneralInput';
import MyButton from '../../app/components/form/MyButton';
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import TableCheckBoxWithSectionHeader from "../../app/components/table/TableCheckBoxWithSectionHeader";
import classNames from "classnames";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from '../../app/common/function/function';
import AlertMessage from '../../app/components/alert/AlertMessage';
import Loading from "../../app/components/loading/Loading";
import _ from 'lodash';
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const BusinessEntityDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { businessEntityStore, countryStore, brandStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { addBusinessEntity, updateBusinessEntity, businessEntityDetail, setBusinessEntityDetail, getBusinessEntityWithId } = businessEntityStore;
  const { brandDropdownList, getBrandDropdown } = brandStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const addAction = IncludesLocationPathName('add');
  const viewAction = IncludesLocationPathName('view');
  const displayTitle = `${
    addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
  } ${intl.formatMessage({id: "BusinessEntity"})}`;
  const breadCrumbList = [{title: intl.formatMessage({id: "BusinessEntity"}), urlPath: RoutesList.businessEntity}];
  const [activeTab, setActiveTab] = useState("1");
  const [brands, setBrands] = useState(brandDropdownList);
  const tabBar = [intl.formatMessage({id: "Branch"}).toUpperCase()];
  const pageSize = Constants.maxPageSize;
  const [loadingState, setLoadingState] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const blnPermissionUpdateBusinessEntity = checkPermission([PermissionConstants.UpdateBusinessEntity]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: (businessEntityDetail || {
      name: "",
      companyNumber: "",
      taxNumber: "",
      contactNumber: "",
      email: "",
      addressLine1: '',
			addressLine2: '',
			city: '',
			postcode: '',
			countryId: '',
			countryName: '',
			stateId: '',
			stateName: '',
      isActive: false,
      brandId: "",
      brandName: "",
      branches: [],
      displayOrder: 1,
    }),
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "BusinessEntityName"})})),
      brandId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Brand"})})),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "DisplayOrder"})})),
    }),
    onSubmit: async (values) => {
      const valuesTemp : any = _.cloneDeep(values);
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }
      
      try {
        if (addAction) {
          await addBusinessEntity(valuesTemp)
        }
        else {
          await updateBusinessEntity(valuesTemp)
        }
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchBusinessEntityDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBrandDropdown(),
        getCountryDropdown()
      ];
      if (id && !addAction) {
        aryAPI.push(getBusinessEntityWithId(id))
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
			setBusinessEntityDetail(undefined);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateBusinessEntity], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateBranch], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageBusinessEntity], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateBusinessEntity){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.businessEntity}/edit/${id}`)} })
    }

    fetchBusinessEntityDetailAPI();
    
    return (()=> {
      if (id && !addAction) {
        setBusinessEntityDetail(undefined);
      }
    })
  }, [])

  useEffect(()=> {
    const brandListTemp : any = _.cloneDeep(brandDropdownList);
    const selectedIndex = _.findIndex(brandDropdownList, {id: businessEntityDetail?.brandId});
    if (selectedIndex > -1) {
      brandListTemp[selectedIndex].isActive = true;
    }
    setBrands(brandListTemp);
  }, [brandDropdownList])

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
		if (id && !addAction) {
		  if (businessEntityDetail) {
			if (businessEntityDetail.countryId) {
			  getStateWithLoading(businessEntityDetail.countryId, false)
			}
		  }
		}
  }, [businessEntityDetail]);

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const getStateWithLoading = async (id: string, removeData: boolean=true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values)=> ({
        ...values,
        stateId: "",
        stateName: ""
      }))
    }
    setLoadingState(false);
  };
  
  return (
    <div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
          {
            loading
            ?
            <Loading />
            :
            <Form
              className="standard-layout"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={()=> {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row form={+true}> {/* +true = Convert boolean to numbers*/}
                <Col xl={addAction ? 12 : 5}>
                  <LineBreakWithTittle 
                    paddingBottom="0px"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({id: "BusinessEntity"})})} 
                    h4Title/>
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "BusinessEntityName"})}
                      name="name"
                      className="mb-3 mt-4"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}/>
                  </SingleColumnRowParent>
                  <Row>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "CompanyNumber"})}
                        name="companyNumber"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "TaxNumber"})}
                        name="taxNumber"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                  </Row>    
                  <Row>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "ContactNumber"})}
                        name="contactNumber"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "Email"})}
                        name="email"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}/>
                    </DoubleColumnRowParent>
                  </Row>         
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>                          
                    <GeneralInput
                      title={intl.formatMessage({ id: "AddressLine1"})}
                      name="addressLine1"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "AddressLine2"})}
                      name="addressLine2"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                  <Row>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "City"})}
                        name="city"
                        type="text"
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        validation={validation}
                      />
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "PostCode"})}
                        name="postcode"
                        type="text"
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        validation={validation}
                      />
                    </DoubleColumnRowParent>
                  </Row>
                  <Row>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      <DropDownWithTitle
                        name={"countryId"}
                        title={intl.formatMessage({ id: "Country"})}
                        specifyReturnFieldName={[
                          {
                            field: "countryId",
                            value: "id",
                          },
                        ]}
                        labelField={"name"}
                        valueField={"id"}
                        options={countryDropdownList}
                        disabled={
                          disabledFieldInput ||
                          viewAction ||
                          loadingState
                        }
                        onChangeFunction={
                          getStateWithLoading
                        }
                        initialLabel={
                          !addAction
                            ? validation
                                .values
                                .countryName
                            : undefined
                        }
                        initialValue={
                          !addAction
                            ? validation
                                .values
                                .countryId
                            : undefined
                        }
                        validationRequired={true}
                        validation={validation}
                      />
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent
                      blnDoubleTab={!addAction}>
                      {loadingState ? (
                        <Loading />
                      ) : (
                        stateList.length > 0
                        ?
                        <DropDownWithTitle
                          name={"stateId"}
                          title={intl.formatMessage({ id: "State"})}
                          specifyReturnFieldName={[
                            {
                              field: "stateId",
                              value: "id",
                            },
                            {
                              field: "stateName",
                              value: "name",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={stateList}
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          initialLabel={
                            !addAction
                              ? validation
                                  .values
                                  .stateName
                              : undefined
                          }
                          initialValue={
                            !addAction
                              ? validation
                                  .values
                                  .stateId
                              : undefined
                          }
                          validationRequired={stateList.length > 0 ? true : false}
                          validation={
                            validation
                          }
                        />
                        :
                        <GeneralInput
                          title={intl.formatMessage({ id: "StateName"})}
                          name="stateName"
                          type="text"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validationRequired={true}
                          validation={validation}
                        />
                      )}
                    </DoubleColumnRowParent>
                  </Row>
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>  
                    <DropDownWithTitle
                      name={"brandId"}
                      title={intl.formatMessage({ id: "BrandEntitledToThisBusinessEntity"})}
                      specifyReturnFieldName={[{"field": "brandId", "value": "id"}, {"field": "brandName", "value": "name"}]}
                      labelField={"name"}
                      valueField={"name"}
                      options={brands}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialValue={validation.values.brandName}
                      validation={validation}/>
                  </SingleColumnRowParent>
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "DisplayOrder"})}
                      name="displayOrder"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                  <SingleColumnRowParent
                    blnDoubleTab={!addAction}>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "Active"})}
                      name="isActive"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes"})}</Label>}/>
                  </SingleColumnRowParent>
                </Col>  
                {
                  !addAction 
                  &&
                  <Col xl={7}>
                    <Nav tabs className="">
                      {
                        tabBar.map((value, indexTab)=> (
                          <NavItem key={`tabkey_${indexTab}`}>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: activeTab === String(indexTab+1),
                              })}
                              onClick={() => {
                                toggle(String(indexTab+1));
                              }}
                            >
                              {value}
                            </NavLink>
                          </NavItem>
                        ))
                      }
                    </Nav>
                    {
                      activeTab === '1'
                      &&
                      <TableCheckBoxWithSectionHeader
                        blnEditable={false}
                        displayContentField={"name"}
                        hyperLinkModule="branch"
                        data={validation.values.branches}
                        setFieldValue={validation.setFieldValue}/>
                    }
                  </Col>
                }
              </Row>
              <GeneralSubmitAndCancelBtn 
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                blnMarginTopByInnerWidth={true}
              />
            </Form>
         }
      </DetailViewLayout>
    </div>
  );
}

export default observer(BusinessEntityDetail);
