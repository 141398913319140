import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, stockReturnToHQStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Input, Form } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import MyButton from "../../app/components/form/MyButton";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import CancelModal from "../../app/components/modal/CancelModal";

const StockReturnToHQInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockReturnToHQStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, setShowCancelPrompt } = commonStore;
  const { updateStockReturnToHQ, stockReturnToHQDetail, setStockReturnToHQDetail, getStockReturnToHQWithId } = stockReturnToHQStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockReturnToHQ" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockReturnToHQ" }), urlPath: RoutesList.stockReturnToHQ }];
  const invoiceFullDetail = stockReturnToHQDetail || {
    id: "",
    stockReturnNo: "",
    requestedDate:  "",
    approvedDate:  "",
    approverId: "",
    approverName: "",
    businessEntityId: "",
    businessEntityName: "",
    branchId: "",
    branchName: "",
    note: "",
    comment: "",
    status: "",
    stockReturnDetails: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockReturnToHQDetail || {
      id: "",
      workflowAction: "",
      comment: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (invoiceFullDetail.status == Constants.processing || invoiceFullDetail.status == Constants.partialReceived) {
          await updateStockReturnToHQ(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  async function fetchStockReturnToHQDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getStockReturnToHQWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockReturnToHQ], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockReturnToHQ], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockReturnToHQ], true)) {
        return;
      }
    }
    
    fetchStockReturnToHQDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockReturnToHQDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  const blnProcessingStatus = invoiceFullDetail.status == Constants.processing;
  const blnApprovedOrCompletedStatus = invoiceFullDetail.status == Constants.approved || invoiceFullDetail.status == Constants.completed;
  const blnPermissionUpdateStockReturnToHQ = checkPermission([PermissionConstants.UpdateStockReturnToHQ]);
  const blnPermissionReviewStockReturnToHQ = checkPermission([PermissionConstants.ReviewStockReturnToHQ]);
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || !blnProcessingStatus;
  
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "StockReturnToHQ" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "StockReturnToHQNo" })}:`, invoiceFullDetail.stockReturnNo)}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "Branch" })}:`, invoiceFullDetail.branchName)}
                          {displayHeaderRowData("Date:", moment(invoiceFullDetail.requestedDate).format(Constants.displayDateFormat))}
                          {displayHeaderRowData("Time:", moment(invoiceFullDetail.requestedDate).format(Constants.displayTimeFormat))}
                        </Col>
                        <hr />
                        {
                          !blnProcessingStatus &&
                          <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                            <Col>
                              <div className="logo-lg" style={{ lineHeight: '20px' }}>
                                <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                  {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                                </span>
                                {displayBillingShippingRowData(`${intl.formatMessage({ id: "ApprovedBy" })}:`, invoiceFullDetail.approverName)}
                                {blnApprovedOrCompletedStatus &&
                                  <>
                                    {displayBillingShippingRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(invoiceFullDetail.approvedDate).format(Constants.displayDateFormat))}
                                    {displayBillingShippingRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(invoiceFullDetail.approvedDate).format(Constants.displayTimeFormat))}
                                  </>
                                }
                              </div>
                            </Col>
                          </Row>
                        }

                      </div>
                      {!blnProcessingStatus && <><br /><br /></>}
                      <address style={{ marginTop: blnProcessingStatus ? '20px' : '' }}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.status}
                            statusColorFunction={stockReturnToHQStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th>{intl.formatMessage({ id: "TotalQuantity" })}</th>
                              <th>{intl.formatMessage({ id: "IsDefect" })}</th>
                              <th>{intl.formatMessage({ id: "Remark" })}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.stockReturnDetails.map((item, key) => (
                                <tr key={`body_${key}`}>
                                  <td>{item.productName}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.isDefect ? Constants.yes : Constants.no}</td>
                                  <td>{item.remark}</td>
                                  <td style={{ width: "40px" }}><Input type='checkbox' className="margin-bottom-8 margin-top-0" style={{ width: "18px", height: "18px" }} disabled={true}></Input></td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        validation={validation}
                      />
                      <div className="margin-top-32"/>
                      <hr/>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Comment" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="comment"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput || (!blnPermissionUpdateStockReturnToHQ && !blnPermissionReviewStockReturnToHQ)}
                        validation={validation}
                      />
                      <div className="mt-3" />
                      <div className="flex-direction-row d-print-none">
                        {
                          viewAction
                          &&
                          blnProcessingStatus
                          &&
                          !Boolean(successMessage)
                          &&
                          <>
                            {
                              blnPermissionReviewStockReturnToHQ
                              &&
                              <>
                                <MyButton
                                  type="submit"
                                  class="btn btn-success margin-right-8"
                                  content={intl.formatMessage({ id: "Approve" })}
                                  onClick={() => {
                                    validation.setFieldValue("workflowAction", Constants.approve)
                                  }}
                                  disable={loading || validation.isSubmitting || !blnPermissionReviewStockReturnToHQ || Boolean(successMessage)}
                                  loading={loading || validation.isSubmitting}
                                />
                                <MyButton
                                  type="submit"
                                  class="btn btn-danger margin-right-8"
                                  content={intl.formatMessage({ id: "Reject" })}
                                  onClick={() => {
                                    validation.setFieldValue("workflowAction", Constants.reject)
                                  }}
                                  disable={loading || validation.isSubmitting || !blnPermissionReviewStockReturnToHQ || Boolean(successMessage)}
                                  loading={loading || validation.isSubmitting}
                                />
                              </>
                            }
                            {
                              blnPermissionUpdateStockReturnToHQ
                              &&
                              <MyButton
                                type="button"
                                class="btn btn-warning margin-right-8"
                                content={Constants.cancel}
                                onClick={async () => {
                                  setShowCancelPrompt(true);
                                }}
                                disable={loading || validation.isSubmitting}
                                loading={loading || validation.isSubmitting}
                              />
                            }
                          </>
                        }
                        <GeneralSubmitAndCancelBtn
                          successMessage={successMessage}
                          className="width-100-percentage"
                          viewAction={false}
                          validation={validation}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={false}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockReturnToHQInvoiceDetail);
