import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { AddOptionsValueField, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnBlobImage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { GeneralAttachmentListInterface, ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { CashOutDetailListObject } from "../../app/models/cashOut";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const CashOutDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { cashOutStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore; 
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addCashOut, updateCashOut, cashOutDetail, setCashOutDetail, getCashOutWithId } = cashOutStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CashOut" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CashOut" }), urlPath: RoutesList.cashOut }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [cashOutTransactioTypeList, setCashOutTransactioTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateCashOut =  checkPermission([PermissionConstants.UpdateCashOut]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: cashOutDetail || {
      id: "",
      branchId: getBranchUser() ? getBranchId() : "",
      branchName: getBranchUser() ? getBranchName() : "",
      submissionDate: "",
      status: "",
      totalAmount: 0,
      note: "",
      attachmentFileHistoryIds: [],
      attachmentFiles: [],
      cashOutDetails: [],
      amountSummaries: [],
      aryAttachments: [],
      workflowAction: ""
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
      submissionDate: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CashCollectionDate" }) })),
      cashOutDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Detail" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (valuesTemp.workflowAction === Constants.submit && valuesTemp.aryAttachments.length === 0) {
        validation.setFieldError("aryAttachments", intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "Attachment" })}))
        return;
      }
      
      let blnDetailsError = false;
      let cashOutDetailsTemp = valuesTemp.cashOutDetails.map((valueCashOutDetailTemp) => {
        let backgroundColor = !valueCashOutDetailTemp.transactionDate || !valueCashOutDetailTemp.transactionType || !valueCashOutDetailTemp.amount ? Constants.orange : "";
        if (!valueCashOutDetailTemp.transactionDate || !valueCashOutDetailTemp.transactionType || !valueCashOutDetailTemp.amount) {
          blnDetailsError = true;
        }
        return ({
          ...valueCashOutDetailTemp,
          backgroundColor: backgroundColor
        })
      })

      validation.setFieldValue("cashOutDetails", cashOutDetailsTemp)
      if (blnDetailsError) {
        setErrorMessage(intl.formatMessage({ id: "CashOutDetailsError" }))
        return;
      }

      let attachmentFilesTemp : any = [];
      let attachmentFileIdsTemp : any = [];
      await Promise.all(valuesTemp.aryAttachments.map(async (valueAttachmentTemp) => {
        if (valueAttachmentTemp.preview !== "") {
          let fileTemp : any = await returnBlobImage(valueAttachmentTemp.preview);
          let fileWithFileName = new File([fileTemp], valueAttachmentTemp.path);
          attachmentFilesTemp.push(fileWithFileName);
        }
        if (valueAttachmentTemp.id) {
          attachmentFileIdsTemp.push(valueAttachmentTemp.id);
        }
      }))
      delete valuesTemp["aryAttachments"];
      valuesTemp.attachmentFiles = attachmentFilesTemp;
      valuesTemp.attachmentFileHistoryIds = attachmentFileIdsTemp;

      try {
        if (addAction) {
          await addCashOut(valuesTemp);
        } else {
          await updateCashOut(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const fieldsOfCashOutDetails: CashOutDetailListObject = { transactionDate: "", transactionType: "", amount: 0, transactionNo: "", remark: "" };
  const aryDynamicInputAndCheckBoxTabCashOutDetails: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "TransactionDate" }),
      type: "input",
      xl: "2.4",
      inputName: "transactionDate",
      inputType: "datetime-local",
      validationRequired: true
    },
    {
      label: intl.formatMessage({ id: "TransactionType" }),
      type: "select",
      xl: "2.4",
      inputName: "transactionType",
      inputType: "select",
      options: AddOptionsValueField(cashOutTransactioTypeList, "displayValue", "displayValue"),
      displayLabel: "transactionTypeDisplay",
      displayValue: "transactionTypeDisplay",
      specifyReturnFieldName: [{ field: "transactionTypeDisplay", value: "label" }, { field: "transactionType", value: "key" }],
      validationRequired: true
    },
    {
      label: `${intl.formatMessage({ id: "Amount" })}`,
      type: "input",
      xl: "2.4",
      inputName: "amount",
      inputType: "number",
      validationRequired: true
    },
    {
      label: `${intl.formatMessage({ id: "TransactionNo" })}`,
      type: "input",
      xl: "2.4",
      inputName: "transactionNo",
      inputType: "text",
    },
    {
      label: `${intl.formatMessage({ id: "Remark" })}`,
      type: "input",
      xl: "2.4",
      inputName: "remark",
      inputType: "text",
    },
  ];

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCashOutDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.cashOutTransactionType)
      ]
      if (id && !addAction) {
        aryAPI.push(getCashOutWithId(id));
      }
      
      let resultAPI = await Promise.all(aryAPI);
      setCashOutTransactioTypeList(resultAPI[1].map((valueTemp: StaticReferenceDataObject)=> ({displayValue: newSpaceBeforeCapitalLetter(valueTemp.displayValue), key: valueTemp.displayValue})))
      
      if (resultAPI.length > 2) {
        if (resultAPI[2]) {
          if (resultAPI[2].status === Constants.submitted) {
            history.push(`/${RoutesList.notFound}`)
            setLoading(false);
            return;
          }
          if (resultAPI[2].attachmentFiles) {
            let aryAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
            resultAPI[2].attachmentFiles.map((valueAttachmentFile)=> {
              aryAttachmentsTemp.push({
                id: valueAttachmentFile.attachmentFileId,
                preview: "",
                path: valueAttachmentFile.attachmentFileName,
                webUrl: valueAttachmentFile.attachmentWebUrl,
              })
            })
            validation.setFieldValue("aryAttachments", aryAttachmentsTemp);
          }
          else {
            validation.setFieldValue("aryAttachments", []);
          }
        }
      }
      setLoading(false);
    }

    if (addAction) {
      setCashOutDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCashOut], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCashOut], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCashOut], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateCashOut){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.cashOut}/edit/${id}`)} })
    }

    fetchCashOutDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCashOutDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "CashOut" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"branchId"}
                    className="mb-3 mt-4"
                    title={intl.formatMessage({ id: "Branch"})}
                    specifyReturnFieldName={[{"field": "branchName", "value": "name"},{"field": "branchId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={branchDropDownList}
                    disabled={disabledFieldInput || viewAction || getBranchUser()}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    validation={validation}
                    validationRequired={true}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "CashCollectionDate" })}
                    name="submissionDate"
                    type="date"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Note" })}
                    name="note"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}/>
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Details" })}
                  className="fw-bold"
                  titleSize="16px"
                />
                <SingleColumnRowParent
                  blnDoubleTab>
                  <TableWithEditableInputFields
                    name="cashOutDetails"
                    className="tab_bar_border_top_width"
                    title={""}
                    buttonTitle={intl.formatMessage({ id: "Add" })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabCashOutDetails}
                    data={validation.values.cashOutDetails}
                    inputField={fieldsOfCashOutDetails}
                    supportBackgroundColor={true}
                    // blnHideBorder={true}
                    validation={validation}
                    blnValidation={true}
                    disabled={disabledFieldInput}
                    blnDisabledCustomizedTableScrolling={true} />
                </SingleColumnRowParent>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Attachments" })}
                  className="margin-top-36 fw-bold"
                  titleSize="16px"
                  blnSkipMarginTop={true}
                />
                <SingleColumnRowParent>
                  <GeneralAttachment
                    title={""}
                    name="aryAttachments"
                    validation={validation}
                    disabled={disabledFieldInput || viewAction}
                    blnMultipleFile={true}/>
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.save)
                }}
                hidePrimaryButton={false}
                submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.submit)
                }}
                extraLeftDirtyDisabled={true}
              />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CashOutDetail);
