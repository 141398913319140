import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import TableCheckBoxWithSectionHeader from "../../app/components/table/TableCheckBoxWithSectionHeader";
import classNames from "classnames";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Nav, NavItem, NavLink, Label } from "reactstrap";
import DropZoneUploadWidget from "../../app/components/form/DropZoneUploadWidget";
import { returnBlobImage } from '../../app/common/function/function';
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { SubBrandObject } from "../../app/models/brand";
import GeneralColorPicker from "../../app/components/form/GeneralColorPicker";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";

const BrandDetail = () => {
	//Use Params
	const { id } = useParams();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const { brandStore, countryStore, commonStore, currencyStore, positionStore, staticReferenceStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
    setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addBrand, updateBrand, brandDetail, setBrandDetail, getBrandWithId, getSubBrandDropdown } = brandStore;
	const { currencyDropdownList, getCurrencyDropdown } = currencyStore;
	const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const { stockRequestMethodType, productCommissionType, getStaticReferenceWithType } = staticReferenceStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${
		addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
	} ${intl.formatMessage({id: "Brand"})}`;
	const breadCrumbList = [{ title: intl.formatMessage({id: "Brand"}), urlPath: RoutesList.brand }];
	const [activeTab, setActiveTab] = useState("1");
	const tabBar = addAction ? [intl.formatMessage({id: "SubBrand"}).toUpperCase()] : [intl.formatMessage({id: "BusinessEntity"}).toUpperCase(), intl.formatMessage({id: "SubBrand"}).toUpperCase()];
  const [newImageUrl, setNewImageUrl] = useState<string>("");
	const [loadingState, setLoadingState] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const fieldsOfSubBrands : SubBrandObject = { name: "", icon: "", displayOrder: 1, isDelete: false};
  const blnPermissionUpdateBrand = checkPermission([PermissionConstants.UpdateBrand]);
  const [selectedPositionIds, setSelectedPositionIds] = useState<any>([]);

  const aryDynamicInputAndCheckBoxTab : ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({id: "Name"}).toUpperCase(),
      type: "input",
      xl: "6",
      inputName: "name",
      inputType: "text",
      validationRequired: true
    },
    {
      label: intl.formatMessage({id: "Icon"}).toUpperCase(),
      type: "image",
      xl: "3",
      inputName: "icon",
      inputType: "text",
      imageWidth: "40px",
      validationRequired: true
    },
    {
      label: intl.formatMessage({id: "DisplayOrder"}).toUpperCase(),
      type: "input",
      xl: "3",
      textAlign: true,
      inputName: "displayOrder",
      inputType: "number",
    },
  ];
  
	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: brandDetail || {
			name: "",
			file: undefined,
      imageUrl: "",
			currencyId: "",
			currencyName: "",
     	stockRequestMethod: Constants.purchaseDeliver,
      productCommissionType: Constants.group,
      prefix: "",
			displayOrder: 1,
			addressLine1: '',
			addressLine2: '',
			city: '',
			postcode: '',
			countryId: '',
			countryName: '',
			stateId: '',
			stateName: '',
			businessEntities: [],
      subBrands: [],
      description: "",
      colorCode: "",
      isHavingSubBrand: false,
      isProductSplitBySubBranch: false,
      entitledCommissionPositionIds: [],
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "BrandName"})})),
      currencyId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Currency"})})),
      stockRequestMethod: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "StockRequestMethod"})})),
      productCommissionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "ProductCommissionType"})})),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "DisplayOrder"})})),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
      
			try {
        if (newImageUrl) {
          let fileTemp = await returnBlobImage(newImageUrl);
          valuesTemp.file = fileTemp; 
        }

        if (valuesTemp.subBrands) {
          let blnEmptyName = false;
          let blnEmptyIcon = false;
          for (let a=0; a<valuesTemp.subBrands.length; a++) {
            if (removeAllSpace(valuesTemp.subBrands[a].name) == "") {
              blnEmptyName = true;
            }
            else if (!valuesTemp.subBrands[a].icon) {
              blnEmptyIcon = true;
            }
          }
          if (blnEmptyName) {
            setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: `${intl.formatMessage({id: "SubBrand"})} ${intl.formatMessage({id: "Name"})}`}))
            return;
          }
          else if (blnEmptyIcon) {
            setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: `${intl.formatMessage({id: "Icon"})}`}))
            return;
          }
        }
				if (addAction) {
					delete valuesTemp.businessEntities;
          await addBrand(valuesTemp);
				} else {
					delete valuesTemp.businessEntities;
					await updateBrand(valuesTemp);
				}
			} finally {
        await getSubBrandDropdown();
				validation.setSubmitting(false);
			}
		},
	});

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		async function fetchBrandDetailAPI() {
			setLoading(true);
      let aryAPI : any = [
        getCurrencyDropdown(),
        getStaticReferenceWithType(Constants.stockRequestMethodType),
        getStaticReferenceWithType(Constants.productCommissionType),
        getCountryDropdown(),
        getPositionDropdown(1, true),
      ];
      if (id && !addAction) {
			  aryAPI.push(getBrandWithId(id));
      }
      await Promise.all(aryAPI);
			setLoading(false);
		} 

    if (addAction) {
			setBrandDetail(undefined);
		}

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateBrand], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateBrand], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageBrand], true)) {
        return;
      }
    }
    
    if(viewAction && blnPermissionUpdateBrand){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.brand}/edit/${id}`)} })
    }

    burgerMenuListContent.push({ label: `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "SiteMap"})}`, onFunction: () => { 
      history.push(`/${RoutesList.brand}/sitemap/${id}`)} 
    })

    fetchBrandDetailAPI();
    
    return (()=> {
      if (id && !addAction) {
        setBrandDetail(undefined);
      }
    })
	}, []);

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	useEffect(() => {
		if (id && !addAction) {
		  if (brandDetail) {
        if (brandDetail.countryId) {
          getStateWithLoading(brandDetail.countryId, false)
        }

        if (brandDetail.subBrands) {
          let aryFinalSubBrands : SubBrandObject[] = [];
          brandDetail.subBrands.map((valueSubBrands)=> {
            if (!valueSubBrands.isDelete) {
              aryFinalSubBrands.push(valueSubBrands);
            }
          })
          validation.setFieldValue("subBrands", aryFinalSubBrands);
        }

        if (brandDetail.entitledCommissionPositionIds) {
          let arySelectedPositionIdTemp: any = [];
          brandDetail.entitledCommissionPositionIds.map((value) => {
            for (var a = 0; a < positionDropdownList.length; a++) {
              if (value === positionDropdownList[a].id) {
                arySelectedPositionIdTemp.push({
                  ...positionDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(positionDropdownList[a].name),
                  value: positionDropdownList[a].id
                })
              }
            }
          })
          setSelectedPositionIds(arySelectedPositionIdTemp);
        }
		  }
		}
	}, [brandDetail]);

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])
	
  const getStateWithLoading = async (id: string, removeData: boolean=true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values)=> ({
        ...values,
        stateId: "",
        stateName: ""
      }))
    }
    setLoadingState(false);
  };

	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={()=> {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle 
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({id: "Brand"})})} 
                  h4Title/>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "BrandName"})}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "BrandDescription"})}
                    name="description"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"currencyId"}
                    title={intl.formatMessage({ id: "Currency"})}
                    specifyReturnFieldName={[
                      {
                        field: "currencyId",
                        value: "id",
                      },
                    ]}
                    labelField={"name"}
                    valueField={"name"}
                    options={currencyDropdownList}
                    validationRequired={true}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={validation.values.currencyName}
                    validation={validation}
                  />
                </SingleColumnRowParent>                  
                {/* <Row> */}
                  {/* <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"stockRequestMethod"}
                      title={intl.formatMessage({ id: "StockRequestMethod"})}
                      specifyReturnFieldName={[{"field": "stockRequestMethod", "value": "displayValue"}]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={stockRequestMethodType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.stockRequestMethod : undefined}
                      validationRequired={true}
                      validation={validation}/>
                  </DoubleColumnRowParent> */}
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"productCommissionType"}
                      title={intl.formatMessage({ id: "ProductCommissionType"})}
                      specifyReturnFieldName={[{"field": "productCommissionType", "value": "displayValue"}]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={productCommissionType}
                      blnValueWithNewSpace={true}
                      disabled={true}
                      initialValue={validation.values.productCommissionType}
                      validationRequired={true}
                      validation={validation}/>
                  </SingleColumnRowParent>
                {/* </Row> */}
                {
                  validation.values.productCommissionType !== Constants.individual
                  &&
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitleMultiSelect
                      name={"entitledCommissionPositionIds"}
                      title={intl.formatMessage({ id: "EntitledCommissionPosition" })}
                      specifyReturnFieldName={[{ "field": "entitledCommissionPositionIds", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={positionDropdownList}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={selectedPositionIds}
                      validationRequired={true}
                      validation={validation} />
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Prefix"})}
                    name="prefix"
                    type="text"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder"})}
                    name="displayOrder"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AddressLine1"})}
                    name="addressLine1"
                    type="text"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "AddressLine2"})}
                    name="addressLine2"
                    type="text"
                    disabled={
                      disabledFieldInput ||
                      viewAction
                    }
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "City"})}
                      name="city"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PostCode"})}
                      name="postcode"
                      type="text"
                      disabled={
                        disabledFieldInput ||
                        viewAction
                      }
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"countryId"}
                      title={intl.formatMessage({ id: "Country"})}
                      specifyReturnFieldName={[
                        {
                          field: "countryId",
                          value: "id",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={countryDropdownList}
                      disabled={
                        disabledFieldInput ||
                        viewAction ||
                        loadingState
                      }
                      onChangeFunction={
                        getStateWithLoading
                      }
                      initialLabel={
                        !addAction
                          ? validation
                              .values
                              .countryName
                          : undefined
                      }
                      initialValue={
                        !addAction
                          ? validation
                              .values
                              .countryId
                          : undefined
                      }
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    {loadingState ? (
                      <Loading />
                    ) : (
                      stateList.length > 0
                      ?
                      <DropDownWithTitle
                        name={"stateId"}
                        title={intl.formatMessage({ id: "State"})}
                        specifyReturnFieldName={[
                          {
                            field: "stateId",
                            value: "id",
                          },
                          {
                            field: "stateName",
                            value: "name",
                          },
                        ]}
                        labelField={"name"}
                        valueField={"id"}
                        options={stateList}
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        initialLabel={
                          !addAction
                            ? validation
                                .values
                                .stateName
                            : undefined
                        }
                        initialValue={
                          !addAction
                            ? validation
                                .values
                                .stateId
                            : undefined
                        }
                        validationRequired={stateList.length > 0 ? true : false}
                        validation={
                          validation
                        }
                      />
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "StateName"})}
                        name="stateName"
                        type="text"
                        disabled={
                          disabledFieldInput ||
                          viewAction
                        }
                        validationRequired={true}
                        validation={validation}
                      />
                    )}
                  </DoubleColumnRowParent>
                </Row>
                <GeneralColorPicker
                  title={intl.formatMessage({ id: "ColorCode"})}
                  name="colorCode"
                  disabled={
                    disabledFieldInput ||
                    viewAction
                  }
                  intl={intl}
                  validation={validation}/>
                <Row>
                  <DoubleColumnRowParent blnDoubleTab>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "IsHavingSubBrand"})}
                      name="isHavingSubBrand"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes"})}</Label>}/>
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab>  
                    <GeneralInput
                      title={intl.formatMessage({ id: "isProductSplitBySubBranch"})}
                      name="isProductSplitBySubBranch"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes"})}</Label>}/>
                  </DoubleColumnRowParent>
                </Row>

                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropZoneUploadWidget
                    name="file"
                    validation={validation}
                    setNewImageUrl={setNewImageUrl}
                    disabled={disabledFieldInput || viewAction}
                    imageUrl={validation.values.imageUrl}
                  />
                </SingleColumnRowParent>
              </Col>
              {(
                <Col xl={7}>
                  <Nav tabs className="">
                    {tabBar.map((value, indexTab) => (
                      <NavItem
                        key={`tabkey_${indexTab}`}
                      >
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={classNames({
                            active:
                              activeTab ===
                              String(
                                indexTab + 1
                              ),
                          })}
                          disabled={indexTab == 1 && !validation.values.isHavingSubBrand}
                          onClick={() => {
                            toggle(
                              String(
                                indexTab + 1
                              )
                            );
                          }}
                        >
                          {value}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {activeTab === "1" && !addAction && (
                    <TableCheckBoxWithSectionHeader
                      blnEditable={false}
                      displayContentField={"name"}
                      hyperLinkModule="businessEntity"
                      data={
                        validation.values
                          .businessEntities
                      }
                      setFieldValue={
                        validation.setFieldValue
                      }
                    />
                  )}
                  {
                    ((activeTab === "1" && addAction) || activeTab === '2')
                    &&
                    <TableWithEditableInputFields
                      name="subBrands"
                      title={intl.formatMessage({ id: "SubBrandsIncludeInBrand"})}
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "SubBrand" }) })}
                      blnEditable={!viewAction}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.subBrands}
                      inputField={fieldsOfSubBrands}
                      supportBackgroundColor={true}
                      validation={validation}
                      disabled={disabledFieldInput || !validation.values.isHavingSubBrand}/>
                  }
                </Col>
              )}
            </Row>
            <GeneralSubmitAndCancelBtn 
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
			</DetailViewLayout>
		</div>
	);
};

export default observer(BrandDetail);
