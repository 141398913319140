import React, { useState, Fragment } from 'react'
import { Card, CardBody, Col, Label, Row, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from "react-intl";
import moment from 'moment';
import { Constants } from '../../../app/constants/Constants';
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { checkPermission, checkValidDate, displayTagUI, getBranchId, returnThousandSeperator } from '../../../app/common/function/function';
import { history } from "../../..";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import WalletOutlineIcon from 'mdi-react/WalletOutlineIcon';
import CalendarEditIcon from 'mdi-react/CalendarEditIcon';
import TableActionButton from '../../../app/components/table/TableActionButton';
import InformationIcon from 'mdi-react/InformationIcon';
import TransferIcon from 'mdi-react/TransferIcon';
import AccountVoiceIcon from 'mdi-react/AccountVoiceIcon';
import Loading from '../../../app/components/loading/Loading';
import GeneralImageWithBase64 from '../../../app/components/form/GeneralImageWithBase64';
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';
import LockCheckOutlineIcon from 'mdi-react/LockCheckOutlineIcon';
import { RelationshipObject } from '../../../app/models/customer';
import { Link } from "react-router-dom"
import MyButton from '../../../app/components/form/MyButton';
import { WindowObject } from '../../../app/models/common';

interface Props {
  validation: any;
  customerCredit: number;
  customerLockedCredit: number;
  transferCredits: number;
  isMenu: boolean;
  toggleMenu: Function;
  setCreditHistoryModal: Function;
  setCreditAdjustmentModal: Function;
  setTransferCreditModal: Function;
  setTransferCreditHistoryModal: Function;
  setSubBranchRequestModal: Function;
  setSubBranchRemoveModal: Function;
  setEditCustomerJoinedDateModal: Function;
  setEditCustomerMediaSourceModal: Function;
  setCustomerAccessRequestModal: Function;
  setAddTagModal: Function;
  setDeleteTagModal: Function;
  setSelectedTagDetails: Function;
  setAddRelationshipModal: Function;
  setDeleteRelationshipModal: Function;
  setSelectedRelationshipDetails: Function;
  isTranseferCreditEnabled: boolean;
  loadingCreditHistory: boolean;
  loadingTransferCreditHistory: boolean;
  isShowAppolous: boolean;
  windowSize: WindowObject;
  successMessage: string;
}

export default function CustomerProfileDetail(props: Props) {
  const [customerLockedCreditBottomTooltip, setCustomerLockedCreditBottomTooltip] = useState(false);
  const intl = useIntl();
  const blnPermissionUpdateCustomer = checkPermission([PermissionConstants.UpdateCustomer]);
  const blnPermissionRequestCreditAdjustment = checkPermission([PermissionConstants.RequestCreditAdjustment]);
  const blnPermissionCreateTransferCredit = checkPermission([PermissionConstants.CreateTransferCredit]);
  const blnPermissionCreateCustomerSubBranchRequest = checkPermission([PermissionConstants.CreateCustomerSubBranchRequest]);
  const blnPermissionDeleteCustomerSubBranchRequest = checkPermission([PermissionConstants.UpdateCustomerSubBranchRequest]);
  const blnPermissionEditCustomerJoinedDate = checkPermission([PermissionConstants.EditCustomerJoinedDate]);
  const blnPermissionEditCustomerMediaSource = checkPermission([PermissionConstants.EditCustomerMediaSource]);
  const blnPermissionCreateCustomerAccessRequest = checkPermission([PermissionConstants.CreateCustomerAccessRequest]);
  const blnOrginalBranchMatchUserBranch = props.validation.values.branchId === getBranchId();

  const onClickTagFunction = (valueTags) => {
    if (blnPermissionUpdateCustomer) {
      props.setSelectedTagDetails(valueTags);
      props.setDeleteTagModal(true);
    }
  }

  const displayRelationshipUI = (valueRelationship: RelationshipObject) => {
    return (
      <div key={`${valueRelationship.linkToCustomerName}_${valueRelationship.relationshipType}`} className="flex-align-items-center-without-height">
        {
          <Link 
            to={valueRelationship.linkToCustomerId ? `/${RoutesList.customer}/view/${valueRelationship.linkToCustomerId}` : ""} 
            target={Constants.supportBlank} 
            rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable" style={{fontSize: "0.8125rem"}}>{valueRelationship.linkToCustomerName || Constants.emptyData} {`(${valueRelationship.relationshipType})`}</Label>
          </Link>
        }    
        {
          blnPermissionUpdateCustomer
          &&
          <div className=''>
            <TableActionButton
              type="delete"
              onClick={() => {
                props.setSelectedRelationshipDetails(valueRelationship);
                props.setDeleteRelationshipModal(true);
              }}/>
          </div>
        }
      </div>
    )
  }

  const displayCustomerProfileDetail = () => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="me-4">
              <AccountCircleIcon className="text-primary h1" />
            </div>
            <div className="flex-grow-1">
              <div className="text-muted">
                <h4>{`${props.validation.values.title}. ${props.validation.values.preferredName}`}</h4>
                <h6 className="mb-1">
                  {props.validation.values.branchName}                  
                </h6>
                {
                  blnPermissionCreateCustomerAccessRequest
                  &&
                  blnOrginalBranchMatchUserBranch
                  &&
                  <>
                    <UncontrolledTooltip placement="top" target={"createAccessRequestTooltip"}>
                      {intl.formatMessage({ id: "CreateCustomerAccessRequest" })}
                    </UncontrolledTooltip>
                    <span id="createAccessRequestTooltip" className="pointer-clickable" style={{backgroundColor: Constants.green, color: 'white', padding: '4px', paddingRight: '6px', borderRadius: '8px', fontSize: '11px'}} onClick={()=> { props.setCustomerAccessRequestModal(true) }}>                        
                      <span>
                        <LockCheckOutlineIcon size={16} />
                      </span>
                      <span className="margin-left-4">{intl.formatMessage({ id: "GrantAccess" })}</span>
                    </span>
                  </>
                }
                <h6 className='mt-2'>
                  {intl.formatMessage({ id: "MemberSince" }).toUpperCase()}: 
                  <span className="badge bg-info ms-2 align-bottom text-center" style={{ fontSize: "11px" }}>
                    {moment(props.validation.values.joinedDate).format(Constants.displayDateFormat)}
                  </span>
                  <span className='margin-left-8'>
                    {
                      blnPermissionEditCustomerJoinedDate
                      &&
                      blnOrginalBranchMatchUserBranch
                      &&
                      props.validation.values.isAllowEditJoinedDate
                      &&
                      <span onClick={()=> {props.setEditCustomerJoinedDateModal(true)}}>
                        <UncontrolledTooltip placement="top" target={"editCustomerJoinedDate"}>
                          {intl.formatMessage({ id: "EditCustomerJoinedDate" })}
                        </UncontrolledTooltip>
                        <span><CalendarEditIcon size={20} className={"text-warning pointer-clickable"} id="editCustomerJoinedDate" /></span>
                      </span>
                    }
                  </span>
                </h6>
              </div>
              <div className='flex-direction-row'>
                {
                  props.validation.values.badges
                  &&
                  props.validation.values.badges.map((valueBagde, index)=> (
                    <div id={`brand_bagde_${index}_888`} key={`brand_bagde_${index}_888`}>
                      <GeneralImageWithBase64
                        index={index}
                        className={index > 0 ? "margin-left-8" : ""}
                        imageWidth='30px'
                        base64Image={valueBagde.icon}
                        />
                      <UncontrolledTooltip placement="top" target={`brand_bagde_${index}_888`}>
                        {valueBagde.name}
                      </UncontrolledTooltip>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              blnPermissionUpdateCustomer
              &&
              blnOrginalBranchMatchUserBranch
              &&
              <TableActionButton
                to={`/${RoutesList.customer}/edit/${props.validation.values.id}`}
                type="edit" />
            }
          </div>
        </CardBody>
        <CardBody className="border-top">
        <Row>
          <Col sm="12" xs="12" xl="6" className="text-center">
            <div className="text-sm mt-sm-0">
              <h6 className='text-truncate'>
                {intl.formatMessage({ id: "LastFollowedUpDate" }).toUpperCase()} 
              </h6> 
              <span className={`badge badge-soft-warning align-bottom text-center ${props.windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-8" : ""}`} style={{fontSize:"15px"}}>
                {checkValidDate(props.validation.values.lastFollowUpDate)}
              </span>
            </div>
          </Col>
          <Col sm="12" xs="12" xl="6" className={`text-center ${Constants.innerWidthCompare > props.windowSize.innerWidth ? "margin-top-16" : ""}`}>
            <div className="text-sm mt-sm-0">
              <h6 className='text-truncate'>
                {intl.formatMessage({ id: "LastVisitDate" }).toUpperCase()} 
              </h6>  
              <span className={`badge badge-soft-dark align-bottom text-center ${props.windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-8" : ""}`} style={{fontSize:"15px"}}>
                {checkValidDate(props.validation.values.lastVisitDate)}
              </span>
            </div>
          </Col>
        </Row>
      </CardBody>
        <CardBody className="border-top" >
          <div className="d-flex text-center">
            <div className="flex-grow-1">
              <Row>
                <Col xl={props.isTranseferCreditEnabled ? 6 : 12}>
                  <div className="text-sm mt-sm-0">
                    <div className='flex-justfity-content-center'>
                      <p className="text-muted mb-2" style={{ fontSize: "13px" }}>{intl.formatMessage({ id: "ClientCredits" })}</p>
                      <InformationIcon
                        id="customerLockedCreditBottom"
                        color='#556ee6'
                        className='margin-left-4'
                        style={{ paddingBottom: "4px" }}
                      />
                      {
                        blnPermissionRequestCreditAdjustment
                        &&
                        blnOrginalBranchMatchUserBranch
                        &&
                        <TableActionButton
                          type="edit"
                          onClick={() => props.setCreditAdjustmentModal(true)}
                          disabled={Boolean(props.successMessage)}
                          iconReplaceUI={<WalletOutlineIcon size={20} className={`${Boolean(props.successMessage) ? "text-secondary" : "text-success"} pointer-clickable`} id="editCreditTooltip" />}
                          iconId="editCreditTooltip"
                          iconToolTipText={`${intl.formatMessage({ id: "Edit" })} ${intl.formatMessage({ id: "CreditAdjustment" })}`} />
                      }
                    </div>
                    {
                      props.loadingCreditHistory
                      ?
                      <Loading />
                      :
                      <h5 className="">
                        <span className="badge badge-soft-success align-bottom text-center margin-bottom--10 credit-badge" style={{ fontSize: `${props.isTranseferCreditEnabled ? `25px` :`32px` }`, color: "green" }} onClick={() => props.setCreditHistoryModal()}>
                          {returnThousandSeperator(props.customerCredit)}
                        </span>
                      </h5>
                    }
                  </div>
                  <Tooltip
                    placement="bottom"
                    isOpen={customerLockedCreditBottomTooltip}
                    target="customerLockedCreditBottom"
                    toggle={() => {
                      setCustomerLockedCreditBottomTooltip(!customerLockedCreditBottomTooltip);
                    }}>
                    {`${intl.formatMessage({ id: "ProcessingCredit" })}: ${returnThousandSeperator(props.customerLockedCredit)}`}
                  </Tooltip>
                </Col>
                {
                  props.isTranseferCreditEnabled &&
                  <Col xl={6}>
                  <div className="text-sm mt-4 mt-sm-0">
                    <div className='flex-justfity-content-center'>
                      <p className="text-muted mb-2" style={{ fontSize: "13px" }}>{intl.formatMessage({ id: "TransferredCredits" })}</p>
                      <span  className='margin-left-4'>
                      {
                        blnPermissionCreateTransferCredit
                        &&
                        blnOrginalBranchMatchUserBranch
                        &&
                        <TableActionButton
                          type="edit"
                          disabled={Boolean(props.successMessage)}
                          onClick={() => props.setTransferCreditModal(true)}
                          iconReplaceUI={<TransferIcon size={20} className={"text-success pointer-clickable"} id="transferCreditTooltip" />}
                          iconId="transferCreditTooltip"
                          iconToolTipText={`${intl.formatMessage({ id: "TransferCredit" })}`} />
                      }
                      </span>
                    </div>
                    {
                      props.loadingTransferCreditHistory
                      ?
                      <Loading />
                      :
                      <h5 className="">
                        <span 
                          className="badge badge-soft-dark align-bottom text-center margin-bottom--10 credit-badge" 
                          style={{ fontSize: "25px", color: "black" }} 
                          onClick={() => props.setTransferCreditHistoryModal()}>
                          {returnThousandSeperator(props.transferCredits)}
                        </span>
                      </h5>
                    }
                  </div>
                </Col>
                }

              </Row>
            </div>
          </div>
        </CardBody>
        <CardBody className="border-top" style={{ fontSize: "14px" }}>
          <Row>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Consultant" })}</p>
                <h6>{props.validation.values.consultantName}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "CustomerNo" })}</p>
                <h6>{props.validation.values.customerNo}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Mobile" })}</p>
                <div className="flex-align-items-center-without-height mb-2">
                  <h6 className='mb-0'>{props.validation.values.mobileNo}</h6>
                  <div className='margin-left-4'>
                    <TableActionButton
                      type="whatsapp"
                      onClick={() => window.open(`https://web.whatsapp.com/send?phone=${props.validation.values.mobileNo}`)}/>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "IdentityNo" })}</p>
                <h6>{props.validation.values.identityNo}{props.validation.values.identityType ? props.validation.values.identityType === Constants.identityCard ? `(NRIC)` : `(P)` : ""}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Gender" })}</p>
                <h6>{props.validation.values.gender}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Birthday" })}</p>
                <h6>{moment(props.validation.values.dob).format(Constants.displayDateFormat)}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Address" })}</p>
                <h6>{`${props.validation.values.addressLine1 || ""} ${props.validation.values.addressLine2 || ""} ${props.validation.values.city || ""} ${props.validation.values.postcode || ""} ${props.validation.values.stateName || ""}, ${props.validation.values.countryName || ""}`}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Source" })}</p>
                <div className="flex-align-items-center-without-height mb-2">
                  <h6 className="mb-0">{props.validation.values.mediaSource}</h6>
                  {
                    blnPermissionEditCustomerMediaSource
                    &&
                    blnOrginalBranchMatchUserBranch
                    &&
                    <div className='margin-left-4'>
                      <TableActionButton
                        type="edit"
                        onClick={() => {
                          props.setEditCustomerMediaSourceModal(true);
                        }}/>
                    </div>
                  }
                </div>
              </div>
            </Col>
            {
              props.validation.values.mediaSource === Constants.referral
              &&
              (props.validation.values.referredById !== null && props.validation.values.referredByName !== null)
              &&
              <Col sm="12">
                <div>
                  <p className="text-muted mb-2">{intl.formatMessage({ id: "ReferredBy" })}</p>
                  <div className="flex-align-items-center-without-height mb-2">
                    <div>
                      <AccountVoiceIcon className="text-muted mb-1" size={18}/>
                    </div>
                    <h6 className='margin-left-4 mb-0'>{props.validation.values.referredByName}</h6>
                  </div>
                </div>
              </Col>
            }
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Race" })}</p>
                <h6>{props.validation.values.raceName || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Occupation" })}</p>
                <h6>{props.validation.values.occupation || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "SubBranch" })}</p>
                <div className="flex-align-items-center-without-height mb-2">
                  <h6 className="mb-0">{props.validation.values.subBranchName || Constants.emptyData}</h6>
                  <div className='margin-left-4'>
                    {
                      !props.validation.values.subBranchName
                      &&
                      blnOrginalBranchMatchUserBranch
                      &&
                      blnPermissionCreateCustomerSubBranchRequest
                      &&
                      <TableActionButton
                        type="add"
                        disabled={Boolean(props.successMessage)}
                        onClick={() => {
                          props.setSubBranchRequestModal(true);
                        }}/>
                    }
                    {
                      props.validation.values.subBranchName
                      &&
                      blnOrginalBranchMatchUserBranch
                      &&
                      <div className="flex-align-items-center-without-height">
                        {/* {
                          blnPermissionCreateCustomerSubBranchRequest
                          &&
                          <TableActionButton
                            type="edit"
                            onClick={() => {
                              props.setSubBranchRequestModal(true);
                            }}/>
                        } */}
                        {
                          blnPermissionDeleteCustomerSubBranchRequest
                          &&
                          <TableActionButton
                            type="delete"
                            onClick={() => {
                              props.setSubBranchRemoveModal(true);
                            }}/>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Col>
            {
              props.validation.values.subBranchName && props.validation.values.subBranchConsultantName
              &&
              <Col sm="12">
                <div>
                  <p className="text-muted mb-2">{intl.formatMessage({ id: "SubBranchConsultant" })}</p>
                  <h6 className="">{props.validation.values.subBranchConsultantName || Constants.emptyData}</h6>
                </div>
              </Col>
            }
            {
              props.validation.values.appolousId !== null
              &&
              props.isShowAppolous
              &&
              <Col sm="12">
                <div
                  className='pointer-clickable'
                  onClick={()=> {
                    window.open(`https://app.appolous.com/clients/${props.validation.values.appolousId}`)
                  }}>
                  <div className='flex-direction-row'>
                    <p className="text-muted mb-2">{intl.formatMessage({ id: "ViewAppolous" })}</p>
                    <ArrowTopRightBoldBoxOutlineIcon className="margin-left-4" size={18} color={Constants.tabBlue}/>
                  </div>
                  <h6>{props.validation.values.appolousId || Constants.emptyData}</h6>
                </div>
              </Col>
            }
            <Col sm="12">
              <div>
                <p className="text-muted mb-0">{intl.formatMessage({ id: "Tags" })}</p>
                <div className="flex-align-items-center-without-height flex-flow-wrap">
                  {props.validation.values.tags.map((valueTag)=> displayTagUI(valueTag, blnPermissionUpdateCustomer, onClickTagFunction))}
                  {
                    blnPermissionUpdateCustomer
                    &&
                    <TableActionButton
                      type="add"
                      onClick={() => {
                        props.setAddTagModal(true);
                      }}/>
                  }
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className='mt-2'>
                <p className="text-muted mb-0">{intl.formatMessage({ id: "Relationship" })}</p>
                {props.validation.values.relationships.map((valueRelationship)=> displayRelationshipUI(valueRelationship))}
                {
                  blnPermissionUpdateCustomer
                  &&
                  <MyButton
                    type="button"
                    class="btn btn-success mt-1 button-padding-overwrite"  
                    content={intl.formatMessage({ id: "Add" })}
                    onClick={()=> {
                      props.setAddRelationshipModal(true);
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <div>
      {
        displayCustomerProfileDetail()
      }
    </div>
  )
}
