import { PaginationRequestBody } from "../models/pagination";
import { AppolousCustomerSaleDetailsObject, AppolousCustomerSaleListObject, AppolousSalesAddPaymentObject, AppolousSalesUpdatePaymentObject } from "../models/appolousCustomerSales";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { convertArrayToStringDisplay } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class AppolousStore {
  appolousCustomerSalesList: AppolousCustomerSaleListObject[] = [];
  appolousCustomerSalesDetail: AppolousCustomerSaleDetailsObject | undefined = undefined;
  appolousCustomerSalesListTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.appolousCustomerSalesList = [];
    this.appolousCustomerSalesDetail = undefined;
    this.appolousCustomerSalesListTotalItems = 0;
  }

  setAppolousCustomerSalesList = (appolousCustomerSalesList: AppolousCustomerSaleListObject[]) => {
    this.appolousCustomerSalesList = appolousCustomerSalesList;
  }
  
  setAppolousCustomerSalesDetail= (appolousCustomerSalesDetail: AppolousCustomerSaleDetailsObject | undefined) => {
    this.appolousCustomerSalesDetail = appolousCustomerSalesDetail;
  }

  getAppolousCustomerSales = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultAppolousCustomerSales = await agent.AppolousCustomerSales.appolousCustomerSale(PaginationRequestBody);
      runInAction(() => {
        this.appolousCustomerSalesList = resultAppolousCustomerSales.data;
        this.appolousCustomerSalesListTotalItems = resultAppolousCustomerSales.pagination.totalItems;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerSalesList = [];
      this.appolousCustomerSalesListTotalItems = 0;
    }
  };

  getAppolousCustomerSalesDetail = async (id: string) => {
    try {
      let resultAppolousCustomerProductDetail = await agent.AppolousCustomerSales.appolousCustomerSaleDetail(id);
      //Comment this sorting function because backend will return sorted array
      // resultAppolousCustomerProductDetail.appolousPayments = resultAppolousCustomerProductDetail.appolousPayments.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
      
      runInAction(() => {
        this.appolousCustomerSalesDetail = resultAppolousCustomerProductDetail;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerSalesDetail = undefined;
    }
  };

  appolousSubmitAdditionalPayment = async (appolousSalesAddPaymentRequestBody: AppolousSalesAddPaymentObject) => {
    try{
      let resultAdditionalPayment = await agent.AppolousCustomerSales.appolousSubmitAdditionalPayment(appolousSalesAddPaymentRequestBody);
      store.commonStore.setSuccessMessage(`AppolousSalesPaymentAddedSuccess`)
      
      if(resultAdditionalPayment){
        return Promise.resolve({status: Constants.success, data: resultAdditionalPayment})
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  appolousUpdateAdditionalPayment = async (appolousSalesUpdatePaymentRequestBody: AppolousSalesUpdatePaymentObject) => {
    try{
      let resultAdditionalPayment = await agent.AppolousCustomerSales.appolousUpdateAdditionalPayment(appolousSalesUpdatePaymentRequestBody);
      store.commonStore.setSuccessMessage(`AppolousSalesPaymentUpdatedSuccess`)
      
      if(resultAdditionalPayment){
        return Promise.resolve({status: Constants.success, data: resultAdditionalPayment})
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  voidAppolousCustomerSalesPayment = async (id : string) => {
    try{
      await agent.AppolousCustomerSales.voidAppolousCustomerSalesPayment(id);
      store.commonStore.setSuccessMessage(`AppolousCustomerSalesPaymentVoidSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  voidAppolousCustomerSales = async (id : string) => {
    try{
      await agent.AppolousCustomerSales.voidAppolousCustomerSales(id);
      store.commonStore.setSuccessMessage(`AppolousCustomerSalesVoidSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
