import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from "react-intl";
import moment from 'moment';
import { Constants } from '../../../app/constants/Constants';
import { checkValidDate, leadStatusColor } from '../../../app/common/function/function';
import ContactsIcon from 'mdi-react/ContactsIcon';
import GeneralStatus from '../../../app/components/form/GeneralStatus';
import GeneralImageWithBase64 from '../../../app/components/form/GeneralImageWithBase64';
import { WindowObject } from '../../../app/models/common';

interface Props {
  validation: any;
  isMenu: boolean;
  toggleMenu: Function;
  windowSize: WindowObject;
}

export default function LeadProfileDetail(props: Props) {
  const intl = useIntl();

  const leadStatusBadgeColor = (status: string) => {
    switch (status) {
      case `${Constants.open}`:
        return "info";
      case `${Constants.followUp}`:
        return "warning";
      case `${Constants.appointmentMade}`:
        return "pink";
      case `${Constants.turnedUp}`:
        return "success";
      case `${Constants.closed}`:
        return "danger";
      default:
        return "dark";
    }
  }

  const displayLeadProfileDetail = () => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="me-2">
              <ContactsIcon 
                className='text-primary h1'/>
            </div>
            <div className="flex-grow-1">
              <div className="text-muted">
                {
                  <h5>{`${(props.validation.values.name.toUpperCase())}`}</h5>
                }
                <h6 className="mb-1">{props.validation.values.brandName}</h6>
                {
                  props.validation.values.isRejoin &&
                  <h6>
                    <span className="badge badge-soft-dark rounded-pill align-bottom text-center mt-1 mb-1" style={{ fontSize: "13px" }}>
                      {`${intl.formatMessage({ id: "RejoinCustomer" })}`}
                    </span>
                  </h6>
                }
                {
                  props.validation.values.isCrossBrand &&
                  <h6>
                    <span className="badge badge-soft-dark rounded-pill align-bottom text-center mt-1 mb-1" style={{ fontSize: "13px" }}>
                      {`${intl.formatMessage({ id: "CrossBrandCustomer" })}`}
                    </span>
                  </h6>
                }
                <GeneralStatus
                  status={props.validation.values.status}
                  statusColorFunction={leadStatusColor}
                  className="mt-2 margin-bottom-2"
                  additionalTextLeft={`${intl.formatMessage({ id: "Status" })} : `}
                  blnDisableCenter/>
                {
                  props.validation.values.subBrandName
                  &&
                  <div className="mt-3">
                    <GeneralImageWithBase64
                      id={`brand_bagde_lead`}
                      imageWidth='30px'
                      base64Image={props.validation.values.subBrandIcon}
                      />
                    <UncontrolledTooltip placement="top" target={`brand_bagde_lead`}>
                      {props.validation.values.subBrandName}
                    </UncontrolledTooltip>
                  </div>
                }
              </div>
            </div>
          </div>
        </CardBody>
        <CardBody className="border-top">
        <Row>
          <Col sm="12" xs="12" xl="6" className="text-center">
            <div className="text-sm mt-sm-0">
              <h6 className='text-truncate'>
                {intl.formatMessage({ id: "LastFollowedUpDate" }).toUpperCase()} 
              </h6> 
              <span className={`badge badge-soft-warning ms-2 align-bottom text-center ${Constants.innerWidthCompare > props.windowSize.innerWidth ? "" : "margin-top-8"}`} style={{fontSize:"15px"}}>
                {checkValidDate(props.validation.values.lastFollowUpDate)}
              </span>
            </div>
          </Col>
          <Col sm="12" xs="12" xl="6" className={`text-center ${Constants.innerWidthCompare > props.windowSize.innerWidth ? "margin-top-16" : ""}`}>
            <div className="text-sm mt-sm-0">
              <h6 className='text-truncate'>
                {intl.formatMessage({ id: "LastAppointmentDate" }).toUpperCase()} 
              </h6>  
              <span className={`badge badge-soft-success ms-2 align-bottom text-center ${Constants.innerWidthCompare > props.windowSize.innerWidth ? "" : "margin-top-8"}`} style={{fontSize:"15px"}}>
                {checkValidDate(props.validation.values.lastAppointmentDate)}
              </span>
            </div>
          </Col>
        </Row>
      </CardBody>
        <CardBody className="border-top">
          <Row>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "LeadNo" })}</p>
                <h6>{props.validation.values.leadNo}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "CreatedAt" })}</p>
                <h6>{moment(props.validation.values.submissionDate).format(Constants.displayDateFormat)}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Mobile" })}</p>
                <h6>{props.validation.values.mobileNo}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Email" })}</p>
                <h6>{props.validation.values.email || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Address" })}</p>
                <h6>{`${props.validation.values.addressLine1 || ""} ${props.validation.values.addressLine2 || ""} ${props.validation.values.city || ""} ${props.validation.values.postcode || ""} ${props.validation.values.stateName || ""}, ${props.validation.values.countryName || ""}`}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Source" })}</p>
                <h6>{props.validation.values.mediaSource}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "Race" })}</p>
                <h6>{props.validation.values.raceName || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "PreferredBranch" })}</p>
                <h6>{props.validation.values.branchName || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "PreferredConsultant" })}</p>
                <h6>{props.validation.values.consultantName || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "SelectedServiceTreatment" })}</p>
                <h6>{props.validation.values.serviceTreatmentName || Constants.emptyData}</h6>
              </div>
            </Col>
            <Col sm="12">
              <div>
                <p className="text-muted mb-2">{intl.formatMessage({ id: "AppointedBy" })}</p>
                <h6>{props.validation.values.marcomConsultantName || Constants.emptyData}</h6>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <div>
      {
        displayLeadProfileDetail()
      }
    </div>
  )
}
