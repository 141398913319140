import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { EmployeeListObject } from "../../../app/models/employee";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { Link, useLocation } from 'react-router-dom';
import CustomerSearchModal from "../../CustomerPurchase/Modal/CustomerSearchModal";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { text } from "stream/consumers";

const PromoCodeHistory = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, branchStore, customerStore, commonStore, staticReferenceStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { promoCodeHistoryList, getPromoCodeHistoryList, setPromoCodeHistoryList, exportPromoCodeHistory } = reportMarcomStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { purchaseType, getStaticReferenceWithType } = staticReferenceStore;


  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [productTypeList, setProductTypeList] = useState<StaticReferenceDataObject[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedProductType, setSelectedProductType] = useState<string>(AllType);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<number | string | undefined>(" ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);
  

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      promoCode: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchCollectionListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.purchaseType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setProductTypeList(resultAPI[1]);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchCollectionListAPI();

    return (() => {
      setPromoCodeHistoryList([]);
    })
  }, []);

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchPromoCodeHistoryListWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportPromoCodeHistory({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, customerId: selectedCustomerId, promoCode: validation.values.promoCode, type: selectedProductTypeId });
    }
    else{
      setCurrentPage(1);
      await getPromoCodeHistoryList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, customerId: selectedCustomerId, promoCode: validation.values.promoCode, type: selectedProductTypeId });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const PromoCodeHistoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "redemptionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.redemptionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.redemptionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.customerName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "itemName",
      text: intl.formatMessage({ id: "RedeemedItem" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.itemName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "purchaseType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.purchaseType || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.quantity}
        </div>
      ),
    },
    {
      dataField: "promotionCode",
      text: intl.formatMessage({ id: "PromotionCode" }).toUpperCase(),
      // formatter: (cellContent, row) => 
      // <>
      //   <Link to={row.promotionCode ? `/${RoutesList.promotion}/view/${row.promotionId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
      //     <Label className="margin-bottom-0 margin-left-4">{row.promotionCode || Constants.emptyData}</Label>
      //   </Link>
      // </>
    },

  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PromotionCodeHistory" }) })}
        title={intl.formatMessage({ id: "PromotionCodeHistory" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchPromoCodeHistoryListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Promotion-Code-History.aspx`}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              initialValue={selectedDateRange}
              validationRequired={true} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          {
            displayCustomerDropdown &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Customer" })}
                name="customer"
                placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                options={customerDropdownList}
                labelField="preferredName"
                valueField="id"
                initialLabel={selectedCustomer}
                initialValue={selectedCustomerId}
                setFieldLabel={setSelectedCustomer}
                setFieldValue={setSelectedCustomerId}
                disabled={localLoading || loading}
                onChange={() => {
                  clearCustomerDropdownList();
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                blnSupportCustomSearch={true}
                onChangeCustomSearch={(value)=> {
                  fetchCustomerDropdown(value);
                }}
                onMenuOpenFunction={()=> {
                  clearCustomerDropdownList();
                  setLoadingCustomer(false);
                }}
                loadingCustomSearch={loadingCustomer}
                setLoadingCustomSearch={setLoadingCustomer}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "PromotionCode" })}
              name="promoCode"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterPromoCode" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
          <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Type" })}
                name="Type"
                options={productTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedProductType}
                initialValue={selectedProductTypeId}
                setFieldLabel={setSelectedProductType}
                setFieldValue={setSelectedProductTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading || loadingCustomer}
              disable={localLoading || loading || loadingCustomer}
              onClick={() => {
                fetchPromoCodeHistoryListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PromoCodeHistoryColumns}
              keyField={"id"}
              data={promoCodeHistoryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PromotionCodeHistory" }) })}
              objSorted={defaultSorted}
              requestAPI={getPromoCodeHistoryList}
              branchId={selectedBranchId}
              customerId={selectedCustomerId}
              dateRange={selectedDateRange}
              promoCode={validation.values.promoCode}
              productType={selectedProductTypeId}
              productTypeName={selectedProductType}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(PromoCodeHistory);
