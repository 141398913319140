import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName,
  returnCurrency,
  returnExpiredDate,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { StockReturnDetailObject } from "../../app/models/stockReturnToHQ";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import moment from "moment";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const StockReturnToHQDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockReturnToHQStore, productStore, branchStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockReturnToHQ, updateStockReturnToHQ, stockReturnToHQDetail, setStockReturnToHQDetail, getStockReturnToHQWithId, getBranchProductForHQStockReturnList } = stockReturnToHQStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockReturnToHQ" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockReturnToHQ" }), urlPath: RoutesList.stockReturnToHQ }];
  const [activeTab, setActiveTab] = useState(1);
  const [loadingBranch, setLoadingBranch] = useState(false);

  const [productStockReturnToHQlist, setProductStockReturnToHQlist] = useState<StockReturnDetailObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "quantity",
      inputType: "number",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "IsDefect" }).toUpperCase(),
      type: "input",
      xl: "1",
      inputName: "isDefect",
      inputType: "checkbox",
      checkBoxText: intl.formatMessage({ id: "Yes" }),
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      type: "input",
      xl: "5",
      inputName: "remark",
      inputType: "text",
    },
  ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockReturnToHQDetail || {
      branchId: "",
      branchName: "",
      note: "",
      stockReturnDetails: [],
      workflowAction: "",
      status: "",
      comment: ""
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let stockReturnToHQDetailsTemp = _.cloneDeep(valuesTemp.stockReturnDetails);
      let blnEmptyProduct = false;
      let blnEmptyQuantityBlock = false;
      if (stockReturnToHQDetailsTemp.length === 0) {
        blnEmptyProduct = true;
      }
      
      stockReturnToHQDetailsTemp = stockReturnToHQDetailsTemp.map((value, index) => {
        if (value.quantity === 0) {
          blnEmptyQuantityBlock = true
        }
      })

      if (blnEmptyProduct) {
        setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Product" })}));
        return;
      }
      if (blnEmptyQuantityBlock) {
        setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" })}));
        return;
      }

      try {
        if (addAction) {
          await addStockReturnToHQ(valuesTemp);
        } else {
          await updateStockReturnToHQ(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnStatusProcessing = (validation.values.status === Constants.processing);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnPermissionUpdateStockReturnToHQ = checkPermission([PermissionConstants.UpdateStockReturnToHQ]);

  useEffect(() => {
    async function fetchStockReturnToHQDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getStockReturnToHQWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStockReturnToHQDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockReturnToHQ], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockReturnToHQ], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockReturnToHQ], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateStockReturnToHQ){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockReturnToHQ}/edit/${id}`)} })
    }

    fetchStockReturnToHQDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockReturnToHQDetail(undefined);
      }
    })
  }, []);

  const onChangeBranch = async (value, removeData: boolean = true) => {
    setLoadingBranch(true);
    let productWithBranchIdTemp = await getBranchProductForHQStockReturnList(value) || [];
    setLoadingBranch(false);
    let productStockReturnDetailList : StockReturnDetailObject[] = [];

    productWithBranchIdTemp.map((valueProductList) => 
    {
      productStockReturnDetailList.push({
        id: undefined,
        productId: valueProductList.productId,
        productName: valueProductList.productName,
        quantity: valueProductList.defectQuantity,
        isDefect: true,
        remark: ""
      })
    })
    setProductStockReturnToHQlist(productStockReturnDetailList);
    if (removeData) {
      validation.setFieldValue("stockReturnDetails", []);
    }
  }

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisable = () => {
    if (!validation.values.branchId) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockReturnToHQ" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockReturnToHQ" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent>
                        <DropDownWithTitle
                            name={"branchId"}
                            title={intl.formatMessage({ id: "Branch" })}
                            className="mb-3 mt-4"
                            specifyReturnFieldName={[
                              {
                                field: "branchId",
                                value: "id",
                              },
                              {
                                field: "branchName",
                                value: "name",
                              },
                            ]}
                            labelField={"name"}
                            valueField={"id"}
                            options={branchDropDownList}
                            loading={loadingBranch}
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={onChangeBranch}
                            initialValue={validation.values.branchId}
                            initialLabel={validation.values.branchName}
                            validationRequired={true}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title={intl.formatMessage({ id: "Note" })}
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="stockReturnDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={true}
                        blnBtnModalBottom={true}
                        options={productStockReturnToHQlist}
                        optionsId={"productId"}
                        setOptionsFunction={setProductStockReturnToHQlist}
                        addButtonFunction={() => setProductModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.stockReturnDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              hidePrimaryButton={blnStatusProcessing || blnTab1}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", 'Submit')
              }}
            />
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={AddOptionsValueField(productStockReturnToHQlist, "name", "id")}
          setOptionsFunction={setProductStockReturnToHQlist}
          fieldInput={validation.values.stockReturnDetails}
          fieldName={"stockReturnDetails"}
          validation={validation} />
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockReturnToHQDetail);
