import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import { CustomerTransferRequestListObject } from "../../app/models/customerTransferRequest";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import moment from "moment";
import { customerTransferRequestStatusColor, checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, returnPriceWithCurrency, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { RoutesList } from "../../app/constants/RoutesList";
import { PaginationRequestBody } from "../../app/models/pagination";
import { history } from "../..";
import { Link, useLocation } from 'react-router-dom';

const CustomerTransferRequest = () => {
  const intl = useIntl();
  //Use Store
  const { customerTransferRequestStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setErrorMessage, setSuccessMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { customerTransferRequestList, getCustomerTransferRequest, deleteCustomerTransferRequest, customerTransferRequestPaginationParams, setCustomerTransferRequestPaginationParams } = customerTransferRequestStore;
  const { customerTransferStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CustomerTransferRequestListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Requests" }), urlPath: "" }];
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCustomerTransferRequestStatus, setSelectedCustomerTransferRequestStatus] = useState<string>(AllStatus);
  const [selectedCustomerTransferRequestStatusId, setSelectedCustomerTransferRequestStatusId] = useState<number | string | undefined>(" ");
  const [customerTransferRequestStatusList, setCustomerTransferRequestStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedFromBranchId, setSelectedFromBranchId] = useState(getBranchId());
  const [selectedToBranchId, setSelectedToBranchId] = useState("");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedIdentityType, setSelectedIdentityType] = useState<string>(AllType);
  const [selectedIdentityTypeId, setSelectedIdentityTypeId] = useState<number | string | undefined>(" ");
  const [identityTypeDropdownList, setIdentityTypeDropdownList] = useState<StaticReferenceDataObject[]>([]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionUpdateCustomerTransferRequest = checkPermission([PermissionConstants.UpdateCustomerTransferRequest]);
  const blnPermissionDeleteCustomerTransferRequest = checkPermission([PermissionConstants.DeleteCustomerTransferRequest]);
  const blnPermissionManageCustomer =  checkPermission([PermissionConstants.ManageCustomer]);
  const [blnOwnBranch, setBlnOwnBranch] = useState(false);


  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerNo: "",
      identityNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerTransferRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    toBranchId: selectedToBranchId,
    fromBranchId: selectedFromBranchId,
    customerTransferRequestStatus: selectedCustomerTransferRequestStatusId,
    customerTransferRequestStatusName: selectedCustomerTransferRequestStatus,
    customerId: "",
    customerName: validation.values.customerName,
    customerNo: validation.values.customerNo,
    identityType: selectedIdentityTypeId,
    identityTypeName: selectedIdentityType,
    identityNo: validation.values.identityNo
  }

  useEffect(() => {
    async function fetchCustomerTransferRequestListAPI() {
      setLocalLoading(true)

      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerTransferRequest)

      if (blnBackNavigation) {
        if (customerTransferRequestPaginationParams) {
          setCurrentPage(customerTransferRequestPaginationParams.pageNumber)
          
          setSelectedCustomerTransferRequestStatus(customerTransferRequestPaginationParams.customerTransferRequestStatusName || AllStatus)
          setSelectedCustomerTransferRequestStatusId(customerTransferRequestPaginationParams.customerTransferRequestStatus)
          setSelectedFromBranchId(customerTransferRequestPaginationParams.fromBranchId || "")
          setSelectedToBranchId(customerTransferRequestPaginationParams.toBranchId || "")
          setSelectedIdentityTypeId(customerTransferRequestPaginationParams.identityType || customerTransferRequestPaginationParams.identityType === 0 ? customerTransferRequestPaginationParams.identityType : " ")
          setSelectedIdentityType(customerTransferRequestPaginationParams.identityTypeName || AllType)
          validation.setValues((values)=> ({
            ...values,
            customerName: customerTransferRequestPaginationParams.customerName || "",
            customerNo: customerTransferRequestPaginationParams.customerNo || "",
            identityNo: customerTransferRequestPaginationParams.identityNo || "",
          }))
          if(customerTransferRequestPaginationParams.fromBranchId !== getBranchId()){
            setBlnOwnBranch(true)
          }
          customerTransferRequestBody = customerTransferRequestPaginationParams;
        }
      }
      else {
        setCustomerTransferRequestPaginationParams(undefined);
      }

      let aryAPI: any = [
        fetchCustomerTransferRequestAPI(false),
        getStaticReferenceWithType(Constants.customerTransferStatus, AllStatus),
        getStaticReferenceWithType(Constants.identityType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setCustomerTransferRequestStatusList(resultAPI[1]);
      setIdentityTypeDropdownList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerTransferRequest], true)) {
      return;
    }

    fetchCustomerTransferRequestListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerTransferRequest)
        if (!blnSetParams) {
          setCustomerTransferRequestPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      ((selectedCustomerTransferRequestStatusId || selectedCustomerTransferRequestStatusId === 0 )|| (selectedIdentityTypeId || selectedIdentityTypeId === 0)) &&
      !initialCallAPI
    ) {
      fetchCustomerTransferRequestAPI(true);
    }
  }, [selectedCustomerTransferRequestStatusId, selectedToBranchId, selectedFromBranchId, selectedIdentityTypeId, triggerSearch]);

  async function fetchCustomerTransferRequestAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerTransferRequest(customerTransferRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CustomerTransferRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerTransferDocumentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.customerTransferDocumentNo || Constants.emptyData}</div>,
    },
    {
      dataField: "toBranchName",
      text: intl.formatMessage({ id: "RequestBranch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.toBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer 
            && 
            row.fromBranchId == getBranchId()
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "identityNo",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
    },
    {
      dataField: "fromBranchName",
      text: intl.formatMessage({ id: "OriginalBranch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.fromBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "signedDate",
      text: intl.formatMessage({ id: "SignedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.signedDate !== Constants.invalidDate ? `${moment(row.signedDate).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.signedDate !== Constants.invalidDate ? moment(row.signedDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "approveBy",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.approveBy || Constants.emptyData}</div>,
    },
    {
      dataField: "approveOn",
      text: intl.formatMessage({ id: "ActionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.approveOn !== Constants.invalidDate ? `${moment(row.approveOn).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.approveOn !== Constants.invalidDate ? moment(row.approveOn).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={customerTransferRequestStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.customerTransferRequest}/view/${row.id}`}
              type={`view`} />
            {
              (blnPermissionUpdateCustomerTransferRequest)
              &&
              row.status == Constants.pending
              &&
              row.toBranchId == getBranchId()
              &&
              <TableActionButton
                to={`/${RoutesList.customerTransferRequest}/edit/${row.id}`}
                type={`edit`} />
            }
            {
              blnPermissionDeleteCustomerTransferRequest
              &&
              row.status == Constants.pending
              &&
              row.toBranchId == getBranchId()
              &&
              <TableActionButton
                onClick={() => onClickDelete(row)}
                type={'delete'} />
            }
          </div>

        </>
      ),
    },
  ];

  const onClickDelete = async (row: CustomerTransferRequestListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerTransferRequest" }) })}
        title={intl.formatMessage({ id: "CustomerTransferRequest" })}
        breadCrumbList={breadCrumbList}>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerTransferRequest" }) })}
                  name="customerTransferRequestStatus"
                  options={customerTransferRequestStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedCustomerTransferRequestStatus}
                  initialValue={selectedCustomerTransferRequestStatusId}
                  setFieldLabel={setSelectedCustomerTransferRequestStatus}
                  setFieldValue={setSelectedCustomerTransferRequestStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-26" : "margin-bottom-4"}`}>
                <div className="btn-group" role="group">
                  <input type="radio" className="btn-check" name="blnRequestFromOwn" id="blnRequestFromOwnFalse" autoComplete="off" disabled={localLoading || loading} defaultChecked={!blnOwnBranch}
                    onClick={() => {
                      setCurrentPage(1);
                      setSelectedFromBranchId(getBranchId())
                      setSelectedToBranchId("")
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth}}htmlFor="blnRequestFromOwnFalse">{intl.formatMessage({ id: "RequestByOtherBranch" })}</label>
                  <input type="radio" className="btn-check" name="blnRequestFromOwn" id="blnRequestFromOwnTrue" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnOwnBranch}
                    onClick={() => {
                      setCurrentPage(1);
                      setSelectedFromBranchId("")
                      setSelectedToBranchId(getBranchId())
                    }} />
                  <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth}} htmlFor="blnRequestFromOwnTrue">{intl.formatMessage({ id: "RequestByOwnBranch" })}</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerName" })}
                  name="customerName"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerName" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerNo" })}
                  name="customerNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "IdentityType" })}
                  name="identityType"
                  options={identityTypeDropdownList}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedIdentityType}
                  initialValue={selectedIdentityTypeId}
                  setFieldLabel={setSelectedIdentityType}
                  setFieldValue={setSelectedIdentityTypeId}
                  blnValueWithNewSpace={true}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                    validation.setFieldValue("identityNo", "")
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "IdentityNo" })}
                  name="identityNo"
                  type="text"
                  disabled={localLoading || loading || !(selectedIdentityTypeId || selectedIdentityTypeId === 0)}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterIdentityNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerTransferRequestColumns}
              data={customerTransferRequestList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerTransferRequest" }) })}
              objSorted={defaultSorted}
              toBranchId={selectedToBranchId}
              fromBranchId={selectedFromBranchId}
              customerTransferRequestStatus={selectedCustomerTransferRequestStatusId}
              customerTransferRequestStatusName={selectedCustomerTransferRequestStatus}
              customerName={validation.values.customerName}
              customerNo={validation.values.customerNo}
              identityType={selectedIdentityTypeId}
              identityTypeName={selectedIdentityType}
              identityNo={validation.values.identityNo}
              requestAPI={getCustomerTransferRequest}
            />
          </>
        )}
      </ListViewLayout>
      <DeleteModal
        displayName={""}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteCustomerTransferRequest(selectedRow?.id || "" || "");
          let currentPageTemp = currentPage;
          if (customerTransferRequestList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getCustomerTransferRequest({ pageNumber: currentPageTemp, pageSize: pageSize })
          setLoading(false);
        }} />
    </div>
  );
};

export default observer(CustomerTransferRequest);
