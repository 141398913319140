import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { DatumObject, RowDataObject } from "../../../models/dashboard";

interface Props {
	data?: DatumObject[];
  rowData?: RowDataObject;
}

interface DougnutObject {
  name: string;
  value: number;
}

const Doughnut = (props: Props) => {
  const [aryFinalData, setAryFinalData] = useState<DougnutObject[]>([]);

  useEffect(()=> {
    let aryDataTemp : any = [];
    let aryCategoryTemp : any = [];
    let aryFinalTemp: DougnutObject[] = [];
    if (props.data) {
      if (props.data.length > 0) {
        aryDataTemp = props.data[0].data;
      }
    }
    if (props.rowData) {
      if (props.rowData.categories) {
        aryCategoryTemp = props.rowData.categories; 
      }
    }

    aryDataTemp.map((valueAryDataTemp, indexAryDataTemp)=> {
      aryFinalTemp.push({
        name: aryCategoryTemp.length > indexAryDataTemp ? aryCategoryTemp[indexAryDataTemp] : "",
        value: valueAryDataTemp
      })
    })
    setAryFinalData(aryFinalTemp);
  }, [props.data, props.rowData])

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: props.rowData?.categories || [],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
    series: [
      {
        name: "",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: "30",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: aryFinalData,
      },
    ],
  };
  return (
    <div>
      <ReactEcharts style={{ height: "235px" }} option={options} />
    </div>
  );
};
export default Doughnut;
