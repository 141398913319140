import { PaginationRequestBody } from "../models/pagination";
import { DeliveryOrderListObject, DeliveryOrderDetailObject, DeliveryOrderSignObject, DeliverOrderVoidObject, DeliverOrderUpdateObject, DeliveryOrderProductListObject, EditDeliverOrderDetailObject } from "../models/deliveryOrder";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { toast } from "react-toastify";

export default class DeliveryOrderStore {
  deliveryOrderList: DeliveryOrderListObject[] = [];
  deliveryOrderDetail: DeliveryOrderDetailObject | undefined = undefined;
  deliveryOrderPaginationParams: PaginationRequestBody | undefined = undefined;
  deliveryOrderProductList: DeliveryOrderProductListObject[] = [];
  customerIdFromCustomerPurchase: string = "";

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.deliveryOrderList = [];
    this.deliveryOrderDetail = undefined;
    this.deliveryOrderProductList = [];
    this.deliveryOrderPaginationParams= undefined;
    this.customerIdFromCustomerPurchase = "";
  }

  setDeliveryOrderList = (deliveryOrderList: DeliveryOrderListObject[]) => {
    this.deliveryOrderList = deliveryOrderList;
  }

  setDeliveryOrderDetail = (deliveryOrderDetail: DeliveryOrderDetailObject | undefined) => {
    this.deliveryOrderDetail = deliveryOrderDetail;
  }

  setDeliveryOrderPaginationParams = (deliveryOrderPaginationParams: PaginationRequestBody | undefined) => {
    this.deliveryOrderPaginationParams = deliveryOrderPaginationParams;
  }

  setCustomerIdFromCustomerPurchase = (customerIdFromCustomerPurchase: string) => {
    this.customerIdFromCustomerPurchase = customerIdFromCustomerPurchase;
  }

  setDeliveryOrderProductList = (deliveryOrderProductList: DeliveryOrderProductListObject[]) => {
    this.deliveryOrderProductList = deliveryOrderProductList;
  }
  
  getDeliveryOrder = async (PaginationRequestBody: PaginationRequestBody, returnNoneAutoGeneratedDO?: boolean) => {
    this.setDeliveryOrderPaginationParams(PaginationRequestBody);
    try{
      let resultDeliveryOrder = await agent.DeliveryOrder.deliveryOrderList(PaginationRequestBody);
      if(returnNoneAutoGeneratedDO){
         resultDeliveryOrder.data = resultDeliveryOrder.data.filter(valueDeliverOrderTemp => {
          return valueDeliverOrderTemp.isSystemAutoGenerated !== true;
        });
      }
      runInAction(() => {
        this.deliveryOrderList = resultDeliveryOrder.data;
        store.commonStore.setTotalItem(resultDeliveryOrder.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.deliveryOrderList = [];
    }
  }

  getDeliveryOrderWithId = async (id: string) => {
    try{
      const resultDeliveryOrderDetail = await agent.DeliveryOrder.deliveryOrderDetail(id);
      runInAction(() => {
        if (resultDeliveryOrderDetail.deliverOrderDetails) {
          resultDeliveryOrderDetail.deliverOrderDetails = resultDeliveryOrderDetail.deliverOrderDetails.sort((a, b) => (a.redeemType > b.redeemType) ? 1 : -1);
        }
        this.deliveryOrderDetail = resultDeliveryOrderDetail;
      });
      return Promise.resolve(resultDeliveryOrderDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.deliveryOrderDetail = undefined;
    }
  }

  signDeliveryOrder = async (signDeliveryOrderRequestBody: DeliveryOrderSignObject) => {
    try{
      await agent.DeliveryOrder.signDeliveryOrder(signDeliveryOrderRequestBody);
      store.commonStore.setSuccessMessage(`CustomerRedemptionSignSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
  
  updateDeliverOrder = async (updateDeliverOrderRequestBody: DeliverOrderUpdateObject) => {
    try{
      const resultDeliverOrderUpdate = await agent.DeliveryOrder.updateDeliverOrder(updateDeliverOrderRequestBody);
      store.commonStore.setSuccessMessage(`CustomerRedemptionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  requestVoidDeliveryOrder = async (requestVoidDeliveryOrderRequestBody: DeliverOrderVoidObject) => {
    try{
      await agent.DeliveryOrder.requestVoidDeliveryOrder(requestVoidDeliveryOrderRequestBody);
      store.commonStore.setSuccessMessage(`VoidCustomerRedemptionRequestSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewVoidDeliveryOrder = async (reviewVoidDeliveryOrderRequestBody: DeliverOrderVoidObject) => {
    try{
      await agent.DeliveryOrder.reviewVoidDeliveryOrder(reviewVoidDeliveryOrderRequestBody);
      store.commonStore.setSuccessMessage(`VoidCustomerRedemptionReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getDeliveryOrderProductList = async (id: string) => {
    try{
      let resultDeliveryOrderProductList = await agent.DeliveryOrder.deliveryOrderProductList(id);

      runInAction(() => {
        if (resultDeliveryOrderProductList.length > 0) {
          resultDeliveryOrderProductList = resultDeliveryOrderProductList.map((valueResultDeliveryOrderProductList, index)=> {
            return({
              ...valueResultDeliveryOrderProductList,
              randomId: `${valueResultDeliveryOrderProductList.deliverOrderNo}_${index}`, 
            })
          })
        }
        this.deliveryOrderProductList = resultDeliveryOrderProductList;
      });
      return Promise.resolve(resultDeliveryOrderProductList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.deliveryOrderProductList = [];
    }
  }

  regenerateProductDeliverOrder = async (id: string)  => {
    try{
      let resultRegenerateProductDeliverOrder = await agent.DeliveryOrder.regenerateProductDeliverOrder(id);
      toast.success(`Customer Product Redemption has regenerated successfully.`)
      return Promise.resolve({status: Constants.success, data: resultRegenerateProductDeliverOrder})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  editDeliveryOrderDetail = async (editDeliverOrderDetailObjectRequestBody: EditDeliverOrderDetailObject) => {
    try {
      await agent.DeliveryOrder.editDeliverOrderDetail(editDeliverOrderDetailObjectRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CustomerRedemptionDetailEditSuccess`);
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };
}
