import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, IncludesLocationPathName } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../../app/components/layout/DetailViewLayout";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";
import SignaturePad from "../../../app/components/form/SignaturePad";
import MyButton from "../../../app/components/form/MyButton";
import GeneralTextArea from "../../../app/components/form/GeneralTextArea";
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { history } from '../../../'

const AppolousCustomerPackageRedemptionDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { appolousCustomerPackageStore, commonStore } = useStore();
  const { loading, setLoading, successMessage } = commonStore;
  const { appolousCustomerPackageRedemptionDetail, getAppolousCustomerPackageRedemptionDetail, setAppolousCustomerPackageRedemptionDetail, voidAppolousCustomerPackageRedemption } = appolousCustomerPackageStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Record" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: appolousCustomerPackageRedemptionDetail?.customerName || "", urlPath: `${RoutesList.customer}/view/${appolousCustomerPackageRedemptionDetail?.customerId}`}, { title: appolousCustomerPackageRedemptionDetail?.packageName || "", urlPath: `${RoutesList.customer}/${RoutesList.appolousCustomerPackage}/view/${appolousCustomerPackageRedemptionDetail?.appolousCustomerPackageId}`}];
  const [localLoading, setLocalLoading] = useState(false);
  const [voidAppolousCustomerPackageRedemptionModal, setVoidAppolousCustomerPackageRedemptionModal] = useState(false);
  const [loadingVoidAppolousCustomerPackageRedemption, setLoadingVoidAppolousCustomerPackageRedemption] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: appolousCustomerPackageRedemptionDetail || {
      appolousCustomerPackageRedemptionId: "",
      packageName: "",
      sessionNo: 0,
      serviceName: "",
      remark: "",
      branchName: "",
      consultantName: "",
      createdAt: "",
      isVoidAllowed: true,
      consumableProductNames: [],
      consumableProductNamesDisplay: "",
      signatureImage: "",
      appolousCustomerPackageId: "",
      customerId: "",
      customerName: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchAppolousCustomerPackageRedemptionDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getAppolousCustomerPackageRedemptionDetail(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageAppolousRedemption], true)) {
      return;
    }

    fetchAppolousCustomerPackageRedemptionDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setAppolousCustomerPackageRedemptionDetail(undefined);
      }
    })
  }, []);

  async function onVoidAppolousCustomerPackageRedemptionAPI() {
    setLoadingVoidAppolousCustomerPackageRedemption(true);
    let resultVoidAppolousCustomerPackageRedemption = await voidAppolousCustomerPackageRedemption(validation.values.appolousCustomerPackageRedemptionId || "");
    if (resultVoidAppolousCustomerPackageRedemption.status === Constants.success) {
      setLoadingVoidAppolousCustomerPackageRedemption(false);
      setVoidAppolousCustomerPackageRedemptionModal(false);
      setTimeout(()=> {
        history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerPackage}/view/${validation.values.appolousCustomerPackageId}`)
      }, Constants.timeoutNavigation)
      return;
    }
    
    setLoadingVoidAppolousCustomerPackageRedemption(false);
    setVoidAppolousCustomerPackageRedemptionModal(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "Record" })}
                      h4Title />   
                    <Row className="mt-3">
                      <Col xl="6" md="6" xs="12">
                        <GeneralInput
                          title={intl.formatMessage({ id: "Date" })}
                          name="createdAt"
                          type="datetime-local"
                          disabled={viewAction}
                          validation={validation}
                        />
                        <GeneralInput
                          title={intl.formatMessage({ id: "Package" })}
                          name="packageName"
                          type="text"
                          disabled={viewAction}
                          validation={validation}
                        />
                        <GeneralInput
                          title={intl.formatMessage({ id: "ConsumableProducts" })}
                          name="consumableProductNamesDisplay"
                          placeholder={Constants.emptyData}
                          type="text"
                          disabled={viewAction}
                          validation={validation}
                        />
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "Remark" })}
                          name="remark"
                          placeholder={Constants.emptyData}
                          row={3}
                          disabled={viewAction}
                          validation={validation}
                        />
                      </Col>
                      <Col xl="6" md="6" xs="12">
                        <GeneralInput
                          title={intl.formatMessage({ id: "PerformBy" })}
                          name="consultantName"
                          type="text"
                          disabled={viewAction}
                          validation={validation}
                        />
                        <GeneralInput
                          title={intl.formatMessage({ id: "Service" })}
                          name="serviceName"
                          type="text"
                          disabled={viewAction}
                          validation={validation}
                        />
                        <GeneralInput
                          title={intl.formatMessage({ id: "SessionNo" })}
                          name="sessionNo"
                          type="text"
                          disabled={viewAction}
                          validation={validation}
                        />
                      </Col>
                    </Row>
                    <SignaturePad
                      title={intl.formatMessage({ id: "CustomerSignature" })}
                      className="margin-top--34"
                      successMessage={""}
                      validation={validation}
                      disabled={true}
                      isSubmitting={false} />
                    {
                      validation.values.isVoidAllowed && !Boolean(successMessage)
                      ?
                      <MyButton
                        type="button"
                        class="btn btn-danger mt-4"
                        content={intl.formatMessage({ id: "VoidRecord" })}
                        disable={localLoading || Boolean(successMessage)}
                        loading={localLoading}
                        onClick={async () => {
                          setVoidAppolousCustomerPackageRedemptionModal(true);
                        }}
                      />
                      :
                      <div className="mt-2"/>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
      {
        voidAppolousCustomerPackageRedemptionModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "VoidAppolousCustomerPackageRedemptionAlert" })}`}
          subTitle={`(${validation.values.serviceName}) ?`}
          primaryActionTitle={`Yes`}
          showPrompt={voidAppolousCustomerPackageRedemptionModal}
          setShowPrompt={setVoidAppolousCustomerPackageRedemptionModal}
          onPrimaryClick={()=> {
            onVoidAppolousCustomerPackageRedemptionAPI()
          }}
          localLoading={loadingVoidAppolousCustomerPackageRedemption}
        />
      }
    </div>
  );
};

export default observer(AppolousCustomerPackageRedemptionDetail);
