import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { MachineUsageListObject } from '../../app/models/machine';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnRouteFromModule } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { Link } from "react-router-dom"
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";

const MachineUsage = () => {
  const intl = useIntl();
  //Use Store
  const { branchStore, machineStore, staticReferenceStore, commonStore } = useStore();
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { loading, setLoading, totalItem, setErrorMessage, setSuccessMessage } = commonStore;
  const { machineUsageList, getMachineUsage, getMachineDropdown } = machineStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [machineListFinal, setMachineListFinal] = useState<ObjectForDropdown[]>([]);
  const AllMachine = `${intl.formatMessage({ id: "AllMachine" })}`;
  const [selectedMachineName, setSelectedMachineName] = useState(AllMachine);
  const [selectedMachineId, setSelectedMachineId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedMachineUsageType, setSelectedMachineUsageType] = useState<number | string>(AllType);
  const [selectedMachineUsageTypeId, setSelectedMachineUsageTypeId] = useState<number | string | undefined>(" ");
  const [machineUsageTypeList, setMachineUsageTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const blnHQStaff = !getBranchUser();

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  //validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      serialNumber: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchAppointmentAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getMachineDropdown(),
        getMachineUsage({ pageNumber: currentPage, pageSize: pageSize }),
        getStaticReferenceWithType(Constants.machineUsageType, AllType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setMachineListFinal(arraySpliceInAllValue(resultAPI[1], AllMachine, false, "name", "id"));
      setMachineUsageTypeList(resultAPI[3]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageMachineUsage], true)) {
      return;
    }

    fetchAppointmentAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchMachineUsageAPI(true);
    }
  }, [selectedBranchId, selectedMachineId, selectedDateRange, selectedMachineUsageTypeId, triggerSearch])

  async function fetchMachineUsageAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getMachineUsage({
      pageNumber: currentPage,
      pageSize: pageSize,
      branchId: selectedBranchId == AllBranch ? "" : selectedBranchId,
      machineId: selectedMachineId == AllMachine ? "" : selectedMachineId,
      machineUsageType: selectedMachineUsageTypeId,
      dateRange: selectedDateRange,
      serialNumber: validation.values.serialNumber
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const MachineUsageColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "machineName",
      text: intl.formatMessage({ id: "Machine" }).toUpperCase(),
    },
    {
      dataField: "machineSerialNumber",
      text: intl.formatMessage({ id: "SerialNumber" }).toUpperCase(),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "referenceEntityName",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => {
        let displayReferenceEntityName = newSpaceBeforeCapitalLetter(row.referenceEntityName);
        if (row.referenceEntityId === Constants.emptyId){
          return (
            <div>
              {displayReferenceEntityName || Constants.emptyData}
            </div>
          )
        }
        else {
          if (row.referenceEntity){
            let routePath = returnRouteFromModule(row.referenceEntity);
            if (routePath !== undefined){
              let finalRoutePath = `/${routePath}/view/${row.referenceEntityId}`;
              return (
                <Link className="d-block text-primary mb-2 h6"
                  to={finalRoutePath}
                  target="_blank"
                  rel="noopener noreferrer">
                  {displayReferenceEntityName}
                </Link>
              )
            }
            else {
              return (
                <div>
                  {displayReferenceEntityName || Constants.emptyData}
                </div>
              )
            }
          }
        }
      },
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        // <div className="text-center">{`${row.quantity}`}</div>
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "BalanceQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.balanceQuantity)}
            statusBackgroundColor={Math.sign(row.balanceQuantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "machineUsageType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.machineUsageType)}</div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "MachineUsage" }) })}
        title={intl.formatMessage({ id: "MachineUsage" })}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || !blnHQStaff}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Machine" })}
              name="machineName"
              labelField={"name"}
              valueField={"id"}
              options={machineListFinal}
              initialLabel={selectedMachineName}
              initialValue={selectedMachineId}
              setFieldLabel={setSelectedMachineName}
              setFieldValue={setSelectedMachineId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "MachineUsageType" })}
              name="machineUsageType"
              options={machineUsageTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedMachineUsageType}
              initialValue={selectedMachineUsageTypeId}
              setFieldLabel={setSelectedMachineUsageType}
              setFieldValue={setSelectedMachineUsageTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "SerialNumber" })}
              name="serialNumber"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSerialNumber" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange} />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={MachineUsageColumns}
              data={machineUsageList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "MachineUsage" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              machineId={selectedMachineId}
              machineUsageType={selectedMachineUsageTypeId}
              serialNumber={validation.values.serialNumber}
              dateRange={selectedDateRange}
              requestAPI={getMachineUsage} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(MachineUsage);
