import React from 'react'

interface Props {
  blnScrollToTop: boolean;
  refDiv: any;
  scrollToDiv?: boolean;
  blnModal?: boolean;
}

export default function ScrollButton(props: Props) {
  return (
    <div 
      style={{
        ...props.blnScrollToTop ? {position: 'fixed', right: props.blnModal ? '60px' : '20px', top:'90px'} : {position: 'fixed', right: props.blnModal ? '60px' : '20px', bottom:'20px'},
        ...props.blnModal && { backgroundColor: 'white', padding: '12px', borderRadius: '10px'}
      }}>
      <div onClick={() => { 
        if (props.blnScrollToTop && !props.scrollToDiv) {
          window.scrollTo(0, 0);
        }
        else {
          props.refDiv.current.scrollIntoView({ behavior: "smooth" });
        }
      }}>
        <i className={props.blnScrollToTop ? "bx bx-chevrons-up scrollButton" : "bx bx-chevrons-down scrollButton"}/>
      </div>
    </div>
  )
}