import { useEffect, useState } from "react";
import {Modal} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RedemptionCustomerPackage } from "../../../app/models/appolousCustomerPackage";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { AddOptionsValueField } from "../../../app/common/function/function";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import { DeliverOrderDetailArray } from "../../../app/models/deliveryOrder";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedDeliveryOrderRedemptionDetail: DeliverOrderDetailArray | undefined;
  setSelectedDeliveryOrderRedemptionDetail: Function;
  editFunction: Function;
  branchId: string;
}

export default function DeliveryOrderRedemptionDetailEditModal(props: Props) {
  const intl = useIntl();
  const { employeeStore } = useStore();
  const { getEmployeeDropdown } = employeeStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [localLoadingDeliveryOrderRedemption, setLocalLoadingDeliveryOrderRedemption] = useState(false);
  const [optionsEmployeeList, setOptionsEmployeeList] = useState([]);
  const [localSucessMessage, setLocalSuccessMessage] = useState("");

  const defaultDeliveryOrderRedemptionDetail = {
    customerDeliverOrderDetailId: props.selectedDeliveryOrderRedemptionDetail?.id || "",
    serviceById: props.selectedDeliveryOrderRedemptionDetail?.serviceById,
    serviceByName: props.selectedDeliveryOrderRedemptionDetail?.serviceByName || "",
    remark: props.selectedDeliveryOrderRedemptionDetail?.description || "",
    serviceName: props.selectedDeliveryOrderRedemptionDetail?.redeemDetailName
  }

  const [deliveryOrderRedemptionDetail, setDeliveryOrderRedemptionDetail] = useState(defaultDeliveryOrderRedemptionDetail)
  const disabledFieldInput = localLoadingDeliveryOrderRedemption || Boolean(localSucessMessage);

  useEffect(()=> {
    async function getEmployeeList() {
      setLocalLoading (true);
      let resultEmployeeList = await getEmployeeDropdown({branchId: props.branchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}, true)
      setOptionsEmployeeList(AddOptionsValueField(resultEmployeeList, "preferredName", "id"));
      setLocalLoading(false);
    }
    
    if (props.branchId) {
      getEmployeeList();
    }
  }, [props.selectedDeliveryOrderRedemptionDetail])

  const hideModal = () => {
    props.setSelectedDeliveryOrderRedemptionDetail(undefined);
    props.setModal(false);
  }

  const onChangeEmployee = (e: any) => {
    setDeliveryOrderRedemptionDetail((valueDeliveryOrderRedemptionDetail: any)=> ({
      ...valueDeliveryOrderRedemptionDetail,
      serviceById: e.value,
      serviceByName: e.label
    }));
  }

  const onClickEditDeliveryOrderRedemption = async () => {
    setLocalLoadingDeliveryOrderRedemption(true);
    let deliveryOrderRedemptionDetailTemp = _.cloneDeep(deliveryOrderRedemptionDetail);
    let resultAPI = await props.editFunction(deliveryOrderRedemptionDetailTemp);
    if (resultAPI.status) {
      if (resultAPI.status === Constants.success) {
        setLocalSuccessMessage(" ");
      }
    }
    setLocalLoadingDeliveryOrderRedemption(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Edit" })} {intl.formatMessage({ id: "CustomerRedemption" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        {
          localLoading
          ?
          <div className="modal-body">
            <Loading/>
          </div>
          :
          <div className="modal-body">
            <GeneralInputForModal
              title={intl.formatMessage({ id: "Service" })}
              name="serviceName"
              className={"mb-3"}
              type="text"
              field={deliveryOrderRedemptionDetail}
              setFieldValue={setDeliveryOrderRedemptionDetail}
              disabled={true}
            />
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "ServiceBy"})}
              name=""
              className="mb-3"
              options={optionsEmployeeList}
              onChange={onChangeEmployee}
              initialLabel={props.selectedDeliveryOrderRedemptionDetail?.serviceByName || ""}
              initialValue={props.selectedDeliveryOrderRedemptionDetail?.serviceById || ""}
              blnReturnFullValue={true}
              validationRequired={true}
              disabled={disabledFieldInput}
            />
            <GeneralTextAreaForModal
              title={intl.formatMessage({ id: "Remark" })}
              name="remark"
              className={"mb-3"}
              validationRequired={true}
              field={deliveryOrderRedemptionDetail}
              setFieldValue={setDeliveryOrderRedemptionDetail}
              row={3}
              disabled={disabledFieldInput}/>
            <div className="float-end">
              {
                !Boolean(localSucessMessage)
                &&
                <MyButton
                  type="button"
                  class="btn btn-primary"
                  content={intl.formatMessage({ id: "Submit" })}
                  onClick={() => {
                    onClickEditDeliveryOrderRedemption()
                  }}
                  loading={localLoadingDeliveryOrderRedemption}
                  disable={disabledFieldInput}
                />
              }
            </div>
          </div>
        }
      </Modal>
  )
}