import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchUser } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";

const LeadsSummaryByMedia = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, mediaSourceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { leadsSummaryByMediaList, getLeadsSummaryByMedia, setLeadsSummaryByMediaList, exportLeadsSummaryByMedia } = reportMarcomStore; 

  //Data List
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);

  const aryLeadsSummaryByMediaDetail : ObjectForReportRow[] = [
    {label: "Employee", value: "employeeName", type: "string"}, 
    {label: "TotalSources", value: "totalSources", type: "string"}, 
    {label: "TotalFollowUpMade", value: "totalFollowUpMade", type: "string"}, 
    {label: "TotalAppointmentMade", value: "totalAppointmentMade", type: "string"}, 
    {label: "TotalTurnUp", value: "totalTurnUp", type: "string"}, 
    {label: "AppointmentMadePercentage", value: "totalAppointmentMadePercentage", type: "string"}, 
    {label: "TurnUpPercentage", value: "totalTurnUpPercentage", type: "string"}, 
    {label: "CancellationPercentage", value: "totalCancellationPercentage", type: "string"}, 
    {label: "TotalClosed", value: "totalClosed", type: "string"}, 
    {label: "Action", value: "routeUrl", type: "action"}
  ];
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchLeadsSummaryByMedia() {
			setLoading(true);
      let aryAPI: any = [
        getMediaSourceDropdown(),
      ];
      
      let resultAPI = await Promise.all(aryAPI);

      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[0], AllSource, false, "name", "id"));
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }
    
    fetchLeadsSummaryByMedia();

    return (()=> {
      setLeadsSummaryByMediaList([]);
    })
  }, [])

  const fetchLeadsSummaryByMediaWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }
    
    setLocalLoading(true);
    if(blnExport){
      await exportLeadsSummaryByMedia({sourceId: selectedMediaSourceId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate});
    }
    else{
      await getLeadsSummaryByMedia({sourceId: selectedMediaSourceId, dateRange: selectedDateRange});
    }
    
    setLocalLoading(false);
	};

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const LeadsSummaryByMediaColumns = [
    { 
      dataField: "sourceId", //field name from array to display
      text: "sourceId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "sourceName",
      text: intl.formatMessage({ id: "MediaSourceName" }).toUpperCase(),
    },
    {
      dataField: "totalSources",
      text: intl.formatMessage({ id: "TotalSources" }).toUpperCase(),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "LeadsSummaryDetails" })}
        rowFieldName={"leadsSummaryDetails"}
        rowColumns={aryLeadsSummaryByMediaDetail}
        keyFieldName={"randomId"}
        actionTitle={intl.formatMessage({ id: "ViewLeadsDetails" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.leadsDetail}`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "LeadsSummaryByMediaSource"})})}
        title={intl.formatMessage({ id: "LeadsSummaryByMediaSource" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchLeadsSummaryByMediaWithLoading(true)
            }}
          />
        }>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "MediaSource" })}
              name="mediaSource"
              styles={{}}
              options={mediaSourceListFinal}
              labelField="name"
              valueField="id"
              initialLabel={selectedMediaSourceName}
              initialValue={selectedMediaSourceId}
              setFieldLabel={setSelectedMediaSourceName}
              setFieldValue={setSelectedMediaSourceId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={()=> {
                fetchLeadsSummaryByMediaWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={LeadsSummaryByMediaColumns}
            data={leadsSummaryByMediaList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "LeadsSummaryByMediaSource"})})}
            objSorted={defaultSorted}
            keyField={"sourceId"}
            sourceId={selectedMediaSourceId}
            dateRange={selectedDateRange}
            requestAPI={getLeadsSummaryByMedia}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadsSummaryByMedia);
