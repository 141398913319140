import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { IncludesLocationPathName, contructValidationErrorMessage } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { history } from "../..";
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";

const ChangePassword = () => {
	//Use Paramsf
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { profileStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { changePassword } = profileStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "Password" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "ChangePassword" }), urlPath: RoutesList.changePassword }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
		},
		validationSchema: Yup.object({
			currentPassword: Yup.string().required(intl.formatMessage({ id: "CurrentPasswordIsRequired" })),
			newPassword: Yup.string()
				.required(intl.formatMessage({ id: "NewPasswordIsRequired" }))
				.min(6, intl.formatMessage({ id: "NewPasswordMustLonger" }))
				.matches(/[a-z]/, intl.formatMessage({ id: "NewPasswordMustContainLowercaseCharacters" }))
				.matches(/[A-Z]/, intl.formatMessage({ id: "NewPasswordMustContainUppercaseCharacters" }))
				.matches(/^(?=.{6,20}$)\D*\d/, intl.formatMessage({ id: "NewPasswordMustContainNumber" })),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: "ConfirmPasswordMustMatchNewPassword" }))
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			// setShowChangePasswordPrompt(true);
			try {
				await changePassword(valuesTemp);
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl={12}>
								<LineBreakWithTittle
									paddingBottom="0px"
									title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ChangePassword" }) })}
									h4Title />
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "CurrentPassword" })}
										name="currentPassword"
										className="mb-3 mt-4"
										type="password"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "NewPassword" })}
										name="newPassword"
										type="password"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "ConfirmPassword" })}
										name="confirmPassword"
										type="password"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
							</Col>
						</Row>
						<GeneralSubmitAndCancelBtn
							successMessage={successMessage}
							viewAction={viewAction}
							validation={validation}
							cancelButtonFunction={() => {
								history.push(`/${RoutesList.dashboard}`)
							}}
						/>
					</Form>
				)}
			</DetailViewLayout>
		</div>
	);
};

export default observer(ChangePassword);
