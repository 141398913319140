import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { appendCountryCode, checkPermission, contructValidationErrorMessage, getBrandId, IncludesLocationPathName, returnSuccessMarkedIcon, returnSubBrandList } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
// import { StateObject } from "../../app/models/lead";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { EmployeeListObject } from "../../app/models/employee";
import { MediaSourceListObject } from "../../app/models/mediaSource";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { history } from "../../";

const LeadDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { leadStore, countryStore, branchStore, employeeStore, mediaSourceStore, serviceTreatmentStore, raceStore, staticReferenceStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addLead, updateLead, leadDetail, setLeadDetail, getLeadWithId } = leadStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown } = countryStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { raceDropdownList, getRaceDropdown } = raceStore;
  const { genderType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Lead" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Lead" }), urlPath: RoutesList.lead }];
  const [loadingState, setLoadingState] = useState(false);
  const [loadingConsultant, setLoadingConsultant] = useState(false);
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const [filteredMediaSourceList, setFilteredMediaSourceList] = useState<MediaSourceListObject[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [blnRejoinOrCrossBrandModal, setBlnRejoinOrCrossBrandModal] = useState(false);
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: leadDetail || {
      name: "",
      mobileNo: "",
      email: "",
      sourceId: undefined,
      mediaSource: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      branchId: "",
      branchName: "",
      consultantId: "",
      consultantName: "",
      serviceTreatmentId: undefined,
      serviceTreatmentName: "",
      promoRemark: "",
      voucherReferenceNo: "",
      remark: "",
      lastFollowUpDate: "",
      lastAppointmentDate: "",
      turnUpDate: "",
      marcomConsultantId: "",
      marcomConsultantName: "",
      raceId: "",
      raceName: "",
      subBrandId: "",
      subBrandName: "",
      gender: "",
      type: ""
    },
    validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any) => {
        if (returnSubBrandList().length > 0 ? true : false) {
          return value;
        }
        else {
          return true;
        }
			}).nullable(),
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LeadName" }) })),
      sourceId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MediaSource" }) })),
      mobileNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MobileNo" }) })),
      countryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) })),
      stateId: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any) => {
          if (stateList.length > 0 && !value) {
            return false;
          }
          return true;
        }).nullable(),
      stateName: Yup.string()
        .test("isValidPass", intl.formatMessage({ id: stateList.length > 0 ? "ValidationSelect" : "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) }), (value: any) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        }).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.branchId) {
        delete valuesTemp["branchId"]
        delete valuesTemp["branchName"]
      }
      if (!valuesTemp.consultantId) {
        delete valuesTemp["consultantId"]
        delete valuesTemp["consultantName"]
      }
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }
      if (valuesTemp.countryName === Constants.malaysia) {
        valuesTemp.mobileNo = appendCountryCode(valuesTemp.mobileNo, "6")
      }
    
      try {
        let resultAPI = addAction ? await addLead(valuesTemp) : await updateLead(valuesTemp);
        if (resultAPI) {
          if (resultAPI.isCrossBrand || resultAPI.isRejoin) {
            setBlnRejoinOrCrossBrandModal(true);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchLeadDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCountryDropdown(),
        getMediaSourceDropdown(),
        getServiceTreatmentDropdown(),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.customerServiceId},${Constants.marComCustomerServiceId}`], isActive: true }, true),
        getRaceDropdown(),
        getStaticReferenceWithType(Constants.genderType)
      ];
      if (id && !addAction) {
        aryAPI.push(getLeadWithId(id));
      }
      let resultAPI = await Promise.all(aryAPI);
      if (!addAction && !viewAction) {
        if (resultAPI[7].type === Constants.lead) {
          if (!checkPermission([PermissionConstants.UpdateLead], true)) {
            return;
          }
        } else if (resultAPI[7].type === Constants.walkIn) {
            history.push(`/${RoutesList.notFound}`)
            return;
        }
      }
      if(resultAPI[2]){
        setFilteredMediaSourceList(resultAPI[2].filter(mediaSource => mediaSource.name !== Constants.referral))
      }
      if (resultAPI[4] && resultAPI[4].length > 0) {
        setMarcomConsultantList(resultAPI[4])
      }
      setLoading(false);
    }

    if (addAction) {
      setLeadDetail(undefined);
    }

    if (addAction) {
      if (!checkPermission([PermissionConstants.CreateLead], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageLead], true)) {
        return;
      }
    }

    fetchLeadDetailAPI();

    return (() => {
      if (id && !addAction) {
        setLeadDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (leadDetail) {
        if (leadDetail.countryId) {
          getStateWithLoading(leadDetail.countryId, false)
        }
        if (leadDetail.branchId) {
          getConsultantWithLoading(leadDetail.branchId, false)
        }
      }
    }
  }, [leadDetail]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const getStateWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        stateId: "",
        stateName: ""
      }))
    }
    setLoadingState(false);
  };

  const getConsultantWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingConsultant(true);
    await getEmployeeDropdown({
      brandId: getBrandId(),
      branchId: id,
      isBranchStaff: true,
      userRoleIds: [`${Constants.consultantUserRoleId},${Constants.branchManagerUserRoleId}`],
      isActive: true
    });
    if (removeData) {
      validation.setValues((values) => ({
        ...values,
        consultantId: "",
        consultantName: ""
      }))
    }
    setLoadingConsultant(false);
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}
                  h4Title />
                <Row className="margin-top-20">
                  <Col xs={"12"} md={"12"} xl={"12"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Name" })}
                      name="name"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"sourceId"}
                      title={intl.formatMessage({ id: "MediaSource" })}
                      specifyReturnFieldName={[{ "field": "mediaSource", "value": "name" }, { "field": "sourceId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={filteredMediaSourceList}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialLabel={validation.values.mediaSource}
                      initialValue={validation.values.sourceId}
                      validation={validation} />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"marcomConsultantId"}
                      title={intl.formatMessage({ id: "AppointedBy" })}
                      specifyReturnFieldName={[{ "field": "marcomConsultantName", "value": "preferredName" }, { "field": "marcomConsultantId", "value": "id" }]}
                      labelField={"preferredName"}
                      valueField={"id"}
                      options={marcomConsultantList}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialLabel={validation.values.marcomConsultantName}
                      initialValue={validation.values.marcomConsultantId}
                      validation={validation} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"raceId"}
                      title={intl.formatMessage({ id: "Race" })}
                      specifyReturnFieldName={[
                        {
                          field: "raceId",
                          value: "id",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={raceDropdownList}
                      disabled={disabledFieldInput ||viewAction}
                      initialLabel={validation.values.raceName}
                      initialValue={validation.values.raceId}
                      validationRequired={false}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"gender"}
                      title={intl.formatMessage({ id: "Gender" })}
                      specifyReturnFieldName={[
                        {
                          field: "gender",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      blnValueWithNewSpace={true}
                      options={genderType}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={validation.values.gender}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "MobileNo" })}
                      name="mobileNo"
                      type="number"
                      blnNumberOnly={true}
                      disabled={disabledFieldInput}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Email" })}
                      name="email"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={false}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <GeneralTextArea
                  title={intl.formatMessage({ id: "Remarks" })}
                  name="remark"
                  row={4}
                  disabled={disabledFieldInput || viewAction}
                  validation={validation} />
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Other" }) })}
                  className="mt-4"
                  h4Title />
                {
                  returnSubBrandList().length > 0
                  &&
                  <DropDownWithTitle
                    name={"subBrandId"}
                    title={intl.formatMessage({ id: "SubBrand"})}
                    specifyReturnFieldName={[{"field": "subBrandName", "value": "name"},{"field": "subBrandId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={returnSubBrandList()}
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    initialLabel={validation.values.subBrandName}
                    initialValue={validation.values.subBrandId}
                    validation={validation}/>
                }
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"branchId"}
                      title={intl.formatMessage({ id: "PreferredBranch" })}
                      specifyReturnFieldName={[{ "field": "branchName", "value": "name" }, { "field": "branchId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={disabledFieldInput || viewAction || loadingConsultant}
                      initialLabel={validation.values.branchName}
                      initialValue={validation.values.branchId}
                      validation={validation}
                      onChangeFunction={
                        getConsultantWithLoading
                      } />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {
                      loadingConsultant ? (
                        <Loading />
                      ) : (
                        <DropDownWithTitle
                          name={"consultantId"}
                          title={intl.formatMessage({ id: "PreferredConsultant" })}
                          specifyReturnFieldName={[{ "field": "consultantName", "value": "preferredName" }, { "field": "consultantId", "value": "id" }]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={employeeDropdownList}
                          disabled={disabledFieldInput || viewAction || !validation.values.branchId}
                          initialLabel={validation.values.consultantName}
                          initialValue={validation.values.consultantId}
                          validation={validation} />
                      )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"serviceTreatmentId"}
                      title={intl.formatMessage({ id: "ServiceTreatment" })}
                      specifyReturnFieldName={[{ "field": "serviceTreatmentName", "value": "name" }, { "field": "serviceTreatmentId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={serviceTreatmentDropdownList}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={validation.values.serviceTreatmentName}
                      initialValue={validation.values.serviceTreatmentId}
                      validation={validation} />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "VoucherReferenceNo" })}
                      name="voucherReferenceNo"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <GeneralTextArea
                  title={intl.formatMessage({ id: "PromotionRemark" })}
                  name="promoRemark"
                  row={4}
                  disabled={disabledFieldInput || viewAction}
                  validation={validation} />
              </Col>
              <Col xl={6}>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Address" }) })}
                  h4Title />
                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine1" })}
                  name="addressLine1"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "AddressLine2" })}
                  name="addressLine2"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}
                />
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "City" })}
                      name="city"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PostCode" })}
                      name="postcode"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"countryId"}
                      title={intl.formatMessage({ id: "Country" })}
                      specifyReturnFieldName={[
                        {
                          field: "countryId",
                          value: "id",
                        },
                        {
                          field: "countryName",
                          value: "name",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={countryDropdownList}
                      disabled={disabledFieldInput || viewAction || loadingState}
                      onChangeFunction={getStateWithLoading}
                      initialLabel={!addAction ? validation.values.countryName : undefined}
                      initialValue={!addAction ? validation.values.countryId : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    {loadingState ? (
                      <Loading />
                    ) : (
                      stateList.length > 0
                        ?
                        <DropDownWithTitle
                          name={"stateId"}
                          title={intl.formatMessage({ id: "State" })}
                          specifyReturnFieldName={[
                            {
                              field: "stateId",
                              value: "id",
                            },
                            {
                              field: "stateName",
                              value: "name",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={stateList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={!addAction ? validation.values.stateName : undefined}
                          initialValue={!addAction ? validation.values.stateId : undefined}
                          validationRequired={stateList.length > 0 ? true : false}
                          validation={validation}
                        />
                        :
                        <GeneralInput
                          title={intl.formatMessage({ id: "StateName" })}
                          name="stateName"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        blnRejoinOrCrossBrandModal
        &&
        <ActionPromptModal 
          title={addAction ? intl.formatMessage({ id: "CreateLeadWithRejoinOrCrossBrand" }) : intl.formatMessage({ id: "UpdateLeadWithRejoinOrCrossBrand" })}
          showPrompt={true}
          setShowPrompt={()=> {}}
          primaryActionTitle={intl.formatMessage({ id: "Ok" }).toUpperCase()}
          onPrimaryClick={()=> {
            history.push({
              pathname: `/${RoutesList.lead}`
            });
          }}
          blnHideCancelButton={true}
          replaceIcon={returnSuccessMarkedIcon}/>
      }
    </div>
  );
};

export default observer(LeadDetail);
