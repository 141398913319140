import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { useIntl } from "react-intl";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import MyButton from "../../app/components/form/MyButton";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";


const CacheSettings = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { settingsStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setLoading, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { clearCache } = settingsStore;
  const displayTitle = `${intl.formatMessage({ id: "Cache" })}`;
  const [clearCacheModal, setClearCacheModal] = useState(false);

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCache], true)) {
      return;
    }
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setClearCacheModal(false);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const onClickClearCache = async () => {
    setLoading(true);
    await clearCache();
    setLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={[]}
        auditTrailId={undefined}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row>
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "Cache" })}
                  h4Title />
                <p className="font-size-15 error_require_text mt-4 mb-3">
                  {intl.formatMessage({ id: "ClearCacheMessage" })}
                </p>
                  <div className="mt-3 mb-3 flex-direction-row">
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      content={intl.formatMessage({ id: "ClearCache" })}
                      onClick={() => { setClearCacheModal(true); }}
                      disable={Boolean(successMessage) || loading}
                      loading={loading}
                    />
                  </div>
              </Col>
            </Row>
          </Form>
        )}
        {
          clearCacheModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "ClearCacheAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={clearCacheModal}
            setShowPrompt={setClearCacheModal}
            onPrimaryClick={() => { onClickClearCache()}}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(CacheSettings);
