import { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Label,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { PaymentMethodForBranchObject } from "../../../app/models/branch";
import { AddOptionsValueField, blockNegativeOrZeroPayment, newSpaceBeforeCapitalLetter, returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import { CustomerPaymentDetailObject } from "../../../app/models/customerOrder";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";
import Loading from "../../../app/components/loading/Loading";
import { IssuerBanksListObject } from "../../../app/models/paymentMethodOption";

interface Props {
  blnShow: boolean;
  setModal: Function;
  cardType: StaticReferenceDataObject[];
  branchPaymentMethod: PaymentMethodForBranchObject[];
  additionalPayment: CustomerPaymentDetailObject[];
  setAdditionalPayment: Function;
  selectedInvoicePaymentIndex: number | undefined;
  setSelectedInvoicePaymentIndex: Function;
  invoiceBalance: number;
  getPaymentMethodOptionDropdown: Function;
  blnEditPayment: boolean;
  setBlnEditPayment: Function;
  editPayment: CustomerPaymentDetailObject[];
  setEditPayment: Function;
  blnCreditPackage: boolean;
}

export default function CustomerSalesOrderPaymentModal(props: Props) {
  const intl = useIntl();
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [loadingIssuerBank, setLoadingIssuerBank] = useState(false);
  const [localIssuerBankList, setLocalIssuerBankList] = useState<IssuerBanksListObject[]>([]);

  const returnLeftOverBalance = () => {
    let invoiceBalanceTemp = props.invoiceBalance;
    if (props.additionalPayment) {
      props.additionalPayment.map((valueAdditionalPaymentTemp)=> {
        invoiceBalanceTemp -= valueAdditionalPaymentTemp.paidAmount;
      })
    }
    if (props.editPayment) {
      props.editPayment.map((valueEditPaymentTemp)=> {
        invoiceBalanceTemp -= valueEditPaymentTemp.paidAmount;
      })
    }
    
    if(Math.sign(invoiceBalanceTemp) == -1){
      return 0;
    }else{
      return invoiceBalanceTemp;
    }
  }

  let selectedIndex = props.selectedInvoicePaymentIndex ? props.selectedInvoicePaymentIndex-1 : -1
  const [state, setState] = useState(
      selectedIndex > -1 
      ?
      props.additionalPayment[selectedIndex]
      :
      {
        paymentMethodId: "",
        paymentMethodName: "",
        paidAmount: props.blnEditPayment ? 0 : returnLeftOverBalance(),
        last4CardDigit: 0,
        cardType: "",
        transactionNo: "",
        paymentMethodOptionId: undefined,
        paymentMethodOptionName: "",
        optionTypeTitle: "",
        optionTypeList: [],
        isCardInput: false,
        isQRPay: false,
        isCustomerCredit: false,
        isTransferCredit: false,
        paymentIssuerBankId: null,
        paymentIssuerBankName: "",
      }
  );

  const errorStateDefault = {
    last4CardDigit: false,
    cardType: false,
    paidAmount: false,
    paymentMethodName: false,
    paymentMethodOptionId: false,
    transactionNo: false,
    paymentIssuerBankId: false
  }

  const [errorState, setErrorState] = useState(_.cloneDeep(errorStateDefault))

  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState(AddOptionsValueField(props.branchPaymentMethod, "name", "id"));
  const optionsCardType = AddOptionsValueField(props.cardType, "displayValue", "displayValue");

  const cardValid = state.isCardInput
  const qrValid = state.isQRPay
  const customerCreditValid = state.isCustomerCredit
  const transferCreditValid = state.isTransferCredit
  const onlineTransferOrChequeValid = !cardValid && !qrValid && !customerCreditValid && !transferCreditValid && state.optionTypeList.length > 0;

  useEffect(()=> {
    if (props.blnCreditPackage) {
      let branchPaymentMethodTemp = _.cloneDeep(props.branchPaymentMethod);
      branchPaymentMethodTemp = _.filter(branchPaymentMethodTemp, {isCustomerCredit: false});
      branchPaymentMethodTemp = _.filter(branchPaymentMethodTemp, {isTransferCredit: false});
      setOptionsPaymentMethod(AddOptionsValueField(branchPaymentMethodTemp, "name", "id"));
    }
  }, [])

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedInvoicePaymentIndex(undefined);
  }

  const onChangePaymentMethod = async (e: any) => {
    setLoadingPaymentMethod(true);
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodId = e.id;
    stateTemp.paymentMethodName = e.name;
    stateTemp.cardType = "";
    stateTemp.last4CardDigit = 0;
    stateTemp.paymentMethodOptionName = "";
    stateTemp.paymentMethodOptionId = undefined;
    stateTemp.optionTypeTitle = "";
    stateTemp.optionTypeList = [];
    stateTemp.isCardInput = e.isCardInput;
    stateTemp.isQRPay = e.isQRPay;
    stateTemp.isCustomerCredit = e.isCustomerCredit;
    stateTemp.isTransferCredit = e.isTransferCredit;
    stateTemp.paymentIssuerBankId = null;
    stateTemp.paymentIssuerBankName = "";

    setLocalIssuerBankList([]);
    
    if (e.isOptionAvailable && e.optionType !== Constants.none) {
      let resultOptionType = await props.getPaymentMethodOptionDropdown(e.id);
      stateTemp.optionTypeTitle = newSpaceBeforeCapitalLetter(e.optionType)
      stateTemp.optionTypeList = AddOptionsValueField(resultOptionType, "name", "id")
    }
    setState(stateTemp);
    setLoadingPaymentMethod(false);
  }

  const onChangeCardType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.cardType = e.displayValue;
    setState(stateTemp);
  }

  const onChangeOptionType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodOptionName = e.label;
    stateTemp.paymentMethodOptionId = e.value;
    stateTemp.paymentIssuerBankId = null;
    stateTemp.paymentIssuerBankName = "";

    setState(stateTemp);
    setLoadingIssuerBank(true);
    setLocalIssuerBankList(AddOptionsValueField(e.issuerBanks, "name", "id") || []);
    setTimeout(()=> {
      setLoadingIssuerBank(false);
    }, 100)
  }

  const onChangeIssuerBank = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentIssuerBankId = e.value;
    stateTemp.paymentIssuerBankName = e.label;

    setState(stateTemp);
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Payment" })}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          loadingPaymentMethod
          ?
          <Loading className="margin-bottom-16" />
          :
          <>
            { 
              <div>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "PaymentMethod" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodName ? "mb-0" : "mb-3"}
                  options={optionsPaymentMethod}
                  onChange={onChangePaymentMethod}
                  blnReturnFullValue={true}
                  onBlur={()=> {
                    if (errorState.paymentMethodName) {
                      setErrorState((value) => ({...value, paymentMethodName: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodName}
                  initialValue={state.paymentMethodId}
                  validationRequired={true}
                />
                {errorState.paymentMethodName ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "PaymentMethod"})})}`}</FormFeedback>
                ) : null}
              </div>
            }
            {
              cardValid
              &&
              <Row>
                <Col>
                  <Label>
                    <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                    <Label className="margin-bottom-0">{intl.formatMessage({ id: "Credit/DebitCardType" })}</Label>
                  </Label>
                  <GeneralSelectDropdown
                    title=""
                    name=""
                    className={errorState.cardType ? "mb-0" : "mb-3"}
                    options={optionsCardType}
                    blnValueWithNewSpace={true}
                    blnReturnFullValue={true}
                    onChange={onChangeCardType}
                    onBlur={()=> {
                      if (errorState.cardType) {
                        setErrorState((value) => ({...value, cardType: false}))
                      }
                    }}
                    initialLabel={state.cardType}
                    initialValue={state.cardType}
                    validationRequired={true}
                  />
                  {errorState.cardType ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "CardType"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <GeneralInputForModal
                    title="Last Four Digit"
                    name="last4CardDigit"
                    className={errorState.last4CardDigit ? "mb-0" : "mb-3"}
                    type="number"
                    maxLength={4}
                    typeExcludeNumberType={true}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.last4CardDigit) {
                        setErrorState((value) => ({...value, last4CardDigit: false}))
                      }
                    }}
                  />
                  {errorState.last4CardDigit ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Last4Digit"})})}`}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            }
            {
              state.optionTypeList.length > 0
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{state.optionTypeTitle}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodOptionId ? "mb-0" : "mb-3"}
                  options={state.optionTypeList}
                  blnReturnFullValue={true}
                  onChange={onChangeOptionType}
                  onBlur={()=> {
                    if (errorState.paymentMethodOptionId) {
                      setErrorState((value) => ({...value, paymentMethodOptionId: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodOptionName}
                  initialValue={state.paymentMethodOptionId}
                  validationRequired={true}
                />
                {errorState.paymentMethodOptionId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: state.optionTypeTitle})}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              loadingIssuerBank
              ?
              <Loading/>
              :
              localIssuerBankList.length > 0
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "IssuerBanks" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentIssuerBankId ? "mb-0" : "mb-3"}
                  options={localIssuerBankList}
                  blnReturnFullValue={true}
                  onChange={onChangeIssuerBank}
                  onBlur={()=> {
                    if (errorState.paymentIssuerBankId) {
                      setErrorState((value) => ({...value, paymentIssuerBankId: false}))
                    }
                  }}
                  validationRequired={true}
                />
                {errorState.paymentIssuerBankId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IssuerBanks" }) })}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              <Row>
                <Col xl={(cardValid || qrValid) ? 6 : 12}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "PaidAmount" })}
                    name="paidAmount"
                    className={errorState.paidAmount ? "mb-0" : "mb-3"}
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.paidAmount) {
                        setErrorState((value) => ({...value, paidAmount: false}))
                      }
                    }}
                    blnDisableNegativeValue={true}
                  />
                  {errorState.paidAmount ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "PaidAmount"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                {
                  (cardValid || qrValid || onlineTransferOrChequeValid)
                  &&
                  <Col>
                    <GeneralInputForModal
                      title={intl.formatMessage({ id: qrValid || onlineTransferOrChequeValid ? "TransactionNo" : "ApprovalCode"})}
                      name="transactionNo"
                      className={errorState.transactionNo ? "mb-0" : "mb-3"}
                      type={"text"}
                      typeExcludeNumberType={true}
                      field={state}
                      setFieldValue={setState}
                      validationRequired={!qrValid && !onlineTransferOrChequeValid}
                      {...!qrValid && !onlineTransferOrChequeValid && {maxLength: 6}}
                    />
                    {errorState.transactionNo ? (
                      <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "ApprovalCode"})})}`}</FormFeedback>
                    ) : null}
                  </Col>
                }
              </Row>
            }
          </>
        }
        <div className="">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({ id: "Save" })}
            disable={loadingPaymentMethod}
            onClick={()=> {
              let errorStateTemp = _.cloneDeep(errorStateDefault);

              let blnErrorFound = false;
              if (!state.paymentMethodName) {
                errorStateTemp.paymentMethodName = true;
                blnErrorFound = true;
              }

              if (cardValid) {
                if (String(state.last4CardDigit).length !== 4) {
                  errorStateTemp.last4CardDigit = true;
                  blnErrorFound = true;
                }
                if (!state.cardType) {
                  errorStateTemp.cardType = true;
                  blnErrorFound = true;
                }
                if (String(state.transactionNo).length !== 6) {
                  errorStateTemp.transactionNo = true;
                  blnErrorFound = true;
                }
              }

              if (blockNegativeOrZeroPayment(state.paidAmount, intl)) {
                errorStateTemp.paidAmount = true;
                blnErrorFound = true;
              }
              
              if ((state.isCustomerCredit || state.isTransferCredit) && state.paidAmount < 1) {
                errorStateTemp.paidAmount = true;
                blnErrorFound = true;
              }

              if (state.optionTypeList.length > 0 && !state.paymentMethodOptionId) {
                errorStateTemp.paymentMethodOptionId = true;
                blnErrorFound = true;
              }

              if (localIssuerBankList.length > 0 && !state.paymentIssuerBankId) {
                errorStateTemp.paymentIssuerBankId = true;
                blnErrorFound = true;
              }

              state.paidAmount = Number(state.paidAmount);

              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                if (props.blnEditPayment) {
                  let editPaymentTemp = _.cloneDeep(props.editPayment);
                  if (selectedIndex > -1) {
                    editPaymentTemp[selectedIndex] = state;
                  }
                  else {
                    editPaymentTemp.push(state);
                  }
                  props.setEditPayment(editPaymentTemp)
                  hideModal();
                }
                else {
                  let additionalPaymentTemp = _.cloneDeep(props.additionalPayment);
                  if (selectedIndex > -1) {
                    additionalPaymentTemp[selectedIndex] = state;
                  }
                  else {
                    additionalPaymentTemp.push(state);
                  }
                  props.setAdditionalPayment(additionalPaymentTemp)
                  hideModal();
                }
              }
            }}/>
          </div>
        </div>
      </Modal>
  )
}