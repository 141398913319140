
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TestTimeZoneAddObject } from "../models/testTimeZone";

export default class TestTimeZoneStore {
  constructor() {
    makeAutoObservable(this)
  }

  addTime = async (testTimeZoneRequestBody: TestTimeZoneAddObject) => {
    try{
      await agent.TestTimeZone.addTime(testTimeZoneRequestBody);
      store.commonStore.setSuccessMessage("Time has added successfully.")
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}