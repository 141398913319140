import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue, returnSubBrandList, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { SubBrandObject } from "../../../app/models/brand";

const SalesOverviewYearly = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize } = commonStore;
  const { salesOverviewYearlyList, getSalesOverviewYearlyList, setSalesOverviewYearlyList, exportSalesOverviewYearly } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());

    async function fetchSalesOverviewYearly() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSalesOverviewYearly();

    return (() => {
      setSalesOverviewYearlyList([]);
    })
  }, [])

  const fetchSalesOverviewYearlyWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportSalesOverviewYearly({ branchId: selectedBranchId, subBrandId: selectedSubBrandId, year: selectedYear });
    } else {
      await getSalesOverviewYearlyList({ branchId: selectedBranchId, subBrandId: selectedSubBrandId, year: selectedYear });
    }
    setLocalLoading(false);
  };

  const returnMonthField = (month) => {
    return ({
      dataField: month,
      text: intl.formatMessage({ id: month }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesOverviewYearlyFontStyle(index)}}>
          {returnPriceWithCurrency(row.monthlySalesFigures[month])}
        </span>
      )
    })
  }

  //Table Content UI
  const SalesOverviewYearlyColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span 
          style={{
            fontWeight: returnSalesOverviewYearlyFontStyle(index),
          }}>
          {row.branchName}
        </span>
      )
    },
    returnMonthField("Jan"),
    returnMonthField("Feb"),
    returnMonthField("Mar"),
    returnMonthField("Apr"),
    returnMonthField("May"),
    returnMonthField("Jun"),
    returnMonthField("Jul"),
    returnMonthField("Aug"),
    returnMonthField("Sep"),
    returnMonthField("Oct"),
    returnMonthField("Nov"),
    returnMonthField("Dec"),
    {
      dataField: "Total",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      style:{backgroundColor: "#b59cf050"},
      formatter: (cellContent, row) => (
        <span 
          style={{
            fontWeight: Constants.boldFontWeight,
          }}>
          {returnPriceWithCurrency(row.totalAmount)}
        </span>
      )
    }
  ];

  const returnSalesOverviewYearlyFontStyle = (index) => {
    return index == salesOverviewYearlyList.length -1 && salesOverviewYearlyList.length > 1 ? Constants.boldFontWeight : Constants.normalFontWeight;
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesOverviewYearly" }) })}
        title={intl.formatMessage({ id: "SalesOverviewYearly" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchSalesOverviewYearlyWithLoading(true)
            }}
          />
        }>
        <Row>
          {
              subBrandListFinal.length > 0
              &&
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
         </Col>
          <Col xl={"3"}>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchSalesOverviewYearlyWithLoading(false)
              }}
            />
          </Col>
        </Row>
        <Row>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={SalesOverviewYearlyColumns}
              data={salesOverviewYearlyList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesOverviewYearly" }) })}
              objSorted={defaultSorted}
              keyField={"branchName"}
              requestAPI={getSalesOverviewYearlyList}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              subBrandId={selectedSubBrandId}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesOverviewYearly);
