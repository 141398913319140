import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { AddOptionsValueField, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnBlobImage, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaymentMethodListObject } from "../../app/models/paymentMethod";
import { IssuerBanksListObject, PaymentMethodOptionListObject } from "../../app/models/paymentMethodOption";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import { UnknownPaymentDetailObject } from "../../app/models/unknownPayment";
import moment from "moment";

const UnknownPaymentDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { unknownPaymentStore, branchStore, customerStore, paymentMethodStore, paymentMethodOptionStore, staticReferenceStore, commonStore } = useStore();
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore; 
  const { paymentMethodDropdownList, getPaymentMethodDropdown } = paymentMethodStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore; 
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addUnknownPayment, updateUnknownPayment, unknownPaymentDetail, setUnknownPaymentDetail, getUnknownPaymentWithId } = unknownPaymentStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "UnknownPayment" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "UnknownPayment" }), urlPath: RoutesList.unknownPayment }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [loadingIssuerBank, setLoadingIssuerBank] = useState(false);
  const [refreshBranchDropdown, setRefreshBranchDropdown] = useState(true);
  const [localPaymentMethodList, setLocalPaymentMethodList] = useState<PaymentMethodListObject[]>([]);
  const [localOptionTypeTitle, setLocalOptionTypeTitle] = useState<string>("");
  const [localOptionTypeList, setLocalOptionTypeList] = useState<PaymentMethodOptionListObject[]>([]);
  const [localIssuerBankList, setLocalIssuerBankList] = useState<IssuerBanksListObject[]>([]);
  const [cardValid, setCardValid] = useState(false);
  const [qrValid, setQRValid] = useState(false);
  const onlineTransferOrChequeValid = !cardValid && !qrValid && localOptionTypeList.length > 0;
  const blnPermissionUpdateUnknownCustomerPayment =  checkPermission([PermissionConstants.UpdateUnknownCustomerPayment]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: unknownPaymentDetail || {
      id: "",
      paymentDate: "",
      paymentTime: "00:00",
      customerBranchId: getBranchUser() ? getBranchId() : "",
      customerBranchName: getBranchUser() ? getBranchName() : "",
      customerId: "",
      customerName: "",
      paymentMethodId: "",
      paymentMethodName: "",
      paymentMethodOptionId: "",
      optionName: "",
      receivedAmount: 0,
      utilizedAmount: 0,
      balanceAmount: 0,
      last4CardDigit: 0,
      last4CardDigitNo: "",
      cardType: "",
      transactionNo: "",
      status: "",
      knockOffDate: "",
      remark: "",
      attachmentFileId: "",
      attachmentFile: undefined,
      attachmentName: "",
      attachmentWebUrl: "",
      aryAttachments: [],
      isCardInput: false,
      isQRPay: false,
      paymentIssuerBankId: null,
      paymentIssuerBankName: null,
      salesOrderPayments: []
    },
    validationSchema: Yup.object({
      paymentDate: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentDate" }) })),
      paymentMethodId: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentMethod" }) })),
      receivedAmount: Yup.number().moreThan(0).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Amount" }) })),
      paymentMethodOptionId: Yup.string().test(
        "paymentMethodOptionId",
        intl.formatMessage({ id: "ValidationSelectOne" }, { field: newSpaceBeforeCapitalLetter(localOptionTypeTitle) }),
        (value: any, context) => {
          if ((qrValid || cardValid) && localOptionTypeList.length > 0) {
            return value;
          }
          return true;
        }
      ).nullable(),
      cardType: Yup.string().test(
        "cardType",
        intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Credit/DebitCardType"}) }),
        (value: any, context) => {
          if (cardValid) {
            return value;
          }
          return true;
        }
      ).nullable(),
      last4CardDigitNo: Yup.string().test(
        "last4CardDigitNo",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LastFourDigit"}) }),
        (value: any, context) => {
          if (cardValid) {
            return String(value).length === 4 && value !== undefined ? true : false;
          }
          return true;
        }
      ).nullable(),
      transactionNo: Yup.string().test(
        "transactionNo",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: qrValid || onlineTransferOrChequeValid ? "TransactionNo" : "ApprovalCode" }) }),
        (value: any, context) => {
          if (cardValid) {
            return String(value).length === 6 && value !== undefined ? true : false;
          }
          return true;
        }
      ).nullable(),
      paymentIssuerBankId: Yup.string().test(
        "paymentIssuerBankId",
        intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "IssuerBank" }) }),
        (value: any, context) => {
          if (localIssuerBankList.length > 0) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.paymentDate = `${valuesTemp.paymentDate}T${valuesTemp.paymentTime}`;
      valuesTemp.last4CardDigit = valuesTemp.last4CardDigitNo;
      delete valuesTemp["paymentTime"];

      try {
        if (valuesTemp.aryAttachments) {
          if (valuesTemp.aryAttachments.length > 0) {
            if (valuesTemp.aryAttachments[0].preview !== "") {
              let fileTemp : any = await returnBlobImage(valuesTemp.aryAttachments[0].preview);
              let fileWithFileName = new File([fileTemp], valuesTemp.aryAttachments[0].path);
              valuesTemp.attachmentFile = fileWithFileName;
              valuesTemp.attachmentFileId = "";
            }
          }
        }

        if (addAction) {
          await addUnknownPayment(valuesTemp);
        } else {
          await updateUnknownPayment(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchUnknownPaymentDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getPaymentMethodDropdown(),
        getStaticReferenceWithType(Constants.cardType)
      ]
      if (id && !addAction) {
        aryAPI.push(getUnknownPaymentWithId(id));
      }
      
      let resultAPI = await Promise.all(aryAPI);
      let paymentMethodListTemp : PaymentMethodListObject[] = [];
      resultAPI[1].map((valuePaymentMethod)=> {
        if (valuePaymentMethod.id === Constants.onlineTransferId || valuePaymentMethod.id === Constants.chequeId || valuePaymentMethod.id === Constants.ambankDuitNowId) {
          paymentMethodListTemp.push(valuePaymentMethod);
        }
        return valuePaymentMethod;
      })
      setLocalPaymentMethodList(AddOptionsValueField(paymentMethodListTemp, "name", "id"));

      if (resultAPI.length > 3) {
        if (resultAPI[3]) {
          if (resultAPI[3].attachmentWebUrl && resultAPI[3].attachmentName && resultAPI[3].attachmentFileId) {
            validation.setFieldValue("aryAttachments", [{
              preview: "",
              path: resultAPI[3].attachmentName,
              webUrl: resultAPI[3].attachmentWebUrl,
            }]);
          }
          else {
            validation.setFieldValue("aryAttachments", []);
          }
          validation.setFieldValue("paymentTime", moment(resultAPI[3].paymentDate).format(Constants.displayTime24HoursFormat))
          validation.setFieldValue("paymentDate", moment(resultAPI[3].paymentDate).format(Constants.textInputDateFormat))
          let indexPaymentMethodFound = _.findIndex(paymentMethodListTemp, {id: resultAPI[3].paymentMethodId})
          if (indexPaymentMethodFound > -1) {
            fetchAfterPaymentMethodSelect(paymentMethodListTemp[indexPaymentMethodFound], false, resultAPI[3])
          }
        }
      }
      setLoading(false);
    }

    if (addAction) {
      setUnknownPaymentDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateUnknownCustomerPayment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateUnknownCustomerPayment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageUnknownCustomerPayment], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateUnknownCustomerPayment){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.unknownPayment}/edit/${id}`)} })
    }

    fetchUnknownPaymentDetailAPI();

    return (() => {
      if (id && !addAction) {
        setUnknownPaymentDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const fetchDataAfterBranchSelect = async (branchId: string) => {
    setRefreshBranchDropdown(false);
    validation.setFieldValue("customerId", "")
    validation.setFieldValue("customerName", "")
    setTimeout(() => {
      setRefreshBranchDropdown(true);
    }, 100);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(validation.values.customerBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }
  
  const fetchAfterPaymentMethodSelect = async (valuePaymentMethod, blnClearData: boolean = true, unknownPaymentDetailTemp: UnknownPaymentDetailObject | undefined = undefined) => {
    setLoadingPaymentMethod(true);
    setCardValid(valuePaymentMethod.isCardInput);
    setQRValid(valuePaymentMethod.isQRPay);

    if (blnClearData) {
      validation.setFieldValue("cardType", "");
      validation.setFieldValue("last4CardDigitNo", "");
      validation.setFieldValue("optionName", "");
      validation.setFieldValue("paymentMethodOptionId", "");
      clearPaymentOptionData(true);
    }

    if (valuePaymentMethod.isOptionAvailable && valuePaymentMethod.optionType !== Constants.none) {
      setLocalOptionTypeTitle(valuePaymentMethod.optionType);
      let paymentMethodOptionListTemp = await getPaymentMethodOptionDropdown(valuePaymentMethod.id)
      setLocalOptionTypeList(paymentMethodOptionListTemp || []);

      if (!blnClearData && unknownPaymentDetailTemp && paymentMethodOptionListTemp) {
        let indexPaymentMethodOptionTemp = _.findIndex(paymentMethodOptionListTemp, { id: unknownPaymentDetailTemp.paymentMethodOptionId });
        if (indexPaymentMethodOptionTemp > -1) {
          onChangePaymentMethodOption(paymentMethodOptionListTemp[indexPaymentMethodOptionTemp], false);
        }
      }
    }
    else {
      setLocalOptionTypeTitle("");
      setLocalOptionTypeList([]);
    }
    setLoadingPaymentMethod(false);
  }

  const onChangePaymentMethodOption = async (valuePaymentMethodOption: PaymentMethodOptionListObject, blnClearData: boolean = true) => {
    if (blnClearData) {
      clearPaymentOptionData(false);
    }

    setLoadingIssuerBank(true);
    setLocalIssuerBankList(valuePaymentMethodOption.issuerBanks || []);
    setTimeout(()=> {
      setLoadingIssuerBank(false);
    }, 100)
  }
  
  const clearPaymentOptionData = (blnClearListing: boolean) => {
    validation.setFieldValue("paymentIssuerBankId", null);
    validation.setFieldValue("paymentIssuerBankName", null);

    if (blnClearListing) {
      setLocalIssuerBankList([]);
    }
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "UnknownPayment" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PaymentDate" })}
                    name="paymentDate"
                    className="mb-2 mt-4"
                    type="date"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                    maxCurrentDateWithHour={true}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={""}
                    name="paymentTime"
                    className="mb-3"
                    type="time"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"paymentMethodId"}
                    title={intl.formatMessage({ id: "PaymentMethod"})}
                    specifyReturnFieldName={[{"field": "paymentMethodId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={localPaymentMethodList}
                    disabled={disabledFieldInput || viewAction || loadingPaymentMethod}
                    initialLabel={validation.values.paymentMethodName}
                    initialValue={validation.values.paymentMethodId}
                    onChangeAllField={true}
                    onChangeFunction={fetchAfterPaymentMethodSelect}
                    validation={validation}
                    validationRequired={true}/>
                </SingleColumnRowParent>
                {
                  loadingPaymentMethod
                  ?
                  <SingleColumnRowParent>
                    <Loading/>
                  </SingleColumnRowParent>
                  :
                  <>
                    {
                      cardValid
                      &&
                      <Row>
                        <DoubleColumnRowParent>
                          <DropDownWithTitle
                            name={"cardType"}
                            title={intl.formatMessage({ id: "Credit/DebitCardType"})}
                            specifyReturnFieldName={[{"field": "cardType", "value": "displayValue"}]}
                            labelField={"displayValue"}
                            valueField={"displayValue"}
                            options={cardType}
                            disabled={disabledFieldInput || viewAction}
                            initialLabel={validation.values.cardType}
                            initialValue={validation.values.cardType}
                            validation={validation}
                            validationRequired={true}/>
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent>
                          <GeneralInput
                            title={intl.formatMessage({ id: "LastFourDigit" })}
                            name="last4CardDigitNo"
                            type="number"
                            maxLength={4}
                            disabled={disabledFieldInput || viewAction}
                            validationRequired={true}
                            blnNumberOnly={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                    }
                    {
                      localOptionTypeList.length > 0
                      &&
                      <SingleColumnRowParent>
                        <DropDownWithTitle
                          name={"paymentMethodOptionId"}
                          title={newSpaceBeforeCapitalLetter(localOptionTypeTitle)}
                          specifyReturnFieldName={[{"field": "paymentMethodOptionId", "value": "id"}]}
                          labelField={"name"}
                          valueField={"id"}
                          options={localOptionTypeList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={validation.values.optionName}
                          initialValue={validation.values.paymentMethodOptionId}
                          onChangeAllField={true}
                          onChangeFunction={onChangePaymentMethodOption}
                          validation={validation}
                          validationRequired={true}/>
                      </SingleColumnRowParent>
                    }
                    {
                      loadingIssuerBank
                      ?
                      <Loading/>
                      :
                      localIssuerBankList.length > 0
                      &&
                      <SingleColumnRowParent>
                        <DropDownWithTitle
                          name={"paymentIssuerBankId"}
                          title={intl.formatMessage({ id: "IssuerBanks" })}
                          specifyReturnFieldName={[{"field": "paymentIssuerBankId", "value": "id"}, {"field": "paymentIssuerBankName", "value": "name"}]}
                          labelField={"name"}
                          valueField={"id"}
                          options={localIssuerBankList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={validation.values.paymentIssuerBankName || ""}
                          initialValue={validation.values.paymentIssuerBankId || ""}
                          validation={validation}
                          validationRequired={true}/>
                      </SingleColumnRowParent>
                    }
                  </>
                }
                {
                  <Row>
                    <DoubleColumnRowParent 
                      blnDoubleTab={!cardValid && !qrValid && !onlineTransferOrChequeValid}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "Amount" })}
                        name="receivedAmount"
                        type="number"
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={true}
                        validation={validation}
                      />
                    </DoubleColumnRowParent>
                    {
                      (cardValid || qrValid || onlineTransferOrChequeValid)
                      &&
                      <DoubleColumnRowParent>
                        <GeneralInput
                          title={intl.formatMessage({ id: qrValid || onlineTransferOrChequeValid ? "TransactionNo" : "ApprovalCode" })}
                          name="transactionNo"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={cardValid}
                          validation={validation}
                          {...cardValid && {maxLength: 6}}
                        />
                      </DoubleColumnRowParent>
                    }
                  </Row>
                }
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"branchId"}
                      title={intl.formatMessage({ id: "Branch"})}
                      specifyReturnFieldName={[{"field": "customerBranchName", "value": "name"},{"field": "customerBranchId", "value": "id"}]}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={disabledFieldInput || viewAction || getBranchUser()}
                      initialLabel={validation.values.customerBranchName}
                      initialValue={validation.values.customerBranchId}
                      validation={validation}
                      onChangeFunction={fetchDataAfterBranchSelect}/>
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    {
                      !refreshBranchDropdown
                      ?
                      <Loading />
                      :
                      <DropDownWithTitle
                        name={"customerName"}
                        title={intl.formatMessage({ id: "Customer"})}
                        placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                        specifyReturnFieldName={[{"field": "customerId", "value": "id"}]}
                        labelField={"preferredName"}
                        valueField={"id"}
                        options={customerDropdownList}
                        disabled={disabledFieldInput || viewAction || !validation.values.customerBranchId}
                        initialLabel={validation.values.customerName}
                        initialValue={validation.values.customerId}
                        validation={validation}
                        onChangeField={"id"}
                        blnSupportCustomSearch={true}
                        onChangeCustomSearch={(value)=> {
                          fetchCustomerDropdown(value);
                        }}
                        onMenuOpenFunction={()=> {
                          clearCustomerDropdownList();
                          setLoadingCustomer(false);
                        }}
                        loadingCustomSearch={loadingCustomer}
                        setLoadingCustomSearch={setLoadingCustomer}/>
                    }
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title="Remark"
                    name="remark"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}/>
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralAttachment
                    title={intl.formatMessage({ id: "Attachment" })}
                    name="aryAttachments"
                    validation={validation}
                    disabled={disabledFieldInput || viewAction}/>
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(UnknownPaymentDetail);
