import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Label } from "reactstrap";
import { Constants } from "../../constants/Constants";
import { AdditionalFormFieldWithFormType } from "../../models/additionalFormField";
import GeneralTextArea from "./GeneralTextArea";
import { useIntl } from "react-intl";
import GeneralInput from "./GeneralInput";
import DropDownWithTitle from "../dropdown/DropDownWithTitle";
import { ObjectForDropdown } from "../../models/common";

interface Props {
  additionalFormFieldFormTypeList: AdditionalFormFieldWithFormType[];
  disabledFieldInput: boolean;
  addAction: boolean;
  viewAction: boolean;
	validation?: any;
  field?: any;
  setFieldValue?: Function;
  blnModal?: boolean;
}

export default function AdditionalFormField(props: Props) {
  const displayForm = (additionalFormFieldList: AdditionalFormFieldWithFormType[]) => {
    let aryViews : any = [];
    additionalFormFieldList.map((valueAdditionalFormField: AdditionalFormFieldWithFormType, index)=> {
      if (valueAdditionalFormField.controlType === Constants.multilineText) {
        aryViews.push(
          <GeneralTextArea
            key={`addtionalForm_${index}`}
            title={valueAdditionalFormField.name}
            name={valueAdditionalFormField.id}
            row={valueAdditionalFormField.rows}
            disabled={
              props.disabledFieldInput || props.viewAction
            }
            validation={props.validation}
            validationRequired={valueAdditionalFormField.isRequired}
            field={props.field}
            setFieldValue={props.setFieldValue}
            blnModal={props.blnModal} />
        )
      }
      else if (valueAdditionalFormField.controlType === Constants.dropDownlist) {
        let aryOptionsTemp = valueAdditionalFormField.options.map((value)=> ({value: value}))
        aryViews.push(
          <DropDownWithTitle
            key={`addtionalForm_${index}`}
            name={valueAdditionalFormField.id}
            title={valueAdditionalFormField.name}
            specifyReturnFieldName={[
              {
                field: valueAdditionalFormField.id,
                value: "value",
              },
            ]}
            labelField={"value"}
            valueField={"value"}
            options={aryOptionsTemp}
            disabled={props.disabledFieldInput || props.viewAction}
            initialLabel={!props.addAction ? props.blnModal ? props.field[valueAdditionalFormField.id] : props.validation.values[valueAdditionalFormField.id] : undefined}
            initialValue={!props.addAction? props.blnModal ? props.field[valueAdditionalFormField.id] : props.validation.values[valueAdditionalFormField.id] : undefined}
            validationRequired={valueAdditionalFormField.isRequired}
            validation={props.validation}
            field={props.field}
            setFieldValue={props.setFieldValue}
            blnModal={props.blnModal}/>
        )
      }
      else if (valueAdditionalFormField.controlType === Constants.radioList || valueAdditionalFormField.controlType === Constants.checkboxes) {
        let aryOptionsTemp : ObjectForDropdown[] = [];
        if (valueAdditionalFormField.controlType === Constants.radioList) {
          aryOptionsTemp = valueAdditionalFormField.options.map((value)=> ({label: value, value: value}))
        }
        else {
          aryOptionsTemp = valueAdditionalFormField.options.map((value)=> {
            return ({
              label: value, 
              value: false
            })
          })
        }
        aryViews.push(
          <GeneralInput
            key={`addtionalForm_${index}`}
            title={valueAdditionalFormField.name}
            name={valueAdditionalFormField.id}
            type={valueAdditionalFormField.controlType === Constants.radioList ? Constants.radio : Constants.checkbox}
            options={aryOptionsTemp}
            blnMultipleCheckbox={valueAdditionalFormField.controlType === Constants.checkboxes}
            disabled={props.disabledFieldInput ||props.viewAction}
            validation={props.validation}
            validationRequired={valueAdditionalFormField.isRequired}
            field={props.field}
            setFieldValue={props.setFieldValue}
            blnModal={props.blnModal}
          />
        )
      }
      else {
        aryViews.push(
          <GeneralInput
            key={`addtionalForm_${index}`}
            title={valueAdditionalFormField.name}
            name={valueAdditionalFormField.id}
            type={valueAdditionalFormField.controlType === Constants.numeric ? "number" : valueAdditionalFormField.controlType === Constants.dateTime ? "datetime-local" : valueAdditionalFormField.controlType === Constants.date ? "date" : valueAdditionalFormField.controlType === Constants.time ? "time" :"text" }
            disabled={props.disabledFieldInput ||props.viewAction}
            validation={props.validation}
            validationRequired={valueAdditionalFormField.isRequired}
            field={props.field}
            setFieldValue={props.setFieldValue}
            blnModal={props.blnModal}
          />
        )
      }      
    })
    return aryViews;
  }
  
	return (
		<div>
      {displayForm(props.additionalFormFieldFormTypeList)}
		</div>
	);
}
