import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, getBrandId, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, returnSubBrandList } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { EmployeeListObject } from "../../../app/models/employee";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { Link, useLocation } from 'react-router-dom';
import CustomerSearchModal from "../../CustomerPurchase/Modal/CustomerSearchModal";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { SubBrandObject } from "../../../app/models/brand";

const CollectionDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportPaymentStore, branchStore, customerStore, paymentMethodStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { collectionDetailsList, getCollectionDetailList, setCollectionDetailsList, exportCollectionDetails } = reportPaymentStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { paymentMethodDropdownList, getPaymentMethodDropdown } = paymentMethodStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [paymentMethodListFinal, setPaymentMethodListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] = useState(All);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(" ");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedConsultantName, setSelectedConsultantName] = useState("");
  const [selectedConsultantId, setSelectedConsultantId] = useState("");

  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingBranch, setLoadingBranch] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportPaymentReport = checkPermission([PermissionConstants.ExportPaymentReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const paramsSearch = useLocation().search;
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const paymentMethodParams = new URLSearchParams(paramsSearch).get('paymentMethodId');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    
    async function fetchCollectionListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let blnParams = (branchParams && paymentMethodParams && fromDateParams && toDateParams) || subBrandParams;
      let aryAPI: any = [
        getBranchDropDown(),
        getPaymentMethodDropdown(),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: selectedBranchId })
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setPaymentMethodListFinal(arraySpliceInAllValue(resultAPI[1], All, false, "name", "id"));
      
      if (blnParams) {
        setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })

        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        let paymentMethodIdTemp: number | string = " ";
        if (paymentMethodParams) {
          let paymentMethodListTemp: any = _.cloneDeep(resultAPI[1]);
          paymentMethodListTemp.map((value) => {
            if (paymentMethodParams == value.id) {
              setSelectedPaymentMethodName(value.name)
              setSelectedPaymentMethodId(value.id);
              paymentMethodIdTemp = value.id;
            }
          })
        }

        let subBrandIdTemp: number | string = " ";
        if (subBrandParams) {
          let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
          subBrandListTemp.map((value) => {
            if (subBrandParams == value.id) {
              setSelectedSubBrandName(value.name)
              setSelectedSubBrandId(value.id);
              subBrandIdTemp = value.id;
            }
          })
        }

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.collectionDetails}` });
        await fetchCollectionDetailApi(false, branchIdTemp, paymentMethodIdTemp, { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" }, subBrandIdTemp);
      }
      else if (branchParams) {
        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.collectionDetails}` });
        await fetchCollectionDetailApi(false, branchIdTemp);
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePaymentReport], true)) {
      return;
    }

    fetchCollectionListAPI();

    return (() => {
      setCollectionDetailsList([]);
    })
  }, []);

  useEffect(() => {
    async function getCustomerAndConsultanttDropdownFunction () {
      setDisplayCustomerDropdown(false);
      setLoadingBranch(true);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setSelectedConsultantId("");
      setSelectedConsultantName("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
      let aryAPI = [
        getEmployeeDropdown({ brandId: getBrandId(), branchId: selectedBranchId }),
      ]
      await Promise.all(aryAPI);
      setLoadingBranch(false);
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerAndConsultanttDropdownFunction();
    }
  }, [selectedBranchId])

  async function fetchCollectionDetailApi(blnDropDown: boolean = false, branchIdTemp?: string, paymentMethodIdTemp?: string, dateRange?: DateRangeInterface, subBrandIdTemp?: string) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getCollectionDetailList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: branchIdTemp || selectedBranchId,
      dateRange: dateRange || selectedDateRange,
      paymentMethodId: paymentMethodIdTemp || selectedPaymentMethodId,
      employeeId: selectedConsultantId,
      customerId: selectedCustomerId,
      subBrandId: subBrandIdTemp || selectedSubBrandId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportPaymentCollectionDetailsApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    await exportCollectionDetails({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      paymentMethodId: selectedPaymentMethodId,
      employeeId: selectedConsultantId,
      customerId: selectedCustomerId,
      subBrandId: selectedSubBrandId
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const CollectionColumns = [
    {
      dataField: "customerSalesOrderId", //field name from array to display
      text: "customerSalesOrderId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.customerSalesOrderId}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "employeeName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.employeeName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "PaidAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.paymentMethodName) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentDetails",
      text: intl.formatMessage({ id: "Details" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.paymentDetails || Constants.emptyData}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentCollectionDetails" }) })}
        title={intl.formatMessage({ id: "PaymentCollectionDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportPaymentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportPaymentCollectionDetailsApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Payment-Collection-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
            </Row>
            <Row>
              {
                loadingBranch
                ?
                <div/>
                :
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Consultant" })}
                    name={"employeeId"}
                    options={employeeDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedConsultantName}
                    initialValue={selectedConsultantId}
                    setFieldLabel={setSelectedConsultantName}
                    setFieldValue={setSelectedConsultantId}
                    disabled={localLoading || loading} />
                </DoubleColumnRowParent>
              }
              {
                !getBranchUser()
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "PaymentMethod" })}
                    name="paymentMethodId"
                    options={paymentMethodListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedPaymentMethodName}
                    initialValue={selectedPaymentMethodId}
                    setFieldLabel={setSelectedPaymentMethodName}
                    setFieldValue={setSelectedPaymentMethodId}
                    disabled={localLoading || loading}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              {/* <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={false}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "CustomField" })}
                />
              </DoubleColumnRowParent> */}
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading || loadingBranch || loadingCustomer}
                  disable={localLoading || loading || loadingBranch || loadingCustomer}
                  onClick={() => {
                    fetchCollectionDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CollectionColumns}
              keyField={"customerSalesOrderId"}
              data={collectionDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PaymentCollectionDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getCollectionDetailList}
              branchId={selectedBranchId}
              paymentMethodId={selectedPaymentMethodId}
              employeeId={selectedConsultantId}
              customerId={selectedCustomerId}
              dateRange={selectedDateRange}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} 
              hideSearchBar={true} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect}
          blnHideOtherBranch={true} />
      }
    </div>
  );
}

export default observer(CollectionDetails);
