import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { UserRoleListObject } from '../../app/models/userRole';
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import UserRolePermissionsListingModal from "./Modal/UserRolePermissionsListingModal";
import { checkPermission, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import Loading from "../../app/components/loading/Loading";

const UserRole = () => {
  //Use Intl
  const intl = useIntl();
  //Use Store
  const { userRoleStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt } = commonStore;
  const { userRoleList, userRoleDetail, getUserRole, getUserRoleWithId } = userRoleStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [userRolePermissionsListingModal, setUserRolePermissionsListingModal] = useState(false);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchUserRoleAPI() {
      setLoading(true);
      await getUserRole()
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageUserRole], true)) {
      return;
    }

    fetchUserRoleAPI();
  }, [])

  const onClickView = async (row: UserRoleListObject) => {
    setLoading(true);
    let resultUserRoleWithId = await getUserRoleWithId(row.id);
    setLoading(false);
    if (resultUserRoleWithId?.status === Constants.success) {
      setUserRolePermissionsListingModal(true);
    }
  }

  //Table Content UI
  const UserRoleColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '85%'
      },
      formatter: (cellContent, row) => <>{newSpaceBeforeCapitalLetter(row.name)}</>, //Custom UI to display
    },

    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              type={`viewModal`}
              onClick={() => onClickView(row)}/>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "UserRole" }) })}
        title={intl.formatMessage({ id: "UserRole" })}
      >
        <div className="standard-layout">
          <Row>
            <Col xl="12">
              <div className="table-responsive">
                {
                  loading 
                  ?
                  <Loading />
                  :
                  <PaginationTableWithoutApi
                    title={intl.formatMessage({ id: "UserRole" })}
                    keyField={"id"}
                    columns={UserRoleColumns}
                    options={userRoleList}
                    blnDisplayForMainListing={true} />
                }
              </div>
            </Col>
          </Row>
        </div>
        {
          userRolePermissionsListingModal
          &&
          <UserRolePermissionsListingModal
            blnShow={userRolePermissionsListingModal}
            setModal={setUserRolePermissionsListingModal}
            selectedUserRole={userRoleDetail} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(UserRole);
