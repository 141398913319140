import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchUser, getBrandId } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { EmployeeListObject } from "../../../app/models/employee";

const LeadsSummaryByEmployee = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { leadsSummaryByEmployeeList, getLeadsByEmployeeSummary, setLeadsSummaryByEmployeeList, exportLeadsSummaryByEmployee } = reportMarcomStore; 

  //Data List
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState(All);
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");  
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);

  const aryLeadsSummaryByEmployeeDetail : ObjectForReportRow[] = [
    {label: "Source", value: "sourceName", type: "string"}, 
    {label: "TotalSources", value: "totalSources", type: "string"}, 
    {label: "TotalFollowUpMade", value: "totalFollowUpMade", type: "string"}, 
    {label: "TotalAppointmentMade", value: "totalAppointmentMade", type: "string"}, 
    {label: "TotalTurnUp", value: "totalTurnUp", type: "string"}, 
    {label: "AppointmentMadePercentage", value: "totalAppointmentMadePercentage", type: "string"}, 
    {label: "TurnUpPercentage", value: "totalTurnUpPercentage", type: "string"}, 
    {label: "CancellationPercentage", value: "totalCancellationPercentage", type: "string"}, 
    {label: "TotalClosed", value: "totalClosed", type: "string"}, 
    {label: "Action", value: "routeUrl", type: "action"}
  ];
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchLeadsSummaryByEmployee() {
			setLoading(true);
      let aryAPI: any = [
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.customerServiceId},${Constants.marComCustomerServiceId}`]}, true)
      ];
      
      let resultAPI = await Promise.all(aryAPI);

      setMarcomConsultantList(arraySpliceInAllValue(resultAPI[0], All, false, "preferredName", "id"));
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }
    
    fetchLeadsSummaryByEmployee();

    return (()=> {
      setLeadsSummaryByEmployeeList([]);
    })
  }, [])

  const fetchLeadsSummaryByEmployeeWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }
    
    setLocalLoading(true);
    if (blnExport) {
      await exportLeadsSummaryByEmployee({ employeeId:  selectedMarcomConsultantId === " " ? undefined : selectedMarcomConsultantId, fromDate: decodeURIComponent(selectedDateRange.fromDate), toDate: decodeURIComponent(selectedDateRange.toDate)})
    }
    else {
      await getLeadsByEmployeeSummary({employeeId: selectedMarcomConsultantId, dateRange: selectedDateRange});
    }
    setLocalLoading(false);
	};

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const LeadsSummaryByEmployeeColumns = [
    { 
      dataField: "employeeId", //field name from array to display
      text: "employeeId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "employeeName",
      text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
    },
    {
      dataField: "totalSources",
      text: intl.formatMessage({ id: "TotalSources" }).toUpperCase(),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "LeadsSummaryDetails" })}
        rowFieldName={"leadsSummaryDetails"}
        rowColumns={aryLeadsSummaryByEmployeeDetail}
        keyFieldName={"sourceId"}
        actionTitle={intl.formatMessage({ id: "ViewLeadsDetails" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.leadsDetail}`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "LeadsSummaryByMarcomCS"})})}
        title={intl.formatMessage({ id: "LeadsSummaryByMarcomCS" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchLeadsSummaryByEmployeeWithLoading(true)
            }}
          />
        }>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "AppointedBy" })}
              name={"marcomConsultantId"}
              options={marcomConsultantList}
              labelField="preferredName"
              valueField="id"
              initialLabel={selectedMarcomConsultantName}
              initialValue={selectedMarcomConsultantId}
              setFieldLabel={setSelectedMarcomConsultantName}
              setFieldValue={setSelectedMarcomConsultantId}
              disabled={localLoading || loading}
              validationRequired={true} />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={()=> {
                fetchLeadsSummaryByEmployeeWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={LeadsSummaryByEmployeeColumns}
            data={leadsSummaryByEmployeeList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "LeadsSummaryByMarcomCS"})})}
            objSorted={defaultSorted}
            keyField={"employeeId"}
            employeeId={selectedMarcomConsultantId}
            dateRange={selectedDateRange}
            requestAPI={getLeadsByEmployeeSummary}
            expandRow={expandRow}
            hideSearchBar={true}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadsSummaryByEmployee);
