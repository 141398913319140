import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, getYears, getMonths, returnSubBrandList } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { Link, useLocation } from 'react-router-dom';
import { PaginationRequestBody } from "../../app/models/pagination";
import TableActionButton from "../../app/components/table/TableActionButton";
import { MarcomDailyRecordListObject } from "../../app/models/marcomDailyRecord";
import MyAddButton from "../../app/components/form/MyAddButton";
import MarcomDailyRecordDetailModal from "./Modal/MarcomDailyRecordDetailModal";
import DeleteModal from "../../app/components/modal/DeleteModal";

const MarcomDailyRecord = () => {
  const intl = useIntl();
  //Use Store
  const { marcomDailyRecordStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { marcomDailyRecordList, deleteMarcomDailyRecord, getMarcomDailyRecord, addMarcomDailyRecord } = marcomDailyRecordStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<MarcomDailyRecordListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedDailySalesRecordType, setSelectedDailySalesRecordType] = useState<number | string>(AllType);
  const [selectedDailySalesRecordTypeId, setSelectedDailySalesRecordTypeId] = useState<number | string | undefined>(" ");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [marcomRecordTypeList, setDailySalesRecordTypeList] = useState<StaticReferenceDataObject[]>([])
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionCreateMarcomDailyRecords = checkPermission([PermissionConstants.CreateMarcomDailyRecords]);
  const blnPermissionDeleteMarcomDailyRecords = checkPermission([PermissionConstants.DeleteMarcomDailyRecords]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const [MarcomDailyRecordModal, setMarcomDailyRecordModal] = useState(false);

  let marcomDailyRecordRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    type: selectedDailySalesRecordTypeId,
    year: selectedYear,
    month: selectedMonthValue
  }
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths())
    
    async function fetchMarcomDailyRecordListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getMarcomDailyRecord(marcomDailyRecordRequestBody),
        getStaticReferenceWithType(Constants.marcomRecordType, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setDailySalesRecordTypeList(resultAPI[2]);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomDailyRecords], true)) {
      return;
    }

    fetchMarcomDailyRecordListAPI();
  }, []);

  useEffect(()=> {
    if ((selectedDailySalesRecordTypeId || selectedDailySalesRecordTypeId === 0 ) && !initialCallAPI) {
      fetchMarcomDailyRecord(true);
    }
  }, [selectedBranchId, selectedDailySalesRecordTypeId, selectedYear, selectedMonth])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setMarcomDailyRecordModal(false);
        fetchMarcomDailyRecord(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchMarcomDailyRecord(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getMarcomDailyRecord(marcomDailyRecordRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const MarcomDailyRecordColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "responseName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase()
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase()
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.type)}
        </>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomerSalesOrder && row.salesOrderId !== Constants.emptyId
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            row.salesOrderNo || Constants.emptyData
          }
        </div>
      ),
    },
    {
      ...returnSubBrandList().length > 0
      ?
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
          </div>
        )
      }
      :
      {
        dataField: "responseName",
        text: intl.formatMessage({ id: "Name" }).toUpperCase(),
        hidden: true //hidden ui
      },
    },
    {
      dataField: "spendingValue",
      text: intl.formatMessage({ id: "SpendingValue" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.spendingValue)}
        </div>
      ),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "CreatedAt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.createdAt ? moment(row.createdAt).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.createdAt ? moment(row.createdAt).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "isManualCreation",
      text: intl.formatMessage({ id: "Manual" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isManualCreation ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.remark || Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionDeleteMarcomDailyRecords
              &&
              <TableActionButton
                type="delete"
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    }
  ];

  const onClickDelete = async (row: MarcomDailyRecordListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "MarcomDailyRecordHistory" }) })}
        title={intl.formatMessage({ id: "MarcomDailyRecordHistory" })}
        addButton={
          blnPermissionCreateMarcomDailyRecords
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "MarcomDailyRecordHistory" })}
            onClick={() => {
              setMarcomDailyRecordModal(true);
            }}
            disable={localLoading || loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Type" })}
              name="marcomRecordType"
              options={marcomRecordTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedDailySalesRecordType}
              initialValue={selectedDailySalesRecordTypeId}
              setFieldLabel={setSelectedDailySalesRecordType}
              setFieldValue={setSelectedDailySalesRecordTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
         </Col>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={MarcomDailyRecordColumns}
              keyField={"id"}
              data={marcomDailyRecordList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "MarcomDailyRecordHistory" }) })}
              objSorted={defaultSorted}
              requestAPI={getMarcomDailyRecord}
              branchId={selectedBranchId}
              type={selectedDailySalesRecordTypeId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              hideSearchBar={true} />
        }
        <DeleteModal
          displayName={`${selectedRow?.responseName} - ${selectedRow?.type}`}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteMarcomDailyRecord(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (marcomDailyRecordList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getMarcomDailyRecord({  ...marcomDailyRecordRequestBody, pageNumber: currentPageTemp })
            setLoading(false);
          }} />
      {
        MarcomDailyRecordModal &&
        <MarcomDailyRecordDetailModal
          blnShow={MarcomDailyRecordModal}
          setModal={setMarcomDailyRecordModal}
          optionYearList={yearList}
          optionMonthList={monthList}
          addMarcomDailyRecord={addMarcomDailyRecord}
        />
      }
      </ListViewLayout>
    </div>
  );
}

export default observer(MarcomDailyRecord);
