import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, firstDayOfMonthToCurrentDay, virtualWarehouseStatusColor, getBranchName, arraySpliceInAllValue } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from 'react-router-dom';
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const RecogniseSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { recogniseSalesDetailsList, getRecogniseSalesDetailsList, setRecogniseSalesDetailsList, exportRecogniseSalesDetails } = reportAccountStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { earnSalesType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [selectedSalesType, setSelectedSalesType] = useState<number | string>("All");
  const [selectedSalesTypeId, setSelectedSalesTypeId] = useState<any>(0);
  const [selectedRedemptionDateRange, setSelectedRedemptionDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedSalesOrderDateRange, setSelectedSalesOrderDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);

  const paramsSearch = useLocation().search;

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    async function fetchDynamicSalesListAPI() {     
      setLoading(true); 
      setLoadingDropdownRefresh(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.earnSalesType)
      ];
      let resultAPI = await Promise.all(aryAPI);  
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      const purchaseBranchIdParams = new URLSearchParams(paramsSearch).get('purchaseBranchId');
      const redemptionFromDateParams = new URLSearchParams(paramsSearch).get('redemptionFromDate');
      const redemptionToDateParams = new URLSearchParams(paramsSearch).get('redemptionToDate');
      const salesOrderFromDateParams = new URLSearchParams(paramsSearch).get('salesOrderFromDate');
      const salesOrderToDateParams = new URLSearchParams(paramsSearch).get('salesOrderToDate');
      const salesTypeParams = new URLSearchParams(paramsSearch).get('salesType');
     
      if (purchaseBranchIdParams && redemptionFromDateParams && redemptionToDateParams && salesOrderFromDateParams && salesOrderToDateParams && salesTypeParams) {
        let indexBranch = _.findIndex(resultAPI[0], { id: purchaseBranchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchName(resultAPI[0][indexBranch].name);
          setSelectedBranchId(resultAPI[0][indexBranch].id);
        }

        let indexEarnSalesType = _.findIndex(resultAPI[1], { key: Number(salesTypeParams) });
        if (indexEarnSalesType > -1) {
          setSelectedSalesType(resultAPI[1][indexEarnSalesType].displayValue);
          setSelectedSalesTypeId(resultAPI[1][indexEarnSalesType].key);
        }

        let redemptionFromDateTemp = moment(redemptionFromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let redemptionToDateTemp = moment(redemptionToDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let salesOrderFromDateTemp = moment(salesOrderFromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let salesOrderToDateTemp = moment(salesOrderToDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedRedemptionDateRange({fromDate: redemptionFromDateTemp, toDate: redemptionToDateTemp});
        setSelectedSalesOrderDateRange({fromDate: salesOrderFromDateTemp, toDate: salesOrderToDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.recogniseSalesDetails}` });
        await getRecogniseSalesDetailsList({
          pageNumber: 1,
          pageSize: pageSize,
          redemptionFromDate: redemptionFromDateTemp,
          redemptionToDate: redemptionToDateTemp,
          salesOrderFromDate: salesOrderFromDateTemp,
          salesOrderToDate: salesOrderToDateTemp,
          branchId: purchaseBranchIdParams,
          salesType: salesTypeParams
        });
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setRecogniseSalesDetailsList([]);
    })
  }, []);

  async function fetchRecogniseSalesDetailsListApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getRecogniseSalesDetailsList({
      pageNumber: 1,
      pageSize: pageSize,
      redemptionFromDate: selectedRedemptionDateRange.fromDate,
      redemptionToDate: selectedRedemptionDateRange.toDate,
      salesOrderFromDate: selectedSalesOrderDateRange.fromDate,
      salesOrderToDate: selectedSalesOrderDateRange.toDate,
      branchId: selectedBranchId,
      salesType: selectedSalesTypeId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportRecogniseSalesDetailsApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    setLocalLoading(true);
    await exportRecogniseSalesDetails({
      pageNumber: 1,
      pageSize: pageSize,
      redemptionFromDate: selectedRedemptionDateRange.fromDate,
      redemptionToDate: selectedRedemptionDateRange.toDate,
      salesOrderFromDate: selectedSalesOrderDateRange.fromDate,
      salesOrderToDate: selectedSalesOrderDateRange.toDate,
      branchId: selectedBranchId,
      salesType: selectedSalesTypeId
    });
    setLocalLoading(false);
  }

  const onSelectRedemptionDateRange = (objDateRange: any) => {
    setSelectedRedemptionDateRange(objDateRange);
  }

  const onSelectSalesOrderDateRange = (objDateRange: any) => {
    setSelectedSalesOrderDateRange(objDateRange);
  }

  const tableFixedWidth = 150;
  const returnHeaderStyle = { width: `${tableFixedWidth}px`, minWidth: `${tableFixedWidth}px`, maxWidth: `${tableFixedWidth}px` };

  //Table Content UI
  const RecogniseSalesDetailsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerRedemptionNo",
      text: intl.formatMessage({ id: "CustomerRedemptionNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            checkPermission([PermissionConstants.ManageCustomerDeliverOrder])
            ?
            <Link to={row.customerRedemptionId ? `/${RoutesList.customerRedemption}/view/${row.customerRedemptionId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerRedemptionNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerRedemptionNo}
            </div>
          }
        </>
      ),
    },
    {
      dataField: "redeemBranchName",
      text: intl.formatMessage({ id: "RedeemBranch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerSalesOrder
            ?
            <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <Label className="margin-bottom-0">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          }
        </>
      )
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "redeemDate",
      text: intl.formatMessage({ id: "RedeemDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.redeemDate)
      ),
    },
    {
      dataField: "isSystemAutoGenerated",
      text: intl.formatMessage({ id: "ProductRedemption" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: '100px', minWidth: '100px', maxWidth: '100px' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isSystemAutoGenerated ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={virtualWarehouseStatusColor} />
      ),
    },
    {
      dataField: "redeemDetailName",
      text: intl.formatMessage({ id: "ItemName" }).toUpperCase(),
      headerStyle: { width: "250px", minWidth: "250px", maxWidth: "250px" },
    },
    {
      dataField: "redeemType",
      text: intl.formatMessage({ id: "RedeemType" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.redeemType)}
        </>
      ),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "RedeemQuantity" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },    
      formatter: (cellContent, row) => (
        <div style={{ textAlign: "center" }}>
          {row.count}
        </div>
      ),
    },
    {
      dataField: "totalSales",
      text: intl.formatMessage({ id: "TotalRecogniseSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalSales)}</div>
      ),
    }
  ];

  const returnDisplayDate = (valueDate) => {
    return (
      valueDate === Constants.invalidDate
      ?
      Constants.emptyDataV2
      :
      <>
        <div className="">
          {`${moment(valueDate).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(valueDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "RecogniseSalesDetails" }) })}
        title={intl.formatMessage({ id: "RecogniseSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportRecogniseSalesDetailsApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Recognise-Sales-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  className={""}
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "RedemptionDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectRedemptionDateRange}
                  initialValue={selectedRedemptionDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SalesOrderDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectSalesOrderDateRange}
                  initialValue={selectedSalesOrderDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SalesType" })}
                  name="salesType"
                  options={earnSalesType}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedSalesType}
                  initialValue={selectedSalesTypeId}
                  setFieldLabel={setSelectedSalesType}
                  setFieldValue={setSelectedSalesTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchRecogniseSalesDetailsListApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={RecogniseSalesDetailsColumns}
              keyField={"randomId"}
              data={recogniseSalesDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "RecogniseSalesDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getRecogniseSalesDetailsList}
              bordered={true}
              branchId={selectedBranchId}
              redemptionFromDate={selectedRedemptionDateRange.fromDate}
              redemptionToDate={selectedRedemptionDateRange.toDate}
              salesOrderFromDate={selectedSalesOrderDateRange.fromDate}
              salesOrderToDate={selectedSalesOrderDateRange.toDate}
              salesType={selectedSalesTypeId} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(RecogniseSalesDetails);
