import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import { AddOptionsValueField, removeAllSpace } from "../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: any;
  transferCreditAdjustmentType?: StaticReferenceDataObject[];
  transferCustomerCredit: Function;
  setErrorMessage: Function;
}

export default function TransferCreditModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [creditAdjustmentOptionType, setCreditAdjustmentOptionType] = useState<any>([]);

  const defaultTransferCreditDetails = {
    customerId: props.customerId,
    adjustmentType: "",
    credits: 0,
    remark: ""
  }
  const [transferCreditDetails, setTransferCreditDetails] = useState(defaultTransferCreditDetails)
  const [errorState, setErrorState] = useState({
    adjustmentType: false,
    credits: false,
    remark: false
  })
  const disabledFieldInput = loadingTransfer || Boolean(successMessage);

  useEffect(() => {
    if (props.transferCreditAdjustmentType) {
      let transferCreditAdjustmentTypeTemp = AddOptionsValueField(props.transferCreditAdjustmentType, "displayValue", "displayValue");
      setCreditAdjustmentOptionType(transferCreditAdjustmentTypeTemp)
    }
  }, [props.transferCreditAdjustmentType])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        hideModal();
        history.push(`/${RoutesList.customer}/view/${props.customerId}`);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      adjustmentType: false,
      credits: false,
      remark: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setTransferCreditDetails(defaultTransferCreditDetails);
    props.setModal(false);
  }

  const onChangeAdjustmentType = (type) => {
    let typeTemp = removeAllSpace(type)
    setTransferCreditDetails(valueTemp => ({
      ...valueTemp,
      adjustmentType: typeTemp,
    }));
    clearErrorState("adjustmentType");
  }

  const onClickTransferCredit = async (customerTransferCreditDetailTemp: any) => {
    setLoadingTransfer(true);
    await props.transferCustomerCredit(customerTransferCreditDetailTemp);
    setLoadingTransfer(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "TransferCredit" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <SingleColumnRowParent blnDoubleTab={true}>
            <Label>
              {<Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>}
              <Label className="margin-bottom-0">{intl.formatMessage({ id: "AdjustmentType" })}</Label>
            </Label>
            <GeneralSelectDropdown
              title=""
              name=""
              className={errorState.adjustmentType ? "mb-0" : "mb-3"}
              options={creditAdjustmentOptionType}
              onChange={onChangeAdjustmentType}
              initialLabel={transferCreditDetails.adjustmentType}
              initialValue={transferCreditDetails.adjustmentType}
              blnValueWithNewSpace={true}
              validationRequired={true}
              disabled={disabledFieldInput}
            />
            {errorState.adjustmentType ? (
              <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CreditAdjustmentType" }) })}</FormFeedback>
            ) : null}
          </SingleColumnRowParent>
          <SingleColumnRowParent blnDoubleTab={true}>
            <GeneralInputForModal
              title={intl.formatMessage({ id: "Credits" })}
              name="credits"
              className={errorState.credits ? "mb-0" : "mb-3"}
              type="number"
              validationRequired={true}
              field={transferCreditDetails}
              setFieldValue={setTransferCreditDetails}
              disabled={disabledFieldInput}
            />
            {errorState.credits ? (
              <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CreditAdjustmentAmount" }) })}</FormFeedback>
            ) : null}
          </SingleColumnRowParent>
          <SingleColumnRowParent blnDoubleTab={true}>
            <GeneralTextAreaForModal
              title={intl.formatMessage({ id: "Remark" })}
              name="remark"
              row={5}
              validationRequired={true}
              field={transferCreditDetails}
              setFieldValue={setTransferCreditDetails}
              disabled={disabledFieldInput} />
            {errorState.remark ? (
              <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
            ) : null}
          </SingleColumnRowParent>
          <p className="font-size-14 error_require_text mt-2">
            {intl.formatMessage({ id: "TransferCreditSubmission" })}
          </p>
          <div className="mt-3 mb-2 flex-right flex-direction-row">
            {
              !Boolean(successMessage)
              &&
              <>
                <MyButton
                  type="button"
                  class="btn btn-primary"
                  content={intl.formatMessage({ id: "Submit" })}
                  onClick={() => {
                    let errorStateTemp = {
                      adjustmentType: false,
                      credits: false,
                      remark: false
                    };
                    let blnErrorFound = false;
                    if (!transferCreditDetails.adjustmentType) {
                      errorStateTemp.adjustmentType = true;
                      blnErrorFound = true;
                    }
                    if (!transferCreditDetails.credits) {
                      errorStateTemp.credits = true;
                      blnErrorFound = true;
                    }
                    if (!transferCreditDetails.remark) {
                      errorStateTemp.remark = true;
                      blnErrorFound = true;
                    }
                    if (blnErrorFound) {
                      setErrorState(errorStateTemp)
                    }
                    else {
                      onClickTransferCredit(transferCreditDetails)
                    }
                  }}
                  disable={disabledFieldInput}
                  loading={loadingTransfer}
                />
              </>
            }
          </div>
        </div>
      </Modal>
  )
}