import { PaginationRequestBody } from "../models/pagination";
import { TaxListObject, TaxAddObject, TaxUpdateObject, TaxDetailObject } from "../models/tax";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class TaxStore {
  taxList: TaxListObject[] = [];
  taxDropdownList: TaxListObject[] = [];
  taxDetail: TaxDetailObject | undefined = undefined;
  taxPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.taxList = [];
    this.taxDropdownList = [];
    this.taxDetail = undefined;
    this.taxPaginationParams= undefined;
  }

  setTaxDetail = (taxDetail: TaxDetailObject | undefined) => {
    this.taxDetail = taxDetail;
  }

  setTaxPaginationParams = (taxPaginationParams: PaginationRequestBody | undefined) => {
    this.taxPaginationParams = taxPaginationParams;
  }
  
  getTax = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setTaxPaginationParams(PaginationRequestBody);
    try{
      const resultTax = await agent.Tax.taxList(PaginationRequestBody);
      runInAction(() => {
        this.taxList = resultTax.data;
        store.commonStore.setTotalItem(resultTax.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.taxList = [];
    }
  }

  getTaxDropdown = async () => {
    try{
      const resultTaxDropdown = await agent.Tax.taxDropdownList();
      runInAction(() => {
        this.taxDropdownList = resultTaxDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.taxDropdownList = [];
    }
  }

  getTaxWithId = async (id: string) => {
    try{
      const resultTaxDetail = await agent.Tax.taxDetail(id);
      runInAction(() => {
        this.taxDetail = resultTaxDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.taxDetail = undefined;
    }
  }

  addTax = async (taxRequestBody: TaxAddObject) => {
    try{
      await agent.Tax.addTax(taxRequestBody);
      store.commonStore.setSuccessMessage(`TaxAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateTax = async (taxRequestBody: TaxUpdateObject) => {
    try{
      await agent.Tax.updateTax(taxRequestBody);
      store.commonStore.setSuccessMessage(`TaxUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteTax  = async (id: string, name: string) => {
    try {
      await agent.Tax.deleteTax(id);
      store.commonStore.setSuccessMessage(`TaxDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}