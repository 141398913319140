import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { Constants } from "../../constants/Constants";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Col, Row } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import { useIntl } from 'react-intl';

interface Props {
  title: string;
  options: any; //Dropdown data to display
  columns: any;
  keyField: string;
  rowEvents?: any;
  selectRow?: any;
  blnShowSearchBar?: boolean;
  blnDisplayForMainListing?: boolean;
  pageSize?: number;
  headerClassName?: string;
  expandRow?: any;
  striped?: boolean;
  blnHideTotal?: boolean;
  blnDisabledScroll?: boolean;
}

export default function PaginationTableWithoutApi(props: Props) {
  const intl = useIntl();
  
  //Convert Search framework into SearchBar for naming.
  const { SearchBar } = Search;
  
  return (
    <PaginationProvider
      pagination={paginationFactory({
        sizePerPage: props.pageSize || Constants.defaultPageSize,
        showTotal: !props.blnHideTotal,
        hideSizePerPage: true,
        ...!props.blnShowSearchBar && { totalSize: props.options.length },
        ...(props.options?.length < 1 || props.pageSize === Constants.maxPageSize) && {pageListRenderer: props => <div/>}
      })}
      keyField={props.keyField}
      columns={props.columns}
      data={props.options}>
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={props.keyField}
          columns={props.columns}
          data={props.options}
          bootstrap4
          search>
          {
            toolkitProps => (
              <>
                {
                  props.blnShowSearchBar
                  &&
                  <Col className="flex-right">
                    <div className="search-box ms-2 margin-bottom-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                }
                <div className={`${!props.blnDisabledScroll ? "table-responsive" : ""}`}>
                  <BootstrapTable
                    responsive
                    bordered={props.blnDisplayForMainListing ? false : true}
                    striped={props.striped || false}
                    classes={"table align-middle"}
                    keyField={props.keyField}
                    noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: props.title})}
                    expandRow={props.expandRow}
                    {...(props.blnDisplayForMainListing || props.headerClassName) && {headerClasses: props.headerClassName || "table-light"}}
                    {...props.rowEvents && {rowEvents: props.rowEvents }}
                    {...props.selectRow && {selectRow: props.selectRow}}
                    {...toolkitProps.baseProps}
                    {...paginationTableProps}
                  />
                </div>
                {/* <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Col>
                </Row> */}
              </>
            )
          }
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}