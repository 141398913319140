import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, displayPromotionCode, getBranchId, getBranchName, getPurchaseTypeIcon, returnPriceWithCurrency } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { RefundDetail } from "../../../app/models/customerRefund";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from '../../..';
interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerForTransfer: any;
  setSelectedCustomerForTransfer: Function;
  addCustomerTransferRequest?: Function;
  setTransferRequestAcknowledgementModal: Function;
  setTransferRequestResponse: Function;
}

export default observer(function CustomerTransferAccessRequestModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingCreate, setLoadingCreate] = useState(false);

  const defaultCreateCustomerTransferRequestDetails = {
    customerId: "",
    toBranchId: "",
    reasonOfChange: "",
  }
  const [createCustomerTransferRequestDetails, setCreateCustomerTransferRequestDetails] = useState(defaultCreateCustomerTransferRequestDetails)
  const [errorState, setErrorState] = useState({
    reasonOfChange: false,
  })
  const blnPermissionCreateCustomerTransferRequest = checkPermission([PermissionConstants.CreateCustomerTransferRequest])

  const hideModal = () => {
    props.setSelectedCustomerForTransfer([]);
    setCreateCustomerTransferRequestDetails(defaultCreateCustomerTransferRequestDetails);
    props.setModal(false);
  }

  useEffect(() => {
    if (props.selectedCustomerForTransfer) {
      validationLocal.setFieldValue("customerName", props.selectedCustomerForTransfer.customerName);
      validationLocal.setFieldValue("toBranchName", getBranchName());
      validationLocal.setFieldValue("fromBranchName", props.selectedCustomerForTransfer.branchName);
    }
  }, [props.selectedCustomerForTransfer])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      toBranchName: "",
      fromBranchName: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      reasonOfChange: false
    });
  }

  const onClickCreateCustomerTransferRequest = async () => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...createCustomerTransferRequestDetails, customerId: props.selectedCustomerForTransfer.customerId, toBranchId: getBranchId() })
    setCreateCustomerTransferRequestDetails(reviewCreditAdjustmentTemp);
    if (props.addCustomerTransferRequest) {
      setLoadingCreate(true);
      let resultAPI = await props.addCustomerTransferRequest(reviewCreditAdjustmentTemp)
      if(resultAPI.status === Constants.success){
        props.setTransferRequestResponse(resultAPI.data);
        setTimeout(()=> {
          props.setTransferRequestAcknowledgementModal(true);
        }, Constants.dismissSuccessMessage)
      }
      setLoadingCreate(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingCreate && !successMessage) {
            hideModal();
          }
        }}
        size="lg"
        centered>
        {
          !props.selectedCustomerForTransfer
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "CustomerTransferRequest" }) })}</h5>
                </div>
                {
                  !loadingCreate && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customerName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "FromBranch" })}
                      name="fromBranchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ToBranch" })}
                      name="toBranchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Borderline />
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "ReasonForTransfer" })}
                  name="reasonOfChange"
                  className="mt-3"
                  row={5}
                  field={createCustomerTransferRequestDetails}
                  setFieldValue={setCreateCustomerTransferRequestDetails}
                  validationRequired={true}
                  onChangeFunction={() => { clearErrorState("reasonOfChange") }}
                  disabled={Boolean(successMessage)}/>
                  {errorState.reasonOfChange ? (
                      <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReasonOfChange" })}</FormFeedback>
                  ) : null}
                <div className="mb-2 mt-4 flex-right">
                  {
                    blnPermissionCreateCustomerTransferRequest
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-primary margin-right-8"
                        style={{ width: '20%' }}
                        content={intl.formatMessage({ id: "Submit" })}
                        onClick={() => {
                          let errorStateTemp = {
                            reasonOfChange: false,
                          };
                          let blnErrorFound = false;
                          if (!createCustomerTransferRequestDetails.reasonOfChange) {
                            errorStateTemp.reasonOfChange = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickCreateCustomerTransferRequest();
                          }
                        }}
                        disable={loadingCreate || Boolean(successMessage)}
                        loading={loadingCreate}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})