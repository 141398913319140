import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  checkPermission,
  contructValidationErrorMessage,
  inboundNoticeStatusColor,
  IncludesLocationPathName,
  newSpaceBeforeCapitalLetter
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import moment from "moment";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralInput from "../../app/components/form/GeneralInput";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const GoodsReceiveNoteDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { goodsReceiveNoteStore, inboundNoticeStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addGoodsReceiveNote, updateGoodsReceiveNote, goodsReceiveNoteDetail, setGoodsReceiveNoteDetail, getGoodsReceiveNoteWithId } = goodsReceiveNoteStore;
  const { inboundNoticeList, getInboundNotice, getInboundNoticeWithId } = inboundNoticeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "GoodsReceiveNote" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "GoodsReceiveNote" }), urlPath: RoutesList.goodsReceiveNote }];
  const pageSize = Constants.maxPageSize;
  const [activeTab, setActiveTab] = useState(1);
  const [inboundNoticeModal, setInboundNoticeModal] = useState(false);
  const [loadingInboundNotice, setLoadingInboundNotice] = useState(false); 
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const onChangeReceivedQuantiy = () => {
    if (validation.values.grnDetails) {
      let grnDetailsTemp = _.cloneDeep(validation.values.grnDetails);
      grnDetailsTemp = grnDetailsTemp.map((valueGrnDetailsTemp)=> {
        let receivedQuantityTemp = 0;
        valueGrnDetailsTemp.additionalDetails.map((valueAdditionalDetails) => {
          if (valueAdditionalDetails.receivedQuantity) {
            receivedQuantityTemp += valueAdditionalDetails.receivedQuantity;
          }
        })
        return {
          ...valueGrnDetailsTemp,
          receivedQuantity: receivedQuantityTemp
        }
      })
      validation.setFieldValue("grnDetails", grnDetailsTemp)
    }
  }
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
        type: "label",
        xl: "3",
        inputName: "productName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "InboundRequestQuantity" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "inboundRequestQuantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "TotalReceivedQuantity" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "receivedQuantity",
        inputType: "number",
        disabled: true,
        valueOnTime: true,
      },
      {
        label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "remark",
        inputType: "text",
      },
      {
        label: `${intl.formatMessage({ id: "AdditionalDetails" }).toUpperCase()}`,
        type: "dynamicRowContent",
        xl: "10",
        contentXL: "12",
        inputName: "additionalDetails",
        inputType: "row",
        disabled: true,
        rowValue: [
          {
            label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
            type: "input",
            xl: "3",
            inputName: "supplierReferenceNo",
            inputType: "text",
            disabled: true,
          },
          {
            label: `${intl.formatMessage({ id: "Quantity" }).toUpperCase()}`,
            type: "input",
            xl: "3",
            inputName: "quantity",
            inputType: "number",
            disabled: true,
          },
          {
            label: intl.formatMessage({ id: "ReceivedQuantity" }).toUpperCase(),
            type: "input",
            xl: "3",
            inputName: "receivedQuantity",
            inputType: "number",
            onChangeFunction: onChangeReceivedQuantiy
            // numberMaxFieldName: "quantity"
          },
          {
            label: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
            type: "input",
            xl: "2",
            inputName: "expiryDate",
            inputType: "date",
          },
          {
            label: intl.formatMessage({ id: "IsFreeOfCharge" }).toUpperCase(),
            type: "input",
            xl: "2",
            inputName: "isFOC",
            inputType: "checkbox",
            disabled: true
          },
        ],
        rowButtonTitle: "",
      },
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: goodsReceiveNoteDetail || {
      id: "",
      inboundNoticeId: "",
      inboundNoticeNo: "",
      note: "",
      grnDetails: [],
      grnStatus: "",
      workflowAction: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.deliveryDate = moment(`${valuesTemp.deliveryFullDate} ${valuesTemp.deliveryFullTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
      try {
        if (goodReceiveNoteDetailsValidation()) {
          return;
        }
        
        if (addAction) {
          await addGoodsReceiveNote(valuesTemp);
        } else {
          await updateGoodsReceiveNote(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || (validation.values.grnStatus !== Constants.pending && !addAction);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnProcessingStatus = validation.values.grnStatus == Constants.processing;
  const blnPartialReceivedStatus = validation.values.grnStatus == Constants.partialReceived;
  const blnReceivedStatus = validation.values.grnStatus == Constants.received || validation.values.grnStatus == Constants.partialReceived;
  const blnCancelledStatus = validation.values.grnStatus == Constants.cancelled;
  const blnPermissionUpdateGRN = checkPermission([PermissionConstants.UpdateGRN]);

  //Table Content UI
  const InboundNoticeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "inboundNoticeNo",
      text: intl.formatMessage({ id: "InboundNoticeNo" }).toUpperCase(),
      sort: true,
    },
    {
      dataField: "deliveryDate",
      text: intl.formatMessage({ id: "DeliveryDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.deliveryDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "inboundNoticeStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.inboundNoticeStatus}
          statusColorFunction={inboundNoticeStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({id: "Select"})}/>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    async function fetchGoodsReceiveNoteDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getInboundNotice({ pageNumber: 1, pageSize: pageSize, inboundNoticeStatus: 2 }), //HQ Purchase
      ];
      if (id && !addAction) {
        aryAPI.push(getGoodsReceiveNoteWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setGoodsReceiveNoteDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateGRN], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateGRN], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageGRN], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateGRN){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.goodsReceiveNote}/edit/${id}`)} })
    }

    fetchGoodsReceiveNoteDetailAPI();

    return (() => {
      if (id && !addAction) {
        setGoodsReceiveNoteDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (goodsReceiveNoteDetail) {
        let aryGoodReceiveNoteDetailsTemp = goodsReceiveNoteDetail.grnDetails.map((value) => {
          let additionalDetailsTemp = value.additionalDetails.map((valueAdditionalDetails)=> ({
            ...valueAdditionalDetails,
            expiryDate: moment(valueAdditionalDetails.expiryDate).format(Constants.displayDateFormat),
          }))
          return ({
            ...value,
            inboundRequestQuantity: value.quantity,
            additionalDetails: additionalDetailsTemp
          })
        })
        validation.setValues({
          ...goodsReceiveNoteDetail,
          grnDetails: aryGoodReceiveNoteDetailsTemp
        })
      }
    }
  }, [goodsReceiveNoteDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisableTab = () => {
    if (blnTab1) {
      if (!validation.values.inboundNoticeId) {
        return true;
      }
    }

    return false;
  }

  const goodReceiveNoteDetailsValidation = () => {
    //Partial Received status no need do handling
    if (blnPartialReceivedStatus) {
      return false;
    }

    let blnEmptyQuantity = false;
    let blnEmptyAdditionalQuantity = false;

    validation.values.grnDetails.map((grnDetailsTemp) => {
      if (!grnDetailsTemp.receivedQuantity && grnDetailsTemp.receivedQuantity !== 0) {
        blnEmptyQuantity = true;
      }

      if (grnDetailsTemp.additionalDetails) {
        grnDetailsTemp.additionalDetails.map((additionalDetailsTemp) => {
          if (!additionalDetailsTemp.receivedQuantity && additionalDetailsTemp.receivedQuantity !== 0) {
            blnEmptyAdditionalQuantity = true;
          }
        })
      }
    })

    if (blnEmptyQuantity) {
      setErrorMessage("Amount of Quantity is require full up at least one")
    }
    else if (blnEmptyAdditionalQuantity) {
      setErrorMessage("Amount of Additional Quantity is require full up at least one")
    }
    
    return blnEmptyQuantity || blnEmptyAdditionalQuantity;
  }

  const onChangeInboundNoticeModal = (row) => {
    validation.setFieldValue("inboundNoticeId", row.id);
    validation.setFieldValue("inboundNoticeNo", row.inboundNoticeNo);
    setInboundNoticeModal(false);
    onChangeInboundNotice(row.id);
  }

  const onChangeInboundNotice = async (id: string) => {
    setLoadingInboundNotice(true);
    try {
      let inboundNoticeDetailObject = await getInboundNoticeWithId(id);
      if (inboundNoticeDetailObject) {
        let grnDetailsTemp = inboundNoticeDetailObject.inboundNoticeDetails.map((value) => {
          let additionalDetailsTemp = value.additionalDetails.map((valueAdditionalDetails)=> ({
            ...valueAdditionalDetails,
            receivedQuantity: 0,
            expiryDate: moment(valueAdditionalDetails.expiryDate).format(Constants.displayDateFormat),
          }))
          let inboundNoticeDetailContent: any = {
            productId: value.productId,
            productName: value.productName,
            receivedQuantity: 0,
            allowedQuantity: value.allowedQuantity,
            inboundRequestQuantity: value.quantity,
            remark: value.remark,
            additionalDetails: additionalDetailsTemp,
          }
          if (!addAction) {
            inboundNoticeDetailContent = {
              ...inboundNoticeDetailContent,
              id: value.id
            }
          }
          return inboundNoticeDetailContent;
        })
        validation.setFieldValue("grnDetails", grnDetailsTemp)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
    setLoadingInboundNotice(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}`}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {`${intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}`}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "GoodsReceiveNote" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent >
                          <GeneralInput
                            name={"inboundNoticeNo"}
                            title={intl.formatMessage({ id: "InboundNotice" })}
                            className="mb-3 mt-4"
                            classNameInput="width-100-percentage"
                            type="text"
                            disabled={
                              true
                            }
                            validation={validation}
                            childrenUI={
                              addAction
                              &&
                              <MyButton
                                type="button"
                                content={"Select"}
                                class="btn btn-primary margin-left-8"
                                onClick={() => { setInboundNoticeModal(true) }}
                                loading={loadingInboundNotice}
                                disable={disabledFieldInput || viewAction || loadingInboundNotice || !addAction}
                              />
                            }/>
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title="Note"
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="grnDetails"
                        title=""
                        buttonTitle={""}
                        blnEditable={!viewAction}
                        hideAddButton={true}
                        hideDeleteButton={true}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.grnDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {
                      blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {
                      blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisableTab()
                        }
                        loading={
                          validation.isSubmitting || loadingInboundNotice
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-3" />
            <div className="flex-direction-row">
              {!Boolean(successMessage) && blnTab2 && !blnReceivedStatus && !blnCancelledStatus && !addAction &&
                <MyButton
                  type="submit"
                  class={`btn btn-warning margin-right-8`}
                  content={Constants.cancel}
                  disable={
                    validation.isSubmitting
                  }
                  loading={
                    validation.isSubmitting
                  }
                  onClick={() => {
                    validation.setFieldValue("workflowAction", Constants.cancel)
                  }}
                />
              }
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={blnProcessingStatus || blnPartialReceivedStatus ? false : true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.save)
                }}
                hidePrimaryButton={blnTab1}
                submitButtonTitle={blnPartialReceivedStatus ? Constants.complete : Constants.submit}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", blnPartialReceivedStatus ? Constants.complete : Constants.submit)
                }}
              />
            </div>
          </Form>
        )}
        <SelectWithTableListModal
          blnShow={inboundNoticeModal}
          setModal={setInboundNoticeModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "InboundNotice" }) })}
          options={inboundNoticeList}
          keyField={"id"}
          fieldName={"inboundNoticeId"}
          onSelectFunction={onChangeInboundNoticeModal}
          columns={InboundNoticeColumns}
          validation={validation}/>
      </DetailViewLayout>
    </div >
  );
};

export default observer(GoodsReceiveNoteDetail);
