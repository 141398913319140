import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, getBranchId, IncludesLocationPathName } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../../app/components/layout/DetailViewLayout";
import TableActionButton from "../../../app/components/table/TableActionButton";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import Borderline from "../../../app/components/form/BorderLine";
import BookCancelOutlineIcon from 'mdi-react/BookCancelOutlineIcon';
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { RedemptionCustomerPackage } from "../../../app/models/appolousCustomerPackage";
import AppolousCustomerPackageRedemptionDetailEditModal from "./Modal/AppolousCustomerPackageRedemptionDetailEditModal";

const AppolousCustomerPackageDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { appolousCustomerPackageStore, commonStore } = useStore();
  const { loading, setLoading, successMessage, setSuccessMessage } = commonStore;
  const { appolousCustomerPackageDetail, getAppolousCustomerPackageDetail, setAppolousCustomerPackageDetail, voidAppolousCustomerPackageRedemption, editAppolousCustomerPackageDetail } = appolousCustomerPackageStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "AppolousCustomerPackage" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: appolousCustomerPackageDetail?.customerName || "", urlPath: `${RoutesList.customer}/view/${appolousCustomerPackageDetail?.customerId}`}];
  const [loadingVoidAppolousCustomerPackageRedemption, setLoadingVoidAppolousCustomerPackageRedemption] = useState(false);
  const [voidAppolousCustomerPackageRedemptionModal, setVoidAppolousCustomerPackageRedemptionModal] = useState(false);
  const [selectedVoidAppolousCustomerPackageRedemption, setSelectedVoidAppolousCustomerPackageRedemption] = useState<RedemptionCustomerPackage | undefined>(undefined);
  const blnPermissionUpdateAppolousRedemptionDetail = checkPermission([PermissionConstants.UpdateAppolousRedemptionDetail]);
  const [editAppolousPackageRedemptionDetailModal, setEditAppolousPackageRedemptionDetailModal] = useState(false);
  const [selectedAppolousPackageRedemptionDetail, setSelectedAppolousPackageRedemptionDetail] = useState<RedemptionCustomerPackage | undefined>(undefined);

  const appolousCustomerPackageFullDetail = appolousCustomerPackageDetail || {
    appolousCustomerPackageId: "",
    packageName: "",
    status: "",
    totalSessions: 0,
    redeemedSessions: 0,
    availableSessions: 0,
    expiredDate: "",
    appolousSaleId: "",
    appolousSalePerformaInvoiceNo: "",
    createdAt: "",
    services: [
      {
        appolousCustomerPackageServiceId: "",
        serviceName: "",
        quantity: 0,
        redeemedQuantity: 0
      }
    ],
    redemptions: [
      {
        appolousCustomerPackageRedemptionId: "",
        sessionNo: 0,
        serviceName: "",
        remark: "",
        branchName: "",
        consultantName: "",
        consumableProductNames: [
          ""
        ],
        createdAt: "",
        isVoidAllowed: false,
        isUpdateDetailAllowed: false
      }
    ]
  }

  const AppolousCustomerPackageColumns = [
    {
      dataField: "sessionNo",
      text: intl.formatMessage({ id: "No." }).toUpperCase()
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>  
      <>
        <div className="">
          {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.createdAt).format(Constants.displayTimeFormat)}
        </div>
      </>
    },
    {
      dataField: "serviceName",
      text: intl.formatMessage({ id: "Service" }).toUpperCase()
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "PerformBy" }).toUpperCase(),
    },
    {
      dataField: "isAlternativeService",
      text: intl.formatMessage({ id: "AlternativeService" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isAlternativeService ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.customer}/${RoutesList.appolousCustomerPackageRedemption}/view/${row.appolousCustomerPackageRedemptionId}`}
              type="view"
              disabled={loadingVoidAppolousCustomerPackageRedemption}/>
            {
              blnPermissionUpdateAppolousRedemptionDetail
              &&
              row.isUpdateDetailAllowed
              &&
              <TableActionButton 
                onClick={()=> {
                  setSelectedAppolousPackageRedemptionDetail(row);
                  setEditAppolousPackageRedemptionDetailModal(true);
                }}
                type="edit"
                disabled={loadingVoidAppolousCustomerPackageRedemption}/>
            }
            {
              row.isVoidAllowed
              &&
              <TableActionButton 
                type="cancel"
                onClick={()=> {
                  setSelectedVoidAppolousCustomerPackageRedemption(row);
                  setVoidAppolousCustomerPackageRedemptionModal(true);
                }}
                iconReplaceUI={<BookCancelOutlineIcon size={18} id="voidAppolousCustomerPackageTooltip" />}
                iconId="voidAppolousCustomerPackageTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "Void" })}`}
                target={Constants.blank}
                disabled={loadingVoidAppolousCustomerPackageRedemption}/>
            }
          </div>
        </>
      ),
    }
  ];

  async function fetchAppolousCustomerPackageDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getAppolousCustomerPackageDetail({appolousCustomerPackageId: id, isIncludeRedemption: true, branchId: getBranchId()}),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  async function onVoidAppolousCustomerPackageRedemptionAPI() {
    setLoadingVoidAppolousCustomerPackageRedemption(true);
    let resultVoidAppolousCustomerPackageRedemption = await voidAppolousCustomerPackageRedemption(selectedVoidAppolousCustomerPackageRedemption?.appolousCustomerPackageRedemptionId || "");
    if (resultVoidAppolousCustomerPackageRedemption.status === Constants.success) {
      setLoadingVoidAppolousCustomerPackageRedemption(false);
      setVoidAppolousCustomerPackageRedemptionModal(false);
      fetchAppolousCustomerPackageDetailAPI();
      return;
    }
    
    setLoadingVoidAppolousCustomerPackageRedemption(false);
    setVoidAppolousCustomerPackageRedemptionModal(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageAppolousRedemption], true)) {
      return;
    }

    fetchAppolousCustomerPackageDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setAppolousCustomerPackageDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    if (successMessage) {
      if (editAppolousPackageRedemptionDetailModal) {
        fetchAppolousCustomerPackageDetailAPI(); 
      }
      setTimeout(() => {
        setSuccessMessage("");
        if (editAppolousPackageRedemptionDetailModal) {
          setEditAppolousPackageRedemptionDetailModal(false);
        }
      }, Constants.dismissSuccessMessage);
    }
  }, [successMessage])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "AppolousCustomerPackage" })}
                      h4Title />   
                    <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                      {`${appolousCustomerPackageFullDetail.packageName}`}
                      <span style={{fontWeight: 'normal'}}>
                        {` (${appolousCustomerPackageFullDetail.totalSessions} ${intl.formatMessage({ id: "Sessions" })})`}
                      </span>
                    </h3>
                    <div className="flex-align-items-center flex-flow-wrap">
                      {
                        appolousCustomerPackageFullDetail.services.map((valueServiceTemp, indexServiceTemp)=> (
                          <GeneralStatus
                            key={`services_status_${indexServiceTemp}`}
                            className={`mt-3 ${indexServiceTemp > 0 ? "margin-left-8" : ""}`}
                            status={valueServiceTemp.serviceName}
                            statusBackgroundColor="#047bf8"
                            blnDisableCenter={true}/>
                        ))
                      }
                    </div>
                    {
                      Boolean(successMessage) 
                      ?
                      <Loading className="mt-4"/>
                      :
                      <div className="margin-top-32">
                        <PaginationTableWithoutApi 
                          title={intl.formatMessage({ id: "Redemption" })}
                          headerClassName={"table-light"}
                          options={appolousCustomerPackageFullDetail.redemptions}
                          columns={AppolousCustomerPackageColumns}
                          pageSize={Constants.maxPageSize}
                          keyField={"appolousCustomerPackageRedemptionId"}/>
                      </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
      {
        voidAppolousCustomerPackageRedemptionModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "VoidAppolousCustomerPackageRedemptionAlert" })}`}
          subTitle={`(${selectedVoidAppolousCustomerPackageRedemption?.serviceName}) ?`}
          primaryActionTitle={`Yes`}
          showPrompt={voidAppolousCustomerPackageRedemptionModal}
          setShowPrompt={setVoidAppolousCustomerPackageRedemptionModal}
          onPrimaryClick={()=> {
            onVoidAppolousCustomerPackageRedemptionAPI()
          }}
          onCancelClick={()=> {
            setSelectedVoidAppolousCustomerPackageRedemption(undefined);
          }}
          localLoading={loadingVoidAppolousCustomerPackageRedemption}
        />
      }
      {
        editAppolousPackageRedemptionDetailModal
        &&
        <AppolousCustomerPackageRedemptionDetailEditModal
          blnShow={editAppolousPackageRedemptionDetailModal}
          setModal={setEditAppolousPackageRedemptionDetailModal}
          selectedAppolousCustomerPackageRedeemDetail={selectedAppolousPackageRedemptionDetail}
          setSelectedAppolousCustomerPackageRedeemDetail={setSelectedAppolousPackageRedemptionDetail}
          editFunction={editAppolousCustomerPackageDetail}
        />
      }
    </div>
  );
};

export default observer(AppolousCustomerPackageDetail);
