import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, firstDayOfMonthToCurrentDay } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from "react-router-dom"

const CustomerTurnedUp = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { customerTurnedUpList, getCustomerTurnedUpList, setCustomerTurnedUpList, exportCustomerTurnedUp } = reportMarcomStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;


  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageLead = checkPermission([PermissionConstants.ManageLead]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];


  useEffect(() => {
    async function fetchCustomerTurnedUpListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      await Promise.all(aryAPI);

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchCustomerTurnedUpListAPI();

    return (() => {
      setCustomerTurnedUpList([]);
    })
  }, []);

  const fetchCustomerTurnedUpListWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportCustomerTurnedUp({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    else {
      setCurrentPage(1);
      await getCustomerTurnedUpList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const CustomerTurnedUpColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerNo || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "customerMobileNo",
      text: intl.formatMessage({ id: "CustomerMobileNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.customerMobileNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerMediaSource",
      text: intl.formatMessage({ id: "CustomerMediaSource" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.customerMediaSource || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "joinedDate",
      text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.joinedDate !== Constants.invalidDateTime ? moment(row.joinedDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.joinedDate !== Constants.invalidDateTime ? moment(row.joinedDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "leadNo",
      text: intl.formatMessage({ id: "LeadNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead && row.leadId
            ?
            <Link to={row.leadId !== null ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.leadNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.leadNo || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "leadName",
      text: intl.formatMessage({ id: "LeadName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead && row.leadId
            ?
            <Link to={row.leadId ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.leadName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.leadName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "leadMobileNo",
      text: intl.formatMessage({ id: "LeadMobileNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.leadMobileNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "leadMediaSource",
      text: intl.formatMessage({ id: "LeadMediaSource" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.leadMediaSource || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "lastAppointmentDate",
      text: intl.formatMessage({ id: "LastAppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${(row.lastAppointmentDate !== Constants.invalidDateTime && row.lastAppointmentDate !== null) ? moment(row.lastAppointmentDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {(row.lastAppointmentDate !== Constants.invalidDateTime && row.lastAppointmentDate !== null) ? moment(row.lastAppointmentDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "leadAppointedBy",
      text: intl.formatMessage({ id: "LeadAppointedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.leadAppointedBy || Constants.emptyData}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerTurnedUp" }) })}
        title={intl.formatMessage({ id: "CustomerTurnedUp" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchCustomerTurnedUpListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Customer-Turned-Up.aspx`}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "JoinedDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              initialValue={selectedDateRange}
              validationRequired={true} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchCustomerTurnedUpListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerTurnedUpColumns}
              keyField={"randomId"}
              data={customerTurnedUpList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerTurnedUp" }) })}
              objSorted={defaultSorted}
              requestAPI={getCustomerTurnedUpList}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CustomerTurnedUp);
