import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import classNames from "classnames";
import { useIntl } from "react-intl";

import { checkPermission, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../app/common/function/function";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Constants } from "../../app/constants/Constants";
import Loading from "../../app/components/loading/Loading";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { RoutesList } from "../../app/constants/RoutesList";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import TableActionButton from "../../app/components/table/TableActionButton";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WalletGiftcardIcon from 'mdi-react/WalletGiftcardIcon';
import CreditCardClockIcon from 'mdi-react/CreditCardClockIcon';
import CashLockIcon from 'mdi-react/CashLockIcon';
import NotebookCheckIcon from 'mdi-react/NotebookCheckIcon';
import ClipboardTextHistoryIcon from 'mdi-react/ClipboardTextHistoryIcon';
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import CustomerRedemptionDetailModal from "./Modal/CustomerRedemptionDetailModal";
import { CreditHistoryObject, DeliveryOrderObject } from "../../app/models/contractLiability";
import CreditHistoriesDetailModal from "./Modal/CreditHistoriesDetailModal";

const ContractLiability = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { contractLiabilityStore, commonStore } = useStore();
  const { loading, setLoading, windowSize } = commonStore;
  const { contractLiabilityDetail, getContractLiabilityDetail, setContractLiabilityDetail } = contractLiabilityStore;
  const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "CustomerSalesOrder" }).toUpperCase(), intl.formatMessage({ id: "PaymentHistories" }).toUpperCase()]);
  const [activeTab, setActiveTab] = useState("1");
  const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "ContractLiability" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${contractLiabilityDetail?.customerId}`}, { title: intl.formatMessage({ id: "ContractLiabilitySiteMap" }), urlPath: `${RoutesList.contractLiabilitySiteMap}/${contractLiabilityDetail?.customerId}`}];
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer])
  const blnPermissionViewReceipt = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const [modalCustomerRedemptionDetail, setModalCustomerRedemptionDetail] = useState(false);
  const [modalCreditHistoriesDetail, setModalCreditHistoriesDetail] = useState(false);
  const [selectedCustomerRedemptionDetail, setSelectedCustomerRedemptionDetail] = useState<DeliveryOrderObject | undefined>(undefined);
  const [selectedCreditHistoriesDetail, setSelectedCreditHistoriesDetail] = useState<CreditHistoryObject | undefined>(undefined);

  const SalesOrderContractLiabilityColumns = [
    {
      dataField: "salesDocumentNo",
      text: intl.formatMessage({ id: "CustomerSalesDocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <div>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={`/${row.isAppolous ? `${RoutesList.customer}/${RoutesList.appolousCustomerSales}` : RoutesList.customerSalesOrder}/view/${row.salesDocumentId}`} target={Constants.blank} rel="noopener noreferrer">
            <p className="mb-1">{row.salesDocumentNo}</p>
          </Link>
          :
          <p className="mb-1">{row.salesDocumentNo}</p>
        }
      </div>,
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      formatter: (cellContent, row) =>  
      <>
        <div className="">
          {`${moment(row.submissionDate).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.submissionDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    },
    {
      dataField: "entitleAmount",
      text: intl.formatMessage({ id: "EntitledAmount" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{returnPriceWithCurrency(row.entitleAmount)}</div>,
    },
    {
      dataField: "usedAmount",
      text: intl.formatMessage({ id: "UsedAmount" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{returnPriceWithCurrency(row.usedAmount)}</div>,
    },
    {
      dataField: "isAppolous",
      text: intl.formatMessage({ id: "Appolous" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isAppolous ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    }
    // {
    //   dataField: "productOnlyAmount",
    //   text: intl.formatMessage({ id: "ProductOnlyAmount" }).toUpperCase(),
    //   formatter: (cellContent, row) => <div>{returnPriceWithCurrency(row.productOnlyAmount)}</div>,
    // }
  ];

  const aryAmount = [
    { title: intl.formatMessage({ id: "Amount" }), iconClass: "mdi-cash-multiple", description: returnPriceWithCurrency(contractLiabilityDetail?.amount || 0.00) },
    { title: intl.formatMessage({ id: "Balance" }), iconClass: "mdi-cash-minus", description: returnPriceWithCurrency(contractLiabilityDetail?.balance || 0.00) },
    { title: intl.formatMessage({ id: "BalanceAfterExpired" }), iconClass: "mdi-cash-remove", description: returnPriceWithCurrency(contractLiabilityDetail?.balanceAfterExpired || 0.00)}
  ];

  const aryCredit = [
    {
      title: contractLiabilityDetail?.exchangedToCredit || 0.00,
      bgColor: "bg-info",
      iconClass: "mdi-cash-refund",
      description: intl.formatMessage({ id: "ExchangedToCredit" }),
    },
    {
      title: contractLiabilityDetail?.usedCredit || 0.00,
      bgColor: "bg-warning",
      iconClass: "mdi-cash-lock",
      description: intl.formatMessage({ id: "UsedCredit" }),
    },
    {
      title: contractLiabilityDetail?.exchangedToCash || 0.00,
      bgColor: "bg-pink",
      iconClass: "mdi-cash-refund",
      description: intl.formatMessage({ id: "ExchangedToCash" }),
    },
  ]

  const PaymentHistoriesContractLiabilityColumns = [
    {
      dataField: "paymentReceiptNo",
      text: intl.formatMessage({ id: "ReceiptNo" }).toUpperCase(),
      formatter: (cellContent, row) => <div>
        {
          blnPermissionViewReceipt
          ?
          <Link to={`/${RoutesList.receipt}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer">
            <p className="mb-1">{row.paymentReceiptNo}</p>
          </Link>
          :
          <p className="mb-1">{row.paymentReceiptNo}</p>
        }
      </div>,
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "PaymentDate" }).toUpperCase(),
      formatter: (cellContent, row) =>  
      <>
        <div className="">
          {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.paymentDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{`${row.paymentMethodName} `} {row.cardType ? `(${row.cardType} | ${row.optionName} | ${row.last4CardDigitNo})` : row.optionName ? `(${row.optionName})` : ""}</div>,
    },
    {
      dataField: "transactionNo",
      text: `${intl.formatMessage({ id: "TransactionNo" })}/${intl.formatMessage({ id: "ApprovalCode" })}`.toUpperCase(),
      formatter: (cellContent, row) => <div>{row.transactionNo || Constants.emptyData}</div>,
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount)}
        </div>
      ),
    },
  ];

  useEffect(()=> {
    if (!checkPermission([PermissionConstants.ManageContractLiability], true)) {
      return;
    }

    async function fetchContractLiabilitySiteMap() {
      setLoading(true);
      await getContractLiabilityDetail(id);
      setLoading(false);
    }

    fetchContractLiabilitySiteMap();

    return(()=> {
      setContractLiabilityDetail(undefined);
    })
  }, [])
  
  const displayRowData = (label, value, iconName?:any) => {
    return (
      <Col sm="4" xs="6">
        <div className="mt-2 font-size-15">
          <h5 style={{fontSize: '13px'}}>
            {iconName && <i className={`${iconName} me-1 text-primary`}/>} {label}
          </h5>
          <p className="mt-0 mb-0" style={{fontSize: '15px', fontWeight: '500'}}>{value}</p>
        </div>
      </Col>
    )
  }

  const returnTabBarIcon = (valueTab: string) => {
    let iconTabBar: any = null;
    if (valueTab === intl.formatMessage({ id: "CustomerSalesOrder" }).toUpperCase()) {
      iconTabBar = <NotebookCheckIcon size={Constants.menuIconSize} />;
    }
    else if (valueTab === intl.formatMessage({ id: "PaymentHistories" }).toUpperCase()) {
      iconTabBar = <ClipboardTextHistoryIcon size={Constants.menuIconSize} />
    }
    
    return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
  }

  const returnTimelineIcon = (valueName: string) => {
    let valueNameTemp = valueName.toLowerCase();
    let iconTemp : any = "";
    if (valueNameTemp.includes("customer redemptions")) {
      iconTemp = <WalletGiftcardIcon size={24} className='text-primary align-middle me-2'/>;
    }
    else if (valueNameTemp.includes("credit histories")) {
      iconTemp = <CreditCardClockIcon size={24} className='text-success align-middle me-2'/>;
    }
    else if (valueNameTemp.includes("posted transaction")){
      iconTemp = <CashLockIcon size={24} className='text-pink align-middle me-2'/>;
    }
  
    return iconTemp;
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {
          loading
            ?
            <Loading />
            :
            !contractLiabilityDetail
            ?
            <div/>
            :
            <Container fluid>
              <Row className="margin-bottom-28">
                <Col xl="4">
                  <Card style={{marginBottom: "4px"}}>
                    <CardBody>
                      <div>
                        <div className="mb-3 me-3">
                          <i className="mdi mdi-account-circle text-primary h1"></i>
                        </div>
                        <div>
                          {
                            blnPermissionViewCustomer
                            ?
                            <Link to={`/${RoutesList.customer}/view/${contractLiabilityDetail.customerId}`} target={Constants.blank} rel="noopener noreferrer">
                              <p style={{fontSize: '1.015625rem', fontWeight: "500px"}} className="mb-1">{contractLiabilityDetail.customerName}</p>
                            </Link>
                            :
                            <p style={{fontSize: '1.015625rem', fontWeight: "500px"}} className="mb-1">{contractLiabilityDetail.customerName}</p>
                          }
                          <div>
                            <p className="text-muted mb-2 mt-3">{intl.formatMessage({ id: "Branch" })}</p>
                            <h6>{contractLiabilityDetail.customerSalesOrderBranch}</h6>
                          </div>
                          <div>
                            <p className="text-muted mb-2 mt-2">{intl.formatMessage({ id: "CustomerNo" })}</p>
                            <h6>{contractLiabilityDetail.customerNo}</h6>
                          </div>
                          <div>
                            <p className="text-muted mb-2">{intl.formatMessage({ id: "Mobile" })}</p>
                            <div className="flex-align-items-center-without-height mb-2">
                              <h6 className='mb-0'>{contractLiabilityDetail.mobileNo}</h6>
                              <div className='margin-left-4'>
                                <TableActionButton
                                  type="whatsapp"
                                  onClick={() => window.open(`https://web.whatsapp.com/send?phone=${contractLiabilityDetail.mobileNo}`)}/>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="text-muted mb-2">{intl.formatMessage({ id: "IdentityNo" })}</p>
                            <h6>{`${contractLiabilityDetail.identityNo} ${contractLiabilityDetail.identityType ? contractLiabilityDetail.identityType === Constants.identityCard ? `(NRIC)` : `(P)` : ""}`}</h6>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">
                        {intl.formatMessage({ id: "AdditionalInformation" })}
                      </CardTitle>
                      <div className="table-responsive mb-4">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">{intl.formatMessage({ id: "CustomerSalesOrder" })} :</th>
                              <td>
                                {
                                  blnPermissionManageCustomerSalesOrder
                                  ?
                                  <Link to={`/${RoutesList.customerSalesOrder}/view/${contractLiabilityDetail.customerSalesOrderId}`} target={Constants.blank} rel="noopener noreferrer">
                                    <p className="mb-1">{contractLiabilityDetail.customerSalesOrderNo}</p>
                                  </Link>
                                  :
                                  <p className="mb-1">{contractLiabilityDetail.customerSalesOrderNo}</p>
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{intl.formatMessage({ id: "PaymentDate" })} :</th>
                              <td>{`${moment(contractLiabilityDetail.paymentDate).format(Constants.displayDateAndTimeFormat)}`}</td>
                            </tr>
                            <tr>
                              <th scope="row">{intl.formatMessage({ id: "ExpiredDate" })}  :</th>
                              <td>{`${moment(contractLiabilityDetail.expiredDate).format(Constants.displayDateAndTimeFormat)}`}</td>
                            </tr>
                            <tr>
                              <th scope="row">{intl.formatMessage({ id: "PostedAmount" })} :</th>
                              <td>{returnPriceWithCurrency(contractLiabilityDetail.postedAmount)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="8">
                  <Row>
                    {aryAmount.map((valueAmount, key) => (
                      <Col md="4" key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  {valueAmount.title}
                                </p>
                                <h4 className="mb-0">{valueAmount.description}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "mdi " + valueAmount.iconClass + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Row className="">
                    {aryCredit.map((valueCredit, index) => (
                      <Col xl="4" key={"_li_" + index} className={`${Constants.innerWidthCompare > windowSize.innerWidth && ((aryCredit.length-1) !== index) ? "mb-4" : ""}`}>
                        <div className="social-source text-center" style={{backgroundColor: 'white', padding: "16px", borderRadius: '4px'}}>
                          <div className="avatar-xs mx-auto mb-3">
                            <span
                              className={
                                "avatar-title rounded-circle " +
                                valueCredit.bgColor +
                                " font-size-16"
                              }
                            >
                              <i
                                className={"mdi " + valueCredit.iconClass + " text-white"}
                              ></i>
                            </span>
                          </div>
                          <h5 className="font-size-15">{valueCredit.title}</h5>
                          <p className="text-muted mb-0">{valueCredit.description}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <div className="standard-layout mt-4" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    <Nav className="nav nav-tabs nav-tabs-custom">
                      {tabBar.map((value, indexTab) => (
                        <NavItem key={`tabkey_${indexTab}`}>
                          <NavLink
                            disabled={indexTab == 1 && (false)}
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active:
                                activeTab ===
                                String(indexTab + 1),
                            })}
                            onClick={() => {
                              toggle(String(indexTab + 1));
                            }}
                          >
                            {/* {value} */}
                            {returnTabBarIcon(value)}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab} className="margin-top-24">
                      <TabPane tabId="1">
                        <PaginationTableWithoutApi 
                          title={intl.formatMessage({ id: "CustomerSalesOrder" })}
                          options={contractLiabilityDetail.salesDocuments}
                          columns={SalesOrderContractLiabilityColumns}
                          pageSize={5}
                          keyField={"salesDocumentNo"}/>
                      </TabPane>
                      <TabPane tabId="2">
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "PaymentHistories" })}
                        options={contractLiabilityDetail.paymentHistories}
                        columns={PaymentHistoriesContractLiabilityColumns}
                        pageSize={3}
                        keyField={"id"}/>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
              <Row>
              <Col xl="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">
                        {intl.formatMessage({ id: "RedemptionHistories" })}
                      </CardTitle>
                      {
                        contractLiabilityDetail.deliveryOrders.length > 0
                        ?
                        <VerticalTimeline layout="1-column-left" lineColor={'#e8e8ed80'} animate={false}>
                          {
                            contractLiabilityDetail.deliveryOrders.map((valueDeliveryOrderContractLiability, key) => (
                              <VerticalTimelineElement
                                key={`customer-deliver-order-${key}`}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#e8e8ed80', color: 'black' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date={moment(valueDeliveryOrderContractLiability.performDateTime).format(Constants.displayDateAndTimeFormat)}
                                iconStyle={{ backgroundColor: 'lightgrey', color: '#fff' }}
                                icon={returnTimelineIcon(intl.formatMessage({ id: "CustomerRedemptions" }))}>
                                <p style={{fontSize: '15px', fontWeight: '500', marginTop: '0px'}}>
                                  {returnPriceWithCurrency(valueDeliveryOrderContractLiability.usedAmount)} 
                                </p>
                                <div
                                  style={{cursor: 'pointer'}}
                                  onClick={()=> {
                                    setModalCustomerRedemptionDetail(true)
                                    setSelectedCustomerRedemptionDetail({
                                      ...valueDeliveryOrderContractLiability,
                                      performDateTime: moment(valueDeliveryOrderContractLiability.performDateTime).format(Constants.displayDateAndTimeFormat),
                                      deliverOrderAmount: returnPriceWithCurrency(valueDeliveryOrderContractLiability.deliverOrderAmount),
                                      usedAmount: returnPriceWithCurrency(valueDeliveryOrderContractLiability.usedAmount),
                                      productOnlyAmount: returnPriceWithCurrency(valueDeliveryOrderContractLiability.productOnlyAmount),
                                    })
                                  }}>
                                  <p style={{fontSize: '13px'}} className="text-primary mt-0">
                                    {`${valueDeliveryOrderContractLiability.customerDeliverOrderNo}`}
                                  </p>
                                </div>
                              </VerticalTimelineElement>
                            ))
                          }
                        </VerticalTimeline>
                        :
                        <div> 
                          {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerRedemptions" })})}
                        </div>
                      }
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="3">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">
                        {intl.formatMessage({ id: "CreditHistories" })}
                      </CardTitle>
                      {
                        contractLiabilityDetail.creditHistories.length > 0
                        ?
                        <VerticalTimeline layout="1-column-left" lineColor={'#e8e8ed80'} animate={false}>
                          {
                            contractLiabilityDetail.creditHistories.map((valueCreditHistories, key) => (
                              <VerticalTimelineElement
                                key={`credit-histories-${key}`}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#e8e8ed80', color: 'black' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date={moment(valueCreditHistories.actionDate).format(Constants.displayDateAndTimeFormat)}
                                iconStyle={{ backgroundColor: 'lightgrey', color: '#fff' }}
                                icon={returnTimelineIcon(intl.formatMessage({ id: "CreditHistories" }))}
                              >
                                <p style={{fontSize: '15px', fontWeight: '500', marginTop: '0px'}}>
                                  {returnPriceWithCurrency(valueCreditHistories.occupiedCredits)}
                                </p>
                                <div
                                  style={{cursor: 'pointer'}}
                                  onClick={()=> {
                                    setModalCreditHistoriesDetail(true)
                                    setSelectedCreditHistoriesDetail({
                                      ...valueCreditHistories,
                                      actionDate: moment(valueCreditHistories.actionDate).format(Constants.displayDateAndTimeFormat)
                                    })
                                  }}>
                                  <p style={{fontSize: '13px'}} className="text-primary mt-0">
                                    {`${newSpaceBeforeCapitalLetter(valueCreditHistories.entityType)}`}
                                  </p>
                                </div>
                              </VerticalTimelineElement>
                            ))
                          }
                        </VerticalTimeline>
                        :
                        <div> 
                          {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditHistories" })})}
                        </div>
                      }
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="5">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">
                        {intl.formatMessage({ id: "PostedTransaction" })}
                      </CardTitle>
                      {
                        contractLiabilityDetail.postedTransactions.length > 0
                        ?
                        <VerticalTimeline layout="1-column-left" lineColor={'#e8e8ed80'} animate={false}>
                          {
                            contractLiabilityDetail.postedTransactions.map((valuePostedTransaction, key) => (
                              <VerticalTimelineElement
                                key={`posted-transaction-${key}`}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#e8e8ed80', color: 'black' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date={moment(valuePostedTransaction.postedDate).format(Constants.displayDateAndTimeFormat)}
                                iconStyle={{ backgroundColor: 'lightgrey', color: '#fff' }}
                                icon={returnTimelineIcon(intl.formatMessage({ id: "PostedTransaction" }))}
                              >
                                <Row >
                                  <Col xs="8">
                                  <h5 className="font-size-14">
                                    <span className="font-size-14">{`${newSpaceBeforeCapitalLetter(valuePostedTransaction.postedType)} `}</span>
                                  </h5>
                                  </Col>
                                  <Col xs="4">
                                    <span className={`badge ${valuePostedTransaction.isAfterExpired ? `bg-danger` : `bg-info`} align-bottom font-size-14`}>{valuePostedTransaction.isAfterExpired ? intl.formatMessage({ id: "Expired" }) : intl.formatMessage({ id: "Active" })}</span>
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  {displayRowData(intl.formatMessage({ id: "Amount" }), returnPriceWithCurrency(valuePostedTransaction.postedAmount), "bx bx-dollar")}
                                  {displayRowData(intl.formatMessage({ id: "Cost" }), returnPriceWithCurrency(valuePostedTransaction.postedCost), "bx bxs-purchase-tag-alt")}
                                  {displayRowData(intl.formatMessage({ id: "Category" }), valuePostedTransaction.postedSalesCategory)}
                                </Row>
                              </VerticalTimelineElement>
                            ))
                          }
                        </VerticalTimeline>
                        :
                        <div> 
                          {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PostedTransaction" })})}
                        </div>
                      }
                      </CardBody>
                    </Card>
                </Col>
              </Row>
            </Container>
        }
        {
          modalCustomerRedemptionDetail
          &&
          <CustomerRedemptionDetailModal
            blnShow={modalCustomerRedemptionDetail}
            setModal={setModalCustomerRedemptionDetail}
            selectedCustomerRedemptionDetail={selectedCustomerRedemptionDetail}
            setSelectedCustomerRedemptionDetail={setSelectedCustomerRedemptionDetail}/>
        }
        {
          modalCreditHistoriesDetail
          &&
          <CreditHistoriesDetailModal
            blnShow={modalCreditHistoriesDetail}
            setModal={setModalCreditHistoriesDetail}
            selectedCreditHistoriesDetail={selectedCreditHistoriesDetail}
            setSelectedCreditHistoriesDetail={setSelectedCreditHistoriesDetail}/>
        }
      </DetailViewLayout>
    </div>
  )
}

export default observer(ContractLiability);