import { PaginationRequestBody } from "../models/pagination";
import { BatchJobListObject, BatchJobAddObject, BatchJobUpdateObject, BatchJobDetailObject, BatchJobHistoryListObject, BatchJobHistoryDetailObject } from "../models/batchJob";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class batchJobStore {
  batchJobList: BatchJobListObject[] = [];
  batchJobDetail: BatchJobDetailObject | undefined = undefined;
  batchJobPaginationParams: PaginationRequestBody | undefined = undefined;
  batchJobHistoryList: BatchJobHistoryListObject[] = [];
  batchJobHistoryDetail: BatchJobHistoryDetailObject | undefined = undefined;
  batchJobHistoryTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.batchJobList = [];
    this.batchJobDetail = undefined;
    this.batchJobPaginationParams= undefined;
    this.batchJobHistoryList = [];
    this.batchJobHistoryDetail = undefined;
    this.batchJobHistoryTotalItems = 0;
  }

  setBatchJobDetail = (batchJobDetail: BatchJobDetailObject | undefined) => {
    this.batchJobDetail = batchJobDetail;
  }
  
  setBatchJobPaginationParams = (batchJobPaginationParams: PaginationRequestBody | undefined) => {
    this.batchJobPaginationParams = batchJobPaginationParams;
  }

  getBatchJob = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setBatchJobPaginationParams(PaginationRequestBody);
    try{
      const resultBatchJob = await agent.BatchJob.batchJobList(PaginationRequestBody);
      runInAction(() => {
        this.batchJobList = resultBatchJob.data;
        store.commonStore.setTotalItem(resultBatchJob.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.batchJobList = [];
    }
  }

  getBatchJobWithId = async (id: string) => {
    try{
      const resultBatchJobDetail = await agent.BatchJob.batchJobDetail(id);
      runInAction(() => {
        this.batchJobDetail = resultBatchJobDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.batchJobDetail = undefined;
    }
  }

  addBatchJob = async (batchJobRequestBody: BatchJobAddObject) => {
    try{
      await agent.BatchJob.addBatchJob(batchJobRequestBody);
      store.commonStore.setSuccessMessage(`BatchJobAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateBatchJob = async (batchJobRequestBody: BatchJobUpdateObject) => {
    try{
      await agent.BatchJob.updateBatchJob(batchJobRequestBody);
      store.commonStore.setSuccessMessage(`BatchJobUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteBatchJob  = async (id: string, name: string) => {
    try {
      await agent.BatchJob.deleteBatchJob(id);
      store.commonStore.setSuccessMessage(`BatchJobDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getBatchClassDropdownList = async () => {
    try{
      let resultBatchJobDetail = await agent.BatchJob.batchClassDropdownList();
      runInAction(() => {
        
      });
      return Promise.resolve(resultBatchJobDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getBatchJobHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    //this.setBatchJobPaginationParams(PaginationRequestBody);
    try{
      const resultBatchJobHistory = await agent.BatchJob.batchJobHistoryList(PaginationRequestBody);
      runInAction(() => {
        resultBatchJobHistory.data = resultBatchJobHistory.data.map((valueResultBatchJobHistoryTemp, index)=> ({
          ...valueResultBatchJobHistoryTemp,
          randomId: `${valueResultBatchJobHistoryTemp.batchJobId}_${index}`
        }))
        this.batchJobHistoryList = resultBatchJobHistory.data;
        this.batchJobHistoryTotalItems = resultBatchJobHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.batchJobHistoryList = [];
      this.batchJobHistoryTotalItems = 0;
    }
  }

  getBatchJobHistoryWithId = async (id: string) => {
    try{
      const resultBatchJobHistoryDetail = await agent.BatchJob.batchJobHistoryDetail(id);
      runInAction(() => {
        this.batchJobHistoryDetail = resultBatchJobHistoryDetail;
      });
      return Promise.resolve(resultBatchJobHistoryDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.batchJobHistoryDetail = undefined;
    }
  }

  refreshBatchJob = async (id: string) => {
    try {
      await agent.BatchJob.refreshBatchJob(id);
      store.commonStore.setSuccessMessage(`RefreshBatchSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}