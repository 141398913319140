import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import { VirtualWarehouseCreditExchangeListObject } from "../../app/models/virtualWarehouseCreditExchange";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { checkPermission, newSpaceBeforeCapitalLetter, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, virtualWarehouseCreditExchangeStatusColor, returnPriceWithCurrency } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";

const VirtualWarehouseCreditExchange = () => {
  const intl = useIntl();
  //Use Store
  const { virtualWarehouseCreditExchangeStore, staticReferenceStore, branchStore, commonStore, customerStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { virtualWarehouseCreditExchangeList, virtualWarehouseCreditExchangePaginationParams, getVirtualWarehouseCreditExchange, setVirtualWarehouseCreditExchangePaginationParams } = virtualWarehouseCreditExchangeStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { virtualWarehouseCreditExchangeStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<VirtualWarehouseCreditExchangeListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: "" }];
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedVirtualWarehouseCreditExchangeStatus, setSelectedVirtualWarehouseCreditExchangeStatus] = useState<string>(AllStatus);
  const [selectedVirtualWarehouseCreditExchangeStatusId, setSelectedVirtualWarehouseCreditExchangeStatusId] = useState<number | string | undefined>(" ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [virtualWarehouseCreditExchangeStatusList, setVirtualWarehouseCreditExchangeStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionManageVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange]);
  const blnPermissionRequestVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.RequestVirtualWarehouseCreditExchange]);
  const blnPermissionReviewVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.ReviewVirtualWarehouseCreditExchange]);
  const blnPermissionUpdateVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.UpdateVirtualWarehouseCreditExchange]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [triggerSearch, setTriggerSearch] = useState(0);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      virtualWarehouseCreditExchangeNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let virtualWarehouseCreditExchangeRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    branchId: selectedCustomerId ? "" : getBranchId(),
    virtualWarehouseCreditExchangeStatus: selectedVirtualWarehouseCreditExchangeStatusId,
    virtualWarehouseCreditExchangeStatusName: selectedVirtualWarehouseCreditExchangeStatus,
    virtualWarehouseCreditExchangeNo: validation.values.virtualWarehouseCreditExchangeNo
  }

  useEffect(() => {
    async function fetchVirtualWarehouseCreditExchangeListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.virtualWarehouseCreditExchange)
      if (blnBackNavigation) {
        if (virtualWarehouseCreditExchangePaginationParams) {
          setCurrentPage(virtualWarehouseCreditExchangePaginationParams.pageNumber)
          setSelectedVirtualWarehouseCreditExchangeStatus(virtualWarehouseCreditExchangePaginationParams.virtualWarehouseCreditExchangeStatusName || "");
          setSelectedVirtualWarehouseCreditExchangeStatusId(virtualWarehouseCreditExchangePaginationParams.virtualWarehouseCreditExchangeStatus);
          setSelectedCustomerId(virtualWarehouseCreditExchangePaginationParams.customerId || "");
          setSelectedCustomer(virtualWarehouseCreditExchangePaginationParams.customerName || "");

          validation.setValues((values) => ({
            ...values,
            virtualWarehouseCreditExchangeNo: virtualWarehouseCreditExchangePaginationParams.virtualWarehouseCreditExchangeNo || "",
          }))
          virtualWarehouseCreditExchangeRequestBody = virtualWarehouseCreditExchangePaginationParams;
        }
      }
      else {
        setVirtualWarehouseCreditExchangePaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchVirtualWarehouseCreditExchangeAPI(false),
        getStaticReferenceWithType(Constants.virtualWarehouseCreditExchangeStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setVirtualWarehouseCreditExchangeStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange], true)) {
      return;
    }

    fetchVirtualWarehouseCreditExchangeListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.virtualWarehouseCreditExchange)
        if (!blnSetParams) {
          setVirtualWarehouseCreditExchangePaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedVirtualWarehouseCreditExchangeStatusId || selectedVirtualWarehouseCreditExchangeStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchVirtualWarehouseCreditExchangeAPI(true);
    }
  }, [selectedVirtualWarehouseCreditExchangeStatusId, selectedCustomerId, triggerSearch]);

  async function fetchVirtualWarehouseCreditExchangeAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getVirtualWarehouseCreditExchange({
      ...virtualWarehouseCreditExchangeRequestBody,
      customerId: customerId === Constants.emptyData ? "" : selectedCustomerId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const VirtualWarehouseCreditExchangeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "virtualWarehouseCreditExchangeNo",
      text: `${intl.formatMessage({ id: "VirtualWarehouseCreditExchangeNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "branchName",
      text: `${intl.formatMessage({ id: "Branch" }).toUpperCase()}`,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerNo}</div>
          }
        </>
      )
    },
    {
      dataField: "subTotalExchangeAmount",
      text: intl.formatMessage({ id: "SubTotalExchangeAmount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.subTotalExchangeAmount)}</>, //Custom UI to display
    },
    {
      dataField: "processingFees",
      text: intl.formatMessage({ id: "ProcessingFees" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.processingFees)}</>, //Custom UI to display
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "TotalExchangeAmount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.totalExchangeAmount)}</>, //Custom UI to display
    },
    {
      dataField: "note",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.note || Constants.emptyData}</div>,
    },
    {
      dataField: "reviewerUserName",
      text: intl.formatMessage({ id: "ReviewedBy" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.reviewerUserName || Constants.emptyData}</div>,
    },
    {
      dataField: "reviewedOn",
      text: intl.formatMessage({ id: "ReviewedOn" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.reviewedOn !== null ? `${moment(row.reviewedOn).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.reviewedOn !== null ? moment(row.reviewedOn).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={virtualWarehouseCreditExchangeStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.virtualWarehouseCreditExchange}/view/${row.id}`}
              type="view" />
            {
              row.status == Constants.pending
              &&
              (blnPermissionReviewVirtualWarehouseCreditExchange || blnPermissionUpdateVirtualWarehouseCreditExchange)
              &&
              <TableActionButton
                to={`/${RoutesList.virtualWarehouseCreditExchange}/edit/${row.id}`}
                type="edit" />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }) })}
        title={intl.formatMessage({ id: "VirtualWarehouseCreditExchange" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionRequestVirtualWarehouseCreditExchange
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }) })}
            onClick={() => { history.push(`/${RoutesList.virtualWarehouseCreditExchange}/add`) }}
            linkTo={`/${RoutesList.virtualWarehouseCreditExchange}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }) })}
                name="virtualWarehouseCreditExchangeStatus"
                options={virtualWarehouseCreditExchangeStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedVirtualWarehouseCreditExchangeStatus}
                initialValue={selectedVirtualWarehouseCreditExchangeStatusId}
                setFieldLabel={setSelectedVirtualWarehouseCreditExchangeStatus}
                setFieldValue={setSelectedVirtualWarehouseCreditExchangeStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "VirtualWarehouseCreditExchangeNo" })}
                name="virtualWarehouseCreditExchangeNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterVirtualWarehouseCreditExchangeNo" })}
                onBlurFunction={() => {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Customer" })}
              name="customer"
              placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
              options={customerDropdownList}
              labelField="preferredName"
              valueField="id"
              initialLabel={selectedCustomer}
              initialValue={selectedCustomerId}
              setFieldLabel={setSelectedCustomer}
              setFieldValue={setSelectedCustomerId}
              disabled={localLoading || loading}
              onChange={() => {
                clearCustomerDropdownList();
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
              blnSupportCustomSearch={true}
              onChangeCustomSearch={(value)=> {
                fetchCustomerDropdown(value);
              }}
              onMenuOpenFunction={()=> {
                clearCustomerDropdownList();
                setLoadingCustomer(false);
              }}
              loadingCustomSearch={loadingCustomer}
              setLoadingCustomSearch={setLoadingCustomer}
            />
          </DoubleColumnRowParent>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={VirtualWarehouseCreditExchangeColumns}
              data={virtualWarehouseCreditExchangeList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }) })}
              objSorted={defaultSorted}
              virtualWarehouseCreditExchangeStatus={selectedVirtualWarehouseCreditExchangeStatusId}
              virtualWarehouseCreditExchangeStatusName={selectedVirtualWarehouseCreditExchangeStatus}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedCustomerId ? "" : getBranchId()}
              virtualWarehouseCreditExchangeNo={validation.values.virtualWarehouseCreditExchangeNo}
              requestAPI={getVirtualWarehouseCreditExchange}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(VirtualWarehouseCreditExchange);
