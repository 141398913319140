import React from 'react'
import {
  Modal,
  Spinner
} from "reactstrap";

export default function LoadingModal() {
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        // tog_center();
      }}
      centered
      style={{zIndex: 9999}}
    >
      <div className='fade-in-animation flex-direction-row flex-align-items-center' style={{padding: '20px'}}>
        <Spinner className="ms-2" color="primary"/>
        <p style={{marginTop: "1em", marginLeft: "1em"}}> Loading </p>
      </div>
    </Modal>
  )
}
