import React from 'react'
import { useIntl } from 'react-intl';
import LineBreakWithTittle from '../form/LineBreakWithTittle';

interface Props {
  moduleName: string;
}

export default function TableEmptyText(props: Props) {
  const intl = useIntl();
  return (
    <tr>
      <td colSpan={10}>{intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: props.moduleName})})}</td>
    </tr>
  )
}
