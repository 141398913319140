import React from 'react';
import { withRouter } from "react-router-dom"

interface Props {
  children?: any;
  location: object
}
const NonAuthLayout = (props : Props) => {
  return (
    <div>{props.children}</div>
  );
}

export default withRouter(NonAuthLayout)
