import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Label,
  FormFeedback,
  Table
} from "reactstrap";
import _ from 'lodash';
import { StaticReferenceDataObject } from "../../../../app/models/staticReference";
import { PaymentMethodForBranchObject } from "../../../../app/models/branch";
import { AddOptionsValueField, blockNegativeOrZeroPayment, checkPermission, getBranchId, newSpaceBeforeCapitalLetter, returnCurrency, returnPriceWithCurrency, returnSubBrandList } from "../../../../app/common/function/function";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";
import { CustomerPaymentDetailObject } from "../../../../app/models/customerOrder";
import MyButton from "../../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { Constants } from "../../../../app/constants/Constants";
import Loading from "../../../../app/components/loading/Loading";
import GeneralInput from "../../../../app/components/form/GeneralInput";
import { AppolousPaymentObject } from "../../../../app/models/appolousCustomerSales";
import { WindowObject } from "../../../../app/models/common";
import moment from "moment";
import { PermissionConstants } from "../../../../app/constants/PermissionConstants";
import { EmployeeListObject } from "../../../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../../../app/components/dropdown/DropDownWithTitleMultiSelect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  cardType: StaticReferenceDataObject[];
  branchPaymentMethod: PaymentMethodForBranchObject[];
  additionalPayment: CustomerPaymentDetailObject[];
  setAdditionalPayment: Function;
  selectedInvoicePaymentIndex: number | undefined;
  setSelectedInvoicePaymentIndex: Function;
  invoiceTotal: number;
  invoiceBalance: number;
  advisableProductCommission: number;
  getPaymentMethodOptionDropdown: Function;
  validationForAddPayment: any;
  onClickUpdateAppolousSalesPayment: Function;
  onClickMakePayment: Function;
  selectedAppolousPayment: AppolousPaymentObject;
  setSelectedAppolousPayment: Function;
  blnDisabledEditPaymentField: boolean;
  windowSize: WindowObject;
  isLastestPayment: boolean;
  setIsLastestPayment: Function;
  getEmployeeDropdown: Function;
}

export default function AppolousCustomerSalesPaymentModal(props: Props) {
  const intl = useIntl();
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const disabledFieldInput = props.blnDisabledEditPaymentField;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState("");
  const [selectedSubBrandId, setSelectedSubBrandId] = useState("");

  const returnLeftOverBalance = () => {
    let invoiceBalanceTemp = props.invoiceBalance;
    if (props.additionalPayment) {
      props.additionalPayment.map((valueAdditionalPaymentTemp)=> {
        invoiceBalanceTemp -= valueAdditionalPaymentTemp.paidAmount;
      })
    }
    
    if(Math.sign(invoiceBalanceTemp) == -1){
      return 0;
    }else{
      return invoiceBalanceTemp;
    }
  }

  let selectedIndex = props.selectedInvoicePaymentIndex ? props.selectedInvoicePaymentIndex-1 : -1
  const [state, setState] = useState(
    selectedIndex > -1 
    ?
    props.additionalPayment[selectedIndex]
    :
    {
      paymentMethodId: "",
      paymentMethodName: "",
      paidAmount: returnLeftOverBalance(),
      last4CardDigit: 0,
      cardType: "",
      transactionNo: "",
      paymentMethodOptionId: undefined,
      paymentMethodOptionName: "",
      optionTypeTitle: "",
      optionTypeList: [],
      isCardInput: false,
      isQRPay: false,
      isCustomerCredit: false,
      isTransferCredit: false,
      isBackdate: false,
      submissionDate: ""
    }
  );

  const errorStateDefault = {
    last4CardDigit: false,
    cardType: false,
    paidAmount: false,
    paymentMethodName: false,
    paymentMethodOptionId: false,
    transactionNo: false,
    packageSaleAmount: false,
    productSaleAmount: false,
    productCommission: false,
    isSaleAmountExceedInvoiceTotalAmount: false,
    subBrandId: false,
    consultantIds: false
  }
  
  const [errorState, setErrorState] = useState(_.cloneDeep(errorStateDefault))

  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState(AddOptionsValueField(props.branchPaymentMethod, "name", "id"));
  const optionsCardType = AddOptionsValueField(props.cardType, "displayValue", "displayValue");

  const cardValid = state.isCardInput
  const qrValid = state.isQRPay
  const customerCreditValid = state.isCustomerCredit
  const transferCreditValid = state.isTransferCredit
  const onlineTransferOrChequeValid = !cardValid && !qrValid && !customerCreditValid && !transferCreditValid && state.optionTypeList.length > 0;
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const blnEditPayment = props.selectedAppolousPayment !== undefined;
  const blnProductExisted = props.advisableProductCommission > 0;
  const blnPaidAmountEqualToBalance =  state.paidAmount === returnLeftOverBalance();
  const blnPaid =  returnLeftOverBalance() === 0;
  const blnHasSubBrand = returnSubBrandList().length > 0;
  const blnPermissionBackdateAppolousSales = checkPermission([PermissionConstants.BackdateAppolousSales]);
  const blnIsAllowProductCommission = ((!blnEditPayment && blnPaidAmountEqualToBalance) || (blnEditPayment && blnPaid && props.isLastestPayment));
  const blnCreditPayment = state.isCustomerCredit;
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const [localLoadingEmployeeDropdown, setLocalLoadingEmployeeDropdown] = useState(false);

  useEffect(() => {
    return (() => {
      props.setSelectedAppolousPayment(undefined);
      props.validationForAddPayment.resetForm();
    })
  }, [])

  useEffect(()=> {
    let totalSalesAmountTemp = totalSalesAmount;
    totalSalesAmountTemp = props.validationForAddPayment.values.packageSaleAmount + props.validationForAddPayment.values.productSaleAmount;
    
    setTotalSalesAmount(totalSalesAmountTemp);
  }, [props.validationForAddPayment.values.packageSaleAmount, props.validationForAddPayment.values.productSaleAmount])
  
  useEffect(()=> {
    async function selectedAppolousPaymentHandling() {
      if(props.selectedAppolousPayment){
        setState({
          ...props.selectedAppolousPayment, 
          optionTypeList: [],
          isCardInput: props.selectedAppolousPayment.cardType ? true : false,
          optionTypeTitle: newSpaceBeforeCapitalLetter(props.selectedAppolousPayment.optionType),
        });
        if(props.selectedAppolousPayment.subBrandId){
          onChangeSubBrand(props.selectedAppolousPayment.subBrandId)
        }

        setLocalLoadingEmployeeDropdown(true);
        let employeeDropdownListTemp = await props.getEmployeeDropdown({ branchId: props.selectedAppolousPayment.branchId, isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}, true);
        setLocalEmployeeDropdownList(employeeDropdownListTemp);
        if (props.selectedAppolousPayment.consultantIds) {
          let arySelectedConsultantOptions : any = [];
          props.selectedAppolousPayment.consultantIds.map((value) => {
            for (var a = 0; a < employeeDropdownListTemp.length; a++) {
              if (value === employeeDropdownListTemp[a].id) {
                arySelectedConsultantOptions.push({
                  ...employeeDropdownListTemp[a],
                  label: employeeDropdownListTemp[a].preferredName,
                  value: employeeDropdownListTemp[a].id
                })
              }
            }
          })
          props.validationForAddPayment.setFieldValue("consultantIds", arySelectedConsultantOptions);
        }
        setLocalLoadingEmployeeDropdown(false);
      }
      else {
        setLocalLoadingEmployeeDropdown(true);
        let employeeDropdownListTemp = await props.getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], isActive: true}, true);
        setLocalEmployeeDropdownList(employeeDropdownListTemp);
        setLocalLoadingEmployeeDropdown(false);
      }
    }

    selectedAppolousPaymentHandling();
  }, [props.selectedAppolousPayment])

  const hideModal = (blnSaveButton: boolean = true) => {
    if(!blnSaveButton || blnEditPayment){
      props.validationForAddPayment.resetForm();
    }
    if(blnEditPayment){
      props.setSelectedAppolousPayment(undefined);
    }
    props.setIsLastestPayment(false);
    props.setModal(false);
    props.setSelectedInvoicePaymentIndex(undefined);
  }

  const onChangePaymentMethod = async (e: any) => {
    setLoadingPaymentMethod(true);
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodId = e.id;
    stateTemp.paymentMethodName = e.name;
    stateTemp.cardType = "";
    stateTemp.last4CardDigit = 0;
    stateTemp.paymentMethodOptionName = "";
    stateTemp.paymentMethodOptionId = undefined;
    stateTemp.optionTypeTitle = "";
    stateTemp.optionTypeList = [];
    stateTemp.isCardInput = e.isCardInput;
    stateTemp.isQRPay = e.isQRPay;
    stateTemp.isCustomerCredit = e.isCustomerCredit;
    stateTemp.isTransferCredit = e.isTransferCredit;
    
    if (e.isOptionAvailable && e.optionType !== Constants.none) {
      let resultOptionType = await props.getPaymentMethodOptionDropdown(e.id);
      stateTemp.optionTypeTitle = newSpaceBeforeCapitalLetter(e.optionType)
      stateTemp.optionTypeList = AddOptionsValueField(resultOptionType, "name", "id")
    }
    setState(stateTemp);
    setLoadingPaymentMethod(false);
  }

  const onChangeCardType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.cardType = e.displayValue;
    setState(stateTemp);
  }

  const onChangeOptionType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodOptionName = e.label;
    stateTemp.paymentMethodOptionId = e.value;
    setState(stateTemp);
  }

  const onChangeSubBrand = (id: string) => {
    if(blnHasSubBrand){
      let subBrandListTemp = returnSubBrandList();
      let indexSubBrand = _.findIndex(subBrandListTemp, { id: id });
      if (indexSubBrand > -1) {
        props.validationForAddPayment.setValues((values) => ({
          ...values,
          subBrandId: subBrandListTemp[indexSubBrand].id,
          subBrandName: subBrandListTemp[indexSubBrand].name
        }))
        setSelectedSubBrandId(subBrandListTemp[indexSubBrand].id || "");
        setSelectedSubBrandName(subBrandListTemp[indexSubBrand].name);
        setErrorState((value) => ({...value, subBrandId: false}));
      }
    }
  }

  const blockSaleAmountExceedInvoiceTotalAmount = () => {
    let invoiceTotalTemp = props.invoiceTotal;
    let saleAmountTemp = props.validationForAddPayment.values.packageSaleAmount + props.validationForAddPayment.values.productSaleAmount;
    
    if(Number(saleAmountTemp) > Number(invoiceTotalTemp)){
      return true;
    }
    else{
      return false;
    }
  }
  
  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        // tog_center();
        if (!props.blnDisabledEditPaymentField) {
          hideModal();
        }
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: !blnEditPayment ? "Add" : "Edit" })} {intl.formatMessage({ id: "Payment" })}</h5>
        {
          !props.blnDisabledEditPaymentField
          &&
          <button
            type="button"
            onClick={() => {
              hideModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        {
          loadingPaymentMethod
          ?
          <Loading className="margin-bottom-16" />
          :
          <>
            <Row>
              <Col xl={`${blnHasSubBrand ? "6" : "12"}`} md={`${blnHasSubBrand ? "6" : "12"}`} xs="12">
                <GeneralInput
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  type="text"
                  disabled={true}
                  validationRequired={true}
                  validation={props.validationForAddPayment}
                />
              </Col>
              {
                blnHasSubBrand
                &&
                <Col xl="6" md="6" xs="12">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    className={errorState.subBrandId ? "mb-0" : "mb-3"}
                    options={returnSubBrandList()}
                    labelField="name"
                    valueField="id"
                    validationRequired={true}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    onChange={onChangeSubBrand}
                    disabled={blnEditPayment || disabledFieldInput}
                  />
                  {errorState.subBrandId ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) })}</FormFeedback>
                  ) : null}
                </Col>
              }
            </Row>
            {
              localLoadingEmployeeDropdown
              ?
              <Loading className="mb-2"/>
              :
              <>
                <DropDownWithTitleMultiSelect
                  name={"consultantIds"}
                  className={errorState.consultantIds ? "margin-bottom-0" : "mb-3"}
                  title={intl.formatMessage({ id: "Consultants" })}
                  specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                  returnFieldWithLabel={true}
                  labelField={"preferredName"}
                  valueField={"id"}
                  options={localEmployeeDropdownList}
                  disabled={disabledFieldInput}
                  initialValue={props.validationForAddPayment.values.consultantIds}
                  validationRequired={true}
                  validation={props.validationForAddPayment} />
                {errorState.consultantIds ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Consultant"})})}`}</FormFeedback>
                ) : null}
              </>
            }
            { 
              <div>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "PaymentMethod" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodName ? "mb-0" : "mb-3"}
                  options={optionsPaymentMethod}
                  onChange={onChangePaymentMethod}
                  blnReturnFullValue={true}
                  onBlur={()=> {
                    if (errorState.paymentMethodName) {
                      setErrorState((value) => ({...value, paymentMethodName: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodName}
                  initialValue={state.paymentMethodId}
                  disabled={blnEditPayment || disabledFieldInput}
                  validationRequired={true}
                />
                {errorState.paymentMethodName ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "PaymentMethod"})})}`}</FormFeedback>
                ) : null}
              </div>
            }
            {
              cardValid
              &&
              <Row>
                <Col>
                  <Label>
                    <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                    <Label className="margin-bottom-0">{intl.formatMessage({ id: "Credit/DebitCardType" })}</Label>
                  </Label>
                  <GeneralSelectDropdown
                    title=""
                    name=""
                    className={errorState.cardType ? "mb-0" : "mb-3"}
                    options={optionsCardType}
                    blnValueWithNewSpace={true}
                    blnReturnFullValue={true}
                    onChange={onChangeCardType}
                    onBlur={()=> {
                      if (errorState.cardType) {
                        setErrorState((value) => ({...value, cardType: false}))
                      }
                    }}
                    initialLabel={state.cardType}
                    initialValue={state.cardType}
                    disabled={blnEditPayment || disabledFieldInput}
                    validationRequired={true}
                  />
                  {errorState.cardType ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "CardType"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <GeneralInputForModal
                    title="Last Four Digit"
                    name={blnEditPayment ? "last4CardDigitNo" :"last4CardDigit"}
                    className={errorState.last4CardDigit ? "mb-0" : "mb-3"}
                    type="number"
                    maxLength={4}
                    typeExcludeNumberType={true}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    disabled={blnEditPayment || disabledFieldInput}
                    onBlur={()=> {
                      if (errorState.last4CardDigit) {
                        setErrorState((value) => ({...value, last4CardDigit: false}))
                      }
                    }}
                  />
                  {errorState.last4CardDigit ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Last4Digit"})})}`}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            }
            {
              ((blnEditPayment && props.selectedAppolousPayment.paymentMethodOptionName) || state.optionTypeList.length > 0)
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{state.optionTypeTitle}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodOptionId ? "mb-0" : "mb-3"}
                  options={state.optionTypeList}
                  blnReturnFullValue={true}
                  onChange={onChangeOptionType}
                  onBlur={()=> {
                    if (errorState.paymentMethodOptionId) {
                      setErrorState((value) => ({...value, paymentMethodOptionId: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodOptionName}
                  initialValue={blnEditPayment ? state.paymentMethodOptionName : state.paymentMethodOptionId}
                  disabled={blnEditPayment || disabledFieldInput}
                  validationRequired={true}
                />
                {errorState.paymentMethodOptionId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: state.optionTypeTitle})}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              <Row>
                <Col xl={(cardValid || qrValid) ? 6 : 12}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "PaidAmount" })}
                    name="paidAmount"
                    className={(errorState.paidAmount) ? "mb-0" : "mb-3"}
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.paidAmount) {
                        setErrorState((value) => ({...value, paidAmount: false}))
                      }
                    }}
                    blnDisableNegativeValue={true}
                    disabled={blnEditPayment || disabledFieldInput}
                  />
                  {errorState.paidAmount ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "PaidAmount"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                {
                  (cardValid || qrValid || onlineTransferOrChequeValid)
                  &&
                  <Col>
                    <GeneralInputForModal
                      title={intl.formatMessage({ id: qrValid || onlineTransferOrChequeValid ? "TransactionNo" : "ApprovalCode"})}
                      name="transactionNo"
                      className={errorState.transactionNo ? "mb-0" : "mb-3"}
                      type={"text"}
                      typeExcludeNumberType={true}
                      field={state}
                      setFieldValue={setState}
                      disabled={blnEditPayment || disabledFieldInput}
                      validationRequired={!qrValid && !onlineTransferOrChequeValid}
                      {...!qrValid && !onlineTransferOrChequeValid && {maxLength: 6}}
                    />
                    {errorState.transactionNo ? (
                      <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "ApprovalCode"})})}`}</FormFeedback>
                    ) : null}
                  </Col>
                }
              </Row>
            }
            {
              <>
                <Row>
                  {
                    !blnCreditPayment
                    &&
                    <Col xl={blnProductExisted ? 6 : 12} md={blnProductExisted ? 6 : 12} xs="12" sm="12">
                      <GeneralInput
                        title={intl.formatMessage({ id: "PackageSaleAmount" })}
                        name={"packageSaleAmount"}
                        className={"mb-0"}
                        type="number"
                        disabled={disabledFieldInput}
                        validationRequired={false}
                        validation={props.validationForAddPayment}
                        onBlurFunction={()=> {
                          if (errorState.packageSaleAmount) {
                            setErrorState((value) => ({...value, packageSaleAmount: false}))
                          }
                          if(errorState.isSaleAmountExceedInvoiceTotalAmount && !blockSaleAmountExceedInvoiceTotalAmount()){
                            setErrorState((value) => ({...value, isSaleAmountExceedInvoiceTotalAmount: false}))
                          }
                        }}
                      />
                      {errorState.packageSaleAmount ? (
                        <FormFeedback type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PackageSaleAmount" }) })}</FormFeedback>
                      ) : null}
                    </Col>
                  }
                  {
                    blnProductExisted
                    &&
                    !blnCreditPayment
                    &&
                      <Col xl="6" md="6" xs="12" sm="12">
                        <GeneralInput
                          title={intl.formatMessage({ id: "ProductSaleAmount" })}
                          name={"productSaleAmount"}
                          className={`${Constants.innerWidthCompare770 > props.windowSize.innerWidth ? "mt-3" : ""} mb-0`}
                          type="number"
                          disabled={disabledFieldInput}
                          validationRequired={false}
                          validation={props.validationForAddPayment}
                          onBlurFunction={()=> {
                            if (errorState.productSaleAmount) {
                              setErrorState((value) => ({...value, productSaleAmount: false}))
                            }
                            if(errorState.isSaleAmountExceedInvoiceTotalAmount && !blockSaleAmountExceedInvoiceTotalAmount()){
                              setErrorState((value) => ({...value, isSaleAmountExceedInvoiceTotalAmount: false}))
                            }
                          }}
                        />
                        {errorState.productSaleAmount ? (
                          <FormFeedback type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ProductSaleAmount" }) })}</FormFeedback>
                        ) : null}
                    </Col>
                  }
                  {<p className={`${errorState.packageSaleAmount || errorState.productSaleAmount || errorState.isSaleAmountExceedInvoiceTotalAmount ? "mb-0" : "mb-2"} total_label`}>{` ${intl.formatMessage({ id: "TotalSalesAmount" })}: ${returnPriceWithCurrency(totalSalesAmount)} `}</p>}
                  {errorState.isSaleAmountExceedInvoiceTotalAmount ? (
                    <FormFeedback className="mt-0 " type="invalid">{`${intl.formatMessage({ id: "SaleAmountIsExceeded" })}`}</FormFeedback>
                  ) : null}
                </Row>
                {
                  blnProductExisted
                  &&
                  !blnCreditPayment
                  &&
                  blnIsAllowProductCommission
                  &&
                  <Row>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ProductCommission" })}
                      name={"productCommission"}
                      className={"mt-2 mb-0"}
                      type="number"
                      disabled={disabledFieldInput}
                      validationRequired={false}
                      validation={props.validationForAddPayment}
                      onBlurFunction={()=> {
                        if (errorState.productCommission) {
                          setErrorState((value) => ({...value, productCommission: false}))
                        }
                      }}
                    />
                    {blnProductExisted && <p className={`${errorState.productCommission ? "mb-0" : "mb-2"} total_label`}>{` ${intl.formatMessage({ id: "AdvisableProductCommission" })}: ${returnPriceWithCurrency(props.advisableProductCommission)} `}</p>}
                    {errorState.productCommission ? (
                      <FormFeedback className="mt-0 mb-3" type="invalid">{intl.formatMessage({ id: "NegativeValueIsNotAllowed" })}</FormFeedback>
                    ) : null}
                  </Row>
                }
              </>
            }
            {
              blnPermissionBackdateAppolousSales
              &&
              !blnEditPayment
              &&
              <Col>
                <GeneralInput
                  title={""}
                  name="isBackdate"
                  className={`mt-2 ${props.validationForAddPayment.values.isBackdate ? "" : "mb-4"} `}
                  type="checkbox"
                  disabled={disabledFieldInput}
                  validationRequired={false}
                  validation={props.validationForAddPayment}
                  onChangeFunctionWithSetValidationField={(valueCheckBox)=> {
                    props.validationForAddPayment.setFieldValue("submissionDate", valueCheckBox ? moment().format(Constants.textInputDateAndTimeFormat) : "");
                  }}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      {intl.formatMessage({ id: "IsApplyPaymentSubmissionDate" })}
                    </Label>
                  }
                />
                {
                  props.validationForAddPayment.values.isBackdate
                  &&
                  <GeneralInput
                    title={""}
                    name="submissionDate"
                    className="mt-2 mb-4"
                    placeholder={intl.formatMessage({ id: "PaymentSubmissionDate" })}
                    type="datetime-local"
                    disabled={disabledFieldInput}
                    validationRequired={true}
                    validation={props.validationForAddPayment}
                    maxCurrentDate={true}
                  />
                }
              </Col>
            }
          </>
        }
        <div className="flex-right">
          {
            <MyButton
              type="button"
              class={`btn btn-success`}
              content={`${intl.formatMessage({ id: "Submit" })}`}
              disable={loadingPaymentMethod || disabledFieldInput}
              loading={disabledFieldInput}
              onClick={()=> {
                let errorStateTemp = _.cloneDeep(errorStateDefault);

                let blnErrorFound = false;
                if (!state.paymentMethodName) {
                  errorStateTemp.paymentMethodName = true;
                  blnErrorFound = true;
                }

                if (cardValid && !blnEditPayment) {
                  if (String(state.last4CardDigit).length !== 4) {
                    errorStateTemp.last4CardDigit = true;
                    blnErrorFound = true;
                  }
                  if (!state.cardType) {
                    errorStateTemp.cardType = true;
                    blnErrorFound = true;
                  }
                  if (String(state.transactionNo).length !== 6) {
                    errorStateTemp.transactionNo = true;
                    blnErrorFound = true;
                  }
                }

                if (blockNegativeOrZeroPayment(state.paidAmount, intl)) {
                  errorStateTemp.paidAmount = true;
                  blnErrorFound = true;
                }

                if (Number(props.validationForAddPayment.values.packageSaleAmount) < 0) {
                  errorStateTemp.packageSaleAmount = true;
                  blnErrorFound = true;
                }

                if (Number(props.validationForAddPayment.values.productSaleAmount) < 0) {
                  errorStateTemp.productSaleAmount = true;
                  blnErrorFound = true;
                }

                if (Number(props.validationForAddPayment.values.productCommission) < 0) {
                  errorStateTemp.productCommission = true;
                  blnErrorFound = true;
                }

                if ((state.isCustomerCredit || state.isTransferCredit) && state.paidAmount < 1) {
                  errorStateTemp.paidAmount = true;
                  blnErrorFound = true;
                }

                if (state.optionTypeList.length > 0 && !state.paymentMethodOptionId) {
                  errorStateTemp.paymentMethodOptionId = true;
                  blnErrorFound = true;
                }

                if(blnHasSubBrand && !props.validationForAddPayment.values.subBrandId){
                  errorStateTemp.subBrandId = true;
                  blnErrorFound = true;
                }

                if (props.validationForAddPayment.values.consultantIds.length < 1) {
                  errorStateTemp.consultantIds = true;
                  blnErrorFound = true;
                }

                state.paidAmount = Number(state.paidAmount);
                
                if (props.validationForAddPayment.values.isBackdate) {
                  state.isBackdate = props.validationForAddPayment.values.isBackdate;
                  state.submissionDate = props.validationForAddPayment.values.submissionDate;
                }

                if(blockSaleAmountExceedInvoiceTotalAmount()){
                  errorStateTemp.isSaleAmountExceedInvoiceTotalAmount = true;
                  blnErrorFound = true;
                }

                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  if (blnEditPayment) {
                    let consultantIdsTemp = props.validationForAddPayment.values.consultantIds.map((valueConsultantIdsTemp: any)=> valueConsultantIdsTemp.value);

                    let objTemp = {
                      appolousPaymentId: props.selectedAppolousPayment.appolousPaymentId,
                      packageSaleAmount: props.validationForAddPayment.values.packageSaleAmount,
                      productSaleAmount: props.validationForAddPayment.values.productSaleAmount,
                      productCommission: props.validationForAddPayment.values.productCommission,
                      consultantIds: consultantIdsTemp
                    }
                    props.onClickUpdateAppolousSalesPayment(objTemp);
                  }
                  else{
                    let additionalPaymentTemp = _.cloneDeep(props.additionalPayment);
                    if (selectedIndex > -1) {
                      additionalPaymentTemp[selectedIndex] = state;
                    }
                    else {
                      additionalPaymentTemp.push(state);
                    }
                    props.onClickMakePayment(additionalPaymentTemp, blnIsAllowProductCommission);
                  }
                }
              }}
            /> 
          }
        </div>
      </div>
    </Modal>
  )
}