import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom"
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { CustomerImportFailedDetailObject, CustomerImportObject, CustomerListObject } from '../../app/models/customer';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import moment from "moment";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, displayTagUI, getBranchId, returnAdvancedSearchText, returnBlobImage, returnSubBrandList } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import MyButton from "../../app/components/form/MyButton";
import CustomerValidationModal from "./Modal/CustomerValidationModal";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PaginationRequestBody } from "../../app/models/pagination";
import Loading from "../../app/components/loading/Loading";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import GeneralImageWithBase64 from "../../app/components/form/GeneralImageWithBase64";
import ImportCustomerModal from "./Modal/ImportCustomerModal";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import { DateRangeInterface } from "../../app/models/common";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";

const Customer = () => {
  const intl = useIntl();
  //Use Store
  const { customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, windowSize, setShowDeletePrompt, setSuccessMessage, setErrorMessage, setLoadingModal } = commonStore;
  const { customerList, customerPaginationParams, getCustomer, getCustomerSearchAll, deleteCustomer, exportCustomer, importCustomer, setCustomerList, setCustomerPaginationParams } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  //Data List
  const [selectedRow, setSelectedRow] = useState<CustomerListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerValidationModal, setCustomerValidationModal] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionCreateCustomer = checkPermission([PermissionConstants.CreateCustomer]);
  const blnPermissionManualCreateCustomer = checkPermission([PermissionConstants.ManualCreateCustomer]);
  const blnPermissionUpdateCustomer = checkPermission([PermissionConstants.UpdateCustomer]);
  const blnPermissionDeleteCustomer = checkPermission([PermissionConstants.DeleteCustomer]);
  const blnPermissionSearchAllBranchCustomer = checkPermission([PermissionConstants.SearchAllBranchCustomer]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [blnPromptTransferRequestAcknowledgement, setBlnPromptTransferRequestAcknowledgement] = useState(false);
  const [transferRequestAcknowledgementModal, setTransferRequestAcknowledgementModal] = useState(false);
  const [transferRequestResponse, setTransferRequestResponse] = useState<any>([]);
  const [importFileModal, setImportFileModal] = useState(false);
  const [importedCustomerDetails, setImportedCustomerDetails] = useState<CustomerImportFailedDetailObject[]>([]);
  const [modalImportedCustomerDetails, setModalImportedCustomerDetails] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: "", toDate: ""});
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [genderList, setGenderList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedGender, setSelectedGender] = useState<string>(All);
  const [selectedGenderId, setSelectedGenderId] = useState<number | string | undefined>(" ");

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerNoOrName: "",
      mobileNo: "",
      email: "",
      identityNo: "",
      allBranchAccess: false
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: getBranchId(),
    customerNoOrName: validation.values.customerNoOrName,
    customerMobileNo: validation.values.mobileNo,
    customerEmail: validation.values.email,
    identityNo: validation.values.identityNo,
    allBranchAccess: validation.values.allBranchAccess,
    gender: selectedGenderId,
    genderName: selectedGender,
    ...selectedDateRange && {dateRange: selectedDateRange},
  }

  useEffect(() => {
    async function fetchCustomerAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customer)
      if (blnBackNavigation) {
        if (customerPaginationParams) {
          setCurrentPage(customerPaginationParams.pageNumber);
          setSelectedDateRange(customerPaginationParams.dateRange || {fromDate: "", toDate: ""});
          setSelectedGenderId(customerPaginationParams.gender);
          setSelectedGender(customerPaginationParams.genderName || All);
          validation.setValues((values)=> ({
            ...values,
            customerNoOrName: customerPaginationParams.customerNoOrName || "",
            mobileNo: customerPaginationParams.customerMobileNo || "",
            email: customerPaginationParams.customerEmail || "",
            identityNo: customerPaginationParams.identityNo || "",
            allBranchAccess: customerPaginationParams.allBranchAccess || false,
          }))
          customerRequestBody = customerPaginationParams;
        }
      }
      else {
        setCustomerPaginationParams(undefined);
      }
      if (customerPaginationParams?.allBranchAccess) {
        await getCustomerSearchAll(customerRequestBody)
      }
      else {
        await getCustomer(customerRequestBody)
      }

      let resultAPIGender = await getStaticReferenceWithType(Constants.genderType, All);
      setGenderList(resultAPIGender!);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomer], true)) {
      return;
    }

    fetchCustomerAPI();

    return (()=> {
      setCustomerList([]);
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customer)
        if (!blnSetParams) {
          setCustomerPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedGenderId || selectedGenderId === 0 ) && !initialCallAPI) {
      fetchCustomerApi(true);
    }
  }, [triggerSearch, selectedDateRange, selectedGenderId])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setCustomerValidationModal(false);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchCustomerApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    if (validation.values.allBranchAccess) {
      await getCustomerSearchAll(customerRequestBody)
    }
    else {
      await getCustomer(customerRequestBody);
    }
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  
  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  const onClickDelete = async (row: CustomerListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const exportCustomerReport = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await (exportCustomer({ exportType: 2 }))
    }
    setLocalLoading(false);
  }

  const importCustomerFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: CustomerImportObject = { file: fileTemp };
        let resultImportCustomer = await importCustomer(valuesTemp);
        if (resultImportCustomer) {
          if (resultImportCustomer.length > 0) {
            resultImportCustomer = resultImportCustomer.map((valueResultImportCustomer)=> ({
              ...valueResultImportCustomer,
              errorCode: intl.formatMessage({ id: valueResultImportCustomer.errorCode })
            }))
            setImportFileModal(false);
            setModalImportedCustomerDetails(true);
            setImportedCustomerDetails(resultImportCustomer);
          }
          else {
            setImportFileModal(false);
            setSuccessMessage(`CustomerImportSuccess`);
            fetchCustomerApi();
          }
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const onClickSelect =  (row) => {
    history.push(`/${RoutesList.customer}/view/${row.id}`)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const CustomerColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
    },
    {
      dataField: "preferredName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.preferredName}
          <div className="flex-align-items-center flex-flow-wrap">
            {
              row.tags
              ?
              row.tags.map((valueTag, index)=> (
                displayTagUI(valueTag, false)
              ))
              :
              null
            }
          </div>
        </div>
      )
    },
    {
      ...returnSubBrandList().length > 0
      ?
      {
        dataField: "badges",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {                          
              row.badges
              ?
              row.badges.length > 0
              ?
              row.badges.map((valueBagde, index)=> (
                <span id={`brand_bagde_${index}_888`} key={`brand_bagde_${index}_888`}>
                  {valueBagde.name}{row.badges.length > index +1 ? ", " : ""}
                </span>
              ))
              :
              Constants.emptyData
              :
              Constants.emptyData
            }
          </div>
        ),
      }
      :
      {
        dataField: "preferredName",
        text: intl.formatMessage({ id: "Name" }).toUpperCase(),
        hidden: true, //hide UI
      },
    },
    {
      dataField: "identityNo",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
    },
    {
      dataField: "mobileNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "lastVisitDate",
      text: intl.formatMessage({ id: "LastVisitDate" }).toUpperCase(),
      // headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <>
          <div>
            {row.lastVisitDate !== Constants.invalidDateTime ? moment(row.lastVisitDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.lastVisitDate !== Constants.invalidDateTime ? moment(row.lastVisitDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "lastFollowUpDate",
      text: intl.formatMessage({ id: "LastFollowedUpDate" }).toUpperCase(),
      // headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <>
          <div>
            {(row.lastFollowUpDate !== Constants.invalidDateTime && row.lastFollowUpDate !== null) ? moment(row.lastFollowUpDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {(row.lastFollowUpDate !== Constants.invalidDateTime && row.lastFollowUpDate !== null) ? moment(row.lastFollowUpDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "isSponsorProfile",
      text: intl.formatMessage({ id: "SponsoredProfile" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isSponsorProfile ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.customer}/view/${row.id}`}
              type="view"
              target={Constants.blank}
              />
            <TableActionButton
              type="whatsapp"
              onClick={() => window.open(`https://web.whatsapp.com/send?phone=${row.mobileNo}`)}/>
            {
              blnPermissionUpdateCustomer
              &&
              getBranchId() === row.branchId
              &&
              <TableActionButton 
                to={`/${RoutesList.customer}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteCustomer
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
        title={intl.formatMessage({ id: "Customer" })}
        addButton={
          <>
            {
              blnPermissionManualCreateCustomer
              && 
              <MyAddButton
                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
                onClick={() => { history.push(`/${RoutesList.customer}/add`) }}
                linkTo={`/${RoutesList.customer}/add`}
                disable={loading} />
            }
            {
              blnPermissionManageCustomer
              &&
              <>
                <MyButton
                  content={intl.formatMessage({ id: "CustomerValidation"})}
                  onClick={() => { setCustomerValidationModal(true) }}
                  class={'btn btn-primary margin-left-8'}
                  disable={loading} />
                <MyButton
                  type="button"
                  class={'btn btn-primary margin-left-8'}
                  content={`${intl.formatMessage({ id: "Customer" })} ${intl.formatMessage({ id: returnAdvancedSearchText() })}`}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
                {/* <MyButton
                  content={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
                  onClick={() => { setImportFileModal(true) }}
                  class={'btn btn-primary margin-left-8'}
                  disable={localLoading || loading} />
                <MyButton
                  content={intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Template" }) })}
                  onClick={() => { exportCustomerReport(true) }}
                  class={'btn btn-info margin-left-8'}
                  disable={localLoading || loading} /> */}
              </>
            }
          </>
        }
        addButtonXL={"6"}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerNoOrName" })}
              name="customerNoOrName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "CustomerNoOrName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "IdentityNo" })}
                name="identityNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "IdentityNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "MobileNo" })}
              name="mobileNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "MobileNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Email" })}
              name="email"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Email" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          {
            !initialCallAPI
            &&
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "JoinedDate"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                validationRequired={false}
                onChangeFunction={onSelectDateRange}
                initialValue={selectedDateRange} />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            {
              initialCallAPI
              ?
              <Loading/>
              :
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Gender" })}
                name="gender"
                options={genderList}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedGender}
                initialValue={selectedGenderId}
                setFieldLabel={setSelectedGender}
                setFieldValue={setSelectedGenderId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            }
          </DoubleColumnRowParent>
          {
            blnPermissionSearchAllBranchCustomer
            &&
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "AllBranchAccess" })}
                name="allBranchAccess"
                type="checkbox"
                disabled={localLoading || loading}
                validation={validation}
                onChangeFunctionWithSetValidationField={()=> {
                  onClickSearch()
                }}
                childrenUI={
                  <Label className="margin-bottom-18 margin-left-4">
                    {intl.formatMessage({ id: "Yes" })}
                  </Label>
                }
              />
            </DoubleColumnRowParent>
          }
        </Row>
        {
          localLoading
          ?
          <Loading />
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={CustomerColumns}
            data={customerList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
            objSorted={defaultSorted}
            branchId={getBranchId()}
            customerNoOrName={validation.values.customerNoOrName}
            customerMobileNo={validation.values.mobileNo}
            customerEmail={validation.values.email}
            identityNo={validation.values.identityNo}
            allBranchAccess={validation.values.allBranchAccess}
            dateRange={selectedDateRange}
            gender={selectedGenderId}
            genderName={selectedGender}
            requestAPI={validation.values.allBranchAccess ? getCustomerSearchAll : getCustomer} />
        }
        <DeleteModal
          displayName={selectedRow?.preferredName}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCustomer(selectedRow?.id || "", selectedRow?.preferredName || "");
            let currentPageTemp = currentPage;
            if (customerList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            if (validation.values.allBranchAccess) {
              await getCustomerSearchAll({ pageNumber: currentPageTemp, pageSize: pageSize, branchId: getBranchId() })
            }
            else {
              await getCustomer({ pageNumber: currentPageTemp, pageSize: pageSize, branchId: getBranchId() })
            }
            setLoading(false);
          }} />
        {
          customerValidationModal
          &&
          <CustomerValidationModal
            blnShow={customerValidationModal}
            setModal={setCustomerValidationModal}
            setTransferRequestAcknowledgementModal={setTransferRequestAcknowledgementModal}
            setTransferRequestResponse={setTransferRequestResponse} />
        }
        {
          customerSearchModal
          &&
          <CustomerSearchModal
            blnShow={customerSearchModal}
            setModal={setCustomerSearchModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
            actionTitle={intl.formatMessage({ id: "View" })}
            onSelectFunction={onClickSelect} />
        }
        {
          transferRequestAcknowledgementModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "TransferRequestAcknowledgementMessage" }, { documentNo: transferRequestResponse.customerTransferDocumentNo })}`}
            primaryActionTitle={`Yes`}
            showPrompt={transferRequestAcknowledgementModal}
            setShowPrompt={setTransferRequestAcknowledgementModal}
            onPrimaryClick={()=> {
              history.push({
                pathname: `/${RoutesList.customerTransferRequest}/edit/${transferRequestResponse.id}`
              }); 
            }}
          />
        }
        <ImportFileModal
          blnShow={importFileModal}
          setModal={setImportFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
          btnFunction={importCustomerFunction}
        />
        {
          modalImportedCustomerDetails
          &&
          <ImportCustomerModal
            blnShow={modalImportedCustomerDetails}
            setModal={setModalImportedCustomerDetails}
            importedCustomerDetails={importedCustomerDetails}
            setImportedCustomerDetails={setImportedCustomerDetails} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Customer);
