import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, returnPriceWithCurrency, checkPermission, getBranchUser, getBranchId, getBranchName, returnSubBrandList, arraySpliceInAllValue } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import { SubBrandObject } from "../../../app/models/brand";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";

const ConsultantSalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { consultantSalesSummaryList, setConsultantSalesSummaryList, getConsultantSalesSummary, exportConsultantSalesSummary } = reportSalesStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  const aryConsultantSalesDetail : ObjectForReportRow[] = [
    {label: "CustomerSalesOrderNo", value: "customerSalesOrderNo", type: "link"}, 
    {label: "SalesOrderDate", value: "salesOrderDate", type: "date"}, 
    {label: "PackageSales", value: "transactionPackageSalesAmount", type: "currency"}, 
    {label: "ProductSales", value: "transactionProductSalesAmount", type: "currency"}, 
    {label: "PaymentReceived", value: "transactionReceivedAmount", type: "currency"},
    {label: "ProductCommission", value: "productCommissionAmount", type: "currency"},
    {label: "SalesOrderBranch", value: "salesOrderBranch", type: "string"},
    {label: "PaymentBranch", value: "paymentBranch", type: "string"},
    {label: "PaymentDate", value: "paymentDate", type: "dateWithoutTime"}
  ];

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchConsultantSalesSummary() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      
      await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchConsultantSalesSummary();

    return (()=> {
      setConsultantSalesSummaryList([]);
    })
  }, [])

  const fetchConsultantSalesSummaryWithLoading = async (blnExport: boolean) => {
    if (selectedBranchId) {
      setCurrentPage(1);
      setLocalLoading(true);
      if (blnExport) {
        await exportConsultantSalesSummary({ pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear || 0, month: selectedMonthValue, subBrandId: selectedSubBrandId })
      }
      else {
        await getConsultantSalesSummary({ pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId });
      }
      setLocalLoading(false);
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
    }
	};

  //Table Content UI
  const ConsultantSalesSummaryColumns = [
    { 
      dataField: "consultantId", //field name from array to display
      text: "consultantId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
    },

    {
      dataField: "packageSalesSum",
      text: intl.formatMessage({ id: "TotalPackageSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.packageSalesSum)}</div>
      ),
    },
    {
      dataField: "productSalesSum",
      text: intl.formatMessage({ id: "TotalProductSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productSalesSum)}</div>
      ),
    },
    {
      dataField: "totalSales",
      text: intl.formatMessage({ id: "TotalSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalSales)}</div>
      ),
    },
    {
      dataField: "productCommissionSum",
      text: intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productCommissionSum)}</div>
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ConsultantSalesDetails" })}
        rowFieldName={"consultantSalesDetails"}
        rowColumns={aryConsultantSalesDetail}
        keyFieldName={"customerSalesOrderId"}
        linkPathName={`/${RoutesList.customerSalesOrder}/view/`}
        linkFieldName={"customerSalesOrderId"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ConsultantSalesSummary"})})}
        title={intl.formatMessage({ id: "ConsultantSalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchConsultantSalesSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Consultant-Sales-Summary.aspx`}>
        <Row>
          {
            subBrandListFinal.length > 0
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={""}
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchConsultantSalesSummaryWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>

        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ConsultantSalesSummaryColumns}
              data={consultantSalesSummaryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ConsultantSalesSummary" }) })}
              objSorted={defaultSorted}
              keyField={"consultantId"}
              requestAPI={getConsultantSalesSummary}
              branchId={selectedBranchId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              subBrandId={selectedSubBrandId}
              expandRow={expandRow}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ConsultantSalesSummary);
