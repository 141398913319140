import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { EmployeeImportObject, EmployeeImportSuccessDetailObject, EmployeeListObject } from '../../app/models/employee';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, convertArrayToStringDisplay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, removeAllSpace, returnBlobImage, returnSwitchTabButtonUI } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import Loading from "../../app/components/loading/Loading";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import _ from "lodash";
import ImportEmployeeModal from "./Modal/ImportEmployeeModal";
import moment from "moment";
import { ObjectForDropdown } from "../../app/models/common";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import LockResetIcon from 'mdi-react/LockResetIcon';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { DepartmentDropdownListObject } from "../../app/models/department";
import { PositionListObject } from "../../app/models/position";

const Employee = () => {
  const intl = useIntl();
  //Use Store
  const { employeeStore, staticReferenceStore, commonStore, departmentStore, positionStore, branchStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setShowDeletePrompt, setErrorMessage, setSuccessMessage, setLoadingModal } = commonStore;
  const { employeeList, employeePaginationParams, getEmployee, deleteEmployee, setEmployeePaginationParams, exportEmployee, exportEmployeeNameUpdate, importEmployee, importEmployeeNameUpdate, resetEmployeePassword } = employeeStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getDepartmentDropdown } = departmentStore;
  const { getPositionDropdown, setPositionDropdownList } = positionStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<EmployeeListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedEmployeeType, setSelectedEmployeeType] = useState<string>(getBranchUser() ? Constants.branchStaff : AllType);
  const [selectedEmployeeTypeId, setSelectedEmployeeTypeId] = useState<number | string | undefined>(getBranchUser() ? 1 : " ");
  const [employeeTypeList, setEmployeeTypeList] = useState<StaticReferenceDataObject[]>([])
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionCreateEmployee = checkPermission([PermissionConstants.CreateEmployee]);
  const blnPermissionUpdateEmployee = checkPermission([PermissionConstants.UpdateEmployee]);
  const blnPermissionDeleteEmployee = checkPermission([PermissionConstants.DeleteEmployee]);
  const blnPermissionResetEmployeePassword = checkPermission([PermissionConstants.ResetEmployeePassword]);
  const [importFileModal, setImportFileModal] = useState(false);
  const [importedEmployeeDetails, setImportedEmployeeDetails] = useState<EmployeeImportSuccessDetailObject[]>([]);
  const [importEmployeeNameUpdateModal, setImportEmployeeNameUpdateModal] = useState(false);
  const [modalImportedEmployeeDetails, setModalImportedEmployeeDetails] = useState<boolean>(false);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [departmentListFinal, setDepartmentListFinal] = useState<DepartmentDropdownListObject[]>([]);
  const AllDepartment = `${intl.formatMessage({ id: "AllDepartment" })}`;
  const [selectedDepartmentName, setSelectedDepartmentName] = useState(AllDepartment);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(" ");
  const [positionListFinal, setPositionListFinal] = useState<PositionListObject[]>([]);
  const AllPosition = `${intl.formatMessage({ id: "AllPosition" })}`;
  const [selectedPositionName, setSelectedPositionName] = useState(AllPosition);
  const [selectedPositionId, setSelectedPositionId] = useState(" ");
  const [importMenu, setImportMenu] = useState(false);
  const [exportMenu, setExportMenu] = useState(false);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const [blnIsConfirmed, setBlnIsConfirmed] = useState(false);
  const [blnAllConfirmed, setBlnAllConfirmed] = useState(true);
  const [resetPasswordPrompt, setResetPasswordPrompt] = useState(false);
  const [resetPasswordSuccessPrompt, setResetPasswordSuccessPrompt] = useState(false);
  const [passwordFromResetPasswordResponse, setPasswordFromResetPasswordResponse] = useState("");
  const [selectedEmployeeDetail, setSelectedEmployeeDetail] = useState<EmployeeListObject | undefined>(undefined);
  const blnHQType = removeAllSpace(selectedEmployeeType) === Constants.hqStaff;
  const blnBranchType = removeAllSpace(selectedEmployeeType) === Constants.branchStaff;
  const tabButtonWidth = windowSize.innerWidth >= Constants.innerWidthCompare ? `${windowSize.innerWidth/15}px` : "";

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employeeName: "",
      employeeNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  let employeeRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.employeeName,
    employeeNo: validation.values.employeeNo,
    ...(blnBranchType || blnHQType) && { positionId: selectedPositionId },
    ...(blnBranchType || blnHQType) && { positionName: selectedPositionName },
    ...(selectedEmployeeType !== AllType) && { isBranchStaff: blnBranchType ? true : false },
    ...blnBranchType && { branchId: selectedBranchId },
    ...blnBranchType && { branchName: selectedBranchName },
    ...blnHQType && { departmentId: selectedDepartmentId },
    ...blnHQType && { departmentName: selectedDepartmentName },
    ...!blnAllActive && {isActive: blnIsActive},
    ...!blnAllConfirmed && {isConfirmed: blnIsConfirmed}
  }

  useEffect(() => {
    async function fetchEmployeeListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.employee)
      if (blnBackNavigation){
        if (employeePaginationParams){
          setCurrentPage(employeePaginationParams.pageNumber)
          if (employeePaginationParams.hasOwnProperty("isBranchStaff")){
            if(employeePaginationParams.isBranchStaff){
              setSelectedEmployeeType(newSpaceBeforeCapitalLetter(Constants.branchStaff))
              setSelectedEmployeeTypeId(1)
              fetchPositionListAPI(1);
            }
            else{
              setSelectedEmployeeType(newSpaceBeforeCapitalLetter(Constants.hqStaff))
              setSelectedEmployeeTypeId(0)
              fetchPositionListAPI(0);
            }
          }
          if (employeePaginationParams.branchName && employeePaginationParams.branchId) {
            setSelectedBranchName(employeePaginationParams.branchName);
            setSelectedBranchId(employeePaginationParams.branchId);
          }
          if (employeePaginationParams.departmentName && employeePaginationParams.departmentId) {
            setSelectedDepartmentName(employeePaginationParams.departmentName);
            setSelectedDepartmentId(employeePaginationParams.departmentId);
          }
          if (employeePaginationParams.positionName && employeePaginationParams.positionId) {
            setSelectedPositionName(employeePaginationParams.positionName);
            setSelectedPositionId(employeePaginationParams.positionId);
          }
          if(employeePaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(employeePaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          if(employeePaginationParams.hasOwnProperty('isConfirmed')){
            setBlnIsConfirmed(employeePaginationParams.isConfirmed || false);
            setBlnAllConfirmed(false);
          }
          validation.setValues((values) => ({
            ...values,
            employeeName: employeePaginationParams.name || "",
            employeeNo: employeePaginationParams.employeeNo || "",
          }))
          employeeRequestBody = employeePaginationParams;
        }
      }
      else {
        setEmployeePaginationParams(undefined);
      }

      let aryAPI: any = [
        getStaticReferenceWithType(Constants.employeeType, getBranchUser() ? "" : AllType),
        getEmployee(employeeRequestBody),
        getBranchDropDown(),
        getDepartmentDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setEmployeeTypeList(resultAPI[0])
      setBranchListFinal(arraySpliceInAllValue(resultAPI[2], isEligibleAllBranch ? AllBranch : "", true))
      setDepartmentListFinal(arraySpliceInAllValue(resultAPI[3], AllDepartment, false, "name", "id"))
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageEmployee], true)) {
      return;
    }

    fetchEmployeeListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.employee)
        if (!blnSetParams) {
          setEmployeePaginationParams(undefined);
        }
      }
      setPositionDropdownList([]);
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchEmployeeApi(true);
    }
  }, [selectedBranchId, selectedDepartmentId, triggerSearch, blnAllActive, blnIsActive, blnAllConfirmed, blnIsConfirmed])

  useEffect(()=> {
    if ((selectedEmployeeTypeId || selectedEmployeeTypeId === 0) && !initialCallAPI) {
      setSelectedPositionName(AllPosition);
      setSelectedPositionId(" ");

      fetchEmployeeApi(true, 1, true);
      if (selectedEmployeeTypeId !== " ") {
        fetchPositionListAPI();
      }
    }
  }, [selectedEmployeeTypeId])

  async function fetchPositionListAPI(employeeTypeIdTemp?: number) {
    setLoadingPosition(true);
    const resultPositionAPI = await getPositionDropdown(employeeTypeIdTemp || employeeTypeIdTemp === 0 ? employeeTypeIdTemp : selectedEmployeeTypeId);
    setPositionListFinal(arraySpliceInAllValue(resultPositionAPI, AllPosition, false, "name", "id"))
    setLoadingPosition(false);
  }

  async function fetchEmployeeApi(blnDropDown: boolean = false, pageSizeInsert?: number, blnEmptyPositionId?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getEmployee({
      ...employeeRequestBody,
      ...pageSizeInsert && { pageNumber: pageSizeInsert },
      ...blnEmptyPositionId && { positionId: " "},
      ...blnEmptyPositionId && { positionName: AllPosition }
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: EmployeeListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const exportEmployeeReport = async (blnExport: boolean, blnIsEmployeeUpdateName: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      if (!blnIsEmployeeUpdateName) {
        await (exportEmployee({ exportType: 2 }))
      }
      else {
        await exportEmployeeNameUpdate()
      }
    }
    setLocalLoading(false);
  }

  const importEmployeeFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: EmployeeImportObject = { file: fileTemp };
        let resultImportEmployee = await importEmployee(valuesTemp);
        if (resultImportEmployee?.employeeDetails) {
          let employeeDetailsTemp = _.cloneDeep(resultImportEmployee.employeeDetails);
          employeeDetailsTemp = employeeDetailsTemp.map((valueEmployeeDetailsTemp) => ({
            ...valueEmployeeDetailsTemp,
            employmentStartDate: moment(valueEmployeeDetailsTemp.employmentStartDate).format(Constants.displayDateFormat),
            errorsDiplay: valueEmployeeDetailsTemp.errors.length > 0 ? intl.formatMessage({ id: convertArrayToStringDisplay(valueEmployeeDetailsTemp.errors)  })  : ""
          }))
          setImportedEmployeeDetails(employeeDetailsTemp);
          setImportFileModal(false);
          setModalImportedEmployeeDetails(true);
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const importEmployeeNameUpdateFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: EmployeeImportObject = { file: fileTemp };
        let resultImportEmployeeUpdate = await importEmployeeNameUpdate(valuesTemp);
        if (resultImportEmployeeUpdate.status === Constants.success) {
          if (resultImportEmployeeUpdate.data.length > 0) {
            setSuccessMessage(intl.formatMessage({id: "EmployeeNameUpdateImportSuccessWithSkip"}, { rowNumber : convertArrayToStringDisplay(resultImportEmployeeUpdate.data)}))
          }
          else {
            setSuccessMessage(intl.formatMessage({id: "EmployeeNameUpdateImportSuccess"}))
          }
          setImportEmployeeNameUpdateModal(false);
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const refreshDataAfterEmployeeImport = () => {
    setCurrentPage(1);
    fetchEmployeeApi(true, 1);
    setTimeout(() => {
      setModalImportedEmployeeDetails(false);
      setImportedEmployeeDetails([]);
    }, 2000)
  }

  //Table Content UI
  const EmployeeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "employeeNo",
      text: intl.formatMessage({ id: "EmployeeNo" }).toUpperCase(),
    },
    {
      dataField: "preferredName",
      text: intl.formatMessage({ id: "PreferredName" }).toUpperCase(),
    },
    {
      dataField: "fullName",
      text: intl.formatMessage({ id: "FullName" }).toUpperCase(),
    },
    {
      dataField: "username",
      text: intl.formatMessage({ id: "Username" }).toUpperCase(),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
    },
    {
      dataField: "roleName",
      text: intl.formatMessage({ id: "Role" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {newSpaceBeforeCapitalLetter(row.roleName)}
        </div>
      ),
    },
    {
      dataField: "positionName",
      text: intl.formatMessage({ id: "Position" }).toUpperCase(),
    },
    {
      dataField: "departmentOrBranch",
      text: intl.formatMessage({ id: blnHQType ? "Department" : blnBranchType ? "Branch" : "DepartmentBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.departmentOrBranch || "-"}
        </div>
      ),
    },
    {
      dataField: "isEnableLogin",
      text: intl.formatMessage({ id: "EnableLogin" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isEnableLogin ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    // {
    //   dataField: "isReceiveNotification",
    //   text: intl.formatMessage({ id: "ReceiveNotification" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.isReceiveNotification ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
    //     </div>
    //   ),
    // },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.employee}/view/${row.id}`}
              type="view" />
            {
              blnPermissionUpdateEmployee
              &&
              <TableActionButton
                to={`/${RoutesList.employee}/edit/${row.id}`}
                type="edit" />
            }
            {
              blnPermissionResetEmployeePassword
              &&
              row.isEnableLogin 
              &&
              <TableActionButton 
                type="cancel"
                onClick={()=> {
                  setSelectedEmployeeDetail(row);
                  setResetPasswordPrompt(true);
                }}
                iconReplaceUI={<LockResetIcon size={18} id="resetEmployeePasswordTooltip" />}
                iconId="resetEmployeePasswordTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "ResetPassword" })}`}
                disabled={false}/>
            }
            {
              blnPermissionDeleteEmployee
              &&
              <TableActionButton
                type="delete"
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const returnImportExportDropdownButton = (blnImport: boolean) => {
    return (
      <ButtonDropdown
        isOpen={blnImport ? importMenu : exportMenu}
        toggle={() => blnImport ? setImportMenu(!importMenu) : setExportMenu(!exportMenu)}
        className={"d-inline-block"}
        disable={loading ? true : false}
        style={{ alignSelf: 'center' }}>
        <DropdownToggle
          className={`btn ${blnImport ? 'btn-primary' : 'btn-info'} margin-left-8`}
          id={`${blnImport ? `page-header-import-dropdown` : `page-header-export-dropdown`}`}
          tag="button"
          style={{ minWidth: '120px', height: "36px" , borderRadius:'0.25rem' }}>
          <span className="ms-2 me-1">{blnImport ? intl.formatMessage({ id: "Import" }) : intl.formatMessage({ id: "Export" })}</span>
          <ChevronDownIcon size={Constants.menuIconSize}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => blnImport ? setImportFileModal(true) : exportEmployeeReport(true, false)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Employee" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "EmployeeTemplate" }) })
            }
          </DropdownItem>
          <DropdownItem tag="a" onClick={() => blnImport ? setImportEmployeeNameUpdateModal(true) : exportEmployeeReport(true, true)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "EmployeeNameUpdate" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "EmployeeNameUpdateTemplate" }) })
            }
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  const blnViewAllFunction = (blnActive) => {
    setCurrentPage(1);
    if (blnActive) {
      setBlnAllActive(true);
    }
    else {
      setBlnAllConfirmed(true);
    }
  }

  const blnActiveOnlyFunction = (blnActive) => {
    setCurrentPage(1);
    if (blnActive) {
      setBlnIsActive(true);
      setBlnAllActive(false);
    }
    else {
      setBlnIsConfirmed(true);
      setBlnAllConfirmed(false);
    }
  }

  const blnInactiveOnlyFunction = (blnActive) => {
    setCurrentPage(1);
    if (blnActive) {
      setBlnIsActive(false);
      setBlnAllActive(false);
    }
    else { 
      setBlnIsConfirmed(false);
      setBlnAllConfirmed(false);
    }
  }

  const displayPositionDropdown = () => {
    return (
      <DoubleColumnRowParent>
        {
          loadingPosition
          ?
          <Loading/>
          :
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Position" })}
            name="positionName"
            options={positionListFinal}
            labelField="name"
            valueField="id"
            initialLabel={selectedPositionName}
            initialValue={selectedPositionId}
            setFieldLabel={setSelectedPositionName}
            setFieldValue={setSelectedPositionId}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              onClickSearch();
            }}
          />
        }
      </DoubleColumnRowParent>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
        title={intl.formatMessage({ id: "Employee" })}
        addButton={
          blnPermissionCreateEmployee
          &&
          <>
            <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
            onClick={() => { history.push(`/${RoutesList.employee}/add`) }}
            linkTo={`/${RoutesList.employee}/add`}
            disable={localLoading || loading} />
            {/* import */}
            {returnImportExportDropdownButton(true)} 
            {/* export */}
            {returnImportExportDropdownButton(false)} 
            {/* <MyButton
            content={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
            onClick={() => { setImportFileModal(true) }}
            class={'btn btn-primary margin-left-8'}
            disable={localLoading || loading} />
            <MyButton
            content={intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Template" }) })}
            onClick={() => { exportEmployeeReport(true) }}
            class={'btn btn-info margin-left-8'}
            disable={localLoading || loading} /> */}
          </>
        }
        addButtonXL={"5"}>
        <Row>
          {
            !initialCallAPI &&
            !getBranchUser() &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "EmployeeType" })}
                name="employeeType"
                options={employeeTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedEmployeeType}
                initialValue={selectedEmployeeTypeId}
                setFieldLabel={setSelectedEmployeeType}
                setFieldValue={setSelectedEmployeeTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="employeeName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterEmployeeName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "EmployeeNo" })}
              name="employeeNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterEmployeeNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          !getBranchUser()
          &&
          blnBranchType
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            {displayPositionDropdown()}
          </Row>
        }
        {
          !getBranchUser()
          &&
          blnHQType
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Department" })}
                name="departmentName"
                options={departmentListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedDepartmentName}
                initialValue={selectedDepartmentId}
                setFieldLabel={setSelectedDepartmentName}
                setFieldValue={setSelectedDepartmentId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            {displayPositionDropdown()}
          </Row>
        }
        <Row>
          <Col xl={'3'} md={'6'} xs={'12'} className={`margin-bottom--8`}>
            <div className="btn-group mb-3" role="group">
              {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), ()=> {blnViewAllFunction(true)}, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), ()=> {blnActiveOnlyFunction(true)}, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), ()=> {blnInactiveOnlyFunction(true)}, tabButtonWidth)}
            </div>
          </Col>
          <Col xl={'3'} md={'6'} xs={'12'}>           
            <div className="btn-group" role="group">
              {returnSwitchTabButtonUI("blnViewAllConfirmed", "blnViewAllConfirmed", blnAllConfirmed, loading || localLoading, intl.formatMessage({ id: "All" }), ()=> {blnViewAllFunction(false)}, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsConfirmed", "blnIsConfirmedTrue", blnIsConfirmed && !blnAllConfirmed, loading || localLoading, intl.formatMessage({ id: "Confirm" }), ()=> {blnActiveOnlyFunction(false)}, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsConfirmed", "blnIsConfirmedFalse", !blnIsConfirmed && !blnAllConfirmed, loading || localLoading, intl.formatMessage({ id: "NonConfirm" }), ()=> {blnInactiveOnlyFunction(false)})}
            </div>
          </Col>
        </Row>
        {
          localLoading ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={EmployeeColumns}
              data={employeeList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
              objSorted={defaultSorted}
              name={validation.values.employeeName}
              employeeNo={validation.values.employeeNo}
              isBranchStaff={selectedEmployeeType == AllType ? "" : blnBranchType ? true : false}
              branchId={blnBranchType ? selectedBranchId : undefined}
              branchName={blnBranchType ? selectedBranchName : undefined}
              departmentId={blnHQType ? selectedDepartmentId : undefined}
              departmentName={blnHQType ? selectedDepartmentName : undefined}
              positionId={blnBranchType || blnHQType ? selectedPositionId : undefined}
              positionName={blnBranchType || blnHQType ? selectedPositionName : undefined}
              isActive={!blnAllActive ? blnIsActive : undefined}
              isConfirmed={!blnAllConfirmed ? blnIsConfirmed : undefined}
              requestAPI={getEmployee} />
        }
        {
          importFileModal
          &&
          <ImportFileModal
            blnShow={importFileModal}
            setModal={setImportFileModal}
            title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
            btnFunction={importEmployeeFunction}
          />
        }
        {
          importEmployeeNameUpdateModal
          &&
          <ImportFileModal
            blnShow={importEmployeeNameUpdateModal}
            setModal={setImportEmployeeNameUpdateModal}
            title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "EmployeeNameUpdate" }) })}
            btnFunction={importEmployeeNameUpdateFunction}
          />
        }
        <DeleteModal
          displayName={selectedRow?.preferredName}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteEmployee(selectedRow?.id || "", selectedRow?.preferredName || "");
            let currentPageTemp = currentPage;
            if (employeeList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getEmployee({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
        {
          modalImportedEmployeeDetails
          &&
          <ImportEmployeeModal
            blnShow={modalImportedEmployeeDetails}
            setModal={setModalImportedEmployeeDetails}
            importedEmployeeDetails={importedEmployeeDetails}
            setImportedEmployeeDetails={setImportedEmployeeDetails}
            refreshData={refreshDataAfterEmployeeImport} />
        }
        {
          resetPasswordPrompt
          &&
          <ActionPromptModal 
            title={intl.formatMessage({id: "EmployeePasswordResetMessage"}, {name: `${selectedEmployeeDetail?.preferredName} (${selectedEmployeeDetail?.employeeNo})`})}
            primaryActionTitle={`Yes`}
            showPrompt={resetPasswordPrompt}
            setShowPrompt={setResetPasswordPrompt}
            localLoading={localLoading}
            onPrimaryClick={async ()=> {
              setLocalLoading(true);
              let resultEmployeePasswordReset = await resetEmployeePassword(selectedEmployeeDetail?.id || "");
              if (resultEmployeePasswordReset.status === Constants.success) {
                setPasswordFromResetPasswordResponse(resultEmployeePasswordReset.data.password)
                setResetPasswordSuccessPrompt(true);
                setResetPasswordPrompt(false);
              }
              setLocalLoading(false);
            }}/>
        }
        {
          resetPasswordSuccessPrompt
          &&
          <ActionPromptModal 
            title={intl.formatMessage({id: "EmployeePasswordResetMessageSuccess"}, {name: `${selectedEmployeeDetail?.preferredName} (${selectedEmployeeDetail?.employeeNo})`, password: passwordFromResetPasswordResponse})}
            primaryActionTitle={`Yes`}
            showPrompt={resetPasswordSuccessPrompt}
            setShowPrompt={setResetPasswordSuccessPrompt}
            blnHideCancelButton={true}
            blnShowCloseButton={true}
            onPrimaryClick={()=> {}}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Employee);
