import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { NotificationDetailObject, NotificationListObject } from '../../app/models/notification';
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import NotificationDetailModal from "./Modal/NotificationDetailModal";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import Loading from "../../app/components/loading/Loading";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import MyButton from "../../app/components/form/MyButton";
import { DateRangeInterface } from "../../app/models/common";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import { history } from "../..";
import moment from "moment";

const Notification = () => {
  //Use Intl
  const intl = useIntl();
  //Use Store
  const { notificationStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { notificationList, notificationDetail, getNotification, getNotificationWithId } = notificationStore;
  const { notificationType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedNotificationType, setSelectedNotificationType] = useState<string>(AllType);
  const [selectedNotificationTypeId, setSelectedNotificationTypeId] = useState<number | string | undefined>(" ");
  const [notificationPermissionsListingModal, setNotificationDetailModal] = useState(false);
  const [notificationTypeList, setNotificationTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [triggerSearch, setTriggerSearch] = useState(0);
  const location = useLocation();
  const [blnViewAll, setBlnViewAll] = useState(false);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      to: "",
      isViewAll: false,
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchNotificationListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchNotificationAPI(false),
        getStaticReferenceWithType(Constants.notificationType, AllType),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setNotificationTypeList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageNotification], true)) {
      return;
    }

    fetchNotificationListAPI();

  }, []);

  useEffect(() => {
    if (location.state) {
      onClickView({ id: location.state.detail })
    }
  }, [location]);

  useEffect(() => {
    if (
      (selectedNotificationTypeId || selectedNotificationTypeId === 0) &&
      !initialCallAPI
    ) {
      fetchNotificationAPI(true);
    }
  }, [selectedNotificationTypeId, selectedDateRange, triggerSearch, blnViewAll]);

  async function fetchNotificationAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getNotification({
      pageNumber: currentPage,
      pageSize: pageSize,
      notificationType: selectedNotificationTypeId,
      to: validation.values.to,
      isViewAll: blnViewAll,
      dateRange: selectedDateRange
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickView = async (row: NotificationListObject | any) => {
    setLoading(true);
    let resultNotificationWithId = await getNotificationWithId(row.id);
    setLoading(false);
    if (resultNotificationWithId?.status === Constants.success) {
      setNotificationDetailModal(true);
    }
  }

  //Table Content UI
  const NotificationColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.createdAt !== Constants.invalidDateTime ? moment(row.createdAt).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.createdAt !== Constants.invalidDateTime ? moment(row.createdAt).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "notificationType",
      text: intl.formatMessage({ id: "NotificationType" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {newSpaceBeforeCapitalLetter(row.notificationType)}
      </>, 
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
    },
    {
      dataField: "subject",
      text: intl.formatMessage({ id: "Subject" }).toUpperCase(),
    },
    {
      dataField: "isNew",
      text: intl.formatMessage({ id: "New" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isNew ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isSent",
      text: intl.formatMessage({ id: "Sent" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isSent ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isRead",
      text: intl.formatMessage({ id: "Read" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isRead ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              type={'view'}
              onClick={() => onClickView(row)} />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Notification" }) })}
        title={intl.formatMessage({ id: "Notification" })}
      >
        <Row>
          {
            !initialCallAPI &&
            <DoubleColumnRowParent >
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "NotificationType" })}
                name="notificationType"
                options={notificationTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedNotificationType}
                initialValue={selectedNotificationTypeId}
                setFieldLabel={setSelectedNotificationType}
                setFieldValue={setSelectedNotificationTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          {
            !initialCallAPI
            &&
          <DoubleColumnRowParent>
              <Label>{intl.formatMessage({ id: "Receiver" })}</Label>
              <div className="btn-group d-flex mb-3" role="group">
                <input type="radio" className="btn-check" name="blnViewType" id="blnViewSentToMe" autoComplete="off" defaultChecked={true} disabled={localLoading || loading}
                  onClick={async ()=> { 
                    setBlnViewAll(false);
                    setCurrentPage(1);
                    validation.setFieldValue("to", "");
                  }}/>
                <label style={{zIndex: 0, minWidth: '120px', maxWidth: '120px'}} className="btn btn-outline-primary" htmlFor="blnViewSentToMe">
                  {intl.formatMessage({ id: "SentToMe" })}
                </label>
                <input type="radio" className="btn-check v" name="blnViewType" id="blnViewSentToAll" autoComplete="off" defaultChecked={false} disabled={localLoading || loading}
                  onClick={async ()=> { 
                    setBlnViewAll(true);
                    setCurrentPage(1);
                  }}/>
                <label style={{zIndex: 0, minWidth: '120px', maxWidth: '120px'}} className="btn btn-outline-primary" htmlFor="blnViewSentToAll">
                  {intl.formatMessage({ id: "SentToAll" })}
                </label>
              </div>
            </DoubleColumnRowParent>
          }
          {
            blnViewAll &&
            <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "To" })}
                  name="to"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "ContactNo/Email" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
            </DoubleColumnRowParent>
          }
        </Row>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={NotificationColumns}
              data={notificationList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Notification" }) })}
              objSorted={defaultSorted}
              notificationType={selectedNotificationTypeId}
              notificationTypeName={selectedNotificationType}
              to={validation.values.to}
              isViewAll={blnViewAll}
              dateRange={selectedDateRange}
              requestAPI={getNotification}
            />
          </>
        )}
        {
          notificationPermissionsListingModal
          &&
          <NotificationDetailModal
            blnShow={notificationPermissionsListingModal}
            setModal={setNotificationDetailModal}
            selectedNotificationDetail={notificationDetail} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Notification);
