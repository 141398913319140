import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { capitalizeFirstLetter, checkPermission, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { CacheListObject } from "../../app/models/cache";
import MyButton from "../../app/components/form/MyButton";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Label, Row } from "reactstrap";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";

const Cache = () => {
  const intl = useIntl();
  //Use Store
  const { cacheStore, settingsStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt } = commonStore;
  const { cacheList, getCache, getCacheWithId, getCacheSize, deleteCache } = cacheStore;
  const { clearCache } = settingsStore;
  const [clearCacheModal, setClearCacheModal] = useState(false);
  const [cacheDetailModal, setCacheDetailModal] = useState(false);
  const [cacheDetail, setCacheDetail] = useState<any>([]);
  const [selectedCacheName, setSelectedCacheName] = useState("");
  const [cacheColumns, setCacheColumns] = useState<any>([]);
  const [loadingCache, setLoadingCache] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  
  //Data List
  const [selectedRow, setSelectedRow] = useState<CacheListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;

  const validation = useFormik({
		initialValues: {
      blnShowAll: false
		},
		validationSchema: Yup.object({}),
		onSubmit: async (values) => {
    }
	});
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCacheAPI() {
      setLoading(true);
      await getCacheLocal();
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCache], true)) {
      return;
    }

    fetchCacheAPI();
  }, [])

  const getCacheLocal = async (blnShowAll ?: boolean) => {
    await getCache(blnShowAll !== undefined ? blnShowAll : validation.values.blnShowAll);
  }

  const onClickDelete = async (row: CacheListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickClearCache = async () => {
    setLoading(true);
    await clearCache();
    await getCacheLocal();
    setLoading(false);
  }

  //Table Content UI
  const CacheColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width: '60%'}}>
          {row.name}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              disabled={loadingCache}
              onClick={()=> {
                setSelectedCacheName(row.name);
                onClickCacheDetail(row.name);
              }}
              type="view"/>
            <TableActionButton 
              disabled={loadingCache}
              onClick={() => onClickDelete(row)}
              type="delete"/>
          </div>
        </>
      ),
    },
  ];

  const onClickCacheDetail = async (cacheKey: string) => {
    setLoadingCache(true);
    let resultCacheDetail = await getCacheWithId(cacheKey);
    if (resultCacheDetail.status === Constants.success) {
      if (!Array.isArray(resultCacheDetail.data)) {
        resultCacheDetail.data = [resultCacheDetail.data];
      }
      setCacheDetail(resultCacheDetail.data);
      if (resultCacheDetail.data.length > 0) {
        let keyOfCacheDetails = Object.keys(resultCacheDetail.data[0]);
        setCacheColumns(keyOfCacheDetails.map((valueKeyOfCacheDetails)=> ({
          dataField: valueKeyOfCacheDetails,
          text: newSpaceBeforeCapitalLetter(capitalizeFirstLetter(valueKeyOfCacheDetails)),
          formatter: (cellContent, row) => (
            <>
              {typeof row[valueKeyOfCacheDetails] === 'object' || Array.isArray(row[valueKeyOfCacheDetails]) ? JSON.stringify(row[valueKeyOfCacheDetails]) : row[valueKeyOfCacheDetails]}
            </>
          )  
        })))
      }
      else {
        setCacheColumns([{dataField: 'id', text: ''}]);
      }

      setTimeout(()=> {
        setCacheDetailModal(true);
      }, 100);
    }
    setLoadingCache(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Cache" }) })}
        title={intl.formatMessage({ id: "Cache" })}
        addButton={
          <>
            <MyButton
              content={intl.formatMessage({ id: "GetCacheSize" })}
              onClick={async () => {
                setLoadingCache(true);
                await getCacheSize();
                setLoadingCache(false);
              }}
              class={'btn btn-success'}
              disable={loading || loadingCache || localLoading} />
            <MyButton
              content={intl.formatMessage({ id: "ClearAllCache" })}
              onClick={() => { setClearCacheModal(true) }}
              class={'btn btn-danger margin-left-8'}
              disable={loading || loadingCache || localLoading} />
          </>
        }
        addButtonXL={"4"}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "ShowAll" })}
              name="blnShowAll"
              type="checkbox"
              disabled={localLoading || loadingCache || loading}
              validation={validation}
              onChangeFunctionWithSetValidationField={async (blnValue)=> {
                await getCacheLocal(blnValue);
              }}
              childrenUI={
                <Label className="margin-bottom-18 margin-left-4">
                  {intl.formatMessage({ id: "Yes" })}
                </Label>
              }
            />
          </DoubleColumnRowParent>
        </Row>
        <div className="standard-layout">
          <PaginationTableWithoutApi 
            title={intl.formatMessage({ id: "Cache" })}
            blnDisplayForMainListing={true}
            options={cacheList}
            columns={CacheColumns}
            blnShowSearchBar={true}
            pageSize={Constants.maxPageSize}
            keyField={"name"}/>
        </div>
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCache(selectedRow?.name || "");
            await getCacheLocal();
            setLoading(false);
          }} />
        {
          clearCacheModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "ClearCacheMessage" })} \n\n ${intl.formatMessage({ id: "ClearAllCacheAlert" })} `}
            primaryActionTitle={`Yes`}
            showPrompt={clearCacheModal}
            setShowPrompt={setClearCacheModal}
            onPrimaryClick={() => { onClickClearCache()}}
          />
        }
        {
          cacheDetailModal &&
          <SelectWithTableListModal
            blnShow={cacheDetailModal}
            setModal={setCacheDetailModal}
            title={selectedCacheName}
            options={cacheDetail}
            keyField={"name"}
            fieldName={"name"}
            columns={cacheColumns}
            totalItem={cacheDetail.length}
            validation={validation}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Cache);
