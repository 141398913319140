import { useEffect, useState } from "react";
import { Modal, FormFeedback, Label, Row, Col } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { AddOptionsValueField, checkPermission, getBranchUser, removeAllSpace, returnCurrency, returnMalaysiaCurrencyRounding, returnPriceWithCurrency, returnRefundStatusColor, returnSubBrandList } from "../../../app/common/function/function";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import Borderline from "../../../app/components/form/BorderLine";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId?: any;
  creditAdjustmentId?: any;
  creditAdjustmentType?: StaticReferenceDataObject[];
  debitType?: StaticReferenceDataObject[];
  previewCreditAdjustment?: Function;
  requestCreditAdjustment?: Function;
  creditAdjustmentReview?: Function;
  setErrorMessage?: Function;
  selectedCreditAdjustmentDetail?: any;
  blnView?: boolean;
}

export default function CreditAdjustmentModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [previewAction, setPreviewAction] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewDetail, setPreviewDetail] = useState<any>([]);
  const [creditAdjustmentOptionType, setCreditAdjustmentOptionType] = useState<any>([]);
  // const [bankAccountOptionType, setBankAccountOptionType] = useState<any>([]);
  const [loadingAdjust, setLoadingAdjust] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [selectedSubBrandName, setSelectedSubBrandName] = useState("");
  const [selectedSubBrandId, setSelectedSubBrandId] = useState("");

  const defaultCreditAdjustmentDetails = {
    customerId: props.customerId || "",
    adjustmentType: "",
    creditAdjustmentAmount: 0,
    reason: "",
    remark: "",
    accountHolderName: "",
    identityNo: "",
    bankAccountType: "",
    bankAccountNo: "",
    status: "",
    subTotalAdjustmentAmount: 0,
    processingFees: 0,
    totalAdjustmentAmount: 0,
    isIgnoreProcessingFee: false,
    subBrandId: selectedSubBrandId,
    subBrandName: selectedSubBrandName
  }
  const defaultReviewCreditAdjustmentDetails = {
    id: "",
    remark: "",
    workflowAction: "",
    revisedProcessingFee: 0,
    subTotalAdjustmentAmount: 0,
    totalAdjustmentAmount: "0",
    rounding: 0
  }
  const [creditAdjustmentDetails, setCreditAdjustmentDetails] = useState(defaultCreditAdjustmentDetails)
  const [reviewCreditAdjustmentDetails, setReviewCreditAdjustmentDetails] = useState(defaultReviewCreditAdjustmentDetails)
  const [errorState, setErrorState] = useState({
    adjustmentType: false,
    creditAdjustmentAmount: false,
    reason: false,
    accountHolderName: false,
    identityNo: false,
    bankAccountType: false,
    bankAccountNo: false,
    remark: false,
    subBrandId: false
  })

  useEffect(() => {
    if (props.selectedCreditAdjustmentDetail) {
      if(props.blnView){
        setCreditAdjustmentDetails(props.selectedCreditAdjustmentDetail)
        setReviewCreditAdjustmentDetails({
          ...reviewCreditAdjustmentDetails, 
          revisedProcessingFee: props.selectedCreditAdjustmentDetail.processingFees.toFixed(2), 
          subTotalAdjustmentAmount: props.selectedCreditAdjustmentDetail.subTotalAdjustmentAmount.toFixed(2),
          totalAdjustmentAmount: props.selectedCreditAdjustmentDetail.totalAdjustmentAmount.toFixed(2),
          rounding: props.selectedCreditAdjustmentDetail.rounding.toFixed(2)
        })

        if(props.selectedCreditAdjustmentDetail.subBrandId){
          onChangeSubBrand(props.selectedCreditAdjustmentDetail.subBrandId)
        }
        else{
          setSelectedSubBrandId(" ")
          setSelectedSubBrandName(Constants.emptyData)
        }
      }
    }
  }, [props.selectedCreditAdjustmentDetail])

  useEffect(() => {
    if (props.creditAdjustmentType) {
      // let creditAdjustmentTypeTemp = getBranchUser() ? AddOptionsValueField([props.creditAdjustmentType[0]], "displayValue", "displayValue") : AddOptionsValueField(props.creditAdjustmentType, "displayValue", "displayValue");
      let creditAdjustmentTypeTemp = AddOptionsValueField(props.creditAdjustmentType, "displayValue", "displayValue");
      // if (getBranchUser() && !props.blnView) {
      //   setCreditAdjustmentDetails((valueCreditAdjustmentDetailsTemp) => ({
      //     ...valueCreditAdjustmentDetailsTemp,
      //     adjustmentType: props.creditAdjustmentType ? props.creditAdjustmentType[0].displayValue : ""
      //   }))
      // }
      setCreditAdjustmentOptionType(creditAdjustmentTypeTemp)
    }
  }, [props.creditAdjustmentType])

  useEffect(() => {
    if (successMessage && !props.blnView) {
      setTimeout(() => {
        hideModal();
        history.push(`/${RoutesList.customer}/view/${props.customerId}`);
      }, Constants.dismissSuccessMessage)
    }
    else if (successMessage && props.blnView) {
      setTimeout(() => {
        hideModal();
        history.push(`/${RoutesList.creditAdjustment}`);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const blnCashType = removeAllSpace(creditAdjustmentDetails.adjustmentType) == Constants.cashAdjustment;
  const blnPendingStatus = creditAdjustmentDetails?.status == Constants.pending;
  const blnPermissionRequestCreditAdjustment = checkPermission([PermissionConstants.RequestCreditAdjustment]);
  const blnPermissionReviewCreditAdjustment = checkPermission([PermissionConstants.ReviewCreditAdjustment]);
  const blnHasSubBrand = returnSubBrandList().length > 0;
  const disabledFieldInput = loadingPreview || previewAction || props.blnView;

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      adjustmentType: false,
      creditAdjustmentAmount: false,
      reason: false,
      accountHolderName: false,
      identityNo: false,
      bankAccountType: false,
      bankAccountNo: false,
      remark: false,
      subBrandId: false
    });
  }

  const onChangeAdjustmentType = (type) => {
    let typeTemp = removeAllSpace(type)
    if (typeTemp !== Constants.cashAdjustment) {
      setCreditAdjustmentDetails(valueTemp => ({
        ...valueTemp,
        accountHolderName: "",
        identityNo: "",
        bankAccountType: "",
        bankAccountNo: "",
        adjustmentType: typeTemp,
      }));
    }
    else {
      setCreditAdjustmentDetails(valueTemp => ({
        ...valueTemp,
        adjustmentType: typeTemp,
      }));
    }
    clearErrorState("adjustmentType");
  }

  const onChangeSubBrand = (id: string) => {
    if(blnHasSubBrand){
      let subBrandListTemp = returnSubBrandList();
      let indexSubBrand = _.findIndex(subBrandListTemp, { id: id });
      if (indexSubBrand > -1) {
        setCreditAdjustmentDetails((values) => ({
          ...values,
          subBrandId: subBrandListTemp[indexSubBrand].id || "",
          subBrandName: subBrandListTemp[indexSubBrand].name || ""
        }))
        setSelectedSubBrandId(subBrandListTemp[indexSubBrand].id || "")
        setSelectedSubBrandName(subBrandListTemp[indexSubBrand].name)
        clearErrorState("subBrandId")
      }
    }
  }

  const hideModal = () => {
    clearErrorState();
    setCreditAdjustmentDetails(defaultCreditAdjustmentDetails);
    if (props.blnView) {
      setReviewCreditAdjustmentDetails(defaultReviewCreditAdjustmentDetails);
    }
    props.setModal(false);
  }

  const onClickPreviewCreditAdjustment = async (creditAdjustmentDetailTemp: any) => {
    if (props.previewCreditAdjustment) {
      setLoadingPreview(true);
      let resultPreview = await props.previewCreditAdjustment(creditAdjustmentDetailTemp)
      if (resultPreview && resultPreview.resultPreviewCreditAdjustmentsDetail) {
        setPreviewDetail({...resultPreview.resultPreviewCreditAdjustmentsDetail, 
          subTotalAdjustmentAmount: resultPreview.resultPreviewCreditAdjustmentsDetail.subTotalAdjustmentAmount.toFixed(2),   
          revisedProcessingFee: resultPreview.resultPreviewCreditAdjustmentsDetail.processingFees,
          rounding: resultPreview.resultPreviewCreditAdjustmentsDetail.rounding.toFixed(2)} );
        setPreviewAction(true);
      }
      setLoadingPreview(false);
    }
  }

  const onClickRequestCreditAdjustment = async (previewDetailTemp: any) => {
    if (props.requestCreditAdjustment) {
      setLoadingAdjust(true);
      await props.requestCreditAdjustment(previewDetailTemp)
      setLoadingAdjust(false);
    }
  }

  const onClickReviewCreditAdjustment = async (valueAction: string) => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...reviewCreditAdjustmentDetails, id: props.selectedCreditAdjustmentDetail.id, workflowAction: valueAction })
    setReviewCreditAdjustmentDetails(reviewCreditAdjustmentTemp);
    if (props.creditAdjustmentReview) {
      setLoadingReview(true);
      await props.creditAdjustmentReview(reviewCreditAdjustmentTemp)
      setLoadingReview(false);
    }
  }
  const updateAdjustmentAmount = (valueAdjustmentAmount: any) => {
    let reviewCreditAdjustmentDetailsTemp = _.cloneDeep(reviewCreditAdjustmentDetails)
    let subTotalAdjustmentAmountTemp = reviewCreditAdjustmentDetailsTemp.subTotalAdjustmentAmount;
    let calculatedSubTotalAdjustmentAmountTemp = String(Number(Number(subTotalAdjustmentAmountTemp) - (valueAdjustmentAmount ? valueAdjustmentAmount.toFixed(2) : "")).toFixed(2));
    
    let finalSubTotalAdjustmentAmountAfterRounding = returnMalaysiaCurrencyRounding(calculatedSubTotalAdjustmentAmountTemp);
    let totalRoundingAmount = Math.round((finalSubTotalAdjustmentAmountAfterRounding - Number(calculatedSubTotalAdjustmentAmountTemp)) * 100) / 100;

    reviewCreditAdjustmentDetailsTemp.totalAdjustmentAmount = String(finalSubTotalAdjustmentAmountAfterRounding.toFixed(2));
    reviewCreditAdjustmentDetailsTemp.revisedProcessingFee = valueAdjustmentAmount; 
    reviewCreditAdjustmentDetailsTemp.rounding = totalRoundingAmount;
    setReviewCreditAdjustmentDetails(reviewCreditAdjustmentDetailsTemp);
  }
  
  const displayHeaderRowData = (label, fieldName) => {
    let blnProcessingFee = label == Constants.processingFees;

    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end " style={{ fontSize: '14px', fontWeight: 'bold', lineHeight: '67px' }}>
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <GeneralInputForModal
            name={fieldName}
            className={""}
            inputGroup={true}
            inputGroupText={returnCurrency()}
            type="number"
            validationRequired={blnProcessingFee}
            field={!props.blnView ? previewDetail : reviewCreditAdjustmentDetails}
            setFieldValue={setReviewCreditAdjustmentDetails}
            numberMaxValue={blnProcessingFee ? reviewCreditAdjustmentDetails.subTotalAdjustmentAmount : 0}
            onChangeFunctionWithValueReturn={updateAdjustmentAmount}
            disabled={loadingReview || Boolean(successMessage) || !blnProcessingFee || !blnPendingStatus  || !blnPermissionReviewCreditAdjustment}
          />
        </Col>
      </Row>
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingPreview && !loadingAdjust && !loadingReview && !successMessage ) {
            hideModal();
          }
        }}
        size={blnCashType ? "lg" : ""}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered
      >
        <div className="modal-header">
        <div className="flex-direction-row" style={{flex:1 }}>
          <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: !props.blnView ? "CreditAdjustment" : "CreditAdjustmentDetails" })}</h5>
          {
            props.blnView &&
            <GeneralStatus
            className="margin-right-38"
            status={creditAdjustmentDetails.status}
            statusColorFunction={returnRefundStatusColor}/>
          }
          {
            !loadingPreview && !loadingAdjust && !loadingReview && !successMessage 
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
          </div>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={blnCashType ? 6 : 12}>
              <Label>
                {blnPendingStatus && <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>}
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "CreditAdjustmentType" })}</Label>
              </Label>
              <GeneralSelectDropdown
                title=""
                name=""
                className={errorState.adjustmentType ? "mb-0" : "mb-3"}
                options={creditAdjustmentOptionType}
                onChange={onChangeAdjustmentType}
                initialLabel={creditAdjustmentDetails.adjustmentType}
                initialValue={creditAdjustmentDetails.adjustmentType}
                blnValueWithNewSpace={true}
                disabled={disabledFieldInput}
                validationRequired={true}
              />
              {errorState.adjustmentType ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CreditAdjustmentType" }) })}</FormFeedback>
              ) : null}
              <GeneralInputForModal
                title={intl.formatMessage({ id: "CreditAdjustmentAmount" })}
                name={!props.blnView ? "creditAdjustmentAmount" : "subTotalAdjustmentAmount"}
                className={errorState.creditAdjustmentAmount ? "mb-0" : "mb-3"}
                type="number"
                validationRequired={true}
                field={creditAdjustmentDetails}
                setFieldValue={setCreditAdjustmentDetails}
                onChangeFunction={() => { clearErrorState("creditAdjustmentAmount") }}
                disabled={disabledFieldInput}
              />
              {errorState.creditAdjustmentAmount ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CreditAdjustmentAmount" }) })}</FormFeedback>
              ) : null}
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Reason" })}
                name="reason"
                className={errorState.reason ? "mb-0" : "mb-3"}
                row={5}
                validationRequired={true}
                field={creditAdjustmentDetails}
                setFieldValue={setCreditAdjustmentDetails}
                onChangeFunction={() => { clearErrorState("reason") }}
                disabled={disabledFieldInput} />
              {errorState.reason ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}</FormFeedback>
              ) : null}
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Remark" })}
                name="remark"
                row={5}
                validationRequired={false}
                field={creditAdjustmentDetails}
                setFieldValue={setCreditAdjustmentDetails}
                disabled={disabledFieldInput} />
            </Col>
            <Col xl={6}>
              {blnCashType &&
                <>
                  {
                    blnHasSubBrand
                    &&
                    <>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "SubBrand" })}
                        name="subBrandName"
                        className={errorState.subBrandId ? "mb-0" : "mb-3"}
                        options={returnSubBrandList()}
                        labelField="name"
                        valueField="id"
                        validationRequired={true}
                        initialLabel={selectedSubBrandName}
                        initialValue={selectedSubBrandId}
                        onChange={onChangeSubBrand}
                        disabled={disabledFieldInput}
                      />
                      {errorState.subBrandId ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) })}</FormFeedback>
                    ) : null}
                    </>
                  }
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "AccountHolderName" })}
                    name="accountHolderName"
                    className={errorState.accountHolderName ? "mb-0" : "mb-3"}
                    type="text"
                    validationRequired={blnCashType}
                    field={creditAdjustmentDetails}
                    setFieldValue={setCreditAdjustmentDetails}
                    onChangeFunction={() => { clearErrorState("accountHolderName") }}
                    disabled={disabledFieldInput}
                  />
                  {errorState.accountHolderName ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AccountHolderName" }) })}</FormFeedback>
                  ) : null}
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "IdentityNo" })}
                    name="identityNo"
                    className={errorState.creditAdjustmentAmount ? "mb-0" : "mb-3"}
                    type="text"
                    blnAlphaNumberic={true}
                    validationRequired={blnCashType}
                    field={creditAdjustmentDetails}
                    setFieldValue={setCreditAdjustmentDetails}
                    onChangeFunction={() => { clearErrorState("identityNo") }}
                    disabled={disabledFieldInput}
                  />
                  {errorState.identityNo ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) })}</FormFeedback>
                  ) : null}
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "BankAccountType" })}
                    name="bankAccountType"
                    className={errorState.bankAccountType ? "mb-0" : "mb-3"}
                    type="text"
                    validationRequired={blnCashType}
                    field={creditAdjustmentDetails}
                    setFieldValue={setCreditAdjustmentDetails}
                    onChangeFunction={() => { clearErrorState("bankAccountType") }}
                    disabled={disabledFieldInput}
                  />
                  {errorState.bankAccountType ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountType" }) })}</FormFeedback>
                  ) : null}
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "BankAccountNo" })}
                    name="bankAccountNo"
                    className={errorState.creditAdjustmentAmount ? "mb-0" : "mb-3"}
                    type="text"
                    validationRequired={blnCashType}
                    field={creditAdjustmentDetails}
                    setFieldValue={setCreditAdjustmentDetails}
                    onChangeFunction={() => { clearErrorState("bankAccountNo") }}
                    disabled={disabledFieldInput}
                  />
                  {errorState.bankAccountNo ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountNo" }) })}</FormFeedback>
                  ) : null}
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "IgnoreProcessingFee" })}
                    name="isIgnoreProcessingFee"
                    type="checkbox"
                    disabled={disabledFieldInput}
                    field={creditAdjustmentDetails}
                    setFieldValue={setCreditAdjustmentDetails}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </>
              }
            </Col>
          </Row>
          {
            (previewAction || props.blnView)
            &&
            <>
              <Borderline />
              <Col className="mt-3">
                {displayHeaderRowData(`${intl.formatMessage({ id: "SubTotalAdjustmentAmount" })}`, 'subTotalAdjustmentAmount')}
                {displayHeaderRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, 'revisedProcessingFee')}
                {displayHeaderRowData(`${intl.formatMessage({ id: "Rounding" })}`, 'rounding')}
                {displayHeaderRowData(`${intl.formatMessage({ id: "TotalAdjustmentAmount" })}`, 'totalAdjustmentAmount')}
              </Col>
            </>
          }
          <div className="mt-3">
            {
              !props.blnView
              &&
              !previewAction
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: "Preview" })}
                onClick={() => {
                  let errorStateTemp = {
                    adjustmentType: false,
                    creditAdjustmentAmount: false,
                    reason: false,
                    accountHolderName: false,
                    identityNo: false,
                    bankAccountType: false,
                    bankAccountNo: false,
                    remark: false,
                    subBrandId: false
                  };
                  let blnErrorFound = false;
                  if (!creditAdjustmentDetails.adjustmentType) {
                    errorStateTemp.adjustmentType = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.creditAdjustmentAmount) {
                    errorStateTemp.creditAdjustmentAmount = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.reason) {
                    errorStateTemp.reason = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.accountHolderName && blnCashType) {
                    errorStateTemp.accountHolderName = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.identityNo && blnCashType) {
                    errorStateTemp.identityNo = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.bankAccountType && blnCashType) {
                    errorStateTemp.bankAccountType = true;
                    blnErrorFound = true;
                  }
                  if (!creditAdjustmentDetails.bankAccountNo && blnCashType) {
                    errorStateTemp.bankAccountNo = true;
                    blnErrorFound = true;
                  }
                  if(blnCashType && blnHasSubBrand && !creditAdjustmentDetails.subBrandId){
                    errorStateTemp.subBrandId = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickPreviewCreditAdjustment(creditAdjustmentDetails)
                  }
                }}
                loading={loadingPreview}
                disable={loadingPreview}
              />
            }
            {
              !props.blnView
              &&
              previewAction
              &&
              !Boolean(successMessage)
              &&
              <div className="mt-3 mb-2 flex-right flex-direction-row">
                <MyButton
                  type="button"
                  class="btn btn-back margin-right-8"
                  style={{ width: '20%' }}
                  content={intl.formatMessage({ id: "Back" })}
                  onClick={() => {
                    setPreviewDetail([]);
                    setPreviewAction(false);
                  }}
                  disable={loadingAdjust || Boolean(successMessage)}
                  loading={loadingAdjust}
                />
                {
                  blnPermissionRequestCreditAdjustment
                  &&
                  <MyButton
                    type="submit"
                    class="btn btn-success"
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Adjust" })}
                    onClick={() => onClickRequestCreditAdjustment(creditAdjustmentDetails)}
                    disable={loadingAdjust || Boolean(successMessage)}
                    loading={loadingAdjust}
                  />
                }
              </div>
            }
            {
              props.blnView
              &&
              <>
                {
                  blnPendingStatus
                  &&
                  <>
                    <Borderline />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={errorState.remark ? "mb-0" : "mb-3"}
                      row={5}
                      validationRequired={errorState.remark}
                      field={reviewCreditAdjustmentDetails}
                      setFieldValue={setReviewCreditAdjustmentDetails}
                      disabled={loadingReview || !blnPendingStatus || Boolean(successMessage) || !blnPermissionReviewCreditAdjustment} />
                    {errorState.remark ? (
                      <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                    ) : null}
                  </>
                }
                <div className="mt-3 mb-2 flex-right flex-direction-row">
                  {/* <MyButton
                    type="button"
                    class={`btn ${blnPendingStatus ? "btn-back margin-right-8" : "btn-primary"}`}
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: blnPendingStatus ? "Back" : "Close" })}
                    onClick={() => hideModal()}
                    disable={loadingReview || Boolean(successMessage)}
                    loading={loadingReview}
                  /> */}
                  {
                    blnPendingStatus
                    &&
                    blnPermissionReviewCreditAdjustment
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-danger margin-right-8"
                        style={{ width: '20%' }}
                        content={Constants.reject}
                        onClick={() => {
                          let errorStateTemp = {
                            adjustmentType: false,
                            creditAdjustmentAmount: false,
                            reason: false,
                            accountHolderName: false,
                            identityNo: false,
                            bankAccountType: false,
                            bankAccountNo: false,
                            remark: false,
                            subBrandId: false
                          };
                          let blnErrorFound = false;
                          if (!reviewCreditAdjustmentDetails.remark) {
                            errorStateTemp.remark = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickReviewCreditAdjustment(Constants.reject);
                          }
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      <MyButton
                        type="submit"
                        class="btn btn-success"
                        style={{ width: '20%' }}
                        content={Constants.approve}
                        onClick={() => {
                          onClickReviewCreditAdjustment(Constants.approve);
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                    </>
                  }
                </div>
              </>
            }
          </div>
        </div>
      </Modal>
  )
}