import _ from 'lodash';
import InformationIcon from 'mdi-react/InformationIcon';
import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { FormFeedback, Input, InputGroup, Label, Row, Tooltip } from 'reactstrap';
import { SketchPicker, ChromePicker } from 'react-color';
import MyButton from './MyButton';

interface Props {
  title: string;
  name: string;
  className?: string;
  validationRequired?: boolean;
  validation: any;
  intl: any;
  disabled?: boolean;
}

export default function GeneralColorPicker(props: Props) {
  const [localColor, setLocalColor] = useState(props.validation.values[props.name] || "#FFFFFF");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChange = (event) => {
    if (event) {
      if (event.hex) {
        setLocalColor(event.hex);
        props.validation.setFieldValue(props.name, event.hex);
      }
    }
  }

  return (
    <div>
      <div className={props.className || "mb-3"}>
        {props.title && (
          <Label>
            {props.validationRequired && !props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
            <Label className="margin-bottom-0">{props.title}</Label>
          </Label>
        )}
        {props.title && (
          <br />
        )}
        <div style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }} 
        onClick={()=> {
          if (!props.disabled) {
            setDisplayColorPicker((value)=> !value)
          }
        }}>
        <div style={{
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          backgroundColor: localColor
        }} />
      </div>
        { 
          displayColorPicker 
          &&
          !props.disabled
          ? 
          <div 
            style={{
              position: 'absolute',
              zIndex: '2',
            }}>
            <div style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }} 
            onClick={()=> {
              setDisplayColorPicker(false);
            } }/>
            <ChromePicker
              color={localColor}
              disableAlpha={true}
              onChange={handleChange} />
          </div> 
          : 
          null 
        }
        
        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}
      </div>
    </div>
  )
}
