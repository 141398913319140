import React from 'react'
import { Link } from 'react-router-dom'
import SidebarMenuContent from './SidebarMenuContent'
import logo from "../../../assets/images/logo.svg";
import logoLightPng from "../../../assets/images/logo-light.png";
import logoLightSvg from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.png";
import onedoc_logo from "../../../assets/images/logo/onedoc_logo.png";
import { RoutesList } from '../../constants/RoutesList';
import { getBrandName, returnBrandSlogan } from '../../common/function/function';
import { Constants } from '../../constants/Constants';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';

export default observer(function SidebarMenu() {
  const { profileStore } = useStore();
  const { profileDetail } = profileStore; 

  return (
    <div className="vertical-menu">
      <div 
        className="navbar-brand-box">
        <Link to={`/${RoutesList.dashboard}`} className="logo logo-dark">
          <span className="logo-sm">
            <img src={logo} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" height="17" />
          </span>
        </Link>

        <Link to={`/${RoutesList.dashboard}`}  className="logo">
          <span className="logo-sm">
            <img src={logoLightSvg} alt="" height="22" />
          </span>
          <div className="logo-lg" style={{lineHeight: '20px', marginTop: '25px'}}>
            <span style={{color: profileDetail?.brandColorCode || '#FFFFFF', fontSize: '18px', fontWeight: 'bold', lineHeight: '20px'}}>
              {`${getBrandName()}`}
            </span> 
            <br/>
            <h5 className="text-truncate" style={{color: profileDetail?.brandColorCode || '#FFFFFF', fontSize: '12px', fontWeight: '600', lineHeight: '20px'}}>
              {profileDetail?.brandDescription}
            </h5>
          </div>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SidebarMenuContent /> 
      </div>
      <div className="sidebar-background"></div>
    </div>
  )
})
