import { useState } from "react";
import { Col, Form, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import { displayTypeWithColor } from "../../../app/common/function/function";
import { LeadImportSuccessDetailObject } from "../../../app/models/lead";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";
import { EmployeeListObject } from "../../../app/models/employee";
import { MediaSourceListObject } from "../../../app/models/mediaSource";
import { SubBrandObject } from "../../../app/models/brand";

interface Props {
  blnShow: boolean;
  setModal: Function;
  importedLeadDetails: LeadImportSuccessDetailObject[];
  setImportedLeadDetails: Function;
  refreshData: Function;
  selectedLeadPlatformTypeValue: string | number;
  marcomConsultantFinalList: EmployeeListObject[];
  filteredMediaSourceList: MediaSourceListObject[];
  subBrandListForModal: SubBrandObject[];
  blnHasDuplicatedLeadOrHasExistingCustomer: boolean;
}

export default observer(function ImportLeadModal(props: Props) {  
  //Use Store
  const intl = useIntl();
  const { commonStore, leadStore } = useStore();
  const { importLeadConfirmation } = leadStore;
  const {
    windowSize,
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;

  const hideModal = () => {
    props.setModal(false);
    props.setImportedLeadDetails([]);
  }

  const [loadingProduct, setLoadingProduct] = useState(false);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "RequiredToImport" }).toUpperCase(),
      type: "input",
      xl: "1.5",
      inputName: "isRequiredToAddLead",
      inputType: "checkbox",
      checkBoxText: "",
    },
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "name",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "mobileNo",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Email" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "email",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Race" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "raceName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "mediaSource",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
      type: "label",
      xl: "1.5",
      inputName: "marcomConsultantName",
      inputType: "text",
    }
  ];

  const aryDynamicInputAndCheckBoxTabShortVer: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "RequiredToImport" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "isRequiredToAddLead",
      inputType: "checkbox",
      checkBoxText: "",
    },
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "name",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "mobileNo",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Email" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "email",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "remark",
      inputType: "text",
    }
  ];

  const [importLeadConfirmationObject, setImportLeadConfirmationObject] = useState({
    subBrandId: "",
    subBrandName: "",
    marcomConsultantId: "",
    marcomConsultantName: "",
    sourceId: "",
    mediaSource: "",
  })

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      leadDetails: props.importedLeadDetails || []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      try {
        const valuesTemp = _.cloneDeep(values);
        if (props.selectedLeadPlatformTypeValue !== "") {
          if (props.subBrandListForModal.length > 0 && !importLeadConfirmationObject.subBrandId) {
            setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" })}))
            return;
          }
          else if (!importLeadConfirmationObject.marcomConsultantId) {
            setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "AppointedBy" })}))
            return;
          }
          else if (!importLeadConfirmationObject.sourceId) {
            setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MediaSource" })}))
            return;
          }
          _.merge(valuesTemp, importLeadConfirmationObject);
        }
        let resultImportLeadConfirmation = await importLeadConfirmation(valuesTemp);
        if (resultImportLeadConfirmation?.status) {
          if (resultImportLeadConfirmation.status === Constants.success) {
            if (props.refreshData) {
              props.refreshData();
            }
          }
        }
      } finally {
        validationLocal.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = Boolean(successMessage) || validationLocal.isSubmitting;

  return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size="xl"
      centered>
      {
        loadingProduct
          ?
          <div style={{ padding: "20px" }}>
            <Loading />
          </div>
          :
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ImportLeadConfirmation" })}</h5>
              </div>
              <button
                type="button"
                onClick={() => { 
                  if (!disabledFieldInput) {
                    hideModal(); 
                  }
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                // className="standard-layout"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationLocal.handleSubmit();
                  return false;
                }}
                onBlur={() => {
                  if (errorMessage || successMessage) {
                    setErrorMessage("");
                    setSuccessMessage("");
                  }
                }}>
                {
                  props.selectedLeadPlatformTypeValue !== ""
                  &&
                  <Row className="mt-2 mb-3">
                    {
                      props.subBrandListForModal.length > 0
                      &&
                      <Col xs={"12"} md={"12"} xl={"4"}>
                        <DropDownWithTitle
                          name={"subBrandId"}
                          title={intl.formatMessage({ id: "SubBrand"})}
                          specifyReturnFieldName={[{"field": "subBrandName", "value": "name"},{"field": "subBrandId", "value": "id"}]}
                          labelField={"name"}
                          valueField={"id"}
                          options={props.subBrandListForModal}
                          disabled={disabledFieldInput}
                          field={importLeadConfirmationObject}
                          setFieldValue={setImportLeadConfirmationObject}
                          validationRequired={true}
                          validation={validationLocal}
                          blnModal={true}/>
                      </Col>
                    }
                    <Col xs={"12"} md={"12"} xl={"4"}>
                      <DropDownWithTitle
                        name={"marcomConsultantId"}
                        title={intl.formatMessage({ id: "AppointedBy" })}
                        specifyReturnFieldName={[{ "field": "marcomConsultantName", "value": "label" }, { "field": "marcomConsultantId", "value": "value" }]}
                        labelField={"preferredName"}
                        valueField={"id"}
                        options={props.marcomConsultantFinalList}
                        disabled={disabledFieldInput}
                        field={importLeadConfirmationObject}
                        setFieldValue={setImportLeadConfirmationObject}
                        validationRequired={true}
                        validation={validationLocal}
                        blnModal={true} />
                    </Col>
                    <Col xs={"12"} md={"12"} xl={"4"}>
                      <DropDownWithTitle
                        name={"sourceId"}
                        title={intl.formatMessage({ id: "MediaSource" })}
                        specifyReturnFieldName={[{ "field": "mediaSource", "value": "name" }, { "field": "sourceId", "value": "id" }]}
                        labelField={"name"}
                        valueField={"id"}
                        options={props.filteredMediaSourceList}
                        disabled={disabledFieldInput}
                        field={importLeadConfirmationObject}
                        setFieldValue={setImportLeadConfirmationObject}
                        validationRequired={true}
                        validation={validationLocal}
                        blnModal={true}/>
                    </Col>
                  </Row>
                }
                <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginRight: "42px", marginBottom: '8px'}}>
                  {displayTypeWithColor(Constants.leadPink, intl.formatMessage({id: "ExistingCustomer"}))}
                  {displayTypeWithColor(Constants.leadYellow, intl.formatMessage({id: "DuplicatedLead"}))}
                </div>
                <LineBreakWithTittle
                  blnSkipMarginTop={true}
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Leads" }) })}
                />
                <TableWithEditableInputFields
                  name="leadDetails"
                  title=""
                  buttonTitle={intl.formatMessage({ id: "Confirm" })}
                  blnEditable={true}
                  aryDynamicInputAndCheckBoxTab={props.selectedLeadPlatformTypeValue === "" ? aryDynamicInputAndCheckBoxTab : aryDynamicInputAndCheckBoxTabShortVer}
                  data={validationLocal.values.leadDetails}
                  validation={validationLocal}
                  disabled={disabledFieldInput || props.blnHasDuplicatedLeadOrHasExistingCustomer}
                  supportBackgroundColor={true}
                  hideAddButton={true}
                  hideDeleteButton={true} />
                {
                  !props.blnHasDuplicatedLeadOrHasExistingCustomer
                  ?
                  <div className="mb-3 mt-4">
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Confirm" })}
                      loading={validationLocal.isSubmitting}
                      disable={disabledFieldInput}
                    />
                  </div>
                  :
                  <div className="mb-3"/>
                }
              </Form>
            </div>
          </div>
      }
    </Modal>
  )
})