import { PaginationRequestBody } from "../models/pagination";
import { ConsultantSalesCommissionExportObject, ConsultantSalesCommissionObject, ConsultantSalesSummaryExportObject, ConsultantSalesListObject, ConsultantSalesSummaryListObject, ConsultantSalesAddObject, DynamicSalesDetailsListObject, SalesByCategoryListObject, SalesByCategoryGetObject, ProductSoldListObject, ProductSoldGetObject, SalesAdjustmentHistoryListObject, DailySalesSummaryExportObject, SalesByCategoryExportObject, ProductSoldExportObject, MonthlySalesSummaryListObject, MonthlySalesSummaryGetObject, MonthlySalesSummaryExportObject, SalesOverviewListObject, SalesOverviewGetObject, SalesOverviewExportObject, SalesOverviewDetailsGetObject, SalesOverviewDetailsListObject, SalesOverviewDetailsExportObject, SubBranchSalesSummaryObject, SubBranchSalesSummaryGetObject, SubBranchSalesSummaryExportObject, ConsultantDailySalesListObject, ConsultantDailySalesExportObject, DiscountSalesDetailsListObject, SalesByProductGetAndExportObject, SalesByProductDetailObject, ProductSalesDetailsObject, ProductSalesDetailsGetAndExportObject, ClosingRateListObject, ClosingRateListGetAndExportObject, SalesOverviewYearlyListObject, SalesOverviewYearlyListGetAndExportObject, MonthlySalesFiguresObject, BranchSalesSummaryListObject, BranchSalesSummaryListGetAndExportObject, BranchDailySalesSummaryListObject, BranchDailySalesSummaryListGetAndExportObject, SalesByGroupCodeListGetAndExportObject, CommissionReportDetailsObject, CommissionReportDetailsGetAndExportObject, ExchangeToCreditDetailsListObject, ExchangeToCreditDetailsGetAndExportObject, RefundFromCreditGetAndExportObject, RefundFromCreditListObject, BranchMonthlySalesSummaryGetAndExportObject, SalesVarianceListObject, SalesVarianceGetAndExportObject, BranchMonthlySalesSummaryListObject } from "../models/reportSales";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel, returnPriceWithCurrency } from "../common/function/function";
import { DailySalesSummaryListObject, DailySalesGetObject, DailySalesDetailListObject, SalesByEmployeeGetAndExportObject } from "../models/reportSales";
import { SalesByEmployeeSummaryObject } from "../models/dashboard";
import _ from "lodash";

export default class ReportSalesStore {
  consultantSalesList: ConsultantSalesListObject[] = [];
  consultantSalesSummaryList: ConsultantSalesSummaryListObject[] = [];
  consultantSalesCommission: ConsultantSalesCommissionObject | undefined = undefined;
  dailySalesSummaryList: DailySalesSummaryListObject[] = [];
  dailySalesDetailsList: DailySalesDetailListObject[] = [];
  salesByCategoryList: SalesByCategoryListObject[] = [];
  dynamicSalesDetailsList: DynamicSalesDetailsListObject[] = [];
  productSoldList: ProductSoldListObject[] = [];
  salesAdjustmentHistoryList: SalesAdjustmentHistoryListObject[] = [];
  monthlySalesSummaryList: MonthlySalesSummaryListObject[] = [];
  salesOverviewList: SalesOverviewListObject[] = [];
  salesOverviewDetailsList: SalesOverviewDetailsListObject[] = [];
  subBranchSalesSummary: SubBranchSalesSummaryObject | undefined = undefined;
  consultantDailySalesList: ConsultantDailySalesListObject[] = [];
  discountSalesDetailsList: DiscountSalesDetailsListObject[] = [];  
  salesByEmployeeList: SalesByEmployeeSummaryObject[] = [];
  salesByProductList: SalesByProductDetailObject[] = [];
  productSalesDetails: ProductSalesDetailsObject | undefined = undefined;
  closingRateList: ClosingRateListObject[] = [];
  salesOverviewYearlyList: SalesOverviewYearlyListObject[] = [];
  branchSalesSummaryList: BranchSalesSummaryListObject[] = [];
  branchDailySalesSummaryList: BranchDailySalesSummaryListObject[] = [];
  commissionReportDetails: CommissionReportDetailsObject | undefined = undefined;
  exchangeToCreditDetailsList: ExchangeToCreditDetailsListObject[] = [];
  refundFromCreditList : RefundFromCreditListObject[] = [];
  branchMonthlySalesSummaryList: BranchMonthlySalesSummaryListObject[] = [];
  salesVarianceList: SalesVarianceListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.consultantSalesList = [];
    this.consultantSalesSummaryList = [];
    this.consultantSalesCommission = undefined;
    this.dailySalesSummaryList = [];
    this.dailySalesDetailsList = [];
    this.salesByCategoryList = [];
    this.dynamicSalesDetailsList = [];
    this.productSoldList = [];
    this.salesAdjustmentHistoryList = [];
    this.monthlySalesSummaryList = [];
    this.salesOverviewList = [];
    this.salesOverviewDetailsList = [];
    this.subBranchSalesSummary = undefined;
    this.consultantDailySalesList = [];
    this.discountSalesDetailsList = [];
    this.salesByEmployeeList = [];
    this.salesByProductList = [];
    this.productSalesDetails = undefined;
    this.closingRateList = [];
    this.salesOverviewYearlyList = [];
    this.branchSalesSummaryList = [];
    this.branchDailySalesSummaryList = [];
    this.commissionReportDetails = undefined;
    this.exchangeToCreditDetailsList = [];
    this.refundFromCreditList = [];
    this.branchMonthlySalesSummaryList = [];
    this.salesVarianceList = [];
  }

  setConsultantSalesList = (consultantSalesListObject: ConsultantSalesListObject[]) => {
    this.consultantSalesList = consultantSalesListObject;
  }
  
  setConsultantSalesSummaryList = (consultantSalesSummaryList: ConsultantSalesSummaryListObject[]) => {
    this.consultantSalesSummaryList = consultantSalesSummaryList;
  }

  setConsultantSalesCommission = (consultantSalesCommission: ConsultantSalesCommissionObject | undefined) => {
    this.consultantSalesCommission = consultantSalesCommission;
  }

  setDailySalesSummaryList = (dailySalesSummaryList: DailySalesSummaryListObject[]) => {
    this.dailySalesSummaryList = dailySalesSummaryList;
  }

  setDailySalesDetailsList = (dailySalesDetailsList: DailySalesDetailListObject[]) => {
    this.dailySalesDetailsList = dailySalesDetailsList;
  }

  setSalesByCategoryList = (salesByCategoryList: SalesByCategoryListObject[]) => {
    this.salesByCategoryList = salesByCategoryList;
  }

  setDynamicSalesDetailsList = (dynamicSalesDetailsList: DynamicSalesDetailsListObject[]) => {
    this.dynamicSalesDetailsList = dynamicSalesDetailsList;
  }

  setProductSoldList = (productSoldList: ProductSoldListObject[]) => {
    this.productSoldList = productSoldList;
  }

  setSalesAdjustmentHistoryList = (salesAdjustmentHistoryListObject: SalesAdjustmentHistoryListObject[]) => {
    this.salesAdjustmentHistoryList = salesAdjustmentHistoryListObject;
  }

  setMonthlySalesSummaryList = (monthlySalesSummaryList: MonthlySalesSummaryListObject[]) => {
    this.monthlySalesSummaryList = monthlySalesSummaryList;
  }

  setSalesOverviewList = (salesOverviewList: SalesOverviewListObject[]) => {
    this.salesOverviewList = salesOverviewList;
  }

  setSalesOverviewDetailsList = (salesOverviewDetailsList: SalesOverviewDetailsListObject[]) => {
    this.salesOverviewDetailsList = salesOverviewDetailsList;
  }

  setSubBranchSalesSummary = (subBranchSalesSummary: SubBranchSalesSummaryObject | undefined)=> {
    this.subBranchSalesSummary = subBranchSalesSummary;
  }

  setConsultantDailySalesList = (consultantDailySalesList: ConsultantDailySalesListObject[]) => {
    this.consultantDailySalesList = consultantDailySalesList;
  }

  setDiscountSalesDetailsList = (discountSalesDetailsList: DiscountSalesDetailsListObject[]) => {
    this.discountSalesDetailsList = discountSalesDetailsList;
  }

  setSalesByEmployeeList = (salesByEmployeeList: SalesByEmployeeSummaryObject[]) => {
    this.salesByEmployeeList = salesByEmployeeList;
  }

  setSalesByProductList = (salesByProductList: SalesByProductDetailObject[]) => {
    this.salesByProductList = salesByProductList;
  }

  setProductSalesDetails = (productSalesDetails: ProductSalesDetailsObject | undefined) => {
    this.productSalesDetails = productSalesDetails;
  }

  setClosingRateList = (clostingRateList: ClosingRateListObject[]) => {
    this.closingRateList = clostingRateList;
  }

  setSalesOverviewYearlyList = (salesOverviewYearlyList: SalesOverviewYearlyListObject[]) => {
    this.salesOverviewYearlyList = salesOverviewYearlyList;
  }

  setBranchSalesSummaryList = (branchSalesSummaryList: BranchSalesSummaryListObject[]) => {
    this.branchSalesSummaryList = branchSalesSummaryList; 
  }

  setBranchDailySalesSummaryList = (branchDailySalesSummaryList: BranchDailySalesSummaryListObject[]) => {
    this.branchDailySalesSummaryList = branchDailySalesSummaryList; 
  }

  setCommissionReportDetails = (commissionReportDetails: CommissionReportDetailsObject | undefined) => {
    this.commissionReportDetails = commissionReportDetails;
  }

  setExchangeToCreditDetailsList = (exchangeToCreditDetailsList: ExchangeToCreditDetailsListObject[]) => {
    this.exchangeToCreditDetailsList = exchangeToCreditDetailsList;
  }

  setRefundFromCreditList = (refundFromCreditList: RefundFromCreditListObject[]) => {
    this.refundFromCreditList = refundFromCreditList;
  }

  setBranchMonthlySalesSummaryList = (branchMonthlySalesSummaryList: BranchMonthlySalesSummaryListObject[]) => {
    this.branchMonthlySalesSummaryList = branchMonthlySalesSummaryList;
  }

  setSalesVarianceList = (salesVarianceList: SalesVarianceListObject[]) => {
    this.salesVarianceList = salesVarianceList;
  }

  getConsultantSales = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultConsultantSales = await agent.ReportSales.consultantSalesList(PaginationRequestBody);
      runInAction(() => {
        this.consultantSalesList = resultConsultantSales.data;
        store.commonStore.setTotalItem(resultConsultantSales.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.consultantSalesList = [];
    }
  }

  getConsultantSalesSummary = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultConsultantSalesSummary = await agent.ReportSales.consultantSalesSummaryList(PaginationRequestBody);
      runInAction(() => {
        this.consultantSalesSummaryList = resultConsultantSalesSummary.data;
        store.commonStore.setTotalItem(resultConsultantSalesSummary.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.consultantSalesSummaryList = [];
    }
  }

  getConsultantSalesCommission = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultConsultantSalesCommission = await agent.ReportSales.consultantSalesCommissionList(PaginationRequestBody);
      runInAction(() => {
        this.consultantSalesCommission = resultConsultantSalesCommission;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.consultantSalesCommission = undefined;
    }
  }

  getDailySalesSummaryList = async (dailySalesGetObject: DailySalesGetObject) => {
    try{
      const resutlDailySalesSummary = await agent.ReportSales.dailySalesSummaryList(dailySalesGetObject);
      runInAction(() => {
        this.dailySalesSummaryList = resutlDailySalesSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dailySalesSummaryList = [];
    }
  }

  getDailySalesDetailsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resutlDailySalesDetailList = await agent.ReportSales.dailySalesDetailsList(PaginationRequestBody);
      resutlDailySalesDetailList.data = resutlDailySalesDetailList.data.map((valueDailySalesDetail)=> {
        valueDailySalesDetail.paymentHistories = valueDailySalesDetail.paymentHistories.map((valuePaymentHistory)=> ({
          ...valuePaymentHistory,
          last4CardDigitNo: valuePaymentHistory.cardType === null && valuePaymentHistory.last4CardDigitNo === "0000" ? "" : valuePaymentHistory.last4CardDigitNo
        }))
        return valueDailySalesDetail;
      })
      runInAction(() => {
        this.dailySalesDetailsList = resutlDailySalesDetailList.data;
        store.commonStore.setTotalItem(resutlDailySalesDetailList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dailySalesDetailsList = [];
    }
  }
  
  addConsultantSales = async (consultantSalesAddObject: ConsultantSalesAddObject) => {
    try{
      await agent.ReportSales.addConsultantSales(consultantSalesAddObject);
      store.commonStore.setSuccessMessage(`ConsultantSalesAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getSalesByCategoryList = async (salesByCategoryListGetObject: SalesByCategoryGetObject) => {
    try{
      let resultSalesByCategory = await agent.ReportSales.salesByCategoryList(salesByCategoryListGetObject);
      runInAction(() => {
        this.salesByCategoryList = resultSalesByCategory;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesByCategoryList = [];
    }
  }

  getDynamicSalesDetailsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultDynamicSalesDetails = await agent.ReportSales.dynamicSalesDetailList(PaginationRequestBody);
      runInAction(() => {
        // resultDynamicSalesDetails.data = resultDynamicSalesDetails.data.map((valueResultCollectionDetailsTemp, index)=> ({
        //   ...valueResultCollectionDetailsTemp,
        //   randomId: `${valueResultCollectionDetailsTemp.appointStartDateTime}_${index}`
        // }))
        this.dynamicSalesDetailsList = resultDynamicSalesDetails.data;
        store.commonStore.setTotalItem(resultDynamicSalesDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.dynamicSalesDetailsList = [];
    }
  }

  getProductSoldList = async (productSoldGetObject: ProductSoldGetObject) => {
    try{
    let resultProductSold : ProductSoldListObject[] = await agent.ReportSales.productSoldList(productSoldGetObject);
    resultProductSold = resultProductSold.map((valueResultProductSold)=> {
      valueResultProductSold.details = valueResultProductSold.details.map((valueResultProductSoldDetailsTemp, index)=> ({
        ...valueResultProductSoldDetailsTemp,
        randomId: `${valueResultProductSoldDetailsTemp.salesOrderId}_${index}`
      }))
      return({
        ...valueResultProductSold
      })
    })
      runInAction(() => {
        this.productSoldList = resultProductSold;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productSoldList = [];
    }
  }

  getSalesAdjustmentHistoryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSalesAdjustmentHistoryList = await agent.ReportSales.salesAdjustmentHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.salesAdjustmentHistoryList = resultSalesAdjustmentHistoryList.data;
        store.commonStore.setTotalItem(resultSalesAdjustmentHistoryList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesAdjustmentHistoryList = [];
    }
  }

  getMonthlySalesSummaryList = async (monthlySalesGetObject: MonthlySalesSummaryGetObject) => {
    try{
      const resultMonthlySales = await agent.ReportSales.monthlySalesSummaryList(monthlySalesGetObject);
      runInAction(() => {
        this.monthlySalesSummaryList = resultMonthlySales;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.monthlySalesSummaryList = [];
    }
  }

  getSalesOverviewList = async (salesOverviewGetObject: SalesOverviewGetObject) => {
    try{
      let resultSalesOverview = await agent.ReportSales.salesOverviewList(salesOverviewGetObject);
      if (resultSalesOverview) {
          if (resultSalesOverview.length > 1) {
          let totalAccumulatedPayment = 0;
          let totalAverageNewVisitPerDay = 0;
          let totalCancellationCount = 0;
          let totalCashRefund = 0;
          let totalConsultationCount = 0;
          let totalNewVisitCount = 0;
          let totalReferralCount = 0;
          let totalResignUpgradeAmount = 0;

          resultSalesOverview.map((valueSalesOverview) => {
            totalAccumulatedPayment += valueSalesOverview.accumulatedPayment;
            totalAverageNewVisitPerDay += valueSalesOverview.averageNewVisitPerDay;
            totalCancellationCount += valueSalesOverview.cancellationCount;
            totalCashRefund += valueSalesOverview.cashRefund;
            totalConsultationCount += valueSalesOverview.consultationCount;
            totalNewVisitCount += valueSalesOverview.newVisitCount;
            totalReferralCount += valueSalesOverview.referralCount;
            totalResignUpgradeAmount += valueSalesOverview.resignUpgradeAmount
          })

          resultSalesOverview.push({
            branchName: "TOTAL",
            accumulatedPayment: totalAccumulatedPayment,
            averageNewVisitPerDay: totalAverageNewVisitPerDay,
            cancellationCount: totalCancellationCount,
            cashRefund: totalCashRefund,
            consultationCount: totalConsultationCount,
            newVisitCount: totalNewVisitCount,
            referralCount: totalReferralCount,
            resignUpgradeAmount: totalResignUpgradeAmount,
            monthlySalesTarget: 0,
            dailySalesTarget: 0,
            accumulatedCollectOnBehalf: 0,
            resignUpgradeCount: 0,
            closeProgramCount: 0,
            closeProgramPercentage: 0
          })
        }
      }
      runInAction(() => {
        resultSalesOverview = resultSalesOverview.map((valueResultSalesOverview, index)=> ({
          ...valueResultSalesOverview,
          randomId: `${valueResultSalesOverview.branchName}_${index}`
        }))
      
        this.salesOverviewList = resultSalesOverview;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesOverviewList = [];
    }
  }

  getSalesOverviewDetailsList = async (salesOverviewDetailsGetObject: SalesOverviewDetailsGetObject) => {
    try{
      let resultSalesOverviewDetails = await agent.ReportSales.salesOverviewDetailsList(salesOverviewDetailsGetObject);
      runInAction(() => {      
        resultSalesOverviewDetails = resultSalesOverviewDetails.map((valueSalesOverviewDetail)=> {
          valueSalesOverviewDetail.recordTypes = valueSalesOverviewDetail.recordTypes.map((valueRecordType)=> {
            valueRecordType.customers = valueRecordType.customers.map((valueCustomer, index)=> ({
              ...valueCustomer,
              randomId: `${valueCustomer.customerId}_${index}`
            }))
            return valueRecordType;
          })
          return valueSalesOverviewDetail;
        })
        this.salesOverviewDetailsList = resultSalesOverviewDetails;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesOverviewDetailsList = [];
    }
  }

  getSubBranchSalesSummary = async (subBranchSalesSummaryGetObject: SubBranchSalesSummaryGetObject) => {
    try{
      let resultSubBranchSalesSummary = await agent.ReportSales.subBranchSalesSummary(subBranchSalesSummaryGetObject);
      runInAction(() => {
        resultSubBranchSalesSummary.earnedSubBranchSalesDetails = resultSubBranchSalesSummary.earnedSubBranchSalesDetails.map((valueResultSubBranchEarnedSales, index)=> ({
          ...valueResultSubBranchEarnedSales,
          randomId: `${valueResultSubBranchEarnedSales.purchaseBranchName}_${valueResultSubBranchEarnedSales.shareBranchName}_${index}`
        }))
        resultSubBranchSalesSummary.sharedSubBranchSalesDetails = resultSubBranchSalesSummary.sharedSubBranchSalesDetails.map((valueResultSubBranchSharedSales, index)=> ({
          ...valueResultSubBranchSharedSales,
          randomId: `${valueResultSubBranchSharedSales.purchaseBranchName}_${valueResultSubBranchSharedSales.shareBranchName}_${index}`
        }))
      
      
        this.subBranchSalesSummary = resultSubBranchSalesSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.subBranchSalesSummary = undefined;
    }
  }

  getConsultantDailySales = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultConsultantDailySalesList = await agent.ReportSales.consultantDailySalesList(PaginationRequestBody);
      runInAction(() => {
        if (resultConsultantDailySalesList) {
          resultConsultantDailySalesList = resultConsultantDailySalesList.map((valueConsultantDailySalesList)=> {
            valueConsultantDailySalesList.consultantDailySalesDetail = valueConsultantDailySalesList.consultantDailySalesDetail.map((valueConsultantDailySalesDetail)=> ({
              ...valueConsultantDailySalesDetail,
              finalPackageSalesAmountDisplay: `${returnPriceWithCurrency(valueConsultantDailySalesDetail.packageSalesAmount)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.packageSalesAdjustment)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.totalPackageSalesAmount)}`,
              finalProductSalesAmountDisplay: `${returnPriceWithCurrency(valueConsultantDailySalesDetail.productSalesAmount)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.productSalesAdjustment)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.totalProductSalesAmount)}`,
              finalProductCommisionAmountDisplay: `${returnPriceWithCurrency(valueConsultantDailySalesDetail.productCommissionAmount)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.productCommissionAdjustment)} / ${returnPriceWithCurrency(valueConsultantDailySalesDetail.totalProductCommissionAmount)}`,
            }))
            return valueConsultantDailySalesList;
          })
        }
        this.consultantDailySalesList = resultConsultantDailySalesList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.consultantDailySalesList = [];
    }
  }

  getDiscountSalesDetailsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultDiscountSalesDetailsList = await agent.ReportSales.discountSalesDetailsList(PaginationRequestBody);
      resultDiscountSalesDetailsList.data = resultDiscountSalesDetailsList.data.map((valueDiscountSalesDetailTemp, index)=> ({
        ...valueDiscountSalesDetailTemp,
        randomId: `${valueDiscountSalesDetailTemp.salesOrderNo}_${index}`
      }))
      runInAction(() => {
        this.discountSalesDetailsList = resultDiscountSalesDetailsList.data;
        store.commonStore.setTotalItem(resultDiscountSalesDetailsList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.discountSalesDetailsList = [];
    }
  }

  getSalesByEmployeeList = async (salesByEmployeeGetAndExportObject: SalesByEmployeeGetAndExportObject) => {
    try{
      let resultSalesByEmployee = await agent.ReportSales.salesByEmployeeList(salesByEmployeeGetAndExportObject);
      let totalDailyReceived = 0;
      let totalMtdReceived = 0;

      resultSalesByEmployee.map((valueSalesByEmployee)=> {
        totalDailyReceived += valueSalesByEmployee.dailyReceived;
        totalMtdReceived += valueSalesByEmployee.mtdReceived;
      })        

      resultSalesByEmployee.push({
        name: "TOTAL",
        dailyReceived: totalDailyReceived,
        mtdReceived: totalMtdReceived
      })

      runInAction(() => {
        this.salesByEmployeeList = resultSalesByEmployee;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesByEmployeeList = [];
    }
  }

  getSalesByProductList = async (salesByProductGetAndExportObject: SalesByProductGetAndExportObject) => {
    try{
      let resultSalesByProduct = await agent.ReportSales.salesByProductList(salesByProductGetAndExportObject);
      let totalAmount = 0;

      resultSalesByProduct.map((valueSalesByProduct)=> {
        totalAmount += valueSalesByProduct.totalAmount;
      })        

      if (resultSalesByProduct.length > 0) {
        resultSalesByProduct.push({
          name: "TOTAL",
          categoryName: "",
          salesCategoryName: "",
          routeUrl: "",
          totalAmount: totalAmount
        })
      }

      runInAction(() => {
        this.salesByProductList = resultSalesByProduct;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesByProductList = [];
    }
  }

  getProductSalesDetails = async (productSalesDetailsGetObject: ProductSalesDetailsGetAndExportObject) => {
    try{
      let resultProductSalesDetails = await agent.ReportSales.productSalesDetails(productSalesDetailsGetObject);
      runInAction(() => {      
        resultProductSalesDetails.erpProductSalesDetails = resultProductSalesDetails.erpProductSalesDetails.map((valueErpProductSalesDetail, index)=> ({
          ...valueErpProductSalesDetail,
          randomId: `${valueErpProductSalesDetail.salesOrderId}_${index}`
        }))

        resultProductSalesDetails.appolousProductSalesDetails = resultProductSalesDetails.appolousProductSalesDetails.map((valueAppolousProductSalesDetail, index)=> ({
          ...valueAppolousProductSalesDetail,
          randomId: `${valueAppolousProductSalesDetail.appolousSaleId}_${index}`
        }))
        
        this.productSalesDetails = resultProductSalesDetails;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productSalesDetails = undefined;
    }
  }

  getClosingRateList = async (closingRateListGetAndExportObject: ClosingRateListGetAndExportObject) => {
    try{
      let resultClosingRateList = await agent.ReportSales.closingRateList(closingRateListGetAndExportObject);
      runInAction(() => {          
        this.closingRateList = resultClosingRateList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.closingRateList = [];
    }
  }

  getSalesOverviewYearlyList = async (salesOverviewYearlyListGetAndExportObject: SalesOverviewYearlyListGetAndExportObject) => {
    try{
      let resultSalesOverviewYearlyList = await agent.ReportSales.salesOverviewYearlyList(salesOverviewYearlyListGetAndExportObject);
      runInAction(() => {          
        if (resultSalesOverviewYearlyList) {
          if (resultSalesOverviewYearlyList.length > 1) {
            let totalMonthlySalesFiguresObjectTemp : MonthlySalesFiguresObject | any= { branchName: "Total", totalAmount: 0 }; 
            resultSalesOverviewYearlyList.map((valueResultSalesOverviewYearlyTemp: SalesOverviewYearlyListObject)=> {
              if (valueResultSalesOverviewYearlyTemp.monthlySalesFigures) {
                let aryMonthlySalesFiguresKey = Object.keys(valueResultSalesOverviewYearlyTemp.monthlySalesFigures);
                aryMonthlySalesFiguresKey.map((valueMonthlySalesFiguresKeyTemp)=> {
                  let blnFieldFound = false;
                  if (totalMonthlySalesFiguresObjectTemp.monthlySalesFigures) {
                    if (totalMonthlySalesFiguresObjectTemp.monthlySalesFigures[valueMonthlySalesFiguresKeyTemp]) {
                      totalMonthlySalesFiguresObjectTemp.monthlySalesFigures[valueMonthlySalesFiguresKeyTemp] += valueResultSalesOverviewYearlyTemp.monthlySalesFigures[valueMonthlySalesFiguresKeyTemp];
                      blnFieldFound = true;
                    }
                  }
                  if (!blnFieldFound) {
                    totalMonthlySalesFiguresObjectTemp.monthlySalesFigures = {...totalMonthlySalesFiguresObjectTemp.monthlySalesFigures, [valueMonthlySalesFiguresKeyTemp]: valueResultSalesOverviewYearlyTemp.monthlySalesFigures[valueMonthlySalesFiguresKeyTemp]};
                  }
                })
              }
              if (valueResultSalesOverviewYearlyTemp.totalAmount) {
                totalMonthlySalesFiguresObjectTemp.totalAmount += valueResultSalesOverviewYearlyTemp.totalAmount;
              }
            })
            resultSalesOverviewYearlyList.push(totalMonthlySalesFiguresObjectTemp);
          }
        }
        this.salesOverviewYearlyList = resultSalesOverviewYearlyList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesOverviewYearlyList = [];
    }
  }

  getBranchSalesSummaryList = async (branchSalesSummaryListGetAndExportObject: BranchSalesSummaryListGetAndExportObject) => {
    try{
      let resultBranchSalesSummaryList = await agent.ReportSales.branchSalesSummaryList(branchSalesSummaryListGetAndExportObject);
      resultBranchSalesSummaryList = resultBranchSalesSummaryList.map((valueBranchSalesSummary)=> {
        if (valueBranchSalesSummary.details.length > 1) {
          valueBranchSalesSummary.details.push({
            groupSales: valueBranchSalesSummary.groupSales,
            productSales: valueBranchSalesSummary.productSales,
            totalSales: valueBranchSalesSummary.totalSales,
            totalPaymentReceived: valueBranchSalesSummary.totalPaymentReceived,
            totalSalesVariance: valueBranchSalesSummary.totalSalesVariance,
            paymentRouteUrl: valueBranchSalesSummary.paymentRouteUrl,
            salesVarianceRouteUrl: valueBranchSalesSummary.salesVarianceRouteUrl,
            routeUrl: valueBranchSalesSummary.routeUrl,
            isTotalField: true
          })
        }
        return valueBranchSalesSummary;
      })
      runInAction(() => {          
        this.branchSalesSummaryList = resultBranchSalesSummaryList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchSalesSummaryList = [];
    }
  }

  getBranchDailySalesSummaryList = async (branchDailySalesSummaryListGetAndExportObject: BranchDailySalesSummaryListGetAndExportObject) => {
    try{
      let resultBranchDailySalesSummaryList = await agent.ReportSales.branchDailySalesSummaryList(branchDailySalesSummaryListGetAndExportObject);
      resultBranchDailySalesSummaryList = resultBranchDailySalesSummaryList.map((valueBranchDailySalesSummary)=> {
        valueBranchDailySalesSummary.dayDetails = valueBranchDailySalesSummary.dayDetails.map((valueDayDetailTemp)=> {
          if (valueDayDetailTemp.details.length > 1) {
            valueDayDetailTemp.details.push({
              groupSales: valueDayDetailTemp.groupSales,
              productSales: valueDayDetailTemp.productSales,
              totalSales: valueDayDetailTemp.totalSales,
              totalPaymentReceived: valueDayDetailTemp.totalPaymentReceived,
              totalSalesVariance: valueDayDetailTemp.totalSalesVariance,
              paymentRouteUrl: valueDayDetailTemp.paymentRouteUrl,
              salesVarianceRouteUrl: valueDayDetailTemp.salesVarianceRouteUrl,
              isTotalField: true
            })
          }
          valueDayDetailTemp = { ...valueDayDetailTemp , subBrandName: " "}
          return valueDayDetailTemp;
        })
        return valueBranchDailySalesSummary;
      })
      runInAction(() => {          
        this.branchDailySalesSummaryList = resultBranchDailySalesSummaryList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchDailySalesSummaryList = [];
    }
  }

  getSalesByGroupCodeList = async (salesByGroupCodeListGetAndExportObject: SalesByGroupCodeListGetAndExportObject) => {
    try{
      let resultSalesByGroupCodeList = await agent.ReportSales.salesByGroupCodeList(salesByGroupCodeListGetAndExportObject);
      return Promise.resolve(resultSalesByGroupCodeList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCommissionReportDetails = async (commissionReportDetailsGetAndExportObject: CommissionReportDetailsGetAndExportObject) => {
    try{
      let resultCommissionReportDetails = await agent.ReportSales.commissionReportDetails(commissionReportDetailsGetAndExportObject);
      if (resultCommissionReportDetails) {
        if (resultCommissionReportDetails.salesOrderCommissions) {
          resultCommissionReportDetails.salesOrderCommissions = resultCommissionReportDetails.salesOrderCommissions.map((valueSalesOrderCommissions, index)=> ({
            ...valueSalesOrderCommissions,
            randomId: `sales-order-${index}`
          }))
        }

        if (resultCommissionReportDetails.partialPaymentSalesOrders) {
          resultCommissionReportDetails.partialPaymentSalesOrders = resultCommissionReportDetails.partialPaymentSalesOrders.map((valuePartialPaymentSalesOrders, index)=> ({
            ...valuePartialPaymentSalesOrders,
            randomId: `partial-payment-${index}`
          }))
        }

        if (resultCommissionReportDetails.manualSalesAdjustments) {
          resultCommissionReportDetails.manualSalesAdjustments = resultCommissionReportDetails.manualSalesAdjustments.map((valueManualSalesAdjustments, index)=> ({
            ...valueManualSalesAdjustments,
            randomId: `manual-sales-adjustment-${index}`
          }))
        }

        if (resultCommissionReportDetails.appolousSalesDetails) {
          resultCommissionReportDetails.appolousSalesDetails = resultCommissionReportDetails.appolousSalesDetails.map((valueAppolousSalesDetails, index)=> ({
            ...valueAppolousSalesDetails,
            randomId: `appolous-sales-${index}`
          }))
        }

        if (resultCommissionReportDetails.earnedSalesCommissionDetails) {
          resultCommissionReportDetails.earnedSalesCommissionDetails = resultCommissionReportDetails.earnedSalesCommissionDetails.map((valueEarnedSalesCommissionDetails, index)=> ({
            ...valueEarnedSalesCommissionDetails,
            randomId: `earn-sales-commission-${index}`
          }))
        }
      }
      runInAction(() => {          
        this.commissionReportDetails = resultCommissionReportDetails;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionReportDetails = undefined;
    }
  }

  getExchangeToCreditDetailsList = async (exchangeToCreditDetailsGetAndExportObject: ExchangeToCreditDetailsGetAndExportObject) => {
    try{
      let resultExchangeToCreditDetails = await agent.ReportSales.exchangeToCreditDetails(exchangeToCreditDetailsGetAndExportObject);
      runInAction(() => {
        this.exchangeToCreditDetailsList = resultExchangeToCreditDetails.data;
        store.commonStore.setTotalItem(resultExchangeToCreditDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.exchangeToCreditDetailsList = [];
    }
  }

  getRefundFromCreditList = async (refundFromCreditGetAndExportObject: RefundFromCreditGetAndExportObject) => {
    try{
      let resultRefundFromCredit = await agent.ReportSales.refundFromCredit(refundFromCreditGetAndExportObject);
      runInAction(() => {
        this.refundFromCreditList = resultRefundFromCredit.data;
        store.commonStore.setTotalItem(resultRefundFromCredit.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.refundFromCreditList = [];
    }
  }

  getBranchMonthlySalesSummary = async (branchMonthlySalesSummaryGetAndExportObject: BranchMonthlySalesSummaryGetAndExportObject) => {
    try{
      let resultBranchMonthlySalesSummary = await agent.ReportSales.branchMonthlySalesSummaryList(branchMonthlySalesSummaryGetAndExportObject);      
      resultBranchMonthlySalesSummary = resultBranchMonthlySalesSummary.map((valueBranchMonthlySalesSummaryTemp)=> {
        valueBranchMonthlySalesSummaryTemp.monthDetails = valueBranchMonthlySalesSummaryTemp.monthDetails.map((valueMonthDetailTemp)=> {
          if (valueMonthDetailTemp.details.length > 1) {
            valueMonthDetailTemp.details.push({
              subBrandName: " ",
              totalPaymentReceived: valueMonthDetailTemp.totalPaymentReceived,
              groupSales: valueMonthDetailTemp.groupSales,
              productSales: valueMonthDetailTemp.productSales,
              totalSales: valueMonthDetailTemp.totalSales,
              totalSalesVariance: valueMonthDetailTemp.totalSalesVariance,
              paymentRouteUrl: valueMonthDetailTemp.paymentRouteUrl,
              salesVarianceRouteUrl: valueMonthDetailTemp.salesVarianceRouteUrl,
              isTotalField: true
            })
          }
          else if (valueMonthDetailTemp.details.length === 1) {
            valueMonthDetailTemp.details[0] = {
              ...valueMonthDetailTemp.details[0],
              paymentRouteUrl: valueMonthDetailTemp.paymentRouteUrl,
              salesVarianceRouteUrl: valueMonthDetailTemp.salesVarianceRouteUrl
            }
          }
          valueMonthDetailTemp = { ...valueMonthDetailTemp , subBrandName: " "}
          return valueMonthDetailTemp;
        })
        return valueBranchMonthlySalesSummaryTemp;
      })
      runInAction(() => {
        this.branchMonthlySalesSummaryList = resultBranchMonthlySalesSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchMonthlySalesSummaryList = [];
    }
  }

  getSalesVarianceList = async (salesVarianceGetAndExportObject: SalesVarianceGetAndExportObject) => {
    try{
      let resultSalesVariance = await agent.ReportSales.salesVarianceList(salesVarianceGetAndExportObject);
      runInAction(() => {
        this.salesVarianceList = resultSalesVariance;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesVarianceList = [];
    }
  }

  exportDailySalesSummary = async (dailySalesSummaryExportRequestBody: DailySalesSummaryExportObject) => {
    try{
      await downloadExcel(`/report/sales/dailysalessummary/export`, dailySalesSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportDailySalesDetails = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/dailysalesdetails/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportDynamicSalesDetails = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/dynamicsalesdetails/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesByCategory = async (salesByCategoryRequestBody: SalesByCategoryExportObject, reportType: string) => {
    try{
      await downloadExcel(`/report/sales/by${reportType}/export`, salesByCategoryRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportProductSold = async (productSoldRequestBody: ProductSoldExportObject) => {
    try{
      await downloadExcel(`/report/sales/productsold/export`, productSoldRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesAdjustmentHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/salesadjustmenthistory/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportMonthlySalesSummary = async (monthlySalesSummaryRequestBody: MonthlySalesSummaryExportObject) => {
    try{
      await downloadExcel(`/report/sales/monthlysalessummary/export`, monthlySalesSummaryRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesOverview = async (salesOverviewRequestBody: SalesOverviewExportObject) => {
    try{
      await downloadExcel(`/report/sales/overview/export`, salesOverviewRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesOverviewDetails = async (salesOverviewDetailsRequestBody: SalesOverviewDetailsExportObject) => {
    try{
      await downloadExcel(`/report/sales/overview/details/export`, salesOverviewDetailsRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSubBranchSalesSummary = async (subBranchSalesSummaryRequestBody: SubBranchSalesSummaryExportObject) => {
    try{
      await downloadExcel(`/report/sales/subbranch/export`, subBranchSalesSummaryRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportConsultantDailySales = async (consultantDailySalesExportRequestBody: ConsultantDailySalesExportObject) => {
    try{
      await downloadExcel(`/report/consultantsales/daily/export`, consultantDailySalesExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportDiscountSalesDetails = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/discount/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesByEmployee = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/byemployee/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesByProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/byproduct/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportProductSalesDetails = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/sales/productsalesdetail/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportClosingRate = async (ClosingRateListRequestBody: ClosingRateListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/closingrate/export`, ClosingRateListRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesOverviewYearly = async (SalesOverviewYearlyRequestBody: SalesOverviewYearlyListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/overview/yearly/export`, SalesOverviewYearlyRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportBranchSalesSummary = async (BranchSalesSummaryRequestBody: BranchSalesSummaryListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/branchsalessummary/export`, BranchSalesSummaryRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportBranchDailySalesSummary = async (BranchDailySalesSummaryRequestBody: BranchDailySalesSummaryListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/branchdailysalessummary/export`, BranchDailySalesSummaryRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesByGroupCode = async (SalesByGroupCodeRequestBody: SalesByGroupCodeListGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/bygroupcode/export`, SalesByGroupCodeRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportConsultantSales = async (PaginationRequestBody: PaginationRequestBody | any) => {
    try{
      await downloadExcel(`/report/consultantsales/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportConsultantSalesSummary = async (consultantSalesSummaryExportRequestBody: ConsultantSalesSummaryExportObject) => {
    try{
      await downloadExcel(`/report/consultantsales/summary/export`, consultantSalesSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportConsultantSalesCommission = async (consultantSalesCommissionExportRequestBody: ConsultantSalesCommissionExportObject) => {
    try{
      await downloadExcel(`/report/commission/export`, consultantSalesCommissionExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCommissionReportDetails = async (commissionReportDetailsGetAndExportObject: CommissionReportDetailsGetAndExportObject) => {
    try{
      await downloadExcel(`/report/commission/commissionDetails/export`, commissionReportDetailsGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportExchangeToCreditDetails = async (exchangeToCreditDetailsGetAndExportObject: ExchangeToCreditDetailsGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/exchangeToCreditDetails/export`, exchangeToCreditDetailsGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportRefundFromCredit = async (refundFromCreditGetAndExportObject: RefundFromCreditGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/refundFromCredit/export`, refundFromCreditGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
  
  exportBranchMonthlySalesSummary = async (branchMonthlySalesSummaryGetAndExportObject: BranchMonthlySalesSummaryGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/branchmonthlysalessummary/export`, branchMonthlySalesSummaryGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesVariance = async (salesVarianceGetAndExportObject: SalesVarianceGetAndExportObject) => {
    try{
      await downloadExcel(`/report/sales/salesvariance/export`, salesVarianceGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
