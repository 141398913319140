import React from "react";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import Loading from "../loading/Loading";
import { Constants } from "../../constants/Constants";

interface Props {
  icon?: Function;
  title: string;
  value: string;
  subValue?: string;
  valueColor?: string;
  subValueColor?: string;
  blnArrow?: boolean;
  loading?: boolean;
  iconUI?: any;
  iconId?: string;
  iconToolTipText?: string;
  cardColor?: string;
  displayCardSubContent?: any;
  isEqualHeightRequired?: boolean;
  style?: any;
}

export default function CardInfo(props: Props) {
  return (
    <Card className={`mini-stats-wid ${props.cardColor ? props.cardColor: `card-green`}`} style={{ minHeight: `${props.isEqualHeightRequired ? '86.6%': ""}`, ...props.style && {...props.style}}}> 
      <CardBody>
        {
          props.loading
          ?
          <Loading />
          :
          <>
            <div className="d-flex" id={props.iconId || ""}>
              <div className="me-3 align-self-center">
                {props.icon ? props.icon() : <div/>}
              </div>
              <div className="flex-grow-1">
                <p className="text-muted mb-2">
                  {props.title} 
                  {
                    props.iconUI 
                    && 
                    <>
                      {props.iconUI}
                      <UncontrolledTooltip placement="top" target={props.iconId ? props.iconId : ""}  style={{whiteSpace:"pre-wrap"}}>
                        {props.iconToolTipText}
                      </UncontrolledTooltip>
                    </>
                  }
                  </p>
                <h4 className={`mb-0 ${props.valueColor ? props.valueColor : ""}`}>
                  {props.value}<br/>
                  {
                    props.subValue &&
                    <span className={`mb-0 font-size-16 ${props.subValueColor ? props.subValueColor : ""}`}>
                      {props.subValue}
                    </span>
                  }
                </h4>
                {
                  props.displayCardSubContent &&
                  <div>
                    <hr/>
                    {props.displayCardSubContent}
                  </div>
                }
              </div>
            </div>
            {
              props.blnArrow
              &&
              <div className="go-corner">
                <div className="go-arrow">
                  →
                </div>
              </div>
            }
          </>
        }
      </CardBody>
    </Card>
  )
}