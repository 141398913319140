import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { SalesOrderCostListObject, CompanyRevenueSummaryListObject, CompanyRevenueSummaryListGetObject, AccountPostListObject, CompanyRevenueSummaryExportObject } from "../models/reportManagement";
import _ from "lodash";
import { downloadExcel } from "../common/function/function";

export default class ReportManagementStore {
  companyRevenueSummaryList: CompanyRevenueSummaryListObject | undefined = undefined;
  salesOrderCostList: SalesOrderCostListObject[] = [];
  accountPostList: AccountPostListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.companyRevenueSummaryList = undefined;
    this.salesOrderCostList = [];
  }

  setCompanyRevenueSummaryList = (companyRevenueSummaryListObject: CompanyRevenueSummaryListObject | undefined) => {
    this.companyRevenueSummaryList = companyRevenueSummaryListObject;
  }

  setSalesOrderCostList = (salesOrderCostListObject: SalesOrderCostListObject[]) => {
    this.salesOrderCostList = salesOrderCostListObject;
  }

  setAccountPostList = (accountPostListObject: AccountPostListObject[]) => {
    this.accountPostList = accountPostListObject;
  }

  getCompanyRevenueSummaryList = async (companyRevenueSummaryListGetObject: CompanyRevenueSummaryListGetObject) => {
    try{
    let resultCompanyRevenueSummary = await agent.ReportManagement.companyRevenueSummaryList(companyRevenueSummaryListGetObject);
    
      runInAction(() => {
        this.companyRevenueSummaryList = resultCompanyRevenueSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.companyRevenueSummaryList = undefined;
    }
  }

  getSalesOrderCostList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSalesOrderCost = await agent.ReportManagement.salesOrderCostList(PaginationRequestBody);
      resultSalesOrderCost.data = resultSalesOrderCost.data.map((valueResultSalesOrderCostTemp, index)=> {
        let customerOrderCostSummaryDetailsTemp : any = _.cloneDeep(valueResultSalesOrderCostTemp.customerOrderCostSummaryDetails);
        customerOrderCostSummaryDetailsTemp = customerOrderCostSummaryDetailsTemp.map((valueCustomerOrderCostSummaryDetailsTemp, indexSummaryDetails)=> ({
          ...valueCustomerOrderCostSummaryDetailsTemp,
          randomId: `${valueCustomerOrderCostSummaryDetailsTemp.customerRedemptionNo}_${index}_${indexSummaryDetails}`
        }))
        return({
          ...valueResultSalesOrderCostTemp,
          customerOrderCostSummaryDetails: customerOrderCostSummaryDetailsTemp,
          randomId: `${valueResultSalesOrderCostTemp.customerOrderNo}_${index}`
        })
      })

      runInAction(() => {
        this.salesOrderCostList = resultSalesOrderCost.data;
        store.commonStore.setTotalItem(resultSalesOrderCost.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesOrderCostList = [];
    }
  }

  getAccountPostList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultAccountPost = await agent.ReportManagement.accountPostList(PaginationRequestBody);
      runInAction(() => {
        this.accountPostList = resultAccountPost.data;
        store.commonStore.setTotalItem(resultAccountPost.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.accountPostList = [];
    }
  }

  exportCompanyRevenue = async (companyRevenueSummaryExportObject: CompanyRevenueSummaryExportObject) => {
    try{
      await downloadExcel(`/report/management/companyRevenue/export`, companyRevenueSummaryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSalesOrderCost = async (salesOrderCostExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/management/salesOrderCost/export`, salesOrderCostExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAccountPosting = async (accountPostingExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/management/accountPost/export`, accountPostingExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}