import { useState } from "react";
import {
  Modal,
  Label,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useStore } from "../../../app/stores/store";
import { getBranchId, getBranchName } from "../../../app/common/function/function";
import { BranchMachineAddObject, BranchMachineListObject } from "../../../app/models/machine";
import { BranchListObject } from "../../../app/models/branch";
import GeneralInput from "../../../app/components/form/GeneralInput";

interface Props {
  blnShow: boolean;
  setModal: Function;
  branchMachineDetail: BranchMachineListObject;
  setBranchMachineDetail: Function;
  addNewOrUpdateBranchMachine: Function;
  branchDropDownList: BranchListObject[];
  viewModalAction: boolean;
  loadingBranchMachine: boolean;
  setLoadingBranchMachine: Function;
  blnDurationType: boolean;
}

export default function BranchMachineEditModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const defaultBranchMachineDetails = {
    ...props.branchMachineDetail.id && {id: props.branchMachineDetail.id},
    branchId: props.branchMachineDetail.branchId || getBranchId(),
    branchName: props.branchMachineDetail.branchName || getBranchName(),
    machineId: props.branchMachineDetail.machineId,
    machineName: props.branchMachineDetail.machineName,
    serialNumber: props.branchMachineDetail.serialNumber || "",
    balanceQuantity: props.branchMachineDetail.balanceQuantity || 0,
    reloadQuantity: props.branchMachineDetail.reloadQuantity || 0,
    isActive: !props.branchMachineDetail.id ? true : props.branchMachineDetail.isActive
  }
  const [branchMachineDetails, setBranchMachineDetails] = useState<BranchMachineListObject>(defaultBranchMachineDetails);
	const displayTitle = `${!branchMachineDetails.id ? intl.formatMessage({ id: "Add" }) : props.viewModalAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })} ${intl.formatMessage({ id: "BranchMachine" })}`;
  const [errorState, setErrorState] = useState({
    branch: false,
    serialNumber: false,
    balanceQuantity: false,
    balanceQuantityExceedLimit: false
  })
  const disabledFieldInput = props.loadingBranchMachine || Boolean(successMessage);

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branch: false,
      serialNumber: false,
      balanceQuantity: false,
      balanceQuantityExceedLimit: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setBranchMachineDetails(defaultBranchMachineDetails);
    props.setModal(false);
  }
  
  const onChangeBranch = (valueBranchId: any) => {
    setBranchMachineDetails(valueTemp => ({
      ...valueTemp,
      branchId: valueBranchId,
    }));
  }

  const onClickAddOrUpdateBranchMachine= async (branchMachineDetailTemp: BranchMachineListObject) => {
    props.addNewOrUpdateBranchMachine(branchMachineDetailTemp);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!Boolean(successMessage) && !props.loadingBranchMachine) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{displayTitle}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Branch" })}</Label>
          </Label> */}
          <GeneralInput
            title={intl.formatMessage({ id: "MachineName" })}
            name="machineName"
            type="text"
            disabled={true}
            blnModal={true}
            field={branchMachineDetails}
            setFieldValue={setBranchMachineDetails}
          />
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Branch" })}
            name=""
            className={errorState.branch ? "mb-0" : "mb-3"}
            labelField="name"
            valueField="id"
            options={props.branchDropDownList}
            onChange={onChangeBranch}
            initialLabel={branchMachineDetails.branchName}
            initialValue={branchMachineDetails.branchId}
            disabled={true}
          />
          {errorState.branch ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
          ) : null}
          <GeneralInput
            title={intl.formatMessage({ id: "SerialNumber" })}
            name="serialNumber"
            className={errorState.serialNumber ? "mb-0" : "mb-3"}
            type="text"
            disabled={disabledFieldInput || Boolean(branchMachineDetails.id)}
            blnModal={true}
            field={branchMachineDetails}
            setFieldValue={setBranchMachineDetails}
            validationRequired={true}
          />
          {errorState.serialNumber ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SerialNumber" }) })}</FormFeedback>
          ) : null}
          <GeneralInput
            title={intl.formatMessage({ id: props.blnDurationType ? "BalanceMinutes" : "BalanceQuantity" })}
            className={"mb-0"}
            name="balanceQuantity"
            type="number"
            disabled={disabledFieldInput}
            blnModal={true}
            field={branchMachineDetails}
            setFieldValue={setBranchMachineDetails}
          />
          <p className={`text-muted mt-1 ${errorState.balanceQuantity || errorState.balanceQuantityExceedLimit ? "mb-0" : "mb-3"}`}>{`${intl.formatMessage({ id: "ReloadQuantity" })}: ${branchMachineDetails.reloadQuantity}`}</p>
          {errorState.balanceQuantity ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BalanceQuantity" }) })}</FormFeedback>
          ) : null}
          {errorState.balanceQuantityExceedLimit ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "MoreThanReloadQuantiy" }, { field: branchMachineDetails.reloadQuantity })}</FormFeedback>
          ) : null}
          <GeneralInput
            title={intl.formatMessage({ id: "Active" })}
            name="isActive"
            type="checkbox"
            disabled={disabledFieldInput}
            blnModal={true}
            field={branchMachineDetails}
            setFieldValue={setBranchMachineDetails}
            childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
          <div className="margin-top-14 ">
            <MyButton
              type="button"
              class="btn btn-success"
              content={intl.formatMessage({ id: "Submit" })}
              loading={props.loadingBranchMachine}
              onClick={() => {
                let errorStateTemp = {
                  branch: false,
                  serialNumber: false,
                  balanceQuantity: false,
                  balanceQuantityExceedLimit: false
                };

                let blnErrorFound = false;
                if (!branchMachineDetails.branchId) {
                  errorStateTemp.branch = true;
                  blnErrorFound = true;
                }
                if (!branchMachineDetails.serialNumber) {
                  errorStateTemp.serialNumber = true;
                  blnErrorFound = true;
                }
                if (!branchMachineDetails.balanceQuantity && branchMachineDetails.balanceQuantity !== 0) {
                  errorStateTemp.balanceQuantity = true;
                  blnErrorFound = true;
                }
                if (branchMachineDetails.balanceQuantity > branchMachineDetails.reloadQuantity) {
                  errorStateTemp.balanceQuantityExceedLimit = true;
                  blnErrorFound = true;
                }

                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  setErrorState(errorStateTemp);
                  onClickAddOrUpdateBranchMachine(branchMachineDetails)
                }
              }}
            />
          </div>
        </div>
      </Modal>
  )
}