import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, returnPriceWithCurrency, checkPermission, getBranchUser, getBranchId, getBranchName, returnSubBrandList, arraySpliceInAllValue, firstDayOfCurrentMonthToLastDayOfCurrentMonth, convertArrayToStringDisplay, newSpaceBeforeCapitalLetter, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from 'react-router-dom';
import _ from "lodash";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import classNames from "classnames";
import CardInfo from "../../../app/components/form/CardInfo";

const SalesOrderCommissionSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { commissionReportDetails, setCommissionReportDetails, getCommissionReportDetails, exportCommissionReportDetails } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [tabBar, setTabBar] = useState([
    intl.formatMessage({ id: "ERPCommissionDetails" }).toUpperCase(), 
    intl.formatMessage({ id: "AppolousSalesDetails" }).toUpperCase(), 
    intl.formatMessage({ id: "PartialPaymentCommission" }).toUpperCase(),
    intl.formatMessage({ id: "ManualSalesAdjustment" }).toUpperCase(),
    intl.formatMessage({ id: "EarnedSalesDetails" }).toUpperCase(),
  ]);
  const [activeTab, setActiveTab] = useState("1");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const [blnIsPackageSalesOnly, setBlnIsPackageSalesOnly] = useState(false);
  const [blnAllPackageSales, setBlnAllPackageSales] = useState(true);
  const pageSize = Constants.maxPageSize;
  const blnApplyButtonMoveToNewTab = Constants.innerWidthCompare1440 > windowSize.innerWidth;

  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
      isPackageSalesOnly: false
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchCommissionReportDetails() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
       
      await Promise.all(aryAPI);

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      
			setLoading(false);
      setInitialCallAPI(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchCommissionReportDetails();

    return (()=> {
      setCommissionReportDetails(undefined);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  useEffect(()=> {
    setSelectedDateRange(dateRangeLimit)
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const fetchCommissionReportDetailsWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportCommissionReportDetails({ branchId: selectedBranchId, subBrandId: selectedSubBrandId, customerId: selectedCustomerId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, salesOrderNo: validation.values.salesOrderNo, ...!blnAllPackageSales && {isPackageSalesOnly: blnIsPackageSalesOnly} })
    }
    else {
      await getCommissionReportDetails({ branchId: selectedBranchId, subBrandId: selectedSubBrandId, customerId: selectedCustomerId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, salesOrderNo: validation.values.salesOrderNo, ...!blnAllPackageSales && {isPackageSalesOnly: blnIsPackageSalesOnly} });
    }
    setLocalLoading(false);
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const columnHeaderStyle= {
    width: '180px',
    minWidth: '180px',
    maxWidth: '180px'
  }

  //Table Content UI
  const ErpCommissionDetailsColumns = [
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          row.customerSalesOrderNo || Constants.emptyData
        }
      </>
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle}
    },
    {
      dataField: "customerBranchName",
      text: intl.formatMessage({ id: "CustomerBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "purchaseBranchName",
      text: intl.formatMessage({ id: "PurchaseBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.submissionDate !== Constants.invalidDate ? moment(row.submissionDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.submissionDate !== Constants.invalidDate ? moment(row.submissionDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      hidden: returnSubBrandList().length < 1
    },
    {
      dataField: "signedDate",
      text: intl.formatMessage({ id: "SignedDate" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.signedDate !== Constants.invalidDate ? moment(row.signedDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
          {row.signedDate !== Constants.invalidDate ? moment(row.signedDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalPayment)}`}</div>
      ),
    },
    {
      dataField: "totalNonCreditPayment",
      text: intl.formatMessage({ id: "TotalNonCreditPayment" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalNonCreditPayment)}`}</div>
      ),
    },
    {
      dataField: "totalCreditPayment",
      text: intl.formatMessage({ id: "TotalCreditPayment" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalCreditPayment)}`}</div>
      ),
    },
    {
      dataField: "totalOriPackageSales",
      text: intl.formatMessage({ id: "TotalOriPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalOriPackageSales)}`}</div>
      ),
    },
    {
      dataField: "totalAdjustPackageSales",
      text: intl.formatMessage({ id: "TotalAdjustPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalAdjustPackageSales)}`}</div>
      ),
    },
    {
      dataField: "totalPackageSales",
      text: intl.formatMessage({ id: "TotalPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalPackageSales)}`}</div>
      ),
    },
    {
      dataField: "totalOriProductSales",
      text: intl.formatMessage({ id: "TotalOriProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalOriProductSales)}`}</div>
      ),
    },
    {
      dataField: "totalAdjustProductSales",
      text: intl.formatMessage({ id: "TotalAdjustProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalAdjustProductSales)}`}</div>
      ),
    },
    {
      dataField: "totalProductSales",
      text: intl.formatMessage({ id: "TotalProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalProductSales)}`}</div>
      ),
    },
    {
      dataField: "totalOriProductComm",
      text: intl.formatMessage({ id: "TotalOriProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalOriProductComm)}`}</div>
      ),
    },
    {
      dataField: "totalAdjustProductComm",
      text: intl.formatMessage({ id: "TotalAdjustProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalAdjustProductComm)}`}</div>
      ),
    },
    {
      dataField: "totalProductComm",
      text: intl.formatMessage({ id: "TotalProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalProductComm)}`}</div>
      ),
    },
    {
      dataField: "isShare",
      text: intl.formatMessage({ id: "IsCommissionShare" }).toUpperCase(),
      headerStyle: { ...columnHeaderStyle, textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isShare ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "mainBranch",
      text: intl.formatMessage({ id: "MainBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, true, "branchName", false)}
        </>
      ),
    },
    {
      dataField: "mainPackageSales",
      text: intl.formatMessage({ id: "MainPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, true, "packageSales", true)}
        </>
      ),
    },
    {
      dataField: "mainProductSales",
      text: intl.formatMessage({ id: "MainProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, true, "productSales", true)}
        </>
      ),
    },
    {
      dataField: "mainProductComm",
      text: intl.formatMessage({ id: "MainProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, true, "productComm", true)}
        </>
      ),
    },
    {
      dataField: "mainConsultants",
      text: intl.formatMessage({ id: "MainConsultants" }).toUpperCase(),
      headerStyle: {minWidth: '250px', width: '250px', maxWidth: '350px'},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, true, "consultants", false, true)}
        </>
      ),
    },
    {
      dataField: "subBranch",
      text: intl.formatMessage({ id: "SubBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, false, "branchName", false)}
        </>
      ),
    },
    {
      dataField: "subPackageSales",
      text: intl.formatMessage({ id: "SubPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, false, "packageSales", true)}
        </>
      ),
    },
    {
      dataField: "subProductSales",
      text: intl.formatMessage({ id: "SubProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, false, "productSales", true)}
        </>
      ),
    },
    {
      dataField: "subProductComm",
      text: intl.formatMessage({ id: "SubProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, false, "productComm", true)}
        </>
      ),
    },
    {
      dataField: "subConsultants",
      text: intl.formatMessage({ id: "SubConsultants" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {returnSalesOrderCommissionDetailHandling(row.salesOrderCommissionDetails, false, "consultants", false, true)}
        </>
      ),
    },
  ];

  const PartialPaymentCommissionColumns = [
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder
          ?
          <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          row.customerSalesOrderNo || Constants.emptyData
        }
      </>
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle}
    },
    {
      dataField: "customerBranchName",
      text: intl.formatMessage({ id: "CustomerBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "purchaseBranchName",
      text: intl.formatMessage({ id: "PurchaseBranch" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "amountPaid",
      text: intl.formatMessage({ id: "AmountPaid" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.amountPaid)}`}</div>
      ),
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.submissionDate !== Constants.invalidDate ? moment(row.submissionDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.submissionDate !== Constants.invalidDate ? moment(row.submissionDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      hidden: returnSubBrandList().length < 1
    },
    {
      dataField: "signedDate",
      text: intl.formatMessage({ id: "SignedDate" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.signedDate !== Constants.invalidDate ? moment(row.signedDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
          {row.signedDate !== Constants.invalidDate ? moment(row.signedDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "totalPackageSales",
      text: intl.formatMessage({ id: "TotalPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalPackageSales)}`}</div>
      ),
    },
    {
      dataField: "totalProductSales",
      text: intl.formatMessage({ id: "TotalProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalProductSales)}`}</div>
      ),
    },
    {
      dataField: "totalProductComm",
      text: intl.formatMessage({ id: "TotalProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.totalProductComm)}`}</div>
      ),
    }
  ]

  const ManualSalesAdjustmentColumns = [
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "PerformedDateTime" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.performedDateTime !== Constants.invalidDate ? moment(row.performedDateTime).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.performedDateTime !== Constants.invalidDate ? moment(row.performedDateTime).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "salesAdjustmentNo",
      text: intl.formatMessage({ id: "SalesAdjustmentNo" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "salesAdjustmentType",
      text: intl.formatMessage({ id: "SalesAdjustmentType" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.salesAdjustmentType)}
        </>
      )
    },
    {
      dataField: "referenceNo",
      text: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "referenceDetails",
      text: intl.formatMessage({ id: "ReferenceDetails" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {row.referenceDetails || Constants.emptyData}
        </>
    },
    {
      dataField: "packageAmount",
      text: intl.formatMessage({ id: "PackageAmount" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.packageAmount)}
        </>
    },
    {
      dataField: "productAmount",
      text: intl.formatMessage({ id: "ProductAmount" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.productAmount)}
        </>
    },
    {
      dataField: "productCommission",
      text: intl.formatMessage({ id: "ProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.productCommission)}
        </>
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {row.remark || Constants.emptyData}
        </>
    },
    {
      dataField: "isSubBranch",
      text: intl.formatMessage({ id: "IsSubBranch" }).toUpperCase(),
      headerStyle: { ...columnHeaderStyle, textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isSubBranch ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      hidden: returnSubBrandList().length < 1
    }
  ]

  const EarnedSalesDetailsColumns = [
    {
      dataField: "fromBranchName",
      text: intl.formatMessage({ id: "FromBranchName" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "date",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.date !== Constants.invalidDate ? moment(row.date).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.date !== Constants.invalidDate ? moment(row.date).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "documentReferenceNo",
      text: intl.formatMessage({ id: "DocumentReferenceNo" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
    },
    {
      dataField: "transactionPackageSalesAmount",
      text: intl.formatMessage({ id: "OriPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.transactionPackageSalesAmount)}
        </>
    },
    {
      dataField: "earnedTransactionPackageSales",
      text: intl.formatMessage({ id: "EarnedPackageSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.earnedTransactionPackageSales)}
        </>
    },
    {
      dataField: "transactionProductSalesAmount",
      text: intl.formatMessage({ id: "OriProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.transactionProductSalesAmount)}
        </>
    },
    {
      dataField: "earnedTransactionProductSales",
      text: intl.formatMessage({ id: "EarnedProductSales" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.earnedTransactionProductSales)}
        </>
    },
    {
      dataField: "productCommissionAmount",
      text: intl.formatMessage({ id: "OriProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.productCommissionAmount)}
        </>
    },
    {
      dataField: "earnedProductCommission",
      text: intl.formatMessage({ id: "EarnedProductComm" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      formatter: (cellContent, row) => 
        <>
          {returnPriceWithCurrency(row.earnedProductCommission)}
        </>
    },
    {
      dataField: "isAppolous",
      text: intl.formatMessage({ id: "IsAppolous" }).toUpperCase(),
      headerStyle: { ...columnHeaderStyle, textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isAppolous ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: {...columnHeaderStyle},
      hidden: returnSubBrandList().length < 1
    }
  ]

  const returnSalesOrderCommissionDetailHandling = (arySalesOrderCommissionDetails, isMain, fieldName, blnCurrency, blnConvertArrayToString?: boolean) => {
    let finalValue : any = blnCurrency ? returnPriceWithCurrency(0) : Constants.emptyData;
    let indexMatched = _.findIndex(arySalesOrderCommissionDetails, {isMain: isMain});

    if (indexMatched > -1) {
      finalValue = arySalesOrderCommissionDetails[indexMatched][fieldName];
      if (blnConvertArrayToString) {
        finalValue = convertArrayToStringDisplay(finalValue);
      }
      else if (blnCurrency) {
        finalValue = returnPriceWithCurrency(finalValue);
      }
    }
    return finalValue;
  }

  const blnViewAllFunction = () => {
    setBlnAllPackageSales(true);
  }

  const blnPackageSalesOnlyFunction = () => {
    setBlnIsPackageSalesOnly(true);
    setBlnAllPackageSales(false);
  }

  const blnNoPackageSalesFunction = () => {
    setBlnIsPackageSalesOnly(false);
    setBlnAllPackageSales(false);
  } 

  const returnApplyButton = () => {
    return (
      <MyButton
        type="button"
        class={`btn btn-primary ${!blnApplyButtonMoveToNewTab ? "margin-left-16" : "margin-top-16 margin-bottom-16"}`}
        content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
        loading={localLoading || loading}
        disable={localLoading || loading}
        onClick={() => {
          fetchCommissionReportDetailsWithLoading(false)
        }}
      />
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "SalesOrderCommissionSummary"})})}
        title={intl.formatMessage({ id: "SalesOrderCommissionSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          <>
            {
              blnPermissionExportSalesReport
              &&
              <MyButton
                type="button"
                class="btn btn-success margin-left-4"
                content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                disable={localLoading || loading}
                onClick={() => {
                  fetchCommissionReportDetailsWithLoading(true);
                }}
                />
            }
          </>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-Order-Commission-Summary.aspx`}>
        {
          !loading
          &&
          <>
          <Row>
            {
              subBrandListFinal.length > 0
              &&
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                // className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || loadingCustomer || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              {
                displayCustomerDropdown 
                &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading || !selectedBranchId}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </Col>
          </Row>
          <Row>
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              {
                localLoadingDateRange
                ?
                <Loading/>
                :
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRange" })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  initialValue={selectedDateRange}
                  minMaxDateObj={dateRangeLimit}
                  validationRequired={true}
                  onChangeFunction={onSelectDateRange}/>
              }
            </Col>
          </Row>
          <Row>
            <Col xl={"3"}>
              <GeneralInput
                title={intl.formatMessage({ id: "SalesOrderNo" })}
                name="salesOrderNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSalesOrderNo" })}
              />
            </Col>
            <Col xl={"6"} className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28": ""} ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "" : "margin-bottom-4"}`}>
              <div className="btn-group" role="group">
                {returnSwitchTabButtonUI("blnAllPackageSales", "blnAllPackageSales", blnAllPackageSales, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, "80px")}
                {returnSwitchTabButtonUI("blnIsPackageSalesOnly", "blnIsPackageSalesOnlyTrue", blnIsPackageSalesOnly && !blnAllPackageSales, loading || localLoading, intl.formatMessage({ id: "PackageSalesOnly" }), blnPackageSalesOnlyFunction, "160px")}
                {returnSwitchTabButtonUI("blnIsPackageSalesOnly", "blnIsPackageSalesOnlyFalse", !blnIsPackageSalesOnly && !blnAllPackageSales, loading || localLoading, intl.formatMessage({ id: "ProductSalesOnly" }), blnNoPackageSalesFunction, "150px")}
              </div>
              {
                !blnApplyButtonMoveToNewTab
                &&
                returnApplyButton()
              }
            </Col>
          </Row>
          {
            blnApplyButtonMoveToNewTab
            &&
            <Col xl={"3"}>
              {returnApplyButton()}
            </Col>
          }
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <>
            {
              commissionReportDetails
              &&
              <>
                <Row className="padding-top-10">
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      cardColor="card-blue"
                      title={intl.formatMessage({ id: "TotalPaymentCollection" }).toUpperCase()}
                      value={returnPriceWithCurrency(commissionReportDetails.totalNonCreditPayment)}
                      />
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      cardColor="card-blue"
                      title={intl.formatMessage({ id: "TotalBranchSales" }).toUpperCase()}
                      value={returnPriceWithCurrency(commissionReportDetails.totalBranchSales)}
                      />
                  </Col>
                </Row>
                <Row className="">
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      title={intl.formatMessage({ id: "TotalPackageSales" }).toUpperCase()}
                      value={returnPriceWithCurrency(commissionReportDetails.totalPackageSales)}
                      />
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      cardColor="card-yellow"
                      title={intl.formatMessage({ id: "TotalProductSales" }).toUpperCase()}
                      value={returnPriceWithCurrency(commissionReportDetails.totalProductSales)}
                      />
                  </Col>
                  <Col xl="3" lg="6" sm="6" xs="12">
                    <CardInfo
                      cardColor="card-yellow"
                      title={intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase()}
                      value={returnPriceWithCurrency(commissionReportDetails.totalProductComm)}
                      />
                  </Col>
                </Row>
                <div style={{ marginBottom: "-20px" }} className="standard-layout">
                  <Nav className="nav nav-tabs nav-tabs-custom">
                    {tabBar.map((value, indexTab) => (
                      (indexTab == 1 && !commissionReportDetails.isShowAppolous) || (indexTab == 2 && !commissionReportDetails.isShowPartialPayment)
                      ?
                      undefined
                      :
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          disabled={false}
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active:
                              activeTab ===
                              String(indexTab + 1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab + 1));
                          }}
                        >
                          {value}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </>
            }
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={activeTab === "1" ? ErpCommissionDetailsColumns : activeTab === "3" ? PartialPaymentCommissionColumns : activeTab === "5" ? EarnedSalesDetailsColumns : ManualSalesAdjustmentColumns}
              data={commissionReportDetails ? activeTab === "1" ? commissionReportDetails.salesOrderCommissions : activeTab === "2" ? commissionReportDetails.appolousSalesDetails : activeTab === "3" ? commissionReportDetails.partialPaymentSalesOrders : activeTab === "4" ? commissionReportDetails.manualSalesAdjustments : commissionReportDetails.earnedSalesCommissionDetails : []}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: !commissionReportDetails ? intl.formatMessage({id: "SalesOrderCommissionSummary"}) : activeTab === "1" ? intl.formatMessage({id: "ERPCommissionDetails"}) : activeTab === "2" ? intl.formatMessage({id: "AppolousSalesDetails"}) : activeTab === "3" ? intl.formatMessage({id: "PartialPaymentCommission"}) : activeTab === "4" ? intl.formatMessage({id: "ManualSalesAdjustment"}) : intl.formatMessage({id: "EarnedSalesDetails"})})}
              objSorted={defaultSorted}
              keyField={"randomId"}
              requestAPI={getCommissionReportDetails}
              hideSearchBar={true}/>
          </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesOrderCommissionSummary);
