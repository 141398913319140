import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from '../../app/components/form/GeneralInput';
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import classNames from "classnames";
import { AddOptionsValueField, checkPermission, compareArrayWithField, contructValidationErrorMessage, getBranchId, IncludesLocationPathName, removeAllSpace, returnCurrency } from '../../app/common/function/function';
import Loading from "../../app/components/loading/Loading";
import _ from 'lodash';
import { Row, Col, Form, Label, Nav, NavItem, NavLink } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { PromotionItemObject } from "../../app/models/promotion";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import moment from "moment";

const PromotionDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { promotionStore, productStore, packageStore, serviceTreatmentStore, commonStore, staticReferenceStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { addPromotion, updatePromotion, promotionDetail, setPromotionDetail, getPromotionWithId } = promotionStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { packageDropdownList, getPackageDropdown } = packageStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { promotionType, redemptionCapType, discountType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName('add');
  const viewAction = IncludesLocationPathName('view');
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Promotion" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Promotion" }), urlPath: RoutesList.promotion }];
  const [activeTab, setActiveTab] = useState(`${Constants.service.toUpperCase()}`);
  const tabBar = [intl.formatMessage({ id: "ServiceTreatment" }).toUpperCase(), intl.formatMessage({ id: "Package" }).toUpperCase(), intl.formatMessage({ id: "Product" }).toUpperCase()];
  const [displayDate, setDisplayDate] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [serviceTreatmentModal, setServiceTreatmentModal] = useState(false);
  const [promotionProductList, setPromotionProductList] = useState<PromotionItemObject[]>([]);
  const [promotionPackageList, setPromotionPackageList] = useState<PromotionItemObject[]>([]);
  const [promotionServiceList, setPromotionServiceList] = useState<PromotionItemObject[]>([]);
  const [refreshSubTabList, setRefreshSubTabList] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  const discountTypeOnChangeFunction = () => {
    setRefreshSubTabList(true);
    setTimeout(() => {
      setRefreshSubTabList(false);
    }, 100)
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "Name" }).toUpperCase(),
        type: "label",
        xl: "2.2",
        inputName: "itemName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Price" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "sellingPrice",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "DiscountType" }).toUpperCase(),
        type: "select",
        xl: "2.8",
        inputName: "discountType",
        inputType: "select",
        options: AddOptionsValueField(discountType, "displayValue", "displayValue"),
        displayLabel: "discountType",
        displayValue: "discountType",
        specifyReturnFieldName: [{ field: "discountType", value: "displayValue" }, { field: "discountValue", value: 0 }],
        validationRequired: true,
        onChangeFunction: discountTypeOnChangeFunction
      },
      {
        label: intl.formatMessage({ id: "DiscountValue" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "discountValue",
        inputType: "number",
        validationRequired: true,
        numberMaxCondition: {
          compareFieldName: "discountType",
          compareValue: Constants.amount,
          trueConditionFieldName: "sellingPrice",
          falseConditionFieldName: "maxPercentage"
        },
      },
      {
        label: intl.formatMessage({ id: "MaxQuantity" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "maxQuantity",
        inputType: "number",
        validationRequired: true
      },
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: (promotionDetail || {
      id: "",
      isDelete: false,
      code: "",
      desc: "",
      brandId: "",
      brandName: "",
      startDate: "",
      endDate: "",
      isForStaffOnly: false,
      isForSponsorProfileOnly: false,
      promotionType: "",
      redemptionCapType: "",
      maxUsePerCustomer: 0,
      maxRedemption: 0,
      isActive: false,
      promotionDetails: [],
      promotionProduct: [],
      promotionService: [],
      promotionPackage: []
    }),
    validationSchema: Yup.object({
      code: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PromotionCode" }) })),
      promotionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PromotionType" }) })),
      redemptionCapType: Yup.string().test(
        "redemptionCapType",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "RedemptionCapType" }) }),
        (value: any, context) => {
          if (!blnStaffOnly) {
            return value;
          }
          return true;
        }
      ).nullable(),
      startDate: Yup.string().test(
        "startDate",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PromotionStartDate" }) }),
        (value: any, context) => {
          if (displayDate) {
            return value;
          }
          return true;
        }
      ).nullable(),
      endDate: Yup.string().test(
        "endDate",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PromotionEndDate" }) }),
        (value: any, context) => {
          if (displayDate) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.promotionDetails = formatPromotionDetailsList(valuesTemp.promotionProduct, valuesTemp.promotionPackage, valuesTemp.promotionService)

      if (valuesTemp.isForStaffOnly) {
        delete valuesTemp.redemptionCapType;
        delete valuesTemp.maxUsePerCustomer;
        delete valuesTemp.maxRedemption;
      }

      if (!displayDate) {
        delete valuesTemp["startDate"];
        delete valuesTemp["endDate"];
      }

      try {
        if (promotionDetailsValidation(valuesTemp)) {
          return;
        }

        if (addAction) {
          await addPromotion(valuesTemp)
        }
        else {
          await updatePromotion(valuesTemp)
        }
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnStaffOnly = validation.values.isForStaffOnly == true;
  const blnPermissionUpdatePromotion = checkPermission([PermissionConstants.UpdatePromotion]);

  useEffect(() => {
    async function fetchPromotionDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getProductDropdown({ productType: 2 /*Purchasable*/ }),
        getServiceTreatmentDropdown({ branchId: getBranchId(), isActive: true }),
        getPackageDropdown({ branchId: getBranchId(), isActive: true }),
        getStaticReferenceWithType(Constants.promotionType),
        getStaticReferenceWithType(Constants.redemptionCapType),
        getStaticReferenceWithType(Constants.discountType)
      ];
      if (id && !addAction) {
        aryAPI.push(getPromotionWithId(id))
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPromotionDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePromotion], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePromotion], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePromotion], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdatePromotion) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.promotion}/edit/${id}`) } })
    }

    fetchPromotionDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPromotionDetail(undefined);
      }
    })
  }, [])

  useEffect(() => {
    let aryServiceTemp: PromotionItemObject[] = [];
    let aryProductTemp: PromotionItemObject[] = [];
    let aryPackageTemp: PromotionItemObject[] = [];
    let arySelectedService: any = [];
    let arySelectedProduct: any = [];
    let arySelectedPackage: any = [];

    if (id && !addAction) {
      if (promotionDetail?.promotionType == Constants.byPeriod) {
        setDisplayDate(true);
      }
      arySelectedService = compareArrayWithField(promotionDetail?.promotionDetails || [], serviceTreatmentDropdownList, "itemId", "id")
      arySelectedProduct = compareArrayWithField(promotionDetail?.promotionDetails || [], productDropdownList, "itemId", "id")
      arySelectedPackage = compareArrayWithField(promotionDetail?.promotionDetails || [], packageDropdownList, "itemId", "id")
    }

    serviceTreatmentDropdownList.map((value) => {
      let indexSelectedServiceTemp = _.findIndex(arySelectedService, { itemId: value.id })
      aryServiceTemp.push({
        itemId: value.id,
        itemName: value.name,
        sellingPrice: value.sellingPrice,
        purchaseType: Constants.service,
        discountType: "",
        discountValue: 0,
        maxQuantity: 0,
        maxPercentage: 100,
        disabled: indexSelectedServiceTemp >= 0 ? true : false
      })
    })

    productDropdownList.map((value) => {
      let indexSelectedProductTemp = _.findIndex(arySelectedProduct, { itemId: value.id })
      aryProductTemp.push({
        itemId: value.id,
        itemName: value.name,
        sellingPrice: value.sellingPrice,
        purchaseType: Constants.product,
        discountType: "",
        discountValue: 0,
        maxQuantity: 0,
        maxPercentage: 100,
        disabled: indexSelectedProductTemp >= 0 ? true : false
      })
    })

    packageDropdownList.map((value) => {
      if (!value.isPrepaidPackage && !value.isCreditPackage) {
        let indexSelectedPackageTemp = _.findIndex(arySelectedPackage, { itemId: value.id })
        aryPackageTemp.push({
          itemId: value.id,
          itemName: value.name,
          sellingPrice: value.sellingPrice,
          purchaseType: Constants.package,
          discountType: "",
          discountValue: 0,
          maxQuantity: 0,
          maxPercentage: 100,
          disabled: indexSelectedPackageTemp >= 0 ? true : false
        })
      }
    })

    setPromotionProductList(aryProductTemp);
    setPromotionServiceList(aryServiceTemp);
    setPromotionPackageList(aryPackageTemp);
  }, [productDropdownList, packageDropdownList, serviceTreatmentDropdownList, promotionDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
        setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const blnEndDate = validation.values.startDate && validation.values.startDate !== "";

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onChangePromotionType = (type) => {
    let valueTemp = removeAllSpace(type);
    if (valueTemp == Constants.byPeriod) {
      setDisplayDate(true);
    }
    else {
      setDisplayDate(false);
    }
  };

  const formatPromotionDetailsList = (promoProduct?: any, promoPackage?: any, promoService?: any) => {
    let aryPromotionDetailsTemp: PromotionItemObject[] = [];
    if (promoProduct.length > 0) {
      promoProduct.map((value) => {
        aryPromotionDetailsTemp.push({
          id: value.id,
          itemId: value.itemId,
          itemName: value.itemName,
          sellingPrice: value.sellingPrice,
          purchaseType: value.purchaseType,
          discountType: value.discountType,
          discountValue: value.discountValue,
          maxQuantity: value.maxQuantity
        })
      })
    }
    if (promoPackage.length > 0) {
      promoPackage.map((value) => {
        aryPromotionDetailsTemp.push({
          id: value.id,
          itemId: value.itemId,
          itemName: value.itemName,
          sellingPrice: value.sellingPrice,
          purchaseType: value.purchaseType,
          discountType: value.discountType,
          discountValue: value.discountValue,
          maxQuantity: value.maxQuantity
        })
      })
    }
    if (promoService.length > 0) {
      promoService.map((value) => {
        aryPromotionDetailsTemp.push({
          id: value.id,
          itemId: value.itemId,
          itemName: value.itemName,
          sellingPrice: value.sellingPrice,
          purchaseType: value.purchaseType,
          discountType: value.discountType,
          discountValue: value.discountValue,
          maxQuantity: value.maxQuantity
        })
      })
    }
    return (aryPromotionDetailsTemp);
  }

  const promotionDetailsValidation = (promotionDetailTemp: any) => {
    let promotionDetailsArrTemp = _.cloneDeep(promotionDetailTemp.promotionDetails);

    let blnEmptyDiscountTypeBlock = false;
    let blnEmptyDiscountValueBlock = false;
    let blnEmptyMaxQuantityBlock = false;
    let blnBlock = false;
    let valuePurchaseTypeTemp = "";

    if (promotionDetailsArrTemp.length > 0) {
      for (let a = 0; a < promotionDetailsArrTemp.length; a++) {
        let value = promotionDetailsArrTemp[a];
        if (value.discountType === "") {
          blnEmptyDiscountTypeBlock = true
        }
        else if (!value.discountValue || value.discountValue === 0) {
          blnEmptyDiscountValueBlock = true
        }
        else if (!value.maxQuantity || value.maxQuantity === 0) {
          blnEmptyMaxQuantityBlock = true
        }

        if (blnEmptyDiscountTypeBlock || blnEmptyDiscountValueBlock || blnEmptyMaxQuantityBlock) {
          valuePurchaseTypeTemp = value.purchaseType;
          setActiveTab(`${valuePurchaseTypeTemp.toUpperCase()}`)
          break;
        }
      }
    }
    else {
      blnBlock = true;
    }

    if (blnBlock) {
      setErrorMessage("Please select at least one Product/Package/Service Treatment")
    }
    else if (blnEmptyDiscountTypeBlock) {
      setErrorMessage(`Please select Discount Type in ${valuePurchaseTypeTemp} tab`)
    }
    else if (blnEmptyDiscountValueBlock) {
      setErrorMessage(`Discount Value in ${valuePurchaseTypeTemp} tab must be greater than 0`)
    }
    else if (blnEmptyMaxQuantityBlock) {
      setErrorMessage(`Max Quantity in ${valuePurchaseTypeTemp} tab must be greater than 0`)
    }

    return blnEmptyDiscountTypeBlock || blnEmptyDiscountValueBlock || blnEmptyMaxQuantityBlock || blnBlock;
  }

  const displayActiveCheckboxUI = () => {
    return (
      <GeneralInput
        title={intl.formatMessage({ id: "Active" })}
        name="isActive"
        type="checkbox"
        disabled={disabledFieldInput || viewAction}
        validation={validation}
        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {
          loading
            ?
            <Loading />
            :
            <Form
              className="standard-layout"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <Row form={+true}> {/* +true = Convert boolean to numbers*/}
                <Col xl={4}>
                  <LineBreakWithTittle
                    paddingBottom="0px"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Promotion" }) })}
                    h4Title />
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PromotionCode" })}
                      name="code"
                      className="mb-3 mt-4"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation} />
                  </SingleColumnRowParent>
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"promotionType"}
                      title={intl.formatMessage({ id: "PromotionType" })}
                      specifyReturnFieldName={[
                        {
                          field: "promotionType",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={promotionType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      onChangeFunction={onChangePromotionType}
                      initialValue={!addAction ? validation.values.promotionType : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </SingleColumnRowParent>
                  {
                    displayDate
                    &&
                    <Row>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "PromotionStartDate" })}
                          name="startDate"
                          type="date"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={displayDate}
                          validation={validation} />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "PromotionEndDate" })}
                          name="endDate"
                          type="date"
                          disabled={disabledFieldInput || viewAction || !blnEndDate}
                          minCurrentDate={true}
                          minDateValue={validation.values.startDate ? moment(validation.values.startDate).format(Constants.displayDateFormat) : ""}
                          validationRequired={displayDate}
                          validation={validation} />
                      </DoubleColumnRowParent>
                    </Row>
                  }
                  <div className="flex-direction-row flex-wrap"> 
                      <GeneralInput
                        title={intl.formatMessage({ id: "LimitTo" })}
                        name="isForStaffOnly"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction || validation.values.isForSponsorProfileOnly}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "StaffOnly" })}</Label>} />
                      <GeneralInput
                        title=" "
                        name="isForSponsorProfileOnly"
                        className="margin-left-16"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction || validation.values.isForStaffOnly}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "SponsoredProfileOnly" })}</Label>} />
                      {
                        windowSize.innerWidth > Constants.innerWidthCompare472 
                        &&
                        <div className="margin-left-72">
                          {displayActiveCheckboxUI()}
                        </div>
                      }
                  </div>
                  {
                    windowSize.innerWidth < Constants.innerWidthCompare472 
                    &&
                    displayActiveCheckboxUI()
                  }
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <GeneralTextArea
                      title={intl.formatMessage({ id: "Description" })}
                      name="desc"
                      row={5}
                      disabled={disabledFieldInput || viewAction}
                      validation={validation} />
                  </SingleColumnRowParent>
                  {
                    !blnStaffOnly &&
                    <>
                      <LineBreakWithTittle
                        title={intl.formatMessage({ id: "ForCustomerUse" })}
                        className="mt-4"
                        h4Title
                      />
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <DropDownWithTitle
                          name={"redemptionCapType"}
                          title={intl.formatMessage({ id: "RedemptionCapType" })}
                          specifyReturnFieldName={[
                            {
                              field: "redemptionCapType",
                              value: "displayValue",
                            },
                          ]}
                          labelField={"displayValue"}
                          valueField={"displayValue"}
                          options={redemptionCapType}
                          blnValueWithNewSpace={true}
                          disabled={disabledFieldInput || viewAction}
                          initialValue={!addAction ? validation.values.redemptionCapType : undefined}
                          validationRequired={!blnStaffOnly}
                          validation={validation} />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "MaxUserPerCustomer" })}
                          name="maxUsePerCustomer"
                          type="number"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "MaxRedemption" })}
                          name="maxRedemption"
                          type="number"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation} />
                      </SingleColumnRowParent>
                    </>
                  }
                </Col>
                <Col xl={8}>
                  <Nav tabs className="">
                    {tabBar.map((value, indexTab) => (
                      <NavItem
                        key={`tabkey_${value}`}
                      >
                        <NavLink
                          style={{ cursor: "pointer", }}
                          className={classNames({ active: activeTab === `${value == intl.formatMessage({ id: "ServiceTreatment" }).toUpperCase() ? Constants.service.toUpperCase() : value.toUpperCase()}`, })}
                          onClick={() => { toggle(`${value == intl.formatMessage({ id: "ServiceTreatment" }).toUpperCase() ? Constants.service.toUpperCase() : value.toUpperCase()}`); }}
                        >
                          {value}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {activeTab === `${Constants.service.toUpperCase()}` && !refreshSubTabList && (
                    <TableWithEditableInputFields
                      name="promotionService"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      options={promotionServiceList}
                      optionsId={"itemId"}
                      setOptionsFunction={setPromotionServiceList}
                      addButtonFunction={() => setServiceTreatmentModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.promotionService}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )}
                  {activeTab === `${Constants.package.toUpperCase()}` && !refreshSubTabList && (
                    <TableWithEditableInputFields
                      name="promotionPackage"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      options={promotionPackageList}
                      optionsId={"itemId"}
                      setOptionsFunction={setPromotionPackageList}
                      addButtonFunction={() => setPackageModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.promotionPackage}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )}
                  {activeTab === `${Constants.product.toUpperCase()}` && !refreshSubTabList && (
                    <TableWithEditableInputFields
                      name="promotionProduct"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      options={promotionProductList}
                      optionsId={"itemId"}
                      setOptionsFunction={setPromotionProductList}
                      addButtonFunction={() => setProductModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.promotionProduct}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )}
                </Col>
              </Row>
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                blnMarginTopByInnerWidth={true}
              />
            </Form>
        }
        {
          productModal &&
          <SelectDropDownModal
            blnShow={productModal}
            setModal={setProductModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
            buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
            placeholder={"- Please Select -"}
            labelField={"itemName"}
            valueField={"itemId"}
            options={promotionProductList}
            setOptionsFunction={setPromotionProductList}
            fieldInput={validation.values.promotionProduct}
            fieldName={"promotionProduct"}
            validation={validation} />
        }
        {
          packageModal &&
          <SelectDropDownModal
            blnShow={packageModal}
            setModal={setPackageModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Package" }) })}
            buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
            placeholder={"- Please Select -"}
            labelField={"itemName"}
            valueField={"itemId"}
            options={promotionPackageList}
            setOptionsFunction={setPromotionPackageList}
            fieldInput={validation.values.promotionPackage}
            fieldName={"promotionPackage"}
            validation={validation} />
        }
        {
          serviceTreatmentModal &&
          <SelectDropDownModal
            blnShow={serviceTreatmentModal}
            setModal={setServiceTreatmentModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
            buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
            placeholder={"- Please Select -"}
            labelField={"itemName"}
            valueField={"itemId"}
            options={promotionServiceList}
            setOptionsFunction={setPromotionServiceList}
            fieldInput={validation.values.promotionService}
            fieldName={"promotionService"}
            validation={validation} />
        }
      </DetailViewLayout>
    </div>
  );
}

export default observer(PromotionDetail);
