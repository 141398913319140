import React, { useState } from "react";
import { ObjectForDropdown } from "../../models/common";
import { Label, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import LineBreakWithTittle from "../form/LineBreakWithTittle";
import _ from "lodash";
import moment from "moment";
import { handleCustomizeTableScrolling } from "../../common/function/function";
import { Constants } from "../../constants/Constants";

interface Props {
  name: string;
  className?: string;
  title: string;
  blnEditable:boolean
  blnValidation?:boolean;
  timeSlot: ObjectForDropdown[];
  data: any;
  initialValue?: string;
  validation: any;
  disabled: boolean;
  customCheckedField?: string;
}

export default function TableWithTimeSlot(props: Props) {
	const [aryData, setData] = useState(_.cloneDeep(props.data));
	const checkedField = props.customCheckedField || "isOperation";
	const blnScreenSupportScroll = handleCustomizeTableScrolling();

	const onChangeChecked = (e: React.ChangeEvent<any>, rowIndex: number) => {
		aryData[rowIndex][checkedField] = e.target.checked;
		props.validation.setFieldValue(props.name, aryData);
	};

	const onChangedDropdown = (value: any, rowIndex: number, blnFromTime) => {
		if (blnFromTime) {
			aryData[rowIndex].fromTime = moment(value, 'hh:mm A').format();;
		} else {
			aryData[rowIndex].toTime = moment(value, 'hh:mm A').format();;
		}

		props.validation.setFieldValue(props.name, aryData);
	};

	const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: props.validation.touched[props.name] && props.validation.errors[props.name] ? 'red' : base.borderColor,
    })
  }

	const displayCheckBox = (rowIndex, rowValue) => {
		return (
			<Col xl={"4"} md={"4"} xs={"4"}>
        <div className="flex-direction-row">
          <Input
            type="checkbox"
            onChange={(e) => onChangeChecked(e, rowIndex)}
            checked={rowValue[checkedField]}
            disabled={props.disabled || !props.blnEditable}
            onBlur={props.validation.handleBlur}
          />
          <Label className="margin-left-4 margin-bottom-4">{rowValue.name}</Label>
        </div>
			</Col>
		);
	};

	const displayTimeDropDown = (rowIndex, rowValue, blnFromTime) => {
		let strTime = blnFromTime ? "fromTime" : "toTime";
    let strValueTemp = moment(rowValue[strTime]).format('hh:mm A');
		let blnClassNamePrex = `${strTime}_${rowIndex}`
		return (
			<Col xl={"4"} md={"4"} xs={"4"}>
				<Select
					name={strTime}
					classNamePrefix={blnClassNamePrex}
          defaultValue={{ label: strValueTemp, value: strValueTemp }}
					options={props.timeSlot}
					styles={selectStyle}
					onChange={(e) =>
						onChangedDropdown(e?.value, rowIndex, blnFromTime)
					}
          menuPosition="fixed"
					isDisabled={props.disabled || !props.blnEditable || !rowValue[checkedField]}
          onBlur={props.validation.handleBlur}
					onMenuOpen={()=> {
						setTimeout(()=>{
							const selectedEl = document.getElementsByClassName(`${blnClassNamePrex}__option--is-selected`)[0];
							if(selectedEl){
								selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
							}
						},15)
          }}
				/>
			</Col>
		);
	};

	const displayEmptyRow = () => {
		return (
			// <Label>There's no content available</Label>
			<LineBreakWithTittle
				title={`There's no content available`}
				color="grey"
			/>
		);
	};

	return (
		<div className={`${props.className} ${blnScreenSupportScroll ? "table-select-row-support-scroll-without-flex" : ""}`}>
			<div className={`${props.className} tab_bar_border`} style={{...blnScreenSupportScroll && {minWidth: '800px'}}}>
				<Label className="mb-3">
					{props.blnValidation && (
						<Label style={{ color: "red" }}>{`*`}</Label>
					)}
					<Label className="margin-bottom-4">{props.title}</Label>
				</Label>
				{
					<Row>
						{aryData.length > 0
							? aryData.map((valueTemp, index) => {
									return (
										<Col key={`${valueTemp.id} ${index}`} xl={"12"} style={{marginTop: index >0 ? "8px" : "0px"}}>
											<Row
												className={`flex-align-items-center margin-bottom-4'}`}
												key={`${valueTemp.id} ${index}`}
											>
												{displayCheckBox(index, valueTemp)}
												{displayTimeDropDown(index, valueTemp, true)}
												{displayTimeDropDown(index, valueTemp, false)}
											</Row>
										</Col>
									);
								})
							: displayEmptyRow()}
					</Row>
				}
			</div>
		</div>
	);
}
