import { PaginationRequestBody } from "../models/pagination";
import { ExtendPackageListObject, ExtendPackageRequestObject, ExtendPackageReviewObject, ExtendPackageDetailObject } from "../models/extendPackage";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class extendPackageStore {
  extendPackageList: ExtendPackageListObject[] = [];
  extendPackageDetail: ExtendPackageDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.extendPackageList = [];
    this.extendPackageDetail = undefined;
  }

  setExtendPackageDetail = (extendPackageDetail: ExtendPackageDetailObject | undefined) => {
    this.extendPackageDetail = extendPackageDetail;
  }
  
  getExtendPackage = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultExtendPackage = await agent.ExtendPackage.extendPackageList(PaginationRequestBody);
      runInAction(() => {
        this.extendPackageList = resultExtendPackage.data;
        store.commonStore.setTotalItem(resultExtendPackage.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.extendPackageList = [];
    }
  }

  getExtendPackageWithId = async (id: string) => {
    try{
      const resultExtendPackageDetail = await agent.ExtendPackage.extendPackageDetail(id);
      runInAction(() => {
        this.extendPackageDetail = resultExtendPackageDetail;
      });
      return Promise.resolve(resultExtendPackageDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.extendPackageDetail = undefined;
    }
  }

  requestExtendPackage = async (extendPackageRequestBody: ExtendPackageRequestObject) => {
    try{
      await agent.ExtendPackage.requestExtendPackage(extendPackageRequestBody);
      store.commonStore.setSuccessMessage(`ExtendPackageRequestSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewExtendPackage = async (extendPackageRequestBody: ExtendPackageReviewObject) => {
    try{
      await agent.ExtendPackage.extendPackageReview(extendPackageRequestBody);
      store.commonStore.setSuccessMessage(`ExtendPackageReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteExtendPackage  = async (id: string, name: string) => {
    try {
      await agent.ExtendPackage.deleteExtendPackage(id);
      store.commonStore.setSuccessMessage(`ExtendPackageDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}