import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Card
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import Borderline from "../../app/components/form/BorderLine";
import ProductRedemptionNoteCart from "./subView/ProductRedemptionNoteCart";
import SignaturePad from "../../app/components/form/SignaturePad";
import { CustomerDeliverOrder } from "../../app/models/customerOrder";
import { useIntl } from "react-intl";
// users
import user9 from '../../assets/images/users/avatar-9.png';
import { checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { Link } from 'react-router-dom';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const ProductRedemptionNoteAcknowledgement = () => {
  //Use INTL
  const intl = useIntl();
  
  //Use Store
  const { productRedemptionNoteStore, commonStore } = useStore();
  const { loading, errorMessage, successMessage, setErrorMessage, setSuccessMessage } = commonStore;
  const { signProductRedemptionNote } = productRedemptionNoteStore;
  const location = useLocation();
  const [breadCrumbList, setBreadCrumbList] = useState([{ title: `${intl.formatMessage({id: "VirtualWarehouse"})}`, urlPath: RoutesList.customerVirtualWarehouse }, { title: `${intl.formatMessage({id: "CustomerVirtualWarehouse"})}`, urlPath: RoutesList.customerVirtualWarehouse }]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateProductRedemptionNote = checkPermission([PermissionConstants.UpdateProductRedemptionNote])

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      customerBranchName: "",
      branchId: "",
      note: "",
      redeemDetails: [],
      signatureImage: "",
      productRedemptionNoteId: "",
      productRedemptionNoteNo: "",
      signatureError: false,
      dataSubmitted: false,
      blnAddFromCustomerProfile: false,
		},
		validationSchema: Yup.object({
    }),
		onSubmit: async (values) => {
      const valuesTemp : any = _.cloneDeep(values);
      if (!valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
      } 
      else {
        let valuesFinal = {
          signatureImage: valuesTemp.signatureImage,
          id: validation.values.productRedemptionNoteId
        }
        try {
          let result = await signProductRedemptionNote(valuesFinal);
          if (result?.status == Constants.success) {
            validation.setFieldValue("dataSubmitted", true);
          }
        } finally {
          validation.setSubmitting(false);
        }
      }
    }
	});

  useEffect(() => {
    if (location.state) {
      validation.setValues({
        ...location.state.detail, 
        dataSubmitted: false,
        signatureImage: "",
        signatureError: false,
      })
    }
    else {
      history.push(`/${RoutesList.productRedemptionNote}`)
    }

    if (location.state.detail.blnAddFromCustomerProfile) {
      setBreadCrumbList([{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${location.state.detail.customerId}` }, ])
    }
  }, [location]);

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        setSuccessMessage("");
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  //Table Content UI
  const ProductRedemptionNoteColumns = [
    { 
      dataField: "productRedemptionNoteId", //field name from array to display
      text: "productRedemptionNoteId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.productRedemptionNoteId}</>, //Custom UI to display
    },
    {
      dataField: "productRedemptionNoteNo",
      text: intl.formatMessage({id: "ProductRedemptionNo"}),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({id: "Action"}),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },   
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton 
              type="button"
              content={intl.formatMessage({id: "View"})}
              class="btn btn-primary"
              onClick={() => { history.push(`/${RoutesList.productRedemptionNote}/edit/${row.productRedemptionNoteId}`)}}/>
          </div>
        </>
      ),
    }
  ];

  return (
    <div>
      <div 
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ProductRedemptionNoteAckowledgement"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({ id: "ProductRedemptionNote" })}</div>
            </Col>
          </Row>
          <BreadCrumb 
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={intl.formatMessage({ id: "ModuleNameWithAcknowledgement" }, { moduleName: intl.formatMessage({id: "ProductRedemptionNote"})})}/>
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading && !successMessage ? (
            <Loading />
					) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={()=> {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5">
                  <div className="standard-layout">
                    <ProductRedemptionNoteCart
                      validation={validation}
                      disabledCustomerOrder={true}
                      blnDisplayNote={true}/>
                  </div>
                </Col>
                <Col xl="7">
                  <div className="standard-layout">
                    <div className="flex-justfity-content-center">
                      <Label style={{fontSize: '30px'}}> {intl.formatMessage({ id: "CustomerRedemption" }).toUpperCase()} </Label>
                    </div>
                    <Borderline/>
                    <Label>
                      <Label className="margin-bottom-0 margin-top-0">{intl.formatMessage({ id: "Customer" })}</Label>
                    </Label>
                    <div className="d-flex mt-3">
                      <div className="me-3">
                        <Link to={`/${RoutesList.customer}/view/${validation.values.customerId}`} target={Constants.blank}>
                          <img
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                            src={user9}
                          />
                        </Link>
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <Link to={`/${RoutesList.customer}/view/${validation.values.customerId}`} target={Constants.blank} style={{fontSize: "13px"}}>{validation.values.customerName}</Link>
                          <p style={{fontSize: "11px"}} className="mb-1">{intl.formatMessage({ id: "CustomerNo" })}: {validation.values.customerNo}</p>
                          <p style={{fontSize: "11px"}} className="mb-0">{intl.formatMessage({ id: "MobileNo" })}: {validation.values.customerMobileNo}</p>
                        </div>
                      </div>
                    </div>
                    <Borderline />
                    <BootstrapTable
                      responsive
                      bordered={false}
                      striped={false}
                      classes={"table align-middle"}
                      keyField="productRedemptionNoteId"
                      noDataIndication={intl.formatMessage({ id: "NoProductRedemptionNoted" })}
                      data={[{productRedemptionNoteId: validation.values.productRedemptionNoteId, productRedemptionNoteNo: validation.values.productRedemptionNoteNo}]}
                      columns={ProductRedemptionNoteColumns}
                    />
                    {
                      blnPermissionUpdateProductRedemptionNote
                      &&
                      <SignaturePad 
                        successMessage={successMessage}
                        validation={validation}
                        disabled={validation.values.dataSubmitted}
                        isSubmitting={validation.isSubmitting}/>
                    }
                    {
                      !Boolean(successMessage) 
                      &&
                      blnPermissionUpdateProductRedemptionNote 
                      &&
                      <Col style={{marginTop: "3rem"}}>
                        <MyButton
                          type={!validation.values.dataSubmitted ? "submit" : "button"}
                          class="btn btn-success"
                          style={{width: '100%'}}
                          content={intl.formatMessage({ id: !validation.values.dataSubmitted ? "Sign" : "Done" })}
                          onClick={()=> {
                            if (validation.values.dataSubmitted) {
                              if (validation.values.blnAddFromCustomerProfile) {
                                history.push(`/${RoutesList.customer}/view/${validation.values.customerId}`)
                              }
                              else {
                                history.push(`/${RoutesList.productRedemptionNote}`)
                              }
                            }
                          }}
                          disable={
                            validation.isSubmitting
                          }
                          loading={
                            validation.isSubmitting
                          }
                        />
                      </Col>
                    }
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
}

export default observer(ProductRedemptionNoteAcknowledgement);
